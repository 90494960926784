import { gql } from '@apollo/client';
import StdQuery from 'components/data/StdQuery.js';
import React from 'react';
import PropTypes from 'prop-types';
import PublicAppVars from 'utils/PublicAppVars.js';
import { filter, values } from 'lodash';
import { RefetchIfOld, useRefetchIfOld } from 'components/data/ServerTime.js';

import useStdQuery from 'components/data/hooks/useStdQuery.js';
import { LOGGED_IN_QUERY } from 'components/data/session-user/LoggingIn.js';
import { GET_ACCEPTED_EULAS } from 'components/data/eulas/AcceptedEulas.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import * as errorMsgStyle from 'styles/errormsg.module.css';
import { EulaFeatures } from './EulaSearch.js';

const cms = {
	pendingEulaError: 'miscText.pending-ach-eula-error',
};

export const GET_PENDING_ACH_EULAS = gql`
	query GetPendingAchEulas {
		eulas {
			pendingAchEulas {
				pendingAchEulasList {
					id
					eulaId
					eulaType
					status
					channel
					name
					eulaDisplayName
					feature
				}
				nodeTime
			}
		}
	}
`;

export const eulaRefetchQueries = [
	{ query: LOGGED_IN_QUERY },
	{ query: GET_PENDING_ACH_EULAS },
	{ query: GET_ACCEPTED_EULAS, variables: { eulaType: PublicAppVars.EULA_ACH_TYPE } },
];

export const pendingAchEulas = (eulas) => {
	return filter(eulas, eula => {
		const eulaChannels = PublicAppVars.EULA_CHANNELS;
		return eula.eulaType === PublicAppVars.EULA_ACH_TYPE
			&& eula.feature === EulaFeatures.NEW_ACH
			&& eulaChannels.includes(eula.channel);
	});
};


export const usePendingAchEulas = () =>  {
	const queryResult = useStdQuery(GET_PENDING_ACH_EULAS);

	const { data, refetch, loading } = queryResult;

	useRefetchIfOld({ refetch, loading, queryTime: data?.eulas?.pendingAchEulas?.nodeTime });

	const pendingAchEulas = queryResult?.data?.eulas?.pendingAchEulas?.pendingAchEulasList ?? [];

	return { ...queryResult, pendingAchEulas };
};

//displays generic error when eula cannot be loaded
export const PendingAchEulaError = ({ error }) => (
	<CmsContentList list={values(cms)}>{({ cmsContent }) => (
		<div className={errorMsgStyle.errorMsg}>
			<CmsContentRenderer.Span  contentKey={cms.pendingEulaError} />
		</div>
	)}</CmsContentList>
);

const PendingAchEulas = ({
	children,
}) => (
	<StdQuery
		query={GET_PENDING_ACH_EULAS}
	>
		{(response) => {
			const { data, refetch, loading, error } = response;

			// data is an array of WSEulaInfo
			return <>
				<RefetchIfOld {...{
					refetch,
					loading,
					queryTime: data?.eulas?.pendingAchEulas?.nodeTime,
				} } />

				{error
					? <PendingAchEulaError />
					: children(data.eulas.pendingAchEulas.pendingAchEulasList, response)
				}
			</>;
		}}
	</StdQuery>
);

PendingAchEulas.propTypes = {
	children: PropTypes.func.isRequired,
};

export default PendingAchEulas;
