import { useLazyQuery, useQuery } from "@apollo/client";
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { levels, log } from 'utils/Logger.js';
import { useCallback } from "react";
import { deserializeGraphqlRequestError } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";

const postQueryActions = (query, queryName, args, history) => {
	if (args.error) {
		log(null, levels.error, {
			error: args.error,
			variables: args.variables || {},
			message: 'useStdQuery server error',
			queryName,
		});
	}

	const backtracePrefix = 'Unexpected error value: { TopLevelKey: "miscText.error-general-auth-required"';
	if (args.error?.graphQLErrors.find(({ message }) => message?.slice?.(0, backtracePrefix.length) === backtracePrefix)) {
		history.push('/');
	}
};

const useStdQuery = (query, options = {}) => {
	const args = useQuery(query, options);
	const history = useHistory();
	const queryName = get(query, 'definitions[0].name.value');

	postQueryActions(query, queryName, args, history);

	return {
		...args,
		parsedError: args.error ? deserializeGraphqlRequestError(args.error) : null,
		queryName,
	};
};

// @todo untested, use at own risk, remove this comment if it works
export const useStdLazyQuery = (query, options = {}) => {
	const [ queryCallback, args ] = useLazyQuery(query, options);
	const history = useHistory();

	const queryName = get(query, 'definitions[0].name.value');
	const memodQueryCallback = useCallback(async (options) => {
		const result = await queryCallback(options);

		postQueryActions(query, queryName, result, history);

		return result;
	}, [ queryCallback, query, queryName, history ]);

	return [
		memodQueryCallback,
		{
			...args,
			queryName,
		},
	];
};

export default useStdQuery;
