import { gql } from "@apollo/client";
import StdQuery from "./StdQuery.js";
import React from "react";

export const GET_VERSION = gql`query GET_VERSION {
	version
}`;
export const GetVersion = (props) => (
	<StdQuery query={GET_VERSION}>
		{(data) => props.children({
			...data,
			version: data.data.version,
		})}
	</StdQuery>
);
