import cx from 'classnames';

import React from "react";
import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";
import {
	head,
	values,
} from 'lodash';
import {
	object as yup_object,
	string as yup_string,
} from "yup";

import {
	GET_TRANSIT_ACCOUNT,
	useTransitAccount,
} from "components/data/transit-account/TransitAccount.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import CmsContentList from "components/data/CmsContentList.js";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { Error as ComponentError } from 'components/data/Error.js';
import { ComponentLoading } from 'components/icons/LoadingIcon.js';
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';

import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import PreventDefault from 'utils/PreventDefault.js';

import Button from "components/Button.js";
import Input from "components/forms/Input.js";
import Panel from 'components/Panel.js';

import {
	IssueSerializedToken,
	TOKEN,
} from "utils/Constants.js";

import {
	OPEN_TRANSIT_REGULAR_CARD,
	OPEN_TRANSIT_TEMPORARY_CARD,
} from 'server/api-types/WSSubsystemAccountToken.js';

import * as cardSummaryStyle from 'components/account/CardSummary.module.css';
import * as addCardsStyle from 'components/account/AddAnotherCardActions.module.css';
import * as style from './ActivateCard.module.css';

import { getLast4Digits } from 'components/card-icons/util.js';
import { TOKEN_ACTION } from 'components/data/transit-account/subsystem/travel-token/TokenAction.mutation';
import { RENAME_CARD } from 'components/data/transit-account/subsystem/travel-token/RenameCard.mutation.js';
import { TRAVEL_TOKEN_TYPES } from 'server/api-types/WSSubsystemAccountTravelTokenDisplayFactory';
import { USER_PAYMENT_METHODS_QUERY } from 'components/data/session-user/SessionUser';
import PublicAppVars from 'utils/PublicAppVars';
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { nickNameMaxLengthCMSKey } from "components/modals/EditCardNickname.js";
import { charlieCardCmskeys } from "../CharlieCardCmskeys.js";


const cms = {
	title: 'miscText.Modals-RenamePaymentMethod-Title',
	header: 'miscText.cardinfo-activate-subheader',
	activeInfoUpgradeLabel: 'miscText["cardinfo-activate-info-upgrade"]',
	activeGenericLabel: 'miscText["cardinfo-activate-info-generic"]',
	activeNewCardLabel: 'miscText["cardinfo-activate-info-newcard"]',
	activeReplacementLabel: 'miscText["cardinfo-activate-info-replacement"]',
	labelRename: 'miscText["addcard-charlie-physical-nickname.label"]',
	saveName: 'miscText.cardinfo-activate-submit',
	cardNameRequired: 'miscText.validation-renameCard-cardName-required',
	...charlieCardCmskeys,
};

export const getPlaceholderKey = (tokenType) => {
	switch (tokenType) {
		case TRAVEL_TOKEN_TYPES.bankcard:
			return cms.placeholderEmv;
		case TRAVEL_TOKEN_TYPES.smartcard:
		case TRAVEL_TOKEN_TYPES.openTransitRegular:
			return cms.placeholderCharliePhysical;
		case TRAVEL_TOKEN_TYPES.virtualCard:
			return cms.placeholderEmvWallet;
		case TRAVEL_TOKEN_TYPES.openTransitVirtual:
			return cms.placeholderCharlieVirtual;
		case TRAVEL_TOKEN_TYPES.openTransitTemporary:
			return cms.placeholderCharlieTemporary;
		default:
			return cms.placeholderAppleWallet;
	}
};

export const ReMapOpenTransitName = ({ tokenType, cmsContent, tokenNickname }) => [
	TRAVEL_TOKEN_TYPES.smartcard,
	TRAVEL_TOKEN_TYPES.virtualCard,
	TRAVEL_TOKEN_TYPES.openTransitVirtual,
	TRAVEL_TOKEN_TYPES.openTransitRegular,
	TRAVEL_TOKEN_TYPES.openTransitTemporary,
].includes(tokenType) && [ "Open Transit Regular", "Open Transit Temporary" ].includes(tokenNickname)
	? findContentOrFallback(cmsContent, getPlaceholderKey(tokenType), '')
	: tokenNickname;

const ActiveMessage = ({ cmsContent, transitAccount }) => {
	let label = findContentOrFallback(cmsContent, cms.activeGenericLabel, 'Please activate your card to begin using it.');

	if (transitAccount.tokens.length === 1) {
		label = findContentOrFallback(cmsContent, cms.activeNewCardLabel, 'Your new Charlie Card is on the way. When it arrives, activate your new card to begin using it.');
		return <p className={cx(cardSummaryStyle.cardStatLabel, style.paragraph)}> {label} </p>;
	}
	const previousToken = transitAccount.tokens[ 1 ];

	if (previousToken.mediaType === OPEN_TRANSIT_REGULAR_CARD) {
		label = findContentOrFallback(cmsContent, cms.activeReplacementLabel, 'Your replacement Charlie Card is on the way. When it arrives, activate your new card to begin using it.');
	}

	if (previousToken.mediaType === OPEN_TRANSIT_TEMPORARY_CARD) {
		label = findContentOrFallback(cmsContent, cms.activeInfoUpgradeLabel, 'Your new Charlie Card is on the way. When it arrives, activate your card to complete your upgrade. Please note that once you activate your Charlie Card, you will no longer be able to use your temporary Charlie Card.If you do not receive your card, please call customer support.');
	}

	return <p className={cx(cardSummaryStyle.cardStatLabel, style.paragraph)}> {label} </p>;
};

const getYupSchema = () => {
	const validations = {
		cardNickname: yup_string()
			.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, nickNameMaxLengthCMSKey)
			.trim(),
	};
	return yup_object().shape(validations);
};

const ActivateCard = () => {
	const transit_account_id = useTransitAccountIdContext();
	const {
		loading: transitAccountLoading,
		data: transitAccountQdata,
		error: transitAccountError,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id });

	const token = head(transitAccountQdata?.transitAccountQ.tokens);
	const isActivationPending = token && token.statusReasonCode === IssueSerializedToken;

	const [ tokenActionMutator, { tokenActionLoading } ] = useStandardMutation(TOKEN_ACTION);

	const [ updateCardNickNameMutator, { updateCardNickNameLoading } ] = useStandardMutation(
		RENAME_CARD,
		{
			refetchQueries: [
				{ query: USER_PAYMENT_METHODS_QUERY },
				{
					query: GET_TRANSIT_ACCOUNT,
					variables: {
						subsystemAccountReference: transit_account_id,
						subscriptions: true,
						archivedTokens: false,
						programMemberships: false,
						transferProductInfo: false,
						productPayments: false,
					},
				},
			],
		}
	);

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({ getYupSchema });

	if (transitAccountError) {
		return <ComponentError error={transitAccountError} />;
	}

	const primaryToken = findPrimaryToken(transitAccountQdata?.transitAccountQ.tokens);

	const updateCardNickName = async (variables) => {
		try {
			await graphqlErrorMiddleware(updateCardNickNameMutator({ variables }));
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Rename Charlie Card during activation failed`);
			throw errorReport;
		};
	};

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Rename Charlie Card form validation failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
		}

		GoogleAnalytics.logEvent("User is attempting to activate a Charlie Card");

		/* https://reflexions.slack.com/archives/CCF68M49M/p1670460449415679
		* for activating a card we call
		* /traveltoken/<token-id>/<tokenaction> PUT
		* with tokenaction = “Unblock”
		* with reasonCode “25"
		*/
		try {
			const variables = {
				tokenId: primaryToken.tokenId,
				subsystemAccountReference: transit_account_id,
				tokenAction: TOKEN.ACTION.unblock,
				reasonCode: PublicAppVars.MANAGE_TOKENS_REASON_CODES.Activate,
			};

			await graphqlErrorMiddleware(tokenActionMutator({ variables }));

			if (primaryToken?.tokenInfo?.tokenNickname !== variables.cardNickname) {
				await updateCardNickName({
					tokenId: primaryToken.tokenId,
					subsystemAccountReference: transit_account_id,
					cardNickname: validated.cardNickname,
				});
			}
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Activate Charlie Card failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmitting(false);
		}
	};


	const submitDisabled = () => {
		return submitting;
	};


	// // We are looking for  Charlie Card token with IssueSerializedToken
	if (!isActivationPending || !primaryToken) {
		return null;
	}

	if (transitAccountError) {
		return <ComponentError error={transitAccountError} />;
	}

	if (transitAccountLoading || tokenActionLoading || updateCardNickNameLoading) {
		return <ComponentLoading />;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => {
			return (
				<Panel overrideClass={cx(addCardsStyle.container, style.activateCardContainer)}>
					<form
						method="post"
						data-qa="RenameCardForm"
						ref={formRef}
						onSubmit={PreventDefault(kickoffSubmit)}
					>
						{formHelper.getFieldErrorJsx('')}

						<CmsContentRendered.H2
							className={cardSummaryStyle.balanceLabel}
							contentKey={cms.header}
							fallbackValue={`Activate Your New Card…${getLast4Digits(primaryToken.tokenInfo)}`}
							variables={{ last4: getLast4Digits(primaryToken.tokenInfo) }}
						/>

						<ActiveMessage
							cmsContent={cmsContent}
							transitAccount={transitAccountQdata.transitAccountQ}
						/>
						<Input
							type="text"
							label={cmsContent[ cms.labelRename ] || "Card Nickname"}
							hideLabel={false}
							name="cardNickname"
							data-qa="rename-your-payment-method-input"
							placeholder={findContentOrFallback(cmsContent, getPlaceholderKey(primaryToken?.mediaType), '')}
							error={formHelper.getFieldError('cardNickname')}
							showCharCount={true}
							maxLength={PublicAppVars.CARD_NICKNAME_MAXLENGTH}
							defaultValue={ReMapOpenTransitName({
								tokenType: primaryToken?.mediaType,
								cmsContent,
								tokenNickname: primaryToken?.tokenInfo.tokenNickname,
							})}
						/>

						<Button
							overrideClass={style.activateCardBtn}
							aria-label={cmsContent[ cms.saveName ]}
							loading={submitting}
							disabled={submitDisabled()}
						>
							<CmsContentRenderer.Span
								contentKey={cms.saveName}
								fallbackValue={"Activate Card"}
							/>
						</Button>
					</form>
				</Panel>
			);
		}}</CmsContentList>
	);
};

ActivateCard.propTypes = {};

export default ActivateCard;
