import React from 'react';
import { values } from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'GroupAdminRouteKeys.js';
import {
	IfLoggedIn,
	IfNotLoggedIn,
} from "components/data/session-user/LoggingIn.js";

import Container from 'components/Container.js';
import Button, { CharlieSecondary, CharliePrimary } from 'components/Button.js';
import { Charlie } from 'components/Icon.js';
import AccountSelector from './AccountSelector.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import SkipToContent from "layouts/components/SkipToContent.js";
import { main } from "layouts/BaseLayout.js";
import SkipToChatbot from "layouts/components/SkipToChatbot.js";

import * as buttonStyle from "components/Button.module.css";
import * as header from './CharlieHeader.module.css';

// Pulled from the customer charlie header.
const cms = {
	logo: 'miscText.topmenu-charlie-alt',
	logIn: 'miscText.general-buttons-login',
	register: 'miscText.general-buttons-register',
	groupAdmin: 'miscText.topmenu-charlie-group',
	skip: "miscText.general-nav-skip",
	skipToDigitalAssistant: "miscText.globalHeader-skip-to-digital-assistant",
};

const GACharlieHeader= () => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Container overrideClass={header.container}>
				<SkipToContent
					elementId={main}
				>
					<CmsContentRenderer.Span
						contentKey={cms.skip}
						fallbackValue={"Skip to main content"}
					/>
				</SkipToContent>
				<SkipToChatbot>
					<CmsContentRenderer.Span contentKey={cms.skipToDigitalAssistant} />
				</SkipToChatbot>
				<div className={header.logoContainer}>
					<Button
						theme={CharlieSecondary}
						to={getPathByRoute(routeKeys.Home)}
						additionalClassNames={cx(header.logoLink, buttonStyle.link)}
					>
						<Charlie
							overrideClass={header.logo}
							svgAriaDesc={cmsContent[ cms.logo ]}
						/>
						<CmsContentRenderer.Span
							contentKey={cms.groupAdmin}
							className={header.logoText}
							fallbackValue="Group Administration" />
					</Button>
				</div>

				<div className={header.actions}>
					<IfLoggedIn>
						<AccountSelector />
					</IfLoggedIn>

					<IfNotLoggedIn>
						<nav className={header.btns} aria-label={"Login Register Group"}>
							<Button
								theme={CharlieSecondary}
								to={getPathByRoute(routeKeys.SignIn)}
								additionalClassNames={header.btn}
							>
								<CmsContentRenderer.Span
									contentKey={cms.logIn}
									fallbackValue="Login" />
							</Button>
							<Button
								theme={CharliePrimary}
								to={getPathByRoute(routeKeys.Register)}
								additionalClassNames={header.btn}
							>
								<CmsContentRenderer.Span
									contentKey={cms.register}
									fallbackValue="Register" />
							</Button>
						</nav>
					</IfNotLoggedIn>
				</div>
			</Container>
		)}</CmsContentList>
	);
};

export default GACharlieHeader;
