import React, { useContext } from 'react';
import { gql } from "@apollo/client";

import StdQuery from "components/data/StdQuery.js";
import {
	cmn,
	yue,
	LanguageGet,
} from "components/data/Language.js";

import * as style from 'App.module.css';
import PublicAppVars from "utils/PublicAppVars.js";
import { useCspNonceContext } from "components/CspNonceContext.js";

import root from 'react-shadow';


export const GET_MBTA_FOOTER = gql`
	query GetMbtaFooter (
		$language: String
	) {
		mbtaComponents(language: $language) {
			id
			css
			footerContent
		}
	}
`;

// https://reflexions.atlassian.net/browse/MBTA-1916
// Both Mandarin and Cantonese language should map to Cantonese on the footer and header.
export const remapCMNLang = (currentLanguage) => [ cmn, yue ].includes(currentLanguage) ? 'zh-cn' : currentLanguage;

const MBTAFooter = () => {
	const currentLanguage = useContext(LanguageGet);

	if (!PublicAppVars.MBTA_HEADER) {
		return null;
	}

	return (
		<div className={style.globalFooter} data-debug={'mbta raw footer'}>
			<root.div>
				<StdQuery query={GET_MBTA_FOOTER} variables={{ language: remapCMNLang(currentLanguage) }}>
					{({ data }) => <>
						<style>{data?.mbtaComponents.css}</style>
						<div dangerouslySetInnerHTML={{ __html: data?.mbtaComponents.footerContent }} />
					</>}
				</StdQuery>
			</root.div>
		</div>
	);
};

export default MBTAFooter;
