import React, {
	useRef,
	useState,
	useEffect,
} from 'react';
import { useCookies } from 'react-cookie';
import cx from "classnames";
import {
	get,
	values,
} from "lodash";
import {

	Redirect,
	useHistory,
} from "react-router-dom";
import qs, {
	parse as qs_parse,
} from 'qs';
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import { Lifecycles } from "libreact/lib/Lifecycles";

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import FormHelper from "utils/FormHelper.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import ClientAjax from "utils/ClientAjax.js";
import CredentialsForbiddenRedirect from "components/data/session-user/CredentialsForbiddenRedirect.js";
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import PreventDefault from "utils/PreventDefault.js";
import {
	SSO_ACTION_SIGN_IN,
	SSO_PROVIDERS,
} from "utils/constants/Sso.js";
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import { useSessionCheckContext, SESSION_EXPIRED_BANNER } from "components/data/session-user/SessionCheck.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import HelmetOGTags from "layouts/HelmetOGTags.js";

import Container from "components/Container.js";
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import Toast from "components/Toast.js";
import Input from 'components/forms/Input.js';
import {
	Caret,
	Facebook,
	Apple,
	SocialMediaIcon,
} from 'components/Icon.js';
import ContactCustomerService from 'components/banners/ContactCustomerService.js';
import NoAccountFound from 'components/banners/NoAccountFound.js';
import Password from 'components/forms/Password.js';
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";
import LoggedOutBanner from 'components/banners/LoggedOutBanner.js';
import { USER_LOGGED_OUT_BANNER } from 'layouts/components/AccountSelector.js';

import * as login from './Login.module.css';
import * as buttonStyle from 'components/Button.module.css';

const cms = {
	pageTitle: 'miscText.topmenu-login',
	header: 'miscText.signin-header',
	socialSubheader: 'miscText.signin-social-subheader',
	socialFacebookLabel: 'miscText.signin-social-facebook',
	socialGoogleLabel: 'miscText.signin-social-google',
	socialAppleLabel: 'miscText.signin-social-apple',
	emailSubheader: 'miscText.signin-email-subheader',
	emailInputLabel: 'miscText["signin-email-email.label"]',
	passwordInputLabel: 'miscText["signin-email-password.label"]',
	forgotPasswordCTA: 'miscText.signin-email-forgotPasswordCTA',
	emailSubmit: 'miscText.signin-email-submit',
	guestActionsText: 'miscText.signin-guest-actions-text',
	viewTravelHistory: 'miscText.view-travel-history-link',
	registerSuccessHeader: 'miscText.register-success-header',
	registerSuccessDescription: 'miscText.register-success-description',
	newPasswordSuccessHeader: 'miscText.forgot-password-reset-confirmation-header',
	newPasswordSuccessDescription: 'miscText.forgot-password-reset-confirmation-description',

	noAccount: 'miscText.signin-register-text',
	createAccount: 'miscText.signin-register-cta',
};

const CreateAccountLink = ({ }) => (
	<>
		<CmsContentRenderer.P
			contentKey={cms.noAccount}
			fallbackValue="Don't have an account?"
			className={login.otherAuthActionText}
		/>
		<Button
			to={getPathByRoute(routeKeys.Register)}
			typeStyle={buttonTypeStylePlain}
			data-qa="RegisterLink"
		>
			<CmsContentRenderer.P
				contentKey={cms.createAccount}
				fallbackValue="Create an Account"
				className={login.forgotPswd}
			/>
		</Button>
	</>
);

const socialLogin = (linkingKey, setRedirect) => {
	const url = '/login/social/redirect-to-provider?'
		+ qs.stringify({
			linkingKey,
			action: SSO_ACTION_SIGN_IN,
		});

	// client-side can't redirect to an Express path
	if (typeof window !== "undefined") {
		window.location.href = url;
	} else {
		setRedirect(<Redirect push to={url} />);
	}
};

const isAutoCompleteField = (htmlElement) => {
	return htmlElement.hasAttribute('autocomplete');
};

const getYupSchema = () => {
	const validations = {
		email: yup_string()
			.email(getErrorKey(COLLECTIONS.registration, 'contact.email', BO_ERRORS.general.invalidEmail))
			.required(getErrorKey(COLLECTIONS.registration, 'contact.email', BO_ERRORS.general.required)),
		password: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.general.required)),
		...recaptchaYupValidations,
	};
	return yup_object().shape(validations);
};

const SignIn = () => {
	const [ , , removeCookie ] = useCookies([ USER_LOGGED_OUT_BANNER, SESSION_EXPIRED_BANNER ]);
	const [ redirect, setRedirect ] = useState(null);
	const [ submitting, setSubmitting ] = useState(false);
	const [ consumedQueryParams, setConsumedQueryParams ] = useState(false);
	const [ , setPassword ] = useState('');
	const { setToast, removeToast } = useGlobalToastsContext();

	const history = useHistory();
	const location = history.location;

	const query = qs_parse(location.search, {
		ignoreQueryPrefix: true,
	});

	const formRef = useRef(null);
	const [ validationState, setValidationState ] = useState({
		validationError: query.errorMessage,
	});

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(
		validationState,
		setValidationState,
		getYupSchema,
		// this is the formHelper getDataToValidate option, which we dont need to override
		// https://github.com/reflexions/cubic-mta/blob/develop-4/frontend/src/utils/FormHelper.js#L199
		null,
		// formHelper preChangeValidation option, we can set a skipValidation flag
		// If its an autocomplete field, lets skip onChange validation.
		// The issue with autofill is that when chrome autocompletes, the browser triggers an onChange event,
		// so we run a field level validation (as opposed to a full form submit) but it seems that right
		// before the validation runs, triggered by the autofill theres a window of a few milliseconds where the inputs are still empty.
		// so the field level validation fails.
		// https://reflexions.slack.com/archives/C8KP2CGTZ/p1657640132975669
		(event) => isAutoCompleteField(event.target),

	);

	const { syntheticTimerEvent } = useSessionCheckContext();

	const handleLockout = ({ email }) => {
		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(routeKeys.UnlockAccount),
			state: { email },
		}} />);
	};

	const handleActivation = ({ email }) => {
		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(routeKeys.RegisterAccountCreated),
			state: { email },
		}} />);
	};

	const handleTwoFactorAuth = () => {
		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(routeKeys.TwoFactorAuth),
		}} />);
	};

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const kickoffSubmit = async () => {
		setSubmitting(true);

		await checkRecaptcha();

		let validated;
		try {
			validated = await formHelper.startValidation(true, null);
		} catch (errorReport) {
			setSubmitting(false);

			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("user signing in");

		// props.submit succeeds or returns an ErrorReport
		try {
			await restClientMiddleware(ClientAjax.post("/ajax/login", validated));
		} catch (errorReport) {
			resetRecaptcha();
			setSubmitting(false);

			if (errorReport.TopLevelKey === getErrorKey(COLLECTIONS.login, '', 'errors.account.is.locked')) {
				handleLockout({
					email: validated?.email,
				});
			} else if (errorReport.TopLevelKey === 'miscText.signin-2factor-method-error-select') {
				handleTwoFactorAuth();
			} else if (errorReport.TopLevelKey === getErrorKey(COLLECTIONS.login, '', 'errors.account.pending.activation')
				|| errorReport.TopLevelKey === getErrorKey(COLLECTIONS.login, '', 'errors.account.token.is.expired')) {
				handleActivation({
					email: validated?.email,
				});
			} else {
				// we're not redirecting anywhere. Prepare the form for the next submit.
				formHelper.validationErrorHandler(errorReport);
			}

			return;
		} finally {
			[
				USER_LOGGED_OUT_BANNER,
				SESSION_EXPIRED_BANNER,
			].forEach(cookieName => removeCookie(cookieName));

			// We're now logged in (or have failed and need to update recaptchaEnabled),
			// calling this will alert the session check of that and trigger a redirect
			syntheticTimerEvent();
		}
	};

	const onFormMount = () => {
		formHelper.wireInputs();

		if (!consumedQueryParams && get(location.state, 'prefill.autoSubmit')) {
			setConsumedQueryParams(true);
			kickoffSubmit()
				.catch(error => noticeError(null, levels.error, error, "SignIn onFormMount kickoffSubmit errored"));
		}
	};


	const prefill = get(location.state, "prefill", {});
	const { prefillEmail } = prefill;

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => {
			const toastNotificationProps = {
				cmsContent,
				history,
				setToast,
				removeToast,
			};
			return <>
				<HelmetOGTags
					title={cmsContent[ cms.pageTitle ]}
					metaDescription=""
				/>

				<LoggedOutBanner />

				<CredentialsForbiddenRedirect />
				<NewPasswordNotification {...toastNotificationProps} />

				{formHelper.getFieldError("") && <NoAccountFound />}

				<section className={login.headerContainer}
					data-qa="SigninHeaderContainer"
				>
					<div className={login.titleContainer}>
						<div className={login.header}>
							<CmsContentRenderer.H1
								className={login.title}
								contentKey={cms.header}
								fallbackValue="Log in to your Charlie Account"
								data-qa="SigninHeaderTitle"
							/>
						</div>
					</div>
					<div className={login.secondaryContent}>
					</div>
				</section>

				<Container overrideClass={login.container}>
					<section className={login.content}>
						<div className={login.socialBtnContainer}>
							<CmsContentRenderer.P
								contentKey={cms.socialSubheader}
								fallbackValue="Choose an account to log in"
								className={login.subheadAlt}
								data-qa="SignInSocialTitle"
							/>
							<div className={login.actions}>
								<button className={cx(login.socialBtn, login.socialBtnFB)}
									data-qa="FBRegisterBtn"
									onClick={PreventDefault(() => socialLogin(SSO_PROVIDERS.FACEBOOK.key, setRedirect))}
									aria-label="Log in with Facebook"
								>
									<Facebook overrideClass={cx(login.socialBtnIcon, login.socialBtnIconFB)}
										aria-hidden={true}
										data-qa="FBLogo"
									/>
									<CmsContentRenderer.Span
										contentKey={cms.socialFacebookLabel}
										fallbackValue="Log in with Facebook"
									/>
								</button>

								<button className={cx(login.socialBtn, login.socialBtnGP)}
									data-qa="GoogleRegisterBtn"
									onClick={PreventDefault(() => socialLogin(SSO_PROVIDERS.GOOGLE.key, setRedirect))}
								>
									<SocialMediaIcon socialMediaType={SSO_PROVIDERS.GOOGLE.key}
										childProps={{ overrideClass: cx(login.socialBtnIcon, login.socialBtnIconGP) }} />
									<CmsContentRenderer.Span
										contentKey={cms.socialGoogleLabel}
										fallbackValue="Log in with Google"
									/>
								</button>

								<button className={cx(login.socialBtn, login.socialBtnAP)}
									data-qa="AppleRegisterBtn"
									onClick={PreventDefault(() => socialLogin(SSO_PROVIDERS.APPLE.key, setRedirect))}
								>
									<Apple overrideClass={cx(login.socialBtnIcon, login.socialBtnIconAP)}
										aria-hidden={true}
										data-qa="AppleLogo"
									/>
									<CmsContentRenderer.Span
										contentKey={cms.socialAppleLabel}
										fallbackValue="Log in with Apple"
									/>
								</button>
							</div>
							<div className={cx(login.otherAuthAction, login.onlyMobile)}>
								<CreateAccountLink />
							</div>
						</div>

						<div className={login.divider}>
							<p className={login.dividerText}>or</p>
						</div>

						<div className={login.formContainer}>
							<CmsContentRenderer.P
								contentKey={cms.emailSubheader}
								fallbackValue="Log In with Your Email"
								className={login.subheadAlt}
								data-qa="SignInWithEmailHeader"
							/>

							<Lifecycles didMount={onFormMount()}>
								<form
									data-qa="SignInInputContainer"
									ref={formRef}
									onSubmit={PreventDefault(() => kickoffSubmit())}
									className={login.SignInForm}
								>
									{formHelper.getFieldErrorJsx('')}
									<div>
										<Input
											label={<CmsContentRenderer.Span
												contentKey={cms.emailInputLabel}
												fallbackValue="Enter your email"
											/>}
											name="email"
											type="email"
											autoComplete="email"
											error={formHelper.getFieldError('email')}
											data-qa="SignInEmailInput"
											defaultValue={prefillEmail}
											overrideAutoCompleteStyles
										/>
									</div>

									<div className={login.registerPasswords}>
										<Password
											label={<CmsContentRenderer.Span
												contentKey={cms.passwordInputLabel}
												fallbackValue="Enter your password"
											/>}
											name="password"
											autoComplete="current-password"
											data-qa="PasswordInput"
											error={formHelper.getFieldError('password')}
											onChange={event => setPassword(event.target.value)}
										/>
									</div>
									<Button
										to={getPathByRoute(routeKeys.ForgotPassword)}
										typeStyle={buttonTypeStylePlain}
										additionalClassNames={login.textLink}
										data-qa="ForgotPswdLink"
									>
										<CmsContentRenderer.P
											contentKey={cms.forgotPasswordCTA}
											fallbackValue="Forgot password?"
											className={login.forgotPswd}
										/>
									</Button>

									<div className={login.formActions}>
										<Button
											isPrimary
											data-qa="SignInNextBtn"
											overrideClass={login.btn}
											{...{ submitting }}
										>
											<CmsContentRenderer.Span
												contentKey={cms.emailSubmit}
												fallbackValue="Log in"
												className={buttonStyle.text}
											/>
										</Button>
									</div>

									<Recaptcha />
								</form>
							</Lifecycles>

							<div className={cx(login.otherAuthAction, login.onlyDeskTop)}>
								<CreateAccountLink />
							</div>
						</div>
					</section>
				</Container>

				<ContactCustomerService />
			</>;
		}}</CmsContentList>
	);
};


const NewPasswordNotification = ({ cmsContent, history, setToast, removeToast }) => {
	const showNewPasswordNotification = history.location.state?.showNewPasswordNotification ?? false;
	useEffect(() => {
		if (showNewPasswordNotification) {
			const {
				showNewPasswordNotification: _removedLocationState,
				...preservedLocationState
			} = history.location.state;
			history.replace({
				...history.location,
				state: preservedLocationState, // `history.location.state` with `showNewPasswordNotification` property removed
			});
			setToast(<Toast
				type="success"
				title={<CmsContentRenderer
					cmsContent={cmsContent}
					contentKey={cms.newPasswordSuccessHeader}
					fallbackValue="Success!"
				/>}
				text={<CmsContentRenderer
					cmsContent={cmsContent}
					contentKey={cms.newPasswordSuccessDescription}
					fallbackValue="New password successfully added. Log in with your email and password to continue."
				/>}
				onClosed={removeToast}
			/>);
		}
	}, [ showNewPasswordNotification, setToast, removeToast, cmsContent, history ]);
	return null;
};

export default SignIn;
