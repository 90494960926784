import React from 'react';

import routeKeys from 'CustomerRouteKeys.js';
import ErrorBoundary from "components/ErrorBoundary.js";
import PublicAppVars from 'utils/PublicAppVars.js';

import HelmetOGTags from "layouts/HelmetOGTags.js";
import Hero from 'components/home/Hero.js';
import ActionNav from 'components/home/ActionNav.js';
import Callout from 'components/home/Callout.js';
import Gallery from 'components/home/Gallery.js';
import Features from 'components/home/Features.js';
import PageLinks from 'components/home/PageLinks.js';
import Download from 'components/home/Download.js';

import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb';
import HomeBanner from 'components/home/HomeBanner';


const cms = {
	breadcrumbKey: 'miscText.general-breadcrumbs-home',
};

export const getHomeBreadcrumbs = () => {
	return [
		<BreadcrumbItem
			key={cms.breadcrumbKey}
			cmsKey={cms.breadcrumbKey}
			fallbackValue="Home"
			routeKey={routeKeys.Home}
		/>,
	];
};

const Home = () => {
	return (
		<>
			<HelmetOGTags
				title={'Home'}
				metaDescription=""
				ogType={"article"}
			/>
			<HomeBanner />
			<Hero />

			{/*Only show the action nav if new card purchase is enabled
				https://reflexions.atlassian.net/browse/MBTA-2896*/}
			{PublicAppVars.ENABLE_NEW_CARD_PURCHASE &&	<ActionNav />}
			<Callout />
			<Features />

			<ErrorBoundary>
				<Gallery />
			</ErrorBoundary>

			<PageLinks />

			{PublicAppVars.ENABLE_DOWNLOAD_INFORMATION && <Download />}
		</>
	);
};

export default Home;
