// extracted by mini-css-extract-plugin
export var additionalOptionsDescription = "pages-auth-__PersonalInformation-module___additionalOptionsDescription";
export var additionalOptionsWrapper = "pages-auth-__PersonalInformation-module___additionalOptionsWrapper";
export var buttons = "pages-auth-__PersonalInformation-module___buttons";
export var calloutBox = "pages-auth-__PersonalInformation-module___calloutBox";
export var cancelButton = "pages-auth-__PersonalInformation-module___cancelButton";
export var checkbox = "pages-auth-__PersonalInformation-module___checkbox";
export var countryDropDownWrapper = "pages-auth-__PersonalInformation-module___countryDropDownWrapper";
export var enterInfoText = "pages-auth-__PersonalInformation-module___enterInfoText";
export var enterInfoTitle = "pages-auth-__PersonalInformation-module___enterInfoTitle";
export var eulaCheckboxContainer = "pages-auth-__PersonalInformation-module___eulaCheckboxContainer";
export var eulaDisclaimer = "pages-auth-__PersonalInformation-module___eulaDisclaimer";
export var eulaLabel = "pages-auth-__PersonalInformation-module___eulaLabel";
export var eulaLink = "pages-auth-__PersonalInformation-module___eulaLink";
export var firstInput = "pages-auth-__PersonalInformation-module___firstInput";
export var form = "pages-auth-__PersonalInformation-module___form";
export var hiddenLabel = "pages-auth-__PersonalInformation-module___hiddenLabel";
export var inputWrapper = "pages-auth-__PersonalInformation-module___inputWrapper";
export var modalOverride = "pages-auth-__PersonalInformation-module___modalOverride";
export var noMargin = "pages-auth-__PersonalInformation-module___noMargin";
export var optionalFlexRow = "pages-auth-__PersonalInformation-module___optionalFlexRow";
export var optionalLabel = "pages-auth-__PersonalInformation-module___optionalLabel";
export var optionalText = "pages-auth-__PersonalInformation-module___optionalText";
export var phone = "pages-auth-__PersonalInformation-module___phone";
export var phoneLabel = "pages-auth-__PersonalInformation-module___phoneLabel";
export var phoneLabelBottomMargin = "pages-auth-__PersonalInformation-module___phoneLabelBottomMargin";
export var phoneLabelMargin = "pages-auth-__PersonalInformation-module___phoneLabelMargin";
export var phoneNumberLabelContainer = "pages-auth-__PersonalInformation-module___phoneNumberLabelContainer";
export var phoneSmsLabel = "pages-auth-__PersonalInformation-module___phoneSmsLabel";
export var phoneTypeContainer = "pages-auth-__PersonalInformation-module___phoneTypeContainer";
export var phoneTypeErrorPadding = "pages-auth-__PersonalInformation-module___phoneTypeErrorPadding";
export var phoneTypeInput = "pages-auth-__PersonalInformation-module___phoneTypeInput";
export var phoneTypeLabel = "pages-auth-__PersonalInformation-module___phoneTypeLabel";
export var phoneTypeLabelContainer = "pages-auth-__PersonalInformation-module___phoneTypeLabelContainer";
export var phoneTypeSelectWrapper = "pages-auth-__PersonalInformation-module___phoneTypeSelectWrapper";
export var pinInput = "pages-auth-__PersonalInformation-module___pinInput";
export var pinText = "pages-auth-__PersonalInformation-module___pinText";
export var pinTitle = "pages-auth-__PersonalInformation-module___pinTitle";
export var regEmailInput = "pages-auth-__PersonalInformation-module___regEmailInput";
export var regEmailWrapper = "pages-auth-__PersonalInformation-module___regEmailWrapper";
export var registerContainer = "pages-auth-__PersonalInformation-module___registerContainer";
export var saveButton = "pages-auth-__PersonalInformation-module___saveButton";
export var secondInput = "pages-auth-__PersonalInformation-module___secondInput";
export var selectField = "pages-auth-__PersonalInformation-module___selectField";
export var selectInputsWrapper = "pages-auth-__PersonalInformation-module___selectInputsWrapper";
export var semibold = "pages-auth-__PersonalInformation-module___semibold";
export var tosContent = "pages-auth-__PersonalInformation-module___tosContent";
export var zip = "pages-auth-__PersonalInformation-module___zip";