import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";

import MapsApiLoader, {
	MapsLoaderContext,
} from "components/map/MapsApiLoader.js";
import Checkbox from "../components/forms/Checkbox.js";
import { Location } from "../components/Icon.js";
import Container from "../components/Container.js";
import LocationAutocomplete from "../components/map/LocationAutocomplete.js";
import LocationCard from "../components/map/LocationCard.js";
import MbtaMap from "../components/map/MbtaMap.js";
import Panel from "../components/Panel.js";
import Toggle from "../components/forms/Toggle.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { GET_POINT_OF_SALE_LOCATIONS } from "components/data/device/PointOfSaleLocations.query.js";
import CmsContent from "components/data/CmsContent.js";
import CmsContentRenderer, {
	findContentOrFallback,
	getContentOrFallback,
} from "components/data/CmsContentRenderer.js";
import CmsContentList from "components/data/CmsContentList.js";
import { values, compact } from "lodash";
import PreventDefault from "../utils/PreventDefault.js";
import PublicAppVars from "utils/PublicAppVars.js";
import Pagination from "components/Pagination.js";
import { MQ_TABLET, useMediaQueryMatches, isTablet } from "utils/Breakpoints.js";
import { getHomeBreadcrumbs } from "./Home.js";
import { useBreadcrumbCallback } from "context/BreadcrumbProvider.js";
import { BreadcrumbItem } from "components/breadcrumb/Breadcrumb.js";
import routeKeys from "CustomerRouteKeys.js";
import { ComponentLoading } from "components/icons/LoadingIcon.js";
import ComingSoon from "components/banners/ComingSoon.js";

import * as style from "./RetailLocations.module.css";
import * as login from "../pages/auth/Login.module.css";
import * as tables from "components/DataTable.module.css";

const cms = {
	header: "miscText.location-finder-header",
	description: "miscText.location-finder-description",
	headerCta: "miscText.location-finder-learnmore-cta",
	headerCtaUrl: "miscText.location-finder-learnmore-url",
	currentLocation: "miscText.location-finder-use-current",
	resultsHeader: "miscText.location-results-header",
	refreshOnMove: "miscText.location-results-refresh-on-move",

	showingRange: "miscText.location-results-display",
	noLocationsSubheader: "miscText.location-results-none",

	geoLocationError: "miscText.location-finder-use-current-error",
	buyOnline: "miscText.location-finder-buy-online",
};

const zoomlevel = 16;

/**
 * Convert longitude/latitude decimal degrees to degrees, minutes, and seconds
 * @param {number} decimal degrees decimal
 */
const convertDMS = (decimal) => {
	// e.g. decimal -73.977222 => -73 58 37

	const negativeCoefficient = decimal > 0 ? 1 : -1;
	const abs = Math.abs(decimal);
	const degrees = Math.floor(abs);
	const fractional = abs - degrees;
	const minutes = Math.floor(fractional * 60);
	const seconds = Math.floor((fractional - minutes / 60) * 3600);

	return `${negativeCoefficient * degrees} ${minutes} ${seconds}`;
};

const resolve = (obj, prop) => {
	// TBD: is there a name for this algorithm where we try it as a function first?
	return typeof obj[ prop ] === "function"
		? obj[ prop ]()
		: obj[ prop ];
};

const ResultPanel = ({
	startItem,
	endItem,
	totalCount,
	toggleList,
	redoSearchOnMove, setRedoSearchOnMove,
}) => {

	const handlePassSelection = () => {
		setRedoSearchOnMove(!redoSearchOnMove);
	};

	return (
		<Panel overrideClass={style.resultsPanel}>
			<CmsContent
				contentKey={cms.showingRange}
				className={style.showSearchText}
				fallbackValue={`Showing ${startItem} - ${endItem} of ${totalCount}`}
				variables={{
					min: startItem,
					max: endItem,
					total: totalCount,
				}}
			>
				{(content) => (
					<div>
						{getContentOrFallback(
							content,
							"Redo search when map moves"
						)}
					</div>
				)}
			</CmsContent>

			<div className={style.redoSearch}>
				<CmsContent contentKey={cms.refreshOnMove}>
					{(content) => (
						<Checkbox
							label={getContentOrFallback(
								content,
								"Redo search when map moves"
							)}
							name="redoSearch"
							labelFirst={false}
							overrideClass={style.redoSearchCheck}
							data-qa="RedoSearch"
							onChange={() => handlePassSelection()}
							checked={redoSearchOnMove}
						/>
					)}
				</CmsContent>
			</div>
			<div className={style.hideOnDesktop}>
				<Toggle
					name="Map"
					/** @todo: add cms translations */
					checkedText="Map"
					uncheckedText="List"
					onChange={toggleList}
				/>
			</div>
		</Panel>
	);
};

const PointOfSaleMap = ({
	isAtLeastTablet,
	showMobileLocationCard,
	wsPointOfSaleLocations,
	selectedLocation,
	setMapCenterView,
	setSelectedLocation,
	showMap,
	mapRef,
	addressCenter, setAddressCenter,
	setMapSearch,
	resultsLoading = false,
	redoSearchOnMove,
}) =>
	<div className={style.resultsMapContainer}>
		<div
			id="list"
			key={"list"}
			className={cx(
				style.resultsList,
				isAtLeastTablet || showMobileLocationCard
					? style.showDisplay
					: style.hideDisplay
			)}
		>
			{resultsLoading
				? <ComponentLoading />
				: (wsPointOfSaleLocations.length === 0)
					? <CmsContentRenderer.Span
						contentKey={cms.noLocationsSubheader}
						fallbackValue="Sorry, no locations found in that area"
						className={style.noLocationsText}
					/>
					: wsPointOfSaleLocations.length > 0 ?
						wsPointOfSaleLocations.map(wsPointOfSaleLocation =>
							<LocationCard
								key={wsPointOfSaleLocation.id}
								{...{ wsPointOfSaleLocation, selectedLocation }}
								onClick={() => {
									setMapCenterView(wsPointOfSaleLocation);
									setSelectedLocation(wsPointOfSaleLocation);
								}}
							/>)
						: null}
		</div>

		{showMap && <div className={style.mapContainer}>
			<MbtaMap
				{...{
					mapRef,
					wsPointOfSaleLocations,
					selectedLocation,
					setSelectedLocation,
					addressCenter, setAddressCenter,
					setMapCenterView,
					setMapSearch,
					redoSearchOnMove,
				}}
			/>
		</div>}
	</div>;

const boDegreeTypeConverter = PublicAppVars.POS_DMS
	? convertDMS
	: (decimal) => decimal.toString().slice(0, 12); // graphql and BO expect a string(12), not a float

const getStartEndItems = (activePage, totalCount) => {
	const startItem = activePage === 0
		? 1
		: (activePage * PublicAppVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE) + 1;

	const endItem =
		activePage === 0
			? PublicAppVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE > totalCount
				? totalCount
				: PublicAppVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE
			: Math.min(
				startItem + PublicAppVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE - 1,
				totalCount
			);

	return { startItem, endItem };
};

const RetailLocationsContent = () => {
	const defaultCenter = { lng: -71.05827290000002, lat: 42.3628491 };

	// This is the current bounding box that MBTA is using via AWS.
	// https://reflexions.atlassian.net/browse/MBTA-2373?jql=statusCategory%20%3D%20new%20AND%20project%20%3D%2010003%20AND%20fixVersion%20%3D%2010412%20ORDER%20BY%20assignee%20ASC%2C%20priority%20DESC%2C%20key%20ASC
	const mapBounds = {
		north: 42.8266,
		west: -71.9380,
		south: 41.3193,
		east: -69.6190,
	};

	// State
	const [ usedGeolocation, setUsedGeolocation ] = useState(false);
	const [ mapSearch, setMapSearch ] = useState(false);
	const [ activePage, setActivePage ] = useState(0);
	const [ redoSearchOnMove, setRedoSearchOnMove ] = useState(false);

	// If we are loading as mobile we do not want to show the map by default.
	const [ showMap, setShowMap ] = useState(false);
	const [ showMobileLocationCard, setShowMobileLocationCard ] = useState(true);
	const [ addressSelected, setAddressSelected ] = useState(false);
	const [ mapApi, setMapApi ] = useState({});
	const [ hasAutoCompleteResult, setHasAutoCompleteResult ] = useState(false);
	const [ addressCenter, setAddressCenter ] = useState(defaultCenter);
	const [ selectedLocation, setSelectedLocation ] = useState(null);
	const [ isClientSide, setIsClientSide ] = useState(false);
	const mapRef = useRef(null);

	const [ geolocationBlocked, setGeolocationBlocked ] = useState(false);

	// We want top set this once SSR has finished but before the dom has had a chance to paint all the elements.
	// This will allow us to know whether we render the map on the initial desktop search
	// OR
	// not at all until the user has specified when searching via mobile
	useLayoutEffect(() => setShowMap(isTablet()), []);
	useEffect(() => setIsClientSide(true), []);

	const queryVariables = () => ({
		// note: a google maps LatLng object uses functions for lat/lng
		// https://developers.google.com/maps/documentation/javascript/reference/coordinates#LatLng
		// there's also a LatLngLiteral that doesn't
		longitude: boDegreeTypeConverter(resolve(addressCenter, "lng")),
		latitude: boDegreeTypeConverter(resolve(addressCenter, "lat")),
	});

	const pointOfSaleResponse = useStdQuery(GET_POINT_OF_SALE_LOCATIONS, {
		variables: addressSelected || mapSearch ? queryVariables() : {},
		skip: !addressSelected && !hasAutoCompleteResult,
	});

	const locationsList = useMemo(() =>
		compact(pointOfSaleResponse.data?.DeviceRoute.getPointOfSaleLocations.pointOfSaleLocations)
	, [ pointOfSaleResponse ]);

	const { startItem, endItem } = getStartEndItems(activePage, locationsList.length);

	const wsPointOfSaleLocations = locationsList.slice(startItem - 1, endItem);
	const totalCount = locationsList.length;

	const isAtLeastTablet = useMediaQueryMatches(MQ_TABLET);

	const toggleList = () => {
		setShowMobileLocationCard((prevValue) => !prevValue);
		setShowMap((prevValue) => !prevValue);
	};

	const onAddressChange = useCallback(
		(place) => {
			var lat = place?.geometry?.location?.lat();
			var lng = place?.geometry?.location?.lng();

			// AutoComplete could not geo-locate the users input value
			if (!lat && !lng) {
				if (place.name) {
					// AutoComplete has the input value but no location
					setAddressSelected(true);
				}
				setHasAutoCompleteResult(false);
				return;
			}

			setHasAutoCompleteResult(true);
			// return to first page of results when a new address is selected
			setActivePage(0);

			setSelectedLocation(null);
			setAddressSelected(true);
			setGeolocationBlocked(false);

			mapRef?.current?.setView([ lat,lng ], zoomlevel);

			setAddressCenter({ lat, lng });
			setUsedGeolocation(false);
			setMapSearch(false);
		},
		[ setAddressSelected, setUsedGeolocation, setMapSearch,setSelectedLocation ]
	);

	const getCurrentLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				const newLatLng = {
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				};

				// return to the first page of results in case there was a previous search
				setActivePage(0);

				setAddressSelected(true);
				mapRef?.current?.setView([ position.coords.latitude, position.coords.longitude ], zoomlevel);
				setAddressCenter(newLatLng);
				setMapSearch(false);
				setUsedGeolocation(true);
				setGeolocationBlocked(false);
			},
			// see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
			error => setGeolocationBlocked(true)
			);
		}
		else {
			setGeolocationBlocked(true);
		}
	};

	const setMapCenterView = (wsPointOfSaleLocation) => {
		const coordinates = [
			wsPointOfSaleLocation.latitude,
			wsPointOfSaleLocation.longitude,
		];

		mapRef?.current?.setView(coordinates, zoomlevel);
	};

	return (
		<div className={style.retailLocationContainer}>
			<MapsLoaderContext.Provider value={[ mapApi, setMapApi ]}>
				<>
					{/* If included server-side, MapsApiLoader errors with "Loader must not be called again with different options."
					 * There's one global Loader instance that gets reused across requests
					 * Let's workaround that by not including it server-side
					 */}
					{isClientSide && <MapsApiLoader />}
					{mapApi.isLoaded && (
						<LocationAutocomplete
							{...{
								setGeolocationBlocked,
								bounds: mapBounds,
								onAddressChange,
								usedGeolocation,
								mapSearch,
							}}
						/>
					)}
				</>
			</MapsLoaderContext.Provider>

			<div className={style.location}>
				<button
					className={style.locationBtn}
					onClick={PreventDefault(getCurrentLocation)}
				>
					<Location
						overrideClass={style.locationIcon}
						aria-hidden={true}
					/>
					<CmsContentRenderer.Span
						contentKey={cms.currentLocation}
						fallbackValue="Use my current location"
					/>
				</button>
			</div>

			<CmsContentRenderer.P
				className={style.desktopHidden}
				contentKey={cms.buyOnline}
				fallbackValue="You may also purchase cards and manage passes directly through the Charlie website and Charlie app."
			/>

			{geolocationBlocked ?
				<CmsContentRenderer.P
					contentKey={cms.geoLocationError}
					fallbackValue="Cannot access current location, please review system permissions, or enter an address."
					className={style.noLocationsText}
				/>
				: null}

			{addressSelected
				? pointOfSaleResponse.loading
					? <ComponentLoading />
					: <ResultPanel
						startItem={startItem}
						endItem={endItem}
						totalCount={totalCount}
						toggleList={toggleList}
						hasAutoCompleteResult={hasAutoCompleteResult}
						{...{ redoSearchOnMove, setRedoSearchOnMove }}
					/>
				: null}
			{addressSelected
				? <PointOfSaleMap
					selectedLocation={selectedLocation}
					setMapCenterView={setMapCenterView}
					setSelectedLocation={setSelectedLocation}
					showMap={showMap}
					mapRef={mapRef}
					isAtLeastTablet={isAtLeastTablet}
					showMobileLocationCard={showMobileLocationCard}
					wsPointOfSaleLocations={wsPointOfSaleLocations}
					{...{
						setMapSearch,
						redoSearchOnMove,
						addressCenter, setAddressCenter,
					}}
					resultsLoading={pointOfSaleResponse.loading}
				/>
				: null}

			{totalCount > PublicAppVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE ? (
				<div className={tables.pagination}>
					<Pagination
						pageRangeDisplayed={3}
						totalCount={totalCount}
						itemsCountPerPage={
							PublicAppVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE
						}
						activePage={activePage}
						setActivePage={setActivePage}
					/>
				</div>
			) : null}
		</div>
	);
};

export const getFindSalesLocationBreadcrumbs = () => [
	...getHomeBreadcrumbs(),
	<BreadcrumbItem
		key={cms.header}
		cmsKey={cms.header}
		routeKey={routeKeys.RetailLocations}
		fallbackValue="Find a sales location"
	/>,
];

const RetailLocations = ({ }) => {
	useBreadcrumbCallback(getFindSalesLocationBreadcrumbs);

	return (
		<CmsContentList list={values(cms)}>
			{({ cmsContent }) => (
				<>
					{PublicAppVars.COMING_SOON_RETAIL_LOCATION_FINDER && <ComingSoon />}

					<section
						className={login.headerContainer}
						data-qa="RetailLocationContainer"
					>
						<div className={login.header}>
							<CmsContentRenderer.H1
								contentKey={cms.header}
								fallbackValue="Find a sales location"
								className={login.title}
								data-qa="RetailLocationTitle"
							/>
						</div>

						<div className={login.secondaryContent}>
							<CmsContentRenderer.P
								contentKey={cms.description}
								fallbackValue="You can purchase a Charlie Card at a fare vending machines in any subway stations or at select bus stops, as well as at retailers located throughout the MBTA service area."
								className={style.calltoActionText}
								data-qa="SubHeaderText"
							/>
							<a
								href={findContentOrFallback(cmsContent, cms.headerCtaUrl, 'https://www.mbta.com/charlie/passes')}
								target="_blank" rel="noreferrer"
								className={style.textLink}
							>
								<CmsContentRenderer.Span
									contentKey={cms.headerCta}
									fallbackValue='Learn more about adding money and passes'
								/>
							</a>
						</div>
					</section>

					<Container>
						<RetailLocationsContent />
					</Container>
				</>
			)}
		</CmsContentList>
	);
};

export default RetailLocations;
