import React, { useState } from "react";
import PropTypes from "prop-types";

import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Toast from "components/Toast.js";
import { getTransitAccountRefetchQueries } from 'components/data/transit-account/TransitAccount.js';
import useStandardMutation from "components/data/hooks/useStandardMutation.js";

import { TOKEN } from "utils/Constants.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import Button, {
	buttonTypeStylePlain,
} from "components/Button.js";
import { TOKEN_ACTION } from 'components/data/transit-account/subsystem/travel-token/TokenAction.mutation';
import PublicAppVars from 'utils/PublicAppVars';

import * as settings from "pages/account/card/Settings.module.css";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";


const cms = {
	removeCard: 'miscText["card-settings-remove-cta"]',
	temporaryCard: "miscText.general-cardtype-temporary",
	toastRemoveCard: "miscText.card-settings-remove-confirmation",
};

const ArchiveToken = ({
	wsTransitAccountToken,
	setError = () => { },
}) => {
	const subsystemAccountReference = useTransitAccountIdContext();
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ archiveTokenMutation ] = useStandardMutation(TOKEN_ACTION, {
		refetchQueries: getTransitAccountRefetchQueries(subsystemAccountReference),
		awaitRefetchQueries: true,
	});
	const [ submitting, setSubmitting ] = useState(false);

	const tokenType = wsTransitAccountToken.tokenInfo.tokenType;

	const handleArchiveToken = async () => {
		setSubmitting(true);

		const variables = {
			tokenId: wsTransitAccountToken.tokenId,
			tokenAction: TOKEN.ACTION.block,
			subsystemAccountReference,
			reasonCode: PublicAppVars.MANAGE_TOKENS_REASON_CODES.Archive,
		};

		try {
			await graphqlErrorMiddleware(archiveTokenMutation({ variables }));
		} catch (errorReport) {
			setError(errorReport.display.topLevelMessage);
			return;
		} finally {
			setSubmitting(false);
		}

		setToast(
			<Toast
				type="success"
				title={
					<CmsContentRenderedInline
						contentKey={cms.toastRemoveCard}
						fallbackValue={'Temporary Charlie Card has been removed.'}
						variables={{ cardtype: tokenType }}
					/>}
				onClosed={removeToast}
			/>
		);
	};

	return (
		<div>
			<Button
				typeStyle={buttonTypeStylePlain}
				additionalClassNames={settings.cardAction}
				submitting={submitting}
				onClick={handleArchiveToken}
			>
				<CmsContentRenderedInline
					className={settings.removeCard}
					contentKey={cms.removeCard}
					variables={{ cardtype: tokenType }}
					fallbackValue='Remove Card'
				/>
			</Button>
		</div>
	);
};

ArchiveToken.propTypes = {
	wsTransitAccountToken: PropTypes.object.isRequired,
};

export default ArchiveToken;
