import React, {
	StrictMode,
} from 'react';

import HelmetDefaults from './HelmetDefaults.js';
import ErrorBoundary from 'components/ErrorBoundary';
import SessionCheck from "components/data/session-user/SessionCheck.js";

import { SVGDefs } from 'components/Icon.js';

import * as style from 'App.module.css';

export const main = "main";



const PrintPdfLayout = ({
	component: Component,
	componentProps,
}) => {

	return (
		<div className={style.siteContainer}>
			<HelmetDefaults />

			<SVGDefs />

			<SessionCheck>

				<ErrorBoundary>
					<main id={main}>
						<StrictMode>
							<Component {...componentProps} />
						</StrictMode>
					</main>
				</ErrorBoundary>
			</SessionCheck>
		</div>
	);
};

export default PrintPdfLayout;