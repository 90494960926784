import React from 'react';

import CreditCardForm, { ACTION_EDIT } from 'components/payments/CreditCardForm.js';
import { BoolCheckbox } from 'components/forms/Checkbox.js';
import {
	useAddressSelectorContext,
	AddressSelectorContext,
} from "components/account-settings/address-selector/AddressSelector.js";
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import Addresses from 'components/data/session-user/Addresses.query.js';
import SelectAddressForm from 'components/account-settings/SelectAddressForm.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

import * as tabs from 'styles/Tabs.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as checkbox from 'components/forms/Checkbox.module.css';



const cms = {
	setPrimaryPayment: "miscText.general-payment-set-primary",
	billingAddress: "miscText.purchase-card-4-billing-address",
};

export const EditCreditCardForm = ({
	formHelper,
	provideNickName = true,
	wsFundingSourceExt,
	hideTopLevelError = false,
}) => {
	const addressSelectorState =  useAddressSelectorContext();
	const getFieldName = (fieldNameBase) => getPaymentInputName({ base: fieldNameBase, isFirst: true, isSplit: false });

	return <>
		<CreditCardForm {...{
			formHelper,
			type: ACTION_EDIT,
			provideNickName,
			wsFundingSourceExt,
		}} />

		<BoolCheckbox
			label={
				<CmsContentRenderedInline
					contentKey={cms.setPrimaryPayment}
					fallbackValue={'Set this as my primary payment method.'}
					className={checkbox.labelText}
				/>}
			name={getFieldName('primaryPaymentMethod')}
			error={formHelper.getFieldError(getFieldName('primaryPaymentMethod'))}
			defaultChecked={wsFundingSourceExt?.setAsPrimary ?? false}
		/>

		<hr className={tabs.hr} />

		<CmsContentRenderedInline
			elementType='p'
			contentKey={cms.billingAddress}
			className={forms.label}
			fallbackValue='Billing address'
		/>
		{addressSelectorState ?
			<AddressSelectorContext.Provider value={addressSelectorState}>
				<Addresses>{(wsAddressExts) => (
					<SelectAddressForm
						{...{
							formHelper,
							wsAddressExts,
							hideTopLevelError,
						}}
					/>
				)}</Addresses>
			</AddressSelectorContext.Provider>
			: null
		}
	</>;
};

export default EditCreditCardForm;
