import React from "react";
import { values } from "lodash";

import cx from "classnames";
import Modal from "components/Modal";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import CmsContentList from "components/data/CmsContentList";
import { titleCaseStr } from "components/account/SsoToggles";
import Button from "components/Button.js";

import * as modalStyles from "components/Modal.module.css";



const cms = {
	confirmDelinkTitle: "miscText.security-login-disable-header",
	confirmDelinkDescription: "miscText.security-login-disable-description",
	confirmDelinkSubmit: "miscText.security-login-disable-submit",
	confirmDelinkCancel: "miscText.general-modal-cancel",
};

const ConfirmDelinkModal = ({ onModalClose, socialMediaType, onSubmitDelink }) => {
	return (
		<CmsContentList list={values(cms)} sharedVariables={{ social: titleCaseStr(socialMediaType) }}>{() => (
			<Modal
				title={<CmsContentRenderer.Span
					contentKey={cms.confirmDelinkTitle}
					fallbackValue="Disconnect Social Account"
				/>}
				onModalClose={onModalClose}
				data-qa="SocialDelinkConfirmModal"
			>
				<CmsContentRenderer.P
					contentKey={cms.confirmDelinkDescription}
					fallbackValue={`Confirm that you want to disable login using your ${
						titleCaseStr(socialMediaType)
					} account. You can re-enable this in the future if you change your mind.`}
				/>
				<div className={modalStyles.actions}>
					<Button

						onClick={onModalClose}
						data-qa="SocialDelinkConfirmCancelBtn"
						overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
					>
						<CmsContentRenderer.Span
							contentKey={cms.confirmDelinkCancel}
							fallbackValue="Cancel"
						/>
					</Button>
					<Button
						onClick={onSubmitDelink}
						overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
						data-qa="SocialDelinkConfirmSubmitBtn"
					>
						<CmsContentRenderer.Span
							contentKey={cms.confirmDelinkSubmit}
							fallbackValue="Yes, disconnect"
						/>
					</Button>
				</div>
			</Modal>
		)}</CmsContentList>
	);
};

export default ConfirmDelinkModal;
