import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { cms } from './ReloadBalance.js';
import { useCmsContent } from "components/data/CmsContent.js";
import PublicAppVars from "utils/PublicAppVars.js";

import * as style from './ReloadBalance.module.css';
import * as typography from 'styles/typography.module.css';

const CurrentBalanceDescription = ({
	chosenDisplayAmount = 0,
	isPurchaseNewProductFlow,
}) => {
	const { purseTotal } = usePurseBalanceContext();

	// we should add auto-add negative balance to cart for all purchase product flows
	// ticket https://reflexions.atlassian.net/browse/MBTA-1106
	// the desing https://projects.invisionapp.com/share/F4P0XWU3B5M#/screens/402454105
	const newBalanceValue = chosenDisplayAmount + purseTotal;

	// minimize flickering when variables update and the content is refetched
	const cmsContent = useCmsContent(
		// request
		{
			key: cms.currentBalancePostSelection,
			isTemplate: true,
			variables: {
				balance: centsToDisplay(purseTotal),
				newbalance: centsToDisplay(newBalanceValue),
				max: centsToDisplay(PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE),
			},
		},
		// fallbackValue
		`Your current balance is ${centsToDisplay(purseTotal)}. Your new balance will be ${centsToDisplay(newBalanceValue)} of a maximum ${centsToDisplay(PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE)} on this card.`
	);

	return (
		<>
			<div className={cx(style.header, style.amtConfig)}>
				<div className={cx(typography.h7, style.amtConfigHeader)}>
					{isPurchaseNewProductFlow
						? <CmsContentRenderer.Span
							contentKey={cms.amountConfigurable}
							fallbackValue="Amount Configurable"
						/>
						: centsToDisplay(newBalanceValue)
					}
				</div>
				<Link className={style.learnMoreLink} to={''}>
					<CmsContentRenderer.Span
						contentKey={cms.learnMore}
						fallbackValue="Learn More"
					/>
				</Link>
			</div>
			<div className={style.currentBalanceText}>
				<CmsContentRenderer
					elementType="p"
					content={cmsContent}
					contentKey={cms.currentBalancePostSelection}
				/>
			</div>
		</>
	);
};

export default CurrentBalanceDescription;
