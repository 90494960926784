import { TRAVEL_TOKEN_TYPES } from 'server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js';
import { HardcodedCardTypes } from 'components/Icon.js';

// Per design:
// Closed Loop default: Charlie Card
// open loop default: [credit card brand name]…####
// is displayed after the nickname but is not part of the nickname (for character count purposes)

/**
 * @param   {WSSubsystemAccountTravelTokenDisplay} - travel token.
 * @returns {string} - maskedPan or last 4 digits of theserial number of the card
 */
export const getLast4Digits = (wsSubsystemAccountTravelTokenDisplay) => {
	const {
		tokenType,
		maskedPan,
		serialNumber,
	} = wsSubsystemAccountTravelTokenDisplay;

	if (!maskedPan && !serialNumber) {
		throw new Error("Missing both serialNumber and maskedPan");
	}

	switch (tokenType) {
		case TRAVEL_TOKEN_TYPES.mobile:
		case TRAVEL_TOKEN_TYPES.bankcard:
		case TRAVEL_TOKEN_TYPES.virtualCard:
		case TRAVEL_TOKEN_TYPES.openTransitVirtual:
			return maskedPan;
		case TRAVEL_TOKEN_TYPES.smartcard:
			return serialNumber.slice(-4);
		// we only pass tokenType for displaying last four digits of TA
		// we do not pass tokenType for purchase history table view
		default:
			return maskedPan;
	};
};


/**
 * @param   {WSSubsystemAccountTravelTokenDisplay} - travel token.
 * @returns {string} - creditCardType or CharlieCard for tokenType smartcard
 */
export const getCreditCardType = (wsSubsystemAccountTravelTokenDisplay) => {
	const {
		tokenType,
		creditCardType,
	} = wsSubsystemAccountTravelTokenDisplay;

	if (!tokenType) {
		throw new Error("GetCreditCardType method: Missing tokenType");
	}

	switch (tokenType) {
		case TRAVEL_TOKEN_TYPES.mobile:
		case TRAVEL_TOKEN_TYPES.bankcard:
		case TRAVEL_TOKEN_TYPES.virtualCard:
		case TRAVEL_TOKEN_TYPES.openTransitVirtual:
			return creditCardType;
		// "tokenType": "Smartcard" doesnt have a creditCardType, but we know it should be a Charlie card
		case TRAVEL_TOKEN_TYPES.smartcard:
			return HardcodedCardTypes.CharlieCard;
		default:
			throw new Error(`GetCreditCardType method: Unidentified tokenType - ${tokenType}`);
	};
};

/**
 * @param   {WSSubsystemAccountTravelTokenDisplay} - travel token.
 * @returns {string} - creditCardType or CharlieCard for tokenType smartcard
 */
export const getCardExpiryDate = (wsSubsystemAccountTravelTokenDisplay) => {
	const {
		tokenType,
		cardExpiryDate,
		expirationYear,
		expirationMonth,
	} = wsSubsystemAccountTravelTokenDisplay;

	if (!tokenType) {
		throw new Error("GetCardExpiryDate method: Missing tokenType");
	}

	if (!cardExpiryDate && !(expirationYear && expirationMonth)) {
		throw new Error("GetCardExpiryDate method: Card expiration date");
	}

	switch (tokenType) {
		case TRAVEL_TOKEN_TYPES.mobile:
		case TRAVEL_TOKEN_TYPES.bankcard:
		case TRAVEL_TOKEN_TYPES.virtualCard:
		case TRAVEL_TOKEN_TYPES.openTransitVirtual:
			return new Date(expirationYear, expirationMonth - 1).toISOString();
		case TRAVEL_TOKEN_TYPES.smartcard:
			return cardExpiryDate;
		default:
			throw new Error(`GetCardExpiryDate method: Unidentified tokenType - ${tokenType}`);
	};
};
