import React from 'react';
import {
	values,
} from 'lodash';
import PropTypes from "prop-types";

import CmsContentList from 'components/data/CmsContentList.js';
import LeavePagePrompt from 'pages/account/purchase/LeavePagePrompt.js';

const cms = {
	promptText: "miscText.warning-still-saving",
};

const PendingChangesPrompt = ({ submitting, route }) => {
	if (!submitting) {
		return null;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<LeavePagePrompt
				message={(location) => {
					if (!location.pathname.includes(route)) {
						return (cmsContent[ cms.promptText ] || "Your changes are still saving, are you sure you want to navigate away from this page?");
					}

					// don't prompt
					return true;
				}}
			/>
		)}</CmsContentList>
	);
};

PendingChangesPrompt.propTypes ={
	submitting: PropTypes.bool,
	route: PropTypes.string,
};

export default PendingChangesPrompt;
