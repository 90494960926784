import React, { Fragment } from 'react';
import {
	compact,
	values,
} from "lodash";

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { getDateStringSync } from 'components/ServerDate.js';
import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import Toast from 'components/Toast.js';
import { ReMapOpenTransitName } from 'components/account/card/activate-card/ActivateCard.js';
import { charlieCardCmskeys } from 'components/account/card/CharlieCardCmskeys.js';

const cms = {
	title: 'miscText.purchase-confirmation-summary',
	balanceConfirmation: 'miscText.purchase-confirmation-balance',
	balanceAutoload: 'miscText.purchase-confirmation-balance-autoload',
	passConfirmation: 'miscText.purchase-confirmation-pass',
	passAutoload: 'miscText.purchase-confirmation-pass-autoload',
	confirmation: 'miscText.purchase-confirmation-sent',
	...charlieCardCmskeys,
};

const StandardNewProductAdded = ({
	cart = null,
	autoloads = null,
}) => {
	const { removeToast } = useGlobalToastsContext();

	const productList = autoloads?.purchases?.productList ?? compact(cart?.products);
	const mediaOptions = compact(cart?.mediaOptions);

	const balance = autoloads?.purchases?.balance ?? cart.balance ?? 0;
	const autoloadBalance = autoloads?.newPlacedAutoloads?.balance?.amount ?? 0;
	const autoloadStartDate = autoloads?.newPlacedAutoloads?.balance?.startDate;
	const autoloadPasses = compact(autoloads?.newPlacedAutoloads?.productList);

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) =>
			<Toast
				onClosed={removeToast}
				type="success"
				title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="The following has been added to your Charlie Card:" />}
				text={<div>
					<ul>
						{balance > 0 && (
							<li>
								<CmsContentRendered
									contentKey={cms.balanceConfirmation}
									fallbackValue={`${centsToDisplay(balance)} balance`}
									variables={{ amount: centsToDisplay(balance) }}
								/>
								{autoloadBalance > 0 &&
									<CmsContentRendered
										contentKey={cms.balanceAutoload}
										fallbackValue={`Autoload of ${centsToDisplay(autoloadBalance)} balance, starting on ${getDateStringSync({ dateISO: autoloadStartDate, options: { day: "numeric", month: "long", year: 'numeric' }, altFormat: ALT_DATE_FORMAT })}`}
										variables={{
											amount: centsToDisplay(autoloadBalance),
											date: getDateStringSync({ dateISO: autoloadStartDate, options: { day: "numeric", month: "long", year: 'numeric' }, altFormat: ALT_DATE_FORMAT }),
										}}
									/>
								}
							</li>)
						}
						{mediaOptions.map(mediaOption => {
							// Open Transit Card are renamed to Charlie Cards
							const productName = ReMapOpenTransitName({
								tokenType: mediaOption.subsystemTokenType,
								cmsContent: cmsContent,
								tokenNickname: mediaOption.nameLong,
							});

							const mediaOptionStr = `${mediaOption.quantity} ${productName}`;

							return (
								<CmsContentRendered
									key={mediaOption.id}
									contentKey={cms.passConfirmation}
									fallbackValue={mediaOptionStr}
									variables={{ pass: mediaOptionStr }}
								/>
							);
						})}
						{productList.map(product => {
							const autoLoadPass = autoloadPasses.find(autoloadPass => autoloadPass.productSKU === product.productSku);
							return (
								<li key={product.productSku}>
									<CmsContentRendered
										contentKey={cms.passConfirmation}
										fallbackValue={`${product.productLongName}`}
										variables={{ pass: product.productLongName }}
									/>
									{autoLoadPass &&
										<CmsContentRendered
											contentKey={cms.passAutoload}
											fallbackValue={`Autoload of ${autoLoadPass.pass}, starting on ${getDateStringSync({ dateISO: autoLoadPass.startDate, options: { day: "numeric", month: "long", year: 'numeric' }, altFormat: ALT_DATE_FORMAT })}`}
											variables={{
												pass: autoLoadPass.pass,
												date: getDateStringSync({ dateISO: autoLoadPass.startDate, options: { day: "numeric", month: "long", year: 'numeric' }, altFormat: ALT_DATE_FORMAT }),
											}}
										/>
									}
								</li>
							);
						})}
					</ul>
					<CmsContentRenderer.Span
						contentKey={cms.confirmation}
						fallbackValue="Active products are available to use now."
					/>
				</div>}
			/>
		}</CmsContentList>
	);
};

export default StandardNewProductAdded;
