import { clone } from "lodash";

// 2.7.29.WSPaymentInfo
// This object represents the payments being done by the system for an order.
export default class WSPaymentInfo {
	constructor({
		authRefNbr,
		paymentType,
		paymentSubtype = null,
		paymentDateTime,
		paymentAmount,
		paymentReferenceNumber,
		paymentStatus,
		paymentAllocatedAmount,
		refundableAmount,
	}) {

		// string(40)
		// (Required) A unique identifier for this authorization.
		// NOTE: This identifier is not guaranteed to be unique
		// across credit card and account authorizations.
		this.authRefNbr = authRefNbr;

		// string(20)
		// (Required) The payment type.
		// See valid values in section Error! Reference
		// source not found.
		this.paymentType = paymentType;

		// String(20)
		// (Optional) indicates the payment subtype such as
		// transit benefits or HAS/FSA.
		this.paymentSubtype = paymentSubtype;

		// dateTime
		// (Required) The timestamp of when the payment
		// was processed.
		this.paymentDateTime = paymentDateTime;

		// Int
		// (Required) The total payment amount.
		this.paymentAmount = paymentAmount;

		// string(40)
		// (Required) Reference number for the order payment.
		this.paymentReferenceNumber = paymentReferenceNumber;

		// string(20)
		// (Required) Indicates the status for the payment.
		// Valid values are:
		// • Authorized
		// • Confirmed
		// • Pending
		// • PendingConfirmation
		// • Successful
		// • Declined
		// • Failed
		// • FailedConfirmation
		// • Expired
		this.paymentStatus = paymentStatus;

		// int
		// (Required) The original payment allocated amount. If
		// the payment status is for an individual line item, this
		// is the portion of the total payment allocated to the
		// line item in question.This number is the number of
		// the base unit of currency, such as cents.
		this.paymentAllocatedAmount = paymentAllocatedAmount;

		// int
		// (Required) The remaining refundable payment
		// amount. This is the total payment amount minus
		// what has been refunded to this payment method.
		// This number is the number of the base unit of
		// currency, such as cents.
		this.refundableAmount = refundableAmount;
	}

	static fromBackoffice(data) {
		const result = new WSPaymentInfo(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSPaymentInfoFragment = `
	authRefNbr: String!
	paymentType: String!
	paymentSubtype: Int
	paymentDateTime: GraphQLDateTime!
	paymentAmount: Int!
	paymentReferenceNumber: String!
	paymentStatus: String!
	paymentAllocatedAmount: Int!
	refundableAmount: Int!
`;

export const InputWSPaymentInfoType = [ `
	input InputWSPaymentInfo {
		${WSPaymentInfoFragment}
	}
` ];

export const WSPaymentInfoType = [ `
	type WSPaymentInfo {
		${WSPaymentInfoFragment}
	}` ];
