import cx from 'classnames';
import React, { useRef } from 'react';
import Flickity from 'react-flickity-component';
import {
	chunk,
	map,
	sortBy,
	values,
} from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";

import CmsObject from 'components/data/CmsObject.js';

import Container from 'components/Container.js';
import Button from 'components/Button.js';

import 'flickity/dist/flickity.min.css';
import * as gallery from './Gallery.module.css';
import * as login from 'pages/auth/Login.module.css';
import * as buttonStyle from 'components/Button.module.css';

const cms = {
	header: 'miscText.landing-charlie-places-header',
	subhead: 'miscText.landing-charlie-places-subheader',
	launchButton: 'miscText.landing-charlie-places-button-text',
	launchButtonUrl: 'miscText.landing-charlie-places-button-url',
};

const Gallery = ({ }) => {
	const flicikyRef = useRef(null);

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<section className={gallery.container}>
				<Container overrideClass={gallery.header}>
					<CmsContentRenderer.H3 contentKey={cms.header}
						className={gallery.title}
						fallbackValue="Charlie takes you places"
						data-qa="GalleryTitle"
					/>

					<CmsContentRenderer.P contentKey={cms.subhead}
						className={gallery.text}
						fallbackValue="It just became easier to go more places in the metro Boston area."
						data-qa="GalleryIntro"
					/>
				</Container>
				<CmsObject contentKey="places">{({ data }) => {
					// Only return the objects with valid image_paths
					const content = Object.values(data?.cmsObject.obj)?.filter(image => image.image_path) ?? [];
					const columns = chunk(sortBy(content, place => place.order), 2);

					return content?.length > 0 ? (
						<Flickity
							className={gallery.media}
							flickityRef={flicikyRef.current}
							options={{
								wrapAround: true,
								pageDots: false,
								cellAlign: 'center',
								autoPlay: false,
								groupCells: 2,
								setGallerySize: false,
							}}
							data-qa="GalleryMedia"
							key="GalleryMedia"
							disableImagesLoaded={true}
						>
							{map(columns, (places, colIndex) =>
								<div className={gallery.col}
									key={colIndex}
									data-qa="GalleryMediaCol"
									aria-hidden={colIndex >= columns.length} // Hide duplicate columns from screen reader
								>
									{map(places, (place, placeIndex) =>
										<a href={place.url}
											key={placeIndex}
											className={gallery.link}
											data-qa="GalleryMediaLink"
										>
											<div className={gallery.linkMedia}>
												<img src={place.image_path ?? "https://picsum.photos/270/180/"}
													alt={place.image_alt}
													className={gallery.linkImage}
													data-qa="GalleryMediaLinkImage"
												/>
											</div>

											<p className={gallery.linkText}
												data-qa="GalleryMediaLinkText"
											>
												{place.title}
											</p>
										</a>
									)}
								</div>
							)}
						</Flickity>
					) : null;
				}}</CmsObject>

				<Container overrideClass={login.formActions}>
					<Button
						isPrimary
						overrideClass={cx(login.btn, gallery.launchButton)}
						data-qa="GalleryAction"
						type="button"
						external
						to={cmsContent[ cms.launchButtonUrl ] ?? "https://www.mbta.com/trip-planner"}
						target="_blank"

					>
						<CmsContentRenderer.Span
							contentKey={cms.launchButton}
							fallbackValue="Launch trip planner"
							className={buttonStyle.text}
						/>
					</Button>
				</Container>
			</section>
		)}</CmsContentList>
	);
};

export default Gallery;
