import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import * as tokenStatusStyles from './TokenStatus.module.css';

import { TOKEN } from 'utils/Constants.js';
import CmsContentList from 'components/data/CmsContentList.js';

import {
	levels,
	log,
} from 'utils/Logger.js';

const cms = {
	active: "miscText.cardinfo-status-active",
	closed: "miscText.cardinfo-status-closed",
	suspended: "miscText.cardinfo-status-suspended",
	terminated: "miscText.cardinfo-status-terminated",

	reasonPending: "miscText.cardinfo-status-reason-pending",
	reasonWriteoff: "miscText.cardinfo-status-reason-writeoff",
	reasonLost: "miscText.cardinfo-status-reason-lost",
	reasonFraud: "miscText.cardinfo-status-reason-fraud",
	reasonInactive: "miscText.cardinfo-status-reason-inactive",
	reasonFrozen: "miscText.cardinfo-status-reason-frozen",
	reasonClosing: "miscText.cardinfo-status-reason-closing",
	reasonNegative: "miscText.cardinfo-status-reason-negative",
	reasonDefecive: "miscText.cardinfo-status-reason-defecive",
	reasonUpgraded: "miscText.cardinfo-status-reason-upgraded",
	reasonExpired: "miscText.cardinfo-status-reason-expired",
	reasonClosed: "miscText.cardinfo-status-reason-closed",
};

export const applyCardStyles = (
	statusDescription,
	cardHasActionableDebt,
	overrideClass
) => {
	const {
		active,
		suspended,
		statusText,
		inactive,
	} = tokenStatusStyles;

	const baseStyle = statusText;
	let statusStyle = active;

	switch (statusDescription) {
		case TOKEN.DESCRIPTION.Active:
			statusStyle = active;
			break;
		case TOKEN.DESCRIPTION.Inactive:
		case TOKEN.DESCRIPTION.PendingActivation:
			statusStyle = inactive;
			break;
		default:
			statusStyle = suspended;
			break;
	}
	// Tokens with debt are returned from api as 'suspended' but we render them as would an "active" card.
	if (cardHasActionableDebt) {
		return cx(baseStyle, active, overrideClass);
	}

	return cx(
		baseStyle,
		statusStyle,
		overrideClass
	);
};


export const TokenStatus = ({
	frontendStatus,
	statusDescription,
	statusReasonCodeDesc,
	cardHasActionableDebt = false,
	elementType: ElementType = "div",
	overrideClass = null,
}) => {

	const getTokenStatusTranslationKey = (frontendStatus, cardHasActionableDebt) => {
		const {
			STATUS: {
				active,
				suspended,
				closed,
				terminated,
			},
		} = TOKEN;

		const mapping = {
			[ active ]: cms.active,
			[ suspended ]: cms.suspended,
			[ closed ]: cms.closed,
			[ terminated ]: cms.terminated,
		};

		if (!mapping[ frontendStatus ]) {
			log(null, levels.error, `Unexpected front-end status ${frontendStatus}`);
		}

		return cardHasActionableDebt
			? mapping[ active ]
			: mapping[ frontendStatus ]
				? mapping[ frontendStatus ]
				: null;
	};

	const getTokenStatusDescriptionTranslationKey = (statusReasonCodeDesc) => {
		const {
			DESCRIPTION: {
				PENDING,
				WRITEOFF,
				LOST,
				FRAUD,
				INACTIVE,
				FROZEN,
				CLOSING,
				NEGATIVE,
				DEFECIVE,
				UPGRADED,
				EXPIRED,
				CLOSED,
			},
		} = TOKEN;

		const mapping = {
			[ PENDING ]: cms.reasonPending,
			[ WRITEOFF ]: cms.reasonWriteoff,
			[ LOST ]: cms.reasonLost,
			[ FRAUD ]: cms.reasonFraud,
			[ INACTIVE ]: cms.reasonInactive,
			[ FROZEN ]: cms.reasonFrozen,
			[ CLOSING ]: cms.reasonClosing,
			[ NEGATIVE ]: cms.reasonNegative,
			[ DEFECIVE ]: cms.reasonDefecive,
			[ UPGRADED ]: cms.reasonUpgraded,
			[ EXPIRED ]: cms.reasonExpired,
			[ CLOSED ]: cms.reasonClosed,
		};

		if (!mapping[ statusReasonCodeDesc ]) {
			log(null, levels.error, `Unexpected front-end status description ${statusReasonCodeDesc}`);
		}

		return  mapping[ statusReasonCodeDesc ]
			? mapping[ statusReasonCodeDesc ]
			: null;
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<ElementType
				className={applyCardStyles(statusDescription, cardHasActionableDebt, overrideClass)}
			>
				{/*
					based on the ticket https://reflexions.atlassian.net/browse/MBTA-966
					we revert cms translations to render BO data
					<div>{cmsContent[ getTokenStatusTranslationKey(frontendStatus, cardHasActionableDebt) ] || frontendStatus}</div>
					<div>{statusDescription !== TOKEN.DESCRIPTION.Active ? cmsContent[ getTokenStatusDescriptionTranslationKey(statusReasonCodeDesc) ] : null}</div>
				*/}
				<div>{frontendStatus ? frontendStatus : null}</div>
				{/*
					updated statusDescription field to use statusReasonCodeDesc based on John
					as of 10/18/2021 https://reflexions.slack.com/archives/CCF68M49M/p1634563407428200
				*/}
				<div className={tokenStatusStyles.reasonDescription}>{statusDescription !== TOKEN.DESCRIPTION.Active ? statusReasonCodeDesc : null}</div>
			</ElementType>
		)}</CmsContentList>
	);
};

TokenStatus.propTypes = {
	frontendStatus: PropTypes.string.isRequired,
	cardHasActionableDebt: PropTypes.bool,
};

export default TokenStatus;
