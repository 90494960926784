export default class WSCountryConfig {
	constructor({
		address2,
		address3,
		city,
		state,
		postalCode,
	}) {
		// String(3)
		// Required)  Contains configuration option for address line 2.
		// Valid values are:
		// REQ (Required), the field is required in UI.
		// OPT (Optional), the field is optional in UI.
		this.address2 = address2;

		// String(3)
		// (Required)  Contains configuration option for address line 3.
		// Valid values are
		// REQ (Required), the field is required in UI.
		// OPT (Optional), the field is optional in UI.
		// NA (Not Applicable), the field is hidden in UI.
		this.address3 = address3;

		// String(3)
		// (Required)  Contains configuration option city.
		// Valid values are
		// REQ (Required), the filed is shown as city in UI and it is required.
		// OPT (Optional), the field is shown as address line 4 in UI and it is optional.
		this.city = city;

		// String(3)
		// (Required)  Contains configuration option state.
		// Valid values are
		// REQ (Required), the field is shown as dropdown in UI with list of states to choose from and it is required.
		// OPT (Optional), the field is shown as address line 5 in UI and it is optional
		this.state = state;

		// String(3)
		// (Required)  Contains configuration option for postal code. Not all the countries contain postal code.
		// Valid values are
		// REQ (Required), the field is required in UI.
		// OPT (Optional), the field is optional in UI.
		// NA (Not Applicable), the field is hidden in UI.
		this.postalCode = postalCode;
	}

	static fromBackoffice(data) {
		const result = new WSCountryConfig(data);
		return result;
	}

	static fromSerialized(data) {
		const result = new WSCountryConfig(data);
		return result;
	}

	toResolver() {
		return this;
	}
}

export const WSCountryConfigType = [ `
	type WSCountryConfig {
		address2: String!
		address3: String!
		city: String!
		state: String!
		postalCode: String!
	}
` ];
