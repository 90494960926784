import React from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";

import Control, {
	THEME_LIGHT,
	THEME_DARK,
} from '../Control.js';

import * as style from './Select.module.css';
import ReactSelect from 'react-select';
import { find } from 'lodash';

// elswehere we style React select using global CSS, but the styles prop is sufficient much of the time
export const reactSelectStyling = {
	dropdownIndicator: (provided, state) => {
		const transform = state.isFocused ? 'rotate(180deg)' : 'rotate(0)';

		return {
			...provided,
			"backgroundPosition": "center",
			"backgroundRepeat": "no-repeat",
			"backgroundSize": "cover",
			'&:hover': {
				color: '#0F61A9',
			},
			transform: transform,
		};
	},
	singleValue: (provided, { data }) => {
		return {
			...provided,
			color: data.filters ? '#1C1E23' : '#0F61A9',
			fontWeight: data.filters ? '400' : '700',
		};
	},
	option: (provided, { data }) => {
		return {
			...provided,
			color: data.filters ? '#1C1E23' : '#0F61A9',
			fontWeight: data.filters ? '400' : '700',
		};
	},
	indicatorSeparator: () => ({
		display: 'none',
	}),
};

const Select = ({
	theme = THEME_LIGHT,
	hideLabel = false,
	labelFirst = true,
	name,
	label = '',
	labelId,
	ariaLabel,
	additionalClasses = '',
	error = '',
	children,
	defaultValue = {},
	options = null,
	helpText = '',
	overrideClass = '',
	selectWrapperClasses = '',
	errorMsg = '',
	tooltip = '',
	horizontal = false,
	reactSelectClassName = '',
	...rest
}) => {
	const defaultValueObj = defaultValue
		? typeof defaultValue === 'object'
			? defaultValue
			: find(options, { value: defaultValue })
		: {};

	const labelIdValue = labelId || `${name}-label`;

	const ariaLabelProps = hideLabel
		? { [ 'aria-label' ]: ariaLabel }
		: { [ 'aria-labelledby' ]: labelIdValue };

	const useReactSelect = Boolean(options);

	const target = `${name}-selector`;

	return (
		<Control
			labelId={labelIdValue}
			{...{
				additionalClasses,
				hideLabel,
				name,
				overrideClass,
				label,
				labelFirst,
				error,
				errorMsg,
				tooltip,
				target,
			}}
		>
			<div className={cx(
				selectWrapperClasses,
				horizontal ? style.horizontal : null)}>
				{helpText && (
					<div>
						{helpText}
					</div>
				)}

				{useReactSelect
					? <ReactSelect
						instanceId={`${name}-selector`}
						inputId={target}
						className={cx(style.select, error ? style.error : null, reactSelectClassName)}
						classNamePrefix={style.select}
						defaultValue={defaultValueObj}
						{...{
							name,
							options,
							...ariaLabelProps,
							...rest,
						}}
						styles={reactSelectStyling}
					/>
					: <select id={name}
						name={name}
						className={cx(theme, error ? style.error : null)}
						{...{
							defaultValue,
							...ariaLabelProps,
						}}
						{...rest}
					>
						{children}
					</select>}
			</div>
		</Control>
	);
};

Select.propTypes = {
	/**	Id for the control */
	name: PropTypes.string.isRequired,

	/** Hide default label and use `aria-label` instead */
	hideLabel: PropTypes.bool,

	/** Label the control */
	label: PropTypes.node,

	/** ID for the visible label, for use with aria-labelledby */
	labelId: PropTypes.string,

	/** Use either value or defaultValue but not both */
	value: PropTypes.any,

	/** Use either value or defaultValue but not both */
	defaultValue: PropTypes.any,

	theme: PropTypes.oneOf([ THEME_LIGHT, THEME_DARK ]),

	error: PropTypes.node,

	children: PropTypes.node,

	inputRef: PropTypes.any,

	/** Custom classes to override styling */
	additionalClasses: PropTypes.string,
};

export default Select;
