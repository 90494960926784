import { gql } from "@apollo/client";

import {
	levels,
	noticeError,
} from "utils/Logger.js";

import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import { GET_AUTOLOAD_SUBSCRIPTION } from "components/data/transit-account/autoload/Subscription.query.js";


export const CANCEL_AUTOLOAD_SUBSCRIPTION = gql`
	mutation CancelSubscription (
		$subscriptionId: String!
		$subsystemAccountReference: String!
	) {
		OrderRoute {
			id
			CancelSubscription (
				subscriptionId: $subscriptionId
				subsystemAccountReference: $subsystemAccountReference
			) {
				success
			}
		}
	}`;

// ported over from OMNY
// TODO add cms translations 

const cms = {
	
};

export const getSubscriptions = async (
	formHelper,
	apolloClient,
	setSubmitting,
	subsystemAccountReference,
) => {
	let queryResult;
	try {
		queryResult = await graphqlErrorMiddleware(
			apolloClient.query({
				query: GET_AUTOLOAD_SUBSCRIPTION,
				variables: { subsystemAccountReference },
			}));
	}
	catch (errorReport) {
		// we're not redirecting anywhere. Prepare the form for the next submit.
		noticeError(null, levels.info, errorReport, `Cancel reload subscription failed`);
		formHelper.validationErrorHandler(errorReport);
		setSubmitting(false);
		return;
	}

	const { data: { TransitAccountRoute: { getSubscription: { subscriptions } } } } = queryResult;

	return { subscriptions };
};

export const cancelAutoloadMutation = async (
	apolloClient,
	subscriptionId,
	subsystemAccountReference,
) => {
	return graphqlErrorMiddleware(
		apolloClient.mutate({
			mutation: CANCEL_AUTOLOAD_SUBSCRIPTION,
			variables: {
				subscriptionId,
				subsystemAccountReference,
			},
			refetchQueries: [
				...getTransitAccountRefetchQueries(subsystemAccountReference),
			],
		}));
};

// TODO place here CancelReloadBalance, CancelReloadPass and CancelReload components
// in order to follow OMNY code structure pattern
