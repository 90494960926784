import cx from "classnames";
import React, { useState } from 'react';
import {
	useParams,
	Redirect,
	useLocation,
} from 'react-router-dom';
import { parse } from "qs";
import { useApolloClient, useQuery } from '@apollo/client';
import {
	map,
	get,
	values,
} from 'lodash';

import { levels, noticeError } from 'utils/Logger.js';

import { useStepContext, STEP_OFFSET_PARAM } from 'context/StepContext.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import { WireFormHelper } from 'utils/FormHelper.js';
import PreventDefault from 'utils/PreventDefault.js';
import Cart from 'components/payments/Cart.js';

import {
	NEW_ADDRESS_ID,
	AddressSelectorContext,
	useAddressSelectorState,
} from "components/account-settings/address-selector/AddressSelector.js";
import SelectAddressForm, { AddressType } from 'components/account-settings/SelectAddressForm.js';
import CmsContentList from 'components/data/CmsContentList.js';
import SelectPaymentMethodForm from 'components/account/card/add-passes/SelectPaymentMethodForm.js';

import {
	getYupSchema as getCreditCardFormYupSchema,
	removeAddressFormInputPrefix,
} from 'pages/account/purchase/AddPaymentMethod.js';
import { getAddressYupSchema } from 'components/forms/address/Address.validations.js';
import { getPrimaryPaymentMethodSchema } from 'pages/account/purchase/PurchaseProductPayment.js';
import WSName from 'server/api-types/WSName.js';
import WSAddress from 'server/api-types/WSAddress.js';
import WSAddressExt from 'server/api-types/WSAddressExt.js';
import { getCartTotal, useCartContext } from 'context/CartProvider.js';
import { GET_FULLNAME , USER_TRAVEL_CARDS_QUERY } from "components/data/session-user/SessionUser.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import WSIssueMediaLineItem from 'server/api-types/WSIssueMediaLineItem.js';
import WSIssueSubsystemMedia from 'server/api-types/WSIssueSubsystemMedia.js';

import useStandardMutation from "components/data/hooks/useStandardMutation.js";

import { ORDER_REPLACE_MUTATION } from "components/data/order/replace/OrderReplace.mutation.js";
import { SUBSYSTEM_ID , TOKEN } from 'utils/Constants.js';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import FormHelperProvider from 'utils/form-helper/FormHelperProvider.js';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import {
	PurchaseCharlieCardTakeoverLayout,
} from 'pages/account/PurchaseCharlieCard.js';
import ReloadSessionDataQs, { reloadProducts } from 'components/data/session-user/refetch-queries/ReloadSessionData.js';
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import { GET_CUSTOMER_ADDRESSES } from 'components/data/session-user/Addresses.query.js';
import { EnablementFee } from 'pages/account/PurchaseCharlieCardCcForm.js';
import routeKeys from 'CustomerRouteKeys.js';
import { getPathByRoute } from 'App.js';
import Toast from 'components/Toast';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { useOptionsSelectedContext } from 'pages/account/card/replacement/OptionsSelectedProvider.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import {
	REASON_TYPE_PARAM,

	BLOCKED_REASON_MAPPING,
} from "pages/account/card/replacement/ReasonOptions.js";
import {
	BYPASS_TO_REPLACEMENT,
} from 'pages/account/card/BlockOrReplaceFlow.js';

import * as tabs from 'styles/Tabs.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as purchaseCharlie from 'pages/account/PurchaseCharlieCardCcForm.module.css';
import { SAVE_ADDRESS_SHOWN } from "components/payments/AddressForm.js";
import { postNewAddress } from "components/account/address/PostNewAddress.js";
import { getPayments } from "utils/payment/getPayments.js";
import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";
import PublicAppVars from 'utils/PublicAppVars';
import { useTerminateTokenMutation } from "components/account/card/token-actions/TerminateToken.js";
import { CARD_REASONS } from 'pages/account/card/replacement/constants.js';

/**
 * Data-QA Tags:
 * CharlieCardCreditCardForm, CharlieCardHeader, CharlieCardCcFormAddressHeader, CharlieCardCreditCardFormSaveCheckbox,
 * CharlieCardCreditCardFormPurchaseBtn, LinkToAddCard, LinkToEMVCardd
 */

const cms = {
	purchaseStepOneHeader: "miscText.purchase-card-1-subheader",
	subHeader: "miscText.purchase-card-3-payment-subheader",
	subHeaderDetails: "miscText.purchase-card-3-shipping-subheader",
	enablementFeeDescription: "miscHtml.purchase-cart-enablementfee-description",
	shippingSubHeader: "miscText.general-shipping-address-subheader",
	confirmMsg: "miscText.card-replace-confirmation",
};


const PurchaseReplacementCharlieCardCcForm = () => {
	const location = useLocation();
	const {
		[ REASON_TYPE_PARAM ]: seachParamReasonType = null,
		[ STEP_OFFSET_PARAM ]: seachParamStepOffSet = '0', // string not int
	} = parse(location.search, { ignoreQueryPrefix: true });

	const stepOffSet = parseInt(seachParamStepOffSet, 10);

	const isSplit = false;
	const isFirst = true;

	const [ redirect, setRedirect ] = useState(null);
	const { setToast, removeToast } = useGlobalToastsContext();

	const {
		reasonType,
	} = useOptionsSelectedContext();

	const apolloClient = useApolloClient();

	const { data: sessionAddresses, loading: addressesLoading } = useQuery(GET_CUSTOMER_ADDRESSES);
	const wsAddressExts = get(sessionAddresses, 'session.customer.addresses', []);

	const {
		replacedTravelTokenId,
	} = useParams();
	const transit_account_id = useTransitAccountIdContext();

	const { cart } = useCartContext();
	const [ orderReplaceMutation ] = useStandardMutation(ORDER_REPLACE_MUTATION);
	const [ tokenActionMutation ] = useTerminateTokenMutation({
		queryOptions: {
			refetchQueries: [
				{ query: USER_TRAVEL_CARDS_QUERY },
				...getTransitAccountRefetchQueries(transit_account_id),
			],
			awaitRefetchQueries: true,
		},
	});

	const { data: nameData } = useStdQuery(GET_FULLNAME);
	const { step } = useStepContext();

	const addressSelectorState = useAddressSelectorState({ addressType: AddressType.Shipping });

	const {
		selectedFundingSources,
		loadProductLineItemFunds,
		isMultiPayment,
	} = useFundingSourcesContext();

	const mobilePayment = false;

	const total = getCartTotal(cart);

	const formHelperContext = useFormHelper({
		getYupSchema: (formHelper) => getCreditCardFormYupSchema({
			formHelper,
			isMultiPayment,
			isSelecting: [ false ],
			isAch: false,
			requireAchEula: false,
			isNew: [
				selectedFundingSources[ 0 ]?.isNew,
			],
			isNewConfirmed: [],
			total,
			hasMobilePayment: Boolean(mobilePayment),
			disableCvv: total === 0,
		})
			.concat(getPrimaryPaymentMethodSchema())
			.concat(getAddressYupSchema(addressSelectorState.addressType)),
		getDataToValidate: (formHelper) => {
			const data = formHelper.getFormData();
			if (data.expirationDate) {
				data.expirationMonth = parseInt(data.expirationDate.slice(0, 2), 10);
				data.expirationYear = parseInt(data.expirationDate.slice(-2), 10);
			}
			return data;
		},
	});

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = formHelperContext;

	const finalizeAndSetFundingSourcesToContext = useFinalizeAndSetFundingSourcesToContext({ formHelper });

	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {
			const validated = await formHelper.startValidation(true);
			const selectedFundingSources = await finalizeAndSetFundingSourcesToContext();

			const payments = getPayments({
				cart,
				selectedFundingSources,
				loadProductLineItemFunds,
			});

			const shippingAddress = removeAddressFormInputPrefix(validated, AddressType.Shipping);

			const wsAddressExt = shippingAddress.saveAddress
				? await postNewAddress({
					wsAddress: new WSAddress(shippingAddress),
					apolloClient,
				})
				: new WSAddressExt({
					...shippingAddress,
					...(
						addressSelectorState.selectedAddressId === NEW_ADDRESS_ID
							? {}
							: { ...shippingAddress }
					),
				});

			const CharlieCard = cart.mediaOptions[ 0 ];

			const {
				firstName,
				lastName,
			} = nameData?.session.customer.contact.name;

			const variables = {
				// We will always use ReplaceTransitAccountMedia because it causes the missing (old) travelToken
				// to be blocked immediately
				replacementType: 'ReplaceTransitAccountMedia',
				issueMediaLineItems: [
					new WSIssueMediaLineItem({
						issueMedia: new WSIssueSubsystemMedia({
							type: "TransitAccountMedia",
							subsystem: SUBSYSTEM_ID,
							mediaType: CharlieCard.mediaType,
							enablementFeeAmount: CharlieCard.enablementFeeAmount,
							itemTotalAmount: CharlieCard.enablementFeeAmount,
							subsystemTokenType: CharlieCard.subsystemTokenType,
							subsystemAccountReference: transit_account_id,
							replacedTravelTokenId,
						}),
					}).toResolver(),
				],
				payments: map(payments, wsPayment => wsPayment.toInputWSPaymentFactory()),
				itemsSubtotalAmount: CharlieCard.enablementFeeAmount,
				orderTotalAmount: CharlieCard.enablementFeeAmount,

				// if we bypassed here then use params reason type
				reasonCode: (stepOffSet === BYPASS_TO_REPLACEMENT && seachParamReasonType)
					? seachParamReasonType
					: reasonType.reasonCode,
				shippingName: new WSName({
					firstName,
					lastName,
				}).toResolver(),
				// Per Jon W: We are correct to use WSAddressExt and yes WSAddressExt.addressId is a required field per API spec
				// but the purchase flow allows for an empty value. jira is ccsa-3849
				// https://reflexions.slack.com/archives/CCF68M49M/p1643996850325519?thread_ts=1643232391.221400&cid=CCF68M49M
				shippingAddress: wsAddressExt,
			};

			await graphqlErrorMiddleware(
				orderReplaceMutation({
					variables,
					refetchQueries: [
						...getTransitAccountRefetchQueries(transit_account_id),
						...ReloadSessionDataQs,
						reloadProducts(transit_account_id),
					],
				}));
		} catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Order Replacement Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);

			return;
		}

		// https://reflexions.atlassian.net/browse/MBTA-2816?search_id=e5e41772-3cf0-4d3d-907a-b5357cc98bdf
		// When replacing a card, manually terminate old card via the front-end
		if (PublicAppVars.TERMINATE_TOKEN_AFTER_REPLACEMENT) {
			const variables = {
				tokenId: replacedTravelTokenId,
				tokenAction: TOKEN.ACTION.terminate,
				subsystemAccountReference: transit_account_id,
				reasonCode: BLOCKED_REASON_MAPPING[ CARD_REASONS.lost ].reasonCode,
			};

			try {
				await graphqlErrorMiddleware(tokenActionMutation({ variables }));
			} catch (errorReport) {
				noticeError(null, levels.info, errorReport, `Order Replacement Terminate Token Failed`);
				formHelper.validationErrorHandler(errorReport);
			} finally {
				setSubmitting(false);
			}
		}

		setToast(<Toast
			type="success"
			title={<CmsContentRendered.Span
				contentKey={cms.confirmMsg}
				fallbackValue="Your card has been blocked, and a replacement has been ordered."
			/>}
			onClosed={removeToast}
		/>);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id }),
			}} />,
		);
	};

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{() => (
			<FormHelperProvider {...{ formHelperContext }}>
				<form
					ref={formRef}
					method="post"
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					<PurchaseCharlieCardTakeoverLayout step={step}>
						<div className={cx(tabs.mainContent, purchaseCharlie.container)} data-qa="CharlieCardCreditCardForm">
							<div className={tabs.main}>
								<CmsContentRenderer.H2
									className={tabs.title}
									data-qa="CharlieCardHeader"
									contentKey={cms.subHeader}
									fallbackValue="1. Enter Payment Details"
								/>
								<div className={tabs.creditCardFormContainer}>
									<WireFormHelper {...{ formHelper }}>
										<SelectPaymentMethodForm {...{
											provideNickName: true,
											formHelper,
											isFirst,
										}} />
									</WireFormHelper>
									<CmsContentRenderer.H3
										className={tabs.title}
										data-qa="CharlieCardCcFormAddressHeader"
										contentKey={cms.subHeaderDetails}
										fallbackValue="2. Where should your new Charlie Card be shipped?"
									/>

									<CmsContentRenderer.P
										contentKey={cms.shippingSubHeader}
										className={forms.label}
										fallbackValue="Shipping Address"
									/>
									{!addressesLoading && addressSelectorState ?
										<AddressSelectorContext.Provider value={addressSelectorState}>
											<SelectAddressForm
												{...{
													formHelper,
													wsAddressExts,
													isSplit,
													isFirst,
													saveAddressCheckboxVisibility: SAVE_ADDRESS_SHOWN,
												}}
											/>
										</AddressSelectorContext.Provider>
										: <LoadingIcon size={SIZES.component} />
									}
								</div>
							</div>
							<EnablementFee />
						</div>
					</PurchaseCharlieCardTakeoverLayout>
					<Cart {...{ formHelper, submitting }} />
				</form>
			</FormHelperProvider>
		)}</CmsContentList>
	);
};

export default PurchaseReplacementCharlieCardCcForm;
