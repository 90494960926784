import {
	merge,
	clone,
} from "lodash";
import WSLoadProduct from "./WSLoadProduct.js";

// 2.13.1.1.4 WSLoadSubsystemAccountValue
// WSLoadSubsystemAccountValue is a subclass of WSLoadProduct. This class is used to add a
// value to a subsystem account. Expected to set the productLineItemType as “LoadSubsystemAccountValue”.

export default class WSLoadSubsystemAccountValue extends WSLoadProduct {
	constructor({
		productCost,
		productLineItemType,
		subsystem,
		subsystemAccountReference,
		purseType,
		purseTypeDescription,
		purseRestriction,
		purseRestrictionDescription,
	}) {
		super({
			productCost,
			productLineItemType,
		});

		// string(20)
		// (Required) Unique identifier of the subsystem.
		this.subsystem = subsystem;

		// string(20)
		// (Required) Unique identifier for the customer’s account in the subsystem.
		this.subsystemAccountReference = subsystemAccountReference;

		// string(40)
		// (Required)  Type of purse it is.
		this.purseType = purseType;

		// string(40)
		// (Required)  Description of the purse type.
		this.purseTypeDescription = purseTypeDescription;

		// string(40)
		// (Required)  Type of the purse restriction.
		this.purseRestriction = purseRestriction;

		// string(40)
		// (Required)  Description of the purse restriction.
		this.purseRestrictionDescription = purseRestrictionDescription;
	}

	static fromBackoffice(data) {
		const result = new WSLoadSubsystemAccountValue(data);
		result.productCost = parseInt(result.productCost, 10);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const result = clone(this);

		return merge(
			result,
			super.toBackoffice(),
		);
	}

	toResolver() {
		const forGraphql = clone(this);

		return merge(
			forGraphql,
			super.toResolver(),
		);
	}
}

const WSLoadSubsystemAccountValueFragment = `
	subsystem: String!
	subsystemAccountReference: String!
	purseType: String!
	purseTypeDescription: String!
	purseRestriction: String!
	purseRestrictionDescription: String!
`;


// address1 no longer required as of R11
export const WSLoadSubsystemAccountValueType = [ `
	type WSLoadSubsystemAccountValue {
		${WSLoadSubsystemAccountValueFragment}
	}
` ];

export const InputWSLoadSubsystemAccountValueType = [ `
	input InputWSLoadSubsystemAccountValue {
		productCost: Int!
		productLineItemType: String!
		${WSLoadSubsystemAccountValueFragment}
	}
` ];
