// note .mjs extension so that we can be loaded from ci env (which runs without webpack)

/**
 * Converts a string to a boolean, treating '0', 'false', 'FALSE', 'off' and falsy values like '' to false, the rest to true.
 * @param {string|boolean} string
 */
const strToBoolean = (string) => typeof string === 'boolean'
	? string // string is already boolean
	: [ '0', 'false', 'FALSE', 'off' ].includes(string)
	 ? false
	 : Boolean(string); // returns false for '', false, null, undefined; true for non-empty string

export default strToBoolean;
