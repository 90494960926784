import React from 'react';
import PublicAppVars from 'utils/PublicAppVars.js';
import {
	map,
	keys,
	first,
	groupBy,
	filter,
	values,
	startsWith, reduce, isEmpty,
} from 'lodash';

import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import {
	PRODUCT_TABS_LIST,
	PRODUCT_TAB_TO_INDEX_mapping,
	DISPLAY_GROUP_MIN_RANGE,
	DISPLAY_GROUP_MAX_RANGE,
	POPULAR_TAB,
	COMMUTER_RAIL_TAB,
	RAIL, SUBWAY_TAB, BUS_TAB, FERRY_TAB,
} from 'utils/Constants.js';
import TabProductList from 'components/account/TabProductList.js';
import { Popular } from 'components/Icon.js';
import { getTransitModeIcon } from 'components/icons/TransitModes.js';

import * as style from 'pages/account/purchase/PurchaseProductCatalog.module.css';


const getCurrentTabItems = (products, currentTabId) => {
	const explodedProducts = reduce(products, (accumulator, wsTransitAccountProduct) => {
		if (isEmpty(wsTransitAccountProduct.display)) {
			return [ ...accumulator, wsTransitAccountProduct ];
		}

		const products = reduce(wsTransitAccountProduct.display, (accumulator, wsProductDisplay) => {
			return [ ...accumulator, {
				...wsTransitAccountProduct,
				displayGroup: wsProductDisplay.group,
				displayOrder: wsProductDisplay.order,
			} ];
		}, []);

		return [ ...accumulator, ...products ];
	}, []);
	const groupedProducts = groupBy(explodedProducts, 'displayGroup');
	const currentDisplayGroup = first(groupedProducts[ currentTabId ])?.displayGroup;

	if (PublicAppVars.LOG_PRODUCT_CAT_DISPLAY_GROUP) {
		console.log("Product catalog display groups: ", keys(groupedProducts));
	}

	if (currentDisplayGroup >= DISPLAY_GROUP_MIN_RANGE && currentDisplayGroup <= DISPLAY_GROUP_MAX_RANGE) {
		return groupedProducts[ currentTabId ].sort((a, b) => a.displayOrder - b.displayOrder);
	}

	return [];
};


const cms = {
	mostPopular: 'miscText.purchase-catalogue-1-popular',
	subway: 'miscText.purchase-catalogue-2-subway',
	bus: 'miscText.purchase-catalogue-3-bus',
	commuterRail: 'miscText.purchase-catalogue-4-train',
	ferry: 'miscText.purchase-catalogue-5-ferry',
};

const getLabelMappings = (label) => {
	const mapping = {
		[ POPULAR_TAB ]: {
			cmsKey: cms.mostPopular,
		},
		[ SUBWAY_TAB ]: {
			cmsKey: cms.subway,
			travelMode: 'Transit',
		},
		[ BUS_TAB ]: {
			cmsKey: cms.bus,
			travelMode: 'Bus',
		},
		[ COMMUTER_RAIL_TAB ]: {
			cmsKey: cms.commuterRail,
			travelMode: 'Rail',
		},
		[ FERRY_TAB ]: {
			cmsKey: cms.ferry,
			travelMode: 'Ferry',
		},
	};

	return mapping[ label ];
};

export const TabsLabel = ({ label }) => {
	const {
		cmsKey,
		travelMode,
	} = getLabelMappings(label);

	return (
		<>
			{label === POPULAR_TAB
				? <>
					<Popular overrideClass={style.tabIcon} />
					<CmsContentRenderedInline
						contentKey={cmsKey}
						fallbackValue={label}
						className={style.tabLabel}
					/>
				</>
				: <>
					{getTransitModeIcon(travelMode, {
						overrideClass: style.tabIcon,
					})}
					<CmsContentRenderedInline
						contentKey={cmsKey}
						fallbackValue={label}
						className={style.tabLabel}
					/>
				</>
			}
		</>
	);
};

const processedProducts = (
	tab,
	formHelper,
	setValidationState,
	productCatalogResponse,
) => {
	const {
		transitValueOptions,
		products,
		maxAddTransitValue,
		minAddTransitValue,
	} = productCatalogResponse;

	// A way of identifying that a product is a "value-based pass" vs reload balance.
	// You'd get a bunch of transitValueOptions, and if they started with "$", they should go into the select amount widget.
	const filteredWsStoredValues = getCurrentTabItems(
		filter(transitValueOptions, (wsStoredValue) => !startsWith(wsStoredValue.name, "$")),
		PRODUCT_TAB_TO_INDEX_mapping[ tab ],
	);

	const sortedProducts = getCurrentTabItems(products, PRODUCT_TAB_TO_INDEX_mapping[ tab ]);

	const finalProducts = [
		...sortedProducts,
		...map(filteredWsStoredValues, wsStoredValue => ({
			id: wsStoredValue.id,
			productLongName: wsStoredValue.name,
			price: wsStoredValue.amount,
			isFareBased: true,
			transitValueOption: true,
		})),
	];

	// https://reflexions.atlassian.net/browse/MBTA-2433
	// Hide the tab if it has no products or stored values
	if (tab !== POPULAR_TAB && !finalProducts.length) {
		return null;
	}

	return {
		key: tab,
		label: <TabsLabel label={tab} />,
		content: <TabProductList
			{...{ formHelper, setValidationState, maxAddTransitValue, minAddTransitValue, transitValueOptions }}
			currentTab={tab}
			showBalanceConfig={tab === POPULAR_TAB}
			products={finalProducts}
		/>,
	};
};

export const getPurchaseCatalogTabs = ({
	formHelper,
	setValidationState,
	productCatalog,
}) => {
	const tabs = map(PRODUCT_TABS_LIST, tab => processedProducts(tab, formHelper, setValidationState, productCatalog));

	// https://reflexions.atlassian.net/browse/MBTA-2433
	// Hide the tab if it has no products or stored values
	return tabs.filter(tab => tab !== null);
};
