import React, { useContext, useEffect, useReducer, useState } from "react";

import {
	levels,
	log,
} from "utils/Logger.js";
import WSCreditDebitPayment from "server/api-types/WSCreditDebitPayment.js";
import WSDirectDebitPayment from "server/api-types/WSDirectDebitPayment.js";
import PublicAppVars from "utils/PublicAppVars.js";
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";

import Payment from "payment";

import {
	titleCase,
	sliceMaskedPAN,
} from "utils/FormatHelpers.js";
import CreditCardClear from "server/api-types/CreditCardClear.js";
import WSCreditCardReference from "server/api-types/WSCreditCardReference.js";
import WSDirectDebitReference from "server/api-types/WSDirectDebitReference.js";
import WSFundingSourceInfo from "server/api-types/WSFundingSourceInfo.js";

export const FundingSourcesContext = React.createContext([
	{},
	() =>
		log(null, levels.error, "FundingSourcesContext used before it was ready"),
]);
export const useFundingSourcesContext = () => useContext(FundingSourcesContext);

export const selectingCount = paymentsData => (paymentsData?.selectedFundingSources.length ?? 0);

export const getIsMultiPayment = paymentsData => (selectingCount(paymentsData) > 1);

class SelectedFundingSource {
	constructor({
		fundingSource,
		isNew,
		cvv,
	}) {
		/** @type WSFundingSource */
		this.fundingSource = fundingSource;

		/** @type Boolean */
		this.isNew = isNew;

		/** @type string */
		this.cvv = cvv;
	}
}

export const fundingSourceToWSPayment = ({
	fundingSource,
	// this WSPayment's paymentAmount may be updated later by setSplitAmount but
	// the split amount is only stored on the first WSPayment's paymentAmount.
	// Any time we're creating a new WSPayment, we have to be sure to
	// preserve that
	cvv,
	paymentAmount,
}) => {
	const creditCard = fundingSource?.creditCard;
	const directDebit = fundingSource?.directDebit;

	if (creditCard) {
		if (creditCard instanceof CreditCardClear) {
			return new WSCreditDebitPayment({
				// paymentAmount gets set
				creditCardType: titleCase(Payment.fns.cardType(creditCard.cardNumber)),
				maskedPan: sliceMaskedPAN(creditCard.cardNumber),
				cvv,
				cardExpiryMMYY: creditCard.cardExpiryMMYY,
				paymentAmount,

				// custom field used by resolveNewPaymentMethod
				oneTime: {
					address: fundingSource.billingAddress,
					cardNumber: creditCard.cardNumber,
					nameOnCard: creditCard.nameOnCard,
				},
			});
		}

		return new WSCreditDebitPayment({
			pgCardId: creditCard.pgCardId,
			creditCardType: creditCard.creditCardType,
			maskedPan: creditCard.maskedPan,
			cvv,
			cardExpiryMMYY: creditCard.cardExpiryMMYY,
			// add this when new saved payment method
			paymentType: PAYMENT_TYPES.creditCard,

			// WSPayment fields
			paymentAmount,
		});
	}

	if (directDebit) {
		// AKA ACH
		return new WSDirectDebitPayment({
			fundingSourceId: fundingSource.fundingSourceId,
			institutionName: directDebit.financialInstitutionName,
			maskedAccountNumber: directDebit.maskedBankAccountNumber,

			// WSPayment fields
			paymentAmount,
		});
	}
};

const FundingSourcesProvider = ({
	children,
}) => {
	const [ selectedFundingSources, updateSelectedFundingSource ] = useReducer((currentSelectedFundingSources, selectedFundingSource) => {
		const newSelectedFundingSources = [ ...currentSelectedFundingSources ];
		const {
			clear = false,
			splitPaymentIndex,
			fundingSource,
			isNew = null,
			cvv,
		} = selectedFundingSource;

		if (clear) {
			newSelectedFundingSources.splice(splitPaymentIndex, 1);
			return newSelectedFundingSources;
		}

		newSelectedFundingSources[ splitPaymentIndex ] = Object.freeze(new SelectedFundingSource({
			...currentSelectedFundingSources[ splitPaymentIndex ] ?? {},
			...(isNew !== null ? { isNew } : {}),
			...(isNew === true ? { fundingSource: null } : {}),
			...(fundingSource ? { fundingSource } : {}),
			cvv: cvv ? cvv : undefined,
		}));

		return newSelectedFundingSources;
	}, []);

	const clearSelectedFundingSource = (splitPaymentIndex) => {
		updateSelectedFundingSource({
			splitPaymentIndex,
			clear: true,
		});
	};

	const [ fundingSources, setFundingSources ] = useState([]);
	const [ loadProductLineItemFunds, setLoadProductLineItemFunds ] = useState([]);
	const [ restrictFunding, setRestrictFunding ] = useState(false);
	const [ refetchFundingSources, setRefetchFundingSources ] = useState(null);
	const [ fundingSourcesInitialized, setFundingSourcesInitialized ] = useState(false);

	const initializeFundingSources = ({
		fundingSources = [],
		loadProductLineItemFunds = [],
		restrictFunding = true,
		refetchFundingSources = null,
	}) => {
		setFundingSources(fundingSources.map(
			fundingSource => new WSFundingSourceInfo({
				...fundingSource,
				creditCard: fundingSource.creditCard ? new WSCreditCardReference(fundingSource.creditCard) : null,
				directDebit: fundingSource.directDebit ? new WSDirectDebitReference(fundingSource.directDebit) : null,
			}),
		));

		if (!fundingSources.length && !selectedFundingSources[ 0 ]) {
			updateSelectedFundingSource({
				splitPaymentIndex: 0,
				isNew: true,
			});
		}

		setLoadProductLineItemFunds(loadProductLineItemFunds);
		setRestrictFunding(restrictFunding);
		setFundingSourcesInitialized(true);
		setRefetchFundingSources(() => refetchFundingSources);
	};

	const [ splitAmount, setSplitAmount ] = useState(null);

	const [ finalWsPayments, setFinalWsPayments ] = useState([]);

	const selectingCount = selectedFundingSources.length ?? 0;
	const setSelectingCount = (count) => {
		if (count === 1 && selectedFundingSources.length === 2) {
			clearSelectedFundingSource(1);
		} else if (count === 2) {
			updateSelectedFundingSource({
				splitPaymentIndex: 1,
			});
		} else {
			throw new Error(`unexpected count ${count}`);
		}
	};

	const isMultiPayment = selectingCount > 1;

	const setMultiPayment = (newIsMultiPayment) => {
		setSelectingCount(newIsMultiPayment ? 2 : 1);
	};

	useEffect(() => {
		selectedFundingSources.forEach((selectedFundingSource, splitPaymentIndex) => {
			if (selectedFundingSource.isNew || !selectedFundingSource?.fundingSource) {
				return;
			}

			if (fundingSources.indexOf(selectedFundingSource.fundingSource) === -1) {
				const newSelectedFundingSource = fundingSources.find(fundingSource => {
					return fundingSource.fundingSourceId === selectedFundingSource.fundingSource.fundingSourceId
						|| fundingSource.creditCard?.pgCardId === selectedFundingSource.fundingSource.creditCard?.pgCardId;
				});

				if (!newSelectedFundingSource) {
					clearSelectedFundingSource(splitPaymentIndex);
				}

				updateSelectedFundingSource({
					splitPaymentIndex,
					fundingSource: newSelectedFundingSource,
				});
			}
		});
	}, [
		fundingSources, selectedFundingSources,
	]);

	const contextValue = {
		fundingSources,
		loadProductLineItemFunds,
		restrictFunding,
		refetchFundingSources,

		initializeFundingSources,
		fundingSourcesInitialized,

		selectedFundingSources,
		updateSelectedFundingSource,

		splitAmount,
		setSplitAmount,

		selectingCount,
		setSelectingCount,

		isMultiPayment,
		setMultiPayment,

		finalWsPayments,
		setFinalWsPayments,
	};

	if (PublicAppVars.LOG_FUNDING_SOURCES_CONTEXT_CHANGES) {
		console.log(contextValue);
	}

	return (
		<FundingSourcesContext.Provider value={contextValue}>
			{children}
		</FundingSourcesContext.Provider>
	);
};

export default FundingSourcesProvider;
