// extracted by mini-css-extract-plugin
export var balanceInput = "pages-account-__AutoloadPass-module___balanceInput";
export var balanceInputLabel = "pages-account-__AutoloadPass-module___balanceInputLabel";
export var formWrapper = "pages-account-__AutoloadPass-module___formWrapper";
export var header = "pages-account-__AutoloadPass-module___header";
export var learnMore = "pages-account-__AutoloadPass-module___learnMore";
export var link = "pages-account-__AutoloadPass-module___link";
export var linkPassIcon = "pages-account-__AutoloadPass-module___linkPassIcon";
export var linkPassValuesWrapper = "pages-account-__AutoloadPass-module___linkPassValuesWrapper";
export var mode = "pages-account-__AutoloadPass-module___mode";
export var moreInfo = "pages-account-__AutoloadPass-module___moreInfo";
export var panel = "pages-account-__AutoloadPass-module___panel";
export var saveBtnWrapper = "pages-account-__AutoloadPass-module___saveBtnWrapper";
export var savePaymentWrapper = "pages-account-__AutoloadPass-module___savePaymentWrapper";
export var section = "pages-account-__AutoloadPass-module___section";
export var text = "pages-account-__AutoloadPass-module___text";
export var value = "pages-account-__AutoloadPass-module___value";