// extracted by mini-css-extract-plugin
export var addressCheckbox = "styles-__CardList-module___addressCheckbox";
export var addressRow = "styles-__CardList-module___addressRow";
export var addressText = "styles-__CardList-module___addressText";
export var card = "styles-__CardList-module___card";
export var cardAddress = "styles-__CardList-module___cardAddress";
export var cardRadioInput = "styles-__CardList-module___cardRadioInput";
export var cardRadioLabel = "styles-__CardList-module___cardRadioLabel";
export var container = "styles-__CardList-module___container";
export var isActive = "styles-__CardList-module___isActive";
export var isSelected = "styles-__CardList-module___isSelected";
export var newAddressForm = "styles-__CardList-module___newAddressForm";
export var singleAddressForm = "styles-__CardList-module___singleAddressForm";
export var textToggle = "styles-__CardList-module___textToggle";