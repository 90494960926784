import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PreventDefault from 'utils/PreventDefault.js';
import { includes } from "lodash";
import {
	Close,
	Warning,
} from 'components/Icon.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";

import * as style from './Banner.module.css';
import { isMobile, MQ_TABLET, useMediaQueryMatches } from 'utils/Breakpoints';


const cms = {
	close: 'miscText.general-banner-close',
};

export const TYPE_ERROR = 'error';
export const TYPE_WARNING = 'warning';
export const TYPE_INFO = 'informational';

const Banner = ({
	type = TYPE_WARNING,
	showIcon = false,
	title = null,
	body = null,
	link = null,
	rightSide = null,
	onBannerClose = null,
}) => {
	const [ showBanner, setShowBanner ] = useState(true);

	const onClose = () => {
		onBannerClose?.();
		setShowBanner(false);
	};

	const isAtLeastTablet = useMediaQueryMatches(MQ_TABLET);

	// Warning and Info banners are not dismissable.
	// https://reflexions.slack.com/archives/CDTQPPXJ8/p1651072775758479?thread_ts=1651007506.910619&cid=CDTQPPXJ8
	const isDismissable = !includes([ TYPE_ERROR, TYPE_INFO ], type);

	return showBanner ?
		<div role="status" className={cx(style.wrapper, style[ type ])}>
			<div className={style.contents}>
				{showIcon
					? <div className={style.warningIconWrapper}>
						<Warning />
					</div>
					: null
				}

				<div className={style.leftSide}>
					<div className={cx(style.title)}>{title}</div>

					<div className={style.text}>
						{body}
					</div>
					{link ? link : null}
				</div>

				<div className={style.rightSide}>
					{isDismissable ?
						<button
							className={style.close}
							onClick={PreventDefault(onClose)}
						>
							<Close overrideClass={style.closeSvg} />
							<CmsContentRenderedInline
								className={style.closeButtonText}
								contentKey={cms.close}
								fallbackValue="Dismiss"
							/>
						</button>
						:null}
					{isAtLeastTablet && rightSide ? <div>{rightSide}</div> : null}
				</div>
			</div>
			{!isAtLeastTablet && rightSide ?  <div>{rightSide}</div> : null}
		</div>
		: null;
};

Banner.propTypes = {
	/** Styling and location of banner */
	type: PropTypes.oneOf([ TYPE_WARNING, TYPE_ERROR, TYPE_INFO ]),

	showIcon: PropTypes.bool,

	title: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),

	body: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.element,
		PropTypes.string,
	]),

	link: PropTypes.oneOfType([
		PropTypes.element,
	]),

	rightSide: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.element,
	]),

	/** to override the default setShowBanner(false) action */
	onBannerClose: PropTypes.func,
};

export default Banner;
