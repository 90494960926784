import React from 'react';
import { values } from 'lodash';
import { Link } from 'react-router-dom';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Container from 'components/Container.js';
import {
	Accounts,
	A11yMan,
	Chat,
} from 'components/Icon.js';

import * as pageLinks from './PageLinks.module.css';



const cms = {
	transitTitle: 'miscText.landing-iconlink1-title',
	transitText: 'miscText.landing-iconlink1-text',
	transitUrl: 'miscText.landing-iconlink1-url',

	stationsStopsTitle: 'miscText.landing-iconlink2-title',
	stationsStopsText: 'miscText.landing-iconlink2-text',
	stationsStopsUrl: 'miscText.landing-iconlink2-url',

	a11yTitle: 'miscText.landing-iconlink3-title',
	a11yText: 'miscText.landing-iconlink3-text',
	a11yUrl: 'miscText.landing-iconlink3-url',
};

const PageLinks = () => {
	return (
		<section className={pageLinks.container}>
			<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => {

				const links = [
					{
						key: 'LinkTransit',
						icon: <Accounts svgAriaDesc={cmsContent[ cms.transitTitle ]} overrideClass={pageLinks.linkIcon} aria-hidden={true} />,
						title: cms.transitTitle,
						text: cms.transitText,
						url: cms.transitUrl,
					},
					{
						key: 'LinkStationsStops',
						icon: <Chat svgAriaDesc={cmsContent[ cms.stationsStopsTitle ]} overrideClass={pageLinks.linkIcon} aria-hidden={true} />,
						title: cms.stationsStopsTitle,
						text: cms.stationsStopsText,
						url: cms.stationsStopsUrl,
					},
					{
						key: 'LinkA11y',
						icon: <A11yMan svgAriaDesc={cmsContent[ cms.a11yTitle ]} overrideClass={pageLinks.linkIcon} aria-hidden={true} />,
						title: cms.a11yTitle,
						text: cms.a11yText,
						url: cms.a11yUrl,
					},
				];

				return (
					<Container
						overrideClass={pageLinks.linksContainer}
						data-qa="PageLinksMenu"
					>
						{links.map(link => {

							const linkContent = <>
								<div className={pageLinks.linkMedia}>
									{link.icon}
								</div>
								<div className={pageLinks.linkContent}>
									<CmsContentRenderer.H4
										contentKey={link.title}
										className={pageLinks.linkTitle}
									/>
									<CmsContentRenderer.P
										contentKey={link.text}
										className={pageLinks.linkText}
									/>
								</div>
							</>;

							const url = cmsContent[ link.url ];

							return (url.includes("http")
								?	<a
									target="_blank"
									href={url}
									className={pageLinks.link}
									data-qa={link.key}
									key={link.key}
									rel="noreferrer"
								>
									{linkContent}
								</a>
								: <Link
									to={url}
									className={pageLinks.link}
									data-qa={link.key}
									key={link.key}
									rel="noreferrer"
								>
									{linkContent}
								</Link>);
						})}
					</Container>
				);}
			}</CmsContentList>
		</section>
	);
};

export default PageLinks;
