import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import Info from './icons/ui/Info.js';

import * as style from './Tooltip.module.css';
import CmsContent from './data/CmsContent.js';
import { getContentOrFallback } from './data/CmsContentRenderer.js';

const cms = {
	ariaLabel: 'miscText.general-tooltip-aria',
};

const Tooltip = ({
	toggle = <Info overrideClass={style.icon} aria-hidden={true} />,
	overrideClass = '',
	tooltipId,
	ariaLabel,
	ariaLabelPanel,
	snapshotTestId = null,
	children,
	...rest
}) => {

	const [ referenceElement, setReferenceElement ] = useState();
	const [ popperElement, setPopperElement ] = useState();
	const { styles, attributes } = usePopper(referenceElement, popperElement);

	return (
		<Popover className={cx(style.container, overrideClass)}
			{...rest}
		>
			<CmsContent contentKey={cms.ariaLabel} variables={{ field: ariaLabel }}>
				 {(content) => (
					<Popover.Button
						ref={setReferenceElement}
						className={style.toggle}
						aria-label={getContentOrFallback(content, `${ariaLabel} more info`)}
						aria-describedby={tooltipId}
					>
						{toggle}
					</Popover.Button>
				 )}
			</CmsContent>
			<Popover.Panel
				className={style.infoContainer}
				ref={setPopperElement}
				style={styles.popper}
				role="tooltip"
				focus={true}
				{...attributes.popper}
			>
				<div id={tooltipId} aria-label={ariaLabelPanel}>
					{children}
				</div>
			</Popover.Panel>
		</Popover>
	);
};

Tooltip.propTypes = {
	overrideClass: PropTypes.string,
};

export default Tooltip;
