// extracted by mini-css-extract-plugin
export var actionBtn = "styles-__Tabs-module___actionBtn";
export var actions = "styles-__Tabs-module___actions";
export var btnContainer = "styles-__Tabs-module___btnContainer";
export var cartSideBar = "styles-__Tabs-module___cartSideBar";
export var ccForm = "styles-__Tabs-module___ccForm";
export var checkContainer = "styles-__Tabs-module___checkContainer";
export var col = "styles-__Tabs-module___col";
export var content = "styles-__Tabs-module___content";
export var creditCardFormContainer = "styles-__Tabs-module___creditCardFormContainer";
export var forgotUsernametxt = "styles-__Tabs-module___forgotUsernametxt";
export var hidePanelMobile = "styles-__Tabs-module___hidePanelMobile";
export var hr = "styles-__Tabs-module___hr";
export var linkToCard = "styles-__Tabs-module___linkToCard";
export var main = "styles-__Tabs-module___main";
export var mainContent = "styles-__Tabs-module___mainContent";
export var resetPassContent = "styles-__Tabs-module___resetPassContent";
export var saveCardText = "styles-__Tabs-module___saveCardText";
export var sidebar = "styles-__Tabs-module___sidebar";
export var textLink = "styles-__Tabs-module___textLink";
export var title = "styles-__Tabs-module___title";
export var validationBox = "styles-__Tabs-module___validationBox";
export var validationBoxMobile = "styles-__Tabs-module___validationBoxMobile";