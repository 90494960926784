import { gql } from "@apollo/client";
import React, { useMemo } from 'react';
import StdQuery from "./StdQuery.js";
import StandardMutation from "./StandardMutation.js";
import LoadingIcon, { PageLoading, SIZES } from "components/icons/LoadingIcon.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";

export const GET_ACCESSIBILITY_PREFERENCES = gql`
	query getAccessibilityPreferences {
		session {
			id
			customer {
				id
				contact {
					id
					accessibilityPreferences {
						lang
						langName
						volAdjust
						sessionExtend
						fontMagnify
						textAudio
						authFactor
					}
				}
			}
		}
	}
`;

export const SET_ACCESSIBILITY_PREFERENCES = gql`
	mutation updateUserPreferences (
		$lang: String
		$langName: String
		$volAdjust: Boolean
		$sessionExtend: Boolean
		$fontMagnify: Boolean
		$textAudio: Boolean
		$authFactor: String
	) {
		updateUserPreferences(
			lang: $lang
			langName: $langName
			volAdjust: $volAdjust
			sessionExtend: $sessionExtend
			fontMagnify: $fontMagnify
			textAudio: $textAudio
			authFactor: $authFactor
		) {
			id
			accessibilityPreferences {
				lang
				langName
				volAdjust
				sessionExtend
				fontMagnify
				textAudio
				authFactor
			}
		}
	}
`;

export const useAccessibilityPreferences = () => {
	const { data, loading: dataLoading } = useStdQuery(GET_ACCESSIBILITY_PREFERENCES);
	const [ setAccessibilityPreferences, { loading: mutationLoading } ] = useStandardMutation(SET_ACCESSIBILITY_PREFERENCES);

	return useMemo(() => ({
		accessibilityPreferences: data?.session.customer?.contact.accessibilityPreferences || {},
		setAccessibilityPreferences,
		loading: dataLoading || mutationLoading,
	}), [ data?.session.customer?.contact.accessibilityPreferences, dataLoading, mutationLoading, setAccessibilityPreferences ]);
};

const AccessibilityPreferences = ({ children }) => {
	const { accessibilityPreferences, setAccessibilityPreferences, loading } = useAccessibilityPreferences();

	if (loading) {
		return <LoadingIcon sizes={SIZES.page} />;
	}

	// if they're not logged in, there won't be a customer
	// but if there's a customer, there's a contact with prefs (that might be null)
	// result.data can be undefined after running LoginStageRefetch's refresh()
	return children({
		accessibilityPreferences,
		setAccessibilityPreferences,
	});
};

export default AccessibilityPreferences;
