import React from 'react';
import subDays from "date-fns/subDays";
import {
	find,
	filter,
	first,
	some,
} from "lodash";

import { ISODatePart } from "utils/FormatHelpers.js";
import {
	DEFAULT_DATEFILTER_TYPE,
	FILTER_TYPE_CURRENT_MONTH,
	ALERTS_MAX_DAYS,
} from 'components/FiltersConstants.js';
import {
	autoFilledTap,
	missedTap,
	Trip,
} from 'server/api-types/WSRideHistoryFactory.js';
import {
	VOID_PENDING,
	CORRECTION_PENDING,
	REJECTED,
	COMPLETED,
} from 'server/api-types/WSTransitTravelPresence.js';
import { RESTRICTED_TRAVEL_CORRECTION_ACTION_TRIPVOID } from "server/api-types/WSRideHistory.js";


import * as tables from 'components/DataTable.module.css';

const cms = {
	pending: 'miscText["triphistory-trip-dispute-status.VoidPending"]',
	approved: 'miscText["triphistory-trip-dispute-status.Completed"]',
	denied: 'miscText["triphistory-trip-dispute-status.Denied"]',
};

const Matched = "Matched";
const Void = "Void";

export const Station = ({ station, mode }) => {
	if (station) {
		return <div className={tables.transportLabel}>{station}</div>;
	}

	return (
		<div className={tables.transportLabel}>{mode}</div>
	);
};

export const getDefaultFilterDates = ({
	isAlerts = false,
}) => {
	const today = new Date();
	const startDateTimeObj = isAlerts
	 	? subDays(today, ALERTS_MAX_DAYS)
		: DEFAULT_DATEFILTER_TYPE === FILTER_TYPE_CURRENT_MONTH
			? new Date(today.getFullYear(), today.getMonth())
			: subDays(today, DEFAULT_DATEFILTER_TYPE);

	return {
		startDateTime: ISODatePart(startDateTimeObj),
		endDateTime: ISODatePart(today),
	};
};

export const isAutoFilledTap = (wsTripRideHistory) => Boolean(find(wsTripRideHistory, function() {
	return (wsTripRideHistory.isCorrectable === true && find(wsTripRideHistory.travelPresenceEvents, { 'travelPresenceIndicator': autoFilledTap }));
}));

export const isOldAutoFilledTap = (wsTripRideHistory) => Boolean(find(wsTripRideHistory, function() {
	return (wsTripRideHistory.isCorrectable === false && find(wsTripRideHistory.travelPresenceEvents, { 'travelPresenceIndicator': autoFilledTap }));
}));

export const isMissedTap = (wsTripRideHistory) => Boolean(find(wsTripRideHistory, function() {
	return (wsTripRideHistory.isCorrectable === true && find(wsTripRideHistory.travelPresenceEvents, { 'travelPresenceIndicator': missedTap }));
}));

export const isOldMissedTap = (wsTripRideHistory) => Boolean(find(wsTripRideHistory, function() {
	return (wsTripRideHistory.isCorrectable === false && find(wsTripRideHistory.travelPresenceEvents, { 'travelPresenceIndicator': missedTap }));
}));

// as per Patricia (03/27/2023) we should update logic to use correctionStatus field instead of tripCorrectionStatus
// slack thread https://reflexions.slack.com/archives/CCF68M49M/p1679682261956279
// our ticket https://reflexions.atlassian.net/browse/MBTA-2745
// BO ticket https://reflexions.atlassian.net/browse/MBTA-2748
export const isCorrectionPending = (wsTripRideHistory) => {
	return find(wsTripRideHistory.travelPresenceEvents, { 'correctionStatus': VOID_PENDING }) // dispute
		|| find(wsTripRideHistory.travelPresenceEvents, { 'correctionStatus': CORRECTION_PENDING }); // correction missed taps
};

export const isCorrectionCompleted = (wsTripRideHistory) => {
	return find(wsTripRideHistory.travelPresenceEvents, { 'correctionStatus': COMPLETED }); // relates to both dispute and tap correction
};

export const isCorrectionRejected = (wsTripRideHistory) => {
	return find(wsTripRideHistory.travelPresenceEvents, { 'correctionStatus': REJECTED }); // relates to both dispute and tap correction
};

export const isEligibleToCorrect = (wsTripRideHistory) => Boolean(find(wsTripRideHistory, function() {
	return ((isAutoFilledTap(wsTripRideHistory)
	&& !isCorrectionPending(wsTripRideHistory)
	&& !isCorrectionCompleted(wsTripRideHistory))
	&& !isCorrectionRejected(wsTripRideHistory)
	|| (isMissedTap(wsTripRideHistory)
	&& !isCorrectionPending(wsTripRideHistory)
	&& !isCorrectionCompleted(wsTripRideHistory))
	&& !isCorrectionRejected(wsTripRideHistory));
}));

export const isEligibleToDisputeTrip = (wsTripHistory) => {
	const {
		restrictedTravelCorrectionActions,
	} = wsTripHistory;
	// if the trip was previously corrected, disputed, denied or currently a pending status,
	// it can not be disputed again

	// corrected missed or autofilled taps
	const tripCorrectPending = isCorrectionPending(wsTripHistory);
	const tripCorrectCompleted = isCorrectionCompleted(wsTripHistory);
	const tripCorrectRejected = isCorrectionRejected(wsTripHistory);

	// dispute trip was denied
	const hasTripVoid = (restrictedTravelCorrectionActions ?? []).some(action => (
		action.key === RESTRICTED_TRAVEL_CORRECTION_ACTION_TRIPVOID
	));

	return Boolean(find(wsTripHistory, function() {
		return (
			wsTripHistory.type === Trip
			// trip is allowed to dispute
			// as per Kirk request update from isVoidable to isFareAdjustable
			// slack https://reflexions.slack.com/archives/CCF68M49M/p1678207399788429
			// ticket https://reflexions.atlassian.net/browse/MBTA-2581
			&& wsTripHistory.isFareAdjustable === true
			&& wsTripHistory.isVoidable === true
			&& !hasTripVoid
			&& !tripCorrectPending
			&& !tripCorrectCompleted
			&& !tripCorrectRejected
		);
	}));
};

const getCorrectionStatus = (wsTripRideHistory) => {
	return some(wsTripRideHistory.travelPresenceEvents, 'correctionStatus');
};

export const getMissedTapsTrips = ({ lineItems }) => {
	const missedTapsTrip = filter(lineItems, wsTripRideHistory => {
		const missedTrip = find(wsTripRideHistory, function() {
			return (!getCorrectionStatus(wsTripRideHistory) && wsTripRideHistory.isCorrectable === true && find(wsTripRideHistory.travelPresenceEvents, { 'travelPresenceIndicator': missedTap }));
		});
		return missedTrip;
	});
	return missedTapsTrip;
};

export const getAutoFilledTapsTrips = ({ lineItems }) => {
	const autoFilledTapsTrip = filter(lineItems, wsTripRideHistory => {
		const autofilledTrip = find(wsTripRideHistory, function() {
			return (!getCorrectionStatus(wsTripRideHistory) && wsTripRideHistory.isCorrectable === true && find(wsTripRideHistory.travelPresenceEvents, { 'travelPresenceIndicator': autoFilledTap }));
		});
		return autofilledTrip;
	});
	return autoFilledTapsTrip;
};

export const getTripStatusCmsKeyValue = (wsTripRideHistory) => {
	const {
		processingStatus,
		correctionStatus,
		correctionDateTime,
		// Per Alessandro (5/28):
		// If correctionDateTime is not returned, use tripCorrectionDateTime as a back up.
		// https://reflexions.slack.com/archives/GA82SPCTV/p1590681386471600?thread_ts=1590611066.448400&cid=GA82SPCTV
		tripCorrectionDateTime: tripCorrectionDateTimeTap,
	} = first(wsTripRideHistory.travelPresenceEvents) ?? {};

	const {
		tripStatus,
		tripCorrectionDateTime,
		restrictedTravelCorrectionActions,
	} = wsTripRideHistory ?? {};

	if (processingStatus === Matched) {
		if (!correctionStatus) {
			const hasTripVoid = (restrictedTravelCorrectionActions ?? []).some(action => (
				action.key === RESTRICTED_TRAVEL_CORRECTION_ACTION_TRIPVOID
			));

			if (hasTripVoid) {
				// Dispute was already submitted and reviewed and denied by CSR.
				// This is a deviation from the spec, because we're getting processingStatus === Matched on the denied trip.
				// https://reflexions.slack.com/archives/CA82SPCTV/p1629850449177600
				return {
					cmsKey: cms.denied,
					fallbackValue: 'Denied',
				};
			}

			// Correction missed tap was approved
			if (tripStatus === Void && (
				correctionStatus === COMPLETED
			&& (correctionDateTime || tripCorrectionDateTime || tripCorrectionDateTimeTap)
			)) {

				return {
					cmsKey: cms.approved,
					fallbackValue: 'Approved',
				};
			}
			// Missing correctionStatus with !hasTripVoid means no dispute was made on that trip.
			return null;
		}

		// Dispute was already submitted for that Trip and pending CSR review
		// Missed tap correction submitted
		if (correctionStatus === VOID_PENDING || correctionStatus === CORRECTION_PENDING) {
			return {
				cmsKey: cms.pending,
				fallbackValue: 'Pending',
			};
		}
		// as of 03/03/2022 BO does not return restrictedTravelCorrectionActions field when dispute trip
		// so use correctionStatus to determine rejected disputed trip
		if (correctionStatus === REJECTED) {
			return {
				cmsKey: cms.denied,
				fallbackValue: 'Denied',
			};
		}

		if (correctionStatus === COMPLETED) {
			return {
				cmsKey: cms.approved,
				fallbackValue: 'Approved',
			};
		}
	}

	if (
		processingStatus === Void

		// Trip was disputed and refunded
		&& (
			(
				correctionStatus === COMPLETED
				&& (correctionDateTime || tripCorrectionDateTime || tripCorrectionDateTimeTap)
			)

			// Missing correctionStatus also means that the trip was disputed,
			// however didn't require CSR approval.
			|| !correctionStatus

			// You also have the date/time when the dispute was created or completed correctionDateTime.
			|| (correctionDateTime || tripCorrectionDateTime || tripCorrectionDateTimeTap)
		)
	) {
		return {
			cmsKey: cms.approved,
			fallbackValue: 'Completed',
		};
	}

	return null;
};
