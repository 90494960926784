// extracted by mini-css-extract-plugin
export var bgImage = "components-home-__Hero-module___bgImage";
export var btn = "components-home-__Hero-module___btn";
export var container = "components-home-__Hero-module___container";
export var content = "components-home-__Hero-module___content";
export var cta = "components-home-__Hero-module___cta";
export var homePageWrapper = "components-home-__Hero-module___homePageWrapper";
export var image = "components-home-__Hero-module___image";
export var subtitle = "components-home-__Hero-module___subtitle";
export var title = "components-home-__Hero-module___title";
export var wrapper = "components-home-__Hero-module___wrapper";