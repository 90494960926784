import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
	join,
	map,
	values,
} from 'lodash';
import cx from 'classnames';

import { useModalContext } from 'context/ModalProvider.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import PreventDefault from 'utils/PreventDefault.js';

import Modal from 'components/Modal.js';
import { getGroupedModes, getTransitModeIcon } from 'components/icons/TransitModes.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';

import * as card from 'components/account/ProductCard.module.css';
import * as modal from 'components/Modal.module.css';
import CmsContent from "components/data/CmsContent.js";
import { useProductCatalogContext } from "components/data/transit-account/ProductCatalogContext.js";




const cms = {
	cost: 'miscText.purchase-pass-cost',
	modes: 'miscText.purchase-pass-modes',
	learnMore: 'miscText.purchase-pass-learnmore-cta',
	learnMoreAria: 'miscText.purchase-pass-learnmore-cta-aria',
	learnMoreUrl: 'miscText.purchase-pass-learnmore-url',
	add: 'miscText.purchase-add-to-cart',
	cancel: 'miscText.general-modal-cancel',
};

const ProductDetails = ({
	product,
	isInCart = false,
	onAddToCart,
	hideAddToCart = false,
	operators,
}) => {
	const [ inCart, setInCart ] = useState(isInCart);
	const { setModal } = useModalContext();

	const addToCart = (product) => {
		if (!product.isFareBased) {
			setInCart(true);
		}
		onAddToCart(product);

		setModal(null);
	};

	const groupedModes = getGroupedModes(product, operators);

	return (
		<Modal title={product.productLongName}
			overrideClass={cx(modal.fullHeight)}
		>
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (

				<div className={card.modalContainer}>
					<div className={card.modalContent}>
						<div className={card.modalColumns}>
							<div className={card.modalCol}>
								<div className={card.modalLabel}>
									<CmsContentRenderer.Span
										contentKey={cms.cost}
										fallbackValue="Cost"
									/>
								</div>

								<div className={card.modalValue}>
									{centsToDisplay(product.price)}
								</div>
							</div>
							<div className={card.modalCol}>
								<div className={card.modalLabel}>
									<CmsContentRenderer.Span
										contentKey={cms.modes}
										fallbackValue="Available Modes"
									/>
								</div>
								<div className={cx(card.mediaContainer, card.modalModeIcons)}>
									{map(groupedModes, (mode, key) => (
										<a key={key}
											data-qa={key}
											title={join(map(mode, wsSubsystemOperator => wsSubsystemOperator?.operatorName), ', ')}
											className={card.modeIcons}
										>
											{getTransitModeIcon(key)}
										</a>
									))}
								</div>
							</div>
						</div>
						<div className={card.modalLink}>
							<CmsContent
								contentKey={cms.learnMoreAria}
								variables={{ product: product.productLongName }}
							>{({ content: ariaLabel }) => (
									<Button external
										to={cmsContent[ cms.learnMoreUrl ]}
										typeStyle={buttonTypeStylePlain}
										additionalClassNames={cx(card.learnMoreText)}
										aria-label={ariaLabel}
									>
										<CmsContentRenderer.Span
											contentKey={cms.learnMore}
											fallbackValue='Learn more'
											aria-hidden={true}
										/>
									</Button>
								)}</CmsContent>
						</div>
					</div>

					<div className={card.modalCtaContainer}>
						<div className={card.modalCtaRow}>
							{(!inCart || !hideAddToCart) && (
								<Button
									isPrimary={true}
									onClick={PreventDefault(() => addToCart(product))}
									overrideClass={card.modalButton}
									type="button"
								>
									<CmsContentRenderer.Span
										contentKey={cms.add}
										fallbackValue="Add to cart"
									/>
								</Button>
							)}
						</div>
						<div className={card.modalCtaRow}>
							<Button
								isPrimary={false}
								onClick={PreventDefault(() => setModal(null))}
								overrideClass={card.modalButton}
							>
								<CmsContentRenderer.Span
									contentKey={cms.cancel}
									fallbackValue="Cancel"
								/>
							</Button>
						</div>
					</div>
				</div>
			)}</CmsContentList>
		</Modal>
	);
};

ProductDetails.propTypes = {
	product: PropTypes.object.isRequired,

	isInCart: PropTypes.bool,

	onAddToCart: PropTypes.func,

	hideAddToCart: PropTypes.bool,
};

export default ProductDetails;
