import React, {
	Component,
	Fragment,
} from 'react';
import PropTypes from 'prop-types';

import { SmallCheck } from '../Icon.js';
import Button, { Primary } from '../Button.js';

import * as panel from '../Panel.module.css';
import ManageMenu from "components/account/ManageMenu.js";

class CardService extends Component {
	static propTypes = {
		service: PropTypes.object,
	};


	static defaultProps = {};


	constructor(props) {
		super(props);

		this.state = {};

		this.transferService = this.transferService.bind(this);
		this.unlinkService = this.unlinkService.bind(this);
	};


	transferService() {

	}


	unlinkService() {

	}


	render() {
		const {
			service,
			isConnected,
		} = this.props;

		const serviceActions = [
			{
				label: "Transfer Service",
				action: this.transferService,
				type: 'normal',
			},
			{
				label: 'Danger Action',
				action: this.unlinkService,
				type: 'danger',
			},
		];

		return (
			<div className={panel.section}>
				<div className={panel.service}>
					<span className={panel.serviceLogo}
						  aria-label={service.name + ' App'}
					>
						{service.image}
					</span>

					<h5 className={panel.serviceName}>{service.name}</h5>

					{isConnected
						? (
							<Fragment>
								<p className={panel.serviceStatus}>
									<span className={panel.serviceIcon}>
										<SmallCheck overrideClass={panel.serviceCheck} aria-hidden={true}/>
									</span>
									Connected as {service.account.name}
								</p>
								<div className={panel.serviceActions}>
									<ManageMenu label="Manage"
											  options={serviceActions}
									/>
								</div>
							</Fragment>
						)
						: (
							<div className={panel.serviceActions}>
								<Button
									text="Link"
									type={Primary}
								/>
							</div>
						)}

				</div>
			</div>
		);
	}
}

export default CardService;
