import {
	map,
	clone,
	merge,
} from "lodash";

import WSLoadProduct, { WSLoadProductType, WSLoadProductFragment } from "./WSLoadProduct.js";
import WSSubsystemOperator,
{ WSSubsystemOperatorType, InputWSSubsystemOperatorType } from "./WSSubsystemOperator.js";

// 1.1.61.3 WSLoadSubsystemProduct
// WSLoadSubsystemProduct is a subclass of WSLoadProduct. This class is used to
// add a subsystem product or pass to subsystem account. Expected to set the
// productLineItemType as “LoadSubsystemProduct”.

export default class WSLoadSubsystemProduct extends WSLoadProduct {
	constructor({
		subsystem,
		subsystemAccountReference,
		productSKU,
		productName,
		productReceiptName,
		validityStartDtm,
		validityEndDtm,
		permittedUsers,
		travelTokenId,

		// Super props
		productCost,
		productLineItemType,
	}) {

		super({
			productCost,
			productLineItemType,
		});

		// string(20)
		// (Required) Unique identifier of the subsystem.
		this.subsystem = subsystem;

		// string(20)
		// (Required) Unique identifier for the customer’s
		// account in the subsystem.
		this.subsystemAccountReference = subsystemAccountReference;

		// string(40)
		// (Required) Product SKU.
		this.productSKU = productSKU;

		// string(250)
		// (Required) The name of the product in English.
		this.productName = productName;

		// string(250)
		// (Required) The product name to be displayed on
		// the order receipt (may be a composition of several
		// product fields).
		this.productReceiptName = productReceiptName;

		// dateTime
		// (Optional) The date the product is valid from.
		this.validityStartDtm = validityStartDtm;

		// dateTime
		// (Optional) The date the product is valid to.
		this.validityEndDtm = validityEndDtm;

		// List<WSSubsystemOperator>
		// (Required)List of operators that are permitted to
		// use this transit product.
		this.permittedUsers = permittedUsers;

		// string(20)
		// (Optional) The travel token id to designate the use
		// of the product.
		this.travelTokenId = travelTokenId;
	}

	static fromBackoffice(data) {
		const result = new WSLoadSubsystemProduct(data);

		result.permittedUsers = map(data.permittedUsers, wsSubsystemOperator =>
			WSSubsystemOperator.fromBackoffice(wsSubsystemOperator));

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const result = clone(this);

		result.permittedUsers = map(this.permittedUsers, wsSubsystemOperator =>
			wsSubsystemOperator.toBackoffice());

		return merge(
			result,
			super.toBackoffice(),
		);
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.productSKU;

		forGraphql.permittedUsers = map(this.permittedUsers, wsSubsystemOperator =>
			wsSubsystemOperator.toResolver());

		return merge(
			forGraphql,
			super.toResolver(),
		);
	}
}

const WSLoadSubsystemProductFragment = `
	${WSLoadProductFragment}
	subsystem: String!
	subsystemAccountReference: String!
	productSKU: String!
	productName: String!
	productReceiptName: String!
	validityStartDtm: GraphQLDateTime
	validityEndDtm: GraphQLDateTime
	travelTokenId: String
`;

export const WSLoadSubsystemProductType = [
	...WSLoadProductType,
	...WSSubsystemOperatorType, `
	type WSLoadSubsystemProduct implements WSLoadProductFactory {
		${WSLoadSubsystemProductFragment}
		permittedUsers: [ WSSubsystemOperator! ]!

	}`,
];

export const InputWSLoadSubsystemProductType = [
	...InputWSSubsystemOperatorType, `
	input InputWSLoadSubsystemProduct {
		${WSLoadSubsystemProductFragment}
		permittedUsers: [ InputWSSubsystemOperator! ]!
	}
` ];
