import { gql } from "@apollo/client";

export const LINK_TRAVEL_TOKEN = gql`
	mutation linkTravelTokenM(
		$travelToken: InputTravelToken!
	) {
		linkTravelToken(
			travelToken: $travelToken
		)
	}
`;
