import React, {
	useState,
} from "react";
import cx from 'classnames';

import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';


import TakeOverLayout from "../../layouts/TakeOverLayout.js";
import Input from "../../components/forms/Input.js";
import Button, {
	Primary,
} from "components/Button.js";
import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";

import {
	Redirect,
} from "react-router-dom";
import { RadioGroup } from '@headlessui/react';
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import PreventDefault from "utils/PreventDefault.js";
import {
	USER_DEBT_QUERY,
	USER_PAYMENT_METHODS_QUERY,
} from "components/data/session-user/SessionUser.js";
import Toast from "../../components/Toast";
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";
import { LINK_TRAVEL_TOKEN } from "../../graphql-queries/TravelTokens.js";
import Tooltip from "components/Tooltip.js";
import { CharlieCardSecurityCode } from "../../components/Icon.js";
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import FormHelperProvider from 'utils/form-helper/FormHelperProvider.js';
import { map, values } from "lodash";
import CmsContentList from "components/data/CmsContentList.js";

import useCardLast4Digits from "components/data/hooks/cardLast4Digits.js";
import { CHARLIE_CARD_PHYSICAL, CHARLIE_CARD_VIRTUAL } from "utils/Constants.js";
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { nickNameMaxLengthCMSKey } from "components/modals/EditCardNickname.js";

import { useGlobalToastsContext } from "context/ToastProvider.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import PublicAppVars from "utils/PublicAppVars.js";

import * as tabs from '../../styles/Tabs.module.css';
import * as style from './LinkCharlieCard.module.css';
import * as forms from 'components/forms/Forms.module.css';
import ComingSoon from "components/banners/ComingSoon.js";
import AFC1Banner from "components/banners/AFC1Card.js";

const getYupSchema = ({ setIsAFC1Card, isAFC1Card = false }) => {

	const validations = {
		cardNumber: yup_string()
			.trim()
			.test({
				//https://reflexions.atlassian.net/browse/MBTA-2907
				name: 'Old AFC1 card code',
				test: (value) => {

					// Only begin validation once we have a full card number, otherwise the normal CC validation will pick up the testing
					if (value.slice(0,2) === "5-") {
						setIsAFC1Card(true);
						return false;
					}
					else if (isAFC1Card) {
						setIsAFC1Card(false);
						return true;
					}

					return true;
				},
				message: () => <CmsContentRenderedInline
					contentKey={'miscHtml.addcard-standard-error-invalid'}
					fallbackValue={'Invalid Charlie Card'}
				/>,
			})
			.required("miscHtml.general-transit-charlie-physical-cardnum-error"),

		cvv: yup_string()
			.matches(/^[0-9]{3}$/, "miscHtml.general-transit-charlie-physical-security-error"),
		cardNickname: yup_string()
			.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, nickNameMaxLengthCMSKey),
	};

	return yup_object().shape(validations);
};


const cms = {
	header: 'miscText.addcard-charlie-header',
	subHeader: 'miscText.addcard-charlie-subheader',
	cardNumberLabel: 'miscText["addcard-charlie-physical-cardnumber.label"]',
	cardNumberPlaceHolder: 'miscText["addcard-charlie-physical-cardnumber.placeholder"]',
	cardCcvLabel: 'miscText["addcard-charlie-physical-security.label"]',
	cardCcvTooltip: 'miscText.addcard-charlie-physical-security-tooltip',
	cardCcvPlaceHolder: 'miscText["addcard-charlie-physical-security.placeholder"]',
	cardNickNameLabel: 'miscText["addcard-charlie-physical-nickname.label"]',
	cardNickNameDescription: 'miscText["addcard-charlie-physical-nickname.description"]',
	cardNickNamePlaceHolder: 'miscText["addcard-charlie-physical-nickname.placeholder"]',
	cardStandSwitchLabel: 'miscHtml.addcard-standard-switch',
	cardPhysicalCharlieLabel: 'miscText.addcard-charlie-physical',
	cardVirtualCharlieLabel: 'miscText.addcard-charlie-virtual',
	submitCardDetails: 'miscText.addcard-standard-submit',
	confirmation: 'miscText.addcard-wallet-confirmation',
};

const charlieOptionsTypeCms = [
	{ key: cms.cardPhysicalCharlieLabel, value: CHARLIE_CARD_PHYSICAL, fallbackValue: 'Physical Charlie Card' },
	{ key: cms.cardVirtualCharlieLabel, value: CHARLIE_CARD_VIRTUAL, fallbackValue: 'Mobile Charlie Card' },
];

const LinkCharlieCard = ({ }) => {
	const [ linkTravelCardMutation, { loading } ] = useStandardMutation(
		LINK_TRAVEL_TOKEN,
		{
			refetchQueries: [
				{ query: USER_DEBT_QUERY },
				{ query: USER_PAYMENT_METHODS_QUERY },
			],
		}
	);
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);
	const [ charlieCardType, setCharlieCardType ] = useState(CHARLIE_CARD_PHYSICAL);
	const { cardLast4Digits, onBlurCardNumber } = useCardLast4Digits();
	const [ isAFC1Card, setIsAFC1Card ] = useState();

	const formHelperContext = useFormHelper({ getYupSchema: () => getYupSchema({ setIsAFC1Card, isAFC1Card }) });

	const {
		formRef,
		formHelper,
	} = formHelperContext;

	const kickoffSubmit = async (cmsContent) => {
		let validated;
		try {
			validated = await formHelper.startValidation(true, null);
		}
		catch (errorReport) {
			//validation failed
			formHelper.validationErrorHandler(errorReport);
			return;
		}
		// GoogleAnalytics.logEvent("User is attempting to add a payment method");

		// props.submit succeeds or returns an ErrorReport

		try {
			await graphqlErrorMiddleware(linkTravelCardMutation({
				variables: {
					travelToken: {
						tokenType: TRAVEL_TOKEN_TYPES.smartcard,
						serialNumber: validated.cardNumber,
						cvv: validated.cvv,
						cardNickname: validated.cardNickname,
					},
					autoCreate: false,
				},
			}));

			setToast(<Toast
				type="success"
				title={<CmsContentRenderedInline
					cmsContent={cmsContent}
					contentKey={cms.confirmation}
					fallbackValue="Charlie Card has been added to your account."
					variables={{ nickname: validated.cardNickname || 'Charlie Card' }}
				/>}
				onClosed={removeToast}
			/>);
		}
		catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Link Smartcard Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardSelection),
			}} />
		);
	};


	if (redirect) {
		return redirect;
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<>
				{PublicAppVars.COMING_SOON_LINK_CHARLIE_CARD && <ComingSoon />}
				{isAFC1Card && <AFC1Banner />}
				<TakeOverLayout
					title={findContentOrFallback(cmsContent, cms.header, "Link Your Charlie Card to Your Account")}
					showCancel
				>
					<div className={tabs.mainContent} data-qa="LinkCardContent">
						<div className={tabs.main}>
							<FormHelperProvider {...{ formHelperContext }}>
								<form
									method="post"
									data-qa="addPaymentMethodForm"
									ref={formRef}
									onSubmit={PreventDefault(() => kickoffSubmit(cmsContent))}
								>
									<div className={tabs.col}>
										<CmsContentRenderer.H2
											contentKey={cms.subHeader}
											className={tabs.title}
											fallbackValue="Enter the details of your Charlie Card"
											data-qa="LinkCardHeader"
										/>

										{PublicAppVars.ENABLE_MOBILE_FARE_CARD &&
										<RadioGroup
											value={charlieCardType}
											onChange={setCharlieCardType}
											className={cx(forms.radioGroup, style.radioCharlieType)}
										>
											<div className={forms.radioGroupOptions}>
												{map(charlieOptionsTypeCms, ({ key, fallbackValue, value }) => (
													<RadioGroup.Option
														key={key}
														value={value}
														className={style.radioCharlieOption}
													>
														{({ checked }) => (
															<span className={cx(forms.radioLabel, checked && forms.radioChecked)}>
																<CmsContentRenderer.Span
																	contentKey={key}
																	className={style.radioCharlieLabel}
																	fallbackValue={fallbackValue}
																/>
															</span>
														)}
													</RadioGroup.Option>))}
											</div>
										</RadioGroup>
										}
										<Input
											type="text"
											inputMode="numeric"
											name="cardNumber"
											label={
												<CmsContentRenderer.Span
													contentKey={cms.cardNumberLabel}
													fallbackValue="Card Number"
												/>}
											data-qa="cardNumber-input"
											error={formHelper.getFieldError("cardNumber")}
											onBlur={() => onBlurCardNumber(formHelper)}
										/>
										{charlieCardType === CHARLIE_CARD_PHYSICAL && (
											<div className={cx(forms.ccRow, forms.passwordCharlieWrapper)}>
												<Input
													type="text"
													name="cvv"
													hideLabel={true}
													label={cmsContent[ cms.cardCcvLabel ] || 'Security Code'}
													data-qa="CvvInput"
													error={formHelper.getFieldError('cvv')}
												/>
												<div className={style.tooltipContainer}>
													<CmsContentRenderer.Span
														contentKey={cms.cardCcvLabel}
														fallbackValue="Security Code"
													/>
													<Tooltip
														tooltipId={'linkCardFormCvvToolTip'}
														overrideClass={style.tooltipOverride}
														ariaLabel={cmsContent[ cms.cardCcvLabel ] || 'Security Code'}
														ariaLabelPanel={cmsContent[ cms.cardCcvTooltip ] || 'Security code can be found below your card number, in the bottom-left corner'}
													>
														<div className={style.tooltipCcv}>
															<CmsContentRenderer.Span
																contentKey={cms.cardCcvTooltip}
																fallbackValue="Security code can be found below your card number, in the bottom-left corner" />
															<CharlieCardSecurityCode className={forms.securityCode} />
														</div>
													</Tooltip>
												</div>
											</div>
										)}

										<Input type="text"
											name="cardNickname"
											label={
												<div>
													<CmsContentRenderer.Div
														contentKey={cms.cardNickNameLabel}
														fallbackValue="Card Nickname" />
													<CmsContentRenderer.Div
														className={forms.ccText}
														contentKey={cms.cardNickNameDescription}
														fallbackValue="Enter a nickname to help you distinguish between cards. The last 4 digits of your card will always display."
													/>
												</div>}
											labelClass={style.labelOverride}
											placeholder={findContentOrFallback(cmsContent, cms.cardNickNamePlaceHolder, 'Charlie Card')}
											isOptional={true}
											data-qa="card-nick-name-input"
											error={formHelper.getFieldError('cardNickname')}
											maxLength={PublicAppVars.CARD_NICKNAME_MAXLENGTH}
											suffix={`...${cardLast4Digits}`}
											autocomplete="off"
										/>
									</div>

									{formHelper.getFieldErrorJsx('')}
									<div className={tabs.actions}>
										<Button
											theme={Primary}
											additionalClassNames={tabs.actionBtn}
											data-qa="LinkCharlieCardBtn"
											submitting={loading}
										>
											<CmsContentRenderer.Span
												contentKey={cms.submitCardDetails}
												fallbackValue="Confirm card details" />
										</Button>
									</div>
								</form>
							</FormHelperProvider>
						</div>

						{PublicAppVars.ENABLE_NEW_CARD_PURCHASE &&
							<div className={tabs.sidebar}>
								<CmsContentRenderer
									contentKey={cms.cardStandSwitchLabel}
									rawHtml={true}
									fallbackValue={`<p>Do you need a new card?</p><p><a href='/direct-link/purchase-card'>Order a new Charlie Card by mail</a></p>`}
								/>
							</div>
						}
					</div>
				</TakeOverLayout>
			</>
		)}
		</CmsContentList>
	);
};

export default LinkCharlieCard;
