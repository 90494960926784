import { useApolloClient } from '@apollo/client';
import cx from 'classnames';
import React, { useState } from 'react';

import Button, { Primary } from '../Button.js';
import PreventDefault from 'utils/PreventDefault.js';
import { WireFormHelper } from 'utils/FormHelper.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { Redirect } from 'react-router-dom';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import { getCardIcon } from 'components/Icon.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import NewCreditCardForm from 'components/account/card/add-passes/NewCreditCardForm.js';

import {
	NEW_ADDRESS_ID,
	useAddressSelectorState,
	AddressSelectorContext,
} from "components/account-settings/address-selector/AddressSelector.js";
import {
	getYupSchema,
	addNewPaymentMethod, paymentFieldsFromForm,
} from 'pages/account/purchase/AddPaymentMethod.js';

import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import { getPrimaryPaymentMethodValiations } from 'pages/account/purchase/PurchaseProductPayment.js';
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import WSAddress from 'server/api-types/WSAddress.js';

import * as forms from 'components/forms/Forms.module.css';
import * as tabs from 'styles/Tabs.module.css';
import * as style from 'components/account/TabAddPaymentCard.module.css';

/**
 * Data-QA Tags:
 * TabAddPaymentCardTitle
 */

const cms = {
	header: "miscText.payments-add-cc-subheader",
	submitButton: "miscText.payments-add-cc-submit",
};

export const PaymentOptionImages = ({
	additionalClassNames,
}) => {
	return <div className={cx(style.acceptedPaymentTypes, additionalClassNames)}>
		{PublicAppVars.ACCEPTED_PAYMENT_TYPES.map(paymentType => (
			<div className={style.cardWrapper} key={paymentType}>
				{getCardIcon(paymentType, { overrideClass: style.ccardIcon })}
			</div>
		))}
	</div>;
};


const TabAddPaymentCard = () => {
	const apolloClient = useApolloClient();
	const [ redirect, setRedirect ] = useState(null);

	const addressSelectorState = useAddressSelectorState({ });

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({
		getYupSchema: () => getYupSchema({
			formHelper,
			isMultiPayment: false,
			isSelecting: [ true ],
			isNew: [ true ],
			isAch: [ false ],
			// can chain shape method, which acts like object extends
		}).shape({
			primaryPaymentMethod: getPrimaryPaymentMethodValiations.primaryPaymentMethod,
		}),
		getDataToValidate: () => {
			const data = formHelper.getFormData();
			if (data.expirationDate) {
				data.expirationMonth = parseInt(data.expirationDate.slice(0, 2), 10);
				data.expirationYear = parseInt(data.expirationDate.slice(-2), 10);
			}
			return data;
		},
	});

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.info, errorReport, `Pay for reload failed`);
			formHelper.validationErrorHandler(errorReport, false);
			return;
		}

		try {
			const preValidatedData = paymentFieldsFromForm(validated);

			const billingAddressId = addressSelectorState?.selectedAddressId === NEW_ADDRESS_ID
				? null
				: addressSelectorState.selectedAddressId;

			const billingAddress = addressSelectorState?.selectedAddressId === NEW_ADDRESS_ID
				? new WSAddress(preValidatedData)
				: null;

			const fundingSourceId = await addNewPaymentMethod({
				apolloClient,
				preValidatedData,
				billingAddressId,
				billingAddress,
			});

			if (fundingSourceId) {
				setRedirect(
					<Redirect push to={{
						pathname: getPathByRoute(routeKeys.AccountPaymentMethods),
						state: { addPaymentSuccess: true },
					}} />
				);
			}
		} catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Add new payment method failed`);
			formHelper.validationErrorHandler(errorReport);
		} finally {
			setSubmitting(false);
		}
	};

	if (redirect) {
		return redirect;
	}

	return (
		<div className={tabs.content}>
			<div className={style.rowHeader}>
				<CmsContentRenderedInline
					elementType="h3"
					className={cx(forms.title, style.title)}
					data-qa="TabAddPaymentCardTitle"
					contentKey={cms.header}
					fallbackValue="Enter the Details of the New Card"
				/>
				<PaymentOptionImages
					additionalClassNames={style.paymentOpts}
				/>
			</div>
			<WireFormHelper {...{ formHelper }}>
				<form
					method="post"
					data-qa="NewPaymentCardForm"
					ref={formRef}
					onSubmit={PreventDefault(() => kickoffSubmit())}
				>
					{formHelper.getFieldErrorJsx('')}
					{/* Adding a new payment method shouldn’t include the “save this payment method” option,
						    since in this context, the user is explicitly already asking to save a new payment method.
							https://reflexions.atlassian.net/browse/MBTA-1994
						*/}
					{addressSelectorState &&
						<AddressSelectorContext.Provider value={addressSelectorState}>
							<NewCreditCardForm {...{ formHelper }} showSaveCheckbox={false} />
						</AddressSelectorContext.Provider>}
					<div className={tabs.actions}>
						<Button
							submitting={submitting}
							calcTypeStyle={Primary}
							overrideClass={tabs.actionBtn}
							data-qa="AddPaymentCardAction"
						>
							<CmsContentRenderedInline
								elementType="span"
								contentKey={cms.submitButton}
								fallbackValue="Add New Card"
							/>
						</Button>
					</div>
				</form>
			</WireFormHelper>
		</div>
	);
};

TabAddPaymentCard.propTypes = {
};

export default TabAddPaymentCard;
