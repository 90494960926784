import React from 'react';
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as admin from 'layouts/Admin.module.css';

/**
 * Data-QA Tags:
 * AdminNav, AdminNavLink
 */

const SidebarNavigation = ({
	links,
	isMobile = false,
	isOpen = false,
	closeMenu,
	additionalClasses,
}) => {
	const location = useLocation();

	return (
		<>
			<nav className={cx(admin.sideNav,
				additionalClasses,
				isMobile && admin.isMobile,
				isOpen && admin.isOpen,
			)}
			data-qa="AdminNav"
			>
				{links.map((link) =>

					<NavLink
						key={link.key}
						to={link.to}
						className={cx(admin.sideNavLink,
							location.pathname === link.to && admin.isActive)}
						data-qa="AdminNavLink"
					>
						{link.label}
					</NavLink>
				)}
			</nav>

			{isOpen &&
				<div className={admin.sideNavOverlay}
					onClick={() => closeMenu}
				/>
			}
		</>)
	;
};

SidebarNavigation.propTypes = {
	links: PropTypes.array.isRequired,

	isMobile: PropTypes.bool,

	isOpen: PropTypes.bool,

	closeMenu: PropTypes.func,
};

export default SidebarNavigation;
