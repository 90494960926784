import WSSubsystemAccountBankcardTravelTokenDisplay, { WSSubsystemAccountBankcardTravelTokenDisplayType } from "./WSSubsystemAccountBankcardTravelTokenDisplay.js";
import WSSubsystemAccountSmartcardTravelTokenDisplay, { WSSubsystemAccountSmartcardTravelTokenDisplayType } from "./WSSubsystemAccountSmartcardTravelTokenDisplay.js";
import WSSubsystemAccountTravelTokenDisplay, { WSSubsystemAccountTravelTokenDisplayFragment, WSSubsystemAccountTravelTokenDisplayType } from "./WSSubsystemAccountTravelTokenDisplay.js";
import WSSubsystemAccountVirtualCardTravelTokenDisplay, { WSSubsystemAccountVirtualCardTravelTokenDisplayType } from "./WSSubsystemAccountVirtualCardTravelTokenDisplay.js";

// 2.6.18 Travel Token Types
// Travel Token Type Description
export const mobile = "Mobile";
export const bankcard = "Bankcard";
export const smartcard = "Smartcard";
export const virtualCard = "VirtualCard";

// According to NIS doc we should expect tokenType as “VirtualCard”. 
// but we are getting back OpenTransitVirtual
// https://reflexions.slack.com/archives/CCF68M49M/p1657574291682959
export const openTransitVirtual = "OpenTransitVirtual";
export const openTransitRegular = "OpenTransitRegular";
export const openTransitTemporary = "OpenTransitTemporary";

export const TRAVEL_TOKEN_TYPES = {
	mobile,
	bankcard,
	smartcard,
	virtualCard,
	openTransitVirtual,
	openTransitRegular,
	openTransitTemporary,
};

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */
export default class WSSubsystemAccountTravelTokenDisplayFactory {
	static fromBackoffice(data) {
		switch (data.tokenType) {
			case TRAVEL_TOKEN_TYPES.bankcard:
				return WSSubsystemAccountBankcardTravelTokenDisplay.fromBackoffice(data);
			case TRAVEL_TOKEN_TYPES.smartcard:
				return WSSubsystemAccountSmartcardTravelTokenDisplay.fromBackoffice(data);
			case TRAVEL_TOKEN_TYPES.virtualCard:
			case TRAVEL_TOKEN_TYPES.openTransitVirtual:
				return WSSubsystemAccountVirtualCardTravelTokenDisplay.fromBackoffice(data);
			default:
				return WSSubsystemAccountTravelTokenDisplay.fromBackoffice(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSSubsystemAccountTravelTokenDisplayFactoryInterface = [ `
	interface WSSubsystemAccountTravelTokenDisplayFactory {
		${WSSubsystemAccountTravelTokenDisplayFragment}
	}`,
];

export const WSSubsystemAccountTravelTokenDisplayFactoryTypes = [
	...WSSubsystemAccountVirtualCardTravelTokenDisplayType,
	...WSSubsystemAccountTravelTokenDisplayType,
	...WSSubsystemAccountBankcardTravelTokenDisplayType,
	...WSSubsystemAccountSmartcardTravelTokenDisplayType,
	...WSSubsystemAccountTravelTokenDisplayFactoryInterface,
];

export const WSSubsystemAccountTravelTokenDisplayFactoryResolvers = {
	WSSubsystemAccountTravelTokenDisplayFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType({ tokenType }) {
			switch (tokenType) {
				case TRAVEL_TOKEN_TYPES.bankcard:
					return "WSSubsystemAccountBankcardTravelTokenDisplay";
				case TRAVEL_TOKEN_TYPES.smartcard:
					return "WSSubsystemAccountSmartcardTravelTokenDisplay";
				case TRAVEL_TOKEN_TYPES.virtualCard:
				case TRAVEL_TOKEN_TYPES.openTransitVirtual:
					return "WSSubsystemAccountVirtualCardTravelTokenDisplay";
				default:
					return "WSSubsystemAccountTravelTokenDisplay";
			}
		},
	},
};
