import React,{ useMemo } from 'react';
import { Link } from 'react-router-dom';
import { stringify as qs_stringify } from 'qs';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList';
import CmsContentRenderer from 'components/data/CmsContentRenderer';

import Container from 'components/Container.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Toast, { TYPE_SUCCESS } from 'components/Toast.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import loginStages from "components/data/session-user/LoginStages.js";

import * as style from './ActionNav.module.css';
import { useLoginStage } from 'components/data/session-user/LoggingIn';

const cms = {
	retail: 'miscText.landing-toplink-find-retail',
	guest: 'miscText.landing-toplink-learnmore',
	card: 'miscText.landing-toplink-buy-card',
	successCTA: 'miscText.guest-forcereg-cardpurchase',
};


const ActionNav = () => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const { loginStage } = useLoginStage();

	// If the user is logged in we want to go to the Account Dashboard and not display the toast message for a new card
	const userIsLoggedIn = useMemo(() => loginStage === loginStages.loggedIn,[ loginStage ]);

	const links = useMemo(() => [
		{
			key: 'ActionNavLink-Retail',
			path: getPathByRoute(routeKeys.RetailLocations),
			text: cms.retail,
			isExternal: false,
			fallback: 'Find a sales location',
		},
		{
			key: 'ActionNavLink-Guest',
			path: `https://www.mbta.com/charlie/registration-benefits`,
			isExternal: true,
			text: cms.guest,
			fallback: 'Learn more about the new Charlie',
		},
		{
			key: 'ActionNavLink-Card',
			isExternal: false,
			path: {
				pathname: getPathByRoute(userIsLoggedIn ? routeKeys.PurchaseCharlieCard : routeKeys.Register),
				search: !userIsLoggedIn && qs_stringify({
					postSigninGoToPath: getPathByRoute(routeKeys.AccountCardSelection) + qs_stringify({
						purchaseCard: true,
					}, { addQueryPrefix: true }),
				}, { addQueryPrefix: true }),
			},
			text: cms.card,
			fallback: 'Buy Charlie Card online',
			onClick: () => !userIsLoggedIn && setToast(
				<Toast
					type={TYPE_SUCCESS}
					onClosed={ removeToast }
					title={<CmsContentRenderedInline contentKey={cms.successCTA}
						fallbackValue="After registering your Charlie account, you will be redirected to purchase a new Charlie Card."
					/>}
				/>
			),
		},
	],[ removeToast,setToast, userIsLoggedIn ]);

	return (
		<Container overrideClass={style.actionNav}>
			<CmsContentList list={Object.values(cms)}>{() => (
				<nav className={style.actionNavItems}
					data-qa="ActionNavMenu"
				>
					{links.map((link) =>
						link.isExternal
							? <a
								target="_blank"
								rel="noreferrer"
								key={link.key}
								href={link.path}
								className={style.actionNavLink}
								data-qa={link.key}
							>
								<CmsContentRenderer.Span contentKey={link.text}
									fallbackValue={link.fallback}
								/>
							</a>
							: <Link
								to={link.path}
								key={link.key}
								className={style.actionNavLink}
								data-qa={link.key}
								onClick={link?.onClick}
							>
								<CmsContentRenderer.Span contentKey={link.text}
									fallbackValue={link.fallback}
								/>
							</Link>
					)}
				</nav>
			)}</CmsContentList>
		</Container>
	);
};

export default ActionNav;
