import {
	find,
	filter,
	head,
	includes,
	isNull,
} from "lodash";


import PublicAppVars from "utils/PublicAppVars.js";
import { OPEN_TRANSIT_BANK_CARD } from "server/api-types/WSSubsystemAccountToken.js";
import { active, closed, suspended, TOKEN } from "utils/Constants.js";
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";
import { CARDTYPE_CHARLIE } from 'server/Constants.js';
import { getLast4Digits } from "components/card-icons/util.js";

// Takes a WSSubsystemAccountTravelTokenDisplay aka tokenInfo prop and returns a boolean.
export const isPrimaryTokenInfo = ({ maskedPan, masterTokenPartial }) => maskedPan === masterTokenPartial;

export const isPrimaryToken = token => isPrimaryTokenInfo(token.tokenInfo);

// maskedPan === primaryTokenPartial, that's the bank card linked to the transit account.
// Finds the primary token if one exists. If not, returns the first token.
// note that there can be multiple primaryTokenPartial in one TA if the first FPAN was reported lost/stolen
// or for Companion cards. Old tokens can have their primaryTokenPartial updated by a cron.
// (https://reflexions.slack.com/archives/GA82SPCTV/p1597339180337100?thread_ts=1597092506.305700&cid=GA82SPCTV)

// Per Alessandro (10/2): we should be using primaryTokenPartial of the last used token
// https://reflexions.slack.com/archives/GA82SPCTV/p1601656026144300


/**
 * @param   {WSSubsystemAccountToken[]} tokenList - an array of WSSubsystemAccountTokens
 * @returns {WSSubsystemAccountToken} - Current Travel Token information in the subsystem account.
 */
export const getOpenTransitCardPrimaryToken = tokenList => {
	// Per Alessandro look at token.status for OMNY card only, since we don't display the tokens like we do for PAYGO
	// https://reflexions.slack.com/archives/CA82SPCTV/p1628778800049700

	// On an OMNY card the tokens that are in Terminated state should be ignored. There would be only one token and it would be that state: Active, Suspended or Closed
	// https://reflexions.slack.com/archives/CA82SPCTV/p1628779342052800?thread_ts=1628778920.052600&cid=CA82SPCTV
	return find(tokenList, wsTransitAccountToken => includes([ active, suspended, closed ], wsTransitAccountToken.frontendStatus)) ?? null;
};

/**
 * @param   {WSSubsystemAccountToken[]} tokenList - an array of WSSubsystemAccountTokens
 * @returns {WSSubsystemAccountToken} - Current Travel Token information in the subsystem account.
 */
export const findPrimaryToken = tokenList => {
	// For open loop cards we want to just take the first token as primary
	// Ghost omny cards have no tokens so return null
	const wsSubsystemAccountToken = head(tokenList) ?? null;

	// For closed loop cards we want only take one token and it would be that state: Active, Suspended or Closed
	if (wsSubsystemAccountToken && wsSubsystemAccountToken?.mediaType === OPEN_TRANSIT_BANK_CARD) {
		return getOpenTransitCardPrimaryToken(tokenList);
	}

	return wsSubsystemAccountToken;
};

/**
 * @param   {WSSubsystemAccountDetailedInfo} subsystemAccountDetailedInfo - WSSubsystemAccountDetailedInfo containing accountStatus and tokens
 * @returns {boolean} - TRUE if the passes WSSubsystemAccountDetailedInfo is a ghost card
 */
// Per Alessandro https://reflexions.slack.com/archives/CA82SPCTV/p1629920004196200?thread_ts=1629907041.185200&cid=CA82SPCTV
export const isGhostCard = (subsystemAccountDetailedInfo) => {
	// a ghost is an account with either empty tokens or all terminated, Per Alessandro https://reflexions.slack.com/archives/CA82SPCTV/p1632846325374200?thread_ts=1632767768.330400&cid=CA82SPCTV
	return isNull(findPrimaryToken(subsystemAccountDetailedInfo.tokens)); // TA does not have a primary token, will return false for Open loop
};

/**
 * @param   {WSSubsystemAccountInfo[]} WSSubsystemAccountInfo - array of WSSubsystemAccountInfo
 * @returns {WSSubsystemAccountDetailedInfo[]} - Array of WSSubsystemAccountDetailedInfo for cards that are ghosts.
 */
export const findGhostCards = subsystemAccountInfoQ => filter(subsystemAccountInfoQ, function (wsSubsystemAccountInfo) { // GHOST CHECK
	return isGhostCard(wsSubsystemAccountInfo.subsystemAccountDetailedInfo);
});

/**
 * @param   {WSSubsystemAccountToken[]} tokenList - an array of WSSubsystemAccountTokens
 * @returns {WSSubsystemAccountTravelTokenDisplay} - Current Travel Token information in the subsystem account.
 */
export const getMasterTokenInfo = tokenList => {
	const primaryToken = findPrimaryToken(tokenList);

	return primaryToken?.tokenInfo ?? null;
};


export const maxCardsLimitReached = tokenCount => {
	return tokenCount >= PublicAppVars.MAX_LINKED_TRAVEL_CARDS;
};

/**
 * @param   {WSSubsystemAccountToken} wsSubsystemAccountToken - single WSSubsystemAccountToken object
 * @returns {string} - "<card-name> ... <last-four>"
 */
export const getTokenNicknameAndPan = (wsSubsystemAccountToken) => {
	const {
		tokenNickname,
		tokenType,
	} = wsSubsystemAccountToken?.tokenInfo ?? {};

	const cardName = tokenNickname ?? (tokenType === TRAVEL_TOKEN_TYPES.bankcard
		? wsSubsystemAccountToken.creditCardType
		: CARDTYPE_CHARLIE);

	const lastFour = getLast4Digits(wsSubsystemAccountToken.tokenInfo);

	return `${cardName} ... ${lastFour}`;
};

/**
 * @param   {WSSubsystemAccountToken[]} tokenList - an array of WSSubsystemAccountTokens
 * @returns {WSSubsystemAccountToken[]} - return the ordered list
 */
export const getOrderedTokens = (tokenList) => {
	const wsTokens = [];

	// It's possible for there to be multiple tokens on the TA.
	// This a configuration that would need to be setup by the BO.
	const activeTokens = tokenList.filter(token => token.status === TOKEN.STATUS.active) ?? [];

	if (activeTokens.length === 0) {
		return tokenList;
	}

	const nonActiveTokens = tokenList.filter(token => token.status !== TOKEN.STATUS.active);

	if (nonActiveTokens?.length > 0) {
		wsTokens.push(...nonActiveTokens);
	}

	// Add the Active token to the front of the array
	wsTokens.unshift(...activeTokens);

	return wsTokens;
};
