import React, { useEffect } from "react";
import {
	values,
} from 'lodash';
import cx from 'classnames';
import { RadioGroup } from '@headlessui/react';
import { useFormHelperContext } from 'utils/form-helper/FormHelperProvider.js';
import { useOptionsSelectedContext } from 'pages/account/card/replacement/OptionsSelectedProvider.js';
import { CARD_RESOLUTIONS } from 'pages/account/card/replacement/constants.js';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Input from 'components/forms/Input.js';

import * as style from 'pages/account/card/BlockOrReplaceFlow.module.css';
import * as forms from 'components/forms/Forms.module.css';

const cms = {
	subHeader: 'miscText.card-freeze-replace-type-subheader',
	newCard: 'miscText.card-freeze-replace-type-order',
	newCardDetails: 'miscText.card-freeze-replace-type-order-details',
	currentCard: 'miscText.card-freeze-replace-type-existing',
	currentCardDetails: 'miscText.card-freeze-replace-type-existing-details',
};

const resolutionsMapping = [
	{
		type: CARD_RESOLUTIONS.newCard,
		action: cms.newCard,
		actionFallback: 'Order Card',
		actionDetails: cms.newCardDetails,
		actionDetailsFallback: '<p>You will be redirected to order a replacement card. Once this is ordered, you won’t be able to use your existing card.</p>',
	},
	{
		type: CARD_RESOLUTIONS.currentCard,
		action: cms.currentCard,
		actionFallback: 'Use My Card',
		actionDetails: cms.currentCardDetails,
		actionDetailsFallback: '<p>Any items such as passes or balance already on the replacement card will be transferred over. </p>',
	},
];

const ResolutionOptions = () => {
	const { formHelper } = useFormHelperContext();
	const {
		resolutionType,
		setResolutionType,
	} = useOptionsSelectedContext();

	// If there is only 1 option we want to preselect it
	useEffect(() => {
		if (resolutionsMapping.length === 1 && resolutionType === '') {
			setResolutionType(resolutionsMapping[ 0 ].type);
		}
	}, [ resolutionType,setResolutionType ]);

	return (
		<CmsContentList list={values(cms)}>{() => (
			<section className={style.actionSelectionSection}>
				<RadioGroup
					value={resolutionType}
					className={forms.radioGroup}
					onChange={(value) => setResolutionType(value)}
				>
					<RadioGroup.Label>
						<CmsContentRenderer.P
							className={style.sectionHeader}
							contentKey={cms.subHeader}
							fallbackValue="Do you want to order a new card, or use a card you already have?"
						/>
					</RadioGroup.Label>

					<div className={forms.radioGroupOptions}>
						{resolutionsMapping.map(value =>
							<RadioGroup.Option
								key={value.type}
								value={value.type}
								checked={resolutionType === value.type}
								className={cx(forms.radioGroupOption)}
							>
								{({ checked }) => <>
									<CmsContentRenderer.P
										className={cx(forms.radioLabel, checked && forms.radioChecked)}
										contentKey={value.action}
										fallbackValue={value.actionFallback}
									/>
									<CmsContentRenderer.Div
										className={style.actionDetails}
										contentKey={value.actionDetails}
										fallbackValue={value.actionDetailsFallback}
									/>
								</>}
							</RadioGroup.Option>
						)}
					</div>
				</RadioGroup>
				<Input
					key={resolutionType}
					type="hidden"
					name='resolutionType'
					value={resolutionType}
					controlled={true}
					hideLabel={true}
					error={formHelper.getFieldError('resolutionType')}
				/>
			</section>
		)}</CmsContentList>
	);
};

export default ResolutionOptions;
