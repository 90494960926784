import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import * as style from './Breadcrumb.module.css';
import { Chevron } from 'components/icons/UI';
import { useGlobalBreadcrumbContext } from 'context/BreadcrumbProvider';
import { getPathByRoute  } from 'App';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';
import Container from 'components/Container.js';
import { MQ_TABLET, useMediaQueryMatches } from 'utils/Breakpoints';


export const BreadcrumbItem = ({ active, routeKey, cmsKey, fallbackValue, children, variables }) => {
	const value = children ? children : <CmsContentRenderedInline contentKey={cmsKey} fallbackValue={fallbackValue} />;
	 return  active ? value : (<Link className={style.breadcrumbItem} to={getPathByRoute(routeKey, { ...variables })} >{value}</Link>);
};


const Breadcrumb = () => {
	const { breadcrumbs } = useGlobalBreadcrumbContext();
	const isAtLeastTablet = useMediaQueryMatches(MQ_TABLET);

	const isBreadcrumbAvailable = isAtLeastTablet && breadcrumbs.length > 0;

	return isBreadcrumbAvailable ? (
		<Container>
			<nav>
				<ul className={style.breadcrumb}>
					{breadcrumbs.map((crumb, i) => {
						const isActive =  breadcrumbs.length - 1 === i;
						return (
							<li key={i} className={cx(style.breadcrumbItem, isActive && style.breadcrumbItemActive)}>
								{React.cloneElement(crumb, { active: isActive })}
								{breadcrumbs.length - 1 !== i && <Chevron overrideClass={style.arrowIcon} aria-hidden={true} />}
							</li>);
					})}
				</ul>
			</nav>
		</Container>
	): null;
};

export default Breadcrumb;
