import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import PreventDefault from "../../../utils/PreventDefault.js";

// Inspired by https://javascript.plainenglish.io/how-to-alert-a-user-before-leaving-a-page-in-react-a2858104ca94
const LeavePagePrompt = ({
	when = true,
	message,
	onUnload,
}) => {
	const alertUser = (event) => {
		event.preventDefault();

		// If you prevent default behavior in Mozilla Firefox prompt will always be shown
		// Chrome requires returnValue to be set
		event.returnValue = '';
	};

	// prompts the user before unloading the DOM.
	// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
	useEffect(() => {
		if (when) {
			window.addEventListener('beforeunload', alertUser);
			window.addEventListener('unload', onUnload);

			return () => {
				window.removeEventListener('beforeunload', alertUser);
				window.removeEventListener('unload', onUnload);
			};
		}
	}, [
		when,
		onUnload,
	]);

	return (
		<Prompt {...{
			when,
			message,
		}} />
	);
};

export default LeavePagePrompt;
