import React from 'react';
import cx from 'classnames';
import { useCookies } from 'react-cookie';
import { map, values } from 'lodash';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import { FullName } from "components/data/session-user/SessionUser.js";
import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { Avatar, SmallChevron } from 'components/Icon.js';
import { getfutureDate } from 'components/data/session-user/SessionCheck.js';
import * as dropdown from './CharlieDropdown.module.css';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { LINK_STATUSES } from "server/api-types/WSAlternateContactInfo.js";
import { GET_ALTERNATES_AND_MANAGED_CUSTOMERS } from "components/data/alternates/Alternates.query.js";
import { gql } from "@apollo/client";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { GET_ALTERNATE_ACCOUNT_FROM_SESSION } from "components/data/alternates/SessionAlternate.js";
import Dropdown, { DropdownItem, DropdownSection } from "components/Dropdown.js";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";
import { useLogoutContext } from "components/auth/Logout.js";

const cms = {
	logout: 'miscText.topmenu-account-logout',
	yourAccount: 'miscText.topmenu-account-you',
	manageCards: 'miscText.topmenu-account-dashboard',
	settings: 'miscText.topmenu-account-settings',
};

const SWITCH_TO_MANAGED_CUSTOMER = gql`
	mutation switchToManagedCustomer(
		$linkId: ID!
	) {
		CustomerMutationRoute {
			alternates {
				manageAlternate(linkId: $linkId) {
					id
				}
			}
		}
	}
`;

export const SWITCH_BACK = gql`
	mutation switchBack{
		CustomerMutationRoute {
			alternates {
				stopManagingAlternate {
					id
				}
			}
		}
	}
`;

export const USER_LOGGED_OUT_BANNER = 'user-logged-out-banner';

const AccountDropdownItem = ({ itemKey, mutation, routeKey, contentKey, ariaLabel, fallbackValue }) => {
	const props = mutation
		? {
			onClick: () => mutation().then(() => { window.location.href = getPathByRoute(routeKey); }),
		}
		: {
			to: getPathByRoute(routeKey),
		};

	return <DropdownItem itemKey={itemKey} overrideClass={dropdown.option}
		aria-label={ariaLabel} {...props}><CmsContentRenderer.Span {...{ contentKey, fallbackValue }} />
	</DropdownItem>;
};

const AccountDropdownSection = ({
	title,
	cmsContent,
	linkId,
	switchBackMutation,
	switchToMutation,
	isFirst = false,
}) => {
	const mutation = switchBackMutation
		|| (
			linkId
				? () => switchToMutation({ variables: { linkId } })
				: null
		);

	return <DropdownSection sectionKey={linkId || 'yourAccount'} overrideClass={dropdown.section} headingOverrideClass={dropdown.heading} label={title} isFirst={isFirst}>
		<AccountDropdownItem
			itemKey={`${title}-cards`}
			mutation={mutation}
			routeKey={routeKeys.AccountCardSelection}
			contentKey={cms.manageCards}
			ariaLabel={`${title} ${findContentOrFallback(cmsContent, cms.manageCards, 'Manage Cards')}`}
			fallbackValue={'Manage Cards'}
		/>
		<AccountDropdownItem
			itemKey={`${title}-settings`}
			mutation={mutation}
			routeKey={routeKeys.AccountPersonalInformation}
			contentKey={cms.settings}
			ariaLabel={`${title} ${findContentOrFallback(cmsContent, cms.settings, 'Account Settings')}`}
			fallbackValue={'Account Settings'}
		/>
	</DropdownSection>;
};

const getSortedManagedCustomerItems = (managedCustomers, currentManagedCustomerId) => {
	if (!currentManagedCustomerId) {
		return managedCustomers;
	}

	let currentlyManagedCustomer;
	const notCurrentlyManagedCustomers = managedCustomers.filter(
		(managedCustomer) => {
			if (managedCustomer.managedCustomerLinkInfo.managedCustomerId === currentManagedCustomerId) {
				currentlyManagedCustomer = managedCustomer;
				return false;
			}

			return true;
		},
	);

	if (!currentlyManagedCustomer) {
		throw new Error("Currently managed customer is not in alternate's managed customers");
	}

	return [
		currentlyManagedCustomer,
		...notCurrentlyManagedCustomers,
	];
};

const AccountSelectorDropdown = ({
	loading,
	sessionData,
	alternatesData,
	switchBackMutation,
	switchToManagedCustomerMutation,
	logoutAndClearCache,
	setCookie,
	cmsContent,
}) => {
	if (loading) {
		return <LoadingIcon size={SIZES.component} />;
	}

	const { CustomerRoute: { alternates: { managedCustomers } } } = alternatesData;

	const alternateSession = sessionData?.session?.alternateSession;

	const currentManagedCustomerId = alternateSession
		? sessionData?.session?.customerId
		: null;

	const sortedManagedCustomers = getSortedManagedCustomerItems(managedCustomers, currentManagedCustomerId);

	const managedCustomerSections = [
		...map(sortedManagedCustomers, ({
			managedCustomerLinkInfo: {
				firstName,
				lastName,
				linkId,
				managedCustomerId,
			},
		}, index) => (
			<AccountDropdownSection
				key={linkId}
				title={`${firstName} ${lastName}`}
				linkId={currentManagedCustomerId !== managedCustomerId && linkId}
				switchToMutation={switchToManagedCustomerMutation}
				cmsContent={cmsContent}
				isFirst={alternateSession && index === 0}
			/>
		)),
	];

	const yourAccountSection = <AccountDropdownSection
		key={'your_account'}
		title={findContentOrFallback(cmsContent, cms.yourAccount, 'Your Account')}
		switchBackMutation={alternateSession ? switchBackMutation : null}
		cmsContent={cmsContent}
		isFirst={!alternateSession}
	/>;

	const logoutSection = <DropdownItem
		key={'logout'}
		itemKey={'logout'}
		overrideClass={cx(dropdown.option, dropdown.logoutLink)}
		onClick={() => {
			logoutAndClearCache();

			const futureDate = getfutureDate();
			// https://www.npmjs.com/package/react-cookie#setname-value-options
			setCookie(USER_LOGGED_OUT_BANNER, futureDate.toISOString(), {
				expires: futureDate,
				path: '/',
			});
		}}>
		<CmsContentRenderer.Span contentKey={cms.logout}
			fallbackValue="Log out"
		/>
	</DropdownItem>;

	const sections = alternateSession
		? [ ...managedCustomerSections, yourAccountSection, logoutSection ]
		: [ yourAccountSection, ...managedCustomerSections, logoutSection ];

	return <>{sections}</>;
};

const AccountSelector = () => {
	const [ switchToManagedCustomerMutation, { loading: switchToManagedLoading } ] = useStandardMutation(SWITCH_TO_MANAGED_CUSTOMER);
	const [ switchBackMutation, { loading: switchBackLoading } ] = useStandardMutation(SWITCH_BACK);
	const [ , setCookie ] = useCookies([ USER_LOGGED_OUT_BANNER ]);

	const { data: alternatesData, loading: alternatesLoading } = useStdQuery(GET_ALTERNATES_AND_MANAGED_CUSTOMERS, {
		variables: {
			linkStatuses: [ LINK_STATUSES.accepted ],
		},
	});

	const { data: sessionData, loading: sessionLoading } = useStdQuery(GET_ALTERNATE_ACCOUNT_FROM_SESSION);

	const logoutAndClearCache = useLogoutContext();

	const loading = sessionLoading || alternatesLoading;
	const switchLoading = switchToManagedLoading || switchBackLoading;

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Dropdown
				as="nav"
				id="accountmenu"
				overrideClass={dropdown.container}
				overrideButtonClass={(isOpen) => cx(dropdown.toggle, isOpen ? dropdown.isOpen : '')}
				overrideOptionsClass={dropdown.options}
				label={switchLoading
					? <LoadingIcon />
					: <>
						<Avatar overrideClass={cx(dropdown.toggleIcon, dropdown.iconAvatar)} aria-hidden={true} />
						<span className={cx(dropdown.toggleText)}><FullName /></span>
						<SmallChevron overrideClass={dropdown.toggleArrow} aria-hidden={true} />
					</>
				}
			>
				<AccountSelectorDropdown
					{...{
						loading,
						sessionData,
						alternatesData,
						switchBackMutation,
						switchToManagedCustomerMutation,
						logoutAndClearCache,
						setCookie,
						cmsContent,
					}}
				/>
			</Dropdown>
		)}</CmsContentList>
	);
};

AccountSelector.propTypes = {};

export default AccountSelector;
