import React from 'react';
import { values } from 'lodash';


import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Toast from "components/Toast.js";
import { useGlobalToastsContext } from 'context/ToastProvider';

const cms = {
	confirmation: 'miscText.alternates-add-confirmation',
	resendConfirmation: 'miscText.alternates-pending-resend-confirmation',
};

const AddedAccountManagerSuccess = ({ email, resend = false }) => {
	const { removeToast } = useGlobalToastsContext();
	return (
		<CmsContentList list={values(cms)} sharedVariables={{ email }}>{() => (
			<Toast
				type="success"
				onClosed={removeToast}
				title={<CmsContentRenderer.Span
					contentKey={resend ? cms.resendConfirmation : cms.confirmation}
					fallbackValue={`${email} has been invited to manage your account.
						You will receive a confirmation when they accept or decline your invitation.`
					}
				/>}
			/>
		)}</CmsContentList>
	);
};

export default AddedAccountManagerSuccess;
