import {
	clone,
	includes,
} from "lodash";

// WSPaymentHistoryInfo paymentStatus (see constructor below for acceptable values)
export const PAYMENT_STATUS = {
	authorized: 'Authorized',
	confirmed: 'Confirmed',
	pending: 'Pending',
	pendingConfirmation: 'PendingConfirmation',
	successful: 'Successful',
	declined: 'Declined',
	failed: 'Failed',
	failedConfirmation: 'FailedConfirmation',
	
	// FailedAuthorization isn't in the WSPaymentHistoryInfo docs, but we get it
	// https://reflexions.slack.com/archives/C8KP2CGTZ/p1603904019061700?thread_ts=1603896926.054900&cid=C8KP2CGTZ
	failedAuthorization: 'FailedAuthorization',
	// seeing this in eil2 on customer/CA593726-82DA-EB11-B827-00505694D063/paymenthistory?startDateTime=2021-07-01&endDateTime=2021-07-30
	failedSpaceAuthorization: 'Failed Authorization',
	
	expired: 'Expired',
	reversed: 'Reversed',
	settled: 'Settled',
	open: 'open',
	noPayment: 'noPayment',
};

const FAILED_AUTH_STATUSES = [
	PAYMENT_STATUS.failedAuthorization,
	PAYMENT_STATUS.failedSpaceAuthorization,
];

const FAILED_PAYMENT_STATUSES = [
	PAYMENT_STATUS.declined,
	PAYMENT_STATUS.failed,
	PAYMENT_STATUS.failedConfirmation,
	...FAILED_AUTH_STATUSES,
];

export const isFailedPaymentStatus = (paymentStatus) => includes(FAILED_PAYMENT_STATUSES, paymentStatus);

export const isFailedAuthStatus = (paymentStatus) => includes(FAILED_AUTH_STATUSES, paymentStatus);

// 2.7.26.WSPaymentHistoryInfo
// This object represents the payment information for the payment history.
export default class WSPaymentHistoryInfo {
	constructor({
		authRefNbr = null,
		paymentType,
		paymentDateTime = null,
		paymentAmount = null,
		paymentReferenceNumber = null,
		paymentStatus = null,
	}) {

		// string(40)
		// (Conditionally-Required) A unique identifier for this
		// authorization.
		// NOTE: This identifier is not guaranteed to be unique
		// across credit card and account authorizations.
		// Required if paymentType is different than “No
		// Payment”
		this.authRefNbr = authRefNbr;

		// string(20)
		// (Required) The payment type.
		// See valid values in section 2.6.14 Payment Type.
		this.paymentType = paymentType;

		// dateTime
		// (Conditionally-Required) The timestamp of when the
		// payment was processed.
		// Required if paymentType is different than “No
		// Payment”
		this.paymentDateTime = paymentDateTime;

		// intint
		// (Conditionally-Required) The total payment amount.
		// Required if paymentType is different than “No
		// Payment”
		this.paymentAmount = paymentAmount;

		// string(40)
		// (Conditionally-Required) Reference number for the
		// order payment.
		// Required if paymentType is different than “No
		// Payment”
		this.paymentReferenceNumber = paymentReferenceNumber;

		// string(20)
		// (ConditionallyRequired) Indicates the status for the
		// payment.
		// Required if paymentType is different than “No
		// Payment”
		// Valid values are:
		// • Authorized
		// • Confirmed
		// • Pending
		// • PendingConfirmation
		// • Successful
		// • Declined
		// • Failed
		// • FailedConfirmation
		// • Expired
		// • Reversed
		// • Settled
		// • Open
		// • NoPayment
		this.paymentStatus = paymentStatus;
	}

	static fromBackoffice(data) {
		const result = new WSPaymentHistoryInfo(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSPaymentHistoryInfoFragment = `
	id: ID!
	authRefNbr: String
	paymentType: String!
	paymentDateTime: GraphQLDateTime
	paymentAmount: Int
	paymentReferenceNumber: String
	paymentStatus: String
`;

export const WSPaymentHistoryInfoType = [ `
	type WSPaymentHistoryInfo implements WSPaymentHistoryInfoFactory {
		${WSPaymentHistoryInfoFragment}
	}
` ];
