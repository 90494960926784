import React, {
	useState,
} from "react";
import {
	clone,
	find,
	get,
	map,
	values,
} from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from 'components/data/CmsContentRendered.js';
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';
import ReasonOptions, {
	REASON_TYPE_PARAM,
	ACTION_TYPE_PARAM,
	RESOLUTION_TYPE_PARAM,
	BLOCKED_REASON_MAPPING,
} from 'pages/account/card/replacement/ReasonOptions.js';
import ResolutionOptions from 'pages/account/card/replacement/ResolutionOptions.js';
import Button from 'components/Button.js';
import Input from 'components/forms/Input.js';

import { RadioGroup } from '@headlessui/react';
import { useOptionsSelectedContext } from 'pages/account/card/replacement/OptionsSelectedProvider.js';
import { FormHelperProvider, useFormHelperContext } from 'utils/form-helper/FormHelperProvider.js';

import {
	useLocation,
	Redirect,
} from "react-router-dom";

import { useApolloClient } from '@apollo/client';

import {
	object as yup_object,
	string as yup_string,
} from "yup";

import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import { parse } from "qs";

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import PreventDefault from 'utils/PreventDefault.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import FormHelper from 'utils/FormHelper.js';
import { SUBSYSTEM_ID, TOKEN } from 'utils/Constants';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { cancelAutoloadMutation } from 'pages/account/CancelReload.js';

import { RIDER_CLASSES, getTransitAccountRefetchQueries, useTransitAccount } from "components/data/transit-account/TransitAccount.js";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { USER_PAYMENT_METHODS_QUERY } from 'components/data/session-user/SessionUser.js';
import { getSessionTransitAccountRefetchQueries } from "components/data/transit-account/SessionTransitAccount.js";
import { useStepContext, STEP_OFFSET_PARAM } from 'context/StepContext.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { Lifecycles } from "libreact/lib/Lifecycles/index.js";

import {
	CARD_ACTIONS,
	CARD_RESOLUTIONS,
} from 'pages/account/card/replacement/constants.js';

import useFormHelper from 'utils/form-helper/useFormHelper.js';

import Toast from "components/Toast.js";

import WSMediaProduct from 'server/api-types/WSMediaProduct.js';
import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';
import { OPEN_TRANSIT_REGULAR_CARD } from 'server/api-types/WSSubsystemAccountToken.js';
import { useCartContext } from 'context/CartProvider.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import WSIssueMediaLineItem from 'server/api-types/WSIssueMediaLineItem.js';
import WSIssueSubsystemMedia from 'server/api-types/WSIssueSubsystemMedia.js';

import { GET_REPLACE_PAYMENT_METHODS } from 'components/data/order/replace/Paymentmethods.query.js';
import {
	BLOCK_OR_REPLACE_CARD,
	BYPASS_TO_REPLACEMENT,
	PURCHASE_REPLACEMENT,
	TRANSFER_REPLACEMENT,
} from 'pages/account/card/BlockOrReplaceFlow.js';
import { TOKEN_ACTION } from 'components/data/transit-account/subsystem/travel-token/TokenAction.mutation';
import { ComponentLoading } from 'components/icons/LoadingIcon.js';


import * as takeover from 'layouts/TakeOverLayout.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as style from 'pages/account/card/BlockOrReplaceFlow.module.css';


const cms = {
	description: 'miscText.card-freeze-header',
	subHeader: 'miscText.card-freeze-action-subheader',

	blockAction: 'miscText.card-freeze-action-freeze',
	blockActionDetails: 'miscHtml.card-freeze-description',

	replaceAction: 'miscText.card-freeze-action-replace',
	replaceActionDetails: 'miscHtml.card-freeze-action-replace-details',
	continueButton: 'miscText.card-freeze-submit',

	toastBlock: 'miscText.card-freeze-confirmation',
	required: 'miscText["errors.general.blankfield"]',
};

const getYupSchema = (step,actionType) => {
	const validations = {
		actionType: yup_string().required(cms.required),
	};

	if (step === BLOCK_OR_REPLACE_CARD) {
		validations[ 'reasonType' ] = yup_string().required(cms.required);

	}
	if (actionType === CARD_ACTIONS.replaceCard) {
		validations[ 'resolutionType' ] = yup_string().required(cms.required);
	}

	return yup_object().shape(validations);
};

const actionMapping = {
	[ CARD_ACTIONS.blockCard ]: {
		action: 'blockAction',
		actionFallback: 'Block card',
		actionDetails: 'blockActionDetails',
		actionDetailsFallback: '<p>You will not be able to use this card for travel while it is blocked. You can unblock this card it at any time.</p>',
	},
	[ CARD_ACTIONS.replaceCard ]: {
		action: 'replaceAction',
		actionFallback: 'Replace card',
		actionDetails: 'replaceActionDetails',
		actionDetailsFallback: '<p>You can order a new card as a replacement, or use a card already on your account.</p>',
	},
};

export const CardActionSelector = () => {
	const { formHelper } = useFormHelperContext();
	const {
		actionType, setActionType,
	} = useOptionsSelectedContext();
	return (
		<CmsContentList list={values(cms)}>{() => (
			<>
				<CmsContentRenderer.Div
					contentKey={cms.description}
					fallbackValue="<p>Block your card to prevent usage in the Charlie system. You can unblock this card, order a replacement card, or transfer balance and passes from this card to a new card. If your card was defective, you may <a href='/deep-link/contact-us'>contact us</a> to see if you're eligible for a free replacement.</p>"
				/>

				<section className={style.actionSelectionSection}>
					<RadioGroup value={actionType}
						onChange={(value) => setActionType(value)}
						className={forms.radioGroup}
					>
						<RadioGroup.Label>
							<CmsContentRenderer.P
								className={style.sectionHeader}
								contentKey={cms.subHeader}
								fallbackValue="Do you want to block your card, or replace it?"
							/>
						</RadioGroup.Label>

						<div className={forms.radioGroupOptions}>
							{map(actionMapping, (value, key) =>
								<RadioGroup.Option
									key={key}
									value={key}
									checked={actionType === key}
									className={cx(forms.radioGroupOption)}
								>
									{({ checked, active }) => (<>
										<CmsContentRenderer.P
											className={cx(forms.radioLabel, checked && forms.radioChecked)}
											contentKey={cms[ value.action ]}
											fallbackValue={value.actionFallback}
										/>

										<CmsContentRenderer.Div
											className={style.actionDetails}
											contentKey={cms[ value.actionDetails ]}
											fallbackValue={value.actionDetailsFallback}
										/>
									</>)}
								</RadioGroup.Option>
							)}
						</div>
					</RadioGroup>
					<Input
						key={actionType}
						type="hidden"
						name='actionType'
						value={actionType}
						controlled={true}
						hideLabel={true}
						error={formHelper.getFieldError('actionType')}
					/>
				</section>
				{(actionType === CARD_ACTIONS.replaceCard || actionType === CARD_ACTIONS.blockCard)
					? <ReasonOptions />
					: null}
				{actionType === CARD_ACTIONS.replaceCard
					? <ResolutionOptions />
					: null}
			</>
		)}</CmsContentList>
	);
};


const BlockOrReplaceSelect = () => {
	const location = useLocation();

	// Once we migrate to React-router 6 we can use the `useSearchParams` hook.
	const {
		[ REASON_TYPE_PARAM ]: seachParamReasonType = null,
		[ ACTION_TYPE_PARAM ]: seachParamActionType = null,
		[ RESOLUTION_TYPE_PARAM ]: seachParamResolutionType = null,
		[ STEP_OFFSET_PARAM ]: seachParamStepOffSet = '0', // string not int
	} = parse(location.search, { ignoreQueryPrefix: true });

	const stepOffSet = parseInt(seachParamStepOffSet, 10);

	const {
		actionType,
		reasonType,
		resolutionType,
	} = useOptionsSelectedContext();

	const transit_account_id = useTransitAccountIdContext();

	const {
		data: transitAccountQdata,
		loading,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id });

	const {
		loading: prodCatLoading,
		mediaOptions,
	} = useGetSubsystemProductCatalog({ riderClassId: RIDER_CLASSES.fullFare });

	const CharlieCard = prodCatLoading ? {} : find(mediaOptions, wsMediaProduct => wsMediaProduct?.mediaType === OPEN_TRANSIT_REGULAR_CARD);

	const {
		addProduct,
		saveShoppingCartId,
	} = useCartContext();
	const { initializeFundingSources } = useFundingSourcesContext();

	const [ getReplacePaymentMethods ] = useStandardMutation(GET_REPLACE_PAYMENT_METHODS, {
		variables: {
			issueMediaLineItems: [
				new WSIssueMediaLineItem({
					issueMedia: new WSIssueSubsystemMedia({
						type: "TransitAccountMedia",
						subsystem: SUBSYSTEM_ID,
						mediaType: CharlieCard.mediaType,
						feeAmount: CharlieCard.enablementFeeAmount,
						itemTotalAmount: CharlieCard.enablementFeeAmount,
						subsystemTokenType: CharlieCard.subsystemTokenType,
					}),
				}).toResolver(),
			],
		},
	});

	const { step, setStep } = useStepContext();
	const {
		formRef,
		formHelper,
		validationState, setValidationState,
		submitting, setSubmitting,
	} = useFormHelper({ getYupSchema: () => getYupSchema(step,actionType) });

	const formHelperContext = {
		formRef,
		formHelper,
		validationState, setValidationState,
		submitting, setSubmitting,
	};

	const [ redirect, setRedirect ] = useState(null);

	const subsystemAccountReference = useTransitAccountIdContext();
	const { setToast, removeToast } = useGlobalToastsContext();

	const apolloClient = useApolloClient();

	const onBlockSuccess = () => {
		setToast(
			<Toast
				type="success"
				title={<CmsContentRendered.Span
					contentKey={cms.toastBlock}
					fallbackValue={'Your card has been blocked. You can choose to resume use, replace, or terminate the card at any time.'}
				/>}
				onClosed={removeToast}
			/>,
		);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardSettings, {
					transit_account_id: subsystemAccountReference,
				}),
				state: { success: true },
			}} />,
		);
	};

	const kickoffSubmit = async () => {
		setSubmitting(true);

		// dont validate the form we are about to redirect using param values
		if (stepOffSet !== BYPASS_TO_REPLACEMENT) {
			try {
				await formHelper.startValidation(true);
			}
			catch (errorReport) {
				setSubmitting(false);
				// yup validation failed, but those errors are already in state
				formHelper.validationErrorHandler(errorReport);
				return;
			}
		}

		if (actionType === CARD_ACTIONS.replaceCard && resolutionType === CARD_RESOLUTIONS.currentCard) {
			setStep(TRANSFER_REPLACEMENT);
			return;
		}

		if (
			(actionType === CARD_ACTIONS.replaceCard && resolutionType === CARD_RESOLUTIONS.newCard) ||
			(seachParamActionType === CARD_ACTIONS.replaceCard && seachParamResolutionType === CARD_RESOLUTIONS.newCard)
		) {
			addProduct(
				new WSMediaProduct(CharlieCard).toResolver(),
				1,
			);

			try {
				const paymentMethodsRes = await getReplacePaymentMethods();

				const {
					shoppingCartId,
					loadProductLineItemFunds,
					fundingSources,
					restrictFunding,
				} = paymentMethodsRes.data.OrderRoute.getReplacePaymentmethods;

				saveShoppingCartId(shoppingCartId);
				initializeFundingSources({
					loadProductLineItemFunds,
					fundingSources, // might be empty
					restrictFunding,
					refetchFundingSources: async () => (await getReplacePaymentMethods()).data.OrderRoute.getReplacePaymentmethods,
				});
			}
			catch (errorReport) {
				// we're not redirecting anywhere. Prepare the form for the next submit.
				noticeError(null, levels.info, errorReport, `Get Payment Methods Failed`);
				formHelper.validationErrorHandler(errorReport);
				return;
			}
			finally {
				setSubmitting(false);
			}

			setStep(PURCHASE_REPLACEMENT);
			return;
		}

		if (actionType === CARD_ACTIONS.blockCard) {
			try {
				await formHelper.startValidation(true);
			}
			catch (errorReport) {
				setSubmitting(false);
				// yup validation failed, but those errors are already in state
				formHelper.validationErrorHandler(errorReport);
				return;
			}

			const primaryToken = findPrimaryToken(transitAccountQdata?.transitAccountQ.tokens);

			GoogleAnalytics.logEvent("User is attempting to block/unblock a Charlie Card");

			const variables = {
				tokenId: primaryToken.tokenId,
				tokenAction: TOKEN.ACTION.block,
				subsystemAccountReference,
				reasonCode: reasonType.reasonCode,
			};

			const { subscriptions } = transitAccountQdata?.transitAccountQ;
			if ( Boolean(subscriptions)) {
				const wsAutoloadSummaries = clone(subscriptions);
				do {
					// Consume wsAutoloadSummary at index 0
					const wsAutoloadSummary = wsAutoloadSummaries.shift();

					let response;
					try {

						response = await cancelAutoloadMutation(
							apolloClient,
							wsAutoloadSummary.subscriptionId,
							subsystemAccountReference,
						);
					}
					catch (errorReport) {
						noticeError(null, levels.info, errorReport, `Cancel reload subscription failed`);
						setValidationState({
							[ FormHelper.getStateErrorField('cancel-subscription') ]: (
								<CmsContentRenderer.Span
									contentKey={cms.blockAutoloadError}
									fallbackValue={'An error occurred while trying to cancel reload on this card. Try cancelling reload first.'}
								/>
							),
						});
						setSubmitting(false);

						// Error is consumed. Lets terminate the loop.
						return;
					}

					if (get(response, "data.OrderRoute.CancelSubscription.success", false)) {
						if (!wsAutoloadSummaries.length) {
							// We have no more wsAutoloadSummary to handle. End transaction.
							break;
						}

					}
					else {
						// Something broke. Terminate loop.
						break;
					}

				}
				while (wsAutoloadSummaries.length);
			}

			try {
				await graphqlErrorMiddleware(
					apolloClient.mutate({
						mutation: TOKEN_ACTION,
						variables,
						refetchQueries: [
							{ query: USER_PAYMENT_METHODS_QUERY },
							...getTransitAccountRefetchQueries(subsystemAccountReference),
							...getSessionTransitAccountRefetchQueries({ subsystemAccountReference }),
						],
					}));
			}
			catch (errorReport) {
				// we're not redirecting anywhere. Prepare the form for the next submit.
				noticeError(null, levels.info, errorReport, `Block Card Submit Failed`);
				formHelper.validationErrorHandler(errorReport);
				setSubmitting(false);
				return;
			}
			setSubmitting(false);

			onBlockSuccess();
		}
	};

	const didMount = () => {
		// We are unblocking, or replacing the card and need to bypass the first step, but still submit the form
		if (stepOffSet === BYPASS_TO_REPLACEMENT &&
			(values(BLOCKED_REASON_MAPPING).find((reasonObj => reasonObj.reasonCode === seachParamReasonType)) ||
			seachParamActionType === CARD_ACTIONS.replaceCard)) {
			kickoffSubmit();
		};
	};

	// We are waiting for hook data to return
	// OR
	// the User has bypassed step 1 and we have submitted the form once it mounted.
	if (loading || prodCatLoading ||
		(stepOffSet === BYPASS_TO_REPLACEMENT && submitting)) {
		return <ComponentLoading />;
	}

	if (redirect) {
		return redirect;
	};

	return (
		<CmsContentList list={values(cms)}>{() => (
			<FormHelperProvider {...{ formHelperContext }}>
				<form
					data-qa="BlockOrReplaceTakeoverForm"
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					<div className={takeover.mainContent}>
						<div className={takeover.mainColumn}>
							<CardActionSelector />

							<div className={forms.actions}>
								<Lifecycles {...{ didMount }}>
									<Button
										isPrimary
										additionalClassNames={forms.action}
										type="submit"
										submitting={submitting}
									>
										<CmsContentRenderer.Span
											contentKey={cms.continueButton}
											fallbackValue="Continue"
										/>
									</Button>
								</Lifecycles>
							</div>
						</div>
					</div>
				</form>
			</FormHelperProvider>
		)}</CmsContentList>
	);
};

export default BlockOrReplaceSelect;
