// Table 2-318. WSAvsDataUpdate Field Descriptions
// Used in 2.9.26 customer/<customer-id>/fundingsource/<fundingsource-id> PATCH

export default class WSAvsDataUpdate {
	constructor({
		address1 = null,
		country = null,
		postalCode = null,
	}) {
		// string(50)
		// (Optional)  First line of the address.
		// The value cannot be removed.
		this.address1 = address1;

		// string(2)
		// Standard ISO 2-letter country code. e.g. US, AU, GB, etc.
		// (ISO 3166-1 alpha-2 standard)
		// (Conditionally-Required)  See section Error! Reference source not found. for
		// allowable values. Required when postalCode is specified.
		this.country = country;

		//  string(10)
		// (Conditionally-Required)  Valid values are country specific. For example,
		// for U.S. addresses, the postal code must be exactly 5 digits. Required when
		// country is specified. The value cannot be removed.
		this.postalCode = postalCode;
	}

	static fromBackoffice(data) {
		const result = new WSAvsDataUpdate(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

const WSAvsDataUpdateFragment = `
	address1: String
	country: String
	postalCode: String
`;

// address1 no longer required as of R11
export const WSAvsDataUpdateType = [ `
	type WSAvsDataUpdate {
		${WSAvsDataUpdateFragment}
	}
` ];

export const InputWSAvsDataUpdateType = [ `
	input InputWSAvsDataUpdate {
		${WSAvsDataUpdateFragment}
	}
` ];
