(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@apollo/client"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["@apollo/client", "react"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@apollo/client"), require("react")) : factory(root["@apollo/client"], root["react"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__apollo_client__, __WEBPACK_EXTERNAL_MODULE_react__) {
return 