import React, {
	createContext,
	useContext,
} from "react";
import { useParams } from "react-router-dom";

const ProgramIdContext = createContext(null);

/** @return string|null */
export const useProgramIdContext = () => useContext(ProgramIdContext);

export const ProgramIdContextProvider = ({ children }) => {
	const params = useParams();

	const {
		program_id,
	} = params;

	return (
		<ProgramIdContext.Provider value={parseInt(program_id, 10)}>
			{children}
		</ProgramIdContext.Provider>
	);
};

export default ProgramIdContext;
