import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import Tooltip from "components/Tooltip";
import React from 'react';
import * as forms from 'components/forms/Forms.module.css';

const cms = {
	emvCardNumTooltip: 'miscText["addcard-emv-cardnumber.tooltip"]',
};

const CardNumberToolTip = () =>
	<Tooltip
		tooltipId={"bankcardNumberTooltip"}
		overrideClass={forms.toolTipIcon}
	>
		<div className={forms.cardNumberToolTip}>
			<CmsContentRenderedInline
				contentKey={cms.emvCardNumTooltip}
				fallbackValue={"The 16-19 digit card number found on the physical card that you added to your mobile wallet."}
			/>
		</div>
	</Tooltip>;

export default CardNumberToolTip;
