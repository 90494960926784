import React, {
	useCallback,
	useContext,
	useState,
} from "react";
import {
	levels,
	log,
} from "../utils/Logger.js";
import { slice } from 'lodash';

const defaultMethod = () => log(null, levels.error, "ToastContext was used before it was ready");

export const ToastContext = React.createContext({
	toasts: [], // array of toasts
	setToast: defaultMethod, // adder method
	removeToast: defaultMethod, // shift a toast from the list
});

export const useGlobalToastsContext = () => useContext(ToastContext);

const ToastProvider = ({ children }) => {
	const [ toasts, setToasts ] = useState([]);

	const setToast = useCallback(toast => {
		setToasts(toasts.concat(toast));
	}, [ toasts ]);

	const removeToast = (event) => {
		event && event.preventDefault();

		setToasts(slice(toasts, 1));
	};

	return (
		<ToastContext.Provider value={{ toasts, setToast, removeToast }}>
			{children}
		</ToastContext.Provider>
	);
};
export default ToastProvider;
