import React from 'react';
import { values } from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import {
	POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID,
	cookieOptions,
} from "components/data/session-user/PostSigninGoToPath.js";

import Toast from "components/Toast.js";
import Modal from 'components/Modal.js';
import Button from "components/Button.js";

import * as style from 'components/modals/ResumeUseCard.module.css';
import { useCookies } from 'react-cookie';
import { useGlobalToastsContext } from 'context/ToastProvider';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';
import { useModalContext } from 'context/ModalProvider';

const cms = {
	replace: 'miscText.guest-sidebar-replace-cta',
	modalText: "miscHtml.guest-replace-modal-description",
	continueBtn: "miscText.guest-replace-modal-continue",
	cancelBtn: "miscText.general-modal-cancel",
	replaceCardToast: 'miscText.guest-forcereg-replace',
};

const ConfirmReplaceCard = ({
	subsystemAccountReference,
	onConfirm,
}) => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ _, setCookie ] = useCookies();
	const { setModal } = useModalContext();

	const onCloseModal = () => setModal(null);

	// Route the user to the CardOverview & display the appropriate banner once registration is complete.
	const handleReplaceCookie = () => {
		setCookie(POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID, subsystemAccountReference, cookieOptions);

		setToast(<Toast
			type="success"
			title={<CmsContentRenderedInline
				contentKey={cms.replaceCardToast}
				fallbackValue="After registering your Charlie account, you will be prompted to order your replacement Charlie Card."
			/>}
			onClosed={removeToast}
		/>);

		onCloseModal();
		onConfirm();
	};

	return (
		<CmsContentList list={values(cms)}>{() =>
			<Modal title={
				<CmsContentRenderer
					contentKey={cms.replace}
					fallbackValue={"Replace your Charlie Card"}
				/>
			}>
				<CmsContentRenderer.P rawHtml
					className={style.boldStyle}
					contentKey={cms.modalText}
					fallbackValue={"<p>Did you receive a defective card? You can register for an account to replace online, and may qualify for a fee refund. You may also <a href='https://www.mbta.com/charlie/replace-card>Learn more</a> to inquire about a waiver.</p>"}
				/>
				<div className={style.actions}>
					<Button
						isPrimary
						type="button"
						onClick={onCloseModal}
					>
						<CmsContentRenderer.Span contentKey={cms.cancelBtn}
							fallbackValue="Cancel"
						/>
					</Button>
					<Button
						isPrimary
						type="submit"
						onClick={handleReplaceCookie}
					>
						<CmsContentRenderer.Span contentKey={cms.continueBtn}
							fallbackValue="Register and replace online"
						/>
					</Button>

				</div>
			</Modal>
		}</CmsContentList>
	);
};

export default ConfirmReplaceCard;
