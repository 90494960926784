import { gql } from "@apollo/client";
import StdQuery from "./StdQuery.js";
import React from "react";

export const GET_CMS_VERSION = gql`query GET_CMS_VERSION {
	cmsVersion
}`;
export const GetCmsVersion = (props) => (
	<StdQuery query={GET_CMS_VERSION}>
		{(data) => props.children({
			...data,
			cmsVersion: data.data.cmsVersion,
		})}
	</StdQuery>
);
