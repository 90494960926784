import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_WARNING } from 'components/Banner.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import Button, {
	Secondary,
} from 'components/Button.js';
import * as style from './RegisterForAccount.module.css';


const cms = {
	 title: 'miscText["dashboard-banner-upgrade.title"]',
	 description: 'miscText["dashboard-banner-upgrade.description"]',
	 cta: 'miscText["dashboard-banner-upgrade.cta"]',
	 link: 'miscText["dashboard-banner-upgrade.cta-url"]',
	 btn: 'miscText["dashboard-banner-upgrade.button"]',
};

/**
 * This is the full width banner that appears above the Card Details on
 * Card Overview page after user has registered and tried to upgrade a Charlie Card as a Guest
 */

const UpgradeCard = ({ transit_account_id }) => {
	return (
		<CmsContentList list={values(cms)}>{() => (
			<Banner
				type={TYPE_WARNING}
				title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Upgrade Your Card" />}
				body={
					<>
						<CmsContentRenderer.P contentKey={cms.description} fallbackValue="As a registered user, you can now upgrade your card." />
						<CmsContentRenderer.P contentKey={cms.cta} fallbackValue="Learn more about card upgrade" />
					</>
				}
				link={
					<a href={"https://www.mbta.com/charlie/upgrade"} className={style.registerButtonOverride}>
						<CmsContentRenderer.Span
							contentKey={cms.link}
							fallbackValue="https://www.mbta.com/charlie/upgrade"
						/>
					</a>
				}
				rightSide={
					<Button
						to={getPathByRoute(routeKeys.AccountCardUpgrade, { transit_account_id })}
						typeStyle={Secondary}
					>
						<CmsContentRenderer.Span contentKey={cms.btn} fallbackValue="Upgrade Your Card" />
					</Button>
				}
			/>
		)}</CmsContentList>
	);
};

UpgradeCard.propTypes = {
	transit_account_id: PropTypes.string.isRequired,
};


export default UpgradeCard;
