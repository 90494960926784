const routeKeys = {
	Home: 'Home',
	RetailLocations: 'RetailLocations',
	ContactUs: 'ContactUs',

	DLPurchaseNewCharlieCard: 'PurchaseNewCharlieCard',

	ViewTransactionHistory: 'ViewTransactionHistory',
	GuestCardOverview: 'GuestCardOverview',

	EulaById: 'eulaById',
	EulasPending: 'eulasPending',
	TermsOfService: 'termsOfService',

	SignIn: 'SignIn',
	Register: 'Register',
	RegisterPersonalInformation: 'PersonalInformation',
	RegisterSecurityInformation: 'SecurityInformation',
	RegisterAccountCreated: 'AccountCreated',
	RegisterVerify: 'RegisterVerify',

	ForgotPassword: 'ForgotPassword',
	UnlockAccount: 'UnlockAccount',
	UnlockAccountTokenVerify: 'UnlockAccountTokenVerify',
	ResetPassword: 'ResetPassword',
	TwoFactorAuth: 'TwoFactorAuth',
	TwoFactorAuthVerify: 'TwoFactorAuthVerify',

	AccountCardSelection: 'AccountCardSelection',

	AccountPersonalInformation: 'AccountPersonalInformation',
	AccountSecurity: 'AccountSecurity',
	AccountNotifications: 'AccountNotifications',
	AccountPaymentMethods: 'AccountPaymentMethods',
	AccountAddPaymentMethod: 'AccountAddPaymentMethod',

	AccountCardOverview: 'AccountCardOverview',
	AccountCardSettings: 'AccountCardSettings',
	AccountCardTransferProducts: 'AccountCardTransferProducts',
	AccountCardRideHistory: 'AccountCardRideHistory',
	AccountCardPurchaseHistory: 'AccountCardPurchaseHistory',
	AccountCardServices: 'AccountCardServices',
	AccountCardReloadBalance: 'AccountCardReloadBalance',
	AccountCardReloadPass: 'AccountCardReloadPass',
	AccountCardBlockOrReplace: 'AccountCardBlockOrReplace',
	AccountCardUpgrade: 'AccountCardUpgrade',
	ContactUsTransitAccount: 'ContactUsTransitAccount',
	AccountDisputeRide: 'AccountDisputeRide',
	AccountDisputePurchase: 'AccountDisputePurchase',
	VerifyUpdateEmail: 'VerifyUpdateEmail',

	LinkCharlieCard: 'LinkCharlieCard',
	DeLinkCard: 'DeLinkCard',
	AddContactlessEMVCard: 'AddContactlessEMVCard',
	AddMobileCard: 'AddMobileCard',

	AccountPurchaseProduct: 'AccountPurchaseProduct',
	PurchaseCharlieCard: 'PurchaseCharlieCard',

	AutoloadBalance: 'AutoloadBalance',
	AutoloadPass: 'AutoloadPass',

	NotFound: 'NotFound',

	// Utility
	pdfExport: "PdfExport",
	DirectLink: "DirectLink",


};

export default routeKeys;
