import { gql , useQuery } from "@apollo/client";
import React from 'react';
import {
	get,
	find,
	map,
	filter,
} from "lodash";
import { WSAddressExtFragment } from "./Address.js";
import WSAddressExt from "server/api-types/WSAddressExt.js";
import StdQuery from 'components/data/StdQuery.js';
import PublicAppVars from "utils/PublicAppVars.js";

export const GET_CUSTOMER_ADDRESSES = gql`
	query GET_CUSTOMER_ADDRESSES {
		session {
			id
			customer {
				addresses {
					${WSAddressExtFragment}
				}
				id
			}
		}
	}
`;

export const GET_CUSTOMER_AND_ALTERNATE_ADDRESSES = gql`
	query GET_CUSTOMER_ADDRESSES {
		session {
			id
			customer {
				addresses {
					${WSAddressExtFragment}
				}
				id
			}
			alternateSession {
				customer {
					contact {
                        name {
                            firstName
                            lastName
                        }
                    }
                    addresses {
                        ${WSAddressExtFragment}
                    }
                }
				id
			}
		}
	}
`;

export const GET_CUSTOMER_ADDRESSES_AND_CONTACT = gql`
	query GET_CUSTOMER_ADDRESSES_AND_CONTACT {
		session {
			id
			customer {
				addresses {
					${WSAddressExtFragment}
				}
				id
				contact {
					id
					address {
						id
					}
				}
			}
		}
	}
`;

export const GET_B2B_CUSTOMER_ADDRESSES = gql`
	query GET_B2B_CUSTOMER_ADDRESSES {
		session {
			id
			b2bCustomer {
				addresses {
					${WSAddressExtFragment}
				}
				id
			}
		}
	}
`;

export const findWSAddressExtByIdOrNew = (wsAddressExts, addressId) => {
	const found = find(
		wsAddressExts,
		wsAddressExt => wsAddressExt.addressId === addressId,
	);

	return new WSAddressExt(found ?? {});
};

const filterOutContactAddress = (wsAddresses, wsContactAddress) => {
	return filter(wsAddresses, wsAddress => wsAddress?.addressId !== wsContactAddress?.address?.id);
};

const addressQuery = PublicAppVars.isB2B
	? GET_B2B_CUSTOMER_ADDRESSES
	: GET_CUSTOMER_ADDRESSES;

export const useAddresses = () => {
	const { data: sessionAddresses, ...result } = useQuery(GET_CUSTOMER_ADDRESSES);
	const wsAddressExts = get(sessionAddresses, 'session.customer.addresses', []);

	return {
		...result,
		wsAddressExts,
	};
};


const Addresses = ({
	children,
	variables = {},
	includeAlternateAddresses = false,
}) => {

	return <StdQuery query={includeAlternateAddresses ? GET_CUSTOMER_AND_ALTERNATE_ADDRESSES : addressQuery} {...variables}>
		{(queryResult) => {
			const wsAddresses = PublicAppVars.isB2B
				? queryResult.data?.session.b2bCustomer.addresses
				: queryResult.data?.session.customer?.addresses;

			const wsAlternateAddresses = (includeAlternateAddresses && queryResult.data?.session.alternateSession)
				? queryResult.data?.session.alternateSession?.customer.addresses
				: [];

			// as per Alessandro
			// https://reflexions.slack.com/archives/CA82SPCTV/p1621616944092000?thread_ts=1621434910.034200&cid=CA82SPCTV
			// since for New York we only get one contact-id so we safe to use
			const wsCustomerContactInfo = !PublicAppVars.isB2B && queryResult.data?.session.customer?.contact;
			const wsAlternateCustomerContactInfo = queryResult.data?.session.alternateSession?.customer.context;

			// filter out profile address do not overlap with payment methods
			// see full conversation https://reflexions.slack.com/archives/CA82SPCTV/p1629907621188200
			const filteredWsAddresses = [
				...filterOutContactAddress(wsAddresses, wsCustomerContactInfo),
				...filterOutContactAddress(wsAlternateAddresses, wsAlternateCustomerContactInfo),
			];

			const wsAddressExts = map(PublicAppVars.isB2B ? wsAddresses : filteredWsAddresses, serializedWsAddressExt => WSAddressExt.fromSerialized(serializedWsAddressExt));
			return children(wsAddressExts, queryResult.data);
		}}
	</StdQuery>;
};


export default Addresses;
