import React, {
	useState,
} from "react";
import cx from 'classnames';

import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';

import TakeOverLayout from "../../layouts/TakeOverLayout.js";
import Input from "../../components/forms/Input.js";
import Button, { Primary } from "components/Button.js";

import CmsContentRenderer, { findContentOrFallback, getContentOrFallback } from 'components/data/CmsContentRenderer.js';
import { useCmsContent } from "components/data/CmsContent.js";
import { useCmsContentList } from "components/data/CmsContentList.js";
import CmsContentListContext from "components/data/CmsContentListContext.js";
import { Redirect } from "react-router-dom";
import { RadioGroup } from '@headlessui/react';
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import PreventDefault from "utils/PreventDefault.js";
import {
	USER_DEBT_QUERY,
	USER_PAYMENT_METHODS_QUERY,
} from "components/data/session-user/SessionUser.js";
import { LINK_TRAVEL_TOKEN } from "../../graphql-queries/TravelTokens.js";
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import FormHelperProvider from 'utils/form-helper/FormHelperProvider.js';
import useCardLast4Digits from "components/data/hooks/cardLast4Digits.js";
import { APPLE_PAY, MOBILE_CARD_WALLET } from "utils/Constants.js";
import { addYupMethod } from "utils/YupValidators.js";
import MaskedInput from "components/forms/MaskedInput.js";
import PublicAppVars from "utils/PublicAppVars.js";
import useCreditCardType from 'components/data/hooks/useCreditCardType.js';
import { nickNameMaxLengthCMSKey } from "components/modals/EditCardNickname.js";

import * as tabs from '../../styles/Tabs.module.css';
import * as contactlessCardstyles from "./AddContactlessEMVCard.module.css";
import * as style from './AddMobileCard.module.css';
import * as forms from '../../components/forms/Forms.module.css';
import * as cardStyles from './LinkCharlieCard.module.css';

import CardNumberToolTip from "components/tooltips/CardNumber.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import Toast from "components/Toast.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import { getCreditCardType, sliceMaskedPAN } from "utils/FormatHelpers.js";

const getYupSchema = () => {
	addYupMethod("creditCard");

	const validations = {
		cardNumber: yup_string()
			.required("Please enter a card number.")
			.trim()
			.creditCard(),
		cardExpiryMMYY: yup_string()
			.required("Please enter an exp date.")
			.trim(),
		cardNickname: yup_string()
			.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, nickNameMaxLengthCMSKey),
	};

	return yup_object().shape(validations);
};

const cms = {
	header: 'miscText.addcard-wallet-header',
	subHeader: 'miscText.addcard-wallet-subheader',
	cardNumberLabel: 'miscText["addcard-emv-cardnumber.label"]',
	cardExpirationDate: 'miscText["addcard-emv-expiry.label"]',
	cardNickNameLabel: 'miscText["addcard-emv-nickname.label"]',
	cardNickNamePlaceHolder: 'miscText["addcard-wallet-apple-nickname.placeholder"]',

	cardNickDescriptionLabel: 'miscText["addcard-emv-nickname.description"]',
	cardNickNameMobilePlaceHolder: 'miscText["addcard-emv-nickname.placeholder"]',

	cardStandSwitchLabel: 'miscHtml.addcard-wallet-switch',
	cardSubmitLabel: 'miscText.addcard-wallet-submit',
	cardTypeMobileWalletLabel: 'miscText.addcard-wallet-emv',
	cardTypeAppleWalletLabel: 'miscText.addcard-wallet-apple',

	successToast: "miscText.addcard-wallet-confirmation",
};

const mobileOptionsTypeCms = [
	{ key: cms.cardTypeMobileWalletLabel, value: MOBILE_CARD_WALLET, fallbackValue: 'Credit Card in my Mobile Device' },
	{ key: cms.cardTypeAppleWalletLabel, value: APPLE_PAY, fallbackValue: 'Apple Card' },
];

const LinkCharlieCard = ({ }) => {
	const [ redirect, setRedirect ] = useState(null);
	const [ mobileCardType, setMobileCardType ] = useState(MOBILE_CARD_WALLET);

	const { cardLast4Digits, onBlurCardNumber } = useCardLast4Digits();
	const { creditCardType, onBlurCreditCardType } = useCreditCardType();
	const { setToast, removeToast } = useGlobalToastsContext();

	const cmsContentList = useCmsContentList({
		list: Object.values(cms),
	});

	const [ linkTravelTokenMutation ] = useStandardMutation(LINK_TRAVEL_TOKEN, {
		refetchQueries: [
			{ query: USER_DEBT_QUERY },
			{ query: USER_PAYMENT_METHODS_QUERY },
		],
	});

	// minimize input flickering when creditCardType updates and the content is refetched
	const cmsContent = useCmsContent(
		// request
		{
			key: cms.cardNickNamePlaceHolder,
			isTemplate: true,
			variables: { cardtype: creditCardType },
		},
		// fallbackValue
		creditCardType
	);

	const formHelperContext = useFormHelper({ getYupSchema });
	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = formHelperContext;

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true, null);
		}
		catch (errorReport) {
			//validation failed
			formHelper.validationErrorHandler(errorReport);
			return;
		}
		finally {
			setSubmitting(false);
		}

		GoogleAnalytics.logEvent("User is attempting to link a mobile device");

		const cardNickname = validated.cardNickname
			? validated.cardNickname
			: (creditCardType + " " + sliceMaskedPAN(validated.cardNumber));

		const variables = {
			travelToken: {
				...validated,
				cardNickname,
				nameOnCard: 'Sample Card Holder Name',
				cardExpiryMMYY: validated.cardExpiryMMYY.replace('/',''),
				tokenType: TRAVEL_TOKEN_TYPES.bankcard,
			},
		};

		try {
			await graphqlErrorMiddleware(linkTravelTokenMutation({ variables }));
		}
		catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Link Smartcard Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}
		finally {
			setSubmitting(false);
		}

		setToast(
			<Toast
				type={"success"	}
				title={<CmsContentRenderedInline
					contentKey={cms.successToast}
					fallbackValue={`{{nickname}} has been added to your account.`}
					variables={{ nickname: cardNickname }}
				/>}
				onClosed={removeToast}
			/>
		);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardSelection),
			}} />
		);
	};


	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentListContext.Provider value={cmsContentList.cmsContent}>
			<TakeOverLayout
				title={<CmsContentRenderer.Span
					contentKey={cms.header}
					fallbackValue="Link a Card in Your Mobile Device to Your Account"
				/>}
				showCancel
			>
				<div className={tabs.mainContent} data-qa="LinkCardContent">
					<div className={tabs.main}>
						<FormHelperProvider {...{ formHelperContext }}>
							<form
								method="post"
								data-qa="addPaymentMethodForm"
								ref={formRef}
								onSubmit={PreventDefault(kickoffSubmit)}
							>
								{formHelper.getFieldErrorJsx('')}
								<div className={tabs.col}>
									<CmsContentRenderer.H2
										contentKey={cms.subHeader}
										className={tabs.title}
										fallbackValue="Enter the details of your card"
										data-qa="LinkCardHeader"
									/>

									{PublicAppVars.ENABLE_MOBILE_FARE_CARD &&
										<RadioGroup
											value={mobileCardType}
											onChange={setMobileCardType}
											className={cx(forms.radioGroup, style.radioCharlieType)}
										>
											<div className={forms.radioGroupOptions}>
												{mobileOptionsTypeCms.map(({ key, fallbackValue, value }) =>
													<RadioGroup.Option
														key={key}
														value={value}
														className={style.radioCharlieOption}
													>
														{({ checked }) =>
															<div className={cx(forms.radioLabel, checked && forms.radioChecked)}>
																<CmsContentRenderer.Span
																	contentKey={key}
																	className={style.radioCharlieLabel}
																	fallbackValue={fallbackValue}
																/>
															</div>
														}
													</RadioGroup.Option>
												)}
											</div>
										</RadioGroup>
									}
									<Input
										type="text"
										inputMode="numeric"
										pattern="[0-9]{13,19}"
										name="cardNumber"
										label={
											<>
												<CmsContentRenderer.Span
													contentKey={cms.cardNumberLabel}
													fallbackValue="Card Number"
												/>
												<span>
													<CardNumberToolTip />
												</span>
											</>
										}
										data-qa="cardNumber-input"
										error={formHelper.getFieldError("cardNumber")}
										onBlur={() => {
											onBlurCardNumber(formHelper);
											mobileCardType === MOBILE_CARD_WALLET && onBlurCreditCardType(formHelper);
										}}
									/>
									<div className={cx(forms.ccRow)}>
										{mobileCardType === MOBILE_CARD_WALLET && (
											<>
												<MaskedInput
													type="text"
													label={
														<CmsContentRenderer.Span
															contentKey={cms.cardExpirationDate}
															fallbackValue="Card Expiration Date"
														/>}
													name="cardExpiryMMYY"
													placeholder="MM / YY"
													onChange={() => {
														const element = event?.target;
														const fieldName = element.name;

														if (formHelper.getFieldError(fieldName)) {
															formHelper.clearFieldErrors(fieldName);
														}
													}}
													overrideClass={contactlessCardstyles.expirationDate}
													options={{
														date: true,
														datePattern: [ "m", "y" ],
													}}
													data-qa="MobileCardExpireDate"
												/>
												{formHelper.getFieldErrorJsx('cardExpiryMMYY')}
											</>
										)}
									</div>
									<Input type="text"
										name="cardNickname"
										label={
											<div>
												<CmsContentRenderer.Div
													contentKey={cms.cardNickNameLabel}
													fallbackValue="Card Nickname" />
												<CmsContentRenderer.Div
													className={forms.ccText}
													contentKey={cms.cardNickDescriptionLabel}
													fallbackValue="Enter a nickname to help you distinguish between cards. The last 4 digits of your card will always display."
												/>
											</div>}
										labelClass={cardStyles.labelOverride}
										placeholder={
											mobileCardType === MOBILE_CARD_WALLET
												? getContentOrFallback({ content: cmsContent }, creditCardType)
												: findContentOrFallback(cmsContentList.cmsContent, cms.cardNickNamePlaceHolder, 'Numberless Card')
										}
										isOptional={true}
										data-qa="card-nick-name-input"
										error={formHelper.getFieldError('cardNickname')}
										maxLength={PublicAppVars.CARD_NICKNAME_MAXLENGTH}
										suffix={`...${cardLast4Digits}`}
										autocomplete="off"
									/>
								</div>

								<div className={tabs.actions}>
									<Button
										theme={Primary}
										additionalClassNames={tabs.actionBtn}
										data-qa="LinkCharlieCardBtn"
										{...{ submitting }}
									>
										<CmsContentRenderer.Span
											contentKey={cms.cardSubmitLabel}
											fallbackValue="Confirm card details" />
									</Button>
								</div>
							</form>
						</FormHelperProvider>

					</div>
					{PublicAppVars.ENABLE_NEW_CARD_PURCHASE ?
						<div className={tabs.sidebar}>
							<CmsContentRenderer
								contentKey={cms.cardStandSwitchLabel}
								rawHtml={true}
								fallbackValue={`
									<p>Do you need a new card?</p>
									<p><a href='/direct-link/link-charlie?option=2'>Order a new Charlie Card by mail</a></p>
								`}
							/>
						</div>
						: null
					}
				</div>
			</TakeOverLayout>
		</CmsContentListContext.Provider>
	);
};

export default LinkCharlieCard;
