import React, {
	Fragment,
	useState,
} from 'react';
import {
	useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
	filter,
	map,
	values,
} from 'lodash';
import { Tab } from '@headlessui/react';

import { HEADER_GO_BACK } from 'utils/Constants.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Container from 'components/Container.js';
import {
	Close,
	Dots,
	SmallCheck,
} from 'components/Icon.js';
import { tabsSecondary } from 'components/Tabs.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import CardIconAndNickname from 'components/card-icons/CardIconAndNickname.js';

import * as layout from 'layouts/TakeOverLayout.module.css';
import * as tabStyle from 'components/Tabs.module.css';
import PreventDefault from 'utils/PreventDefault';


const cms = {
	cancel: "miscText.general-modal-cancel",
};

/**
 * Generate the cancel button in the header, either with javascript and
 * `history.goBack()` or a specified page
 */
export const CancelButton = ({
	cancelLink = HEADER_GO_BACK,
	handleCancel,
}) => {
	const history = useHistory();

	const getToProp = () => cancelLink !== HEADER_GO_BACK
		? cancelLink
		: null;

	const to = getToProp();

	const getOnClickProp = () => handleCancel
		? handleCancel
		: !to
			? () => history.goBack()
			: null;

	const onClick = getOnClickProp();

	return <CmsContentList list={values(cms)}>{({ cmsContent }) =>
		<Button
			type="button"
			className={layout.goBack}
			typeStyle={buttonTypeStylePlain}
			{...{ to }}
			{...{ onClick }}
		>
			<i className={layout.goBackIcon}>
				<Close
					overrideClass={layout.goBackIcon}
					aria-hidden={true}
				/>
			</i>

			<CmsContentRenderer.Span
				contentKey={cms.cancel}
				fallbackValue="Cancel"
				className={layout.goBackText}
			/>
		</Button>
	}</CmsContentList>;
};


const TakeOverLayout = ({
	title,
	cancelLink = HEADER_GO_BACK,
	handleCancel,
	currentStep = 0,
	steps = [],
	tabs = [],
	showCancel = false,
	showTabs = false,
	showNickname = false,
	backButton = null,
	children,
	containerOverrideClass,
}) => {
	const stepOrder = (step) => {
		if (currentStep > step) {
			return cx(layout.step, layout.isComplete);
		} else if (currentStep < step) {
			return layout.step;
		} else {
			return cx(layout.step, layout.isActive);
		}
	};

	const [ selectedIndex, setSelectedIndex ] = useState(0);

	return (
		<main
			className={cx(layout.main, containerOverrideClass)}
			data-qa="MainTakeOverContainer"
		>
			<section className={cx(layout.header, showTabs && layout.hasTabs)}>
				<Container overrideClass={layout.headerContent}>
					<h1 className={layout.title} data-qa="TakeOverTitle">{title}</h1>
					{showNickname && <CardIconAndNickname />}

					{Boolean(steps.length > 0) ?
						<div className={layout.stepsContainer}>
							<div className={layout.stepsContent}
								data-qa="TakeOverSteps"
							>
								{filter(steps, stepValue => stepValue.step === currentStep)[ 0 ].label}
							</div>
							<div className={layout.stepsIcons}>
								{steps.map((stepValue, index) =>
									<Fragment key={stepValue.step}>
										<div
											className={stepOrder(stepValue.step)}
											data-qa="TakeOverStep"
										>
											<SmallCheck overrideClass={layout.stepIcon} aria-hidden={true} />
										</div>
										{index + 1 !== steps.length ? (
											<Dots overrideClass={layout.dots} aria-hidden={true} />
										) : null}
									</Fragment>
								)}
							</div>
						</div>
						: null
					}

					{backButton &&
							<Button
								type="button"
								to={backButton?.redirectUrl}
								typeStyle={buttonTypeStylePlain}
								additionalClassNames={layout.backLink}
								onClick={PreventDefault(backButton?.handleBackstep)}
							>
								{backButton.label}
							</Button>
					}

					{showCancel &&  <CancelButton {...{ cancelLink, handleCancel }} desktopShowText />}
				</Container>

			</section>

			<section className={cx(layout.content, showTabs && layout.tabContainer)}>
				{showTabs
					? (
						<Tab.Group
							as="div"
							className={cx(tabsSecondary, tabStyle.takeover)}
							selectedIndex={selectedIndex}
							onChange={setSelectedIndex}
						>
							<Tab.List className={tabStyle.tabHeader}>
								<Container>
									{map(tabs, ({ label, key }) =>
										<Tab
											className={({ selected }) => cx(tabStyle.tabItem, selected && tabStyle.isSelected)}
											key={key}
										>
											{({ selected }) => label}
										</Tab>
									)}
								</Container>
							</Tab.List>
							<Tab.Panels
								className={layout.tabsContent}
							>
								<Container>
									{map(tabs, ({ content, key }) =>
										<Tab.Panel key={key}>
											{content}
										</Tab.Panel>
									)}
								</Container>
							</Tab.Panels>
						</Tab.Group>
					)
					: null}
				<Container>
					{children}
				</Container>
			</section>
		</main>
	);
};

// In some instances (the purchase flow, for example), we may want to control whether or not we show the <TakeOverLayout>
// since a both a parent and child component might be providing a <TakeOverLayout>. The child component maybe
// providing a <TakeOverLayout> in one flow and in another the parent may provide the <TakeOverLayout>.
// This allows us to make the component reusable/flow agnostic.
export const TakeOverLayoutWrapper = ({
	showTakeOverLayout = true,
	children,
	...props
}) => {
	return showTakeOverLayout
		? (
			<TakeOverLayout {...props}>
				{children}
			</TakeOverLayout>
		)
		: (
			<>
				{children}
			</>
		);
};

TakeOverLayout.propTypes = {
	title: PropTypes.node.isRequired,
	cancelLink: PropTypes.string,
	handleCancel: PropTypes.func,
	currentStep: PropTypes.number,
	backButton: PropTypes.object,
	steps: PropTypes.array,
	step: PropTypes.number,
	tabs: PropTypes.array,
	showCancel: PropTypes.bool,
	showSteps: PropTypes.bool,
	showTabs: PropTypes.bool,
	showNickname: PropTypes.bool,
};

export default TakeOverLayout;
