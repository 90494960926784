import {
	forEach,
} from 'lodash';
import GoogleAnalytics from "./GoogleAnalytics.js";
import {
	levels,
	noticeError,
} from "../Logger.js";

export const wireEventElements = () => {
	// we look for a selector instead of hard-wiring the analytics events so that html coming from the cms can be wired
	const elementsToWire = document.querySelectorAll('[data-analytics]');
	forEach(elementsToWire, (element) => {
		try {
			if (element.dataset.analyticsWired) {
				return;
			}
			element.dataset.analyticsWired = true;

			element.addEventListener("click", () => {
				const eventOptions = JSON.parse(element.dataset.analytics);
				GoogleAnalytics.logEvent(...eventOptions);
			});
		}
		catch (error) {
			noticeError(null, levels.error, error, "Wiring analytics events");
		}
	});
};

export const sendPageviewEvent = (pathname) => {
	// tell google
	GoogleAnalytics.sendPageview({
		page_path: pathname,
	});
};
