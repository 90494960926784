import {
	clone,
	merge,
} from "lodash";
import WSDirectDebit, {
	WSDirectDebitFragment,
	WSDirectDebitType,
} from "server/api-types/WSDirectDebit.js";

/*  2.7.26.1 WSDirectDebitClear is a subclass of WSDirectDebit. This object represents the complete bank
	account details, unmasked, non-tokenized. The following table provides a description of the
	WSDirectDebitClear fields.
	*/

export default class WSDirectDebitClear extends WSDirectDebit {
	constructor({
		nameOnAccount,
		bankAccountNumber,
		bankRoutingNumber,
		accountType,
		financialInstitutionName,

		// Super props
	}) {
		super({});

		// string(100)
		// (Required)  Full name of the account holder.
		this.nameOnAccount = nameOnAccount;

		// string(17)
		// (Required)  The bank account number of the account holder.
		this.bankAccountNumber = bankAccountNumber;

		// string(10)
		// (Required)  Bank routing number.
		this.bankRoutingNumber = bankRoutingNumber;

		// string(1)
		// (Required)  The type of the account.
		// C – Checking
		// S – Savings
		this.accountType = accountType;

		// string(50)
		// (Required)  The name of the financial institution.
		this.financialInstitutionName = financialInstitutionName;
	}

	static fromBackoffice(data) {
		const result = new WSDirectDebitClear(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const result = clone(this);
		return merge(
			result,
			super.toBackoffice(),
		);
	}

	toResolver() {
		const forGraphql = clone(this);
		return merge(
			forGraphql,
			super.toResolver(),
		);
	}
}

export const InputWSDirectDebitClearType = [ `
	input InputWSDirectDebitClear {
		nameOnAccount: String!
		bankAccountNumber: String!
		bankRoutingNumber: String!
		accountType: String!
		financialInstitutionName: String!

		businessName: String
		ssnLastFour: String
	}
` ];

export const WSDirectDebitClearType = [
	...WSDirectDebitType,
	...InputWSDirectDebitClearType,
	`
		type WSDirectDebitClear implements WSDirectDebitFactory {
			${WSDirectDebitFragment}
			nameOnAccount: String!
			bankAccountNumber: String!
			bankRoutingNumber: String!
			accountType: String!
			financialInstitutionName: String!
		}
	`,
];
