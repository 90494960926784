import { useApolloClient } from "@apollo/client";
import React, {
} from 'react';
import cx from 'classnames';
import {
	isEmpty,
	replace,
	values,
} from 'lodash';
import {
	object as yup_object,
	string as yup_string,
	boolean as yup_boolean,
} from "yup";
import strToBoolean from 'utils/StrToBoolean.mjs';
import PropTypes from 'prop-types';
import { endOfMonth, isAfter } from 'date-fns';

import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import { WireFormHelper } from "utils/FormHelper.js";
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import PreventDefault from 'utils/PreventDefault.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import {
	EDIT_FUNDING_SOURCE,
	FUNDING_SOURCES_GET_QUERY,
	FUNDING_SOURCES_B2B_GET_QUERY,
} from 'graphql-queries/FundingSources.js';
import { addNewPaymentMethodAddress } from 'components/account-settings/edit-contact/EditContact.helpers.js';
import Modal from 'components/Modal.js';
import Button, { Secondary } from 'components/Button.js';
import EditCreditCardForm from "components/account/card/add-passes/EditCreditCardForm";
import CmsContentList from "components/data/CmsContentList.js";
import { useModalContext } from 'context/ModalProvider.js';
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";

import {
	NEW_ADDRESS_ID,
	AddressSelectorContext,
	useAddressSelectorState,
} from "components/account-settings/address-selector/AddressSelector.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";
import WSCreditCardUpdate from "server/api-types/WSCreditCardUpdate.js";
import PublicAppVars from "utils/PublicAppVars.js";
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';

import { GET_B2B_CUSTOMER_ADDRESSES, GET_CUSTOMER_ADDRESSES } from "components/data/session-user/Addresses.query";
import WSAddressExt from "server/api-types/WSAddressExt.js";
import { nickNameMaxLengthCMSKey } from "components/modals/EditCardNickname.js";

import * as modal from 'components/Modal.module.css';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import { getAddressYupSchema } from "components/forms/address/Address.validations.js";

const cms = {
	header: 'miscText.payments-info-manage-edit-header',
	cancel: 'miscText.general-modal-cancel',
	saveChanges: 'miscText.personal-info-edit-submit',
};

const getInputName = (fieldName) => getPaymentInputName({ base: fieldName });

export const emptyToNull = (value, originalValue) => !value
	? null
	: originalValue;

export const getYupSchema = ({
	isNew,
	paymentType = PAYMENT_TYPES.creditCard,
}) => {
	if (paymentType === PAYMENT_TYPES.creditCard) {
		const cardValidations = {
			nameOnCard: yup_string()
				.transform(value => value === '' ? undefined : value)
				.required(() => <CmsContentRenderedInline
					contentKey={'miscText["errors.general.field_required"]'}
					fallbackValue="Cardholder name is required"
					variables={{ field: 'Cardholder name' }}
				/>)
				.trim(),
			cardNickname: yup_string()
				.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, nickNameMaxLengthCMSKey)
				.trim(),
			expirationDate: yup_string()
				.matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "miscHtml.general-payment-cc-error-expiry")
				.test({
					name: 'expired expiration date',
					test: (value) => {
					// Only begin validation once we have a full date
						if (value.length === 5) {
							const today = new Date();

							const splitDate = value.split("/");

							// getMonth is indexed so we add 1 to compare to the user input
							const parsedMonth = parseInt(splitDate[ 0 ]) - 1;

							// Scoped to 21st Century
							const parsedYear = parseInt(splitDate[ 1 ]) + 2000;

							// Get the last date of the expiration date based on the month
							const expirationDate = endOfMonth(new Date(parsedYear, parsedMonth));

							return isAfter(expirationDate, today);
						}
						// Expiration date is not in a format we support or not fully input so we won't validate
						return true;
					},
					message: () => <CmsContentRenderedInline
						contentKey={'miscHtml.general-payment-cc-error-expiry'}
						fallbackValue={`<p>Invalid expiration date</p>`}
					/>,
				})
				.required(<CmsContentRenderedInline
					contentKey={'miscText["errors.general.field_required"]'}
					fallbackValue="Expiration Date is required"
					variables={{ field: 'Expiration Date' }}
				/>),

			cardCVV: yup_string().trim()
				// transform empty strings because otherwise we do not see the required error message, only min error msg
				// https://github.com/jquense/yup/issues/24#issuecomment-178029968
				.transform(value => value === '' ? undefined : value)
				.required("miscHtml.general-transit-charlie-physical-security-error")
				.matches(/^\d+$/, "miscHtml.general-transit-charlie-physical-security-error")
				.min(3, "Minimum security code length not met")
				.max(4, "Minimum security code length exceeded"),
		};

		const validation = yup_object().shape({
			...cardValidations,
			primaryPaymentMethod: yup_boolean(),
		}).concat(isNew ? getAddressYupSchema('', getInputName('')): null);

		return validation;
	}
	else if (paymentType === PAYMENT_TYPES.directDebit) {
		return yup_object().shape({
			cardNickname: yup_string()
				.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, nickNameMaxLengthCMSKey)
				.trim(),
		});
	}
};

const EditPaymentMethod = ({
	fundingSource: wsFundingSourceExt,
	programId = null, // provided if B2B. Null if CS.
}) => {
	const {
		billingAddressId: originalBillingAddressId,
		fundingSourceId,
	} = wsFundingSourceExt;

	const addressSelectorState = useAddressSelectorState({
		defaultAddressId: originalBillingAddressId,
	});

	const {
		selectedAddressId,
		isEditingAddress,
	} = addressSelectorState;

	const { setModal } = useModalContext();
	const apolloClient = useApolloClient();
	const pgCardId = wsFundingSourceExt?.creditCard?.pgCardId ?? "";

	// WSFundingSourceExt doesn't have the same creditCard vs directDebit split that WSPayment does, but we can pretend
	const paymentType = !isEmpty(wsFundingSourceExt?.creditCard)
		? PAYMENT_TYPES.creditCard
		: PAYMENT_TYPES.directDebit;

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({
		getYupSchema: () => getYupSchema({
			isEditingAddress,
			isNew: selectedAddressId === NEW_ADDRESS_ID,
			paymentType,
		}) });

	const onModalClose = () => setModal(null);

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		}
		catch (errorReport) {
			// yup validation failed, but those errors are already in state
			noticeError(null, levels.info, errorReport, `Edit Payment Method form validation`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		let postAddressResponse = null;
		const billingAddress = new WSAddressExt({
			...validated,
			primaryShipping: strToBoolean(validated.primaryShipping),
			primaryBilling: strToBoolean(validated.primaryBilling),
			...(
				addressSelectorState.selectedAddressId === NEW_ADDRESS_ID
					? {}
					: { ...addressSelectorState.address }
			),
		});

		if (selectedAddressId === NEW_ADDRESS_ID) {
			postAddressResponse = await addNewPaymentMethodAddress({
				apolloClient,
				wsAddressExt: billingAddress,
				formHelper,
				setSubmitting,
				fundingSourceId,
			});

			// The mutation was unsuccessful.
			// Do not allow the rest of the mutations to be run
			if (!postAddressResponse) {
				return false;
			}
		};

		const addressId = postAddressResponse?.addressId ?? billingAddress.addressId;
		const variables = paymentType === PAYMENT_TYPES.creditCard
			? {
				wsCreditCardUpdate: new WSCreditCardUpdate({
					pgCardId: validated.pgCardId,
					cardExpiryMMYY: replace(validated.expirationDate, "/", ""),
					nameOnCard: validated.nameOnCard,
					cvv: validated.cardCVV,
				}).toBackoffice(),
				fundingSourceId,
				nickname: validated.cardNickname,
				programId,
				setAsPrimary: validated.primaryPaymentMethod,
				...(
					addressId
						? { billingAddressId: addressId }
						: {}
				),
			}
			: {
				isDirectDebit: true,
				fundingSourceId: validated.fundingSourceId,
				nickname: validated.cardNickname,
				programId,
			};

		try {
			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: EDIT_FUNDING_SOURCE,
					variables,
					refetchQueries: [
						...(PublicAppVars.isB2B
							? [
								{ query: FUNDING_SOURCES_B2B_GET_QUERY, variables: { programId: parseInt(programId, 10) } },
								{ query: GET_B2B_CUSTOMER_ADDRESSES },
							]
							: [
								{ query: FUNDING_SOURCES_GET_QUERY },
								{ query: GET_CUSTOMER_ADDRESSES },
							]
						),
					],
				}));
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Edit payment method form submit failed`);
			formHelper.validationErrorHandler(errorReport);
			return false;
		}

		onModalClose();
	};

	return addressSelectorState ? (
		<AddressSelectorContext.Provider value={addressSelectorState}>
			<CmsContentList list={values(cms)}>{() => (
				<Modal
					title={
						<CmsContentRenderer.Span
							contentKey={cms.header}
							fallbackValue="Edit Payment Method"
						/>}
				>
					<WireFormHelper {...{ formHelper }}>
						<form
							ref={formRef}
							onSubmit={PreventDefault(() => kickoffSubmit())}
						>

							<EditCreditCardForm
								{...{
									formHelper,
									wsFundingSourceExt,
									hideTopLevelError: true,
								}}
							/>

							{paymentType === PAYMENT_TYPES.creditCard
								? <input type="hidden" value={pgCardId} name="pgCardId" />
								: null}

							{formHelper.getFieldErrorJsx('')}
							<div className={modal.actionButtons}>
								<Button
									submitting={submitting}
									overrideClass={cx(modal.btn, modal.leftButton)}
									disabled={submitting}
								>
									<CmsContentRenderer.Span
										contentKey={cms.saveChanges}
										fallbackValue="Save Changes"
									/>
								</Button>
								<Button
									onClick={PreventDefault(onModalClose)}
									type={Secondary}
									overrideClass={cx(modal.btn, modal.rightButton)}
								>
									<CmsContentRenderer.Span
										contentKey={cms.cancel}
										fallbackValue="Cancel"
									/>
								</Button>
							</div>
						</form>
					</WireFormHelper>
				</Modal>
			)}</CmsContentList>
		</AddressSelectorContext.Provider>
	) : null;
};

EditPaymentMethod.propType = {
	fundingSource: PropTypes.object.isRequired,
};

export default EditPaymentMethod;
