import React from 'react';
import cx from "classnames";
import { values } from 'lodash';
import qs from 'qs';
import { STANDARD_PURCHASE_FLOW_STEPS } from 'pages/account/purchase/StandardPurchaseFlow.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import PreventDefault from 'utils/PreventDefault.js';
import * as typography from 'styles/typography.module.css';
import TakeOverLayout from 'layouts/TakeOverLayout.js';
import PurchaseTable from 'components/account/PurchaseTable.js';
import PurchaseBillingPanel from 'components/account/PurchaseBillingPanel.js';
import PurchaseShippingPanel from 'components/account/PurchaseShippingPanel.js';
import StandardNewProductAdded from 'components/toasts/StandardNewProductAdded.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import { Printer } from 'components/Icon.js';
import CartLeavePagePrompt from 'pages/account/purchase/CartLeavePagePrompt.js';
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import { useEmvCanAutoload } from "components/data/transit-account/EMV.helpers.js";
import loginStages from "components/data/session-user/LoginStages.js";
import SetupAutoload from "components/modals/SetupAutoload.js";
import { useStepContext } from 'context/StepContext.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { cms as purchaseCmsKeys } from 'pages/account/purchase/product-catalog-takeover/constants.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import CmsContentList from 'components/data/CmsContentList.js';
import AutoloadContextProvider, { useAutoloadContext } from "context/AutoloadContext.js";
import { useModalContext } from 'context/ModalProvider.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { getCartSubtotal, getCartTotal, useCartContext } from 'context/CartProvider.js';

import * as style from './PurchaseProductConfirm.module.css';
import { PDF_TYPES } from 'components/account/download-reports/pdf/Document';
import { useFundingSourcesContext } from 'context/FundingSourcesContext';
import WSSubsystemValuePayment from "server/api-types/WSSubsystemValuePayment.js";

const cms = {
	stepFourdescription: 'miscText.purchase-step4-description',
	print: 'miscText.purchase-step4-print',
	done: 'miscText.purchase-step4-done',
	purchaseSummary: 'miscText.purchase-step4-summary-subheader',
	billingInformation: 'miscText.purchase-card-4-billing-subheader',
	shippingInformation: "miscText.purchase-card-4-shipping-subheader",
};

export const PurchaseCompleteContent = ({
	handleRedirect,
	onModalClose,
	setToastSuccess,
}) => {
	const { setModal } = useModalContext();
	const { setToast } = useGlobalToastsContext();
	const { loginStage } = useLoginStage();
	const { cart } = useCartContext();
	const { finalWsPayments } = useFundingSourcesContext();
	const { autoloads } = useAutoloadContext();
	const loggedIn = (loginStage === loginStages.loggedIn);
	const transit_account_id = useTransitAccountIdContext();
	const { products, purchaseConfirmationInfo, mediaOptions, balance } = cart;
	const subTotal = getCartSubtotal(cart, true);
	const total = getCartTotal(cart);

	// there's no transit_account_id on the charlie card purchase flow order
	// confirmation step
	const emvCanAutoload = useEmvCanAutoload({
		subsystemAccountReference: transit_account_id,
		queryOptions: { skip: !transit_account_id },
	});

	const canAutoload = (Boolean(autoloads?.enabledAutoloads?.length) && loggedIn && emvCanAutoload);

	const orderData = {
		...purchaseConfirmationInfo,
		products,
		mediaOptions,
		balance,
		paymentMethods: finalWsPayments.reduce((paymentMethods, payment) => {
			if (payment instanceof WSSubsystemValuePayment) {
				return paymentMethods;
			}

			return [
				...paymentMethods,
				{
					paymentType: payment.paymentType,
					creditCardType: payment.creditCardType,
					paymentAmount: payment.paymentAmount,
					maskedPan: payment.maskedPan,
				},
			];
		}, []),
		subTotal,
		total,
	};

	const hasBillingInfo = total > 0;
	const hasShippingAddress = cart?.shippingDetails?.shippingAddress?.address1;

	const handleCompleteRedirect = () => {
		handleRedirect();

		setModal(<SetupAutoload {...{
			onModalClose,
			setToastSuccess,
			subsystemAccountReference: transit_account_id,
		}} />);
	};

	const handlePurchaseSuccess = () => {
		handleRedirect();
		setToast(<StandardNewProductAdded cart={cart} />);
	};

	return (
		<CmsContentList list={values(cms)}>{() => (
			<div className={style.review}>
				<div className={style.reviewHeader}>
					<CmsContentRenderer.H3
						contentKey={cms.stepFourdescription}
						fallbackValue="Your order is complete"
					/>
				</div>
				<div>
					<div className={style.reviewSubheader}>
						<CmsContentRenderer.H4
							contentKey={cms.purchaseSummary}
							fallbackValue="Purchase Summary"
						/>
					</div>
					<PurchaseTable />
				</div>

				{hasBillingInfo ?
					<div>
						<div className={style.reviewSubheader}>
							<CmsContentRenderer.H4
								contentKey={cms.billingInformation}
								fallbackValue="Billing Information"
							/>
						</div>
						<PurchaseBillingPanel />
					</div>
					: null
				}

				{hasShippingAddress &&
					<div>
						<div className={cx(style.reviewSubheader, style.shippingContainerMargin)}>
							<CmsContentRenderer.H3
								className={typography.h8}
								contentKey={cms.shippingInformation}
								fallbackValue="Shipping Information"
							/>
						</div>
						<PurchaseShippingPanel />
					</div>
				}
				<div className={style.confirmButtons}>
					<div>
						<Button
							typeStyle={buttonTypeStylePlain}
							to={`/download-pdf/${PDF_TYPES.order}?${qs.stringify({
								transitAccountId: transit_account_id,
								orderData: JSON.stringify(orderData),
							}, { skipNulls: true })}`}
							target="_blank"
							external
						>
							<Printer overrideClass={style.printerIcon} />
							<CmsContentRenderer.Span
								className={style.link}
								contentKey={cms.print}
								fallbackValue="Print Receipt"
							/>
						</Button>
					</div>
					<div>
						{canAutoload
							? <Button
								isPrimary={true}
								additionalClassNames={cx(style.button)}
								onClick={PreventDefault(handleCompleteRedirect)}
							>
								<CmsContentRenderer.Span contentKey={cms.done} fallbackValue="Done" />
							</Button>
							: <Button
								isPrimary={true}
								additionalClassNames={cx(style.button)}
								onClick={PreventDefault(handlePurchaseSuccess)}
							>
								<CmsContentRenderer.Span contentKey={cms.done} fallbackValue="Done" />
							</Button>
						}

					</div>
				</div>
			</div>
		)}</CmsContentList>
	);
};

const ConfirmPurchaseProduct = ({
	handleRedirect,
	onModalClose,
	setToastSuccess,
}) => {
	const { step } = useStepContext();
	return (
		<AutoloadContextProvider>
			<TakeOverLayout
				title={<CmsContentRenderedInline
					contentKey={purchaseCmsKeys.purchasNewProduct}
					fallbackValue="Purchase a New Product"
				/>}
				showCancel={false}
				steps={STANDARD_PURCHASE_FLOW_STEPS}
				currentStep={step}
			>
				<CartLeavePagePrompt />
				<PurchaseCompleteContent {...{ handleRedirect, onModalClose, setToastSuccess }} />
			</TakeOverLayout>
		</AutoloadContextProvider>
	);
};

export default ConfirmPurchaseProduct;
