import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from 'crypto-hash';
import { print } from 'graphql';
import PublicAppVars from "./PublicAppVars.js";
import { createUploadLink } from 'apollo-upload-client';

export const create_client_apollo_client = (getCsrfToken) => {
	const httpLink = createUploadLink({
		// https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-http#options
		// By default, this client will send queries to the `/graphql` endpoint on the same host

		// whether to include basic auth in the request. If 'include', then you must set a cors origin in APOLLO_CORS
		credentials: PublicAppVars.APOLLO_CLIENT_CREDENTIALS,

		// we can't useGETForQueries because GET_ROUTE is too long. But we can use useGETForHashedQueries
		//useGETForQueries: false,

		uri: PublicAppVars.FRONTEND_CLIENT_BASE + '/graphql',
	});

	// https://www.apollographql.com/docs/link/links/context.html
	const authLink = setContext((request, previousContext) => {
		const token = getCsrfToken();
		return {
			headers: {
				"X-XSRF-TOKEN": token,
			},
		};
	});

	// https://www.apollographql.com/docs/react/api/core/ApolloClient/#the-apolloclient-constructor
	return new ApolloClient({
		assumeImmutableResults: PublicAppVars.APOLLO_IMMUTABLE,
		link: createPersistedQueryLink({
			useGETForHashedQueries: true,

			// crypto-hash is really lightweight (300 bytes) but doesn't work over http
			// in that case, use crypto-js instead, which adds 16.4 kB min+gz https://bundlephobia.com/result?p=crypto-js
			// another option would be to have everyone use chrome://flags/#unsafely-treat-insecure-origin-as-secure
			...(
				// if we're on a secure origin and crypto is supported, digest will be a function
				crypto?.subtle?.digest
					? {
						// use crypto-hash
						sha256,
					}
					: {
						// use crypto-js
						generateHash: async query => {
							// default implementation is: sha256(print(query))
							// see https://github.com/apollographql/apollo-client/blob/main/src/link/persisted-queries/index.ts

							const docString = print(query);
							const sha256 = (await import("crypto-js/sha256")).default;
							return sha256(docString).toString();
						},
					}
			),
		})
			.concat(authLink)
			.concat(httpLink),
		cache: new InMemoryCache({
			freezeResults: true,
			// TODO: do we need https://www.apollographql.com/docs/react/data/fragments/#defining-possibletypes-manually
			// (same question with the InMemoryCache in server/ApolloServerClient.js)
		}).restore(window.__APOLLO_INITIAL_STATE__ || {}),
		ssrForceFetchDelay: 300,
	});
};
