import React from 'react';
import PropTypes from "prop-types";
import CmsContent from "./CmsContent.js";
import CmsContentRenderer from './CmsContentRenderer.js';

const CmsContentRendered = ({
	contentKey,
	rawHtml,
	elementType = 'div',
	variables,
	...rest
}) => (
	<CmsContent contentKey={contentKey} variables={variables}>
		{({ content }) => {
			// CmsContent might return a null, but we want that to render as ''
			return (
				<CmsContentRenderer
					content={content}
					contentKey={contentKey}
					rawHtml={rawHtml}
					elementType={elementType}
					{...rest}
				/>
			);
		}}
	</CmsContent>
);

CmsContentRendered.propTypes = {
	contentKey: PropTypes.string.isRequired,
	elementType: PropTypes.node,
	rawHtml: PropTypes.bool,
};

CmsContentRendered.Div = props => <CmsContentRendered elementType="div" {...props} />;
CmsContentRendered.Div.propTypes = CmsContentRendered.propTypes;
CmsContentRendered.Div.displayName = "CmsContentRendered.Div";

CmsContentRendered.Span = props => <CmsContentRendered elementType="span" {...props} />;
CmsContentRendered.Span.propTypes = CmsContentRendered.propTypes;
CmsContentRendered.Span.displayName = "CmsContentRendered.Span";

CmsContentRendered.H1 = props => <CmsContentRendered elementType="h1" {...props} />;
CmsContentRendered.H1.propTypes = CmsContentRendered.propTypes;
CmsContentRendered.H1.displayName = "CmsContentRendered.H1";

CmsContentRendered.H2 = props => <CmsContentRendered elementType="h2" {...props} />;
CmsContentRendered.H2.propTypes = CmsContentRendered.propTypes;
CmsContentRendered.H2.displayName = "CmsContentRendered.H2";

CmsContentRendered.H3 = props => <CmsContentRendered elementType="h3" {...props} />;
CmsContentRendered.H3.propTypes = CmsContentRendered.propTypes;
CmsContentRendered.H3.displayName = "CmsContentRendered.H3";

CmsContentRendered.H4 = props => <CmsContentRendered elementType="h4" {...props} />;
CmsContentRendered.H4.propTypes = CmsContentRendered.propTypes;
CmsContentRendered.H4.displayName = "CmsContentRendered.H4";

// Note that generally <div> is better than <p> for cms content because cms content can have <p>
// but html doesn't let you have <p> inside a <p>
CmsContentRendered.P = props => <CmsContentRendered elementType="p" {...props} />;
CmsContentRendered.P.propTypes = CmsContentRendered.propTypes;
CmsContentRendered.P.displayName = "CmsContentRendered.P";

export default CmsContentRendered;
