import { gql } from "@apollo/client";

export const GET_PAYMENT_METHODS = gql`
	mutation Paymentmethods (
		#Todo: storedValues and products are subclasses of WSLoadProduct, we should use an input factory
		$issueMediaLineItems: [ InputWSIssueMediaLineItem! ]
		$feeDueLineItems: [ InputWSFeeDueLineItem! ]
		$products: [ InputWSLoadSubsystemProduct! ]
		$storedValues: [ InputWSLoadSubsystemAccountValue! ]
	) {
		OrderRoute {
			id
			getPaymentmethods(
				issueMediaLineItems: $issueMediaLineItems
				feeDueLineItems: $feeDueLineItems
				products: $products
				storedValues: $storedValues
			) {
				shoppingCartId
				loadProductLineItemFunds {
					subsystemAccountFunds {
						subsystem
						subsystemAccountReference
						purseType
						purseTypeDescription
						purseRestriction
						purseRestrictionDescription
						nickname
						balance
						purseAllocPrecedence
						suggestedAllocAmount
						paymentType
					}
				}
				fundingSources {
					id
					creditCard {
						pgCardId
						maskedPan
						cardExpiryMMYY
						nameOnCard
						creditCardType
					}
					fundingSourceId
					nickname
					setAsPrimary
					directDebit {
						#maskedBankAccountNumber
						financialInstitutionName
						accountType
					}
					billingAddressId
					customerId
				}
				restrictFunding
			}
		}
	}`;
