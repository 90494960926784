import React from "react";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import {
	filter,
	forEach,
	map,
} from 'lodash';
import {
	POST_NEW_PAYMENT_ADDRESS,
} from "graphql-queries/FundingSources.js";
import WSAddress from 'server/api-types/WSAddress.js';
import { UPDATE_PAYMENT_ADDRESS } from 'components/modals/EditAddress.js';
import { GET_CUSTOMER_ADDRESSES_AND_CONTACT } from 'components/data/session-user/Addresses.query.js';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';

export const getFailedUpdatedSources = ({
	fundingSourceDependencies,
	contactDependencies,
}) => {
	// 06/14/202 Alessandro's answer regards a possible way to get partial success funding sources updates
	// when updates address was failed to some funding sources
	// https://reflexions.slack.com/archives/GA82SPCTV/p1623682644401400
	// same address should be shared between an ACH and Credit Card payment method
	const contactDependenciesFailedToUpdate = [];
	const fundingSourceDependenciesFailedToUpdate = [];

	const failedToUpdate = filter(fundingSourceDependencies, wsFundingSourceInfo => wsFundingSourceInfo.value === "Failed");

	if (contactDependencies?.length) {
		forEach(failedToUpdate, wsFundingSourceInfo => {
			if (wsFundingSourceInfo.key === contactDependencies[ 0 ].contactId) {
				contactDependenciesFailedToUpdate.push(contactDependencies[ 0 ]);
			}
		});
	}
	if (fundingSourceDependencies?.length) {
		forEach(failedToUpdate, wsFundingSourceInfoFailed => {
			forEach(fundingSourceDependencies, wsFundingSourceInfo => {
				if (wsFundingSourceInfoFailed.fundingSourceId === wsFundingSourceInfo.fundingSourceId) {
					fundingSourceDependenciesFailedToUpdate.push(wsFundingSourceInfo);
				}
			});
		});
	}

	return {
		contactDependenciesFailedToUpdate,
		fundingSourceDependenciesFailedToUpdate,
	};
};

export const updatePaymentAddress = async ({
	addressId,
	updateAllAddressDependencies,
	wsAddressExt,
	contactDependencies,
	fundingSourceDependencies,
	apolloClient,
	formHelper,
	fundingSourceId = null,
	setSubmitting,
}) => {

	// link new created address to editing sources
	const variables = {
		address: new WSAddress(wsAddressExt),
		addressId,
		primaryBilling: wsAddressExt.primaryBilling,
		primaryShipping: wsAddressExt.primaryShipping,
		updateDependencies: true,
	};

	if (wsAddressExt.unverified) {
		variables.unverified = wsAddressExt.unverified;
	}

	// do not need to provide the fundingSourcesUpdated if the user wants to update all dependencies
	// Alessandro's replay on 06/18/2021 https://reflexions.slack.com/archives/GA82SPCTV/p1623974322464300
	// set up updateDependencies = true to perform PUT api call
	if (!updateAllAddressDependencies) {

		// List<String(40)>
		// (Optional) Id’s of the contacts which will be updated to the new address provided. Contact Id is unique identifier of the contact.
		variables.contactsUpdated = map(contactDependencies, wsCustomerContactInfo => wsCustomerContactInfo.contactId);

		// List<String(20)>
		// (Conditionally-Required) Id’s of the funding sources which will be updated to the new address provided. Funding source Id is unique identifier of the funding source.
		// Required if dependent funding sources exist.
		variables.fundingSourcesUpdated = [

			...(fundingSourceId
				? [
					fundingSourceId,
				]
				: []
			),

			...map(fundingSourceDependencies, wsFundingSourceInfo => wsFundingSourceInfo.fundingSourceId),
		];
	}

	let updatePaymentAddressResponse;
	try {
		updatePaymentAddressResponse = await graphqlErrorMiddleware(
			apolloClient.mutate({
				mutation: UPDATE_PAYMENT_ADDRESS,
				variables,
			}));
		// don't need to refetch addresses here. Caller will do it.
	} catch (errorReport) {
		// yup validation failed, but those errors are already in state
		noticeError(null, levels.info, errorReport, `Edit payment address submit failed`);
		setSubmitting?.(false);
		formHelper.validationErrorHandler(errorReport);
		return;
	}
	return updatePaymentAddressResponse;
};

export const postNewAddress = async ({
	apolloClient,
	wsAddress,
	refetch = true,
	formHelper,
	setSubmitting,
}) => {
	let addAddressResponse;
	try {
		addAddressResponse = await graphqlErrorMiddleware(
			apolloClient.mutate({
				mutation: POST_NEW_PAYMENT_ADDRESS,
				variables: {
					address: wsAddress.toBackoffice(),
				},
				...(refetch
					? {
						refetchQueries: [
							// Refetch addresses from session
							{ query: GET_CUSTOMER_ADDRESSES_AND_CONTACT },
						],
					}
					: {}
				),
			}));
	} catch (errorReport) {
		// yup validation failed, but those errors are already in state
		noticeError(null, levels.info, errorReport, `Post new payment address submit failed`);
		formHelper.validationErrorHandler(errorReport);
		setSubmitting(false);
		return;
	}

	return addAddressResponse;
};

export const getActiveFundingSourceDependencies = fundingSourceDependencies => filter(fundingSourceDependencies, wsFundingSourceInfo => wsFundingSourceInfo.status === 'Active');

/**
 * We were editing an address used by other contacts/funding sources, and prompted the user
 * to ask which ones to update to the new address. The user either selected all, or made a limited
 * selection, in which case we need to move only the selected entries to the new address (otherwise
 * we can edit the existing address in place)
 * We're called during the "Use this address" submit, not the final "Save" submit.
 * @param {object<WSAddressExt>} wsAddressExt
 * @param {boolean} updateAllAddressDependencies
 * @param contactDependencies
 * @param fundingSourceDependencies
 * @param {object<ApolloClient>} apolloClient
 * @param {object<formHelper>} formHelper
 * @param {function<setSubmitting>} setSubmitting
 * @returns {Promise<void>}
 */
export const updateAddressDependencies = async ({
	addressId: currentAddressId,
	wsAddressExt,
	updateAllAddressDependencies,
	contactDependencies,
	fundingSourceDependencies,
	apolloClient,
	formHelper,
	setSubmitting,
	fundingSourceId = null,
}) => {

	let postAddressResponse = null;
	if (!updateAllAddressDependencies) {

		const wsAddress = new WSAddress(wsAddressExt);
		// Some dependencies are moving to the new address, some aren't
		// create a new address for the sources/contacts that are moving over
		postAddressResponse = await postNewAddress({
			apolloClient,
			wsAddress,
			refetch: false,
			formHelper,
			setSubmitting,
		});
	}

	const updatePaymentAddressResponse = await updatePaymentAddress({
		apolloClient,
		formHelper,
		wsAddressExt,
		addressId: updateAllAddressDependencies ? currentAddressId : postAddressResponse.data.CustomerMutationRoute.addressPost.addressId,
		updateAllAddressDependencies,
		contactDependencies,
		fundingSourceDependencies,
		fundingSourceId,
	});

	return updatePaymentAddressResponse;
};


export const addNewPaymentMethodAddress = async ({
	wsAddressExt,
	apolloClient,
	formHelper,
	setSubmitting,
	fundingSourceId,
}) => {
	if (!fundingSourceId) {
		throw new Error(`Funding Source Id required`);
	}

	const wsAddress = new WSAddress(wsAddressExt);
	// Some dependencies are moving to the new address, some aren't
	// create a new address for the sources/contacts that are moving over
	const postAddressResponse = await postNewAddress({
		apolloClient,
		wsAddress,
		refetch: false,
		formHelper,
		setSubmitting,
	});

	if (!postAddressResponse?.data) {
		return;
	}

	const addressId = postAddressResponse.data.CustomerMutationRoute.addressPost.addressId;

	// If either of these booleans are true we need to update the address, else
	if (wsAddressExt.primaryBilling || wsAddressExt.primaryShipping) {
		await updatePaymentAddress({
			apolloClient,
			formHelper,
			wsAddressExt,
			addressId: postAddressResponse.data.CustomerMutationRoute.addressPost.addressId,
			updateAllAddressDependencies: true,
			fundingSourceId,
			setSubmitting,
		});
	}

	return { addressId };
};
