import React, {
	useState,
	forwardRef,
	useMemo,
} from "react";
import PropTypes from "prop-types";
import { values } from 'lodash';
import cx from "classnames";
import FormHelper from "utils/FormHelper.js";

import * as style from "./Input.module.css";
import { getMiscTextErrorKey } from "utils/GetErrorKey.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import CmsContentList from "components/data/CmsContentList.js";

const cms = {
	charCountAriaLabel: "miscText.input-box-character-count-aria-label",
	generalOptional: "miscText.general-optional-field",
};

// This mouse over is to combat the styling that Google Chrome implements when autocomplete is on.
// We can't use onLoad because there is a race condition as to when Chrome will auto-fill the values and when the DOM loads.
// So we will make sure the styles are fixed on the mouseover
const handleverrideAutoCompleteStyles = (event) => {
	event.target.value = event.target.value;
};

const CharacterCount = ({
	remainingChars,
	localId,
	maxLength,
	maxLengthDisplay,
}) => {

	if (!maxLength || !maxLengthDisplay) {
		return null;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) =>
			<span
				className={style.charCount}
				aria-live="polite"
				aria-atomic={true}
				aria-label={`${remainingChars} ${cmsContent[ cms.charCountAriaLabel ] ?? "characters remaining"}`}
				id={`${localId}-charCount`}
			>
				{remainingChars}
			</span>
		}</CmsContentList>
	);
};

const Label = ({
	hideLabel,
	label,
	localId,
	description,
	required,
	labelClass,
}) =>
	hideLabel
		? null
		: (
			<label
				htmlFor={localId}
				className={cx(style.label, labelClass)}
			>
				{label}
				{required
					? null
					: <span className={style.optionalLabel}>
						&nbsp;
						{"("}
						<CmsContentRendered.Span className={style.optionalLabel} contentKey={cms.generalOptional} fallbackValue="(Optional)" />
						{")"}
					</span>
				}
				{description
					? <div>{description}</div>
					: null
				}
			</label>
		);

const Suffix = ({ suffix }) => suffix
	? <div className={style.inputSuffix}>
		<span> {suffix}</span>
	</div>
	: null;

const ErrorMessage = ({
	maxLength,
	remainingChars,
	maxLengthErrorMsg,
}) => maxLength && remainingChars < 0
	?
	<div className={style.maxLengthErrorMsg} role="alert">
		{maxLengthErrorMsg}
	</div>
	: null;

const getAriaInfo = ({ name, error, maxLength, remainingChars, hideLabel, label }) => {
	const isInvalid = Boolean(error || (maxLength && remainingChars < 0));

	const ariaInvalid = isInvalid
		? { "aria-invalid": isInvalid }
		: null;

	const ariaErrorMessage = isInvalid
		? { "aria-errormessage": FormHelper.getStateErrorField(name) }
		: null;

	const ariaLabel = hideLabel
		? { "aria-label": label }
		: null;

	return { ariaInvalid, ariaErrorMessage, ariaLabel };
};

const Input = forwardRef(({
	name = null,
	label = '',
	hideLabel = false,
	error = '',
	maxLengthDisplay = true,
	maxLengthErrorMsg = <CmsContentRendered.Span contentKey={getMiscTextErrorKey("errors.general.value.toolong")} />,
	overrideClass = '',
	labelClass = '',
	suffix = '',
	hideErrorText = false,
	maxLength,
	controlled,
	value,
	defaultValue,
	onChange,
	description = '',
	required = true,
	id,
	overrideAutoCompleteStyles = false,
	type = 'text',
	...rest
}, ref) => {
	const [ remainingChars, setRemainingChars ] = useState(maxLength);

	const localId = id ?? name;
	const getLocalValue = () => controlled
		? value
		: ref && ref.current
			? ref.current.value
			: defaultValue;

	const getValueProp = () =>
		controlled
			? { value: getLocalValue() }
			: {};

	const { ariaInvalid, ariaErrorMessage, ariaLabel } = useMemo(() => {
		const { ariaInvalid, ariaErrorMessage, ariaLabel } =
			getAriaInfo({ name, error, maxLength, remainingChars, hideLabel, label });

		return { ariaInvalid, ariaErrorMessage, ariaLabel };
	}, [ error, maxLength, remainingChars, hideLabel, label, name ]);

	const getFieldErrorJsx = () => {
		const stateField = FormHelper.getStateErrorField(localId);
		return FormHelper.errorJsx(error, stateField);
	};

	const handleChange = (event) => {
		if (maxLength) {
			const newRemaining = maxLength - (event.target.value.length ?? 0);

			if (remainingChars !== newRemaining) {
				setRemainingChars(newRemaining);
			}
		}
		onChange?.(event);
	};

	return (
		<div className={cx(style.container, style[ type ], overrideClass)}>
			<Label {...{
				label,
				localId,
				description,
				required,
				labelClass,
				hideLabel,
			}} />

			<div className={style.charCountWrapper}>
				<input
					{...rest}
					{...getValueProp()}
					type={type}
					id={localId}
					name={name}
					ref={ref}
					defaultValue={defaultValue}
					className={cx(error
						? style.error
						: style.input)}
					onChange={event => handleChange(event)}
					onMouseOver={event => overrideAutoCompleteStyles && handleverrideAutoCompleteStyles(event)}
					aria-label={ariaLabel}
					aria-invalid={ariaInvalid}
					aria-errormessage={ariaErrorMessage}
					aria-controls={maxLength && maxLengthDisplay
						? `${name}-charCount`
						: ``
					}
					{...{ maxLength }}
				/>

				<CharacterCount
					{...{
						localId,
						remainingChars,
						maxLength,
						maxLengthDisplay,
					}}
				/>
				<Suffix suffix={suffix} />

			</div>
			<ErrorMessage
				{...{
					maxLength,
					remainingChars,
					maxLengthErrorMsg,
				}}
			/>

			{hideErrorText
				? null
				: getFieldErrorJsx()}
		</div>
	);
});

Input.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx as long as !hideLabel
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.bool,
	]),
	hideLabel: PropTypes.bool,
	error: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.bool,
	]),
	type: PropTypes.string,
	overrideClass: PropTypes.string,
	labelClass: PropTypes.string,
	value: PropTypes.any,
	maxLength: PropTypes.number,
	maxLengthDisplay: PropTypes.bool,
	maxLengthErrorMsg: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	suffix: PropTypes.string,

	// set true if value comes from prop
	// If not set, you'll get errors like "A component is changing an uncontrolled input of type hidden to be controlled."
	// Make sure the value prop you send isn't undefined, otherwise React will create a controlled element
	controlled: PropTypes.bool,
	required: PropTypes.bool,

	// Meant to only be used when a browser is going to implement autocomplete styles such as Chrome when the user logsout.
	overrideAutoCompleteStyles: PropTypes.bool,

	// hideError lets us hide the error text but show error styling
	hideErrorText: PropTypes.bool,
};

Input.displayName = 'Input';

export default Input;
