import React from 'react';
import { usePypestreamConfig } from "../../components/pypestream-chatbot/PypestreamConfig.query.js";
import SkipToContent from "./SkipToContent.js";
import PublicAppVars from "../../utils/PublicAppVars.js";

const SkipToChatbot = ({
	elementId = PublicAppVars.PYPESTREAM_CHATBOT_WRAPPED_ELEMENT_ID,
	ariaFlowTo = undefined,
	children,
}) => {
	const { enabled, queryResult: { loading } } = usePypestreamConfig();

	if (loading || !enabled) {
		return null;
	}

	return <SkipToContent {...{ elementId, ariaFlowTo }}>{children}</SkipToContent>;
};
export default SkipToChatbot;
