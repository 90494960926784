import {
	clone,
} from "lodash";
import WSFeeDue from './WSFeeDue.js';

// 2.7.27.1.1 WSSubsystemAccountFeeDue is a subclass of WSFeeDue.
// This class is used to pay and update the fee due status in the target
// subsystem account. Expected to set the feeDueLineItemType as “SubsystemAccountFeeDue”.
export default class WSSubsystemAccountFeeDue extends WSFeeDue {
	constructor({
		subsystem,
		subsystemAccountReference,
		tokenId,
		tokenNickname = null,

		// Super props
		feeDueAmount,
		feeDueLineItemType,
		feeDueType,
		feeDueOriginalAmount,
		feeDueDiscountAmount,
	}) {

		super({
			feeDueAmount,
			feeDueLineItemType,
			feeDueType,
			feeDueOriginalAmount,
			feeDueDiscountAmount,
		});

		/**
		* @type string(20)
		* (Required) Unique identifier of the subsystem.
		*/
		this.subsystem = subsystem;

		/**
		 * @type string (20)
		 * (Required) Unique identifier for the customer’s account in the subsystem.
		 */
		this.subsystemAccountReference = subsystemAccountReference;

		/**
		* @type string(20)
		* (Conditionally-Required) Unique token identifier which identifies the transit
		* travel token for which the fee is being paid. Required when feeDueType is
		* TransitAccountTokenEnablementFee.
		*/
		this.tokenId = tokenId;

		/**
		* @type string(40)
		* (Optional) Nick name of the travel token.
		*/
		this.tokenNickname = tokenNickname;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemAccountFeeDue(data);

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		return forGraphql;
	}
}

export const WSSubsystemAccountFeeDueFragment = `
	feeDueAmount: Int!
	feeDueLineItemType: String!
	feeDueType: String!
	feeDueOriginalAmount: Int
	feeDueDiscountAmount: Int
	
	subsystem: String!
	subsystemAccountReference: String
	tokenId: String
	tokenNickname: String
`;

export const InputWSSubsystemAccountFeeDueType = [ `
	input InputWSSubsystemAccountFeeDue {
		${WSSubsystemAccountFeeDueFragment}
	}
` ];

export const WSSubsystemAccountFeeDueType = [ `
	type WSSubsystemAccountFeeDue {
		${WSSubsystemAccountFeeDueFragment}
	}
` ];
