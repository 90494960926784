import {
	includes,
	reduce,
} from 'lodash';
import WSTripRideHistory from "./WSTripRideHistory.js";
import WSInspectionTapRideHistory from "./WSInspectionTapRideHistory.js";
import WSRejectedTapRideHistory from "./WSRejectedTapRideHistory.js";
import WSRideHistory,
{ WSRideHistoryType, WSRideHistoryFragment } from "./WSRideHistory.js";
import { WSKeyValueType } from "server/api-types/WSKeyValue.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

// 1.1.84.2 WSTripRideHistory
// WSTripRideHistory extends WSRideHistory when the type is Trip.

export const autoFilledTap = 'Auto-Filled';
export const missedTap = 'Incomplete';
export const deviceTap = 'Device Tap';

export const Trip = "Trip";
export const InspectionTap = "InspectionTap";
export const RejectedTap = "RejectedTap";
export const UnmatchedTap = "UnmatchedTap";

// see the integration guide (inspection and rejected taps) and trip history table
// desing attached in ticket https://reflexions.atlassian.net/browse/MBTA-663


// inspection taps statuses
const inspection = 'Inspection';
const warning = 'Warning Issued';
const citation = 'Citation Issued';
const onboardSurcharge = 'Onboard Surcharge';
const penaltyFare = 'Penalty Fare';

export const INSPECTION_REASON = {
	inspection,
	warning,
	citation,
	onboardSurcharge,
	penaltyFare,
};

export const ALLOWED_INSPECTION_REASONS = {
	'213': warning,
	'214': citation,
	'215': citation,
	'216': warning,
	'220': inspection,
	'221': inspection,
	'222': onboardSurcharge,
	'223': inspection,
	'224': inspection,
	'225': inspection,
	'226': penaltyFare,
};

export const filterAllowedInspectionTap = (lineItems) => {
	return reduce(lineItems, (accumulator, lineItem) => {
		if (lineItem.type === InspectionTap) {
			if (includes(Object.keys(ALLOWED_INSPECTION_REASONS), lineItem.travelPresenceEvents.status)) {
				accumulator.push(lineItem);
			}
		} else {
			accumulator.push(lineItem);
		}
		return accumulator;
	}, []);
};


// rejected taps reasons
const expired = "Expired card";
const risk = "Risk list";
const passback = "Passback";
const badBin = "Bad BIN";
const unknownCard = "Unknown card";
const notValid = "Not valid for entry";

export const REJECTION_REASON = {
	expired,
	risk,
	passback,
	badBin,
	unknownCard,
	notValid,
};

// See Trip history integration Guide provided by John as of 11/10/2021
// https://reflexions.slack.com/archives/CCF68M49M/p1636562277186600?thread_ts=1636133865.153500&cid=CCF68M49M

// for ex. lineItems.travelPresenceEvents.status = 2 means rejected reason "Bad BIN"

// Declined Taps are allowed to be shown to User based on doc see below:

// “Rejected: Bad BIN”
// • DeviceNotInBinList = 2, <Not associated with a User>

// “Rejected: “Expired card”
// • DeviceCardExpired = 3,

// “Rejected: Risk List”
// • DeviceRiskAssessmentFailed = 4
// • Server Denied = 901,
// • Server Denied Cached = 902

// “Rejected: Passback”
// • DevicePassbackAssessmentFailed = 5,
// • Server Denied Multi-Ride = 903
// • Server Denied Multi-Rode Cached = 905

// “Rejected: Invalid card”
// • DeviceInvalidDigest = 6, < Not associated with a User>
// • DeviceCardDataNotValid = 7, < Not associated with a User>

// “Rejected: Not valid for entry”
// • ServerDeniedValidtoExitOnly = 907

export const ALLOWED_REJECTION_REASONS = {
	'2': badBin,
	'3': expired,
	'4': risk,
	'5': passback,
	'6': unknownCard,
	'7': unknownCard,
	'901': risk,
	'902': risk,
	'903': passback,
	'905': passback,
	'907': notValid,
};

export const filterAllowedRejectedTap = (lineItems) => {
	return reduce(lineItems, (accumulator, lineItem) => {
		if (lineItem.type === RejectedTap) {
			if (includes(Object.keys(ALLOWED_REJECTION_REASONS), lineItem.travelPresenceEvents.status)) {
				accumulator.push(lineItem);
			}
		} else {
			accumulator.push(lineItem);
		}
		return accumulator;
	}, []);
};


export default class WSRideHistoryFactory {
	static fromBackoffice(data) {
		switch (data.type) {
			case Trip:
				return WSTripRideHistory.fromBackoffice(data);
			case InspectionTap:
				return WSInspectionTapRideHistory.fromBackoffice(data);
			case RejectedTap:
				return WSRejectedTapRideHistory.fromBackoffice(data);
			default:
				return WSRideHistory.fromBackoffice(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSRideHistoryFactoryInterface = [ `
	interface WSRideHistoryFactory {
		${WSRideHistoryFragment}
	}`,
];

export const WSRideHistoryFactoryTypes = [
	...WSRideHistoryType,
	...WSKeyValueType,
	...WSRideHistoryFactoryInterface,
];

export const WSRideHistoryFactoryResolvers = {
	WSRideHistoryFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType({ type }) {
			switch (type) {
				case Trip:
					return "WSTripRideHistory";
				case InspectionTap:
					return "WSInspectionTapRideHistory";
				case RejectedTap:
					return "WSRejectedTapRideHistory";
				default:
					return "WSRideHistory";
			}
		},
	},
};
