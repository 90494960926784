import React, { 
	useState,
	useContext,
} from 'react';
import {
	levels,
	log,
} from 'utils/Logger.js';

export const ModalContext= React.createContext(() => log(null, levels.error, "ModalContext was used before it was ready"));
export const useModalContext = () => useContext(ModalContext);

const ModalProvider = ({ children }) => {
	const [ modal, setModal ] = useState(null);
	return (
		<ModalContext.Provider value={{ modal, setModal }}>
			{children}
			{modal}
		</ModalContext.Provider>
	);
};
export default ModalProvider;
