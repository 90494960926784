import { gql } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	map,
	find,
	filter,
	values,
	head,
	cloneDeep,
} from "lodash";
import cx from 'classnames';
import CmsContentList from '../data/CmsContentList';
import CmsContentRenderer from '../data/CmsContentRenderer';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import PreventDefault from 'utils/PreventDefault.js';

import Modal from 'components/Modal.js';
import Button, {
	buttonTypeStylePlain,
	Primary,
	Secondary,
} from 'components/Button.js';
import Checkbox from 'components/forms/Checkbox.js';
import { centsToDisplay } from "utils/FormatHelpers.js";
import { WSAUTOLOAD_TYPES } from "server/api-types/WSAutoloadFactory.js";
import AutoloadContextProvider, { useAutoloadContext, AUTOLOAD_AFTER_PURCHASE } from "context/AutoloadContext.js";
import { TransitAccountIdContextProvider } from 'context/TransitAccountIdContext.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import * as modal from 'components/Modal.module.css';
import * as style from './SetupAutoload.module.css';
import * as forms from 'components/forms/Forms.module.css';

const cms = {
	title: 'miscText.purchase-autoload-prompt-header',
	subTitle: 'miscText.purchase-autoload-prompt-subheader',
	description: 'miscText.purchase-autoload-prompt-description',
	learnMoreCta: 'miscText.purchase-autoload-prompt-learmore-cta',
	learnMoreLink: 'miscText.purchase-autoload-prompt-learnmore-url',
	lineItem: 'miscText.purchase-autoload-prompt-lineitem',
	closeButton: 'miscText.purchase-autoload-prompt-cancel',
	submitButton: 'miscText.purchase-autoload-prompt-submit',
};

export const ENROLL_AUTOLOAD_PASS = gql`
	mutation EnrollAutoLoad (
		$fundingSourceSetItems: [ InputWSFundingSourceSetItem! ]!
		$autoloadSubsystemProduct: InputWSAutoloadSubsystemProduct
		$autoloadSubsystemValue: InputWSAutoloadSubsystemValue
		$autoloadCriteria: InputWSAutoloadCriteria
		$startDate: String
	) {
		OrderRoute {
			id
			postEnroll(
				fundingSourceSetItems: $fundingSourceSetItems
				autoloadSubsystemProduct: $autoloadSubsystemProduct
				autoloadSubsystemValue: $autoloadSubsystemValue
				autoloadCriteria: $autoloadCriteria
				startDate: $startDate
			) {
				subscriptionId
				fundingSourceSetId
			}
		}
	}`;

export const getRedirectUrl = (targetAutoloads, transit_account_id) => {
	const redirectUrl = (head(targetAutoloads).type === WSAUTOLOAD_TYPES.addSubsystemValue)
		? getPathByRoute(routeKeys.AutoloadBalance, { transit_account_id })
		: getPathByRoute(routeKeys.AutoloadPass, { transit_account_id, product_sku: head(targetAutoloads).productSku });
	return redirectUrl;
};


const SetupAutoloadContent = ({
	setToastSuccess,
	onModalClose,
	subsystemAccountReference,
}) => {
	const history = useHistory();

	const { autoloads, addTargetAutoload, removeTargetAutoload } = useAutoloadContext();
	const { enabledAutoloads, targetAutoloads } = autoloads ?? {};
	const balanceAutoload = find(enabledAutoloads ?? [], autoload => autoload.type === WSAUTOLOAD_TYPES.addSubsystemValue);
	const productsAutoload = filter(enabledAutoloads ?? [], autoload => !autoload.type);

	const handleProductSelection = ({ target }, product) => {
		const { checked } = target;

		if (checked) {
			addTargetAutoload(product);
		} else {
			removeTargetAutoload(product);
		}
	};

	const handlePurchaseSuccess = () => {
		onModalClose();
		setToastSuccess({ autoloads });
	};

	const handleContinue = () => {
		if (Boolean(targetAutoloads?.length)) {
			onModalClose();
			const clonedAutoloads = cloneDeep(autoloads);
			removeTargetAutoload(head(targetAutoloads));
			clonedAutoloads[ 'targetAutoloads' ] = clonedAutoloads.targetAutoloads.slice(1);

			history.replace({
				pathname: getRedirectUrl(targetAutoloads, subsystemAccountReference),
				state: { from: AUTOLOAD_AFTER_PURCHASE, autoloads: clonedAutoloads },
			});

		} else {
			handlePurchaseSuccess();
		}
	};

	return (
		<Modal
			title={<CmsContentRenderedInline contentKey={cms.title}
				className={modal.title}
				fallbackValue="Set Up Autoload"
			/>}
			onModalClose={handlePurchaseSuccess}
		>
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
				<div className={style.autoloadInfo}>
					<CmsContentRenderer.Div
						className={style.subTitle}
						contentKey={cms.subTitle}
						fallbackValue="Make sure you always have your fare ready"
					/>
					<CmsContentRenderer.Div
						contentKey={cms.description}
						fallbackValue="Set up autoload to have passes or money automatically added your Charlie Card. To get started, select what you’d like to have autoloaded. You can also set up and manage autoload from your card overview page."
					/>
					<Button typeStyle={buttonTypeStylePlain}
						type="button"
						to={cmsContent[ cms.learnMoreLink ]}
						additionalClassNames={style.plainBtn}
						target="_blank"
						external
					>
						<CmsContentRenderer.Span contentKey={cms.learnMoreCta}
							fallbackValue="Learn more about autoload"
						/>
					</Button>

					<div className={style.checkboxGroup}>
						{Boolean(balanceAutoload) &&
							<div className={style.checkboxWrapper}>
								<Checkbox
									name="balance"
									label={<CmsContentRenderedInline
										elementType='span'
										contentKey={cms.lineItem}
										fallbackValue={`Autoload ${centsToDisplay(balanceAutoload.balance)}`}
										variables={{ product: centsToDisplay(balanceAutoload.balance) }}
										className={cx(forms.label, style.label)}
									/>}
									value="balance"
									controlled={true}
									onChange={(event) => handleProductSelection(event, balanceAutoload)}
								/>
							</div>
						}
						{Boolean(productsAutoload) &&
							map(productsAutoload, productAutoload => (
								<div className={style.checkboxWrapper}>
									<Checkbox
										name={`pass_${productAutoload.productSku}`}
										label={<CmsContentRenderedInline
											elementType='span'
											contentKey={cms.lineItem}
											fallbackValue={`Autoload ${productAutoload.productLongName}`}
											variables={{ product: productAutoload.productLongName }}
											className={cx(forms.label, style.label)}
										/>}
										value={productAutoload.productSku}
										onChange={(event) => handleProductSelection(event, productAutoload)}
									/>
								</div>
							))
						}
					</div>
				</div>

				<div className={style.actions}>
					<Button
						type={Secondary}
						onClick={PreventDefault(handlePurchaseSuccess)}
						overrideClass={cx(style.button, modal.leftButton)}
						data-qa="SetupAutoloadCancelButton"
					>
						<CmsContentRenderer.Span contentKey={cms.closeButton} fallbackValue="Close" />
					</Button>
					<Button
						typeStyle={Primary}
						overrideClass={cx(style.button, modal.rightButton)}
						data-qa="SetupAutoloadConfirmBtn"
						onClick={PreventDefault(handleContinue)}
					>
						<CmsContentRenderer.Span contentKey={cms.submitButton} fallbackValue="Continue" />
					</Button>
				</div>
			</>)}</CmsContentList>
		</Modal>
	);
};

const SetupAutoload = ({
	onModalClose,
	setToastSuccess,
	subsystemAccountReference,
}) => {
	return (
		<TransitAccountIdContextProvider>
			<AutoloadContextProvider {...{ subsystemAccountReference }}>
				<SetupAutoloadContent {...{ onModalClose, setToastSuccess, subsystemAccountReference }} />
			</AutoloadContextProvider>
		</TransitAccountIdContextProvider>
	);
};


SetupAutoload.propTypes = {

};

export default SetupAutoload;
