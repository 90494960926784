import { useState } from "react";
import { CARD_TYPE_CONTACTLESS } from "utils/Constants.js";
import {
	titleCase,
	creditCardIsValid,
	getCreditCardType,
} from 'utils/FormatHelpers.js';

const useCreditCardType = () => {
	const [ creditCardType, setCreditCardType ] = useState(CARD_TYPE_CONTACTLESS);

	const onBlurCreditCardType = (formHelper) => {
		
		const creditCardNumber = formHelper.getFieldValue('cardNumber').trim();

		const creditCardTye = creditCardIsValid(creditCardNumber)
			? titleCase(getCreditCardType(creditCardNumber)) 
			: CARD_TYPE_CONTACTLESS;

		setCreditCardType(creditCardTye);
	};

	return {
		creditCardType, setCreditCardType,
		onBlurCreditCardType,
	};
};

export default useCreditCardType;
