import React from 'react';
import { values } from 'lodash';

import CmsContentList  from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Container from "components/Container.js";

import * as callout from './Callout.module.css';


const cms = {
	headline: 'miscText.landing-headline',
};


const Callout = () => {
	return (
		<CmsContentList list={values(cms)}>{() => (
			<Container overrideClass={callout.container}>
				<CmsContentRenderer.H2
					contentKey={cms.headline}
					className={callout.title}
					fallbackValue="Charlie gets you going faster and makes it easier to pay your fare."
				/>
			</Container>
		)}</CmsContentList>
	);
};

export default Callout;
