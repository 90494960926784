import React, {
	useState,
	useEffect,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { values } from 'lodash';
import { useInterval } from 'react-use';
import { useCookies } from 'react-cookie';
import { LogoutContext } from "components/auth/Logout.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import PublicAppVars from 'utils/PublicAppVars.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import Modal from 'components/Modal.js';
import Button, {
	Primary,
	Secondary,
} from 'components/Button.js';
import {
	en,
	useLanguage,
	chinese_Lang_mapping,
} from 'components/data/Language.js';
import formatDuration from 'date-fns/formatDuration';
import { intervalToDuration } from "date-fns";
import { getCookieExpiresDate } from 'components/data/session-user/SessionCheck.js';

import * as modalStyles from 'components/Modal.module.css';
import * as style from './SessionTimeout.module.css';

const cms = {
	header: 'miscText.timeout-header',
	text: 'miscText.timeout-text',
	logout: 'miscText.timeout-logout',
	extend: 'miscText.timeout-extend',
};

const getLocaleObj = async (locale) => (await import(`date-fns/locale/${locale}`)).default;

const getTimeRemaining = async (secondsLeft, locale) => {
	const localeObj = await getLocaleObj(locale);
	const time = formatDuration(
		intervalToDuration({ start: 0, end: secondsLeft * 1000 }),
		{ locale: localeObj }
	);
	return time;
};

const COUNTDOWN_INTERVAL = 1000;

/**
 * Renders a modal with a timer counting down till the user is
 * automatically logged out.
 */
const SessionTimeout = ({
	modalActive = false,
	onRequestClose = () => { },
}) => {
	const [ cookies ] = useCookies();
	const [ secondsLeft, setSecondsLeft ] = useState(PublicAppVars.SESSION_FRONTEND_WARNING);
	const [ formatted, setFormatted ] = useState(null);
	const language = useLanguage();

	const locale = language === en ? 'en-US'
		: chinese_Lang_mapping[ language ]
			? chinese_Lang_mapping[ language ]
			: language;

	useEffect(() => {
		getTimeRemaining(secondsLeft, locale)
			.then(result => setFormatted(result));
	},[ locale, secondsLeft ]);

	useEffect(() => {
		if (!modalActive && secondsLeft !== PublicAppVars.SESSION_FRONTEND_WARNING) {
			setSecondsLeft(PublicAppVars.SESSION_FRONTEND_WARNING);
		}
	}, [ modalActive, secondsLeft ]);

	const countdownEvent = () => {
		const expires = getCookieExpiresDate(cookies);
		setSecondsLeft(Math.ceil((expires.getTime() - (new Date()).getTime()) / 1000));
	};

	useInterval(countdownEvent, modalActive ? COUNTDOWN_INTERVAL : null);

	return (
		<Modal
			title={<CmsContentRenderedInline
				contentKey={cms.header}
				fallbackValue="Your session is about to end"
			/>}
			onModalClose={onRequestClose}
			{...{ modalActive }}
			data-qa="IdleTimeOutNotificationModal"
			overrideClass={style.modalWrapper}
			overrideOverlayClass={style.overlay}
		>
			<CmsContentList list={values(cms)}>{() => (<>
				<p className={modalStyles.text}>
					<CmsContentRenderer.Span
						contentKey={cms.text}
						fallbackValue={`To protect your account's security, we automatically log you out after a period of inactivity. Time remaining:`}
					/>
					{" "}
					{formatted}
				</p>

				<div className={modalStyles.actions}>
					<LogoutContext.Consumer>
						{logoutAndClearCache => (
							<Button
								text={<CmsContentRenderer.Span
									contentKey={cms.logout}
									fallbackValue="Log out"
								/>}
								typeStyle={Secondary}
								onClick={logoutAndClearCache}
								overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
								data-qa="LogMeOutModalBtn"
							/>
						)}
					</LogoutContext.Consumer>

					<Button
						text={<CmsContentRenderer.Span
							contentKey={cms.extend}
							fallbackValue="Stay logged in"
						/>}
						typeStyle={Primary}
						onClick={onRequestClose}
						overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
						data-qa="CloseModalBtn"
					/>
				</div>
			</>)}</CmsContentList>
		</Modal>
	);
};

SessionTimeout.propTypes = {
	modalActive: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	countDown: PropTypes.oneOfType([
		PropTypes.string, PropTypes.func,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default SessionTimeout;
