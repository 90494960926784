import React, { Fragment } from 'react';
import cx from "classnames";
import {
	values,
} from 'lodash';
import {
	useCartContext,
	getCartProducts,
	getCartBalance,
	getCartTotal,
	getCartSubtotal,
	getCartMediaOption,
} from 'context/CartProvider.js';
import { centsToDisplay } from 'utils/FormatHelpers';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Panel from 'components/Panel';
import CmsContentList from 'components/data/CmsContentList.js';
import { ReMapOpenTransitName } from 'components/account/card/activate-card/ActivateCard.js';

import * as style from './PurchaseTable.module.css';
import { charlieCardCmskeys } from "./card/CharlieCardCmskeys.js";

const cms = {
	products: 'miscText.purchasehistory-moreinfo-products',
	amount: 'miscText.purchasehistory-table-amount',
	cost: 'miscText.purchase-pass-cost',
	total: 'miscText.purchase-cart-total',
	subTotal: 'miscText.purchase-cart-subtotal',
	reloadBalance: 'miscText.purchase-header-reload-balance',
	enablementFee: 'miscText.cardinfo-enablementfee-label',

	charlieCard: 'miscText.purchase-cart-cards',
	balanceApplied: 'miscText.purchase-cart-balance-applied',
	...charlieCardCmskeys,
};

const SubRow = ({
	label,
	columnOne,
	columnTwo,
}) =>
	<div className={style.tableLineItem}>
		<div className={style.tableColumnOne}>
			{label}
		</div>
		<div className={style.tableColumnTwo}>
			{columnOne}
		</div>
		<div className={cx(style.tableColumnThree, style.boldMobile)}>
			{columnTwo}
		</div>
	</div>;

const CardFee = ({
	quantity = 1, // set up default = 1 if no quantity when unpaid fee
	feeAmount,
}) => (
	<SubRow
		label={(
			<>
				<CmsContentRenderer.Span
					className={style.boldMobile}
					contentKey={cms.enablementFee}
					fallbackValue="Card Fee"
				/>
				<div className={style.hideDesktop}>
					<CmsContentRenderer.Span
						contentKey={cms.amount}
						fallbackValue="Amount"
					/>
					{":"}&nbsp;
					<span>{quantity}</span>
				</div>
			</>
		)}
		columnOne={quantity}
		columnTwo={centsToDisplay(feeAmount)}
	/>);

const MediaOptions = ({ cmsContent }) => {
	const { cart } = useCartContext();
	const wsMediaProduct = getCartMediaOption(cart);

	if (!wsMediaProduct) {
		return null;
	}

	return (
		<Fragment key={wsMediaProduct.id}>
			<SubRow
				label={(
					<>
						<span className={style.boldMobile}>{
							ReMapOpenTransitName({
								tokenType: wsMediaProduct.mediaType,
								cmsContent,
								tokenNickname: wsMediaProduct.nameLong,
							})
						}</span>
						<div className={style.hideDesktop}>
							<CmsContentRenderer.Span
								contentKey={cms.amount}
								fallbackValue="Amount"
							/>
							{":"}&nbsp;
							<span>{wsMediaProduct.quantity}</span>
						</div>
					</>
				)}
				columnOne={wsMediaProduct.quantity}
				columnTwo={centsToDisplay(wsMediaProduct.price)}
			/>
			<CardFee {...{
				feeAmount: wsMediaProduct.enablementFeeAmount,
			}} />
		</Fragment>
	);
};

const Products = () => {
	const { cart } = useCartContext();
	const productList = getCartProducts(cart);

	return productList.map(wsTransitAccountProduct =>
		<SubRow
			key={wsTransitAccountProduct.id}
			label={(
				<>
					<span className={style.boldMobile}>{wsTransitAccountProduct.productLongName}</span>
					<div className={style.hideDesktop}>
						<CmsContentRenderer.Span
							contentKey={cms.amount}
							fallbackValue="Amount"
						/>
						{":"}&nbsp;
						<span>{wsTransitAccountProduct.quantity}</span>
					</div>
				</>
			)}
			columnOne={wsTransitAccountProduct.quantity}
			columnTwo={centsToDisplay(wsTransitAccountProduct.price)}
		/>
	);
};

const Balance = () => {
	const { cart } = useCartContext();
	const balance = getCartBalance(cart);

	return Boolean(balance)
		? (
			<SubRow
				label={(
					<>
						<CmsContentRenderer.Span
							className={style.boldMobile}
							contentKey={cms.reloadBalance}
							fallbackValue="Reload Balance"
						/>
						<div className={style.hideDesktop}>
							<CmsContentRenderer.Span
								contentKey={cms.amount}
								fallbackValue="Amount"
							/>
							{":"}&nbsp;
							<span>{centsToDisplay(balance)}</span>
						</div>
					</>
				)}
				columnOne={centsToDisplay(balance)}
				columnTwo={centsToDisplay(balance)}
			/>
		)
		: null;
};


const PurchaseTable = () => {
	const { cart } = useCartContext();
	const wsMediaOption = getCartMediaOption(cart);
	const unpaidFee = cart.unpaidFee;

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Panel overrideClass={style.table}>
				<div className={style.tableHeader}>
					<div className={style.tableColumnOne}>
						<CmsContentRenderer.Span
							contentKey={cms.products}
							fallbackValue="Product"
							role="columnheader"
						/>
					</div>
					<div className={style.tableColumnTwo}>
						<CmsContentRenderer.Span
							contentKey={cms.amount}
							fallbackValue="Amount"
							role="columnheader"
						/>
					</div>
					<div className={style.tableColumnThree}>
						<CmsContentRenderer.Span
							contentKey={cms.cost}
							fallbackValue="Cost"
							role="columnheader"
						/>
					</div>
				</div>
				<div>
					<div>
						<MediaOptions {...{ cmsContent }} />
						<Products />
						<Balance />
						{Boolean(unpaidFee) &&
							<CardFee {...{
								feeAmount: unpaidFee,
							}} />
						}
					</div>
					<div className={style.tableSummary}>

						<div className={style.tableLineItem}>
							<div className={style.tableColumnOne}>
								<CmsContentRenderer.Span
									className={style.hideDesktop}
									contentKey={cms.subTotal}
									fallbackValue="Subtotal"
								/>
							</div>
							<div className={style.tableColumnTwo}>
								<CmsContentRenderer.Span
									className={style.hideMobile}
									contentKey={cms.subTotal}
									fallbackValue="Subtotal"
								/>
							</div>
							<div className={style.tableColumnThree}>
								{centsToDisplay(getCartSubtotal(cart))}
							</div>
						</div>

						{cart?.appliedStoredValue
							? <div className={style.tableLineItem}>
								{/* Todo: add missing cms key */}
								<div className={style.tableColumnOne}>
									<CmsContentRenderer.Span
										className={style.hideDesktop}
										fallbackValue="Balance Applied"
										contentKey={cms.balanceApplied}
									/>
								</div>
								<div className={style.tableColumnTwo}>
									<CmsContentRenderer.Span
										className={style.hideMobile}
										fallbackValue="Balance Applied"
										contentKey={cms.balanceApplied}
									/>
								</div>
								<div className={style.tableColumnThree}>
									- {centsToDisplay(cart.appliedStoredValue)}
								</div>
							</div>
							: null}

						{wsMediaOption && <div className={style.tableLineItem}>
							<div className={style.tableColumnOne}>
								<CmsContentRenderer.Span
									className={style.hideDesktop}
									fallbackValue="Charlie Cards"
									contentKey={cms.charlieCard}
								/>
							</div>
							<div className={style.tableColumnTwo}>
								<CmsContentRenderer.Span
									className={style.hideMobile}
									fallbackValue="Charlie Cards"
									contentKey={cms.charlieCard}
								/>
							</div>
							<div className={style.tableColumnThree}>
								{`x${wsMediaOption.quantity || 0}`}
							</div>
						</div>}

						<div className={cx(style.tableLineItem, style.bold)}>
							<div className={style.tableColumnOne}>
								<CmsContentRenderer.Span
									className={style.hideDesktop}
									contentKey={cms.total}
									fallbackValue="Total"
								/>
							</div>
							<div className={style.tableColumnTwo}>
								<CmsContentRenderer.Span
									className={style.hideMobile}
									contentKey={cms.total}
									fallbackValue="Total"
								/>
							</div>
							<div className={style.tableColumnThree}>
								{centsToDisplay(getCartTotal(cart))}
							</div>
						</div>
					</div>
				</div>
			</Panel>
		)}</CmsContentList>
	);
};

export default PurchaseTable;
