import React from 'react';
import { values } from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import TransferProvider from 'context/TransferContext.js';
import PurseBalanceProvider from 'context/PurseBalanceContext.js';
import TransitAccountIdContext from 'context/TransitAccountIdContext.js';
import { StepContextProvider, useStepContext } from 'context/StepContext.js';

import CmsContentList from 'components/data/CmsContentList.js';
import TransitAccount from 'components/data/transit-account/TransitAccount.js';

import TransferProducts from './TransferProducts.js';
import TransferProductsConfirmation from './TransferProductsConfirmation.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";

const cms = {
	header: 'miscText.transfer-header',
	transferStepOneHeader: 'miscText.transfer-subheader',
	transferStepTwoHeader: 'miscText.transfer-confirm-header',
};


const TRANSFER_FLOW_SELECT_PRODUCTS = 0;
const TRANSFER_FLOW_CONFIRMATION = 1;

export const TRANSFER_FLOW_STEPS = [
	{
		step: TRANSFER_FLOW_SELECT_PRODUCTS,
		label: <CmsContentRendered.Span
			contentKey={cms.transferStepOneHeader}
			fallbackValue="Step 1: Select items for transfer"
		/>,
	},
	{
		step: TRANSFER_FLOW_CONFIRMATION,
		label: <CmsContentRendered.Span
			contentKey={cms.transferStepTwoHeader}
			fallbackValue="Step 2: Confirm Transfer"
		/>,
	},
];
/**
 * Return the current step of the flow
 */
const CurrentStep = ({ transitAccountQ }) => {
	const { subsystemAccountReference: transit_account_id } = transitAccountQ;
	const { step } = useStepContext();

	const redirectUrl = getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id });

	switch (step) {
		case TRANSFER_FLOW_SELECT_PRODUCTS:
			return <TransferProducts {...{ transitAccountQ, takeoverTitle: cms.header }} cancelUrl={redirectUrl} />;
		case TRANSFER_FLOW_CONFIRMATION:
			return <TransferProductsConfirmation {...{ transitAccountQ }} cancelUrl={redirectUrl} />;
	}
};

const TransferFlow = () => (
	<TransitAccountIdContext.Consumer>{(subsystemAccountReference) => (
		<TransitAccount {...{ subsystemAccountReference, transferProductInfo: true }}>{({ transitAccountQ }) => (
			<CmsContentList list={values(cms)}>{() =>
				<StepContextProvider min={TRANSFER_FLOW_SELECT_PRODUCTS} max={TRANSFER_FLOW_CONFIRMATION}>
					<PurseBalanceProvider {...{ subsystemAccountReference }}>
						<TransferProvider>
							<CurrentStep {...{ transitAccountQ }} />
						</TransferProvider>
					</PurseBalanceProvider>
				</StepContextProvider>
			}</CmsContentList>
		)}</TransitAccount>
	)}</TransitAccountIdContext.Consumer>
);

export default TransferFlow;
