import {
	find,
	head,
	reduce,
	includes,
} from 'lodash';
import {
	types,
	ORDER_TYPE,
} from 'server/api-types/WSPaymentHistoryFactory.js';
import { BANKCARD_TYPES } from 'server/api-types/WSPaymentHistoryInfoFactory.js';
import { PAYMENT_STATUS } from 'server/api-types/WSPaymentHistoryInfo.js';

export const isMultiPurchase = (orderLineItems) => orderLineItems.length > 1;

export const getMultiPayment = (wsPaymentHistory) =>
	(wsPaymentHistory.payments.length > 1 && wsPaymentHistory.orderType === ORDER_TYPE.sale);

export const getPayment = wsPaymentHistory => {
	return find(wsPaymentHistory.payments, wsPaymentHistoryInfo =>
		(includes(BANKCARD_TYPES, wsPaymentHistoryInfo.paymentType)) || head(wsPaymentHistory.payments));
};

export const getPaymentStatus = wsPaymentHistory => getPayment(wsPaymentHistory).paymentStatus;

export const wsPaymentHistoryIsRefund = wsPaymentHistory => {
	return wsPaymentHistory.type === types.travel
		? wsPaymentHistory.amount < 0
		: wsPaymentHistory?.orderType === ORDER_TYPE.refund;
};

export const getFareSubtotal = (trips) => {
	return reduce(
		trips,
		(accumulator, wsTravelPaymentHistoryDetail) => {
			accumulator += wsTravelPaymentHistoryDetail.totalFare;
			return accumulator;
		}, 0);
};

export const getAmountCollected = (wsPaymentHistory) => {

	const paymentStatus = getPaymentStatus(wsPaymentHistory);

	return paymentStatus === PAYMENT_STATUS.declined
		? getFareSubtotal(wsPaymentHistory.trips)
		: wsPaymentHistory.amount;
};


export const getDiscount = (wsPaymentHistory) => {
	const {
		amount,
		trips,
		totalDiscountAmount,
	} = wsPaymentHistory;

	// until totalDiscountAmount becomes available, we calculate the discount
	return totalDiscountAmount
	 	? totalDiscountAmount
		: (getFareSubtotal(trips) - amount);
};
