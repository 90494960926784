// extracted by mini-css-extract-plugin
export var charCount = "components-forms-__Input-module___charCount";
export var charCountWrapper = "components-forms-__Input-module___charCountWrapper";
export var container = "components-forms-__Input-module___container";
export var error = "components-forms-__Input-module___error";
export var input = "components-forms-__Input-module___input";
export var inputSuffix = "components-forms-__Input-module___inputSuffix";
export var label = "components-forms-__Input-module___label";
export var maxLengthErrorMsg = "components-forms-__Input-module___maxLengthErrorMsg";
export var optionalLabel = "components-forms-__Input-module___optionalLabel";
export var tooltip = "components-forms-__Input-module___tooltip";