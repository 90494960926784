// extracted by mini-css-extract-plugin
export var actions = "pages-guest-__ViewTransactionHistory-module___actions";
export var btn = "pages-guest-__ViewTransactionHistory-module___btn";
export var col = "pages-guest-__ViewTransactionHistory-module___col";
export var contentContainer = "pages-guest-__ViewTransactionHistory-module___contentContainer";
export var imgContainer = "pages-guest-__ViewTransactionHistory-module___imgContainer";
export var input = "pages-guest-__ViewTransactionHistory-module___input";
export var mainContent = "pages-guest-__ViewTransactionHistory-module___mainContent";
export var mainPanelContainer = "pages-guest-__ViewTransactionHistory-module___mainPanelContainer";
export var passwordWrapper = "pages-guest-__ViewTransactionHistory-module___passwordWrapper";
export var subTitleViewHistory = "pages-guest-__ViewTransactionHistory-module___subTitleViewHistory";
export var tabLabel = "pages-guest-__ViewTransactionHistory-module___tabLabel";
export var tabwIcon = "pages-guest-__ViewTransactionHistory-module___tabwIcon";
export var text = "pages-guest-__ViewTransactionHistory-module___text";
export var title = "pages-guest-__ViewTransactionHistory-module___title";