import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as icon from 'components/icons/Icon.module.css';
import { wrapper } from 'components/Icon';



const DropdownContactless = (props) => wrapper( 
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 18">
		<g fill="none" transform="translate(1.6 1)">
			<path fill="#000" d="M23.3739574,0 L1.49195497,0 C0.667971281,0 0,0.667971281 0,1.49195497 L0,14.6390681 C0,15.4630518 0.667971281,16.1310174 1.49195497,16.1310174 L23.3739574,16.1310174 C24.1979411,16.1310174 24.8659067,15.4630518 24.8659067,14.6390681 L24.8659067,1.49195497 C24.8659067,0.667971281 24.1979411,0 23.3739574,0 Z" />
			<path fill="#FFF" stroke="#165C96" strokeWidth="1.5" d="M23.2496354,0.124330524 L1.61628549,0.124330524 C0.792301805,0.124330524 0.124330524,0.792301805 0.124330524,1.61628549 L0.124330524,14.5147461 C0.124330524,15.3387298 0.792301805,16.0066954 1.61628549,16.0066954 L23.2496354,16.0066954 C24.0736191,16.0066954 24.7415847,15.3387298 24.7415847,14.5147461 L24.7415847,1.61628549 C24.7415847,0.792301805 24.0736191,0.124330524 23.2496354,0.124330524 Z" />
			<g fill="#165C96" transform="translate(7.9 2.4)">
				<path d="M6.91998326,11.2136731 C6.73893296,11.1614649 6.5554397,10.9467711 6.51689526,10.7418156 C6.49174185,10.6083646 6.52241454,10.5157604 6.71432242,10.1457945 C7.48982572,8.65078238 7.82686338,7.27497452 7.82686338,5.60503339 C7.82686338,3.99117776 7.52846064,2.73547581 6.78715875,1.23045489 C6.62800459,0.907377187 6.497804,0.607382904 6.497804,0.564011322 C6.497804,0.4038701 6.63225714,0.171683361 6.77783932,0.0806120576 C6.97752848,-0.0442728485 7.31212318,-0.0209189202 7.47227262,0.128942967 C7.53379896,0.186561346 7.69376744,0.448233547 7.8276777,0.710266425 C8.35807093,1.74820878 8.72152872,2.88696075 8.90095038,4.07224023 C9.01196923,4.80576999 9.03404633,6.196005 8.94537602,6.86749806 C8.78169786,8.10624813 8.45361771,9.21154717 7.92829136,10.294394 C7.61079736,10.9489352 7.41147012,11.2181816 7.24462517,11.2181816 C7.21123808,11.2181816 7.15676918,11.2264772 7.12365353,11.2366664 C7.09035692,11.2466752 6.99879126,11.2363959 6.91998326,11.2136731 L6.91998326,11.2136731 Z" />
				<path d="M4.76140396 10.0882664C4.48372112 10.0077449 4.28348908 9.66825636 4.36374476 9.4138879 4.38319794 9.35230206 4.51394141 9.06862847 4.65418527 8.78351218 5.24818587 7.57632147 5.4952865 6.28220727 5.37422439 5.01162733 5.27469649 3.96764362 5.08695067 3.30922515 4.61401219 2.34585306 4.29669915 1.69951732 4.28195092 1.59933888 4.46643946 1.3391094 4.64188 1.09168399 5.07971228 1.05155852 5.30971221 1.26183404 5.46913781 1.40754813 5.96496771 2.43944911 6.15633272 3.02383834 6.78969208 4.95779608 6.69305954 7.03386112 5.88136427 8.93373474 5.46560909 9.90693531 5.17372091 10.2078313 4.76140396 10.0882664zM2.53541772 8.94699003C2.36386782 8.86989501 2.2162046 8.68910495 2.17603152 8.50687217 2.15151147 8.39587339 2.19367511 8.27306238 2.3972549 7.86224965 3.14489039 6.35326127 3.1529431 4.89981737 2.42186544 3.42590497 2.10084272 2.77857736 2.09378529 2.64972499 2.36531549 2.39445483 2.49931624 2.26848789 2.5562281 2.246216 2.74442631 2.246216 3.06011071 2.246216 3.22378887 2.40428332 3.50590523 2.98172949 3.93975639 3.86990012 4.11736845 4.64039941 4.11592955 5.62757627 4.11438261 6.64225487 3.94835198 7.33881508 3.48482341 8.27540679 3.21935535 8.81164545 3.08815949 8.95321173 2.82241999 8.98955008 2.74135 9.00064094 2.61223517 8.98143482 2.53541772 8.94699003L2.53541772 8.94699003zM.458270085 7.88388946C.322369261 7.82780397.167196218 7.64349729.121051464 7.4832659.0695683959 7.30418906.111732033 7.16208175.315764228 6.82773285.578155964 6.39771399.639229902 6.16696997.639229902 5.60494281.639229902 5.04300581.578155964 4.81217162.315764228 4.38215276.226822478 4.23634851.137518808 4.06168998.117251308 3.9939726.032743073 3.71056953.269709954 3.3632362.588018274 3.30372426.857829362 3.25322928 1.04313222 3.37324502 1.29122813 3.75917094 2.00954812 4.87655272 2.00945764 6.3692204 1.29086621 7.47713436 1.03743198 7.86801961.754772746 8.00615945.458270085 7.88388946L.458270085 7.88388946z" />
			</g>
		</g>
	</svg>, props
);

DropdownContactless.propTypes = {
	overrideClass: PropTypes.string,
};

export default DropdownContactless;
