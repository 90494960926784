import React from "react";
import {
	levels,
	log,
} from "utils/Logger.js";
import cx from "classnames";

import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";
import GenericIconAndMaskedValue from "components/card-icons/GenericIconAndMaskedValue.js";
import StoredCreditChip from "components/card-icons/StoredCreditChip.js";
import { BlankCheck } from "components/Icon.js";


import * as style from "components/DataTable.module.css";

/**
 * When we have a WSPaymentHistoryInfo, we don't have a way of getting the nickname
 *
 * @param {WSPaymentHistoryInfo} wsPaymentHistoryInfo
 * @returns {JSX.Element|string}
 */
const PaymentHistoryChip = ({
	wsPaymentHistoryInfo,
	iconClassName,
	maskedPanClassName,
	className = style.cardIconAlignment,

	overrideClass,
}) => {
	const {
		paymentType,
	} = wsPaymentHistoryInfo || {};

	switch (paymentType) {
		case PAYMENT_TYPES.debitCard:
		case PAYMENT_TYPES.creditCard:
		case PAYMENT_TYPES.creditCardNotPresent: {
			// Now that we know it's WSPaymentHistoryCreditDebitInfo:
			const {
				creditCardType,
				maskedPAN,
				maskedPan,
			} = wsPaymentHistoryInfo;
			return (
				<GenericIconAndMaskedValue {...{
					iconType: paymentType,
					creditCardType,
					maskedPAN,
					maskedPan,
					className,
					iconClassName,
					maskedPanClassName,
				}} />
			);
		}
		case PAYMENT_TYPES.directDebit:
			return (
				<GenericIconAndMaskedValue
					showName={false}
					iconType={PAYMENT_TYPES.directDebit}
					maskedBankAccountNumber={wsPaymentHistoryInfo.maskedAccountNumber}
					{...{
						className,
						iconClassName,
						maskedPanClassName,
					}}
				/>
			);
		case PAYMENT_TYPES.check:
			return <div className={iconClassName}>
				<BlankCheck overrideClass={cx(style.cardIconImg, style.fitContentHeight)} />
			</div>;
		case PAYMENT_TYPES.storedValue:
			return <StoredCreditChip {...{ overrideClass }}/>;
		case PAYMENT_TYPES.thirdParty: {
			return <GenericIconAndMaskedValue
				iconType={PAYMENT_TYPES.thirdParty}
				{...{
					className,
					iconClassName,
					maskedPanClassName,
				}}
			/>;
		}
		default:
			log(null, levels.error, `unknown WSPaymentHistoryInfo.paymentType ${paymentType}`);
			return "";
	}
};
export default PaymentHistoryChip;
