/*
 * CartToggle is the fixed container that is shown, even when the cart is collapse.
 * All of this components contents are the content within that bar, shown depending on desktop or mobile.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import {
	getCartTotal,
	useCartContext,
} from 'context/CartProvider.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';

import Container from 'components/Container.js';
import { SmallChevron } from 'components/Icon.js';
import NextStep from './NextStep.js';

import * as cartStyle from 'components/payments/Cart.module.css';



const cms = {
	cart: 'miscText.purchase-cart-subheader',
	total: 'miscText.purchase-cart-total',
};


const CartToggle = ({
	isOpen,
	setIsOpen,
	nextText,
	formHelper,
	submitting,
	disableNext = false,
}) => {
	const { cart } = useCartContext();

	return (
		<div className={cartStyle.toggleHeader}>
			<Container overrideClass={cx(cartStyle.innerContainer, cartStyle.headerbackgroundColor)}>
				<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
					<div className={cartStyle.toggleSubHeader}>
						<CmsContentRenderer.H3 contentKey={cms.cart}
							fallbackValue="Cart"
							className={cartStyle.title}
						/>
						<SmallChevron
							overrideClass={cx(
								cartStyle.desktopHidden,
								cartStyle.chevronIcon,
								isOpen
									? cartStyle.chevronIconClose
									: cartStyle.chevronIconTrigger)}
							data-qa="CollapsibleToggle"
							role="button"
							aria-expanded={isOpen}
							tabIndex={0}
							onClick={() => setIsOpen(!isOpen)}
							onKeyDown={() => setIsOpen(!isOpen)}
						/>
					</div>

					<div className={cartStyle.flexRow}>

						{/* MOBILE & DESKTOP Total and Next */}
						<div className={cx(cartStyle.toggleSummaryDetails,
							isOpen ? cartStyle.hidden : cartStyle.mobileHidden,
						)}
						>
							<div className={cx(cartStyle.totals, cartStyle.lastcolTotal, cartStyle.totalHeader)}>
								<CmsContentRenderer.Span
									contentKey={cms.total}
									fallbackValue="Total"
									className={cartStyle.totalsFinalLabel}
								/>
								<span className={cartStyle.totalsFinalPrice}>
									{centsToDisplay(getCartTotal(cart))}
								</span>
							</div>
							{disableNext
								? null
								: <NextStep
									{...{ nextText, submitting }}
									additionalClassNames={cx(cartStyle.fullWidth)}
								/>
							}
						</div>

						{/* Chevron is visible at all times, all screen sizes */}
						<div
							className={cartStyle.chevronButton}
							type="button"
							aria-expanded={isOpen}
							data-qa="CollapsibleToggle"
							onClick={() => setIsOpen(!isOpen)}
						>
							<SmallChevron
								overrideClass={cx(
									cartStyle.mobileHidden,
									cartStyle.chevronIcon,
									isOpen
										? cartStyle.chevronIconClose
										: cartStyle.chevronIconTrigger)}
							/>
						</div>
					</div>
				</>)}</CmsContentList>
			</Container>
			<div className={cx(cartStyle.errorWrapper, cartStyle.mobileHidden, cartStyle.errorDesktop)}>
				{formHelper.getFieldErrorJsx('')}
			</div>
		</div>
	);
};

CartToggle.propTypes = {
	nextText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
	]),

	isOpen: PropTypes.bool,

	setIsOpen: PropTypes.func,
};

export default CartToggle;
