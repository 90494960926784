import {
	clone,
	merge,
} from "lodash";
import WSRefundMethod, { WSRefundMethodFragment } from "./WSRefundMethod.js";
import WSPaymentInfo, { WSPaymentInfoType, InputWSPaymentInfoType } from "./WSPaymentInfo.js";

// 2.7.38.1.WSOriginalPaymentRefundMethod
// Extends WSRefundMethod when type is OriginalPayment. Indicates
// the refund is done to a previous authorized payment. The refund
// amount must be less or equal to the payment confirmed amount.

export default class WSOriginalPaymentRefundMethod extends WSRefundMethod {
	constructor({
		authRefNbr,

		// WSOriginalPaymentRefundMethod.payment is a required field per the api doc
		// but we are not getting it.
		// Making it optional for now until we hear back fro ALessandro.
		// https://reflexions.slack.com/archives/GA82SPCTV/p1599089442091500
		//
		// UPDATE: 5/26/21 - WSOriginalPaymentRefundMethod.payment is now being returned from the BO
		payment,

		// super props
		type,
		refundPaymentType,
		refundAmount,
		priority,
	}) {

		super({
			type,
			refundPaymentType,
			refundAmount,
			priority,
		});

		// string(40)
		// (Required) A unique identifier for this authorization.
		// NOTE: This identifier is not guaranteed to be unique
		// across credit card and account authorizations.
		this.authRefNbr = authRefNbr;

		// WSPaymentInfo
		// (Required) The original payment and its current
		// state.
		this.payment = payment;
	}

	static fromBackoffice(data) {
		const result = new WSOriginalPaymentRefundMethod(data);
		result.payment = data.payment
			? WSPaymentInfo.fromBackoffice(data.payment)
			: null;

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const result = clone(this);
		result.payment = this.payment
			? this.payment.toBackoffice()
			: null;

		return merge(
			result,
			super.toBackoffice(),
		);
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.payment = this.payment
			? this.payment.toResolver()
			: null;

		return {
			...merge(
				forGraphql,
				super.toResolver(),
			),
			// super's id field is forGraphql.type + forGraphql.refundAmount, which is not always unique
			// concat `authRefNbr` to make id unique
			id: forGraphql.id + forGraphql.authRefNbr,
		};
	}
}

// TODO: consider replacing InputWSPaymentInfo with a new InputWSPaymentInfoFactory
export const InputWSOriginalPaymentRefundMethodType = [
	...InputWSPaymentInfoType, `
		input InputWSOriginalPaymentRefundMethod {
			${WSRefundMethodFragment}
			authRefNbr: String
			payment: InputWSPaymentInfo
		}
	`,
];

export const WSOriginalPaymentRefundMethodType = [
	...WSPaymentInfoType, `
		type WSOriginalPaymentRefundMethod implements WSRefundMethodFactory {
			${WSRefundMethodFragment}
			authRefNbr: String!
			payment: WSPaymentInfo
		}
	`,
];
