import { gql } from "@apollo/client";
import { get } from "lodash";
import useStdQuery from "components/data/hooks/useStdQuery.js";

export const SOCIAL_SIGNIN_OPTIONS = gql`
	query getSocialSigninOptions {
		socialSigninOptions {
			name
			value
		}
		session {
			id
			customer {
				contact {
					systemGenPassword
					id
				}
				id
			}
		}
	}
`;

// returns the new systemGenPassword value to refresh PIN_AND_SYSTEMGENPASSWORD_QUERY but
// only part of SOCIAL_SIGNIN_OPTIONS (doesn't return the new socialSigninOptions)
export const UPDATE_SOCIAL_SIGNIN_OPTIONS = gql`
	mutation delinkSocialAccount(
		$linkingKey: String!
		$newPassword: String
	) {
		delinkSocialAccount(
			linkingKey: $linkingKey
			newPassword: $newPassword
		) {
			contact {
				systemGenPassword
				id
			}
			id
		}
	}
`;

export const useSocialSigninOptions = () => {
	const { loading, data } = useStdQuery(SOCIAL_SIGNIN_OPTIONS);
	const isSystemGenPassword = get(data, 'session.customer.contact.systemGenPassword', null);
	return {
		loading,
		socialSigninOptions: data?.socialSigninOptions ?? [],
		isSystemGenPassword: isSystemGenPassword,
	};
};