import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import { SmallCheck } from 'components/Icon.js';

import * as card from 'components/account/ProductCard.module.css';



const cms = {
	inCart: 'miscText.purchase-in-cart',
};

const InCart = () => {
	return (
		<div className={card.isInCart}>
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
				<SmallCheck overrideClass={cx(card.checkMark, card.mobileCheckmark)} />
				<CmsContentRenderer.Span contentKey={cms.inCart}
					fallbackValue={'In Cart'}
					className={card.isInCartText}
				/>
			</>)}</CmsContentList>
		</div>
	);
};

InCart.propTypes = { };

export default InCart;
