import React from 'react';
import PropTypes from 'prop-types';

import Control from '../Control.js';
import * as controlStyle from 'components/forms/Control.module.css';
import * as style from "./RadioInput.module.css";

const RadioInput = ({
	hideLabel = true,
	labelFirst = true,
	name,
	label = '',
	children,

	// legacy use `additionalClasses`
	overrideClass = '',
	additionalClasses = '',

	// legacy use `error`
	errorMsg = null,
	error = null,

	controlled,
	inputRef,
	value,
	...rest
}) => {

	const valueProp = controlled
		? { value }
		: {};

	const getAriaLabel = () => {
		return hideLabel && !label
			? { [ 'aria-label' ]: label }
			: null;
	};

	const getLabel = () => {
		if (!label) {
			return '';
		}

		return typeof label === 'object'
			? label
			: <p className={controlStyle.label}>{label}</p>;
	};

	const ariaLabel = getAriaLabel();

	return (
		<Control {...{
			additionalClasses,
			label,
			hideLabel,
			labelFirst,
			error,
			errorMsg,
		}}
		target={name}
		>
			<div className={style.radioInput}>
				<label className={style.label}>
					<input
						type="radio"
						name={name}
						className={style.input}
						{...ariaLabel}
						{...valueProp}
						{...rest}
					/>
					<span className={style.radioButton} />

					{getLabel()}
				</label>
			</div>
		</Control>
	);
};

export const BoolRadioInput = (props) => {
	return (
		<>
			<input type="hidden" value={false} name={props.name} />
			<RadioInput value={true} controlled={true} {...props} />
		</>
	);
};

RadioInput.propTypes = {
	/**	Id for the control */
	name: PropTypes.string.isRequired,

	/** Hide default label and use `aria-label` instead */
	hideLabel: PropTypes.bool,

	/** Label the control */
	label: PropTypes.node,

	/** Use either value or defaultValue but not both */
	value: PropTypes.any,

	/** Use either value or defaultValue but not both */
	defaultValue: PropTypes.any,

	error: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
	]),

	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),

	inputRef: PropTypes.any,

	/** Custom classes to override styling */
	additionalClasses: PropTypes.string,
};

export default RadioInput;
