// extracted by mini-css-extract-plugin
export var balanceContent = "pages-account-card-__AutoloadBalance-module___balanceContent";
export var balanceValue = "pages-account-card-__AutoloadBalance-module___balanceValue";
export var balanceWrapper = "pages-account-card-__AutoloadBalance-module___balanceWrapper";
export var bold = "pages-account-card-__AutoloadBalance-module___bold";
export var calendarIconOverride = "pages-account-card-__AutoloadBalance-module___calendarIconOverride";
export var container = "pages-account-card-__AutoloadBalance-module___container";
export var frequencyOption = "pages-account-card-__AutoloadBalance-module___frequencyOption";
export var frequencyOptionContent = "pages-account-card-__AutoloadBalance-module___frequencyOptionContent";
export var frequencyRadio = "pages-account-card-__AutoloadBalance-module___frequencyRadio";
export var frequencySelection = "pages-account-card-__AutoloadBalance-module___frequencySelection";
export var frequencySelectionGroup = "pages-account-card-__AutoloadBalance-module___frequencySelectionGroup";
export var frequencyWrapper = "pages-account-card-__AutoloadBalance-module___frequencyWrapper";
export var italic = "pages-account-card-__AutoloadBalance-module___italic";
export var normalWeight = "pages-account-card-__AutoloadBalance-module___normalWeight";
export var saveButton = "pages-account-card-__AutoloadBalance-module___saveButton";
export var saveText = "pages-account-card-__AutoloadBalance-module___saveText";
export var section = "pages-account-card-__AutoloadBalance-module___section";
export var sectionHeader = "pages-account-card-__AutoloadBalance-module___sectionHeader";
export var selected = "pages-account-card-__AutoloadBalance-module___selected";
export var thresholdSelection = "pages-account-card-__AutoloadBalance-module___thresholdSelection";
export var toastDescription = "pages-account-card-__AutoloadBalance-module___toastDescription";