import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import * as style from './Button.module.css'; // Button.js is in package.json's sideEffects list so that this style gets included in the CSS in the same order as the js was imported
import LoadingIcon from "./icons/LoadingIcon.js";

export const buttonThemeLight = style.themeLight;

export const Primary = style.isPrimary;
export const Secondary = style.isSecondary;
export const Ghost = style.isGhost;
export const GhostAlt = style.isGhostAlt;
export const CharliePrimary = style.isCharliePrimary;
export const CharlieSecondary = style.isCharlieSecondary;
export const Disabled = style.isDisabled;

export const buttonTypeStyleDanger = style.typeStyleDanger;
export const buttonTypeStylePlain = style.typeStylePlain;
export const Alert = style.isAlert;


const Button = forwardRef(({
	text = '',
	children,

	submitting = false,
	disabled = false,

	to = null,
	linkComponent: LinkComponent = Link,
	external = false,

	type = 'submit',
	typeStyle = null,

	// consume these: (used by getDefaultClass)
	theme = buttonThemeLight,
	isPrimary = null,
	overrideClass,
	additionalClassNames = '',
	target,

	...rest
}, ref) => {
	const calcTypeStyle = (isPrimary, disabled) => {
		// disabled here means we were passed the disabled prop
		// when submitting, this disabled=false
		if (disabled) {
			return Disabled;
		}
		return isPrimary
			? Primary
			: Secondary;
	};

	// behaviorally disabled
	const isDisabled = disabled || submitting;

	// type=submit means it's the button that gets submitted when you hit enter in a form
	// type=button is for secondary buttons, and links (where to is set) are always (behaviorally) secondary
	const calcIsPrimary = isPrimary ?? (type === 'submit' && !to);
	const calculatedStyle = typeStyle === null
		? calcTypeStyle(calcIsPrimary, disabled)
		: typeStyle;

	return !to || isDisabled
		? (
			<button
				className={cx(
					style.base,
					calculatedStyle,
					overrideClass,
					theme,
					additionalClassNames,
					isDisabled && style.isDisabled,
				)}
				type={type}
				disabled={isDisabled}
				ref={ref}
				{...rest}
			>
				{submitting && <LoadingIcon overrideClass={style.spinner} />}
				{text
					? <span className={style.text} aria-hidden={submitting}>{text}</span>
					: children
				}
			</button>
		) : external
			? (<a href={to}
				className={cx(
					style.base,
					calculatedStyle,
					overrideClass,
					theme,
					additionalClassNames,
				)}
				ref={ref}
				{...target === "_blank"
					? {
						rel: "noreferrer",
						target:"_blank",
					}
					: null
				}
				{...rest}
			>
				{text || children}
			</a>)
			: (
				<LinkComponent to={to}
					className={cx(
						style.base,
						theme,
						calculatedStyle,
						additionalClassNames,
					)}
					ref={ref}
					disabled={isDisabled}
					{...rest}
				>
					{text || children}
				</LinkComponent>
			);
});

Button.displayName = 'Button';

Button.propTypes = {
	text: PropTypes.string, // legacy. Use children instead
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),

	submitting: PropTypes.bool,
	disabled: PropTypes.bool,

	to: PropTypes.oneOfType([ // makes this an <a> that looks like a button instead of a <button>
		PropTypes.string,
		PropTypes.object,
	]),

	linkComponent: PropTypes.elementType, // in case you want to use HashLink instead
	external: PropTypes.bool,

	type: PropTypes.oneOf([ 'submit', 'reset', 'button' ]),
	typeStyle: PropTypes.string, // defaults to either primary or secondary depending on type

	theme: PropTypes.string,
	isPrimary: PropTypes.bool,
	overrideClass: PropTypes.string, // legacy. Use additionalClassNames instead.
	additionalClassNames: PropTypes.string,
};

export default Button;
