import {
	clone,
	merge,
} from "lodash";
import WSRefundMethod, { WSRefundMethodFragment } from "./WSRefundMethod.js";

// 2.7.38.5.WSSubsystemAccountValueRefundMethod
// Extends WSRefundMethod when type is SubsystemAccountValue and refundType
// is either StoredValue or BenefitValue. Indicates the refund is done to
// subsystem account value purse.

export default class WSSubsystemAccountValueRefundMethod extends WSRefundMethod {
	constructor({
		subsystem,
		subsystemAccountReference,
		purseType,
		purseTypeDescription,
		purseRestriction = null,
		purseRestrictionDescription = null,
		nickname = null,

		// super props
		type,
		refundPaymentType,
		refundAmount,
		priority,
	}) {

		super({
			type,
			refundPaymentType,
			refundAmount,
			priority,
		});

		// string(20)
		// (Required) Unique identifier for the subsystem where
		// the travel token is registered.
		this.subsystem = subsystem;

		// string(20)
		// (Required) Unique identifier for the customer’s
		// account in the subsystem.
		this.subsystemAccountReference = subsystemAccountReference;

		// string(40)
		// (Required) Type of purse it is.
		this.purseType = purseType;

		// string(40)
		// (Optional) Description of the purse type.
		this.purseTypeDescription = purseTypeDescription;

		// string(40)
		// (Optional) Type of the purse restriction.
		this.purseRestriction = purseRestriction;

		// string(40)
		// (Optional) Description of the purse restriction.
		this.purseRestrictionDescription = purseRestrictionDescription;

		// string(30)
		// (Optional) The nickname for this purse.
		this.nickname = nickname;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemAccountValueRefundMethod(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	};

	toBackoffice() {
		const result = clone(this);

		return merge(
			result,
			super.toBackoffice(),
		);
	};

	toResolver() {
		const forGraphql = clone(this);

		return merge(
			forGraphql,
			super.toResolver(),
		);
	};
}

export const InputWSSubsystemAccountValueRefundMethodType = `
	input InputWSSubsystemAccountValueRefundMethod {
		${WSRefundMethodFragment}
		subsystem: String
		subsystemAccountReference: String
		purseType: String
		purseTypeDescription: String
		purseRestriction: String
		purseRestrictionDescription: String
		nickname: String
	}
`;


export const WSSubsystemAccountValueRefundMethodType = [ `
		type WSSubsystemAccountValueRefundMethod implements WSRefundMethodFactory {
			${WSRefundMethodFragment}
			subsystem: String!
			subsystemAccountReference: String!
			purseType: String!
			purseTypeDescription: String!
			purseRestriction: String!
			purseRestrictionDescription: String!
			nickname: String!
		}
	`,
];
