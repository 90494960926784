import {
	clone,
	includes,
} from "lodash";
import { castAmountDueToString } from "utils/FormatHelpers.js";
// 2.7.27 WSPayment
// This object represents the payments being done by the system for an order.

export const CREDIT_TYPES = {
	creditCard: "CreditCard",
	creditCardNotPresent: "CreditCardNotPresent",
};

const CREDIT_DEBIT_TYPES = {
	debitCard: "DebitCard",
	...CREDIT_TYPES,
};

export const PAYMENT_TYPES = {
	noPayment: "NoPayment",
	cash: "Cash",
	check: "Check",
	storedValue: "StoredValue",
	benefitValue: "BenefitValue",
	directDebit: "DirectDebit",
	ppb: "PPB",
	extCollection: "ExtCollection",
	thirdParty: "ThirdParty",
	...CREDIT_DEBIT_TYPES,
};

export const isCreditDebitType = (paymentType) => includes(CREDIT_DEBIT_TYPES, paymentType);

export default class WSPayment {
	constructor({
		paymentType,
		paymentAmount,
	}) {

		// string(20)
		// (Required)  indicates the type of payment.
		// for allowed values
		this.paymentType = paymentType;

		// int
		// (Required) The payment amount. This number is the number of the base unit of currency, such as cents.
		this.paymentAmount = paymentAmount;
	}

	static fromBackoffice(data) {
		const result = new WSPayment(data);
		return result;
	}

	static fromSerialized(data) {
		const result = new WSPayment(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		result.paymentAmount = castAmountDueToString(result.paymentAmount);
		return result;
	}

	toResolver() {
		return this;
	}
}

export const WSPaymentFragment = `
	paymentType: String!
	paymentAmount: Int!
`;

// address1 no longer required as of R11
export const WSPaymentType = [ `
	type WSPayment {
		${WSPaymentFragment}
	}
` ];
