/*
This component renders a resuable modal element. Modal is activated when the props boolean modal active is true.
The modal content displays child elements.
*/
import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from "react-modal";
import cx from 'classnames';

import { useModalContext } from 'context/ModalProvider.js';

import { Close } from "./Icon.js";

import * as modal from './Modal.module.css';
import CmsContent from "components/data/CmsContent.js";
import { getContentOrFallback } from "components/data/CmsContentRenderer.js";
import HidePypestreamChatbot from "components/pypestream-chatbot/HidePypestreamChatbot.js";



// set the app element for react-modal
if (process.env.NODE_ENV !== 'test') {
	ReactModal.setAppElement('#root');
}


/**
 * Data-QA Tags:
 * ModalContainer, ModalTitle, ModalClose
 */

const Modal = ({
	headerTitle = '',
	title = null,
	closeText,
	onModalClose = null,
	modalActive = true,
	overrideClass,
	overrideOverlayClass,
	overrideHeaderClass,
	overrideCloseClass,
	overrideCloseAriaText,
	showCloseBtn = true,
	hideChatbot = true,
	children,
}) => {
	const { setModal } = useModalContext();
	const onRequestClose = onModalClose ?? (() => setModal(null));


	/* Use setAppElement to properly hide the application from screenreaders and other assistive technologies while the modal is open.
	By default, the modal portal will be appended to the document's body. Determine a different parent element by providing a
	function to the parentSelector prop that returns the element to be used */


	return <>
		{(hideChatbot && modalActive) ? <HidePypestreamChatbot /> : null}

		<ReactModal
			isOpen={modalActive}
			onRequestClose={onRequestClose}
			className={cx(modal.content, overrideClass)}
			overlayClassName={cx(modal.overlay, overrideOverlayClass)}
			contentLabel={headerTitle}
			data-qa="mainModalContainer"
			shouldFocusAfterRender={true}
		>
			<div
				className={modal.dialog}
				data-qa="ModalContainer"
			>
				<div className={cx(modal.header, overrideHeaderClass)}>
					<h2 className={modal.title}
						data-qa="ModalTitle"
					>
						{title}
					</h2>

					{showCloseBtn
						&& <CmsContent contentKey={'miscText.general-modal-close-aria'}>{(closeAriaContent) => (
							<button
								className={cx(modal.close, overrideCloseClass)}
								onClick={onRequestClose}
								data-qa="ModalClose"
								aria-label={overrideCloseAriaText || getContentOrFallback(closeAriaContent, 'Close dialog')}
							>
								{closeText ?? null}
								<Close overrideClass={modal.closeIcon} />
							</button>
						)}</CmsContent>
					}
				</div>

				<div
					data-qa="ModalContent"
					className={modal.contents}>
					{children}
				</div>
			</div>
		</ReactModal>
	</>;
};

Modal.propTypes = {
	title: PropTypes.node.isRequired,
	headerTitle: PropTypes.string,
	closeText: PropTypes.node,
	children: PropTypes.node.isRequired,
	onModalClose: PropTypes.func,
};

export default Modal;
