import React from 'react';

import * as style from 'components/icons/Icon.module.css';


const Info = () => (
	<svg
		className={style.info}
		width="15"
		height="15"
		viewBox="-2 -2 44 44"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="none">
			<circle cx="20" cy="20" r="20" fill="#E9EAED" strokeWidth="4" stroke="#494F5C" />
			<path fill="#494F5C" d="M22.5,10.0438333 L22.5,14.0033333 L17.5,14.0033333 L17.5,10.0438333 L22.5,10.0438333 Z M22.5,16.4569167 L22.5,30.6944167 L17.5,30.6944167 L17.5,16.4569167 L22.5,16.4569167 Z" />
		</g>
	</svg>
);

export default Info;
