import { gql } from "@apollo/client";
import { values } from "lodash";
import cx from 'classnames';
import React, { useState } from 'react';
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { GET_ALTERNATES_AND_MANAGED_CUSTOMERS } from "components/data/alternates/Alternates.query.js";
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";

import Button, {
	Primary,
	Secondary,
} from "components/Button.js";
import { useModalContext } from "context/ModalProvider.js";

import * as modalStyles from "components/Modal.module.css";
import RevokeAlternateInviteSuccess from "components/toasts/alternates/RevokeAlternateInviteSuccess.js";
import Modal from "components/Modal.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { useGlobalToastsContext } from "context/ToastProvider";

const cms = {
	title: 'miscText.alternates-cancel-modal-header',
	body: 'miscText.alternates-cancel-modal-description',
	cancel: 'miscText.alternates-cancel-modal-cancel',
	confirm: 'miscText.alternates-cancel-modal-confirm',
	confirmation: 'miscText.alternates-cancel-confirmation',
};

export const REVOKE_INVITE = gql`
	mutation revokeInvite(
		$linkId: ID!
	) {
		CustomerMutationRoute {
			alternates {
				alternateAction(
					linkId: $linkId
					action: "REVOKE"
				)
			}
		}
	}
`;

const RevokeAlternateInvite = ({ wsAlternateContactInfo: { linkId, email } }) => {
	const [ revokeInvite, { loading } ] = useStandardMutation(REVOKE_INVITE, {
		refetchQueries: [
			{ query: GET_ALTERNATES_AND_MANAGED_CUSTOMERS },
		],
	});
	const { setModal } = useModalContext();
	const { setToast } = useGlobalToastsContext();

	const [ error, setError ] = useState(null);

	const kickoffSubmit = async () => {
		try {
			await graphqlErrorMiddleware(revokeInvite({
				variables: {
					linkId,
				},
			}));

			setModal(null);
			setToast(<RevokeAlternateInviteSuccess email={email} />);

		} catch (errorReport) {
			setError(errorReport.display.topLevelMessage);
		}
	};
	return (
		<CmsContentList list={values(cms)} sharedVariables={{ email }}>
			{() => (
				<Modal
					title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue={'Delete Invitation'} />}
				>
					{error
						? <div>
							{error}
						</div>
						: null
					}
					<CmsContentRenderer contentKey={cms.body}
						fallbackValue={'Are you sure you want to cancel the invitation for {{email}}? Their invitation will be retracted.'} />

					<div className={modalStyles.actions}>
						<Button
							type={Secondary}
							onClick={() => setModal(null)}
							overrideClass={cx(modalStyles.btn,modalStyles.leftButton)}
							data-qa="RevokeAlternateInvite"
						>
							<CmsContentRenderer.Span contentKey={cms.cancel} fallbackValue="Cancel" />
						</Button>

						<Button
							type={Primary}
							submitting={loading}
							additionalClassNames={cx(modalStyles.btn, modalStyles.rightButton)}
							onClick={kickoffSubmit}
						>
							<CmsContentRenderer.Span contentKey={cms.confirm} fallbackValue="" />
						</Button>
					</div>
				</Modal>
			)}
		</CmsContentList>
	);
};

export default RevokeAlternateInvite;
