/**
 * This is the Full width banner that appears above the Card Details on
 * the Guest Card Overview page after submitting card details to check
 */
import React from 'react';
import { values } from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Banner, { TYPE_INFO } from 'components/Banner.js';
import Button, {
	Secondary,
	buttonTypeStylePlain,
} from 'components/Button.js';

import cx from 'classnames';

import * as style from './RegisterForAccount.module.css';

const cms = {
	title: 'miscText["guest-banner.title"]',
	text: 'miscText["guest-banner.description"]',
	link: 'miscText["guest-banner.cta"]',
	btn: 'miscText["guest-banner.button"]',
};

const RegisterForAccount = () => (
	<CmsContentList list={values(cms)}>{({ cmsContent }) => (
		<Banner
			type={TYPE_INFO}
			title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Register for a Charlie Account" />}
			body={<CmsContentRenderer.P contentKey={cms.text} fallbackValue="For more information and control over your account, please register." />}
			link={
				<Button to={getPathByRoute(routeKeys.SignIn)}
					typeStyle={buttonTypeStylePlain}
					additionalClassNames={style.registerButtonOverride}
				>
					<CmsContentRenderer.Span contentKey={cms.link} fallbackValue="Already registered? Log In" />
				</Button>
			}
			rightSide={<Button to={getPathByRoute(routeKeys.Register)}
				additionalClassNames={cx(style.widthFull, Secondary)}
			>
				<CmsContentRenderer.Span contentKey={cms.btn} fallbackValue="Register" />
			</Button>}
		/>
	)}</CmsContentList>
);

RegisterForAccount.propTypes = {};

export default RegisterForAccount;
