import React from 'react';
import { values } from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import { useGlobalToastsContext } from "context/ToastProvider.js";
import Toast from "components/Toast.js";

const cms = {
	confirmation: 'miscText.alternates-remove-confirmation',
};

const RemovedAlternateSuccess = ({ name }) => {
	const { removeToast } = useGlobalToastsContext();

	return (
		<CmsContentList list={values(cms)} sharedVariables={{ name }}>{() => (
			<Toast
				type="success"
				title={<CmsContentRenderer.Span
					contentKey={cms.confirmation}
					fallbackValue={`${name} has been removed as an alternate for your account.`}
				/>}
				onClosed={removeToast}
			/>
		)}</CmsContentList>
	);
};

export default RemovedAlternateSuccess;
