import {
	gql,
	useQuery,
} from "@apollo/client";
import React, { useMemo } from 'react';
import {
	filter,
	get,
} from 'lodash';
import PropTypes from "prop-types";
import StdQuery from "../StdQuery.js";
import loginStages from "components/data/session-user/LoginStages.js";
import PublicAppVars from "utils/PublicAppVars.js";

const contactSelector = "session.customer.contact";

export const LOGGED_IN_QUERY = gql`query LoggedIn {
	session {
		id
		customerId
		customer {
			id
			contact {
				id
				credentialStatus
				email
			}
			id
		}
		pendingEulaIds
		registrationCredentials
		transitAccountId
	}
}`;

export const requiredEulasForLogin = (wsEulaInfos) => {
	return filter(wsEulaInfos, eula => (
		PublicAppVars.EULA_CHANNELS.includes(eula.channel)
		&& PublicAppVars.EULA_REQUIRED_FEATURES.includes(eula.feature)
	));
};

const calcLoginStage = ({
	hasEmail,
	customerId,
	contactId,
	pendingEulaIds,
	registrationCredentials,
	subsystemAccountReference,
}) => {

	if (!(hasEmail && customerId && contactId)) {
		// If they don't have ids and an email address, they're not logged in, unless they have a subsystem
		// account reference, and then they're essentially logged in, but limited
		return subsystemAccountReference
			? loginStages.unRegisteredLoggedIn
			: loginStages.anonymous;
	}

	if (pendingEulaIds.length) {
		return loginStages.needsEula;
	}

	if (registrationCredentials) {
		return loginStages.registrationAddCards;
	}

	return loginStages.loggedIn;
};

const loginStageFromQuery = (loggedInQueryResult) => {
	if (!loggedInQueryResult) {
		// maybe the apollo store was reset and queries are refetching
		return undefined;
	}

	const customerId = loggedInQueryResult.session.customerId;
	const contactId = get(loggedInQueryResult, contactSelector + ".id");
	const email = get(loggedInQueryResult, contactSelector + ".email");
	const pendingEulaIds = loggedInQueryResult.session.pendingEulaIds;
	const registrationCredentials = loggedInQueryResult.session?.registrationCredentials;
	const subsystemAccountReference = loggedInQueryResult.session?.transitAccountId;

	return calcLoginStage({
		hasEmail: Boolean(email),
		customerId,
		contactId,
		pendingEulaIds,
		registrationCredentials,
		subsystemAccountReference,
	});
};

export const loginStageFromReq = (req) => {
	if (!req) {
		throw new Error("req missing");
	}

	const { session } = req;
	const {
		hasEmail,
		contactId,
		customerId,
		pendingEulaIds,
		registrationCredentials,
		transitAccountId,
		hasProgram,
	} = session;

	return calcLoginStage({
		hasEmail,
		customerId,
		contactId,
		pendingEulaIds,
		registrationCredentials,
		subsystemAccountReference: transitAccountId,
		hasProgram,
	});
};

export const useLoginStage = () => {
	const loginState = useQuery(LOGGED_IN_QUERY);

	return useMemo(() => ({
		...loginState,
		loginStage: (loginState.loading || loginState.error)
			? null
			: loginStageFromQuery(loginState.data),
	}), [ loginState ]);
};

export const LoginStageData = ({ children }) => (
	<StdQuery query={LOGGED_IN_QUERY}>
		{(response) => {
			return children(loginStageFromQuery(response.data), response);
		}}
	</StdQuery>
);

export const PendingEulaIds = ({ children }) => (
	<StdQuery query={LOGGED_IN_QUERY}>
		{({ data }) => {
			return children(data?.session?.pendingEulaIds ?? []);
		}}
	</StdQuery>
);

export const LoginStageRefetch = ({ children }) => (
	<StdQuery query={LOGGED_IN_QUERY}>
		{({ data, refetch }) => {
			return children({
				refetch,
				loginStage: loginStageFromQuery(data),
			});
		}}
	</StdQuery>
);

export class IfLoggedIn extends React.Component {
	render() {
		return (
			<StdQuery query={LOGGED_IN_QUERY}>
				{({ data }) => {
					return loginStageFromQuery(data) === loginStages.loggedIn
						? this.props.children
						: null;
				}}
			</StdQuery>
		);
	}
}

export const IfNotRegistered = ({ children }) => (
	<StdQuery query={LOGGED_IN_QUERY}>
		{({ data }) => {
			return loginStageFromQuery(data) === loginStages.unRegisteredLoggedIn
				? children
				: null;
		}}
	</StdQuery>
);

export class IfNotLoggedIn extends React.Component {
	render() {
		return (
			<StdQuery query={LOGGED_IN_QUERY}>
				{({ data }) => {
					return loginStageFromQuery(data) < loginStages.loggedIn
						? this.props.children
						: null;
				}}
			</StdQuery>
		);
	}
}

export class IfLoginStage extends React.Component {
	static propTypes = {
		min: PropTypes.number,
		max: PropTypes.number,
	};

	static defaultProps = {
		min: loginStages.anonymous,
		max: loginStages.loggedIn,
	};

	render() {
		const {
			min,
			max,
		} = this.props;

		return (
			<StdQuery query={LOGGED_IN_QUERY}>
				{({ data }) => {
					const stage = loginStageFromQuery(data);
					return (stage >= min && stage <= max)
						? this.props.children
						: null;
				}}
			</StdQuery>
		);
	}
}
