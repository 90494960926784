import {
	Route,
	withRouter,
} from 'react-router-dom';
import React, { Component } from 'react';

class HttpStatus extends Component {
	render() {
		return (
			<Route render={({ staticContext }) => {
				if (staticContext) {
					staticContext.status = this.props.code;
				}
				return this.props.children;
			}}/>
		);
	}
}

export { HttpStatus };
export default withRouter(HttpStatus);
