import React from 'react';
import PublicAppVars from "utils/PublicAppVars.js";

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_WARNING } from 'components/Banner';
import { values } from 'lodash';
import * as style from "./ComingSoon.module.css";
import Button, { buttonTypeStylePlain } from 'components/Button';
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";
import routeKeys from 'CustomerRouteKeys';
import { getPathByRoute } from 'App';

const cms = {
	title: 'miscText["addcard-standard-afc1-banner.title"]',
	desc: 'miscText["addcard-standard-afc1-banner.description"]',
	cta: 'miscText["addcard-standard-afc1-banner.cta"]',
	ctaUrl: 'miscText["addcard-standard-afc1-banner.cta-url"]',
	btn: 'miscText["addcard-standard-afc1-banner.button"]',
};

const AFC1Banner = () => {
	const { loginStage } = useLoginStage();
	const loggedIn = (loginStage === loginStages.loggedIn);

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => {
			return <Banner
				showIcon
				type={TYPE_WARNING}
				title={<CmsContentRenderer.Span
					contentKey={cms.title}
					fallbackValue="Invalid Charlie Card"
				/>}
				body={<CmsContentRenderer.Div
					asRawHtml
					contentKey={cms.desc}
					fallbackValue="You have entered a Charlie Card number that is no longer valid.<br>Please enter a different card number, or purchase a new Charlie Card."
				/>}
				link={
					<Button
						typeStyle={buttonTypeStylePlain}
						overrideClass={style.plainBtn}
						type="button"
						to={cmsContent[ cms.ctaUrl ]}
						target="_blank"
						external
					>
						<CmsContentRenderer.Span
							contentKey={cms.cta}
							fallbackValue="Learn more about the new Charlie Card"
						/>
					</Button>
				}
				rightSide={PublicAppVars.ENABLE_NEW_CARD_PURCHASE
					? <>
						<Button
							type="button"
							to={getPathByRoute(loggedIn
								? routeKeys.PurchaseCharlieCard
								: routeKeys.Register)
							}
						>
							<CmsContentRenderer.Span
								contentKey={cms.btn}
								fallbackValue="Purchase Charlie Card"
							/>
						</Button>

					</> : null}
			/>;
		}}</CmsContentList>
	);
};

AFC1Banner.propTypes = {};

export default AFC1Banner;
