import React, { useState } from 'react';
import {
	Redirect,
	useLocation,
} from "react-router-dom";
import cx from 'classnames';
import {
	values,
} from 'lodash';
import {
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
} from "yup";

import TakeOverLayout from "layouts/TakeOverLayout.js";
import Button from 'components/Button.js';
import Input from 'components/forms/Input.js';
import { getPathByRoute } from "App.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import ClientAjax from "utils/ClientAjax.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import CmsContentList from 'components/data/CmsContentList.js';
import PreventDefault from 'utils/PreventDefault.js';
import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";
import routeKeys from 'CustomerRouteKeys.js';
import groupAdminRouteKeys from 'GroupAdminRouteKeys.js';

import { useSessionCheckContext } from "components/data/session-user/SessionCheck.js";
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";
import Phone from "components/forms/Phone.js";
import { WireFormHelper } from "utils/FormHelper.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";

import { BO_ERRORS, COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import { useUsername } from './VerificationCodePanel.js';
import PublicAppVars from "utils/PublicAppVars.js";

import * as loginStyles from "./Login.module.css";
import * as verificationCodePanelStyles from "./VerificationCodePanel.module.css";
import * as personalInfoStyles from "./PersonalInformation.module.css";
import * as inputStyles from "components/forms/Input.module.css";
import * as formStyles from 'components/forms/Forms.module.css';



export const MODES = {
	RegisterVerify: "RegisterVerify",
	PasswordReset: "PasswordReset",
	UnlockAccountTokenVerify: "UnlockAccountTokenVerify",
	TwoFactorAuthVerify: "TwoFactorAuthVerify",
};

const cms = {
	unlockHeader: "miscText.unlock-account-header",
	description: "miscText.unlock-account-description",
	emailLabel: `miscText["unlock-account-email-label.label"]`,
	phoneLabel: `miscText["unlock-account-phone-label.label"]`,
	phoneNumberAriaLabel: 'miscText["register-info-phonetype.number"]',
	phoneCountryAriaLabel: 'miscText["register-info-phonetype.country"]',
	emailSubmit: "miscText.unlock-account-email-submit",
	accountError: "miscText.unlock-account-error-invalid",
	signinCTA: 'miscText.forgot-password-verify-signin-cta',
};

const getYupSchema = () => {
	const validations = {
		email: yup_string()
			.required("miscText.validation-unlockAccountRequest-email-required")
			.trim()
			.email("miscText.validation-unlockAccountRequest-email-valid"),
		phoneNumber: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.phone[0].number', BO_ERRORS.general.required))
			.trim()
			.validPhoneNumber(
				yup_ref(`phoneCountry`),
				getErrorKey(COLLECTIONS.registration, 'contact.phone[0].number', BO_ERRORS.general.invalidPhone),
			),
		...recaptchaYupValidations,
	};

	return yup_object().shape(validations);
};

const UnlockAccountRequest = () => {
	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema });

	const isB2B = PublicAppVars.isB2BApi;
	const [ redirect, setRedirect ] = useState(null);

	const { syntheticTimerEvent } = useSessionCheckContext();

	// Data from query
	const { username } = useUsername();

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const kickoffSubmit = async () => {
		await checkRecaptcha();

		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.verbose, errorReport,
				`VerificationCodePanel form validation`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("account info provided");

		try {
			const postData = validated;

			// succeeds or returns an ErrorReport
			await restClientMiddleware(ClientAjax.post("/ajax/request-unlock-account", postData));
		}
		catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);

			resetRecaptcha();
			return;
		}
		finally {
			// Recaptcha for form may have been submitted successfully, so check to see if we should reload recaptcha passes
			syntheticTimerEvent();
			setSubmitting(false);
		}

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(isB2B
					? groupAdminRouteKeys.UnlockAccountTokenVerify
					: routeKeys.UnlockAccountTokenVerify),
				state: { email: username },
			}} />
		);
	};

	if (redirect) {
		// this is a <Redirect>
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout
				title={<CmsContentRenderer contentKey={cms.unlockHeader} />}
				cancelLink={getPathByRoute(isB2B ? groupAdminRouteKeys.SignIn : routeKeys.SignIn)}
				showCancel={false}
				data-qa="UnlockAccountMainContainer"
			>
				<WireFormHelper formHelper={formHelper}>
					<div className={cx(loginStyles.container, verificationCodePanelStyles.twoFactorContentWrapper)}>
						<form
							className={loginStyles.registerForm}
							data-qa="UnlockAccountForm"
							ref={formRef}
							onSubmit={PreventDefault(kickoffSubmit)}
						>
							<CmsContentRenderer.P contentKey={cms.description} />
							<div className={personalInfoStyles.regEmailInput}>
								<div className={personalInfoStyles.regEmailWrapper}>
									<Input
										name="email"
										label={findContentOrFallback(cmsContent, cms.emailLabel, "Email")}
										data-qa="emailAddressInput"
										aria-label={findContentOrFallback(cmsContent, cms.emailLabel, "Email")}
										defaultValue={username}
										error={formHelper.getFieldError('email')}
									/>
								</div>
								<Phone
									withHiddenInput={true}
									formHelper={formHelper}
									legend={<CmsContentRenderer
										contentKey={cms.phoneLabel}
										fallbackValue="Phone"
										className={inputStyles.label}
									/>}
									name="phoneNumber"
									phoneNumberAriaLabel={findContentOrFallback(cmsContent, cms.phoneNumberAriaLabel, 'phone number')}
									phoneCountryAriaLabel={findContentOrFallback(cmsContent, cms.phoneCountryAriaLabel, 'phone number country')}
									className={formStyles.phoneInput}
								/>
							</div>

							<Recaptcha />

							{formHelper.getFieldErrorJsx('')}
							<div className={loginStyles.formActions}>
								<Button
									to={getPathByRoute(isB2B ? groupAdminRouteKeys.SignIn : routeKeys.SignIn)}
									additionalClassNames={loginStyles.secondaryBtn}
									isPrimary={false}>
									<CmsContentRenderer.Span contentKey={cms.signinCTA}
										fallbackValue="Return to Log In" />
								</Button>
								<Button
									isPrimary={true}
									data-qa="SubmitVerifyTokenBtn"
									submitting={submitting}
								>
									<CmsContentRenderer.Span contentKey={cms.emailSubmit}
										fallbackValue="Submit" />
								</Button>
							</div>
						</form>
					</div>
				</WireFormHelper>
			</TakeOverLayout>
		)}</CmsContentList >
	);
};

UnlockAccountRequest.propTypes = {
};

export default UnlockAccountRequest;
