// extracted by mini-css-extract-plugin
export var borderTriangle = "components-__Tooltip-module___borderTriangle";
export var borderTriangleOutline = "components-__Tooltip-module___borderTriangleOutline";
export var container = "components-__Tooltip-module___container";
export var disputeLink = "components-__Tooltip-module___disputeLink";
export var icon = "components-__Tooltip-module___icon";
export var infoContainer = "components-__Tooltip-module___infoContainer";
export var inlineWithText = "components-__Tooltip-module___inlineWithText";
export var link = "components-__Tooltip-module___link";
export var text = "components-__Tooltip-module___text";
export var textIcon = "components-__Tooltip-module___textIcon";
export var textIconBlue = "components-__Tooltip-module___textIconBlue";
export var toggle = "components-__Tooltip-module___toggle";