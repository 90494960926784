import { clone } from "lodash";
import WSAvsDataUpdate, {
	WSAvsDataUpdateType,
	InputWSAvsDataUpdateType,
} from "./WSAvsDataUpdate.js";

// Table 2-319. WSCreditCardUpdate Field Descriptions
// Used in 2.9.26 customer/<customer-id>/fundingsource/<fundingsource-id> PATCH

export default class WSCreditCardUpdate {
	constructor({
		pgCardId,
		cardExpiryMMYY = null,
		nameOnCard = null,
		avsData = null,
		cvv = null,
	}) {

		// string(40)
		// (Required)  The unique card ID, assigned by the tokenization system.
		this.pgCardId = pgCardId;

		// string(4)
		// (Optional)  The card expiry date in MMYY format. For MM, 01 = January.
		this.cardExpiryMMYY = cardExpiryMMYY;

		// string(60)
		// (Optional) Full name of credit card owner as it appears on credit card.
		// If not specified, value will be left unchanged.
		this.nameOnCard = nameOnCard;

		// WSAvsDataUpdate
		// (Conditionally-Required) Address verification details for the associated billing address.
		// The value cannot be removed.
		// Required if the billingAddressId is provided and the payment type of the funding source is a credit card.
		this.avsData = avsData;

		// string(4)
		// (Conditionally-Required) Customer Verification Value.
		// The field must be all numeric and must contain a minimum of three digits and maximum of four digits.
		// It is left padded with 0. Either null or a 0-length value indicates its absence.
		// Required if NIS is configured to require CVV for customer funding sources.
		this.cvv = cvv;
	}

	static fromBackoffice(data) {
		const result = new WSCreditCardUpdate(data);
		result.avsData = data.avsData
			? WSAvsDataUpdate.fromBackoffice(data.avsData)
			: null;

		return result;
	}

	toBackoffice() {
		const result = clone(this);
		result.avsData = result.avsData
			? result.avsData.toBackoffice()
			: null;
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.avsData = forGraphql.avsData
			? forGraphql.avsData.toResolver()
			: null;
		return forGraphql;
	}
}

const WSCreditCardUpdateFragment = `
	pgCardId: String!
	cardExpiryMMYY: String
	nameOnCard: String
	cvv: String
`;

export const InputWSCreditCardUpdateType = [
	...InputWSAvsDataUpdateType, `
	input InputWSCreditCardUpdate {
		${WSCreditCardUpdateFragment}
		avsData: InputWSAvsDataUpdate
	}
` ];

export const WSCreditCardUpdateType = [
	...WSAvsDataUpdateType, `
	type WSCreditCardUpdate {
		${WSCreditCardUpdateFragment}
		avsData: WSAvsDataUpdate
	}
` ];
