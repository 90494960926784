import { gql } from "@apollo/client";
import {
	filter,
	get,
	map,
} from "lodash";

import {
	levels,
	noticeError,
} from "utils/Logger.js";
import {
	getTransitAccountRefetchQueries,
} from "components/data/transit-account/TransitAccount.js";


import {
	GET_AUTOLOAD_PAYMENT_METHODS,
} from 'components/data/transit-account/GetAutoloadPaymentMethods.js';

export const ENROLL_AUTOLOAD_PASS = gql`
	mutation EnrollAutoLoad (
		$fundingSourceSetItems: [ InputWSFundingSourceSetItem! ]!
		$autoloadSubsystemProduct: InputWSAutoloadSubsystemProduct
		$autoloadSubsystemValue: InputWSAutoloadSubsystemValue
		$autoloadCriteria: InputWSAutoloadCriteria
		$startDate: String
	) {
		OrderRoute {
			id
			postEnroll(
				fundingSourceSetItems: $fundingSourceSetItems
				autoloadSubsystemProduct: $autoloadSubsystemProduct
				autoloadSubsystemValue: $autoloadSubsystemValue
				autoloadCriteria: $autoloadCriteria
				startDate: $startDate
			) {
				subscriptionId
				fundingSourceSetId
			}
		}
	}`;

export const getAutoloadPassPaymentMethods = async (
	subsystemAccountReference,
	wsAccountProduct,
	apolloClient,
	formHelper,
	setSubmitting,
) => {
	const {
		fareProductTypeId,
		name,
		productSKU,
		price,
		permittedUsers,
	} = wsAccountProduct;

	let response;
	try {

		const variables = {
			autoloadSubsystemProduct: {
				// string(20)
				// (Required) Identifies the type of auto load. Valid values are:
				// • AddOneAccountValue
				// • AddSubsystemValue
				// • PurchaseSubsystemProduct
				type: 'PurchaseSubsystemProduct',

				// string(30)
				// (Conditionally-Required) Behavior of the autoload. Possible
				// value are:
				// • AddAutoloadValue
				// • IncreaseBalanceTo
				loadValueBehavior: 'AddAutoloadValue',
				subsystemAccountId: subsystemAccountReference,
				subsystemRef: 'ABP',
				productTypeId: fareProductTypeId,
				productName: name,
				productSKU,
				productCost: price,
				permittedUsers: map(permittedUsers, wsSubsystemOperator => {
					return { operatorId: wsSubsystemOperator.operatorId };
				}),
			},
			autoloadCriteria: {
				type: "PassThreshold",
			},
		};

		response = await apolloClient.mutate({
			mutation: GET_AUTOLOAD_PAYMENT_METHODS,
			variables,
			refetchQueries: [
				...getTransitAccountRefetchQueries(subsystemAccountReference),
			],
		});
	}
	catch (errorReport) {
		// we're not redirecting anywhere. Prepare the form for the next submit.
		noticeError(null, levels.info, errorReport, `Auto-load Pass Submit Failed`);
		formHelper.validationErrorHandler(errorReport);
		setSubmitting(false);

		return;
	}
	const {
		fundingSources,
	} = get(response, "data.OrderRoute.getAutoLoadEnrollPaymentmethods", {});

	return {
		fundingSources,
	};
};
