import { gql } from "@apollo/client";
import StdQuery from "./StdQuery.js";
import React, { useEffect } from "react";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { map } from "lodash";
import { add } from "date-fns";
import PublicAppVars from "utils/PublicAppVars.js";

export const GET_SERVER_TIME = gql`query GET_SERVER_TIME {
	serverTime
}`;

export const useServerTime = () => {
	const queryResult = useStdQuery(GET_SERVER_TIME);
	const serverTime = queryResult.data?.serverTime;
	return {
		...queryResult,
		serverTime,
	};
};

let lastServerRefresh = null;

// Automatically refresh to deal with cache issues like
// https://reflexions.atlassian.net/browse/BU3-888
export const useRefetchIfOld = ({ refetch, loading = false, queryTime, oldCriteria = PublicAppVars.REFETCH_IF_OLDER_THAN }) => {
	const { serverTime, loading: loadingServerTime, refetch: refetchServerTime } = useServerTime();
	// refresh from server on mount if we haven't refreshed recently
	// this is to get the latest server time
	useEffect(() => {
		const now = new Date();
		if (!lastServerRefresh || add(lastServerRefresh, oldCriteria) < now) {
			lastServerRefresh = now;
			refetchServerTime();
		}
	}, [
		refetchServerTime,
		oldCriteria,
	]);

	// assuming serverTime is up to date (the other useEffect handles that)
	// refresh the query if the data in the query is old
	useEffect(() => {
		if (loading || loadingServerTime) {
			return;
		}

		if (serverTime && queryTime) {
			const serverTimeObj = new Date(serverTime);
			const queryTimeObj = new Date(queryTime);
			if (add(queryTimeObj, oldCriteria) < serverTimeObj) {
				refetch();
			}
		}
	}, [
		loading,
		loadingServerTime,
		refetch,
		serverTime,
		queryTime,
		oldCriteria,
	]);

	return null;
};


// Automatically refresh to deal with cache issues like
// https://reflexions.atlassian.net/browse/BU3-888
export const RefetchIfOld = (props) => {
	useRefetchIfOld(props);
	return null;
};

const ServerTime = ({ children }) => (
	<StdQuery query={GET_SERVER_TIME}>
		{(response) => children({
			...response,
			serverTime: response.data?.serverTime,
		})}
	</StdQuery>
);
export default ServerTime;
