import React, {
	useState,
	useRef,
} from 'react';
import {
	get,
	values,
} from "lodash";
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import { gql } from "@apollo/client";
import cx from 'classnames';
import PropTypes from 'prop-types';

import { PIN_QUERY } from '../data/session-user/SessionUser';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Input from '../forms/Input.js';
import Button from '../Button.js';
import StandardMutation from '../data/StandardMutation.js';
import StdQuery from '../data/StdQuery.js';
import Modal from '../Modal.js';
import Toast from "components/Toast.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import FormHelper from 'utils/FormHelper';
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import { Lifecycles } from 'libreact/lib/Lifecycles';
import CmsContentList from '../data/CmsContentList.js';
import PreventDefault from 'utils/PreventDefault';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics';
import { BO_ERRORS, COLLECTIONS, getErrorKey, getMiscTextErrorKey } from 'utils/GetErrorKey.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware';

import * as modalStyles from '../Modal.module.css';
import * as editPinStyle from './EditPinCode.module.css';



const cms = {
	header: 'miscText.security-info-pin-reset-header',
	description: 'miscText.security-info-pin-reset-description',
	pinLabel: 'miscText.security-info-pin-reset-label',
	cancel: 'miscText.security-info-pin-reset-cancel',
	submit: 'miscText.security-info-pin-reset-submit',
	confirmation: 'miscText.security-info-pin-reset-confirmation',
};

const SET_PIN = gql`
	mutation setPin($pin: String!) {
		setPin(pin: $pin) {
			id
			contact {
				id
				pin
			}
		}
	}
`;

const EditPinCode = ({ onModalClose }) => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});

	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;

	const getYupSchema = () => {
		return yup_object().shape({
			pin: yup_string()
				.required(getMiscTextErrorKey(BO_ERRORS.general.required))
				.min(4, getErrorKey(COLLECTIONS.securityProfile, 'pin', 'errors.general.value.toosmall'))
				.max(4, getErrorKey(COLLECTIONS.securityProfile, 'pin', 'errors.general.value.toolong')),
		});
	};

	formHelper.onHookedRender(validationState, setValidationState, getYupSchema);

	const setEditPinConfirmationToast = (cmsContent) => {
		setToast(<Toast
			type="success"
			title={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cms.confirmation}
				fallbackValue="Your customer support access PIN has been successfully updated."
			/>}
			onClosed={removeToast}
		/>);
	};

	const kickoffSubmit = async (callback, cmsContent) => {
		setSubmitting(true);
		formHelper.clearAllErrors();

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.verbose, errorReport, `Create pin panel form validation`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("User is attempting to create account pin");

		// submit succeeds or returns an ErrorReport
		try {
			await graphqlErrorMiddleware(callback({ variables: validated }));
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		setEditPinConfirmationToast(cmsContent);
		onModalClose();
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal
				title={cmsContent[ cms.header ] || 'Reset PIN'}
				data-qa="EditPinModal"
				onModalClose={onModalClose}
			>
				<CmsContentRenderer.P
					contentKey={cms.description}
					fallbackValue="Your customer support access PIN will be used to identify you on calls with Customer Support."
					className={modalStyles.text}
					data-qa="ResetPinModalDescription"
				/>
				<StdQuery
					name="Create Pin Panel - Get pin from Session"
					query={PIN_QUERY}
				>
					{({ data }) => {
						const sessionPin = get(data, "session.customer.contact.pin");
						return (
							<StandardMutation mutation={SET_PIN} showLoadingState={false} errorChildren={null} refetchQueries={[ { query: PIN_QUERY } ]}>{setPin => (
								<form
									data-qa="ResetPinModalForm"
									ref={formRef}
									onSubmit={PreventDefault(() => kickoffSubmit(setPin, cmsContent))}
								>
									<Lifecycles didMount={formHelper.wireInputs}>
										<div className={editPinStyle.container}>
											<Input
												defaultValue={sessionPin}
												type="text"
												name="pin"
												label={<CmsContentRenderer.Span
													contentKey={cms.pinLabel}
													fallbackValue="Enter new PIN"
												/>}
												data-qa="ResetPinModalPin"
												error={formHelper.getFieldError('pin')}
												onChange={formHelper.clearAllErrors}
											/>
											{formHelper.getFieldErrorJsx('')}
											<div className={cx(modalStyles.actions, editPinStyle.modalActionsWrapper)}>
												<Button
													type="button"
													onClick={onModalClose}
													overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
													data-qa="ResetPinModalCancelButton"
												>
													<CmsContentRenderer.Span
														contentKey={cms.cancel}
														fallbackValue="Cancel"
													/>
												</Button>
												<Button
													type="submit"
													overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
													data-qa="ResetPinModalSubmitBtn"
													submitting={submitting}
												>
													<CmsContentRenderer.Span
														contentKey={cms.submit}
														fallbackValue="Submit"
													/>
												</Button>
											</div>
										</div>
									</Lifecycles>
								</form>
							)}</StandardMutation>
						);
					}}
				</StdQuery>
			</Modal>
		)}</CmsContentList>
	);
};

EditPinCode.propTypes = {
	onModalClose: PropTypes.func.isRequired,
};

export default EditPinCode;
