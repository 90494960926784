import React, { useState } from "react";
import {
	Link,
	Redirect,
} from "react-router-dom";
import cx from "classnames";
import { values } from 'lodash';
import qs from 'qs';

import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import PreventDefault from "utils/PreventDefault.js";
import {
	SSO_ACTION_REGISTER,
	SSO_PROVIDERS,
} from "utils/constants/Sso.js";

import Container from 'components/Container.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import {
	Facebook,
	Apple,
	SocialMediaIcon,
} from "components/Icon";
import LoginCredentials from "components/auth/LoginCredentials.js";
import ContactCustomerService from "components/banners/ContactCustomerService.js";

import * as login from "./Login.module.css";



const cms = {
	header: "miscText.register-header",
	benefitsCta: "miscText.register-benefits-cta",
	socialSubheader: "miscText.register-social-subheader",
	facebookBtnTitle: "miscText.register-social-facebook",
	googleBtnTitle: "miscText.register-social-google",
	appleBtnTitle: "miscText.register-social-apple",
	or_Conjunction: "miscText.register-or",
	registerWithEmailText: "miscText.register-email-subheader",
	footerContactHelpText: "miscText.general-contactFooter-text",
	footerContactBtnTitle: "miscText.general-contactFooter-button",
	registerSigninText: 'miscText.register-signin-text',
	registerSigninCTA: 'miscText.register-signin-cta',
};

const AccountLogInLink = ({ }) => (
	<>
		<CmsContentRenderer.Span
			contentKey={cms.registerSigninText}
			fallbackValue="Already have an account?"
			className={login.otherAuthActionText}
		/>

		<Button
			to={getPathByRoute(routeKeys.SignIn)}
			typeStyle={buttonTypeStylePlain}
			type="button"
		>
			<CmsContentRenderer.Span
				contentKey={cms.registerSigninCTA}
				fallbackValue="Log in"
				className={login.forgotPswd}
			/>
		</Button>
	</>
);



const socialAuth = (linkingKey, setRedirect) => {
	const url = '/login/social/redirect-to-provider?'
		+ qs.stringify({
			linkingKey,
			action: SSO_ACTION_REGISTER,
		});

	// client-side can't redirect to an Express path
	if (typeof window !== "undefined") {
		window.location.href = url;
	}
	else {
		setRedirect(<Redirect push to={url} />);
	}
};

const Register = () => {
	const [ redirect, setRedirect ] = useState(null);

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
			<section className={login.headerContainer}
				data-qa="RegisterHeaderContainer"
			>
				<div className={login.titleContainer}>
					<div className={login.header}>
						<CmsContentRenderer.H1
							className={login.title}
							data-qa="RegisterHeaderTitle"
							contentKey={cms.header}
							fallbackValue='Register for a Charlie Account'
						/>
					</div>
				</div>
				<div className={login.secondaryContent}>
					<div className={login.text}>
						<Link to='/'
							className={cx(login.textLink, login.registerBenefitsLink)}
							data-qa="LinkToSeeBenefits"
						>
							<CmsContentRenderer.Span
								className={cx(login.textLinkText, login.viewText)}
								contentKey={cms.benefitsCta}
								fallbackValue='See the benefits of registering'
							/>
						</Link>
					</div>
				</div>
			</section>

			<Container overrideClass={login.container}>
				<section className={cx(login.content, login.registerContent)}>
					<div className={login.socialBtnContainer}>
						<CmsContentRenderer.P
							className={login.subheadAlt}
							data-qa="RegisterSocialTitle"
							contentKey={cms.socialSubheader}
							fallbackValue='Register with your social account'
						/>
						<div className={login.actions}>
							<button className={cx(login.socialBtn, login.socialBtnFB)}
								data-qa="FBRegisterBtn"
								onClick={PreventDefault(() => socialAuth(SSO_PROVIDERS.FACEBOOK.key, setRedirect))}
								aria-label={cmsContent[ cms.facebookBtnTitle ]}
							>
								<Facebook overrideClass={cx(login.socialBtnIcon, login.socialBtnIconFB)}
									aria-hidden={true}
									data-qa="FBLogo"
								/>
								<CmsContentRenderer.Span
									contentKey={cms.facebookBtnTitle}
									fallbackValue='Register with Facebook'
								/>
							</button>

							<button className={cx(login.socialBtn, login.socialBtnGP)}
								data-qa="GoogleRegisterBtn"
								onClick={PreventDefault(() => socialAuth(SSO_PROVIDERS.GOOGLE.key, setRedirect))}
								aria-label={cmsContent[ cms.googleBtnTitle ]}
							>
								<SocialMediaIcon socialMediaType={SSO_PROVIDERS.GOOGLE.key} childProps={{ overrideClass: cx(login.socialBtnIcon, login.socialBtnIconGP) }} />
								<CmsContentRenderer.Span
									contentKey={cms.googleBtnTitle}
									fallbackValue='Register with Google'
								/>
							</button>

							<button className={cx(login.socialBtn, login.socialBtnAP)}
								data-qa="AppleRegisterBtn"
								onClick={PreventDefault(() => socialAuth(SSO_PROVIDERS.APPLE.key, setRedirect))}
								aria-label={cmsContent[ cms.appleBtnTitle ]}
							>
								<Apple overrideClass={cx(login.socialBtnIcon, login.socialBtnIconAP)}
									aria-hidden={true}
									data-qa="AppleLogo"
								/>

								<CmsContentRenderer.Span
									contentKey={cms.appleBtnTitle}
									fallbackValue='Register with Apple'
								/>
							</button>

						</div>
						<div className={cx(login.otherAuthAction, login.onlyMobile)}>
							<AccountLogInLink />
						</div>
					</div>

					<div className={login.divider}>
						<CmsContentRenderer.P
							className={login.dividerText}
							contentKey={cms.or_Conjunction}
							fallbackValue='OR'
						/>
					</div>

					<div className={login.formContainer}>
						<CmsContentRenderer.P
							className={login.subheadAlt}
							data-qa="RegisterWithEmailHeader"
							contentKey={cms.registerWithEmailText}
							fallbackValue='Register with your email'
						/>

						<div className={login.form}>
							<LoginCredentials data-qa="RegisterForm" />

							<div className={cx(login.otherAuthAction, login.onlyDeskTop)}>
								<AccountLogInLink />
							</div>
						</div>
					</div>
				</section>
			</Container>

			<ContactCustomerService />
		</>)}</CmsContentList>
	);
};

export default Register;
