import React from 'react';
import cx from 'classnames';
import { values } from 'lodash';

import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import {
	IfLoggedIn,
	IfNotLoggedIn,
} from "components/data/session-user/LoggingIn.js";
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';

import Container from 'components/Container.js';
import Button, {
	CharlieSecondary,
	CharliePrimary,
} from 'components/Button.js';
import { Charlie } from 'components/Icon.js';
import LanguageSelector from './LanguageSelector.js';
import AccountSelector from './AccountSelector.js';
import CardSelector from 'layouts/components/CardSelector.js';
import SkipToContent from 'layouts/components/SkipToContent.js';
import SkipToChatbot from "layouts/components/SkipToChatbot.js";
import { main } from 'layouts/BaseLayout.js';
import {
	stringify as qs_stringify,
} from "qs";

import * as buttonStyle from "components/Button.module.css";
import * as header from './CharlieHeader.module.css';


const cms = {
	logo: 'miscText.topmenu-logo',
	logIn: 'miscText.topmenu-login',
	register: 'miscText.topmenu-register',
	topCharlieMenu: 'miscText.topmenu-charlie-alt',
	skip: "miscText.general-nav-skip",
	skipToDigitalAssistant: "miscText.globalHeader-skip-to-digital-assistant",
};

const CharlieHeader = () => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Container overrideClass={header.container}>
				<SkipToContent
					elementId={main}
				>
					<CmsContentRenderer.Span
						contentKey={cms.skip}
						fallbackValue={"Skip to main content"}
					/>
				</SkipToContent>
				<SkipToChatbot>
					<CmsContentRenderer.Span contentKey={cms.skipToDigitalAssistant} />
				</SkipToChatbot>
				<div className={header.logoContainer}>
					<Button
						theme={CharlieSecondary}
						to={getPathByRoute(routeKeys.Home)}
						additionalClassNames={cx(header.logoLink, buttonStyle.link)}
						aria-label={findContentOrFallback(cmsContent, cms.topCharlieMenu, "Return to Charlie landing page")}
					>
						<Charlie
							overrideClass={header.logo}
							svgAriaDesc={cmsContent[ cms.logo ]}
						/>
					</Button>
				</div>

				<IfLoggedIn>
					<AccountSelector />
					<CardSelector />
				</IfLoggedIn>

				<IfNotLoggedIn>
					<nav className={header.btns} aria-label={"Login Register Group"}>
						<Button
							theme={CharlieSecondary}
							to={getPathByRoute(routeKeys.SignIn)}
							additionalClassNames={header.btn}
						>
							<CmsContentRenderer.Span contentKey={cms.logIn} fallbackValue="Log in" />
						</Button>

						<Button
							theme={CharliePrimary}
							to={{
								pathname: getPathByRoute(routeKeys.Register),
								search: qs_stringify({
									postSigninGoToPath: getPathByRoute(routeKeys.AccountCardSelection),
								}),
							}}
							additionalClassNames={header.btn}
						>
							<CmsContentRenderer.Span contentKey={cms.register} fallbackValue="Register" />
						</Button>
					</nav>
				</IfNotLoggedIn>

				<LanguageSelector />
			</Container>
		)}</CmsContentList>
	);
};


export default CharlieHeader;
