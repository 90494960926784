import {
	map,
	clone,
} from "lodash";
import WSPaymentHistoryInfoFactory, { WSPaymentHistoryInfoFactoryTypes } from "./WSPaymentHistoryInfoFactory.js";
import { WSPaymentHistoryInfoType } from "./WSPaymentHistoryInfo.js";
import { WSPaymentHistoryCreditDebitInfoType } from "./WSPaymentHistoryCreditDebitInfo.js";
import { WSPaymentHistoryDirectDebitInfoType } from "./WSPaymentHistoryDirectDebitInfo.js";


export const retailer = "Retailer";

// 2.7.25.WSPaymentHistory
// This object is used to represent a payment history line item in the payment history.
export default class WSPaymentHistory {
	constructor({
		type,
		amount = null,
		paymentRefNbr = null,
		allowDispute = null,
		allowsDispute,
		origin,
		payments,
		paymentHistoryDateTime,
	}) {

		// string(20)
		// (Required) The type to identify if it’s a order payment history of a
		// travel payment history. Valid values are:
		// • order
		// • travel
		this.type = type;

		// int
		// (Optional) The sum of all payments collected for this order.
		// This number is the number of the base unit of currency, such as
		// cents.
		this.amount = amount;

		// string(40)
		// (Optional) The payment reference number assigned to the
		// order.
		this.paymentRefNbr = paymentRefNbr;

		// boolean
		// (Required) This option is true if not already refunded (refund
		// sale) and false for order type adjustment, Transfer Product and
		// Refund.
		this.allowsDispute = allowDispute ?? allowsDispute;

		// string(20)
		// (Required) The origin of this paymentpayment.
		this.origin = origin;

		// List<WSPaymentHistoryInfo>
		// (Required) The list contains payments collected for the order.
		this.payments = payments;

		// dateTime
		// (Required) The date and time the payment history was created.
		this.paymentHistoryDateTime = paymentHistoryDateTime;
	}

	static fromBackoffice(data) {
		const result = new WSPaymentHistory(data);

		result.payments = map(data.payments, wsPaymentHistoryInfo =>
			WSPaymentHistoryInfoFactory.fromBackoffice(wsPaymentHistoryInfo));

		return result;
	}

	static fromSerialized(data) {
		const result = new WSPaymentHistory(data);

		result.payments = map(data.payments, wsPaymentHistoryInfo =>
			WSPaymentHistoryInfoFactory.fromSerialized(wsPaymentHistoryInfo));

		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = forGraphql.paymentRefNbr + forGraphql.paymentHistoryDateTime;

		forGraphql.payments = map(this.payments, wsPaymentHistoryInfo =>
			wsPaymentHistoryInfo.toResolver());

		return forGraphql;
	}
}
// Per updated api docs as of 9/30 we should expect
// allowsDispute but we do get allowDispute.
// We'll manage the conversiioon between allowDispute and allowsDispute
// in the WSPaymentHistory constructor.
// https://reflexions.slack.com/archives/GA82SPCTV/p1601474560108500?thread_ts=1601408461.090300&cid=GA82SPCTV

export const WSPaymentHistoryFragment = `
	id: ID!
	type: String!
	amount: Int!
	paymentRefNbr: String
	allowsDispute: Boolean!
	origin: String!
	payments: [ WSPaymentHistoryInfoFactory! ]!
	paymentHistoryDateTime: GraphQLDateTime!
`;

export const WSPaymentHistoryType = [
	...WSPaymentHistoryInfoFactoryTypes,
	...WSPaymentHistoryInfoType,
	...WSPaymentHistoryCreditDebitInfoType,
	...WSPaymentHistoryDirectDebitInfoType, `
	type WSPaymentHistory implements WSPaymentHistoryFactory {
		${WSPaymentHistoryFragment}
	}
` ];
