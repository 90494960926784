import { clone } from "lodash";
export default class WSSubsystemPurse {
	constructor({
		nickname = null,
		balance,
		purseType,
		purseTypeDescription,
		purseRestriction = null,
		purseRestrictionDescription = null,
		supportsTransfer = null,
		supportsLoad,
		purseSKU,
		purseName,
	}) {
		// string(30)
		// (Optional) The nickname for this purse. If a nickname is not specified, a default will be assigned.
		this.nickname = nickname;

		// int
		// (Required) Current balance of the purse
		this.balance = balance;

		// string(40)
		// (Required) Type of purse it is.
		this.purseType = purseType;

		// string(40)
		// (Required) Description of the purse type.
		this.purseTypeDescription = purseTypeDescription;

		// string(40)
		// (Required) Type of the purse restriction.
		this.purseRestriction = purseRestriction;

		// string(40)
		// (Required) Description of the purse restriction.
		this.purseRestrictionDescription = purseRestrictionDescription;

		// boolean
		// (Optional) Indicates whether the purse value can be transferred.
		// Absense of the flag means false.
		this.supportsTransfer = supportsTransfer;

		// boolean
		// (Optional) Indicates whether the purse supports loading value.
		// Absense of the flag means false.
		this.supportsLoad = supportsLoad;

		// string(40)
		// (Optional) If the purse is a product, the purse SKU as it was defined in product catalog.
		this.purseSKU = purseSKU;

		// string(40)
		// (Optional) If the purse is a product, the purse name as it was defined in product catalog.
		this.purseName = purseName;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemPurse(data);

		return result;
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSSubsystemPurse(data);

		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSSubsystemPurseType = [
	`
		type WSSubsystemPurse {
			nickname: String
			balance: Int!
			purseType: String!
			purseTypeDescription: String!
			purseRestriction: String
			purseRestrictionDescription: String
			supportsTransfer: Boolean
			supportsLoad: Boolean
			purseSKU: String
			purseName: String
		}
	`,
];
