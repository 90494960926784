import {
	map,
	clone,
	merge,
} from "lodash";
import WSAutoload, { WSAutoloadFragment } from "./WSAutoload.js";

// 2.7.6.2 WSAutoloadSubsystemValue
// This extends the WSAutoload object. An auto load for subsystem value in a purse.
export default class WSAutoloadSubsystemValue extends WSAutoload {
	constructor({
		subsystemAccountId,
		subsystemRef,
		value,
		purseType,
		purseRestriction,

		// super props
		type,
		loadValueBehavior = null,
	}) {

		super({
			type,
			loadValueBehavior,
		});

		// string(40)
		// (Required) The unique identifier for the
		// subsystem account.
		this.subsystemAccountId = subsystemAccountId;

		// string(20)
		// (Required) Unique identifier for the subsystem
		// where the subsystem account is registered.
		this.subsystemRef = subsystemRef;

		// int
		// (Required) Value to auto load. This may be used
		// with AddValue and ThresholdPlusValue types. The
		// type will define the auto load behavior.
		this.value = value;

		// string(40)
		// (Required) Type of purse it is.
		this.purseType = purseType;

		// string(40)
		// (Required) Type of the purse restriction.
		this.purseRestriction = purseRestriction;
	}

	static fromBackoffice(data) {
		const result = new WSAutoloadSubsystemValue(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	};

	toBackoffice() {
		return {
			...this,
			...super.toBackoffice(),
		};
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.subsystemAccountId + this.value;

		return merge(
			forGraphql,
			super.toResolver(),
		);
	}
}

export const WSAutoloadSubsystemValueFragment = `
	subsystemAccountId: String!
	subsystemRef: String!
	value: Int!
	purseType: String!
	purseRestriction: String!
`;

export const InputWSAutoloadSubsystemValueType = [ `
	input InputWSAutoloadSubsystemValue {
		${WSAutoloadFragment}
		${WSAutoloadSubsystemValueFragment}
	}
` ];

export const WSAutoloadSubsystemValueType = [ `
	type WSAutoloadSubsystemValue implements WSAutoloadFactory {
		id: ID!
		${WSAutoloadFragment}
		${WSAutoloadSubsystemValueFragment}
	}
` ];
