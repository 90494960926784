import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
	find,
	values,
} from 'lodash';
import {
	levels,
	noticeError,
} from "utils/Logger.js";

import { useCartContext, getCartBalance } from 'context/CartProvider.js';
import { useTransitAccountProductCatalog } from 'components/data/transit-account/ProductCatalog.query';
import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import {
	centsToUnitlessDisplay,
} from 'utils/FormatHelpers.js';
import PreventDefault from 'utils/PreventDefault.js';
import { rangeError } from 'components/account/purchases/ValuePurchaseTypeSelection.js';
// import FormHelper from 'utils/FormHelper.js';
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import CmsContentListContext from 'components/data/CmsContentListContext.js';
import { RIDER_CLASSES } from 'components/data/transit-account/TransitAccount.js';

import Button, { Primary } from 'components/Button.js';
import Input from 'components/forms/Input.js';

import { OPEN_TRANSIT_REGULAR_CARD } from 'server/api-types/WSSubsystemAccountToken';
import { getYupSchema } from 'pages/account/PurchaseCharlieCardStoreForm.js';
import PublicAppVars from "utils/PublicAppVars.js";
import { useCustomerTravelCards } from 'components/data/session-user/useCustomerTravelCards.js';
import { useTranslations } from "components/data/CmsContentList.js";
import { yupErrorMiddleware } from "utils/error-handling/yupMiddleware.js";
import { useFormHelperContext } from 'utils/form-helper/FormHelperProvider.js';
import {
	object as yup_object,
	number as yup_number,
} from "yup";
import {
	BO_ERRORS,
	COLLECTIONS,
	getErrorKey,
} from 'utils/GetErrorKey.js';

import * as cartStyle from 'components/payments/Cart.module.css';

const cms = {
	maxCardLimitError: "miscText.purchase-card-1-count-error",
	update: 'miscText.purchase-cart-update',
	amount: 'miscText.purchase-balance-other-label',
	rangeError,
	maxPass: 'miscText.purchase-cart-update-error-toomany',
};

const UpdateCart = ({
	productId = '',
	quantity: quantityProp = null,
	additionalClassNames = '',
}) => {
	const { formHelper } = useFormHelperContext();

	const { data: customerTravelCardsData } = useCustomerTravelCards();
	const wsSubsystemAccountInfos = customerTravelCardsData?.subsystemAccountInfoQ ?? [];

	const subsystemAccountReference = useTransitAccountIdContext();
	const useTransitAccount = Boolean(subsystemAccountReference);

	const taResult = useTransitAccountProductCatalog({
		subsystemAccountReference,
		queryOptions: { skip: !useTransitAccount },
	});

	// riderClassId is a required field for subsystem/<subsystem-id>/productcatalog/get POST
	// if we are using the subsystem prod cat, then we purchasing a charlie card
	// so we dont have a TA nor riderClassId, defualt to RIDER_CLASSES.fullFare
	const subsystemResult = useGetSubsystemProductCatalog({
		riderClassId: RIDER_CLASSES.fullFare,
		queryOptions: { skip: useTransitAccount },
	});

	const minAddTransitValue = useTransitAccount
		? taResult.minAddTransitValue
		: subsystemResult.minAddValue;
	const maxAddTransitValue = useTransitAccount
		? taResult.maxAddTransitValue
		: subsystemResult.maxAddValue;

	const cmsContent = useTranslations({
		list: values(cms),
		sharedVariables: {
			num: wsSubsystemAccountInfos.length,
			remaining: (PublicAppVars.MAX_TRAVEL_CARD_LIMIT - wsSubsystemAccountInfos.length),
			min: 1,
			max: PublicAppVars.MAX_TRAVEL_CARD_LIMIT,
		},
	});

	const { purseTotal } = usePurseBalanceContext();
	const {
		cart,
		addProduct,
		removeProduct,
		addBalance,
	} = useCartContext();

	const purchaseStoredValueAmount = getCartBalance(cart);
	const cartHasStoredValueAmount = Boolean(purchaseStoredValueAmount);

	const [ showUpdateBtn, setShowUpdateBtn ] = useState(false);
	const [ customAmt, setCustomAmt ] = useState(0);
	const [ newQuantity, setNewQuantity ] = useState(0);

	const handleUpdate = async () => {
		if (!productId && cartHasStoredValueAmount) {
			const allowedMaxValue = maxAddTransitValue - purseTotal - purchaseStoredValueAmount;
			try {
				const schema = yup_object().shape({
					balanceAmount: yup_number()
						.min(minAddTransitValue, getErrorKey(COLLECTIONS.purchaseStoredValue, 'loadProductLineItems[n].product', 'errors.general.value.required'))
						.max(allowedMaxValue, cmsContent[ cms.rangeError ])
						.typeError(getErrorKey(COLLECTIONS.registration, 'contact.pin', BO_ERRORS.general.numbersOnly))
						.required('miscText["errors.general.field_required"]'),

				});
				await yupErrorMiddleware(
					schema.validate({
						balanceAmount: customAmt,
					}, {
						abortEarly: false,
					}),
				);
			} catch (errorReport) {
				// yup validation failed, but those errors are already in state
				noticeError(null, levels.info, errorReport, `Allowed balance amount failed`);
				formHelper.validationErrorHandler(errorReport);
				return;
			}

			addBalance(customAmt);
		} else if (productId) {
			// mediaOptions are charlie cards
			const found = find([ ...cart.products, ...cart.mediaOptions ], product => product.id === productId);

			// we are purchasing charlie cards, lets validate the total number of travel cards
			if (found?.mediaType === OPEN_TRANSIT_REGULAR_CARD) {
				try {
					const schema = getYupSchema(wsSubsystemAccountInfos.length, cmsContent);
					await yupErrorMiddleware(
						schema.validate({
							quantity: newQuantity,
						}, {
							abortEarly: false,
						}),
					);
				} catch (errorReport) {
					// yup validation failed, but those errors are already in state
					noticeError(null, levels.info, errorReport, `Allowed number of travel cards failed`);
					formHelper.validationErrorHandler(errorReport);
					return;
				}
			} else {
				const allowedMaxPasses = found?.maxAddPass;

				try {
					const schema = yup_object().shape({
						quantity: yup_number()
							// maxAddPass logic ticket https://reflexions.atlassian.net/browse/MBTA-2354
							.test({
								name: 'max add pass amount',
								test: (val) => val <= allowedMaxPasses,
								message: () => <CmsContentRenderedInline
									contentKey={'miscText.purchase-cart-update-error-toomany'}
									variables={{ max: allowedMaxPasses }}
									fallbackValue={`Cannot exceed limit of ${allowedMaxPasses}`}
								/>,
							})
							.typeError(getErrorKey(COLLECTIONS.registration, 'contact.pin', BO_ERRORS.general.numbersOnly))
							.required('miscText["errors.general.field_required"]'),

					});
					await yupErrorMiddleware(
						schema.validate({
							quantity: newQuantity,
						}, {
							abortEarly: false,
						}),
					);
				} catch (errorReport) {
					// yup validation failed, but those errors are already in state
					noticeError(null, levels.info, errorReport, `Allowed passes amount failed`);
					formHelper.validationErrorHandler(errorReport);
					return;
				}
			}

			if (found) {
				if (found?.mediaType !== OPEN_TRANSIT_REGULAR_CARD && newQuantity <= 0) {
					removeProduct(productId);
				} else {
					addProduct(found, newQuantity);
				}
			}
		}

		formHelper.clearAllErrors();
		setShowUpdateBtn(false);
	};

	const valueProp = (!productId && cartHasStoredValueAmount)
		? {}
		: { defaultValue: quantityProp };

	const placeHolderProp = (!productId && cartHasStoredValueAmount)
		? { placeholder: centsToUnitlessDisplay(purchaseStoredValueAmount) ?? `${centsToUnitlessDisplay(maxAddTransitValue)} max` }
		: {};

	const getError = () => {
		return formHelper.getFieldError(
			productId
				? 'quantity'
				: 'balanceAmount'
		);
	};

	return (
		<CmsContentListContext.Provider value={cmsContent}>
			<>
				<div className={cx(additionalClassNames, cartStyle.column, cartStyle.cartWrapper)}>
					{showUpdateBtn && (
						<Button
							type="button"
							typeStyle={Primary}
							data-qa="CartActionBtn"
							additionalClassNames={cartStyle.updateBtn}
							onClick={PreventDefault(handleUpdate)}
						>
							<CmsContentRenderer.Span
								contentKey={cms.update}
								fallbackValue="Update"
							/>
						</Button>
					)}
				</div>
				<div className={cx(additionalClassNames, cartStyle.column, cartStyle.amount)}>
					<CmsContentRenderer.Span
						contentKey={cms.amount}
						fallbackValue="Enter Amount"
						className={cx(cartStyle.mobileItemLabel)}
					/>
					<Input
						hideLabel
						hideErrorText
						name={productId ? 'quantity' : 'balanceAmount'}
						overrideClass={cx(cartStyle.customInput, cartHasStoredValueAmount && cartStyle.hasDollarIcon)}
						type={productId ? "number" : "text"}
						onFocus={() => { setShowUpdateBtn(true); }}
						onBlur={() => { setShowUpdateBtn(true); }}
						onChange={event => {
							setShowUpdateBtn(true);

							if (!productId && cartHasStoredValueAmount) {
								setCustomAmt(parseInt(event.target.value) * 100);
							} else if (productId) {
								setNewQuantity(parseInt(event.target.value));
							}
						}}
						{...valueProp}
						{...placeHolderProp}
						error={getError()}
					/>
					{formHelper.getFieldErrorJsx(productId ? 'quantity' : 'balanceAmount')}
				</div>
			</>
		</CmsContentListContext.Provider>
	);
};

UpdateCart.propTypes = {
	quantity: PropTypes.number,

	productId: PropTypes.number,

	additionalClassNames: PropTypes.string,
};

export default UpdateCart;
