/**
 * Displays helpful information and require no action or acknowledgement
 * from othe user.
 *
 * @design https://projects.invisionapp.com/d/main#/console/15463912/321351967/preview
 */
import React from 'react';
import PropTypes from 'prop-types';

import Info from './icons/ui/Info.js';

import * as style from './InfoPanel.module.css';



const InfoPanel = ({
	children,
	...rest
}) => {
	return (
		<div className={style.container}
			{...rest}
		>
			<div className={style.icon}>
				<Info aria-hidden={false} />
			</div>

			{children}
		</div>
	);
};

InfoPanel.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default InfoPanel;
