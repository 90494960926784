import React from 'react';
import {
	values,
} from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Container from 'components/Container.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';

import * as banner from 'components/Banner.module.css';
import * as feature from 'components/home/Features.module.css';
import * as style from './RegisterForCharlieAccount.module.css';

import phoneinhand from 'components/home/assets/phone-in-hand.png';
import CmsObject from 'components/data/CmsObject';

const REGISTER_KEY = 'register';

///  This is a full width banner that appears on the Guest Login below the card forms
const RegisterForCharlieAccount = () => {
	return (
		<CmsObject contentKey="features">{({ data }) => {
			const item = values(data.cmsObject.obj).find(obj => obj.key === REGISTER_KEY);

			return (
				<section className={banner.accountBenefits} aria-label={item.title}>
					<Container>
						<div className={style.wrapper}>
							<div className={style.containerWidth}>
								<div className={style.image}>
									<img
										src={item.image_path ?? phoneinhand}
										alt={item.cta_text}
										className={banner.accountBenefitsImage}
									/>
								</div>
							</div>

							<div className={style.containerWidth}>
								<CmsContentRenderer.H3
									content={item.title}
									fallbackValue="Register for a Charlie Account"
									className={banner.accountBenefitsTitle}
								/>

								<CmsContentRenderer.Div
									rawHtml
									content={item.text}
									className={feature.text}
								/>

								<Button
									isPrimary
									to={getPathByRoute(routeKeys.Register)}
									additionalClassNames={banner.accountBenefitsBtn}
									data-qa="GuestRegisterBtnCreate"
								>
									<CmsContentRenderer.Span
										content={item.button_text}
										fallbackValue="Register for a Charlie Account"
									/>
								</Button>
								<div className={style.benefits}>
									<Button
										typeStyle={buttonTypeStylePlain}
										to="#"
										additionalClassNames={banner.accountBenefitsCta}
									>
										<CmsContentRenderer.Span
											content={item.cta_text}
											fallbackValue="See the Benefits of Registering"
										/>
									</Button>
								</div>
							</div>
						</div>
					</Container>
				</section>
			);
		}}</CmsObject>
	);
};

export default RegisterForCharlieAccount;
