import { clone } from "lodash";
import WSTravelTokenDisplay, { WSTravelTokenDisplayType } from "./WSTravelTokenDisplay";

export const EXIT_TYPE = 'Exit';
export const ENTRY_TYPE = 'Entry';

// 2.7.68 WSTravelPresence
export default class WSTravelPresence {
	constructor({
		objectType,
		travelPresenceId,
		transactionDateTime,
		type,
		typeDescription,
		locationCode,
		locationDescription,
		operator,
		operatorDescription,
		token,
		status,
		statusDescription,
		generatedCause,
		generatedCauseDescription,
		travelPresenceIndicator,
		isCorrectable,
		isVoidable,
	}) {

		// string(20)
		// (Required)  Type of travel presence. This value should be used to determine sub-classes when they valid values are:
		// TransitTravel
		this.objectType = objectType;

		// string(20)
		// (Required)  Unique identifier for the travel presence event.
		this.travelPresenceId = travelPresenceId;

		// dateTime
		// (Required)  The date when the traveler’s presence was captured.
		this.transactionDateTime = transactionDateTime;

		// string(20)
		// (Required)  The type of the presence event. Examples are Tap In, Tap Out
		this.type = type;

		// string(40)
		// (Optional)  Stop Id or Code where the use transaction was captured. The stopPoint
		// here is the starting location (entry tap).
		this.typeDescription = typeDescription;

		// string(10)
		// (Required)  The code of the location where the traveler’s presence was captured.
		this.locationCode = locationCode;

		// string(100)
		// (Required)  The location description where the traveler’s presence was captured.
		this.locationDescription = locationDescription;

		// string(20)
		// (Required)  Unique identifier of the operator.
		this.operator = operator;

		// string(30)
		// (Required)  Description of the operator.
		this.operatorDescription = operatorDescription;

		// WSTravelTokenDisplay
		// (Required)  Token used for the travel.
		this.token = token;

		// string(20)
		// (Required)  The unique identifier of the travel presence event status.
		this.status = status;

		// string(30)
		// (Required)  Description of travel presence event status.
		this.statusDescription = statusDescription;

		// string(20)
		// (Required)  The unique identifier of the cause of the travel presence event.
		this.generatedCause = generatedCause;

		// string(30)
		// (Required)  Description of cause of the travel presence event.
		this.generatedCauseDescription = generatedCauseDescription;

		// string(30)
		// (Required)  Indicator of the travel presence event. Allowed valued are:
		// Incomplete
		// Auto-Filled
		// Manual-Filled
		// DeviceTap
		this.travelPresenceIndicator = travelPresenceIndicator;

		// boolean
		// (Required)  Flag to indicate if this travel presence record can be corrected.
		this.isCorrectable = isCorrectable;

		// boolean
		// (Required)  Flag to indicate if this travel presence record can be voided.
		this.isVoidable = isVoidable;
	}

	static fromBackoffice(data) {
		const result = new WSTravelPresence(data);
		result.token = WSTravelTokenDisplay.fromBackoffice(data.token);

		return result;
	}

	toBackoffice() {
		const result = clone(this);
		result.token = this.token.toBackoffice();
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSTravelPresenceType = [
	...WSTravelTokenDisplayType,
	`
	type WSTravelPresence {
		objectType: String!
		travelPresenceId: String!
		transactionDateTime: GraphQLDateTime!
		type: String!
		typeDescription: String
		locationCode: String!
		locationDescription: String!
		operator: String!
		operatorDescription: String!
		token: WSTravelTokenDisplay!
		status: String!
		statusDescription: String!
		generatedCause: String!
		generatedCauseDescription: String!
		travelPresenceIndicator: String!
		isCorrectable: Boolean!
		isVoidable: Boolean!
	}
` ];
