import React, {
	Fragment,
	useRef,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';

import CollapsibleContext, { CollapsibleContextProvider } from "context/CollapsibleContext.js";

import * as style from './Collapsible.module.css';


const Collapsible = ({
	additionalClasses = '',
	toggleOpenState,
	toggleClosedState,
	provideOpenContext = true,
	initialOpenState = false,
	overrideOpen,
	itemKey,
	children,
}) => {
	const contentRef = useRef();
	const OpenContext = provideOpenContext
		? CollapsibleContextProvider
		: Fragment;


	return (
		<OpenContext initialOpenState={initialOpenState}>
			<CollapsibleContext.Consumer>{([ isOpen, setIsOpen ]) => (
				<div className={cx(additionalClasses,
					isOpen ? style.isOpen : null,
				)}>
					<button type="button"
						className={style.toggle}
						aria-expanded={isOpen}
						aria-controls={`${itemKey}-content`}
						onClick={() => setIsOpen(!isOpen)}
						data-qa="CollapsibleToggle"
						disabled={overrideOpen}
					>
						{isOpen ? toggleOpenState : toggleClosedState}
					</button>

					<Collapse
						isOpen={isOpen}
						className={style.slide}
						overflowOnExpanded={true}
					>
						<div id={`${itemKey}-content`}
							className={style.content}
							aria-hidden={!isOpen}
							ref={contentRef}
						>
							{children}
						</div>
					</Collapse>
				</div>
			)}</CollapsibleContext.Consumer>
		</OpenContext>
	);
};

Collapsible.propTypes = {
	open: PropTypes.bool,
	additionalClasses: PropTypes.string,

	toggleOpenState: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),

	toggleClosedState: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),

	provideOpenContext: PropTypes.bool,

	itemKey: PropTypes.string.isRequired,

	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default Collapsible;
