import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useCookies } from 'react-cookie';
import { values } from 'lodash';
import htmlescape from "htmlescape";

// app imports
import { APP_NAME } from '../utils/Constants.js';
import { LANGUAGE_COOKIE_NAME, LANGUAGES } from "components/data/Language.js";
import PublicAppVars from '../utils/PublicAppVars.js';
import CmsContentList from '../components/data/CmsContentList.js';
import { useCspNonceContext } from "../components/CspNonceContext.js";
import StdQuery from "components/data/StdQuery.js";


const metaContent = {
	defaultTitle: 'miscText.meta-default-title',
	metaDescription: 'miscText.meta-description',
};

// see docs at https://github.com/nfl/react-helmet
const HelmetDefaults = () => {
	const [ cookies ] = useCookies([ LANGUAGE_COOKIE_NAME ]);

	// https://cloud.google.com/translate/attribution requires
	// https://cloud.google.com/translate/markup
	// which specifies this html lang attribute
	const language = cookies?.language ?? PublicAppVars.LANGUAGE;
	const lang = language === 'en'
		? 'en'
		: `${language}-x-mtfrom-en`;

	const languageDirection = LANGUAGES[ language ]?.direction;

	const titleTemplate = `
		${PublicAppVars.ENV_NAME ? '[' + PublicAppVars.ENV_NAME + '] ' : ''}
		%s | Charlie
	`;

	return (
		<CmsContentList list={values(metaContent)}>{({ cmsContent }) => (
			<Helmet
				encodeSpecialCharacters={true}
				{...{ titleTemplate }}
				defaultTitle={cmsContent[ metaContent.defaultTitle ]}
			>
				{/* html attributes */}
				<html lang={lang} dir={languageDirection} />

				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />

				<meta charSet="utf-8" />

				{/* Meta descriptions are commonly used on search engine result pages to display preview snippets for a given page. */}
				<meta name="description" content={cmsContent[ metaContent.metaDescription ]} />

				{/* The width property controls the size of the viewport, the initial-scale property controls
				the zoom level when the page is first loaded */}
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, target-densityDpi=device-dpi" />

				{/* Add to homescreen for Chrome on Android */}
				<meta name="mobile-web-app-capable" content="yes" />

				{/* Add to homescreen for Safari on IOS */}
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black" />
				<meta name="apple-mobile-web-app-title" content={APP_NAME} />

				<script id="app-vars" type="application/json">
					{htmlescape(PublicAppVars)}
				</script>

				<link rel="apple-touch-icon" type="image/png" sizes="180x180" href="https://cdn.mbta.com/images/mbta-logo-t-180-d5470cd485cc2f7b41871621e151b1c1.png?vsn=d"  />
				<link rel="icon" type="image/png" sizes="32x32" href="https://cdn.mbta.com/images/mbta-logo-t-favicon-86d6bc07973596dbd09bc4e81f3577f6.png?vsn=d" />
				<link rel="icon" type="image/png" sizes="16x16" href="https://cdn.mbta.com/favicon-3092667d44a8676d16eba452955a7c24.ico?vsn=d" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<meta name="msapplication-TileColor" content="#da532c" />
				<meta name="theme-color" content="#ffffff" />

				{PublicAppVars.PYPESTREAM_CHATBOT_APP_ID
					? <link rel="preconnect" href={new URL(PublicAppVars.PYPESTREAM_CHATBOT_SCRIPT_SRC).origin} />
					: null}
			</Helmet>
		)}</CmsContentList>
	);
};

export default HelmetDefaults;
