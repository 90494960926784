import React, {
	Fragment,
} from "react";
import {
	map,
	some,
} from "lodash";
import cx from "classnames";

import { useModalContext } from "context/ModalProvider.js";
import { useCartContext } from 'context/CartProvider.js';
import PreventDefault from 'utils/PreventDefault.js';

import Button from "components/Button.js";
import { SmallChevron } from "components/Icon.js";
import Input from 'components/forms/Input.js';
import Pass from "components/account/products/Pass.js";
import ProductDetails from "components/modals/ProductDetails.js";

import * as card from "./ProductCard.module.css";
import * as balanceStyles from 'components/account/reload-balance/ReloadBalance.module.css';

import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import { useProductCatalogContext } from "components/data/transit-account/ProductCatalogContext.js";


const cms = {
	add: "miscText.purchase-add-to-cart",
};

const ProductCard = ({
	hideAddToCart = false,
	products: processedProducts,
	maxAddTransitValue,
	parentFormHelper,
	setValidationState,
}) => {

	const { setModal } = useModalContext();
	const {
		cart,
		addProduct,
	} = useCartContext();

	const { operators } = useProductCatalogContext();

	const currentBalance = cart.balance;

	const onAddToCart = (wsTransitAccountProduct) => {
		setValidationState({ validationError: null });
		addProduct(wsTransitAccountProduct);
	};

	const toggleProductModal = (wsTransitAccountProduct, isInCart) => {
		setModal(
			<ProductDetails
				product={wsTransitAccountProduct}
				{... {
					hideAddToCart,
					isInCart,
					onAddToCart,
					operators,
				}}
			/>
		);
	};


	return (
		<div className={card.content}>
			{map(processedProducts, (wsTransitAccountProduct) => {
				const isInCart = some(cart.products, wsTransitAccountProduct);

				return (
					<Fragment key={wsTransitAccountProduct.productSku}>
						<div
							className={cx(card.hideOnDesktop, card.cardStuff, card.isMobile, isInCart && card.inCart)}
							onClick={PreventDefault(() => toggleProductModal(wsTransitAccountProduct, isInCart))}
						>
							<Pass {...{
								wsTransitAccountProduct,
								isInCart,
							}}>
								<div className={balanceStyles.arrowIconWrapper}>
									<SmallChevron />
								</div>
							</Pass>
						</div>

						<div className={cx(card.hideOnMobile, card.cardStuff)}>
							<Pass {...{
								wsTransitAccountProduct,
								isInCart,
							}}>
								{(isInCart || hideAddToCart)
									? null
									: (
										<Button
											isPrimary
											type="button"
											onClick={PreventDefault(() => onAddToCart(wsTransitAccountProduct))}
										>
											<CmsContentRenderedInline
												contentKey={cms.add}
												fallbackValue="Add to Cart"
											/>
										</Button>
									)}
							</Pass>
						</div>
					</Fragment>
				);
			})}

			<Input
				type="hidden"
				name="products"
				hideLabel
				value={cart.products}
				controlled={true}
				error={parentFormHelper.getFieldError("products")}
			/>
		</div>
	);
};

export default ProductCard;
