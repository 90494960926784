export default class WSKeyValue {
	constructor({
		key,
		value,
	}) {
		this.key = key;
		this.value = value;
	}

	static fromBackoffice(data) {
		const result = new WSKeyValue(data);
		return result;
	}

	static fromSerialized(data) {
		return new WSKeyValue(data);
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

export const WSKeyValueType = [
	`
		type WSKeyValue {
			key: String
			value: String
		}
	`,
];
