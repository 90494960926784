import React, { useRef, useState } from "react";
import cx from "classnames";
import { values,  map, isEmpty } from 'lodash';
import { useApolloClient } from "@apollo/client";
import { gql } from '@apollo/client/core';
import * as style from './TransferProducts.module.css';
import * as takeoverStyles from 'layouts/TakeOverLayout.module.css';
import * as panel from "components/Panel.module.css";
import {
	object as yup_object,
} from 'yup';

import Button, { buttonTypeStylePlain } from "components/Button.js";
import PreventDefault from "utils/PreventDefault.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import CmsContentList from "components/data/CmsContentList.js";
import Toast from "components/Toast.js";
import { centsToDisplay } from "utils/FormatHelpers.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { getSessionTransitAccountRefetchQueries } from "components/data/transit-account/SessionTransitAccount.js";
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import { levels, noticeError } from "utils/Logger.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import FormHelper from "utils/FormHelper.js";
import { Lifecycles } from "libreact/lib/Lifecycles";
import { Redirect } from "react-router-dom";
import { getPathByRoute } from "App.js";
import routeKeys from "CustomerRouteKeys.js";
import { usePurseBalanceContext } from "context/PurseBalanceContext.js";
import { reMapErrors } from "components/account/purchases/ValuePurchaseTypeSelection.js";
import { useStepContext } from "context/StepContext.js";
import { useTransferContext } from "context/TransferContext.js";
import TakeOverLayout from "layouts/TakeOverLayout.js";
import { TRANSFER_FLOW_STEPS } from "./TransfersFlow.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { findPrimaryToken, getTokenNicknameAndPan } from "components/manage-cards/TokenHelpers.js";

export const POST_ORDER_TRANSFER = gql`
	mutation OrderTransfer (
		$sourceSubsystemAccountReference: String!
		$targetSubsystemAccountReference: String!
		$targetCustomerId: ID
		$passSerialNumbers: [ String ]
		$transferValue: Int
		$transferValueOption: Boolean!
	) {
		OrderRoute {
			id
			postOrderTransfer (
				sourceSubsystemAccountReference: $sourceSubsystemAccountReference
				targetSubsystemAccountReference: $targetSubsystemAccountReference
				targetCustomerId: $targetCustomerId
				passSerialNumbers: $passSerialNumbers
				transferValue: $transferValue
				transferValueOption: $transferValueOption
			) {
				orderId
			}
		}
	}
`;

const cms = {
	confirmation: 'miscText.transfer-confirmation',
	header: 'miscText.transfer-header',
	description: 'miscHtml.transfer-confirm-description',
	back: 'miscText.transfer-confirm-back',

	carlieCards: 'miscText.transfer-confirm-cards-subheader',
	transferFrom: 'miscText.transfer-confirm-cards-from',
	transferTo: 'miscText.transfer-confirm-cards-to',
	itemsBeingTransfered: 'miscText.transfer-confirm-items-subheader',
	balance: 'miscText.transfer-confirm-items-balance',

	balanceAndPasses: 'miscText.transfer-balance-passes',
	cancel: 'miscText.transfer-confirm-cancel',
	submit: 'miscText.transfer-confirm-submit',
};

export const TransferItemsList = ({ balance, passDescriptions }) => (
	<ul>
		{balance
			? <li>{centsToDisplay(balance)}</li>
			: null
		}
		{map(passDescriptions, passDescription => <li>{passDescription}</li>)}
	</ul>
);

export const TransferProductsSuccessToast = ({ nickname, balance, passDescriptions }) => {
	const { removeToast } = useGlobalToastsContext();
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => {
			return (<Toast
				type="success"
				onClosed={removeToast}
				title={
					<>
						<CmsContentRenderedInline
							contentKey={cms.confirmation}
							variables={{ nickname }}
							fallbackValue="Confirmation" />
						<TransferItemsList {...{ balance, passDescriptions }} />
					</>}
			/>);
		}}</CmsContentList>
	);
};

export const TransferDetails = ({ sourceCardNickname, targetNickname, balance, passDescriptions })	=> (
	<CmsContentList list={values(cms)}>{() => (
		<div className={cx(style.confirmationDetailsWrapper, panel.card)}>
			<CmsContentRenderer.H4 className={style.transferConfirmationSubheaders} contentKey={cms.carlieCards} fallbackValue="CHARLIE CARDS" />
			<div className={style.confirmationDetailsSection}>
				<div>
					<CmsContentRenderer.Span contentKey={cms.transferFrom} className={style.transferSubheader} fallbackValue="Transfer From" />
					<br />
					{ sourceCardNickname }
				</div>
				<div>
					<CmsContentRenderer.Span contentKey={cms.transferTo} className={style.transferSubheader} fallbackValue="Transfer To" />
					<br />
					{ targetNickname }
				</div>
			</div>

			<CmsContentRenderer.H4 className={style.transferConfirmationSubheaders} contentKey={cms.itemsBeingTransfered} fallbackValue="ITEMS FOR TRANSFER" />
			<div className={style.confirmationDetailsSection}>
				<CmsContentRenderer.Span className={style.transferSubheader} contentKey={cms.balanceAndPasses} fallbackValue="Balance and Passes" />
				<br />
				{ balance
					? <><CmsContentRenderer.Span contentKey={cms.balance} fallbackValue="HC Balance" />&nbsp; {centsToDisplay(balance)}</>
					: null }
				{passDescriptions ?
					map(passDescriptions, passDescription => <p>{passDescription}</p>)
					: null
				}
			</div>
		</div>
	)}</CmsContentList>
);


const getYupSchema = () => {
	return yup_object().shape({});
};

const TransferProductsConfirmation = ({
	transitAccountQ,
	cancelUrl,
}) => {
	// Data from Contexts
	const { step, prevStep } = useStepContext();
	const { cart, validationState, setValidationState } = useTransferContext();
	const sourceSubsystemAccountReference = useTransitAccountIdContext();
	const { supportsTransferTotal: sourceBalance } = usePurseBalanceContext();

	// Navigational Helpers
	const { setToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);

	// FORM States
	const apolloClient = useApolloClient();
	const [ submitting, setSubmitting ] = useState(false);

	// FORM Set up
	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(
		validationState,
		setValidationState,
		getYupSchema,
	);

	// Parsings and Assignments
	const passDescriptions = map(cart.transferPasses, wsSubsystemAccountPass => wsSubsystemAccountPass.passDescription);

	const targetNickname = cart.targetCard.nickname;
	const balance = cart.transferValue;

	const sourceMasterToken = findPrimaryToken(transitAccountQ.tokens);
	const sourceCardNickname = getTokenNicknameAndPan(sourceMasterToken);

	const kickoffSubmit = async () => {
		setSubmitting(true);

		// Submit Object
		const postData = {
			transferValueOption: cart.transferValueOption,
			transferValue: parseInt(cart.transferValue, 10),
			passSerialNumbers: map(cart.transferPasses, ({ passSerialNbr }) => passSerialNbr),
			sourceSubsystemAccountReference,
			targetSubsystemAccountReference: cart.targetCard.subsystemAccountReference,
			targetCustomerId: cart.targetCustomerId,
		};

		try {
			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: POST_ORDER_TRANSFER,
					variables: postData,
					refetchQueries: [
						...getSessionTransitAccountRefetchQueries({ sourceSubsystemAccountReference }),
						...getTransitAccountRefetchQueries(sourceSubsystemAccountReference),
						// refresh cache for the TA recieving the transfer
						...getTransitAccountRefetchQueries(postData.targetSubsystemAccountReference),
					],
				}));
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.info, errorReport, `Transfer Value Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			reMapErrors({
				errorReport,
				formHelper,
				maxAddTransitValue: sourceBalance,
				minAddTransitValue: 1,
			 });

			 // send field level errors back to step 1
			 if (!isEmpty(errorReport.FieldErrors)) {
				prevStep();
			 }

			return;
		}

		setSubmitting(false);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardOverview, {
					transit_account_id: sourceSubsystemAccountReference,
				}),
				state: { success: true },
			}} />,
		);
		setToast(<TransferProductsSuccessToast {...{ nickname: targetNickname, balance, passDescriptions }} />);
	};


	if (redirect) {
		return redirect;
	}

	return (
		<Lifecycles didMount={formHelper.wireInputs}>
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (
				<form
					method="post"
					data-qa="transferProductConfirm"
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					<TakeOverLayout
						title={cmsContent[ cms.header ]}
						cancelLink={cancelUrl}
						showCancel
						showNickname
						steps={TRANSFER_FLOW_STEPS}
						currentStep={step}
					>
						<div>
							<div className={style.section}>
								<div className={takeoverStyles.backButtonWrapper}>
									<Button
										typeStyle={buttonTypeStylePlain}
										onClick={PreventDefault(prevStep)}
									>
										<CmsContentRenderer.Span contentKey={cms.back} fallbackValue="Back to Select Items for Transfer" />
									</Button>
								</div>
								<CmsContentRenderedInline
									contentKey={cms.description}
									variables={{ nickname: targetNickname }}
									rawHtml={true}
									fallbackValue="Please make sure the information below is correct before submitting your transfer.<br>You may make up to 3 transfers per day." />
							</div>

							<TransferDetails {...{ sourceCardNickname, targetNickname, balance, passDescriptions }} />

							{formHelper.getFieldErrorJsx('')}
							<div className={cx(style.section, style.transferButtonsWrapper)}>
								<Button
									additionalClassNames={style.transferButton}
									isPrimary
									type="submit"
									submitting={submitting} >
									<CmsContentRenderer.Span contentKey={cms.submit} fallbackValue="Complete Transfer" />
								</Button>
							</div>
						</div>
					</TakeOverLayout>
				</form>
			)}</CmsContentList>
		</Lifecycles>
	);
};

export default TransferProductsConfirmation;
