// extracted by mini-css-extract-plugin
export var cancelButton = "components-modals-__EditAddress-module___cancelButton";
export var cardDisplayInfo = "components-modals-__EditAddress-module___cardDisplayInfo";
export var cardIcon = "components-modals-__EditAddress-module___cardIcon";
export var cardInfoContainer = "components-modals-__EditAddress-module___cardInfoContainer";
export var cardName = "components-modals-__EditAddress-module___cardName";
export var checkboxWrapper = "components-modals-__EditAddress-module___checkboxWrapper";
export var defaultAddressCheckbox = "components-modals-__EditAddress-module___defaultAddressCheckbox";
export var defaultAddressDisplayText = "components-modals-__EditAddress-module___defaultAddressDisplayText";
export var defaultAddressHelpText = "components-modals-__EditAddress-module___defaultAddressHelpText";
export var editAddressStackedFormRow = "components-modals-__EditAddress-module___editAddressStackedFormRow";
export var errorMessageWrapper = "components-modals-__EditAddress-module___errorMessageWrapper";
export var hiddenInput = "components-modals-__EditAddress-module___hiddenInput";
export var statesSelectInput = "components-modals-__EditAddress-module___statesSelectInput";
export var submitBtn = "components-modals-__EditAddress-module___submitBtn";