import React, {
	Component,
	Fragment,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import cx from 'classnames';

import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';

import Modal from '../Modal.js';
import Alert from '../Alert.js';
import Button, {
	Primary,
	Secondary,
} from '../Button.js';
import Input from '../forms/Input.js';
import Checkbox from '../forms/Checkbox.js';

import * as modalStyles from '../Modal.module.css';
import * as forms from '../forms/Forms.module.css';


/**
 * Data-QA Tags:
 * JoinGroupModalTitle
 * JoinGroupModalForm, JoinGroupModalLabel, JoinGroupModalInput, JoinGroupModalCheckbox JoinGroupModalAction
 */

class JoinGoup extends Component {

	static propTypes = {
		modalActive: PropTypes.bool,
		onModalClose: PropTypes.func.isRequired,
	};

	static defaultProps = {
		modalActive: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			modalActive: false,
			isSuccessful: false,
		};

		this.setSuccess = this.setSuccess.bind(this);
		this.closeSuccess = this.closeSuccess.bind(this);
		this.setError = this.setError.bind(this);
		this.closeError = this.closeError.bind(this);
	}

	setSuccess() {
		this.setState({ isSuccessful: true });
		this.props.onModalClose();
	}


	closeSuccess() {
		this.setState({ isSuccessful: false });
	}


	setError() {
		this.setState({ isError: true });
		this.props.onModalClose();
	}


	closeError() {
		this.setState({ isError: false });
	}


	textTermsConditions() {
		return (
			<Fragment>
				Please read our &nbsp;
				<Link to={getPathByRoute(routeKeys.TermsOfService)}
					  className={forms.textLink}
				>
					Terms and Conditions
				</Link>
				&nbsp; carefully.
			</Fragment>
		);
	}


	render() {
		const {
			modalActive,
			onModalClose,
			...rest
		} = this.props;

		return (
			<Fragment>
				<Modal
					title="Join a Goup"
					modalActive={modalActive}
					onModalClose={onModalClose}
					overrideClass={modalStyles.isSmall}
				>
					<p className={modalStyles.text}
					   data-qa="JoinGroupModalText"
					>
						If your have received a Group ID as a part of an Employer Benefits Program or Community Program,
						please enter it below.
					</p>

					<Input
						type="text"
						name="groupID"
						label="Group ID"
						placeholder="AAA-B000-0000000"
						data-qa="JoinGroupModalInput"
					/>

					<div className={cx(forms.box, forms.isStacked, forms.isStretched)}>
						<label className={forms.label}>Group`&apos;`s Terms and Conditions</label>

						<Checkbox
							label={this.textTermsConditions()}
							name="JoinGroupTerms"
							data-qa="JoinGroupModalTermsCheckbox"
						/>
					</div>


					<div className={modalStyles.actions}>
						<Button
							text="Cancel"
							type={Secondary}
							onClick={this.setError}
							overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
							data-qa="JoinGroupModalBtn"
						/>
						<Button
							text="Join Group"
							type={Primary}
							onClick={this.setSuccess}
							overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
							data-qa="JoinGroupModalBtn"
						/>
					</div>
				</Modal>

				<Alert
					type="success"
					title="You have joined a group"
					text="You have successfully joined the group."
					modalActive={this.state.isSuccessful}
					onModalClose={this.closeSuccess}
				/>
			</Fragment>
		);
	}
}

export default JoinGoup;
