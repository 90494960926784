// extracted by mini-css-extract-plugin
export var disclaimer = "components-account-panels-__NotificationContacts-module___disclaimer";
export var email = "components-account-panels-__NotificationContacts-module___email";
export var emailText = "components-account-panels-__NotificationContacts-module___emailText";
export var header = "components-account-panels-__NotificationContacts-module___header";
export var headerWrapper = "components-account-panels-__NotificationContacts-module___headerWrapper";
export var label = "components-account-panels-__NotificationContacts-module___label";
export var link = "components-account-panels-__NotificationContacts-module___link";
export var mainContainer = "components-account-panels-__NotificationContacts-module___mainContainer";
export var phone = "components-account-panels-__NotificationContacts-module___phone";
export var phoneDropdown = "components-account-panels-__NotificationContacts-module___phoneDropdown";
export var phoneDropdown__control = "components-account-panels-__NotificationContacts-module___phoneDropdown__control";
export var phoneDropdown__singleValue = "components-account-panels-__NotificationContacts-module___phoneDropdown__single-value";
export var phoneDropdown__valueContainer = "components-account-panels-__NotificationContacts-module___phoneDropdown__value-container";
export var phoneLabel = "components-account-panels-__NotificationContacts-module___phoneLabel";
export var sectionWrapper = "components-account-panels-__NotificationContacts-module___sectionWrapper";
export var title = "components-account-panels-__NotificationContacts-module___title";