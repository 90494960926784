import React from 'react';
import { Helmet } from 'react-helmet-async';

import PublicAppVars from 'utils/PublicAppVars.js';


// see docs at https://github.com/nfl/react-helmet
// for info on Open Graph markup https://developers.facebook.com/docs/sharing/webmasters/
const HelmetOGTags = (props) => {
	const {
		title,
		metaDescription,
		ogType,
		ogTitle,
		ogMetaDescription,
	} = props;

	const defaultedOgTitle = ogTitle
		? ogTitle
		: title;

	const defaultedOgDescription = ogMetaDescription
		? ogMetaDescription
		: metaDescription;

	return (
		<Helmet encodeSpecialCharacters={true}>
			{title ? <title>{title}</title> : null}
			{metaDescription ? <meta name="description" content={metaDescription} /> : null}

			{ogType ? <meta name="og:type" content={ogType} /> : null}
			{defaultedOgTitle ? <meta property="og:title" content={defaultedOgTitle} /> : null}
			{defaultedOgTitle ? <meta name="twitter:title" content={defaultedOgTitle} /> : null}
			{/* A brief description of the content, usually between 2 and 4 sentences. This will displayed below the title of the post. */}
			{defaultedOgDescription ? <meta property="og:description" content={defaultedOgDescription} /> : null}
			{defaultedOgDescription ? <meta name="twitter:description" content={defaultedOgDescription} /> : null}

			{/* <meta property="og:image" content={PublicAppVars.PUBLIC_URL + '/hardcoded-assets/omny-meta-image.png'} />
			<meta name="twitter:image" content={PublicAppVars.PUBLIC_URL + '/hardcoded-assets/omny-meta-image.png'} />
			<meta property="og:image:alt" content="black rectangular OMNY logo" />
			<meta property="og:image:width" content="1200" />
			<meta property="og:image:height" content="630" />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@mta" />
			<meta name="twitter:creator" content="@mta" /> */}
		</Helmet>
	);
};

export default HelmetOGTags;
