import React from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';

import Tooltip from 'components/Tooltip.js';

import * as forms from "./Forms.module.css";
import * as inputStyles from "./Input.module.css";

import FormHelper from "utils/FormHelper.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';

const Control = ({
	overrideClass = '', //legacy use `additionalClasses`
	additionalClasses = '',

	target,
	labelId,
	label,
	labelFirst = true,
	hideLabel = false,
	isOptional,
	children,
	tooltip = null,

	//legacy use `error`
	errorMsg = null,
	error = null,
}) => {

	const getFieldErrorJsx = () => {
		const stateField = FormHelper.getStateErrorField(target);
		return FormHelper.errorJsx(error, stateField);
	};

	const errorJsx = (getFieldErrorJsx() || <p className={forms.errorMsg}>{errorMsg}</p>);

	const wrapper = (children) => (
		<div className={cx(forms.control, overrideClass, additionalClasses)}>
			{children}
		</div>
	);

	if (hideLabel) {
		return wrapper(
			<>
				{children}
				{errorJsx}
			</>
		);
	}

	return wrapper(
		<>
			{labelFirst
				? null
				: children}

			<label className={inputStyles.label} htmlFor={target} id={labelId}>
				{label}

				{tooltip
					? <Tooltip text={tooltip} />
					: null}

				{isOptional
					? <CmsContentRenderedInline
						contentKey={"miscText.general-optional-field"}
						fallbackValue="Optional"
						className={forms.optionalLabel}
					/>
					: null}
			</label>

			{labelFirst
				? children
				: null}

			{errorJsx}
		</>
	);
};

Control.propTypes = {
	target: PropTypes.string,
	labelId: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	secondLabel: PropTypes.string,
	labelFirst: PropTypes.bool,
	hideLabel: PropTypes.bool,
	overrideClass: PropTypes.string,
	tooltip: PropTypes.string,
	errorMsg: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
		PropTypes.object,
	]),
	additionalClasses: PropTypes.string,
};

export default Control;
