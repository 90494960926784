import { clone } from "lodash";
import { hashId } from "server/utils/HashUtils.js";

export const LINK_STATUSES = {
	pending: 'PENDING',
	accepted: 'ACCEPTED',
	declined: 'DECLINED',
	revoked: 'REVOKED',
	// @todo There's others here I can't remember
};

// 2.7.105 WSAlternateContactInfo
//
export default class WSAlternateContactInfo {
	constructor({
		linkId,
		alternateCustomerId = null,
		alternateContactId = null,
		firstName,
		lastName,
		email,
		phone,
		linkStatus,
		statusChangeDtm,
	}) {

		// String(40)
		// (Required) Unique identifier generated for
		// alternate contact link
		this.linkId = linkId;

		// string(40)
		// (Required) Unique identifier for customer
		this.alternateCustomerId = alternateCustomerId ?? null;

		// string(40)
		// (Required) Unique identifier for contact
		this.alternateContactId = alternateContactId ?? null;

		// string(60)
		// (Required) The person's first name
		this.firstName = firstName ?? null;

		// string(60)
		// (Required) The person's last name
		this.lastName = lastName ?? null;

		// string(100)
		// (Required) The contact’s email address.
		this.email = email;

		// string(20)
		// (Required) The phone number in national format.
		this.phone = phone;

		// string(20)
		// (Required) Link Status enum
		this.linkStatus = linkStatus;

		// Date
		// (Required) Datetime of Link status change
		this.statusChangeDtm = statusChangeDtm;
	}

	static fromBackoffice(data) {
		return new WSAlternateContactInfo(data);
	}

	static fromSerialized(data) {
		return new WSAlternateContactInfo(data);
	}

	toBackoffice() {
		return clone(this);
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.linkId;

		forGraphql.alternateCustomerId = this.alternateCustomerId ? hashId(this.alternateCustomerId) : null;
		forGraphql.alternateContactId = this.alternateContactId ? hashId(this.alternateContactId) : null;

		return forGraphql;
	}
}

export const WSAlternateContactInfoType = [
	`
		type WSAlternateContactInfo {
			id: ID!
			linkId: String!
			alternateCustomerId: String
			alternateContactId: String
			firstName: String
			lastName: String
			email: String!
			phone: String!
			linkStatus: String!
			statusChangeDtm: DateTime!
		}
	`,
];
