import { gql } from "@apollo/client";

import StdQuery from "../StdQuery.js";
import React from "react";

import PropTypes from "prop-types";
import {
	tokenFragment,
	purseFragment,
	passFragment,
} from 'components/data/transit-account/SessionTransitAccount.js';
import { SIZES } from "components/icons/LoadingIcon.js";
import { useRefetchIfOld, RefetchIfOld } from "components/data/ServerTime.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { levels, noticeError } from "utils/Logger.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";


export const RIDER_CLASSES = {
	fullFare: 1,
};

// Fetch TA from transitaccount/<transitaccount-id>/subsystem/<subsystem-id> GET
export const GET_TRANSIT_ACCOUNT = gql`
	query TransitAccountGET (
		$subsystemAccountReference: ID
		$archivedTokens: Boolean
		$subscriptions: Boolean!
		$programMemberships: Boolean!
		$transferProductInfo: Boolean!
		$productPayments: Boolean!
	){
		transitAccountQ (
			subsystemAccountReference: $subsystemAccountReference
			productPayments: $productPayments
		){
			subsystemAccountReference

			accountStatus
			accountStatusDescription
			accountStatusChangeDateTime

			riderClass
			riderClassDescription

			debtCollectionInfo {
				action
				amountDue

				#Per api doc: We get bankcardCharged only when "action" is "AvailableWait"
				bankcardCharged {
					maskedPan
					expirationMonth
					expirationYear
					creditCardType
				}
				attemptInSeconds
				remainingAttemptsForDay
			}

			tapVoidInfo {
				totalVoidCount
				provisionalVoidCount
				totalCorrectionCount
				provisionalCorrectionCount
			}

			orderInfo {
				totalVoidCount
				provisionalVoidCount
				totalCorrectionCount
				provisionalCorrectionCount
			}

			tokens(archived: $archivedTokens) {
				${tokenFragment}
				...on WSTransitAccountToken {
					canBePrimary
					statusReasonCodeDesc
					statusReasonCode
				}
			}

			purses {
				${purseFragment}
			}

			passes {
				${passFragment}
			}

			subscriptions @include(if: $subscriptions) {
				id
				passId
				subscriptionId
				status
				startDtm
				criteria {
					type
					thresholdValue
					day
					frequency
					recurrence
				}
				autoload {
					type
					loadValueBehavior
					...on WSAutoloadSubsystemValue {
						subsystemRef
						subsystemAccountId
					}

					...on WSAutoloadSubsystemProduct {
						subsystemAccountId
						productName
						productSKU
						travelTokenId
					}
				}
			}

			# WSProgramMembership
			programMemberships @include(if: $programMemberships) {
				id
				b2bCustomerId
				b2bCustomerName
				programId
				programName
				memberId
				memberStatus
				memberStatusShortDesc
				# WSMemberBenefit
				benefitElections {
					id
					benefitId
					benefitName
					benefitElectionId
					benefitDeliveryTypeId
					pretax
					electedAmount
				}
				memberStartDate
				memberExpirationDate
				memberExternalRef
			}

			# WSTransitAccountTransferProductInfo
			transferProductInfo @include(if: $transferProductInfo) {
				totalTransferCount
				provisionalTransferCount
			}

			id
			nodeTime
		}
	}
`;

export const getTransitAccountRefetchQueries = (subsystemAccountReference) => [
	{ query: GET_TRANSIT_ACCOUNT, variables: { subsystemAccountReference, subscriptions: true, archivedTokens: false, programMemberships: false, transferProductInfo: false, productPayments: false } },

	// as far as i can tell, subscriptions: false, programMemberships: true isn't used
	{ query: GET_TRANSIT_ACCOUNT, variables: { subsystemAccountReference, subscriptions: true, programMemberships: true, transferProductInfo: false, productPayments: false } },
	{ query: GET_TRANSIT_ACCOUNT, variables: { subsystemAccountReference, subscriptions: true, programMemberships: false, transferProductInfo: false, productPayments: false } },
	{ query: GET_TRANSIT_ACCOUNT, variables: { subsystemAccountReference, subscriptions: false, programMemberships: false, transferProductInfo: false, productPayments: false } },
];

export const getTransitAccount = async ({
	formHelper,
	apolloClient,
	subsystemAccountReference = null,
	archivedTokens = false,
	subscriptions = false,
	programMemberships = false,
	transferProductInfo = false,
	productPayments = false,
}) => {
	let queryResult;
	try {
		queryResult = await graphqlErrorMiddleware(
			apolloClient.query({
				query: GET_TRANSIT_ACCOUNT,
				variables: {
					subsystemAccountReference,
					archivedTokens,
					subscriptions,
					programMemberships,
					transferProductInfo,
					productPayments,
				},
			}));
	}
	catch (errorReport) {
		noticeError(null, levels.info, errorReport, `Get Transit Account details info failed`);
		formHelper.validationErrorHandler(errorReport);
		return;
	}

	const { data, refetch, loading } = queryResult;

	return { data, refetch, loading };
};
/**
 * @param {?string} subsystemAccountReference
 * @param archivedTokens
 * @param subscriptions
 * @param programMemberships
 * @param transferProductInfo
 * @param productPayments
 * @param restProps
 * @return {{data: any, response: {subscribeToMore<TSubscriptionData=any, TSubscriptionVariables=OperationVariables extends OperationVariables>(options: SubscribeToMoreOptions<any, TSubscriptionVariables, TSubscriptionData>): () => void, variables: OperationVariables | undefined, data: any, called: boolean, startPolling(pollInterval: number): void, networkStatus: NetworkStatus, parsedError: any|null|null, refetch(variables?: Partial<OperationVariables>): Promise<ApolloQueryResult<any>>, reobserve(newOptions?: Partial<WatchQueryOptions<OperationVariables, any>>, newNetworkStatus?: NetworkStatus): Promise<ApolloQueryResult<any>>, error?: ApolloError, loading: boolean, updateQuery<TVars=OperationVariables extends OperationVariables>(mapFn: (previousQueryResult: any, options: Pick<WatchQueryOptions<TVars, any>, "variables">) => any): void, fetchMore<TFetchData=any, TFetchVars=OperationVariables extends OperationVariables>(fetchMoreOptions: (FetchMoreQueryOptions<TFetchVars, TFetchData> & {updateQuery?: (previousQueryResult: any, options: {fetchMoreResult: TFetchData, variables: TFetchVars}) => any})): Promise<ApolloQueryResult<TFetchData>>, stopPolling(): void, observable: ObservableQuery<any, OperationVariables>, client: ApolloClient<any>, queryName: any, previousData?: any}, loading: boolean}}
 */
export const useTransitAccount = ({
	subsystemAccountReference = null,
	archivedTokens = false,
	subscriptions = false,
	programMemberships = false,
	transferProductInfo = false,
	productPayments = false,
	...restProps
}) => {

	const response = useStdQuery(GET_TRANSIT_ACCOUNT, {
		variables: {
			subsystemAccountReference,
			archivedTokens,
			subscriptions,
			programMemberships,
			transferProductInfo,
			productPayments,
		},
		...restProps,
	});

	const { data, loading: loadingResponse, refetch } = response;
	const queryTime = data?.transitAccountQ?.nodeTime;

	useRefetchIfOld({ refetch, loading: loadingResponse, queryTime });

	return { data, loading: loadingResponse, response };
};

const TransitAccount = ({
	children,
	subsystemAccountReference = null,
	archivedTokens = false,
	subscriptions = false,
	programMemberships = false,
	transferProductInfo = false,
	productPayments = false,
	spinnerSize = SIZES.component,
	...restProps
}) => (
	<StdQuery query={GET_TRANSIT_ACCOUNT}
		variables={{
			subsystemAccountReference,
			archivedTokens,
			subscriptions,
			programMemberships,
			transferProductInfo,
			productPayments,
		}}
		{...{ spinnerSize }}
		{...restProps}
	>
		{(response) => {
			const { data, refetch, loading } = response;

			return <>
				<RefetchIfOld {...{
					refetch: refetch,
					loading: loading,
					queryTime: data?.transitAccountQ?.nodeTime,
				}} />

				{children(data, response)}
			</>;
		}}
	</StdQuery>
);

TransitAccount.propTypes = {
	children: PropTypes.func.isRequired,
	subsystemAccountReference: PropTypes.string,
};

export default TransitAccount;
