// noprotect
import React from 'react';
import { find, get, isEmpty, reduce } from 'lodash';
import PublicAppVars from 'utils/PublicAppVars.js';
import { addYupMethod } from 'utils/YupValidators.js';

import { boolean as yup_boolean, number as yup_number, object as yup_object, string as yup_string } from 'yup';

import ReloadSessionDataQs from 'components/data/session-user/refetch-queries/ReloadSessionData.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import WSAddress from 'server/api-types/WSAddress.js';
import { ccMonthYearBackoffice, centsToUnitlessNumber } from 'utils/FormatHelpers.js';
import { GET_B2B_CUSTOMER_ADDRESSES, GET_CUSTOMER_ADDRESSES } from 'components/data/session-user/Addresses.query.js';
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";
import WSFundingSourceInfo from 'server/api-types/WSFundingSourceInfo.js';
import WSCreditCardReference from 'server/api-types/WSCreditCardReference.js';
import { cardCVV } from 'components/payments/CreditCardForm.js';
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { ADD_FUNDING_SOURCE } from 'graphql-queries/FundingSources';
import { getAddressYupSchema } from 'components/forms/address/Address.validations.js';
import { endOfMonth, isAfter } from 'date-fns';
import { nickNameMaxLengthCMSKey } from "components/modals/EditCardNickname.js";

addYupMethod("creditCard");

/**
 * modifies data to add a field with a date object representing the expiration Year & Month fields
 * @param isMultiPayment
 * @param data
 */
export const addExpiryDateFields = ({ isMultiPayment, data }) => (isMultiPayment ? [ true, false ] : [ true ])
	.forEach(isFirst => {
		const getInputName = (fieldName) => getPaymentInputName({ base: fieldName, isSplit: isMultiPayment, isFirst });
		const expirationDateInput = getInputName('expirationDate');
		const expirationYearInput = getInputName('expirationYear');
		const expirationMonthInput = getInputName('expirationMonth');

		data[ expirationMonthInput ] = data?.[ expirationDateInput ]
			? parseInt(data[ expirationDateInput ].slice(0, 2), 10)
			: null;
		data[ expirationYearInput ] = data?.[ expirationDateInput ]
			? parseInt(data[ expirationDateInput ].slice(-2), 10)
			: null;
	});

export const addressFields = [
	`addressId`,
	`address1`,
	`address2`,
	`address3`,
	`city`,
	`state`,
	`postalCode`,
	`country`,
];

export const paymentFieldsFromForm = (
	allFormData,
	getInputName = (fieldName) => fieldName,
) => {
	// select either the *First or *Second fields (depending on isFirst) and remove that suffix
	const getInputValue = (fieldName) => allFormData[ getInputName(fieldName) ];

	// make consistent fields names which will be used in addNewPaymentMethod function on the AddPaymentMethod component
	const fields = [
		'nameOnCard',
		'cardNumber',
		// MBTA has a single exp date field
		'expirationDate',
		// OMNY has split exp date field
		'expirationMonth',
		'expirationYear',
		'cardCVV',
		'cardNickname',
		...addressFields,
	];

	return reduce(fields, (acc, field) => {
		const value = getInputValue(field);

		return {
			...acc,
			[ field ]: value,
		};
	}, {});
};

// This is needed when the address is not being pre-validated
export const removeAddressFormInputPrefix = (address, prefix = "", suffix = "") => {
	const addlFields = [ 'primaryBilling', 'primaryShipping', 'saveAddress', 'unverified' ];

	return reduce(addressFields.concat(addlFields), (accumulator, field) => {
		const value = address[ prefix + field + suffix ];

		if (value === undefined) {
			return accumulator;
		}

		return {
			...accumulator,
			[ field + suffix ]: value,
		};
	}, {});
};

const getNewPaymentInputs = ({
	isFirst,
	isMultiPayment,
}) => {
	const getInputName = (fieldName) => getPaymentInputName({ base: fieldName, isSplit: isMultiPayment, isFirst });

	const validations = {
		[ getInputName('nameOnCard') ]: yup_string()
			.transform(value => value === '' ? undefined : value)
			.required(() => <CmsContentRenderedInline
				contentKey={'miscText["errors.general.field_required"]'}
				fallbackValue="Cardholder name is required"
				variables={{ field: 'Cardholder name' }}
			/>)
			.trim(),
		[ getInputName('cardNickname') ]: yup_string()
			.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, nickNameMaxLengthCMSKey)
			.trim(),
		[ getInputName('cardNumber') ]: yup_string()
			.transform(value => value === '' ? undefined : value)
			.required(() => <CmsContentRenderedInline
				contentKey={'miscText["errors.general.field_required"]'}
				fallbackValue="Card number is required"
				variables={{ field: 'Card number' }}
			/>)
			.creditCard("miscHtml.general-payment-cc-error-cardnum")
			.trim(),
		[ getInputName('expirationDate') ]: yup_string()
			.matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "miscHtml.general-payment-cc-error-expiry")
			.test({
				name: 'expired expiration date',
				test: (value) => {
					// Only begin validation once we have a full date
					if (value.length === 5) {
						const today = new Date();

						const splitDate = value.split("/");

						// getMonth is indexed so we add 1 to compare to the user input
						const parsedMonth = parseInt(splitDate[ 0 ]) - 1;

						// Scoped to 21st Century
						const parsedYear = parseInt(splitDate[ 1 ]) + 2000;

						// Get the last date of the expiration date based on the month
						const expirationDate = endOfMonth(new Date(parsedYear, parsedMonth));

						return isAfter(expirationDate, today);
					}
					// Expiration date is not in a format we support or not fully input so we won't validate
					return true;
				},
				message: () => <CmsContentRenderedInline
					contentKey={'miscHtml.general-payment-cc-error-expiry'}
					fallbackValue={`<p>Invalid expiration date</p>`}
				/>,
			})
			.required(<CmsContentRenderedInline
				contentKey={'miscText["errors.general.field_required"]'}
				fallbackValue="Expiration Date is required"
				variables={{ field: 'Expiration Date' }}
			/>),
	};

	return yup_object()
		.shape(validations)
		.concat(getAddressYupSchema());
};

export const getYupSchema = ({
	isMultiPayment,
	// whether they can select from multiple payment methods
	isSelecting,
	// whether selected payment is ACH
	isAch,
	// whether or not the ach eula needs to be accepted
	requireAchEula = false,
	// whether they've selected a new payment method
	isNew,
	hasMobilePayment,
	disableCvv = false,
	total = null,
	isNewConfirmed = [],
}) => {
	addYupMethod("creditCard");

	// No payment needs to be made.
	if (total === 0) {
		return yup_object().shape({});
	}

	const validationArray = isMultiPayment
		? [
			// create validations for both first (primary) and second methods
			{
				isFirst: true,
				isSelecting: isSelecting[ 0 ],
				isAch: isAch[ 0 ],
				isNew: isNew[ 0 ],
				isNewConfirmed: isNewConfirmed[ 0 ],
			},
			{
				isFirst: false,
				isSelecting: isSelecting[ 1 ],
				isAch: isAch[ 1 ],
				isNew: isNew[ 1 ],
				isNewConfirmed: isNewConfirmed[ 1 ],
			},
		]
		: [
			// Not split pay. Need only one set of validations
			{
				isFirst: true,
				isSelecting: isSelecting[ 0 ],
				isAch: isAch[ 0 ],
				isNew: isNew[ 0 ],
				isNewConfirmed: isNewConfirmed[ 0 ],
			},
		];

	const validations = reduce(
		validationArray,
		(acc, { isFirst, isSelecting, isAch, isNew, isNewConfirmed }) => {
			const result = {};

			if (
				PublicAppVars.CVV_FIELD_ENABLED // should mirror the BO setting
				&& !disableCvv // some flows don't make the user enter their CVV (autoloads & value purchase)
				&& !isAch // direct debit doesn't have CVV
				&& !hasMobilePayment // mobile payments don't get the CVV from us
				&& (
					// new cards prompt for CVV along with the other new fields
					(isSelecting && isNew)
					// existing cards prompt for CVV after being selected (after "Use this payment method")
					|| (!isSelecting && !isNewConfirmed)
				)
			) {
				const cvvInputName = getPaymentInputName({ base: cardCVV, isSplit: isMultiPayment, isFirst });
				// required for existing cards on the final step (when !isSelecting) and for new cards
				result[ cvvInputName ] = yup_string()
					// transform empty strings because otherwise we do not see the required error message, only min error msg
					// https://github.com/jquense/yup/issues/24#issuecomment-178029968
					.transform(value => value === '' ? undefined : value)
					.required("miscHtml.general-payment-cc-error-cvv")
					.matches(/^\d+$/, "miscHtml.general-payment-cc-error-cvv-invalid")
					.min(3, "miscHtml.general-payment-cc-error-cvv-invalid")
					.max(4, "miscHtml.general-payment-cc-error-cvv-invalid")
					.trim();
			}

			if (isMultiPayment) {
				result[ 'firstPaymentMethodAmount' ] = yup_number()
					.max(centsToUnitlessNumber(total), "miscText.validation-first-payment-amount-max");
			}

			if (isAch && requireAchEula) {
				// this input will only show once, even in split pay.
				// If the user selects ACH as method 1 and two, they will see the check box in method 1.
				// If they select only one split pay as ACH, they will see the check box only with that method
				result[ 'agreeToTOS' ] = yup_boolean()
					.required("miscText.validation-ach-form-tos-required")
					.oneOf([ true ], "miscText.validation-ach-form-tos-required");

				result[ 'agreedEulaId' ] = yup_number();
			}

			const yupResult = yup_object()
				.shape(result)
				.concat(isNew ? getNewPaymentInputs({ isFirst, isMultiPayment }) : null);

			return acc.concat(yupResult);
		}, yup_object());

	return validations;
};

export const addNewPaymentMethod = async ({
	apolloClient,
	preValidatedData = null,
	programId = null, // null on CS, required on B2B
	mutation = ADD_FUNDING_SOURCE,
	billingAddressId = null,
	billingAddress = null,
}) => {
	const variables = {
		inputCard: {
			nameOnCard: preValidatedData.nameOnCard,
			cardNumber: preValidatedData.cardNumber,
			cardExpiryMMYY: preValidatedData.expirationDate
				? preValidatedData.expirationDate.replace('/', '')
				: ccMonthYearBackoffice(
					preValidatedData.expirationMonth,
					preValidatedData.expirationYear,
				),
			cvv: preValidatedData.cardCVV,
			cardNickname: preValidatedData.cardNickname,
		},
		...(billingAddress ? { billingAddress } : {}),
		...(billingAddressId ? { billingAddressId } : {}),
		setAsPrimary: preValidatedData.primaryPaymentMethod,
		nickname: preValidatedData.cardNickname,
		programId,
	};

	const fundingSourceResponse = await graphqlErrorMiddleware(apolloClient.mutate({
		mutation,
		variables,
		refetchQueries: [
			...(PublicAppVars.isB2B
				? [ { query: GET_B2B_CUSTOMER_ADDRESSES } ]
				: [ ...ReloadSessionDataQs, { query: GET_CUSTOMER_ADDRESSES } ]
			),

		],
	}));

	return fundingSourceResponse?.data.CustomerMutationRoute.addFundingSource?.fundingSourceId ?? null;
};

export const getWsFundingSourceInfo = (wsPayment, fundingSources) => {
	if (wsPayment?.paymentType !== PAYMENT_TYPES.creditCard && wsPayment?.paymentType !== PAYMENT_TYPES.directDebit) {
		return;
	}

	if (wsPayment?.paymentType === PAYMENT_TYPES.directDebit) {
		const directDebitFundingSource = find(fundingSources, ({ fundingSourceId }) => fundingSourceId === wsPayment.fundingSourceId);
		// Alessandro's response on 05/24/2021 regards translation Bank Account
		// https://reflexions.slack.com/archives/GA82SPCTV/p1622150334180500
		// no need translate
		return directDebitFundingSource;
	} else {
		const wsFundingSourceInfo = find(fundingSources, (wsFundingSourceInfo => wsFundingSourceInfo.creditCard?.pgCardId === wsPayment.pgCardId));

		if (wsFundingSourceInfo) {
			return wsFundingSourceInfo;
		}

		const creditCardType = wsPayment.creditCardType ?? wsPayment.creditCard?.creditCardType;
		const maskedPan = !isEmpty(wsPayment.oneTime)
			? wsPayment.oneTime?.cardNumber.slice(-4)
			: !isEmpty(wsPayment.creditCard)
				? wsPayment.creditCard?.maskedPan
				: wsPayment.maskedPan;

		const creditCard = new WSCreditCardReference({
			creditCardType,
			maskedPan,
			nickname:wsPayment.nickname,
		});

		const billingAddress = wsPayment.oneTime?.address
			? new WSAddress(wsPayment.oneTime.address)
			: null;

		// simulates a wsFundingSourceInfo
		return new WSFundingSourceInfo({
			creditCard,
			billingAddress,
		}).toResolver();
	}
};
