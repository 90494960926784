import { gql } from "@apollo/client";
import React from "react";
import PropTypes from "prop-types";
import {
	get,
	reduce,
} from 'lodash';
import StdQuery from "./StdQuery.js";

export const GET_STATES = gql`
	query States (
		$country: String
	) {
		countriesQ(
			country: $country
		) {
			states {
				key
				value
			}
			id
		}
	}
`;
const States = (props) => (
	<StdQuery query={GET_STATES} variables={{ country: props.country }}>
		{(data) => {
			// states is [{key: "AL", value: "Alabama"}, {key: "AK", value: "Alaska"}, etc]
			const states = get(data, 'data.countriesQ[0].states', []);
			const statesObj = reduce(states, (accumulator, value, key) => {
				accumulator[ value.key ] = value.value;
				return accumulator;
			}, {});

			return props.children({
				...data,
				states,
				statesObj,
			});
		}}
	</StdQuery>
);
States.propTypes = {
	country: PropTypes.string.isRequired,
};
export default States;
