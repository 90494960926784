import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cx from 'classnames';

import { SmallCheck, Close } from './Icon';
import * as alert from './Alert.module.css';
import Button, { Alert as isAlert } from './Button';

/**
 * Data-QA Tags:
 * AlertContainer, AlertContent, AlertClose
 * AlertTitle, AlertText, AlertLink, AlertImage
 */
const Alert = ({
	type,
	title,
	text,
	modalActive,
	onModalClose,
	...rest
}) => {

	const closeModal = onModalClose;

	useEffect(() => {
		const timeoutModal = setTimeout(closeModal, 3000);

		return () => clearTimeout(timeoutModal);
	});

	return (
		<ReactModal
			isOpen={modalActive}
			onRequestClose={closeModal}
			className={alert.content}
			overlayClassName={alert.overlay}
			contentLabel={title}
			parentSelector={() => document.querySelector("#root")}
			data-qa="AlertContainer"
			{...rest}
		>
			<div
				className={alert.dialog}
				data-qa="AlertContent"
			>
				<div className={alert.content}>
					{type === 'success' ? (
						<div className={cx(alert.icon, alert.isSuccess)}>
							<SmallCheck overrideClass={alert.iconSvg} aria-hidden={true} />
						</div>
					) : (
						<div className={cx(alert.icon, alert.isError)}>
							<Close overrideClass={alert.iconSvg} aria-hidden={true} />
						</div>
					)}

					<div className={alert.info}>
						<h2 className={alert.title}
							data-qa="AlertTitle"
						>
							{title}
						</h2>

						{text
							&& <p className={alert.text}
								data-qa="AlertText"
							>
								{text}
							</p>
						}
					</div>
				</div>

				<Button text="Dismiss"
					type={isAlert}
					overrideClass={alert.close}
					onClick={closeModal}
					data-qa="AlertClose"
				/>
			</div>
		</ReactModal>
	);
};

Alert.propTypes = {
	type: PropTypes.oneOf([ 'success', 'error' ]).isRequired,
	title: PropTypes.node.isRequired,
	modalActive: PropTypes.bool.isRequired,
	onModalClose: PropTypes.func.isRequired,
};

export default Alert;
