import cx from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SmallCheck, Close } from 'components/Icon.js';
import Button from 'components/Button.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

import * as alert from './Alert.module.css';
import RenderAfterAWhile from "utils/RenderAfterAwhile.js";
import PublicAppVars from "utils/PublicAppVars.js";

export const TYPE_ERROR = 'error';
export const TYPE_SUCCESS = 'success';

const cms = {
	close: 'miscText.general-toast-close',
};

/**
 * Data-QA Tags:
 * AlertContainer, AlertContent, AlertClose
 * AlertTitle, AlertText, AlertLink, AlertImage
 */
const Toast = ({
	type,
	title,
	text,
	onClosed = null,
	timeout = 6000,
}) => {
	const [ show, setShow ] = useState(true);

	const _onClosed = onClosed ?? (() => setShow(false));

	// If there is no timeout, then we want to persist the toast until user closes it
	if (timeout) {
		setTimeout(() => {
			if (!PublicAppVars.AUTO_DISMISS_TOAST) {
				return;
			}

			_onClosed();
		}, timeout + 1000);
	}

	return (
		show && <div
			className={alert.dialog}
			data-qa="AlertContent"
		>
			<div className={alert.content}>
				{type === TYPE_SUCCESS ? (
					<div className={cx(alert.icon, alert.isSuccess)}>
						<SmallCheck overrideClass={alert.iconSvg} aria-hidden={true} />
					</div>
				) : (
					<div className={cx(alert.icon, alert.isError)}>
						<Close overrideClass={alert.iconSvg} aria-hidden={true} />
					</div>
				)}
				<div className={alert.info} role="status">
					{/* The div with role status needs to render before the content. Having a useEffect that adds
						the content after the first render seems to work in firefox, but not chrome, this seems to work
						in both.
					 */}
					<RenderAfterAWhile timeout={150}>
						<h2 className={alert.title}data-qa="AlertTitle">
							{title}
						</h2>

						{text ? <div className={alert.text} data-qa="AlertText">{text}</div> : null}
					</RenderAfterAWhile>
				</div>
			</div>

			<Button
				type="button"
				overrideClass={alert.closeToast}
				onClick={() => _onClosed()}
				data-qa="AlertClose"
				aria-hidden="true"
			>
				<div className={alert.closeContainer}>
					<Close overrideClass={alert.closeSvg} />
					<CmsContentRenderedInline
						className={alert.closeText}
						contentKey={cms.close}
						fallbackValue="Dismiss"
					/>
				</div>
			</Button>

		</div>
	);
};

Toast.propTypes = {
	type: PropTypes.oneOf([ TYPE_SUCCESS, TYPE_ERROR ]).isRequired,
	title: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.node),
	]).isRequired,
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
	]),
};

export default Toast;
