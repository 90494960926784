import { get } from "lodash";
import ClientAjax from "./ClientAjax.js";
import GoogleAnalytics from './analytics/GoogleAnalytics.js';
import { get_txn_uuid } from "../server/TxnUuid.js";
import { jsonDecycle } from './cycle.js';

// npm logging levels: https://github.com/winstonjs/winston#logging-levels
export const levels = {
	error: 'error',
	warn: 'warn',
	info: 'info',
	verbose: 'verbose',
	debug: 'debug',
	silly: 'silly',
};

export const checkErrorLevel = (level) => {
	return levels[ level ] !== undefined;
};

const winstonlessLog = (level, payload) => {
	// we don't need to log this to the console, and /ajax/client-log knows what it's getting
	delete payload.serverSide;

	const {
		message,
		// we'll override payload.level with level from params
		level: ignoredPayloadLevel,
		...rest
	} = payload;

	// wish it would log the object in this order, but it logs the keys alphabetically
	const withParamLevel = {
		...rest,
		level,
	};

	if (typeof console[ level ] !== 'undefined') {
		console[ level ](message, withParamLevel);
	}
	else {
		console.log(message, withParamLevel);
	}

	// don't use restClientMiddleware because it uses log(), which infinite loops
	ClientAjax.post("/ajax/client-log", {
		level,
		message,
		...rest,
	})
		.then(response => {
		})
		.catch(error => {
			console.error("Unable to send log message to server", error);
		});
};

const winstonLog = (payload, level, req) => {
	// assume a winstonjs-compatible interface
	// assume that it will handle reporting to server if client-side
	const winstonInstance = globalThis.winstonInstance;
	payload.level = level;

	if (req) {
		// note: the default null here means that they'll get logged. If not specified, undefined is the default and
		// they won't show up
		payload.req_session_id = req.cookies?.session_id ?? null;
		payload.customer_id = req.session?.customerId ?? null;
		payload.contact_id = req.session?.contactId ?? null;
		payload.txn_uuid = get_txn_uuid(req);
		payload.ip = req.ip ?? null;
		payload.method = req.method ?? null;
		payload.originalUrl = req.originalUrl ?? null;
	}
	else {
		payload.no_request_context = '1'; // graylog doesn't like actual booleans
	}

	winstonInstance.log(payload);
};

const objectify = (message) => {
	return (typeof message === 'string')
		? { message }
		: message;
};

const getServerSide = (payload) => {
	return (typeof payload.serverSide === 'undefined')
		? (typeof window === 'undefined')
		: payload.serverSide;
};

const getTxnUuid = (context) => {
	return context && context.req
		? get_txn_uuid(context.req)
		: undefined;
};

function shouldAddStacktrace(message, level) {
	return !message.stack && (message.stacktrace || level === levels.error);
}

const removeRecursion = obj => {
	// Remove any recursion.
	// both winston and NewRelic are erroring when asked to log recursive objects.
	// htmlescape simply throws if it detects recursion, so that's not very useful.
	// (we wouldn't log any recursive messages)
	// flatted's stringify makes a best-effort object, but mostly loses the original structure
	// (we can't find obj.message after it)
	// fclone infinite loops when processing an express request object
	// decycle from https://github.com/douglascrockford/JSON-js/blob/master/cycle.js
	// is recommended by https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value

	// Need to preserve message and stack for errors
	if (obj instanceof Error) {
		obj.message2 = obj.message;
		obj.stack2 = obj.stack;
	}
	return jsonDecycle(obj);
};

const makeMessageSafe = message => {
	// we're going to be modifying message, so clone it to avoid affecting caller
	// JSON.decycle clones while removing recursion
	return objectify(removeRecursion(message));
};

export const log = (context, level, message) => {
	if (!checkErrorLevel(level)) {
		throw new Error(`unknown error level ${level}`);
	}

	const payload = makeMessageSafe(message);

	payload.serverSide = getServerSide(payload);
	payload.txn_uuid = getTxnUuid(context);

	if (shouldAddStacktrace(message, level)) {
		// without this tmp var, winstonlessLog's fullPayload doesn't have stack
		if (typeof Error.captureStackTrace === 'function') {
			const tmp = {};
			Error.captureStackTrace(tmp, log);
			payload.stack = tmp.stack;
		}
	}

	// We don't have multiple environments logging to the same destination, but if we did..
	//payload.publicUrl = PublicAppVars.PUBLIC_URL;

	if (typeof window === 'undefined') {
		winstonLog(payload, level, context?.req);
	}
	else {
		// we don't have winston on the client-side yet
		winstonlessLog(level, payload);
	}
};

export const noticeError = (context, level, error, message = "Noticed Error") => {
	const payload = makeMessageSafe(message);
	const safeError = removeRecursion(error);

	// the nr call is isomorphic
	// for eslint:
	/*global newrelic*/
	if (typeof newrelic !== 'undefined') {
		// https://github.com/newrelic/node-newrelic#newrelicnoticeerrorerror-customparameters
		// custom parameter type must be boolean, number, or string
		newrelic.noticeError(safeError, {
			...payload,
			level,
		});
	}

	if (typeof window !== 'undefined') {
		// tell google analytics directly
		GoogleAnalytics.logException({
			description: payload.message,
			fatal: true,
		});
	}

	log(
		context,
		level,
		{
			...payload,
			error: safeError,
			error_msg: get(safeError, 'message'),
			error_stack: get(safeError, 'stack'),
		},
	);
};
