import React, { StrictMode } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from "prop-types";

import HelmetDefaults from './HelmetDefaults.js';
import ErrorBoundary from 'components/ErrorBoundary';
import LoginStageRedirect from "components/data/session-user/LoginStageRedirect.js";
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import SessionCheck from "components/data/session-user/SessionCheck.js";

import MBTAHeader from 'layouts/components/MBTAHeader.js';
import CharlieHeader from 'layouts/components/CharlieHeader.js';
import GACharlieHeader from 'layouts/components/GACharlieHeader.js';
import MBTAFooter from 'layouts/components/MBTAFooter.js';

import { SVGDefs } from 'components/Icon.js';
import loginStages from "components/data/session-user/LoginStages.js";
import { IfLoggedIn } from "components/data/session-user/LoggingIn.js";
import AlternateInfo from "components/banners/alternates/AlternateInfo.js";
import { PostRouteDataProvider } from 'AppDataProvider.js';
import { useModalContext } from "context/ModalProvider.js";
import Breadcrumb from 'components/breadcrumb/Breadcrumb.js';
import PypestreamInit from "components/pypestream-chatbot/PypestreamInit.js";

import * as style from 'App.module.css';

export const main = "main";

/**
 * Show the correct site header with logo and global nav
 *
 * @param {bool} showCharlieBar
 * @param {bool} isGroupAdmin
 */
const CharlieBar = ({
	showCharlieBar,
	isGroupAdmin,
}) => {
	if (showCharlieBar) {
		if (isGroupAdmin) {
			return <GACharlieHeader />;
		} else {
			return <CharlieHeader />;
		}
	}
	return null;
};


const Child = ({
	component: Component,
	componentProps,
	render,
	showFooter,
	showCharlieBar,
	isGroupAdmin,
}) => {
	const { toasts } = useGlobalToastsContext();
	const { modal } = useModalContext();


	return (
		<div className={style.siteContainer}>
			<HelmetDefaults />

			<SVGDefs />

			<ErrorBoundary>
				<MBTAHeader />
			</ErrorBoundary>

			<SessionCheck>
				<ErrorBoundary>
					<CharlieBar {...{ showCharlieBar, isGroupAdmin }} />
					<IfLoggedIn>
						<AlternateInfo />
					</IfLoggedIn>
				</ErrorBoundary>

				<ErrorBoundary>
					<main id={main}>
						<StrictMode>
							<Breadcrumb />
							{!modal && toasts[ 0 ]}

							{render
								? render(componentProps)
								: <Component {...componentProps} />
							}
						</StrictMode>
					</main>
				</ErrorBoundary>
			</SessionCheck>

			<PypestreamInit />

			<ErrorBoundary>
				{showFooter && <MBTAFooter />}
			</ErrorBoundary>
		</div>
	);
};

// inspiration: https://simonsmith.io/reusing-layouts-in-react-router-4/
const BaseLayout = ({
	minLoginLevel = loginStages.anonymous,
	maxLoginLevel = loginStages.loggedIn,
	showCharlieBar = true,
	isGroupAdmin = false,
	showFooter = true,
	component,
	render,
	key,
	childComponent: ChildComponent,
	...rest
}) => (
	<Route {...rest}
		render={componentProps => {
			const {
				staticContext,
				match,
			} = componentProps;

			// when server-rendering, tell render_react which route matched
			if (staticContext) {
				staticContext.routeMatch = match;
			}

			return (
				<PostRouteDataProvider>
					<LoginStageRedirect
						minLevel={minLoginLevel}
						maxLevel={maxLoginLevel}
						returnAfterSignIn={true}
					>
						{ChildComponent ?
							<ChildComponent {...{
								component,
								componentProps,
								render,
								showFooter,
								showCharlieBar,
								isGroupAdmin,
							}} />
							: <Child {...{
								component,
								componentProps,
								render,
								showFooter,
								showCharlieBar,
								isGroupAdmin,
							}} />}
					</LoginStageRedirect>
				</PostRouteDataProvider>
			);
		}} />
);


BaseLayout.propTypes = {
	// we call it minLoginLevel, but really it's that they're logged in and registration is complete
	minLoginLevel: PropTypes.number,
	maxLoginLevel: PropTypes.number,

	showCharlieBar: PropTypes.bool,
	showFooter: PropTypes.bool,
	isGroupAdmin: PropTypes.bool,

	// either component or render is required
	component: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.func,
	]),

	render: PropTypes.func,
};

export default BaseLayout;
