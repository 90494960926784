import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	head,
	values,
} from 'lodash';
import cx from 'classnames';

import Button from 'components/Button.js';
import CmsContentList from 'components/data/CmsContentList';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Panel from 'components/Panel.js';
import { useTransitAccountProductCatalog } from 'components/data/transit-account/ProductCatalog.query';
import { useModalContext } from 'context/ModalProvider.js';
import Modal from 'components/Modal.js';
import { useCartContext, getCartBalance } from 'context/CartProvider.js';
import PreventDefault from 'utils/PreventDefault.js';
import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { SmallChevron } from 'components/Icon.js';
import ReloadBalanceOptions from './ReloadBalanceOptions.js';
import CurrentBalanceDescription from './CurrentBalanceDescription.js';
import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';

import { RIDER_CLASSES } from 'components/data/transit-account/TransitAccount.js';
import { validateFormData } from 'components/account/reload-balance/helpers.js';

import * as typography from 'styles/typography.module.css';
import * as cardStyle from 'components/account/ProductCard.module.css';
import * as cartStyle from 'components/payments/Cart.module.css';
import * as style from './ReloadBalance.module.css';
import { mapWsProductCatalogToTransitAccountCatalog } from "pages/account/PurchaseCharlieCardSelectProducts.js";
import { useMediaQueryMatches, MQ_TABLET } from 'utils/Breakpoints.js';

export const cms = {
	currentBalancePreSelection: 'miscText.purchase-balance-description1',
	currentBalancePostSelection: 'miscText.purchase-balance-description2',
	reloadYourBalance: 'miscText.purchase-balance-subheader1',
	amountConfigurable: 'miscText.purchase-balance-subheader2',
	learnMore: 'miscText.purchase-balance-learnmore-cta',
	addToCart: 'miscText.purchase-add-to-cart',
	enterAmount: 'miscText.purchase-balance-other-label',
	otherAmountLabel: 'miscText.purchase-balance-options-other',
	purchaseBalanceLimitsDescription: 'miscText.purchase-balance-other-limits',
	cancelPurchase: 'miscText.purchase-cancel',
};

const ReloadBalance = ({
	valueIncrement,
	maxAddTransitValue,
	minAddTransitValue,
	transitValueOptions,
	// This needs deeper review after the July 2022 demo. Alex set it to true for the add to cart buttons but isn't convinced there wasn't something deeper worth revisiting.
	// It appears to be used in lots of places, but toggling it doesn't seem to have much/any affect on the user other than the buttons.
	// Setting it back to false since we dont need the buttons.
	// isPurchaseNewProductFlow handles validations on submit vs on focus.
	isPurchaseNewProductFlow = false,
	optionsOnly = false,
	formHelper,
	multiStep = true,
}) => {
	const { purseTotal } = usePurseBalanceContext();
	const [ customAmt, setCustomAmt ] = useState('');

	const isAtleastMobile = useMediaQueryMatches(MQ_TABLET);

	// If the balance is negative, the negative purseTotal is always applied to the Cart.
	const [ chosenDisplayAmount, setChosenDisplayAmount ] = useState(0);
	const [ submitting, setSubmitting ] = useState(false);
	const [ optionsModalOpen, setOptionsModalOpen ] = useState(false);

	const cartContext = useCartContext();
	const { setModal } = useModalContext();

	const cancelModal = () => {
		setChosenDisplayAmount(0);
		cartContext.addBalance(0);
		setOptionsModalOpen(false);
		setModal(null);
	};

	const kickoffSubmit = async () => {
		setSubmitting(true);

		const cartBalance = getCartBalance(cartContext.cart);

		const validated = await validateFormData({
			purseTotal,
			valueIncrement,
			maxAddTransitValue,
			minAddTransitValue,
			cartBalance,
			validateAllFields: true,
			formHelper,
		});

		if (validated) {
			// happy path :)
			const amountToAdd = head(validated[ 'storedValue[]' ]) ?? validated.storedValueOther;
			// Update the balance with a new amount, prev amount will be overwritten
			// can perserve by passing false as the the secong arg
			cartContext.addBalance(amountToAdd, !isPurchaseNewProductFlow);

			if (isPurchaseNewProductFlow) {
				setCustomAmt('');
				if (optionsModalOpen) {
					setOptionsModalOpen(false);
					setModal(null);
				}
			}

			setChosenDisplayAmount(cartBalance + amountToAdd);
		}
		setSubmitting(false);
	};

	const onChevronClick = () => {
		setOptionsModalOpen(true);
	};

	const reloadBalanceOptionProps = {
		valueIncrement,
		maxAddTransitValue,
		minAddTransitValue,
		transitValueOptions,
		kickoffSubmit,
		formHelper,
		isPurchaseNewProductFlow,
		setCustomAmt,
		customAmt,
		setChosenDisplayAmount,
		cancelModal,
		validateFormData,
		optionsModalOpen,
	};

	return (
		<CmsContentList list={values(cms)}>{() => <>
			<Panel overrideClass={cx(style.panel, style.panelRelative)} >
				{!optionsOnly &&
					<div className={style.headerContainer}
						onClick={multiStep && !isAtleastMobile ? onChevronClick : null}>
						<div className={style.headerWrapper}>
							<div className={style.leftCol}>
								<div className={cardStyle.cardTitle}>
									<CmsContentRenderer.H2
										contentKey={cms.reloadYourBalance}
										fallbackValue="Reload Your Balance"
										className={typography.h7}
									/>
								</div>
								<div className={cx((multiStep && cartStyle.mobileHidden), style.deskTopWrapper)}>
									<CurrentBalanceDescription {...{
										chosenDisplayAmount,
										isPurchaseNewProductFlow,
									}} />
								</div>
								{multiStep &&
								<div className={cartStyle.desktopHidden} >
									<CurrentBalanceDescription {...{
										chosenDisplayAmount,
										isPurchaseNewProductFlow,
									}} />
								</div>}
							</div>
						</div>
						{multiStep &&
							<div className={style.arrowIconWrapper}>
								<SmallChevron />
							</div>
						}
					</div>
				}
				<div className={cx((multiStep
					? style.multiStepOptions
					: style.singleStepOptions),
				optionsOnly ? null : style.desktopConfOptns)}>
					<ReloadBalanceOptions {...reloadBalanceOptionProps} multiStep={multiStep} />
				</div>
				{isPurchaseNewProductFlow && <Button
					{...{ submitting }}
					additionalClassNames={style.addToCartBtn}
					onClick={PreventDefault(kickoffSubmit)}
					isPrimary={false}
					type="button"
				>
					<CmsContentRenderer.Span
						contentKey={cms.addToCart}
						fallbackValue="Add to Cart"
					/>
				</Button>}
			</Panel>
			{/*
			* This modal is added manually to the render flow, as opposed to being added with the `setModal` context,
			* because the content of the modal needs to be updated as props change (onClick -> setModal will freeze the props in time).
			*/}
			<Modal
				modalActive={optionsModalOpen}
				overrideClass={style.mobileConfigOptns}
				title={<CmsContentRenderer.Span
					contentKey={cms.reloadYourBalance}
					fallbackValue="Reload Your Balance"
				/>}
				onModalClose={reloadBalanceOptionProps.cancelModal}
			>
				<CurrentBalanceDescription {...{
					chosenDisplayAmount,
					isPurchaseNewProductFlow,
				}} />
				<ReloadBalanceOptions {...{
					...reloadBalanceOptionProps,
				}} />
			</Modal>
		</>}</CmsContentList>
	);
};

const ReloadBalanceWithData = ({ ...props }) => {
	const {
		transit_account_id: subsystemAccountReference,
	} = useParams();

	const useTransitAccount = Boolean(subsystemAccountReference);

	const taResult = useTransitAccountProductCatalog({
		subsystemAccountReference,
		queryOptions: { skip: !useTransitAccount },
	});
	const subsystemResult = useGetSubsystemProductCatalog({
		riderClassId: RIDER_CLASSES.fullFare,
		queryOptions: { skip: useTransitAccount },
	});

	const {
		valueIncrement,
		maxAddTransitValue,
		minAddTransitValue,
		transitValueOptions,
		products,
	} = useTransitAccount
		? taResult
		: mapWsProductCatalogToTransitAccountCatalog(subsystemResult);

	return (
		<PurseBalanceProvider {...{ subsystemAccountReference }}>
			<ReloadBalance {...props} {...{
				valueIncrement,
				maxAddTransitValue,
				minAddTransitValue,
				transitValueOptions,
				products,
			}} />
		</PurseBalanceProvider>
	);
};
export default ReloadBalanceWithData;
