import React, {
	useState,
} from "react";
import PropTypes from 'prop-types';
import {
	object as yup_object,
	string as yup_string,
} from "yup";

import { SelectedLocationContextProvider } from "context/SelectedLocationContext.js";
import { StepContextProvider, useStepContext } from 'context/StepContext.js';
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";

import CorrectMissingTap, { getKnownTap } from 'components/modals/CorrectMissingTap.js';
import CorrectMissingTapConfirm from 'components/modals/CorrectMissingTapConfirm.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import Modal from "components/Modal";

const CORRECT_MISSING_TAP_SELECT_STOP = 0;
const CORRECT_MISSING_TAP_CONFIRMATION = 1;

const cms = {
	title: 'miscText.resolvetap-header',
	successTitle: 'miscText.resolvetap-confirmation',
};

export const tapValidations = {
	tripId: yup_string()
		.required(getErrorKey(COLLECTIONS.correctionLineItems, 'correctionLineItems[n].travelPresenceId', BO_ERRORS.general.required))
		.trim(),
	travelMode: yup_string()
		.required(getErrorKey(COLLECTIONS.correctionLineItems, 'correctionLineItems[n].travelMode', BO_ERRORS.general.required))
		.trim(),
	station: yup_string()
		.required(getErrorKey(COLLECTIONS.correctionLineItems, 'correctionLineItems[n].correction.stopPoint', BO_ERRORS.general.required))
		.trim(),
	travelPresenceId: yup_string()
		.required(getErrorKey(COLLECTIONS.correctionLineItems, 'correctionLineItems[n].travelPresenceId', BO_ERRORS.general.required))
		.trim(),
	operator: yup_string()
		.required(getErrorKey(COLLECTIONS.correctionLineItems, 'correctionLineItems[n].operator', BO_ERRORS.general.required))
		.trim(),
};

const yupSchema = () => {
	return yup_object().shape(tapValidations);
};

const CurrentStep = ({
	wsTripRideHistory,
	prevFormData,
	setPrevFormData,
	subsystemAccountReference,
	removeAlert,
}) => {
	const { step } = useStepContext();

	switch (step) {
		case CORRECT_MISSING_TAP_SELECT_STOP:
			return <CorrectMissingTap {...{
				wsTripRideHistory,
				setPrevFormData,
				yupSchema,
			}} />;
		case CORRECT_MISSING_TAP_CONFIRMATION:
			return <CorrectMissingTapConfirm  {...{
				prevFormData,
				removeAlert,
				wsTripRideHistory,
				subsystemAccountReference,
				yupSchema,
			}} />;
	}
};


const ManageTap = ({
	wsTripRideHistory,
	subsystemAccountReference,
	removeAlert,
}) => {
	const [ prevFormData, setPrevFormData ] = useState(null);

	return (
		<CmsContentList list={Object.values(cms)}>{() =>
			<div>
				<Modal
					title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Resolve Missing Tap" />}
				>
					<CurrentStep {...{
						wsTripRideHistory,
						subsystemAccountReference,
						prevFormData,
						setPrevFormData,
						removeAlert,
					}} />
				</Modal>
			</div>
		}</CmsContentList>
	);
};

const ManageTapWithData = ({
	wsTripRideHistory,
	removeAlert,
	subsystemAccountReference,
}) => {
	const { travelPresenceEvents } = wsTripRideHistory ?? {};

	const { operator, zone } = getKnownTap(travelPresenceEvents) ?? {};

	return <StepContextProvider min={CORRECT_MISSING_TAP_SELECT_STOP} max={CORRECT_MISSING_TAP_CONFIRMATION}>
		<SelectedLocationContextProvider {...{ variables: { subsystemAccountReference, operator, zone } }}>
			<ManageTap {...{
				wsTripRideHistory,
				removeAlert,
				subsystemAccountReference,
			}} />
		</SelectedLocationContextProvider>
	</StepContextProvider>;
};

ManageTapWithData.propTypes = {
	wsTripRideHistory: PropTypes.object,
};

export default ManageTapWithData;
