import React from 'react';
import {
	number as yup_number,
} from 'yup';
import { replace } from 'lodash';

import {
	rangeError,
	maxBalanceError,
	getStoredValueOtherValidations,
	getYupSchema as getValuePurchaseYupSchema,
} from 'components/account/purchases/ValuePurchaseTypeSelection.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { yupErrorMiddleware } from 'utils/error-handling/yupMiddleware.js';

import {
	levels,
	noticeError,
} from "utils/Logger.js";

import PublicAppVars from "utils/PublicAppVars.js";

export const RangeError = ({ min, max }) => <CmsContentRenderedInline
	contentKey={rangeError}
	fallbackValue={`Amount must be between ${centsToDisplay(min)} and ${centsToDisplay(max)}`}
	variables={{ min: centsToDisplay(min), max: centsToDisplay(max) }}
/>;

export const MaxBalanceReachedError = () => <CmsContentRenderedInline
	contentKey={maxBalanceError}
	fallbackValue={`You've reached the maximum balance, and can't load additional funds`}
/>;

export const balanceSelectionValidation = ({
	minValue: minPurchaseValue, // miminum purchase amount, ie $1
	maxValue: maxPurchaseValue, // maximum purchase amount, ie $200
	cartBalance,
	purseTotal,
}) => {
	// Check that the current selected mount doesnt bring account balance past
	// max balance amount (en var TRANSIT_ACCOUNT_MAX_BALANCE)
	const allowedMaxValue = purseTotal
		? (cartBalance + purseTotal) < PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE
			? (PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE - cartBalance - purseTotal)
			: 0
		// if some balance were added to cart need to calculate remaining allowed max value
		: cartBalance
			? PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE - cartBalance
			: PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE;

	const baseYupSchema = yup_number()
		// 1. check that current selected amount is greater than or equal to the min purchase amount (value from BO)
		.min(minPurchaseValue, () => <RangeError min={minPurchaseValue} max={maxPurchaseValue} />);

	// 2. current transit-account balance already at the balance limit, we cant purchase any more balance
	if (purseTotal >= PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE) {
		return baseYupSchema
			.max(0, () => <MaxBalanceReachedError />);
	}

	// 3. check that current selected amount + cart amount (for example owes a fee) does not bring over purchase limit
	// (value from BO), if so show range error.
	if (allowedMaxValue > maxPurchaseValue) {
		return baseYupSchema
			.max(maxPurchaseValue, () => <RangeError min={minPurchaseValue} max={maxPurchaseValue} />);
	}

	// 4. check that current selected amount is not over purchase limit (value from BO), if so, show range error.
	return baseYupSchema
		.max(allowedMaxValue, () => <RangeError min={minPurchaseValue} max={allowedMaxValue} />);
};

export const validateFormData = async ({
	purseTotal,
	cartBalance,
	valueIncrement,
	maxAddTransitValue,
	minAddTransitValue,
	validateAllFields = true,
	formHelper,
}) => {
	try {

		const args = {
			purseTotal,
			cartBalance,
			valueIncrement,
			maxAddTransitValue,
			minAddTransitValue,
		};

		const schema = validateAllFields
			? getValuePurchaseYupSchema(args)
			: getStoredValueOtherValidations(args);

		const rawFormData = formHelper.getDataToValidate();

		const storedValueOther = typeof rawFormData.storedValueOther === 'number'
			? rawFormData.storedValueOther
			: parseInt(rawFormData.storedValueOther.replace(/[^0-9\.]+/g, "") * 100);

		const validated = await yupErrorMiddleware(
			schema.validate({
				...(
					validateAllFields
						? {
							'storedValue[]': rawFormData[ 'storedValue[]' ]
								// cast to number, radio option returns a string number
								? rawFormData[ 'storedValue[]' ].map(value => Number(value))
								: [],
						}
						: {}
				),
				storedValueOther,
			}, {
				abortEarly: false,
			}),
		);
		formHelper.clearAllErrors();
		return validated;
	} catch (errorReport) {
		formHelper.validationErrorHandler(errorReport);
		const msg = `${validateAllFields ? 'Stored value options' : 'Custom amount'} validation failed`;
		noticeError(null, levels.info, errorReport, msg);
		return;
	}
};

export const castNumberStringToNumber = str => Number(replace(str, /[^0-9]+/g, ""));
