import {
	forEach,
	reduce,
	get,
} from "lodash";
import WSRefundMethod, {
	InputWSRefundMethodType,
	WSRefundMethodFragment,
	WSRefundMethodType,
} from "./WSRefundMethod.js";
import WSSubsystemAccountValueRefundMethod, {
	InputWSSubsystemAccountValueRefundMethodType,
	WSSubsystemAccountValueRefundMethodType,
} from "./WSSubsystemAccountValueRefundMethod.js";
import WSOriginalPaymentRefundMethod, {
	WSOriginalPaymentRefundMethodType,
	InputWSOriginalPaymentRefundMethodType,
} from "./WSOriginalPaymentRefundMethod.js";
import WSPaymentInfo from "./WSPaymentInfo.js";

import {
	levels,
	log,
} from "../../utils/Logger.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

export const types = {
	subsystemAccountValue: "SubsystemAccountValue",
	originalPayment: "OriginalPayment",
};

export const rawTypeToGraphqlFactoryType = (type) => `ws${type}RefundMethod`;

export const graphqlFactoryDataToWsDataTypes = (refundMethodFactories) => {
	return reduce(refundMethodFactories, (acc, inputWSRefundMethodFactory) => {
		forEach(types, type => {
			const wsRefundMethod = get(inputWSRefundMethodFactory, rawTypeToGraphqlFactoryType(type), null);
			if (wsRefundMethod) {
				acc.push(wsRefundMethod);
			}
		});
		return acc;
	}, []);
};

const typeToClass = (type) => {
	switch (type) {
		case types.subsystemAccountValue:
			return "WSSubsystemAccountValueRefundMethod";
		case types.originalPayment:
			return "WSOriginalPaymentRefundMethod";
		default:
			log(null, levels.error, `typeToClass: unknown  WSRefundMethod type ${type}`);
			return "WSRefundMethod";
	}
};

export default class WSRefundMethodFactory {
	static buildClass(data) {
		switch (data.type) {
			case types.subsystemAccountValue:
				return new WSSubsystemAccountValueRefundMethod(data);
			case types.originalPayment:
				return new WSOriginalPaymentRefundMethod({
					...data,
					payment: new WSPaymentInfo(data?.payment ?? {}),
				});
			default:
				log(null, levels.error, `unknown  WSRefundMethod type ${data.type}`);
				return new WSRefundMethod(data);
		};
	}

	static fromBackoffice(data) {
		switch (data.type) {
			case types.subsystemAccountValue:
				return WSSubsystemAccountValueRefundMethod.fromBackoffice(data);
			case types.originalPayment:
				return WSOriginalPaymentRefundMethod.fromBackoffice(data);
			default:
				log(null, levels.error, `unknown  WSRefundMethod type ${data.type}`);
				return WSRefundMethod.fromBackoffice(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSRefundMethodFactoryInterface = [ `
	interface WSRefundMethodFactory {
		${WSRefundMethodFragment}
	}`,
];

export const InputWSRefundMethodFactoryType = [
	InputWSSubsystemAccountValueRefundMethodType,
	...InputWSOriginalPaymentRefundMethodType,
	`
		input InputWSRefundMethodFactory {
			wsSubsystemAccountValueRefundMethod: InputWSSubsystemAccountValueRefundMethod
			wsOriginalPaymentRefundMethod: InputWSOriginalPaymentRefundMethod
		}
	`,
];

export const WSRefundMethodFactoryTypes = [
	InputWSSubsystemAccountValueRefundMethodType,
	...InputWSOriginalPaymentRefundMethodType,
	InputWSRefundMethodType,
	...WSRefundMethodType,
	...WSOriginalPaymentRefundMethodType,
	...WSSubsystemAccountValueRefundMethodType,
	...WSRefundMethodFactoryInterface,
	...InputWSRefundMethodFactoryType,
];

export const WSRefundMethodFactoryResolvers = {
	WSRefundMethodFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inpired by https://stackoverflow.com/a/59520666

		__resolveType({ type }) {
			return typeToClass(type);
		},
	},
};
