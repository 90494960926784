import { get } from 'lodash';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import {
	FUNDING_SOURCES_GET_QUERY,
	POST_NEW_PAYMENT_ADDRESS,
} from "graphql-queries/FundingSources.js";
import { GET_CUSTOMER_ADDRESSES_AND_CONTACT } from 'components/data/session-user/Addresses.query.js';
import WSAddressExt from "server/api-types/WSAddressExt.js";

export const postNewAddress = async ({
	wsAddress,
	apolloClient,
	refetch = true,
}) => {

	const addressPostResponse = await graphqlErrorMiddleware(
		apolloClient.mutate({
			mutation: POST_NEW_PAYMENT_ADDRESS,
			variables: {
				address: wsAddress,
			},
			...(refetch
				? {
					refetchQueries: [
						// Refetch addresses from session
						{ query: FUNDING_SOURCES_GET_QUERY },
						{ query: GET_CUSTOMER_ADDRESSES_AND_CONTACT },
					],
				}
				: {}
			),
		})
	);

	const {
		// String
		// (Required) The unique identifier of the address created.
		addressId,
		// Boolean
		// (Required) True if the new address already existed for this customer
		// and the addressId is for the existing address. Fields for comparing
		// the address are every field in the WSAddress object
		isExistingAddress,
	} = get(addressPostResponse, 'data.CustomerMutationRoute.addressPost', {});

	return new WSAddressExt({
		addressId,
		...wsAddress,
	});
};
