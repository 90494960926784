import React from 'react';
import cx from 'classnames';
import {
	get,
	head,
	map,
	find,
} from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import StdQuery from 'components/data/StdQuery.js';
import { USER_PAYMENT_METHODS_QUERY } from 'components/data/session-user/SessionUser.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { DropdownCard } from 'components/icons/CardBrands.js';

import Dropdown, { DropdownItem } from "components/Dropdown.js";
import { SmallChevron } from "components/Icon.js";
import { MaskedValues } from 'components/card-icons/GenericIconAndMaskedValue.js';
import { getCreditCardType } from 'components/card-icons/util';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import * as charlieDropdown from './CharlieDropdown.module.css';
import * as style from './CardSelector.module.css';

/**
 * Shows a dropdown for the cards on the account, powered by
 * the HeadlessUI Menu component
 */
const CardSelector = () => {
	const transit_account_id = useTransitAccountIdContext();

	if (!transit_account_id) {
		return null;
	}

	return (
		<StdQuery
			query={USER_PAYMENT_METHODS_QUERY}
		>
			{({ data }) => {
				const subsystemAccountInfoQ = get(data, 'subsystemAccountInfoQ', []);

				const cards = map(subsystemAccountInfoQ, (wsSubsystemAccountInfo) => {
					const {
						subsystemAccountReference,
						subsystemAccountDetailedInfo,
					} = wsSubsystemAccountInfo;
					const token = head(subsystemAccountDetailedInfo.tokens);

					// sometimes TAs dont have tokens
					if (!token) {
						noticeError(null, levels.error, `TA: ${subsystemAccountReference} is missing tokens`);
						return null;
					};

					const tokenInfo = get(token, 'tokenInfo', {});

					return {
						id: token.tokenId,
						label: <MaskedValues {...{
							showName: true,
							nickname: tokenInfo.tokenNickname,
							maskedPan: tokenInfo?.maskedPan,
							creditCardType: getCreditCardType(tokenInfo),
							serialNumber: tokenInfo?.serialNumber,
							tokenType: tokenInfo.tokenType,
						}} />,
						action: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference }),
						subsystemAccountReference,
						type: tokenInfo.tokenType,
					};
				}).filter(x => x);

				const selectedCard = find(cards, option => option.subsystemAccountReference === transit_account_id);

				if (!selectedCard) {
					return null;
				}

				return (
					<Dropdown
						id={'mobileCardSelector'}
						overrideClass={cx(charlieDropdown.container, style.container)}
						overrideButtonClass={cx(charlieDropdown.toggle, style.toggle)}
						overrideOptionsClass={charlieDropdown.options}
						label={
							<>
								<span className={cx(charlieDropdown.iconCard, charlieDropdown.toggleIcon)}>
									<DropdownCard type={selectedCard.type} />
								</span>
								<SmallChevron overrideClass={charlieDropdown.toggleArrow} aria-hidden={true} />
							</>
						}
					>
						{map(cards, (card) => (
							<DropdownItem overrideClass={charlieDropdown.option} to={card.action} key={`link-${card.id}`} itemKey={`link-${card.id}`}>
								{card.label}

								<span className={charlieDropdown.iconCard}>
									<DropdownCard type={card.type} />
								</span>
							</DropdownItem>
						))}
					</Dropdown>
				);
			}}
		</StdQuery>
	);
};


export default CardSelector;
