import React from "react";

import {
	object as yup_object,
	string as yup_string,
	boolean as yup_boolean,
} from 'yup';
import { addYupMethod } from 'utils/YupValidators.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { countryCodeUS } from 'utils/Constants.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from 'utils/GetErrorKey.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';

// https://reflexions.slack.com/archives/CDTQPPXJ8/p1642109159053600
// Just the street address 2 and 3 is optional (edited)
// Note that the field labels should be Street Address 1 and Street Address 2
// Street Address 3 is for international only

export const getAddressYupSchema = (addressPrefix = '', addressPostfix = '') => {
	addYupMethod('validStringInput');
	addYupMethod('validPhoneNumber');

	const getInputName = (fieldName) => `${addressPrefix}${fieldName}${addressPostfix}`;

	const validations =
		{
			[ getInputName('country') ]: yup_string()
				.required(getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.country', BO_ERRORS.general.required))
				.trim()
				.concat(
					// If the country is not US don't bother validating the rest of the form.
					// We want to draw attention to only this validation error.
					!PublicAppVars.ENABLE_INTERNATIONAL_ADDRESS
						? yup_string().oneOf([ countryCodeUS ], 'miscText.general-address-international-error')
						: null,
				),
			[ getInputName('address1') ]: yup_string()
				.validStringInput(() =>
					<CmsContentRenderedInline
						contentKey={'miscText.error-validation-no-special-chars'}
						fallbackValue="Field cannot contain special characters"
					/>
				)
				.max(64, getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.address1', BO_ERRORS.general.tooLong))
				.required(getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.address1', BO_ERRORS.general.required))
				.trim(),
			[ getInputName('address2') ]: yup_string()
				.validStringInput(() =>
					<CmsContentRenderedInline
						contentKey={'miscText.error-validation-no-special-chars'}
						fallbackValue="Field cannot contain special characters"
					/>
				)
				.max(64, getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.address2', BO_ERRORS.general.tooLong))
				.trim(),
			[ getInputName('address3') ]: yup_string()
				.trim(),
			[ getInputName('city') ]: yup_string()
				.validStringInput(() =>
					<CmsContentRenderedInline
						contentKey={'miscText.error-validation-no-special-chars'}
						fallbackValue="Field cannot contain special characters"
					/>
				)
				.max(50, getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.city', BO_ERRORS.general.tooLong))
				.required(getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.city', BO_ERRORS.general.required))
				.trim(),
			[ getInputName('attentionTo') ]: yup_string()
				.trim(),
			[ getInputName('state') ]: yup_string()
				.required(getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.state', BO_ERRORS.general.required))
				.trim(),
			[ getInputName('postalCode') ]: yup_string()
				.when(getInputName('country'),
					{
						is: countryCodeUS,
						then: (schema) => schema.matches(
							/^[0-9]{5}$/,
							getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.postalCode', 'errors.general.invalid.postal.code')
						),
					},
				)
				.required(getErrorKey(COLLECTIONS.purchaseCharlieCard, 'address.postalCode', BO_ERRORS.general.required)),
			[ getInputName('saveAddress') ]: yup_boolean(),
			[ getInputName('unverified') ]: yup_boolean(),
			[ getInputName('primaryBilling') ]: yup_boolean(),
			[ getInputName('primaryShipping') ]: yup_boolean(),
		};

	return yup_object().shape(validations);
};
