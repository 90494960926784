import React, {
	useState,
} from 'react';
import qs from 'qs';
import cx from 'classnames';

import { Redirect } from 'react-router-dom';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import PreventDefault from 'utils/PreventDefault.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { useTransitAccount } from "components/data/transit-account/TransitAccount.js";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import {
	types,
} from 'server/api-types/WSPaymentHistoryFactory.js';

import {
	isEligibleToDisputeTrip,
	getTripStatusCmsKeyValue,
} from 'utils/TripHistory.js';
import {
	isEligibleToDisputeOrder,
	wsPaymentHistoryIsRefund,
	getProductType,
	getPurchaseRefundStatusCmsKeyValue,
} from 'utils/PurchaseHistory.js';

import Button, { buttonTypeStylePlain, Ghost } from 'components/Button.js';
import Print from 'components/icons/ui/Print.js';
import { PDF_TYPES } from 'components/account/download-reports/pdf/Document.js';
import { SIZES } from 'components/icons/LoadingIcon.js';

import * as tables from 'components/DataTable.module.css';


const cms = {
	printSingle: 'miscText.purchasehistory-printsingle-aria',
	print: 'miscText.purchasehistory-printsingle',

	disputeCharge: 'miscText.purchasehistory-dispute-cta',
	disputeChargeQuestion: 'miscText.purchasehistory-dispute',
	contactCustService: 'miscText.purchasehistory-dispute-status-contact-cta',

	disputeRide: 'miscText.triphistory-trip-dispute-cta',
	disputeRideQuestion: 'miscText.triphistory-trip-dispute',
	disputedPurchaseLabel: 'miscText.purchasehistory-dispute-status',
	disputedTripLabel: 'miscText.triphistory-trip-dispute-status',

	// Uses the same content as contactCustService, but making it it's own key incase MBTA wants to change the text around trip disputes
	disputeTripDisabled: 'miscText.triphistory-trip-dispute-disabled',

	ctaLink: 'miscText.customer-service-url',
	disputePurchaseDisable: 'miscText.purchasehistory-dispute-disabled',
};

const ContactCustomerServiceButton = ({ isPurchaseTable = false }) =>
	<CmsContentList list={Object.values(cms)}>{({ cmsContent }) =>
		<Button
			typeStyle={buttonTypeStylePlain}
			type="button"
			to={cmsContent[ cms.ctaLink ]}
			additionalClassNames={cx(tables.requestRefundBtn)}
			target="_blank"
			external
		>
			<CmsContentRenderer.Span contentKey={isPurchaseTable
				? cms.contactCustService
				: cms.disputeTripDisabled}
			fallbackValue={"Contact Customer Service"} />
		</Button>
	}</CmsContentList>;

const PurchaseRefundButton = ({
	wsPaymentHistory,
	handleDisputeRedirect,
	productType,
	isDisputesMax,
}) => {

	const allowOrderDispute = isEligibleToDisputeOrder(wsPaymentHistory, productType);
	const orderDisputeStatus = Boolean(wsPaymentHistory.orderRefundStatus);

	if (allowOrderDispute && !isDisputesMax) {
		// display Dispute Charge button if eligible
		return <Button
			type={Ghost}
			additionalClassNames={cx(buttonTypeStylePlain, tables.requestRefundBtn)}
			onClick={PreventDefault(handleDisputeRedirect)}
		>
			<CmsContentRenderer.Span contentKey={cms.disputeCharge} fallbackValue={"Dispute Charge"} />
		</Button>;
	} else if (orderDisputeStatus) {
		// display contact customer service button if order dispute status exists
		return <ContactCustomerServiceButton isPurchaseTable={true} />;
	} else {
		// overwise display disable dispute message
		return <CmsContentRenderer.Span contentKey={cms.disputePurchaseDisable} fallbackValue={"This is a non-refundable purchase."} />;
	}
};

const RideRefundButton = ({
	wsTripHistory,
	handleDisputeRedirect,
	isDisputesMax,
}) => {
	// we display contact customer service button if fare dispute is not allowed
	const allowTripDispute = isEligibleToDisputeTrip(wsTripHistory);

	if (allowTripDispute && !isDisputesMax) {
		return <Button
			typeStyle={buttonTypeStylePlain}
			additionalClassNames={cx(tables.requestRefundBtn)}
			onClick={PreventDefault(handleDisputeRedirect)}
		>
			<CmsContentRenderer.Span contentKey={cms.disputeRide} fallbackValue={"Dispute trip"} />
		</Button>;
	}

	return <ContactCustomerServiceButton />;
};


const DisputeStatus = ({
	isPurchaseTable,
	clonedRow,
}) => {

	const tripDisputeCorrectionStatus = !isPurchaseTable ? getTripStatusCmsKeyValue(clonedRow) : null;
	const purchaseRefundStatus = isPurchaseTable ? getPurchaseRefundStatusCmsKeyValue(clonedRow.orderRefundStatus) : null;

	return (
		<div className={tables.statusWrapper}>
			<CmsContentRenderer.Span
				className={tables.disputeChargeQuestion}
				contentKey={isPurchaseTable ? cms.disputedPurchaseLabel : cms.disputedTripLabel}
				fallbackValue={isPurchaseTable ? 'Disputed Charge:' : 'Disputed Trip:'}
			/>
			{isPurchaseTable
				? <CmsContentRenderedInline
					className={tables.disputeChargeQuestion}
					contentKey={purchaseRefundStatus.cmsKey}
					fallbackValue={purchaseRefundStatus.fallbackValue}
				/>
				: <CmsContentRenderedInline
					className={tables.disputeChargeQuestion}
					contentKey={tripDisputeCorrectionStatus.cmsKey}
					fallbackValue={tripDisputeCorrectionStatus.fallbackValue}
				/>
			}
		</div>
	);
};

const RowStatus = ({
	clonedRow,
	isPurchaseTable,
	productType,
}) => {

	const allowTripDispute = !isPurchaseTable ? isEligibleToDisputeTrip(clonedRow) : false;

	const allowOrderDispute = isPurchaseTable ? isEligibleToDisputeOrder(clonedRow, productType) : false;

	const tripDisputeCorrectionStatus = !isPurchaseTable ? getTripStatusCmsKeyValue(clonedRow) : null;
	const orderDisputeStatus = isPurchaseTable ? clonedRow?.orderRefundStatus : null;

	return (
		<div>
			{((isPurchaseTable && allowOrderDispute) || allowTripDispute)
				? <CmsContentRenderer.Span contentKey={isPurchaseTable ? cms.disputeChargeQuestion : cms.disputeRideQuestion}
					className={tables.disputeChargeQuestion}
					fallbackValue="Do you believe you have been mischarged?"
				/>
				: ((!isPurchaseTable && tripDisputeCorrectionStatus) || (isPurchaseTable && orderDisputeStatus))
					? <DisputeStatus {...{ isPurchaseTable, clonedRow }} />
					: null
			}
		</div>);
};


const TableRowActions = ({
	isPurchaseTable = false,
	filtersApplied,
	clonedRow = {},
	onModalClose,
	transitAccountId: transitAccountIdFromProps = '', // comes when dispute purchase on mobile view
}) => {
	const [ redirect, setRedirect ] = useState(null);
	const transit_account_id = useTransitAccountIdContext();

	// inherited from OMNY
	// From Alessandro's BU2 TA-TW - API Integration Guide:
	// Dispute a Ride Flow. Note: To limit the # of disputes that can be made Web/Mobile need to look at both the
	// WSTransitAccountOrderInfo & WSTapVoidInfo provisionalVoidCount if one of the two is equal to 0 no
	// disputes can be submitted.
	// as of 10/07/2019 https://reflexions.slack.com/archives/CA82SPCTV/p1570486186203100
	const {
		data: transitAccountQdata,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id });
	const { orderInfo, tapVoidInfo } = transitAccountQdata ?? {};
	const isDisputesMax = (orderInfo?.provisionalVoidCount === 0 || tapVoidInfo?.provisionalVoidCount === 0);

	// for print purchase order
	const { orderId } = clonedRow ?? {};

	// subRowHeaders and subRows are unneeded
	delete clonedRow.subRowHeaders;
	delete clonedRow.subRows;
	const wsPaymentHistory = clonedRow;

	// do not show row status and dispute action if isRefund=true
	const isRefund = wsPaymentHistoryIsRefund(wsPaymentHistory) ?? false;

	// if a PayGo Payment (use “Trip dispute” instead of “order dispute”)
	const productType = getProductType({
		isRefund,
		type: wsPaymentHistory.type ?? types.travel,
		orderType: wsPaymentHistory?.orderType,
		cms,
	});

	const handleDisputeRedirect = () => {
		if (isPurchaseTable) {
			const transitAccountId = transitAccountIdFromProps ? transitAccountIdFromProps : transit_account_id;
			setRedirect(<Redirect push
				to={{
					pathname: getPathByRoute(routeKeys.AccountDisputePurchase, { transit_account_id: transitAccountId }),
					state: { wsPaymentHistory, filtersApplied },
				}} />);
		} else {
			const wsTripHistory = clonedRow;
			setRedirect(<Redirect push
				to={{
					pathname: getPathByRoute(routeKeys.AccountDisputeRide, { transit_account_id }),
					state: { wsTripHistory, filtersApplied },
				}} />);
		}
	};

	if (redirect) {
		// close mobile purchase history details modal
		onModalClose?.();

		return redirect;
	};

	return (
		<CmsContentList list={Object.values(cms)} spinnerSize={SIZES.component}>{({ cmsContent }) =>
			<div className={tables.rowActions}>
				{isPurchaseTable ?
					<div aria-label={cmsContent[ cms.printSingle ]}>
						<Button typeStyle={buttonTypeStylePlain}
							additionalClassNames={cx(tables.tableAction, tables.printBtn)}
							aria-label={cmsContent[ cms.printSingle ]}
							to={`/download-pdf/${PDF_TYPES.order}?${qs.stringify({ transitAccountId: transit_account_id, orderId, ...filtersApplied }, { skipNulls: true })}`}
							target="_blank"
							external
						>
							<i className={tables.tableActionIcon}><Print /></i>
							<CmsContentRenderer.Span contentKey={cms.print}
								fallbackValue="Print"
							/>
						</Button>
					</div>
					: null
				}
				{!isRefund ?
					<div className={tables.disputeActionMob}>
						<RowStatus {...{ clonedRow, isPurchaseTable, productType }} />
						{isPurchaseTable
							? <PurchaseRefundButton {...{
								wsPaymentHistory,
								handleDisputeRedirect,
								productType,
								isDisputesMax,
							}} />
							: <RideRefundButton {...{
								wsTripHistory: clonedRow,
								handleDisputeRedirect,
								isDisputesMax,
							}} />}
					</div>
					: null
				}
			</div>
		}</CmsContentList>
	);
};

export default TableRowActions;
