export const CARD_ACTIONS = {
	blockCard: 'blockCard',
	replaceCard: 'replaceCard',
};

export const BLOCK_CARD_REASONS = {
	lost: 'lost',
	stolen: 'stolen',
};

export const REPLACE_CARD_REASONS = {
	expired: 'expired',
	broken: 'broken',
};

export const CARD_REASONS = {
	...BLOCK_CARD_REASONS,
	...REPLACE_CARD_REASONS,
};

export const CARD_RESOLUTIONS= {
	newCard: 'newCard',
	currentCard: 'currentCard',
};