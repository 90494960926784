import React, { useRef, useState } from 'react';
import cx from "classnames";
import {
	values,
} from 'lodash';
import { gql, useApolloClient } from "@apollo/client";
import {
	NO_FILTERS,
} from 'components/FiltersConstants.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import ServerDate from 'components/ServerDate.js';
import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { getTransitModeIcon } from 'components/icons/TransitModes.js';

import Button from 'components/Button.js';
import { useSelectedLocationContext } from "context/SelectedLocationContext";
import { useStepContext } from 'context/StepContext.js';
import {
	simplifyDefault,
	getTapToCorrect,
	getKnownTap,
} from 'components/modals/CorrectMissingTap.js';

import * as tables from 'components/DataTable.module.css';
import * as style from 'components/modals/CorrectMissingTap.module.css';
import * as modalStyles from '../Modal.module.css';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics';
import Toast from 'components/Toast';
import { getDefaultFilterDates } from 'utils/TripHistory';
import { useGlobalToastsContext } from 'context/ToastProvider';
import { useModalContext } from 'context/ModalProvider';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware';
import { GET_RIDES_HISTORY } from 'components/data/transit-account/RidesHistory.query';
import { getTransitAccountRefetchQueries } from 'components/data/transit-account/TransitAccount';
import FormHelper, { WireFormHelper } from 'utils/FormHelper';
import PreventDefault from 'utils/PreventDefault';
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";

const TAP_CORRECTION = gql`
	mutation TapCorrectionM(
		$tripId: String!
		$reasonCode: String
		$travelMode: String
		$travelPresenceId: String!
		$operator: String
		$subsystemAccountReference: String!
		$entryOrExit: String
		$presenceDateTime: String
		$sector: String
		$stopPoint: String
	) {
		tapCorrection(
			tripId: $tripId
			reasonCode: $reasonCode
			travelMode: $travelMode
			travelPresenceId: $travelPresenceId
			operator: $operator
			subsystemAccountReference: $subsystemAccountReference
			entryOrExit: $entryOrExit
			presenceDateTime: $presenceDateTime
			sector: $sector
			stopPoint: $stopPoint
		)
	}
`;



const cms = {
	rideDateLabel: 'miscText.',
	confirmDescription: 'miscText.resolvetap-confirm-description',
	confirmBack: 'miscText.resolvetap-confirm-back',
	confirmSubmit: 'miscText.resolvetap-confirm-submit',
};



const CorrectMissingTapConfirm = ({
	wsTripRideHistory,
	prevFormData,
	subsystemAccountReference,
	removeAlert,
	yupSchema,
}) => {
	const apolloClient = useApolloClient();
	const formRef = useRef(null);
	const [ submitting, setSubmitting ] = useState(false);
	const { setToast, removeToast } = useGlobalToastsContext();
	const { setModal } = useModalContext();

	const { location, loading: selectedLocationLoading } = useSelectedLocationContext();
	const { prevStep } = useStepContext();
	const [ validationState, setValidationState ] = useState({});

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, yupSchema);

	if (selectedLocationLoading) {
		return <LoadingIcon size={SIZES.component} />;
	}

	const defaultFilterDates = getDefaultFilterDates({ isAlerts: false });
	const filtersApplied = {
		...NO_FILTERS,
		...defaultFilterDates,
	};

	const { travelPresenceEvents, travelModeDescription, startLocationDescription } = wsTripRideHistory ?? {};

	const tapToCorrect = getTapToCorrect(travelPresenceEvents) ?? {};

	const station = location?.value ?? simplifyDefault(wsTripRideHistory, tapToCorrect)?.value;

	const knownTap = getKnownTap(travelPresenceEvents) ?? {};

	const transactionDateTime = knownTap?.transactionDateTime;


	const onSuccess = (cmsContent) => setToast(
		<Toast
			type="success"
			title={<CmsContentRenderer.Span
				cmsContent={cmsContent}
				contentKey={cms.successTitle}
				fallbackValue="Your ride has been corrected. If there is a difference in the fare, this will be added or deducted from your balance."
			/>}
			onClosed={removeToast}
		/>,
	);


	const kickoffSubmit = async (cmsContent) => {
		setSubmitting(true);


		GoogleAnalytics.logEvent("User is attempting to correct a tap");

		try {
			const variables = {
				tripId: prevFormData.tripId,
				travelMode: prevFormData.travelMode,
				travelPresenceId: prevFormData.travelPresenceId,
				operator: prevFormData.operator,
				subsystemAccountReference,
				presenceDateTime: null,
				sector: prevFormData.sector,
				stopPoint: prevFormData.stopPoint,
			};

			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: TAP_CORRECTION,
					variables,
					refetchQueries: [
						{
							query: GET_RIDES_HISTORY,
							variables: {
								subsystemAccountRef: subsystemAccountReference,
								filtersApplied,
							},
						},
						...getTransitAccountRefetchQueries(subsystemAccountReference),
					],
				})
			);
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);

			return;
		}

		setSubmitting(false);
		setModal(null);
		removeAlert();
		onSuccess(cmsContent);
	};


	const handleFormChange = (event) => {
		formHelper.clearFieldErrors(event.target);
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => {
			return (
				<>
					<WireFormHelper {...{ formHelper }}>
						<form
							method="post"
							data-qa="manageTapForm"
							ref={formRef}
							onSubmit={PreventDefault(() => kickoffSubmit(cmsContent))}
							onChange={handleFormChange}
						>
							<div className={style.tripDateInfo}>
								<CmsContentRenderer.Span contentKey={cms.rideDateLabel}
									className={style.dateLabel}
									fallbackValue="Ride date:"
								/>
								<div className={style.tapDateTime}>
									<ServerDate
										dateISO={transactionDateTime}
										options={{ day: "numeric", month: "long", year: "numeric" }}
										altFormat={ALT_DATE_FORMAT}
									/>
								</div>
							</div>

							<div className={style.tripInfo}>
								<div>
									<div className={style.tapRowGap}>
										<ServerDate
											dateISO={transactionDateTime}
											options={{ hour: 'numeric', minute: 'numeric' }}
										/>
									</div>
									<div>
									&mdash;
									</div>
								</div>

								<div>
									<div className={style.tapRowGap}>
										{getTransitModeIcon(travelModeDescription, {
											overrideClass: tables.transportIcon,
										})}
									</div>
									<div>
										{getTransitModeIcon(travelModeDescription, {
											overrideClass: tables.transportIcon,
										})}
									</div>
								</div>

								<div>
									<div className={style.tapRowGap}>
										{startLocationDescription}
									</div>
									<div>
										{station}
									</div>
								</div>
							</div>

							<CmsContentRendered.P
								className={style.boldStyle}
								contentKey={cms.confirmDescription}
								fallbackValue={`Station entered as missing tap: ${station}. Please confirm that this information is correct. Screen reader support enabled.`}
								variables={{ station: location?.value ?? simplifyDefault(wsTripRideHistory, tapToCorrect)?.value }}
							/>
							<div className={style.actions}>
								<Button type="button"
									additionalClassNames={cx(style.btn, modalStyles.leftButton)}
									onClick={prevStep}
								>
									<CmsContentRenderer.Span contentKey={cms.confirmBack}
										fallbackValue="No, change missed tap info"
									/>
								</Button>

								<Button
									isPrimary
									type='submit'
									additionalClassNames={cx(style.btn, modalStyles.rightButton)}
									disabled={submitting}
									submitting={submitting}
								>
									<CmsContentRenderer.Span contentKey={cms.confirmSubmit}
										fallbackValue="Yes, this is correct"
									/>
								</Button>
								{formHelper.getFieldErrorJsx('')}
							</div>
						</form>
					</WireFormHelper>
				</>);
		}}</CmsContentList>
	);
};

export default CorrectMissingTapConfirm;
