import { gql } from "@apollo/client";
import React from "react";
import StdQuery from "components/data/StdQuery.js";
import {
	map,
	reduce,
} from "lodash";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import WSCountryInfo from "server/api-types/WSCountryInfo.js";

export const GET_COUNTRIES = gql`query Countries {
	CustomerService {
		countries {
			country
			description
			id
			config {
				address2
				address3
				city
				state
				postalCode
			}
		}
	}
}`;

/**
 *
 * @param queryResult
 * @returns {{countriesObj: Object.<string, WSCountryInfo>, countries: {WSCountryInfo[]}}}
 */
const parseQueryResult = queryResult => {
	// states is [{key: "AL", value: "Alabama"}, {key: "AK", value: "Alaska"}, etc]
	const countries = map(queryResult.data?.CustomerService.countries, country => WSCountryInfo.fromSerialized(country));
	const countriesObj = reduce(countries, (accumulator, value, key) => {
		accumulator[ value.country ] = value;
		return accumulator;
	}, {});

	return {
		...queryResult,
		countries,
		countriesObj,
	};
};

const Countries = ({ children }) => (
	<StdQuery query={GET_COUNTRIES}>
		{(queryResult) => children(parseQueryResult(queryResult))}
	</StdQuery>
);

export const useCountries = () => {
	const queryResult = useStdQuery(GET_COUNTRIES);
	return parseQueryResult(queryResult);
};

export default Countries;
