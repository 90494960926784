import React, {
	useEffect,
} from "react";
import PropTypes from 'prop-types';
import {
	map,
	find,
	filter,
	values,
} from 'lodash';
import Select from 'react-select';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import { useSelectedLocationContext } from "context/SelectedLocationContext";
import { BUS } from 'utils/Constants.js';

import Control from 'components/forms/Control.js';
import Input from 'components/forms/Input.js';

import * as style from 'components/forms/inputs/Select.module.css';



const cms = {
	formLabelsStation: "miscText.Forms-Labels-Station",
	busPlaceholder: 'miscText.resolvetap-bus-placeholder',
	trainPlaceholder: 'miscText.resolvetap-train-placeholder',
};


export const simplify = (data) => ({
	value: data?.stopPointDesc,
	label: data?.stopPointDesc,
	operator: data?.operator,
	sector: data?.sector,
	stopPoint: data?.stopPoint,
});

const LocationSelect = ({
	name,
	error,
	defaultValue,
	additionalClasses = '',
	newLocation,
	searchInput,
	setSearchInput,
	travelModeDescription,
	prefix = "",
}) => {
	useEffect(() => {
		if (newLocation) {
			setSearchInput(newLocation?.value);
		}
	}, [
		newLocation, setSearchInput,
	]);

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
			<Control
				label={<CmsContentRenderer.Span
					contentKey={travelModeDescription === BUS ? cms.busPlaceholder : cms.trainPlaceholder}
					fallbackValue={travelModeDescription === BUS ? "Search for destination stop name" : "Search for destination station name"} />}
				hideLabel={false}
				name={`${name}${prefix}`}
				error={error}
				additionalClasses={additionalClasses}
			>
				<Input
					value={searchInput}
					id={"location-select"}
					name={`${name}${prefix}`}
					placeholder={cmsContent[ travelModeDescription === BUS ? cms.busPlaceholder : cms.trainPlaceholder ]}
					onChange={event => {
						setSearchInput(event.target.value);
					}}
					controlled={true}
					overrideClass={style.locationSelect}
					type="search"
				/>
			</Control>

			<input
				type="hidden"
				name={`stopPoint${prefix}`}
				value={newLocation?.stopPoint ?? defaultValue?.stopPoint}
			/>

			<input
				type="hidden"
				name={`operator${prefix}`}
				value={newLocation?.operator ?? defaultValue?.operator}
			/>

			<input
				type="hidden"
				name={`sector${prefix}`}
				value={newLocation?.sector ?? defaultValue?.sector}
			/>
		</>)}</CmsContentList>
	);
};

LocationSelect.propTypes = {
	name: PropTypes.string,

	error: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
	]),

	defaultValue: PropTypes.object,

	additionalClasses: PropTypes.string,

	prefix: PropTypes.string,
};

export default LocationSelect;
