import { gql } from "@apollo/client";
import  { useMemo } from "react";
import useStdQuery from "../hooks/useStdQuery.js";

import PublicAppVars from 'utils/PublicAppVars.js';
import { NO_FILTERS } from "components/FiltersConstants.js";


const travelPresenceEventsFragment = `
	objectType
	travelPresenceId
	transactionDateTime
	type
	typeDescription
	locationCode
	operator
	operatorDescription
	status
	statusDescription
	stopPoint
	stopPointDescription
	routeNumber
	generatedCause
	generatedCauseDescription
	travelPresenceIndicator
	processingStatus
	correctionDateTime
	correctionStatus
	zone
	transactionStatusDescription
`;

const rideHistoryFragment = `
(
	$subsystemAccountRef: ID!
	$startDateTime: String
	$endDateTime: String
	$specificDate: String
	$offset: Int
	$limit: Int
	$dateFilterType: String
	$type: String
) {
	TransitAccountRoute {
		getRidesHistory (
			subsystemAccountRef: $subsystemAccountRef
			startDateTime: $startDateTime
			endDateTime: $endDateTime
			specificDate: $specificDate
			offset: $offset
			limit: $limit
			dateFilterType: $dateFilterType
			type: $type
		) {
			lineItems {
				__typename
				subsystemAccountReference
				rideId
				startDateTime
				endDateTime
				type
				travelMode
				travelModeDescription
				startLocationDescription
				endLocationDescription
				restrictedTravelCorrectionActions {
					key
					value
				}
				...on WSTripRideHistory {
					travelMode
					totalFare
					tripStatus
					riderClass
					riderClassDescription
					fareDescription
					isCorrectable
					isVoidable
					isFareAdjustable
					fareNotAdjustableReasons
					fareAdjustmentAmount
					tripCorrectionStatus
					tripCorrectionDateTime
					travelPresenceEvents {
						...on WSRideHistoryTransitTravelPresence {
							${travelPresenceEventsFragment}
						}

					}
					tripPayments {
						productDescription
						purseDescription
					}
				}

				...on WSInspectionTapRideHistory {
					travelPresenceEvents {
						...on WSRideHistoryTransitTravelPresence {
							${travelPresenceEventsFragment}
						}
					}
				}

				...on WSRejectedTapRideHistory {
					travelPresenceEvents {
						...on WSRideHistoryTransitTravelPresence {
							${travelPresenceEventsFragment}
						}
					}
				}

			}
			totalCount
		}
	}
}
`;

const GET_RIDES_HISTORY_MISSED_TAPS = gql`
query transitAccountMissedTapsGET
${rideHistoryFragment}
`;

export const GET_RIDES_HISTORY = gql`
	query transitAccountRidesHistoryGET
	${rideHistoryFragment}
`;

export const useRideHistory = ({
	offset = 0,
	filtersApplied = NO_FILTERS,
	subsystemAccountRef = null,
	type = null,
	limit = PublicAppVars.TRIPS_HISTORY_PAGE_SIZE,
	isMissedTaps = false, // Used for the TapAlertsContext to differentiate between the normal hide history query and the tap alerts.
}) => {

	const variables = {
		offset,
		...filtersApplied,
		subsystemAccountRef,
		limit,
		type,
	};

	const queryResult = useStdQuery(isMissedTaps
		? GET_RIDES_HISTORY_MISSED_TAPS
		: GET_RIDES_HISTORY, {
		variables,
		fetchPolicy: 'cache-and-network',
		skip: !subsystemAccountRef,
	 });

	const { lineItems, totalCount } = useMemo(() =>
		queryResult?.data?.TransitAccountRoute?.getRidesHistory ?? {}
	,[ queryResult ]);

	return {
		lineItems,
		totalCount,
		...queryResult,
	};
};
