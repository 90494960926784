import React, { Component } from "react";
import cx from 'classnames';

import CardLayout, { getCardLayoutBreadcrumbs } from '../../../layouts/CardLayout.js';

import Panel from 'components/Panel.js';
import CardService from "components/account/CardService.js";

import * as admin from '../../../layouts/Admin.module.css';
import * as panel from 'components/Panel.module.css';

import { Services } from 'server/DummyData.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { useBreadcrumbCallback } from "context/BreadcrumbProvider.js";

const cms  = {
	title: 'miscText.connected-services-header',
};

export const getConnectedServicesBreadcrumbs = () => [
	...getCardLayoutBreadcrumbs(),
	<CmsContentRenderedInline
		key={cms.title}
		contentKey={cms.title}
		fallbackValue="Connected Services"
	/>,
];

const ConnectedServices = () => {
	useBreadcrumbCallback(getConnectedServicesBreadcrumbs);

	return (
		<CardLayout title="Connected Services">
			<h2 className={admin.subTitle}>Use Charlie to Pay for All Your Transportation</h2>

			<Panel>
				<div className={cx(panel.header, panel.serviceHeader)}>
					<h3 className={panel.heading}>Connected Services</h3>
				</div>

				<div className={panel.services}>
					{Services.filter(service => service.isConnected).map(service => (
						<CardService service={service}
										 isConnected={true}
										 key={service.key}
						/>
					))}
				</div>
			</Panel>


			<Panel>
				<div className={cx(panel.header, panel.serviceHeader)}>
					<h3 className={panel.heading}>Connect More Services</h3>
				</div>

				<div className={panel.services}>
					{Services.filter(service => !service.isConnected).map(service => (
						<CardService service={service}
										 isConnected={false}
										 key={service.key}
						/>
					))}
				</div>
			</Panel>
		</CardLayout>
	);
	
};

export default ConnectedServices;
