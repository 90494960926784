import { find } from 'lodash';
import { generatePath } from "react-router-dom";
import PublicAppVars, {
	SSO_PROVIDER_APPLE,
	SSO_PROVIDER_FACEBOOK,
	SSO_PROVIDER_GOOGLE,
} from "../PublicAppVars.js";

export const SSO_ACTION_SIGN_IN = 'sign-in';
export const SSO_ACTION_REGISTER = 'register';
export const SSO_ACTION_LINK = 'link';

// Note: this file is public, so we can't have the private keys here
export const SSO_PROVIDERS = {
	FACEBOOK: PublicAppVars.FACEBOOK_OAUTH_ENABLED ? {
		// the key we use with the BO
		key: PublicAppVars.SSO_BO_IDS[ SSO_PROVIDER_FACEBOOK ][ 0 ],
		// all the keys that the BO uses for this provider (including the mobile app)
		allKeys: PublicAppVars.SSO_BO_IDS[ SSO_PROVIDER_FACEBOOK ],
		display: 'Facebook',
		oauthBase: 'https://www.facebook.com/v3.1/dialog/oauth?',
		authorizationStatic: {
			// the key we send to the provider
			client_id: PublicAppVars.FACEBOOK_OAUTH_APP_ID,
			redirect_uri: generatePath(PublicAppVars.OAUTH_REDIRECT_PATH, { provider: SSO_PROVIDER_FACEBOOK }),
			response_type: 'code',
			scope: 'email',
		},
	} : null,
	GOOGLE: PublicAppVars.GOOGLE_OAUTH_ENABLED ? {
		key: PublicAppVars.SSO_BO_IDS[ SSO_PROVIDER_GOOGLE ][ 0 ],
		allKeys: PublicAppVars.SSO_BO_IDS[ SSO_PROVIDER_GOOGLE ],
		// code smell. Indicates content that isn't CMS-controlled/translated. It's a TM though.
		display: 'Google',
		oauthBase: 'https://accounts.google.com/o/oauth2/v2/auth?',
		authorizationStatic: {
			client_id: PublicAppVars.GOOGLE_OAUTH_CLIENT_ID,
			redirect_uri: generatePath(PublicAppVars.OAUTH_REDIRECT_PATH, { provider: SSO_PROVIDER_GOOGLE }),
			response_type: 'code',
			scope: 'email profile',
		},
	} : null,
	APPLE:  PublicAppVars.APPLE_OAUTH_ENABLED ? {
		key: PublicAppVars.SSO_BO_IDS[ SSO_PROVIDER_APPLE ][ 0 ],
		allKeys: PublicAppVars.SSO_BO_IDS[ SSO_PROVIDER_APPLE ],
		display: 'Apple',
		oauthBase: 'https://appleid.apple.com/auth/authorize?',
		authorizationStatic: {
			client_id: PublicAppVars.APPLE_OAUTH_CLIENT_ID,
			redirect_uri: generatePath(PublicAppVars.OAUTH_REDIRECT_PATH, { provider: SSO_PROVIDER_APPLE }),
			response_type: 'code',

			// if we request email, we get
			// "response_mode must be form_post when name or email scope is requested"
			// that'd mean that the response comes to us as a POST instead of query parameters,
			// and we'd have to rewrite our callback handling
			scope: '',
		},
	}: null,
};


export const providerFromKey = (key) => find(SSO_PROVIDERS, provider => provider.allKeys.includes(key));
