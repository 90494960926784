/**
 * Catches Yup errors and turns them into an ErrorReport
 */
import { forEach } from "lodash";
import { isValidElement } from "react";
import {
	levels,
	log,
	noticeError,
} from "../Logger.js";
import ErrorReport from './ErrorReport.js';

const isCmsKey = (errorMsg) => {
	return errorMsg.match(/^(misc|errors\.)/);
};

const yupErrorToReport = (report, error) => {
	error.inner.forEach(innerErr => {
		if (innerErr.path === '') {
			 if (isValidElement(error.message)) {
				 report.display.topLevelMessage = error.message;
				 report.display.language = 'en'; // todo: this should match the selected language
			 }
			// is this error message a cms key?
		 	else if (isCmsKey(error.message)) {
				report.TopLevelKey = error.message;
			}
			else {
				// fallback for hardcoded error messages
				report.display.topLevelMessage = error.message;
				report.display.language = 'en';
				log(null, levels.warn, { message: 'Untranslated yup error', error });
			 }
		 }
		else {
			// Errors might be a combination of hardcoded strings and cms keys until transition to keys is
			// complete. If that happens, we fall back to using the hardcoded strings

			const errorKeys = report.FieldErrors[ innerErr.path ] = [];
			 const errorMessages = report.display.fieldErrors[ innerErr.path ] = [];
 
			 forEach(innerErr.errors, (errorMsg, fieldName) => {
				 if (errorMsg.inner) {
					 innerErr.errors.forEach(innerInnerErr => {
						 yupErrorToReport(report, innerInnerErr);
					 });
				 }
				 else {
					 if (isValidElement(error.message)) {
						 errorKeys.push(null);
						 errorMessages.push(errorMsg);
					 }
					 // is this error message a cms key?
					 else if (typeof errorMsg === 'string' && isCmsKey(errorMsg)) {
						 errorKeys.push(errorMsg);
						 errorMessages.push(null);
					 }
					 else {
						 // fallback for hardcoded error messages
						 errorKeys.push(null);
						 errorMessages.push(errorMsg);
						 log(null, levels.warn, { message: 'Untranslated yup error', errorMsg, fieldName });
					 }
				 }
			 });
		 }
	 });
};
 
export const yupErrorMiddleware = async (validatePromise) => {
	 let yupResponse;
	 try {
		 yupResponse = await validatePromise;
	 }
	 catch (error) {
		 const report = new ErrorReport();
		 if (error.inner) {
			 yupErrorToReport(report, error); // modifies report
		}
		else {
			// Couldn't parse the error response
			noticeError(null, levels.error, error, 'Error parsing yup error');

			// Create something for the frontend to display
			report.TopLevelKey = 'miscText.error-yup-failed';
		}

		throw report;
	}
	return yupResponse;
};
