import {
	clone,
} from "lodash";
import { WSFundingSourceFragment } from "./WSFundingSource.js";
import WSCreditCardReference from "server/api-types/WSCreditCardReference.js";
import WSDirectDebitReference from "server/api-types/WSDirectDebitReference.js";
import WSAddress from "server/api-types/WSAddress.js";
import { hashId } from "server/utils/HashUtils.js";

export default class WSFundingSourceInfo {
	constructor({
		creditCard = null,
		directDebit = null,
		setAsPrimary = null,
		billingAddressId = null,
		nickname = null,
		billingAddress = null,
		fundingSourceId = null,
		status,
		statusDescription,
		statusDateTime,
		declineCount = null,
		isBlocked = false,
		customerId,
	}) {
		/**
		 * @type {WSCreditCardReference}
		 * (Conditionally-Required) The credit card information associated
		 * with this funding source.
		 * Either credit card or direct debit but not both can be set per funding
		 * source.
		 */
		this.creditCard = creditCard;

		/**
		 * @type {WSDirectDebitReference}
		 * (Conditionally-Required) The debit information associated with this
		 * funding source.
		 * Either credit card or direct debit but not both can be set per funding
		 * source.
			 */
		this.directDebit = directDebit;

		// boolean
		// (Optional) True if this is to be marked as primary funding source for
		// the account. False will be ignored.
		this.setAsPrimary = setAsPrimary;

		// string(40)
		// (Conditionally-Required) A unique reference to an address of the
		// customer. This is required if billingAddress is not populated.
		this.billingAddressId = billingAddressId;

		// string(256)
		// (Optional) A customer created nickname for the funding source.
		this.nickname = nickname;

		/**
		 * @type WSAddress
		 * Customer site only
		 * (Conditionally-Required) The billing address associated with this
		 * funding source.
		 * This is required if billingAddressId is not populated.
		 */
		this.billingAddress = billingAddress;

		// int
		// (Conditionally-Required)  The unique ID that identifies the billing information. Assigned by the back office system.ng source.
		// Per Alessandro: (10/12 via call) this is Conditionally-Required. Spec does say (incorrectly) required.
		this.fundingSourceId = fundingSourceId;

		// string(20)
		// (Required)  The status of the funding source.
		this.status = status;

		// string(30)
		// (Required)  The status description of the funding source.
		this.statusDescription = statusDescription;

		// 	dateTime
		// (Required)  Last status date.
		this.statusDateTime = statusDateTime;

		// int
		// (Conditionally-Required)  Number of soft declines on the funding source which impacts greylisting of it eventually.
		this.declineCount = declineCount;

		// boolean
		// (Required) Flag that indicates if the funding source blocked.
		this.isBlocked = isBlocked;

		// String(40)
		// Customer site only
		// (Required) the customer id to which this funding
		// source belongs to.
		this.customerId = customerId;
	}

	static fromBackoffice(data) {
		const result = new WSFundingSourceInfo(data);

		result.creditCard = data.creditCard
			? WSCreditCardReference.fromBackoffice(data.creditCard)
			: null;

		result.directDebit = data.directDebit
			? WSDirectDebitReference.fromBackoffice(data.directDebit)
			: null;

		result.billingAddress = data.billingAddress
			? WSAddress.fromBackoffice(data.billingAddress)
			: null;

		return result;
	}

	static fromSerialized(data) {
		const result = new WSFundingSourceInfo(data);

		result.creditCard = data.creditCard
			? WSCreditCardReference.fromSerialized(data.creditCard)
			: null;

		result.directDebit = data.directDebit
			? WSDirectDebitReference.fromSerialized(data.directDebit)
			: null;

		result.billingAddress = data.billingAddress
			? WSAddress.fromSerialized(data.billingAddress)
			: null;

		return result;
	}

	toBackoffice() {
		const result = clone(this);
		result.creditCard = this.creditCard
			? this.creditCard.toBackoffice()
			: null;

		result.directDebit = this.directDebit
			? this.directDebit.toBackoffice()
			: null;

		result.billingAddress = this.billingAddress
			? this.billingAddress.toBackoffice()
			: null;

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.fundingSourceId
			? this.fundingSourceId
			: this.creditCard?.pgCardId ?? this.directDebit?.token;

		forGraphql.creditCard = this.creditCard
			? this.creditCard.toResolver()
			: null;

		forGraphql.directDebit = this.directDebit
			? this.directDebit.toResolver()
			: null;

		forGraphql.billingAddress = this.billingAddress
			? this.billingAddress.toResolver()
			: null;

		forGraphql.customerId = this.customerId ? hashId(this.customerId) : null;

		return forGraphql;
	}
}

export const WSFundingSourceInfoType = [ `
	type WSFundingSourceInfo {
		id: ID!
		${WSFundingSourceFragment}
		fundingSourceId: Int
		status: String
		statusDescription: String
		statusDateTime: GraphQLDateTime
		declineCount: Int
		isBlocked: Boolean!
		customerId: String
	}
` ];
