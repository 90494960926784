// extracted by mini-css-extract-plugin
export var container = "components-__Tabs-module___container";
export var enablementFee = "components-__Tabs-module___enablementFee";
export var guest = "components-__Tabs-module___guest";
export var isSelected = "components-__Tabs-module___isSelected";
export var main = "components-__Tabs-module___main";
export var primary = "components-__Tabs-module___primary";
export var secondary = "components-__Tabs-module___secondary";
export var tabHeader = "components-__Tabs-module___tabHeader";
export var tabItem = "components-__Tabs-module___tabItem";
export var tabList = "components-__Tabs-module___tabList";
export var tabPanel = "components-__Tabs-module___tabPanel";
export var tabPanelTitle = "components-__Tabs-module___tabPanelTitle";
export var tabPanels = "components-__Tabs-module___tabPanels";
export var takeover = "components-__Tabs-module___takeover";