import React from 'react';
import cx from 'classnames';
import { useCartContext } from 'context/CartProvider.js';
import {
	cityStateZipStr,
} from 'components/data/session-user/Address.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

import * as style from './PurchasePanel.module.css';

const cms = {
	shippingAddress: "miscText.purchase-card-4-shipping-address",
};

const PurchaseShippingPanel = () => {
	const { cart } = useCartContext();

	const {
		address1,
		address2,
		city,
		state,
		postalCode,
	} = cart.shippingDetails.shippingAddress;

	const sessAddressLabel = `${address1}${address2 ? ', ' + address2 : ''}, ${cityStateZipStr({ city, state, postalCode })}`;

	return (
		<div className={style.container}>
			<div>
				<CmsContentRenderedInline
					contentKey={cms.shippingAddress}
					className={style.subheader}
					fallbackValue="Shipping Address"
				/>
				<div className={cx(style.item, style.address)}>
					{sessAddressLabel}
				</div>
			</div>
		</div>
	);
};

export default PurchaseShippingPanel;
