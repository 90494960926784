import {
	first,
} from 'lodash';
import {
	PAYGO,
	UNRESTRICTED,
	BALANCE_PRODUCT,
	STORED_VALUE_WITH_SPACE,
} from "utils/Constants.js";

export const getTripPayments = (wsTripRideHistory) => {
	const {
		purseDescription,
		productDescription,
	} = wsTripRideHistory.tripPayments
		? first(wsTripRideHistory.tripPayments)
		: {};

	return {
		purseDescription,
		productDescription,
	};
};

export const getProductTypeLabel = (productDescription, purseDescription) => {
	if (purseDescription === UNRESTRICTED) {
		return STORED_VALUE_WITH_SPACE;
	}
	return productDescription ?? BALANCE_PRODUCT;
};
