import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import DefaultError from './DefaultError.js';
import DefaultLoading from './DefaultLoading.js';
import { ERROR_TYPE_SERVER, ERROR_TYPE_RENDER, ERROR_TYPE_BLANK } from './errorTypes.js';

export { ERROR_TYPE_SERVER, ERROR_TYPE_RENDER, ERROR_TYPE_BLANK };

// todo: use notifyOnNetworkStatusChange to set browser loading indicator

/**
 * This class is meant to address a shortcoming in StandardQuery, which we hope to replace with this.
 * StandardQuery didn't pass through all the args that react-apollo's Query was passing to us
 * That made adding features like pagination and custom error handling more difficult than necessary
 *
 * In StdQuery, all render functions (children, loading, and error) all get the same props that Query
 * is passing to us. That way the StdQuery's parent has full control over rendering. E.g. to ignore
 * all errors, the parent could pass the same render function to our error prop as to children.
 * <StdQuery query=... error={renderMethod}>{renderMethod}</StdQuery>
 *
 * The error render function is passed an additional arg, errorType, to distinguish the different error
 * types
 */

function StandardQuery({
	query,
	loading = DefaultLoading,
	error = DefaultError,
	errorsToScreen = false,
	serverErrorHandler = undefinedFunc,
	renderErrorHandler = undefinedFunc,
	blankErrorHandler = undefinedFunc,
	...theRest
}) {
	// reconstruct the props object with defaults applied
	const props = {
		query,
		loading,
		error,
		errorsToScreen,
		serverErrorHandler,
		renderErrorHandler,
		blankErrorHandler,
		...theRest,
	};

	const args = useQuery(query, props);

	if (args.loading) {
		return loading(args);
	}

	let queryName = null;
	try {
		queryName = query.definitions[0].name.value;
	} catch (e) {}

	const errorDefaults = {
		...args,
		props,
		queryName,
	};

	if (args.error) {
		return error({
			...errorDefaults,
			errorType: ERROR_TYPE_SERVER,
		});
	}

	// We'll act as an ErrorBoundary. Not actually using one because they don't work server-side.
	let toRender;
	try {
		toRender = props.children(args);
	} catch (renderError) {
		return error({
			...errorDefaults,
			errorType: ERROR_TYPE_RENDER,
			renderError,
		});
	}

	// Query's handling of undefined is really hard to debug. Let's do our own logging instead.
	if (toRender === undefined) {
		return error({
			...errorDefaults,
			errorType: ERROR_TYPE_BLANK,
		});
	}

	return toRender;
}

StandardQuery.propTypes = {
	// graphql query object
	query: PropTypes.object.isRequired,

	// render prop during loading state
	loading: PropTypes.func,

	// overrides the default error rendering method
	error: PropTypes.func,

	// true if errors should be printed to the browser window.
	// unnecessary if the props.error is overriden
	errorsToScreen: PropTypes.bool,

	// this function will receive the apollo error, and be tasked with determining
	serverErrorHandler: PropTypes.func,
	renderErrorHandler: PropTypes.func,
	blankErrorHandler: PropTypes.func,
};

function undefinedFunc() {
	return undefined;
}

export default StandardQuery;
