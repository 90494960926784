import React, { useMemo, useState } from 'react';
import {
	values,
} from 'lodash';

import CmsContentList from "components/data/CmsContentList.js";
import SelectAddressForm from 'components/account-settings/SelectAddressForm.js';
import { BoolCheckbox } from 'components/forms/Checkbox.js';
import { WireFormHelper } from "../../utils/FormHelper.js";
import PublicAppVars from "../../utils/PublicAppVars.js";
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import Addresses from 'components/data/session-user/Addresses.query.js';
import { useIsEmvCard } from 'components/data/transit-account/EMV.helpers.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import {
	useAddressSelectorState,
	AddressSelectorContext,
} from "components/account-settings/address-selector/AddressSelector.js";
import CreditCardForm from 'components/payments/CreditCardForm.js';
import { IfLoggedIn, useLoginStage } from "components/data/session-user/LoggingIn.js";
import { IsNotAlternate } from "components/data/alternates/SessionAlternate.js";
import TogglePaymentMethod from 'components/account/card/add-passes/TogglePaymentMethod.js';
import loginStages from "components/data/session-user/LoginStages.js";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';

import * as checkboxStyle from 'components/forms/Checkbox.module.css';
import * as forms from 'components/forms/Forms.module.css';
import { SAVE_ADDRESS_REQUIRED, SAVE_ADDRESS_SHOWN } from "components/payments/AddressForm.js";

const cms = {
	setPrimaryPayment: "miscText.general-payment-set-primary",
	savePaymentMethod: "miscText.general-payment-save",
	billingAddress: "miscText.purchase-card-4-billing-address",
	requiredSave: "miscText.general-payment-save-required",
};

const AddDebitCreditCard = ({
	provideNickName = true,
	formHelper,
	isSplit,
	isFirst,
	selectedFundingSource,
	allowSave = true,
	alwaysSaveNewPayment = false,
	requiredSave = false,
}) => {
	const transit_account_id = useTransitAccountIdContext();
	const addressSelectorState = useAddressSelectorState({});
	const { loginStage } = useLoginStage();
	const [ savePayment, setSavePayment ] = useState(requiredSave);
	const saveAddressCheckboxVisibility = (requiredSave || savePayment) ? SAVE_ADDRESS_REQUIRED : SAVE_ADDRESS_SHOWN;

	const loggedIn = loginStage === loginStages.loggedIn;

	const isEmv = useIsEmvCard({ subsystemAccountReference: transit_account_id });
	const toggleIsVisible = loggedIn && !isEmv;

	const getInputName = (fieldName) => getPaymentInputName({ base: fieldName, isSplit, isFirst });

	return (
		<CmsContentList list={values(cms)}>{() => (
			<WireFormHelper formHelper={formHelper}>
				<>
					<CreditCardForm {...{
						provideNickName: provideNickName && loggedIn,
						formHelper,
						isSplit,
						isFirst,
						wsFundingSourceExt: selectedFundingSource.fundingSource,
					}} />
					<IfLoggedIn>
						<IsNotAlternate>
							{/**
							 * @todo: investigate where alwaysSaveNewPayment is used and if we should also show
							 * `this payment will be saved to your account` there instead of just a blank
							 */}
							{(allowSave && !alwaysSaveNewPayment)
								? requiredSave
									? <>
										<CmsContentRenderer.P
											contentKey={cms.requiredSave}
											fallbackValue="This payment will be saved to your account"
										/>
										<input name={getInputName('savePayment')} type="hidden" value="true" />
									</>
									: (
										<BoolCheckbox
											additionalClasses={checkboxStyle.checkboxGroupSpacing}
											name={getInputName('savePayment')}
											handleOnChange={
												({ target }) => setSavePayment(target.checked)
											}
											label={<CmsContentRenderer.Span
												className={checkboxStyle.labelText}
												contentKey={cms.savePaymentMethod}
												fallbackValue="Save this payment method to my account"
											/>}
											error={formHelper.getFieldError(getInputName('savePayment'))}
										/>
									) : null}
							{!PublicAppVars.isB2B
								? <BoolCheckbox
									additionalClasses={checkboxStyle.checkboxGroupSpacing}
									name={getInputName('primaryPaymentMethod')}
									label={<CmsContentRenderer.Span
										className={checkboxStyle.labelText}
										contentKey={cms.setPrimaryPayment}
										fallbackValue="Set as my default payment method"
									/>}
									error={formHelper.getFieldError(getInputName('primaryPaymentMethod'))}
								/>
								: null}
						</IsNotAlternate>
					</IfLoggedIn>

					{toggleIsVisible
						? <TogglePaymentMethod {...{
							formHelper,
							splitPaymentIndex: isFirst ? 0 : 1,
						}} />
						: null
					}

					<CmsContentRenderer.P
						contentKey={cms.billingAddress}
						className={forms.label}
						fallbackValue="Billing address"
					/>
					{addressSelectorState ?
						<AddressSelectorContext.Provider value={addressSelectorState}>
							<Addresses>{(wsAddressExts) =>
								<SelectAddressForm
									{...{
										formHelper,
										wsAddressExts,
										isSplit,
										isFirst,
										saveAddressCheckboxVisibility,
									}}
								/>
							}</Addresses>
						</AddressSelectorContext.Provider>
						: null
					}
				</>
			</WireFormHelper>
		)}</CmsContentList>
	);
};

AddDebitCreditCard.propTypes = {};

export default AddDebitCreditCard;
