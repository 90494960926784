/**
 * <ManageMenu> renders a normal <Dropdown /> but with a default but overwritable toggle that
 * includes a chevron, allowing the toggle of the normal <Dropdown /> to not include it
 */
import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import cx from "classnames";

import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';

import Dropdown, { DropdownItem } from 'components/Dropdown.js';
import { SmallChevron } from 'components/Icon.js';

import * as dropdown from 'components/Dropdown.module.css';

const cms = {
	manage: 'miscText.general-manage-menu',
};

const ManageMenu = ({
	id,
	label,
	additionalClasses = '',
	options,
}) => (
	<Dropdown
		id={id}
		overlayComponent='nav'
		additionalClasses={cx(dropdown.manageMenu, additionalClasses)}
		label={label || <>
			<CmsContentRenderedInline contentKey={cms.manage} fallbackValue="Manage" />
			<SmallChevron overrideClass={dropdown.toggleIcon} aria-hidden={true} />
		</>}
		placement={'bottom-end'}
	>
		{map(options, (option, index) =>
			<DropdownItem
				key={option.key ?? index}
				itemKey={option.key ?? index}
				onClick={option.action}
			>
				{ option.label }
			</DropdownItem>
		)}
	</Dropdown>
);

ManageMenu.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string,
	additionalClasses: PropTypes.string,
	options: PropTypes.array.isRequired,
};

export default ManageMenu;
