import { clone } from "lodash";

export const SubsystemAccountFeeDue = 'SubsystemAccountFeeDue';

export const TransitAccountTokenEnablementFee = 'TransitAccountTokenEnablementFee';

// 2.7.27 WSFeeDue
export default class WSFeeDue {
	constructor({
		feeDueAmount,
		feeDueLineItemType,
		feeDueType,
		feeDueOriginalAmount = null,
		feeDueDiscountAmount = null,
	}) {

		 /**
 		 * @type int
 		 * (Required) Fee amount being paid. This number is the number of the base unit of currency, such as cents.
 		 **/

		this.feeDueAmount = feeDueAmount;
		
		/**
		* @type string(20)
		* (Required) Determines the type of fee due:
		*  SubsystemAccountFeeDue
		**/
		this.feeDueLineItemType = feeDueLineItemType;
		
		/**
		* @type string(40)
		* (Required) Type of fee due. Valid value is:
		*  TransitAccountTokenEnablementFee
		**/
		this.feeDueType = feeDueType;
		
		/**
		* @type int
		* (Optional) Fee amount before applying any promotion. This number is the number of the base unit of currency, such as cents.
		**/

	 	this.feeDueOriginalAmount = feeDueOriginalAmount;
	 
		 /**
		 * @type int
		 * (Optional) Fee discount amount based on promotion used. This number is the number of the base unit of currency, such as cents.
		 **/

		this.feeDueDiscountAmount = feeDueDiscountAmount;
		
	}

	static fromBackoffice(data) {
		const result = new WSFeeDue(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		return forGraphql;
	}
}
