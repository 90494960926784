import {
	clone,
	merge,
} from "lodash";
import WSPaymentHistoryInfo, { WSPaymentHistoryInfoFragment } from "./WSPaymentHistoryInfo.js";

// 2.7.26.1.WSPaymentHistoryCreditDebitInfo
// This object extends WSPaymentHistorytInfo when paymentType is CreditCard or CreditCardNotPresent or
// DebitCard.
export default class WSPaymentHistoryCreditDebitInfo extends WSPaymentHistoryInfo {
	constructor({
		maskedPAN = null,
		maskedPan = null,
		creditCardType = null,

		//super props
		authRefNbr,
		paymentType,
		paymentDateTime,
		paymentAmount,
		paymentReferenceNumber,
		paymentStatus,
	}) {

		super({
			authRefNbr,
			paymentType,
			paymentDateTime,
			paymentAmount,
			paymentReferenceNumber,
			paymentStatus,
		});

		// string(20)
		// (Conditionally-Required) The masked PAN when the
		// paymentType is CreditCard or CreditCardNotPresent
		// or DebitCard.
		this.maskedPAN = maskedPAN ?? maskedPan;
		// to prepare for the transition to maskedPan, can use this field:
		this.maskedPan = maskedPan ?? maskedPAN;

		// string(30)
		// (Conditionally-Required) The type of credit card
		// (Such as Visa, Mastercard, etc) when the
		// paymentType is CreditCard or
		// CreditCardNotPresent.`
		this.creditCardType = creditCardType;
	}

	static fromBackoffice(data) {
		const result = new WSPaymentHistoryCreditDebitInfo(data);

		return merge(
			result,
			super.fromBackoffice(data)
		);
	}

	toBackoffice() {
		const result = clone(this);

		return merge(
			result,
			super.toBackoffice()
		);
	}

	toResolver() {
		const forGraphql = clone(this);

		return merge(
			forGraphql,
			super.toResolver()
		);
	}
}

export const WSPaymentHistoryCreditDebitInfoType = [ `
	type WSPaymentHistoryCreditDebitInfo implements WSPaymentHistoryInfoFactory {
		${WSPaymentHistoryInfoFragment}
		maskedPAN: String @deprecated(reason: "Prefer maskedPan instead")
		maskedPan: String
		creditCardType: String
	}`,
];
