import { reduce } from 'lodash';
import React, { useEffect } from 'react';

export const AddressHiddenFields = ({
	wsAddressExt,
	getFieldName,
	formHelper,
}) => {
	// if inputs change, rewire formhelper
	useEffect(() => {
		formHelper.wireInputs();
	}, [
		wsAddressExt,
		getFieldName,
		formHelper,
	]);

	// we want to omit 'primaryShipping' and 'primaryBilling'
	// these are applicable to a new address form
	// here we are using an already saved address
	const {
		primaryShipping,
		primaryBilling,
		...filteredWsAddressExt
	} = wsAddressExt;

	return reduce(filteredWsAddressExt, (accumulator, value, fieldName) => {
		if (value === null) {
			return accumulator;
		}

		return [
			...accumulator,
			<input
				key={`${getFieldName(fieldName)}-hidden`}
				type="hidden"
				name={`${getFieldName(fieldName)}`}
				value={value || ''}
			/>,
		];
	}, []);
};
