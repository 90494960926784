import { sampleWsSubsystemAccountInfo } from './Token.js';
import {
	first,
} from "lodash";

const {
	subsystemAccountReference,
	subsystemAccountDetailedInfo: {
		tokens,
	},
} = sampleWsSubsystemAccountInfo;

export const match = {
	params: {
		token_id: first(tokens).tokenId,
		transit_account_id: subsystemAccountReference,
	},
};



export const sampleGroups = [
	{
		id: 1234567890,
		name: "Boston University Student Transit",
		joined: '08/18',
		expires: '08/19',
	},
];
