import React, {
	useState,
	useEffect,
} from 'react';
import cx from 'classnames';
import {
	map,
	filter,
	startsWith,
	isEmpty,
	find,
	findIndex,
} from 'lodash';

import Button, { Primary, Secondary } from 'components/Button.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import Input from 'components/forms/Input.js';
import { useCartContext, getCartBalance } from 'context/CartProvider.js';
import PreventDefault from 'utils/PreventDefault.js';
import RadioInput from 'components/forms/inputs/RadioInput.js';
import FormHelper from 'utils/FormHelper.js';
import { castNumberStringToNumber, validateFormData , balanceSelectionValidation } from './helpers.js';
import { cms } from './ReloadBalance.js';
import { useFundingSourcesQueryContext } from 'components/account/reload-balance/FundingSourcesQueryProvider.js';
import { useAutoloadContext } from "context/AutoloadContext.js";
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import {
	object as yup_object,
} from 'yup';
import { yupErrorMiddleware } from 'utils/error-handling/yupMiddleware.js';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import * as formStyle from 'components/forms/Forms.module.css';
import * as controlStyle from 'components/forms/Control.module.css';
import * as reloadBalanceStyle from './ReloadBalance.module.css';
import * as modalStyles from 'components/Modal.module.css';

const ReloadBalanceOptions = ({
	valueIncrement,
	maxAddTransitValue,
	minAddTransitValue,
	transitValueOptions,
	kickoffSubmit,
	cancelModal,
	formHelper,
	isPurchaseNewProductFlow,
	setCustomAmt,
	customAmt,
	setChosenDisplayAmount,
	multiStep = true,
	optionsModalOpen,
}) => {
	const { purseTotal } = usePurseBalanceContext();
	const { cart, addBalance } = useCartContext();

	const [ selectCustom, setSelectCustom ] = useState(false);
	const [ selectedOption, setSelectedOption ] = useState(null);
	const { getFundingSources } = useFundingSourcesQueryContext();

	const { autoloads } = useAutoloadContext();
	const { currentSubscriptionInfo } = autoloads ?? {};
	const filteredOptions = filter(transitValueOptions, ({ name }) => startsWith(name, "$"));

	const cartBalance = getCartBalance(cart);

	const onBlurRadioSelected = async (amount) => {
		try {
			// testing that the current amount selected won't take the total balance past the limit
			const schema = yup_object().shape({
				'storedValue[]': balanceSelectionValidation({
					minValue: minAddTransitValue,
					maxValue: maxAddTransitValue,
					cartBalance,
					purseTotal,
				}),
			});

			await yupErrorMiddleware(
				schema.validate({ 'storedValue[]': amount }, {
					abortEarly: false,
				}),
			);

		} catch (errorReport) {
			throw errorReport;
		}
	};

	const onSelectStoredAmountRadio = async (amount, idx) => {
		setSelectedOption(idx);
		setSelectCustom(false);

		if (!isPurchaseNewProductFlow && !currentSubscriptionInfo) {
			try {
				await onBlurRadioSelected(amount);
			}
			catch (errorReport) {
				formHelper.validationErrorHandler(errorReport);
				noticeError(null, levels.info, errorReport, 'Stored value options validation failed');
				return;
			}

			// Update the balance with a new amount, prev amount will be overwritten
			// can perserve by passing false as the the secong arg
			addBalance(amount);

			// on stored value selected amount select/change
			// update the available saved payment methods if any
			getFundingSources({ amount, formHelper });
		}

		customAmt && setCustomAmt("");
		formHelper.clearAllErrors();
	};

	const onSelectCustomAmountRadio = () => {
		!isPurchaseNewProductFlow && addBalance(0);
		setSelectCustom(true);
	};

	// Whenever the balance changes from user input we want to reflect that change on the new display total
	useEffect(() =>
		setChosenDisplayAmount(cartBalance)
	,[ cartBalance, setChosenDisplayAmount ]);

	// update autoload useEffect
	useEffect(() => {
		if (!isEmpty(currentSubscriptionInfo)) {
			const autoloadValue = currentSubscriptionInfo?.autoload?.value;
			// current autoload - selected stored amount radio options
			if (!isEmpty(find(filteredOptions, { 'amount': autoloadValue }))) {
				// initial selected amount option mounting
				// update radio option
				// change custom amount -> selected radio
				// selected radio -> custom amount
				if (!selectCustom) {
					const idx = selectedOption ?? findIndex(filteredOptions, { 'amount': autoloadValue });
					setSelectedOption(idx);
				}
				// current autoload - custom amount radio option
			} else {
				// selectedOption === null when initial custom amount mounting
				// selectedOption < 0 to allow choosing radio option when update custom amount -> first radio (selectedOption = 0)
				if (selectedOption < 0 || selectedOption === null) {
					setSelectCustom(true);
					setCustomAmt(autoloadValue);
				}
			}
		}
	}, [
		cartBalance,
		selectedOption,
		setSelectedOption,
		setCustomAmt,
		selectCustom,
		setSelectCustom,
		filteredOptions,
		currentSubscriptionInfo,
	]);

	const onChangeCustomAmount = ({ target: { value } }) => {
		onSelectCustomAmountRadio();
		setCustomAmt(castNumberStringToNumber(value));
	};

	const onBlurCustomAmount = async () => {
		const validated = await validateFormData({
			purseTotal,
			valueIncrement,
			maxAddTransitValue,
			minAddTransitValue,

			// We are in the balance-only purchase flow
			// the cart will always be cleared before validation
			cartBalance: 0,
			validateAllFields: true,
			formHelper,
		});

		if (validated) {
			// Update the balance with a new amount, prev amount will be overwritten
			// can perserve by passing false as the the secong arg
			!isPurchaseNewProductFlow && addBalance(validated.storedValueOther, !isPurchaseNewProductFlow);
			formHelper.clearAllErrors();
		}
	};

	const errorMessage = formHelper.getFieldError("storedValue[]") || formHelper.getFieldError("storedValue[][0]") || formHelper.getFieldError("storedValueOther");
	const parentFormId = formHelper.options.formRef.current?.id;

	return (
		<div className={cx(reloadBalanceStyle.valuesList, !multiStep && reloadBalanceStyle.singleForm)}>
			<div className={cx(formStyle.control, formStyle.radioGridBox)}>{map(filteredOptions, ({ id, amount }, idx) => (
				<div key={id} className={cx(reloadBalanceStyle.valuesContainer, reloadBalanceStyle.radioFocus)}>
					<RadioInput
						id={id + "storedAmount"}
						name={'storedValue[]'}
						key={`amount${id}`}
						label={centsToDisplay(amount)}
						checked={!selectCustom && selectedOption === idx}
						onClick={() => onSelectStoredAmountRadio(amount, idx)}
						onChange={() => { }}
						value={amount}
						additionalClasses={reloadBalanceStyle.radioInput}
						{...(parentFormId) ? { form: parentFormId } : {}}
						controlled
					/>
				</div>
			))}</div>

			<div className={reloadBalanceStyle.modalSection}>
				<CmsContentRenderer
					elementType="label"
					htmlFor="storedValueOtherOption"
					contentKey={cms.enterAmount}
					fallbackValue="Enter amount"
					className={reloadBalanceStyle.customAmtHeader}
				/>
				<div className={cx(reloadBalanceStyle.valuesContainer, reloadBalanceStyle.custom)}>
					<RadioInput
						checked={selectCustom}
						id="storedValueOtherOption"
						label={<CmsContentRenderer.P
							className={controlStyle.label}
							contentKey={cms.otherAmountLabel}
							fallbackValue="Other"
						/>}
						name="storedValueOtherOption"
						additionalClasses={cx(reloadBalanceStyle.radioInput, reloadBalanceStyle.otherAmountRadio)}
						onClick={onSelectCustomAmountRadio}
						onChange={() => { }}
						{...(parentFormId) ? { form: parentFormId } : {}}
					/>
					<Input
						overrideClass={reloadBalanceStyle.customInput}
						hideLabel
						name="storedValueOther"
						controlled={true}
						value={customAmt ? centsToDisplay(customAmt) : ""}
						onBlur={PreventDefault(onBlurCustomAmount)}
						placeholder={centsToDisplay(maxAddTransitValue)}
						onChange={onChangeCustomAmount}
						{...(parentFormId) ? { form: parentFormId } : {}}
					/>
				</div>
				{errorMessage && <div className={cx(reloadBalanceStyle.customAmtText, reloadBalanceStyle.customAmtError)}>
					{FormHelper.errorJsx(errorMessage)}
				</div>}
				<div className={reloadBalanceStyle.customAmtText}>
					<CmsContentRenderedInline
						contentKey={cms.purchaseBalanceLimitsDescription}
						fallbackValue={`You may add up to ${centsToDisplay(maxAddTransitValue)} for this purchase.`}
						variables={{ amount: centsToDisplay(maxAddTransitValue) }}
					/>
				</div>

				{isPurchaseNewProductFlow &&
					<div className={cx(modalStyles.actionButtons, optionsModalOpen ? null : reloadBalanceStyle.btnWrapper)}>
						<Button
							type="button"
							typeStyle={Primary}
							onClick={kickoffSubmit}
							additionalClassNames={cx(modalStyles.btn, modalStyles.leftButton)}
						>
							<CmsContentRenderer
								contentKey={cms.addToCart}
								fallbackValue="Add to Cart"
							/>
						</Button>
						<Button
							theme={Secondary}
							onClick={cancelModal}
							additionalClassNames={cx(modalStyles.btn, modalStyles.rightButton)}
						>
							<CmsContentRenderer
								contentKey={cms.cancelPurchase}
								fallbackValue="Cancel"
							/>
						</Button>
					</div>
				}
			</div>
		</div>
	);
};

export default ReloadBalanceOptions;
