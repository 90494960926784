import React, {
	useRef,
} from 'react';
import PropTypes from "prop-types";
import cx from "classnames";

import Control from "./Control.js";
import { SmallCheck } from '../Icon.js';

import * as checkbox from './Checkbox.module.css';
import * as forms from "./Forms.module.css";



const Checkbox = ({
	labelFirst = true,
	hideLabel = true,
	overrideClass = '', //legacy use `additionalClasses`
	additionalClasses,
	tabIndex = 0,
	label = '',
	name,
	locked = false,

	//legacy use `error`
	errorMsg = null,
	error = null,
	id = null,

	handleOnChange = null,
	inputRef,
	...rest
}) => {

	const localRef = useRef();

	const getInputRef = () => {
		return inputRef
			? inputRef
			: localRef;
	};

	const getRefProp = () => {
		return {
			ref: getInputRef(),
		};
	};

	const handleOnKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			const { ref: { current } } = getRefProp();
			current.checked = !current.checked;
		}
	};


	const getAriaLabel = () => {
		return hideLabel && !(typeof label === 'object')
			? { [ 'aria-label' ]: label }
			: null;
	};

	const getLabel = () => {
		return typeof label === 'object'
			? label
			: <p className={cx(forms.label, checkbox.labelText)}>{label}</p>;
	};

	const ariaLabel = getAriaLabel();
	const refProp = getRefProp();



	return (
		<Control {...{
			overrideClass, //legacy use `additionalClasses`
			additionalClasses,
			label,
			hideLabel,
			labelFirst,
			error,
			errorMsg,
		}}
		target={id ? id : name}
		>
			<div className={checkbox.container}>
				<label className={checkbox.labelWrapper}>
					<input type="checkbox"
						className={checkbox.input}
						name={name}
						id={id ? id : name}
						onChange={handleOnChange}
						onKeyPress={handleOnKeyPress}
						{...ariaLabel}
						{...refProp}
						{...rest}
					/>
					<div className={cx(checkbox.iconWrapper, locked && checkbox.locked)}>
						<SmallCheck overrideClass={checkbox.checkboxIcon} aria-hidden={true} />
					</div>

					{label && getLabel()}

				</label>
			</div>
		</Control>
	);
};

/**
 * This will work around the problem noted here:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#attr-checked
 * Namely, the checkboxes that are unchecked will not show up as fields in the FormData object.
 */
export const BoolCheckbox = (props) => {
	return (
		<>
			<input type="hidden" value={false} name={props.name} />
			<Checkbox value={true} {...props} />
		</>
	);
};

Checkbox.propTypes = {
	/**	Id for the control */
	name: PropTypes.string.isRequired,

	/** Hide default label and use `aria-label` instead */
	hideLabel: PropTypes.bool,

	/** Label the control */
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),

	labelFirst: PropTypes.bool,

	/** Display the error state */
	error: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
	]),

	/** Custom classes to override styling */
	overrideClass: PropTypes.string,

	tabIndex: PropTypes.number,

	inputRef: PropTypes.any,

	/** Set value if control */
	value: PropTypes.any,
};

export default Checkbox;
