// extracted by mini-css-extract-plugin
export var answerLabel = "components-account-panels-__SecurityInformation-module___answerLabel";
export var answerTopMargin = "components-account-panels-__SecurityInformation-module___answerTopMargin";
export var createPassButton = "components-account-panels-__SecurityInformation-module___createPassButton";
export var createPasswordLabel = "components-account-panels-__SecurityInformation-module___createPasswordLabel";
export var modalActionRow = "components-account-panels-__SecurityInformation-module___modalActionRow";
export var passwordPinLabel = "components-account-panels-__SecurityInformation-module___passwordPinLabel";
export var passwordPinLabelWrapper = "components-account-panels-__SecurityInformation-module___passwordPinLabelWrapper";
export var passwordPinRow = "components-account-panels-__SecurityInformation-module___passwordPinRow";
export var pinValue = "components-account-panels-__SecurityInformation-module___pinValue";
export var securityHeading = "components-account-panels-__SecurityInformation-module___securityHeading";
export var securityQsEditButton = "components-account-panels-__SecurityInformation-module___securityQsEditButton";
export var securityQsHeaderWrapper = "components-account-panels-__SecurityInformation-module___securityQsHeaderWrapper";
export var securityQsList = "components-account-panels-__SecurityInformation-module___securityQsList";
export var toolTip = "components-account-panels-__SecurityInformation-module___toolTip";
export var twoFactorToggle = "components-account-panels-__SecurityInformation-module___twoFactorToggle";
export var twoFactorTooltip = "components-account-panels-__SecurityInformation-module___twoFactorTooltip";