import { map } from 'lodash';
import WSLoadSubsystemProduct from "./WSLoadSubsystemProduct.js";
import WSLoadSubsystemAccountValue from "./WSLoadSubsystemAccountValue.js";

import WSSubsystemOperator from './WSSubsystemOperator.js';
import WSLoadProduct, { WSLoadProductFragment } from "./WSLoadProduct.js";
import { levels, log } from "utils/Logger.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

// 1.1.61 WSLoadProduct

export const types = {
	loadSubsystemProduct: "LoadSubsystemProduct",
	loadSubsystemAccountValue: "LoadSubsystemAccountValue",
};

const typeToClass = (type) => {
	switch (type) {
		case types.loadSubsystemProduct:
			return "WSLoadSubsystemProduct";
		case types.loadSubsystemAccountValue:
			return "WSLoadSubsystemAccountValue";
		default:
			log(null, levels.error, `typeToClass: unknown  WSLoadProduct type ${type}`);
			return "WSLoadProduct";
	}
};

export default class WSLoadProductFactory {
	static buildClass(data) {
		switch (data.productLineItemType) {
			case types.loadSubsystemProduct:
				return new WSLoadSubsystemProduct({
					...data,
					permittedUsers: map(data.permittedUsers, wsSubsystemOperator => new WSSubsystemOperator(wsSubsystemOperator)),
				});
			case types.loadSubsystemAccountValue:
				return new WSLoadSubsystemAccountValue(data);
			default:
				log(null, levels.error, `unknown  WSLoadProduct type ${data.productLineItemType}`);
				return new WSLoadProduct(data);
		}
	}

	static fromBackoffice(data) {
		switch (data.productLineItemType) {
			case types.loadSubsystemProduct:
				return WSLoadSubsystemProduct.fromBackoffice(data);
			case types.loadSubsystemAccountValue:
				return WSLoadSubsystemAccountValue.fromBackoffice(data);
			default:
				log(null, levels.error, `unknown  WSLoadProduct type ${data.productLineItemType}`);
				return WSLoadProduct.fromBackoffice(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSLoadProductFactoryInterface = [ `
	interface WSLoadProductFactory {
		${WSLoadProductFragment}
	}`,
];

export const WSLoadProductFactoryTypes = [
	...WSLoadProductFactoryInterface,
];

export const WSLoadProductFactoryResolvers = {
	WSLoadProductFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType({ productLineItemType }) {
			return typeToClass(productLineItemType);
		},
	},
};
