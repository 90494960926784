import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal.js';
import Button from '../Button.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import {
	values,
} from "lodash";

import * as buttonStyles from 'components/Button.module.css';
import * as langConfirmationStyles from 'components/modals/LanguageConfirmation.module.css';

const cms = {
	header: "miscText.preferences-language-change-header",
	description: "miscText.preferences-language-change-description",
	cancel: "miscText.preferences-language-change-cancel",
	submit: "miscText.preferences-language-change-submit",
};

const LanguageConfirmation = ({
	modalActive,
	onCancelLanguageSelection,
	onConfirmLanguageSelection,
	currentlang,
	selectedlang,
}) => (
	<CmsContentList list={values(cms)} languageOverride={currentlang}>{({ cmsContent: cmsContentCurrentLang }) => (
		<CmsContentList list={values(cms)} languageOverride={selectedlang}>{({ cmsContent: cmsContentSelectedLang }) => (
			<Modal
				title={<>
					<CmsContentRenderer.Span cmsContent={cmsContentSelectedLang} contentKey={cms.header} fallbackValue={`[ ${selectedlang}: Confirm Language Change ]`} />
					<CmsContentRenderer.Span className={langConfirmationStyles.lowerTitleText} cmsContent={cmsContentCurrentLang} contentKey={cms.header} fallbackValue="Confirm Language Change" />
				</>}
				modalActive={modalActive}
				overrideCloseAriaText={`${findContentOrFallback(cmsContentSelectedLang, cms.cancel, `[ ${selectedlang}: Cancel ]`)} / ${findContentOrFallback(cmsContentCurrentLang, cms.cancel, `Cancel `)} ` }
				onModalClose={onCancelLanguageSelection}
				overrideClass={langConfirmationStyles.header}
			>
				<CmsContentRenderer.P cmsContent={cmsContentSelectedLang} contentKey={cms.description} fallbackValue={`[ ${selectedlang}: This will change your preferred language on this website and other MBTA devices. Confirm you want to proceed with this change. ]`} />
				<CmsContentRenderer.P cmsContent={cmsContentCurrentLang} contentKey={cms.description} fallbackValue={'This will change your preferred language on this website and other MBTA devices. Confirm you want to proceed with this change.'} />
				<div className={buttonStyles.wrapper}>
					<Button
						onClick={onCancelLanguageSelection}
						additionalClassNames={buttonStyles.flexOne} >
						<div className={langConfirmationStyles.buttonText}>
							<CmsContentRenderer.Span cmsContent={cmsContentSelectedLang} contentKey={cms.cancel} fallbackValue={`[ ${selectedlang}: Cancel ]`} />
							{' / '}
							<CmsContentRenderer.Span cmsContent={cmsContentCurrentLang} contentKey={cms.cancel} fallbackValue="Cancel" />
						</div>
					</Button>
					<Button
						onClick={() => onConfirmLanguageSelection(selectedlang, currentlang)}
						additionalClassNames={buttonStyles.flexOne} >
						<div className={langConfirmationStyles.buttonText}>
							<CmsContentRenderer.Span cmsContent={cmsContentSelectedLang} contentKey={cms.submit} fallbackValue={`[ ${selectedlang}: Confirm ]`} />
							{' / '}
							<CmsContentRenderer.Span cmsContent={cmsContentCurrentLang} contentKey={cms.submit} fallbackValue="Confirm" />
						</div>
					</Button>
				</div>
			</Modal>
		)}</CmsContentList>
	)}</CmsContentList>
);

LanguageConfirmation.propTypes = {
	modalActive: PropTypes.bool,
	onCancelLanguageSelection: PropTypes.func.isRequired,
	onConfirmLanguageSelection: PropTypes.func.isRequired,
	currentlang: PropTypes.string.isRequired,
	selectedlang: PropTypes.string.isRequired,
};

export default LanguageConfirmation;
