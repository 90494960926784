import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
	map,
	keys,
	values,
} from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from "components/data/CmsContentRenderer.js";

import { SmallCheck } from 'components/Icon.js';

import * as login from 'pages/auth/Login.module.css';
import * as forms from 'components/forms/Forms.module.css';


const cms = {
	requirementsHeader: "miscText.register-email-password-req-header",
	requirementsDesc: "miscText.register-email-password-req-description",
	minChars: "miscText.register-email-password-req-chars",
	capital: "miscText.register-email-password-req-upper",
	lower: "miscText.register-email-password-req-lower",
	number: "miscText.register-email-password-req-num",
	special: "miscText.register-email-password-req-special",
};


const validateExpression = inputIsValid => {
	return inputIsValid ? login.check : cx(login.check, login.isNotValid);
};

// We want the ScreenReaderOnlyReqs component to serve the purpose of
// aria-labelledby but not to be visible by the SR, otherwise this component recieves focus
const ScreenReaderOnlyReqs = () => {
	return (
		<div aria-hidden id="passwordRequirements" className={forms.srOnly}>
			{map(keys(cms), (req, index) => <CmsContentRenderer
				key={`${index}-${req}`}
				elementType={'span'}
				contentKey={cms[ req ]}
			/>)}
		</div>
	);
};


const PasswordValidation = ({
	password,
	overrideClass,
}) => (
	<CmsContentList list={values(cms)}>{() => (
		<>
			<ScreenReaderOnlyReqs />
			<div
				className={cx(login.validationContainer, overrideClass)}
				data-qa="RegisterFormValidationList"
				aria-hidden="true"
			>
				<div className={login.validationBox}>
					<CmsContentRenderer
						elementType={'b'}
						contentKey={cms.requirementsHeader}
						fallbackValue="Password requirements:"
						className={login.validationHeader}
					/>
					<div className={login.validationLine}>
						<SmallCheck overrideClass={validateExpression(password.length > 9)} aria-hidden={true} />
						<CmsContentRenderer.Span
							contentKey={cms.minChars}
							fallbackValue="Minimum 10 characters"
							className={login.validationText}
						/>
					</div>
					<div className={login.validationLine}>
						<CmsContentRenderer.Span
							contentKey={cms.requirementsDesc}
							fallbackValue="At least 3 of the following:"
							className={login.conditionalText}
						/>
					</div>
					<div className={login.validationLine}>
						<SmallCheck overrideClass={validateExpression(password.match(/[A-Z]/))} aria-hidden={true} />
						<CmsContentRenderer.Span
							contentKey={cms.capital}
							fallbackValue="At least one capital letter"
							className={login.validationText}
						/>
					</div>
					<div className={login.validationLine}>
						<SmallCheck overrideClass={validateExpression(password.match(/[a-z]/))} aria-hidden={true} />
						<CmsContentRenderer.Span
							contentKey={cms.lower}
							fallbackValue="At least one lower case letter"
							className={login.validationText}
						/>
					</div>
					<div className={login.validationLine}>
						<SmallCheck overrideClass={validateExpression(password.match(/\d/))} aria-hidden={true} />
						<CmsContentRenderer.Span
							contentKey={cms.number}
							fallbackValue="At least one number"
							className={login.validationText}
						/>
					</div>

					<div className={login.validationLine}>
						<SmallCheck overrideClass={validateExpression(password.match(/[!@#$%^]/))} aria-hidden={true} />
						<CmsContentRenderer.Span
							contentKey={cms.special}
							fallbackValue="At least one of these characters: ! @ # $ % ^"
							className={login.validationText}
						/>
					</div>
				</div>
			</div>
		</>
	)}</CmsContentList>
);

PasswordValidation.propTypes = {
	password: PropTypes.string.isRequired,
	overrideClass: PropTypes.string,
};

PasswordValidation.defaultProps = {
	overrideClass: "",
};

export default PasswordValidation;
