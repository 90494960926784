import { gql } from "@apollo/client";

export const GET_TWO_FACTOR_DELIVERY_OPTIONS = gql`
    query twoFactorDeliveryOptions {
        twoFactorDeliveryOptions {
            maskedDestination
            channel
        }
    }
`;

export const POST_TWO_FACTOR_DELIVERY_OPTIONS = gql`
    mutation postTwoFactorDeliveryOptions(
        $deliveryOption: InputWSAuthCodeDeliveryOption!
    ) {
        postTwoFactorDeliveryOptions(deliveryOption: $deliveryOption)
    }
`;

export const VERIFY_TWO_FACTOR_CODE = gql`
    mutation verifyTwoFactorCode($verificationToken: String!) {
        verifyTwoFactorCode(verificationToken: $verificationToken)
    }
`;