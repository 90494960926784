import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useCartContext, getCartBalance } from 'context/CartProvider.js';
import PreventDefault from 'utils/PreventDefault.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { OPEN_TRANSIT_REGULAR_CARD } from 'server/api-types/WSSubsystemAccountToken.js';

import Button, { Ghost } from 'components/Button.js';
import { Close } from 'components/Icon.js';
import UpdateCart from './UpdateCart.js';
import { useFormHelperContext } from 'utils/form-helper/FormHelperProvider.js';
import Input from 'components/forms/Input.js';

import * as cartStyle from 'components/payments/Cart.module.css';

const cms = {
	charlieCard: 'miscText.purchase-cart-cards',
	amount: 'miscText.purchasehistory-table-amount',
};

const Item = ({
	id,
	name,
	amount,
	price,
	amountEditable = true,
	quantity,
	disableDelete,
	isIncludeAmountFromFareBased = false,
	mediaType = "",
}) => {

	const { formHelper } = useFormHelperContext();

	const {
		cart,
		removeProduct,
		removeFareBasedProduct,
		addBalance,
	} = useCartContext();

	const purchaseStoredValueAmount = getCartBalance(cart);

	const handleRemoveItem = () => {
		if (amountEditable && purchaseStoredValueAmount && !id) {
			// Update the balance with a new amount, prev amount will be overwritten
			// can perserve by passing false as the the secong arg
			addBalance(0);
			// fare based product included to balance, so we should remove it also
			if (isIncludeAmountFromFareBased) {
				removeFareBasedProduct();
			}
			return;
		}

		removeProduct(id);
	};

	return (
		<>
			<div className={cartStyle.itemWrapper}>
				<div className={cx(cartStyle.column, cartStyle.itemName)}>
					<div className={cx(cartStyle.itemNameLabel, mediaType === OPEN_TRANSIT_REGULAR_CARD && cartStyle.indentItem)}>
						{!disableDelete && (
							<Button
								type="button"
								typeStyle={Ghost}
								additionalClassNames={cartStyle.removeIconWrapper}
								onClick={PreventDefault(handleRemoveItem)}
							>
								<Close overrideClass={cartStyle.removeIcon} aria-hidden />
							</Button>
						)}
						{mediaType === OPEN_TRANSIT_REGULAR_CARD
							? <CmsContentRenderedInline
								contentKey={cms.charlieCard}
								fallbackValue="Charlie Cards"
							/>
							: name}
						<div className={cx(cartStyle.column, cartStyle.itemPrice, cartStyle.desktopHidden)}>
							{centsToDisplay(quantity ? (quantity * price) : price)}
						</div>
					</div>

					{/* MOBILE Quantity Editor and Display */}
					<div className={cx(cartStyle.desktopHidden)}>
						{amountEditable
							? <UpdateCart {...{ quantity }} productId={id} />
							: <>
								<div className={cx(cartStyle.column)} />
								<div className={cx(cartStyle.column, cartStyle.amount)}>
									<CmsContentRenderedInline
										contentKey={cms.amount}
										fallbackValue="Amount:"
										className={cartStyle.mobileItemLabel}
									/>
									<span>{quantity ?? amount}</span>
								</div>
								<div className={cx(cartStyle.column, cartStyle.itemPrice, cartStyle.mobileHidden)}>
									{centsToDisplay(quantity ? (quantity * price) : price)}
								</div>
							</>}
					</div>

				</div>


				{/* DESKTOP Quantity Editor and Display */}
				{amountEditable
					? <UpdateCart additionalClassNames={cartStyle.mobileHidden} {...{ quantity }} productId={id} />
					: <>
						<div className={cx(cartStyle.column, cartStyle.mobileHidden)} />
						<div className={cx(cartStyle.column, cartStyle.amount, cartStyle.mobileHidden)}>
							{quantity ? quantity : amount}
						</div>
					</>
				}

				<div className={cx(cartStyle.column, cartStyle.itemPrice, cartStyle.mobileHidden)}>
					{centsToDisplay(quantity ? (quantity * price) : price)}
				</div>
			</div>
			<Input
				type="hidden"
				error={formHelper.getFieldError('quantity')}
			/>
			<Input
				type="hidden"
				error={formHelper.getFieldError('balanceAmount')}
			/>
		</>
	);
};

Item.propTypes = {
	id: PropTypes.number,

	name: PropTypes.node,

	amount: PropTypes.number,

	price: PropTypes.number,

	amountEditable: PropTypes.bool,

	quantity: PropTypes.string,

	disableDelete: PropTypes.bool,
};

export default Item;
