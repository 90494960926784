import React from 'react';

import tempCharlieCard from 'components/account/assets/tempCharlieCard.svg';

// Desktop
import amex from 'components/assets/large/card-American-Express.svg';
import discover from 'components/assets/large/card-Discover.svg';
import generic from 'components/assets/large/card-Generic.svg'; // asset missing
import charliecard from 'components/assets/large/card-Charlie.svg';
import mastercard from 'components/assets/card-MasterCard.svg';
import visa from 'components/assets/large/card-Visa.svg';

// Desktop Inactive
import amexInactive from 'components/assets/large/card-American-Express-inactive.svg';
import charliecardInactive from 'components/assets/large/card-Charlie-inactive.svg';
import mastercardInactive from 'components/assets/large/card-MasterCard-inactive.svg';
import visaInactive from 'components/assets/large/card-Visa-inactive.svg';

// Mobile
import visaMobile from 'components/assets/large/card-Visa-mobile.svg';
import discoverMobile from 'components/assets/large/card-Discover-mobile.svg';
import mastercardMobile from 'components/assets/large/card-MasterCard-mobile.svg';
import americanExpressMobile from 'components/assets/large/card-American-Express-mobile.svg';
import charliecardMobile from 'components/assets/large/card-Charlie-mobile.svg';

// Mobile Inactive
import visaMobileInactive from 'components/assets/large/card-Visa-mobile-inactive.svg';
import discoverMobileInactive from 'components/assets/large/card-Discover-mobile-inactive.svg';
import mastercardMobileInactive from 'components/assets/large/card-MasterCard-mobile-inactive.svg';
import americanExpressMobileInactive from 'components/assets/large/card-American-Express-mobile-inactive.svg';
import charliecardMobileInactive from 'components/assets/large/card-Charlie-mobile-inactive.svg';

import contactlessCardIcon from 'components/assets/contactless-card.svg';

// Guest versions
import guestCharlieCard from 'components/assets/guest-Charlie.svg';
import guestGenericCard from 'components/assets/guest-Generic.svg';
import { CARD_TYPE_CHARLIE, TOKEN } from 'utils/Constants';
import DropdownCharlie from './cards/DropdownCharlie';
import DropdownContactless from './cards/DropdownContactless';

import { TRAVEL_TOKEN_TYPES } from 'server/api-types/WSSubsystemAccountTravelTokenDisplayFactory';

const discoverInactive = discover;

export const CharlieCard = "CharlieCard";
export const CharlieCardInactive = "CharlieInactive";

export const GenericCard = "Generic";

export const CREDIT_CARD_TYPES = {
	AmericanExpress: 'American Express',
	Mastercard: 'Mastercard',
	Visa: 'Visa',
};

/**
 * Returns an img tag instead of svg code for a specific brand
 */
export const CardImage = ({
	creditCardType,
	mobileWalletType = null,
	status = TOKEN.STATUS.active,
	...props
}) => {

	// https://reflexions.slack.com/archives/CCF68M49M/p1703018194686389
	// Sometimes the BO passes us Unknown from the credit card vendor and we should still use the creditCardType

	if (status === TOKEN.STATUS.active) {
		if (mobileWalletType) {
			const mobile_mapping = {
				Visa: <img src={visaMobile} alt={creditCardType} {...props} />,
				MasterCard: <img src={mastercardMobile} alt={creditCardType} {...props} />,
				Mastercard: <img src={mastercardMobile} alt={creditCardType} {...props} />,
				"American Express": <img src={americanExpressMobile} alt={creditCardType} {...props} />,
				AmEx: <img src={americanExpressMobile} alt={creditCardType} {...props} />,
				Amex: <img src={americanExpressMobile} alt={creditCardType} {...props} />,
				Discover: <img src={discoverMobile} alt={creditCardType} {...props} />,
				[ CharlieCard ]: <img src={charliecardMobile} alt={creditCardType} {...props} />,
				Smartcard: <img src={charliecardMobile} alt={creditCardType} {...props} />,
			};
			return mobile_mapping[ creditCardType ] ?? <img src={charliecardMobile} alt={creditCardType} {...props} />;
		}

		const mapping = {
			Visa: <img src={visa} alt={creditCardType} {...props} />,
			VISA: <img src={visaMobile} alt={creditCardType} {...props} />,
			MasterCard: <img src={mastercard} alt={creditCardType} {...props} />,
			Mastercard: <img src={mastercard} alt={creditCardType} {...props} />,
			MASTERCARD: <img src={mastercard} alt={creditCardType} {...props} />,
			"American Express": <img src={amex} alt={creditCardType} {...props} />,
			AmEx: <img src={amex} alt={creditCardType} {...props} />,
			Amex: <img src={amex} alt={creditCardType} {...props} />,
			Discover: <img src={discover} alt={creditCardType} {...props} />,
			[ CharlieCard ]: <img src={charliecard} alt={creditCardType} {...props} />,
			Smartcard: <img src={charliecard} alt={creditCardType} {...props} />,
			[ TRAVEL_TOKEN_TYPES.openTransitTemporary ]: <img src={tempCharlieCard} alt={creditCardType} {...props} />,
		};

		return mapping[ creditCardType ] ?? <img src={generic} alt={creditCardType} data-bad-creditcardtype={JSON.stringify(creditCardType)} {...props} />;
	};

	if (mobileWalletType) {
	//  if mobileWalletType has a defined value or “Unknown” then we have a device.
	//  We know its a card if mobileWalletType  is missing altogether.
		const mobile_mapping = {
			Visa: <img src={visaMobileInactive} alt={creditCardType} {...props} />,
			VisaInactive: <img src={visaMobileInactive} alt={creditCardType} {...props} />,

			MasterCard: <img src={mastercardMobileInactive} alt={creditCardType} {...props} />,
			Mastercard: <img src={mastercardMobileInactive} alt={creditCardType} {...props} />,
			MasterCardInactive: <img src={mastercardMobileInactive} alt={creditCardType} {...props} />,
			MastercardInactive: <img src={mastercardMobileInactive} alt={creditCardType} {...props} />,

			"American Express": <img src={americanExpressMobileInactive} alt={creditCardType} {...props} />,
			AmEx: <img src={americanExpressMobileInactive} alt={creditCardType} {...props} />,
			Amex: <img src={americanExpressMobileInactive} alt={creditCardType} {...props} />,
			AmExInactive: <img src={americanExpressMobileInactive} alt={creditCardType} {...props} />,

			Discover: <img src={discoverMobileInactive} alt={creditCardType} {...props} />,
			DiscoverInactive: <img src={discoverMobileInactive} alt={creditCardType} {...props} />,

			[ CharlieCard ]: <img src={charliecardMobileInactive} alt={creditCardType} {...props} />,
			[ CharlieCardInactive ]: <img src={charliecardMobileInactive} alt={creditCardType} {...props} />,

			Smartcard: <img src={charliecardMobileInactive} alt={creditCardType} {...props} />,
			SmartcardInactive: <img src={charliecardMobileInactive} alt={creditCardType} {...props} />,
		};
		return mobile_mapping[ creditCardType ] ?? <img src={charliecardMobile} alt={creditCardType} {...props} />;
	}

	const mapping = {
		Visa: <img src={visaInactive} alt={creditCardType} {...props} />,
		VISA: <img src={visaInactive} alt={creditCardType} {...props} />,
		VisaInactive: <img src={visaInactive} alt={creditCardType} {...props} />,

		MasterCard: <img src={mastercardInactive} alt={creditCardType} {...props} />,
		Mastercard: <img src={mastercardInactive} alt={creditCardType} {...props} />,
		MASTERCARD: <img src={mastercardInactive} alt={creditCardType} {...props} />,
		MasterCardInactive: <img src={mastercardInactive} alt={creditCardType} {...props} />,
		MastercardInactive: <img src={mastercardInactive} alt={creditCardType} {...props} />,

		"American Express": <img src={amexInactive} alt={creditCardType} {...props} />,
		AmEx: <img src={amexInactive} alt={creditCardType} {...props} />,
		Amex: <img src={amexInactive} alt={creditCardType} {...props} />,
		AmExInactive: <img src={amexInactive} alt={creditCardType} {...props} />,

		Discover: <img src={discoverInactive} alt={creditCardType} {...props} />,
		DiscoverInactive: <img src={discoverInactive} alt={creditCardType} {...props} />,

		[ CharlieCard ]: <img src={charliecardInactive} alt={creditCardType} {...props} />,
		[ CharlieCardInactive ]: <img src={charliecardInactive} alt={creditCardType} {...props} />,
		Smartcard: <img src={charliecardInactive} alt={creditCardType} {...props} />,
		SmartcardInactive: <img src={charliecardInactive} alt={creditCardType} {...props} />,
	};

	return mapping[ creditCardType ] ?? <img src={generic} alt={creditCardType} data-bad-creditcardtype={JSON.stringify(creditCardType)} {...props} />;
};

export const ContactlessCardIcon = ({ mobileWalletTypeProp,...props }) => <img src={contactlessCardIcon}  { ...props} />;

/**
 * Shows a unique card image on the Guest Login for the updated collapsible toggles
 */
export const GuestCard = ({ type, altText, ...props }) =>
	type === CharlieCard
		? <img src={guestCharlieCard} alt={altText} {...props} />
		: <img src={guestGenericCard} alt={altText} {...props} />;


/**
 * Shows a simplified icon in the CardLayout sidebar card selector
 */
export const DropdownCard = ({ type }) =>
	type === CARD_TYPE_CHARLIE
		? <DropdownCharlie />
		: <DropdownContactless />;
