import React from 'react';
import PropTypes from 'prop-types';
import {
	map,
	values,
} from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import {
	useCartContext,
	getCartBalance,
	getCartProducts,
	getCartMediaOption,
	amountFromFareBased,
} from 'context/CartProvider.js';
import Item from './Item.js';

import * as cartStyle from 'components/payments/Cart.module.css';


const cms = {
	amount: 'miscText.purchase-balance-other-label',
	cost: 'miscText.purchase-pass-cost',
	emptyCart: 'miscText.purchase-cart-empty-subheader',
	selectProduct: 'miscText.purchase-cart-empty-description',
	reloadBalance: 'miscText.purchase-header-reload-balance',
	unpaidFee: 'miscText.purchase-cart-enablementfee',
};

const CartItem = ({
	item,
	disableDelete = false,
	amountEditable: amountEditableProp = null,
}) => {
	// Per Jon Wanke(3/28/22):
	// If we have validityStartDtm and validityEndDtm its non-rolling.
	// A non-rolling is for a specific time (like all of April). A rolling pass is good for any 30 days (for example).
	// The rolling pass does not gain validityStartDtm, even after it is in use. It gains startDateTime and endDateTime upon first use.
	// validityStartDtm seems to me to be a reliable indicator of being non-rolling.
	// https://reflexions.slack.com/archives/CCF68M49M/p1648498074833849?thread_ts=1648480908.998749&cid=CCF68M49M
	// https://reflexions.atlassian.net/browse/MBTA-1568
	return (
		<Item
			name={item.productLongName ?? item.nameLong}
			price={item.price}
			id={item.id}
			// if a pass has a set start & end date, you can't add multiples.
			// https://reflexions.atlassian.net/browse/MBTA-1618
			// only enable the user to edit the number of passes in the cart if maxAddPass > 1
			// https://reflexions.atlassian.net/browse/MBTA-2354
			// only check maxAddPass param to determine rolling pass
			// per Kirk (03/02/2023) confirmed https://reflexions.slack.com/archives/CCF68M49M/p1677794910453889?thread_ts=1677788723.276969&cid=CCF68M49M
			amountEditable={amountEditableProp ?? item.maxAddPass > 1}
			quantity={item.quantity}
			disableDelete={disableDelete}
			{...item}
		/>
	);
};

const CartBalance = ({ disableDelete }) => {
	const { cart } = useCartContext();
	const purchaseStoredValueAmount = getCartBalance(cart);
	const isIncludeAmountFromFareBased = amountFromFareBased(cart.products);

	return Boolean(purchaseStoredValueAmount)
		? (
			<Item
				name={<CmsContentRenderer.Span contentKey={cms.reloadBalance} fallbackValue="Reload Balance" />}
				amount={1}
				price={purchaseStoredValueAmount}
				isIncludeAmountFromFareBased={isIncludeAmountFromFareBased}
				{...{ disableDelete }}
				amountEditable={true}
			/>
		)
		: "";
};

const UnpaidFee = () => {
	const { cart } = useCartContext();
	const cartMediaOption = getCartMediaOption(cart);
	const fees = [];

	if (cart.unpaidFee) {
		fees.push(cart.unpaidFee);
	}

	if (cartMediaOption?.enablementFeeAmount) {
		const {
			enablementFeeAmount,
		} = cartMediaOption;

		fees.push(enablementFeeAmount);
	}

	return fees.length
		? map(fees, (fee, index) =>
			<Item
				key={`${fee}-${index}`}
				name={<CmsContentRenderer.Span contentKey={cms.unpaidFee} fallbackValue="Card fee" />}
				amount={1}
				price={fee}
				disableDelete={true}
				amountEditable={false}
			/>,
		)
		: null;
};


const CartListing = ({ disableDelete }) => {

	const { cart } = useCartContext();
	const purchaseStoredValueAmount = getCartBalance(cart);
	const cartMediaOption = getCartMediaOption(cart);
	const cartProducts = [ ...getCartProducts(cart) ];
	const hasUnpaidFee = Boolean(cart.unpaidFee);

	if (
		!cartProducts.length
		&& !cartMediaOption
		&& !purchaseStoredValueAmount
		&& !hasUnpaidFee
	) {
		return (
			<div className={cx(cartStyle.prodSummaryContainer, cartStyle.isEmpty)}>
				<div className={cartStyle.productsSummary}>
					<CmsContentRenderedInline
						elementType="p"
						contentKey={cms.emptyCart}
						fallbackValue="Cart is empty"
						className={cartStyle.productSummaryHeader}
					/>
					<CmsContentRenderedInline
						elementType="p"
						contentKey={cms.selectProduct}
						fallbackValue="Add money or passes to your cart"
						className={cartStyle.productTextSummary}
					/>
				</div>
			</div>
		);
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<div className={cartStyle.cartItemsWrapper} role="row">
				<div className={cartStyle.cartItemsHeader}>
					<div className={cx(cartStyle.columnHeader, cartStyle.name)} />
					<div className={cx(cartStyle.columnHeader)} />
					<CmsContentRenderer.Div
						className={cx(cartStyle.columnHeader, cartStyle.amount)}
						contentKey={cms.amount}
						fallbackValue="Enter Amount"
						role="columnheader"
					/>
					<CmsContentRenderer.Div contentKey={cms.cost}
						className={cx(cartStyle.columnHeader, cartStyle.cost)}
						fallbackValue="Cost"
						role="columnheader"
					/>
				</div>
				{cartMediaOption
					? <CartItem item={cartMediaOption} disableDelete amountEditable />
					: null}
				<UnpaidFee />
				{map(cartProducts, item => <CartItem {...{ item }} />)}
				<CartBalance {...{ disableDelete }} />
			</div>
		)}</CmsContentList>
	);
};

CartListing.propTypes = {
	cart: PropTypes.object,

	disableDelete: PropTypes.bool,
};

export default CartListing;
