import { findPrimaryToken } from "components/manage-cards/TokenHelpers.js";
import { active, suspended } from "utils/Constants.js";
import { getAccountBalance } from "./GetAccountBalance.js";

/**
 * @param   {transitAccountQ} transitAccountQ - transitAccountQ containing purse
 * @returns {boolean} - TRUE if the passed transitAccountQ has a negative balance
 */
export const isNegativeBalance = (transitAccountQ) => {
	const accountBalance = getAccountBalance(transitAccountQ);
	return accountBalance < 0;
};

/**
 * @param   {transitAccountQ} transitAccountQ  - transitAccountQ containing accountStatus and purses
 * @returns {boolean} - TRUE if the passes transitAccountQ is Suspended due to negative balance
 */
export const isNegativeBalanceSuspended = (transitAccountQ) => {
	const { accountStatus } = transitAccountQ;
	return isNegativeBalance(transitAccountQ) && accountStatus === suspended;
};

/**
 * @param   {transitAccountQ} transitAccountQ - transitAccountQ containing accountStatus and purses
 * @returns {boolean} - TRUE if the passed transitAccountQ is Active or Suspended due to negative balance
 */
export const isActiveOrNegative = (transitAccountQ) => {
	const { accountStatus } = transitAccountQ;
	return isNegativeBalanceSuspended(transitAccountQ) || accountStatus === active;
};


/**
 * @param   {transitAccountQ} transitAccountQ - transitAccountQ containing accountStatus and purses
 * @returns {boolean} - TRUE if the passed primaryToken is Active or Suspended due to negative balance
 */
export const isPrimaryTokenNegativeSuspended = (transitAccountQ) => {
	const { frontendStatus } = findPrimaryToken(transitAccountQ?.tokens);
	const isNegativeSuspendedBalance = frontendStatus === suspended && isNegativeBalance(transitAccountQ);

	return (isNegativeSuspendedBalance || frontendStatus === active);
};
