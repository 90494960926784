import {
	clone,
} from "lodash";

export const MEMBER_STATUSES = {
	active: "Active",
	approvalDenied: "ApprovalDenied",
	expired: "Expired",
	onHold: "OnHold",
	pendingApproval: "PendingApproval",
	pendingRemoval: "PendingRemoval",
	removed: "Removed",
	terminated: "Terminated",
};

export default class WSMemberStatus {
	constructor({
		status,
		shortDesc,
		description,
	}) {
		// string(20)
		// The member status
		this.status = status;
		// string(16)
		// Short description about the member status
		this.shortDesc = shortDesc;
		// string(512)
		// A longer description of a member status
		this.description = description;
	}

	static fromBackoffice(data) {
		const result = new WSMemberStatus(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	static fromSerialized(data) {
		const result = new WSMemberStatus(data);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSMemberStatusType = [
	`
		type WSMemberStatus {
			status: String
            shortDesc: String
            description: String
		}
	`,
];

export const InputWSMemberStatusType = [
	`
		input InputWSMemberStatus {
			status: String!
            shortDesc: String!
            description: String!
		}
	`,
];
