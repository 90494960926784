import { clone } from "lodash";
import WSFundingSourceSet,
{ WSFundingSourceSetType, InputWSFundingSourceSetType } from "./WSFundingSourceSet.js";
import WSAutoloadCriteria,
{ WSAutoloadCriteriaType, InputWSAutoloadCriteriaType } from "./WSAutoloadCriteria.js";
import WSAutoload,
{ WSAutoloadType, InputWSAutoloadType } from "./WSAutoload.js";
import WSFundingSourceSetUpdate, { WSFundingSourceSetUpdateType, InputWSFundingSourceSetUpdateType } from "./WSFundingSourceSetUpdate.js";

// 2.14.1.WSAutoloadSubscriptionUpdate
export default class WSAutoloadSubscriptionUpdate {
	constructor({
		loadValueBehavior,
		loadValue,
		criteriaType,
		timePeriodRecurrence,
		timePeriodDay,
		timePeriodFrequency,
		fundingSourceSet,
		startDate,
		status,
		thresholdValue,
		daysBeforeExpiryToTrigger,
		productSKU,
		isPaused, 
	}) {
		// string(30)
		// (Optional)
		// OAM - Required when the type is AddValue:
		// • AddAutoloadValue
		// • IncreaseBalanceTo
		this.loadValueBehavior = loadValueBehavior;

		// int
		// (Optional) Value to auto load. This may be used with AddAutoloadValue and IncreaseBalanceTo types.
		// The type will define the auto load behavior.
		this.loadValue = loadValue;

		// string(20)
		// (Optional) - Identifies the type of criteria. Valid values are:
		// • Threshold
		// • Recurring
		// • PassThreshold
		this.criteriaType = criteriaType;

		// string(20)
		// (Conditionally-Required) - Identifies the type of recurrence time period. Valid values are:
		// • Daily
		// • Weekly
		// • Monthly
		// Required if the criteriaType is set to Recurring.
		this.timePeriodRecurrence = timePeriodRecurrence;

		// string(3)
		// (Contitionally-Required) The day of the time period on which load is triggered.
		// Required if the criteriaType is set to Recurring.
		// Does not apply to “Daily”.
		// If the period types is Weekly, valid values are: SUN, MON, TUE, WED, THU, FRI, SAT
		// If the periodType is Monthly, valid values are 1-31 where the value represents the calendar day.
		// If the calendar date is larger than the number of days in the month the load will be triggered on the last day of the month.
		// For example, if the value was set to 31 and the current month is April, the load will be triggered on the 30th.
		this.timePeriodDay = timePeriodDay;

		// int
		// (Contitionally-Required) - The frequency of recurrence.
		// Required if the criteriaType is set toRecurring
		// Example: If the type = Weekly, day = MON, and frequency =
		// 2, the load will happen every other week on Monday.
		this.timePeriodFrequency = timePeriodFrequency;

		// WSFundingSourceSetUpdate
		// (Optional) Update the funding source set being used with the autoload.
		// This is applied as a PUT fully replacing the existing funding source set.
		this.fundingSourceSet = fundingSourceSet;

		// dateTime
		// (Optional) The date when the subscription becomes active
		// Date field must be in UTC format.
		this.startDate = startDate;

		// string(20)
		// (Optional) The status of the subscription. Valid values are:
		// • Active
		// • FulfillmentPending
		// • SuspendedDueToError
		// • SuspendedManually
		this.status = status;

		// int
		// (Conditionally-Required) Value at which to trigger
		// the auto load. Needed when the criteria type is
		// Threshold.
		// If this is not defiend, a system wide configuration is
		// used to define this thresholdAmount.
		this.thresholdValue = thresholdValue;

		// int
		// (Conditionally-Required) Days before pass expiration at which to trigger the auto load.
		// Needed when the criteria type is PassThreshold.
		// If this is not defiend, a system wide configuration is used to define this.
		this.daysBeforeExpiryToTrigger = daysBeforeExpiryToTrigger;

		// string
		// (Conditionally-Required) The SKU of the product that autoload subscription is for.
		// This is required if daysBeforeExpiryToTrigger field is being updated so the correct product can be
		// identified in the the product catalog.
		this.productSKU = productSKU;

		// boolean
		// (Conditionally-Required) Value to pause auto load. This may be used with startDate value.
		this.isPaused = isPaused;

	}

	static fromBackoffice(data) {
		const result = clone(this);

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		result.fundingSourceSet =
			this.fundingSourceSet.toBackoffice();

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.fundingSourceSet =
			this.fundingSourceSet.toResolver();

		return forGraphql;
	}
}

const WSAutoloadSubscriptionUpdateFragment = `
	loadValueBehavior: String
	loadValue: Int
	criteriaType: String
	timePeriodRecurrence: String
	timePeriodDay: String
	timePeriodFrequency: Int
	startDate: GraphQLDateTime
	status: String
	thresholdValue: Int
	daysBeforeExpiryToTrigger: Int
	productSKU: String
	isPaused: Boolean
`;

export const InputWSAutoloadSubscriptionUpdateType = [
	...InputWSFundingSourceSetUpdateType, `
	input InputWSAutoloadSubscriptionUpdate {
		fundingSourceSet: InputWSFundingSourceSetUpdate
		${WSAutoloadSubscriptionUpdateFragment}
	}
` ];

export const WSAutoloadSubscriptionUpdateType = [
	...WSFundingSourceSetUpdateType, `
	type WSAutoloadSubscriptionUpdate {
		fundingSourceSet: WSFundingSourceSetUpdate
		${WSAutoloadSubscriptionUpdateFragment}
	}
` ];
