import React from 'react';

const cms = {
	currentBalance: 'miscText.account-purchases-select-value-panel-sub-header',
	backButtonTitle: 'miscText.account-purchases-select-value-panel-back-button-label',
	backButtonTitleRecurring: 'miscText.account-purchases-select-value-panel-back-button-recurring-label',
	backButtonTitleThreshold: 'miscText.account-purchases-select-value-panel-back-button-threshold-label',
};

const selectReloadMethod = 0;
const selectReloadAmount = 1;
const purchaseValue = 2;

export const Weekly = "Weekly";
export const Monthly = "Monthly";

export const PURCHASE_VALUE_STEPS = {
	selectReloadMethod,
	selectReloadAmount,
	purchaseValue,
};