import {
	parse as qs_parse,
} from 'qs';
import { get } from 'lodash';
import { useCookies } from "react-cookie";
import { useLocation } from 'react-router-dom';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';


export const POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID = "post_sign_in_upgrade_card_transit_account_id";
export const POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID = "post_sign_in_replace_card_transit_account_id";

export const cookieOptions = {
	path: '/',
};

export const postSignInCookies = [
	POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID,
	POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID,
];

// Helper funcs to apply specific Cookie logic
const getOverViewRouteFromCookie = ({ cookies }) => {
	const transit_account_id =
		get(cookies, POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID, null)
	|| 	get(cookies, POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID, null);

	if (transit_account_id) {
		return getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id });
	}
};

export const getPostSignInFromCookies = ({ cookies }) => {
	let postSigninGoToPath = undefined;

	// Both of these cookies direct to the same route but have different banners
	if (POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID in cookies || POST_SIGN_IN_REPLACE_CARD_TRANSIT_ACOUNT_ID in cookies)
	{
		postSigninGoToPath = getOverViewRouteFromCookie({ cookies });
	}

	return postSigninGoToPath;
};

export const getPostSignInFromLocation = ({ location }) =>
	qs_parse(location.search, { ignoreQueryPrefix: true })?.postSigninGoToPath;


/**
 * @param location
 * @returns { String }
 */
export const usePostSigninGoToPath = () => {
	const location = useLocation();
	const [ cookies ] = useCookies(postSignInCookies);

	const locationRedirect = getPostSignInFromLocation({ location });
	const cookieRedirect = getPostSignInFromCookies({ cookies });

	const redirect = locationRedirect
		? locationRedirect
		: cookieRedirect;

	return redirect;
};
