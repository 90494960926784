import React from "react";
import { Helmet } from 'react-helmet-async';
import cx from 'classnames';
import { parse } from "qs";
import PublicAppVars from "utils/PublicAppVars.js";
import Unpaginate from "./Unpaginate.js";
import CmsContentList from 'components/data/CmsContentList.js';
import { useLocation, useRouteMatch } from "react-router-dom";
import { PAYMENT_HISTORY_TA_GET } from "components/data/transit-account/PaymentHistoryTA.query.js";
import { columns as purchaseColumns, mapPaymentHistory } from "components/account/data-tables/PurchaseHistoryTable.js";
import { getColumns as getTripsColumns } from "components/account/data-tables/TripHistoryTable.js";
import DataTable, { TABLE_PURCHASES, TABLE_TRAVEL } from "components/DataTable.js";
import DataTableZeroState, { TYPE_PURCHASES, TYPE_TRIPS } from 'components/account/data-tables/DataTableZeroState.js';
import { FullName } from "components/data/session-user/SessionUser.js";
import { useModalContext } from "context/ModalProvider.js";
import { useTransitAccount } from "components/data/transit-account/TransitAccount.js";
import { GET_RIDES_HISTORY } from "components/data/transit-account/RidesHistory.query";
import { orderDetailsColumns, orderSummaryColumns, paymentDetailsColumns } from "./DocumentTableColumns.js";
import { centsToDisplay } from "utils/FormatHelpers.js";
import { format } from 'date-fns';
import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { useLoginStage } from "components/data/session-user/LoggingIn.js";
import loginStages from "components/data/session-user/LoginStages.js";
import TokenNameAndPan from "components/account/card/TokenNameAndPan.js";
import { findPrimaryToken } from "components/manage-cards/TokenHelpers.js";
import HidePypestreamChatbot from "components/pypestream-chatbot/HidePypestreamChatbot.js";

import * as docStyle from "./Document.module.css";


export const PDF_TYPES = {
	trips: 'trips',
	// a list of WSPaymentHistory
	purchases: 'purchases',
	// a single WSOrderPaymentHistory receipt
	order: 'order',
};

const PAYMENT_HISTORY_TYPES = {
	order: 'ORDER',
	travel: 'TRAVEL',
};

export const UNPAGINATE_DONE_CLASSNAME = 'unpaginate-done';

const formatDateString = (dateString) => {
	const [ year, month, day ] = dateString.split("-");

	return format(new Date(`${month}-${day}-${year}`), 'M/d/yyyy');
};

/**
 * Populates the quantity field and de-duplicates line item entries.
 *
 * TODO: Check with team to see if duplicates in the orderLineItems / trips will actually ever happen.
 * Otherwise, quantity will always be `1`.
 */
const aggregateOrderLineItems = (lineItems) => {
	const lineItemMap = {};
	for (const lineItem of lineItems) {
		const key = JSON.stringify(lineItem);
		if (!(key in lineItemMap)) {
			lineItemMap[ key ] = 1;
		} else {
			lineItemMap[ key ]++;
		}
	}
	const aggregatedResult = Object.entries(lineItemMap).map(([ stringifiedLineItem, quantity ]) => ({
		...JSON.parse(stringifiedLineItem),
		quantity,
	}));
	return aggregatedResult;
};


const DoneEl = ({ done }) => done ? <div className={UNPAGINATE_DONE_CLASSNAME}></div> : null;


const cms = {
	purchaseAt: 'miscText.purchasehistory-moreinfo-where-web',
	transitAccount: 'miscText.cardinfo-transit-id',
	nickName: 'miscText["addcard-charlie-physical-nickname.label"]',
	showDateLabel: 'miscText.purchasehistory-showing-dates',
	orderSummaryTitle: 'miscText.purchase-receipt-order-summary',
	orderDetailsTitle: 'miscText.purchase-receipt-order-details',
	paymentDetailsTitle: 'miscText.purchase-receipt-payment-details',
	totalLabel: 'miscText.purchase-cart-total',
	subTotalLabel: "miscText.purchase-cart-subtotal",
	shipping: "miscText.purchase-cart-shipping",
	balance: "miscText.cardinfo-balance",
};

const CardNickName = ({ transitAccountId }) => {
	const { data, loading } = useTransitAccount({ subsystemAccountReference: transitAccountId });

	if (loading) {
		return null;
	}

	const primaryToken = findPrimaryToken(data?.transitAccountQ.tokens);
	const primaryTokenInfo = primaryToken?.tokenInfo ?? null;

	return primaryTokenInfo
		? <TokenNameAndPan tokenInfo={primaryTokenInfo} mediaType={primaryToken.mediaType} />
		: null;
};


const OrderConfirmationDocument = ({
	transitAccountId,
	orderData,
	cmsContent,
}) => {
	const { loginStage } = useLoginStage();

	const loggedIn = (loginStage === loginStages.loggedIn);

	const mediaOption = orderData.mediaOptions.length ? {
		productName: orderData.mediaOptions[ 0 ].name,
		quantity: orderData.mediaOptions[ 0 ].quantity,
		productStartDate: new Date(),
		itemTotalAmount: orderData.mediaOptions[ 0 ].price,
		discountAmount: 0,
		feeAmount: orderData.mediaOptions[ 0 ].enablementFeeAmount,
	} : null;

	const balanceProduct = orderData.balance ? [ {
		itemTotalAmount: orderData.balance,
		productName: <CmsContentRendered contentKey={cms.balance} fallbackValue={"Balance"} />,
		quantity: mediaOption?.quantity || 1,
		discountAmount: 0,
		feeAmount: 0,
	} ] : [];

	const products = orderData.products.map(product => ({
		productName: product.productLongName,
		quantity: product.quantity * (mediaOption?.quantity || 1),
		productStartDate: new Date(),
		itemTotalAmount: product.price,
		discountAmount: 0,
		feeAmount: 0,
	}));

	const productDetails = [ ...(mediaOption ? [ mediaOption ] : []), ...products, ...balanceProduct ];

	const orderSummary = [ {
		orderId: orderData.orderId,
		paymentHistoryDateTime: orderData.authDateTime,
		origin: findContentOrFallback(cmsContent, cms.purchaseAt, 'Website'),
		orderLineItems: productDetails,
		amount: orderData.total,
	} ];

	const paymentMethods = orderData.paymentMethods.map(paymentMethod => ({
		...paymentMethod,
		paymentDateTime: orderData.authDateTime,
		authRefNbr: orderData.authRefNbr,
	}));

	return (<>
		<DocumentHeaderWrapper>
			<div>
				{loggedIn && <div className={docStyle.primaryHeader}><FullName /></div>}
				{transitAccountId &&
					<div className={docStyle.bottomSubheaderRow}>
						<div className={docStyle.leftSideSubHeader}>
							<CmsContentRenderer.Span
								className={docStyle.subheaderLabelText}
								contentKey={cms.nickName}
								fallbackValue="Card Nickname"
							/>
							<CardNickName transitAccountId={transitAccountId} />
						</div>
					</div>
				}
			</div>
			{transitAccountId &&
				<div>
					<div className={docStyle.leftSideSubHeader}>
						<CmsContentRenderer.Span
							className={docStyle.subheaderLabelText}
							contentKey={cms.transitAccount}
							fallbackValue="Transit ID"
						/>
						<div>{transitAccountId}</div>
					</div>
				</div>
			}
		</DocumentHeaderWrapper>

		<section>
			<div className={docStyle.primaryHeader}>
				<CmsContentRenderer.Span
					contentKey={cms.orderSummaryTitle}
					fallbackValue="Order Summary"
				/>
			</div>
			<DataTable
				additionalTableClassNames={docStyle.orderSummaryTable}
				tableType={TABLE_PURCHASES}
				tableColumns={orderSummaryColumns}
				tableData={orderSummary}
				showPagination={false}
				isPdfView
			/>
		</section>

		<section className={docStyle.purchaseReceiptSection}>
			<div className={docStyle.primaryHeader}>
				<CmsContentRenderer.Span
					contentKey={cms.orderDetailsTitle}
					fallbackValue="Order Details"
				/>
			</div>
			<DataTable
				additionalTableClassNames={docStyle.orderDetailsTable}
				tableType={TABLE_PURCHASES}
				tableColumns={orderDetailsColumns(true)}
				tableData={productDetails}
				showPagination={false}
				isPdfView
			/>
			<div className={docStyle.flexEndColumnContainer}>
				<div>
					<div className={docStyle.rightSideSubHeader}>
						<div className={docStyle.leftSideSubtotal}>
							<div>
								<CmsContentRenderer.Span
									contentKey={cms.subTotalLabel}
									fallbackValue="Subtotal"
								/>
							</div>
							<div>
								<CmsContentRenderer.Span
									contentKey={cms.shipping}
									fallbackValue="Shipping"
								/>
							</div>
						</div>
						<div className={docStyle.rightSideSubtotal}>
							<div>{centsToDisplay(orderData.subTotal)}</div>
							<div>&mdash;</div>
						</div>
					</div>
					<div className={cx(docStyle.rightSideSubHeader, docStyle.lastRowSubtotal)}>
						<div className={docStyle.leftSideSubtotal}>
							<CmsContentRenderer.Span
								contentKey={cms.totalLabel}
								fallbackValue="Order Total"
							/>
						</div>
						<div
							className={docStyle.rightSideSubtotal}>{centsToDisplay(orderData.subTotal)}</div>
					</div>
					<hr className={docStyle.totalsLine} />
				</div>
				<div className={cx(docStyle.rightSideSubHeader, docStyle.lastRowSubtotal)}>
					<div className={docStyle.leftSideSubtotal}>
						<CmsContentRenderer.Span
							contentKey={cms.totalLabel}
							fallbackValue="Order Total"
						/>
					</div>
					<div className={docStyle.rightSideSubtotal}>{centsToDisplay(orderData.total)}</div>
				</div>
			</div>
		</section>

		<section className={docStyle.purchaseReceiptSection}>
			<div className={docStyle.primaryHeader}>
				<CmsContentRenderer.Span
					contentKey={cms.paymentDetailsTitle}
					fallbackValue="Payment Details"
				/>
			</div>
			<DataTable
				additionalTableClassNames={docStyle.paymentDetailsTable}
				tableType={TABLE_PURCHASES}
				tableColumns={paymentDetailsColumns}
				tableData={paymentMethods}
				showPagination={false}
				isPdfView
			/>
		</section>
		<DoneEl done={true} />
	</>

	);
};

const Document = () => {
	const location = useLocation();
	const match = useRouteMatch();
	const { pdf_type: pdfType } = match.params;
	const {
		transitAccountId,
		orderId,
		paymentId,
		orderData,
		...filtersApplied
	} = parse(location.search, { ignoreQueryPrefix: true });

	const isHistoryType = pdfType !== PDF_TYPES.order;
	const orderInfo = orderData && JSON.parse(orderData);

	return (<>
		<HidePypestreamChatbot />
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) =>
			(!isHistoryType && orderInfo)
				? <OrderConfirmationDocument
					transitAccountId={transitAccountId}
					orderData={orderInfo}
					cmsContent={cmsContent}
				/>
				: <>
					{/* create history document */}
					<DocumentHeader
						isHistoryType={isHistoryType}
						transitAccountId={transitAccountId}
						filtersApplied={filtersApplied}
					/>
					<HistoryContent
						pdfType={pdfType}
						transitAccountId={transitAccountId}
						filtersApplied={filtersApplied}
						isHistoryType={isHistoryType}
						paymentId={paymentId}
						orderId={orderId}
					/>
				</>}
		</CmsContentList>
	</>);
};


const DocumentHeader = ({ isHistoryType, transitAccountId, filtersApplied }) => {
	const { loginStage } = useLoginStage();
	const loggedIn = loginStage === loginStages.loggedIn;

	return (
		<DocumentHeaderWrapper>
			{loggedIn ?
				<>
					<div className={cx(docStyle.leftSideSubHeader, docStyle.columContainer)}>
						<div className={cx(docStyle.leftSideSubHeader, docStyle.primaryHeader)}>
							<FullName />
						</div>

						{isHistoryType
							? <HistoryDates {...filtersApplied} />
							: <div className={cx(docStyle.leftSideSubHeader)}>
								<CmsContentRenderer.Span
									className={docStyle.subheaderLabelText}
									contentKey={cms.nickName}
									fallbackValue="Card Nickname"
								/>
								<CardNickName transitAccountId={transitAccountId} />
							</div>
						}
					</div>
					<div className={cx(docStyle.rightSideSubHeader, docStyle.columContainer)}>
						<div className={cx(docStyle.leftSideSubHeader)}>
							<CmsContentRenderer.Span
								className={docStyle.subheaderLabelText}
								contentKey={cms.transitAccount}
								fallbackValue="Transit ID"
							/>
							<div>{transitAccountId}</div>
						</div>
						{isHistoryType &&
							<div className={cx(docStyle.leftSideSubHeader)}>
								<CmsContentRenderer.Span
									className={docStyle.subheaderLabelText}
									contentKey={cms.nickName}
									fallbackValue="Card Nickname"
								/>
								<CardNickName transitAccountId={transitAccountId} />
							</div>
						}
					</div>
				</> : <>
					<div className={docStyle.leftSideSubHeader}>
						<HistoryDates {...filtersApplied} />
					</div>
					<div className={cx(docStyle.rightSideSubHeader, docStyle.columContainer)}>
						<div className={cx(docStyle.leftSideSubHeader)}>
							<CmsContentRenderer.Span
								className={docStyle.subheaderLabelText}
								contentKey={cms.transitAccount}
								fallbackValue="Transit ID"
							/>
							<div>{transitAccountId}</div>
						</div>
					</div>
				</>}
		</DocumentHeaderWrapper>
	);
};


const HistoryDates = ({ startDateTime, endDateTime }) =>
	<div className={cx(docStyle.leftSideSubHeader)}>
		<CmsContentRenderer.Span
			className={cx(docStyle.subheaderLabelText)}
			contentKey={cms.showDateLabel}
			fallbackValue="Showing"
		/>
		<span>{formatDateString(startDateTime)} - {formatDateString(endDateTime)}</span>
	</div>;

const HistoryContent = ({ pdfType, transitAccountId, filtersApplied, isHistoryType, paymentId, orderId }) => isHistoryType
	? <HistoryTable {...{ pdfType, transitAccountId, filtersApplied }} />
	: <PurchaseDocument {...{ pdfType, transitAccountId, filtersApplied, paymentId, orderId }} />;


const HistoryTable = ({ pdfType, transitAccountId, filtersApplied }) => {
	const { setModal } = useModalContext();
	const isTripsType = pdfType === PDF_TYPES.trips;

	const unpaginateArgs = isTripsType ?
		{
			query: GET_RIDES_HISTORY,
			name: `PDF Print - ${pdfType}`,
			variables: {
				...filtersApplied,
				hideTravelReversals: true,
				subsystemAccountRef: transitAccountId,
				limit: PublicAppVars.MAX_TRIPS_HISTORY_PAGE_SIZE,
			},
			dataSelector: 'TransitAccountRoute.getRidesHistory.lineItems',
			countSelector: 'TransitAccountRoute.getRidesHistory.totalCount',
		} : {
			query: PAYMENT_HISTORY_TA_GET,
			name: `PDF Print - ${pdfType}`,
			variables: {
				...filtersApplied,
				hideTravelReversals: true,
				subsystemAccountReference: transitAccountId,
				limit: PublicAppVars.MAX_CHARGES_HISTORY_PAGE_SIZE,
			},
			dataSelector: 'TransitAccountRoute.getPaymentHistoryTA.lineItems',
			countSelector: 'TransitAccountRoute.getPaymentHistoryTA.totalCount',
		};

	return (
		<Unpaginate {...unpaginateArgs}>{({ data, done }) => {
			const tableData = isTripsType
				? data.slice().sort((a, b) => {
					const aTime = new Date(a.startDateTime).getTime();
					const bTime = new Date(b.startDateTime).getTime();
					return bTime - aTime;
				})
				: mapPaymentHistory(data);

			return (<>
				<DoneEl done={done} />

				{data.length === 0
					? <DataTableZeroState type={isTripsType ? TYPE_TRIPS : TYPE_PURCHASES} />
					: <DataTable
						additionalTableClassNames={isTripsType ? docStyle.tripHistoryTable : docStyle.purchaseHistoryTable}
						tableType={isTripsType ? TABLE_TRAVEL : TABLE_PURCHASES}
						tableColumns={isTripsType ? getTripsColumns(setModal) : purchaseColumns}
						tableData={tableData}
						showPagination={false}
						isPdfView
					/>}
			</>
			);
		}}</Unpaginate>
	);
};

const PurchaseDocument = ({ pdfType, transitAccountId, filtersApplied, paymentId, orderId }) =>
	<CmsContentList list={Object.values(cms)}>
		{() =>
			(<Unpaginate
				query={PAYMENT_HISTORY_TA_GET}
				name={`PDF Print - ${pdfType}`}
				variables={{
					hideTravelReversals: true,
					subsystemAccountReference: transitAccountId,
					...filtersApplied,
					...(orderId && { orderId }),
					...(paymentId && { paymentId }),
				}}
				dataSelector="TransitAccountRoute.getPaymentHistoryTA.lineItems"
				countSelector="TransitAccountRoute.getPaymentHistoryTA.totalCount"
			>{({ data, done }) => {

					if (data.length === 0) {
						return <>
							<DoneEl done={done} />
							<DataTableZeroState type={TYPE_PURCHASES} />
						</>;
					}

					const wsPaymentHistory = data[ 0 ];
					const isOrderType = wsPaymentHistory.type === PAYMENT_HISTORY_TYPES.order;
					const lineItems = aggregateOrderLineItems(isOrderType ? wsPaymentHistory.orderLineItems : wsPaymentHistory.trips);

					return <>
						<DoneEl done={done} />
						<section>
							<div className={docStyle.primaryHeader}>
								<CmsContentRenderer.Span
									contentKey={cms.orderSummaryTitle}
									fallbackValue="Order Summary"
								/>
							</div>
							<DataTable
								additionalTableClassNames={docStyle.orderSummaryTable}
								tableType={TABLE_PURCHASES}
								tableColumns={orderSummaryColumns}
								tableData={data}
								showPagination={false}
								isPdfView
							/>
						</section>
						<section className={docStyle.purchaseReceiptSection}>
							<div className={docStyle.primaryHeader}>
								<CmsContentRenderer.Span
									contentKey={cms.orderDetailsTitle}
									fallbackValue="Order Details"
								/>
							</div>
							<DataTable
								additionalTableClassNames={docStyle.orderDetailsTable}
								tableType={TABLE_PURCHASES}
								tableColumns={orderDetailsColumns(isOrderType)}
								tableData={lineItems}
								showPagination={false}
								isPdfView
							/>
							<div className={docStyle.flexEndColumnContainer}>
								<div>
									<div className={docStyle.rightSideSubHeader}>
										<div className={docStyle.leftSideSubtotal}>
											<div>
												<CmsContentRenderer.Span
													contentKey={cms.subTotalLabel}
													fallbackValue="Subtotal"
												/>
											</div>
											<div>Shipping</div>
										</div>
										<div className={docStyle.rightSideSubtotal}>
											<div>{centsToDisplay(wsPaymentHistory.amount)}</div>
											{/* TODO: Find out where shipping costs would be included, and remove hardcoded dash */}
											<div>&mdash;</div>
										</div>
										<hr className={docStyle.totalsLine} />
									</div>
									<div className={cx(docStyle.rightSideSubHeader, docStyle.lastRowSubtotal)}>
										<div className={docStyle.leftSideSubtotal}>
											<CmsContentRenderer.Span
												contentKey={cms.totalLabel}
												fallbackValue="Order Total"
											/>
										</div>
										<div
											className={docStyle.rightSideSubtotal}>{centsToDisplay(data[ 0 ].amount)}</div>
									</div>
									<hr className={docStyle.totalsLine} />
								</div>
								<div className={cx(docStyle.rightSideSubHeader, docStyle.lastRowSubtotal)}>
									<div className={docStyle.leftSideSubtotal}>
										<CmsContentRenderer.Span
											contentKey={cms.totalLabel}
											fallbackValue="Order Total"
										/>
									</div>
									<div
										className={docStyle.rightSideSubtotal}>{centsToDisplay(wsPaymentHistory.amount)}</div>
								</div>
							</div>
						</section>
						<section className={docStyle.purchaseReceiptSection}>
							<div className={docStyle.primaryHeader}>
								<CmsContentRenderer.Span
									contentKey={cms.paymentDetailsTitle}
									fallbackValue="Payment Details"
								/>
							</div>
							<DataTable
								additionalTableClassNames={docStyle.paymentDetailsTable}
								tableType={TABLE_PURCHASES}
								tableColumns={paymentDetailsColumns}
								tableData={wsPaymentHistory.payments}
								showPagination={false}
								isPdfView
							/>
						</section>
					</>;
				}}</Unpaginate>
			)}
	</CmsContentList>;

const DocumentHeaderWrapper = ({ children }) => {
	return (
		<>
			<Helmet>
				<html className={docStyle.pdfHtml} />
			</Helmet>
			<div className={docStyle.subheaderContainer}>
				{children}
			</div>
		</>
	);
};


export default Document;
