import React, {
	Fragment,
	useEffect,
	useState,
	useContext,
	useMemo,
} from "react";
import {
	Link,
	Redirect,
	useLocation,
} from "react-router-dom";
import {
	parse as qs_parse,
	stringify,
} from "qs";
import {
	map,
	pick,
	values,
} from "lodash";
import cx from "classnames";
import {
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
	array as yup_array,
	boolean as yup_boolean,
} from "yup";

import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { addYupMethod } from "utils/YupValidators.js";
import { WireFormHelper } from "utils/FormHelper.js";
import ClientAjax from 'utils/ClientAjax.js';
import EulaData from 'components/data/EulaData.js';
import EulaSearch, { useEulaSearch, EulaFeatures } from 'components/data/eulas/EulaSearch.js';
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";
import PublicAppVars from "utils/PublicAppVars.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import Countries from "components/data/Countries.js";
import TakeOverLayout from "layouts/TakeOverLayout.js";
import Input from "components/forms/Input.js";
import MaskedInput from 'components/forms/MaskedInput.js';
import Button, {
	Secondary,
} from 'components/Button.js';
import Select from 'components/forms/inputs/Select.js';
import Phone from "components/forms/Phone.js";
import Checkbox, { BoolCheckbox } from "components/forms/Checkbox.js";
import Panel from "components/Panel.js";
import ContactCustomerService from "components/banners/ContactCustomerService.js";
import TransitAccountIdContext from "context/TransitAccountIdContext.js";
import { BO_ERRORS, COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import preventDefault from "utils/PreventDefault.js";
import { useSessionCheckContext } from "components/data/session-user/SessionCheck.js";
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";
import { usePostSigninGoToPath } from "components/data/session-user/PostSigninGoToPath.js";
import LoadingIcon from "components/icons/LoadingIcon";

import * as formStyles from 'components/forms/Forms.module.css';
import * as buttonStyles from "components/Button.module.css";
import * as loginStyles from "./Login.module.css";
import * as personalInfoStyles from "./PersonalInformation.module.css";
import * as inputStyles from "components/forms/Input.module.css";

const cms = {
	header: "miscText.register-header",
	subheader: "miscText.register-info-subheader",
	description: "miscHtml.register-info-description",
	firstNameInputLabel: 'miscText["register-info-first.label"]',
	middleInitialInputLabel: 'miscText["register-info-mid.label"]',
	lastNameInputLabel: 'miscText["register-info-last.label"]',
	emailAddressInputLabel: 'miscText["register-info-email.label"]',
	phoneInputLabel: 'miscText["register-info-phone.label"]',
	phoneNumberAriaLabel: 'miscText["register-info-phonetype.number"]',
	phoneCountryAriaLabel: 'miscText["register-info-phonetype.country"]',
	phoneTypeInputLabel: 'miscText["register-info-phonetype.label"]',
	phoneTypeMobileLabel: "miscText.register-info-phonetype-mobile",
	phoneTypeOfficeLabel: "miscText.register-info-phonetype-office",
	phoneTypeHomeLabel: "miscText.register-info-phonetype-home",
	phoneTypeOtherLabel: "miscText.register-info-phonetype-other",
	countryLabel: "miscText.register-info-country",
	zipLabel: "miscText.register-info-zip",
	postalLabel: "miscText.register-info-postal",
	accessPinLabel: 'miscText["register-info-pin.label"]',
	accessPinDescription: 'miscHtml["register-info-pin.description"]',
	accessPinPlaceholder: 'miscText["register-info-pin.placeholder"]',
	agreementLabel: 'miscText["register-info-agreement.label"]',
	agreementTOC: "miscHtml.register-info-agreement-terms-and-conditions",
	agreementAgeEula: "miscHtml.register-info-agreement-coppa",
	submit: "miscText.register-info-submit",
	footerContactHelpText: "miscText.general-contactFooter-text",
	footerContactBtnTitle: "miscText.general-contactFooter-button",
};

export const COUNTRY_USA = "US";

export const TextTermsConditions = ({ to }) => {
	return (
		<div className={formStyles.label}>
			<CmsContentRenderer.Span
				fallbackValue="Please read our"
			/> &nbsp;
			<Link to={to}
				className={formStyles.textLink}
			>
				<CmsContentRenderer.Span
					fallbackValue="Terms and Conditions"
				/>
			</Link>
			&nbsp;
			<CmsContentRenderer.Span
				fallbackValue="carefully."
			/>
			<CmsContentRenderer.Div
				fallbackValue="Please check the box if you agree."
			/>
		</div>
	);
};

const EmailField = ({ prefillEmail, isSocialRegistration, formHelper }) => {
	if (isSocialRegistration) {
		return (
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (
				<div className={personalInfoStyles.regEmailInput}>
					<div className={cx(personalInfoStyles.inputWrapper, personalInfoStyles.regEmailWrapper)}>
						<Input
							name="email"
							label={cmsContent[ cms.emailAddressInputLabel ] || "Email"}
							data-qa="emailAddressInput"
							aria-label={cmsContent[ cms.emailAddressInputLabel ] || "Email"}
							defaultValue={prefillEmail}
							error={formHelper.getFieldError('email')}
						/>
					</div>
				</div>
			)}</CmsContentList>
		);
	}
	return null;
};

const RequiredEulaHiddenInputs = () => {
	return (
		<EulaSearch
			channels={PublicAppVars.EULA_CHANNELS}
			features={PublicAppVars.EULA_REQUIRED_FEATURES}
			loading={() => null}
		>{eulas => map(eulas, eula => (
				<input key={eula.eulaId}
					name="agreedEulaIds[]"
					type="hidden"
					value={eula.eulaId}
				/>
			))}</EulaSearch>
	);
};

export const OptionalEulas = ({
	header = '',
	type = '',
	showBar = true,
}) => {
	return (
		<EulaSearch params={[ { explicit: false, channel: 'Any', status: 'Active' } ]}>{eulas => {
			const WrapperElement = type === 'panel' ? Panel : Fragment;

			return eulas.length ? (
				<WrapperElement>
					{header && header()}
					{showBar && <hr className={formStyles.hr} />}
					<CmsContentRenderer.Div
						fallbackValue="We’re always trying to improve the rider experience. Please consider sharing additional data with us to help us make these improvements."
						className={personalInfoStyles.eulaDisclaimer}
					/>
					{map(eulas, eula => {
						return (
							<div className={personalInfoStyles.eulaCheckboxContainer} key={eula.eulaId}>
								<Checkbox label={
									<div className={cx(formStyles.label, personalInfoStyles.eulaLabel)}>
										{eula.featureDescription}
										<CmsContentRenderer.Span fallbackValue="(optional)"
											className={formStyles.optionalLabel}
										/>
									</div>
								}
								labelFirst={false}
								name="optionalEulaIds[]"
								value={eula.eulaId}
								controlled={true}
								/>
								<Button to={getPathByRoute(routeKeys.EulaById, { eula_id: eula.eulaId })}
									additionalClassNames={cx(buttonStyles.link, personalInfoStyles.eulaLink)}
									type={Secondary}
								>
									{eula.name}
								</Button>
							</div>
						);
					})}</WrapperElement>
			)
				: null;
		}}</EulaSearch>
	);
};

addYupMethod("validPhoneNumber");
addYupMethod("notEqualToIfNotEmpty");

const getYupSchema = () => {
	// TODO: Use API to get country and Address validation rules?
	const validations = {
		firstName: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.name.firstName', BO_ERRORS.general.required))
			.trim(),
		middleInitial: yup_string()
			.max(1, getErrorKey(COLLECTIONS.registration, 'contact.name.middleInitial', BO_ERRORS.general.tooLong))
			.matches(/^[A-Za-z]*$/, "Middle initial must contain only letters") // @todo: add translations
			.nullable(),
		lastName: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.name.lastName', BO_ERRORS.general.required))
			.trim(),
		country: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.address.country', BO_ERRORS.general.required)),
		postalCode: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.address.postalCode', BO_ERRORS.general.required))
			.trim(),
		phoneNumber: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.phone[0].number', BO_ERRORS.general.required))
			.trim()
			.validPhoneNumber(
				yup_ref(`phoneCountry`),
				getErrorKey(COLLECTIONS.registration, 'contact.phone[0].number', BO_ERRORS.general.invalidPhone),
			),
		phoneType: yup_string()
			.trim()
			.when(
				`phoneNumber`,
				{
					is: (val) => Boolean(val),
					then: s => s.required(getErrorKey(COLLECTIONS.registration, 'contact.phone[0].type', BO_ERRORS.general.required)),
				},
			),
		pinValue: yup_string()
			// transform empty strings because otherwise we do not see the required error message, only min error msg
			// https://github.com/jquense/yup/issues/24#issuecomment-178029968
			.transform(value => value === '' ? undefined : value)
			.required("miscText.register-info-error-pin")
			.min(4, getErrorKey(COLLECTIONS.registration, 'contact.pin', BO_ERRORS.general.tooSmall))
			.max(4, getErrorKey(COLLECTIONS.registration, 'contact.pin', BO_ERRORS.general.tooLong))
			.typeError(getErrorKey(COLLECTIONS.registration, 'contact.pin', BO_ERRORS.general.numbersOnly)),
		SignupTermsAgree: yup_boolean()
			.required()
			.oneOf([ true ], "miscText.register-info-error-terms"),
		SignupAgeVerify: yup_boolean()
			.required()
			.oneOf([ true ], "miscText.register-info-error-coppa"),
		agreedEulaIds: yup_array()
			.min(1)
			.of(yup_string()),
		optionalEulaIds: yup_array()
			.of(yup_string()),
		...recaptchaYupValidations,
	};
	return yup_object().shape(validations);
};

export const getPreviousPageData = (location) => {
	return location.state
		? location.state
		: qs_parse(location.search, { ignoreQueryPrefix: true });
};

const kickoffSubmit = async ({
	setSubmitting,
	formHelper,
	setSubmitted,
	unregisteredTransitAccountId,
	checkRecaptcha,
	resetRecaptcha,
	syntheticTimerEvent,
}) => {
	await checkRecaptcha();

	setSubmitting(true);

	let validated;

	try {
		validated = await formHelper.startValidation(true);
	} catch (errorReport) {
		setSubmitting(false);
		resetRecaptcha();

		noticeError(
			null,
			levels.verbose,
			errorReport,
			`Personal Info form validation`,
		);
		formHelper.validationErrorHandler(errorReport);
		return;
	}

	GoogleAnalytics.logEvent("account info provided");

	const {
		phoneNumber,
		phoneCountry,
		phoneType,
		SignupTermsAgree,
		SignupAgeVerify,
		pinValue,
	} = validated;

	const phones = [ {
		country: phoneCountry,
		number: phoneNumber,
		type: phoneType,
	} ];
	const data = {
		...validated,
		phones,
		eulaCheckbox: SignupTermsAgree,
		minimumAgeCheckbox: SignupAgeVerify,
		agreedEulaIds: validated[ 'agreedEulaIds[]' ],
		optionalEulaIds: validated[ 'optionalEulaIds[]' ],
		unregisteredTransitAccountId,
		address: {
			postalCode: validated.postalCode,
			country: validated.country,
		},
		pin: pinValue,
	};

	// props.submit succeeds or returns an ErrorReport
	try {
		await restClientMiddleware(ClientAjax.post("/ajax/register", data));
		setSubmitted(true);
	} catch (errorReport) {
		resetRecaptcha();
		setSubmitting(false);
		// we're not redirecting anywhere. Prepare the form for the next submit.
		formHelper.validationErrorHandler(errorReport);
	} finally {
		await syntheticTimerEvent();
	}
};

const PersonalInformation = () => {
	const location = useLocation();
	const postSigninGoToPath = usePostSigninGoToPath();

	const [ redirect, setRedirect ] = useState(null);
	const [ registeredUser ] = useState(null);
	const [ submitted, setSubmitted ] = useState(null);
	const [ country, setCountry ] = useState('US');
	const [ countryObj, setCountryObj ] = useState(null);
	const unregisteredTransitAccountId = useContext(TransitAccountIdContext);

	const { eulas, response: eulaResponse } = useEulaSearch({
		features: [ EulaFeatures.NEW_REGISTERED_USER ],
	});

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema });

	const { syntheticTimerEvent } = useSessionCheckContext();

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const previousPageData = useMemo(() => getPreviousPageData(location), [ location ]);
	const isSocialRegistration = Boolean(previousPageData?.subjectId);
	const prefillEmail = previousPageData?.email;

	// redirect back if missing state data
	useEffect(
		() => {
			// if we don't have this data (maybe a refresh?) send them back
			// Apple SSO doesn't have email
			if (previousPageData && !(isSocialRegistration || prefillEmail)) {
				setRedirect(<Redirect push to={getPathByRoute(routeKeys.Register)} />);
			}
		},
		[
			previousPageData,
			isSocialRegistration,
			prefillEmail,
		],
	);

	const PreviousPageDataInputs = () => {
		const picks = isSocialRegistration
			? [ 'socialMediaType', 'subjectId' ]
			: [ 'email', 'password', 'confirm' ];

		const data = previousPageData || {};
		const filteredData = pick(data, picks);

		return <>{map(filteredData, (value, fieldName) => {
			return <input key={fieldName} type="hidden" name={fieldName} value={value} />;
		})}</>;
	};

	const {
		eulaAccepted: tosAccepted = false,
	} = qs_parse(location.search, { ignoreQueryPrefix: true });

	// only social will be able to prefill name
	const prefillFirstName = previousPageData?.firstName;
	const prefillLastName = previousPageData?.lastName;

	if (submitted) {
		return <Redirect push to={{
			pathname: getPathByRoute(routeKeys.RegisterAccountCreated),
			search: stringify({
				postSigninGoToPath,
			}),
			state: {
				submitted,
				email: prefillEmail,
			},
		}} />;
	}

	if (registeredUser) {
		return (
			<Redirect
				push
				to={{
					pathname: getPathByRoute(routeKeys.AccountCardOverview),
					state: registeredUser,
				}}
			/>
		);
	}


	if (eulaResponse.loading) {
		return <LoadingIcon />;
	}

	if (redirect) {
		return redirect;
	}

	const eulaId = eulas[ 0 ]?.eulaId;

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<>
				<TakeOverLayout
					title={cmsContent[ cms.header ] || "Register for a Charlie Account"}
					containerOverrideClass={personalInfoStyles.registerContainer}
				>
					<WireFormHelper formHelper={formHelper}>
						<form
							className={personalInfoStyles.form}
							data-qa="RegisterFormInfo"
							ref={formRef}
							onSubmit={preventDefault(() => kickoffSubmit({
								setSubmitting,
								formHelper,
								setSubmitted,
								unregisteredTransitAccountId,
								checkRecaptcha,
								resetRecaptcha,
								syntheticTimerEvent,
							}))}
						>
							<div>
								<CmsContentRenderer.H2
									className={personalInfoStyles.enterInfoTitle}
									contentKey={cms.subheader}
									fallbackValue="Enter your information"
								/>
								<CmsContentRenderer.P
									className={personalInfoStyles.enterInfoText}
									contentKey={cms.description}
									rawHtml={true}
									fallbackValue="<p>Your name and contact information are used to secure your account so we can validate your identity should you ever need support.</p>"
								/>
							</div>

							<div className={personalInfoStyles.regEmailInput}>
								<div
									className={cx(personalInfoStyles.inputWrapper, personalInfoStyles.regEmailWrapper)}>
									<EmailField {...{ isSocialRegistration, formHelper, prefillEmail }} />
								</div>
							</div>

							<div>
								<div className={personalInfoStyles.inputWrapper}>
									<Input
										overrideClass={personalInfoStyles.firstInput}
										type="text"
										name="firstName"
										label={cmsContent[ cms.firstNameInputLabel ] || 'First Name'}
										defaultValue={prefillFirstName}
										data-qa="RegisterInputFirstName"
										error={formHelper.getFieldError("firstName")}
									/>
									<Input
										overrideClass={personalInfoStyles.secondInput}
										type="text"
										name="middleInitial"
										label={<>
											<CmsContentRenderer.Span
												contentKey={cms.middleInitialInputLabel}
												fallbackValue="Middle Initial"
											/>
										</>}
										data-qa="RegisterInputMiddleName"
										required={false}
										error={formHelper.getFieldError("middleInitial")}
										maxLength={1}
									/>
								</div>
								<Input
									type="text"
									name="lastName"
									label={cmsContent[ cms.lastNameInputLabel ] || 'Last Name'}
									defaultValue={prefillLastName}
									data-qa="RegisterInputLastName"
									error={formHelper.getFieldError("lastName")}
								/>
							</div>

							<div
								className={cx(personalInfoStyles.inputWrapper, personalInfoStyles.selectInputsWrapper)}>
								<div className={cx(personalInfoStyles.phone, personalInfoStyles.firstInput)}>
									<Phone
										legend={cmsContent[ cms.phoneInputLabel ] || 'Phone'}
										legendProps={{
											className: inputStyles.label,
										}}
										formHelper={formHelper}
										limitMaxLength
										data-qa="PhoneInput0"
										phoneNumberAriaLabel={findContentOrFallback(cmsContent, cms.phoneNumberAriaLabel, 'phone number')}
										phoneCountryAriaLabel={findContentOrFallback(cmsContent, cms.phoneCountryAriaLabel, 'phone number country')}
										className={formStyles.phoneInput}
									/>
								</div>
								<div>
									<Select
										overrideClass={personalInfoStyles.secondInput}
										reactSelectClassName={personalInfoStyles.selectField}
										name="phoneType"
										label={findContentOrFallback(cmsContent, cms.phoneTypeInputLabel, 'Phone Type')}
										placeholder="Select"
										options={[
											{ value: "H", label: cmsContent[ cms.phoneTypeHomeLabel ] || "Home" },
											{ value: "M", label: cmsContent[ cms.phoneTypeMobileLabel ] || "Mobile" },
											{ value: "W", label: cmsContent[ cms.phoneTypeOfficeLabel ] || "Office" },
											{ value: "O", label: cmsContent[ cms.phoneTypeOtherLabel ] || "Other" },
										]}
										error={formHelper.getFieldError("phoneType")}
										searchable={false}
										required={true}
										onChange={() => (
											formHelper.clearFieldErrors('phoneType')
										)}
									/>
								</div>
							</div>
							<Countries>{({ countries, countriesObj }) => {

								if (countryObj === null) {
									setCountryObj(countriesObj[ country ]);
								}

								return (
									<div
										className={cx(personalInfoStyles.inputWrapper, personalInfoStyles.selectInputsWrapper)}>
										{/* List of options needed, set default val for select example: select https://codesandbox.io/s/32pwzwxom */}
										<div className={personalInfoStyles.firstInput}>
											<Select
												name="country"
												reactSelectClassName={personalInfoStyles.selectField}
												label={findContentOrFallback(cmsContent, cms.countryLabel, "Country")}
												value={{
													value: country,
													label: countriesObj[ country ].description,
												}}
												defaultValue={country}
												options={map(countries, country => ({
													value: country.country,
													label: country.description,
												}))}
												searchable={false}
												onChange={option => setCountry(option.value)}
												error={formHelper.getFieldError("country")}
												data-qa="RegisterInputCountry"
											/>
										</div>
										<Input
											overrideClass={cx(personalInfoStyles.secondInput, personalInfoStyles.zip)}
											name={"postalCode"}
											label={country === COUNTRY_USA
												? <CmsContentRenderer.Span contentKey={cms.zipLabel}
													fallbackValue="ZIP code" />
												: <CmsContentRenderer.Span contentKey={cms.postalLabel}
													fallbackValue="Postal code" />}
											data-qa="AddressFormPostalCode"
											error={formHelper.getFieldError("postalCode")}
										/>
									</div>
								);
							}}</Countries>
							<div>
								<label htmlFor="pinValue">
									<CmsContentRenderer.Span
										className={personalInfoStyles.pinTitle}
										contentKey={cms.accessPinLabel}
										fallbackValue="Customer Support Access PIN"
									/>
									<CmsContentRenderer.Span
										className={personalInfoStyles.pinText}
										contentKey={cms.accessPinDescription}
										rawHtml={true}
										fallbackValue="This PIN is used to authenticate you when accessing your account by phone. Be sure to store this number in a safe place."
									/>
								</label>
								<MaskedInput
									placeholder={cmsContent[ cms.accessPinPlaceholder ] || '4 digits'}
									type="tel"
									name="pinValue"
									hideLabel={true}
									overrideClass={personalInfoStyles.pinInput}
									options={{ numeral: false }}
									error={formHelper.getFieldError("pinValue")}
									minLength={4}
									maxLength={4}
									inputMode="numeric"
									pattern="[0-9]+"
								/>
							</div>

							<div className={cx(formStyles.calloutBox, personalInfoStyles.calloutBox)}>
								<CmsContentRenderer.H3
									className={formStyles.calloutBoxTitle}
									contentKey={cms.agreementLabel}
									fallbackValue="User Agreement"
								/>
								<BoolCheckbox
									label={eulaId
										? <EulaData eulaId={eulaId} returnDocument={true}>{({ document: { document } }) =>
											<CmsContentRendered.Span
												rawHtml
												className={cx(formStyles.label, personalInfoStyles.tosContent)}
												contentKey={cms.agreementTOC}
												variables={{ url: document }}
												fallbackValue={`<p>Please read our <a href='${document}' target='_blank'>Terms and Conditions</a> carefully. Please check the box if you agree.</p>`}
											/>
										}</EulaData>
										: ""
									}
									labelFirst={false}
									overrideClass={personalInfoStyles.checkbox}
									name="SignupTermsAgree"
									error={formHelper.getFieldError('SignupTermsAgree')}
									defaultChecked={tosAccepted}
								/>

								<BoolCheckbox
									label={
										<CmsContentRenderer.Span
											contentKey={cms.agreementAgeEula}
											rawHtml={true}
											className={cx(formStyles.label, personalInfoStyles.tosContent)}
											fallbackValue="<p>I certify that I am 13 years of age or older.<br /><a href='https://www.mbta.com/fares/reduced/customers-who-ride-for-free'>Info for riders 12 years of age and under</a></p>"
										/>
									}
									labelFirst={false}
									overrideClass={personalInfoStyles.checkbox}
									name="SignupAgeVerify"
									id="SignupAgeVerify"
									error={formHelper.getFieldError('SignupAgeVerify')}
								/>
							</div>

							{/* <OptionalEulas /> */}

							<RequiredEulaHiddenInputs />

							<PreviousPageDataInputs />

							<Recaptcha />

							<div className={loginStyles.formActions}>
								<Button
									isPrimary
									overrideClass={loginStyles.btn}
									data-qa="RegisterFormInfoAction"
									submitting={submitting}
								>
									<CmsContentRenderer.Span contentKey={cms.submit} fallbackValue='Submit' />
								</Button>
							</div>

							{formHelper.getFieldErrorJsx('')}
							{formHelper.getFieldErrorJsx('email')}
						</form>
					</WireFormHelper>
				</TakeOverLayout>

				<ContactCustomerService />
			</>
		)}</CmsContentList>
	);
};

export default PersonalInformation;
