import { merge } from "lodash";
import WSPayment, {
	PAYMENT_TYPES,
	WSPaymentFragment,
} from "./WSPayment.js";
import WSPaymentFactoryInterface from "server/api-types/WSPaymentFactoryInterface.js";

// This object extends WSPayment when paymentType is StoredValue and represents a payment using value
// from an account (OneAccount or Subsystem).

export const ACCOUNT_TYPES = {
	oneAccount: "OneAccount",
	subsystemAccount: "SubsystemAccount",
};

export default class WSAccountValuePayment extends WSPayment {
	constructor({
		accountType,

		// super props
		paymentAmount,
	}) {
		super({
			paymentType: PAYMENT_TYPES.storedValue,
			paymentAmount,
		});

		// string(30)
		// (Required) Type of the account:
		// • OneAccount
		// • SubsystemAccount
		this.accountType = accountType;
	}

	static fromBackoffice(data) {
		const result = new WSAccountValuePayment(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		return {
			...this,
			...super.toBackoffice(),
		};
	}

	toResolver() {
		return merge(
			this,
			super.toResolver(),
		);
	}
}

export const WSAccountValuePaymentFragment = `
	${WSPaymentFragment}
	accountType: String!
`;

export const WSAccountValuePaymentType = [
	...WSPaymentFactoryInterface,
	`
		type WSAccountValuePayment implements WSPaymentFactory {
			${WSAccountValuePaymentFragment}
		}
	`,
];
