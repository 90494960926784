import React, { useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import { values } from 'lodash';
import { Redirect } from 'react-router-dom';
import { useCookies } from "react-cookie";

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import Toast from "components/Toast.js";
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from 'components/data/CmsContentRendered.js';
import TransitAccount from 'components/data/transit-account/TransitAccount.js';

import Container from 'components/Container.js';
import Button, { buttonTypeStylePlain, Secondary } from 'components/Button.js';
import { useGlobalToastsContext } from "context/ToastProvider.js";
import TripHistoryTable from 'components/account/data-tables/TripHistoryTable.js';
import PurchaseHistoryTable from 'components/account/data-tables/PurchaseHistoryTable.js';
import Tabs, { tabsPrimary } from 'components/Tabs.js';
import RegisterForAccount from 'components/banners/RegisterForAccount.js';
import BenefitsOfCharlieAccount from 'components/banners/BenefitsOfCharlieAccount.js';
import CardSummary from 'components/account/CardSummary.js';
import TokenNameAndPan from 'components/account/card/TokenNameAndPan.js';
import ContactCustomerService from 'components/banners/ContactCustomerService.js';
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';
import MissedTap from 'components/banners/MissedTap.js';
import SuspendedForNegativeBalance from 'components/banners/SuspendedForNegativeBalance.js';
import {
	useTransitAccountIdContext,
} from "context/TransitAccountIdContext.js";
import TapsAlertsProvider, {
	TapsAlertsContext,
} from "context/TapsAlertsContext.js";
import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import PublicAppVars from 'utils/PublicAppVars.js';

import * as admin from 'layouts/Admin.module.css';
import * as tabStyle from 'components/Tabs.module.css';
import { getViewTransactionHistoryBreadcrumbs } from './ViewTransactionHistory';
import { useBreadcrumbCallback } from 'context/BreadcrumbProvider';
import { SelectedCardName } from 'layouts/CardLayout';
import {
	POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID,
	cookieOptions,
} from "components/data/session-user/PostSigninGoToPath.js";
import { useModalContext } from 'context/ModalProvider';
import ConfirmReplaceCard from 'components/modals/ConfirmReplace';
import { useIsEmvCard } from 'components/data/transit-account/EMV.helpers';


const cms = {
	recentRides: 'miscText.guest-recent-trips',
	purchaseHistory: 'miscText.guest-recent-purchases',

	checkAnother: 'miscText.guest-sidebar-check-another',
	loadPassesOrBalance: 'miscText.cardmenu-purchase',

	replace: 'miscText.guest-sidebar-replace-cta',
	upgrade: 'miscText.guest-sidebar-upgrade-cta',

	upgradeCardToast: 'miscText.guest-forcereg-upgrade',
	replaceCardToast: 'miscText.guest-forcereg-replace',
};

export const getCardOverviewWithDataBreadcrumbs = () => [
	...getViewTransactionHistoryBreadcrumbs(),
	<SelectedCardName
		key="selectedCardNameOverview"
		routeKey={routeKeys.GuestCardOverview}
	/>,
];

const CardOverview = ({
	subsystemAccountReference,
}) => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const { hasNegativeBalance } = usePurseBalanceContext();
	const [ redirect, setRedirect ] = useState(false);

	const isEmvCard = useIsEmvCard({ subsystemAccountReference });

	const { setModal } = useModalContext();
	const [ _, setCookie ] = useCookies();

	const registerRoute = useMemo(() =>
		<Redirect push to={{ pathname: getPathByRoute(routeKeys.Register) }} />
	, []);

	const tabs = useMemo(() => [
		{
			key: 'trip-history',
			label: <CmsContentRenderer.Span contentKey={cms.recentRides} fallbackValue="Recent trips" />,
			content: <TripHistoryTable showFilters={false} isUnregistered limit={PublicAppVars.CARD_OVERVIEW_RECENT_TRIPS_LIMIT} />,
		},
		{
			key: 'purchase-history',
			label: <CmsContentRenderer.Span contentKey={cms.purchaseHistory} fallbackValue="Recent purchases" />,
			content: <PurchaseHistoryTable showFilters={false} isUnregistered />,
		},
	], []);

	useEffect(() => {
		if (!subsystemAccountReference) {
			setRedirect(
				<Redirect push
					to={{
						pathname: getPathByRoute(routeKeys.ViewTransactionHistory),
					}}
				/>
			);
		}
	}, [ subsystemAccountReference ]);

	// Upgrade card flow
	const handleUpgradeCookie = () => {
		if (PublicAppVars.ENABLE_UPGRADE_CARD) {
			setCookie(POST_SIGN_IN_UPGRADE_CARD_TRANSIT_ACOUNT_ID, subsystemAccountReference, cookieOptions);

			setToast(<Toast
				type="success"
				title={<CmsContentRendered
					contentKey={cms.upgradeCardToast}
					fallbackValue="After registering your Charlie account, you will be prompted to order your upgraded Charlie Card."
				/>}
				onClosed={removeToast}
			/>);
		}
		setRedirect(registerRoute);
	};

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{() => (
			<>
				{hasNegativeBalance
					? <SuspendedForNegativeBalance subsystemAccountReference={subsystemAccountReference} />
					: <TapsAlertsProvider>
						<TapsAlertsContext.Consumer>{({ tapsAlerts }) => (
							tapsAlerts?.length
								? <MissedTap />
								: <RegisterForAccount />
						)}</TapsAlertsContext.Consumer>
					</TapsAlertsProvider>
				}
				<Container overrideClass={admin.container}>
					<section className={admin.main}>
						<div className={admin.header}>
							<TransitAccount subsystemAccountReference={subsystemAccountReference}>{({ transitAccountQ }) => {
								const primaryToken = findPrimaryToken(transitAccountQ.tokens);
								const primaryTokenInfo = primaryToken.tokenInfo;

								return (
									<h1 className={admin.pageTitle} data-qa="AdminPageTitle">
										{primaryTokenInfo
											? <TokenNameAndPan mediaType={primaryToken.mediaType} tokenInfo={primaryTokenInfo} />
											: null}
									</h1>
								);
							}}</TransitAccount>

							<CardSummary />
							<Tabs {...{ tabs }}
								tabStyle={tabsPrimary}
								additionalClasses={tabStyle.guest}
							/>
						</div>
					</section>

					<div className={admin.rightNav}>
						<Button
							type={Secondary}
							additionalClassNames={admin.sidebarItem}
							to={getPathByRoute(routeKeys.ViewTransactionHistory)}
						>
							<CmsContentRenderer.Span
								contentKey={cms.checkAnother}
								fallbackValue="Check Another Card"
							/>
						</Button>

						<Button
							isPrimary
							to={{
								pathname: getPathByRoute(routeKeys.AccountPurchaseProduct, { transit_account_id: subsystemAccountReference }),
								state: { purchase: true },
							}}
							additionalClassNames={admin.sidebarItem}
						>
							<CmsContentRenderer.Span
								contentKey={cms.loadPassesOrBalance}
								fallbackValue="Load Passes or Balance"
							/>
						</Button>

						{!isEmvCard &&
							<Button
								typeStyle={buttonTypeStylePlain}
								onClick={() =>
									setModal(
										<ConfirmReplaceCard subsystemAccountReference={subsystemAccountReference} onConfirm={() => setRedirect(registerRoute)} />
									)
								}
								additionalClassNames={cx(admin.sidebarLink, admin.sidebarItem)}
							>
								<CmsContentRenderer.Span
									contentKey={cms.replace}
									fallbackValue="Replace your Charlie Card"
								/>
							</Button>
						}
						{PublicAppVars.ENABLE_UPGRADE_CARD &&
							<Button
								typeStyle={buttonTypeStylePlain}
								onClick={handleUpgradeCookie}
								additionalClassNames={cx(admin.sidebarLink, admin.sidebarItem)}
							>
								<CmsContentRenderer.Span
									contentKey={cms.upgrade}
									fallbackValue="Upgrade to Standard Fare Card" />
							</Button>
						}
					</div>
				</Container>

				<BenefitsOfCharlieAccount />
				<ContactCustomerService {...{ subsystemAccountReference, isUnregistered: true }} />
			</>
		)}</CmsContentList>
	);
};

const CardOverviewWithData = () => {
	const subsystemAccountReference = useTransitAccountIdContext();

	useBreadcrumbCallback(getCardOverviewWithDataBreadcrumbs);

	return (
		<PurseBalanceProvider {...{ subsystemAccountReference }}>
			<CardOverview {...{ subsystemAccountReference }} />
		</PurseBalanceProvider>
	);
};
export default CardOverviewWithData;
