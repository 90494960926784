import {
	clone,
	merge,
} from "lodash";
import WSTravelTokenDisplay, { WSTravelTokenDisplayFragment } from "./WSTravelTokenDisplay.js";

// 1.9.1.32 WSBankcardTravelTokenDisplay
// WSBankcardTravelTokenDisplay is a subclass of WSTravelTokenDisplay. This object represents displayable
// fields for bankcard travel tokens. Expected to set the tokenType as “Bankcard”.

export default class WSBankcardTravelTokenDisplay extends WSTravelTokenDisplay {
	constructor({
		maskedPAN = null,
		maskedPan,
		cardExpiryMMYY,

		//Super props
		tokenType,
		tokenNickname,
		tokenId,
	}) {

		super({
			tokenType,
			tokenNickname,
			tokenId,
		});

		// string(20)
		// (Required) The masked PAN.
		// Per api doc maskedPAN is deprecated we should use maskedPan
		this.maskedPan = maskedPAN ?? maskedPan;

		// string(4)
		// (Required) The card expiry date in MMYY format.
		// For MM, 01 = January.
		this.cardExpiryMMYY = cardExpiryMMYY;
	}

	static fromBackoffice(data) {
		const result = new WSBankcardTravelTokenDisplay(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const result = clone(this);

		return {
			...result,
			...super.toBackoffice(),
		};
	}

	toResolver() {
		const forGraphql = clone(this);

		return merge(
			forGraphql,
			super.toResolver(),
		);
	}
}

export const WSBankcardTravelTokenDisplayType = [ `
	type WSBankcardTravelTokenDisplay implements WSTravelTokenDisplayFactory {
		${WSTravelTokenDisplayFragment}
		maskedPan: String!
		cardExpiryMMYY: String!
	}
` ];
