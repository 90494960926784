// extracted by mini-css-extract-plugin
export var button = "pages-account-purchase-__PurchaseProductConfirm-module___button";
export var confirmButtons = "pages-account-purchase-__PurchaseProductConfirm-module___confirmButtons";
export var link = "pages-account-purchase-__PurchaseProductConfirm-module___link";
export var printerIcon = "pages-account-purchase-__PurchaseProductConfirm-module___printerIcon";
export var review = "pages-account-purchase-__PurchaseProductConfirm-module___review";
export var reviewButtonContainer = "pages-account-purchase-__PurchaseProductConfirm-module___reviewButtonContainer";
export var reviewButtons = "pages-account-purchase-__PurchaseProductConfirm-module___reviewButtons";
export var reviewHeader = "pages-account-purchase-__PurchaseProductConfirm-module___reviewHeader";
export var reviewSubheader = "pages-account-purchase-__PurchaseProductConfirm-module___reviewSubheader";
export var shippingContainerMargin = "pages-account-purchase-__PurchaseProductConfirm-module___shippingContainerMargin";