import {
	levels,
	log,
} from "utils/Logger.js";
import WSDirectDebit, { WSDirectDebitFragment } from "server/api-types/WSDirectDebit.js";
import WSDirectDebitClear, { WSDirectDebitClearType } from "server/api-types/WSDirectDebitClear.js";
import WSDirectDebitReference, { WSDirectDebitReferenceType } from "server/api-types/WSDirectDebitReference.js";

const objToClass = ({ token, bankAccountNumber }) => {
	if (token) {
		return "WSDirectDebitReference";
	}

	if (bankAccountNumber) {
		return "WSDirectDebitClear";
	}

	log(null, levels.error, `typeToClass: unknown  WSDirectDebit type`);
	return "WSDirectDebit";
};

export default class WSDirectDebitFactory {
	static fromBackoffice(data) {
		if (data.token) {
			return WSDirectDebitReference.fromBackoffice(data);
		}

		if (data.bankAccountNumber) {
			return WSDirectDebitClear.fromBackoffice(data);
		}

		log(null, levels.error, `unknown  WSDirectDebit object ${JSON.stringify(data)}`);
		return WSDirectDebit.fromBackoffice(data);
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSDirectDebitFactoryInterface = [ `
	interface WSDirectDebitFactory {
		${WSDirectDebitFragment}
	}`,
];

export const WSDirectDebitFactoryType = [
	...WSDirectDebitFactoryInterface,
	...WSDirectDebitClearType,
	...WSDirectDebitReferenceType,
];

export const WSDirectDebitFactoryResolvers = {
	WSDirectDebitFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType(obj) {
			return objToClass(obj);
		},
	},
};
