import React, { useCallback, useContext } from "react";
import { useCookies } from "react-cookie";
import { get } from 'lodash';
import { Helmet } from "react-helmet-async";
import { useLocation } from 'react-router-dom';
import PublicAppVars from '../../utils/PublicAppVars.js';
import {
	levels,
	log,
} from "utils/Logger.js";
import {
	LoginStageData, useLoginStage,
} from "./session-user/LoggingIn.js";
import { useAccessibilityPreferences } from "./AccessibilityPreferences.js";
import loginStages from "components/data/session-user/LoginStages.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import { LanguageChanged } from "components/toasts/LanguageChanged.js";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";

export const en = 'en';
export const cmn = 'cmn';
export const yue = 'yue';

export const chinese_Lang_mapping = {
	// Per Jon Wanke: For Mandarin (yue) and Cantonese (cmn), it is simplified Chinese
	// characters when written, yet distinctly Mandarin or Cantonese when spoken.
	// https://reflexions.slack.com/archives/CCF68M49M/p1607361550320800

	// Cmn and Yue are spoken dialects
	// the BO only supports zh-CN (simplified chinese).
	[ 'cmn' ]: 'zh-CN',
	[ 'yue' ]: 'zh-CN',
};

export const DEFAULT_DATE_LOCALE = 'en-US';
export const LANGUAGE_COOKIE_NAME = 'language';

export const LanguageGet = React.createContext(PublicAppVars.LANGUAGE);
export const LanguageSet = React.createContext(() => log(null, levels.error, "LanguageSet used before it was ready"));

const ltr = 'ltr';

// https://cloud.google.com/translate/docs/languages
export const LANGUAGES = {
	// english is the name of the language in english. Used when setting langName in the BO.
	// abbr is what we show when the language select dropdown is inactive
	// native is the name of the language in that language
	// direction is either 'ltr' or 'rtl' (left/right to right/left)
	// for langFile, see export_locales in cms. It's the {langFile}.json file in /cms-exports.
	[ en ]: {
		english: 'English - USA',
		abbr: 'EN',
		native: 'English',
		direction: ltr,
		langFile: 'en',
		recaptchaLocale: 'en',
	},
	'es': {
		english: 'Spanish - Spain',
		abbr: 'SP',
		native: 'Español',
		direction: ltr,
		langFile: 'es',
		recaptchaLocale: 'es',
	},
	'pt': {
		english: 'Portuguese',
		abbr: 'PT',
		native: 'Português',
		direction: ltr,
		langFile: 'pt',
		recaptchaLocale: 'pt',
	},
	[ cmn ]: {
		english: 'Chinese - Mandarin',
		abbr: 'ZH',
		native: '中文 - 普通话',
		direction: ltr,
		langFile: 'zh',
		recaptchaLocale: 'zh-CH',
	},
	[ yue ]: {
		english: 'Chinese - Cantonese',
		abbr: 'ZH',
		native: '中文 - 广东话',
		direction: ltr,
		langFile: 'zh',
		recaptchaLocale: 'zh-CH',
	},
	//'fr': 'French',
	//'ar-001': 'Arabic',
	//'it': 'Italian',
	//'ja': 'Japanese',
	//'pl': 'Polish',
	//'ur': 'Urdu',
};

export const languageFromCookies = (cookies) => get(cookies, LANGUAGE_COOKIE_NAME, PublicAppVars.LANGUAGE);

export const getLanguage = (sessionLang, cookies, defaultLang = PublicAppVars.LANGUAGE) => {
	const lang = sessionLang
		? sessionLang
		: languageFromCookies(cookies);

	if (!Object.keys(LANGUAGES).includes(lang)) {
		return defaultLang;
	}

	return lang;
};

const Language = ({ children }) => {
	const [ cookies, setCookie ] = useCookies([ LANGUAGE_COOKIE_NAME ]);
	const location = useLocation();
	const { setToast } = useGlobalToastsContext();
	const { accessibilityPreferences, setAccessibilityPreferences, loading: prefsLoading } = useAccessibilityPreferences();
	const { loginStage, loading: loginStageLoading } = useLoginStage();

	const setLanguage = useCallback(async (
		newLanguage,
		langKey,
	) => {
		// we'll set the cookie first so that at least the interface changes even if we fail to save it to the account
		setCookie(LANGUAGE_COOKIE_NAME, newLanguage, { path: '/' });

		setToast(<LanguageChanged newLanguage={newLanguage} />);

		// Only save to the BO when enabled
		if (PublicAppVars.SAVE_LANGUAGE_TO_BO && loginStage > loginStages.unRegisteredLoggedIn) {
			await setAccessibilityPreferences({
				variables: {
					// It's necessary to send the full accessibility preferences object to the resolver and not a partial update,
					// because if not, `WSAccessibilityPreference` will overwrite those missing values to defaults and save them in BO
					...accessibilityPreferences,
					lang: newLanguage,
					langName: LANGUAGES[ langKey ].english,
				},
			});
		}
	}, [ accessibilityPreferences, loginStage, setAccessibilityPreferences, setCookie, setToast ]);

	const language = getLanguage(accessibilityPreferences.lang, cookies);

	if (loginStageLoading || prefsLoading) {
		return <LoadingIcon sizes={SIZES.page} />;
	}

	// not sure why <html lang={something} /> isn't working inside <Helmet>
	// so instead that logic is happening inside HelmetDefaults
	return <LanguageGet.Provider value={language}>
		<LoginStageData>{(loginStage) => (
			<LanguageSet.Provider value={setLanguage}>
				<Helmet>
					{language === PublicAppVars.LANGUAGE
						? null
						: (
							<link
								rel="alternate machine-translated-from"
								hrefLang={PublicAppVars.LANGUAGE}
								href={PublicAppVars.PUBLIC_URL + location.pathname}
							/>
						)}
				</Helmet>
				{children}
			</LanguageSet.Provider>
		)}</LoginStageData>
	</LanguageGet.Provider>;
};

export const useLanguage = () => useContext(LanguageGet);

export default Language;
