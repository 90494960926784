// extracted by mini-css-extract-plugin
export var infoBlock = "components-home-__Download-module___infoBlock";
export var infoBlockActions = "components-home-__Download-module___infoBlockActions";
export var infoBlockApp = "components-home-__Download-module___infoBlockApp";
export var infoBlockAppLink = "components-home-__Download-module___infoBlockAppLink";
export var infoBlockAppLinkImage = "components-home-__Download-module___infoBlockAppLinkImage";
export var infoBlockContainer = "components-home-__Download-module___infoBlockContainer";
export var infoBlockContent = "components-home-__Download-module___infoBlockContent";
export var infoBlockImage = "components-home-__Download-module___infoBlockImage";
export var infoBlockMedia = "components-home-__Download-module___infoBlockMedia";
export var infoBlockText = "components-home-__Download-module___infoBlockText";
export var infoBlockTitle = "components-home-__Download-module___infoBlockTitle";