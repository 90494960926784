import React from 'react';
import { values } from 'lodash';

import CmsContentList from 'components/data/CmsContentList';
import AccountLayout from 'layouts/AccountLayout.js';
import PanelSecurityInformation from 'components/account/panels/SecurityInformation.js';
import PanelSignInSettings from 'components/account/panels/SignInSettings.js';
import { findContentOrFallback } from 'components/data/CmsContentRenderer.js';

import * as admin from 'layouts/Admin.module.css';
import { getCharlieCardBreadcrumbs } from '../CardSelection';
import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb';
import routeKeys from 'CustomerRouteKeys';
import { useBreadcrumbCallback } from 'context/BreadcrumbProvider';

const cms = {
	header: "miscText.security-header",
};

export const getSecurityLoginBreadcrumbs = () =>  [
	...getCharlieCardBreadcrumbs(),
	<BreadcrumbItem
		key={cms.header}
		cmsKey={cms.header}
		fallbackValue={'Security & Login'}
		routeKey={routeKeys.AccountPersonalInformation}
	>
	</BreadcrumbItem>,
	 
];
const Security = () => {
	useBreadcrumbCallback(getSecurityLoginBreadcrumbs);
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<AccountLayout
				title={findContentOrFallback(cmsContent, cms.header, "Security & Log In")}
			>
				<div className={admin.pageColWrapper}>
					<div className={admin.mainCol}>
						<PanelSecurityInformation />
					</div>
					<div className={admin.secondaryCol}>
						<PanelSignInSettings />

						{/* <PanelSecurityLevel /> */}
					</div>
				</div>
			</AccountLayout>
		)}</CmsContentList>
	);
};

export default Security;
