import React from 'react';
import Banner, { TYPE_INFO } from "components/Banner.js";
import CmsContentList from "components/data/CmsContentList.js";
import { values } from "lodash";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import Button, { buttonTypeStylePlain } from "components/Button.js";
import * as bannerStyle from "components/Banner.module.css";
import cx from "classnames";
import StdQuery from "components/data/StdQuery.js";
import { SWITCH_BACK } from "layouts/components/AccountSelector.js";
import { GET_FIRSTNAME } from "components/data/session-user/SessionUser.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";

const cms = {
	header: 'miscText["alternate-view-banner.title"]',
	body: 'miscText["alternate-view-banner.description"]',
	linkText: 'miscText["alternate-view-banner.cta"]',
	url: 'miscText["alternate-view-banner.cta-url"]',
	buttonText: 'miscText["alternate-view-banner.button"]',
};

const AlternateManagingAccount = () => {
	const [ switchBackMutation, { loading } ] = useStandardMutation(SWITCH_BACK);

	const switchBack = () => {
		switchBackMutation().then(() => window.location.reload());
	};

	return (
		<StdQuery query={GET_FIRSTNAME}>
			{({ data: { session: { customer: { contact: { name: { firstName } } } } } }) => (
				<CmsContentList list={values(cms)} sharedVariables={{ name: firstName }}>
					{(cmsContent) => (

						<Banner
							type={TYPE_INFO}
							title={<CmsContentRenderer.Span
								contentKey={cms.header}
								params={{
									name: firstName,
								}}
							/>}
							body={<>
								<CmsContentRenderer.P
									contentKey={cms.body}
									params={{
										name: firstName,
									}}
								/>
							</>}

							link={<Button typeStyle={buttonTypeStylePlain}
								to={cmsContent[ cms.url ]}
							>
								<CmsContentRenderer.Span
									contentKey={cms.linkText}
								/>
							</Button>
							}

							rightSide={<div className={bannerStyle.actions}>
								<Button
									isPrimary
									submitting={loading}
									onClick={switchBack}
									additionalClassNames={cx(bannerStyle.button, bannerStyle.action)}
								>
									<CmsContentRenderer.Span contentKey={cms.buttonText} />
								</Button>
							</div>}
						/>
					)}
				</CmsContentList>
			)}
		</StdQuery>
	);
};

export default AlternateManagingAccount;
