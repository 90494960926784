import React from 'react';
import {
	isEmpty,
	map,
	upperFirst,
} from 'lodash';
import cx from 'classnames';

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsObject from 'components/data/CmsObject.js';

import Container from 'components/Container.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import {
	MQ_TABLET,
	useMediaQueryMatches,
} from "utils/Breakpoints.js";

import * as style from './Features.module.css';

const getPercentAsTyped = (num) => {
	switch (num) {
		case 40:
			return "Forty";
		case 60:
			return "Sixty";
		default:
			return "Fifty";
	}
};

const parseLayout = (layout = '50-50') => {
	const split = layout.split('-');

	const leftMaxWidth = parseInt(split[ 0 ]);
	const rightMaxWidth = parseInt(split[ 1 ]);

	return { leftMaxWidth: getPercentAsTyped(leftMaxWidth), rightMaxWidth: getPercentAsTyped(rightMaxWidth) };
};

const Feature = ({ feature }) => {
	const { leftMaxWidth, rightMaxWidth } = parseLayout(feature.layout);
	const isAtLeastTablet = useMediaQueryMatches(MQ_TABLET);

	return (
		<section
			className={cx(
				style.wrapper,
				style[ `align${upperFirst(feature.image_align)}` ],
			)}
		>
			<Container
				overrideClass={cx(style.container,
					style[ feature.bg_color ],
				)}>
				{feature.image_path ? (
					<div
						className={cx(
							style.media,
							isAtLeastTablet ? style[ `maxWidth${leftMaxWidth}` ] : "",
						)}
					>
						<img
							src={feature.image_path}
							className={style.media_image}
						/>
					</div>
				) : null}

				<div
					className={cx(
						style.content,
						isAtLeastTablet ? style[ `maxWidth${rightMaxWidth}` ] : "",
					)}
				>
					<CmsContentRenderer.H2
						content={feature.title}
						className={style.title}
					/>

					<CmsContentRenderer.Div
						rawHtml
						content={feature.text}
						className={style.text}
					/>

					{(!isEmpty(feature.button_text) && !isEmpty(feature.button_url)) && (
						<Button
							isPrimary
							to={feature.button_url}
							text={feature.button_text}
							additionalClassNames={style.btn}
							external={feature.button_url.includes("http")}
							{...feature.button_url.includes("http") ? { target: "_blank" } : {}}
						/>
					)}

					{(!isEmpty(feature.cta_text) && !isEmpty(feature.cta_url)) && (
						<Button
							typeStyle={buttonTypeStylePlain}
							to={feature.cta_url}
							text={feature.cta_text}
							additionalClassNames={style.cta}
							external={feature.cta_url.includes("http")}
							{...feature.cta_url.includes("http") ? { target: "_blank" } : {}}
						/>
					)}
				</div>
			</Container>
		</section>
	);
};


const Features = () =>
	<div>
		<CmsObject contentKey="features">{({ data }) =>
			map(data.cmsObject.obj, (feature) =>
				<Feature
					key={feature.key ?? feature.id}
					{...{ feature }}
				/>,
			)
		}</CmsObject>
	</div>;


export default Features;
