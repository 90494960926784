import {
	clone,
	merge,
} from "lodash";
import WSPayment, { PAYMENT_TYPES } from "./WSPayment.js";

// 2.7.61.2.WSDirectDebitPayment
// This object extends WSPayment when paymentType = DirectDebit and represents an ACH payment.
export default class WSDirectDebitPayment extends WSPayment {
	constructor({
		// super props
		paymentAmount,

		fundingSourceId,
		institutionName,
		merchantId,
		maskedAccountNumber,
	}) {

		super({
			paymentType: PAYMENT_TYPES.directDebit,
			paymentAmount,
		});

		// 	int
		// (Required) The unique ID that identifies the billing information.
		// Assigned by the back office system.
		this.fundingSourceId = fundingSourceId;

		// string(100)
		// (Required) The name of the institution for this Direct Debit/ACH Paymentpayment.
		// This is expected to correspond to the information in the registered funding source.
		// If not the same, this value is ignored and the correct institution name is used in the notifications for this payment.
		this.institutionName = institutionName;

		// string(11)
		// (Required) The merchant who processed the direct debit payment.
		this.merchantId = merchantId;

		// string(20)
		// (Required) The masked account number.
		// This is expected to correspond to the information in the registered funding source.
		// If not the same, this value is ignored and the correct institution name is used in the notifications for this payment.
		this.maskedAccountNumber = maskedAccountNumber;
	}

	static fromBackoffice(data) {
		const result = new WSDirectDebitPayment(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const result = clone(this);

		return merge(
			result,
			super.toBackoffice()
		);
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = this.fundingSourceId;

		return merge(
			forGraphql,
			super.toResolver(),
		);
	}

	toGraphqlInput() {
		return merge(
			this,
			super.toResolver(),
		);
	}

	toInputWSPaymentFactory() {
		return {
			wsDirectDebitPayment: this.toGraphqlInput(),
		};
	}
}

const  WSDirectDebitPaymentFragment = `
	fundingSourceId: Int!
	institutionName: String!
	merchantId: String
	maskedAccountNumber: String!
`;

export const WSDirectDebitPaymentType = [ `
	type WSDirectDebitPayment {
		${WSDirectDebitPaymentFragment}
	}
` ];

export const InputWSDirectDebitPaymentType = [ `
	input InputWSDirectDebitPayment {
		${WSDirectDebitPaymentFragment}
		paymentType: String!
		paymentAmount: Int!
	}
` ];
