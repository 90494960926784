import React from 'react';
import PropTypes from 'prop-types';
import { ERROR_TYPE_SERVER, ERROR_TYPE_RENDER, ERROR_TYPE_BLANK } from './errorTypes.js';

function DefaultError(props) {
	const { errorType } = props;
	const { errorsToScreen, serverErrorHandler, renderErrorHandler, blankErrorHandler } = props.props;

	let errorHandlerResult;

	switch (errorType) {
		case ERROR_TYPE_SERVER:
			errorHandlerResult = serverErrorHandler(props);
			break;
		case ERROR_TYPE_RENDER:
			errorHandlerResult = renderErrorHandler(props);
			break;
		case ERROR_TYPE_BLANK:
			errorHandlerResult = blankErrorHandler(props);
			break;
		default:
			break;
	}

	// if the error method returns a valid react element, return that.
	// this lets use sneak in react router Redirects and such.
	if (React.isValidElement(errorHandlerResult)) {
		return errorHandlerResult;
	}

	if (errorsToScreen && errorHandlerResult) {
		return (
			<span>
				GraphQL API {errorType} error: {String(errorHandlerResult)}
			</span>
		);
	}

	return <span>GraphQL API Error</span>;
}

DefaultError.propTypes = {
	errorType: PropTypes.string,
	props: PropTypes.object,
};

export default DefaultError;
