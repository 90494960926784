import React, { useState } from 'react';
import { values } from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import TransferProvider from 'context/TransferContext.js';
import PurseBalanceProvider from 'context/PurseBalanceContext.js';
import TransitAccountIdContext, { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { StepContextProvider, useStepContext } from 'context/StepContext.js';

import CmsContentList from 'components/data/CmsContentList.js';
import TransitAccount from 'components/data/transit-account/TransitAccount.js';

import TransferProducts from './card/TransferProducts.js';
import DeLinkCard from './DeLinkCard.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

const cms = {
	headerDelink: 'miscText.remove-transit-header',
	delinkStepOneHeader: 'miscText.remove-transit-transfer-step',
	delinkStepTwoHeader: 'miscText.remove-transit-review-step',
};

const DELINK_FLOW_SELECT_PRODUCTS = 0;
const DELINK_FLOW_CONFIRMATION = 1;

export const DELINK_FLOW_STEPS = [
	{
		step: DELINK_FLOW_SELECT_PRODUCTS,
		label: <CmsContentRenderedInline
			contentKey={cms.delinkStepOneHeader}
			fallbackValue="Step 1: Transfer items before unlinking (optional)"
		/>,
	},
	{
		step: DELINK_FLOW_CONFIRMATION,
		label: <CmsContentRenderedInline
			contentKey={cms.delinkStepTwoHeader}
			fallbackValue="Step 2: Review & confirm"
		/>,
	},
];

/**
 * Return the current step of the flow
 */
const CurrentStep = ({ transitAccountQ }) => {
	const transit_account_id = useTransitAccountIdContext();
	const [ hideTransfer, setHideTransfer ] = useState(false);
	const { step } = useStepContext();

	const redirectUrl = getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id });

	switch (step) {
		case DELINK_FLOW_SELECT_PRODUCTS:
			return <TransferProducts { ...{ transitAccountQ, isDelink: true, takeoverTitle: cms.headerDelink, setHideTransfer }} cancelUrl={redirectUrl}  />;
		case DELINK_FLOW_CONFIRMATION:
			return <DeLinkCard  { ...{ transitAccountQ, hideTransfer }} cancelUrl={redirectUrl} />;
	};
};

const DeLinkCardFlow = () => (
	<TransitAccountIdContext.Consumer>{(subsystemAccountReference) => (
		<TransitAccount {...{ subsystemAccountReference, transferProductInfo: true }}>{({ transitAccountQ }) => (
			<CmsContentList list={values(cms)}>{() => (
				<StepContextProvider min={DELINK_FLOW_SELECT_PRODUCTS} max={DELINK_FLOW_CONFIRMATION}>
					<PurseBalanceProvider {...{ subsystemAccountReference }}>
						<TransferProvider>
							<CurrentStep {...{ transitAccountQ }}/>
						</TransferProvider>
					</PurseBalanceProvider>
				</StepContextProvider>
			)}</CmsContentList>
		)}</TransitAccount >
	)}</TransitAccountIdContext.Consumer>
);

export default DeLinkCardFlow;
