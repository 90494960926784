import { gql } from "@apollo/client";
import StdQuery from "../StdQuery.js";
import {
	get,
	some,
	pickBy,
} from "lodash";
import React from "react";

export const WSAddressExtFragment = `
	addressId
	address1
	address2
	address3
	city
	state
	postalCode
	country
	primaryBilling
	primaryShipping
	id`;

export const GET_CONTACT_ADDRESS = gql`
	query GET_CONTACT_ADDRESS {
		session {
			id
			customer {
				contact {
					address {
						${WSAddressExtFragment}
					}
					id
				}
				id
			}
		}
	}
`;

export const cityStateZipStr = ({ city, state, postalCode }) => (
	(
		(city && state)
			? `${city}, ${state}`
			: (city || state)
	) + ` ${postalCode}`
);

/**
 * Returns a one-line address string
 *
 * @param {*} address
 * @param {*} showCountry
 */
export const getFullAddress = (address, showCountry = false) => {
	let result = '';

	if (address.address1) {
		result += address.address1;
	}

	if (address.address2) {
		result += ', ' + address.address2;
	}

	if (address.address3) {
		result += ', ' + address.address3;
	}

	result += result === ''
		? cityStateZipStr(address)
		: ', ' + cityStateZipStr(address);

	if (showCountry) {
		result += ', ' + address.country;
	}

	return result;
};

/**
 * Checks if the address is complete
 *
 * @param {WSAddressExt} wsAddressExts
 * @returns bool
 */
export const isFullAddress = (wsAddressExt) =>
	 (wsAddressExt.address1
			&& wsAddressExt.city
			&& wsAddressExt.state
			&& wsAddressExt.postalCode);

/**
 * Checks if full address is available in the
 * list of addresses for the user
 *
 * @param {WSAddressExt[]} wsAddressExts
 * @returns bool
 */
export const isFullAddressAvailable = (wsAddressExts) => {
	return some(wsAddressExts, wsAddressExt => isFullAddress(wsAddressExt));
};

export const Address = () => (
	<StdQuery
		query={GET_CONTACT_ADDRESS}
	>
		{({ data }) => {
			const rawData = get(data, "session.customer.contact.address", { noData: true });
			const filteredData = pickBy(rawData);
			const {
				address1 = '',
				address2 = '',
				address3 = '',
				city = '',
				state = '',
				postalCode = '',
				country = 'US',
				noData,
			} = filteredData;

			if (noData) {
				return "";
			}

			const brPostfixIfSet = (value) => {
				return value
					? <span>{value}<br /></span>
					: null;
			};

			return (
				<span>
					{brPostfixIfSet(address1)}
					{brPostfixIfSet(address2)}
					{brPostfixIfSet(address3)}
					{cityStateZipStr({ city, state, postalCode })}<br />
					{country}
				</span>
			);
		}}
	</StdQuery>
);
