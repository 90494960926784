import React from 'react';
import cx from 'classnames';
import FundingPaymentMethods from 'components/FundingPaymentMethods.js';

import * as style from 'components/account/card/add-passes/TogglePaymentMethod.module.css';

export const cms = {};

const SelectPaymentMethodForm = ({
	provideNickName,
	formHelper,
	overwriteLabel = null,
	isRequired = true,
	isFirst = true,
	isSplit,
}) => (
	<div className={cx(style.lineItem, style.select)}>
		<div className={style.dropdownWrapper}>
			<FundingPaymentMethods {...{
				provideNickName,
				overwriteLabel,
				formHelper,
				isRequired,
				isFirst,
				isSplit,
			}} />
		</div>
	</div>
);

export default SelectPaymentMethodForm;
