// extracted by mini-css-extract-plugin
export var active = "components-__Pagination-module___active";
export var container = "components-__Pagination-module___container";
export var disabled = "components-__Pagination-module___disabled";
export var firstLink = "components-__Pagination-module___firstLink";
export var lastLink = "components-__Pagination-module___lastLink";
export var link = "components-__Pagination-module___link";
export var linkWrapper = "components-__Pagination-module___linkWrapper";
export var list = "components-__Pagination-module___list";
export var next = "components-__Pagination-module___next";
export var prev = "components-__Pagination-module___prev";