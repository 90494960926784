import React from 'react';
import { values } from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList';

import AccountLayout from 'layouts/AccountLayout.js';
import PanelPersonalInformation from 'components/account/panels/PersonalInformation.js';
import PanelUserPreferences from 'components/account/panels/UserPreferences.js';
import PanelManageAccounts from 'components/account/panels/ManageAccounts.js';
import PanelTermsAndConditions from 'components/account/panels/TermsAndConditions.js';
import { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import { IsNotAlternate } from "components/data/alternates/SessionAlternate.js";

import * as admin from 'layouts/Admin.module.css';
import { getCharlieCardBreadcrumbs } from '../CardSelection';
import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb';
import routeKeys from 'CustomerRouteKeys';
import { useBreadcrumbCallback } from 'context/BreadcrumbProvider';

const cms = {
	header: "miscText.personal-header",
	optIns: 'miscText.optin-header',
};
export const getPersonalInformationBreadcrumbs = () =>  [
	...getCharlieCardBreadcrumbs(),
	<BreadcrumbItem
		key={cms.header}
		cmsKey={cms.header}
		fallbackValue="Personal Information"
		routeKey={routeKeys.AccountPersonalInformation}
	>
	</BreadcrumbItem>,

];

const PersonalInformation = () => {
	useBreadcrumbCallback(getPersonalInformationBreadcrumbs);
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<AccountLayout
				title={findContentOrFallback(cmsContent, cms.header, "Personal Information")}
			>
				<div className={cx(admin.pageColWrapper, admin.isSplit)}>
					<div className={admin.mainCol}>
						<PanelPersonalInformation />

						<PanelTermsAndConditions />

						{/* Hidden from MBTA-1121, coming back eventually */}
						{/* <OptionalEulas
							type='panel'
							header={()=>(
								<CmsContentRenderer.H3
									className={panelStyle.heading}
									contentKey={cms.optIns}
									fallbackValue="Opt-Ins"
								/>
							)}
							showBar={false}
						/> */}
					</div>


					<div className={admin.secondaryCol}>
						<IsNotAlternate>
							<PanelManageAccounts />
						</IsNotAlternate>

						<PanelUserPreferences />
					</div>
				</div>
			</AccountLayout>
		)}</CmsContentList>
	);
};

export default PersonalInformation;
