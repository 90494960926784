import React, {
	useCallback,
	useContext, useMemo,
} from "react";

import {
	reduce,
	filter,
	get,
	head,
} from 'lodash';

import {
	levels,
	log,
} from "../utils/Logger.js";
import {
	Unrestricted,
	TransitOnly,
	Refundable,
	UNRESTRICTED,
	STORED_VALUE,
} from "../utils/Constants.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { GET_TRANSIT_ACCOUNT } from "components/data/transit-account/TransitAccount.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";

export const PurseBalanceContext = React.createContext([ {}, () => log(null, levels.error, "PurseBalanceContext used before it was ready") ]);
export const usePurseBalanceContext = () => useContext(PurseBalanceContext);

export const getTotalPurseBalance = purses => reduce(purses, (sum, { balance }) => sum + parseInt(balance), 0);

export const getSupportsTransferPurses = (purses) => filter(purses, ({ supportsTransfer, purseRestriction, purseType }) =>
	supportsTransfer
	&& (purseRestriction === UNRESTRICTED || purseRestriction === Refundable) // purse can be General Value or Refundable Value
	&& (purseType === STORED_VALUE)
); // Jon has confirmed this logic https://reflexions.slack.com/archives/CCF68M49M/p1639166419487000?thread_ts=1639162396.481800&cid=CCF68M49M

const PurseBalanceProvider = ({
	subsystemAccountReference: subsystemAccountReferenceProp = null,
	children,
}) => {
	const transitAccountId = useTransitAccountIdContext();
	const subsystemAccountReference = subsystemAccountReferenceProp ?? transitAccountId;

	const queryOptions = { skip: !subsystemAccountReference };
	const { data, loading } = useStdQuery(GET_TRANSIT_ACCOUNT, {
		variables: {
			subsystemAccountReference,
			subscriptions: false,
			programMemberships: false,
			transferProductInfo: false,
			productPayments: false,
		},
		...queryOptions,
	});

	const wSSubsystemAccountInfo = (loading || !subsystemAccountReference)
		? []
		: data?.transitAccountQ;
	const subSystemPurses = get(wSSubsystemAccountInfo, 'purses', []);

	// https://reflexions.slack.com/archives/GA82SPCTV/p1612285834086400
	// For Boston we should only have Unrestricted, TransitOnly, Refundable
	const filterPurses = filter(subSystemPurses, purse =>
		purse?.purseRestriction === Unrestricted || purse?.purseRestriction === TransitOnly || purse?.purseRestriction === Refundable
	);

	const getValidPurses = (type) => filter(filterPurses, purse => purse?.purseRestriction === type && purse.purseType === STORED_VALUE);

	const getParsedTotal = (type) => {
		const validPursesFromFilter = getValidPurses(type);
		const validPursesTotal = getTotalPurseBalance(validPursesFromFilter);
		return validPursesTotal;
	};

	const transitValue = getParsedTotal(Unrestricted); // Also called "General Transit" https://reflexions.slack.com/archives/CCF68M49M/p1616186327013400?thread_ts=1616184575.011400&cid=CCF68M49M
	const refundableValue = getParsedTotal(Refundable);
	const preTaxValue = getParsedTotal(TransitOnly); // Ref: https://reflexions.slack.com/archives/CCF68M49M/p1616186298013200?thread_ts=1616184575.011400&cid=CCF68M49M

	const purseTotal = transitValue + refundableValue + preTaxValue;

	const hasNegativeBalance = purseTotal < 0;

	const supportsTransferPurses = getSupportsTransferPurses(filterPurses);

	const supportsTransferTotal = getTotalPurseBalance(supportsTransferPurses);

	const generalValuePurse = head(getValidPurses(Unrestricted));

	const products = get(wSSubsystemAccountInfo, 'passes', []);
	const tokens = get(wSSubsystemAccountInfo, 'tokens', []);


	const contextValue = {
		filterPurses,
		transitValue,
		refundableValue,
		preTaxValue,
		purseTotal,
		hasNegativeBalance,
		generalValuePurse,
		supportsTransferTotal,
		products,
		tokens,
	};

	return (
		<PurseBalanceContext.Provider value={contextValue}>
			{children}
		</PurseBalanceContext.Provider>
	);
};

export default PurseBalanceProvider;
