/**
 * This is the Full width banner that appears above the Card Details
 * if the user in the unregistered user purchase charlie card flow.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Banner, { TYPE_WARNING } from 'components/Banner.js';
import Button, {
	GhostAlt,
} from 'components/Button.js';

const cms = {
	title: 'miscText["card-overview-banner-purchase.title"]',
	text: 'miscText["card-overview-banner-purchase.description"]',
	btn: 'miscText["card-overview-banner-purchase.button"]',
	close: 'miscText["general-banner-close"]',
};

const PurchaseCharlieCardBanner = ({
	handleBannerClose = null,
}) => {
	return (
		<CmsContentList list={values(cms)}>{() => (
			<Banner
				onBannerClose={handleBannerClose}
				closeText={<CmsContentRenderer.Span contentKey={cms.close} fallbackValue="Close" />}
				type={TYPE_WARNING}
				title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Purchase a Charlie Card" />}
				body={<CmsContentRenderer.P contentKey={cms.text} fallbackValue="Now that you've successfully created a registered account, you may purchase Charlie Cards online." />}
				rightSide={<Button to={getPathByRoute(routeKeys.PurchaseCharlieCard)}
					typeStyle={GhostAlt}
				>
					<CmsContentRenderer.Span contentKey={cms.btn} fallbackValue="Purchase Charlie Card" />
				</Button>}
			/>
		)}</CmsContentList>
	);
};

PurchaseCharlieCardBanner.propTypes = {
	handleBannerClose: PropTypes.func,
};

export default PurchaseCharlieCardBanner;
