import React from 'react';
import { values } from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys';
import { useModalContext } from 'context/ModalProvider.js';
import PreventDefault from 'utils/PreventDefault.js';

import Panel from 'components/Panel.js';
import Button, { Secondary } from 'components/Button.js';
import {
	DesktopContactlessIcon,
	MobileContactlessIcon,
	DesktopCharlieAddIcon,
	MobileCharlieAddIcon,
	DesktopAddMobileWalletIcon,
	MobileAddMobileWalletIcon,
	MobileCharlieMobileIcon,
	DesktopCharlieAddMobileIcon,
	MobileCharlieIcon,
	DesktopCharlieIcon,
} from 'components/Icon.js';
import CreateMobileCharlieCard from 'components/modals/CreateMobileCharlieCard.js';
import { useCustomerTravelCards } from 'components/data/session-user/useCustomerTravelCards.js';

import * as style from './AddAnotherCardActions.module.css';
import PublicAppVars from 'utils/PublicAppVars';




const cms = {
	addCardMobileAppHeader: 'miscText.dashboard-addcard-mobile-app-header',
	addCardMobileAppDescription: 'miscText.dashboard-addcard-mobile-app-description',
	addCardMobileAppCta: 'miscText.dashboard-addcard-mobile-app-cta',

	addCardSubHeader: 'miscText.dashboard-addcard-subheader',
	emptyStateSubHeader: 'miscText.dashboard-addcard-subheader-empty',
	addCardExisitng: 'miscText.dashboard-addcard-existing',
	addCardPurchse: 'miscText.dashboard-addcard-purchase',

	addCardMobileApp: 'miscText.dashboard-addcard-mobile-app',
	addCardEmv: 'miscText.dashboard-addcard-emv',
	addCardMobleWallet: 'miscText.dashboard-addcard-mobile-wallet',

	comingSoon: 'miscText["dashboard-addcard-comingsoon"]',
};

const AddAnotherCardActions = ({}) => {
	const { setModal } = useModalContext();
	const { data: customerTravelCardsData } = useCustomerTravelCards();

	const wsSubsystemAccountInfos = customerTravelCardsData?.subsystemAccountInfoQ ?? [];
	const hasTravelCards = (wsSubsystemAccountInfos?.length > 0);

	const openCreateMobileCharlieModal = () => {
		setModal(<CreateMobileCharlieCard />);
	};

	return (
		<Panel overrideClass={style.container}>
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>

				<CmsContentRenderer.H2
					contentKey={hasTravelCards
						? cms.addCardSubHeader
						: cms.emptyStateSubHeader}
					className={style.title}
					fallbackValue={hasTravelCards
						? "Add Another Charlie Card or Use Your Mobile Device"
						: "To get started, add a Charlie Card, order a new card by mail or add a Charlie Card to your mobile device."}
				/>

				<div className={style.actionsList}>
					<div className={style.actionsWrapper}>
						<Button
							type={Secondary}
							to={getPathByRoute(routeKeys.AddContactlessEMVCard)}
							additionalClassNames={style.action}
						>
							<div className={style.actionContainer}>
								<div className={style.content}>
									<div className={style.desktopIcon}>
										<DesktopContactlessIcon alt=" " />
									</div>
									<div className={style.mobileIcon}>
										<MobileContactlessIcon alt=" " />
									</div>
									<CmsContentRenderer.P
										contentKey={cms.addCardEmv}
										className={style.cardLabel}
										fallbackValue="Tap to ride with my credit card"
									/>
								</div>
							</div>
						</Button>

						<Button
							type={Secondary}
							to={getPathByRoute(routeKeys.AddMobileCard)}
							additionalClassNames={style.action}
						>
							<div className={style.actionContainer}>
								<div className={style.content}>
									<div className={style.desktopIcon}>
										<DesktopAddMobileWalletIcon alt=" " />
									</div>
									<div className={style.mobileIcon}>
										<MobileAddMobileWalletIcon alt=" " />
									</div>
									<CmsContentRenderer.P
										contentKey={cms.addCardMobleWallet}
										className={style.cardLabel}
										fallbackValue="Tap to ride with my mobile wallet"
									/>
								</div>
							</div>
						</Button>
					</div>

					<div className={style.actionsWrapper}>
						<Button
							type={Secondary}
							additionalClassNames={style.action}
							to={getPathByRoute(routeKeys.LinkCharlieCard)}
						>
							<div className={style.actionContainer}>
								<div className={style.content}>
									<div className={style.desktopIcon}>
										<DesktopCharlieIcon alt=" " />
									</div>
									<div className={style.mobileIcon}>
										<MobileCharlieIcon alt=" " />
									</div>

									<CmsContentRenderer.P
										contentKey={cms.addCardExisitng}
										className={style.cardLabel}
										fallbackValue="Use my existing Charlie Card"
									/>
								</div>

								{PublicAppVars.COMING_SOON_LINK_CHARLIE_CARD
									? <div className={style.comingSoon}>
										<CmsContentRenderer.Span
											contentKey={cms.comingSoon}
											fallbackValue="Coming soon"
										/>
									</div>
									: null
								}
							</div>
						</Button>

						{PublicAppVars.ENABLE_NEW_CARD_PURCHASE ?
							<Button
								typeStyle={Secondary}
								to={getPathByRoute(routeKeys.PurchaseCharlieCard)}
								additionalClassNames={style.action}
							>
								<div className={style.actionContainer}>
									<div className={style.content}>
										<div className={style.desktopIcon}>
											<DesktopCharlieAddIcon alt=" " />
										</div>
										<div className={style.mobileIcon}>
											<MobileCharlieAddIcon alt=" " />
										</div>
										<CmsContentRenderer.P
											contentKey={cms.addCardPurchse}
											className={style.cardLabel}
											fallbackValue="Order a new Charlie Card by mail"
										/>
									</div>
									{PublicAppVars.COMING_SOON_PURCHASE_CHARLIE_CARD
										? <div className={style.comingSoon}>
											<CmsContentRenderer.Span
												contentKey={cms.comingSoon}
												fallbackValue="Coming soon"
											/>
										</div>
										: null
									}
								</div>
							</Button>
							: null
						}

						{PublicAppVars.ENABLE_MOBILE_FARE_CARD
							? <Button
								type={Secondary}
								additionalClassNames={cx(style.action)}
								onClick={PreventDefault(() => openCreateMobileCharlieModal())}
							>
								<div className={style.actionContainer}>
									<div className={style.content}>
										<div className={style.desktopIcon}>
											<DesktopCharlieAddMobileIcon alt=" " />
										</div>
										<div className={style.mobileIcon}>
											<MobileCharlieMobileIcon alt=" " />
										</div>
										<CmsContentRenderer.P
											contentKey={cms.addCardMobileApp}
											className={style.cardLabel}
											fallbackValue="Create a mobile Charlie Card"
										/>
									</div>
									{PublicAppVars.COMING_SOON_MOBILE_CARD
										? <div className={style.comingSoon}>
											<CmsContentRenderer.Span
												contentKey={cms.comingSoon}
												fallbackValue="Coming soon"
											/>
										</div>
										: null
									}
								</div>
							</Button>
							: null
						}
					</div>
				</div>
			</>)}</CmsContentList>
		</Panel>
	);
};

AddAnotherCardActions.propTypes = {};

export default AddAnotherCardActions;
