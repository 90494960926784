// extracted by mini-css-extract-plugin
export var confirmationDetailsSection = "pages-account-card-__TransferProducts-module___confirmationDetailsSection";
export var confirmationDetailsWrapper = "pages-account-card-__TransferProducts-module___confirmationDetailsWrapper";
export var section = "pages-account-card-__TransferProducts-module___section";
export var transferBalance = "pages-account-card-__TransferProducts-module___transferBalance";
export var transferButton = "pages-account-card-__TransferProducts-module___transferButton";
export var transferButtonsWrapper = "pages-account-card-__TransferProducts-module___transferButtonsWrapper";
export var transferConfirmationSubheaders = "pages-account-card-__TransferProducts-module___transferConfirmationSubheaders";
export var transferProduct = "pages-account-card-__TransferProducts-module___transferProduct";
export var transferSubheader = "pages-account-card-__TransferProducts-module___transferSubheader";
export var transferValueInput = "pages-account-card-__TransferProducts-module___transferValueInput";
export var wrapper = "pages-account-card-__TransferProducts-module___wrapper";