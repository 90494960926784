// extracted by mini-css-extract-plugin
export var actions = "components-modals-__CorrectMissingTap-module___actions";
export var boldStyle = "components-modals-__CorrectMissingTap-module___boldStyle";
export var btn = "components-modals-__CorrectMissingTap-module___btn";
export var control = "components-modals-__CorrectMissingTap-module___control";
export var dateLabel = "components-modals-__CorrectMissingTap-module___dateLabel";
export var location = "components-modals-__CorrectMissingTap-module___location";
export var locationList = "components-modals-__CorrectMissingTap-module___locationList";
export var locationText = "components-modals-__CorrectMissingTap-module___locationText";
export var locationsWrapper = "components-modals-__CorrectMissingTap-module___locationsWrapper";
export var routeInfo = "components-modals-__CorrectMissingTap-module___routeInfo";
export var routeLabel = "components-modals-__CorrectMissingTap-module___routeLabel";
export var stationStopLabel = "components-modals-__CorrectMissingTap-module___stationStopLabel";
export var tapDateTime = "components-modals-__CorrectMissingTap-module___tapDateTime";
export var tapInfo = "components-modals-__CorrectMissingTap-module___tapInfo";
export var tapRowGap = "components-modals-__CorrectMissingTap-module___tapRowGap";
export var tripDateInfo = "components-modals-__CorrectMissingTap-module___tripDateInfo";
export var tripInfo = "components-modals-__CorrectMissingTap-module___tripInfo";