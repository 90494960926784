import React, { useContext } from "react";
import {
	levels,
	log,
} from "../utils/Logger.js";
import {
	autoloadSubsystemProduct,
	autoloadSubsystemValue,
	useAutoloadNextOccurrence,
} from 'components/data/transit-account/autoload/SubscriptionNextOccurrence.query.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import WSAutoloadCriteria, { AUTO_LOAD_CRITERIA_TYPES } from 'server/api-types/WSAutoloadCriteria.js';
import WSAutoloadFactory, { WSAUTOLOAD_TYPES } from 'server/api-types/WSAutoloadFactory.js';
import {
	map,
} from 'lodash';
import WSSubsystemOperator from 'server/api-types/WSSubsystemOperator.js';

export const AutoloadNextOccurrenceContext = React.createContext(() => log(null, levels.error, "AutoloadNextOccurrenceContext was used before it was ready"));
export const useAutoloadNextOccurrenceContext = () => useContext(AutoloadNextOccurrenceContext);

export const AutoloadNextOccurrenceContextProvider = ({ wsAutoloadSubscriptionInfo, autoLoadSuspended, children }) => {

	const subsystemAccountReference = useTransitAccountIdContext();

	const {
		autoload,
		criteria,
		startDate,
	} = wsAutoloadSubscriptionInfo;

	// the BO returns `nextDate` but lets rename to be more descriptive
	const { nextDate: autoloadNextOccurrenceDate } = useAutoloadNextOccurrence({
		subsystemAccountReference,
		[ autoload.type === WSAUTOLOAD_TYPES.addSubsystemValue
			? autoloadSubsystemValue
			: autoloadSubsystemProduct
		]: WSAutoloadFactory.buildClass({
			...autoload,
			permittedUsers: map(autoload.permittedUsers, wsSubsystemOperator =>
				new WSSubsystemOperator(wsSubsystemOperator)),
		}),
		autoloadCriteria: new WSAutoloadCriteria(criteria),
		startDate,

		queryOptions: { skip: autoLoadSuspended },
	});

	// Omit if there's no autoloadNextOccurrenceDate
	const hasNextAutoloadDate = (criteria?.type === AUTO_LOAD_CRITERIA_TYPES.recurring && wsAutoloadSubscriptionInfo.startDate)
		|| (autoload?.type === WSAUTOLOAD_TYPES.purchaseSubsystemProduct && autoloadNextOccurrenceDate);

	return (
		<AutoloadNextOccurrenceContext.Provider value={{ hasNextAutoloadDate, autoloadNextOccurrenceDate }}>
			{children}
		</AutoloadNextOccurrenceContext.Provider>
	);
};

export default AutoloadNextOccurrenceContext;
