import { gql } from "@apollo/client";
import useStdQuery from "components/data/hooks/useStdQuery.js";

export const autoloadSubsystemProduct = "autoloadSubsystemProduct";
export const autoloadSubsystemValue = "autoloadSubsystemValue";

export const GET_AUTOLOAD_SUBSCRIPTION_NEXT_OCCURRENCE = gql`
	query AutoloadSubscriptionNextOccurrence (
		$subsystemAccountReference: String!
		$autoloadSubsystemProduct: InputWSAutoloadSubsystemProduct
		$autoloadSubsystemValue: InputWSAutoloadSubsystemValue
		$autoloadCriteria: InputWSAutoloadCriteria
		$startDate: String
	) {
		TransitAccountRoute {
			getSubscriptionNextOccurrence (
				subsystemAccountReference: $subsystemAccountReference
				autoloadSubsystemProduct: $autoloadSubsystemProduct
				autoloadSubsystemValue: $autoloadSubsystemValue
				autoloadCriteria: $autoloadCriteria
				startDate: $startDate
			) {
				nextDate
			}
		}
	}
`;


export const useAutoloadNextOccurrence = ({
	subsystemAccountReference,
	autoloadSubsystemValue,
	autoloadSubsystemProduct, 
	autoloadCriteria,
	startDate,
	queryOptions,
}) => {

	const variables = {
		subsystemAccountReference,
		autoloadSubsystemValue,
		autoloadSubsystemProduct, 
		autoloadCriteria,
		startDate,
	};

	const queryResult = useStdQuery(GET_AUTOLOAD_SUBSCRIPTION_NEXT_OCCURRENCE, { variables, ...queryOptions });
	const { nextDate } = queryResult.data?.TransitAccountRoute.getSubscriptionNextOccurrence ?? {};

	return {
		nextDate,
		...queryResult,
	};
};