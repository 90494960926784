import { useApolloClient } from "@apollo/client";
import React, { useState } from 'react';
import { values, map, keys } from 'lodash';
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import * as yup from "yup";
import cx from 'classnames';
import { WireFormHelper } from "utils/FormHelper.js";
import { RadioGroup } from '@headlessui/react';

import CmsContentList from 'components/data/CmsContentList';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import PreventDefault from "utils/PreventDefault.js";
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";
import { useSessionCheckContext } from "../data/session-user/SessionCheck.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { UNREGISTERED_LOGIN } from "../data/transit-account/UnregisteredAccountLogin.query.js";
import { getPathByRoute } from "App.js";
import routeKeys from "CustomerRouteKeys.js";
import { levels, noticeError } from "utils/Logger.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import { addYupMethod } from "utils/YupValidators.js";

import Input from 'components/forms/Input.js';
import Button from 'components/Button.js';
import Tooltip from 'components/Tooltip.js';
import MaskedInput from "../forms/MaskedInput.js";

import * as style from 'pages/guest/ViewTransactionHistory.module.css';
import * as inputStyles from "components/forms/Input.module.css";
import * as forms from 'components/forms/Forms.module.css';
import * as addPaymentStyle from 'components/account/TabAddPaymentCard.module.css';
import CmsContentRenderer, { findContentOrFallback } from '../data/CmsContentRenderer.js';
import { LOGGED_IN_QUERY } from "components/data/session-user/LoggingIn.js";
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";
import PublicAppVars from "utils/PublicAppVars.js";
import CardNumberToolTip from "components/tooltips/CardNumber.js";

const cms = {
	charliePhysicalLabel: 'miscText.guest-login-charlie-physical',
	charliePhysicalCard: 'miscText.general-transit-charlie-physical-cardnum',
	charliePhysicalSecurity: 'miscText.general-transit-charlie-physical-security',
	charliePhysicalTooltip: 'miscText.general-transit-charlie-physical-security-tooltip',

	charlieVirtualLabel: 'miscText.guest-login-charlie-virtual',
	charlieVirtualNum: 'miscText.general-transit-charlie-virtual-transitnum',
	charlieVirtualNumToolTip: 'miscText.general-transit-charlie-virtual-transitnum-tooltip',
	charlieVirtualDpan: 'miscText.general-transit-charlie-virtual-dpan',
	charlieVirtualDpanTooltip: 'miscText.general-transit-charlie-virtual-dpan-tooltip',

	emvPhysicalLabel: 'miscText.guest-login-contactless-physical',
	emvWalletLabel: 'miscText.guest-login-contactless-wallet',
	appleLabel: 'miscText.guest-login-contactless-apple',

	emvCardNum: 'miscText.general-transit-cc-physical-cardnum',

	emvExpiry: 'miscText.general-transit-cc-physical-expiry',
	emvWalletCardNum: 'miscText.general-transit-cc-wallet-cardnum',
	emvWalletExpiry: 'miscText.general-transit-cc-wallet-expiry',

	appleTransitNum: 'miscText.general-transit-cc-apple-transitnum',
	appleTransitNumTooltip: 'miscText.general-transit-cc-apple-transitnum-tooltip',
	appleDpan: 'miscText.general-transit-cc-apple-dpan',
	appleDpanTooltip: 'miscText.general-transit-cc-apple-dpan-tooltip',

	next: 'miscText.general-nav-next',
};

export const TooltipCode = ({
	cmsContent,
	tooltipId,
	labelCmsKey,
	labelPanelCmsKey,
	labelFallbackValue,
	labelPanelFallbackValue,
}) => (
	<div className={cx(forms.title, forms.codeGroup)}>
		<Tooltip
			tooltipId={tooltipId}
			overrideClass={forms.toolTipIcon}
			ariaLabel={cmsContent[ labelCmsKey ] || labelFallbackValue}
			ariaLabelPanel={cmsContent[ labelPanelCmsKey ] || labelPanelFallbackValue}
		>
			<div className={addPaymentStyle.securityCodeTooltip}>
				<CmsContentRenderedInline contentKey={labelPanelCmsKey} fallbackValue={labelPanelFallbackValue} />
			</div>
		</Tooltip>
	</div>
);

const SmartcardInputs = ({ cmsContent, formHelper }) => {
	return (
		<>
			<Input
				labelClass={inputStyles.label}
				type={"text"}
				inputMode="numeric"
				id="charliecardNumber"
				name="cardNumber"
				label={cmsContent[ cms.charliePhysicalCard ]}
				data-qa="CardSerialInput"
				error={formHelper.getFieldError('cardNumber')}
			/>
			<div className={style.passwordWrapper}>
				<Input
					type={"text"}
					id="charliecardSecurityCode"
					name="cvv"
					overrideClass={style.input}
					inputMode="numeric"
					data-qa="CvnInput"
					label={findContentOrFallback(cmsContent, cms.charliePhysicalSecurity, 'Security code')}
					error={formHelper.getFieldError('cvv')}
					maxLength={3}
				/>
				<TooltipCode {...{
					cmsContent,
					tooltipId: 'charliecardSecurityCodeTooltip',
					labelCmsKey: cms.charliePhysicalSecurity,
					labelPanelCmsKey: cms.charliePhysicalTooltip,
					labelFallbackValue: 'Security code',
					labelPanelFallbackValue: 'Last 3 digits on the back',
				}} />
			</div>
		</>
	);
};

const BankcardInputs = ({ cmsContent, formHelper }) => {
	return (
		<>
			<Input
				type={"text"}
				labelClass={inputStyles.label}
				id="bankcardNumber"
				name="cardNumber"
				overrideClass={style.input}
				inputMode="numeric"
				label={
					<>
						<span>{cmsContent[ cms.emvCardNum ]}</span>
						<span>
							<CardNumberToolTip />
						</span>
					</>
				}
				data-qa="PanInput"
				error={formHelper.getFieldError('cardNumber')}
			/>
			<MaskedInput
				type="text"
				id="bankcardExpirationDate"
				name="expirationDate"
				label={cmsContent[ cms.emvExpiry ]}
				placeholder="MM / YY"
				options={{
					date: true,
					datePattern: [ 'm', 'y' ],

				}}
				data-qa="EMVCardExpireDate"
				error={formHelper.getFieldError('expirationDate')}
			/>

		</>
	);
};

const MobileInputs = ({ cmsContent, formHelper, cardType }) => {
	return (
		<>
			<div className={style.passwordWrapper}>
				<Input
					type={"text"}
					id={`${cardType}AccountId`}
					name={`cardNumber`}
					inputMode="numeric"
					overrideClass={style.input}
					label={cmsContent[ cms.charlieVirtualNum ] || 'Customer Service Id'}
					data-qa="CustomerServiceId"
					error={formHelper.getFieldError(`cardNumber`)}
				/>
				<TooltipCode {...{
					cmsContent,
					tooltipId: `${cardType}AccountIdTooltip`,
					labelCmsKey: cms.charlieVirtualNum,
					labelPanelCmsKey: cms.charlieVirtualNumToolTip,
					labelFallbackValue: 'Customer Service Id',
					labelPanelFallbackValue: 'Last 3 digits on the back',
				}} />
			</div>
			<div className={style.passwordWrapper}>
				<Input
					type={"text"}
					id={`${cardType}Dpan`}
					name={`cvv`}
					inputMode="numeric"
					overrideClass={style.input}
					label={cmsContent[ cms.appleDpan ] || 'Last 4 digits of DPAN'}
					data-qa="LastFourDpan"
					error={formHelper.getFieldError(`cvv`)}
					maxLength={4}
				/>
				<TooltipCode {...{
					cmsContent,
					tooltipId: `${cardType}DpanTooltip`,
					labelCmsKey: cms.appleDpan,
					labelPanelCmsKey: cms.charlieVirtualDpanTooltip,
					labelFallbackValue: 'Last 4 digits of DPAN',
					labelPanelFallbackValue: 'A contactless card allows you to pay by tapping the card on the reader...',
				}} />
			</div>
		</>
	);
};

const getYupSchema = ({
	tokenType,
	isAFC1Card = false,
	setIsAFC1Card,
}) => {
	addYupMethod("creditCard");

	let schema;

	switch (tokenType) {
		case TRAVEL_TOKEN_TYPES.bankcard:
			schema = {
				cardNumber: yup.string()
					.trim()
					.creditCard("miscHtml.general-payment-cc-error-cardnum")
					.required("miscHtml.general-payment-cc-error-cardnum"),

				expirationDate: yup.string()
					.matches(/[0-9]{2}\/[0-9]{2}/, "miscHtml.general-payment-cc-error-expiry")
					.required("miscHtml.general-payment-cc-error-expiry"),
			};
			break;
		case TRAVEL_TOKEN_TYPES.smartcard:
			schema = {
				cardNumber: yup.string()
					.test({
					//https://reflexions.atlassian.net/browse/MBTA-2907
						name: 'Old AFC1 card code',
						test: (value) => {

							// Only begin validation once we have a full card number, otherwise the normal CC validation will pick up the testing
							if (value.slice(0,2) === "5-") {
								setIsAFC1Card(true);
								return false;
							}
							else if (isAFC1Card) {
								setIsAFC1Card(false);
								return true;
							}

							return true;
						},
						message: () => <CmsContentRenderedInline
							contentKey={'miscHtml.addcard-standard-error-invalid'}
							fallbackValue={'Invalid Charlie Card'}
						/>,
					})
					.required("miscHtml.general-transit-charlie-physical-cardnum-error"),
				cvv: yup.string()
					.matches(/^[0-9]{3}$/, "miscHtml.general-transit-charlie-physical-security-error"),
			};
			break;
		case TRAVEL_TOKEN_TYPES.mobile:
			schema = {
				cardNumber: yup.string()
					.required("miscHtml.general-transit-cc-physical-cardnum-error"),
				cvv: yup.string()
					.matches(/^[0-9]{4}$/, "miscHtml.general-transit-charlie-physical-security-error")
					.required("miscHtml.general-transit-charlie-physical-security-error"),
			};
			break;
	}

	schema = { ...schema, ...recaptchaYupValidations };

	return yup.object().shape(schema);
};

export const CharlieCardFormType = 'charlieCard';
export const EmvCardFormType = 'emvCard';

const CardTypeFormMappings = {
	charlieCard: {
		charlieCardPhysical: {
			tokenType: TRAVEL_TOKEN_TYPES.smartcard,
			label: cms.charliePhysicalLabel,
			InputsComponent: SmartcardInputs,
		},
		charlieCardVirtual: {
			tokenType: TRAVEL_TOKEN_TYPES.virtualCard,
			label: cms.charlieVirtualLabel,
			InputsComponent: MobileInputs,
		},
	},
	emvCard: {
		emvCardPhysical: {
			tokenType: TRAVEL_TOKEN_TYPES.bankcard,
			label: cms.emvPhysicalLabel,
			InputsComponent: BankcardInputs,
		},
		emvCardWallet: {
			tokenType: TRAVEL_TOKEN_TYPES.bankcard,
			label: cms.emvWalletLabel,
			InputsComponent: BankcardInputs,
		},
		...(PublicAppVars.ENABLE_MOBILE_FARE_CARD && {
			applePay: {
				tokenType: TRAVEL_TOKEN_TYPES.mobile,
				label: cms.appleLabel,
				InputsComponent: MobileInputs,
			},
		}),
	},
};

const CardForm = ({
	isAFC1Card = false,
	setIsAFC1Card,
	cardType,
}) => {
	const { syntheticTimerEvent } = useSessionCheckContext();

	const [ formType, setFormType ] = useState(keys(CardTypeFormMappings[ cardType ])[ 0 ]);
	const { tokenType, InputsComponent } = CardTypeFormMappings[ cardType ][ formType ];
	const [ redirect, setRedirect ] = useState(null);

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema: () => getYupSchema({ tokenType, isAFC1Card, setIsAFC1Card }) });

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const apolloClient = useApolloClient();

	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {
			await checkRecaptcha();

			const validated = await formHelper.startValidation(true);
			GoogleAnalytics.logEvent("user is checking transactions");

			// Remove the / from the MMYY
			const cardExpiryMMYY = validated.expirationDate?.replace('/') ?? '';

			const {
				cardNumber,
				cvv,
				recaptchaValue,
			} = validated;

			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: UNREGISTERED_LOGIN,
					refetchQueries: [ { query: LOGGED_IN_QUERY } ],
					awaitRefetchQueries: true,
					variables: {
						tokenType,
						recaptchaValue,
						card: {
							cvv,
							cardNumber,
							cardExpiryMMYY,
						},
					},
				}),
			);

			// so that they're logged in by the time we send them here
			setRedirect(
				<Redirect push to={{
					pathname: getPathByRoute(routeKeys.GuestCardOverview),
				}} />,
			);

		} catch (errorReport) {
			resetRecaptcha();

			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Ungregistered signin Failed`);
			formHelper.validationErrorHandler(errorReport);
		} finally {
			// reloads the GET_CUSTOMER_CONTACT_IDS query, logging the user in
			// the mutation returns the new session, but apollo doesn't know that that session replaces the current session
			// should we reach into the apollo cache and clear that? probably..
			await syntheticTimerEvent();
			setSubmitting(false);
		}
	};

	if (redirect) {
		return redirect;
	}

	return (<CmsContentList list={values(cms)}>{({ cmsContent }) => (
		<>
			<WireFormHelper formHelper={formHelper}>
				<form
					 	data-qa="UnregisteredLoginForm"
					 	ref={formRef}
					 	onSubmit={PreventDefault(kickoffSubmit)}
					 >
					<section>
						<RadioGroup
							value={formType}
							onChange={(type) => {
								if (type === formType) {
									return;
								}

								formHelper.clearAllErrors();
								setIsAFC1Card?.(false);
								setFormType(type);
							}}
						>
							<RadioGroup.Label>
								<span />
							</RadioGroup.Label>
							<div className={forms.radioGroupOptions}>
								{map(CardTypeFormMappings[ cardType ], (form, name) => {
									return (
										<RadioGroup.Option
											key={name}
											value={name}
										>
											{({ checked }) => (
												<span className={cx(forms.radioLabel, checked ? forms.radioChecked : "")}>
													<CmsContentRenderer.Span
														contentKey={form.label}
														fallbackValue={'I use a physical Charlie Card'}
													/>
												</span>
											)}
										</RadioGroup.Option>
									);
								})}
							</div>
						</RadioGroup>
					</section>
					{InputsComponent && <>
						<InputsComponent {...{ formHelper, cmsContent, cardType }} />
						<Recaptcha />
						<div className={style.actions}>
							<Button
								isPrimary
								overrideClass={style.btn}
								data-qa="ViewCharlieCardNxtBtn"
								submitting={submitting}
								disabled={submitting}
							>
								<CmsContentRenderer.Span
									contentKey={cms.next}
									fallbackValue="Next"
								/>
							</Button>
							{formHelper.getFieldErrorJsx('')}
						</div>
					</>}
				</form>
			</WireFormHelper>
		</>
	)}</CmsContentList>);
};

CardForm.propTypes = {
	cardType: PropTypes.oneOf(keys(CardTypeFormMappings)),
};

export default CardForm;
