/**
 * This Banner appears in the CardLayout when the currently selected card
 * has a balance < 0, which also sets their status to Suspended
 */
import React, { useState } from 'react';
import { values } from 'lodash';

import CmsContentList from '../data/CmsContentList.js';
import CmsContentRenderer from '../data/CmsContentRenderer.js';
import CmsContentRendered from '../data/CmsContentRendered.js';

import Banner, { TYPE_ERROR } from '../Banner.js';
import { useIsEmvCard } from "../data/transit-account/EMV.helpers.js";

import PublicAppVars from "utils/PublicAppVars.js";
import Button, { Primary } from "../Button.js";
import * as style from "../account/CardSummary.module.css";
import useStandardMutation from "../data/hooks/useStandardMutation.js";
import { gql } from "@apollo/client";
import { getTransitAccountRefetchQueries } from "../data/transit-account/TransitAccount.js";
import { useModalContext } from "../../context/ModalProvider.js";
import Modal from "../Modal.js";
import { graphqlErrorMiddleware } from "../../utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { USER_TRAVEL_CARDS_QUERY } from "../data/session-user/SessionUser.js";

const CLEAR_DEBT = gql`
	mutation clearDebt(
		$subsystemAccountReference: String!
	) {
		clearDebt(subsystemAccountReference: $subsystemAccountReference)
	}
`;

const cms = {
	title: `miscText["card-overview-banner-negative.title"]`,
	text: `miscText["card-overview-banner-negative.description"]`,
	titleEmv: `miscText["card-overview-banner-negative-emv.title"]`,
	textEmv: `miscText["card-overview-banner-negative-emv.description"]`,
	button: `miscText["card-overview-banner-negative-emv.button"]`,
	successTitle: 'miscText["card-overview-negative-emv-success.title"]',
	successText: 'miscText["card-overview-negative-emv-success.description"]',
	errorTitle: 'miscText["card-overview-negative-emv-fail.title"]',
	errorText: 'miscText["card-overview-negative-emv-fail.description"]',
};

const SuccessModal = () => <Modal
	title={
		<CmsContentRendered.Span contentKey={cms.successTitle} fallbackValue={'Payment successful'} />
	}
>
	<CmsContentRendered.Div contentKey={cms.successText}
		fallbackValue={'Your payment has been processed. Your status is now active and your card can be used for travel.'} />
</Modal>;

const ErrorModal = ({ error }) => <Modal
	title={
		<CmsContentRendered.Span contentKey={cms.errorTitle} fallbackValue={'Payment unsuccessful'} />
	}
>
	<CmsContentRendered.Div contentKey={cms.errorText}
		fallbackValue={'Please resolve any issues with your payment method and try again.'} />
	{error && <div>{error}</div>}
</Modal>;


const SuspendedForNegativeBalance = ({ subsystemAccountReference }) => {
	const isEmv = useIsEmvCard({ subsystemAccountReference });
	const [ doClearDebt ] = useStandardMutation(CLEAR_DEBT, {
		refetchQueries: [
			...getTransitAccountRefetchQueries(subsystemAccountReference),
			{ query: USER_TRAVEL_CARDS_QUERY },
		],
		awaitRefetchQueries: true,
	});
	const [ submitting, setSubmitting ] = useState(false);
	const { setModal } = useModalContext();

	const kickoffSubmit = async () => {
		try {
			setSubmitting(true);

			await graphqlErrorMiddleware(doClearDebt({ variables: { subsystemAccountReference } }));
		} catch (errorReport) {
			setModal(<ErrorModal error={errorReport.display.topLevelMessage} />);
			return;
		} finally {
			setSubmitting(false);
		}

		setModal(<SuccessModal />);
	};

	return (
		<CmsContentList list={values(cms)}>{() => (
			<Banner
				showIcon
				type={TYPE_ERROR}
				title={<CmsContentRenderer.Span
					contentKey={isEmv ? cms.titleEmv : cms.title}
					fallbackValue="This Charlie Card is blocked due to a negative balance."
				/>}
				body={<CmsContentRenderer.Span
					contentKey={isEmv ? cms.textEmv : cms.text}
					fallbackValue="Bring the balance up to at least $0.00 to make the card active."
				/>}
				rightSide={isEmv && !PublicAppVars.ENABLE_EMV_RELOAD
					&& <Button
						onClick={kickoffSubmit}
						typeStyle={Primary}
						additionalClassNames={style.colBtn}
						submitting={submitting}
					>
						<CmsContentRenderer.Span
							contentKey={cms.button}
							fallbackValue="Retry Payment"
						/>
					</Button>
				}
			/>
		)}</CmsContentList>
	);
};

SuspendedForNegativeBalance.propTypes = {};

export default SuspendedForNegativeBalance;
