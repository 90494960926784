import React, { useState } from "react";
import {
	getCartSubtotal,
	getCartTotal,
	useCartContext,
} from 'context/CartProvider.js';
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import Input from 'components/forms/Input.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import { WireFormHelper } from 'utils/FormHelper.js';
import ErrorReport from "utils/error-handling/ErrorReport.js";
import { COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import { castNumberStringToNumber } from 'components/account/reload-balance/helpers.js';

import * as style from 'pages/account/purchase/ApplyStoredValueOption.module.css';

const cms = {
	inputLabel: 'miscText["purchase-payment-apply-balance.label"]',
};

export const numberAppendCents = (value) => {
	const hasCents = value % 1 != 0;

	const formattedValue = hasCents
		? value.toFixed(2)
		: (value * 1).toFixed(2);

	return formattedValue;
};

export const validateStoredValueOption = async ({
	cart,
	formHelper,
	purseTotal,
	required = true,
}) => {
	const formData = formHelper.getDataToValidate();

	GoogleAnalytics.logEvent("User is attempting to pay using stored value");

	const parsedAmount = formData?.storedValue
		? parseInt(formData.storedValue.replace(/[^0-9\.]+/g, "") * 100)
		: null;

	/** @todo add cms key */
	// https://reflexions.slack.com/archives/CAT36MFLZ/p1635257461130600
	if (parsedAmount > getCartSubtotal(cart)) {
		throw ErrorReport.newFromObj({
			FieldErrors: {
				// Applied stored value is greater than purchase total.
				storedValue: 'miscText.purchase-payment-apply-balance-error-exceeds-due',
			},
		});
	}

	if (required && !parsedAmount) {
		throw ErrorReport.newFromObj({
			FieldErrors: {
				// Please set an amount.
				storedValue: getErrorKey(COLLECTIONS.purchaseProducts, 'payments', 'errors.general.value.required'),
			},
		});
	}

	if (parsedAmount > purseTotal) {
		throw ErrorReport.newFromObj({
			FieldErrors: {
				// 'Applied stored value is greater than available stored value.
				storedValue: 'miscText.purchase-payment-apply-balance-error-exceeds-due',

			},
		});
	}

	return { storedValue: parsedAmount };
};

export const ApplyStoredValueOption = ({ formHelper }) => {
	const { cart, applyStoredValue } = useCartContext();
	const [ storedValue, setStoredValue ] = useState(cart.appliedStoredValue);

	const { purseTotal } = usePurseBalanceContext();

	// 1. No cart balance
	// 2. User must have purse total greater than 0
	// 3. Products that are fare based are not elidgible for storedValue payment
	const showBalanceApply = !cart.balance &&
		purseTotal > 0 &&
		cart.products.filter(product => !product.isFareBased).length > 0;

	if (!showBalanceApply) {
		return null;
	}

	const handleOnBlur = async () => {
		let validated;
		try {
			validated = await validateStoredValueOption({
				cart,
				formHelper,
				purseTotal,
				applyStoredValue,
				required: false,
			});
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `filters validation failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}
		applyStoredValue(validated.storedValue);
	};

	const onChangeCustomAmount = ({ target: { value } }) => {
		formHelper.getFieldError("storedValue") && formHelper.clearFieldErrors("storedValue");
		setStoredValue(castNumberStringToNumber(value));
	};

	return (<WireFormHelper {...{ formHelper }}>
		<Input
			name="storedValue"
			label={
				<CmsContentRenderedInline
					variables={{ balance: centsToDisplay(purseTotal) }}
					contentKey={cms.inputLabel}
					fallbackValue={`Apply Charlie Card balance to purchase (${centsToDisplay(purseTotal)} available)`}
				/>
			}
			overrideClass={style.currency}
			maxValue={40}
			formHelper={formHelper}
			onBlur={handleOnBlur}
			onChange={onChangeCustomAmount}
			controlled={true}
			value={storedValue ? centsToDisplay(storedValue) : ""}
			placeholder={centsToDisplay(0)}
			error={formHelper.getFieldError("storedValue")}
		/>
	</WireFormHelper>
	);
};

export default ApplyStoredValueOption;
