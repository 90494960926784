import React from 'react';
import {
	ORDER_TYPE,
	types,
} from 'server/api-types/WSPaymentHistoryFactory.js';
import { retailer } from "server/api-types/WSPaymentHistory.js";
import {
	DIRECT_PAY,
	REFUND_STATUS,
} from "utils/Constants.js";
import {
	log,
	levels,
} from 'utils/Logger.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";

const cms = {
	reload: 'miscText.purchasehistory-type-reload',
	autoload: 'miscText.purchasehistory-type-autoload',
	refund: 'miscText.purchasehistory-type-refund',
	directPay: 'miscText.triphistory-product-directpay',

	PartialRefundCompleted: 'miscText["purchasehistory-dispute-status.PartialRefundCompleted"]',
	FullRefundCompleted: 'miscText["purchasehistory-dispute-status.FullRefundCompleted"]',
	PartialRefundPending: 'miscText["purchasehistory-dispute-status.PartialRefundPending"]',
	FullRefundPending: 'miscText["purchasehistory-dispute-status.FullRefundPending"]',
	PartialRefundFailed: 'miscText["purchasehistory-dispute-status.PartialRefundFailed"]',
	FullRefundFailed: 'miscText["purchasehistory-dispute-status.FullRefundFailed"]',
	PartialRefundRejected: 'miscText["purchasehistory-dispute-status.PartialRefundRejected"]',
	FullRefundRejected: 'miscText["purchasehistory-dispute-status.FullRefundRejected"]',
	PreAccepted: 'miscText["purchasehistory-dispute-status.PreAccepted"]',
	NotApplicable:'miscText["purchasehistory-dispute-status.NotApplicable"]',
};

// as per Jon as of 02/10/2022
// there's no restriction to dispute order if pass is used
// The order dispute is allowed when:
// - Order must have a payment amount charged
// - Order was placed by the User (not placed by a group on behalf of the User) TODO not implemented yet
// - Not a PayGo Payment (use “Trip dispute” instead of “order dispute”)
// - No pending or finalized dispute against this order already.
// - If an order contains no line items that can refunded, the entire order is not eligible for refund
// - An enablement fee cannot be refunded as a line item TODO not implemented yet
// - purchased pass has used
export const isEligibleToDisputeOrder = (wsPaymentHistory, productType) => (Boolean((wsPaymentHistory.type === types.order
		&& Boolean(wsPaymentHistory.orderLineItems?.length) && wsPaymentHistory.allowsDispute)
	&& !wsPaymentHistory.orderRefundStatus
	&& productType !== DIRECT_PAY
	&& wsPaymentHistory.amount > 0
	&& wsPaymentHistory.origin !== retailer));

export const isPurchaseRefundPending = (wsPaymentHistory) => {
	return ((wsPaymentHistory.orderType === ORDER_TYPE.sale
			&& ((wsPaymentHistory.orderRefundStatus === REFUND_STATUS.PartialRefundPending)
				|| (wsPaymentHistory.orderRefundStatus === REFUND_STATUS.FullRefundPending)))
		|| (wsPaymentHistory.orderType === ORDER_TYPE.transfer
			&& ((wsPaymentHistory.orderRefundStatus === REFUND_STATUS.PartialRefundPending)
				|| (wsPaymentHistory.orderRefundStatus === REFUND_STATUS.FullRefundPending))));
};

export const isPurchaseRefundCompleted = (wsPaymentHistory) => {
	return (wsPaymentHistory.orderType === ORDER_TYPE.sale
		&& ((wsPaymentHistory.orderRefundStatus === REFUND_STATUS.PartialRefundCompleted)
			|| (wsPaymentHistory.orderRefundStatus === REFUND_STATUS.FullRefundCompleted)));
};

export const isPurchaseRefundRejected = (wsPaymentHistory) => {
	return (wsPaymentHistory.orderType === ORDER_TYPE.sale
		&& ((wsPaymentHistory.orderRefundStatus === REFUND_STATUS.PartialRefundRejected)
			|| (wsPaymentHistory.orderRefundStatus === REFUND_STATUS.FullRefundRejected)));
};

export const getProductType = ({
	isRefund,
	type,
	orderType,
}) => {

	if (isRefund) {
		return (
			<CmsContentRenderedInline contentKey={cms.refund}
				fallbackValue="Refund"
			/>
		);
	}

	if (orderType === ORDER_TYPE.autoload) {
		return (
			<CmsContentRenderedInline contentKey={cms.autoload}
				fallbackValue="Autoload"
			/>
		);
	}

	return type === types.order
		? <CmsContentRenderedInline contentKey={cms.reload}
			fallbackValue="Reload"
		/>
		: <CmsContentRenderedInline contentKey={cms.directPay}
			fallbackValue={DIRECT_PAY}
		/>;
};


export const wsPaymentHistoryIsRefund = wsPaymentHistory => {
	return wsPaymentHistory.type === types.travel
		? wsPaymentHistory.amount < 0
		: wsPaymentHistory?.orderType === ORDER_TYPE.refund;
};

const REFUND_STATUS_CMS_MAPPING = {
	[ REFUND_STATUS.PartialRefundCompleted ]: {
		cmsKey: cms.PartialRefundCompleted,
		fallbackValue: 'Partial refund completed',
	},
	[ REFUND_STATUS.FullRefundCompleted ]: {
		cmsKey: cms.FullRefundCompleted,
		fallbackValue: 'Full refund completed',
	},
	[ REFUND_STATUS.PartialRefundPending ]: {
		cmsKey: cms.PartialRefundPending,
		fallbackValue: 'Partial refund requested',
	},
	[ REFUND_STATUS.FullRefundPending ]: {
		cmsKey: cms.FullRefundPending,
		fallbackValue: 'Full refund requested',
	},
	[ REFUND_STATUS.PartialRefundFailed ]: {
		cmsKey: cms.PartialRefundFailed,
		fallbackValue: 'Partial refund failed',
	},
	[ REFUND_STATUS.FullRefundFailed ]: {
		cmsKey: cms.FullRefundFailed,
		fallbackValue: 'Full refund failed',
	},
	[ REFUND_STATUS.PartialRefundRejected ]: {
		cmsKey: cms.PartialRefundRejected,
		fallbackValue: 'Partial refund denied',
	},
	[ REFUND_STATUS.FullRefundRejected ]: {
		cmsKey: cms.FullRefundRejected,
		fallbackValue: 'Full refund denied',
	},
	[ REFUND_STATUS.PreAccepted ]: {
		cmsKey: cms.PreAccepted,
		fallbackValue: 'Refund pre-accepted',
	},
	[ REFUND_STATUS.NotApplicable ]: {
		cmsKey: cms.NotApplicable,
		fallbackValue: 'Refund not applicable',
	},
};

export const getPurchaseRefundStatusCmsKeyValue = (status) => {
	if (!status) {
		return null;
	}

	const {
		cmsKey = null,
		fallbackValue = null,
	} = REFUND_STATUS_CMS_MAPPING[ status ] ?? {};


	if (!cmsKey || !fallbackValue) {
		log(null, levels.error, `Unexpected order refund status ${status}`);
		return null;
	}
	return {
		cmsKey,
		fallbackValue,
	};
};
