import React from 'react';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_ERROR } from 'components/Banner';
import { values } from 'lodash';
import * as style from "./ComingSoon.module.css";
import Button, { buttonTypeStylePlain } from 'components/Button';

const cms = {
	title: 'miscText["dashboard-banner-beta.title"]',
	desc: 'miscText["dashboard-banner-beta.description"]',
	cta: 'miscText["dashboard-banner-beta.cta"]',
	ctaUrl: 'miscText["dashboard-banner-beta.cta-url"]',
};

const ComingSoon = () => {

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Banner
				showIcon
				type={TYPE_ERROR}
				title={<CmsContentRenderer.Span
					contentKey={cms.title}
					fallbackValue="This feature is not currently available to the public"
				/>}
				body={<CmsContentRenderer.Span
					contentKey={cms.desc}
					fallbackValue="This page is intended for specific test participants only. This feature will be available to the general public soon."
				/>}
				link={
					<Button
						typeStyle={buttonTypeStylePlain}
						overrideClass={style.plainBtn}
						type="button"
						to={cmsContent[ cms.ctaUrl ]}
						target="_blank"
						external
					>
						<CmsContentRenderer.Span
							contentKey={cms.cta}
							fallbackValue="This page is intended for specific test participants only. This feature will be available to the general public soon."
						/>
					</Button>
				}
			/>
		)}</CmsContentList>
	);
};

ComingSoon.propTypes = {};

export default ComingSoon;
