import { gql } from "@apollo/client";

export const ADD_FUNDING_SOURCE = gql`
	mutation addFundingSourceM (
		$card: InputWSCreditCardReference
		$inputCard: InputCard
		$billingAddress: InputWSAddress
		$billingAddressId: ID
		$setAsPrimary: Boolean
		$nickname: String
		# $programId: Int
		# $directDebit: InputWSDirectDebitClear
	) {
		CustomerMutationRoute {
			addFundingSource(
				card: $card
				inputCard: $inputCard
				billingAddress: $billingAddress
				billingAddressId: $billingAddressId
				setAsPrimary: $setAsPrimary
				nickname: $nickname
				# programId: $programId
				# directDebit: $directDebit
			) {
				fundingSourceId
			}
		}
	}
`;

export const DELETE_FUNDING_SOURCE = gql`
	mutation deleteFundingSourceM(
		$fundingSourceId: Int!
		$programId: Int
	) {
		deleteFundingSource(
			fundingSourceId: $fundingSourceId
			programId: $programId
		)
	}
`;

export const EDIT_FUNDING_SOURCE = gql`
	mutation editFundingSourceM(
		$fundingSourceId: Int!
		$wsCreditCardUpdate: InputWSCreditCardUpdate
		$setAsPrimary: Boolean
		$billingAddressId: String
		$nickname: String
		$isDirectDebit: Boolean
		$programId: Int
	) {
		editFundingSource(
			fundingSourceId: $fundingSourceId
			wsCreditCardUpdate: $wsCreditCardUpdate
			setAsPrimary: $setAsPrimary
			billingAddressId: $billingAddressId
			nickname: $nickname
			isDirectDebit: $isDirectDebit
			programId: $programId
		)
	}
`;

export const FUNDING_SOURCES_GET_QUERY = gql`
	query GET_FundingSources {
		session {
			directDebitFundingSourceCountRemaining
    		creditCardFundingSourceCountRemaining
    		directDebitFundingSourceAttemptsCountRemaining

			fundingSources {
				id
				billingAddressId
				creditCard {
					cardExpiryMMYY
					creditCardType
					maskedPan
					pgCardId
					nameOnCard
				}
				directDebit {
					financialInstitutionName
					#maskedBankAccountNumber
					accountType
				}
				fundingSourceId
				nickname
				setAsPrimary
				status
				statusDescription
			}
		}
	}
`;

export const FUNDING_SOURCES_B2B_GET_QUERY = gql`
	query GET_FundingSourcesB2b (
		$programId: Int!
	) {
		b2b {
			program(id: $programId) {
				# FundingSourceInfo
				fundingSources {
					creditCardFundingSourceCountRemaining
					fundingSourceCountRemaining

					# WSFundingSourceInfo
					fundingSources {
						id
						billingAddressId
						creditCard {
							cardExpiryMMYY
							creditCardType
							maskedPan
							pgCardId
							nameOnCard
						}
						directDebit {
							financialInstitutionName
							maskedBankAccountNumber
							accountType
						}
						fundingSourceId
						nickname
						setAsPrimary
						status
						statusDescription
					}
				}
			}
		}
	}
`;

export const POST_NEW_PAYMENT_ADDRESS = gql`
	mutation addNewPaymentAddressM ($address: InputWSAddress!) {
		CustomerMutationRoute {
			addressPost(address: $address) {
				addressId
				isExistingAddress
			}
		}
	}
`;

export const GET_ADDRESS_DEPENDENCIES = gql`
	query GET_AddressDependencies($addressId: ID!) {
		CustomerRoute {
			id
			addressDependenciesQ (addressId: $addressId) {
				contactDependencies {
					id
					contactId
					contactType
					name {
						firstName
						lastName
					}
					address {
						address1
						address2
						city
						state
						country
						postalCode
						addressId
					}
					phone {
						number
						type
						country
					}
					email
					dateOfBirth
					personalIdentifierInfo {
						personalIdentifier
						personalIdentifierType
					}
					username
					credentialStatus
				}
				fundingSourceDependencies {
					creditCard {
						cardExpiryMMYY
						creditCardType
						maskedPan
						pgCardId
						nameOnCard
					}
					directDebit {
						financialInstitutionName
						#maskedBankAccountNumber
						accountType
					}
					setAsPrimary
					billingAddressId
					nickname
					billingAddress {
						address1
						address2
						city
						state
						country
						postalCode
					}
					fundingSourceId
					status
					statusDescription
					declineCount
					isBlocked
				}
			}
		}
	}
`;

export const DELETE_ADDRESS = gql`
	mutation deleteAddressM(
		$addressId: ID!
	) {
		deleteAddress (
			addressId: $addressId
		) {
			# List<WSCustomerContactInfo>
			contactDependencies {
				id
				contactId
				contactType
				name {
					firstName
					lastName
				}
				address {
					address1
					address2
					city
					state
					country
					postalCode
					addressId
				}
				phone {
					number
					type
					country
				}
				email
				dateOfBirth
				personalIdentifierInfo {
					personalIdentifier
					personalIdentifierType
				}
				username
				credentialStatus
			}

			# List<WSFundingSourceInfo>
			fundingSourceDependencies {
				creditCard {
					cardExpiryMMYY
					creditCardType
					maskedPan
					pgCardId
					nameOnCard
				}
				directDebit {
					financialInstitutionName
					#maskedBankAccountNumber
					accountType
				}
				setAsPrimary
				billingAddressId
				nickname
				billingAddress {
					address1
					address2
					city
					state
					country
					postalCode
				}
				fundingSourceId
				status
				statusDescription
				declineCount
				isBlocked
			}
		}
	}
`;
