import {
	clone,
	map,
} from "lodash";

// Table 2-623. WSFundingSourceSetUpdateItem Field Descriptions
export default class WSFundingSourceUpdateSet {
	constructor({
		fundingSourceId,
		priority,
		maxPaymentAmount = null,
	}) {
		// int
		// (Required) The unique identifier of the funding source to make the payment with.
		this.fundingSourceId = fundingSourceId;
		// int
		// (Required) Priority of this funding source used to define the order in which the funding sources are used. A lower value is a higher priority (priority 1 will be charged before priority 2)
		this.priority = priority;
		// int
		// (Optional) Maximum amount to attempt to charge to this funding source.
		this.maxPaymentAmount = maxPaymentAmount;
	}

	static fromBackoffice() {
		const result = clone(this);

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		return forGraphql;
	}
}

export const WSFundingSourceUpdateSetFragment = `
	fundingSourceId: Int!
	priority: Int!
	maxPaymentAmount: Int
`;

export const InputWSFundingSourceUpdateSetType = [ `
	input InputWSFundingSourceUpdateSet {
		${WSFundingSourceUpdateSetFragment}
	}
` ];

export const WSFundingSourceUpdateSetType = [ `
	type WSFundingSourceUpdateSet {
		${WSFundingSourceUpdateSetFragment}
	}
` ];
