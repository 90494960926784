import { useApolloClient } from "@apollo/client";
import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { isEmpty } from 'lodash';
import { useCookies } from 'react-cookie';

import {
	levels,
	log,
	noticeError,
} from "utils/Logger.js";

import ClientAjax from 'utils/ClientAjax.js';
import PublicAppVars from "utils/PublicAppVars.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import { useLogoutContext } from "components/auth/Logout.js";

import { GetCustomerContactIds } from "./SessionUser.js";
import { GetVersion } from "components/data/Version.js";
import { GetCmsVersion } from "components/data/CmsVersion.js";
import RecaptchaEnabled from "components/data/RecaptchaEnabled.js";

import SessionTimeout from 'components/modals/SessionTimeout.js';
import { useRestartableInterval } from "utils/custom-react-hooks/useRestartableInterval.js";


export const SESSION_EXPIRED_BANNER = 'session-expired-banner';
const SESSION_TIMEOUT_COOKIE_NAME = 'idleTimeOut';

const pausedBy = {};

// Will expire after 1hr (SESSION_FRONTEND_TIMEOUT is in number of sec)
export const getfutureDate = () => new Date((new Date()).getTime() + (PublicAppVars.SESSION_FRONTEND_TIMEOUT * 1000));

// Right now there's only ever one SessionCheck on a page, but we could add checks here to deal with multiple
export const sessionCheckEnabled = () => isEmpty(pausedBy);
export const pauseSessionCheck = (pauser) => {
	pausedBy[ pauser ] = true;
};
export const unpauseSessionCheck = (pauser) => {
	delete pausedBy[ pauser ];
};

// can be used to force a check
export const SessionCheckContext = createContext({
	syntheticTimerEvent: async () => {
		log(null, levels.error, "SessionCheckContext.syntheticTimerEvent used before it was ready");
	},
	syntheticClick: () => {
		log(null, levels.error, "SessionCheckContext.syntheticClick used before it was ready");
	},
});

export const useSessionCheckContext = () => useContext(SessionCheckContext);

const isValidDate = (date) => {
	return date instanceof Date && !isNaN(date);
};

export const getCookieExpiresDate = (cookies) => {
	const cookieDateStr = cookies[ SESSION_TIMEOUT_COOKIE_NAME ];

	const expiredDate = new Date(1970, 0, 1);

	if (!cookieDateStr) {
		return expiredDate;
	}

	const date = new Date(cookieDateStr);

	if (!isValidDate(date)) {
		// no cookie or it had invalid date
		return expiredDate;
	}

	return new Date(date);
};

const recordActivity = (setCookie, restartSessionCheckInterval, expiryDate) => {
	// Note: We don't use server time in case useragent's clock is wrong
	try {

		// Will expire after 1hr (SESSION_FRONTEND_TIMEOUT is in number of sec)
		const futureDate = getfutureDate();

		if (expiryDate.getTime() + 10000 > futureDate.getTime()) {
			// performance optimization:
			// only update once every 10 seconds
			// especially noticeable savings when favoriting from the dropdown on schedules/my-locations when logged out
			return;
		}

		// https://www.npmjs.com/package/react-cookie#setname-value-options
		setCookie(SESSION_TIMEOUT_COOKIE_NAME, futureDate.toISOString(), {
			expires: futureDate,
			path: '/',
		});

		restartSessionCheckInterval();
	}
	catch (error) {
		noticeError(null, levels.error, error);
	}
};


// SessionCheck:
// - Shows a warning message to inactive users after SESSION_FRONTEND_TIMEOUT seconds
// - Logs out inactive users after SESSION_FRONTEND_TIMEOUT seconds
// - Reloads the page if the frontend has a different active user than the backend (e.g. logged in in a 2nd tab)
// - Reloads the page if there's a new deployed version
// - Reloads the apollo cache if there's new cms content
// - Reloads the recaptchaEnabled value in apollo cache if the recaptchaEnabled session boolean changes
// Notes:
// - The timeout is coordinated using a cookie so that activity in one tab will keep the others from logging out
const SessionCheck = ({ children }) => {
	const [ cookies, setCookie ] = useCookies([ SESSION_TIMEOUT_COOKIE_NAME, SESSION_EXPIRED_BANNER ]);
	const [ showDialog, setShowDialog ] = useState(false);
	const refetchRecaptchaEnabledRef = useRef(null);

	const logoutAndClearCache = useLogoutContext();
	const apolloClient = useApolloClient();

	const version = useRef(null);
	const cmsVersion = useRef(null);

	// client-side never gets the actual customer/contact id, but customers can see their transit account id, so that's not hashed
	// that leaves unregistered users with a little less protection, but it's the best we can do
	const customerIdHashed = useRef(null);
	const contactIdHashed = useRef(null);
	const transitAccountId = useRef(null);
	const recaptchaEnabled = useRef(null);

	const hasActiveFrontendSession = () => {
		return Boolean(customerIdHashed.current || transitAccountId.current);
	};

	const reloadRecaptchaEnabledQuery = () => {
		if (!refetchRecaptchaEnabledRef.current) {
			window.location.reload();
		}

		try {
			refetchRecaptchaEnabledRef.current();
		}
		catch (error) {
			noticeError(null, levels.info, error, "Unable to reloadRecaptchaEnabledQuery. Perhaps the query was invalidated with apolloClient.clearStore or is no longer being rendered.");
		}
	};

	const clearApolloOrReload = () => {
		apolloClient
			? apolloClient.resetStore()
			: window.location.reload();
	};

	const processLatestServerStatus = (responseData) => {
		if (!responseData.correctCredentials) {
			log(null, levels.info, "credentials changed");
			window.location.reload();
		}
		else if (responseData.version !== version.current) {
			log(null, levels.info, `version changed ${version.current} to ${responseData.version}`);
			window.location.reload();
		}
		else if (responseData.recaptchaEnabled !== recaptchaEnabled.current) {
			log(null, levels.info, `recaptcha enabled changed ${recaptchaEnabled.current} to ${responseData.recaptchaEnabled}`);
			reloadRecaptchaEnabledQuery();
		}
		else if (responseData.cmsVersion !== cmsVersion.current) {
			log(null, levels.info, `cms version changed ${cmsVersion.current} to ${responseData.cmsVersion}`);
			clearApolloOrReload();
		}
	};

	const serverSideCheck = async () => {
		// Note:
		// 1. we do this regardless of whether graphql thinks we're logged in
		//    because the user may have logged in in another tab
		// 2. making this request will update the timeout on the server-side
		try {
			const response = await restClientMiddleware(ClientAjax.get('/ajax/session-heartbeat', {
				params: {
					version: version.current, // the server doesn't actually use this, but it's nice to have in the access log
					customerId: customerIdHashed.current,
					contactId: contactIdHashed.current,
					transitAccountId: transitAccountId.current,
				},
			}));

			const responseData = response.data;

			processLatestServerStatus(responseData);
		}
		catch (error) {
			noticeError(null, levels.error, error, `session-heartbeat failed`);
		}
		finally {
			unpauseSessionCheck("timerEvent running");
		}
	};

	const timeExpired = () => {
		const expires = getCookieExpiresDate(cookies);
		return (new Date()).getTime() > expires.getTime();
	};

	const timeToWarn = () => {
		const warnTime = getCookieExpiresDate(cookies);
		warnTime.setSeconds(warnTime.getSeconds() - PublicAppVars.SESSION_FRONTEND_WARNING);
		return (new Date()).getTime() > warnTime.getTime();
	};

	const checkSessionTimeout = async () => {
		if (timeExpired()) {
			setShowDialog(false);

			await logoutAndClearCache();

			const futureDate = getfutureDate();

			// https://www.npmjs.com/package/react-cookie#setname-value-options
			setCookie(SESSION_EXPIRED_BANNER, futureDate.toISOString(), {
				expires: futureDate,
				path: '/',
			});
		}
		else if (timeToWarn()) {
			if (!showDialog) {
				setShowDialog(true);
				log(null, levels.info, `Idle time warning: will force logout in ${PublicAppVars.SESSION_FRONTEND_WARNING} sec`);
			}
		}
		else {
			// dialog may have been closed in another tab
			setShowDialog(false);
		}
	};

	//mounted(data) fn removed in dev branch

	const timerEvent = async () => {
		if (!sessionCheckEnabled()) {
			log(null, levels.debug, { message: "SessionCheck skipping timerEvent for pausedBy", pausedBy });
			return;
		}
		pauseSessionCheck("timerEvent running");

		// make sure frontend knows the right credentials / cms / etc
		await serverSideCheck();

		if (!hasActiveFrontendSession()) {
			// user isn't logged in. There's nothing to timeout.
			return;
		}

		await checkSessionTimeout();
	};

	const {
		restartInterval: restartSessionCheckInterval,
	} = useRestartableInterval(timerEvent, PublicAppVars.SESSION_CHECK_INTERVAL * 1000);

	const gotData = (data) => {
		[
			[ data.version, version ],
			[ data.cmsVersion, cmsVersion ],
			[ data.customerId, customerIdHashed ],
			[ data.contactId, contactIdHashed ],
			[ data.transitAccountId, transitAccountId ],
			[ data.recaptchaEnabled, recaptchaEnabled ],
		].forEach(([ dataValue, ref ]) => {
			ref.current = dataValue;
		});
	};

	useEffect(() => {
		const userClickedAnywhere = () => recordActivity(setCookie, restartSessionCheckInterval, getCookieExpiresDate(cookies));

		document.body.addEventListener('click', userClickedAnywhere, false);

		// cleanup
		return () => {
			document.body.removeEventListener('click', userClickedAnywhere, false);
		};
	}, [ cookies, setCookie, restartSessionCheckInterval ]);

	// const needsRefresh = () => {
	// 	const expires = getCookieExpiresDate(cookies);
	// 	const cookieCreatedTime = expires.getTime() - PublicAppVars.SESSION_FRONTEND_TIMEOUT * 1000;
	// 	const olderThanThisNeedsRefresh = (new Date()).getTime() - PublicAppVars.SESSION_CHECK_INTERVAL * 1000;
	// 	return cookieCreatedTime <= olderThanThisNeedsRefresh;
	// };

	const onRequestModalClose = () => {
		// closing the modal
		recordActivity(setCookie, restartSessionCheckInterval, getCookieExpiresDate(cookies));
		setShowDialog(false);
	};

	return (
		<SessionCheckContext.Provider value={{
			syntheticTimerEvent: async () => timerEvent(),
			syntheticClick: () => recordActivity(setCookie, restartSessionCheckInterval, getCookieExpiresDate(cookies)),
		}}>
			{children}
			<RecaptchaEnabled>{(recaptchaEnabled, { refetch: refetchRecaptchaEnabled }) => (
				<GetCustomerContactIds>{({ customerId, contactId, transitAccountId }) => (
					<GetVersion>{({ version }) => (
						<GetCmsVersion>{({ cmsVersion }) => {
							refetchRecaptchaEnabledRef.current = refetchRecaptchaEnabled;

							const data = {
								version,
								cmsVersion,
								customerId,
								contactId,
								transitAccountId,
								recaptchaEnabled,
							};
							gotData(data);

							return (
								<SessionTimeout
									modalActive={showDialog}
									onRequestClose={onRequestModalClose}
								/>
							);
						}}</GetCmsVersion>
					)}</GetVersion>
				)}</GetCustomerContactIds>
			)}</RecaptchaEnabled>
		</SessionCheckContext.Provider>
	);
};

export default SessionCheck;
