import { gql } from "@apollo/client";
import React from 'react';
import StdQuery from "components/data/StdQuery";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware";
import {
	levels,
	noticeError,
} from "utils/Logger";
import { SIZES } from 'components/icons/LoadingIcon.js';

export const GET_AUTOLOAD_SUBSCRIPTION_INFO = gql`
	query AutoloadSubscriptionInfoGET (
		$subsystemAccountReference: String!
		$subscriptionId: String!
	) {
		TransitAccountRoute {
			id
			getSubscriptionInfo (
				subsystemAccountReference: $subsystemAccountReference
				subscriptionId: $subscriptionId
			) {
				# WSAutoloadSubscriptionInfo
				subscription {
					id
					criteria {
						type
						recurrence
						day
						frequency
						thresholdValue
						daysBeforeExpiryToTrigger
					}
					autoload {
						type
						loadValueBehavior
						...on WSAutoloadSubsystemValue {
							id
							value
							purseRestriction
							purseType
							subsystemAccountId
							subsystemRef
						}
						...on WSAutoloadSubsystemProduct {
							id
							productName
							productSKU
							productCost
							productTypeId
							travelTokenId
							subsystemAccountId
							subsystemRef
							permittedUsers {
								operatorId
								operatorName
							}
						}
					}
					# WSFundingSourceSetInfo
					fundingSourceSet {
						id
						# WSFundingSourceSetItem
						fundingSources {
							fundingSourceId
							maxPaymentAmount
							priority
							# WSCreditCardReference
							creditCard {
								maskedPan
								creditCardType
							}
						}
						fundingSourceSetId
					}
					startDate
					status
					subscriptionId
					# lastTriggeredDate
				}
			}
		}
	}
`;

export const getSubscriptionInfo = async (
	formHelper,
	apolloClient,
	setSubmitting,
	subsystemAccountReference,
	subscriptionId,
) => {
	let queryResult;
	try {
		queryResult = await graphqlErrorMiddleware(
			apolloClient.query({
				query: GET_AUTOLOAD_SUBSCRIPTION_INFO,
				variables: {
					subsystemAccountReference,
					subscriptionId,
				},
				fetchPolicy: 'network-only',
			}));
	}
	catch (errorReport) {
		// we're not redirecting anywhere. Prepare the form for the next submit.
		noticeError(null, levels.info, errorReport, `Get subscription info failed`);
		formHelper.validationErrorHandler(errorReport);
		setSubmitting(false);
		return;
	}

	const { data: { TransitAccountRoute: { getSubscriptionInfo: { subscription } } } } = queryResult;

	return { subscription };
};

const SubscriptionInfo = ({
	children,
	subsystemAccountReference,
	subscriptionId,
	...rest
}) => {
	const options = {
		variables: { subsystemAccountReference, subscriptionId },
		displayName: "Autoload Subscription Info Query",
		...rest,
	};
	return <StdQuery {...options}
		query={GET_AUTOLOAD_SUBSCRIPTION_INFO}
		fetchPolicy={"network-only"}
		spinnerSize={SIZES.component}
	>
		{(queryResult) => {
			const { TransitAccountRoute: { getSubscriptionInfo: { subscription } } } = queryResult.data;

			return children(subscription, queryResult);
		}}
	</StdQuery>;
};

export default SubscriptionInfo;
