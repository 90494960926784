import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import {
	values,
	get,
	map,
	head,
} from 'lodash';
import { WireFormHelper } from 'utils/FormHelper.js';
import Cart from 'components/payments/Cart.js';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import { useTransitAccountProductCatalog } from 'components/data/transit-account/ProductCatalog.query';
import { getAmountDue, getCartTotal, useCartContext } from 'context/CartProvider.js';
import TakeOverLayout from 'layouts/TakeOverLayout.js';
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";
import CmsContentList from 'components/data/CmsContentList';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import SelectPaymentMethodForm from 'components/account/card/add-passes/SelectPaymentMethodForm.js';

import {
	getPrimaryBillingAddressSchema,
	getPrimaryPaymentMethodSchema,
} from '../purchase/PurchaseProductPayment.js';
import { types } from 'server/api-types/WSLoadProductFactory.js';

import ProductCard from 'components/account/ProductCard.js';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import FundingSourcesQueryProvider, {
	useFundingSourcesQueryContext,
} from 'components/account/reload-balance/FundingSourcesQueryProvider.js';
import Input from 'components/forms/Input.js';
import Button, { Secondary } from 'components/Button.js';
import PreventDefault from 'utils/PreventDefault.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import NewProductAdded from 'components/toasts/NewProductAdded.js';
import { ApplyStoredValueOption } from 'pages/account/purchase/ApplyStoredValueOption.js';
import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { EnablementFee } from 'pages/account/PurchaseCharlieCardCcForm.js';
import { getMasterTokenInfo } from 'components/manage-cards/TokenHelpers.js';
import { isUnpaidFee } from 'pages/account/purchase/StandardPurchaseFlow.js';
import WSFeeDueLineItem from 'server/api-types/WSFeeDueLineItem.js';
import WSSubsystemAccountFeeDue from 'server/api-types/WSSubsystemAccountFeeDue.js';
import { SubsystemAccountFeeDue, TransitAccountTokenEnablementFee } from 'server/api-types/WSFeeDue.js';
import TransitAccountIdContext, { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import ProductCatalogContext from "components/data/transit-account/ProductCatalogContext.js";

import {
	SUBSYSTEM_ID,
} from 'utils/Constants.js';

import * as styles from './ReloadBalanceTakeover.module.css';
import WSLoadSubsystemProduct from 'server/api-types/WSLoadSubsystemProduct.js';
import FundingSourcesProvider, { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import { FULL_PURCHASE_MUTATION } from '../PurchasePass.js';
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { GET_TRANSIT_ACCOUNT } from 'components/data/transit-account/TransitAccount.js';
import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";
import { getPayments } from "utils/payment/getPayments.js";
import { getYupSchema as getAddPaymentMethodYupSchema } from "pages/account/purchase/AddPaymentMethod.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";

const cms = {
	pageTitle: 'miscText.purchase-header-reload-pass',
	panelHeader: 'miscText.purchase-reload-selection',
	panelSubHeader: 'miscText.purchase-card-3-payment-subheader',
	promoLabel: 'miscText["purchase-cart-promo-label.label"]',
	applyLabel: 'miscText.purchase-cart-promo-submit',
	submitButtonLabel: 'miscText.purchase-cart-submit',
};

// Exported only for the jest tests.
export const ReloadPassBody = ({
	formHelper,
	selectedProduct,
}) => {
	const { addProduct } = useCartContext();

	useEffect(() => {
		addProduct(selectedProduct);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.section}>
				<CmsContentRenderer.H2 contentKey={cms.panelHeader} fallbackValue="You Have Selected" />
				<ProductCard parentFormHelper={formHelper} products={[ selectedProduct ]} hideAddToCart={true} />
			</div>
			<div className={styles.section}>
				<CmsContentRenderer.H2 contentKey={cms.panelSubHeader} fallbackValue="Enter Payment Details" />
				<ApplyStoredValueOption formHelper={formHelper} />
				<SelectPaymentMethodForm
					formHelper={formHelper}
					provideNickName={false}
				/>
			</div>
		</div>
	);
};

// Exported only for the jest tests.
export const ReloadPassTakeover = () => {
	const [ newAddress, setNewAddress ] = useState(false);
	const [ newPaymentMethod, setNewPaymentMethod ] = useState(false);
	const { product_id } = useParams();
	const { loginStage } = useLoginStage();

	const unRegisteredLoggedIn = loginStage === loginStages.unRegisteredLoggedIn;
	const {
		cart,
		addUnpaidFee,
		setNegativeBalance,
	} = useCartContext();

	const [ redirect, setRedirect ] = useState(null);
	const { setToast } = useGlobalToastsContext();
	const subsystemAccountReference = useTransitAccountIdContext();
	const { purseTotal, hasNegativeBalance, tokens } = usePurseBalanceContext();
	const { loadProductLineItemFunds } = useFundingSourcesContext();
	const productCatalogResult = useTransitAccountProductCatalog({ subsystemAccountReference });

	const {
		products,
	} = productCatalogResult;

	const [ purchaseMutator ] = useStandardMutation(
		FULL_PURCHASE_MUTATION, {
			refetchQueries: [ {
				query: GET_TRANSIT_ACCOUNT, variables: {
					subsystemAccountReference,
					subscriptions: true,
					archivedTokens: false,
					programMemberships: false,
					transferProductInfo: false,
					productPayments: false,
				},
			} ],
		},
	);

	const masterTokenInfo = getMasterTokenInfo(tokens);
	const token = head(tokens);
	const { status, feeDueAmount } = masterTokenInfo?.tokenEnablementFee ?? {};
	const hasUnpaidFee = isUnpaidFee(status);

	const {
		isMultiPayment,
		selectedFundingSources,
	} = useFundingSourcesContext();

	const total = getCartTotal(cart);

	const getYupSchema = (formHelper) =>
		getAddPaymentMethodYupSchema({
			formHelper,
			isMultiPayment,
			isSelecting: [ false ],
			isAch: [ false ],
			requireAchEula: false,
			isNew: [ selectedFundingSources[ 0 ]?.isNew ],
			hasMobilePayment: false,
			total,
			disableCvv: total === 0,
		})
			.concat(getPrimaryPaymentMethodSchema())
			.concat(getPrimaryBillingAddressSchema({}));

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema });

	useEffect(() => {
		if (hasNegativeBalance && !cart.negativeBalance) {
			setNegativeBalance(purseTotal);
		}

	}, [ cart.negativeBalance, hasNegativeBalance, purseTotal, setNegativeBalance ]);

	useEffect(() => {
		if (hasUnpaidFee && !cart.unpaidFee) {
			addUnpaidFee(feeDueAmount);
		}
	}, [ addUnpaidFee, cart.unpaidFee, feeDueAmount, hasUnpaidFee ]);

	const completePurchase = useCallback(() => {
		setToast(<NewProductAdded {...{ cart }} />);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(
					unRegisteredLoggedIn
						? routeKeys.GuestCardOverview
						: routeKeys.AccountCardOverview,
					{ transit_account_id: subsystemAccountReference },
				),
			}} />,
		);
	}, [ unRegisteredLoggedIn, subsystemAccountReference, setToast, cart ]);

	const selectedProduct = products?.find(product => product.productSku === product_id) ?? {};

	const {
		productName,
		productSku,
		price,
		permittedUsers,
		validityStartDtm,
	} = selectedProduct;

	const wsLoadSubsystemProduct = useMemo(() => new WSLoadSubsystemProduct({
		subsystem: SUBSYSTEM_ID,
		productCost: price,
		productLineItemType: types.loadSubsystemProduct,
		subsystemAccountReference,
		productSKU: productSku,
		productReceiptName: productName,
		permittedUsers: map(permittedUsers, wsSubsystemOperator => ({ operatorId: wsSubsystemOperator.operatorId })),
		productName,
		validityStartDtm,
	}), [ permittedUsers, price, productName, productSku, subsystemAccountReference, validityStartDtm ]);

	const finalizeFundingSourcesFromForm = useFinalizeAndSetFundingSourcesToContext({ formHelper });

	const kickoffSubmit = async () => {
		const amountDue = getAmountDue(cart);
		setSubmitting(true);

		try {
			const selectedFundingSources = await finalizeFundingSourcesFromForm();

			const payments = getPayments({
				cart,
				selectedFundingSources,
				loadProductLineItemFunds,
			});

			const shoppingCartId = get(cart, 'shoppingCartId', "");

			const variables = {
				products: [ wsLoadSubsystemProduct ],
				amountDue,
				payments: map(payments, wsPayment => wsPayment.toInputWSPaymentFactory()),
				shoppingCartId,
			};

			if (hasUnpaidFee && token) {
				variables.feeDueLineItems = new WSFeeDueLineItem({
					feeDue: new WSSubsystemAccountFeeDue({
						feeDueAmount: cart.unpaidFee,
						feeDueLineItemType: SubsystemAccountFeeDue,
						feeDueType: TransitAccountTokenEnablementFee,
						subsystem: SUBSYSTEM_ID,
						subsystemAccountReference,
						tokenId: token.tokenId,
					}),
				});
			}

			await graphqlErrorMiddleware(purchaseMutator({ variables }));

			setSubmitting(false);
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		completePurchase();
	};

	if (!products) {
		return null;
	}

	if (redirect) {
		return redirect;
	}

	return (
		<FundingSourcesQueryProvider>
			<ProductCatalogContext.Provider value={productCatalogResult}>
				<ReloadPassTakeoverBody
					formRef={formRef}
					formHelper={formHelper}
					selectedProduct={selectedProduct}
					submitting={submitting}
					product={wsLoadSubsystemProduct}
					setNewAddress={setNewAddress}
					setNewPaymentMethod={setNewPaymentMethod}
					newAddress={newAddress}
					newPaymentMethod={newPaymentMethod}
					hasUnpaidFee={hasUnpaidFee}
					onSubmit={kickoffSubmit}
				/>
			</ProductCatalogContext.Provider>
		</FundingSourcesQueryProvider>
	);
};


const ReloadPassTakeoverBody = ({
	formHelper,
	product,
	formRef,
	submitting,
	selectedProduct,
	hasUnpaidFee,
	onSubmit,
}) => {

	const { getFundingSources, loading } = useFundingSourcesQueryContext();

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Lifecycles didMount={() => getFundingSources({ products: [ product ], formHelper })}>
				<Lifecycles didMount={() => formHelper.wireInputs()}>
					<WireFormHelper {...{ formHelper }}>
						<form
							data-qa="reloadPassTakeoverForm"
							ref={formRef}
							onSubmit={PreventDefault(onSubmit)}
						>
							{formHelper.getFieldErrorJsx('')}
							<TakeOverLayout
								title={<CmsContentRenderer.Span contentKey={cms.pageTitle}
									fallbackValue="Reload Pass" />}
								showTabs={false}
								showCancel
								showNickname
							>
								<div className={styles.reloadBalanceWrapper}>
									{!loading && <ReloadPassBody
										selectedProduct={selectedProduct}
										formHelper={formHelper}
									/>}
									<div className={styles.sideBar}>
										{hasUnpaidFee && <EnablementFee overrideClass={styles.enablementFee} />}
										<Input label={cmsContent[ cms.promoLabel ] ?? 'Promo Code'} />
										<Button typeStyle={Secondary}>
											<CmsContentRenderer.Span contentKey={cms.applyLabel}
												fallbackValue="Apply" />
										</Button>
									</div>
								</div>
							</TakeOverLayout>
							<Cart
								disableDelete={true}
								disableNext={loading}
								nextText={cmsContent[ cms.submitButtonLabel ] ?? 'Complete Payment'}
								{...{ submitting, formHelper }}
							/>
						</form>
					</WireFormHelper>
				</Lifecycles>
			</Lifecycles>
		)}</CmsContentList>
	);
};


const ReloadPassTakeoverWithData = (props) => (
	<FundingSourcesProvider>
		<TransitAccountIdContext.Consumer>{(subsystemAccountReference) => (
			<PurseBalanceProvider {...{ subsystemAccountReference }}>
				<ReloadPassTakeover />
			</PurseBalanceProvider>
		)}</TransitAccountIdContext.Consumer>
	</FundingSourcesProvider>
);
export default ReloadPassTakeoverWithData;
