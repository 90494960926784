/**
 * This is the Full width banner that appears above the Card Details
 * if the user hasn't yet setup security questions.
 */
import React from 'react';
import { values } from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Banner, { TYPE_WARNING } from 'components/Banner.js';
import Button, {
	GhostAlt,
} from 'components/Button.js';
import useStdQuery from 'components/data/hooks/useStdQuery';
import { GET_CUSTOMER_SECURITY_QUESTIONS } from 'components/data/customer/SecurityQuestions';



const cms = {
	title: 'miscText["security-q-banner.title"]',
	text: 'miscText["security-q-banner.description"]',
	btn: 'miscText["security-q-banner.cta"]',
};

const SetupSecurityQuestions = () => {
	const { loading, data } = useStdQuery(GET_CUSTOMER_SECURITY_QUESTIONS);

	if (loading) {
		return null;
	}

	// Do not display the banner if the user has setup security questions
	const customerSecurityQuestions = data?.session.customer?.contact.securityQAs ?? []; // WSSecurityQA[]
	if (customerSecurityQuestions.length > 0) {
		return null;
	}

	return (
		<CmsContentList list={values(cms)}>{() => (
			<Banner
				closeText={<CmsContentRenderer.Span contentKey={cms.close} fallbackValue="Close" />}
				type={TYPE_WARNING}
				title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Set up security questions to complete registration" />}
				body={<CmsContentRenderer.P contentKey={cms.text} fallbackValue="Set up security questions to enable password recovery and access full customer support." />}
				rightSide={<Button to={getPathByRoute(routeKeys.AccountSecurity)}
					typeStyle={GhostAlt}
				>
					<CmsContentRenderer.Span contentKey={cms.btn} fallbackValue="Go to security settings" />
				</Button>}
			/>
		)}</CmsContentList>
	);
};

SetupSecurityQuestions.propTypes = {};

export default SetupSecurityQuestions;