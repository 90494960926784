import React from 'react';
import cx from 'classnames';
import { useCartContext, getCartTotal } from 'context/CartProvider.js';
import { getCardNickNameOrType } from 'components/account/card/add-passes/TogglePaymentMethod.js';
import { centsToDisplay } from 'utils/FormatHelpers';
import {
	get,
	pickBy,
} from 'lodash';
import StdQuery from '../data/StdQuery';
import {
	findWSAddressExtByIdOrNew,
	GET_CUSTOMER_ADDRESSES, GET_CUSTOMER_AND_ALTERNATE_ADDRESSES,
} from 'components/data/session-user/Addresses.query.js';
import {
	cityStateZipStr,
	GET_CONTACT_ADDRESS,
} from 'components/data/session-user/Address.js';
import WSAddressExt from 'server/api-types/WSAddressExt.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import { CardImage } from 'components/icons/CardBrands.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

import { useIsEmvCard } from "components/data/transit-account/EMV.helpers";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext";

import * as style from './PurchasePanel.module.css';
import { useLoginStage } from 'components/data/session-user/LoggingIn';
import loginStages from 'components/data/session-user/LoginStages';
import useStdQuery from "components/data/hooks/useStdQuery.js";

const cms = {
	billingAddress: "miscText.purchase-card-4-billing-address",
	billingSubheader: "miscText.purchase-card-4-billing-payment",
};

const PurchaseBillingPanel = () => {
	const { cart } = useCartContext();
	const subsystemAccountReference = useTransitAccountIdContext();

	const { loginStage } = useLoginStage();
	const isEmvCard = useIsEmvCard({ subsystemAccountReference });

	const { selectedFundingSources } = useFundingSourcesContext();

	const wsFundingSourceInfo = selectedFundingSources[ 0 ]?.fundingSource ?? {};

	const { data: billingAddressData } = useStdQuery(GET_CUSTOMER_AND_ALTERNATE_ADDRESSES, {
		skip: !selectedFundingSources || Boolean(wsFundingSourceInfo.billingAddress),
	});

	if (!selectedFundingSources) {
		return null;
	}

	const {
		creditCard: {
			nickname,
			creditCardType,
			maskedPan,
		} = {},
		billingAddressId,
	} = wsFundingSourceInfo ?? {};

	// Card is not EMV
	// & a new card is selected or the user is unregistered
	const isUnregisteredEmv = isEmvCard && loginStage === loginStages.unRegisteredLoggedIn;
	const wsAddressExts = billingAddressData?.session.customer.addresses
		.concat(billingAddressData?.session.alternateSession?.customer.addresses ?? []);
	const billingAddress = wsFundingSourceInfo.billingAddress ?? findWSAddressExtByIdOrNew(wsAddressExts, billingAddressId);

	const {
		address1 = '',
		address2 = '',
		city = '',
		state = '',
		postalCode = '',
	} = billingAddress;

	const sessAddressLabel = `${address1}${address2 ? ', ' + address2 : ''}, ${cityStateZipStr({ city, state, postalCode })}`;

	return (
		<div className={style.container}>
			<div>
				<CmsContentRenderedInline
					className={style.subheader}
					contentKey={cms.billingSubheader}
					fallbackValue="Payment Information"
				/>
				<div className={cx(style.item, style.billingCard)}>
					<div>
						<CardImage
							creditCardType={creditCardType}
							className={style.ccIcon}
						/>
						{getCardNickNameOrType(nickname, creditCardType)} ...{maskedPan}
					</div>
					<div className={style.billingAmount}>
						{centsToDisplay(getCartTotal(cart))}
					</div>
				</div>
			</div>
			{!isUnregisteredEmv &&
				<div>
					<CmsContentRenderedInline
						contentKey={cms.billingAddress}
						className={style.subheader}
						fallbackValue="Billing Address"
					/>
					<div className={cx(style.item, style.address)}>
						{sessAddressLabel}
					</div>
				</div>
			}
		</div>
	);
};

export default PurchaseBillingPanel;
