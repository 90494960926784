import {
	clone,
} from "lodash";
import { DateTimeType } from "./DateTime.js";

export default class WSLastUsageDetails {
	constructor(obj = {}) {
		this.transactionDateTime = obj.transactionDateTime;
		this.device = obj.device;
		this.status = obj.status;
		this.statusDescription = obj.statusDescription;
		this.location = obj.location;
	}

	static fromBackoffice(data) {
		const result = new WSLastUsageDetails(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSLastUsageDetailsType = [
	DateTimeType,
	`
		type WSLastUsageDetails {
			transactionDateTime: GraphQLDateTime!
			device: String!
			status: String!
			statusDescription: String!
			location: String!
		}
	`,
];
