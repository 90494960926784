// extracted by mini-css-extract-plugin
export var close = "components-__Alert-module___close";
export var closeContainer = "components-__Alert-module___closeContainer";
export var closeSvg = "components-__Alert-module___closeSvg";
export var closeText = "components-__Alert-module___closeText";
export var closeToast = "components-__Alert-module___closeToast";
export var content = "components-__Alert-module___content";
export var dialog = "components-__Alert-module___dialog";
export var icon = "components-__Alert-module___icon";
export var iconSvg = "components-__Alert-module___iconSvg";
export var info = "components-__Alert-module___info";
export var isError = "components-__Alert-module___isError";
export var isSuccess = "components-__Alert-module___isSuccess";
export var overlay = "components-__Alert-module___overlay";
export var text = "components-__Alert-module___text";
export var title = "components-__Alert-module___title";