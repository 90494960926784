import EulaById from "./EulaById.js";
import { head } from "lodash";
import PublicAppVars from "utils/PublicAppVars.js";
import EulaSearch from "components/data/eulas/EulaSearch.js";
import React from "react";
import { useRouteMatch } from "react-router-dom";

const LatestEulaOfFeature = ({
	feature,
	eulaType,
}) => {
	const match = useRouteMatch();
	const eulaId = parseInt(match?.params?.eula_id ?? '0', 10);

	if (eulaId) {
		return <EulaById
			eulaId={eulaId}
			featureReq={feature}
		/>;
	}

	return (
		<EulaSearch
			channels={PublicAppVars.EULA_CHANNELS}
			features={[ feature ]}
			eulaType={eulaType}
			loading={() => null}
		>{eulas => (
				head(eulas)
					? <EulaById
						eulaId={head(eulas).eulaId}
						featureReq={feature}
					/>
					: null
			)}</EulaSearch>
	);
};
export default LatestEulaOfFeature;
