import React, { useEffect } from 'react';
import {
	generatePath,
	Switch,
	Redirect,
} from 'react-router-dom';
import {
	get,
	find,
	transform,
} from 'lodash';

import {
	levels,
	log,
} from "utils/Logger.js";
import { wireEventElements } from "utils/analytics/AnalyticsEvents.js";
import {
	routes as customerRoutes,
} from "CustomerRoutes.js";
import { groupAdminRoutes } from 'GroupAdminRoutes.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import AppDataProvider from "AppDataProvider.js";

import BaseLayout from "layouts/BaseLayout.js";

// pages
import NotFound from 'pages/NotFound.js';


// Normalize.css, Variables, Font definitions
import 'styles/breakpoints.css';
import 'styles/colors.css';
import 'styles/sizings.css';
import 'styles/fonts.css';
import 'App.module.css';

/**
 * Get the routes specific to the site being viewed
 */
const getRoutes = () => PublicAppVars.isGroupAdmin
	? groupAdminRoutes
	: customerRoutes;

// prefer getPathByPage to this (compiler can detect invalid lookups)
// but this is needed when one Page has multiple routes
export const getRouteByRoute = (routeName) =>
	PublicAppVars.isGroupAdmin
		? groupAdminRoutes[ routeName ]
		: customerRoutes[ routeName ];


/**
 * Return the path for the given route
 *
 * @param {string} routeName
 *
 * @returns string
 */
export const getPathTemplateByRoute = (routeName) => {
	const route = getRouteByRoute(routeName);

	if (!route) {
		log(null, levels.error, "Invalid route");
		throw new Error(`invalid route ${routeName}`);
	}

	if (!route?.props?.path) {
		log(null, levels.error, "Pathless route");
		throw new Error(`Pathless route ${routeName}`);
	}

	return route.props.path;
};


/**
 * Get url for passed in route name
 *
 * @param {string} routeName
 * @param {object} urlData
 *
 * @returns string
 */
export const getPathByRoute = (routeName, urlData = {}) => {
	const routeTemplate = getPathTemplateByRoute(routeName);
	return generatePath(routeTemplate, urlData);
};


/**
 * @deprecated use getRouteByRoute instead. That allows for tree shaking and avoids circular references.
 */
export const getRouteByPage = (Page) => {
	const found = find(customerRoutes, (route) => {
		return get(route, 'props.component') === Page;
	});

	if (found) {
		return found;
	}

	if (process.env.NODE_ENV === 'test') {
		// We're compiled to commonjs under jest, and we can't reliably compare class equality
		// (why it works sometimes, I don't know. E.g. Footer.test.js)
		// We'll rely on the import statement bombing to cover testing the caller
		// and here we'll return the first route we find
		// https://reflexions.slack.com/archives/C8KP2CGTZ/p1542295350121200
		return customerRoutes[ Object.keys(customerRoutes)[ 0 ] ];
	}
	throw new Error("route not found");
};


/**
 * Redirect to an Express path from the client side
 *
 * @param {string} url
 * @param {component} component
 */
export const redirectToExpress = (url, component) => {
	// client-side can't redirect to an Express path
	if (typeof window !== "undefined") {
		window.location.href = url;
	} else {
		component.setState({
			redirect: <Redirect push to={url} />,
		});
	}
};


const App = () => {
	useEffect(() => {
		wireEventElements();
	});

	/* Load routes specific to site being viewed */
	const routeDefs = getRoutes();

	const keyedRoutes = transform(routeDefs, (accumulator, value, key) => {
		accumulator.push(
			React.cloneElement(
				value,
				{ key },
			),
		);

		return true;
	}, []);

	return (
		<AppDataProvider>
			<Switch>
				{/* Load routes specific to site being viewed */}
				{keyedRoutes}

				<BaseLayout path='*' component={NotFound} isGroupAdmin={PublicAppVars.isB2BApi} />
			</Switch>
		</AppDataProvider>
	);
};

export default App;
