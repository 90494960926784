import React, { useContext, createContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GET_SESSION_TRANSIT_ACCOUNT_ID } from "components/data/session-user/SessionUser.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { getPathByRoute } from "App.js";
import CustomerRouteKeys from "CustomerRouteKeys.js";

export const TransitAccountIdContext = createContext(null);

/** @return string|null */
export const useTransitAccountIdContext = () => useContext(TransitAccountIdContext);

export const UNREGISTERED = 'unregistered';

export const TransitAccountIdContextProvider = ({ children }) => {
	const { pathname } = useLocation();
	const isPurchaseNewCharlieCard = pathname === getPathByRoute(CustomerRouteKeys.PurchaseCharlieCard);
	const { transit_account_id: paramTransitAccountId } = useParams();
	const queryResult = useStdQuery(GET_SESSION_TRANSIT_ACCOUNT_ID, {
		skip: isPurchaseNewCharlieCard || Boolean(paramTransitAccountId) && paramTransitAccountId !== UNREGISTERED,
	});
	const sessionTransitAccountId = queryResult?.data?.session?.transitAccountId;

	return (
		<TransitAccountIdContext.Provider value={(paramTransitAccountId !== UNREGISTERED && paramTransitAccountId) || sessionTransitAccountId}>
			{children}
		</TransitAccountIdContext.Provider>
	);
};

export default TransitAccountIdContext;
