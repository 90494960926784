// TODO we'll need different tokens for the different WS* types, and different statuses

export const testSuspendedTokenInfo = {
	tokenType: 'Bankcard',
	tokenNickname: 'Sample tokenNickname',
	maskedPan: '9876',
	cardExpiryMMYY: '0520',
	creditCardType: 'MasterCard',
};

export const SuspendedToken = {
	tokenInfo: testSuspendedTokenInfo,
	tokenId: '5263',
	subsystemAccountReference: '440000052033',
	subSystemNickname: 'ABP 2033',
	status: 'Suspended',
	statusDescription: 'Suspended',
};


export const sampleWsSubsystemAccountToken = {
	tokenInfo: {
		cardBrand: 'Visa',
		tokenType: 'Visa',
		tokenType: 'Bankcard',
		tokenNickname: 'tokenNickname',
		maskedPan: '9876',
		expirationMonth: 5,
		expirationYear: 2020,
		creditCardType: 'Visa',
		cardExpiryDate: "2025-01-01T23:59:59.000Z",
	},
	tokenId: '5263',
	subSystemNickname: 'subSystemNickname',
	status: 'Active',
	frontendStatus: 'Active',
	statusDescription: 'statusDescription',
	products: [],
};

export const sampleWsSubsystemAccountPass = {
	id: '123',
	passSKU: '123',
	createdDateTime: '2020-01-02',
	travelTokenId: '123',
	passDescription: 'Monthly Rail Pass Current',
	expirationDateTime: '2022-01-01T07:22:02.000Z',
	startDateTime: '2021-03-01T07:22:02.000Z',
	endDateTime: '2021-03-31T07:22:02.000Z',
	passSerialNbr: '1234',
	supportsAutoload: true,
	fareProductTypeId: '123',
	autoloadEnabled: true,
};

// aka Transit Account aka Travel Card
export const sampleWsSubsystemAccountInfo = {
	subsystemAccountReference: "440000217370",
	subsystemAccountDetailedInfo: {
	  tokens: [ sampleWsSubsystemAccountToken ],
	  id: "ABP440000217370",
	  debtCollectionInfo: {
			action: "NotNeeded",
			amountDue: 0,
			bankcardCharged: {
		  maskedPan: "3799",
		  expirationMonth: 4,
		  expirationYear: 2024,
		  creditCardType: "Visa",
			},
			attemptInSeconds: null,
			remainingAttemptsForDay: null,
	  },
	  accountStatus: "Active",
	  accountStatusDescription: "Active",
	},
};

// Sample Payment Method
export const sampleWsFundingSourceExt = {
	billingAddressId: '00000000-0000-0000-0000-00000000000',
	creditCard: {
		cardExpiryMMYY: "0120",
		creditCardType: "American Express",
		maskedPan: "0161",
		pgCardId: "5776",
	},
	fundingSourceId: 25,
	setAsPrimary: true,
	status: "Active",
	statusDescription: "Active",
};
