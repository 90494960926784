import React from 'react';
import { values } from 'lodash';
import PropTypes from 'prop-types';
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import cx from 'classnames';
import {
	levels,
	noticeError,
} from "utils/Logger";

import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import Modal from 'components/Modal.js';
import Input from "components/forms/Input.js";
import CmsContentList from "components/data/CmsContentList.js";
import Button from 'components/Button.js';

import PreventDefault from 'utils/PreventDefault.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';

import * as modalStyles from 'components/Modal.module.css';
import * as editNicknameStyles from './EditCardNickname.module.css';
import { useModalContext } from 'context/ModalProvider';
import Toast from 'components/Toast';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import { RENAME_CARD } from 'components/data/transit-account/subsystem/travel-token/RenameCard.mutation.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { GET_TRANSIT_ACCOUNT } from 'components/data/transit-account/TransitAccount.js';
import { USER_PAYMENT_METHODS_QUERY } from 'components/data/session-user/SessionUser';
import CmsContentRendered from 'components/data/CmsContentRendered';
import PublicAppVars from 'utils/PublicAppVars';


export const nickNameMaxLengthCMSKey = 'miscText["errors.general.value.toolong"]';

const cms = {
	header: 'miscText.card-settings-manage-nickname-header',
	nicknameLabel: 'miscText.card-settings-manage-nickname-label',
	cancelLabel: 'miscText.card-settings-manage-nickname-cancel',
	submitLabel: 'miscText.card-settings-manage-nickname-submit',
	confirmation: 'miscText.card-settings-manage-nickname-confirmation',
	nickNamePlaceholder: 'miscText["general-payment-cc-nickname.label"]',
	nickNameMaxLength: nickNameMaxLengthCMSKey,
	required: 'miscText["errors.general.field_required"]',
};


const EditCardNickname = ({
	tokenNickname,
	tokenId,
	transitAccountId,
}) => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const { setModal } = useModalContext();

	const getYupSchema = () => yup_object().shape({
		cardNickname: yup_string()
			.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, cms.nickNameMaxLength)
			.required(() => <CmsContentRendered contentKey={cms.required} variables={{ field: "Nickname" }} fallbackValue={"Nickname is required"} />)
			.trim(),
	});

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({ getYupSchema });

	const [ mutator, { loading } ] = useStandardMutation(
		RENAME_CARD,
		{
			refetchQueries: [ { query: USER_PAYMENT_METHODS_QUERY }, {
				query: GET_TRANSIT_ACCOUNT, variables: {
					subsystemAccountReference: transitAccountId,
					subscriptions: true,
					archivedTokens: false,
					programMemberships: false,
					transferProductInfo: false,
					productPayments: false,
				},
			} ],
		}
	);

	const onModalClose = () => setModal(null);

	const onToastConfirmation = () => {
		setToast(<Toast
			type="success"
			title={<CmsContentRendered
				contentKey={cms.confirmation}
				fallbackValue="The nickname for this card has been updated"
			/>}
			onClosed={removeToast}
		/>);
		onModalClose();
	};

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true, null);
		}
		catch (errorReport) {
			//validation failed
			setSubmitting(false);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		try {
			const variables = {
				tokenId: tokenId,
				subsystemAccountReference: transitAccountId,
				cardNickname: validated.cardNickname,
			};

			await graphqlErrorMiddleware(mutator({ variables }));
			onToastConfirmation();
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, 'Edit card nickname Failed');
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
		}
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal
				title={<CmsContentRenderer
					contentKey={cms.header}
					fallbackValue={'Edit Card Nickname'}
				/>}
				onModalClose={onModalClose}
			>
				<form
					method="post"
					data-qa="EditTransitAccountNicknameModalForm"
					ref={formRef}
					onSubmit={PreventDefault(() => kickoffSubmit(cmsContent))}
				>
					<div className={modalStyles.row}>
						<div className={editNicknameStyles.inputWrapper}>
							<Input
								type="text"
								data-qa="NameYourCardInput"
								label={findContentOrFallback(cmsContent, cms.nicknameLabel, 'Card nickname')}
								error={formHelper.getFieldError('cardNickname')}
								name="cardNickname"
								placeholder={findContentOrFallback(cmsContent, cms.nickNamePlaceholder, '')}
								aria-label="Name your card, optional input field"
								overrideClass={editNicknameStyles.nickNameInput}
								defaultValue={tokenNickname}
								maxLength={PublicAppVars.CARD_NICKNAME_MAXLENGTH}
							/>
						</div>
					</div>

					{formHelper.getFieldErrorJsx('')}
					<div className={cx(modalStyles.actionButtons, editNicknameStyles.modalActionsWrapper)}>
						<Button
							type="button"
							onClick={onModalClose}
							additionalClassNames={cx(modalStyles.btn, modalStyles.leftButton)}
						>
							<CmsContentRenderer.Span
								contentKey={cms.cancelLabel}
								fallbackValue={'Cancel'}
								data-qa="EditCardNicknameModalCancelButton"
							/>
						</Button>
						<Button
							submitting={submitting || loading}
							type="submit"
							additionalClassNames={cx(modalStyles.btn, modalStyles.rightButton)}
						>
							<CmsContentRenderer.Span
								contentKey={cms.submitLabel}
								fallbackValue={'Update Nickname'}
								data-qa="EditCardNicknameModalSubmitButton"
							/>
						</Button>
					</div>
				</form>
			</Modal>
		)}</CmsContentList>
	);
};

EditCardNickname.propTypes = {
	tokenNickname: PropTypes.string.isRequired,
	transitAccountId: PropTypes.string.isRequired,
	tokenId: PropTypes.string.isRequired,
};

export default EditCardNickname;
