import React from "react";
import cx from "classnames";
import { values } from "lodash";
import Collapse from "@kunukn/react-collapse";

import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import {
	useCartContext,
	getCartSubtotal,
	getCartTotal, getCartMediaOption,
} from 'context/CartProvider.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import CollapsibleContext, { CollapsibleContextProvider } from "context/CollapsibleContext.js";

import Container from "../Container.js";
import CartToggle from "./cart/CartToggle.js";
import NextStep from "./cart/NextStep.js";

import CartListing from "./cart/CartListing.js";
import FormHelperProvider, { useFormHelperContext } from 'utils/form-helper/FormHelperProvider.js';

import * as collapsible from 'components/Collapsible.module.css';
import * as cartStyle from "./Cart.module.css";
import * as container from "components/Container.module.css";

const cms = {
	cart: 'miscText.purchase-cart-subheader',
	total: 'miscText.purchase-cart-total',
	subtotal: 'miscText.purchase-cart-subtotal',
	discount: 'miscText.purchase-cart-discount',
	balanceApplied: 'miscText.purchase-cart-balance-applied',
	charlieCard: 'miscText.purchase-cart-cards',
};

const CartBody = ({
	disableDelete,
	redirectPath,
	submitting,
	nextText,
	disableNext,
}) => {
	const { cart } = useCartContext();
	const wsMediaOption = getCartMediaOption(cart);

	const hasDiscounts = cart.promoCodes !== null;

	return (<div className={cx(cartStyle.container)}>
		<Container overrideClass={cx(cartStyle.innerContainer, container.noPadding)}>
			<CartListing {...{ cart, disableDelete }} />

			{/* DESKTOP Subtotals, Totals, and Next */}
			<div className={cx(cartStyle.prodTotalContainer, cartStyle.mobileHidden)}>
				<div className={cartStyle.totalContainerText}>
					<div className={cartStyle.totals}>
						<CmsContentRenderer.Span contentKey={cms.subtotal}
							fallbackValue="Subtotal:"
							className={cartStyle.subTotal}
						/>
						<span className={cartStyle.totalsPrice}>
							{centsToDisplay(getCartSubtotal(cart))}
						</span>
					</div>
					{(cart.appliedStoredValue > 0) && (
						<div className={cartStyle.totals}>
							<CmsContentRenderer.Span
								className={cartStyle.subTotal}
								fallbackValue="Balance Applied"
								contentKey={cms.balanceApplied}
							/>
							<span className={cartStyle.totalsPrice}>
								{centsToDisplay(cart.appliedStoredValue)}
							</span>
						</div>
					)}
					{hasDiscounts && (
						<div className={cartStyle.totals}>
							<CmsContentRenderer.Span contentKey={cms.discount}
								fallbackValue="Discount:"
								className={cartStyle.subTotal}
							/>
							<span className={cartStyle.totalsPrice}>
								{/* todo: hook up discounts */}
								{0.00}
							</span>
						</div>
					)}

					{wsMediaOption ?
						<div className={cartStyle.totals}>
							<CmsContentRenderer.Span
								fallbackValue="Charlie Cards"
								contentKey={cms.charlieCard}
								className={cartStyle.subTotal}
							/>
							<span className={cartStyle.totalsPrice}>
								{`x${wsMediaOption.quantity || 0}`}
							</span>
						</div>
						: null
					}
					<hr className={cartStyle.hr} />
					<div className={cartStyle.panelBottom}>
						<div className={cx(cartStyle.totals, cartStyle.lastcolTotal, cartStyle.uncollapse)}>
							<CmsContentRenderer.P contentKey={cms.total}
								fallbackValue="Total"
							/>
							<div className={cartStyle.totalsPrice}>
								{centsToDisplay(getCartTotal(cart))}
							</div>
						</div>
					</div>
				</div>
				{disableNext
					? null
					: <NextStep {...{
						additionalClassNames: cartStyle.uncollapseNextBtn,
						redirectPath,
						submitting,
						nextText,
					}} />}
			</div>
			{/* MOBILE Subtotals, Total and Next*/}
			{/* MOBILE Subtotals */}
			<div className={cx(cartStyle.desktopHidden, cartStyle.mobileTotals)}>
				<CmsContentRenderer.Span contentKey={cms.subtotal}
					fallbackValue="Subtotal:"
					className={cartStyle.subTotal}
				/>
				<div className={cartStyle.totalsPrice}>
					{centsToDisplay(getCartSubtotal(cart))}
				</div>
			</div>
		</Container>
	</div>);
};

// Cart that stays open and cannot collapse
export const CartStatic = (props) => {
	const { formHelper } = props;
	const formHelperContext = useFormHelperContext();
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<FormHelperProvider formHelperContext={formHelper ? { formHelper } : formHelperContext}>
				<section className={container.containerSticky}>
					<div className={cartStyle.toggleHeader}>
						<Container overrideClass={cx(cartStyle.innerContainer, cartStyle.headerbackgroundColor)}>
							<div className={cartStyle.toggleSubHeader}>
								<CmsContentRenderer.H3 contentKey={cms.cart}
									fallbackValue="Cart"
									className={cartStyle.title}
								/>
							</div>
						</Container>
					</div>

					<CartBody {...props} />

				</section>
			</FormHelperProvider>
		)}</CmsContentList>);
};

const Cart = ({
	formHelper,
	redirectPath,
	nextText,
	submitting = false,
	disableNext = false,
}) => {
	const formHelperContext = useFormHelperContext();
	const { cart } = useCartContext();

	return (
		<CmsContentList list={values(cms)}>{() =>
			<FormHelperProvider formHelperContext={formHelper ? { formHelper } : formHelperContext}>
				<CollapsibleContextProvider>
					<CollapsibleContext.Consumer>{([ isOpen, setIsOpen ]) => (
						<section className={container.containerSticky}>
							<div>
								<CartToggle {...{ nextText, isOpen, setIsOpen, formHelper, submitting, disableNext }} />
								<Collapse {...{ isOpen }}
									className={collapsible.slide}
									overflowOnExpanded={true}
								>
									<CartBody {...{ formHelper, redirectPath, nextText, submitting, disableNext }} />
								</Collapse>

								<div className={cx(cartStyle.toggleSummary, cartStyle.desktopHidden)}>
									<div className={cartStyle.toggleSummaryDetails}>
										<div className={cx(cartStyle.totals, cartStyle.lastcolTotal, cartStyle.totalHeader)}>
											<CmsContentRenderer.Span
												contentKey={cms.total}
												fallbackValue="Total"
												className={cartStyle.totalsFinalLabel}
											/>
											<span className={cartStyle.totalsFinalPrice}>
												{centsToDisplay(getCartTotal(cart))}
											</span>
										</div>
										{disableNext
											? null
											: <NextStep {...{
												additionalClassNames: cartStyle.fullWidth,
												redirectPath,
												submitting,
												nextText,
											}} />}
									</div>
									<div className={cartStyle.errorWrapper}>
										{formHelper.getFieldErrorJsx('')}
									</div>
								</div>
							</div>
						</section>
					)}</CollapsibleContext.Consumer>
				</CollapsibleContextProvider>
			</FormHelperProvider>
		}</CmsContentList>
	);
};

export default Cart;
