import { clone } from "lodash";

export default class WSSecurityQA {
	constructor({
		securityQuestion,
		securityAnswer,
	}) {
		// string(250)
		// (Required) The security question provided by the contact.
		this.securityQuestion = securityQuestion;

		// string(64)
		// (Required) The security answer given by the contact.
		this.securityAnswer = securityAnswer;
	}

	static fromBackoffice(data) {
		const result = new WSSecurityQA(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSSecurityQA(data);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSSecurityQAType = [ `
	type WSSecurityQA {
		securityQuestion: String!
		securityAnswer: String!
	}
` ];

export const InputWSSecurityQAType = [ `
	input InputWSSecurityQA {
		securityQuestion: String!
		securityAnswer: String!
	}
` ];
