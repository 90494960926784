import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { levels, log } from "utils/Logger.js";
import FormHelper from "utils/FormHelper.js";

import Control from "./Control.js";
import CmsContentRenderer from "../data/CmsContentRenderer";
import CmsContent from "../data/CmsContent";

import * as style from "./Input.module.css";
import * as formStyle from "./Forms.module.css";



class Textarea extends React.Component {
	static propTypes = {
		id: PropTypes.string,
		name: PropTypes.string,
		label: PropTypes.node,
		hideLabel: PropTypes.bool,
		error: PropTypes.oneOfType([
			PropTypes.string,
			// can be jsx
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.node),
		]),
		overrideClass: PropTypes.string,
		labelClass: PropTypes.string,
		value: PropTypes.any,
		maxLength: PropTypes.number,
		maxLengthErrorMsg: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object,
		]),

		// set true if value comes from prop
		// If not set, you'll get errors like "A component is changing an uncontrolled input of type hidden to be controlled."
		// Make sure the value prop you send isn't undefined, otherwise React will create a controlled element
		controlled: PropTypes.bool,

		inputRef: PropTypes.any,
	};

	static defaultProps = {
		name: '',
		label: '',
		hideLabel: false,
		error: '',
		maxLengthErrorMsg: <CmsContentRenderer.Span contentKey={'miscText.input-box-character-count-warning'} />,
		overrideClass: '',
		labelClass: '',
	};

	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
		this.renderCharCount = this.renderCharCount.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.state = {
			remainingChars: this.props.maxLength,
		};
	}

	get value() {
		return this.props.controlled
			? this.props.value
			: this.getInputRef().current
				? this.getInputRef().current.value
				: this.props.defaultValue;
	}

	static getFlattenedError(error) {
		if (error instanceof Array) {
			log(null, levels.warn, "callers should be flattening the error array before sending to Textarea");
			return error.join();
		}
		return error;
	}

	inputClass() {
		const { error } = this.props;
		return error
			? style.error
			: style.input;
	}

	getAriaLabel() {
		const {
			label,
			hideLabel,
		} = this.props;

		return hideLabel
			? { "aria-label": label }
			: null;
	}

	getId() {
		const {
			id,
			name,
		} = this.props;

		return id || name;
	}

	getLabel() {
		const {
			label,
			description = '',
			hideLabel,
			labelClass,
		} = this.props;
		return hideLabel
			? null
			: (
				<label
					htmlFor={this.getId()}
					className={cx(style.label, labelClass)}
				>
					{label}
					{description ? <div>{description}</div> : ""}
				</label>
			);
	}

	getInput() {
		const {
			id,
			name,
			error,
			...others
		} = this.props;

		const ariaLabel = this.getAriaLabel();
		const refProp = this.getRefProp();
		const valueProp = this.getValueProp();

		return (
			<textarea
				id={this.getId()}
				name={name}
				aria-invalid={Boolean(error) || (this.props.maxLength && !this.state.remainingChars)}
				aria-controls={this.props.maxLength
					? `${name}-charCount`
					: ``}
				onChange={(e) => this.handleChange(e)}
				{...ariaLabel}
				{...others}
				{...refProp}
				className={this.inputClass()}
			>
				{valueProp}
			</textarea>
		);
	}

	getInputRef() {
		return this.props.inputRef
			? this.props.inputRef
			: this.inputRef;
	}

	getRefProp() {
		return {
			ref: this.getInputRef(),
		};
	}

	getValueProp() {
		return this.props.controlled
			? this.props.value
			: null;
	}

	getFieldErrorJsx() {
		return FormHelper.errorJsx(this.props.error);
	}

	handleChange(e) {
		if (this.props.maxLength) {
			const newRemaining = this.props.maxLength - this.value.length;
			if (this.state.remainingChars !== newRemaining) {
				this.setState({
					remainingChars: newRemaining,
				});
			}
		}
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}

	renderCharCount() {
		const cmsKey = "miscText.input-box-character-count-aria-label";

		if (!this.props.maxLength) {
			return null;
		}
		return (
			<CmsContent contentKey={cmsKey}>{({ content }) => (
				<span
					className={style.charCount}
					aria-live="polite"
					aria-atomic={true}
					aria-label={`${this.state.remainingChars} characters remaining`}
					id={`${this.getId()}-charCount`}
				>{this.state.remainingChars}</span>
			)}</CmsContent>
		);
	}

	renderCharCountError() {
		if (this.props.maxLength && this.state.remainingChars === 0) {
			return (
				<div className={style.maxLengthErrorMsg} role="alert">
					{this.props.maxLengthErrorMsg}
				</div>
			);
		}
		return null;
	}

	render() {
		// NOTE: leave unused props in this to keep them out of the 'others' var
		// label, hideLabel are used by getAriaLabel
		// controlled, value used by getValueProp
		// labelClass by getLabel
		const {
			overrideClass,
		} = this.props;

		const errorJsx = this.getFieldErrorJsx();

		return (
			<div className={cx(style.container, style[ this.props.type ], overrideClass)}>
				{this.getLabel()}
				<div className={style.charCountWrapper}>
					{this.getInput()}
					{this.renderCharCount()}
				</div>
				{this.renderCharCountError()}
				{errorJsx}
			</div>
		);
	}
}

export default Textarea;
