import React, {
	useState,
	useRef,
} from 'react';
import {
	Redirect,
	useParams,
} from 'react-router-dom';
import {
	map,
	values,
} from 'lodash';
import {
	object as yup_object,
	string as yup_string,
} from 'yup';
import { RadioGroup } from '@headlessui/react';
import cx from 'classnames';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import routeKeys from 'CustomerRouteKeys.js';
import { getPathByRoute } from 'App.js';
import loginStages from "components/data/session-user/LoginStages.js";

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRendered from 'components/data/CmsContentRendered.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import FormHelper from 'utils/FormHelper.js';
import ClientAjax from 'utils/ClientAjax.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import restClientMiddleware from 'utils/error-handling/RestClientMiddleware.js';
import useRecaptcha, { recaptchaYupValidations } from 'components/Recaptcha.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { addYupMethod } from 'utils/YupValidators.js';
import {
	Email,
	FullName,
} from 'components/data/session-user/SessionUser.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import ContactUsFormSubmitted from 'components/toasts/ContactUsFormSubmitted.js';
import {
	IfLoggedIn,
	IfNotLoggedIn,
	IfNotRegistered,
	useLoginStage,
} from 'components/data/session-user/LoggingIn.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from 'utils/GetErrorKey.js';
import { SAMPLE_PHONE_NUMBER } from 'utils/Constants.js';
import {
	useTransitAccountIdContext,
	UNREGISTERED,
} from 'context/TransitAccountIdContext.js';


import TakeOverLayout from 'layouts/TakeOverLayout.js';
import Button from 'components/Button.js';
import Panel from 'components/Panel.js';
import Input from 'components/forms/Input.js';
import Textarea from 'components/forms/Textarea.js';
import CardIconAndNickname from 'components/card-icons/CardIconAndNickname.js';

import * as style from 'layouts/TakeOverLayout.module.css';
import * as forms from 'components/forms/Forms.module.css';
import PreventDefault from "utils/PreventDefault.js";
import { useSessionCheckContext } from "components/data/session-user/SessionCheck.js";
import TransitAccount from 'components/data/transit-account/TransitAccount.js';
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';
import TokenNameAndPan from 'components/account/card/TokenNameAndPan.js';


const cms = {
	header: 'miscText.customer-service-header',
	description: 'miscHtml.customer-service-description',

	headingAccount: 'miscText["customer-service-contact.label"]',
	cardNum: 'miscText.customer-service-card',
	email: 'miscText.customer-service-contact-unregistered-email',
	cardNumber: 'miscText.customer-service-contact-card',

	headingReason: 'miscText.customer-service-reason-subheader',
	FeedbackOnWebsite: 'miscText["customer-service-reason.FeedbackOnWebsite"]',
	GeneralFeedbackAndInquiries: 'miscText["customer-service-reason.GeneralFeedbackAndInquiries"]',
	CardFailure: 'miscText["customer-service-reason.CardFailure"]',
	PaymentFailure: 'miscText["customer-service-reason.PaymentFailure"]',
	AccountUpdate: 'miscText["customer-service-reason.AccountUpdate"]',
	ErrorReport: 'miscText["customer-service-reason.ErrorReport"]',

	headingDesc: 'miscText["customer-service-details.label"]',
	btn: 'miscText.customer-service-submit',
	legal: 'miscText.customer-service-submit-disclaimer',
};


const getYupSchema = () => {
	addYupMethod("validFeedbackType");

	return yup_object().shape({
		feedbackType: yup_string()
			.required(getErrorKey(COLLECTIONS.contactCustomerService, 'feedbackType', BO_ERRORS.general.required))
			.validFeedbackType(getErrorKey(COLLECTIONS.contactCustomerService, 'feedbackType', BO_ERRORS.general.invalid))
			.trim(),
		email: yup_string(),
		feedbackMessage: yup_string()
			.required('miscText["errors.general.blankfield"]')
			.max(PublicAppVars.CONTACT_US_MAX_CHAR_LENGTH, getErrorKey(COLLECTIONS.contactCustomerService, 'feedbackMessage', BO_ERRORS.general.tooLong))
			.trim(),
		...recaptchaYupValidations,
	}).transform(function (current, original) {
		// email validation only for unlogged and unregistered user to send email info to BO,
		// logged in user has prefilled email, so we are not verifying email field
		this.fields.email = original?.email?.length === undefined
			? yup_string()
			: yup_string()
				.email('miscText.forgot-password-email-error-invalid')
				.required('miscText["errors.general.blankfield"]');
		return current;
	});
};

const CardNickName = ({ subsystemAccountReference }) => {
	return (

		<TransitAccount subsystemAccountReference={subsystemAccountReference}>
			{({ transitAccountQ }) => {
				const primaryToken = findPrimaryToken(transitAccountQ.tokens);
				const primaryTokenInfo = primaryToken.tokenInfo;

				return (primaryTokenInfo ? <TokenNameAndPan tokenInfo={primaryTokenInfo} mediaType={primaryToken.mediaType} /> : null);
			}}
		</TransitAccount>

	);
};

const ContactUs = () => {
	const { loginStage } = useLoginStage();
	const [ feedbackType, setFeedbackType ] = useState('');
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});
	const [ redirect, setRedirect ] = useState(null);
	const subsystemAccountReference = useTransitAccountIdContext();
	const { setToast } = useGlobalToastsContext();
	const { transit_account_id } = useParams();

	const isUnregistered = Boolean(transit_account_id === UNREGISTERED);
	const unRegisteredLoggedIn = (Boolean(subsystemAccountReference) && isUnregistered); // guest page

	const redirectUrl = (loginStage === loginStages.loggedIn)
		? Boolean(transit_account_id)
			// when contact button clicked on the Card pages like Overview or Trip History e.g.
			? getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id })
			// when contact button clicked on Account settings pages
			: getPathByRoute(routeKeys.AccountPersonalInformation)
		: unRegisteredLoggedIn
			// when contact button clicked on the Guest page
			? getPathByRoute(routeKeys.GuestCardOverview)
			// when anonymous user clicks contact button
			: getPathByRoute(routeKeys.SignIn);


	const formRef = useRef(null);

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, getYupSchema);

	const completeSubmit = () => {
		setToast(<ContactUsFormSubmitted />);
	};

	const { syntheticTimerEvent } = useSessionCheckContext();

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const kickoffSubmit = async () => {
		await checkRecaptcha();

		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
			formHelper.clearAllErrors();
		}
		catch (errorReport) {
			// yup validation failed, but those errors are already in state
			noticeError(null, levels.verbose, errorReport, `Contact form submit validation: ${errorReport.message}`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		// props.submit succeeds or returns an ErrorReport
		try {
			// set up dummy phone as per John request as of 10/27/2021
			// https://reflexions.slack.com/archives/CCF68M49M/p1635369774037000?thread_ts=1635357326.032900&cid=CCF68M49M
			validated.phone = SAMPLE_PHONE_NUMBER;
			await restClientMiddleware(ClientAjax.post('/ajax/contact-form', validated));
		}
		catch (errorReport) {
			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmitting(false);

			await syntheticTimerEvent();
		}

		GoogleAnalytics.logEvent("User is submitting the contact form");
		document.getElementById("ContactUsForm").reset();

		setRedirect(<Redirect push to={{ pathname: redirectUrl }} />);

		completeSubmit();
	};

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) =>
			<TakeOverLayout
				title={cmsContent[ cms.header ] || "Contact Customer Service"}
				showCancel
			>
				<div className={style.contactContainer}>
					<CmsContentRendered.Div
						data-qa="Feedback"
						contentKey={cms.description}
						fallbackValue={"<p>If you would like to report an issue or feedback regarding your Charlie Card or account,	please provide details below. If you are experiencing technical issues,	<a href='http://www.mbta.com/customer-support'>contact MBTA support</a>.</p>"}
					/>

					<form
						method="post"
						id="ContactUsForm"
						data-qa="contactUsForm"
						onSubmit={PreventDefault(kickoffSubmit)}
						ref={formRef}
					>
						<section>
							<CmsContentRenderer.H2
								className={style.sectionHeading}
								contentKey={cms.headingAccount}
								fallbackValue="Account Contact Details"
							/>

							<IfLoggedIn>
								<Panel>
									<div className={style.contactDetailsWrapper}>
										<div>
											<div className={style.contactValue}><FullName /></div>
											<div className={style.contactValue}><Email /></div>
										</div>
										{/*
											We display card info if user comes from Card pages like Overview or Trip History e.g.,
											and from the Unregister History page. We are not showing card info if user comes
											from Account settings pages even we have stored transitAccountId in the session
											(because user checked unregistered card previously)
									 */}
										{Boolean(transit_account_id)
											? isUnregistered
												? <div>
													<CmsContentRenderer.Span
														className={style.cardNumber}
														contentKey={cms.cardNumber}
														fallbackValue="Card Number"
													/>
													<CardIconAndNickname {...{ showCardIcon: false, showName: false }} />
												</div>
												: <div className={style.cardNumber}>
													<CardNickName subsystemAccountReference={subsystemAccountReference} />
												</div>
											: null}
									</div>
								</Panel>
							</IfLoggedIn>
							{/*
							 using isUnregistered flag here allows to display card info only on the Unregister History page,
							 and does not on the Login and Register pages if user previously checked
							 unregister card and we have stored transitAccountId in the session
							*/}
							{(Boolean(subsystemAccountReference) && isUnregistered) &&
								<IfNotRegistered>
									<Panel>
										<div className={style.contactDetailsWrapper}>
											<div>
												<CmsContentRenderer.Span
													className={style.cardNumber}
													contentKey={cms.cardNumber}
													fallbackValue="Card Number"
												/>
												<CardIconAndNickname {...{ showCardIcon: false, showName: false }} />
											</div>
										</div>
									</Panel>
								</IfNotRegistered>
							}

							<IfNotLoggedIn>
								<Input
									type="email"
									name="email"
									label={
										<CmsContentRenderer.Span
											contentKey={cms.email}
											fallbackValue="Provide an email where we can contact you"
										/>
									}
								/>
								{formHelper.getFieldErrorJsx('email')}
							</IfNotLoggedIn>
						</section>

						<section className={style.contactSection}>
							<RadioGroup value={feedbackType}
								onChange={setFeedbackType}
								className={forms.radioGroup}
							>
								<RadioGroup.Label>
									<CmsContentRenderer.H2
										className={style.sectionHeading}
										contentKey={cms.headingReason}
										fallbackValue="Select reason for contact Customer Service:"
									/>
								</RadioGroup.Label>

								<div className={forms.radioGroupOptions}>
									{map(PublicAppVars.CONTACT_US_OPTIONS, (value, key) => (
										<RadioGroup.Option
											key={key}
											value={key}
											className={cx(forms.radioGroupOption)}
										>
											{({ checked }) => (
												<span className={cx(forms.radioLabel, checked && forms.radioChecked)}>
													<CmsContentRenderer.Span
														contentKey={cms[ key ]}
														fallbackValue={value}
													/>
												</span>
											)}
										</RadioGroup.Option>
									))}
								</div>
							</RadioGroup>
							<Input
								key={feedbackType}
								type="hidden"
								name='feedbackType'
								value={feedbackType}
								controlled={true}
								hideLabel={true}
							/>
						</section>
						{formHelper.getFieldErrorJsx('feedbackType')}

						<section className={style.contactSection}>
							<Textarea
								name="feedbackMessage"
								maxLength={PublicAppVars.CONTACT_US_MAX_CHAR_LENGTH}
								id='feedbackMessage'
								label={<CmsContentRenderer.H2
									className={style.sectionHeading}
									contentKey={cms.headingDesc}
									fallbackValue="Description of issue:"
								/>}
							/>
						</section>
						{formHelper.getFieldErrorJsx('feedbackMessage')}

						<div className={forms.actions}>
							<Button
								isPrimary
								additionalClassNames={forms.action}
								submitting={submitting}
							>
								<CmsContentRenderer.Span
									contentKey={cms.btn}
									fallbackValue="Submit"
								/>
							</Button>

							<CmsContentRenderer.P
								className={style.legal}
								contentKey={cms.legal}
								fallbackValue="Response may take up to 5 business days"
							/>
						</div>
						<Recaptcha />
					</form>
				</div>
			</TakeOverLayout>
		}</CmsContentList>
	);
};

ContactUs.propTypes = {};

export default ContactUs;
