import React from 'react';
import cx from "classnames";
import Button, { buttonTypeStylePlain, Secondary,Primary } from "components/Button.js";
import * as settings from "pages/account/card/Settings.module.css";
import { TOKEN } from "utils/Constants.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import Toast from "components/Toast.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { TOKEN_ACTION } from "components/data/transit-account/subsystem/travel-token/TokenAction.mutation.js";
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";

import * as modalStyles from 'components/Modal.module.css';
import CmsContentRenderer from 'components/data/CmsContentRenderer';
import CmsContentList from 'components/data/CmsContentList';

import Modal from 'components/Modal';
import useFormHelper from 'utils/form-helper/useFormHelper';
import PreventDefault from 'utils/PreventDefault';
import { useModalContext } from 'context/ModalProvider';


const cms = {
	header: "miscText.card-setprimary-modal-header",
	desc: "miscText.card-setprimary-modal-description",
	cancelBtn: "miscText.card-setprimary-modal-cancel",
	submitBtn:"miscText.card-setprimary-modal-submit",

	toast: "miscText.card-setprimary-confirmation",
	emvPrimary: "miscText.card-settings-setprimary-cta",
};


const PrimaryTokenModal = ({
	wsTransitAccountToken,
	subsystemAccountReference,
	setModal,
}) => {
	const { setToast, removeToast } = useGlobalToastsContext();

	const onModalClose = () => setModal(null);

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({ getYupSchema: {} });

	const [ tokenActionMutation ] = useStandardMutation(TOKEN_ACTION);

	const kickoffSubmit = async () => {
		setSubmitting(true);

		const variables = {
			tokenId: wsTransitAccountToken.tokenId,
			tokenAction: TOKEN.ACTION.primary,
			subsystemAccountReference,
			reasonCode: wsTransitAccountToken.statusReasonCode,
		};

		try {
			await graphqlErrorMiddleware(tokenActionMutation({
				variables,
				refetchQueries: getTransitAccountRefetchQueries(subsystemAccountReference),
				awaitRefetchQueries: true,
			}));
		} catch (errorReport) {
			setToast(
				<Toast
					type={"error"}
					title={errorReport.display.topLevelMessage}
					onClosed={removeToast}
				/>
			);

			return;
		} finally {
			setSubmitting(false);
			onModalClose();
		}

		setToast(
			<Toast
				type={"success"	}
				title={<CmsContentRenderedInline
					contentKey={cms.toast}
					fallbackValue={`You have successfully switched the primary card. You may make changes to this setting at any time.`}
				/>}
				onClosed={removeToast}
			/>
		);
	};

	return <CmsContentList list={Object.values(cms)}>{() =>
		<Modal
			onModalClose={onModalClose}
			title={
				<CmsContentRenderer
					contentKey={cms.header}
					fallbackValue={"Change my primary card"}
				/>

			}>
			<CmsContentRenderer.Span
				className={modalStyles.boldStyle}
				contentKey={cms.desc}
				fallbackValue={`Are you sure you want to switch how you use your card? You will no longer be able to use the current card for travel. You can switch back later, if needed.`}
			/>
			<form
				method="post"
				data-qa="MakePrimary"
				ref={formRef}
				onSubmit={PreventDefault(kickoffSubmit)}
			>
				{formHelper.getFieldErrorJsx('')}

				<div className={modalStyles.actionButtons}>
					<Button
						typeStyle={Secondary}
						onClick={onModalClose}
						overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
					>
						<CmsContentRenderer.Span
							contentKey={cms.cancelBtn}
							fallbackValue="No, do not change the card"
						/>
					</Button>

					<Button
						typeStyle={Primary}
						disabled={submitting}
						overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
					>
						<CmsContentRenderer.Span
							contentKey={cms.submitBtn}
							fallbackValue={`Yes, switch primary card`}
						/>
					</Button>
				</div>
			</form>
		</Modal>
	}</CmsContentList>;
};

const PrimaryToken = ({
	wsTransitAccountToken,
}) => {
	const { setModal } = useModalContext();

	const subsystemAccountReference = useTransitAccountIdContext();

	const handleMakePrimary = () => setModal(<PrimaryTokenModal
		{...{ subsystemAccountReference, wsTransitAccountToken, setModal }}
	/>);

	return (
		<div>
			<Button
				typeStyle={buttonTypeStylePlain}
				additionalClassNames={settings.cardAction}
				onClick={handleMakePrimary}
			>
				<CmsContentRenderedInline
					fallbackValue="Make this my primary card"
					contentKey={cms.emvPrimary}
				/>
			</Button>
		</div>
	);
};

export default PrimaryToken;
