import {
	clone,
	merge,
} from "lodash";
import WSDirectDebit, {
	WSDirectDebitFragment,
	WSDirectDebitType,
} from "server/api-types/WSDirectDebit.js";

/*
 * WSDirectDebitReference is a subclass of WSDirectDebit. This contains reference to an externally stored
 * bank account details.
 */

export default class WSDirectDebitReference extends WSDirectDebit {
	constructor({
		token,
		nameOnAccount = null,
		maskedBankAccountNumber = null,
		bankRoutingNumber = null,
		accountType = null,
		financialInstitutionName = null,
		nonce = null,
		// Super props
	}) {
		super({});

		// string(100)
		// (Required) Token for the bank account.
		this.token = token;

		// string(100)
		// (Optional) Full name of the account holder.
		this.nameOnAccount = nameOnAccount;

		// string(17)
		// (Optional) The bank account number of the
		// account holder.
		this.maskedBankAccountNumber = maskedBankAccountNumber;

		// string(10)
		// (Optional) Bank routing number.
		this.bankRoutingNumber = bankRoutingNumber;

		// string(20)
		// (Optional) The type of the account.
		this.accountType = accountType;

		// string(50)
		// (Optional) The name of the financial
		// institution.
		this.financialInstitutionName = financialInstitutionName;

		// string(100)
		// (Conditionally-Required) Nonce to fetch account
		// details from external payment providers like
		// Telecheck as part of the enrollment.
		this.nonce = nonce;
	}

	static fromBackoffice(data) {
		const result = new WSDirectDebitReference(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSDirectDebitReference(data);

		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return merge(
			result,
			super.toBackoffice(),
		);
	}

	toResolver() {
		const forGraphql = clone(this);
		return merge(
			forGraphql,
			super.toResolver(),
		);
	}
}

export const InputWSDirectDebitReferenceType = [ `
	input InputWSDirectDebitReference {
		${WSDirectDebitFragment}
		token: String!
		nameOnAccount: String
		maskedBankAccountNumber: String
		bankRoutingNumber: String
		accountType: String
		financialInstitutionName: String
		nonce: String!
	}
` ];

export const WSDirectDebitReferenceType = [
	...WSDirectDebitType,
	`
		type WSDirectDebitReference implements WSDirectDebitFactory {
			${WSDirectDebitFragment}
			token: String!
			nameOnAccount: String
			maskedBankAccountNumber: String
			bankRoutingNumber: String
			accountType: String
			financialInstitutionName: String
			nonce: String!
		}
	`,
];
