import React, { useState } from "react";
import {
	levels,
	log,
} from "../utils/Logger.js";

export const CollapsibleContext = React.createContext(() => log(null, levels.error, "CollapsibleContext was used before it was ready"));

export const CollapsibleContextProvider = ({ children, initialOpenState = false }) => {
	const [ isOpen, setIsOpen ] = useState(initialOpenState);
	const handleOpen = (bool) => setIsOpen(bool);

	return (
		<CollapsibleContext.Provider value={[ isOpen, handleOpen ]}>
			{children}
		</CollapsibleContext.Provider>
	);
};

export default CollapsibleContext;
