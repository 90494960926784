import React from 'react';
import { values } from "lodash";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import Toast from "../Toast";
import CmsContentList from '../data/CmsContentList';
import { useGlobalToastsContext } from 'context/ToastProvider.js';

const cms = {
	pass: 'miscText.autoload-confirmation-pass',
};

const PassAutoloadEnabled = ({ pass }) => {
	const { removeToast } = useGlobalToastsContext();
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Toast
				type="success"
				title={<>
					<CmsContentRendered.Span
						contentKey={cms.pass}
						fallbackValue={`${pass} set to autoload.`}
						variables={{ pass }}
					/>
				</>}
				onClosed={removeToast}
			/>
		)}
		</CmsContentList>
	);
};

export default PassAutoloadEnabled;
