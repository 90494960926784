import React, {
	useEffect,
	useRef,
	useState,
} from "react";
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import { usePrevious } from 'react-use';
import { countryCodeUS } from 'utils/Constants.js';
import cx from 'classnames';

import * as formStyles from 'components/forms/Forms.module.css';

const Phone = ({
	name = 'phoneNumber',
	countryName = 'phoneCountry',
	formHelper,
	defaultCountry = countryCodeUS,
	defaultNumber = '',
	legend,
	legendProps = {},
	placeholder = '+1 (555) 555-5555',
	id = 'phonenumberinputid',
	onCountryChanged = () => { },
	onNumberChanged = () => { },
	// if the initial value is an international number, then clearing out the field clears the country selection
	displayInitialValueAsLocalNumber = true, // changes the default
	className = "",
	phoneCountryAriaLabel,
	phoneNumberAriaLabel,
	...rest
}) => {


	// "current" prefix in case we want <Phone> to support a controlled 'country' prop
	const [ currentCountry, setCurrentCountry ] = useState(defaultCountry);
	const [ currentNumber, setCurrentNumber ] = useState(defaultNumber);
	const prevNumber = usePrevious(currentNumber);
	const numberRef = useRef();


	useEffect(() => {
		if (prevNumber !== undefined && prevNumber !== currentNumber) {
			onNumberChanged(currentNumber);
		}
	}, [ prevNumber, currentNumber ]); // eslint-disable-line react-hooks/exhaustive-deps

	const _onCountryChanged = (newCountry) => {
		setCurrentCountry(newCountry);
	};

	const _onNumberChanged = (newNumber) => {
		const newDefinedNumber = newNumber === undefined
			? ''
			: newNumber;

		setCurrentNumber(newDefinedNumber);
	};

	const realPhoneProps = {
		country: currentCountry,
		value: currentNumber,
		defaultCountry,
		displayInitialValueAsLocalNumber,
		placeholder,
		international: true,
		onChange: _onNumberChanged,
		onCountryChange: _onCountryChanged,
		onBlur: () => {
			if (formHelper) {
				// formhelper typically listens to the onChange event, which fires onBlur (contrast with onInput)
				formHelper.handleInputOnChange({ target: numberRef.current });
			}
		},
		id,
		...rest,
	};



	const inputClass = (error) => cx(
		className,
		error ? formStyles.phoneInputError : "",
	);
	// http://catamphetamine.github.io/react-phone-number-input/docs/styleguide/index.html#phoneinput
	return (
		<fieldset className={formStyles.phoneFieldSet}>
			<legend {...legendProps}>
				{legend}
			</legend>

			<PhoneInput
				ref={numberRef}
				numberInputProps={{ 'aria-label': phoneNumberAriaLabel }}
				countrySelectProps={{
					'aria-label': phoneCountryAriaLabel,
				}}
				className={inputClass(formHelper.getFieldError(name))}
				{...realPhoneProps}
			/>

			<input name={name} value={currentNumber} type="hidden" />
			<input name={countryName} value={currentCountry} type="hidden" />

			{name && formHelper.getFieldErrorJsx(name)}
		</fieldset>
	);
};
Phone.propTypes = {
	name: PropTypes.string,
	formHelper: PropTypes.object.isRequired,
};

export default Phone;
