import {
	clone,
	forEach,
	includes,
	merge,
} from "lodash";
import WSPayment, {
	PAYMENT_TYPES,
	WSPaymentFragment,
} from "./WSPayment.js";
import {
	InputWSEncryptedTokenType,
	WSEncryptedTokenType,
} from "server/api-types/WSEncryptedToken.js";
import { InputWSAddressType } from "server/api-types/WSAddress.js";

// 2.7.3.1 WSCreditDebitPayment
// This object extends WSPayment and represents a credit/debit card payment.
export default class WSCreditDebitPayment extends WSPayment {
	constructor({
		// super props
		paymentAmount,
		paymentType = null,

		pgCardId = null,
		creditCardType = null,
		maskedPan = null,
		cvv = null,
		pointOfEntryMode = null,
		merchantId = null,
		transactionType = null,
		paymentSubType = null,
		encryptedCrdbToken = null,
		cardExpiryMMYY = null,
		oneTime = null,
	}) {
		super({
			paymentType: paymentType ?? PAYMENT_TYPES.creditCard,
			paymentAmount,
		});

		// string(40)
		// (Conditionally-Required) The token representing the credit card to be
		// authorized.
		// Required if encryptedCrdbToken is null.
		this.pgCardId = pgCardId;

		// string(30)
		// (Optional)  The type of credit card. See section Error! Reference source not found. for valid values.
		this.creditCardType = creditCardType;

		// string(20)
		// (Optional) The masked PAN.
		this.maskedPan = maskedPan;

		// string(4)
		// (Optional)  Customer Verification Value.
		// The field must be all numeric and must contain a minimum of three digits and maximum of four digits.
		// It is left padded with 0.  Either null or a 0-length value indicates its absence.
		// This is required only for payment type CardNotPresent, Not applicable for other payment types.
		this.cvv = cvv;

		// int
		// (Required) Point of service entry mode. Indicates the actual card data entry mode and the terminal capability. For example, card swiped, card entered, card not present and etc.
		// Valid values are:
		// 011: Manual with personal identification number (PIN) entry capability.
		// 012: Manual with no PIN entry capability.
		// 021: Magnetic stripe with PIN entry capability.
		// 022: Magnetic stripe with no PIN entry capability.
		// 031: Magnetic stripe and manual with PIN entry capability.
		// 032: Magnetic stripe and manual with no PIN entry capability.
		// Required if encryptedCrdbToken is null.
		this.pointOfEntryMode = pointOfEntryMode;

		// string(11)
		// (Required) The merchant who processed the credit or debit card payment.
		this.merchantId = merchantId;

		// string(30)
		// (Conditionally-Required) Must be one of:
		// ECOMMERCE
		// RECURRING
		// MOTO (Mail Order/Telephone Order)
		// IVR
		// When using CPA as the payment gateway: this maps into webTixRequest
		// in CPA – used to determine which fields need to be combined to be sent
		// to the bank. VISA and ECOMMERCE might require different fields then
		// MC and ECOMMERCE.
		// Required if encryptedCrdbToken is null.
		this.transactionType = transactionType;

		// String(20)
		// (Optional) indicates the payment subtype such as
		// transit benefits or HAS/FSA.
		this.paymentSubType = paymentSubType;

		// WSEncryptedToken
		// (Conditionally-Required) The encrypted payment information. Required
		// for payments using virtual wallets like Apple Pay/Android Pay and credit
		// or debit cards that are not registered customer funding sources (one-time
		// use).
		// If this field is present, then pgCardId is not required.
		this.encryptedCrdbToken = encryptedCrdbToken;

		// string(4)
		// (Optional) The card expiry date in MMYY format. For MM, 01 = January.
		// This is to be provided when the card expiration must be corrected prior to
		// authorizing the payment.
		this.cardExpiryMMYY = cardExpiryMMYY;

		// reflexions custom oneTime payment data
		this.oneTime = oneTime;
	}

	static fromBackoffice(data) {
		const result = new WSCreditDebitPayment(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const result = merge(
			this,
			super.toBackoffice(),
		);

		// Per api doc: If encryptedCrdbToken is present then pgCardId is not required.
		if (result.encryptedCrdbToken) {
			delete result.pgCardId;
		}

		// Per api doc: Cvv is required only for payment type CreditCardNotPresent, Not
		// applicable for other payment types.
		if (result.paymentType === PAYMENT_TYPES.creditCardNotPresent) {
			delete result.cvv;
		}

		if (!result.encryptedCrdbToken) {
			delete result.encryptedCrdbToken;
		}

		if (!result.oneTime) {
			delete result.oneTime;
		}

		const OPTIONAL_FIELDS = [
			'creditCardType',
			'maskedPan',
			'paymentSubType',
			'cardExpiryMMYY',
		];

		const newResult = {};

		forEach(result, (value, key) => {
			// filter out optional fields that are empty
			if (includes(OPTIONAL_FIELDS, key) && !value) {
				return;
			}

			newResult[ key ] = value;
		});

		if (result.encryptedCrdbToken?.toBackoffice) {
			newResult.encryptedCrdbToken = result.encryptedCrdbToken.toBackoffice();
		}

		return newResult;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = this.pgCardId;

		return merge(
			forGraphql,
			super.toResolver(),
		);
	}

	toGraphqlInput() {
		// WSCreditDebitPayment doesn't have all the fields required to make an input
		// Also need cardCvv

		const forGraphqlInput = clone(this);

		if (!forGraphqlInput.encryptedCrdbToken) {
			delete forGraphqlInput.encryptedCrdbToken;
		}

		return forGraphqlInput;
	}

	toInputWSPaymentFactory() {
		return {
			wsCreditDebitPayment: this.toGraphqlInput(),
		};
	}
}

const WSCreditDebitPaymentFragment = `
	${WSPaymentFragment}

	pgCardId: String
	creditCardType: String
	maskedPan: String
	cvv: String

	# comes from env var. Null client-side
	pointOfEntryMode: Int
	merchantId: String
	transactionType: String

	paymentSubType: String
	cardExpiryMMYY: String
`;

// address1 no longer required as of R11
export const WSCreditDebitPaymentType = [
	...WSEncryptedTokenType,
	`
		type WSCreditDebitPayment {
			${WSCreditDebitPaymentFragment}
			encryptedCrdbToken: WSEncryptedToken
		}
	`,
];

export const InputWSCreditDebitPaymentType = [
	...InputWSEncryptedTokenType,
	...InputWSAddressType,
	`
		input InputWSCreditDebitPaymentOneTime {
			address: InputWSAddress!
			cardNumber: String!
			nameOnCard: String!
		}

		input InputWSCreditDebitPayment {
			${WSCreditDebitPaymentFragment}
			encryptedCrdbToken: InputWSEncryptedToken

			# fields used for one-time payment
			oneTime: InputWSCreditDebitPaymentOneTime
		}
	`,
];
