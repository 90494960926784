import React, { useState, useRef, useMemo } from "react";
import { capitalize, map, values, find, filter, reject } from "lodash";
import { RadioGroup } from "@headlessui/react";
import cx from "classnames";

import { useStepContext } from 'context/StepContext.js';
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import ServerDate from "components/ServerDate.js";
import { ALT_DATE_FORMAT, BUS } from "utils/Constants.js";
import { getTransitModeIcon } from "components/icons/TransitModes.js";
import { Station } from "utils/TripHistory.js";
import PreventDefault from "utils/PreventDefault.js";

import LocationSelect, {
	simplify,
} from "components/forms/inputs/LocationSelect.js";
import Button from "components/Button.js";
import { useSelectedLocationContext } from "context/SelectedLocationContext";
import {
	autoFilledTap,
	missedTap,
	deviceTap,
} from "server/api-types/WSRideHistoryFactory.js";
import { EXIT_TYPE } from "server/api-types/WSTravelPresence.js";

import * as tables from "components/DataTable.module.css";
import * as style from "components/modals/CorrectMissingTap.module.css";
import * as modalStyles from "../Modal.module.css";
import FormHelper from "utils/FormHelper";
import { useModalContext } from "context/ModalProvider";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";
import publicAppVars from "utils/PublicAppVars.js";

const cms = {
	text: "miscText.resolvetap-description",

	tapOn: "miscText.resolvetap-tapon",
	missingTap: 'miscText["resolvetap-missing-tap.label"]',
	selectStop: "miscText.resolvetap-bus-select",
	selectStation: "miscText.resolvetap-train-select",

	cancel: "miscText.resolvetap-cancel",
	resolveTap: "miscText.resolvetap-submit",

	formLabelsStation: "miscText.Forms-Labels-Station",
	busPlaceholder: "miscText.resolvetap-bus-placeholder",
	trainPlaceholder: "miscText.resolvetap-train-placeholder",

	route: "miscText.resolvetap-bus-route",
	stop: "miscText.resolvetap-bus-stop",
	station: "miscText.resolvetap-train-station",
};



export const simplifyDefault = (data, tapToCorrect) => ({
	value:
		tapToCorrect?.type === EXIT_TYPE
			? data?.endLocationDescription
			: data?.startLocationDescription,
	label:
		tapToCorrect?.type === EXIT_TYPE
			? data?.endLocationDescription
			: data?.startLocationDescription,
	operator: tapToCorrect?.operator,
	sector: tapToCorrect?.sector,
	// stopPoint: tapToCorrect?.stopPoint,
});

export const getTapToCorrect = (travelPresenceEvents) => {
	return (
		find(travelPresenceEvents, { travelPresenceIndicator: autoFilledTap }) ||
		find(travelPresenceEvents, { travelPresenceIndicator: missedTap })
	);
};

export const getKnownTap = (travelPresenceEvents) => {
	return find(travelPresenceEvents, { travelPresenceIndicator: deviceTap });
};

const CorrectMissingTap = ({
	wsTripRideHistory,
	prefix = "",
	setPrevFormData,
	yupSchema,
}) => {
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});

	const { setModal } = useModalContext();
	const { nextStep } = useStepContext();
	const formRef = useRef(null);

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, yupSchema);

	const { travelPresenceEvents, travelModeDescription } = wsTripRideHistory ?? {};

	const tapToCorrect = getTapToCorrect(travelPresenceEvents) ?? {};
	const knownTap = getKnownTap(travelPresenceEvents) ?? {};

	const [ searchInput, setSearchInput ] = useState(null);
	const { location, applyLocation, locations, locationsLoading } = useSelectedLocationContext();

	if (locationsLoading) {
		return <LoadingIcon size={SIZES.component} />;
	}

	const simplifiedLocations = map(locations, simplify);

	// filtering default stopPoint to hide already tapped entry/exit stops
	const filteredLocations = reject(simplifiedLocations, { stopPoint: knownTap.stopPoint });

	const searchFilteredLocations = searchInput
		? filter(filteredLocations,
			(location) => location.value.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1,
		)
		: filteredLocations;

	const locationsForSelect = searchFilteredLocations.slice(0, publicAppVars.MISSED_TAP_MAX_LOCATIONS);

	const kickoffSubmit = async () => {
		await setSubmitting(true);
		try {
			const validated = await formHelper.startValidation(true);

			setPrevFormData(validated);
		} catch (errorReport) {
			setSubmitting(false);
			// yup validation failed, but those errors are already in state
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		// Set next step in taps form
		nextStep();
		setSubmitting(false);

	};

	const onModalClose = () => {
		setModal(null);
	};


	const handleFormChange = (event) => {
		formHelper.clearFieldErrors(event.target);
	};

	return (
		<CmsContentList list={values(cms)}>
			{() => (
				<form
					method="post"
					data-qa="manageTapForm"
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
					onChange={handleFormChange}
				>

					<CmsContentRenderer.P
						contentKey={cms.text}
						fallbackValue="To properly calculate fare, some modes require a tap on and off. You missed one of these taps, so you may have been mischarged. If it is unclear whether a known tap was a tap on or tap off, it is assumed to be a tap on. You may resolve up to 3 missed taps per month. Screen reader support enabled."
					/>

					<div>
						<CmsContentRenderer.H3
							contentKey={cms.tapOn}
							fallbackValue="Tap on"
						/>

						<div className={style.tapDateTime}>
							<ServerDate
								dateISO={knownTap.transactionDateTime}
								options={{
									day: "numeric",
									month: "short",
									hour: "numeric",
									minute: "numeric",
								}}
								altFormat={ALT_DATE_FORMAT}
							/>
						</div>

						<div className={style.tapInfo}>
							<div className={style.routeInfo}>
								{travelModeDescription === BUS && (
									<CmsContentRenderer.Span
										contentKey={cms.route}
										className={style.routeLabel}
										fallbackValue="Route"
									/>
								)}
								{getTransitModeIcon(
									capitalize(wsTripRideHistory.travelMode),
									{
										overrideClass: tables.transportIcon,
									},
								)}
							</div>
							<div>
								{travelModeDescription === BUS ? (
									<CmsContentRenderer.Span
										contentKey={cms.stop}
										className={style.stationStopLabel}
										fallbackValue="Stop"
									/>
								) : (
									<CmsContentRenderer.Span
										contentKey={cms.station}
										className={style.stationStopLabel}
										fallbackValue="Station"
									/>
								)}
								<Station
									{...{
										station: wsTripRideHistory.startLocationDescription,
										mode: wsTripRideHistory.travelModeDescription,
									}}
								/>
							</div>
						</div>

						<input
							type="hidden"
							name={`tripId${prefix}`}
							value={wsTripRideHistory.rideId}
						/>
						<input
							type="hidden"
							name={`travelMode${prefix}`}
							value={wsTripRideHistory.travelMode}
						/>
						<input
							type="hidden"
							name={`travelPresenceId${prefix}`}
							value={tapToCorrect.travelPresenceId}
						/>
					</div>

					<div className={""}>
						<CmsContentRenderer.H3
							contentKey={cms.missingTap}
							fallbackValue="Missing Tap"
						/>

						<LocationSelect
							name={"station"}
							error={formHelper.getFieldErrorJsx("station")}
							additionalClasses={style.control}
							defaultValue={simplifyDefault(
								wsTripRideHistory,
								tapToCorrect,
							)}
							newLocation={location}
							searchInput={searchInput}
							setSearchInput={setSearchInput}
							travelModeDescription={travelModeDescription}
							{...{ prefix }}
						/>
					</div>
					<div className={style.locationsWrapper}>
						<RadioGroup value={location} onChange={applyLocation}>
							<RadioGroup.Label>
								<CmsContentRenderer.H4
									contentKey={
										travelModeDescription === BUS
											? cms.selectStop
											: cms.selectStation
									}
									fallbackValue={
										travelModeDescription === BUS
											? "Select from these stops"
											: "Select from these stations"
									}
								/>
							</RadioGroup.Label>
							<div className={style.locationList}>
								{map(locationsForSelect || [], (location, index) => (
									<RadioGroup.Option
										key={`${location.value}-${index}`}
										value={location}
										className={style.location}
									>
										<span className={style.locationText}>
											{location.value}
										</span>
									</RadioGroup.Option>
								))}
							</div>
						</RadioGroup>
					</div>

					<div className={style.actions}>
						<Button
							type="button"
							onClick={PreventDefault(onModalClose)}
							additionalClassNames={cx(style.btn, modalStyles.leftButton)}
						>
							<CmsContentRenderer.Span
								contentKey={cms.cancel}
								fallbackValue="Cancel"
							/>
						</Button>

						<Button
							isPrimary
							type="submit"
							additionalClassNames={cx(
								style.btn,
								modalStyles.rightButton,
							)}
							submitting={submitting}
							disabled={!location}
						>
							<CmsContentRenderer.Span
								contentKey={cms.resolveTap}
								fallbackValue="Resolve Tap"
							/>
						</Button>
						{formHelper.getFieldErrorJsx("")}
					</div>
				</form>
			)}
		</CmsContentList>
	);
};

export default CorrectMissingTap;
