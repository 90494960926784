import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Panel from 'components/Panel.js';

import * as noResults from './DataTableZeroState.module.css';
import LoadingIcon from 'components/icons/LoadingIcon';



export const TYPE_TRIPS = "trips";
export const TYPE_PURCHASES = "purchases";

const cms = {
	trips: 'miscHtml.triphistory-empty',
	purchases: 'miscHtml.purchasehistory-empty',
};

const DataTableZeroState = ({
	type = TYPE_TRIPS,
	isLoading = false,
}) => {
	return (
		<CmsContentList list={values(cms)}>{() =>
			<Panel overrideClass={noResults.container}>
				{isLoading ?
					<LoadingIcon />
					:	type === TYPE_TRIPS
						?
						<CmsContentRenderer.Div rawHtml
							contentKey={cms.trips}
							fallbackValue="<p><strong>You have no recent trips.</strong></p><p>Start using your Charlie Card and your data will display here.</p>"
							className={noResults.text}
						/>
						:
						<CmsContentRenderer.Div rawHtml
							contentKey={cms.purchases}
							fallbackValue="<p><strong>You have no recent purchases.</strong></p>"
							className={noResults.text}
						/>
				}
			</Panel>
		}</CmsContentList>
	);
};

DataTableZeroState.propTypes = {
	type: PropTypes.string,
};

export default DataTableZeroState;
