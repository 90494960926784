// extracted by mini-css-extract-plugin
export var acceptedPaymentTypes = "components-account-__TabAddPaymentCard-module___acceptedPaymentTypes";
export var cardWrapper = "components-account-__TabAddPaymentCard-module___cardWrapper";
export var ccardIcon = "components-account-__TabAddPaymentCard-module___ccardIcon";
export var nicknameSuffix = "components-account-__TabAddPaymentCard-module___nicknameSuffix";
export var paymentOpts = "components-account-__TabAddPaymentCard-module___paymentOpts";
export var rowHeader = "components-account-__TabAddPaymentCard-module___rowHeader";
export var securityCode = "components-account-__TabAddPaymentCard-module___securityCode";
export var securityCodeTooltip = "components-account-__TabAddPaymentCard-module___securityCodeTooltip";
export var setPrimaryCheckbox = "components-account-__TabAddPaymentCard-module___setPrimaryCheckbox";
export var setSavePaymentCheckbox = "components-account-__TabAddPaymentCard-module___setSavePaymentCheckbox";
export var title = "components-account-__TabAddPaymentCard-module___title";