import { MEMBER_STATUSES } from 'server/api-types/WSMemberStatus.js';
import PublicAppVars from "../utils/PublicAppVars.js";
import {
	startOfTomorrow,
	subDays,
	subYears,
} from "date-fns";


export const FILTER_TYPE_30_DAYS = "30";
export const FILTER_TYPE_45_DAYS = "45";
export const FILTER_TYPE_CURRENT_MONTH = 'current-month';
export const FILTER_TYPE_90_DAYS = "90";
export const FILTER_TYPE_12_MONTHS = "365";
export const X_DAYS_PRIOR_OPTIONS = [
	FILTER_TYPE_30_DAYS,
	FILTER_TYPE_45_DAYS,
	FILTER_TYPE_90_DAYS,
	FILTER_TYPE_12_MONTHS,
];

export const ASCENDING_SORT_ORDER = 'asc';
export const DESCENDING_SORT_ORDER = 'desc';

export const LAST_NAME_SORT_TYPE = 'lastName';
export const EXP_DATE_SORT_TYPE = 'expirationDate';
export const STATUS_SORT_TYPE = 'memberStatus';

// sortType by default should be: DATE_ADDED_SORT_TYPE,
// for now use last name sort as a default because
// sort activationDate was not implemented by BO as of 08/10/2021
// export const DEFAULT_MEMBERS_SORT_B2B = `${DATE_ADDED_SORT_TYPE}.${DESCENDING_SORT_ORDER}`;
export const DEFAULT_MEMBERS_SORT_B2B = `${LAST_NAME_SORT_TYPE}.${DESCENDING_SORT_ORDER}`;
export const DEFAULT_MEMBERS_STATUSES_B2B = Object.values(MEMBER_STATUSES);
export const NO_FILTERS_MEMBERS_B2B = {
	sortBy: DEFAULT_MEMBERS_SORT_B2B,
	lastNameMatch: null,
	firstNameMatch: null,
	emailMatch: null,
	memberStatuses: null, // DEFAULT_MEMBERS_STATUSES_B2B.join(','),
	transitAccountIdMatch: null,
	externalMemberIdMatch: null,
	expirationDateStart: null,
	expirationDateEnd: null,
};

export const ALERTS_MAX_DAYS = PublicAppVars.ALERTS_MAX_DAYS;

export const DEFAULT_DATEFILTER_TYPE =
	PublicAppVars.isB2BApi
		? FILTER_TYPE_12_MONTHS
		: X_DAYS_PRIOR_OPTIONS.includes(PublicAppVars.DEFAULT_FILTER_DAYS)
			? PublicAppVars.DEFAULT_FILTER_DAYS
			: FILTER_TYPE_CURRENT_MONTH;


export const NO_FILTERS = {
	dateFilterType: DEFAULT_DATEFILTER_TYPE,
	startDateTime: null,
	endDateTime: null,
	specificDate: null,
	tokenIds: null,
	subsystemAccountRef: null,
	purseRestriction: null,
};
export const FILTER_TYPE_SELECTED_DATE = 'selected-date';
export const FILTER_TYPE_DATE_RANGE = 'date-range';

export const FILTER_TYPE_ALL_PAYMENT_METHODS = 'allPaymentMethods';
export const FILTER_TYPE_ALL_FUNDS_METHODS = 'allFundsMethods';

export const FILTER_TYPE_TRIP = 'trip';
export const FILTER_TYPE_BALANCE = 'balance';
export const FILTER_TYPE_PURCHASE = 'purchase';

export const FILTER_TYPE_MEMBERS = 'members';

export const FILTERABLE_MEMBERS_FIELDS = [
	{ value: 'lastName', label: 'Last name' },
	{ value: 'firstName', label: 'First name' },
	{ value: 'email', label: 'Email' },
	{ value: 'memberStatus', label: 'Status' },
	{ value: 'transitAccountId', label: 'Transit Account #' },
	{ value: 'externalMemberId', label: 'External ID' },
	{ value: 'expirationDate', label: 'Expiration date' },
];

export const UNREGISTERED_HISTORY_DAYS = PublicAppVars.UNREGISTERED_HISTORY_DAYS;


export const NO_FILTERS_B2B = {
	dateFilterType: DEFAULT_DATEFILTER_TYPE,
	startDateTime: subYears(startOfTomorrow(), 1).toISOString(),
	endDateTime: startOfTomorrow().toISOString(),
	specificDate: null,
};

// activationDate type BO release should be confirmed by Andres 08/09/2021
// https://reflexions.slack.com/archives/C025ELK3M39/p1628528732169000
export const DATE_ADDED_SORT_TYPE = 'activationDate';

export const SORT_TYPES = {
	lastName: LAST_NAME_SORT_TYPE,
	status: STATUS_SORT_TYPE,
	expirationDate: EXP_DATE_SORT_TYPE,
};

export const DEFAULT_UPLOAD_HISTORY_B2B = {
	startDateTime: subYears(startOfTomorrow(), 1).toISOString(),
	endDateTime: startOfTomorrow().toISOString(),
	dateFilterType: FILTER_TYPE_12_MONTHS,
};

export const PAYMENT_STATUS_B2B_SUCCESS = 'Success';
export const PAYMENT_STATUS_B2B_DECLINE = 'Decline';
export const PAYMENT_STATUS_B2B_REVERSAL = 'Reversal';

export const PAYMENT_TYPE_B2B_CHECK = 'Check';
export const PAYMENT_TYPE_B2B_DIRECT_DEBIT = 'DirectDebit'; // ACH
export const PAYMENT_TYPE_B2B_DIRECT_DEPOSIT = 'DirectDeposit'; // Direct Deposit – direct deposit
export const PAYMENT_TYPE_B2B_CREDIT_CARD = 'CreditCardNotPresent'; // CC
export const PAYMENT_TYPE_B2B_LOCKBOX = 'Lockbox';
export const PAYMENT_TYPE_B2B_WIRE = 'Wire'; // Wire Transfer


export const FILTER_TYPE_ORDERS = 'orders';
export const FILTER_TYPE_PAYMENTS = 'payments';
export const FILTER_TYPE_UPLOADS = 'uploads';
export const FILTER_TYPE_INVOICES = 'invoices';
export const FILTER_TYPE_STATEMENTS = 'statements';

export const FILTER_PAYMENT_B2B = 'paymentB2B';

const dateMinuteCeil = (date) => {
	const result = new Date(date.getTime());
	result.setSeconds(0);
	result.setMilliseconds(0);
	result.setMinutes(result.getMinutes() + 1);
	return result;
};

export const dateFilterToStartEndDate = (dateFilterType) => {
	// To avoid generating a different date with each rerender of a component using these date filters
	// (which would then trigger another rerender) we clamp the date to the end of the current minute.
	const now = dateMinuteCeil(new Date());
	const startDate = subDays(now, dateFilterType);
	const startDateMidnight = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

	return { startDateTime: startDateMidnight, endDateTime: now };
};
