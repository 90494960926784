/**
 * This Banner appears on Login when the email isn't found in the system
 */
import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Banner, { TYPE_ERROR } from 'components/Banner.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';



const cms = {
	title: 'miscText["signin-error-notfound-banner.title"]',
	text: 'miscText["signin-error-notfound-banner.description"]',
	cta: 'miscText["signin-error-notfound-banner.cta"]',
	ctaUrl: 'miscText["signin-error-notfound-banner.cta_url"]',
};

const NoAccountFound = ({}) => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Banner
				type={TYPE_ERROR}
				title={<CmsContentRenderer.Span
					contentKey={cms.title}
					fallbackValue="Been a while since you've logged in?"
				/>}
				body={<CmsContentRenderer.Span
					contentKey={cms.text}
					fallbackValue="You may need to create a new account."
				/>}
				rightSide={
					<Button typeStyle={buttonTypeStylePlain}
						external
						to={cmsContent[ cms.ctaUrl ] ?? 'https://mycharlie.mbta.com/'}
					>
						<CmsContentRenderer.Span contentKey={cms.cta}
							fallbackValue="Learn more"
						/>
					</Button>
				}
			/>
		)}</CmsContentList>
	);
};

NoAccountFound.propTypes = {};

export default NoAccountFound;
