import { clone } from "lodash";

// 2.7.41 WSIssueMedia
export default class WSIssueMedia {
	constructor({
		type,
	}) {

		/**
		 * @type string
		 * date
		 * (Required) It is used to identify the type of new media. Currently supported type is:
		 *  TransitAccountMedia – Deprecated, use SubsystemMedia
		 *  VirtualToken
		 *  IosEndorVTokenMedia
		 *  IosEndorWalletVTokenMedia
		 *  SubsystemMedia
		 *  TollingTransponder – FUTURE
		 *  CSCMedia (TransitCardBasedMedia) – FUTURE
		 *  OneAccountMedia – FUTURE (across multiple subsystems)
		 **/
		this.type = type;
	}

	static fromBackoffice(data) {
		const result = new WSIssueMedia(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		return forGraphql;
	}
}
