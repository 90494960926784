import React, { useEffect } from 'react';
import {
	map,
	find,
	values,
	isEmpty, uniq,
} from 'lodash';
import Select from './forms/inputs/Select.js';
import { useIsEmvCard } from 'components/data/transit-account/EMV.helpers.js';
import { cardCVV, TooltipCvv } from 'components/payments/CreditCardForm.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import TogglePaymentMethod, { getCardNickNameOrType } from 'components/account/card/add-passes/TogglePaymentMethod.js';
import { CardImage } from 'components/icons/CardBrands.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import AddDebitCreditCard from 'components/account/AddDebitCreditCard.js';
import { LOGGED_IN_QUERY, useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";
import FormHelperProvider, { useFormHelperContext } from 'utils/form-helper/FormHelperProvider.js';
import { PREFIX_PRIMARY, PREFIX_BACKUP } from 'utils/Constants.js';

import * as style from 'components/account/card/add-passes/TogglePaymentMethod.module.css';
import * as forms from 'components/forms/Forms.module.css';
import Input from "components/forms/Input.js";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { useSessionAlternateName } from "components/data/alternates/SessionAlternate.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { getFormattedNameSuffix } from "utils/FormatHelpers.js";
import { useCustomerName } from "components/data/session-user/SessionUser.js";

const cms = {
	placeholderLabel: 'miscText["purchase-payment-choose-payment.label"]',
	securityCode: 'miscText.["general-payment-cc-cvv.label"]',
	securityCodeDescription: "miscText.general-payment-cc-cvv-tooltip",
};

export const getPaymentMethodInputName = ({ isSplit, isFirst }) => {
	return getPaymentInputName({ base: 'paymentMethod', isSplit, isFirst });
};

export const DropdownLabel = ({
	creditCardType,
	nameOnCard,
	maskedPan,
	nicknameSuffix = null,
}) => (
	<span className={style.lineItem}>
		<CardImage
			creditCardType={creditCardType}
			className={style.ccIcon}
		/>
		{nameOnCard} ...{maskedPan} {nicknameSuffix && <span className={forms.nicknameSuffix}>{nicknameSuffix}</span>}
	</span>
);

const FundingPaymentMethods = ({
	formHelper: formHelperProp,
	provideNickName,
	overwriteLabel,
	isSplit,
	isFirst = true,
	alwaysSaveNewPayment = false,
	defaultFundingSource: defaultFundingSourceProp = {},
	canHideCvvInput = false,
	requiredSave = false,
}) => {
	const { loginStage } = useLoginStage();
	const unRegisteredLoggedIn = (loginStage === loginStages.unRegisteredLoggedIn);
	const formHelperContext = useFormHelperContext();
	const formHelper = formHelperProp ?? formHelperContext.formHelper;
	const {
		fundingSources,
		selectedFundingSources,
		updateSelectedFundingSource,
	} = useFundingSourcesContext();

	const transit_account_id = useTransitAccountIdContext();
	const { data: { session: { customerId: currentCustomerId = null } = {} } = {} } = useStdQuery(LOGGED_IN_QUERY);

	const splitPaymentIndex = isFirst ? 0 : 1;
	const selectedFundingSource = selectedFundingSources[ splitPaymentIndex ];

	const isEmv = useIsEmvCard({ subsystemAccountReference: transit_account_id });

	const showNewPaymentForm = (unRegisteredLoggedIn && !isEmv || selectedFundingSource?.isNew);
	const primaryFundingSource = find(fundingSources, ({ setAsPrimary }) => setAsPrimary) || fundingSources.length === 1 && fundingSources[ 0 ];
	// set up default payment for primary payment and for backup payment if edit autoload

	const toggleIsVisible = !unRegisteredLoggedIn && !showNewPaymentForm && !isEmv;

	const alternateName = useSessionAlternateName();
	const customerName = useCustomerName();
	const { customerNameFormatted, alternateNameFormatted } = getFormattedNameSuffix({ alternateName, customerName });

	const defaultFundingSource =
		!isEmpty(defaultFundingSourceProp)
			? defaultFundingSourceProp
			: isFirst
				? primaryFundingSource
				: null;

	const selectedFundingSourceChanged = ({ fundingSource }) => {
		updateSelectedFundingSource({
			splitPaymentIndex,
			fundingSource,
		});

		formHelper.clearAllErrors();
	};

	useEffect(() => {
		if (defaultFundingSource && !selectedFundingSource) {
			updateSelectedFundingSource({
				splitPaymentIndex,
				fundingSource: defaultFundingSource,
			});
		}
	}, [
		defaultFundingSource,
		selectedFundingSource,
		updateSelectedFundingSource,
		splitPaymentIndex,
	]);

	const placeholder = !isEmpty(defaultFundingSource)
		? <DropdownLabel {...{
			creditCardType: defaultFundingSource?.creditCard?.creditCardType,
			nameOnCard: getCardNickNameOrType(defaultFundingSource?.Nickname, defaultFundingSource?.creditCard?.creditCardType),
			maskedPan: defaultFundingSource?.creditCard?.maskedPan,
		}} />
		: <CmsContentRenderer.Span contentKey={cms.placeholderLabel} fallbackValue="Choose Payment Method" />;

	const fundingOptions = map(fundingSources, fundingSource => {
		const {
			nickname,
			customerId,
		} = fundingSource;

		const {
			creditCardType,
			maskedPan,
		} = fundingSource.creditCard ?? {};

		const nameOnCard = getCardNickNameOrType(nickname, creditCardType);

		const nicknameSuffix = alternateName
			? customerId !== currentCustomerId
				? alternateNameFormatted
				: customerNameFormatted
			: null;

		return {
			label: <DropdownLabel {...{ creditCardType, nameOnCard, maskedPan, nicknameSuffix }} />,
			value: fundingSource,
		};
	});

	const showCvvInput = !canHideCvvInput;
	const getInputName = (fieldName) => getPaymentInputName({ base: fieldName, isSplit, isFirst });

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<FormHelperProvider formHelperContext={{ formHelper }}>
				<>
					{showNewPaymentForm
						? <AddDebitCreditCard {...{
							formHelper,
							isSplit,
							isFirst,
							alwaysSaveNewPayment,
							provideNickName,
							requiredSave,
							selectedFundingSource,
						}} />
						: <div className={style.selectorWrapper}>
							<Select
								name={getPaymentMethodInputName({ isSplit, isFirst })}
								label={overwriteLabel ??
									<CmsContentRenderer.Span contentKey={cms.placeholderLabel} fallbackValue="HC: Choose Payment Method" />}
								{...{ placeholder }}
								options={fundingOptions}
								isSearchable={false}
								value={fundingOptions.find(({ value }) => value === selectedFundingSource?.fundingSource) ?? {}}
								onChange={({ value }) => selectedFundingSourceChanged({ fundingSource: value })}
								error={formHelper.getFieldError(getPaymentMethodInputName({ isSplit, isFirst }))}
								additionalClasses={style.savePaymentSelect}
							/>
							{showCvvInput ?
								<div className={forms.passwordWrapper}>
									<Input
										autoComplete="cc-csc"
										id={getInputName(cardCVV)}
										arialabel={cmsContent[ cms.securityCode ] || 'Security Code'}
										hideLabel={true}
										name={getInputName(cardCVV)}
										overrideClass={style.passwordInput}
										maxLength={4}
										error={formHelper.getFieldError(getInputName(cardCVV))}
										value={selectedFundingSource?.cvv ?? ''}
										onChange={({ target }) => updateSelectedFundingSource({ splitPaymentIndex, cvv: target.value })}
										maxLengthErrorMsg={null}
										maxLengthDisplay={false}
										controlled={true}
									/>
									<TooltipCvv {...{
										tooltipId: `${isFirst ? PREFIX_PRIMARY : PREFIX_BACKUP}_cvv_tooltip`,
										cmsContent,
										labelCmsKey: cms.securityCode,
										labelPanelCmsKey: cms.securityCodeDescription,
										htmlFor: getInputName(cardCVV),
									}} />
								</div>
								: null
							}
						</div>
					}

					{toggleIsVisible
						? <TogglePaymentMethod {...{
							formHelper,
							splitPaymentIndex,
						}} />
						: null
					}
				</>
			</FormHelperProvider>
		)}</CmsContentList>
	);
};

export default FundingPaymentMethods;
