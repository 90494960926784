import {
	map,
	clone,
} from "lodash";
import WSIssueMediaLoadProduct, { InputWSIssueMediaLoadProductType } from "./WSIssueMediaLoadProduct.js";
import WSIssueMedia from './WSIssueMedia.js';
import {
	InputWSIssueMediaLoadSubsystemAccountValueType,
} from "server/api-types/WSIssueMediaLoadSubsystemAccountValue.js";
import { InputWSIssueMediaLoadSubsystemProductType } from "server/api-types/WSIssueMediaLoadSubsystemProduct.js";
import WSIssueMediaLoadProductFactory, {
	InputWSIssueMediaLoadProductFactoryType,
} from "server/api-types/WSIssueMediaLoadProductFactory.js";

// Table 2.7.39.1 WSIssueSubsystemMedia Field Descriptions
export default class WSIssueSubsystemMedia extends WSIssueMedia {
	constructor({
		subsystem,
		subsystemAccountReference = null,
		loadProducts = null,
		mediaType,
		itemTotalAmount,
		replacedTravelTokenId = null,
		subsystemTokenType,
		enablementFeeAmount = null,
		inventoryPartNumber = null,

		// Super props
		type,
	}) {

		super({
			type,
		});

		/**
		 * @type string(20)
		 * (Required) Unique identifier of the subsystem.
		 */
		this.subsystem = subsystem;

		/**
		 * @type string (20)
		 * (Conditionally-Required) Unique identifier for the customer’s account in the subsystem.
		 * If the subsystemAccountReference is not provided, a new subsystem account will be created
		 * and linked to the registered customer with the issued media.
		 * Otherwise, the media will be issued to the specified subsystem account.
		 * subsystemAccountReference is conditionally required when replacedTravelTokenId is provided.
		 */
		this.subsystemAccountReference = subsystemAccountReference;

		/**
		 * @type string(50)
		 * (Required) the type of media to issue. See section 2.6.9 Media Typesfor possible values.
		 */
		this.mediaType = mediaType;

		/**
		 * @type List<WSIssueMediaLoadProduct>
		 * (Optional) the products to be loaded to the new media.
		 */
		this.loadProducts = loadProducts;

		/**
		 * @type int
		 * (Required) Total amount in cents for the line item (includes media, deposit and products).
		 */
		this.itemTotalAmount = itemTotalAmount;

		/**
		 * @type string(20)
		 * (Optional) The media travel token id being replaced. This field will be used when used in the replacement context.
		 */
		this.replacedTravelTokenId = replacedTravelTokenId;

		/**
		 * @type string(40)
		 * (Required) Subsystem specific token type for this media type as
		 * defined by the subsystem that manages the travel token.
		 * For example, for subsystem ABP, Smartcard, Bankcard, OpenTransitRegular.
		 * This information is needed for issuing the travel token in the subsystem.
		 */
		this.subsystemTokenType = subsystemTokenType;

		/**
		* @type Int
		* (Optional) the media fee amount
		*/
		this.enablementFeeAmount = enablementFeeAmount;

		/**
		 * @type string(100)
		 * (Optional) Media Product Inventory Part Number.
		 */
		this.inventoryPartNumber = inventoryPartNumber;
	}

	static fromBackoffice(data) {
		const result = new WSIssueSubsystemMedia(data);

		result.loadProducts = data.loadProducts
			? map(data.loadProducts, loadProduct => WSIssueMediaLoadProductFactory.fromBackoffice(loadProduct)):
			null;

		return result;
	}

	static fromSerialized(data) {
		const result = new WSIssueSubsystemMedia(data);

		result.loadProducts = data.loadProducts
			? map(data.loadProducts, loadProduct => WSIssueMediaLoadProductFactory.fromSerialized(loadProduct)):
			null;

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		result.loadProducts = this.loadProducts
			? map(this.loadProducts, loadProduct => loadProduct.toBackoffice())
			: null;

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.loadProducts = this.loadProducts
			? map(this.loadProducts, loadProduct => loadProduct.toResolver())
			: null;

		return forGraphql;
	}
}

export const WSIssueSubsystemMediaFragment = `
	type: String!
	subsystem: String!
	subsystemAccountReference: String
	mediaType: String!
	itemTotalAmount: Int!
	replacedTravelTokenId: String
	subsystemTokenType: String!
	enablementFeeAmount: Int
	inventoryPartNumber: String
`;

export const InputWSIssueSubsystemMediaType = [
	...InputWSIssueMediaLoadProductType,
	...InputWSIssueMediaLoadProductFactoryType,
	`input InputWSIssueSubsystemMedia {
		${WSIssueSubsystemMediaFragment}
		loadProducts: [ InputWSIssueMediaLoadProductFactory! ]
	}`,
];

export const WSIssueSubsystemMediaType = [ `
	type WSIssueSubsystemMedia {
		${WSIssueSubsystemMediaFragment}
		loadProducts: [ WSIssueMediaLoadProduct! ]
	}
` ];
