import React, {
	useContext,
} from "react";

import {
	head,
	map,
} from 'lodash';

import {
	log,
	levels,
	noticeError,
} from 'utils/Logger.js';
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { GET_PAYMENT_METHODS } from 'components/data/order/sale/Paymentmethods.query.js';
import { GET_AUTOLOAD_PAYMENT_METHODS } from 'components/data/transit-account/GetAutoloadPaymentMethods.js';
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import {
	getProductList,
} from 'pages/account/PurchasePass.js';
import WSAutoloadSubsystemValue from 'server/api-types/WSAutoloadSubsystemValue.js';
import WSLoadSubsystemAccountValue from 'server/api-types/WSLoadSubsystemAccountValue.js';
import { useCartContext } from "context/CartProvider";

export const FundingSourcesQueryContext = React.createContext([ {}, () => log(null, levels.error, "FundingSourcesQueryContext used before it was ready") ]);
export const useFundingSourcesQueryContext = () => useContext(FundingSourcesQueryContext);

// Auto reloading stored value gets fundingsources from order/autoload/enroll/paymentmethods.
// Purchasing stored value gets fundingsources from order/sale/paymentmethods.
// This react context provides the appropraite query and sets the response to context.

const FundingSourcesQueryProvider = ({
	reloadPaymentMethods = false,
	subsystemAccountReference: providedSubsystemAccountReference,
	children,
}) => {
	const transitAccountId = useTransitAccountIdContext();
	const [ mutator, { loading } ] = useStandardMutation(reloadPaymentMethods ? GET_AUTOLOAD_PAYMENT_METHODS : GET_PAYMENT_METHODS);
	const {
		initializeFundingSources,
	} = useFundingSourcesContext();
	const {
		saveShoppingCartId,
	} = useCartContext();

	const { filterPurses } = usePurseBalanceContext();
	const wSSubsystemPurse = head(filterPurses);

	const subsystemAccountReference = providedSubsystemAccountReference || transitAccountId;

	const getFundingSources = ({ products, amount, formHelper }) => {
		const variables = reloadPaymentMethods
		// AM-032  W-4965 Manage Automatic Charges - Automatic Reload
		// https://reflexions.slack.com/archives/CAT36MFLZ/p1637716476088300
			? {
				autoloadSubsystemValue: new WSAutoloadSubsystemValue({
					subsystemAccountId: subsystemAccountReference,
					subsystemRef: "ABP",
					value: amount,
					purseType: "StoredValue",
					purseRestriction: "Unrestricted",
					type: "AddSubsystemValue",
					loadValueBehavior: "AddAutoloadValue",
				}),
			}
		// Integration Guide AM-110 Purchase Stored Value
		// https://reflexions.slack.com/archives/CAT36MFLZ/p1637716492088800
			: {
				storedValues: map(
					getProductList({ balance: amount }, subsystemAccountReference, wSSubsystemPurse).storedValues,
					wsLoadSubsystemProduct => new WSLoadSubsystemAccountValue({ ...wsLoadSubsystemProduct })
				),
				products: products,
				subsystemAccountReference,
			};
		return mutator({
			variables,
		}).then(response => {
			const { fundingSources, loadProductLineItemFunds = [] } = response.data.OrderRoute[
				reloadPaymentMethods
					? 'getAutoLoadEnrollPaymentmethods'
					: 'getPaymentmethods'
			];

			initializeFundingSources({
				fundingSources,
				loadProductLineItemFunds,
			});

			saveShoppingCartId(response?.data.OrderRoute.getPaymentmethods.shoppingCartId);
		}).catch(errorReport => {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Purchase Stored Value Payment Methods POST Failed`);
			formHelper.validationErrorHandler(errorReport);
		});
	};

	const contextValue = {
		getFundingSources,
		loading,
	};

	return (
		<FundingSourcesQueryContext.Provider value={contextValue}>
			{children}
		</FundingSourcesQueryContext.Provider>
	);
};

export default FundingSourcesQueryProvider;
