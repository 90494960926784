import { map } from 'lodash';
import WSIssueMediaLoadSubsystemProduct, {
	InputWSIssueMediaLoadSubsystemProductType,
	WSIssueMediaLoadSubsystemProductType,
} from "./WSIssueMediaLoadSubsystemProduct.js";
import WSIssueMediaLoadSubsystemAccountValue, {
	InputWSIssueMediaLoadSubsystemAccountValueType,
	WSIssueMediaLoadSubsystemAccountValueType,
} from "./WSIssueMediaLoadSubsystemAccountValue.js";

import WSSubsystemOperator from './WSSubsystemOperator.js';
import WSIssueMediaLoadProduct, { WSIssueMediaLoadProductFragment } from "./WSIssueMediaLoadProduct.js";
import { levels, log } from "utils/Logger.js";

import { types } from "server/api-types/WSLoadProductFactory.js";
import WSOneAccountValuePayment from "server/api-types/WSOneAccountValuePayment.js";
import WSSubsystemValuePayment from "server/api-types/WSSubsystemValuePayment.js";
import WSDirectDebitPayment from "server/api-types/WSDirectDebitPayment.js";
import WSCreditDebitPayment from "server/api-types/WSCreditDebitPayment.js";
import WSEncryptedTokenPayment from "server/api-types/WSEncryptedTokenPayment.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

// 2.7.46 WSIssueMediaLoadProduct

const objToClass = ({ loadType }) => {
	switch (loadType) {
		case types.loadSubsystemProduct:
			return WSIssueMediaLoadSubsystemProduct;
		case types.loadSubsystemAccountValue:
			return WSIssueMediaLoadSubsystemAccountValue;
		default:
			log(null, levels.error, `typeToClass: unknown  WSIssueMediaLoadProduct type ${loadType}`);
			return "WSIssueMediaLoadProduct";
	}
};

const objToClassName = (obj) => {
	return objToClass(obj).constructor.name;
};

const inputObjFieldMapping = {
	wsIssueMediaLoadSubsystemProduct: WSIssueMediaLoadSubsystemProduct,
	wsIssueMediaLoadSubsystemAccountValue: WSIssueMediaLoadSubsystemAccountValue,
};

export default class WSIssueMediaLoadProductFactory {
	static buildClass(data) {
		switch (data.loadType) {
			case types.loadSubsystemProduct:
				return new WSIssueMediaLoadSubsystemProduct({
					...data,
					permittedUsers: map(data.permittedUsers, wsSubsystemOperator => new WSSubsystemOperator(wsSubsystemOperator)),
				});
			case types.loadSubsystemAccountValue:
				return new WSIssueMediaLoadSubsystemAccountValue(data);
			default:
				log(null, levels.error, `unknown  WSIssueMediaLoadProduct type ${data.loadType}`);
				return new WSIssueMediaLoadProduct(data);
		}
	}

	/**
	 * takes an InputWSIssueMediaLoadProductFactory, returns an instantiated WSIssueMediaLoadProduct subclass
	 * @param inputFactoryData
	 * @returns {*}
	 */
	static buildInputClass(inputFactoryData) {
		const [ lookupKey, data ] = Object.entries(inputFactoryData)[ 0 ];
		const inputClassPtr = inputObjFieldMapping[ lookupKey ];
		return new inputClassPtr(data);
	}

	static fromBackoffice(data) {
		switch (data.loadType) {
			case types.loadSubsystemProduct:
				return WSIssueMediaLoadSubsystemProduct.fromBackoffice(data);
			case types.loadSubsystemAccountValue:
				return WSIssueMediaLoadSubsystemAccountValue.fromBackoffice(data);
			default:
				log(null, levels.error, `unknown  WSIssueMediaLoadProduct type ${data.loadType}`);
				return WSIssueMediaLoadProduct.fromBackoffice(data);
		}
	}

	static fromSerialized(data) {
		switch (data.loadType) {
			case types.loadSubsystemProduct:
				return WSIssueMediaLoadSubsystemProduct.fromSerialized(data);
			case types.loadSubsystemAccountValue:
				return WSIssueMediaLoadSubsystemAccountValue.fromSerialized(data);
			default:
				log(null, levels.error, `unknown  WSIssueMediaLoadProduct type ${data.productLineItemType}`);
				return WSIssueMediaLoadProduct.fromSerialized(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSIssueMediaLoadProductFactoryInterface = [ `
	interface WSIssueMediaLoadProductFactory {
		${WSIssueMediaLoadProductFragment}
	}`,
];

export const WSIssueMediaLoadProductFactoryTypes = [
	...WSIssueMediaLoadProductFactoryInterface,
];

export const WSIssueMediaLoadProductFactoryResolvers = {
	WSIssueMediaLoadProductFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType(obj) {
			return objToClassName(obj);
		},
	},
};

export const InputWSIssueMediaLoadProductFactoryType = [
	...InputWSIssueMediaLoadSubsystemProductType,
	...InputWSIssueMediaLoadSubsystemAccountValueType,
	`
		input InputWSIssueMediaLoadProductFactory {
			wsIssueMediaLoadSubsystemProduct: InputWSIssueMediaLoadSubsystemProduct,
			wsIssueMediaLoadSubsystemAccountValue: InputWSIssueMediaLoadSubsystemAccountValue,
		}
	`,
];
