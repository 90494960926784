import { useState } from "react";
import {
	creditCardIsValid,
	sliceMaskedPAN,
} from "utils/FormatHelpers.js";

const defaultCard4Digits = '0000';

const useCardLast4Digits = () => {

	const [ cardLast4Digits, setCardLast4Digits ] = useState(defaultCard4Digits);

	const onBlurCardNumber = (formHelper) => {
		const creditCardNumber = formHelper.getFieldValue('cardNumber').trim();

		const last4Digits = creditCardIsValid(creditCardNumber)
			? sliceMaskedPAN(creditCardNumber)
			: defaultCard4Digits;

		setCardLast4Digits(last4Digits);
	};

	return {
		cardLast4Digits,
		onBlurCardNumber,
	};
};

export default useCardLast4Digits;
