import React from "react";
import cx from 'classnames';
import * as container from "./Container.module.css";

const Container = ({
	overrideClass,
	children,
}) => (
	<div className={cx(container.containerDefault, overrideClass)}>
		{children}
	</div>
);

export default Container;
