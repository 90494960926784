import { gql } from '@apollo/client';

import { values } from "lodash";
import React from 'react';
import PropTypes from 'prop-types';
import { PendingAchEulaError } from 'components/data/eulas/PendingAchEulas.js';
import useStdQuery from 'components/data/hooks/useStdQuery.js';
import LoadingIcon from 'components/icons/LoadingIcon.js';
import { useRefetchIfOld } from 'components/data/ServerTime.js';
import PublicAppVars from 'utils/PublicAppVars.js';

export const GET_EULAS_SEARCH_IDS = gql`
	query EulaSearch (
		$status: String
		$channels: [ String! ]
		$features: [ String! ]
		$eulaType: String
	){
		eulas {
			search(
				status: $status
				channels: $channels
				features: $features
				eulaType: $eulaType
			) {
				eulaSearchList {
					id
					eulaId
					feature
					name
					effectiveDate
					archive
					featureDescription
				}
				nodeTime
			}
		}
	}
`;

// BO Eula Features
// https://reflexions.atlassian.net/browse/MBTA-2583
export const NEW_ACH = "NewACHFundingSource";
export const NEW_REGISTERED_USER = "NewRegisteredUser";
export const NEW_TRAVELER_AUTOLOAD = "NewTravelerAutoload";
export const NEW_TRAVELER_ALTERNATE_USER = "NewTravelerAlternateUser";

export const EulaFeatures = {
	NEW_ACH,
	NEW_REGISTERED_USER,
	NEW_TRAVELER_AUTOLOAD,
	NEW_TRAVELER_ALTERNATE_USER,
};

export const useEulaSearch = ({
	status = "Active",
	channels = PublicAppVars.EULA_CHANNELS,
	features,
	eulaType,
	loading,
}) => {
	const response = useStdQuery(GET_EULAS_SEARCH_IDS, {
		variables: {
			status,
			channels,
			features,
			eulaType,
		},
		loading,
	});

	const { data, loading: loadingResponse, refetch } = response;
	const queryTime = data?.eulas.search.nodeTime;

	useRefetchIfOld({ refetch, loading: loadingResponse, queryTime });

	const eulas = data?.eulas.search.eulaSearchList ?? [];

	return { eulas, response };
};

const EulaSearch = ({
	children,
	status = 'Active',
	channels,
	features,
	eulaType,
	loading,
	renderError = null,
}) => {

	const { eulas, response } = useEulaSearch({
		status,
		channels,
		features,
		eulaType,
		loading,
	});

	const { loading: loadingResponse, error } = response;

	if (loadingResponse) {
		return (<LoadingIcon />);
	}

	if (error) {
		return (<>{renderError || <PendingAchEulaError error={response?.error} />}</>);
	}

	return (<>
		{children(eulas, response)}
	</>);
};

EulaSearch.propTypes = {
	children: PropTypes.func.isRequired,
	status: PropTypes.string,
	channels: PropTypes.arrayOf(PropTypes.string),
	features: PropTypes.oneOf(values(EulaFeatures)),
	idsOnly: PropTypes.bool,
	eulaType: PropTypes.string,
};

export default EulaSearch;
