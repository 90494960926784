import React from 'react';
import PropTypes from 'prop-types';
import {
	map,
	split,
	values,
} from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Container from 'components/Container.js';
import Button, { Primary } from 'components/Button.js';

import * as style from 'components/Banner.module.css';

import phoneinhand from 'components/home/assets/phone-in-hand.png';




const cms = {
	heading: 'miscText.guest-footer-register-title',
	text: 'miscText.guest-footer-register-benefits',
	image: 'miscText.guest-footer-register-image-path',

	btn: 'miscText.guest-footer-register-button',
};

const BenefitsOfCharlieAccount = () => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<section className={style.accountBenefits} aria-label={cmsContent[ cms.heading ]}>
				<Container>
					<div className={style.accountBenefitsContent}>
						<div className={style.accountBenefitsText}>
							<CmsContentRenderer.H3 contentKey={cms.heading}
								fallbackValue="Benefits of registering for a Charlie Account"
								className={style.accountBenefitsTitle}
							/>

							<ul className={style.accountBenefitsList}>
								{map(split(cmsContent[ cms.text ], ','), (item) => (
									<CmsContentRenderer elementType="li"
										key={item}
										content={item}
										className={style.accountBenefitsListItem}
									/>
								))}
							</ul>
						</div>
						<div className={style.accountBenefitsBtnContainer}>
							<Button
								isPrimary
								typeStyle={Primary}
								to={getPathByRoute(routeKeys.Register)}
								data-qa="GuestRegisterBtnCreate"
							>
								<CmsContentRenderer.Span contentKey={cms.btn} fallbackValue="Register for a Charlie Account" />
							</Button>
						</div>
						<div className={style.accountBenefitsMedia}>
							<img src={cmsContent[ cms.image ] ?? phoneinhand}
								className={style.accountBenefitsImage}
							/>
						</div>
					</div>
				</Container>
			</section>
		)}</CmsContentList>
	);
};

BenefitsOfCharlieAccount.propTypes = {};

export default BenefitsOfCharlieAccount;
