import {
	clone,
} from "lodash";

export default class WSSubsystemOperator {
	constructor({
		operatorId,
		operatorName = null,
	}) {
		// string(20)
		// (Required) Operator Id as configured in the subsystem.
		this.operatorId = operatorId;

		// string(40)
		// (Optional) Name of subsystem operator.
		this.operatorName = operatorName;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemOperator(data);
		return result;
	};

	static fromSerialized(data) {
		const result = new WSSubsystemOperator(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = forGraphql.operatorId;

		return forGraphql;
	}
}

const WSSubsystemOperatorFragment = `
	operatorId: ID!
	operatorName: String
`;

export const WSSubsystemOperatorType = [ `
	type WSSubsystemOperator {
		id: ID!
		${WSSubsystemOperatorFragment}
	}
` ];

export const InputWSSubsystemOperatorType = [ `
	input InputWSSubsystemOperator {
		${WSSubsystemOperatorFragment}
	}
`,
];
