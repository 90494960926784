import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import CmsContentList from 'components/data/CmsContentList.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { values } from 'lodash';

import * as style from './Pagination.module.css';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';


const cms = {
	prev: "miscText.general-nav-prev",
	next: "miscText.general-nav-next",
};

const Pagination = ({
	activePage = 0,
	setActivePage,
	totalCount,
	itemsCountPerPage = PublicAppVars.TRIPS_HISTORY_PAGE_SIZE,
	pageRangeDisplayed = 2,
}) => {
	const pageCount = Math.ceil(totalCount / itemsCountPerPage);
	const marginPagesDisplayed = 1;

	const onPageChange = (data) => {
		setActivePage(data.selected);
	};

	return (
		<CmsContentList list={values(cms)}>{() => (
			<ReactPaginate
				pageCount={pageCount}
				pageRangeDisplayed={pageRangeDisplayed}
				marginPagesDisplayed={marginPagesDisplayed}
				initialPage={activePage}
				onPageChange={onPageChange}

				// Labels
				previousLabel={<CmsContentRenderer.Span contentKey={cms.prev} fallbackValue={"Prev"}/>}
				nextLabel={<CmsContentRenderer.Span contentKey={cms.next} fallbackValue={"Next"}/>}

				// Styles
				containerClassName={style.list}
				pageClassName={style.linkWrapper}
				pageLinkClassName={style.link}
				activeClassName={style.active}
				previousLinkClassName={style.prev}
				nextLinkClassName={style.next}
				disabledClassName={style.disabled}
				breakClassName={style.linkWrapper}
				breakLinkClassName={style.link}
			/>
		)}</CmsContentList>
	);
};

Pagination.propTypes = {
	activePage: PropTypes.number,
	totalItemsCount: PropTypes.number,
	setActivePage: PropTypes.func,
	itemsCountPerPage: PropTypes.number,

	// Range of pages in paginator, exclude navigation blocks (prev, next, first, last pages)
	pageRangeDisplayed: PropTypes.number,
};

export default Pagination;
