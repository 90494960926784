import cx from "classnames";
import React from "react";
import {
	values,
	includes,
} from 'lodash';

import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "../data/CmsContentRenderer.js";
import CmsContentRendered from "../data/CmsContentRendered.js";
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";
import { ACCOUNT_TYPE_CHECKING, ACCOUNT_TYPE_SAVING, CARD_TYPE_CHARLIE } from "utils/Constants.js";
import {
	levels,
	log,
} from "utils/Logger.js";

import { CardImage } from "components/icons/CardBrands.js";
import { Bank , Retail } from "components/Icon.js";
import StoredCreditChip from "./StoredCreditChip.js";
import { getLast4Digits } from 'components/card-icons/util.js';

import * as tables from "components/DataTable.module.css";
import { CARDTYPE_CHARLIE } from "server/Constants.js";
import { TRAVEL_TOKEN_TYPES } from 'server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js';

const cms = {
	// Todo: add missing cms key
	noPayment: "miscText.NEED",
	noVirtualCardNickName: 'miscText["addcard-charlie-virtual-nickname.placeholder"]',
};

export const BankAccountType = ({
	accountType,
}) => {
	switch (accountType) {
		case ACCOUNT_TYPE_CHECKING:
			return <CmsContentRenderer.Span
				fallbackValue="Checking account"
				contentKey={cms.checkingType}
			/>;
		case ACCOUNT_TYPE_SAVING:
			return <CmsContentRenderer.Span
				fallbackValue="Savings account"
				contentKey={cms.savingType}
			/>;
		default:
			log(null, levels.error, `GenericIconAndMaskedValue component: unknown accountType`);
			return `${accountType || 'Bank'} account`;
	}
};

const GetIcon = ({ iconType, creditCardType }) => {

	if (!iconType && !creditCardType) {
		log(null, levels.error, `GenericIconAndMaskedValue component: iconType and creditCardType props are undefined`);
		return "";
	}

	if (iconType === PAYMENT_TYPES.storedValue) {
		return <StoredCreditChip />;
	}

	if (iconType === PAYMENT_TYPES.thirdParty) {
		return <Retail className={tables.cardIconImg} />;
	}

	if (iconType === PAYMENT_TYPES.directDebit) {
		return <Bank overrideClass={tables.cardIconImg} />;
	}

	if (includes([
		PAYMENT_TYPES.debitCard,
		PAYMENT_TYPES.creditCard,
		PAYMENT_TYPES.creditCardNotPresent,
	], iconType) && creditCardType) {
		return (
			<CardImage
				creditCardType={creditCardType}
				className={tables.cardIconImg}
			/>
		);
	}

	// log(null, levels.error, `GenericIconAndMaskedValue component: iconType prop is unknown - ${iconType}`);
	return "";
};

// eslint-disable-next-line complexity
export const MaskedValues = ({
	iconType,
	showName,
	nickname,
	accountType,
	maskedBankAccountNumber,
	maskedPan,
	maskedPAN,
	creditCardType,
	serialNumber,
	tokenType,
}) => {

	if (iconType === PAYMENT_TYPES.thirdParty) {
		return <CmsContentRenderer.Span fallbackValue={'Retail'} contentKey={cms.retail} />;
	}
	// TODO update ACH icon for Boston if nessesary
	if (iconType === PAYMENT_TYPES.directDebit) {
		// Alessandro's response on 05/24/2021 regards translation Bank Account
		// https://reflexions.slack.com/archives/GA82SPCTV/p1622150334180500
		return (
			<>
			  {/*
					uncomment if it needs for Boston

					{showName ? (nickname ?? <BankAccountType {...{ accountType }} />) : ''}
				*/}
				&nbsp;...{maskedBankAccountNumber.replace(/[^a-zA-Z0-9]/g, "")}
			</>
		);
	}


	if (
		creditCardType
		|| includes([
			PAYMENT_TYPES.debitCard,
			PAYMENT_TYPES.creditCard,
			PAYMENT_TYPES.creditCardNotPresent,
		], iconType)
		|| (showName && nickname)
	) {

		const creditCardBrand = includes([ 'AmEx', 'AMEX' ], creditCardType)
			? 'American Express'
			: creditCardType === CARD_TYPE_CHARLIE ? CARDTYPE_CHARLIE : creditCardType;


		const last4Digits = getLast4Digits({
			tokenType,
			maskedPan: maskedPan ?? maskedPAN,
			serialNumber,
		});

		return `${showName ? (nickname ? nickname : creditCardBrand) : ''} ...${last4Digits}`;
	}

	if ([ TRAVEL_TOKEN_TYPES.virtualCard, TRAVEL_TOKEN_TYPES.openTransitVirtual ].includes(tokenType)) {
		return <CmsContentRendered.Span fallbackValue={"Virtual Charlie Card"} contentKey={cms.noVirtualCardNickName} />;;
	}

	if (!iconType) {
		log(null, levels.error, `GenericIconAndMaskedValue component: iconType prop is undefined`);
		return <CmsContentRendered.Span fallbackValue={"No Payment"} contentKey={cms.noPayment} />;
	}
};


const GenericIconAndMaskedValue = ({
	showPrimary = false,
	primary = null,
	showName,
	iconType,
	nickname,
	accountType,
	creditCardType,
	maskedBankAccountNumber,
	maskedPan,
	maskedPAN,
	className,
	iconClassName,
	maskedPanClassName,
	serialNumber,
	tokenType,
}) => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<div className={className}>
				{iconType &&
					<div className={cx(tables.cardImageAndMaskedPAN, iconClassName)}>
						<GetIcon {...{ iconType, creditCardType }} />
					</div>
				}
				<div className={cx(tables.cardNameGroup, maskedPanClassName)}>
					<MaskedValues {...{
						iconType,
						showName,
						nickname,
						accountType,
						maskedBankAccountNumber,
						maskedPan,
						maskedPAN,
						creditCardType,
						serialNumber,
						tokenType,
					}} />
					{/* came from OMNY
						{showPrimary && primary
							&& (<>{" "}<CmsContentRenderer.Span contentKey={cms.primary} fallbackValue={"(Primary)"}/></>)}
				*/}
				</div>
			</div>
		)}</CmsContentList>
	);
};

export default GenericIconAndMaskedValue;
