import { gql } from "@apollo/client";

export const UNREGISTERED_LOGIN = gql`
	mutation unregisteredLogin(
		$tokenType: String!
		$card: InputTravelTokenSearchCard!
		$recaptchaValue: String
	){
		unregisteredTransitAccountLogin(
			tokenType: $tokenType
			card: $card
			recaptchaValue: $recaptchaValue
		){
			id
			transitAccountId
		}
	}
`;
