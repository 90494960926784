import {
	useRef,
	useState,
} from "react";
import FormHelper from 'utils/FormHelper.js';

/**
 * A hook that takes care of boiler plate logic for setting up a FormHelper instance. 
 * Should be initialized before the render function that returns <form>.
 *
 * @typedef {Object} Result
 * @property {HTMLInputElement} formRef - refer to a DOM element refers to a <form> DOM element 
 * @property {Object} formHelper - binds to <form> via formRef. Validates and returns form fields. 
 * @property {Object} validationState - holds errors states for <form> fields
 * @property {function} setValidationState - sets errors states for <form> fields
 * @property {boolean} submitting - states whether the <form> is in flight
 * @property {function} setSubmitting - sets the <form> flight state
 *
 *
 * @param {function} getYupSchema - returns the relevant yup validation schema
 * @param {function} getDataToValidate - returns form data with transformations if defined
 * @returns {Result}
 * 
 * @component
 * @example 
 * 
 * const { 
 *		formRef,
 *		formHelper,
 *		validationState, setValidationState,
 *		submitting, setSubmitting,
 *	} = useFormHelper({ getYupSchema });
 * 
 * return (
 *	<WireFormHelper {...{ formHelper }}>
 * 		<form
 * 			ref={formRef}
 * 			onSubmit={PreventDefault(kickoffSubmit)}
 * 		>
 * 			<>component body</>
 * 		</form>
 *	</WireFormHelper>
 * );
 * 
 **/

const useFormHelper = ({
	getYupSchema,
	getDataToValidate,
}) => {
	const [ validationState, setValidationState ] = useState({});
	const [ submitting, setSubmitting ] = useState(false);

	const formRef = useRef(null);

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;

	formHelper.onHookedRender(
		validationState,
		setValidationState,

		/**
		* Sometimes the getYupSchema callback needs access to the formHelper instance
		*
		* const getYupSchema = (formHelper) => {
		*   const country = formHelper.getFieldValue('country');
		*	if (country !== COUNTRY_USA ){
		*		validations.address2 = yup_string()
		*			.required("Please enter a street address.")
		*			.trim();
		*	};
		*				
		*	return yup_object().shape(validations);
		* };
		**/
		
		getYupSchema
			? () => getYupSchema(formHelper)
			: null,

		// onHookedRender checks if a function is passed to use that or if the default formHelper.getDataToValidate
		// should be used
		getDataToValidate
			? () => getDataToValidate(formHelper)
			: null,
	);

	return {
		formRef,
		formHelper,
		validationState, setValidationState,
		submitting, setSubmitting,
	};
};

export default useFormHelper;
