import { DateTimeType } from "./DateTime.js";
import WSPhoneExt from "./WSPhoneExt.js";

export const TOKEN_ENABLEMENT_FEE_STATUS = {
	NONE: 'None',
	PAID: 'Paid',
	WAIVED: 'Waived',
	UNPAID: 'Unpaid',
};

export default class WSTravelTokenEnablementFeeInfo {
	constructor({
		status,
		statusReasonCode,
		statusLastUpdate,
		feeDueAmount,
	}) {
		// string(40)
		// (Required) The enablement fee status. Indicates if the fee is applicable
		// and if it was paid or owed. Expected values are:
		//  None: fee is not applicable
		//  Paid: fee was fully paid
		//  Waived: applicable but waived
		//  Unpaid: fee is owed and not paid
		this.status = status;

		// string(40)
		// (Optional) Reason code for current status.
		this.statusReasonCode = statusReasonCode;

		// dateTime
		// (Required) Timestamp when current status was updated.
		this.statusLastUpdate = statusLastUpdate;

		// int
		// (Required) Enablement fee amount that was due when the travel token
		// was issued to the subsystem account. This value may be used to
		// calculate the amount to be paid in order to enable the travel token for
		// travel.
		this.feeDueAmount = feeDueAmount;
	}

	set(field, value) {
		this[ field ] = value;
		return this;
	}

	static fromBackoffice(data) {
		const result = new WSPhoneExt(data);
		return result;
	}
}

export const WSTravelTokenEnablementFeeInfoType = [
	DateTimeType,
	`
		type WSTravelTokenEnablementFeeInfo {
			status: String!
			statusReasonCode: String
			statusLastUpdate: GraphQLDateTime
			feeDueAmount: Int!
		}
	`,
];
