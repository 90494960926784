import {
	map,
	clone,
	merge,
} from "lodash";
import WSAutoload, { WSAutoloadFragment } from "./WSAutoload.js";
import WSSubsystemOperator,
{ WSSubsystemOperatorType, InputWSSubsystemOperatorType } from "./WSSubsystemOperator.js";

// 2.7.5.3.WSAutoloadSubsystemProduct
// This extends the WSAutoload object. An auto load for a subsystem product.
export default class WSAutoloadSubsystemProduct extends WSAutoload {
	constructor({
		subsystemAccountId,
		subsystemRef,
		productTypeId,
		productName = null,
		productSKU = null,
		productCost,
		permittedUsers,
		travelTokenId = null,

		// super props
		type,
		loadValueBehavior = null,
	}) {

		super({
			type,
			loadValueBehavior,
		});

		// string(40)
		// (Required) The unique identifier for the
		// subsystem account.
		this.subsystemAccountId = subsystemAccountId;

		// string(20)
		// (Required) Unique identifier for the subsystem
		// where the subsystem account is registered.
		this.subsystemRef = subsystemRef;

		// string(40)
		// (Required) The unique identifier of the product to
		// load.
		this.productTypeId = productTypeId;

		// string(250)
		// (Required) A textual description of the product
		// field.
		this.productName = productName;

		// string(40)
		// (Required) Product SKU.
		this.productSKU = productSKU;

		// int
		// (Required) Price of the product. This number is
		// the number of the base unit of currency, such as
		// cents.
		// For “value” products, the value to be loaded onto
		// the farecard or into the account.
		// For “pass” products, the cost of the pass.
		this.productCost = productCost;

		// List<WSSubsystemOperator>
		// (Required)List of operators that are permitted to
		// use this transit product.
		this.permittedUsers = permittedUsers;

		// String(20)
		// (Optional) the token id under which the enrollment is made.
		this.travelTokenId = travelTokenId;
	}

	static fromBackoffice(data) {
		const result = new WSAutoloadSubsystemProduct(data);
		result.permittedUsers = map(data.permittedUsers, wsSubsystemOperator =>
			WSSubsystemOperator.fromBackoffice(wsSubsystemOperator));

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSAutoloadSubsystemProduct(data);
		result.permittedUsers = map(data.permittedUsers, wsSubsystemOperator =>
			WSSubsystemOperator.fromSerialized(wsSubsystemOperator));

		return merge(
			result,
			super.fromSerialized(data),
		);
	}

	toBackoffice() {
		return {
			...this,
			...super.toBackoffice(),
		};
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.productSKU;

		forGraphql.permittedUsers = map(this.permittedUsers, wsSubsystemOperator =>
			wsSubsystemOperator.toResolver());

		return merge(
			forGraphql,
			super.toResolver(),
		);
	}
}

export const WSAutoloadSubsystemProductFragment = `
	subsystemAccountId: String!
	subsystemRef: String!
	productTypeId: String!
	productName: String!
	productSKU: String!
	productCost: Int!
	travelTokenId: String
`;

export const InputWSAutoloadSubsystemProductType = [
	...InputWSSubsystemOperatorType, `
	input InputWSAutoloadSubsystemProduct {
		${WSAutoloadFragment}
		${WSAutoloadSubsystemProductFragment}
		permittedUsers: [ InputWSSubsystemOperator! ]!
	}
` ];

export const WSAutoloadSubsystemProductType = [ `
	type WSAutoloadSubsystemProduct implements WSAutoloadFactory {
		id: ID!
		${WSAutoloadFragment}
		${WSAutoloadSubsystemProductFragment}
		permittedUsers: [ WSSubsystemOperator! ]!
	}
` ];
