import React, { useEffect, useMemo } from 'react';

import AddressForm, { SAVE_ADDRESS_HIDDEN, SAVE_ADDRESS_REQUIRED, SAVE_ADDRESS_SHOWN } from 'components/payments/AddressForm.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";

import * as forms from 'components/forms/Forms.module.css';
import * as cardList from 'styles/CardList.module.css';
import { filter, values } from 'lodash';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { cityStateZipStr, isFullAddress, isFullAddressAvailable } from "components/data/session-user/Address.js";
import { AddressHiddenFields } from "components/account-settings/address-selector/AddressHiddenFields.js";
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import PreventDefault from "utils/PreventDefault.js";
import CmsContentList from 'components/data/CmsContentList.js';

import {
	AddressSelector,
	NEW_ADDRESS_ID,
	useAddressSelectorContext,
} from 'components/account-settings/address-selector/AddressSelector.js';
import PropTypes from "prop-types";
import FormHelper from "utils/FormHelper.js";
import { levels, log } from "utils/Logger.js";


export const cms = {
	saveAddress: 'miscText["general-address.save"]',
	savedAddress: 'miscText.general-payment-billing-address-choose-saved',
	differentAddress: 'miscText.general-payment-billing-address-choose-new',
	newAddress: 'miscText.personal-addresses-add-cta-aria',
	setDefaultBilling: 'miscText["general-address.set-billing"]',
	setDefaultShipping: 'miscText["general-address.set-shipping"]',
};

export const AddressType = {
	Shipping: "shipping",
	Billing: "billing",
	PersonalInfo: "personalInfo",
};
/** makes sure selectedAddressId is set */
const useEnsureSelectedAddressId = ({
	addressType,
	setAddress,
	defaultAddressId,
	wsAddressExts,
	toDeleteAddresses,
	selectedAddressId,
	setSelectedAddressId,
}) => {
	useEffect(
		// if useAddressSelectorState was called without a defaultAddressId,
		// or selectedAddressId has been reset after deletion, pick the first address
		() => {
			if (toDeleteAddresses === undefined) {
				log(null, levels.error, "AddressSelector requires AddressSelectorContext");
				return null;
			}

			if (!selectedAddressId) {
				const found = (
					!toDeleteAddresses.some(toDelete => toDelete.addressId === defaultAddressId)
					&& wsAddressExts.find(wsAddressExt => wsAddressExt.addressId === defaultAddressId)
				);

				if (found) {
					setAddress(found);
					setSelectedAddressId(found.addressId);
				}
				else {
					// Try to get the primary address else get the first in the list
					const first = wsAddressExts?.[ 0 ];

					// make sure we have a default set no matter what if there are any valid wsAddressExts
					const address = wsAddressExts.find(address => AddressType.Shipping
						? address.primaryShipping
						: address.primaryBilling
					) ?? first;

					if (address) {
						setAddress(address);
						setSelectedAddressId(address?.addressId);
					}
					else {
						setAddress(null);
						setSelectedAddressId(NEW_ADDRESS_ID);
					}
				}
			}
		},
		[
			setAddress,
			addressType,
			defaultAddressId,
			selectedAddressId,
			wsAddressExts,
			toDeleteAddresses,
			setSelectedAddressId,
		]
	);
};

export const SelectAddressForm = ({
	formHelper,
	wsAddressExts,
	alternateAddressIds = [],
	isSplit = false,
	isFirst = false,
	saveAddressCheckboxVisibility = SAVE_ADDRESS_HIDDEN,
	hideTopLevelError = false,
}) => {
	const { loginStage } = useLoginStage();
	const loggedIn = loginStage === loginStages.loggedIn;

	const {
		addressType,
		defaultAddressId,
		selectedAddressId,
		setSelectedAddressId,
		setAddress,
		toDeleteAddresses,
	} = useAddressSelectorContext();

	const showAddressForm = selectedAddressId === NEW_ADDRESS_ID;

	const getFieldName = (fieldNameBase) => (addressType + getPaymentInputName({ base: fieldNameBase, isFirst, isSplit }));
	const fieldName = getFieldName('addressInputOption');

	// Filter out partial addresses.
	// The "Add payment method" form shows the Profile information address as a saved address
	// even when it's partially complete (e.g. state and zip only).
	const filteredAddresses = useMemo(
		() => filter(wsAddressExts, wsAddressExt => isFullAddress(wsAddressExt)),
		[ wsAddressExts ]
	);

	useEnsureSelectedAddressId({
		addressType,
		fieldName,
		defaultAddressId,
		setAddress,
		wsAddressExts: filteredAddresses,
		toDeleteAddresses,
		selectedAddressId,
		setSelectedAddressId,
	});

	const fullAddressAvailable = isFullAddressAvailable(filteredAddresses);

	useEffect(
		() => {
			if (selectedAddressId !== NEW_ADDRESS_ID && !filteredAddresses.length) {
				setSelectedAddressId(NEW_ADDRESS_ID);
			}
		},
		[ filteredAddresses, selectedAddressId, setSelectedAddressId ]
	);

	return (
		<CmsContentList list={values(cms)}>{() =>
			<>
				{filteredAddresses.length
					? filteredAddresses.length === 1 && fullAddressAvailable
						? (
							<>
								<div className={cardList.singleAddressForm}>
									{`${filteredAddresses[ 0 ].address1}, ${cityStateZipStr({ ...filteredAddresses[ 0 ] })}`}
								</div>
								{showAddressForm
									? null
									: <AddressHiddenFields {...{
										wsAddressExt: filteredAddresses[ 0 ],
										formHelper,
										getFieldName,
									}} />
								}
							</>
						)
						: <div className={cardList.container}>
							<AddressSelector
								{...{
									wsAddressExts: filteredAddresses,
									alternateAddressIds,
									fieldName,
									formHelper,
									getFieldName,

									// we are inputting a new address so disable this form
									formDisabled: showAddressForm,
								}} />
						</div>
					: null
				}

				<div className={forms.section}>
					{filteredAddresses.length > 0 ?
						<Button
							type="button"
							typeStyle={buttonTypeStylePlain}
							onClick={PreventDefault(() => {
								formHelper.clearAllErrors();
								setSelectedAddressId(showAddressForm ? defaultAddressId : NEW_ADDRESS_ID);
								setAddress(null);
							})}
						>
							<CmsContentRenderer.Span
								contentKey={showAddressForm ? cms.savedAddress : cms.differentAddress}
								fallbackValue={showAddressForm ? "Use My Saved Address" : "Use a Different Address"}
							/>
						</Button>
						: null
					}
				</div>

				{showAddressForm ?
					<>
						{loggedIn &&
							<CmsContentRenderer.Span
								contentKey={cms.newAddress}
								fallbackValue="Add New Address"
								className={forms.label}
							/>
						}
						<div className={cardList.newAddressForm}>
							<AddressForm
								{...{
									saveAddressCheckboxVisibility,
									formHelper: formHelper,
									formInputNamePrefix: addressType,
									isSplit,
									isFirst,
									hideTopLevelError,
								}} />
						</div>
					</>
					: null
				}
			</>
		}</CmsContentList>
	);
};

SelectAddressForm.propTypes = {
	formHelper: PropTypes.instanceOf(FormHelper).isRequired,
	wsAddressExts: PropTypes.arrayOf(PropTypes.object).isRequired,
	isSplit: PropTypes.bool,
	isFirst: PropTypes.bool,
	saveAddressCheckboxVisibility: PropTypes.oneOf([ SAVE_ADDRESS_HIDDEN, SAVE_ADDRESS_SHOWN, SAVE_ADDRESS_REQUIRED ]),
};

export default SelectAddressForm;
