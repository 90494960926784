import { getTransitModeIcon } from 'components/icons/TransitModes.js';

export const Services = [
	{
		key: "Uber",
		name: "Uber",
		isConnected: true,
		account: {
			name: "Shawn Rosalia",
		},
		image: getTransitModeIcon('Uber'),
	},
	{
		key: "Lyft",
		name: "Lyft",
		isConnected: true,
		account: {
			name: "Shawn Rosalia",
		},
		image: getTransitModeIcon('Lyft'),
	},
	{
		key: 'BikeShare',
		name: 'Bike Share',
		isConnected: false,
		account: {
			name: 'Shawn Rosalia',
		},
		image: getTransitModeIcon('Bikeshare'),
	},
	{
		key: 'ParkingClerk',
		name: 'Parking Clerk',
		isConnected: false,
		account: {
			name: 'Shawn Rosalia',
		},
		image: getTransitModeIcon('Parkingclerk'),
	},
];


export const Cards = [
	{
		id: 12345,
		title: "Work Travel Card...2345",
		type: "charliecard",
		status: "Active",
		balance: "42.25",
		transitvalue: '0',
		expiration_date: "2018-10-31",
		last4digits: '3345',
		alerts: [
			{
				type: "warning",
				message: "Expires in 5 days",
			},
		],
		modes: [ "subway", "bus" ],
		group: {
			name: 'Lunar Industries',
			status: 'Active',
			expiration_date: '2023-12-6',
			hasAutoLoad: true,
		},
		products: [
			{
				name: 'Monthly LinkPass',
				expiration_date: '2019-10-31',
				modes: [ 'subway', 'bus', 'lyft' ],
				group: 'Lunar Industries',
			},
			{
				name: 'Commuter Rail Pass',
				expiration_date: '2019-10-31',
				modes: [ 'rail' ],
				group: 'Lunar Industries',
				hasAutoLoad: false,
			},

		],
		services: [
			'lyft', 'uber',
		],
	},
	{
		id: 2345,
		title: "Shawn School Card...2355",
		type: "charlieinactive",
		status: "Suspended",
		balance: "5.00",
		transitvalue: '0',
		last4digits: "0999",
		expiration_date: "2018-10-31",
		modes: [ "subway", "bus" ],
		group: {
			name: 'Boston University',
			status: 'Active',
			expiration_date: '',
		},
		products: [
			{
				name: 'Monthly LinkPass',
				expiration_date: '2019-11-30',
				modes: [ 'subway', 'bus', 'lyft' ],
				hasAutoLoad: true,
				autoLoad: {
					type: 'visa',
					last4digits: '0441',
					nextReload: '2019-11-24',
				},
			},
			{
				name: 'Commuter Rail Pass',
				expiration_date: '2019-10-31',
				modes: [ 'rail' ],
				group: 'Lunar Industries',
				hasAutoLoad: false,
			},
		],
		services: [
			'bikeshare', 'parkingboston',
		],
	},
	{
		id: 65478,
		title: "Personal Use Card...3657",
		type: "mastercard",
		status: "Active",
		balance: "0",
		transitvalue: '0',
		last4digits: "1234",
		expiration_date: "2018-11-15",
		alerts: [
			{
				type: "warning",
				message: "Expires in 20 days",
			},
		],
		modes: [ "subway", "bus", "rail", "ferry", "trolley" ],
		group: {
			name: 'Reflexions',
			status: 'Suspended',
			expiration_date: '',
		},
		products: [
			{
				name: 'Monthly LinkPass',
				expiration_date: '2019-10-31',
				modes: [ 'subway', 'bus', 'lyft' ],
				group: 'Lunar Industries',
			},
		],
		services: [],
	},
	{
		id: 57662,
		title: "Charlie Card ... 7662",
		type: "charliecardfull",
		status: "Pending",
		last4digits: "6789",
		expiration_date: "2018-10-31",
		balance: "50.00",
		transitvalue: '0',
		activation_date: "2018-12-1",
		modes: [ "subway", "bus" ],
		group: {
			name: 'Cubic Transit Systems',
			status: 'Active',
			expiration_date: '',
		},
		products: [],
		services: [],
	},
];
