import React from 'react';
import PropTypes from "prop-types";
import PreventDefault from "utils/PreventDefault.js";

import * as style from "./SkipToContent.module.css";

const SkipToContent = ({
	elementId,
	children = null,
}) => {

	const scrollTo = (elementId) => {
		document.getElementById(elementId).scrollIntoView();
	};

	return (
		<a onClick={PreventDefault(() => scrollTo(elementId))}
			className={style.SkipNav}
			aria-flowto={elementId}
		>
			{children}
		</a>
	);
};

SkipToContent.propTypes = {
	elementId: PropTypes.string.isRequired,

	children: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.node),
	]),
};

export default SkipToContent;
