import {
	useEffect,
	useState,
} from "react";
import { useInterval } from "react-use";
import PublicAppVars from "utils/PublicAppVars.js";

const getDisplay = el => el.style.display || 'block';

// only restore the chatbot after all mounted instances are unmounted
let numMounted = 0;

const hideIt = (setTriesRemaining) => {
	const chatbotEl = document.getElementById(PublicAppVars.PYPESTREAM_CHATBOT_ELEMENT_ID);

	if (!chatbotEl) {
		return;
	}

	// don't need to keep trying
	setTriesRemaining(0);

	if (getDisplay(chatbotEl) === 'block') {
		console.info("hiding chatbot");

		// Per discussion with Pypestream, there's no API for us to call,
		// so we have to hide/restore this element on our own
		chatbotEl.style.display = 'none';
	}
};

const showIt = () => {
	const chatbotEl = document.getElementById(PublicAppVars.PYPESTREAM_CHATBOT_ELEMENT_ID);

	if (!chatbotEl) {
		return;
	}

	console.info("restoring chatbot");
	chatbotEl.style.display = 'block';
};

/**
 * If this component is present on a page, the pypestream chatbot will be hidden
 * @returns {null}
 * @constructor
 */
const HidePypestreamChatbot = () => {
	// If the pypestream chatbot is not enabled, there's nothing for us to do.
	const enabled = Boolean(PublicAppVars.PYPESTREAM_CHATBOT_APP_ID);

	const [ triesRemaining, setTriesRemaining ] = useState(20);
	const doneChecking = !enabled || triesRemaining <= 0;

	useEffect(() => {
		if (!enabled) {
			return;
		}

		numMounted++;
		hideIt(setTriesRemaining);

		return () => {
			// restore it when we're unmounted if we're the last remaining <HidePypestreamChatbot>
			numMounted--;

			if (numMounted === 0) {
				showIt();
			}
		};
	}, [ enabled ]);

	// it may not have loaded yet when this component mounted. Check again a few times.
	useInterval(
		() => {
			setTriesRemaining(tries => tries - 1);
			hideIt(setTriesRemaining);
		},
		doneChecking
			? null // disables the interval
			: 1000
	);

	return null;
};
export default HidePypestreamChatbot;
