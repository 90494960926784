import React from 'react';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import CardLayout, { getCardLayoutBreadcrumbs } from '../../../layouts/CardLayout.js';
import PurchaseHistoryTable from 'components/account/data-tables/PurchaseHistoryTable.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import { useBreadcrumbCallback } from 'context/BreadcrumbProvider.js';



const cms = {
	title: 'miscText.purchasehistory-header',
};

export const getPurchaseHistoryBreadcrumbs = () => [
	...getCardLayoutBreadcrumbs(),
	<CmsContentRenderedInline
		key={cms.title}
		contentKey={cms.title}
		fallbackValue="Purchase History"
	/>,
];

const PurchaseHistory = () => {
	useBreadcrumbCallback(getPurchaseHistoryBreadcrumbs);
	return (
		<CmsContentList list={Object.values(cms)}>
			{() =>
				<CardLayout title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Purchase History" />}>
					<PurchaseHistoryTable />
				</CardLayout>
			}
		</CmsContentList>
	);
};

export default PurchaseHistory;
