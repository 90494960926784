/**
 * Catches graphql request errors and turns them into an ErrorReport
 *
 * The goal of this middleware is not to translate a server error to a particular api key, but to standardize the
 * format of the result. E.g. timeouts otherwise would be an error, but we return them as a TopLevelKey.
 * Any error report from the server is essentially passed through. (we have to deserialize the report from the
 * error message string that graphql returns)
 */
import {
	levels,
	noticeError,
} from "../../Logger.js";
import ErrorReport from "../ErrorReport.js";
import { get } from "lodash";

export const ERROR_KEY_AUTH_REQUIRED = 'miscText.error-general-auth-required';
export const ERROR_KEY_NONCE_FAILED = 'miscText.error-general-nonce-failed';
export const ERROR_KEY_CAN_BE_LINKED_FAILED = 'miscText.error-general-can-be-linked-failed';
export const ERROR_KEY_CUSTOMER_GET_FAILED = 'miscText.error-general-customer-get-failed';

export const APOLLO_ERROR_PREFIX = 'GraphQL error: ';
export const MESSAGE_PREFIX = "GraphqlRequestError: ";
export const MESSAGE_PREFIX_REGEX = /.*GraphqlRequestError: (?<serialized>.*)/;

export const deserializeGraphqlRequestError = (error, serverSide = false) => {
	// if we recognize this error message string as a GraphqlRequestError, we'll turn it back into an object
	// and throw that so that our caller still knows it's an error condition
	const { message } = error;

	const result = message?.match(MESSAGE_PREFIX_REGEX);
	if (result?.groups.serialized) {
		return ErrorReport.newFromString(result?.groups.serialized);
	}

	// TODO: handle timeouts

	return null;
};

export const graphqlErrorMiddleware = async (mutationCallbackPromise, logoutAndClearCache = null) => {
	let response;
	try {
		response = await mutationCallbackPromise;
	}
	catch (error) {
		// Try parsing the error from the server
		const parsedError = deserializeGraphqlRequestError(error);

		if (logoutAndClearCache && parsedError.TopLevelKey === "miscText.error-general-auth-required") {
			logoutAndClearCache();
			throw parsedError;
		}

		if (parsedError) {
			throw parsedError;
		}

		// Couldn't parse the error response
		noticeError(null, levels.error, error, "graphqlErrorMiddleware noticed");

		// Create something for the frontend to display
		throw ErrorReport.newFromObj({ TopLevelKey: 'Unexpected server error' });
	}

	return response;
};


export const parseError = errorMessage => {
	// if we recognize this error message string as a GraphqlRequestError, we'll turn it back into an object
	const PREFIX = APOLLO_ERROR_PREFIX + MESSAGE_PREFIX;
	if (errorMessage.slice(0, PREFIX.length) === PREFIX) {
		return ErrorReport.newFromString(errorMessage.slice(PREFIX.length));
	}
	return errorMessage;
};

export const errorIsGraphqlRequestError = (error) => {
	return (
		error
		&& get(error, 'message', '').slice(0, MESSAGE_PREFIX.length) === MESSAGE_PREFIX
		&& ErrorReport.isErrorReport(error)
	);
};
