import { useQuery } from "@apollo/client";
import {
	useEffect,
	useState,
} from "react";
import { get } from 'lodash';

// Takes a paginated query and reruns it until all pages have been consumed
const Unpaginate = ({
	query,
	variables,
	dataSelector,
	countSelector,
	children,
	maxLength = 100000, // number of records (not pages)
}) => {
	const [ aggregation, setAggregation ] = useState([]);
	const [ offset, setOffset ] = useState(0);
	const [ done, setDone ] = useState(false);
	const [ success, setSuccess ] = useState(false);

	const pageResult = useQuery(query, {
		variables: {
			...variables,
			offset,
		},
	});

	useEffect(
		() => {
			if (pageResult.loading || done || !pageResult.data) {
				return;
			}

			const data = get(pageResult.data, dataSelector);
			const serverCount = get(pageResult.data, countSelector);

			const newOffset = offset + data.length;

			// if there are entries we haven't consumed yet...
			if (newOffset > aggregation.length) {
				// consume them
				setAggregation(aggregation.concat(data));

				// and check for more
				if (newOffset < Math.min(serverCount, maxLength)) {
					setOffset(newOffset);
				}
			}

			// if we're done...
			if (newOffset >= Math.min(serverCount, maxLength)) {
				setDone(true);

				if (newOffset === serverCount) {
					setSuccess(true);
				}
				else {
					// we'd go beyond the limit if we continued on, but we didn't get all our data
					setSuccess(false);
				}
			}
		},
		[
			aggregation,
			offset,
			setOffset,
			dataSelector,
			countSelector,
			pageResult.data,
			pageResult.loading,
			maxLength,
			done,
		],
	);

	return children({
		data: aggregation,
		done,
		success,
	});
};
export default Unpaginate;
