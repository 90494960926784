import PhoneTypes from "../../utils/constants/PhoneTypes.js";
import { clone } from "lodash";

export const WSPhoneOps = {
	Add: 'add',
	Remove: 'remove',
	Replace: 'replace',
};

export default class WSPhone {
	constructor({
		number,
		type = PhoneTypes.Mobile,
		country = 'US',
		op = null,
		oldPhone = null,
		oldType = null,
	}) {
		// string(20)
		// (Required)
		//
		// When present in a request:
		// 	- The phone number in national format. E.g. for U.S. systems,
		// 	the phone number must be a 10 digit number in the format:
		// 	"(###) ###-####" e.g. "(415) 555-2671" or
		// 	"(###)#######" e.g. "(415)5552671"
		// 	OR
		// 	- The phone number in E.164 format:
		// 	"[+][country code][area code][local phone
		// 	number]", e.g. "+14155552671".
		//
		// When present in a response:
		// 	- The phone number in E.164 format:
		// 	"[+][country code][area code][local phone
		// 	number] " and can have a maximum of fifteen digits.
		// 	e.g. US number: "(415) 555-2671" in E.164
		// 	format is: "+14155552671".
		this.number = number;

		// Per Sana: Remove phone number type from website
		// https://reflexions.slack.com/archives/G1WFWE4PL/p1573233106116000?thread_ts=1571082627.010100&cid=G1WFWE4PL

		// It's ok to default to mobile.
		// https://reflexions.slack.com/archives/GA82SPCTV/p1573240264313600

		// 2.6.18 Phone Types
		// 2.10.2 customerservice/phonetypes GET
		// see constants/PhoneTypes.js
		this.type = type;

		// string(6)
		// (Required) Standard ISO 2-letter country code. e.g. US, AU, GB,
		// etc. (ISO 3166-1 alpha-2 standard)
		// For UK member regions/countries/nations it is a 6-letter country
		// code e.g. GB-ENG, GB-SCT, GB-NOR, GB-WLS, GB-CHA (ISO
		// 3166-2 standard)
		this.country = country;

		// string
		// (Optional) The operation to be performed on the phone record.
		// Used in the customer/<customer-id>/contact/<contact-id>
		// PATCH API.
		// Valid values are:
		// - add
		// - remove
		// - replace
		this.op = op;

		// string
		// (Conditionally-Required) The old phone number used to match an
		// existing phone record in the "replace" operation.
		// Used in the customer/<customer-id>/contact/<contact-id>
		// PATCH API.
		// Required if op=”replace”
		this.oldPhone = oldPhone;

		// string
		// (Conditionally-Required) The old phone type used to match an
		// existing record in the "replace" operation".
		// Used in the customer/<customer-id>/contact/<contact-id>
		// PATCH API.
		// Required if op=”replace”
		this.oldType = oldType;
	}

	static fromBackoffice(data) {
		const result = new WSPhone(data);
		return result;
	}

	toBackoffice() {
		return {
			number: this.number,
			type: this.type,
			country: this.country,
			op: this.op,
			oldPhone: this.oldPhone,
			oldType: this.oldType,
		};
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSPhoneType = [ `
	type WSPhone {
		number: String!
		type: String!
		country: String!
	}
` ];

export const InputWSPhoneType = [ `
	input InputWSPhone {
		number: String!
		type: String!
		country: String!
		op: String,
		oldPhone: String,
		oldType: String,
	}
` ];
