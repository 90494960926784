import React from 'react';
import { gql } from "@apollo/client";
import StdQuery from "components/data/StdQuery.js";
import { GetCustomerContactIds } from "components/data/session-user/SessionUser.js";
import { sortBy } from "lodash";

export const GET_NOTIFICATION_PREFERENCES = gql`
	query getPreferences {
		notificationsPreferences {
			smsPhone
			firstName
			contactId
			preferences {
				notificationDescription
				category {
					name
					description
				}
				subCategory {
					name
					description
				}
				notificationType
				optIn
				channels {
					channel
					enabled
					reqType
				}
			}
		}
	}
`;

export const UPDATE_NOTIFICATION_PREFERENCES = gql`
	mutation updatePreference(
		$contactId: ID
		$phoneNumber: String
		$enabled: Boolean
		$notificationChannel: String
		$notificationTypeKeys: [ String! ]
	){
		updateNotifications(
			contactId: $contactId
			enabled: $enabled
			notificationTypeKeysList: $notificationTypeKeys
			phoneNumber: $phoneNumber
			notificationChannel: $notificationChannel
		)
	}
`;

export const NotificationPreferences = ({ children, primaryFirst = false }) => (
	<GetCustomerContactIds>{({ contactId }) => (
		<StdQuery
			query={GET_NOTIFICATION_PREFERENCES}
		>{({ data: { notificationsPreferences } }) =>
				children({
					notificationPreferences: primaryFirst
						? sortBy(
							notificationsPreferences,
							({ contactId: notificationPrefContactId }) => contactId !== notificationPrefContactId,
						)
						: notificationsPreferences,
				})
			}
		</StdQuery>
	)}</GetCustomerContactIds>
);
