import useStdQuery from "components/data/hooks/useStdQuery.js";
import { USER_TRAVEL_CARDS_QUERY } from 'components/data/session-user/SessionUser.js';

export const useCustomerTravelCards = () => {
	const { data, loading, error } = useStdQuery(USER_TRAVEL_CARDS_QUERY);
	return { 
		data, 
		error,
		loading,  
	};
};
