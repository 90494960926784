import {
	merge,
	clone,
} from "lodash";

export default class WSDirectDebit {
	constructor ({
	}) {
		// Abstract class to reference either  WSDirectDebitClear or WSDirectDebitReference
	}

	static fromBackoffice(data) {
		const result = new WSDirectDebit(data);

		return result;
	};

	toBackoffice() {
		return this;
	}

	toResolver() {
		const forGraphql = clone(this);

		return forGraphql;
	}
}

export const WSDirectDebitFragment = `
	# can't have empty type, so use noop field
	# https://github.com/graphql/graphql-spec/issues/568
	_: Boolean
`;

export const WSDirectDebitType = [ `
	type WSDirectDebit implements WSDirectDebitFactory {
		${WSDirectDebitFragment}
	}
` ];
