import WSTravelPaymentHistory, { WSTravelPaymentHistoryType } from "./WSTravelPaymentHistory.js";
import WSPaymentHistory, { WSPaymentHistoryFragment } from "./WSPaymentHistory.js";
import { levels, log } from "utils/Logger.js";
import WSOrderPaymentHistory, { WSOrderPaymentHistoryType } from "./WSOrderPaymentHistory.js";
import { startCase } from "lodash";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

// 2.7.25.2.WSTravelPaymentHistory
// WSTravelPaymentHistory extends WSPaymentHistory when the type is travel.

export const types = {
	travel: "TRAVEL",
	order: "ORDER",
};

export const ORDER_STATUS = {
	OrderAccepted: 'OrderAccepted',
	PaymentFailed: 'PaymentFailed',
	FulfillmentFailed: 'FulfillmentFailed',
	Completed: 'Completed',
	CompletedWithErrors: 'CompletedWithErrors',
	PaymentAccepted: 'PaymentAccepted',
	PreAccepted: 'PreAccepted',
	FulfillmentPending: 'FulfillmentPending',
	Failed: 'Failed',
	PaymentRequested: 'PaymentRequested',
	Rejected: 'Rejected',
	OrderReversed: 'OrderReversed',
	OrderCancelled: 'OrderCancelled',
};

export const getOrderStatusFriendly = (orderStatus) => {
	if (orderStatus === ORDER_STATUS.CompletedWithErrors) {
		return 'Completed with Errors';
	}
	else if (orderStatus === ORDER_STATUS.PreAccepted) {
		return 'Fulfillment Pending';
	}

	return startCase(orderStatus);
};

export const ORDER_TYPE = {
	sale: "Sale",
	autoload: "Autoload",
	refund: "Refund",
	transfer: "Transfer",
	adjustment: "Adjustment",
	debtCollection: "DebtCollection",
};

const objToClass = ({ type }) => {
	switch (type) {
		case types.travel:
			return "WSTravelPaymentHistory";
		case types.order:
			return "WSOrderPaymentHistory";
		default:
			log(null, levels.error, `typeToClass: unknown  WSPaymentHistory type ${type}`);
			return "WSPaymentHistory";
	}
};

export default class WSPaymentHistoryFactory {
	static fromBackoffice(data) {
		switch (data.type) {
			case types.travel:
				return WSTravelPaymentHistory.fromBackoffice(data);
			case types.order:
				return WSOrderPaymentHistory.fromBackoffice(data);
			default:
				log(null, levels.error, `unknown  WSPaymentHistory type ${data.type}`);
				return WSPaymentHistory.fromBackoffice(data);
		}
	}

	static fromSerialized(data) {
		switch (data.type) {
			case types.travel:
				return WSTravelPaymentHistory.fromSerialized(data);
			case types.order:
				return WSOrderPaymentHistory.fromSerialized(data);
			default:
				log(null, levels.error, `unknown  WSPaymentHistory type ${data.type}`);
				return WSPaymentHistory.fromSerialized(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSPaymentHistoryFactoryInterface = [ `
	interface WSPaymentHistoryFactory {
		${WSPaymentHistoryFragment}
	}`,
];

export const WSPaymentHistoryFactoryTypes = [
	...WSPaymentHistoryFactoryInterface,
];

export const WSPaymentHistoryFactoryResolvers = {
	WSPaymentHistoryFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType(obj) {
			return objToClass(obj);
		},
	},
};
