import { getPathByRoute } from 'App';
import routeKeys from 'CustomerRouteKeys';
import React, {
	useEffect,
} from 'react';
import qs, {
	parse as qs_parse,
	stringify,
} from 'qs';

import { mapKeys } from 'lodash';

import { useHistory , useParams } from 'react-router-dom';
import NotFound from './NotFound';

const getQuery = (queryString) => qs_parse(queryString, {
	ignoreQueryPrefix: true,
});

const mapPrefillValues = (query) =>  {
	return { 
		prefill: {
			prefillEmail: query.email,
		}, 
	};
};

const REDIRECT_LINK = {
	'find-retailer': { routeKey : routeKeys.RetailLocations },
	'register': { routeKey: routeKeys.Register },
	'login': { 
		routeKey: routeKeys.SignIn, 
		queryToStateMap: mapPrefillValues,
	},
	'forgot-password': { 
		routeKey: routeKeys.ForgotPassword, 
		queryToStateMap: mapPrefillValues, 
	},
	'email-registerpassword': { 
		routeKey: routeKeys.RegisterVerify,
		queryToQueryMap: {
			email: 'username',
			token: 'token',
		},
	 },
	'guest-customer-service': { routeKey: routeKeys.ContactUs },
	'customer-service': { routeKey: routeKeys.ContactUs },
	'unlock-account': { 
		routeKey: routeKeys.UnlockAccount, 
		queryToQueryMap: {
			email: 'username',
		},
	},
	'email-recovery': { 
		routeKey: routeKeys.UnlockAccountTokenVerify, 
		queryToQueryMap: {
			email: 'username',
			token: 'token',
		},
	},
};


const getValuesFromMap = (map, query) => {
	
	if (typeof map === 'function') {
		return map(query);
	}

	if (typeof map === 'object') {
		return mapKeys(query, (_, key) => { return map[ key ]; });
	}

	return {};
};

const DirectLink = () => {
	const history = useHistory();
	const { key } = useParams();
	const redirect = REDIRECT_LINK[ key ];

	useEffect(() => {
		
		if (!redirect) {
			return;
		}

		const { search } = history.location;
		const query = getQuery(search);

		const route = getPathByRoute(redirect.routeKey);

		
		const state =  getValuesFromMap(redirect.queryToStateMap, query);
		const queryParams = stringify(getValuesFromMap(redirect.queryToQueryMap, query)); 

		history.replace(route.concat(queryParams && `?${queryParams}`), state);

	}, [ history, key, redirect ]); 



	return (!redirect && <NotFound/>);
};

export default DirectLink;