// extracted by mini-css-extract-plugin
export var backButtonWrapper = "layouts-__TakeOverLayout-module___backButtonWrapper";
export var backLink = "layouts-__TakeOverLayout-module___backLink";
export var cardDetails = "layouts-__TakeOverLayout-module___cardDetails";
export var cardMedia = "layouts-__TakeOverLayout-module___cardMedia";
export var cardName = "layouts-__TakeOverLayout-module___cardName";
export var cardNumber = "layouts-__TakeOverLayout-module___cardNumber";
export var contactContainer = "layouts-__TakeOverLayout-module___contactContainer";
export var contactDetailsWrapper = "layouts-__TakeOverLayout-module___contactDetailsWrapper";
export var contactSection = "layouts-__TakeOverLayout-module___contactSection";
export var contactValue = "layouts-__TakeOverLayout-module___contactValue";
export var content = "layouts-__TakeOverLayout-module___content";
export var currentstepContainer = "layouts-__TakeOverLayout-module___currentstepContainer";
export var dots = "layouts-__TakeOverLayout-module___dots";
export var goBack = "layouts-__TakeOverLayout-module___goBack";
export var goBackIcon = "layouts-__TakeOverLayout-module___goBackIcon";
export var goBackText = "layouts-__TakeOverLayout-module___goBackText";
export var groupAdmin = "layouts-__TakeOverLayout-module___groupAdmin";
export var hasTabs = "layouts-__TakeOverLayout-module___hasTabs";
export var hasTooltip = "layouts-__TakeOverLayout-module___hasTooltip";
export var header = "layouts-__TakeOverLayout-module___header";
export var headerContent = "layouts-__TakeOverLayout-module___headerContent";
export var isActive = "layouts-__TakeOverLayout-module___isActive";
export var isComplete = "layouts-__TakeOverLayout-module___isComplete";
export var legal = "layouts-__TakeOverLayout-module___legal";
export var main = "layouts-__TakeOverLayout-module___main";
export var mainColumn = "layouts-__TakeOverLayout-module___mainColumn";
export var mainContent = "layouts-__TakeOverLayout-module___mainContent";
export var rideInfo = "layouts-__TakeOverLayout-module___rideInfo";
export var sectionHeading = "layouts-__TakeOverLayout-module___sectionHeading";
export var step = "layouts-__TakeOverLayout-module___step";
export var stepIcon = "layouts-__TakeOverLayout-module___stepIcon";
export var stepsContainer = "layouts-__TakeOverLayout-module___stepsContainer";
export var stepsContent = "layouts-__TakeOverLayout-module___stepsContent";
export var stepsIcons = "layouts-__TakeOverLayout-module___stepsIcons";
export var subhead = "layouts-__TakeOverLayout-module___subhead";
export var subheadTooltip = "layouts-__TakeOverLayout-module___subheadTooltip";
export var subtitle = "layouts-__TakeOverLayout-module___subtitle";
export var tabContainer = "layouts-__TakeOverLayout-module___tabContainer";
export var tabsContent = "layouts-__TakeOverLayout-module___tabsContent";
export var title = "layouts-__TakeOverLayout-module___title";