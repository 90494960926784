import React, {
	useCallback,
	useContext,
	useState,
} from 'react';
import { gql } from "@apollo/client";
import { LanguageGet } from "components/data/Language.js";
import { remapCMNLang } from "layouts/components/MBTAFooter.js";
import PublicAppVars from "utils/PublicAppVars.js";
import { Helmet } from "react-helmet-async";
import { useCspNonceContext } from "components/CspNonceContext.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import mbtaAssetsJson from "dotcomchrome/assets-manifest.json";
import root from 'react-shadow';
import HidePypestreamChatbot from "components/pypestream-chatbot/HidePypestreamChatbot.js";

import * as style from 'App.module.css';

export const GET_MBTA_HEADER = gql`
	query GetMbtaHeader (
		$language: String
	) {
		mbtaComponents(language: $language) {
			id
			css
			headerContent
		}
	}
`;

export const MBTA_COMPONENTS_JS_FILE = '/mbta/' + mbtaAssetsJson[ "dotcomchrome.js" ].src;

/**
 * The MBTA menu has its own full-screen menu toggle on mobile breakpoint
 * We'll listen for them adding a `data-nav-open="true"` attribute to their top-level element
 * and when that changes, call onToggle with a boolean `open` value
 * @param {element} element
 * @param {CallableFunction} onToggle Called with boolean first param indicating whether the menu is open
 */
const setupMobileMenuToggleListener = ({
	element,
	onToggle,
}) => {
	if (!onToggle) {
		return;
	}

	const observer = new MutationObserver(function(mutations) {
		mutations.forEach(mutation => {
			if (mutation.type === "attributes") {
				// it's <div> when closed, <div data-nav-open="true"> when open
				const open = Boolean(mutation.target.getAttribute('data-nav-open'));

				// note that there's a bug in the MBTA-provided code: the mobile menu doesn't close
				// when the window is resized above the mobile breakpoint. Ignoring.
				onToggle(open);
			}
		});
	});

	observer.observe(element, {
		attributes: true, // listen to attribute changes
	});
};

const MBTAHeaderShadowRootContent = ({
	cspNonce,
}) => {
	const [ mobileMenuOpen, setMobileMenuOpen ] = useState(false);

	const currentLanguage = useContext(LanguageGet);
	const { data: { mbtaComponents: { headerContent, css } = {} } = {} } = useStdQuery(GET_MBTA_HEADER, {
		variables: {
			language: remapCMNLang(currentLanguage),
		},
	});

	const headerSetupRef = useCallback(mbtaHeaderRef => {
		if (!headerContent || !mbtaHeaderRef) {
			return;
		}

		setupMobileMenuToggleListener({
			element: mbtaHeaderRef,
			onToggle: setMobileMenuOpen,
		});

		window.setupDotcomChrome(mbtaHeaderRef);
	}, [
		headerContent,
	]);

	if (!headerContent) {
		return null;
	}

	return (
		<div>
			{mobileMenuOpen ? <HidePypestreamChatbot /> : null}
			<div ref={headerSetupRef} dangerouslySetInnerHTML={{ __html: headerContent }} />
			<style>{css}</style>
		</div>
	);
};

const MBTAHeader = () => {
	const cspNonce = useCspNonceContext();

	if (!PublicAppVars.MBTA_HEADER) {
		return null;
	}

	return (
		<div className={style.globalHeader}>
			<Helmet>
				<script type="text/javascript" nonce={cspNonce} src={MBTA_COMPONENTS_JS_FILE} defer />
			</Helmet>
			<div data-debug="mbta raw header">
				<root.div>
					<MBTAHeaderShadowRootContent
						cspNonce={cspNonce}
					/>
				</root.div>
			</div>
		</div>
	);
};

export default MBTAHeader;
