import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from "classnames";

import * as panel from './Panel.module.css';

class Panel extends Component {
	static propTypes = {
		overrideClass: PropTypes.string,
	};


	static defaultProps = {
		overrideClass: '',
	};


	render() {
		const { children, overrideClass } = this.props;

		return (
			<div className={cx(panel.container, overrideClass)}>
				{children}
			</div>
		);
	}
}

export default Panel;
