import React, {
	useState,
	useRef,
} from 'react';
import {
	values,
} from "lodash";
import {
	object as yup_object, ref as yup_ref,
	string as yup_string,
} from "yup";
import { gql } from "@apollo/client";
import cx from 'classnames';
import PropTypes from 'prop-types';

import Input from '../forms/Input.js';
import Button from '../Button.js';
import Modal from '../Modal.js';
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import FormHelper from 'utils/FormHelper';
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import { Lifecycles } from 'libreact/lib/Lifecycles';
import CmsContentList from '../data/CmsContentList.js';
import PreventDefault from 'utils/PreventDefault';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";

import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { useLogoutContext } from "components/auth/Logout.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { getPathByRoute } from "App.js";
import CustomerRouteKeys from "CustomerRouteKeys.js";

import * as modalStyles from 'components/Modal.module.css';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { GET_EMAIL } from "components/data/session-user/SessionUser.js";
import LoadingIcon from "components/icons/LoadingIcon.js";

const cms = {
	header: 'miscText.personal-email-edit-header',
	description: 'miscHtml.personal-email-edit-description',
	label: 'miscText["personal-email-edit-email1.label"]',
	confirmLabel: 'miscText["personal-email-edit-email2.label"]',
	cancel: 'miscText.personal-email-edit-cancel',
	submit: 'miscText.personal-email-edit-submit',
	confirmation: 'miscHtml.personal-email-validate-description',
};

const UPDATE_EMAIL = gql`
	mutation updateEmail(
		$email: String!,
	) {
		CustomerMutationRoute {
			updateEmail(
				email: $email
			)
		}
	}
`;

const EditEmail = ({ onModalClose }) => {
	const [ updateEmailMutation, { loading } ] = useStandardMutation(UPDATE_EMAIL);
	const [ validationState, setValidationState ] = useState({});
	const logoutAndClearCache = useLogoutContext();
	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;

	const { data: emailData, loading: getEmailLoading } = useStdQuery(GET_EMAIL);

	if (getEmailLoading) {
		return <LoadingIcon />;
	}

	const currentEmail = emailData.session.customer.contact.email;

	const getYupSchema = () => {
		return yup_object().shape({
			email: yup_string()
				.required(() => <CmsContentRenderedInline
					contentKey={'miscText["errors.general.field_required"]'}
					fallbackValue="New Email is required"
					variables={{ field: 'New Email' }}
				/>)
				.email('miscText["errors.general.email.invalid.format"]')
				.notOneOf([ currentEmail ], 'miscText.personal-email-edit-error-same'),
			confirm: yup_string()
				.required(() => <CmsContentRenderedInline
					contentKey={'miscText["errors.general.field_required"]'}
					fallbackValue="Confirm New Email is required"
					variables={{ field: 'Confirm New Email' }}
				/>)
				// @todo key
				.oneOf([ yup_ref('email') ], 'Emails must match'),
		});
	};

	formHelper.onHookedRender(validationState, setValidationState, getYupSchema);

	const kickoffSubmit = async () => {
		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			noticeError(null, levels.verbose, errorReport, `Edit email form validation`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("User is attempting to edit email");

		const { email } = validated;

		try {
			await graphqlErrorMiddleware(updateEmailMutation({ variables: { email } }));
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		onModalClose();
		logoutAndClearCache({
			redirectTo: {
				pathname: getPathByRoute(CustomerRouteKeys.VerifyUpdateEmail),
				state: {
					email,
				},
			},
		});
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal
				title={cmsContent[ cms.header ] || 'Edit Email'}
				data-qa="EditEmailModal"
				onModalClose={onModalClose}
			>
				<CmsContentRenderer.P
					contentKey={cms.description}
					fallbackValue="Enter the new email address that you'd like to use to log in to your account. After you save, you will be logged out and will need to validate the new email address."
					className={modalStyles.text}
					data-qa="EditEmailModalDescription"
				/>
				<Lifecycles didMount={formHelper.wireInputs}>
					<form
						data-qa="EditEmailModalForm"
						ref={formRef}
						onSubmit={PreventDefault(kickoffSubmit)}
					>
						{formHelper.getFieldErrorJsx('')}
						<div>
							<Input
								type="email"
								name="email"
								label={<CmsContentRenderer.Span
									contentKey={cms.label}
									fallbackValue="New Email"
								/>}
								data-qa="EditEmailModalEmail"
								error={formHelper.getFieldError('email')}
								onChange={() => formHelper.clearAllErrors()}
							/>
							<Input
								type="email"
								name="confirm"
								label={<CmsContentRenderer.Span
									contentKey={cms.confirmLabel}
									fallbackValue="Confirm New Email"
								/>}
								data-qa="EditEmailModalConfirm"
								error={formHelper.getFieldError('confirm')}
							/>
							<div className={modalStyles.actionButtons}>
								<Button
									type="button"
									onClick={onModalClose}
									additionalClassNames={cx(modalStyles.btn, modalStyles.leftButton)}
									data-qa="EditEmailModalCancelButton"
								>
									<CmsContentRenderer.Span
										contentKey={cms.cancel}
										fallbackValue="Cancel"
									/>
								</Button>
								<Button
									type="submit"
									additionalClassNames={cx(modalStyles.btn, modalStyles.rightButton)}
									data-qa="EditEmailModalSubmitBtn"
									submitting={loading}
									disabled={formHelper.hasErrors()}
								>
									<CmsContentRenderer.Span
										contentKey={cms.submit}
										fallbackValue="Submit"
									/>
								</Button>
							</div>
						</div>
					</form>
				</Lifecycles>
			</Modal>
		)}</CmsContentList>
	);
};

EditEmail.propTypes = {
	onModalClose: PropTypes.func.isRequired,
};

export default EditEmail;
