/**
 * Catches REST request errors in the client-side and turns them into an ErrorReport
 * Client side uses Axios for REST, server-side uses Request, so don't use this on the server-side
 * In other words, use this with ClientAjax not BackofficeApiForReq
 */
import { get } from 'lodash';
import {
	levels,
	log,
	noticeError,
} from "../Logger.js";
import ErrorReport from "./ErrorReport.js";

const reportUnhandledError = (error) => {
	const mapping = {
		// malformed response. timeout or failed connect to our backend
		'Request failed with status code 500': 'miscText.error-general-unexpected1',
		"Network Error": 'miscText.error-general-network',
	};

	throw ErrorReport.newFromObj({
		TopLevelKey: (
			mapping[ error.message ]
				? mapping[ error.message ]
				: 'miscText.error-general-unexpected4'
		),
	});
};

const reportWellformedError = (error) => {
	// we got a well-formed 500 response
	// most likely our backend noticed an error with the nfps api

	if (typeof get(error, "response.data.errorReport.TopLevelKey") === 'string') {
		throw error.response.data.errorReport;
	}

	if (process.env.NODE_ENV === 'development') {
		log(null, levels.warn, "Got a raw API response. Express route should be returning an ErrorReport instead");
	}

	throw ErrorReport.newFromObj({
		TopLevelKey: 'miscText.error-general-unexpected5',
	});
};

const restClientMiddleware = async (callbackPromise) => {
	const mockAjax = get(window, 'mockAjax', []);
	if (mockAjax.length) {
		const curMock = mockAjax.shift();

		if (curMock.realToo) {
			let result;
			try {
				result = await callbackPromise;
				log(null, levels.info, "original request response:");
				log(null, levels.info, result);
			}
			catch (error) {
				noticeError(null, levels.info, error, "original request errored");
			}
		}

		if (curMock.throw) {
			throw curMock.throw;
		}
		return curMock.return;
	}

	return callbackPromise
		.then(response => {
			const { errorReport } = response.data;

			// success: false is a problem, but it's ok if success is not part of the response
			const success = get(response, "data.success", true);
			if (success) {
				return response;
			}

			if (errorReport) {
				throw errorReport;
			}

			// Couldn't parse the error response
			log(null, levels.error, "received http code 200 request success=false but no errorReport");

			// Create something for the frontend to display
			throw ErrorReport.newFromObj({ TopLevelKey: 'miscText.error-general-200-failure' });
		})
		.catch(error => {
			// Try parsing the error from the server

			if (ErrorReport.isErrorReport(error)) {
				// let it bubble
				throw error;
			}

			// catches submit errors like: 500 errors, timeouts, etc
			noticeError(null, levels.error, error, "restClientMiddleware error handler");

			if (get(error, "response.data.success") === true) {
				log(null, levels.error, "success=true but http code <> 200");
				throw ErrorReport.newFromObj({ TopLevelKey: 'miscText.error-general-success-not-200' });
			}
			else if (get(error, "response.data.success") === false) {
				reportWellformedError(error);
			}
			reportUnhandledError(error);
		});
};

export default restClientMiddleware;
