import { createContext, useContext } from "react";
import {
	levels,
	log,
} from "utils/Logger.js";

// On the server, nonce is on req.locals.
// On the client, it's in window.__CSP_NONCE

const CspNonceContext = createContext(() => log(null, levels.error, "CspNonceContext used before it was ready"));
export const useCspNonceContext = () => useContext(CspNonceContext);

export default CspNonceContext;
