import React, { cloneElement } from 'react';
import parse from 'html-react-parser';
import { get } from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary.js';

const HtmlParser = (rawHtmlBody) => {
	const getNodes = (rawHtmlBody) => {
		const nodes = parse(rawHtmlBody, {
			// https://www.npmjs.com/package/html-react-parser#replacedomnode
			replace: (node) => {
				const elementType = get(node, 'attribs.data-component', '');

				const getMapping = (reactElement, props) => {
					const mapping = {
					};

					return mapping[ reactElement ];
				};

				switch (node.type) {
					case 'tag': {
						if (node.name.toLowerCase() === 'img' && elementType === 'SystemRolloutDropdown') {

							const props = {
								service: get(node, 'attribs.data-service', ''),
								svgAriaDesc: get(node, 'attribs.data-svg-aria-desc', ''),
							};

							const childrenToRender = getMapping(elementType, props);

							return (
								<ErrorBoundary>
									{childrenToRender}
								</ErrorBoundary>
							);
						}
						break;
					}
				}
			},
		});

		return nodes;
	};

	return getNodes(rawHtmlBody);
};

export default HtmlParser;
