import React, { useState, useRef } from 'react';
import {
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
} from "yup";
import {
	values,
} from 'lodash';

import { BO_ERRORS, COLLECTIONS, getErrorKey } from 'utils/GetErrorKey.js';
import PreventDefault from 'utils/PreventDefault.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from 'components/data/CmsContentRendered.js';
import Phone from 'components/forms/Phone.js';

import FormHelper from "../../utils/FormHelper.js";
import ClientAjax from "../../utils/ClientAjax.js";
import restClientMiddleware from "../../utils/error-handling/RestClientMiddleware.js";

import * as login from "./Login.module.css";
import { useModalContext } from 'context/ModalProvider.js';
import Modal from 'components/Modal.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Toast from 'components/Toast.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { addYupMethod } from 'utils/YupValidators.js';

const cms = {
	// Button
	resendUnlockButton: "miscText.unlock-account-verify-resend-cta",

	// Modal
	resendUnlockHeader: "miscText.unlock-account-verify-resend-modal-header",
	resendUnlockDescription: "miscText.unlock-account-verify-resend-modal-description",
	resendUnlockPhoneLabel: "miscText.unlock-account-verify-resend-modal-phone",
	resendUnlockSubmit: "miscText.unlock-account-verify-resend-modal-submit",

	// Toast
	resendUnlockToast: "miscText.unlock-account-verify-resend-confirmation",
};

export const LockoutResendButton = (props) => {
	const { setModal } = useModalContext();

	return (<Button
		typeStyle={buttonTypeStylePlain}
		onClick={PreventDefault(() => setModal(<LockoutResendForm { ...props } />))}
		isPrimary={false}
	>
		<CmsContentRendered.Span contentKey={cms.resendUnlockButton} fallbackValue="HC Resend Email" />
	</Button>
	);
};

const LockoutResendSuccessToast = () => {
	const { removeToast } = useGlobalToastsContext();

	return (<Toast
		type="success"
		onClosed={removeToast}
		title={<CmsContentRendered.Span contentKey={cms.resendUnlockToast} fallbackvalue="HC Resend Code"/>}
	/>);
};

addYupMethod("validPhoneNumber");

const getYupSchema = () => {
	const validations = {
		phoneNumber: yup_string()
			.required(getErrorKey(COLLECTIONS.registration, 'contact.phone[0].number', BO_ERRORS.general.required))
			.trim()
			.validPhoneNumber(
				yup_ref('phoneCountry'),
				getErrorKey(COLLECTIONS.registration,  'contact.phone[0].number', BO_ERRORS.general.invalidPhone),
			),
	};
	return yup_object().shape(validations);
};

const LockoutResendForm = ({ isUsernameEncrypted, parentFormHelper, initialEmail }) => {
	// FORM States
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});

	// Navigation and Render Helpers
	const { setModal } = useModalContext();
	const { setToast } = useGlobalToastsContext();

	// FORM Set up
	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(
		validationState,
		setValidationState,
		getYupSchema,
	);

	const triggerResend = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation();
		}
		catch (errorReport) {
			setSubmitting(false);
			formHelper.validationErrorHandler(errorReport);
			return;
		};

		const email = initialEmail ?? parentFormHelper.getFieldValue('email');

		const postData = {
			encrypted: isUsernameEncrypted,
			email,
			...validated,
		};

		try {
			await restClientMiddleware(ClientAjax.post("/ajax/request-unlock-account", postData));
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		};

		setSubmitting(false);
		setModal(null);
		setToast(<LockoutResendSuccessToast />);

	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal
				title={<CmsContentRenderer.Span contentKey={cms.resendUnlockHeader} fallbackValue='Resend Access Code' />}
			>
				<form
					data-qa="verificationCodeForm"
					ref={formRef}
					onSubmit={PreventDefault(() => triggerResend())}
				>
					<CmsContentRenderer.P contentKey={cms.resendUnlockDescription} fallbackValue="To have another access code emailed to you, please enter your phone number to verify your account." />

					<Phone
						{...{
							formHelper,
							legend: <CmsContentRenderer.Span contentKey={cms.resendUnlockPhoneLabel} fallbackValue="Phone Number" />,
						}}
					/>
					{formHelper.getFieldErrorJsx('')}
					<div className={login.formActions}>
						<Button
							submitting={submitting} >
							<CmsContentRenderer.Span contentKey={cms.resendUnlockSubmit} fallbackValue="Resend Code" />
						</Button>
					</div>
				</form>
			</Modal>
		)}
		</CmsContentList>
	);
};

export default LockoutResendForm;
