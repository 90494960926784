/**
 * Universal logging for GA
 */

import {
	forEach,
	size,
} from 'lodash';
import PublicAppVars from '../PublicAppVars.js';
import {
	levels,
	log,
} from '../Logger.js';


export default class GoogleAnalytics {

	// https://developers.google.com/gtagjs/reference/event
	static logEvent(...data) {
		const gtag = this.getGtag();
		gtag && gtag('event', ...data);
	}

	// https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions
	static logException(data) {
		log(null, levels.debug, { message: "GoogleAnalytics.logException", data });

		const gtag = this.getGtag();
		gtag && gtag('event', 'exception', data);
	}

	// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
	static sendPageview(data = {}) {
		const gtag = this.getGtag();
		if (!gtag) {
			return;
		}

		const TRACKING_IDS = PublicAppVars.GOOGLE_ANALYTICS_TRACKERS;

		// ready to pass the data to each google property
		forEach(TRACKING_IDS, (trk_meta, trk_id) => {
			gtag('config', trk_id, data);
		});
	}

	// https://developers.google.com/gtagjs/reference/api#set
	static setGA(data) {
		const gtag = this.getGtag();
		gtag && gtag('set', data);
	}

	// https://developers.google.com/analytics/devguides/collection/gtagjs/screens
	static screenView(data) {
		const gtag = this.getGtag();
		gtag && gtag('event', 'screen_view', data);
	}

	static getGtag() {
		if (typeof window === 'undefined') {
			log(null, levels.debug, { message: "trying to do GA tracking on server" });
			return;
		}

		const TRACKING_IDS = PublicAppVars.GOOGLE_ANALYTICS_TRACKERS;

		if (size(TRACKING_IDS) === 0) {
			log(null, levels.debug, { message: "No GA tracker configured." });
			return;
		}

		if (typeof window.gtag === 'undefined') {
			log(null, levels.debug, {
				message: "on client-side, with ga trackers, but gtag not defined",
			});
			return;
		}

		return window.gtag;
	}
}
