import React, { useContext } from 'react';
import PropTypes from "prop-types";
import cx from 'classnames';
import HtmlParser from "utils/markup-processing/HtmlParser.js";
import CmsContentListContext from "./CmsContentListContext.js";

import * as style from './CmsHtmlStyle.module.css';
import PublicAppVars from "utils/PublicAppVars.js";

export const getContentOrFallback = (translatedContent, fallbackValue) => {
	const fallbackOverride = PublicAppVars.CONTENT_FALLBACK_OVERRIDE ?? '{fallbackValue}';

	return translatedContent?.content
		?? fallbackOverride
			.replaceAll("{key}", translatedContent.key)
			.replaceAll("{fallbackValue}", fallbackValue);
};

export const findContentOrFallback = (cmsContent, contentKey, fallbackValue) =>
	 getContentOrFallback({
		content: cmsContent?.[ contentKey ],
		key: contentKey,
	}, fallbackValue);

const CmsContentRenderer = ({
	rawHtml = null,
	parseComponents = true,

	// div supports any child content, so it gives flexibility
	elementType: ElementType = "div",

	contentKey,
	content: contentProp,
	cmsContent: cmsContentProp,
	fallbackValue = '',

	className: propClassName,
	withStyle = true,

	...rest
}) => {
	const cmsContentListContext = useContext(CmsContentListContext);
	const cmsContent = cmsContentProp ?? cmsContentListContext;
	const content = contentProp ?? cmsContent?.[ contentKey ];
	const defaultedContent = getContentOrFallback({ content, key: contentKey }, fallbackValue);
	const asRawHtml = rawHtml ?? Boolean(contentKey?.match?.(/^miscHtml/));
	const fallbackStyle = (content == null && PublicAppVars.CONTENT_FALLBACK_COLORED)
		? {
			style: { color: 'rebeccapurple !important' },
		}
		: {};

	const className = withStyle
		? cx(
			propClassName,
			style.cmsHtmlStyle,
		)
		: propClassName;

	if (asRawHtml) {
		if (parseComponents) {
			return (
				<ElementType
					{...{
						className,
						...fallbackStyle,
						...rest,
					}}
					data-cms-key={contentKey}
				>
					{HtmlParser(defaultedContent)}
				</ElementType>
			);
		}

		return (
			<ElementType
				{...{
					className,
					...fallbackStyle,
					...rest,
				}}
				data-cms-key={contentKey}
				dangerouslySetInnerHTML={{ __html: defaultedContent }}
			/>
		);
	}

	return (
		<ElementType
			{...{
				className,
				...fallbackStyle,
				...rest,
			}}
			data-cms-key={contentKey}
		>
			{defaultedContent}
		</ElementType>
	);
};

CmsContentRenderer.propTypes = {
	contentKey: PropTypes.string,
	content: PropTypes.string,
	cmsContent: PropTypes.object,
	withStyle: PropTypes.bool,

	rawHtml: PropTypes.bool, // will be inferred from optional contentKey

	// lets you select the type of element that the cms content will be wrapped in
	elementType: PropTypes.node,
};

CmsContentRenderer.Div = props => <CmsContentRenderer elementType="div" {...props} />;
CmsContentRenderer.Div.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.Div.displayName = "CmsContentRenderer.Div";

CmsContentRenderer.Span = props => <CmsContentRenderer elementType="span" {...props} />;
CmsContentRenderer.Span.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.Span.displayName = "CmsContentRenderer.Span";

CmsContentRenderer.H1 = props => <CmsContentRenderer elementType="h1" {...props} />;
CmsContentRenderer.H1.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.H1.displayName = "CmsContentRenderer.H1";

CmsContentRenderer.H2 = props => <CmsContentRenderer elementType="h2" {...props} />;
CmsContentRenderer.H2.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.H2.displayName = "CmsContentRenderer.H2";

CmsContentRenderer.H3 = props => <CmsContentRenderer elementType="h3" {...props} />;
CmsContentRenderer.H3.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.H3.displayName = "CmsContentRenderer.H3";

CmsContentRenderer.H4 = props => <CmsContentRenderer elementType="h4" {...props} />;
CmsContentRenderer.H4.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.H4.displayName = "CmsContentRenderer.H4";

CmsContentRenderer.H5 = props => <CmsContentRenderer elementType="h5" {...props} />;
CmsContentRenderer.H5.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.H5.displayName = "CmsContentRenderer.H5";

// Note that generally <div> is better than <p> for cms content because cms content can have <p>
// but html doesn't let you have <p> inside a <p>
CmsContentRenderer.P = props => <CmsContentRenderer elementType="p" {...props} />;
CmsContentRenderer.P.propTypes = CmsContentRenderer.propTypes;
CmsContentRenderer.P.displayName = "CmsContentRenderer.P";


export default CmsContentRenderer;
