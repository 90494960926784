export const AddSingleMemberSteps = [
	{ step: 1, label: 'Step 1: Search for the Memebr in the MBTA Directory' },
	{ step: 2, label: 'Step 2: Confirm User and Addition Details (Optional)' },
];

export const AddNewSubgroupSteps = [
	{ step: 1, label: 'Step 1: Enter Details' },
	{ step: 2, label: 'Step 2: Payment Method' },
];


export const BatchAddMembersSteps = [
	{ step: 1, label: 'Step 1: Upload' },
	{ step: 2, label: 'Step 2: Confirm Upload' },
];


export const GroupAdminRegisterSteps = [
	{ step: 1, label: 'Step 1: Basic Group Information' },
	{ step: 2, label: 'Step 2: Account Registration' },
	{ step: 3, label: 'Step 3: Program Details' },
	{ step: 4, label: 'Step 4: Submit Application' },
];


export const RegisterSteps = [
	{ step: 1, label: 'Step 1: Personal Information' },
	{ step: 2, label: 'Step 2: Security Information' },
];


export const ReplaceCharlieCardSteps = [
	{ step: 1, label: 'Step 1: Select Charlie Card' },
	{ step: 2, label: 'Step 2: Shipping and Billing Address' },
];


export const Months = [
	{ value: 1, label: 'Jan' },
	{ value: 2, label: 'Feb' },
	{ value: 3, label: 'Mar' },
	{ value: 4, label: 'Apr' },
	{ value: 5, label: 'May' },
	{ value: 6, label: 'June' },
	{ value: 7, label: 'July' },
	{ value: 8, label: 'Aug' },
	{ value: 9, label: 'Sept' },
	{ value: 10, label: 'Oct' },
	{ value: 11, label: 'Nov' },
	{ value: 12, label: 'Dec' },
];


export const States = [
	{ value: 'AL', label: 'Alabama' },
	{ value: 'AK', label: 'Alaska' },
	{ value: 'AZ', label: 'Arizona' },
	{ value: 'AR', label: 'Arkansas' },
	{ value: 'CA', label: 'California' },
	{ value: 'CO', label: 'Colorado' },
	{ value: 'CT', label: 'Connecticut' },
	{ value: 'DE', label: 'Delaware' },
	{ value: 'FL', label: 'Florida' },
	{ value: 'GA', label: 'Georgia' },
	{ value: 'HI', label: 'Hawaii' },
	{ value: 'ID', label: 'Idaho' },
	{ value: 'IL', label: 'Illinois' },
	{ value: 'IN', label: 'Indiana' },
	{ value: 'IA', label: 'Iowa' },
	{ value: 'KS', label: 'Kansas' },
	{ value: 'KY', label: 'Kentucky' },
	{ value: 'LA', label: 'Louisiana' },
	{ value: 'ME', label: 'Maine' },
	{ value: 'MD', label: 'Maryland' },
	{ value: 'MA', label: 'Massachusetts' },
	{ value: 'MI', label: 'Michigan' },
	{ value: 'MN', label: 'Minnesota' },
	{ value: 'MS', label: 'Mississippi' },
	{ value: 'MO', label: 'Missouri' },
	{ value: 'MT', label: 'Montana' },
	{ value: 'NE', label: 'Nebraska' },
	{ value: 'NV', label: 'Nevada' },
	{ value: 'NH', label: 'New Hampshire' },
	{ value: 'NJ', label: 'New Jersey' },
	{ value: 'NM', label: 'New Mexico' },
	{ value: 'NY', label: 'New York' },
	{ value: 'NC', label: 'North Carolina' },
	{ value: 'ND', label: 'North Dakota' },
	{ value: 'OH', label: 'Ohio' },
	{ value: 'OK', label: 'Oklahoma' },
	{ value: 'OR', label: 'Oregon' },
	{ value: 'PA', label: 'Pennsylvania' },
	{ value: 'RI', label: 'Rhode Island' },
	{ value: 'SC', label: 'South Carolina' },
	{ value: 'SD', label: 'South Dakota' },
	{ value: 'TN', label: 'Tennessee' },
	{ value: 'TX', label: 'Texas' },
	{ value: 'UT', label: 'Utah' },
	{ value: 'VT', label: 'Vermont' },
	{ value: 'VA', label: 'Virginia' },
	{ value: 'WA', label: 'Washington' },
	{ value: 'WV', label: 'West Virginia' },
	{ value: 'WI', label: 'Wisconsin' },
	{ value: 'WY', label: 'Wyoming' },
];

export const CARDTYPE_AMEX = 'American Express';
export const CARDTYPE_MASTERCARD = 'Mastercard';
export const CARDTYPE_VISA = 'Visa';
export const CARDTYPE_CHARLIE = 'Charlie Card';
