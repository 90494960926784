import { useApolloClient } from "@apollo/client";
import React, {
	useState,
	useRef,
} from 'react';
import { values } from 'lodash';
import {
	object as yup_object,
} from "yup";

import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import FormHelper from "utils/FormHelper.js";
import { useGlobalToastsContext } from 'context/ToastProvider.js';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { TOKEN } from 'utils/Constants';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import { TOKEN_ACTION } from 'components/data/transit-account/subsystem/travel-token/TokenAction.mutation';
import { USER_PAYMENT_METHODS_QUERY } from 'components/data/session-user/SessionUser.js';
import { getSessionTransitAccountRefetchQueries } from "components/data/transit-account/SessionTransitAccount.js";
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import PublicAppVars from 'utils/PublicAppVars';

import Toast from "components/Toast.js";
import Modal from 'components/Modal.js';
import Button from "components/Button.js";

import * as style from 'components/modals/ResumeUseCard.module.css';

const cms = {
	resumeHeader: "miscText.card-unfreeze-header",
	confirmDescription: "miscHtml.card-unfreeze-description",
	confirmSubmit: "miscText.card-unfreeze-submit",
	successTitle: "miscText.card-unfreeze-confirmation",
};

const yupSchema = () => {
	return yup_object().shape({});
};

const ResumeUseCard = ({
	tokenId,
	subsystemAccountReference,
	onModalClose,
}) => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});

	const formRef = useRef(null);

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, yupSchema);

	const apolloClient = useApolloClient();

	const onSuccess = (cmsContent) => setToast(
		<Toast
			type="success"
			title={<CmsContentRenderer.Span
				cmsContent={cmsContent}
				contentKey={cms.successTitle}
				fallbackValue={'Your card has been reactivated, and can be used again for travel.'}
			/>}
			onClosed={removeToast}
		/>,
	);

	const kickoffSubmit = async (cmsContent) => {
		setSubmitting(true);

		const variables = {
			tokenId,
			tokenAction: TOKEN.ACTION.unblock,
			subsystemAccountReference,
			reasonCode: PublicAppVars.MANAGE_TOKENS_REASON_CODES.Unblock,
		};

		try {
			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: TOKEN_ACTION,
					variables,
					refetchQueries: [
						{ query: USER_PAYMENT_METHODS_QUERY },
						...getTransitAccountRefetchQueries(subsystemAccountReference),
						...getSessionTransitAccountRefetchQueries({ subsystemAccountReference }),
					],
				}));
		} catch (errorReport) {
			switch (errorReport.TopLevelKey) {
				default:
					// we're not redirecting anywhere. Prepare the form for the next submit.
					noticeError(null, levels.info, errorReport, `Resume Use Card Submit Failed`);
					formHelper.validationErrorHandler(errorReport);
			}
			return;
		} finally {
			setSubmitting(false);
		}

		onModalClose();
		onSuccess(cmsContent);
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal title={
				<CmsContentRenderer
					contentKey={cms.resumeHeader}
					fallbackValue={"Resume Use"}
				/>
			}>
				<CmsContentRenderer.P rawHtml
					className={style.boldStyle}
					contentKey={cms.confirmDescription}
					fallbackValue={"<p>Confirm that you would like to unblock this card.</p>"}
				/>
				<div className={style.actions}>
					<Button
						isPrimary
						type="submit"
						onClick={() => kickoffSubmit(cmsContent)}
						disabled={submitting}
					>
						<CmsContentRenderer.Span contentKey={cms.confirmSubmit}
							fallbackValue="Confirm"
						/>
					</Button>
				</div>
			</Modal>
		)}</CmsContentList>
	);
};

export default ResumeUseCard;
