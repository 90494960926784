import { gql } from "@apollo/client";
import React from 'react';
import StdQuery from "./StdQuery.js";

export const RECAPTCHA_ENABLED_QUERY = gql`
	query recaptchaEnabledQ {
		recaptchaEnabled
	}
`;

const RecaptchaEnabled = (props) => (
	<StdQuery
		query={RECAPTCHA_ENABLED_QUERY}
		name="RecaptchaEnabled component"
	>
		{(response) => (props.children(response.data.recaptchaEnabled, response))}
	</StdQuery>
);
export default RecaptchaEnabled;
