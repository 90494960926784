import React, {
	useEffect,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { wrapper } from '../Icon.js';

import * as style from './LoadingIcon.module.css';

import LoadingIconAsset from "components/assets/masked-pipes.gif";
import ButtonLoadingIcon from 'components/assets/masked-pipes-small.gif';
import { useTookAWhile } from "utils/RenderAfterAwhile.js";




export const SIZES = {
	button: 'button',
	cmsContent: 'content',
	component: 'component',
	page: 'page',
};



/**
 * Animated pipes used as when a request is being executed
 * alternate: Loader
 */
const LoadingIcon = ({
	hasLoaderIconWrapper = false,
	waitAWhile = false,
	height = 100,
	width = 100,
	size = SIZES.button,
	overrideClass = '',
	...propsRest
}) => {

	const { tookAWhile, startWaiting } = useTookAWhile(1000);

	useEffect(() => {
		startWaiting();
	}, [ startWaiting ]);

	if (waitAWhile && !tookAWhile) {
		return null;
	}

	const tookAWhileAttrs = {
		"aria-busy": "true",
		"aria-live": "polite",
	};

	const dimensions = {
		height,
		width,
	};

	return wrapper(
		<img
			src={size === SIZES.button ? ButtonLoadingIcon : LoadingIconAsset}
			{...(tookAWhile && tookAWhileAttrs)}
			aria-hidden="true"
			role="img"
			alt="loading icon"
			className={style.spinner}
			{...dimensions}
		/>,
		{
			...propsRest,
			overrideClass: hasLoaderIconWrapper
				? cx(style.loaderIcon, overrideClass)
				: overrideClass,
		},
	);
};

LoadingIcon.propTypes = {
	waitAWhile: PropTypes.bool,
	hasLoaderIconWrapper: PropTypes.bool,
	overrideClass: PropTypes.string,
	size: PropTypes.oneOf([ SIZES.button, SIZES.cmsContent, SIZES.component, SIZES.page ]),
};

export const PageLoading = (props) => {
	return <LoadingIcon {...props} size={SIZES.page} />;
};


export const ComponentLoading = (props) => {
	return <LoadingIcon {...props} waitAWhile={true} size={SIZES.component} />;
};


export default LoadingIcon;
