import { clone } from "lodash";

export default class WSAddress {
	constructor ({
		address1,
		address2 = null,
		address3 = null,
		city = null,
		state = null,
		country,
		postalCode,
	}) {
		// (Required) First line of the address.
		// string(64)
		this.address1 = address1;

		// (Conditionally-Required) Second line of the address. Address2 will be required based on country.
		// string(64)
		this.address2 = address2;

		// (Optional) Third line of the address.
		// string(64)
		this.address3 = address3;

		// (Conditionally-Required) The city, town, district or something
		// equivalent, where the address is located within for some specific
		// countries. For all other countries it is a fourth address line. If this field
		// holds city, it is required. If it holds fourth address line, it is optional.
		// string(64)
		this.city = city;

		// (Conditionally-Required) The state, province, county or something
		// equivalent, where the address is located within for some specific
		// countries. For all other countries it is a fifth address line.
		// Standard state codes for some specific countries. For USA, this would
		// be the standard 2-letter state codes. If this field holds state, it is
		// required. If it holds fifth address line, it is optional
		// string(64)
		this.state = state;

		// (Required) Standard ISO 2-letter country code. e.g. US, AU, GB, etc.
		// (ISO 3166-1 alpha-2 standard)
		// For UK member regions/countries/nations it is a 6-letter country code
		// e.g. GB-ENG, GB-SCT, GB-NOR, GB-WLS, GB-CHA (ISO 3166-2
		// standard)
		// string(6)
		this.country = country;

		// (Conditionally-Required) Valid values are country specific. For
		// example, for U.S. addresses, the postal code must be exactly 5 digits.
		// Postal code is required based on the country as all countries don’t
		// have postal code.
		// string(20)
		this.postalCode = postalCode;
	}

	static fromBackoffice(data) {
		const result = new WSAddress(data);
		return result;
	}

	static fromSerialized(data) {
		const result = new WSAddress(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}

	toDirectPay() {
		const forDirectPay = clone(this);

		// DirectPay V1.0 specs
		// https://reflexions.slack.com/archives/GA82SPCTV/p1530229591000123
		return {
			// string(60)
			// (optional) Shipping address line 1
			addressLine1: forDirectPay.address1,

			// string(60)
			// (optional) Shipping address line 2
			addressLine2: forDirectPay.address2,

			// string(60)
			// (optional) Shipping city
			city: forDirectPay.city,

			// string(60)
			// (optional) Shipping state
			state: forDirectPay.state,

			// string(60)
			// (optional) Billing zip code
			zip: forDirectPay.postalCode,

			// string(60)
			// (optional) Shipping 3 digit ISO country code
			countryCode: forDirectPay.country,
		};
	}
}

// address1 no longer required as of R11
export const WSAddressType = [ `
	type WSAddress {
		address1: String
		address2: String
		address3: String
		city: String
		state: String
		country: String!
		postalCode: String
	}
` ];

// address1 no longer required as of R11
export const InputWSAddressType = [ `
	input InputWSAddress {
		attentionTo: String
		address1: String
		address2: String
		address3: String
		city: String
		state: String
		country: String!
		postalCode: String
	}
` ];
