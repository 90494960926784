import * as helpers from './Styles.module.css';

/**
 * Return local css module class for status coloring, since its not
 * specific what property will change with the status
 *
 * @param {String} status - card status to check
 * @param {Component} component - module specific css
 */
export const colorCodeStatus = (status, component = helpers) => {
	const states = {
		Pending: component.isPending,
		Active: component.isActive,
		Suspended: component.isSuspended,
	};

	return states[ status ];
};
