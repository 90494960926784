/* eslint-disable complexity */
import { useApolloClient } from '@apollo/client';
import React, {
	useEffect,
	useState,
} from 'react';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import {
	useParams,
	useLocation,
	Redirect,
	useHistory,
} from 'react-router-dom';
import cx from 'classnames';
import {
	find,
	get,
	map,
	values,
	cloneDeep,
	concat,
	head,
	forEach,
} from 'lodash';

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { useTransitAccount, getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import { useCartContext } from 'context/CartProvider.js';
import WSCreditCardReference from 'server/api-types/WSCreditCardReference.js';
import { useEmvCanAutoload } from "components/data/transit-account/EMV.helpers.js";

import WSFundingSource from "server/api-types/WSFundingSource.js";
import { getTransitModeIcon } from 'components/icons/TransitModes.js';
import TakeOverLayout from 'layouts/TakeOverLayout.js';

import { SaveBtnPanel, handleEula, getBackupPaymentMethodValidation } from './card/AutoloadBalance.js';
import PreventDefault from 'utils/PreventDefault.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import { getPathByRoute } from 'App.js';
import { centsToDisplay, removeSpaces } from 'utils/FormatHelpers.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import routeKeys from 'CustomerRouteKeys.js';

import PassAutoloadEnabled from 'components/toasts/PassAutoloadEnabled.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { format } from 'date-fns';
import enUSLocale from 'date-fns/locale/en-US';

import { ENROLL_AUTOLOAD_PASS } from "pages/account/autoLoad/SetupAutoload.js";
import FundingSourcesProvider, { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import WSFundingSourceSetItem from "server/api-types/WSFundingSourceSetItem.js";
import WSAutoloadSubsystemProduct from 'server/api-types/WSAutoloadSubsystemProduct.js';
import WSAutoloadSubscriptionUpdate from 'server/api-types/WSAutoloadSubscriptionUpdate.js';
import WSFundingSourceSetUpdate from "server/api-types/WSFundingSourceSetUpdate.js";
import WSFundingSourceUpdateSet from 'server/api-types/WSFundingSourceUpdateSet.js';
import { WSAUTOLOAD_TYPES } from 'server/api-types/WSAutoloadFactory.js';
import { getRedirectUrl } from 'components/modals/SetupAutoload.js';

import {
	levels,
	noticeError,
} from "utils/Logger.js";

import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';
import {
	addExpiryDateFields,
	getYupSchema as getAddPaymentMethodYupSchema, paymentFieldsFromForm,
} from 'pages/account/purchase/AddPaymentMethod.js';

import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import {
	GET_AUTOLOAD_PAYMENT_METHODS,
	UPDATE_AUTOLOAD,
} from 'components/data/transit-account/GetAutoloadPaymentMethods.js';
import FormHelperProvider from 'utils/form-helper/FormHelperProvider.js';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import { Error as ComponentError } from 'components/data/Error.js';
import LoadingIcon, { ComponentLoading, SIZES } from 'components/icons/LoadingIcon.js';
import FundingPaymentMethods from 'components/FundingPaymentMethods.js';
import ReloadSessionDataQs from 'components/data/session-user/refetch-queries/ReloadSessionData.js';
import AutoloadContextProvider, { useAutoloadContext, AUTOLOAD_AFTER_PURCHASE } from "context/AutoloadContext.js";
import StandardNewProductAdded from 'components/toasts/StandardNewProductAdded.js';

import * as style from './AutoloadPass.module.css';
import * as typography from "styles/typography.module.css";

import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";
import { useFundingSources } from "components/data/session-user/FundingSources.query.js";

const cms = {
	header: "miscText.autoload-setup-cta",
	editHeader: 'miscText.autoload-edit-header',
	disclaimer: 'miscText.autoload-pass-disclaimer',
	paymentHeader: "miscText.autoload-payment-header",
	applyBalanceHeader: "miscText.autoload-payment-applybalance-subheader",
	applyBalanceDescription: "miscText.autoload-payment-applybalance-description",
	autoloadDescription: "miscHtml.autoload-description",
	learnMore: "miscText.purchase-balance-learnmore-cta",
	autoloadWhen: "miscText.autoload-pass-when",

	primarySubheader: 'miscText.autoload-payment-primary-subheader',
	secondarySubheader: 'miscText.autoload-payment-secondary-subheader',
	secondaryDescription: 'miscText.autoload-payment-secondary-description',
	generalOptional: "miscText.general-optional-field",
};

const LinkPassPanel = ({ wsTransitAccountProduct }) => {

	const { addProduct } = useCartContext();

	useEffect(() => {
		addProduct(wsTransitAccountProduct);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={style.section}>
			<CmsContentRenderer.Div
				rawHtml
				className={style.text}
				contentKey={cms.autoloadDescription}
				fallbackValue={`<p>For more information about autoload, <a href="http://www.mbta.com/charlie/autoload">click here</a>.</p>`}
			/>
			<div className={style.panel}>
				<h2 className={typography.h7}>{wsTransitAccountProduct?.name}</h2>
				<div className={style.linkPassValuesWrapper}>
					<span className={style.value}>{centsToDisplay(wsTransitAccountProduct.price)}</span>

					{wsTransitAccountProduct.permittedUsers && map(wsTransitAccountProduct.permittedUsers, wsSubsystemOperator => (
						<div className={style.mode} key={wsSubsystemOperator.id}>
							{wsSubsystemOperator.operatorName && getTransitModeIcon(removeSpaces(wsSubsystemOperator.operatorName), { alt: wsSubsystemOperator.operatorName })}
						</div>
					))}

					<CmsContentRenderer.Span
						contentKey={cms.learnMore}
						fallbackValue="Learn More"
						className={cx(style.text, style.link, style.learnMore)}
					/>
				</div>
				<CmsContentRendered.Div
					contentKey={cms.autoloadWhen}
					/** @todo: map locale to language setting*/
					fallbackValue={`Charged on the ${format(new Date(2017, 10, 6), 'do', { locale: enUSLocale })} of each month`}
					className={style.text}
					variables={{ day: format(new Date(2017, 10, 6), 'do', { locale: enUSLocale }) }}
				/>
			</div>

			<CmsContentRenderer.Div
				className={style.text}
				contentKey={cms.disclaimer}
				fallbackValue="By setting up autoload, you are agreeing to purchase the selected product with an understanding that fares are subject to change. You will be notified in advance of any such changes, and for passes, your autoload will be automatically adjusted accordingly unless you manually make changes prior to a change in fare levels."
			/>
		</div>
	);
};

const AutoloadPassContent = () => {
	const apolloClient = useApolloClient();
	const { transit_account_id, product_sku } = useParams();
	const history = useHistory();
	const location = useLocation();
	const [ redirect, setRedirect ] = useState(null);
	const emvCanAutoload = useEmvCanAutoload({ subsystemAccountReference: transit_account_id });

	const [ updateAutoload, setUpdateAutoload ] = useState(false);
	const [ fundingSourceSet, setFundingSourceSet ] = useState(null);

	const { refetch: refechWsFundingSourceInfos } = useFundingSources();

	const {
		fundingSources,
		initializeFundingSources,
		selectedFundingSources,
		isMultiPayment,
	} = useFundingSourcesContext();

	const autoloadPrimaryFundingSourceSetItem = find(fundingSourceSet?.fundingSources, { 'priority': 1 });
	const autoloadPrimaryFundingSource = find(fundingSources, { fundingSourceId: autoloadPrimaryFundingSourceSetItem?.fundingSourceId });
	const autoloadBackupFundingSourceSetItem = find(fundingSourceSet?.fundingSources, { 'priority': 2 });
	const autoloadBackupFundingSource = find(fundingSources, { fundingSourceId: autoloadBackupFundingSourceSetItem?.fundingSourceId });

	// user shouldn't be allowed to view this route since EMV Autoload is disabled
	useEffect(() => {
		if (!emvCanAutoload) {
			setRedirect(<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardOverview, {
					transit_account_id,
				}),
			}} />);
		}
	}, [ emvCanAutoload, transit_account_id ]);

	const {
		data,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id });

	const {
		products = [],
		loading: productCatalogLoading,
		error: productCatalogError,
	} = useGetSubsystemProductCatalog({ riderClassId: data?.transitAccountQ?.riderClass, queryOptions: { skip: !data } });

	const wsTransitAccountProduct = find(products, product => product.productSKU === product_sku);

	const fromPurchaseFlow = location.state?.from === AUTOLOAD_AFTER_PURCHASE ?? false;
	const { autoloads, addAutoload, removeTargetAutoload } = useAutoloadContext();
	const { targetAutoloads, currentSubscriptionInfo } = autoloads ?? {};

	useEffect(() => {
		if (location.state?.updateAutoload) {

			const { wsAutoloadSubscriptionInfo: { fundingSourceSet } } = location?.state ?? {};

			setUpdateAutoload(true);
			setFundingSourceSet(fundingSourceSet);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [ loadFundingSourcesMutator, {
		loading: fundingSourcesLoading,
		called: fundingSourcesCalled,
	} ] = useStandardMutation(GET_AUTOLOAD_PAYMENT_METHODS);

	const isNew = selectedFundingSources.map(({ isNew }) => isNew);
	const getYupSchema = (formHelper) => (
		getAddPaymentMethodYupSchema({
			formHelper,
			isMultiPayment,
			isSelecting: selectedFundingSources.map(() => false),
			isAch: [ false, false ],
			requireAchEula: false,
			isNew,
			isNewConfirmed: selectedFundingSources.map(({ cvv }) => Boolean(cvv)),
			hasMobilePayment: false,
			disableCvv: false,
			total: wsTransitAccountProduct.price,
		})
			.concat(
				getBackupPaymentMethodValidation({ isNew }),
			)
	);

	const getDataToValidate = (formHelper) => {
		const data = formHelper.getFormData();
		const elements = formHelper.getFormElements();
		forEach(elements, element => {
			if (element.name === 'reloadThreshold') {
				const fieldName = element.name;

				//convert decimal amount to int
				data[ fieldName ] = parseInt(element.value.replace(/[^0-9\.]+/g, "") * 100);
			}
		});

		addExpiryDateFields({
			isMultiPayment,
			data,
		});

		return data;
	};

	const formHelperContext = useFormHelper({ getYupSchema, getDataToValidate });

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = formHelperContext;

	const loadFundingSources = () => {
		const {
			fareProductTypeId,
			name,
			productSKU,
			price,
			permittedUsers,
		} = wsTransitAccountProduct;

		loadFundingSourcesMutator(
			{
				variables: {
					autoloadSubsystemProduct: {
						// string(20)
						// (Required) Identifies the type of autoload. Valid values are:
						// • AddOneAccountValue
						// • AddSubsystemValue
						// • PurchaseSubsystemProduct
						type: WSAUTOLOAD_TYPES.purchaseSubsystemProduct,

						// string(30)
						// (Conditionally-Required) Behavior of the autoload. Possible
						// value are:
						// • AddAutoloadValue
						// • IncreaseBalanceTo
						loadValueBehavior: 'AddAutoloadValue',
						subsystemAccountId: transit_account_id,
						subsystemRef: 'ABP',
						productTypeId: fareProductTypeId,
						productName: name,
						productSKU,
						productCost: price,
						permittedUsers: map(permittedUsers, wsSubsystemOperator => {
							return { operatorId: wsSubsystemOperator.operatorId };
						}),
					},
					autoloadCriteria: {
						type: "PassThreshold",
					},
				},
			}).then(response => {
			const { fundingSources } = get(response, "data.OrderRoute.getAutoLoadEnrollPaymentmethods", {});
			initializeFundingSources({
				fundingSources,
				refetchFundingSources: async () => ({ fundingSources: (await refechWsFundingSourceInfos()).data.session.fundingSources }),
			});
		}).catch(errorReport => {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Purchase Stored Value Payment Methods POST Failed`);
			formHelper.validationErrorHandler(errorReport, false);
		});

		return fundingSources;
	};

	const { setToast } = useGlobalToastsContext();

	const finalizeFundingSourcesFromForm = useFinalizeAndSetFundingSourcesToContext({
		formHelper,
	});

	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {
			await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		try {
			await handleEula({ apolloClient });
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Eula Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		// for prompted autoload from standart purchase product flow
		if (Boolean(targetAutoloads.length)) {
			removeTargetAutoload(head(targetAutoloads));
		}

		try {
			const {
				fareProductTypeId,
				name,
				productSKU,
				price,
				permittedUsers,
			} = wsTransitAccountProduct;

			const selectedFundingSources = await finalizeFundingSourcesFromForm();

			const wsAutoloadSubsystemProduct = {
				subsystemRef: 'ABP',
				subsystemAccountId: transit_account_id,
				productSKU,
				productTypeId: fareProductTypeId,
				productName: name,
				// travelTokenId: token_id,  // When calling autoload/enroll API we shouldn't be passing travelTokenId. Per Alessandro https://reflexions.slack.com/archives/GA82SPCTV/p1625592071134000?thread_ts=1625249929.112200&cid=GA82SPCTV
				productCost: price,
				type: WSAUTOLOAD_TYPES.purchaseSubsystemProduct,
				permittedUsers: map(permittedUsers, wsSubsystemOperator => {
					return { operatorId: wsSubsystemOperator.operatorId };
				}),
				loadValueBehavior: 'AddAutoloadValue',
			};

			const wsAutoloadCriteria = {
				type: "PassThreshold",
			};

			if (updateAutoload) {
				const variables = {
					subscriptionId: currentSubscriptionInfo.subscriptionId,
					subscription: new WSAutoloadSubscriptionUpdate({
						loadValueBehavior: currentSubscriptionInfo.autoload.loadValueBehavior,
						criteriaType: wsAutoloadCriteria.type,
						fundingSourceSet: new WSFundingSourceSetUpdate({
							fundingSourceSetId: currentSubscriptionInfo.fundingSourceSet.fundingSourceSetId.toString(),
							fundingSources: selectedFundingSources.map(({ fundingSource }, index) => new WSFundingSourceUpdateSet({
								fundingSourceId: fundingSource.fundingSourceId,
								priority: index + 1,
								...(index === 0 ? { maxPaymentAmount: price } : {}),
							})),
						}),
						daysBeforeExpiryToTrigger: currentSubscriptionInfo?.criteria?.daysBeforeExpiryToTrigger,
						productSKU: currentSubscriptionInfo?.autoload?.productSKU,
					}),
					subsystemAccountId: transit_account_id,
				};

				await graphqlErrorMiddleware(
					apolloClient.mutate({
						mutation: UPDATE_AUTOLOAD,
						variables,
						refetchQueries: [
							...ReloadSessionDataQs,
							...getTransitAccountRefetchQueries(transit_account_id),
						],
					}));
			} else {
				const wsFundingSourceSetItems = selectedFundingSources.map(({ fundingSource }, index) =>
					new WSFundingSourceSetItem({
						fundingSourceId: fundingSource.fundingSourceId,
						priority: index + 1,
						fundingSource: new WSFundingSource(fundingSource),
						...(index === 0 ? { maxPaymentAmount: price } : {}),
					}),
				);

				const variables = {
					fundingSourceSetItems: wsFundingSourceSetItems,
					autoloadSubsystemProduct: new WSAutoloadSubsystemProduct(wsAutoloadSubsystemProduct),
					autoloadCriteria: wsAutoloadCriteria,
				};

				await graphqlErrorMiddleware(
					apolloClient.mutate({
						mutation: ENROLL_AUTOLOAD_PASS,
						variables,
						refetchQueries: [
							...ReloadSessionDataQs,
							...getTransitAccountRefetchQueries(transit_account_id),
						],
					}));
			}
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Setup Autoload Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		} finally {
			setSubmitting(false);
		}

		if (fromPurchaseFlow) {
			// prompted autoload from standard purchase product flow
			const autoLoadInfo = {
				type: WSAUTOLOAD_TYPES.purchaseSubsystemProduct,
				startDate: new Date().toISOString(),
				pass: wsTransitAccountProduct.name,
				productSKU: wsTransitAccountProduct.productSKU,
			};

			addAutoload(autoLoadInfo);
			if (Boolean(targetAutoloads.length)) {
				// next target autoload redirect
				const clonedAutoloads = cloneDeep(autoloads);
				const redirectUrl = getRedirectUrl(targetAutoloads, transit_account_id);
				clonedAutoloads[ 'targetAutoloads' ] = clonedAutoloads.targetAutoloads.slice(1);
				clonedAutoloads[ 'newPlacedAutoloads' ][ 'productList' ] = concat(clonedAutoloads.newPlacedAutoloads.productList, autoLoadInfo);

				history.replace({
					pathname: redirectUrl,
					state: { from: AUTOLOAD_AFTER_PURCHASE, autoloads: clonedAutoloads },
				});

			} else {
				// end of target autoloads list, redirect and show toast success

				const clonedAutoloads = cloneDeep(autoloads);
				clonedAutoloads[ 'newPlacedAutoloads' ][ 'productList' ] = concat(clonedAutoloads.newPlacedAutoloads.productList, autoLoadInfo);

				history.replace({
					pathname: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id }),
					state: { from: AUTOLOAD_AFTER_PURCHASE, autoloads: clonedAutoloads },
				});

				setToast(<StandardNewProductAdded {...{ autoloads: clonedAutoloads }} />);
			}
		} else {
			// standard autoload flow
			setRedirect(
				<Redirect push to={{
					pathname: getPathByRoute(routeKeys.AccountCardOverview, {
						transit_account_id,
					}),
					state: { success: true },
				}} />,
			);
			setToast(<PassAutoloadEnabled pass={wsTransitAccountProduct.name} />);
		}

	};


	const handleCancel = () => {
		if (fromPurchaseFlow) {
			if (Boolean(targetAutoloads.length)) {
				removeTargetAutoload(head(targetAutoloads));
				// next target autoload redirect
				const redirectUrl = getRedirectUrl(targetAutoloads, transit_account_id);
				const clonedAutoloads = cloneDeep(autoloads);
				clonedAutoloads[ 'targetAutoloads' ] = clonedAutoloads.targetAutoloads.slice(1);

				history.replace({
					pathname: redirectUrl,
					state: { from: AUTOLOAD_AFTER_PURCHASE, autoloads: clonedAutoloads },
				});

			} else {
				// end of target autoloads list, redirect and show toast success
				setRedirect(<Redirect push to={{
					pathname: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id }),
				}} />);

				setToast(<StandardNewProductAdded {...{ autoloads }} />);
			}

		} else {
			// standard autoload flow
			setRedirect(<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id }),
			}} />);

		}
	};

	if (redirect) {
		return redirect;
	}

	if (productCatalogError) {
		return <ComponentError error={productCatalogError} />;
	}

	if (productCatalogLoading) {
		return <ComponentLoading />;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout
				title={<CmsContentRenderedInline
					contentKey={updateAutoload ? cms.editHeader : cms.header}
					fallbackValue={updateAutoload ? 'Edit Autoload' : 'Set Up Autoload'}
				/>}
				showCancel
				handleCancel={handleCancel}
				showSteps={false}
				showNickname
			>
				<Lifecycles didMount={loadFundingSources}>
					{(!fundingSourcesCalled || fundingSourcesLoading)
						? <LoadingIcon size={SIZES.page} />
						: <FormHelperProvider {...{ formHelperContext }}>
							<form
								ref={formRef}
								className={style.formWrapper}
								onSubmit={PreventDefault(kickoffSubmit)}
							>
								<LinkPassPanel wsTransitAccountProduct={wsTransitAccountProduct} />

								<div className={style.section}>
									<CmsContentRenderer.H2
										contentKey={cms.paymentHeader}
										fallbackValue="How Would You Like To Pay?"
										className={cx(typography.h7, style.header)}
									/>
									{/*
									https://reflexions.atlassian.net/browse/MBTA-2613
									Stored value cannot currently be applied to autoload so we are disabling it.

									<PurseBalanceProvider subsystemAccountReference={transit_account_id}>
										<PurseBalanceContext.Consumer>{({ purseTotal }) => (
											<Input
												label={<CmsContentRendered
													contentKey={cms.applyBalanceHeader}
													fallbackValue={`Apply Balance on This Charlie Card Toward This Purchase yu($${centsToDisplay(purseTotal)} available)`}
													variables={{ balance: centsToDisplay(purseTotal) }}
												/>}
												description={<CmsContentRenderer.Span
													contentKey={cms.applyBalanceDescription}
													fallbackValue="Enter the amount of your balance that you'd like to apply to this autoload. If enabled, any available transit value (up to the specified amount) will be applied towards the pass purchase before charging your other payment methods."
													className={style.text}
												/>}
												overrideClass={style.balanceInput}
												labelClass={style.balanceInputLabel}
												name="reloadThreshold"
												error={formHelper.getFieldError('reloadThreshold')}
											/>
										)}</PurseBalanceContext.Consumer>
									</PurseBalanceProvider> */}

									<div className={style.savePaymentWrapper}>
										{/* We are using a split payment form template+logic but the second payment method is optional
										https://projects.invisionapp.com/d/main?origin=v7#/console/15935242/434524528/preview?scrollOffset=0 */}
										<FundingPaymentMethods
											{...{
												cmsContent,
												isSplit: isMultiPayment, // used to determine input names
												isFirst: true,
												defaultFundingSource: autoloadPrimaryFundingSource,
												requiredSave: true,
												overwriteLabel: <CmsContentRendered.Span
													contentKey={cms.primarySubheader}
													fallbackValue="Which Payment Method Should Be Charged?" />,
											}}
										/>
										<FundingPaymentMethods
											{...{
												cmsContent,
												isSplit: true, // used to determine input names
												isFirst: false,
												requiredSave: true,
												defaultFundingSource: autoloadBackupFundingSource,
												overwriteLabel: (
													<div>
														<CmsContentRendered.Span contentKey={cms.secondarySubheader}
															fallbackValue="Do You Want to Set a Backup Payment Method?" /> {" "}
														<CmsContentRendered.Span contentKey={cms.generalOptional}
															className={cx(typography.fontWeightLight, typography.italic)}
															fallbackValue="(optional)" />
														<CmsContentRendered.P contentKey={cms.secondaryDescription}
															className={typography.fontWeightLight}
															fallbackValue="This payment method will be charged if your primary autoload payment method fails." />
													</div>
												),
											}}
										/>
									</div>
								</div>
								{formHelper.getFieldErrorJsx('')}
								<div className={cx(style.section, style.saveBtnWrapper)}>
									<SaveBtnPanel {...{ submitting }} />
								</div>
							</form>
						</FormHelperProvider>
					}
				</Lifecycles>
			</TakeOverLayout>
		)}</CmsContentList>
	);
};


const AutoloadPass = () => {
	return (
		<FundingSourcesProvider>
			<AutoloadContextProvider>
				<AutoloadPassContent />
			</AutoloadContextProvider>
		</FundingSourcesProvider>
	);
};

export default AutoloadPass;
