import WSIssueMediaLoadProduct, { WSIssueMediaLoadProductFragment } from "server/api-types/WSIssueMediaLoadProduct.js";
import WSSubsystemOperator, {
	InputWSSubsystemOperatorType,
	WSSubsystemOperatorType,
} from "server/api-types/WSSubsystemOperator.js";
import { clone, map } from "lodash";

export default class WSIssueMediaLoadSubsystemProduct extends WSIssueMediaLoadProduct {
	constructor(props) {
		super(props);

		this.loadType = 'LoadSubsystemProduct';

		/**
		 * @type string(40)
		 * (Required) The unique identifier of the pass to load.
		 */
		this.passSKU = props.passSKU;

		/**
		 * @type string(250)
		 * (Required) A textual description of the passSKU
		 * field.
		 */
		this.passName = props.passName;

		/**
		 * @type dateTime
		 * (Optional) For pass types that have a fixed start
		 * date, indicates the specific start date/time for this
		 * pass.
		 * Date field must be in UTC format.
		 */
		this.startDtm = props.startDtm || null;

		/**
		 * @type dateTime
		 * (Optional) For pass types that have a fixed end
		 * date, indicates the specific end date/time for this
		 * pass.
		 * Date field must be in UTC format.
		 */
		this.endDtm = props.endDtm || null;

		/**
		 * @type List<WSSubsystemOperator>
		 * (Required) List of operators that are permitted to use this transit
		 * product.
		 */
		this.permittedUsers = props.permittedUsers;

		/**
		 * @type int
		 * (Optional) Origin of journey
		 */
		this.origin = props.origin;

		/**
		 * @type int
		 * (Optional) Destination of journey
		 */
		this.destination = props.destination;
	}

	static fromBackoffice(data) {
		const result = new WSIssueMediaLoadSubsystemProduct(data);

		result.permittedUsers = map(data.permittedUsers, WSSubsystemOperator.fromBackoffice);

		return result;
	}

	static fromSerialized(data) {
		const result = new WSIssueMediaLoadSubsystemProduct(data);

		result.permittedUsers = map(data.permittedUsers, WSSubsystemOperator.fromSerialized);

		return result;
	}

	toInputWSIssueMediaLoadProductFactory() {
		return {
			wsIssueMediaLoadSubsystemProduct: clone(this),
		};
	}
}

export const WSIssueMediaLoadSubsystemProductFragment = `
	passSKU: String!
	passName: String!
	startDtm: DateTime
	endDtm: DateTime
	origin: Int
	destination: Int
`;

export const InputWSIssueMediaLoadSubsystemProductType = [
	...InputWSSubsystemOperatorType,
	`input InputWSIssueMediaLoadSubsystemProduct {
		${WSIssueMediaLoadProductFragment}
		${WSIssueMediaLoadSubsystemProductFragment}
		permittedUsers: [ InputWSSubsystemOperator! ]!
	}`,
];

export const WSIssueMediaLoadSubsystemProductType = [
	...WSSubsystemOperatorType,

	`type WSIssueMediaLoadSubsystemProduct {
		${WSIssueMediaLoadProductFragment}
		${WSIssueMediaLoadSubsystemProductFragment}
		permittedUsers: [ WSSubsystemOperator! ]!
	}`,
];
