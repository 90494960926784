import React from 'react';
import PropTypes from 'prop-types';
import {
	map,
} from 'lodash';

import PurseBalanceProvider from "context/PurseBalanceContext";
import Card from './Card.js';
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';

import * as style from 'pages/account/CardSelection.module.css';

const CardList = ({
	wsSubsystemAccountInfos,
}) => {
	return (
		<section className={style.cardList}>
			{wsSubsystemAccountInfos.map((wsSubsystemAccountInfo) => {
				const {
					subsystemAccountReference,
					subsystemAccountDetailedInfo,
				} = wsSubsystemAccountInfo;

				const { tokens } = subsystemAccountDetailedInfo;

				// ghost cards dont have a primary token
				// https://reflexions.atlassian.net/browse/MBTA-2758
				const primaryToken = findPrimaryToken(tokens) ?? null;

				return <PurseBalanceProvider
					key={`${subsystemAccountReference}-${primaryToken?.id}`}
					{...{ subsystemAccountReference }}
				>
					<Card
						{...{
							wsSubsystemAccountInfo,
							subsystemAccountReference,
							wsSubsystemAccountToken: primaryToken,
						}} />
				</PurseBalanceProvider>;
			})}
		</section>
	);
};

CardList.propTypes = {
	wsSubsystemAccountInfos: PropTypes.array.isRequired,
};


export default CardList;
