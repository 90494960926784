import React, {
	useState,
} from 'react';
import {
	values,
	capitalize,
} from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Input from '../forms/Input.js';
import MaskedInput from '../forms/MaskedInput.js';

import CreditCard from '../forms/CreditCard.js';
import CmsContentList from 'components/data/CmsContentList';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Tooltip from 'components/Tooltip.js';
import { SecurityCode } from '../Icon.js';
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import PublicAppVars from "utils/PublicAppVars.js";

import * as forms from '../forms/Forms.module.css';
import * as inputStyle from "components/forms/Input.module.css";
import * as addPaymentStyle from '../account/TabAddPaymentCard.module.css';

export const ACTION_EDIT = 'edit';
export const ACTION_ADD = 'add';

export const cms = {
	nameInputLabel: 'miscText["general-payment-cc-name.label"]',
	cardNumberInputLabel: 'miscText["general-payment-cc-cardnum.label"]',
	expiration: 'miscText["general-payment-cc-expiry.label"]',
	securityCode: 'miscText["general-payment-cc-cvv.label"]',
	securityCodeDescription: "miscText.general-payment-cc-cvv-tooltip",
	cardNickNameInputLabel: 'miscText["addcard-charlie-physical-nickname.label"]',
	cardNickNameDescription: 'miscText["addcard-charlie-virtual-nickname.description"]',
	cardPlaceholderText: 'miscText["general-payment-cc-name.label"]',
};

const setInputStyle = (type, long = true) => {
	switch (type) {
		case ACTION_EDIT:
			return long
				? forms.inlineInput
				: cx(forms.inlineInput, forms.shortInput);
		default:
			return long
				? forms.ccFormInput
				: cx(forms.ccFormInput, forms.ccShortInput);
	}
};

export const TooltipCvv = ({
	contentKeyOverride = null,
	cmsContent,
	tooltipId,
	labelCmsKey,
	labelPanelCmsKey,
	htmlFor,
}) => (
	<label className={inputStyle.label} {...{ htmlFor }}>
		<CmsContentRenderedInline
			elementType="span"
			contentKey={contentKeyOverride ?? labelCmsKey}
			fallbackValue="Security Code"
		/>
		<Tooltip
			tooltipId={tooltipId}
			overrideClass={forms.toolTipIcon}
			ariaLabel={cmsContent[ labelCmsKey ] || 'Security Code'}
			ariaLabelPanel={cmsContent[ labelPanelCmsKey ] || 'Last 3 digits on the back, or 4 on the front for Amex'}
		>
			<div className={addPaymentStyle.securityCodeTooltip}>
				<CmsContentRenderedInline
					rawHtml
					elementType="div"
					contentKey={labelPanelCmsKey}
					fallbackValue={"<p>Last 3 digits on the back, <br />or 4 on the front for Amex</p>"}
				/>
				<SecurityCode className={addPaymentStyle.securityCode} />
			</div>
		</Tooltip>
	</label>
);

/**
 * Data-QA Tags:
 * CCFormName, CCFormNickname, CCFormNumber, CCFormExp, CCFormCVV
 */

export const cardCVV = "cardCVV";
export const getCardCvvFieldName = (formInputNamePrefix) => (formInputNamePrefix + cardCVV);

const CreditCardForm = ({
	formHelper,
	type = ACTION_ADD,
	provideNickName = true,
	isSplit = false,
	isFirst = true,
	updateSelectedFundingSource = () => {},
	wsFundingSourceExt,
}) => {
	const getInputName = (fieldName) => getPaymentInputName({ base: fieldName, isSplit, isFirst });

	const [ currentCardType, setCurrentCardType ] = useState('generic');
	const isActionAdd = type === ACTION_ADD;

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<>
				<div className={cx(forms.row, forms.ccRow)}>
					<Input
						type="text"
						name={getInputName('nameOnCard')}
						label={<CmsContentRenderer.Span contentKey={cms.nameInputLabel} fallbackValue="Name" />}
						placeholder={cmsContent[ cms.cardPlaceholderText ] ?? 'As written on card'}
						overrideClass={setInputStyle(type)}
						data-qa={getInputName('dataQaName')}
						error={formHelper.getFieldError(getInputName('nameOnCard'))}
						defaultValue={wsFundingSourceExt?.creditCard?.nameOnCard ?? ''}
					/>
				</div>
				{isActionAdd
					? <div className={cx(forms.row, forms.ccRow)}>
						<CreditCard
							name={getInputName('cardNumber')}
							label={<CmsContentRenderer.Span contentKey={cms.cardNumberInputLabel} fallbackValue="Card Number" />}
							placeholder="0000 0000 0000 0000"
							overrideClass={setInputStyle(type)}
							defaultValue={wsFundingSourceExt?.creditCard?.cardNumber}
							options={{
								creditCard: true,
								onCreditCardTypeChanged: type => setCurrentCardType(type),
								delimiter: '',
							}}
							currentCard={capitalize(currentCardType)}
							data-qa={getInputName('dataQaCardNumber')}
							error={formHelper.getFieldError(getInputName('cardNumber'))}
						/>
					</div>
					: null
				}

				<div className={cx(forms.row, forms.ccRow, forms.cvc)}>
					<MaskedInput
						type="text"
						name={getInputName("expirationDate")}
						label={<CmsContentRenderer.Span contentKey={cms.expiration} fallbackValue="Expiration" />}
						placeholder="MM / YY"
						overrideClass={setInputStyle(type, false)}
						options={{
							date: true,
							datePattern: [ 'm', 'y' ],
						}}
						data-qa={getInputName("CCFormExpiration")}
						error={formHelper.getFieldError(getInputName("expirationDate"))}
						initialvalue={wsFundingSourceExt?.creditCard?.cardExpiryMMYY ?? ''}
					/>
					<div className={forms.passwordWrapper}>
						<Input
							name={getInputName(cardCVV)}
							autoComplete="cc-csc"
							id={getInputName(cardCVV)}
							arialabel={cmsContent[ cms.securityCode ] || 'Security Code'}
							placeholder="123"
							hideLabel={true}
							overrideClass={setInputStyle(type, false)}
							data-qa={getInputName('dataQaCardCVV')}
							error={formHelper.getFieldError(getInputName(cardCVV))}
							maxLengthErrorMsg={null}
							maxLengthDisplay={false}
							onChange={({ target }) => updateSelectedFundingSource({
								splitPaymentIndex: isFirst ? 0 : 1,
								cvv: target.value,
							})}
						/>
						<TooltipCvv {...{
							cmsContent,
							tooltipId: 'cvvCardToolTip',
							labelCmsKey: cms.securityCode,
							labelPanelCmsKey: cms.securityCodeDescription,
							htmlFor: getInputName(cardCVV),
						}} />
					</div>
				</div>
				{provideNickName &&
					<div className={cx(forms.row, forms.ccRow, forms.withSuffix)}>
						<Input
							maxLength={PublicAppVars.CARD_NICKNAME_MAXLENGTH}
							type="text"
							name={getInputName('cardNickname')}
							label={<div>
								<CmsContentRenderer.Span contentKey={cms.cardNickNameInputLabel} fallbackValue="Card Nickname" />
								{isActionAdd && <CmsContentRenderer.Div
									contentKey={cms.cardNickNameDescription}
									fallbackValue="Enter a nickname to help you distinguish between cards. The last 4 digits of your card will always display."
									className={forms.ccText}
								/>}
							</div>}
							overrideClass={setInputStyle(type)}
							data-qa={getInputName('dataQaNicknameInput')}
							error={formHelper.getFieldError(getInputName('cardNickname'))}
							defaultValue={wsFundingSourceExt?.nickname ?? ''}
							suffix="...0000"
						/>
					</div>
				}
			</>
		)}</CmsContentList>
	);
};

CreditCardForm.propTypes = {
	formHelper: PropTypes.object.isRequired,
	type: PropTypes.string,
};

export default CreditCardForm;
