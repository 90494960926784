import {
	useEffect,
	useState,
} from "react";

export const BP_MOBILE = 576;    // 36rem
export const BP_TABLET = 768;    // 48rem
export const BP_LAPTOP = 1088;   // 68rem
export const BP_DESKTOP = 1344;  // 84rem

// same queries as in src/styles/breakpoints.css
export const MQ_MOBILE = `screen and (min-width: 36em)`;
export const MQ_TABLET = `screen and (min-width: 48em)`;
export const MQ_LAPTOP = `screen and (min-width: 68em)`;
export const MQ_DESKTOP = `screen and (min-width: 84em)`;

export const isMobile = () => typeof window !== "undefined" && window.matchMedia(MQ_MOBILE).matches;
export const isTablet = () => typeof window !== "undefined" && window.matchMedia(MQ_TABLET).matches;
export const isLaptop = () => typeof window !== "undefined" && window.matchMedia(MQ_LAPTOP).matches;
export const isDesktop = () => typeof window !== "undefined" && window.matchMedia(MQ_DESKTOP).matches;

/**
 * Returns true if the given media query matches.
 * Accepts a callback as a 2nd param that will be called when the media query
 * matching changes. It is passed the MediaQueryListEvent.
 *
 * @param {string} mediaQuery
 * @param {function(MediaQueryListEvent):void} callback
 * @returns {boolean}
 */
export const useMediaQueryMatches = (
	mediaQuery,
	callback = () => {},
) => {
	const [ crossed, setCrossed ] = useState(null);

	useEffect(() => {
		const mql = window.matchMedia(mediaQuery);
		setCrossed(mql.matches);

		const event = 'change';
		const listener = event => {
			setCrossed(event.matches);
			callback(event);
		};

		mql.addEventListener(event, listener);
		return () => mql.removeEventListener(event, listener);
	}, [
		callback,
		mediaQuery,
	]);

	return crossed;
};
