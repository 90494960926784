import {
	merge,
	clone,
} from "lodash";
import WSAccountValuePayment, {
	WSAccountValuePaymentFragment,
} from "./WSAccountValuePayment.js";

// This object extends WSAccountValuePayment and represents a Subsystem purse value payment.

export default class WSSubsystemValuePayment extends WSAccountValuePayment {
	constructor({
		subsystem,
		subsystemAccountReference,
		purseType,
		purseTypeDescription,
		purseRestriction,
		purseRestrictionDescription,
		nickname = null,

		// super props
		paymentAmount,
		accountType,
	}) {

		super({
			accountType,
			paymentAmount,
		});

		// string(20)
		// (Required) Unique identifier for the subsystem where the travel token is
		// registered.
		this.subsystem = subsystem;

		// string(20)
		// (Required) Unique identifier for the customer’s account in the subsystem.
		this.subsystemAccountReference = subsystemAccountReference;

		// string(40)
		// (Required) Type of purse it is.
		this.purseType = purseType;

		// string(40)
		// (Required) Description of the purse type.
		this.purseTypeDescription = purseTypeDescription;

		// string(40)
		// (Required) Type of the purse restriction.
		this.purseRestriction = purseRestriction;

		// string(40)
		// (Required) Description of the purse restriction.
		this.purseRestrictionDescription = purseRestrictionDescription;

		// string(30)
		// (Optional) The nickname for this purse.
		this.nickname = nickname;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemValuePayment(data);

		return merge(
			result,
			super.fromBackoffice(data),
		);
	}

	toBackoffice() {
		const forBackoffice = {
			...this,
			...super.toBackoffice(),
		};

		delete forBackoffice.nickname;

		return forBackoffice;
	}

	toResolver() {
		return merge(
			this,
			super.toResolver(),
		);
	}

	toGraphqlInput() {
		const forGraphqlInput = clone(this);

		if (!forGraphqlInput.nickname) {
			delete forGraphqlInput.nickname;
		}

		return merge(
			forGraphqlInput,
			super.toResolver(),
		);
	}

	toInputWSPaymentFactory() {
		return {
			wsSubsystemValuePayment: this.toGraphqlInput(),
		};
	}
}

export const WSSubsystemValuePaymentFragment =`
	subsystem: String!
	subsystemAccountReference: String!
	purseType: String!
	purseTypeDescription: String!
	purseRestriction: String!
	purseRestrictionDescription: String!
	nickname: String
	${WSAccountValuePaymentFragment}
`;

export const InputWSSubsystemValuePaymentType = [ `
	input InputWSSubsystemValuePayment {
		${WSSubsystemValuePaymentFragment}
	}
` ];

export const WSSubsystemValuePaymentType = [ `
	type WSSubsystemValuePayment {
		${WSSubsystemValuePaymentFragment}
	}
` ];
