import { gql } from "@apollo/client";
import { find, flatten, isEmpty, map, times } from 'lodash';
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";
import { getCartBalance, getCartMediaOption, getCartProducts } from 'context/CartProvider.js';
import WSSubsystemPurse from 'server/api-types/WSSubsystemPurse.js';
import WSLoadSubsystemAccountValue from 'server/api-types/WSLoadSubsystemAccountValue.js';
import WSLoadSubsystemProduct from 'server/api-types/WSLoadSubsystemProduct.js';
import WSFeeDueLineItem from 'server/api-types/WSFeeDueLineItem.js';
import WSSubsystemAccountFeeDue from 'server/api-types/WSSubsystemAccountFeeDue.js';
import { SubsystemAccountFeeDue, TransitAccountTokenEnablementFee } from 'server/api-types/WSFeeDue.js';

import { types } from 'server/api-types/WSLoadProductFactory.js';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { SUBSYSTEM_ID, UNRESTRICTED } from 'utils/Constants.js';

import { GET_PAYMENT_METHODS } from 'components/data/order/sale/Paymentmethods.query.js';
import WSIssueMediaLineItem from 'server/api-types/WSIssueMediaLineItem.js';
import WSIssueSubsystemMedia from 'server/api-types/WSIssueSubsystemMedia.js';

export const FULL_PURCHASE_MUTATION = gql`
	mutation PurchasePass (
		$travelTokenId: String
		$amountDue: Int!
		$shoppingCartId: String!

		# what we're buying
		$products: [ InputWSLoadSubsystemProduct! ]
		$storedValues: [ InputWSLoadSubsystemAccountValue! ]
		$issueMediaLineItems: [ InputWSIssueMediaLineItem! ]
		$feeDueLineItems: [ InputWSFeeDueLineItem! ]

		$eulaId: String

		# what we're paying with
		$payments: [ InputWSPaymentFactory! ]!
		$shippingName: InputWSName
		$shippingAddress: InputWSAddressExt
	) {
		OrderRoute {
			id
			postOrderSale (
				travelTokenId: $travelTokenId
				amountDue: $amountDue
				shoppingCartId: $shoppingCartId

				products: $products
				storedValues: $storedValues
				issueMediaLineItems: $issueMediaLineItems
				feeDueLineItems: $feeDueLineItems

				eulaId: $eulaId

				payments: $payments
				shippingName: $shippingName
				shippingAddress : $shippingAddress
			) {
				orderId
				responseCode
				paymentRefNbr
				authRefNbr
				authDateTime
			}
		}
	}`;

export const getWsFundingSourceInfo = (wsPayment, fundingSources) => {
	if (wsPayment?.paymentType !== PAYMENT_TYPES.creditCard && wsPayment?.paymentType !== PAYMENT_TYPES.directDebit) {
		return;
	}

	if (wsPayment?.paymentType === PAYMENT_TYPES.directDebit) {
		const directDebitFundingSource = find(fundingSources, ({ fundingSourceId }) => fundingSourceId === wsPayment.fundingSourceId);
		// Alessandro's response on 05/24/2021 regards translation Bank Account
		// https://reflexions.slack.com/archives/GA82SPCTV/p1622150334180500
		// no need translate
		return directDebitFundingSource;
	} else {

		const wsFundingSourceInfo = find(fundingSources, (wsFundingSourceInfo => wsFundingSourceInfo.creditCard?.pgCardId === wsPayment.pgCardId));

		if (wsFundingSourceInfo) {
			return wsFundingSourceInfo;
		}

		const creditCardType = wsPayment.creditCardType ?? wsPayment.creditCard?.creditCardType;
		const maskedPan = !isEmpty(wsPayment.oneTime)
			? wsPayment.oneTime?.cardNumber.slice(-4)
			: !isEmpty(wsPayment.creditCard)
				? wsPayment.creditCard?.maskedPan
				: wsPayment.maskedPan;

		const nickname = wsPayment.nickname;

		const creditCard = {
			creditCardType,
			maskedPan,
			nickname,
		};

		// simulates a wsFundingSourceInfo
		return { creditCard };
	}
};

// only show the finish button if there are no null payments
export const getDisplayingPayAndFinish = (selectedWSPayments) => !selectedWSPayments.some(source => !source);

export const getProductList = (
	cart,
	subsystemAccountReference,
	wSSubsystemPurse,
	travelTokenId = null,
) => {
	const issueMedia = getCartMediaOption(cart);
	const products = !issueMedia ? getCartProducts(cart) : [];
	const storedValueAmt = !issueMedia ? getCartBalance(cart) : 0;
	const feeDue = cart.unpaidFee;
	const loadSubsystemAccountValues = [];

	if (storedValueAmt) {
		const {
			purseType,
			purseTypeDescription,
			purseRestriction,
			purseRestrictionDescription,
		} = wSSubsystemPurse || new WSSubsystemPurse({
			purseType: PAYMENT_TYPES.storedValue,
			purseTypeDescription: "Stored Value",
			purseRestriction: UNRESTRICTED,
			purseRestrictionDescription: UNRESTRICTED,
		});

		const wsLoadSubsystemAccountValue = new WSLoadSubsystemAccountValue({
			subsystem: SUBSYSTEM_ID,
			productCost: storedValueAmt,
			productLineItemType: types.loadSubsystemAccountValue,
			subsystemAccountReference,
			purseType,
			purseTypeDescription,
			purseRestriction,
			purseRestrictionDescription,
		});
		loadSubsystemAccountValues.push(wsLoadSubsystemAccountValue);
	}

	// Todo: storedValues and products are subclasses of WSLoadProduct, we should use an input factory
	const productList = {
		issueMediaLineItems: issueMedia
			? times(issueMedia.quantity, () => new WSIssueMediaLineItem({
				issueMedia: new WSIssueSubsystemMedia({
					type: "SubsystemMedia",
					subsystem: SUBSYSTEM_ID,
					mediaType: issueMedia.mediaType,
					enablementFeeAmount: issueMedia.enablementFeeAmount,
					itemTotalAmount: issueMedia.itemTotalAmount,
					subsystemTokenType: issueMedia.subsystemTokenType,
					inventoryPartNumber: issueMedia.inventoryPartNumber,
					loadProducts: issueMedia.loadProducts,
				}),
			}))
			: [],
		storedValues: loadSubsystemAccountValues,
		products: flatten(map(products, wsTransitAccountProduct => {
			const {
				price,
				productSku,
				productName,
				permittedUsers,
				// Todo:
				// Rolling passes dont have a `validityStartDtm`
				// will this break the order sale api?
				// https://reflexions.slack.com/archives/CCF68M49M/p1649272534608129
				validityStartDtm,
				quantity,
			} = wsTransitAccountProduct;

			const newProduct = new WSLoadSubsystemProduct({
				subsystem: SUBSYSTEM_ID,
				productCost: price,
				productLineItemType: types.loadSubsystemProduct,
				subsystemAccountReference,
				productSKU: productSku,
				productReceiptName: productName,
				permittedUsers: map(permittedUsers, wsSubsystemOperator => ({ operatorId: wsSubsystemOperator.operatorId })),
				productName,
				travelTokenId,
				validityStartDtm,
			});
			return Array(quantity).fill(newProduct);
		})),
	};

	if (Boolean(feeDue)) {
		productList.feeDueLineItems = new WSFeeDueLineItem({
			feeDue: new WSSubsystemAccountFeeDue({
				feeDueAmount: feeDue,
				feeDueLineItemType: SubsystemAccountFeeDue,
				feeDueType: TransitAccountTokenEnablementFee,
				subsystem: SUBSYSTEM_ID,
				subsystemAccountReference,
				tokenId: travelTokenId,
			}),
		});
	}
	return productList;
};

/**
 * 	Get available payment methods2.14.19 order/sale/paymentmethods POST
	This API is used to return the possible payment methods for the lineItems in a sale order. This method can be
	used for sale orders for registered and unregistered customers.
	If the order is for a registered customer, the payment methods may include available amounts in the
	OneAccount purses , Subsystem Account purses and registered funding sources.
	If the order is for an unregistered customer, the payment methods will include available amounts in the
	Subsystem Account purses.
	Load of products can be paid with OneAccount or Subsystem Account purses depending on purse restriction.
	Load of value cannot be paid with amounts from existing purses.
	Fee dues can be paid with OneAccount purses depending on purse restriction.
 */
export const getPaymentMethods = async ({
	cart,
	apolloClient,
	subsystemAccountReference,
	wSSubsystemPurse,
	tokenId = null,
}) => {
	const productList = getProductList(cart, subsystemAccountReference, wSSubsystemPurse, tokenId);

	const response = await graphqlErrorMiddleware(
		apolloClient.mutate({
			mutation: GET_PAYMENT_METHODS,
			variables: productList,
		}));

	const {
		shoppingCartId,
		loadProductLineItemFunds,
		fundingSources, // can be null
		restrictFunding,
	} = response.data.OrderRoute.getPaymentmethods;

	return {
		shoppingCartId,
		loadProductLineItemFunds,
		fundingSources,
		restrictFunding,
	};
};
