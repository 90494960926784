import React, {
	useState,
	useRef,
} from 'react';
import { values } from 'lodash';
import cx from "classnames";
import {
	boolean as yup_boolean,
	object as yup_object,
} from "yup";

import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import Panel from 'components/Panel.js';
import { BoolToggle } from "components/forms/Toggle.js";
import EditPassword from 'components/modals/EditPassword.js';
import EditPinCode from 'components/modals/EditPinCode.js';
import EditSecurityQuestion, { SECURITY_QUESTIONS_FORM_MODE } from 'components/modals/EditSecurityQuestion.js';
import { GET_SYSTEMGENPASSWORD_SETTING, Pin } from 'components/data/session-user/SessionUser.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import Tooltip from 'components/Tooltip.js';
import StandardMutation from 'components/data/StandardMutation.js';
import FormHelper, { WireFormHelper } from 'utils/FormHelper.js';
import { AUTH_FACTOR_ONE, AUTH_FACTOR_TWO } from 'server/api-types/WSAccessibilityPreference.js';
import useStdQuery from 'components/data/hooks/useStdQuery';
import Button from 'components/Button';
import {
	GET_ACCESSIBILITY_PREFERENCES,
	SET_ACCESSIBILITY_PREFERENCES,
} from 'components/data/AccessibilityPreferences.js';
import {
	GET_AVAILABLE_SECURITY_QUESTIONS,
	GET_CUSTOMER_SECURITY_QUESTIONS,
} from 'components/data/customer/SecurityQuestions.js';
import { useModalContext } from 'context/ModalProvider.js';

import * as typography from "styles/typography.module.css";
import * as panelStyles from 'components/Panel.module.css';
import * as securityInformationStyles from './SecurityInformation.module.css';

const cms = {
	securityInfoSubheader: 'miscText.security-info-subheader',
	securityInfoPassword: 'miscText.security-info-password',
	passwordReset: 'miscText.security-info-password-reset',
	passwordCreate: 'miscText.security-info-password-create',
	pinLabel: 'miscText.security-info-pin',
	pinReset: 'miscText.security-info-pin-reset',
	pinTooltip: 'miscText["security-info-pin.tooltip"]',
	securityTwoFactorHeading: 'miscText.security-2fa-subheader',
	securityTwoFactorDescription: 'miscText.security-2fa-description',
	securityTwoFactorTooltip: 'miscHtml.security-2fa-tooltip',
	securityQsSubHeader: 'miscText.security-questions-subheader',
	securityQsDescription: 'miscText.security-questions-description',
	securityQsEditCTA: 'miscText.security-questions-edit-cta',
	securityQsSetup: 'miscText.security-questions-setup-cta',
	uncheckedText: 'miscText.general-toggle-off',
	checkedText: 'miscText.general-toggle-on',
};

const getYupSchema = () => yup_object().shape({
	authFactor: yup_boolean().required(),
});

const useSecurityInformationData = () => {
	const prefsResponse = useStdQuery(GET_ACCESSIBILITY_PREFERENCES);
	const customerSecurityQsResponse = useStdQuery(GET_CUSTOMER_SECURITY_QUESTIONS);
	const availableSecurityQsResponse = useStdQuery(GET_AVAILABLE_SECURITY_QUESTIONS);
	const systemGenPasswordResponse = useStdQuery(GET_SYSTEMGENPASSWORD_SETTING);
	const wsAccessibilityPreference = prefsResponse.data?.session.customer?.contact.accessibilityPreferences ?? {};
	const customerSecurityQuestions = customerSecurityQsResponse.data?.session.customer?.contact.securityQAs ?? []; // WSSecurityQA[]
	const availableSecurityQuestions = availableSecurityQsResponse.data?.securityQuestionsQ ?? []; // WSNameValue[]
	const isSystemGenPassword = systemGenPasswordResponse.data?.session?.customer?.contact?.systemGenPassword ?? false;
	const { refetch: refetchSystemGenPassword } = systemGenPasswordResponse;
	return {
		wsAccessibilityPreference,
		customerSecurityQuestions,
		availableSecurityQuestions,
		isSystemGenPassword,
		refetchSystemGenPassword,
	};
};

const SecurityInformation = () => {
	const { setModal } = useModalContext();

	const [ validationState, setValidationState ] = useState({});
	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({ formRef }));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(
		validationState,
		setValidationState,
		getYupSchema,
	);

	const {
		wsAccessibilityPreference,
		customerSecurityQuestions,
		availableSecurityQuestions,
		isSystemGenPassword,
		refetchSystemGenPassword,
	} = useSecurityInformationData();

	const onModalClose = () => setModal(null);
	const openResetPinModal = () => setModal(<EditPinCode {...{ onModalClose }} />);
	const openEditSecurityQuestionModal = (formMode) => () => setModal(<EditSecurityQuestion
		{...{ formMode, onModalClose, customerSecurityQuestions, availableSecurityQuestions }}
	/>);
	const openResetPasswordModal = () => setModal(<EditPassword
		showCurrentPasswordField={!isSystemGenPassword}
		{...{ onModalClose, refetchSystemGenPassword }}
	/>);

	const kickOffSubmit = (updateUserPreferences, wsAccessibilityPreference) => async () => {
		let validated;
		try {
			validated = await formHelper.startValidation();
			formHelper.clearAllErrors();
		} catch (errorReport) {
			noticeError(null, levels.verbose, errorReport, `Enable Two-Factor Auth Validation`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		const authFactor = validated.authFactor ? AUTH_FACTOR_TWO : AUTH_FACTOR_ONE;
		const payload = {
			...wsAccessibilityPreference,
			authFactor,
		};

		GoogleAnalytics.logEvent(`User is updating 2FA settings to new value: ${authFactor}`);

		try {
			await graphqlErrorMiddleware(updateUserPreferences({
				variables: payload,
			}));
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
		}
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<StandardMutation
				mutation={SET_ACCESSIBILITY_PREFERENCES}
				refetchQueries={[ { query: GET_ACCESSIBILITY_PREFERENCES } ]}
				showLoadingState={false}
				errorChildren={null}
			>{(updateUserPreferences) => (
					<Panel>
						<div className={panelStyles.section}>
							<CmsContentRenderer.H2
								contentKey={cms.securityInfoSubheader}
								fallbackValue="Security information"
								className={cx(panelStyles.heading, securityInformationStyles.securityHeading, typography.h8)}
								data-qa="PanelSecInfoTitle"
							/>
							<div className={cx(panelStyles.row, securityInformationStyles.passwordPinRow)}>
								<div className={securityInformationStyles.passwordPinLabelWrapper}>
									<CmsContentRenderer.Div
										contentKey={cms.securityInfoPassword}
										fallbackValue="Password"
										className={cx(panelStyles.infoLabel, securityInformationStyles.passwordPinLabel, {
											[ securityInformationStyles.createPasswordLabel ]: isSystemGenPassword,
										})}
										data-qa="PanelSecInfoPasswordLabel"
									/>
									{!isSystemGenPassword && <div className={panelStyles.info}>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</div>}
								</div>
								<button className={cx(panelStyles.rowBtn, isSystemGenPassword && securityInformationStyles.createPassButton)}
									onClick={openResetPasswordModal}
									data-qa="PanelSecInfoPasswordBtn"
								>
									{isSystemGenPassword ? (
										<CmsContentRenderer.Span
											contentKey={cms.passwordCreate}
											fallbackValue="Enable login with email and password"
										/>
									) : (
										<CmsContentRenderer.Span
											contentKey={cms.passwordReset}
											fallbackValue="Reset"
										/>
									)}
								</button>
							</div>
							<div className={cx(panelStyles.row, securityInformationStyles.passwordPinRow)}>
								<div className={securityInformationStyles.passwordPinLabelWrapper}>
									<div className={cx(panelStyles.infoLabel, securityInformationStyles.passwordPinLabel)} data-qa="PanelSecInfoPinLabel">
										<CmsContentRenderer.Span
											contentKey={cms.pinLabel}
											data-qa="PanelSecInfoPinLabel"
											fallbackValue="Customer Support Access PIN"
										/>
										<Tooltip
											tooltipId={'panelSecInfoPinLabelToolTip'}
											ariaLabel={cmsContent[ cms.pinLabel ] || 'Customer Support Access PIN'}
											ariaLabelPanel={cmsContent[ cms.pinTooltip ] || 'Customer support may ask for this PIN in order to authenticate your account before providing assistance.'}
										>
											<CmsContentRenderer.Span
												contentKey={cms.pinTooltip}
												fallbackValue="Customer support may ask for this PIN in order to authenticate your account before providing assistance."
											/>
										</Tooltip>
									</div>
									<div className={cx(panelStyles.info, securityInformationStyles.pinValue)}><Pin /></div>
								</div>
								<button className={panelStyles.rowBtn}
									onClick={openResetPinModal}
									data-qa="PanelSecInfoPinBtn"
								>
									<CmsContentRenderer.Span
										contentKey={cms.pinReset}
										fallbackValue="Reset PIN"
									/>
								</button>
							</div>
						</div>

						<div className={panelStyles.section}>
							<h2 className={panelStyles.heading} data-qa="PanelTwoFactorAuthTitle">
								<CmsContentRenderer.Span
									contentKey={cms.securityTwoFactorHeading}
									fallbackValue="Two-Factor Authentication"
									className={typography.h7}
								/>
								<Tooltip
									overrideClass={securityInformationStyles.toolTip}
									tooltipId={'panelTwoFactorAuthTitleToolTip'}
									ariaLabel={cmsContent[ cms.securityTwoFactorHeading ] || 'Two-Factor Authentication'}
									ariaLabelPanel={cmsContent[ cms.securityTwoFactorTooltip ] || "Two-factor authentication adds an extra layer of security during account login, sending a unique code via email or text message to ensure you're the account owner."}
								>
									<CmsContentRenderer.Span
										className={securityInformationStyles.twoFactorTooltip}
										contentKey={cms.securityTwoFactorTooltip}
										rawHtml={true}
										fallbackValue="<p><strong>Two-factor authentication</strong> adds an extra layer of security during account login, sending a unique code via email or text message to ensure you're the account owner.</p>"
									/>
								</Tooltip>
							</h2>
							<div className={cx(panelStyles.row, securityInformationStyles.twoFactorToggle)}>
								<CmsContentRenderer.P
									className={panelStyles.rowLabel}
									data-qa="PanelTwoFactorAuthLabel"
									contentKey={cms.securityTwoFactorDescription}
									fallbackValue="Turn on two-factor authentication for account login."
								/>
								<WireFormHelper {...{ formHelper }}>
									<form
										data-qa="two-factor-auth-toggle-form"
										ref={formRef}
									>
										<BoolToggle
											name="authFactor"
											overrideClass={panelStyles.rowAction}
											onChange={kickOffSubmit(updateUserPreferences, wsAccessibilityPreference)}
											data-qa="two-factor-auth-toggle"
											defaultChecked={wsAccessibilityPreference.authFactor === AUTH_FACTOR_TWO}
										/>
										{formHelper.getFieldErrorJsx('')}
									</form>
								</WireFormHelper>
							</div>
						</div>

						<div className={panelStyles.section}>
							<div className={securityInformationStyles.securityQsHeaderWrapper}>
								<CmsContentRenderer.H2
									className={cx(typography.h8, panelStyles.heading)}
									data-qa="PanelSecQsTitle"
									contentKey={cms.securityQsSubHeader}
									fallbackValue="Security Questions"
								/>
								{customerSecurityQuestions.length > 0 && (
									<button className={cx(panelStyles.rowBtn, securityInformationStyles.securityQsEditButton)}
										onClick={openEditSecurityQuestionModal(SECURITY_QUESTIONS_FORM_MODE.edit)}
										data-qa="PanelSecQsEditBtn"
									>
										<CmsContentRenderer
											contentKey={cms.securityQsEditCTA}
											fallbackValue="Edit"
										/>
									</button>
								)}
							</div>
							<CmsContentRenderer.P
								className={panelStyles.label}
								data-qa="PanelSecQsDescLabel"
								contentKey={cms.securityQsDescription}
								fallbackValue="If you need to update your password or unlock your account, you'll need to answer these questions first. Be sure to set responses that you'll remember."
							/>
							{customerSecurityQuestions.length === 0 ? (
								<Button
									onClick={openEditSecurityQuestionModal(SECURITY_QUESTIONS_FORM_MODE.setup)}
									data-qa="PanelSecQsSetupBtn"
								>
									<CmsContentRenderer.Span
										contentKey={cms.securityQsSetup}
										fallbackValue="Setup security questions"
									/>
								</Button>
							) : (
								<ol className={securityInformationStyles.securityQsList}>
									{customerSecurityQuestions.map((wsSecurityQA, i) => {
										// there is a maximum of 2 questions that should be displayed here
										return (i >= 2) ? null : (
											<li className={panelStyles.infoLabel}>
												<div className={panelStyles.row}>
													<p className={panelStyles.infoLabel} data-qa="PanelSecQsInfoLabel">{wsSecurityQA.securityQuestion}</p>
												</div>
												<div className={panelStyles.row}>
													<div className={panelStyles.info}>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</div>
												</div>
											</li>
										);
									})}
								</ol>
							)}
						</div>
					</Panel>
				)}
			</StandardMutation>
		)}</CmsContentList>
	);
};


export default SecurityInformation;
