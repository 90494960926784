// extracted by mini-css-extract-plugin
export var arrowWrapper = "components-account-panels-__NotificationPreferences-module___arrowWrapper";
export var body = "components-account-panels-__NotificationPreferences-module___body";
export var border = "components-account-panels-__NotificationPreferences-module___border";
export var checkboxIcon = "components-account-panels-__NotificationPreferences-module___checkboxIcon";
export var collapse = "components-account-panels-__NotificationPreferences-module___collapse";
export var container = "components-account-panels-__NotificationPreferences-module___container";
export var description = "components-account-panels-__NotificationPreferences-module___description";
export var displayLabels = "components-account-panels-__NotificationPreferences-module___displayLabels";
export var errorCheckboxWrapper = "components-account-panels-__NotificationPreferences-module___errorCheckboxWrapper";
export var header = "components-account-panels-__NotificationPreferences-module___header";
export var headerSection = "components-account-panels-__NotificationPreferences-module___headerSection";
export var headerWrapper = "components-account-panels-__NotificationPreferences-module___headerWrapper";
export var labels = "components-account-panels-__NotificationPreferences-module___labels";
export var leftColumn = "components-account-panels-__NotificationPreferences-module___leftColumn";
export var lockIcon = "components-account-panels-__NotificationPreferences-module___lockIcon";
export var mainHeader = "components-account-panels-__NotificationPreferences-module___mainHeader";
export var mediaLabel = "components-account-panels-__NotificationPreferences-module___mediaLabel";
export var mediaLabelMobile = "components-account-panels-__NotificationPreferences-module___mediaLabelMobile";
export var mobileLabelLeft = "components-account-panels-__NotificationPreferences-module___mobileLabelLeft";
export var mobileLabelRight = "components-account-panels-__NotificationPreferences-module___mobileLabelRight";
export var notificationRows = "components-account-panels-__NotificationPreferences-module___notificationRows";
export var optional = "components-account-panels-__NotificationPreferences-module___optional";
export var optionalSection = "components-account-panels-__NotificationPreferences-module___optionalSection";
export var panelWrapper = "components-account-panels-__NotificationPreferences-module___panelWrapper";
export var rightColumn = "components-account-panels-__NotificationPreferences-module___rightColumn";
export var section = "components-account-panels-__NotificationPreferences-module___section";
export var singleNotification = "components-account-panels-__NotificationPreferences-module___singleNotification";
export var subHeaderLabel = "components-account-panels-__NotificationPreferences-module___subHeaderLabel";
export var textContent = "components-account-panels-__NotificationPreferences-module___textContent";
export var toggleContainer = "components-account-panels-__NotificationPreferences-module___toggleContainer";