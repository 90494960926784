// extracted by mini-css-extract-plugin
export var action = "components-account-__AddAnotherCardActions-module___action";
export var actionContainer = "components-account-__AddAnotherCardActions-module___actionContainer";
export var actionDisabled = "components-account-__AddAnotherCardActions-module___actionDisabled";
export var actionsList = "components-account-__AddAnotherCardActions-module___actionsList";
export var actionsWrapper = "components-account-__AddAnotherCardActions-module___actionsWrapper";
export var cardLabel = "components-account-__AddAnotherCardActions-module___cardLabel";
export var comingSoon = "components-account-__AddAnotherCardActions-module___comingSoon";
export var container = "components-account-__AddAnotherCardActions-module___container";
export var content = "components-account-__AddAnotherCardActions-module___content";
export var desktopIcon = "components-account-__AddAnotherCardActions-module___desktopIcon";
export var mobileIcon = "components-account-__AddAnotherCardActions-module___mobileIcon";
export var title = "components-account-__AddAnotherCardActions-module___title";