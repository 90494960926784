/**
 * A standardized data format hopefully capable of representing all error conditions
 *
 * Sometimes one error report might be translated to another. E.g. a backend api call middleware might return one error
 * report that the caller translates into an error report for the frontend. This is particularly helpful when the
 * backend returns errors in different forms. The middleware would put a timeout error into TopLevelKey for example.
 *
 * A top-level message is used when an error can't be traced back to a single field
 *
 * TopLevelKey and FieldErrors store CMS keys. Display stores the corresponding CMS data.
 * Messages with no corresponding CMS key can appear in display, and will have a falsy key equivalent.
 */
export default class ErrorReport {
	// TODO: Rename these across the project to use lowercase first letter
	// The cms key for the top-level error message
	TopLevelKey = '';
	// Obj keyed on field name, with cms keys for the field-level error messages
	// It can also have an array of cms keys per field, in which case the display fieldErrors for that field will
	// have an array of the same length. A null in this array means that the display version should be used and vice versa
	FieldErrors = {};

	// the content retrieved from the cms for the above cms keys
	// client-side doesn't know how to fetch this, but could be taught to use graphql
	display = {
		language: '',
		topLevelMessage: '',
		fieldErrors: {},
	};

	static newFromObj(obj) {
		return Object.assign(new ErrorReport(), obj);
	}

	static newFromString(string) {
		return Object.assign(new ErrorReport(), JSON.parse(string));
	}

	static isErrorReport(obj) {
		return (typeof obj.TopLevelKey === 'string');
	}

	sendToRes(res) {
		// we don't actually know if this error report is a server error (e.g. timeout to BO) or client error (bad data)
		// but we'll use 500 anyway
		res.status(500)
			.json({
				success: false,
				errorReport: this,
			});
	}

	toString() {
		return JSON.stringify(this);
	}
}
