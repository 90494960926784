import React, {
	useState,
} from 'react';
import {
	levels,
	log,
} from 'utils/Logger.js';
import { AUTO_LOAD_CRITERIA_TYPES } from 'server/api-types/WSAutoloadCriteria.js';
import { WEEKLY } from '../../../utils/Constants';
import PublicAppVars from "utils/PublicAppVars.js";
export const BIWEEKLY_FREQUENCY = 2;
export const WEEKLY_MONTHLY_FREQUENCY = 1;

export const AutoLoadCriteriaContext = React.createContext(() =>
	log(null, levels.error, "AutoLoadCriteria used before it was ready"));

export const AutoLoadCriteriaContextProvider = ({
	children,
}) => {

	const [ autoLoadCriteriaType, setAutoLoadCriteriaType ] = useState(AUTO_LOAD_CRITERIA_TYPES.threshold);
	const [ thresholdAmount, setThresholdAmount ] = useState(0);
	const [ reloadValue, setReloadValue ] = useState(0);

	const [ frequency, setFrequency ] = useState(WEEKLY_MONTHLY_FREQUENCY);
	const [ recurrence, setRecurrence ] = useState(WEEKLY);
	const [ monthDaySelect, setMonthDaySelect ] = useState(null);
	const [ weekDaySelect, setWeekDaySelect ] = useState(null);
	const [ startDate, setStartDate ] = useState(null);
	const [ originalStartDate, setOriginalStartDate ] = useState(null);

	const contextValue = {
		autoLoadCriteriaType,
		setAutoLoadCriteriaType,
		thresholdAmount,
		setThresholdAmount,
		reloadValue,
		setReloadValue,

		frequency,
		setFrequency,
		recurrence,
		setRecurrence,
		monthDaySelect,
		setMonthDaySelect,
		weekDaySelect,
		setWeekDaySelect,
		startDate,
		setStartDate,
		originalStartDate,
		setOriginalStartDate,
	};
	
	if (PublicAppVars.LOG_FUNDING_SOURCES_CONTEXT_CHANGES) {
		console.log(contextValue);
	}

	return (
		<AutoLoadCriteriaContext.Provider value={contextValue}>
			{children}
		</AutoLoadCriteriaContext.Provider>
	);
};