import { first } from "lodash";
import { FUND_PAYMENT_TYPES } from "server/api-types/WSSubsystemFunds.js";
import WSSubsystemValuePayment from "server/api-types/WSSubsystemValuePayment.js";
import { ACCOUNT_TYPES } from "server/api-types/WSAccountValuePayment.js";
import { getAmountDue } from "context/CartProvider.js";
import WSCreditDebitPayment from "server/api-types/WSCreditDebitPayment.js";
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";
import { fundingSourceToWSPayment } from "context/FundingSourcesContext.js";

export const getWSSubsystemValuePayments = (cart, loadProductLineItemFunds) => {
	const { appliedStoredValue } = cart;

	if (!appliedStoredValue) {
		return [];
	}

	const wsSubsystemValuePayments = [];

	const subsystemAccountFunds = (first(loadProductLineItemFunds)?.subsystemAccountFunds ?? []).sort((a, b) => {
		// Sort benefit values first
		if (a.paymentType === b.paymentType) {
			return 0;
		}

		if (a.paymentType === FUND_PAYMENT_TYPES.benefitValue) {
			return -1;
		}

		if (b.paymentType === FUND_PAYMENT_TYPES.benefitValue) {
			return 1;
		}

		return 0;
	});

	const getPaymentAmount = (partialBalance, requestedAmount) => requestedAmount >= partialBalance
		? partialBalance
		: requestedAmount;

	if (subsystemAccountFunds.length > 0) {
		let remainingPayments = appliedStoredValue;

		while (remainingPayments > 0) {
			const wsSubsystemFunds = subsystemAccountFunds.shift();
			const {
				balance,
				...wsSubsystemFundsRest
			} = wsSubsystemFunds;

			const paymentAmount = getPaymentAmount(balance, remainingPayments);

			// if wsSubsystemFunds.balance is 0, 
			// then paymentAmount is zero. Nothing to be applied towards full payment.
			if (paymentAmount > 0) {
				wsSubsystemValuePayments.push(
					new WSSubsystemValuePayment({
						...wsSubsystemFundsRest,
						paymentAmount,
						accountType: ACCOUNT_TYPES.subsystemAccount,
					}),
				);
				remainingPayments -= paymentAmount;
			}
		}
	}

	return wsSubsystemValuePayments;
};
/**
 * Turns selectedWSPayments into an array of WSPayment subclasses
 * @returns {WSPayment[]}
 */
export const getPayments = ({
	cart,
	selectedFundingSources,
	loadProductLineItemFunds,
	splitAmount = 0,
	mobileToken = null,
}) => {
	const wsSubsystemValuePayments = getWSSubsystemValuePayments(cart, loadProductLineItemFunds);

	const amtPaid = wsSubsystemValuePayments.reduce((acc, wsSubsystemValuePayment) => {
		const { paymentAmount } = wsSubsystemValuePayment;
		return acc + paymentAmount;
	}, 0);

	const total = getAmountDue(cart);

	// Mobile payments can't be split with anything else, so if we find one, we're done:
	if (mobileToken) {
		const payment = new WSCreditDebitPayment({
			paymentType: PAYMENT_TYPES.creditCardNotPresent,
			paymentAmount: (total - amtPaid),

			// alessandro's comments about encryptedCrdbToken:
			// https://reflexions.slack.com/archives/GA82SPCTV/p1614722980082500
			encryptedCrdbToken: mobileToken,
		});
		return [ payment ];
	}

	if (cart.appliedStoredValue >= total) {
		return [
			...wsSubsystemValuePayments,
		];
	}

	const selectedWSPayments = selectedFundingSources.map(({ fundingSource, cvv }, index) => {
		const paymentAmount = (splitAmount && index === 0)
			? splitAmount
			: (total - amtPaid - splitAmount);

		return fundingSourceToWSPayment({
			fundingSource,
			cvv,
			paymentAmount,
		});
	});

	// these are all subclasses of WSPayment:
	return [
		...wsSubsystemValuePayments,
		...selectedWSPayments,
	];
};
