import React, { useContext, useEffect, useState } from 'react';
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { GET_ALTERNATES_AND_MANAGED_CUSTOMERS } from "components/data/alternates/Alternates.query.js";
import Modal from "components/Modal.js";
import CmsContentList from "components/data/CmsContentList.js";
import cx from "classnames";
import { gql } from "@apollo/client";
import { values } from "lodash";
import * as modalStyles from "components/Modal.module.css";
import Button, { Primary, Secondary } from "components/Button.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import { useModalContext } from "context/ModalProvider.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import FormHelper from "utils/FormHelper.js";
import RemovedAlternateSuccess from "components/toasts/alternates/RemovedAlternateSuccess.js";
import ResignedAlternateSuccess from "components/toasts/alternates/ResignedAlternateSuccess.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";

const resignManagedCms = {
	title: 'miscText.managed-accounts-remove-modal-header',
	body: 'miscText.managed-accounts-remove-modal-description',
	cancel: 'miscText.managed-accounts-remove-modal-cancel',
	confirm: 'miscText.managed-accounts-remove-modal-submit',
	confirmation: 'miscText.managed-accounts-remove-confirmation',
};

const removeAlternateCms = {
	title: 'miscText.alternates-remove-modal-header',
	body: 'miscText.alternates-remove-modal-description',
	cancel: 'miscText.alternates-remove-modal-cancel',
	confirm: 'miscText.alternates-remove-modal-confirm',
	confirmation: 'miscText.alternates-remove-confirmation',
};

export const DELINK_ALTERNATE = gql`
	mutation delinkAlternate(
		$link: WSAlternateContactLinkedInfoInput!,
		$delinkAlternates: Boolean!,
	) {
		CustomerMutationRoute {
			alternates {
				delinkAlternate(linkedAlternateContacts: [ $link ], delinkAlternates: $delinkAlternates)
			}
		}
	}
`;

const RemoveAlternate = ({ wsAlternateOrManagedCustomerInfo, isAlternate }) => {
	const [ delinkAlternate, { loading } ] = useStandardMutation(DELINK_ALTERNATE, {
		refetchQueries: [
			{ query: GET_ALTERNATES_AND_MANAGED_CUSTOMERS },
		],
	});
	const { setModal } = useModalContext();
	const { setToast } = useGlobalToastsContext();
	const [ error, setError ] = useState(null);

	const wsAlternateContactLinkedInfo = {
		linkId: wsAlternateOrManagedCustomerInfo.linkId,
		linkedCustomerId: !isAlternate
			? wsAlternateOrManagedCustomerInfo.alternateCustomerId
			: wsAlternateOrManagedCustomerInfo.managedCustomerId,
		linkedContactId: !isAlternate
			? wsAlternateOrManagedCustomerInfo.alternateContactId
			: wsAlternateOrManagedCustomerInfo.managedContactId,
	};

	const kickoffSubmit = async () => {
		try {
			await graphqlErrorMiddleware(delinkAlternate({
				variables: {
					link: wsAlternateContactLinkedInfo,
					delinkAlternates: !isAlternate,
				},
			}));
		} catch (error) {
			setError(FormHelper.errorJsx(error.display.topLevelMessage));
			return;
		}

		setModal(null);
		setToast(
			isAlternate
				? <ResignedAlternateSuccess name={wsAlternateOrManagedCustomerInfo.firstName} />
				: <RemovedAlternateSuccess name={wsAlternateOrManagedCustomerInfo.firstName} />
		);
	};

	const cms = isAlternate ? resignManagedCms : removeAlternateCms;

	return (
		<CmsContentList list={values(cms)} sharedVariables={{
			name: wsAlternateOrManagedCustomerInfo.firstName,
			nickname: wsAlternateOrManagedCustomerInfo.firstName,
			firstname: wsAlternateOrManagedCustomerInfo.firstName,
			fullname: wsAlternateOrManagedCustomerInfo.firstName + ' ' + wsAlternateOrManagedCustomerInfo.lastName,
		}}>
			{() => (
				<Modal
					title={<CmsContentRenderer.Span contentKey={cms.title}
						fallbackValue="Remove Alternate or Managed Account" />}
				>
					{error ? <div>{error}</div> : null}

					<CmsContentRenderer contentKey={cms.body} fallbackValue="Are you sure?" />

					<div className={modalStyles.actions}>
						<Button
							type={Secondary}
							onClick={() => setModal(null)}
							overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
							data-qa="RemoveAlternate"
						>
							<CmsContentRenderer.Span contentKey={cms.cancel} fallbackValue="Cancel" />
						</Button>
						<Button
							type={Primary}
							submitting={loading}
							overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
							onClick={kickoffSubmit}
						>
							<CmsContentRenderer.Span contentKey={cms.confirm} fallbackValue="Confirm" />
						</Button>
					</div>
				</Modal>
			)}
		</CmsContentList>
	);
};

export default RemoveAlternate;
