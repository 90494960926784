import React from 'react';

import CmsObject from 'components/data/CmsObject.js';

import * as style from "./HomeBanner.module.css";
import Banner, { TYPE_INFO } from 'components/Banner.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';


const HomeBanner = () =>
	<div className={style.wrapper}>
		<CmsObject contentKey="banners">{({ data }) => {
			const banner = data.cmsObject.obj[ "home-page-banner-1" ];

			return banner
				? <Banner
					showIcon={false}
					type={TYPE_INFO}
					title={banner.title}
					body={
						<CmsContentRenderer.Div
							rawHtml
							content={banner.text}
						/>
					}
					rightSide={banner.button_url && banner.button_text
						? <Button
							isPrimary={false}
							type="button"
							to={banner.button_url}
							target="_blank"
							external
						>
							{banner.button_text}
						</Button>
						: null
					}
					link={banner.cta_text && banner.cta_url
						? <Button
							typeStyle={buttonTypeStylePlain}
							overrideClass={style.plainBtn}
							type="button"
							to={banner.cta_url}
							target="_blank"
							external
						>
							{banner.cta_text}
						</Button>
						: null
					}
				/>
				: null;
		}}</CmsObject>
	</div>;

export default HomeBanner;
