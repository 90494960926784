import { clone } from "lodash";
import {
	ccMonthYearBackoffice,
	mmYyMonth,
	mmYyYear,
} from "../../utils/FormatHelpers.js";
import WSSubsystemAccountTravelTokenDisplay, { WSSubsystemAccountTravelTokenDisplayFragment } from "./WSSubsystemAccountTravelTokenDisplay.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

//  Todo we should be using a factory class.
export default class WSSubsystemAccountBankcardTravelTokenDisplay extends WSSubsystemAccountTravelTokenDisplay {
	constructor({
		maskedPan,
		masterTokenPartial = null,
		cardExpiryMMYY,
		expirationMonth,
		expirationYear,
		creditCardType,

		//super props
		tokenType,
		tokenNickname,
		tokenEnablementFee,

		mobileWalletType,
		mobileWalletDeviceType,
	}) {

		super({
			tokenType,
			tokenNickname,
			tokenEnablementFee,
			mobileWalletType,
			mobileWalletDeviceType,
		});

		this.maskedPan = maskedPan;

		// Todo: We should be implementing super constructor methods
		// sometimes masterTokenPartial is ''
		this.masterTokenPartial = masterTokenPartial ? masterTokenPartial : null;

		// Backoffice returns a single value for expiration date: cardExpiryMMYY
		// break the exp date into 2 fields ( expirationMonth, expirationYear ) and replace cardExpiryMMYY
		if (cardExpiryMMYY) {
			this.expirationMonth = mmYyMonth(cardExpiryMMYY);
			this.expirationYear = mmYyYear(cardExpiryMMYY);
		}
		else {
			this.expirationMonth = expirationMonth;
			this.expirationYear = expirationYear;
		}

		this.creditCardType = creditCardType;
	}

	get data() {
		return this;
	}

	set(field, value) {
		this[ field ] = value;
		return this;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemAccountBankcardTravelTokenDisplay(data);

		return result;
	}

	toBackoffice() {

		return {
			...super.toBackoffice(),
			maskedPan: this.maskedPan,
			creditCardType: this.creditCardType,
			cardExpiryMMYY: ccMonthYearBackoffice(this.expirationMonth, this.expirationYear),
		};
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSSubsystemAccountBankcardTravelTokenDisplayType = [ `
	type WSSubsystemAccountBankcardTravelTokenDisplay implements WSSubsystemAccountTravelTokenDisplayFactory {
		${WSSubsystemAccountTravelTokenDisplayFragment}
		maskedPan: String!
		creditCardType: String!

		# generated by us
		expirationMonth: Int
		expirationYear: Int
	}`,
];
