import React, {
	useState,
	useContext,
	useEffect, useCallback,
} from 'react';
import {
	levels,
	log,
} from "utils/Logger.js";
import {
	reduce,
	concat,
	filter,
	sumBy, first, map,
} from 'lodash';
import PublicAppVars from "utils/PublicAppVars.js";
import { useLocation } from "react-router-dom";
import { OPEN_TRANSIT_REGULAR_CARD } from 'server/api-types/WSSubsystemAccountToken.js';
import WSIssueMediaLoadSubsystemAccountValue from "server/api-types/WSIssueMediaLoadSubsystemAccountValue.js";
import WSIssueMediaLoadSubsystemProduct from "server/api-types/WSIssueMediaLoadSubsystemProduct.js";
import WSSubsystemOperator from "server/api-types/WSSubsystemOperator.js";

export const CartContext = React.createContext([]);
export const useCartContext = () => useContext(CartContext);

// Fare based passes are predetermined balance amounts that appears as passes but these are not passes.
// They are simply an easy way to add balance. So we add Fare based passes to the cart as passes but they are ignored
// where passes are concerned.
// https://reflexions.slack.com/archives/CAT36MFLZ/p1644331420482319
export const amountFromFareBased = (products) => reduce(
	products,
	(accumulator, item) => {
		if (item.isFareBased) {
			return accumulator + item.price;
		}

		return accumulator;
	},
	0,
);

const getWsIssueMediaFullPrice = (wsIssueMedia, loadProducts) => {
	const loadProductsPrice = reduce(loadProducts, (total, wsIssueMediaLoadProduct) => {
		return total + wsIssueMediaLoadProduct.itemPrice;
	}, 0);

	return wsIssueMedia.price + wsIssueMedia.enablementFeeAmount + loadProductsPrice;
};

export const getCartBalance = (cart) => {
	return (cart.balance ?? 0) + (cart.negativeBalance ?? 0) + amountFromFareBased(cart.products);
};

export const getCartProducts = (cart) => cart.products
	? filter(cart.products, product => !product.isFareBased)
	: [];

/**
 *
 * @param {WSTransitAccountProduct} wsTransitAccountProduct
 * @returns {WSIssueMediaLoadSubsystemProduct}
 */
export const mapWSTransitAccountProductToWSIssueMediaLoadSubsystemProduct = (wsTransitAccountProduct) => new WSIssueMediaLoadSubsystemProduct(
	{
		itemPrice: wsTransitAccountProduct.price,
		passSKU: wsTransitAccountProduct.productSku,
		passName: wsTransitAccountProduct.productName,
		startDtm: wsTransitAccountProduct.validityStartDtm,
		endDtm: wsTransitAccountProduct.validityEndDtm,
		permittedUsers: map(wsTransitAccountProduct.permittedUsers, permittedUser => new WSSubsystemOperator(permittedUser)),
	},
);

export const getCartMediaOption = (cart) => {
	if (!cart.mediaOptions?.length) {
		return;
	}

	const loadBalance = getCartBalance(cart);
	const products = getCartProducts(cart);

	const loadProducts = [
		...loadBalance
			? [
				new WSIssueMediaLoadSubsystemAccountValue({
					itemPrice: loadBalance,
				}),
			]
			: [],
		...products
			? map(products, mapWSTransitAccountProductToWSIssueMediaLoadSubsystemProduct)
			: [],
	];

	const wsIssueMedia = first(cart.mediaOptions);

	return {
		...first(cart.mediaOptions),
		itemTotalAmount: getWsIssueMediaFullPrice(wsIssueMedia, loadProducts),
		loadProducts: map(loadProducts, (wsIssueMediaLoadProduct) => wsIssueMediaLoadProduct.toInputWSIssueMediaLoadProductFactory()),
	};
};

const mediaOptions = 'mediaOptions';
const products = 'products';

/**
 * Get the price of an item. Takes item quantity into account.
 *
 * @param {object} item
 *
 * @returns int
 */
const getProductCost = ({ price, quantity }) => (price * quantity);

/**
 * Get the discount of an item. Takes item quantity into account.
 *
 * @param {object} item
 *
 * @returns int
 */
const getProductDiscounts = ({ discount, quantity }) => discount
	? (discount * quantity)
	: 0;

/**
 * Get the fees of an item
 *
 * @param {object} item
 *
 * @returns int
 */
const getProductFees = ({ enablementFeeAmount, quantity }) => enablementFeeAmount
	? (enablementFeeAmount * quantity)
	: 0;

/**
 * Get the cost of items before any discounts are applied
 *
 * @param {object} cart
 *
 * @param includeMediaQuantity
 * @returns int
 */
export const getCartSubtotal = (cart, includeMediaQuantity = false) => {
	const mediaOption = getCartMediaOption(cart);

	if (mediaOption) {
		return mediaOption.itemTotalAmount * (includeMediaQuantity ? mediaOption.quantity : 1);
	}

	const productsTotal = sumBy(
		concat(getCartProducts(cart)),
		(item) => getProductCost(item) + getProductFees(item),
	);

	return productsTotal + getCartBalance(cart) + (cart.unpaidFee ?? 0);
};

export const getDiscounts = (cart) => {
	return sumBy(concat(getCartProducts(cart)), item => getProductDiscounts(item));
};

export const getAmountDue = (cart) => {
	const subTotal = getCartSubtotal(cart, true);

	const discounts = getDiscounts(cart);

	return subTotal + discounts;
};

/**
 * Get the total cost of items in the cart, with any stored value applied
 *
 * @param {object} cart
 *
 * @returns int
 */
export const getCartTotal = (cart) => {
	const amountDue = getAmountDue(cart);

	return amountDue - cart.appliedStoredValue;
};


const initialCart = {
	balance: 0,
	appliedStoredValue: 0,
	negativeBalance: 0,
	products: [],
	mediaOptions: [],
	unpaidFee: 0,
	shippingDetails: {
		shippingAddress: null,
		shippingName: null,
	},
	shoppingCartId: null,
	promoCodes: null,
	purchaseConfirmationInfo: null,
};

export const checkoutFlowPathRegex = new RegExp("^/account/cards/\\d+/purchase-product/.*$");
export const checkoutFlowPathRegexBalance = new RegExp("^/account/cards/\\d+/reload-balance/.*$");
export const checkoutFlowPathRegexUpgrade = new RegExp("^/account/cards/\\d+/upgrade/.*$");
export const checkoutFlowPathRegexReplace = new RegExp("^/account/cards/\\d+/\\d+/freeze-replace/\?.*$");


const CartProvider = ({
	children,
}) => {
	const location = useLocation();
	const [ cart, setCart ] = useState(initialCart);

	// clear the cart if the user leaves the checkout flow
	useEffect(
		() => {
			const insideCheckoutFlow = (
				location.pathname.match(checkoutFlowPathRegex)
				|| location.pathname.match(checkoutFlowPathRegexBalance)
				|| location.pathname.match(checkoutFlowPathRegexUpgrade)
				|| location.pathname.match(checkoutFlowPathRegexReplace)
			);
			if (insideCheckoutFlow && Boolean(location.state?.purchase)) {
				// save negative balance and unpaidFee when user checkout from the purchase flow, but remains on the same TA
				setCart(prevCart => ({
					...initialCart,
					negativeBalance: prevCart.negativeBalance ?? 0,
					unpaidFee: prevCart.unpaidFee ?? 0,
				}));
			} else if (!insideCheckoutFlow && !Boolean(location.state?.purchase)) {
				// left checkout flow. Clear cart
				setCart(initialCart);
			}
		},
		[
			location.pathname,
			location.state,
		],
	);

	/**
	 * Add a product to the cart
	 *
	 * @param {object} product
	 * @param {integer} quantity
	 */
	const addProduct = useCallback((product, quantity = 1) => {
		const productType = product?.mediaType === OPEN_TRANSIT_REGULAR_CARD
			? mediaOptions
			: products;

		const filtered = filter(cart[ productType ], wsTransitAccountProduct => wsTransitAccountProduct.id !== product.id);

		setCart(prevCart => ({ ...prevCart, [ productType ]: concat(filtered, { ...product, quantity }) }));
	}, [ cart ]);

	/**
	 * Remove a product from the cart based on its id
	 *
	 * @param {integer} productId
	 */
	const removeProduct = useCallback((productId) => {
		setCart(prevCart => ({
			...prevCart,
			products: filter(cart.products, wsTransitAccountProduct => wsTransitAccountProduct.id !== productId),
		}));
	}, [ cart.products ]);

	/**
	 * Remove a fare based product from the cart based on isFareBased flag
	 *
	 */
	const removeFareBasedProduct = useCallback(() => {
		setCart(prevCart => ({
			...prevCart,
			products: filter(cart.products, product => !product.isFareBased),
		}));
	}, [ cart.products ]);

	/**
	 * Update the balance with a new amount, can overwrite the previous balance
	 *
	 * @param {integer} amount
	 * @param {boolean} overwrite
	 */
	const addBalance = useCallback((amount, overwrite = true) => {

		if (typeof amount !== 'number') {
			log(null, levels.error, { message: "Amount was not a number", amount });
			return;
		}

		setCart(prevCart => ({
			...prevCart,
			balance: overwrite === true
				? amount
				: (prevCart.balance + amount),
		}));
	}, []);

	/**
	 * Add the unpaid fee to the cart, can overwrite the previous fee
	 *
	 * @param {integer} amount
	 * @param {boolean} overwrite
	 */
	const addUnpaidFee = useCallback((amount, overwrite = true) => {
		if (typeof amount !== 'number') {
			log(null, levels.error, { message: "Amount was not a number", amount });
			return;
		}

		setCart(prevCart => ({
			...prevCart,
			unpaidFee: overwrite === true
				? amount
				: (prevCart.unpaidFee + amount),
		}));
	}, []);

	/**
	 * Allows storing the absolute amount for the negative balance in the cart
	 * can overwrite the previous balance
	 *
	 * @param {integer} amount
	 * @param {boolean} overwrite
	 */
	const setNegativeBalance = useCallback((amount, overwrite = false) => {
		const absoluteAmount = Math.abs(amount);

		setCart(prevCart => ({
			...prevCart,
			negativeBalance: overwrite === true
				? absoluteAmount
				: (prevCart.negativeBalance + absoluteAmount),
		}));
	}, []);


	/**
	 * Allows storing the sale order confirmation info
	 *
	 * @param {Object} purchaseConfirmationInfo
	 * @param {string} purchaseConfirmationInfo.orderId
	 * @param {string} purchaseConfirmationInfo.responseCode
	 * @param {string} purchaseConfirmationInfo.paymentRefNbr
	 * @param {string} purchaseConfirmationInfo.authRefNbr
	 * @param {date} purchaseConfirmationInfo.authDateTime
	 */
	const setPurchaseConfirmationInfo = useCallback((purchaseConfirmationInfo) => {
		setCart(prevCart => ({
			...prevCart, purchaseConfirmationInfo,
		}));
	}, []);

	/**
	 * Store shopping cart id into local context
	 *
	 * @param {integer} shoppingCartId
	 */
	const saveShoppingCartId = useCallback((shoppingCartId) => {
		setCart(prevCart => ({ ...prevCart, shoppingCartId }));
	}, []);

	const applyStoredValue = useCallback((amount) => {
		setCart(prevCart => ({ ...prevCart, appliedStoredValue: amount }));
	}, []);

	const setShippingDetails = useCallback(({
		shippingName,
		shippingAddress,
	}) => {
		const newShippingDetails = {
			shippingName,
			shippingAddress,
		};

		setCart(prevCart => ({ ...prevCart, shippingDetails: newShippingDetails }));
	}, []);

	const contextValue = {
		cart,
		addProduct,
		removeProduct,
		removeFareBasedProduct,
		addBalance,
		addUnpaidFee,
		saveShoppingCartId,
		setNegativeBalance,
		setPurchaseConfirmationInfo,
		applyStoredValue,
		setShippingDetails,
	};

	if (PublicAppVars.LOG_FUNDING_SOURCES_CONTEXT_CHANGES) {
		console.log(contextValue);
	}

	return (
		<CartContext.Provider
			value={contextValue}
		>
			{children}
		</CartContext.Provider>
	);
};

export default CartProvider;
