import React from 'react';
import AlternatePendingInvite from "components/banners/alternates/AlternatePendingInvite.js";
import { IsAlternate, IsNotAlternate } from "components/data/alternates/SessionAlternate.js";
import AlternateManagingAccount from "components/banners/alternates/AlternateManagingAccount.js";

const AlternateInfo = () => (
	<>
		<IsNotAlternate>
			<AlternatePendingInvite />
		</IsNotAlternate>
		<IsAlternate>
			<AlternateManagingAccount />
		</IsAlternate>
	</>
);

export default AlternateInfo;
