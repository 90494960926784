import React, { Component } from 'react';
import HttpStatus from '../components/HttpStatus';

import * as style from './NotFound.module.css';

class NotFound extends Component {

	render() {
		return (
			<HttpStatus code={404}>
				<div className={style.errorPage}>
					<h1 className={style.errorTitle}>404 page not found</h1>
					<p>The page you are looking for does not exist.</p>
				</div>
			</HttpStatus>
		);
	}
}

export default NotFound;
