import { gql } from "@apollo/client";
import React from 'react';
import PropTypes from "prop-types";
import StdQuery from "./StdQuery.js";
import { LanguageGet } from "./Language.js";

export const GET_OBJECT = gql`
	query getObject (
		$contentKey: String!
		$language: String!
	){
		cmsObject(
			contentKey: $contentKey
			language: $language
		) {
			id
			obj
		}
	}
`;

const CmsObject = ({ children, contentKey }) => (
	<LanguageGet.Consumer>{language => (
		<StdQuery query={GET_OBJECT} variables={{ contentKey, language }}>
			{({ data }) => {
				const { cmsObject } = data;

				const origObjectString = cmsObject.obj;
				const newCmsObject = {
					...cmsObject,
					obj: typeof origObjectString === 'string'
						? JSON.parse(origObjectString)
						: data.cmsObject.obj,
					origObjectString: data.cmsObject.obj,
				};
				const newData = {
					...data,
					cmsObject: newCmsObject,
				};

				const newArgs = {
					data: newData,
				};

				return children(newArgs);
			}}
		</StdQuery>
	)}</LanguageGet.Consumer>
);
CmsObject.propTypes = {
	contentKey: PropTypes.string.isRequired,
};

export default CmsObject;
