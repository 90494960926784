import React from 'react';
import { get } from 'lodash';
import { Redirect } from "react-router-dom";
import StandardQuery from '@reflexions/standardquery';

import {
	levels,
	log,
	noticeError,
} from "utils/Logger.js";
import PublicAppVars from '../../utils/PublicAppVars.js';

import LoadingIcon, { SIZES } from 'components/icons/LoadingIcon.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';



/**
 * In StdQuery, all render functions (children, loading, and error) all get the same props that Query
 * is passing to us. That way the StdQuery's parent has full control over rendering. E.g. to ignore
 * all errors, the parent could pass the same render function to our error prop as to children.
 * <StdQuery query=... error={renderMethod}>{renderMethod}</StdQuery>
 *
 * The error render function is passed an additional arg, errorType, to distinguish the different error
 * types
 */

const cms = {
	graphQLError: "miscText.graphQL-api-error-generic",
};

function StdQuery(props) {
	return (
		<StandardQuery
			loading={() => <LoadingIcon size={props.spinnerSize ?? SIZES.component} />}
			renderErrorHandler={renderErrorHandler}
			blankErrorHandler={defaultErrorHandler}
			serverErrorHandler={defaultErrorHandler}
			errorsToScreen={PublicAppVars.ERRORS_TO_SCREEN}
			{...props}
		/>
	);
}

export function maskedError(errorHandlerResult) {
	if (PublicAppVars.ERRORS_TO_SCREEN) {
		return errorHandlerResult;
	}

	return (
		<CmsContentRenderedInline
			contentKey={cms.graphQLError}
			fallbackValue="Oops, something went wrong. It should be fixed shortly, but in the meantime, you can try refreshing your screen."
		/>
	);
}

function renderErrorHandler(props) {
	const { renderError } = props;

	noticeError(null, levels.error, renderError, {
		renderError,
		...getMessageArgs(props, renderError),
		...getFindInLogArgs(props),
	});

	return maskedError(renderError);
}

function defaultErrorHandler(props) {
	const { error } = props;

	// ERROR_TYPE_SERVER and ERROR_TYPE_BLANK don't have a useful backtrace here, so do a normal log
	// ERROR_TYPE_SERVER should've hopefully already logged something more useful if the error
	// was caught by the server, but maybe we're client-side encountering a timeout, so we'll still log
	log(null, levels.error, {
		error,
		...getMessageArgs(props, error),
		...getFindInLogArgs(props),
	});

	if (get(error, 'TopLevelKey') === 'miscText.error-general-auth-required') {
		log(null, levels.error, "query failed due to permissions. Sending user home.");
		return <Redirect to="/" />;
	}

	return maskedError(error);
}

function getMessageArgs(props, relevantError) {
	const { errorType, queryName } = props;

	return {
		message: `StdQuery: ${queryName} ${errorType} error ${relevantError?.message}`,
	};
}

function getFindInLogArgs({ queryName, props: { name, variables } }) {
	return {
		name,
		// In case this element doesn't have a name, log a bunch of other stuff to make it easier to
		// find out which element is erroring
		queryName,
		variables: variables || {},
	};
}

export default StdQuery;
