import React from "react";
import PropTypes from "prop-types";
import { gql } from "@apollo/client";
import { pickBy } from 'lodash';

import StdQuery from "../StdQuery.js";
import useStdQuery from 'components/data/hooks/useStdQuery.js';

import { SIZES } from "components/icons/LoadingIcon.js";



export const GET_LOCATIONS_BO_QUERY = `
query getLocationsBO (
	$subsystemAccountReference: ID
	$operator: String
	$route: String
	$svcType: String
	$zone: String
	$direction: String
	$sector: String
	$travelMode: String
	$sortBy: String
	$offset: Int
	$limit: Int
	$tapCreationExclusions: String
	$tapCorrectionExclusions: String
	$stopPoint: String
) {
	getLocationsBO (
		subsystemAccountReference: $subsystemAccountReference
		operator: $operator
		route: $route
		svcType: $svcType
		zone: $zone
		direction: $direction
		sector: $sector
		travelMode: $travelMode
		sortBy: $sortBy
		offset: $offset
		limit: $limit
		tapCreationExclusions: $tapCreationExclusions
		tapCorrectionExclusions: $tapCorrectionExclusions
		stopPoint: $stopPoint
	){
		locations {
			operator
			operatorName
			stopPoint
			stopPointDesc
			routeNumber
			routeDesc
			zone
			zoneDesc
			direction
			directionDesc
			sector
			sectorDesc
			stopPointExternalReference
		}
	}
}
`;

// Backoffice locations
export const GET_LOCATIONS_BO = gql`${GET_LOCATIONS_BO_QUERY}`;

// TODO cms query inherited from Bribane, handle it if needs to Boston or remove
export const GET_LOCATIONS_CMS_QUERY = `
query getLocationsCMS(
	$language: String
) {
	locations(language: $language) {
		id
		title
		lat
		lng
		operator
		sector
		stopPoint
		stopPointDesc
		zone
	}
}
`;

// CMS Locations
export const GET_LOCATIONS_CMS = gql`${GET_LOCATIONS_CMS_QUERY}`;


export const useBOLocations = ({
	subsystemAccountReference,
	operator,
	route,
	svcType,
	zone,
	direction,
	sector,
	travelMode,
	sortBy,
	offset,
	limit,
	tapCreationExclusions,
	tapCorrectionExclusions,
	stopPoint,
}) => {
	// Pick Only the non null
	const variables = pickBy({
		subsystemAccountReference,
		operator,
		route,
		svcType,
		zone,
		direction,
		sector,
		travelMode,
		sortBy,
		offset,
		limit,
		tapCreationExclusions,
		tapCorrectionExclusions,
		stopPoint,
	});

	const { data, ...rest } = useStdQuery(GET_LOCATIONS_BO, { variables });

	return {
		data: data?.getLocationsBO?.locations,
		...rest,
	};
};

const LocationQuery = ({
	children = "",
	subsystemAccountReference,
	operator = "",
	route = "",
	svcType = "",
	zone = "",
	direction = "",
	sector = "",
	travelMode = "",
	sortBy = "",
	offset = null,
	limit = null,
	tapCreationExclusions,
	tapCorrectionExclusions,
	stopPoint = "",
}) => (
	<StdQuery query={GET_LOCATIONS_BO}
		variables={{
			subsystemAccountReference,
			operator,
			route,
			svcType,
			zone,
			direction,
			sector,
			travelMode,
			sortBy,
			offset,
			limit,
			tapCreationExclusions,
			tapCorrectionExclusions,
			stopPoint,
		}}
		fetchPolicy={"cache-and-network"}
		spinnerSize={SIZES.component}
	>
		{(queryResult) => {

			const { getLocationsBO } = queryResult.data;

			return children(getLocationsBO, queryResult);
		}}
	</StdQuery>
);

LocationQuery.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.element,
		PropTypes.func,
	]).isRequired,
	subsystemAccountReference: PropTypes.string,
	operator: PropTypes.string,
	route: PropTypes.string,
	svcType: PropTypes.string,
	zone: PropTypes.string,
	direction: PropTypes.string,
	sector: PropTypes.string,
	travelMode: PropTypes.string,
	sortBy: PropTypes.string,
	offset: PropTypes.number,
	limit: PropTypes.number,
	tapCreationExclusions: PropTypes.string,
	tapCorrectionExclusions: PropTypes.string,
	stopPoint: PropTypes.string,
};

export default LocationQuery;
