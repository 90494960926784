import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import cx from 'classnames';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Modal from 'components/Modal.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import {
	GooglePlay,
	AppStore,
} from 'components/Icon.js';

import * as style from 'components/Modal.module.css';




const cms = {
	title: 'miscText.dashboard-addcard-mobile-app-header',
	desc: 'miscText.dashboard-addcard-mobile-app-description',
	learnMore: 'miscText.dashboard-addcard-mobile-app-cta',
	ctaURL: 'miscText.dashboard-addcard-mobile-app-url',
};

const CreateMobileCharlieCard = ({ }) => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Download the MBTA Charlie App" />}>
				<>
					<CmsContentRenderer.P
						contentKey={cms.desc}
						className={style.text}
						fallbackValue="You can download the MBTA Charlie app and use your NFC-enabled mobile device to enter the system using a Charlie Card on your mobile device."
					/>

					<Button external
						typeStyle={buttonTypeStylePlain}
						to={cmsContent[ cms.ctaURL ] ?? '#'}
						target="_blank"
						rel="noreferrer"
					>
						<CmsContentRenderer.Span
							contentKey={cms.learnMore}
							fallbackValue="Learn more about using Charlie Cards on your mobile device"
						/>
					</Button>

					<div className={style.appIconWrapper}>
						<GooglePlay
							className={cx(style.googleLink, style.googlePlayIcon)}
						/>
						<AppStore
							className={cx(style.appleLink, style.appStoreIcon)}
						/>
					</div>
				</>
			</Modal>
		)}</CmsContentList>
	);
};

CreateMobileCharlieCard.propTypes = {

};

export default CreateMobileCharlieCard;
