import { WSPaymentFragment } from "server/api-types/WSPayment.js";

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

// not in WSPaymentFactory.js to avoid circular dependencies
// (the factory has to include the child class, so it can't also include WSPaymentFragment from the parent WSPayment class)

const WSPaymentFactoryInterface = [ `
	interface WSPaymentFactory {
		${WSPaymentFragment}
	}`,
];
export default WSPaymentFactoryInterface;
