import cx from 'classnames';
import { useApolloClient } from "@apollo/client";
import React from 'react';
import { values, includes } from 'lodash';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { DELETE_FUNDING_SOURCE, FUNDING_SOURCES_GET_QUERY } from 'graphql-queries/FundingSources.js';
import PreventDefault from "utils/PreventDefault.js";
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { useModalContext } from 'context/ModalProvider.js';

import Modal from 'components/Modal.js';
import Button, {
	Primary,
	Secondary,
} from 'components/Button';
import Toast from "components/Toast.js";

import * as style from 'components/modals/ResumeUseCard.module.css';
import * as modalStyles from 'components/Modal.module.css';

const cms = {
	header: "miscText.payments-info-manage-delete-header",
	body: "miscText.payments-info-manage-delete-details",
	submit: "miscText.payments-info-manage-delete-submit",
	cancel: "miscText.payments-info-manage-delete-cancel",
};

const DeletePaymentMethodConfirmation = ({ wsFundingSourceInfo }) => {
	const { setModal } = useModalContext();
	const { setToast, removeToast } = useGlobalToastsContext();
	const apolloClient = useApolloClient();
	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({});

	const nickname = wsFundingSourceInfo?.nickname
		? wsFundingSourceInfo.nickname
		: `${wsFundingSourceInfo.creditCard.creditCardType} ...${wsFundingSourceInfo.creditCard.maskedPan}`;

	const kickoffSubmit = async () => {
		setSubmitting(true);
		try {
			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: DELETE_FUNDING_SOURCE,
					refetchQueries: [ { query: FUNDING_SOURCES_GET_QUERY } ],
					awaitRefetchQueries: true,
					variables: {
						fundingSourceId: wsFundingSourceInfo?.fundingSourceId,
					},
				}));
		} catch (errorReport) {

			noticeError(null, levels.info, errorReport, 'delete funding source error');
			formHelper.validationErrorHandler(errorReport);
			// errors.Manage Payment Information
			if (includes(errorReport.TopLevelKey, 'errors.Manage Payment Information')) {
				setModal(null);
				setToast(
					<Toast
						type="error"
						title={formHelper.getFieldErrorJsx('')}
						onClosed={removeToast}
					/>
				);
			}
		} finally {
			setSubmitting(false);
		}

		// post to delete payment method succeeded, close modal
		setModal(null);
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal title={
				<CmsContentRenderer
					contentKey={cms.header}
					fallbackValue={"Delete payment method?"}
				/>
			}>
				<form
					method="post"
					onSubmit={PreventDefault(kickoffSubmit)}
					ref={formRef}
				>
					<CmsContentRendered.P
						className={style.boldStyle}
						contentKey={cms.body}
						fallbackValue={`Are you sure you want to delete the following payment method: ${nickname}?`}
						variables={{ nickname }}
					/>
					{formHelper.getFieldErrorJsx('')}
					<div className={modalStyles.actionButtons}>
						<Button
							typeStyle={Primary}
							disabled={submitting}
							overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
						>
							<CmsContentRenderer.Span contentKey={cms.submit}
								fallbackValue="Delete now"
							/>
						</Button>
						<Button
							typeStyle={Secondary}
							onClick={() => setModal(null)}
							overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
						>
							<CmsContentRenderer.Span contentKey={cms.cancel}
								fallbackValue="Cancel"
							/>
						</Button>
					</div>
				</form>
			</Modal>
		)}</CmsContentList>
	);
};

export default DeletePaymentMethodConfirmation;
