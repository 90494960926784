// extracted by mini-css-extract-plugin
export var container = "layouts-components-__CharlieDropdown-module___container";
export var heading = "layouts-components-__CharlieDropdown-module___heading";
export var i18n = "layouts-components-__CharlieDropdown-module___i18n";
export var iconAvatar = "layouts-components-__CharlieDropdown-module___iconAvatar";
export var iconCard = "layouts-components-__CharlieDropdown-module___iconCard";
export var iconGlobe = "layouts-components-__CharlieDropdown-module___iconGlobe";
export var iconStar = "layouts-components-__CharlieDropdown-module___iconStar";
export var isActive = "layouts-components-__CharlieDropdown-module___isActive";
export var isOpen = "layouts-components-__CharlieDropdown-module___isOpen";
export var link = "layouts-components-__CharlieDropdown-module___link";
export var linkCards = "layouts-components-__CharlieDropdown-module___linkCards";
export var logoutLink = "layouts-components-__CharlieDropdown-module___logoutLink";
export var option = "layouts-components-__CharlieDropdown-module___option";
export var optionGroup = "layouts-components-__CharlieDropdown-module___optionGroup";
export var optionIcon = "layouts-components-__CharlieDropdown-module___optionIcon";
export var options = "layouts-components-__CharlieDropdown-module___options";
export var overlay = "layouts-components-__CharlieDropdown-module___overlay";
export var section = "layouts-components-__CharlieDropdown-module___section";
export var toggle = "layouts-components-__CharlieDropdown-module___toggle";
export var toggleArrow = "layouts-components-__CharlieDropdown-module___toggleArrow";
export var toggleIcon = "layouts-components-__CharlieDropdown-module___toggleIcon";
export var toggleText = "layouts-components-__CharlieDropdown-module___toggleText";