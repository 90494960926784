import { clone } from "lodash";

// WSCreditCardReference
// This is a subclass of WSCreditCard. This is a container object that represents a credit card reference.
// A credit card reference contains a token to the credit card repository.  The token can be used to charge the
// credit card that is associated with that token.  This keeps the client application from having to store the
// credit card number in its own database.  By centralizing the credit card numbers, it makes the system easier
// to achieve PCI compliance.

export default class WSCreditCardReference {
	constructor ({
		pgCardId,
		maskedPan,
		cardExpiryMMYY,
		nameOnCard = null,
		creditCardType,
	}) {

		// string(40)
		// (Required)  The unique card ID, assigned by the tokenization system.
		this.pgCardId = pgCardId;

		// string(20)
		// (Required)  The masked PAN.  The masking format is project specific, but is commonly
		// just the last 4 digits of the PAN.
		this.maskedPan = maskedPan;

		// string(4)
		// (Required)  The card expiry date in MMYY format. For MM, 01 = January.
		this.cardExpiryMMYY = cardExpiryMMYY;

		// string(60)
		// (Required)  Full name of credit card owner as it appears on credit card.

		// The documentation suggests this is a required but we were instructed per Sana
		// to make nameOnCard optional.
		this.nameOnCard = nameOnCard;

		// string(30)
		// (Required)  The type of credit card (Such as Visa, Mastercard, etc)
		this.creditCardType = creditCardType;
	}

	static fromBackoffice(data) {
		const result = new WSCreditCardReference(data);
		return result;
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSCreditCardReference(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

const WSCreditCardReferenceFragment = `
	pgCardId: String!
	maskedPan: String!
	cardExpiryMMYY: String!
	nameOnCard: String
	creditCardType: String!
`;

export const InputWSCreditCardReferenceType = [ `
	input InputWSCreditCardReference {
		${WSCreditCardReferenceFragment}
	}
` ];

export const WSCreditCardReferenceType = [ `
	type WSCreditCardReference {
		${WSCreditCardReferenceFragment}
	}
` ];
