import { gql } from "@apollo/client";
import React, { useState } from 'react';
import {
	Redirect,
	useRouteMatch,
} from 'react-router-dom';
import cx from 'classnames';
import { get } from "lodash";
import { usePostSigninGoToPath } from "components/data/session-user/PostSigninGoToPath.js";

import { stringify } from "qs";

import * as flatStyle from 'layouts/FlatWhiteLayout.module.css';
import * as tosStyle from './EulaById.module.css';
import EulaData from 'components/data/EulaData.js';
import Button from 'components/Button.js';
import { dateTimeToAbbreviatedMonthDayYear } from 'utils/FormatHelpers.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import { LanguageGet } from 'components/data/Language.js';
import { LogoutContext } from 'components/auth/Logout.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import StandardMutation from 'components/data/StandardMutation.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import {
	LOGGED_IN_QUERY,
	LoginStageData,

} from "components/data/session-user/LoggingIn.js";
import loginStages from "components/data/session-user/LoginStages.js";
import NotFound from "pages/NotFound.js";
import HidePypestreamChatbot from "components/pypestream-chatbot/HidePypestreamChatbot.js";

const cms = {};

export const ACCEPT_EULA = gql`
	mutation acceptEula (
		$eulaId: Int!
	) {
		eulas {
			acceptEula(
				eulaId: $eulaId
			)
		}
	}
`;

const EulaAcceptButton = ({
	feature,
	actionAccept,
	...buttonProps
}) => {
	return (
		<Button
			{...buttonProps}
			additionalClassNames={tosStyle.actionBtn}
			onClick={(event) => actionAccept(event)}
		>
			{feature === PublicAppVars.EULA_TOS
				? <CmsContentRenderer.Div contentKey={cms.tosAcceptButton} fallbackValue="I Accept the Terms & Conditions" />
				: feature === PublicAppVars.EULA_PP
					? <CmsContentRenderer.Div contentKey={cms.privacyAcceptButton} />
					: <CmsContentRenderer.Div contentKey={cms.otherAcceptButton} />
			}
		</Button>
	);
};

const EulaDeclineButton = ({
	actionCancel,
	...buttonProps
}) => {
	return (
		<Button
			type="button"
			additionalClassNames={cx(tosStyle.actionBtn, tosStyle.declineToS)}
			onClick={(event) => actionCancel(event)}
			{...buttonProps}
		>
			<CmsContentRenderer.Span contentKey={cms.declineButton} fallbackValue="I Do Not Accept" />
		</Button>
	);

};

// TODO: waiting on cubic's Jon Wandke to set up Eula
// https://reflexions.slack.com/archives/CCF68M49M/p1605035926203300
const EulaById = ({
	eulaId: eulaIdOverride, // for rendering the eula page on a url that doesn't have a eula_id path parameter match
	featureReq, // if specified, we 404 if the eula doesn't match this feature. We 404 regardless if eula not found.
}) => {
	const match = useRouteMatch();
	const postSigninGoToPath = usePostSigninGoToPath();

	const [ redirect, setRedirect ] = useState(null);

	const eulaId = eulaIdOverride ?? parseInt(match?.params?.eula_id ?? '0', 10);

	const actionAccept = async (mutation) => {
		await graphqlErrorMiddleware(
			mutation(),
		);

		setRedirect(<Redirect push to={postSigninGoToPath} />);
	};

	const actionCancel = (event, logoutAndClearCache) => {
		event.preventDefault();
		// setRedirect before logoutAndClearCache, because for some reason we don't return from logoutAndClearCache
		setRedirect(<Redirect push to={'/'} />);
		logoutAndClearCache();
	};

	if (redirect) {
		return redirect;
	}

	return (
		<LoginStageData>{(loginStage, { data }) => {
			const loginNeedsEula = loginStage === loginStages.needsEula;
			const loginAnonymous = loginStage === loginStages.anonymous;
			const redirectPath = get(location, 'state.redirectPath', "");
			const pendingEulasList = get(data, 'session.pendingEulaIds', []);
			const isEulaPending = pendingEulasList.includes(eulaId);

			return (
				<EulaData
					eulaId={eulaId}
					returnDocument={true}
				>{({
						info: {
							name,
							feature,
							noticeDate,
						},
						document: {
							document,
						},
					}) => {

						if (featureReq && feature !== featureReq) {
							return <NotFound />;
						}

						return (<>
							<div className={flatStyle.background}>
								<div className={flatStyle.headerBackground}>
									<div className={flatStyle.header}>
										<div className={flatStyle.pageHeader}>
											<h1 className={flatStyle.title}
												data-qa="EulaHeader">
												{name}
											</h1>
											<LanguageGet.Consumer>{language => (
												<h2 className={flatStyle.subHeader}
													data-qa="FlatWhiteSubHeader">
													<CmsContentRenderer.Span
														contentKey={cms.revised} /> {dateTimeToAbbreviatedMonthDayYear(language, noticeDate)}
												</h2>
											)}
											</LanguageGet.Consumer>
										</div>
									</div>
								</div>
								<div className={flatStyle.bodyContainer}>
									<div dangerouslySetInnerHTML={{ __html: document }} />
								</div>
							</div>

							{redirectPath && loginAnonymous
								? <div className={tosStyle.actionFooter}>
									<HidePypestreamChatbot />

									<EulaAcceptButton
										{...{ feature }}
										actionAccept={() => {
											setRedirect(
												<Redirect push to={{
													...redirectPath,
													search: stringify({ eulaAccepted: true, ...redirectPath.search }, {
														addQueryPrefix: true,
														skipNulls: true,
													}),
												}} />,
											);
										}}
									/>
									<EulaDeclineButton
										actionCancel={() => {
											setRedirect(<Redirect push to={redirectPath} />);
										}}
									/>
								</div>
								: null}
							{loginNeedsEula && isEulaPending
								? <LogoutContext.Consumer>{logoutAndClearCache => (
									<div className={tosStyle.actionFooter}>
										<HidePypestreamChatbot />

										{feature === PublicAppVars.EULA_TOS
											? <CmsContentRenderer.Div contentKey={cms.tosActionBody} />
											: feature === PublicAppVars.EULA_PP
												? <CmsContentRenderer.Div contentKey={cms.privacyActionBody} />
												: <CmsContentRenderer.Div contentKey={cms.otherActionBody} />
										}

										<StandardMutation
											mutation={ACCEPT_EULA}
											variables={{
												eulaId,
											}}
											refetchQueries={() => [ { query: LOGGED_IN_QUERY } ]}
											// awaitRefetchQueries so that we can redirect after LOGGED_IN_QUERY loads
											awaitRefetchQueries={true}
											errorChildren={null}
											renderErrorChildren={null}
										>
											{(acceptEula) => {
												return (
													<EulaAcceptButton
														{...{ feature }}
														actionAccept={() => actionAccept(acceptEula)}
													/>
												);
											}}
										</StandardMutation>
										<EulaDeclineButton
											actionCancel={(event) => actionCancel(event, logoutAndClearCache)}
										/>
									</div>
								)}</LogoutContext.Consumer>
								: null}
						</>);
					}}</EulaData>
			);}}</LoginStageData>
	);
};

export default EulaById;
