import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noticeError, levels } from '../utils/Logger';

import HttpStatus from './HttpStatus';

class ErrorBoundary extends Component {
	static propTypes = {
		http_500: PropTypes.bool,
	};

	static defaultProps = {
		http_500: false,
	};

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// log the error to an error reporting service
		noticeError(null, levels.error, error, info);
	}

	render() {
		if (this.state.hasError) {
			if (this.props.http_500) {
				return (
					<HttpStatus code={500}>
						<h1>Something went wrong.</h1>
					</HttpStatus>
				);
			}
			else {
				return <h1>Something went wrong.</h1>;
			}
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
