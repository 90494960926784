import React from 'react';
import PropTypes from 'prop-types';
import GenericIconAndMaskedValue from 'components/card-icons/GenericIconAndMaskedValue';
import { CARD_TYPE_CHARLIE } from 'utils/Constants';
import { getLast4Digits } from 'components/card-icons/util.js';
import { ReMapOpenTransitName } from 'components/account/card/activate-card/ActivateCard.js';
import { useCmsContentList } from "components/data/CmsContentList.js";
import {
	values,
} from 'lodash';
import { charlieCardCmskeys } from "./CharlieCardCmskeys.js";

export const Last4Digits = ({
	tokenInfo: wsSubsystemAccountTravelTokenDisplay,
}) => {

	const last4Digits = getLast4Digits(wsSubsystemAccountTravelTokenDisplay);
	return (
		<span>{last4Digits}</span>
	);
};

const cms = {
	...charlieCardCmskeys,
};

const TokenNameAndPan = ({
	tokenInfo: wsSubsystemAccountTravelTokenDisplay,
	additionalClassNames = "",
	// div supports any child content, so it gives flexibility
	elementType: ElementType = "span",
	showName = true,
	mediaType = null,
	...rest
}) => {

	const cmsContentList = useCmsContentList({
		list: Object.values(cms),
	});


	if (!wsSubsystemAccountTravelTokenDisplay) {
		return <></>;
	}

	const {
		creditCardType,
		tokenType,
		tokenNickname,
	} = wsSubsystemAccountTravelTokenDisplay;

	const nickname = ReMapOpenTransitName({
		tokenType: mediaType,
		cmsContent: cmsContentList.cmsContent,
		tokenNickname: tokenNickname,
	});

	return (
		<ElementType {...rest}>
			<GenericIconAndMaskedValue
				showName={showName}
				creditCardType={tokenType === CARD_TYPE_CHARLIE ? CARD_TYPE_CHARLIE : creditCardType}
				{...{ nickname }}
				{...wsSubsystemAccountTravelTokenDisplay}
			/>
		</ElementType>
	);
};

TokenNameAndPan.propTypes = {
	additionalClassNames: PropTypes.string,
	tokenInfo: PropTypes.shape({
		tokenNickname: PropTypes.string,
		tokenType: PropTypes.string,
		creditCardType: PropTypes.string,
		maskedPan: PropTypes.string,
		serialNumber: PropTypes.string,
	}).isRequired,
	// lets you select the type of element that the TokenNameAndPan component will be wrapped in
	elementType: PropTypes.node,
	showName: PropTypes.bool,
};

export default TokenNameAndPan;
