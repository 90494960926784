import React, {
	useState,
	useRef,
} from 'react';
import {
	values,
} from "lodash";
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
} from "yup";
import { Lifecycles } from "libreact/lib/Lifecycles";

import Button from '../Button.js';
import Modal from '../Modal.js';
import Password from '../forms/Password.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Toast from "components/Toast.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import PasswordValidation from 'components/auth/PasswordValidation.js';
import CmsContentList from "components/data/CmsContentList.js";
import { passwordVerification } from 'components/auth/LoginCredentials.js';
import { SessionCheckContext } from "components/data/session-user/SessionCheck.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import ClientAjax from "utils/ClientAjax.js";
import FormHelper from 'utils/FormHelper.js';
import PreventDefault from 'utils/PreventDefault.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";

import * as modalStyles from '../Modal.module.css';
import * as editPasswordStyles from './EditPassword.module.css';
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";



const cms = {
	resetHeader: 'miscText.security-info-password-reset-header',
	resetCurrentPassword: 'miscText["security-info-password-reset-current.label"]',
	resetNewPassword: 'miscText["security-info-password-reset-new.label"]',
	resetConfirmPassword: 'miscText["security-info-password-reset-new-confirm.label"]',
	resetCancel: 'miscText.security-info-password-reset-cancel',
	resetSubmit: 'miscText.security-info-password-reset-submit',
	resetConfirmation: 'miscText.security-info-password-reset-confirmation',
	resetErrorMismatch: 'miscText.security-info-password-reset-error-mismatch',

	createHeader: 'miscText.security-info-password-create-header',
	createPassDescription: 'miscText.security-info-password-create-description',
	createNewPassword: 'miscText["security-info-password-create-new.label"]',
	createConfirmPassword: 'miscText["security-info-password-create-new-confirm.label"]',
	createCancel: 'miscText.security-info-password-create-cancel',
	createSubmit: 'miscText.security-info-password-create-submit',
	createConfirmation: 'miscText.security-info-password-create-confirmation',
	createErrorMismatch: 'miscText.security-info-password-create-error-mismatch',
};

const EditPassword = ({ onModalClose, showCurrentPasswordField, refetchSystemGenPassword }) => {
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ password, setPassword ] = useState('');
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});

	const getYupSchema = () => {
		const validations = {
			newpassword: passwordVerification().password,
			confirmPassword: yup_string()
				.oneOf(
					[ yup_ref('newpassword') ],
					cms[ showCurrentPasswordField ? 'resetErrorMismatch' : 'createErrorMismatch' ],
				)
				.required(getErrorKey(COLLECTIONS.securityProfile, 'newpassword', BO_ERRORS.general.required)),
			...recaptchaYupValidations,
		};

		if (showCurrentPasswordField) {
			validations.oldpassword = yup_string().required(getErrorKey(COLLECTIONS.securityProfile, 'oldpassword', BO_ERRORS.general.required));
		}

		return yup_object().shape(validations);
	};

	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, getYupSchema);

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const setEditPasswordConfirmationToast = (cmsContent) => {
		setToast(<Toast
			type="success"
			title={<CmsContentRenderer
				contentKey={cms[ showCurrentPasswordField ? 'resetConfirmation' : 'createConfirmation' ]}
				fallbackValue={showCurrentPasswordField
					? 'Your password has been updated successfully.'
					: 'Your password has been setup successfully. You can now login using your email and password.'
				}
				cmsContent={cmsContent}
			/>}
			onClosed={removeToast}
		/>);
	};

	const kickoffSubmit = async (syntheticTimerEvent, cmsContent) => {
		await checkRecaptcha();

		setSubmitting(true);
		formHelper.clearAllErrors();

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.verbose, errorReport, `Reset password modal form validation`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("User is attempting to reset password");

		try {
			const { oldpassword, newpassword, recaptchaValue } = validated;
			const payload = {
				...(oldpassword && { oldpassword }),
				newpassword,
				recaptchaValue,
			};
			await restClientMiddleware(ClientAjax.post('/ajax/request-password-update', payload));
		} catch (errorReport) {
			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		} finally {
			await syntheticTimerEvent();
		}

		if (!showCurrentPasswordField) {
			// For new password creation (opposed to reset pw)
			// Query GraphQL for the new systemGeneratedPassword flag, which should have changed
			refetchSystemGenPassword();
		}

		setEditPasswordConfirmationToast(cmsContent);
		onModalClose();
	};

	const clearErrors = () => {
		formHelper.clearAllErrors();
	};

	const onPasswordChange = (e) => {
		clearErrors();
		setPassword(e.target.value);
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal
				title={<CmsContentRenderer
					contentKey={cms[ showCurrentPasswordField ? 'resetHeader' : 'createHeader' ]}
					fallbackValue={showCurrentPasswordField ? 'Reset Password' : 'Enable login with email and password'}
				/>}
				onModalClose={onModalClose}
			>
				{!showCurrentPasswordField && <CmsContentRenderer.P
					contentKey={cms.createPassDescription}
					fallbackValue="In order to enable logging in with your email, you'll need to setup a password."
					className={modalStyles.text}
					data-qa="ResetPasswordModalDescription"
				/>}
				<SessionCheckContext.Consumer>{({ syntheticTimerEvent }) => (
					<form
						data-qa="ResetPasswordModalForm"
						ref={formRef}
						onSubmit={PreventDefault(() => kickoffSubmit(syntheticTimerEvent, cmsContent))}
					>
						<Lifecycles didMount={formHelper.wireInputs}>
							{showCurrentPasswordField && (
								<div className={modalStyles.row}>
									<Password
										label={<CmsContentRenderer.Span
											contentKey={cms.resetCurrentPassword}
											fallbackValue="Current Password"
										/>}
										name="oldpassword"
										overrideClass={modalStyles.password}
										error={formHelper.getFieldError('oldpassword')}
										data-qa="ResetPasswordModalCurrentPassword"
										onChange={clearErrors}
									/>
								</div>
							)}
							<div className={editPasswordStyles.passwordsAndValidationWrapper}>
								<div className={editPasswordStyles.passwordInputsWrapper}>
									<div className={modalStyles.row}>
										<Password
											label={<CmsContentRenderer.Span
												contentKey={cms[ showCurrentPasswordField ? 'resetNewPassword' : 'createNewPassword' ]}
												fallbackValue={showCurrentPasswordField ? 'New Password' : 'Password'}
											/>}
											name="newpassword"
											overrideClass={cx(modalStyles.password, editPasswordStyles.passwordInput)}
											error={formHelper.getFieldError('newpassword')}
											autoComplete="new-password"
											data-qa="ResetPasswordModalNewPassword"
											onChange={onPasswordChange}
											aria-labelledby="passwordRequirements"
										/>
									</div>
									<div className={modalStyles.row}>
										<Password
											label={<CmsContentRenderer.Span
												contentKey={cms[ showCurrentPasswordField ? 'resetConfirmPassword' : 'createConfirmPassword' ]}
												fallbackValue={showCurrentPasswordField ? 'Confirm New Password' : 'Confirm Password'}
											/>}
											name="confirmPassword"
											autoComplete="new-password"
											error={formHelper.getFieldError('confirmPassword')}
											overrideClass={cx(modalStyles.password, editPasswordStyles.passwordInput)}
											data-qa="ResetPasswordModalConfirmPassword"
											onChange={clearErrors}
										/>
									</div>
								</div>
								<PasswordValidation
									password={password}
									overrideClass={editPasswordStyles.validationWrapper}
								/>
							</div>
							{formHelper.getFieldErrorJsx('')}
							<div className={cx(modalStyles.actionButtons, editPasswordStyles.modalActionsWrapper)}>
								<Recaptcha />
								<Button
									type="button"
									onClick={onModalClose}
									overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}

								>
									<CmsContentRenderer.Span
										contentKey={cms[ showCurrentPasswordField ? 'resetCancel' : 'createCancel' ]}
										fallbackValue={showCurrentPasswordField ? 'Cancel' : 'Cancel'}
										data-qa="ResetPasswordModalCancelButton"
									/>
								</Button>
								<Button
									submitting={submitting}
									type="submit"
									overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
								>
									<CmsContentRenderer.Span
										contentKey={cms[ showCurrentPasswordField ? 'resetSubmit' : 'createSubmit' ]}
										fallbackValue={showCurrentPasswordField ? 'Save password' : 'Set password'}
										data-qa="ResetPasswordModalSubmitBtn"
									/>
								</Button>
							</div>
						</Lifecycles>
					</form>
				)}</SessionCheckContext.Consumer>
			</Modal>
		)}</CmsContentList>
	);
};

EditPassword.propTypes = {
	onModalClose: PropTypes.func.isRequired,
};

export default EditPassword;
