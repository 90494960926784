import { clone } from "lodash";
import { castAmountDueToString } from "../../utils/FormatHelpers.js";
import WSReplacedMedia, { InputWSReplacedMediaType, WSReplacedMediaType } from "./WSReplacedMedia.js";
import WSReplacementMedia, { InputWSReplacementMediaType, WSReplacementMediaType } from "./WSReplacementMedia.js";

// 2.7.17 WSReplaceMediaLineItem

export default class WSReplaceMediaLineItem {
	constructor({
		replacedMedia,
		replacementMedia,
		itemTotalAmount,
	}) {

		// WSReplacedMedia
		// (Required) The media to be replaced.
		this.replacedMedia = replacedMedia;

		// WSReplacementMedia
		// (Required) The media to use as replacement.
		this.replacementMedia = replacementMedia;

		// int
		// (Required) Total amount in cents for the line item (currently the only amount is the replacement fee).
		this.itemTotalAmount = itemTotalAmount;
	}

	static fromBackoffice(data) {
		const result = new WSReplaceMediaLineItem(data);

		result.replacedMedia =
			new WSReplacedMedia(data.replacedMedia);

		result.replacementMedia =
			new WSReplacementMedia(data.replacementMedia);

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		result.replacementMedia =
			this.replacementMedia.toBackoffice();

		result.replacedMedia =
			this.replacedMedia.toBackoffice();

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.replacementMedia =
			this.replacementMedia.toResolver();

		forGraphql.replacedMedia =
			this.replacedMedia.toResolver();

		return forGraphql;
	}
}

export const WSReplaceMediaLineItemFragment = `
	itemTotalAmount: Int!
`;

export const InputWSReplaceMediaLineItemType = [
	...InputWSReplacedMediaType,
	...InputWSReplacementMediaType,`
	input InputWSReplaceMediaLineItem {
		replacedMedia: InputWSReplacedMedia!
		replacementMedia: InputWSReplacementMedia!
		${WSReplaceMediaLineItemFragment}
	}
` ];

export const WSReplaceMediaLineItemType = [
	...WSReplacedMediaType,
	...WSReplacementMediaType, `
	type WSReplaceMediaLineItem {
		replacedMedia: WSReplacedMediaType!
		replacementMedia: WSReplacementMediaType!
		${WSReplaceMediaLineItemFragment}
	}
` ];
