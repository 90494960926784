import {
	USER_PAYMENT_METHODS_QUERY,
} from "../SessionUser.js";
import { SESSION_TRANSIT_ACCOUNT_GET } from 'components/data/transit-account/SessionTransitAccount.js';
import { FUNDING_SOURCES_GET_QUERY } from 'graphql-queries/FundingSources.js';
import { GET_PRODUCT_CATALOG } from 'components/data/transit-account/ProductCatalog.query.js';

const ReloadSessionDataQs = [
	{ query: USER_PAYMENT_METHODS_QUERY },
	{ query: SESSION_TRANSIT_ACCOUNT_GET, variables: { wsSubsystemAccountInfo_Id: null } },
	{ query: FUNDING_SOURCES_GET_QUERY },
];

export const reloadProducts = (subsystemAccountReference) => {
	return ({
		query: GET_PRODUCT_CATALOG,
		variables: { subsystemAccountReference },
	});
};

export default ReloadSessionDataQs;
