import { gql } from "@apollo/client";
import React from "react";
import PropTypes from "prop-types";
import StdQuery from "../StdQuery.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { groupBy } from "lodash";

{/*
	REASON_CODES are inherited from OMNY: confirm them if needs to use them in Boston
*/}

export const REASON_CODES = {
	TripVoid: 11,
	RefundOrder: 19,
	ReplaceTravelToken: 16,
};

export const GET_REASON_CODES = gql`
	query ReasonCodes (
		$typeId: ID
		$active: Boolean,
		$roleLevel: Int,
		$returnApprovalRules: Boolean,
	){
		reasonCodesQ (
			typeId: $typeId
			active: $active,
			roleLevel: $roleLevel,
			returnApprovalRules: $returnApprovalRules,
		){
			reasonCodeTypes {
				typeId
				description
				reasonCodes {
					reasonCodeId
					description
					active
					minAmount
					maxAmount
					chargeReplacementFee
					chargeEnalbementFee
					notesMandatoryFlag
					financiallyResponsibleOperators {
						financiallyResponsibleOperatorId
						financiallyResponsibleOperatorDescription
					}
					minUserRoleLevel
					approvalRules {
						approvalWorkflowName
						userRoleLevel
						minAmount
						maxAmount
						maxUserCountPerNumDays
						priority
					}
				}
			}
		}
	}
`;

const getReasonCodeTypes = ({ data }) => data?.reasonCodesQ ?? {};
const groupReasonCodeTypes = (reasonCodeTypes) => groupBy(reasonCodeTypes?.reasonCodes, "typeId");

export const useReasonCodes = ({
	typeId,
	active,
	roleLevel,
	returnApprovalRules,
}) => {

	const response = useStdQuery(GET_REASON_CODES, {
		variables: {
			typeId,
			active,
			roleLevel,
			returnApprovalRules,
		},
	});

	const { reasonCodeTypes } = getReasonCodeTypes(response);
	const reasonCodesObj = groupReasonCodeTypes(reasonCodeTypes);

	return {
		response,
		reasonCodeTypes,
		reasonCodesObj,
	};
};

const ReasonCodes = ({
	children,
	typeId = null,
	active = null,
	roleLevel = null,
	returnApprovalRules = null,
}) => (
	<StdQuery
		query={GET_REASON_CODES}
		variables={{
			typeId,
			active,
			roleLevel,
			returnApprovalRules,
		}}
	>
		{(apolloResponse) => {
			const { reasonCodeTypes } = getReasonCodeTypes(apolloResponse);
			const reasonCodesObj = groupReasonCodeTypes(reasonCodeTypes);

			return children({
				data: apolloResponse.data,
				reasonCodeTypes,
				reasonCodesObj,
			});
		}}
	</StdQuery>
);

ReasonCodes.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.element,
		PropTypes.func,
	]).isRequired,
	typeId: PropTypes.number,
	active: PropTypes.bool,
	roleLevel: PropTypes.int,
	returnApprovalRules: PropTypes.bool,
};

ReasonCodes.defaultProps = {
};

export default ReasonCodes;
