// The Autoload object defines what should be load. This object is abstract.
// See the concrete AutoloadOneAccountValue, AutoloadSubsystemValue and
// AutoloadSubsystemProduct for details.
export default class WSAutoload {
	constructor({
		type,
		loadValueBehavior = null,
	}) {

		// string(20)
		// (Required) Identifies the type of auto load. Valid values are:
		// • AddOneAccountValue
		// • AddSubsystemValue
		// • PurchaseSubsystemProduct
		this.type = type;

		// string(30)
		// (Conditionally-Required) Behavior of the autoload. Possible
		// value are:
		// • AddAutoloadValue
		// • IncreaseBalanceTo
		this.loadValueBehavior = loadValueBehavior;
	}

	static fromBackoffice(data) {
		const result = new WSAutoload(data);
		return result;
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSAutoload(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

export const WSAutoloadFragment = `
	type: String!
	loadValueBehavior: String
`;

export const InputWSAutoloadType = [ `
	input InputWSAutoload {
		${WSAutoloadFragment}
	}
` ];

export const WSAutoloadType = [ `
	type WSAutoload implements WSAutoloadFactory {
		${WSAutoloadFragment}
	}
` ];
