import { useMutation } from "@apollo/client";
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { levels, log } from 'utils/Logger.js';

const useStandardMutation = (mutation, options = {}) => {
	const args = useMutation(mutation, options);

	const history = useHistory();

	const mutationName = get(mutation, 'definitions[0].name.value');

	if (args[ 1 ].error) {
		log(null, levels.error, {
			error: args.error,
			variables: args.variables || {},
			message: 'useStandardMutation server error',
			mutationName,
		});
	}

	if (get(args[ 1 ].error, 'TopLevelKey') === 'miscText.error-general-auth-required') {
		history.push('/');
	}

	return [
		...args,
		mutationName,
	];
};

export default useStandardMutation;
