import { clone } from "lodash";
import WSCreditCardReference, { WSCreditCardReferenceType, InputWSCreditCardReferenceType } from "./WSCreditCardReference.js";
import WSDirectDebitClear, { WSDirectDebitClearType, InputWSDirectDebitClearType } from "./WSDirectDebitClear.js";
import WSAddress, { WSAddressType, InputWSAddressType } from "./WSAddress.js";
import {
	WSDirectDebitFactoryResolvers,
	WSDirectDebitFactoryType,
} from "server/api-types/WSDirectDebitFactory.js";

import { InputWSDirectDebitReferenceType } from "./WSDirectDebitReference.js";

/* 2.7.22 : Defines a single funding source which is either credit card or bank account details
* (not both). Contains a new billing address or a reference to an existing address but not both.
*/

export default class WSFundingSource {
	constructor({
		// 2.7.25 WSCreditCard
		// Abstract class to reference either WSCreditCardClear or WSCreditCardReference.
		creditCard = null,
		directDebit = null,
		setAsPrimary = null,
		billingAddressId = null,
		nickname = null,
		billingAddress = null,
	}) {

		// WSCreditCard
		// (Conditionally-Required)  The credit card information associated with this funding source.
		// Either credit card or direct debit but not both can be set per funding source.
		this.creditCard = creditCard;

		// WSDirectDebit
		// (Conditionally-Required)  The debit information associated with this funding source.
		// Either credit card or direct debit but not both can be set per funding source.
		this.directDebit = directDebit;

		// boolean
		// (Optional)  True if this is to be marked as primary funding source for the account.
		// False will be ignored.
		this.setAsPrimary = setAsPrimary;

		// string(40)
		// (Conditionally - Required) A unique reference to an address of the customer.
		// This is required if billingAddress is not populated.This is required when creditCard as funding source.
		this.billingAddressId = billingAddressId;

		// string(256)
		// (Optional) A customer created nickname for the
		// funding source.
		this.nickname = nickname;

		// WSAddress
		// (Conditionally-Required) The billing address
		// associated with this funding source.
		// This is required if billingAddressId is not populated.
		this.billingAddress = billingAddress;
	}

	static fromBackoffice(data) {
		const result = new WSFundingSource(data);

		result.creditCard = data.creditCard
			? WSCreditCardReference.fromBackoffice(data.creditCard)
			: null;

		result.directDebit = data.directDebit
			? WSDirectDebitClear.fromBackoffice(data.directDebit)
			: null;

		result.billingAddress = data.billingAddress
			? WSAddress.fromBackoffice(data.billingAddress)
			: null;

		return result;
	}

	toBackoffice() {
		const result = clone(this);
		result.creditCard = this.creditCard
			? this.creditCard.toBackoffice()
			: null;

		result.directDebit = this.directDebit
			? this.directDebit.toBackoffice()
			: null;

		result.billingAddress = this.billingAddress
			? this.billingAddress.toBackoffice()
			: null;

		if (!result.billingAddress) {
			delete result.billingAddress;
		}

		if (!result.billingAddressId) {
			delete result.billingAddressId;
		}

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.fundingSourceId
			? this.fundingSourceId
			: this.creditCard?.pgCardId ?? this.directDebit?.token;

		forGraphql.creditCard = this.creditCard
			? this.creditCard.toResolver()
			: null;

		forGraphql.directDebit = this.directDebit
			? this.directDebit.toResolver()
			: null;

		forGraphql.billingAddress = this.billingAddress
			? this.billingAddress.toResolver()
			: null;

		return forGraphql;
	}
}

export const WSFundingSourceFragment = `
	creditCard: WSCreditCardReference
	directDebit: WSDirectDebitClear
	setAsPrimary: Boolean
	billingAddressId: String
	billingAddress: WSAddress
	nickname: String
`;

export const InputWSFundingSourceType = [
	...InputWSAddressType,
	...InputWSCreditCardReferenceType,
	...InputWSDirectDebitReferenceType,
	...InputWSDirectDebitClearType, `
	input InputWSFundingSource {
		creditCard: InputWSCreditCardReference
		directDebit: InputWSDirectDebitReference
		setAsPrimary: Boolean
		billingAddressId: String
		billingAddress: InputWSAddress
		nickname: String
	}`,
];

export const WSFundingSourceType = [
	...WSAddressType,
	...WSCreditCardReferenceType,
	...WSDirectDebitFactoryType,
	...WSDirectDebitClearType, `
	type WSFundingSource {
		id: ID!
		${WSFundingSourceFragment}
	}`,
];

export const WSFundingSourceResolvers = {
	...WSDirectDebitFactoryResolvers,
};
