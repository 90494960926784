import { clone } from "lodash";

// 2.7.38.WSRefundMethod
// Method used to refund the amount.
export default class WSRefundMethod {
	constructor({
		type,
		refundPaymentType,
		refundAmount,
		amount,
		priority,
	}) {

		// string(20)
		// (Required) Type of refund method. Valid values are:
		// • OriginalPayment
		// • Check
		// • OneAccountValue
		// • SubsystemAccountValue
		this.type = type;

		// string(20)
		// (Required) Indicates the type of payment for the
		// refund. Valid values for refund types are:
		// • CreditCardNotPresent
		// • Check
		// • DebitCard
		// • DirectDebit
		// • StoredValue
		// • BenefitValue
		this.refundPaymentType = refundPaymentType;

		// int
		// (Required) The amount to be refunded using this
		// refund method. This number is the number of the
		// base unit of currency, such as cents.
		this.refundAmount = refundAmount;

		// int
		// (Required) The stored value amount.
		this.amount = amount;

		// int
		// (Required) The priority for using this refund method
		// in relation to other options based on project
		// configured business rules. Priority 1 is the highest
		// priority.
		this.priority = priority;
	}

	static fromBackoffice(data) {
		const result = new WSRefundMethod(data);
		return result;
	};

	toBackoffice() {
		return this;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = forGraphql.type + forGraphql.refundAmount;

		return forGraphql;
	}
}

export const WSRefundMethodFragment = `
	id: ID!
	type: String!
	refundPaymentType: String!
	refundAmount: Int!
	priority: Int!
`;

export const InputWSRefundMethodType = `
	input InputWSRefundMethod {
		${WSRefundMethodFragment}
	}
`;

export const WSRefundMethodType = [ `
	type WSRefundMethod implements WSRefundMethodFactory {
		${WSRefundMethodFragment}
	}`,
];
