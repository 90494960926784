import React from 'react';
import cx from 'classnames';
import { WireFormHelper } from 'utils/FormHelper.js';

import CreditCardForm from 'components/payments/CreditCardForm.js';
import { BoolCheckbox } from 'components/forms/Checkbox.js';

import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import Addresses from 'components/data/session-user/Addresses.query.js';
import SelectAddressForm from 'components/account-settings/SelectAddressForm.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

import {
	AddressSelectorContext,
	useAddressSelectorContext,
} from "components/account-settings/address-selector/AddressSelector.js";

import * as tabs from 'styles/Tabs.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as checkbox from 'components/forms/Checkbox.module.css';
import * as style from 'components/account/TabAddPaymentCard.module.css';

/**
 * Data-QA Tags:
 * TabAddPaymentCardTitle
 */

const cms = {
	setPrimaryPayment: "miscText.general-payment-set-primary",
	savePaymentMethod: "miscText.general-payment-save",
	billingAddress: "miscText.purchase-card-4-billing-address",
};

export const NewCreditCardForm = ({
	formHelper,
	type,
	provideNickName = true,
	showSaveCheckbox = true,
}) => {
	const addressSelectorState =  useAddressSelectorContext();
	const getFieldName = (fieldNameBase) => (addressSelectorState.addressType + getPaymentInputName({ base: fieldNameBase, isFirst:true, isSplit:false }));

	return (
		<WireFormHelper {...{ formHelper }}>
			<>
				<CreditCardForm {...{
					formHelper,
					type,
					provideNickName,
				}} />
				{showSaveCheckbox &&
					<BoolCheckbox
						label={
							<CmsContentRenderedInline
								contentKey={cms.savePaymentMethod}
								fallbackValue={'Save this payment method to my account'}
								className={cx(checkbox.labelText, style.setSavePaymentCheckbox)}
							/>}
						name={getFieldName('savePayment')}
						labelFirst={false}
						overrideClass={style.setPrimaryCheckbox}
						data-qa="CharlieCardCreditCardFormSaveCheckbox"
						error={formHelper.getFieldError(getFieldName('savePayment'))}
					/>
				}
				<BoolCheckbox
					label={
						<CmsContentRenderedInline
							contentKey={cms.setPrimaryPayment}
							fallbackValue={'Set this as my primary payment method.'}
							className={checkbox.labelText}
						/>}
					name={getFieldName('primaryPaymentMethod')}
					error={formHelper.getFieldError(getFieldName('primaryPaymentMethod'))}
				/>

				<hr className={tabs.hr} />

				<CmsContentRenderedInline
					elementType="p"
					contentKey={cms.billingAddress}
					className={forms.label}
					fallbackValue='Billing address'
				/>
				{addressSelectorState ?
					<AddressSelectorContext.Provider value={addressSelectorState}>
						<Addresses>{(wsAddressExts) => (
							<SelectAddressForm
								{...{
									formHelper,
									wsAddressExts,
								}}
							/>
						)}</Addresses>
					</AddressSelectorContext.Provider>
					:null
				}
			</>
		</WireFormHelper>
	);
};

export default NewCreditCardForm;
