import React, {
	useState,
	useEffect,
	useCallback,
} from 'react';
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import { Redirect } from 'react-router-dom';
import {
	get,
	values,
} from 'lodash';
import {
	stringify } from 'qs';
import cx from 'classnames';

import Container from "../../components/Container.js";
import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentRenderer from '../../components/data/CmsContentRenderer.js';
import Button, { buttonTypeStylePlain } from '../../components/Button.js';
import restClientMiddleware from '../../utils/error-handling/RestClientMiddleware.js';
import ClientAjax from '../../utils/ClientAjax.js';
import Input from '../../components/forms/Input.js';
import PreventDefault from 'utils/PreventDefault.js';

import CmsContentList from 'components/data/CmsContentList.js';
import { useToken, useUsername } from './VerificationCodePanel.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import Toast from 'components/Toast.js';
import { useSessionCheckContext } from 'components/data/session-user/SessionCheck.js';
import useRecaptcha, { recaptchaYupValidations } from 'components/Recaptcha.js';
import { usePostSigninGoToPath } from "components/data/session-user/PostSigninGoToPath.js";

import * as accountCreatedStyles from './AccountCreated.module.css';
import * as loginStyles from "./Login.module.css";

const cms = {
	header: "miscText.register-verify-header",
	updateEmailHeader: "miscText.personal-email-validate-header",
	subheader: "miscHtml.register-verify-subheader",
	updateEmailSubheader: "miscHtml.personal-email-validate-description",
	resendCta: "miscText.register-verify-resend-cta",
	signinCta: "miscText.register-verify-signin-cta",
	verifyEmailLabel: 'miscText["register-verify-manual-email.label"]',
	verifyTokenLabel: 'miscText["register-verify-manual-verification.label"]',
	submit: "miscText.register-verify-submit",
	confirmResendEmail: 'miscText.general-email-validation-resend-email-confirmation',
	registerSuccessHeader: 'miscText.register-success-header',
	registerSuccessDescription: 'miscText.register-success-description',
	//TODO:
	// register-verify-manual-subheader
	// register-verify-manual-email.label
	// register-verify-manual-submit
};

const getYupSchema = (encrypted, resendEmail = false) => {
	const collectedReqs = {
		...recaptchaYupValidations,
	};
	if (encrypted) {
		collectedReqs.email = yup_string()
			.required('miscText["errors.general.blankfield"]')
			.trim();
	} else {
		collectedReqs.email = yup_string()
			.email("miscText.register-error-email")
			.required('miscText["errors.general.blankfield"]')
			.trim();
	}

	if (!resendEmail) {
		collectedReqs.token = yup_string()
			.min(4, 'miscText["errors.security.invalid_verificationToken"]')
			.required('miscText["errors.general.blankfield"]')
			.trim();
	}

	return yup_object().shape(collectedReqs);
};

const AccountCreatedToast = () => {
	const { removeToast } = useGlobalToastsContext();
	return (<CmsContentList list={values(cms)}>{({ cmsContent }) => (
		<Toast
			type="success"
			title={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cms.registerSuccessHeader}
				fallbackValue="Success!"
			/>}
			text={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cms.registerSuccessDescription}
				fallbackValue="Your email has been confirmed."
			/>}
			onClosed={removeToast}
		/>
	)}</CmsContentList>
	);
};

const ResendActivationSuccessToast = () => {
	const { removeToast } = useGlobalToastsContext();
	return (<CmsContentList list={values(cms)}>{({ cmsContent }) => (
		<Toast
			type="success"
			title={<CmsContentRenderer.Span contentKey={cms.confirmResendEmail} fallbackValue={"Email sent"} />}
			onClosed={removeToast}
		/>
	)}</CmsContentList>
	);
};

const ActivateAccount = ({ afterEmailUpdate = false }) => {
	const postSigninGoToPath = usePostSigninGoToPath();

	// Navigational helpers
	const [ redirect, setRedirect ] = useState(null);
	const { setToast } = useGlobalToastsContext();

	// Data from query
	const { username, encrypted } = useUsername();
	const token = useToken();

	// Local state
	const [ emailEncrypted, setEmailEncrypted ] = useState(encrypted ?? false); // true if the email came from the query params
	const [ email, setEmail ] = useState(username || '');
	const [ accessCode, setAccessCode ] = useState(token || '');

	// Make sure there is no race condtion and email is always populated if possible
	useEffect(() => {
		if (email === "" && emailEncrypted) {
			setEmail(username);
		}
	},[ username,emailEncrypted ,email ]);

	const [ shouldAutoSubmit, setShouldAutoSubmit ] = useState(token && encrypted);

	// Form Set up
	const [ submittingResend, setSubmittingResend ] = useState(false);
	const formHelperContext = useFormHelper({ getYupSchema: () => getYupSchema(encrypted, submittingResend) });
	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = formHelperContext;

	const { syntheticTimerEvent } = useSessionCheckContext();

	const { Recaptcha, checkRecaptcha, resetRecaptcha, loading: recaptchaLoading } = useRecaptcha({ formHelper });

	const resendEmail = async () => {
		await checkRecaptcha();

		setSubmittingResend(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmittingResend(false);
			return;
		}

		try {
			await restClientMiddleware(ClientAjax.post("/ajax/resend-activation-link", validated)); // requires plain text email
		} catch (errorReport) {
			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmittingResend(false);

			syntheticTimerEvent();
		}

		setToast(<ResendActivationSuccessToast />);
	};

	const submitRegisterToken = useCallback(async () => {
		await checkRecaptcha();

		setSubmitting(true);
		setShouldAutoSubmit(false);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		let response;
		try {
			response = await restClientMiddleware(ClientAjax.post("/ajax/verify-registration-token", {
				...validated,
				encrypted: emailEncrypted,
			}));
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);

			// if the email came from the query params,
			// clear out the email fields so the user must type in email
			if (emailEncrypted) {
				setEmail("");
				setEmailEncrypted(false);
			}
			return;
		} finally {
			syntheticTimerEvent();
		}

		if (get(response, 'data.success', false)) {
			// The account is now verified, but we don't have credentials to log them in.
			// Send them to the login screen and show a toast notification that their account has been verified.
			setToast(<AccountCreatedToast />);

			setRedirect(<Redirect push to={{
				pathname: getPathByRoute(routeKeys.SignIn),
				search: stringify({
					postSigninGoToPath,
				}),
			}} />);

		} else if (get(response, 'data.activationEmailSuccess', false)) {
			alert('The link in the email you clicked is no longer valid. A new activation email is on its way. Please try again from the new email message.');
		}
	}, [ checkRecaptcha, emailEncrypted, formHelper, postSigninGoToPath, resetRecaptcha, setEmail, setSubmitting, setToast, syntheticTimerEvent ]);

	// Verify Token automatically if it's provided
	useEffect(() => {
		if (recaptchaLoading || !shouldAutoSubmit) {
			return;
		}

		submitRegisterToken();
	}, [ shouldAutoSubmit, submitRegisterToken, recaptchaLoading ]);

	if (redirect) {
		return redirect;
	}

	return (
		<Container overrideClass={loginStyles.resetPassword}
			data-qa="AccountCreatedContainer"
		>
			<CmsContentList list={values(cms)}>{() => (<>
				<CmsContentRenderer.H2
					contentKey={afterEmailUpdate ? cms.updateEmailHeader : cms.header}
					fallbackValue="Almost there ..."
					className={loginStyles.subhead}
					data-qa="AccountCreatedHeader"
				/>

				<CmsContentRenderer
					contentKey={afterEmailUpdate ? cms.updateEmailSubheader : cms.subheader}
					className={accountCreatedStyles.accountCreatedText}
					data-qa="AccountCreatedText"
					rawHtml={true}
					fallbackValue={`
						<p>We've sent a confirmation email to your email address.</p>
						<p>Click the link in your email or enter the validation code below to activate your account.</p>
					`}
				/>

				<div className={loginStyles.acctCreateTokenWrapper}>
					<form
						method="post"
						ref={formRef}
						onSubmit={PreventDefault(submitRegisterToken)}
					>
						{emailEncrypted
							? <input
								name="email"
								type="hidden"
								value={email}
							/>
							: <Input
								label={<CmsContentRenderer.Span contentKey={cms.verifyEmailLabel}
									fallbackValue="Email" />}
								onChange={event => setEmail(event.target.value)}
								value={email}
								defaultValue={email}
								controlled={true}
								error={formHelper.getFieldError('email')}
								name="email"
							/>}

						<Input
							label={<CmsContentRenderer.Span contentKey={cms.verifyTokenLabel}
								fallbackValue="Verification Code" />}
							onChange={event => setAccessCode(event.target.value)}
							data-qa="tokenInput"
							name="token"
							type="text"
							controlled={true}
							value={accessCode}
							error={formHelper.getFieldError('token')}
						/>
						{formHelper.getFieldErrorJsx('')}
						<Recaptcha />
						<Button
							isPrimary={true}
							additionalClassNames={accountCreatedStyles.submitButton}
							submitting={submitting}
							data-qa="SubmitRegisterToken"
						>
							<CmsContentRenderer.Span contentKey={cms.submit} fallbackValue="Submit" />
						</Button>
					</form>
				</div>

				<Button
					typeStyle={buttonTypeStylePlain}
					data-qa="ResendEmailButton"
					additionalClassNames={cx(loginStyles.textLink, accountCreatedStyles.resendEmailButton)}
					onClick={PreventDefault(resendEmail)}
					isPrimary={false}
					submitting={submittingResend}
				>
					<CmsContentRenderer.Span
						contentKey={cms.resendCta}
						fallbackValue="Resend email"
					/>
				</Button>

				<Button
					typeStyle={buttonTypeStylePlain}
					additionalClassNames={cx(loginStyles.textLink, accountCreatedStyles.resendEmailButton)}
					to={getPathByRoute(routeKeys.SignIn)}
					isPrimary={false}
				>
					<CmsContentRenderer.Span
						contentKey={cms.signinCta}
						fallbackValue="Return to login"
					/>
				</Button>
			</>)}</CmsContentList>
		</Container>
	);
};

export const AccountCreated = (params) => ActivateAccount({ ...params, afterEmailUpdate: false });
export const VerifyUpdateEmail = (params) => ActivateAccount({ ...params, afterEmailUpdate: true });

export default ActivateAccount;
