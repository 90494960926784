import {
	clone,
	map,
} from "lodash";
import WSFundingSourceUpdateSet, { WSFundingSourceUpdateSetType, InputWSFundingSourceUpdateSetType } from "./WSFundingSourceUpdateSet.js";

// Table 2-623. WSFundingSourceSetUpdate Field Descriptions
export default class WSFundingSourceSetUpdate {
	constructor({
		fundingSourceSetId,
		fundingSources,
	}) {
		// string(40)
		// (Required) The ID of the funding source set being updated.
		this.fundingSourceSetId = fundingSourceSetId;

		// List<WSFundingSourceUpdateSet>
		// (Required) List of funding sources and payment rules for
		// payment
		this.fundingSources = fundingSources;
	}

	static fromBackoffice(data) {
		const result = new WSFundingSourceSetUpdate(data);

		result.fundingSources = map(data.fundingSources, wsFundingSourceUpdateSet =>
			WSFundingSourceUpdateSet
				.fromBackoffice(wsFundingSourceUpdateSet)
		);

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		result.fundingSources =
			map(this.fundingSources, wsFundingSourceUpdateSet =>
				wsFundingSourceUpdateSet.toBackoffice());

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.fundingSources =
			map(this.fundingSources, wsFundingSourceUpdateSet =>
				wsFundingSourceUpdateSet.toResolver());

		return forGraphql;
	}
}

export const WSFundingSourceSetUpdateFragment = [ `
	input InputWSFundingSourceSetUpdate {
		fundingSourceSetId: String
		fundingSources: [ InputWSFundingSourceUpdateSet! ]!
	}
` ];

export const InputWSFundingSourceSetUpdateType = [
	...InputWSFundingSourceUpdateSetType,
	...WSFundingSourceUpdateSetType, `
	input InputWSFundingSourceSetUpdate {
		fundingSourceSetId: String
		fundingSources: [ InputWSFundingSourceUpdateSet! ]!
	}
` ];

export const WSFundingSourceSetUpdateType = [ `
	type WSFundingSourceSetUpdate {
		fundingSourceSetId: String
		fundingSources: [ WSFundingSourceUpdateSet! ]!
	}
` ];
