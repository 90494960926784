import {
	useEffect,
	useMemo,
} from 'react';
import PublicAppVars from "utils/PublicAppVars.js";
import { usePypestreamConfig } from "components/pypestream-chatbot/PypestreamConfig.query.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import { useLanguage } from "components/data/Language.js";

const PYPESTREAM_SCRIPT_ID = 'pypestream-script-tag';

const PypestreamInit = () => {
	const language = useLanguage();
	const { config, enabled, queryResult: { loading } } = usePypestreamConfig();

	const mappedLanguage = PublicAppVars.PYPESTREAM_CHATBOT_LANGUAGE_OVERRIDES?.[ language ] ?? language;

	const fullConfig = useMemo(() => ({
		...config,
		language: mappedLanguage,
	}), [
		config,
		mappedLanguage,
	]);

	useEffect(() => {
		if (!enabled || loading) {
			return;
		}

		try {
			// keep this up to date as config changes to sync login/out
			// thanks to the ?., this does nothing if the injected script hasn't loaded yet
			window.Pypestream?.updatePypestreamConfig(fullConfig);
		}
		catch (error) {
			noticeError(null, levels.error, error, "updatePypestreamConfig failed");
		}

		const scriptEl = document.getElementById(PYPESTREAM_SCRIPT_ID);
		if (!scriptEl) {
			// only set pypestreamConfig once
			window.pypestreamConfig = fullConfig;

			// inject launcher.js
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.src = PublicAppVars.PYPESTREAM_CHATBOT_SCRIPT_SRC + Date.now();
			script.async = true;
			script.id = PYPESTREAM_SCRIPT_ID;
			document.body.appendChild(script);
		}
	}, [
		fullConfig,
		enabled,
		loading,
	]);

	return null;
};
export default PypestreamInit;
