import React from 'react';
import CmsContentList from "components/data/CmsContentList.js";
import { values } from "lodash";
import Toast from "components/Toast.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";

const cms = {
	langChanged: 'miscText.preferences-language-change-confirmation',
};

export const LanguageChanged = ({ newLanguage }) => {
	const { removeToast } = useGlobalToastsContext();

	return (
		<CmsContentList languageOverride={newLanguage} list={values(cms)}>{() => (
			<Toast
				type="success"
				title={
					<CmsContentRenderer.Span contentKey={cms.langChanged}
						fallbackValue="Your language preference has been updated." />
				}
				onClosed={removeToast}
			/>
		)}
		</CmsContentList>
	);
};
