import { clone } from "lodash";
import WSAutoloadCriteria, { WSAutoloadCriteriaType } from "./WSAutoloadCriteria.js";
import WSAutoload, { WSAutoloadType } from "./WSAutoload.js";
import WSAutoloadSubsystemProduct, { WSAutoloadSubsystemProductType } from "./WSAutoloadSubsystemProduct.js";
import WSAutoloadFactory, { WSAutoloadFactoryType } from "./WSAutoloadFactory.js";


export const active = 'Active';
export const fulfillmentPending = 'FulfillmentPending';
export const suspendedDueToError = 'SuspendedDueToError';
export const suspendedManually = 'SuspendedManually';
export const paused = 'Paused';

export const AutoloadStatuses = {
	active,
	fulfillmentPending,
	suspendedDueToError,
	suspendedManually,
	paused,
};

export const AutoloadSuspendedStatuses = [
	suspendedDueToError,
	suspendedManually,
];

// 2.7.10 WSAutoloadSummary
export default class WSAutoloadSummary {
	constructor({
		subscriptionId,
		passId = null,
		purseId = null,
		purseType,
		purseRestriction = null,
		nickname = null,
		criteria = null,
		autoload = null,
		status,
		startDtm = null,
	}) {
		// string
		// (Required) The unique identifier for the autoload
		this.subscriptionId = subscriptionId;

		// string(40)
		// (Conditionally-Required) The pass unique identifier
		// Required if purseId is not provided.
		this.passId = passId;

		// int
		// (Conditionally-Required) The purse unique identifier.
		// Required if pass Id and purseType are not provided.
		this.purseId = purseId;

		// string(40)
		// (Conditionally-Required) The type of the purse. Required if purse type provided.
		this.purseType = purseType;

		// string(40)
		// (Conditionally-Required) The purse restriction. Required if purse type is provided.
		this.purseRestriction = purseRestriction;

		// string(40)
		// (Required) Name of the purse or pass.
		// criteria
		this.nickname = nickname;

		// WSAutoloadCriteria
		// (Conditionally-Required) Required if subscriptionId is specified.
		this.criteria = criteria;

		/**
		 * @type {WSAutoload|null}
		 * (Conditionally-Required) Required if subscriptionId is specified.
		 */
		this.autoload = autoload;

		// string(20)
		// (Required) The subscription status:
		//  Active
		//  FulfillmentPending
		//  SuspendedManually
		this.status = status;

		// Date
		// (Required) : the date when the autoload will be triggered
		this.startDtm = startDtm;
	}

	static fromBackoffice(data) {
		const result = new WSAutoloadSummary(data);

		result.autoload = data.autoload
			// This extends the WSAutoload object. An auto load for a subsystem product.
			// Todo: lets use a factory here
			? WSAutoloadFactory.fromBackoffice(data.autoload)
			: null;

		result.criteria = data.criteria
			? WSAutoloadCriteria.fromBackoffice(data.criteria)
			: null;

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		result.autoload =
			this.autoload?.toBackoffice();

		result.criteria =
			this.criteria?.toBackoffice();

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = this.subscriptionId;

		forGraphql.autoload =
			this.autoload?.toResolver();

		forGraphql.criteria =
			this.criteria?.toResolver();

		return forGraphql;
	}
}

export const WSAutoloadSummaryType = [
	...WSAutoloadFactoryType,
	...WSAutoloadSubsystemProductType,
	...WSAutoloadCriteriaType, `
	type WSAutoloadSummary {
		id: ID!
		subscriptionId: String!
		passId: String
		purseId: Int
		purseType: String
		purseRestriction: String
		nickname: String
		criteria: WSAutoloadCriteria
		autoload: WSAutoloadFactory
		status: String!
		startDtm: GraphQLDateTime
	}
` ];
