import { clone } from "lodash";
import WSFundingSource, { WSFundingSourceType, InputWSFundingSourceType } from "./WSFundingSource";

// 2.7.7.WSFundingSourceSetItem
// WSFundingSourceSetItem represents a funding source and the rules for usage as part of a funding source
// set.
export default class WSFundingSourceSetItem {
	constructor({
		fundingSourceId,
		priority,
		maxPaymentAmount = null,
		fundingSource,
	}) {

		// int
		// (Required) The unique identifier of the funding
		// source to make the payment with.
		this.fundingSourceId = fundingSourceId;

		// int
		// (Required) Priority of this funding source used
		// to define the order in which the funding sources
		// are used. A lower value is a higher priority
		// (priority 1 will be charged before priority 2)
		this.priority = priority;

		// int
		// (Optional) Maximum amount to attempt to
		// charge to this funding source.
		this.maxPaymentAmount = maxPaymentAmount;

		// WsFundingSource
		// (Required) The funding source to make the payment with.
		this.fundingSource = fundingSource;
	}

	static fromBackoffice(data) {
		const result = new WSFundingSourceSetItem(data);

		result.fundingSource = WSFundingSource.fromBackoffice(data.fundingSource);

		return result;
	};

	toBackoffice() {
		const result = clone(this);

		result.fundingSource = this.fundingSource.toBackoffice();

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.fundingSource = this.fundingSource.toBackoffice();

		return forGraphql;
	}
}

export const InputWSFundingSourceSetItemType = [
	...InputWSFundingSourceType,
	`
	input InputWSFundingSourceSetItem {
		fundingSourceId: Int!
		priority: Int!
		maxPaymentAmount: Int
		fundingSource: InputWSFundingSource
	}
` ];

export const WSFundingSourceSetItemType = [
	...WSFundingSourceType,
	`
	type WSFundingSourceSetItem {
		fundingSourceId: Int
		priority: Int!
		maxPaymentAmount: Int
		fundingSource: WSFundingSource
	}
` ];
