import {
	clone,
} from "lodash";

import WSTravelPresence, { WSTravelPresenceType } from './WSTravelPresence.js';
import WSBankcardTravelTokenDisplay, { WSBankcardTravelTokenDisplayType } from "./WSBankcardTravelTokenDisplay.js";

// Valid correctionStatus statuses
// slack https://reflexions.slack.com/archives/CCF68M49M/p1679939489344649?thread_ts=1679682261.956279&cid=CCF68M49M
export const VOID_PENDING = 'VoidPending'; // dispute trip status
export const CORRECTION_PENDING = 'CorrectionPending'; // misses/autofilled taps correction status
export const REJECTED = 'Rejected'; // for both dispute and taps correction status
export const COMPLETED = 'Completed'; // for both dispute and taps correction status

// Extends WSTravelPresence with objectType = TransitTravel.
export default class WSTransitTravelPresence extends WSTravelPresence {
	constructor({
		deviceId,
		transactionDescription,
		transactionStatus,
		transactionStatusDescription,
		stopPoint,
		stopPointDescription,
		// isVoidable,
		routeNumber,
		routeDescription,
		zone,
		processingStatus,
		correctionStatus,
		correctionDateTime,

		//Super props
		objectType,
		travelPresenceId,
		transactionDateTime,
		type,
		typeDescription,
		locationCode,
		locationDescription,
		operator,
		operatorDescription,
		token,
		status,
		statusDescription,
		generatedCause,
		generatedCauseDescription,
		travelPresenceIndicator,
		isCorrectable,
		isVoidable,

	}) {
		super({
			objectType,
			travelPresenceId,
			transactionDateTime,
			type,
			typeDescription,
			locationCode,
			locationDescription,
			operator,
			operatorDescription,
			token,
			status,
			statusDescription,
			generatedCause,
			generatedCauseDescription,
			travelPresenceIndicator,
			isCorrectable,
			isVoidable,
		});

		// string(30)
		// (Required)  Unique identifier of the device that detected the travel presence.
		this.deviceId = deviceId;

		// string(100)
		// (Required)  A description of the transaction. It should include information relevant
		// to the travel mode and location of the transaction (entry, exit, tolling point, etc)
		this.transactionDescription = transactionDescription;

		// string(20)
		// (Required)  The status of the transaction.
		this.transactionStatus = transactionStatus;

		// string(100)
		// (Required)  A description of the transaction status.
		this.transactionStatusDescription = transactionStatusDescription;

		// string(40)
		// (Optional)  Stop Id or Code where the use transaction was captured. The stopPoint
		// here is the starting location (entry tap).
		this.stopPoint = (stopPoint === undefined) ? null : stopPoint;

		// string(100)
		// (Optional)  The description of the stop point where the traveler’s presence was captured.
		this.stopPointDescription = (stopPointDescription === undefined) ? null : stopPointDescription;

		// string(100)
		// (Optional)  The description of the stop point where the traveler’s presence was captured.
		this.isVoidable =  (isVoidable === undefined) ? null : isVoidable;

		// string(20)
		// (Optional)  Route Id or Code where the use transaction was captured.
		this.routeNumber = (routeNumber === undefined) ? null : routeNumber;

		// string(100)
		// (Optional)  The description of the route number where the traveler’s presence was captured.
		this.routeDescription = (routeDescription === undefined) ? null : routeDescription;

		// string(20)
		// (Optional)  Zone Id or Code where the use transaction was captured.
		this.zone = (zone === undefined) ? null : zone;

		// string(30)
		// (Optional)  Description of processing status. Possible values depend on Transit
		// Travel Subsystem processing flow.  For ABP:
		// Matched
		// Void
		this.processingStatus = (processingStatus === undefined) ? null : processingStatus;

		// string(20)
		// (Optional) Indicates the tap correction status. Valid values are:
		// VoidPending
		// CorrectionPending
		// Completed
		this.correctionStatus = (correctionStatus === undefined) ? null : correctionStatus;

		// dateTime
		// (Optional) The timestamp for when the tap correction was completed or the request
		// created (if still Pending).
		this.correctionDateTime = (correctionDateTime === undefined) ? null : correctionDateTime;
	}

	static fromBackoffice(data) {
		const result = new WSTransitTravelPresence(data);
		result.token = WSBankcardTravelTokenDisplay.fromBackoffice(result.token);

		return result;
	}

	toBackoffice() {
		return {
			...super.toBackoffice(),
			...this,
		};
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.token =  forGraphql.token.toResolver();

		return forGraphql;
	}
}

export const WSTransitTravelPresenceType = [
	...WSBankcardTravelTokenDisplayType,
	...WSTravelPresenceType,
	`
	type WSTransitTravelPresence {
		objectType: String!
		travelPresenceId: String!
		transactionDateTime: GraphQLDateTime!
		type: String!
		typeDescription: String
		locationCode: String!
		locationDescription: String!
		operator: String!
		operatorDescription: String!
		token: WSBankcardTravelTokenDisplay!

		status: String!
		statusDescription: String!
		generatedCause: String!
		generatedCauseDescription: String!
		travelPresenceIndicator: String!
		isCorrectable: Boolean!


		deviceId: String!
		transactionDescription: String!
		transactionStatus: String!
		transactionStatusDescription: String!
		stopPoint: String
		stopPointDescription: String
		isVoidable: String
		routeNumber: String
		routeDescription: String
		zone: String
		processingStatus: String
		correctionStatus: String
		correctionDateTime: GraphQLDateTime
	}
` ];
