// 2.7.6.WSAutoloadCriteria
// The AutoloadCritera object contains the information for when auto load should occur. This object is handles
// both threshold or recurring types of criteria. For threshold type, the threshold value is a system configuration
// value.

export const AUTO_LOAD_CRITERIA_TYPES = {
	threshold: 'Threshold',
	recurring: 'Recurring',
	passThreshold: 'passThreshold',
	purchaseSubsystemProduct: 'PurchaseSubsystemProduct',
	addSubsystemValue: 'AddSubsystemValue',
	addOneAccountValue: 'AddOneAccountValue',
};

export default class WSAutoloadCriteria {
	constructor({
		type,
		recurrence = null,
		day = null,
		frequency = null,
		thresholdValue = null,
		daysBeforeExpiryToTrigger = null,
	}) {

		// string(20)
		// (Required) Identifies the type of criteria. Valid values are:
		// • Threshold
		// • Recurring
		// • PassThreshold
		// • PurchaseSubsystemProduct
		this.type = type;

		// string(20)
		// (Conditionally-Required) Needed only for Recurring type.
		// Identifies the type of recurrence time period. Valid values
		// are:
		// • Daily
		// • Weekly
		// • Monthly
		this.recurrence = recurrence;

		// string(3)
		// (Contitionally-Required) The day of the time period on which
		// load is triggered.
		// Required when type is Recurring and recurrence is not Daily.
		// If the period types is Weekly, valid values are:
		// SUN, MON, TUE, WED, THU, FRI, SAT
		// If the periodType is Monthly, valid values are 1-31 where the
		// value represents the calendar day. If the calendar date is
		// larger than the number of days in the month the load will be
		// triggered on the last day of the month.
		// For example, if the value was set to 31 and the current
		// month is April, the load will be triggered on the 30th.
		this.day = day;

		// int
		// (Conditionally-Required) The frequency of recurrence.
		// Needed when the criteria type is Recurring.
		// Example: If the type = Weekly, day = MON, and frequency =
		// 2, the load will happen every other week on Monday.
		this.frequency = frequency;

		// int
		// (Conditionally-Required) Value at which to trigger
		// the auto load. Needed when the criteria type is
		// Threshold.
		// If this is not defiend, a system wide configuration is
		// used to define this thresholdAmount.
		this.thresholdValue = thresholdValue;

		// int
		// (Conditionally-Required) Days before pass
		// expiration at which to trigger the auto load. Needed
		// when the criteria type is PassThreshold.
		// If this is not defiend, a system wide configuration is
		// used to define this.
		this.daysBeforeExpiryToTrigger = daysBeforeExpiryToTrigger;
	}

	static fromBackoffice(data) {
		const result = new WSAutoloadCriteria(data);
		return result;
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSAutoloadCriteria(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

const WSAutoloadCriteriaTypeFragment = `
	type: String!
	recurrence: String
	day: String
	frequency: Int
	thresholdValue: Int
	daysBeforeExpiryToTrigger: Int
`;

export const InputWSAutoloadCriteriaType = [ `
	input InputWSAutoloadCriteria {
		${WSAutoloadCriteriaTypeFragment}
	}
` ];

export const WSAutoloadCriteriaType = [ `
	type WSAutoloadCriteria {
		${WSAutoloadCriteriaTypeFragment}
	}
` ];
