import React from 'react';
import StdQuery from "components/data/StdQuery.js";
import { gql } from "@apollo/client";
import { map } from "lodash";
import { LINK_STATUSES } from "server/api-types/WSAlternateContactInfo.js";
import { SUBSYSTEM_ACCOUNT_INFO_FIELDS } from "components/data/session-user/SessionUser.js";

export const MANAGED_CUSTOMER_LINK_INFO_FIELDS = gql`
	fragment ManagedCustomerLinkInfoFields on WSManagedCustomerInfo {
		id
		linkId
		managedCustomerId
		managedContactId
		firstName
		lastName
		email
		phone
		linkStatus
		statusChangeDtm
	}
`;

export const GET_ALTERNATES_AND_MANAGED_CUSTOMERS = gql`
	${MANAGED_CUSTOMER_LINK_INFO_FIELDS}
	query getAlternates(
		$linkStatuses: [ String! ]
	) {
		CustomerRoute {
			alternates(linkStatuses: $linkStatuses) {
				alternateContacts {
					id
					linkId
					alternateCustomerId
					alternateContactId
					firstName
					lastName
					email
					phone
					linkStatus
					statusChangeDtm
				}
				managedCustomers {
					managedCustomerLinkInfo {
						...ManagedCustomerLinkInfoFields
					}
					id
				}
				remainingPendingRequests
				remainingLinkedAlternateContacts
			}
			id
		}
	}
`;

export const GET_MANAGED_CUSTOMER_SUBSYSTEM_ACCOUNTS = gql`
	${SUBSYSTEM_ACCOUNT_INFO_FIELDS}
	${MANAGED_CUSTOMER_LINK_INFO_FIELDS}
	query getManagedCustomerSubsystemAccounts {
		CustomerRoute {
			alternates(linkStatuses: [ "${LINK_STATUSES.accepted}" ]) {
				managedCustomers {
					id
					managedCustomerDetails {
						subsystemAccounts {
							...SubsystemAccountInfoFields
						}
					}
					managedCustomerLinkInfo {
						...ManagedCustomerLinkInfoFields
					}
				}
			}
		}
	}
`;

export const AlternatesAndManagedCustomers = ({ children, linkStatuses }) => (
	<StdQuery query={GET_ALTERNATES_AND_MANAGED_CUSTOMERS} variables={{ linkStatuses }}>
		{({ data: { CustomerRoute: { alternates } } }) => {
			return children(alternates);
		}}
	</StdQuery>
);

export const Alternates = ({ children }) => (
	<AlternatesAndManagedCustomers>
		{({ alternateContacts, remainingLinkedAlternateContacts, remainingPendingRequests }) => {
			return children({
				wsAlternateContactInfos: alternateContacts,
				remainingLinkedAlternateContacts,
				remainingPendingRequests,
			});
		}}
	</AlternatesAndManagedCustomers>
);

export const PendingAlternates = ({ children }) => (
	<AlternatesAndManagedCustomers linkStatuses={[ LINK_STATUSES.pending ]}>
		{({ alternateContacts, remainingPendingRequests }) => {
			return children({ wsAlternateContactInfos: alternateContacts, remainingPendingRequests });
		}}
	</AlternatesAndManagedCustomers>
);

export const AcceptedManagedCustomers = ({ children }) => (
	<AlternatesAndManagedCustomers linkStatuses={[ LINK_STATUSES.accepted ]}>
		{({ managedCustomers }) => {
			return children({
				wsManagedCustomerInfos: map(managedCustomers, ({ managedCustomerLinkInfo }) => managedCustomerLinkInfo),
			});
		}}
	</AlternatesAndManagedCustomers>
);

export const PendingManagedCustomers = ({ children }) => (
	<AlternatesAndManagedCustomers linkStatuses={[ LINK_STATUSES.pending ]}>
		{({ managedCustomers }) => {
			return children({
				pendingWsManagedCustomerInfos: map(managedCustomers, ({ managedCustomerLinkInfo }) => managedCustomerLinkInfo),
			});
		}}
	</AlternatesAndManagedCustomers>
);
