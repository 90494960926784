import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { values } from "lodash";
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import Button from '../Button.js';
import Input from '../forms/Input.js';
import PasswordValidation from './PasswordValidation.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';

import { object as yup_object, string as yup_string, ValidationError } from "yup";
import { Lifecycles } from 'libreact/lib/Lifecycles';
import CmsContentList from '../data/CmsContentList.js';
import Password from '../forms/Password';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import ClientAjax from "utils/ClientAjax.js";
import { usePostSigninGoToPath } from "components/data/session-user/PostSigninGoToPath.js";
import { stringify } from "qs";

import * as login from '../../pages/auth/Login.module.css';
import PreventDefault from 'utils/PreventDefault.js';

const cms = {
	emailInputLabel: 'miscText["register-email-email.label"]',
	passwordInputLabel: 'miscText["register-email-password.label"]',
	nextBtnTitle: "miscText.register-email-submit",
};

const prevalidateApiToYup = (errorReport, dataToValidate) => {
	// we only care about email & password errors. Anything else can be handled by the submit route.
	const errors = [];
	[ "email", "password" ].forEach(field => {
		const fieldErrors = errorReport.FieldErrors[ field ];
		if (fieldErrors) {
			errors.push({
				inner: [
					{
						path: field,
						value: dataToValidate[ field ],
						errors: fieldErrors,
					},
				],
			});
		}
	});
	return new ValidationError(errors);
};

export const passwordVerification = () => {
	return {
		password: yup_string()
			.min(10, getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.general.tooSmall))
			.matches(/[A-Za-z]/, getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.password.containsChars))
			.matches(/[A-Z]/, getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.password.containsMixedCase ))
			.matches(/[a-z]/, getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.password.containsMixedCase))
			.matches(/\d/, getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.password.containsDigits))
			.matches(/[!@#$%^&]+/, getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.password.containsSpecial))
			/**
			Commented out for now in case we need to bring this code back
			Only 4 of the 5 criteria is required to validate a password
			.test({
				name: 'minimumCriteriaMatched',
				message: `miscText["register-error-password"]`,
				test: (value) =>
				{
					let validationPassed = 0;
					const regexes = [
						/[A-Za-z]/,
						/[A-Z]/,
						/[a-z]/,
						/\d/,
						/[!@#$%^&]+/,
					];
					regexes.forEach( regex =>
						regex.test(value) && validationPassed++
					);
					return validationPassed > 3;
				},
			})
			*/
			.required(getErrorKey(COLLECTIONS.registration, 'contact.password', BO_ERRORS.general.required)),
	};
};

const getYupSchema = () => {
	return yup_object().shape({
		email: yup_string()
			.email(getErrorKey(COLLECTIONS.registration, 'contact.email', BO_ERRORS.general.invalidEmail))
			.required(getErrorKey(COLLECTIONS.registration, 'contact.email', BO_ERRORS.general.required)),
		...passwordVerification(),
	});
};


const LoginCredentials = () => {
	const postSigninGoToPath = usePostSigninGoToPath();

	const [ password, setPassword ] = useState('');
	const [ redirect, setRedirect ] = useState(null);

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema });

	const handleSubmit = async () => {

		setSubmitting(true);
		GoogleAnalytics.logEvent("registration step 1 submitting");
		let validated;
		try {
			validated = await formHelper.startValidation(true);

			await restClientMiddleware(ClientAjax.post("/ajax/register-prevalidate", validated,));
		}
		catch (errorReport) {
			setSubmitting(false);
			GoogleAnalytics.logEvent("registration step 1 failed");
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		setSubmitting(false);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.RegisterPersonalInformation),
				search: stringify({
					postSigninGoToPath,
				}),
				state: validated,
			}}/>
		);

		GoogleAnalytics.logEvent("registration step 1 complete");
	};


	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Lifecycles didMount={() => formHelper.wireInputs()}>
				<form className={login.registerForm}
					ref={formRef}
					data-qa="RegisterFormCreds"
					onSubmit={PreventDefault(handleSubmit)}
				>
					{formHelper.getFieldErrorJsx('')}
					<Input type="text"
						name="email"
						label={cmsContent[ cms.emailInputLabel ] || 'Enter Your Email'}
						helptext="You will use this email to login in"
						data-qa="RegisterInputUsername"
						error={formHelper.getFieldError('email')}
					/>

					<div className={login.registerPasswords}>
						<Password
							label={cmsContent[ cms.passwordInputLabel ] || 'Create Password'}
							name="password"
							autoComplete="new-password"
							data-qa="RegisterInputPassword"
							onChange={event => setPassword(event.target.value)}
							error={formHelper.getFieldError('password')}
							aria-labelledby="passwordRequirements"
						/>

						<PasswordValidation password={password} />
					</div>

					<div className={login.formActions}>
						<Button
							isPrimary
							overrideClass={login.btn}
							data-qa="RegisterFormCredsAction"
							submitting={submitting}
						>
							<CmsContentRenderer.Span contentKey={cms.nextBtnTitle} fallbackValue='Next' />
						</Button>
					</div>
				</form>
			</Lifecycles>
		)}</CmsContentList>
	);
};

export default LoginCredentials;
