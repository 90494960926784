import cx from 'classnames';
import React, {
	useMemo,
	useState,
	useEffect,
} from "react";
import {
	date as yup_date,
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
} from "yup";

import * as filtersStyle from './Filters.module.css';
import * as style from './HistoryFilters.module.css';

import { SmallChevron, Calendar } from './Icon.js';
import CmsContentList from 'components/data/CmsContentList.js';
import {
	map,
	values,
	includes,
} from 'lodash';
import {
	FILTER_TYPE_CURRENT_MONTH,
	FILTER_TYPE_DATE_RANGE,
	FILTER_TYPE_SELECTED_DATE, NO_FILTERS,
	X_DAYS_PRIOR_OPTIONS,
	FILTER_TYPE_TRIP,
} from './FiltersConstants.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { addYupMethod } from "utils/YupValidators.js";
import subDays from 'date-fns/subDays';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import Input from 'components/forms/Input.js';
import { ISODatePart } from 'utils/FormatHelpers.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import parseISO from 'date-fns/parseISO';
import Select from 'components/forms/inputs/Select.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";

import {
	ALL_MODES,
	BUS,
	RAPID_TRANSIT,
	COMMUTER_RAIL,
	COMMUTER_FERRY,
	INNER_HARBOR_FERRY,
	PC_BUS,
	RETAILER,
} from 'utils/Constants.js';

import Button from 'components/Button.js';
import { Lifecycles } from "libreact/lib/Lifecycles";
import PreventDefault from 'utils/PreventDefault.js';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import { COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";

const TRAVEL_MODES = [
	ALL_MODES,
	BUS,
	RAPID_TRANSIT,
	COMMUTER_RAIL,
	COMMUTER_FERRY,
	INNER_HARBOR_FERRY,
	PC_BUS,
	RETAILER,
];

const cms = {
	invalidStartDateInput: getErrorKey(COLLECTIONS.tripHistory, 'startDateTime', 'errors.invalid.date'),
	specificDateRequired: getErrorKey(COLLECTIONS.tripHistory, 'startDateTime', 'errors.general.value.required'),
	specificDateFuture: getErrorKey(COLLECTIONS.tripHistory, 'startDateTime', 'errors.invalid.date'),
	startDateTimeRequired: getErrorKey(COLLECTIONS.tripHistory, 'startDateTime', 'errors.general.value.required'),
	endDateTimeRequired: getErrorKey(COLLECTIONS.tripHistory, 'endDateTime', 'errors.general.value.required'),
	endDateFuture: getErrorKey(COLLECTIONS.tripHistory, 'endDateTime', 'errors.invalid.date'),
	startDateNotGreater: getErrorKey(COLLECTIONS.tripHistory, 'endDateTime', 'errors.general.enddate.before.startdate'),
	purchaseHistoryFilterDate: 'miscText["purchasehistory-filter-date.label"]',
	tripHistoryFilterDate: 'miscText["triphistory-filter-date.label"]',
	filterModeLabel: 'miscText["triphistory-filter-mode.label"]',
	filterModeAll: 'miscText.triphistory-filter-mode-all',
	applyFilterTripBtnTitle: 'miscText.triphistory-filter-submit',
	applyFilterPurchaseBtnTitle: 'miscText.purchasehistory-filter-submit',
};

export const schemaForFilterType = (dateFilterType) => {
	const sharedSchema = {
		dateFilterType: yup_string()
			.required('miscText.validation-RnC-filter-dateFilterType-required')
			.trim(),
	};

	const baseSchemaUsers = [
		FILTER_TYPE_CURRENT_MONTH,
		...X_DAYS_PRIOR_OPTIONS,
	];

	if (includes(baseSchemaUsers, dateFilterType)) {
		return yup_object().shape({
			...sharedSchema,
		});
	}

	switch (dateFilterType) {
		case FILTER_TYPE_SELECTED_DATE:
			return yup_object().shape({
				...sharedSchema,
				specificDate: yup_date()
					.required(cms.specificDateRequired)
					.max(new Date(), cms.specificDateFuture)
					.typeError(cms.invalidStartDateInput),
			});

		case FILTER_TYPE_DATE_RANGE:
			return yup_object().shape({
				...sharedSchema,
				startDateTime: yup_date()
					.typeError(cms.invalidStartDateInput)
					.required(cms.startDateTimeRequired),
				endDateTime: yup_date()
					.max(new Date(), cms.endDateFuture)
					.validDateRange(
						yup_ref("startDateTime"),
						cms.startDateNotGreater,
					)
					.typeError(cms.invalidStartDateInput)
					.required(cms.endDateTimeRequired),
			});
		default:
			throw new Error(`unknown dateFilterType`);
	}
};

addYupMethod("validShortDate");
addYupMethod("validDateRange");

const DateRanges = ({
	filterdaysLimit,
	filters,
	formHelper,
}) => {
	const today = useMemo(() => ISODatePart(new Date()),[]);

	return (<>
		<div className={style.calendarInputWrapper}>
			<Input
				name="startDateTime"
				data-qa="allDatesOption"
				type="date"
				min={filterdaysLimit}
				max={today}
				overrideClass={style.calendarInput}
				aria-label={"Start date filter input"}
				defaultValue={filters.startDateTime}
				error={Boolean(formHelper.getFieldError('startDateTime'))}
			/>
			<Calendar
				overrideClass={cx(style.calendarIcon, style.dateRange)}
				aria-hidden="true"
				data-qa="calendar-icon"
			/>
		</div>
		<div className={style.calendarInputWrapper}>
			<Input
				name="endDateTime"
				data-qa="allDatesOption"
				type="date"
				min={filterdaysLimit}
				max={today}
				overrideClass={style.calendarInput}
				aria-label={"End date filter input"}
				defaultValue={filters.endDateTime}
				error={Boolean(formHelper.getFieldError('endDateTime'))}
			/>
			<Calendar
				overrideClass={cx(style.calendarIcon, style.dateRange)}
				aria-hidden="true"
				data-qa="calendar-icon"
			/>
		</div>
	</>
	);
};

const HistoryFilters = ({
	title,
	filters = NO_FILTERS,
	applyFilter = () => { },
	filterType = null,
	parentError = null,
	...rest
}) => {
	const today = useMemo(() => ISODatePart(new Date()),[]);
	const [ isVisible, setIsVisible ] = useState(false);
	const [ dateFilterType, setDateFilterType ] = useState(FILTER_TYPE_DATE_RANGE);

	const getYupSchema = () => {
		return schemaForFilterType(FILTER_TYPE_DATE_RANGE);
	};

	const getDataToValidate = (formHelper) => {
		const dataToValidate = formHelper.getFormData();
		const dateFilterType = dataToValidate.dateFilterType;
		if (includes(X_DAYS_PRIOR_OPTIONS, dateFilterType)) {
			const now = new Date();
			const startDate = subDays(now, dateFilterType);
			const startDateMidnight = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
			dataToValidate.startDateTime = startDateMidnight;
			dataToValidate.endDateTime = now;
		}

		return dataToValidate;
	};

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({
		getYupSchema,
		getDataToValidate: (formHelper) => getDataToValidate(formHelper),
	});

	useEffect(() => {
		parentError && formHelper.validationErrorHandler(parentError);
	}, [
		formHelper,
		parentError,
	]);

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			noticeError(null, levels.info, errorReport, `filters validation failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmitting(false);
		}

		GoogleAnalytics.logEvent("User is attempting to a fetch Trips, Charges and Balance with filter method");

		formHelper.clearAllErrors();

		applyFilter({
			dateFilterType,
			startDateTime: validated.startDateTime ? ISODatePart(validated.startDateTime) : null,
			endDateTime: validated.endDateTime ? ISODatePart(validated.endDateTime) : null,
			specificDate: validated.specificDate ? ISODatePart(validated.specificDate) : null,
		});
	};

	const onSubmit = () => {
		if (formHelper.hasErrors()) {
			return;
		}

		kickoffSubmit();
	};

	const toggleVisibility = () => {
		setIsVisible(wasVisible => !wasVisible);
	};

	// Calculate the min date and return in YYYY-DD-MM format. If no RNC_MAX_DAYS variable was provided, use default.
	const filterdaysLimit = PublicAppVars.RNC_MAX_DAYS
		? ISODatePart(subDays(parseISO(today), PublicAppVars.RNC_MAX_DAYS))
		: "2019-01-01";

	const filterOptions = map(TRAVEL_MODES, mode => ({
		value: mode,
		label: mode,
		filters: true,
	}));

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Lifecycles didMount={() => formHelper.wireInputs()}>
				<form
					method="post"
					data-qa="addPaymentMethodForm"
					onSubmit={PreventDefault(() => onSubmit())}
					ref={formRef}
					id="filterChargesForm"
					aria-label="Filter Charges Options"
					tabIndex="0"
					className={style.outlineNone}
				>
					<div
						className={filtersStyle.container}
						data-qa="FiltersContainer"
						{...rest}
					>
						<h2 className={filtersStyle.title} data-qa="FiltersTitle">
							<CmsContentRenderedInline
								contentKey={title}
								fallbackValue={filterType === FILTER_TYPE_TRIP ? "Filter Rides" : "Filter Purchase History"}
							/>

							<SmallChevron className={cx(filtersStyle.chevron, isVisible ? filtersStyle.isReverse : '')}
								onClick={toggleVisibility} />

						</h2>

						<div className={cx(filtersStyle.row, isVisible ? "" : filtersStyle.rowHidden)}>
							<div>
								<CmsContentRenderer.Span
									className={style.dateLabel}
									contentKey={filterType === FILTER_TYPE_TRIP ? cms.tripHistoryFilterDate : cms.purchaseHistoryFilterDate}
									fallbackValue={filterType === FILTER_TYPE_TRIP ? "By Date" : "Date"}
									aria-label={"Date range filters"}
								/>
								<div className={style.dateWrapper}>
									<DateRanges {...{
										filterdaysLimit,
										filters,
										formHelper,
									}}/>

									{filterType === FILTER_TYPE_TRIP && PublicAppVars.ENABLE_MODE_FILTER ?
										<div className={style.modesWrapper}>
											<CmsContentRenderedInline
												className={style.labelFilter}
												contentKey={cms.filterModeLabel}
												fallbackValue="By Mode"
											/>
											<Select
												name="modesSelector"
												options={filterOptions}
												data-qa="travel-modes-filter-selector"
												searchable={false}
												defaultValue={filterOptions.find(filter => filter.label === ALL_MODES) ?? filterOptions[ 0 ]}
												error={formHelper.getFieldError('modesSelector')}
												overrideClass={style.modesSelector}
											/>
										</div>
										: null
									}
								</div>
							</div>

							<div className={style.errWrapper}>
								{formHelper.getFieldErrorJsx('startDateTime')}
								{formHelper.getFieldErrorJsx('endDateTime')}
							</div>
							<Input
								name="dateFilterType"
								type="hidden"
								value={dateFilterType}
								controlled={true}
							/>
							<div className={style.applyFiltersBtnWrapper}>
								<Button
									additionalClassNames={style.applyFiltersBtn}
									data-qa="applyFilterBtn"
									{...{ submitting }}
								>
									<CmsContentRenderer.Span
										contentKey={filterType === FILTER_TYPE_TRIP ? cms.applyFilterTripBtnTitle : cms.applyFilterPurchaseBtnTitle}
										fallbackValue={"Apply Filter"}
									/>
								</Button>
							</div>
						</div>
					</div>
				</form>
			</Lifecycles>
		)}</CmsContentList>
	);
};

export default HistoryFilters;
