import { Mutation } from "@apollo/client/react/components";
import React from 'react';
import PropTypes from "prop-types";
import { get } from 'lodash';

import {
	levels,
	noticeError,
} from "utils/Logger.js";
import {
	deserializeGraphqlRequestError,
	errorIsGraphqlRequestError,
} from "utils/error-handling/graphql/GraphqlClientMiddleware.js";

import LoadingIcon, { SIZES } from 'components/icons/LoadingIcon.js';




class StandardMutation extends React.Component {
	static propTypes = {
		mutation: PropTypes.object.isRequired,

		// if either of these are null when they'd be used, they fall through to this.props.children
		loadingChildren: PropTypes.object,
		errorChildren: PropTypes.object,

		// if this is null, errorChildren is used instead
		renderErrorChildren: PropTypes.object,

		// if true, the loading state is shown (default is true)
		showLoadingState: PropTypes.bool,
	};

	static defaultProps = {
		loadingChildren: <LoadingIcon />,
		errorChildren: <span>GraphQL Mutation Error</span>,
		renderErrorChildren: <span>GraphQL Mutation Render Error</span>,
		showLoadingState: true,
	};

	constructor(props) {
		super(props);

		this.lastReportedError = null;
	}

	loadedRender(mutateFunction, result) {
		// We'll act as an ErrorBoundary. Not using one because they don't work server-side.
		let toRender;
		try {
			toRender = this.props.children(mutateFunction, result);
		}
		catch (error) {
			const queryName = get(this.props, 'query.definitions[0].name.value');
			const errorMessage = `${get(error, 'message')} error when rendering Mutation ${queryName} children ${this.props.children.toString()} with props ${JSON.stringify(this.props)}`;
			noticeError(null, levels.error, error, errorMessage);
			return this.props.renderErrorChildren
				? this.props.renderErrorChildren
				: this.props.errorChildren;
		}

		// Mutation's handling of undefined is really hard to debug. Let's do our own logging instead.
		if (toRender === undefined) {
			const queryName = get(this.props, 'query.definitions[0].name.value');
			console.error(`nothing to render for Mutation ${queryName} children ${this.props.children.toString()} with props ${JSON.stringify(this.props)}`);
			return this.props.renderErrorChildren
				? this.props.renderErrorChildren
				: this.props.errorChildren;
		}
		return toRender;
	}

	logFirstErrorOccurrence(error) {
		if (this.lastReportedError === error) {
			return;
		}
		this.lastReportedError = error;

		// we log non-GraphqlRequestError errors because they might happen on the initial query
		noticeError(null, levels.error, error);
	}

	render() {
		const {
			loadingChildren,
			errorChildren,
			showLoadingState,
			...passthroughProps
		} = this.props;

		return (
			<Mutation {...passthroughProps}>
				{(mutateFunction, result) => {
					// lists the other variables available
					// https://www.apollographql.com/docs/react/essentials/mutations.html#render-prop

					const {
						loading,
						error,
					} = result;

					if (loading && showLoadingState) {
						return loadingChildren;
					}

					if (error) {
						const requestError = deserializeGraphqlRequestError(error);

						// it'd be nice to use instanceof here, but that doesn't work with the es5 code this is transpiled to
						if (errorIsGraphqlRequestError(requestError)) {
							// we can assume the caller is logging this when they run the mutation
							//noticeError(null, levels.info, requestError);
							result[ 'submitErrors' ] = requestError.report;
						}
						else {
							this.logFirstErrorOccurrence(error);
							if (errorChildren) {
								return errorChildren;
							}
						}
					}

					return this.loadedRender(mutateFunction, result);
				}}
			</Mutation>
		);
	}
}

export default StandardMutation;
