import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';

import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";
import CmsContentList from "components/data/CmsContentList.js";
import { Search } from 'components/Icon.js';

import * as retail from 'pages/RetailLocations.module.css';


// Google Geocoder docs
// https://developers.google.com/maps/documentation/javascript/reference/geocoder#GeocoderStatus
// Google Autocomplete docs
// https://developers.google.com/maps/documentation/javascript/places-autocomplete
// example
// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete
// api
// https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete

const cms = {
	enterLocation: 'miscText.retailLocations-search-enterLocation',
	myLocation: 'miscText.retailLocations-search-myCurrentLocation',
	mapView: 'miscText.retailLocations-search-mapView',
	searchLabel: 'miscText["location-finder-search.label"]',
};

const LocationAutocomplete = ({
	usedGeolocation,
	mapSearch,
	onAddressChange,
	bounds,
	cmsContent,
	setGeolocationBlocked,
}) => {
	const inputRef = useRef();
	const [ autocomplete, setAutocomplete ] = useState(null);
	const [ prediction, setPrediction ] = useState(null);

	const options = useMemo(() => ({
		fields: [ 'geometry' ],
		// https://developers.google.com/maps/documentation/javascript/reference/places-widget#AutocompleteOptions.strictBounds
		strictBounds: true,
		bounds,
		componentRestrictions: {
			'country': [ 'us' ],
		},
	}), [ bounds ]);

	// initialization
	useEffect(() => {
		// if this errors, make sure MapsApiLoader is returning isLoaded
		const ac = new window.google.maps.places.Autocomplete(inputRef.current, options);
		setAutocomplete(ac);

		ac.addListener('place_changed', () => {
			// https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
			const place = ac.getPlace();
			onAddressChange(place);
		});

		setAutocomplete(null);
	}, [ onAddressChange, setAutocomplete, bounds, options ]);

	useEffect(() => {
		if (mapSearch || usedGeolocation) {
			inputRef.current.value = null;
		}
	}, [ mapSearch, usedGeolocation ]);

	useEffect(() => {
		if (!autocomplete) {
			return;
		}

		// bounds prop changed. Tell autocomplete about it.
		autocomplete.setBounds(bounds);
	}, [ autocomplete, bounds ]);

	// Set the prediction to get the place from geocode services
	useEffect(() => {
		if (prediction) {
			new window.google.maps.Geocoder().geocode({ address: prediction },
				function (results, status) {
					if (status !== window.google.maps.GeocoderStatus.OK || !results) {
						return;
					}

					// This object here is a "place"
					onAddressChange(results[ 0 ]);
				}
			);

			setPrediction(null);
		}
	}, [ prediction, onAddressChange ]);


	// On enter with valid input we will get the first result from the predictions
	const getFirstItemFromPredictions = () => {
		if (!inputRef.current.value) {
			return null;
		}

		const service = new window.google.maps.places.AutocompleteService();

		const request = {
			input: inputRef.current.value,
			bounds,
			componentRestrictions: {
				'country': [ 'us' ],
			},
		};

		service.getQueryPredictions(request,
			function (predictions, status) {
				if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
					return;
				}

				setPrediction(predictions[ 0 ]?.description);
				setGeolocationBlocked(false);
			}
		);
	};

	return (
		<>
			<CmsContentRenderer.Span
				contentKey={cms.searchLabel}
				fallbackValue="Find a Location Near You"
				className={retail.autocompleteLabel}
			/>
			<div id="searchLocation" className={retail.autocomplete}>
				<div>
					<input
						id="locationAutocomplete"
						ref={inputRef}
						placeholder={findContentOrFallback(cmsContent, cms.searchLabel, 'Enter Location')}
						className={retail.autocompleteInput}
						onKeyDown={(event) => event.key === "Enter"  && getFirstItemFromPredictions()}
						{...(prediction ? { value: prediction } : {})}
					/>
					<button
						className={retail.autocompleteBtn}
						onClick={getFirstItemFromPredictions}
					>
						<Search overrideClass={retail.autocompleteBtnIcon} aria-hidden={true} />
					</button>
				</div>
			</div>
		</>
	);
};

// wait to create a LocationAutocomplete until we have cms content so that its useEffects have an input element
const LocationAutocompleteCms = (props) => <CmsContentList list={values(cms)}>{({ cmsContent }) =>
	<LocationAutocomplete {...{
		...props,
		cmsContent,
	}} />
}</CmsContentList>;

LocationAutocomplete.propTypes = {
	onAddressChange: PropTypes.func.isRequired,
	bounds: PropTypes.object.isRequired,
};

export default LocationAutocompleteCms;
