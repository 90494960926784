import { get } from "lodash";
import { ParkingOnly, STORED_VALUE } from "../Constants";

export const getAccountBalance = (transitAccountQ) => {
	const currentPurses = get(transitAccountQ, 'purses', []);
	const validPurses = currentPurses.filter(purse => purse.purseType !== STORED_VALUE || purse.purseRestriction !== ParkingOnly);
	const currentBalance = validPurses.reduce((acc, cur) => {
		return acc + cur.balance;
	}, 0);
	return currentBalance;
};
