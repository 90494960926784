import React, {
	useContext, useState,
} from 'react';
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import { find, reduce, values } from "lodash";
import cx from 'classnames';

import Panel from 'components/Panel.js';
import Select from 'components/forms/inputs/Select.js';
import Toggle from 'components/forms/Toggle.js';
import AccessibilityPreferences, {
	GET_ACCESSIBILITY_PREFERENCES,
	SET_ACCESSIBILITY_PREFERENCES,
} from 'components/data/AccessibilityPreferences.js';
import { getOptions as getLanguageOptions } from 'layouts/components/LanguageSelector.js';
import { LanguageGet, LanguageSet } from 'components/data/Language.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { useModalContext } from 'context/ModalProvider.js';
import LanguageConfirmation from 'components/modals/LanguageConfirmation.js';
import CmsContentList from 'components/data/CmsContentList.js';

import * as panelStyle from 'components/Panel.module.css';
import * as userPreferencesStyle from './UserPreferences.module.css';
import * as typography from "styles/typography.module.css";

import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { WireFormHelper } from "utils/FormHelper.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { levels, noticeError } from "utils/Logger.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import PendingChangesPrompt from 'components/PendingChangesPrompt';
import { getPathByRoute } from 'App';
import routeKeys from 'CustomerRouteKeys';

const cms = {
	subheader: "miscText.preferences-subheader",
	language: "miscText.preferences-language",
	languageInfo: "miscText.preferences-language-info",
	highContrast: "miscText.preferences-highcontrast",
	highContrastInfo: "miscText.preferences-highcontrast-info",
	audioMode: "miscText.preferences-audiomode",
	audioModeInfo: "miscText.preferences-audiomode-info",
	extendTimeout: "miscText.preferences-extendtimeout",
	extendTimeoutInfo: "miscText.preferences-extendtimeout-info",
	uncheckedText: 'miscText.general-toggle-off',
	checkedText: 'miscText.general-toggle-on',
};

const getYupSchema = () => {
	return yup_object().shape({
		lang: yup_string().required(),
		sessionExtend: yup_string(), // 'on'
		fontMagnify: yup_string(), // 'on'
		textAudio: yup_string(), // 'on'
	});
};

const TOGGLE_ON_VALUE = 'on';

const UserPreferences = () => {
	const language = useContext(LanguageGet);
	const setLanguage = useContext(LanguageSet);
	const { setModal } = useModalContext();
	const [ currentLoadingToggle, setCurrentLoadingToggle ] = useState();

	const [ updatePreferenceMutation ] = useStandardMutation(SET_ACCESSIBILITY_PREFERENCES, {
		refetchQueries: [
			{ query: GET_ACCESSIBILITY_PREFERENCES },
		],
	});

	const languageOptions = getLanguageOptions();
	const initialLanguageOption = find(languageOptions, { value: language });

	const onConfirmLanguageSelection = (newLanguage, langKey) => {
		setLanguage(newLanguage, langKey);
		setModal(null);
	};

	const { formRef, formHelper, submitting, setSubmitting } = useFormHelper({
		getYupSchema,
	});

	const kickoffSubmit = async (event) => {
		setSubmitting(true);
		let validated;
		try {
			validated = await formHelper.startValidation(true, null);
			formHelper.clearAllErrors();
		} catch (errorReport) {
			noticeError(null, levels.verbose, errorReport, `Update Accessibility Preferences Validation!`);
			formHelper.validationErrorHandler(errorReport);
			event.target.checked = false;
			setSubmitting(false);
			return;
		}

		setCurrentLoadingToggle(event.target.name);

		const wsAcessibilityPreferences = reduce(validated, (result, value, key) => {
			const resultValue = value === TOGGLE_ON_VALUE ? true : value;

			return { ...result, [ key ]: resultValue };
		}, {});

		GoogleAnalytics.logEvent("User is attempting to update Accessibility Preferences.");

		try {
			await graphqlErrorMiddleware(updatePreferenceMutation({
				variables: wsAcessibilityPreferences,
			}));
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			event.target.checked = false;
		} finally {
			setCurrentLoadingToggle(false);
		}

		setSubmitting(false);
	};

	return (
		<CmsContentList list={values(cms)}>{() => (
			<AccessibilityPreferences>
				{({ accessibilityPreferences: wsAccessibilityPreference }) => (
					<Panel>
						<CmsContentRenderer.H2
							className={cx(typography.h7, panelStyle.heading)}
							contentKey={cms.subheader}
							fallbackValue="Preferences"
						/>
						<WireFormHelper formHelper={formHelper}>
							<PendingChangesPrompt submitting={submitting} route={getPathByRoute(routeKeys.AccountPersonalInformation)} />
							<form
								data-qa="accessibilityPreferencesForm"
								ref={formRef}
								onSubmit={(event) => event.preventDefault()}
							>
								{formHelper.getFieldErrorJsx('')}
								{formHelper.getFieldErrorJsx('lang')}
								{formHelper.getFieldErrorJsx('fontMagnify')}
								{formHelper.getFieldErrorJsx('textAudio')}
								{formHelper.getFieldErrorJsx('sessionExtend')}

								<Select
									horizontal
									name="lang"
									selectWrapperClasses={userPreferencesStyle.languageSelect}
									defaultValue={language}
									label={<CmsContentRenderer.Span
										className={cx(userPreferencesStyle.label, userPreferencesStyle.languageLabel)}
										contentKey={cms.language}
										fallbackValue="Language"
									/>}
									helpText={<CmsContentRenderer.P
										className={userPreferencesStyle.helpText}
										contentKey={cms.languageInfo}
										fallbackValue="Select the language you are most comfortable with"
									/>}
									options={languageOptions}
									onChange={(inputValue) => {
										// e.g. .value 'es'
										if (inputValue.value === language) {
											return;
										}

										setModal(
											<LanguageConfirmation
												currentlang={language}
												selectedlang={inputValue.value}
												onCancelLanguageSelection={() => setModal(null)}
												onConfirmLanguageSelection={onConfirmLanguageSelection}
											/>,
										);
									}}
									isClearable={false}
									isMulti={false}
									isSearchable={false}
									value={initialLanguageOption}
									blurInputOnSelect
								/>

								<div className={cx(userPreferencesStyle.toggleRow)}>
									<div className={cx(userPreferencesStyle.toggleDescription)}>
										<CmsContentRenderer.H2
											className={userPreferencesStyle.label}
											contentKey={cms.highContrast}
											fallbackValue="High Contrast Mode"
										/>
										<CmsContentRenderer.P
											className={userPreferencesStyle.helpText}
											contentKey={cms.highContrastInfo}
											fallbackValue="Enables high contrast mode at fare vending machines by default"
										/>
									</div>
									<div className={cx(userPreferencesStyle.toggleButton)} >
										<Toggle
											name="fontMagnify"
											onChange={(event) => kickoffSubmit(event)}
											defaultChecked={wsAccessibilityPreference.fontMagnify}
											value={TOGGLE_ON_VALUE}
											overrideClass={userPreferencesStyle.toggle}
											loading={currentLoadingToggle === 'fontMagnify'}
										/>
									</div>
								</div>

								<div className={cx(userPreferencesStyle.toggleRow)}>
									<div className={cx(userPreferencesStyle.toggleDescription)}>
										<CmsContentRenderer.H2
											className={userPreferencesStyle.label}
											contentKey={cms.audioMode}
											fallbackValue="Audio Mode"
										/>
										<CmsContentRenderer.P
											className={userPreferencesStyle.helpText}
											contentKey={cms.audioModeInfo}
											fallbackValue="Enables audio controls at fare vending machines by default"
										/>
									</div>
									<div className={cx(userPreferencesStyle.toggleButton)} >
										<Toggle
											name="textAudio"
											onChange={(event) => kickoffSubmit(event)}
											defaultChecked={wsAccessibilityPreference.textAudio}
											value={TOGGLE_ON_VALUE}
											overrideClass={userPreferencesStyle.toggle}
											loading={currentLoadingToggle === 'textAudio'}
										/>
									</div>
								</div>

								<div className={cx(userPreferencesStyle.toggleRow)}>
									<div className={cx(userPreferencesStyle.toggleDescription)} >
										<CmsContentRenderer.H2
											className={userPreferencesStyle.label}
											contentKey={cms.extendTimeout}
											fallbackValue="Extend Transaction Timeout"
										/>
										<CmsContentRenderer.P
											className={userPreferencesStyle.helpText}
											contentKey={cms.extendTimeoutInfo}
											fallbackValue="Extends the time you can take to complete a transaction at fare vending machines by default"
										/>
									</div>
									<div className={cx(userPreferencesStyle.toggleButton)} >
										<Toggle
											name="sessionExtend"
											onChange={(event) => kickoffSubmit(event)}
											defaultChecked={wsAccessibilityPreference.sessionExtend}
											value={TOGGLE_ON_VALUE}
											overrideClass={userPreferencesStyle.toggle}
											loading={currentLoadingToggle === 'sessionExtend'}
										/>
									</div>

								</div>

							</form>
						</WireFormHelper>
					</Panel>
				)}
			</AccessibilityPreferences>
		)}</CmsContentList>
	);
};

export default UserPreferences;
