import React from 'react';
import Modal from 'components/Modal.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import * as modal from 'components/Modal.module.css';
import * as style from 'components/modals/ErrorAutoload.module.css';
import Button from "components/Button.js";

const cms = {
	unlinkErrorHeader: "miscText.card-settings-manage-unlink-error-header",
	unlinkErrorAutoload: "miscHtml.card-settings-manage-unlink-error-autoload",
	unlinkErrorSubmit: "miscText.card-settings-manage-unlink-error-submit",
};

const ErrorAutoload = ({ onModalClose, cmsContent }) => {
	return (
		<div>
			<Modal title={
				<CmsContentRenderer.Span
					content={cmsContent[ cms.unlinkErrorHeader ]}
					fallbackValue={"You may not remove this card"}
					className={modal.largeHeader}
					modalActive={true}
				/>
			}
			overrideClass={style.contents}
			showCloseBtn={false}
			>

				<CmsContentRenderer.Span
					content={cmsContent[ cms.unlinkErrorAutoload ]}
					fallbackValue={"<p>You must disable all balance and pass autoloads before removing this card.</p>"}
					rawHtml={true}
				/>
				<div className={ modal.rightAlign }>
					<Button
						overrideClass={modal.btn}
						isPrimary={true}
						onClick={() => {
							onModalClose();
						}}
					>
						<CmsContentRenderer.Span
							fallbackValue={"I understand"}
							content={cmsContent[ cms.unlinkErrorSubmit ]}
						/>
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default ErrorAutoload;