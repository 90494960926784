import cx from "classnames";
import React from 'react';

import TakeOverLayout from 'layouts/TakeOverLayout.js';
import Cart from 'components/payments/Cart.js';

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import SelectPaymentMethodForm from 'components/account/card/add-passes/SelectPaymentMethodForm.js';

import { values } from 'lodash';

import {
	object as yup_object,
	string as yup_string,
	boolean as yup_boolean,
} from 'yup';
import { WireFormHelper } from 'utils/FormHelper.js';
import PreventDefault from 'utils/PreventDefault.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import {
	getCartTotal,
	useCartContext,
} from 'context/CartProvider.js';
import { getYupSchema as getAddPaymentMethodYupSchema , addExpiryDateFields } from 'pages/account/purchase/AddPaymentMethod.js';

import CartLeavePagePrompt from 'pages/account/purchase/CartLeavePagePrompt.js';
import ApplyStoredValueOption from 'pages/account/purchase/ApplyStoredValueOption.js';
import { useStepContext } from 'context/StepContext.js';
import { STANDARD_PURCHASE_FLOW_STEPS } from 'pages/account/purchase/StandardPurchaseFlow.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { cms as purchaseCmsKeys } from 'pages/account/purchase/product-catalog-takeover/constants.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { EnablementFee } from 'pages/account/PurchaseCharlieCardCcForm.js';
import useFormHelper from "utils/form-helper/useFormHelper";

import * as tabs from 'styles/Tabs.module.css';
import * as style from './PurchaseProductPayment.module.css';
import * as typography from 'styles/typography.module.css';

import { useLoginStage } from "components/data/session-user/LoggingIn";
import loginStages from "components/data/session-user/LoginStages";
import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";
import { levels, noticeError } from "utils/Logger.js";
import { useIsEmvCard } from "components/data/transit-account/EMV.helpers";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext";

const cms = {
	backTo: 'miscText.purchase-step3-back-catalogue',
	paymentHeader: 'miscText.autoload-payment-header',
};

export const getPrimaryBillingAddressSchema = ({
	formInputNamePrefix = '',
}) => {
	const getInputName = (fieldName) => (formInputNamePrefix + fieldName);

	const validations = {
		[ getInputName('primaryBilling') ]: yup_boolean(),
		[ getInputName('primaryShipping') ]: yup_boolean(),
		[ getInputName('saveAddress') ]: yup_boolean()
			.when(getInputName('primaryShipping'), {
				is: (primaryShipping) => Boolean(primaryShipping),
				then: yup_boolean()
					.required()
					/** @todo: Add cms translation key */
					.oneOf([ true ], "miscText.general-address-error-shipping-without-save"),
				otherwise: yup_boolean(),
			})
			.when(getInputName('primarBilling'), {
				is: (primaryBilling) => Boolean(primaryBilling),
				then: yup_boolean()
					.required()
					/** @todo: Add cms translation key */
					.oneOf([ true ], "miscText.general-address-error-billing-without-save"),
				otherwise: yup_boolean(),
			}),
	};
	return yup_object().shape(validations);
};

export const getPrimaryPaymentMethodValiations = ({
	primaryPaymentMethod: yup_boolean(),
	savePayment: yup_boolean()
		.when('primaryPaymentMethod', {
			is: (primaryPaymentMethod) => Boolean(primaryPaymentMethod),
			then: yup_boolean()
				.required()
				/** @todo: Add cms translation key */
				.oneOf([ true ], "miscText.general-payment-error-default-without-save"),
			otherwise: yup_boolean(),
		}),
});

export const getPrimaryPaymentMethodSchema = () => {
	const validations = {
		...getPrimaryPaymentMethodValiations,
	};
	return yup_object().shape(validations);
};

export const getPaymentValidations = ({
	formInputNamePrefix = '',
}) => ({
	// TODO add cms translations for each field
	[ formInputNamePrefix + 'newNumber' ]: yup_string()
		.required('Please enter a card number')
		.trim(),
	[ formInputNamePrefix + 'newName' ]: yup_string()
		.required('Please enter a cardholder name')
		.trim(),
	[ formInputNamePrefix + 'newExp' ]: yup_string()
		.required('Please enter an expiration date')
		.trim(),
	[ formInputNamePrefix + 'newCVC' ]: yup_string()
		.required('Please enter cvv code')
		.trim(),
});

const PurchaseProductPayment = ({ cancelUrl }) => {
	const { step, prevStep, nextStep } = useStepContext();
	const {	isMultiPayment, selectedFundingSources } = useFundingSourcesContext();
	const { loginStage } = useLoginStage();

	const subsystemAccountReference = useTransitAccountIdContext();

	const isEmvCard = useIsEmvCard({ subsystemAccountReference });

	const { cart } = useCartContext();
	const total = getCartTotal(cart);
	const hasUnpaidFee = Boolean(cart.unpaidFee);
	const mobilePayment = false;

	// Card is not EMV
	// & a new card is selected or the user is unregistered
	const isNew = !isEmvCard && (selectedFundingSources[ 0 ]?.isNew || loginStage === loginStages.unRegisteredLoggedIn);

	const getYupSchema = (formHelper) => getAddPaymentMethodYupSchema({
		formHelper,
		isMultiPayment,
		isSelecting: [ false ],
		isAch: false,
		requireAchEula: false,
		isNew: [ isNew ],
		hasMobilePayment: Boolean(mobilePayment),
		total,
		// If the balance covers the entire purchase,
		// don't validate cvv
		disableCvv: total === 0,
	}).concat(getPrimaryPaymentMethodSchema());

	const getDataToValidate = (formHelper) => {
		const data = formHelper.getFormData();

		addExpiryDateFields({
			isMultiPayment,
			data,
		});

		return data;
	};

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema, getDataToValidate });

	const finalizeAndSetFundingSourcesToContext = useFinalizeAndSetFundingSourcesToContext({
		formHelper,
	});

	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {
			await finalizeAndSetFundingSourcesToContext();

			nextStep();
		} catch (errorReport) {
			// yup validation failed, but those errors are already in state
			noticeError(null, levels.info, errorReport, `Purchase product validation failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
		}
	};

	return (
		<CmsContentList list={values(cms)}>{() =>
			<WireFormHelper formHelper={formHelper}>
				<CartLeavePagePrompt />
				<form
					method='post'
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					<TakeOverLayout
						title={<CmsContentRenderedInline
							contentKey={purchaseCmsKeys.purchasNewProduct}
							fallbackValue="Purchase a New Product"
						/>}
						showCancel
						cancelLink={cancelUrl}
						backButton={{
							handleBackstep: prevStep,
							label: <CmsContentRenderer.Span contentKey={cms.backTo} fallbackValue='Back to Select Products' />,
						}}
						steps={STANDARD_PURCHASE_FLOW_STEPS}
						currentStep={step}
						showNickname
						containerOverrideClass={style.takeoverLayout}
					>
						<div className={tabs.mainContent}>
							<div className={cx(style.container, hasUnpaidFee && style.feeWrapper)}>
								<CmsContentRenderer.H2
									className={typography.h7}
									contentKey={cms.paymentHeader}
									fallbackValue='How Would You Like To Pay?'
								/>
								<ApplyStoredValueOption {...{ formHelper }} />
								<div className={hasUnpaidFee && style.paymentWFeeWrapper}>
									<SelectPaymentMethodForm {...{
										formHelper,
										isFirst: true,
									}} />
									{hasUnpaidFee &&
										<div className={style.enablementFeeContainer}>
											<EnablementFee overrideClass={style.enablementFee} />
										</div>
									}
								</div>
							</div>
						</div>
					</TakeOverLayout>
					<Cart {...{ formHelper, submitting }} />
				</form>
			</WireFormHelper>
		}</CmsContentList>
	);
};

export default PurchaseProductPayment;
