export const TWO_FACTOR_DELIVERY_OPTIONS = {
	email: 'EMAIL',
	sms: 'SMS',
};

// 2.7.17 WSAuthCodeDeliveryOption
export default class WSAuthCodeDeliveryOption {
	constructor({
		maskedDestination,
		channel,
	}) {
		// string(250)
		// (Condionally-Required) A masked description of the
		// destination, in most cases this will be a masked value
		// of the contact number for the channel. Provided if
		// calling API /authcodedeliveryoptions
		this.maskedDestination = maskedDestination;

		// string(20)
		// (Required) This field will contain one of the following
		// values:
		//   EMAIL
		//   SMS
		this.channel = channel;
	}

	static fromBackoffice(data) {
		const result = new WSAuthCodeDeliveryOption(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

export const WSAuthCodeDeliveryOptionType = [
	`type WSAuthCodeDeliveryOption {
		maskedDestination: String!
    	channel: String!
	}`,
];

export const InputWSAuthCodeDeliveryOptionType = [
	`input InputWSAuthCodeDeliveryOption {
		maskedDestination: String!
    	channel: String!
	}`,
];