import { useJsApiLoader } from "@react-google-maps/api";
import PublicAppVars from "utils/PublicAppVars.js";
import { useCspNonceContext } from "components/CspNonceContext.js";
import { createContext, useContext, useEffect } from "react";



// This is a separate const to avoid
// "Performance warning! LoadScript has been reloaded unintentionally! You should not pass `libraries` prop as new array. Please keep an array of libraries as static class property for Components and PureComponents, or just a const variable outside of component, or somewhere in config files or ENV variables"
const gmapLibraries = [
	"geometry",
	"drawing",
	"places",
];

export const MapsLoaderContext = createContext(null);
export const useMapsLoaderContext = () => useContext(MapsLoaderContext);

/**
 * Loads the map js from google and sets the { isLoaded, loadError } result into context
 * @returns {null}
 * @constructor
 */
const MapsApiLoader = () => {
	const nonce = useCspNonceContext();
	const [ context, setContext ] = useMapsLoaderContext(); // expects something else to provide a state var
	// Note: useJsApiLoader uses a global Loader object that throws an error about different
	// args if rendered server-side by subsequent requests.

	const result = useJsApiLoader({
		id: 'google-map-script',
		nonce,
		googleMapsApiKey: PublicAppVars.GOOGLE_MAPS_API_KEY,
		libraries: gmapLibraries,
	});

	useEffect(() => {
		if (
			result.isLoaded !== context.isLoaded
			|| result.loadError !== context.loadError
		) {
			setContext(result);
		}
	}, [
		result,
		context,
		setContext,
	]);

	return null;
};

export default MapsApiLoader;
