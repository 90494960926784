import React from "react";
import {
	levels,
	log,
} from "utils/Logger.js";

// On the server, we can modify the session directly to logout
// On the client, we have to make an ajax request
// This context provides an isomorphic way to call either.

const LogoutRequestContext = React.createContext(() => {
	log(null, levels.error, "LogoutAjaxContext used before it was ready");
});
export default LogoutRequestContext;
