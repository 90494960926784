import React from 'react';
import { TakeOverLayoutWrapper } from 'layouts/TakeOverLayout.js';
import { incrementError } from 'components/account/purchases/ValuePurchaseTypeSelection.js';
import { useStepContext } from 'context/StepContext.js';

const cms = {
	emptySelectionError: "miscText.purchase-cart-error-empty",
	incrementError,
};

/**
 * This component renders purchase products tabs to be used by the standard purchase flow and the
 * purchase charlie cards flow.
 *
 * @todo The tabs themselves should not be rendered by the <TakeOverLayout/> itself but instead as a
 * subcomponent of TakeOverLayout's body.
 *
 * If we attempt to render a multi-step form we end up with
 * <TakeOverLayout/>
 *  <TakeOverLayout/>
 * </TakeOverLayout>
 *
 * For now we added a conditional, if we are on the add products step, then render the products <TakeOverLayout/>
 * outside the overall <TakeOverLayout/> wrapper.
 **/

const ProductTakeOverLayout = ({
	title,
	showNickname,
	tabs,
	steps,
	cancelUrl,
	showTakeOverLayout,
}) => {
	const { step } = useStepContext();

	return (
		<TakeOverLayoutWrapper {...{
			tabs,
			steps,
			title,
			showNickname,
			showTakeOverLayout,
		}}
		showCancel
		showTabs
		currentStep={step}
		cancelLink={cancelUrl}
		/>
	);
};

export default ProductTakeOverLayout;
