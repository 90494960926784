import React, { useRef } from 'react';
import PropTypes from "prop-types";
import cx from "classnames";

import FormHelper from "utils/FormHelper.js";

import * as inputStyles from "./Input.module.css";
import * as forms from './Forms.module.css';
import * as style from './Toggle.module.css';
import * as buttonStyle from 'components/Button.module.css';
import LoadingIcon from "components/icons/LoadingIcon.js";


/**
 * Generates a stylized checkbox with On / Off as the default labels. Uses the same
 * classes as <Control> but because of how its designed it requires a slightly
 * different internal structure.
 */
const Toggle = ({
	name,
	label = '',
	overrideClass,
	helpText,
	uncheckedText = 'Off',
	checkedText = 'On',
	defaultChecked = false,
	errorMsg = null,
	error = null,
	disabled = false,
	value = 'on',
	onChange = () => {},
	loading = false,
	...rest
}) => {
	const getFieldErrorJsx = () => {
		return FormHelper.errorJsx(error);
	};

	const inputRef = useRef();

	const handleOnKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			const { current } = inputRef;
			current.checked = !current.checked;
		}
	};

	const errorJsx = (getFieldErrorJsx() || <p className={forms.errorMsg}>{errorMsg}</p>);

	return (
		<div className={cx(forms.control, style.container, overrideClass)}>
			<div className={style.content}>
				<label className={cx(inputStyles.label, style.labelText)} htmlFor={name}>
					{label}
					<br />
					{helpText ? <span className={cx(forms.help, style.help)}>{helpText}</span> : null}
				</label>
				{errorJsx}
			</div>

			<div>
				<input
					ref={inputRef}
					onKeyPress={handleOnKeyPress}
					type="checkbox"
					// Autocomplete is technically invalid on checkboxes but gets firefox to stop remembering the value
					autoComplete={"off"}
					className={style.input}
					id={name}
					name={name}
					defaultChecked={defaultChecked}
					onChange={onChange}
					disabled={loading || disabled}
					value={value}
					{...rest}
				/>
				<label
					htmlFor={name}
					className={cx(style.label, loading && style.loading)}
				>
					{ loading && <LoadingIcon overrideClass={buttonStyle.spinner} /> }
					<span className={cx(style.text, style.textUnchecked)}>{uncheckedText}</span>
					<span className={cx(style.text, style.textChecked)}>{checkedText}</span>
				</label>
			</div>
		</div>
	);
};


/**
 * This will work around the problem noted here:
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#attr-checked
 * Namely, the checkboxes that are unchecked will not show up as fields in the FormData object.
 */
export const BoolToggle = (props) => <>
	<input type="hidden" value={false} name={props.name} />
	<Toggle value={true} {...props} />
</>;

Toggle.propTypes = {
	name: PropTypes.string.isRequired,
	uncheckedText: PropTypes.node,
	checkedText: PropTypes.node,
	label: PropTypes.node,
	hideLabel: PropTypes.bool,
	defaultChecked: PropTypes.bool,
	error: PropTypes.node,
	errorMsg: PropTypes.node,
	tabIndex: PropTypes.number,
	onChange: PropTypes.func,
	tooltip: PropTypes.string,
	disabled: PropTypes.bool,
	helpText: PropTypes.node,
};

export default Toggle;
