import { useApolloClient } from "@apollo/client";
import React, {
	useEffect,
	useState,
} from 'react';
import {
	head,
	values,
} from 'lodash';

import { useTransitAccountProductCatalog } from 'components/data/transit-account/ProductCatalog.query.js';
import CmsContentList from 'components/data/CmsContentList.js';
import FormHelper from 'utils/FormHelper.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { Lifecycles } from 'libreact/lib/Lifecycles';
import {
	getCartBalance,
	useCartContext,
} from 'context/CartProvider.js';
import PreventDefault from 'utils/PreventDefault.js';

import Cart from "components/payments/Cart.js";
import { getPaymentMethods } from 'pages/account/PurchasePass.js';
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import CartLeavePagePrompt from 'pages/account/purchase/CartLeavePagePrompt.js';
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { incrementError } from 'components/account/purchases/ValuePurchaseTypeSelection.js';
import { PURCHASE_FLOW_STEP_1_FORM_ID } from 'components/account/reload-balance/consts.js';
import { STANDARD_PURCHASE_FLOW_STEPS } from 'pages/account/purchase/StandardPurchaseFlow.js';
import { useStepContext } from 'context/StepContext.js';
import ProductTakeOverLayout from 'pages/account/purchase/product-catalog-takeover/ProductTakeOverLayout.js';
import { getPurchaseCatalogTabs } from 'pages/account/purchase/product-catalog-takeover/ProductTabs.js';
import { cms as purchaseCmsKeys } from 'pages/account/purchase/product-catalog-takeover/constants.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import ProductCatalogContext, {
	useProductCatalogContext,
} from "components/data/transit-account/ProductCatalogContext.js";

const cms = {
	emptySelectionError: "miscText.purchase-cart-error-empty",
	incrementError,
};

const PurchaseProductCatalog = ({
	cancelUrl,
}) => {
	const { step, nextStep } = useStepContext();

	const {
		cart,
		setNegativeBalance,
		saveShoppingCartId,
	} = useCartContext();

	const { initializeFundingSources } = useFundingSourcesContext();
	const [ submitting, setSubmitting ] = useState(false);
	const apolloClient = useApolloClient();
	const { purses, purseTotal, hasNegativeBalance, tokens } = usePurseBalanceContext();
	const tokenId = head(tokens)?.tokenId;

	const wSSubsystemPurse = head(purses);

	const transit_account_id = useTransitAccountIdContext();

	const {
		formRef,
		formHelper,
		setValidationState,
	} = useFormHelper({ getYupSchema: {} });


	const kickoffSubmit = async () => {
		setSubmitting(true);

		if (cart.products.length === 0 && (getCartBalance(cart) - cart.unpaidFee) === 0) {
			setValidationState({
				validationError: FormHelper.cmsKeyToJsx(cms.emptySelectionError),
			});
			setSubmitting(false);
			return;
		}

		let paymentMethodsRes;
		try {
			paymentMethodsRes = await getPaymentMethods({
				cart,
				apolloClient,
				subsystemAccountReference: transit_account_id,
				wSSubsystemPurse,
				tokenId,
			});
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Get Payment Methods Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		const {
			shoppingCartId,
			loadProductLineItemFunds,
			fundingSources,
			restrictFunding,
		} = paymentMethodsRes;

		saveShoppingCartId(shoppingCartId);
		initializeFundingSources({
			loadProductLineItemFunds,
			fundingSources, // might be empty
			restrictFunding,
		});

		setSubmitting(false);
		nextStep();
	};

	const didMount = () => {
		formHelper.wireInputs();
	};

	// When an account has a negative balance should we reflect the negative balance in the initial cart total
	useEffect(() => {
		if (hasNegativeBalance && !cart.negativeBalance) {
			setNegativeBalance(purseTotal);
		}
	}, [ hasNegativeBalance, cart.negativeBalance,purseTotal,setNegativeBalance ]);

	const productCatalog = useProductCatalogContext();

	const purchaseProductCatalogTabs = getPurchaseCatalogTabs({ formHelper, setValidationState, productCatalog });

	return (
		<CmsContentList list={values(cms)}>{() => {
			return (
				<Lifecycles didMount={didMount}>
					<CartLeavePagePrompt />
					<form
						id={PURCHASE_FLOW_STEP_1_FORM_ID}
						data-qa={PURCHASE_FLOW_STEP_1_FORM_ID}
						ref={formRef}
						onSubmit={PreventDefault(kickoffSubmit)}
					>
						<ProductTakeOverLayout
							showCancel
							showNickname
							showTabs
							title={<CmsContentRenderedInline
								contentKey={purchaseCmsKeys.purchasNewProduct}
								fallbackValue="Purchase a New Product"
							/>}
							steps={STANDARD_PURCHASE_FLOW_STEPS}
							currentStep={step}
							tabs={purchaseProductCatalogTabs}
							cancelLink={cancelUrl}
							unpaidFee={cart.unpaidFee}
						/>
						<Cart formHelper={formHelper} {...{ submitting }} />
					</form>
				</Lifecycles>
			);
		}}</CmsContentList>
	);
};

const PurchaseProductCatalogWithContextProvider = ({
	cancelUrl,
}) => {
	const subsystemAccountReference = useTransitAccountIdContext();
	const productCatalog = useTransitAccountProductCatalog({ subsystemAccountReference });

	return (
		<ProductCatalogContext.Provider value={productCatalog}>
			<PurchaseProductCatalog {...{ cancelUrl }} />
		</ProductCatalogContext.Provider>
	);
};

export default PurchaseProductCatalogWithContextProvider;
