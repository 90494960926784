import React from 'react';
import PropTypes from 'prop-types';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { values } from 'lodash';

import { getPathByRoute } from 'App.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Button, { Primary } from 'components/Button.js';

import * as cartStyle from 'components/payments/Cart.module.css';



const cms = {
	next: 'miscText.general-nav-next',
};

const NextStep = ({
	redirectPath,
	nextText,
	...props
}) => {
	const transit_account_id = useTransitAccountIdContext();

	const getToProp = () => redirectPath
		? getPathByRoute(redirectPath, { transit_account_id })
		: null;

	const to = getToProp();

	return (
		<CmsContentList list={values(cms)}>{() => (
			<Button
				overrideClass={cartStyle.nextButton}
				typeStyle={Primary}
				data-qa="CartActionBtn"
				{...{ to }}
				{...props}
			>
				{nextText ?? <CmsContentRenderer.Span contentKey={cms.next} fallbackValue="Next Step" />}
			</Button>
		)}</CmsContentList>
	);
};

NextStep.propTypes = {
	redirectPath: PropTypes.string,

	nextText: PropTypes.string,
};

export default NextStep;
