import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CmsContentRenderer from '../data/CmsContentRenderer.js';

import Panel from '../Panel.js';
import ProductCard from './ProductCard.js';
import ReloadBalance from './reload-balance/ReloadBalance.js';

import * as panelStyles from 'components/Panel.module.css';
import * as tabs from 'components/Tabs.module.css';
import * as plist from 'components/account/ProductList.module.css';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import { EnablementFee } from 'pages/account/PurchaseCharlieCardCcForm.js';
import {
	useCartContext,
} from 'context/CartProvider.js';
import PurseBalanceProvider from "context/PurseBalanceContext";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';

const cms = {
	payWithCash: "miscHtml.purchase-step1-description",
};

const TabProductList = ({
	formHelper,
	showBalanceConfig = false,
	products,
	maxAddTransitValue,
	setValidationState,
	currentTab,
}) => {

	const { cart } = useCartContext();
	const hasUnpaidFee = Boolean(cart.unpaidFee);
	const subsystemAccountReference = useTransitAccountIdContext();

	return (
		<>
			<div className={cx(tabs.main, plist.topPadding, plist.mainDescription)}>
				<CmsContentRenderer.H3 content={currentTab} className={tabs.tabPanelTitle} />
				<div className={plist.reloadTextWrapper}>
					<CmsContentRenderedInline
						elementType="div"
						rawHtml
						contentKey={cms.payWithCash}
						fallbackValue="<p>You may purchase one or multiple products at a time. You will be asked if you want to set up autoload after checkout.</p><p>Want to reload with cash? <a href='/direct-link/find-retailer'>Find sales locations</a>.</p>"
					/>
				</div>
			</div>
			<div className={cx(hasUnpaidFee ? tabs.enablementFee : tabs.main, plist.topPadding)}>
				<Panel overrideClass={panelStyles.hidePanelMobile}>
					{showBalanceConfig && <ReloadBalance {...{ formHelper, isRequired: true, isPurchaseNewProductFlow: true, multiStep: true }} />}
					<PurseBalanceProvider {...{ subsystemAccountReference }}>
						<ProductCard
							products={products}
							maxAddTransitValue={maxAddTransitValue}
							parentFormHelper={formHelper}
							setValidationState={setValidationState}
						/>
					</PurseBalanceProvider>
				</Panel>
				{hasUnpaidFee && <EnablementFee />}
			</div>
		</>
	);
};

TabProductList.propTypes = {
	products: PropTypes.array.isRequired,
	showBalanceConfig: PropTypes.bool,
};

export default TabProductList;
