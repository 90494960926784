// extracted by mini-css-extract-plugin
export var container = "components-forms-__Toggle-module___container";
export var content = "components-forms-__Toggle-module___content";
export var help = "components-forms-__Toggle-module___help";
export var input = "components-forms-__Toggle-module___input";
export var label = "components-forms-__Toggle-module___label";
export var labelText = "components-forms-__Toggle-module___labelText";
export var loading = "components-forms-__Toggle-module___loading";
export var servicesToggle = "components-forms-__Toggle-module___servicesToggle";
export var text = "components-forms-__Toggle-module___text";
export var textChecked = "components-forms-__Toggle-module___textChecked";
export var textUnchecked = "components-forms-__Toggle-module___textUnchecked";
export var toggleBtnWrapper = "components-forms-__Toggle-module___toggleBtnWrapper";