import WSIssueMediaLoadProduct, { WSIssueMediaLoadProductFragment } from "server/api-types/WSIssueMediaLoadProduct.js";
import { clone } from "lodash";

export default class WSIssueMediaLoadSubsystemAccountValue extends WSIssueMediaLoadProduct {
	constructor(props) {
		super(props);

		this.loadType = 'LoadSubsystemAccountValue';
	}

	static fromBackoffice(data) {
		return new WSIssueMediaLoadSubsystemAccountValue(data);
	}

	static fromSerialized(data) {
		return new WSIssueMediaLoadSubsystemAccountValue(data);
	}

	toInputWSIssueMediaLoadProductFactory() {
		return {
			wsIssueMediaLoadSubsystemAccountValue: clone(this),
		};
	}
}

export const InputWSIssueMediaLoadSubsystemAccountValueType = [ `
	input InputWSIssueMediaLoadSubsystemAccountValue {
		${WSIssueMediaLoadProductFragment}
	}
` ];

export const WSIssueMediaLoadSubsystemAccountValueType = [ `
	type WSIssueMediaLoadSubsystemAccountValue {
		${WSIssueMediaLoadProductFragment}
	}
` ];
