// extracted by mini-css-extract-plugin
export var bottomSubheaderRow = "components-account-download-reports-pdf-__Document-module___bottomSubheaderRow";
export var columContainer = "components-account-download-reports-pdf-__Document-module___columContainer";
export var dateTextValue = "components-account-download-reports-pdf-__Document-module___dateTextValue";
export var flexEndColumnContainer = "components-account-download-reports-pdf-__Document-module___flexEndColumnContainer";
export var lastRowSubtotal = "components-account-download-reports-pdf-__Document-module___lastRowSubtotal";
export var leftSideSubHeader = "components-account-download-reports-pdf-__Document-module___leftSideSubHeader";
export var leftSideSubtotal = "components-account-download-reports-pdf-__Document-module___leftSideSubtotal";
export var orderDetailsTable = "components-account-download-reports-pdf-__Document-module___orderDetailsTable";
export var orderSummaryTable = "components-account-download-reports-pdf-__Document-module___orderSummaryTable";
export var paymentDetailsTable = "components-account-download-reports-pdf-__Document-module___paymentDetailsTable";
export var pdfHtml = "components-account-download-reports-pdf-__Document-module___pdfHtml";
export var primaryHeader = "components-account-download-reports-pdf-__Document-module___primaryHeader";
export var purchaseHistoryTable = "components-account-download-reports-pdf-__Document-module___purchaseHistoryTable";
export var purchaseReceiptSection = "components-account-download-reports-pdf-__Document-module___purchaseReceiptSection";
export var rightSideSubHeader = "components-account-download-reports-pdf-__Document-module___rightSideSubHeader";
export var rightSideSubtotal = "components-account-download-reports-pdf-__Document-module___rightSideSubtotal";
export var subheaderContainer = "components-account-download-reports-pdf-__Document-module___subheaderContainer";
export var subheaderLabelText = "components-account-download-reports-pdf-__Document-module___subheaderLabelText";
export var subheadingExtraMargin = "components-account-download-reports-pdf-__Document-module___subheadingExtraMargin";
export var totalsLine = "components-account-download-reports-pdf-__Document-module___totalsLine";
export var tripHistoryTable = "components-account-download-reports-pdf-__Document-module___tripHistoryTable";