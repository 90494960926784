import React, { useState, useContext, useEffect } from "react";
import {
	get,
	find,
	reduce,
	filter,
	concat,
	sortBy,
} from 'lodash';
import {
	levels,
	log,
} from 'utils/Logger.js';
import { useLocation } from "react-router-dom";

import useStdQuery from "components/data/hooks/useStdQuery.js";
import { WSAUTOLOAD_TYPES } from 'server/api-types/WSAutoloadFactory.js';
import { GET_TRANSIT_ACCOUNT } from "components/data/transit-account/TransitAccount.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import {
	useCartContext,
	getCartBalance,
	getCartProducts,
} from 'context/CartProvider.js';
import PublicAppVars from "utils/PublicAppVars.js";

export const AUTOLOAD_AFTER_PURCHASE = 'autoload after purchase';

export const AutoloadContext = React.createContext({});
export const useAutoloadContext = () => useContext(AutoloadContext);

const defineBalanceAutoload = ({
	balance,
	wsAutoloadSummaries,
}) => {
	const balanceAutoload = (balance > 0
		&& !Boolean(find(wsAutoloadSummaries ?? [], wsAutoloadSummary => wsAutoloadSummary.autoload.type === WSAUTOLOAD_TYPES.addSubsystemValue)))
		? [ {
			type: WSAUTOLOAD_TYPES.addSubsystemValue,
			balance,
		} ]
		: [];

	return balanceAutoload;
};

const defineProductAutoload = ({
	productList,
	wsAutoloadSummaries,
}) => {
	const productsAutoload = reduce(productList ?? [], (acc, product) => {
		if (!product.supportsAutoload) {
			return acc;
		}

		if (!Boolean(find(wsAutoloadSummaries ?? [], wsAutoloadSummary => wsAutoloadSummary.autoload.productSKU === product.productSku))) {
			acc.push(product);
		}
		return acc;
	}, []);

	return productsAutoload;
};

const initialAutoloads = {
	enabledAutoloads: [],
	targetAutoloads: [],
	purchases: {
		balance: 0,
		productList: [],
	},
	newPlacedAutoloads: {
		balance: {},
		productList: [],
	},
	currentSubscriptionInfo: {},
};

const AutoloadContextProvider = ({
	subsystemAccountReference: providedSubsystemAccountReference,
	children,
}) => {

	const [ autoloads, setAutoloads ] = useState(initialAutoloads);

	const transitAccountId = useTransitAccountIdContext();

	const location = useLocation();

	const { data, loading } = useStdQuery(GET_TRANSIT_ACCOUNT, { variables: {
		subsystemAccountReference: transitAccountId ?? providedSubsystemAccountReference,
		subscriptions: true,
		programMemberships: false,
		transferProductInfo: false,
		productPayments: false,
	} });

	const existedWSSubsystemAccountInfo = loading
		? []
		: data?.transitAccountQ;
	const wsAutoloadSummaries = get(existedWSSubsystemAccountInfo, 'subscriptions', []);

	const { cart } = useCartContext();
	const balance = getCartBalance(cart);
	const productList = getCartProducts(cart);

	// runs when we get new TA data
	useEffect(() => {
		if (!loading && data && !location.state?.from) {
			const balanceAutoload = defineBalanceAutoload({ balance, wsAutoloadSummaries });
			const productsAutoload = defineProductAutoload({ productList, wsAutoloadSummaries });
			const allowedAutoloads = [ ...balanceAutoload, ...productsAutoload ];

			setAutoloads(prevAutoloads => ({
				...prevAutoloads,
				enabledAutoloads: allowedAutoloads,
				purchases: {
					balance,
					productList,
				},
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		loading,
		data,
	]);

	// runs when step by step autoloads
	useEffect(() => {
		if (location.state?.from === AUTOLOAD_AFTER_PURCHASE) {
			setAutoloads(location.state?.autoloads);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// runs when update existed autoload
	useEffect(() => {
		if (location.state?.updateAutoload) {
			setAutoloads(prevAutoloads => ({
				...prevAutoloads,
				currentSubscriptionInfo: location.state?.wsAutoloadSubscriptionInfo,
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addTargetAutoload = (product) => {

		const filtered = product.type === WSAUTOLOAD_TYPES.addSubsystemValue
			? filter(autoloads.targetAutoloads, wsAutoloadSummary => wsAutoloadSummary.type !== WSAUTOLOAD_TYPES.addSubsystemValue)
			: filter(autoloads.targetAutoloads, wsAutoloadSummary => wsAutoloadSummary.productSku !== product.productSku);

		setAutoloads(prevAutoloads => ({
			...prevAutoloads,
			targetAutoloads: sortBy(concat(filtered, product), [ 'type' ]),
		}));
	};

	const removeTargetAutoload = (product) => {
		if (product.type === WSAUTOLOAD_TYPES.addSubsystemValue) {
			setAutoloads(prevAutoloads => ({
				...prevAutoloads,
				targetAutoloads: sortBy(filter(autoloads.targetAutoloads, wsAutoloadSummary => wsAutoloadSummary.type !== WSAUTOLOAD_TYPES.addSubsystemValue), [ 'type' ]),
			}));
		} else {
			setAutoloads(prevAutoloads => ({
				...prevAutoloads,
				targetAutoloads: sortBy(filter(autoloads.targetAutoloads, wsAutoloadSummary => wsAutoloadSummary.productSku !== product.productSku), [ 'type' ]),
			}));
		}
	};

	const addAutoload = (product) => {
		if (product.type === WSAUTOLOAD_TYPES.addSubsystemValue) {
			setAutoloads(prevAutoloads => ({
				...prevAutoloads,
				newPlacedAutoloads: {
					productList: prevAutoloads.newPlacedAutoloads.productList,
					balance: product,
				},
			}));
		} else {
			setAutoloads(prevAutoloads => ({
				...prevAutoloads,
				newPlacedAutoloads: {
					balance: prevAutoloads.newPlacedAutoloads.balance,
					productList: concat(prevAutoloads.newPlacedAutoloads.productList, product),
				},
			}));
		}
	};


	const contextValue = {
		autoloads,
		addTargetAutoload,
		removeTargetAutoload,
		addAutoload,
	};

	if (PublicAppVars.LOG_FUNDING_SOURCES_CONTEXT_CHANGES) {
		console.log(contextValue);
	}

	return (
		<AutoloadContext.Provider value={contextValue}>
			{children}
		</AutoloadContext.Provider>
	);
};

export default AutoloadContextProvider;
