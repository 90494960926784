import React from 'react';

import { wrapper } from 'components/Icon.js';

import * as icon from 'components/icons/Icon.module.css';


export const Chevron = (props) => wrapper(
	<svg className={icon.smallChevron} viewBox="0 0 8 5">
		<polyline fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
			points="43 9 46 12 49 9" transform="translate(-42 -8)" />
	</svg>,
	props,
);
