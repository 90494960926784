import React, { createContext, useContext, useState } from "react";
import cx from 'classnames';
import { findWSAddressExtByIdOrNew } from 'components/data/session-user/Addresses.query.js';
import { AddressHiddenFields } from 'components/account-settings/address-selector/AddressHiddenFields.js';
import { levels, log } from 'utils/Logger.js';

import * as forms from 'components/forms/Forms.module.css';
import * as cardList from 'styles/CardList.module.css';
import { useSessionAlternateName } from "components/data/alternates/SessionAlternate.js";
import { useCustomerName } from "components/data/session-user/SessionUser.js";
import { getFormattedNameSuffix } from "utils/FormatHelpers.js";


export const NEW_ADDRESS_ID = "new";

export const AddressSelectorContext = createContext(null);
export const useAddressSelectorContext = () => useContext(AddressSelectorContext);

export const useAddressSelectorState = ({
	defaultAddressId = null, // defaults to the priamry address, else the first
	addressType = "",
}) => {
	// the final selected, validated WSAddressExt. Changes when user clicks the "Use this address" button.
	// this is null at startup until useEnsureAddress runs
	const [ address, setAddress ] = useState(null);

	// While the user is selecting between addresses, this keeps track of which radio button is selected
	// It's set to NEW_ADDRESS_ID if the user is creating a new address
	const [ selectedAddressId, setSelectedAddressId ] = useState(defaultAddressId);

	// True if the user has clicked the edit button next to an address and is seeing the input fields
	const [ isEditingAddress, setIsEditingAddress ] = useState(false);

	// list of addresses that the user has confirmed they want deleted, but haven't yet clicked "Save" to process
	const [ toDeleteAddresses, setToDeleteAddresses ] = useState([]);

	return {
		addressType,
		defaultAddressId,
		address, setAddress,
		selectedAddressId, setSelectedAddressId,
		isEditingAddress, setIsEditingAddress,
		toDeleteAddresses, setToDeleteAddresses,
	};
};

export const AddressSelector = ({
	fieldName = "",
	formHelper,

	// adding default function since we need to find all instances of
	// AddressSelector and add the prop
	getFieldName = (fieldNameBase) => fieldNameBase,

	wsAddressExts,
	alternateAddressIds = [],
	formDisabled = false,
}) => {

	const addressSelectorState = useAddressSelectorContext();

	const {
		addressType,
		setAddress,
		selectedAddressId,
		setSelectedAddressId,
	} = addressSelectorState;

	const alternateName = useSessionAlternateName();
	const customerName = useCustomerName();

	if (!addressSelectorState) {
		log(null, levels.error, "AddressSelectorContext required");
		return "AddressSelectorContext required";
	}

	const {
		customerNameFormatted,
		alternateNameFormatted,
	} = getFormattedNameSuffix({ alternateName, customerName });

	return wsAddressExts.map(wsAddressExt => {
		const { addressId, address1, city, country, postalCode } = wsAddressExt;
		const isSelected = selectedAddressId === addressId;

		const addressName = alternateName ?
			alternateAddressIds.includes(addressId) ? alternateNameFormatted : customerNameFormatted
			: null;

		return (
			<div key={addressId} className={cx(cardList.addressRow, isSelected && cardList.isSelected)}>
				<div className={forms.radio} key={`${addressType}-${addressId}`}>
					<label
						className={cx(forms.radioLabel, cardList.cardRadioLabel, isSelected && forms.radioChecked)}>
						<input
							type="radio"
							className={cx(forms.radioInput, cardList.cardRadioInput)}
							name={fieldName}
							value={addressId}
							checked={isSelected}
							disabled={formDisabled}
							onChange={() => {
								// if there were errors with the new address, we can ignore them if the new address isn't selected
								// (also applies to saved addresses with errors)
								formHelper.clearAllErrors();
								setAddress(wsAddressExt);
								setSelectedAddressId(addressId);
							}}
						/>
						<div className={cx(cardList.addressText, isSelected && cardList.isSelected)}>
							{address1}, {city}, {country}, {postalCode}<span className={forms.nicknameSuffix}>{addressName}</span>
						</div>
					</label>
					<AddressHiddenFields
						wsAddressExt={findWSAddressExtByIdOrNew(wsAddressExts, selectedAddressId)}
						{...{
							formHelper,
							getFieldName,
						}}
					/>
				</div>
			</div>
		);
	});
};

export default AddressSelector;
