/* eslint-disable complexity */
import { useApolloClient } from '@apollo/client';
import React, {
	useState,
	useContext,
	useEffect,
	useMemo,
} from "react";
import {
	forEach,
	values,
	get,
	find,
	head,
	cloneDeep,
	indexOf,
} from 'lodash';
import cx from 'classnames';
import { string as yup_string, object as yup_object } from "yup";

import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import ReloadBalance from 'components/account/reload-balance/ReloadBalance.js';
import { useEmvCanAutoload } from "components/data/transit-account/EMV.helpers.js";
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import ProductCatalog from 'components/data/transit-account/ProductCatalog.query.js';
import Panel from 'components/Panel.js';
import TakeOverLayout from 'layouts/TakeOverLayout.js';
import PreventDefault from 'utils/PreventDefault.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import AutoloadContextProvider, { useAutoloadContext, AUTOLOAD_AFTER_PURCHASE } from "context/AutoloadContext.js";
import Button from 'components/Button.js';

import AutoloadFrequency from './AutoloadFrequency.js';
import NewAutoloadToast from '../AutoloadToast.js';
import { STORED_VALUE, BIWEEKLY, WEEKLY, SHORT_EN_DAYS } from 'utils/Constants.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import { useEulaSearch, GET_EULAS_SEARCH_IDS, EulaFeatures } from 'components/data/eulas/EulaSearch.js';
import { ACCEPT_EULA } from 'pages/eula/EulaById.js';
import { WSAUTOLOAD_TYPES } from 'server/api-types/WSAutoloadFactory.js';
import { GET_ACCEPTED_EULAS_IDS } from 'components/account/panels/TermsAndConditions.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import FundingSourcesProvider, {
	useFundingSourcesContext,
} from 'context/FundingSourcesContext.js';
import {
	getYupSchema as getAddPaymentMethodYupSchema,
	addExpiryDateFields,
} from 'pages/account/purchase/AddPaymentMethod.js';
import {
	getYupSchema as getValuePurchaseYupSchema,
} from 'components/account/purchases/ValuePurchaseTypeSelection.js';
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import {
	GET_AUTOLOAD_PAYMENT_METHODS,
	UPDATE_AUTOLOAD,
} from 'components/data/transit-account/GetAutoloadPaymentMethods.js';
import WSAutoloadSubsystemValue from 'server/api-types/WSAutoloadSubsystemValue.js';
import WSAutoloadSubscriptionUpdate from 'server/api-types/WSAutoloadSubscriptionUpdate.js';
import WSFundingSourceSetUpdate from "server/api-types/WSFundingSourceSetUpdate.js";

import WSFundingSource from "server/api-types/WSFundingSource.js";

import WSFundingSourceUpdateSet from 'server/api-types/WSFundingSourceUpdateSet.js';
import { ENROLL_AUTOLOAD_PASS, getRedirectUrl } from 'components/modals/SetupAutoload.js';
import {
	AutoLoadCriteriaContext,
	AutoLoadCriteriaContextProvider,
} from 'components/account/purchases/AutoLoadCriteriaContext.js';
import WSFundingSourceSetItem from "server/api-types/WSFundingSourceSetItem.js";
import WSAutoloadCriteria, { AUTO_LOAD_CRITERIA_TYPES } from 'server/api-types/WSAutoloadCriteria.js';
import ReloadSessionDataQs from 'components/data/session-user/refetch-queries/ReloadSessionData.js';
import TransitAccountIdContext, { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import FundingSourcesQueryProvider from 'components/account/reload-balance/FundingSourcesQueryProvider.js';
import FundingPaymentMethods from 'components/FundingPaymentMethods.js';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import FormHelperProvider from 'utils/form-helper/FormHelperProvider.js';
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { eulaRefetchQueries } from "components/data/eulas/PendingAchEulas.js";
import StandardNewProductAdded from 'components/toasts/StandardNewProductAdded.js';

import * as style from './AutoloadBalance.module.css';
import * as typography from "styles/typography.module.css";

import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";
import EulaData from 'components/data/EulaData.js';

import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";

const cms = {
	header: 'miscText.autoload-header',
	editHeader: 'miscText.autoload-edit-header',
	description: 'miscHtml.autoload-description',
	learnMore: 'miscText.purchase-balance-learnmore-cta',
	learnMoreAria: 'miscText.purchase-balance-learnmore-cta-aria',
	learnMoreUrl: 'miscText.purchase-balance-learnmore-cta-url',
	balanceAmount: 'miscText.autoload-balance-amount',
	balanceSelectAmount: 'miscText.autoload-balance-how-select-amount',
	balanceLimit: 'miscText.autoload-balance-amount-limit',
	balanceWhen: 'miscText.autoload-balance-when',
	primarySubheader: 'miscText.autoload-payment-primary-subheader',
	secondarySubheader: 'miscText.autoload-payment-secondary-subheader',
	secondaryDescription: 'miscText.autoload-payment-secondary-description',
	agreement: 'miscHtml.autoload-agreement',
	save: 'miscText.autoload-edit-submit',
	amountMax: 'miscText.autoload-balance-amount-max',
};

export const SaveBtnPanel = ({
	submitting,
	disabled,
}) => {

	const { eulas, response } = useEulaSearch({
		channels: PublicAppVars.EULA_CHANNELS_AUTOLOAD,
		features: [ EulaFeatures.NEW_TRAVELER_AUTOLOAD ],
	});

	if (response.loading) {
		return <LoadingIcon />;
	}

	const eulaId = eulas[ 0 ]?.eulaId;

	return (
		<CmsContentList list={values(cms)}>{() =>
			<Panel>
				{eulaId
					? <EulaData eulaId={eulaId} returnDocument={true}>{({ document: { document } }) =>
						<div className={style.saveText}>
							<CmsContentRendered.Span
								rawHtml
								contentKey={cms.agreement}
								variables={{ url: document }}
								fallbackValue={`By continuing, you are agreeing to the MBTA's <a href='${document}'>Autoload Terms & Conditions</a>.`}
							/>
						</div>
					}</EulaData>
					: null
				}

				<Button
					{...{ submitting, disabled }}
					overrideClass={style.saveButton}
				>
					<CmsContentRenderer.Span
						contentKey={cms.save}
						fallbackValue="Save"
					/>
				</Button>
			</Panel>
		}</CmsContentList>
	);
};

const DisplayBalance = () => {

	const { purseTotal } = usePurseBalanceContext();

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Panel overrideClass={style.balanceWrapper}>
				<div>
					<CmsContentRenderer.H2
						contentKey={cms.balanceAmount}
						fallbackValue="Balance"
						className={typography.h7}
					/>
				</div>
				<div className={style.balanceContent}>
					<div className={style.balanceValue}>
						{centsToDisplay(purseTotal)}
					</div>
					<div>
						{/* <Button     //  Enable for link to internal page
                                    to={getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference })}
                                    additionalClassNames={cx(buttonStyles.link)}
                                >
                                    <CmsContentRenderer.Span fallbackValue="Learn More" />
                                </Button> */}
						<a className={style.bold} href={cmsContent[ cms.learnMoreUrl ]}>
							<CmsContentRenderer.Span contentKey={cms.learnMore} fallbackValue="Learn More" />
						</a>
					</div>
				</div>
			</Panel>
		)}</CmsContentList>
	);
};

export const handleEula = async ({
	apolloClient,
}) => {

	const eulaIdResponse = await graphqlErrorMiddleware(apolloClient.query({
		query: GET_EULAS_SEARCH_IDS,
		variables: {
			channels: PublicAppVars.EULA_CHANNELS_AUTOLOAD,
			status: "Active",
			...PublicAppVars.EULA_AUTOLOAD,
		},
	}));
	const autoLoadEula = get(eulaIdResponse, "data.eulas.search.eulaSearchList[0]", {});

	const eulaId = get(autoLoadEula, "eulaId", "");
	const archive = get(autoLoadEula, "archive", false);
	const effectiveDate = get(autoLoadEula, "effectiveDate", "");

	const effectiveDateInMilliSecs = Date.parse(effectiveDate);
	const date = new Date();
	const today = date.getTime();

	const acceptedEulasResponse = await graphqlErrorMiddleware(apolloClient.query({
		query: GET_ACCEPTED_EULAS_IDS,
	}));

	const acceptedEulas = get(acceptedEulasResponse, "data.eulas.acceptedEulas", []);
	const isAutoloadAccepted = Boolean(find(acceptedEulas, acceptedEula => acceptedEula.eulaId === eulaId)) ?? false;

	if (effectiveDateInMilliSecs < today && !archive && !isAutoloadAccepted) {

		await graphqlErrorMiddleware(apolloClient.mutate({
			mutation: ACCEPT_EULA,
			variables: {
				eulaId,
			},
			refetchQueries: () => [
				...eulaRefetchQueries,
			],
		}));
	}
};

// We are using a split payment form template + logic but the second payment method is optional
// we still want to validate the selected payment method if the cvv field was populated
export const getBackupPaymentMethodValidation = ({ isNew }) => {
	const validations = {
		paymentMethodSecond: !(isNew?.[ 1 ])
			? yup_string().when('cardCVVSecond', {
				is: (value) => Boolean(value),
				then: (schema) => schema.required("miscHtml.general-payment-invalid-selection-cvv"),
			})
			: null,
		cardCVVSecond: yup_string().when('paymentMethodSecond', {
			is: (value) => Boolean(value),
			then: (schema) => schema.transform(value => value === '' ? undefined : value)
				.required("miscHtml.general-payment-cc-error-cvv")
				.matches(/^\d+$/, "miscHtml.general-payment-cc-error-cvv-invalid")
				.min(3, "miscHtml.general-payment-cc-error-cvv-invalid")
				.max(4, "miscHtml.general-payment-cc-error-cvv-invalid")
				.trim(),
		}),
	};

	return yup_object().shape(validations,
		// Fixes above cyclic dependency: https://github.com/jquense/yup/issues/720
		[ 'paymentMethodSecond', 'cardCVVSecond' ],
	);
};

const AutoloadBalanceContent = ({
	valueIncrement,
	maxAddTransitValue,
	minAddTransitValue,
	transitValueOptions,
	fundingSourceSet,
}) => {
	const subsystemAccountReference = useTransitAccountIdContext();
	const apolloClient = useApolloClient();
	const [ updateAutoload, setUpdateAutoload ] = useState(false);
	const [ redirect, setRedirect ] = useState(null);
	const { setToast } = useGlobalToastsContext();
	const { purseTotal } = usePurseBalanceContext();
	const [ loadFundingSourcesMutator, {
		loading: fundingSourcesLoading,
		called: fundingSourcesCalled,
	} ] = useStandardMutation(GET_AUTOLOAD_PAYMENT_METHODS, {
		variables: {
			autoloadSubsystemValue: {
				// string(20)
				// (Required) Identifies the type of auto load. Valid values are:
				// • AddOneAccountValue
				// • AddSubsystemValue
				// • PurchaseSubsystemProduct
				type: WSAUTOLOAD_TYPES.addSubsystemValue,

				// string(30)
				// (Conditionally-Required) Behavior of the autoload. Possible
				// value are:
				// • AddAutoloadValue
				// • IncreaseBalanceTo
				loadValueBehavior: 'AddAutoloadValue',
				subsystemAccountId: subsystemAccountReference,
				subsystemRef: 'ABP',
				purseType: STORED_VALUE,
				purseRestriction: "Unrestricted",
				value: purseTotal,
			},
		},
	});

	const history = useHistory();
	const emvCanAutoload = useEmvCanAutoload({ subsystemAccountReference });

	const cardOverviewRedirect = useMemo(() => <Redirect push to={{
		pathname: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference }),
	}} />, [ subsystemAccountReference ]);

	const location = useLocation();
	const fromPurchaseFlow = location.state?.from === AUTOLOAD_AFTER_PURCHASE ?? false;

	const { autoloads, addAutoload, removeTargetAutoload } = useAutoloadContext();
	const { targetAutoloads, currentSubscriptionInfo } = autoloads ?? {};

	const {
		autoLoadCriteriaType,
		setAutoLoadCriteriaType,

		frequency,
		setFrequency,
		recurrence,
		setRecurrence,
		setWeekDaySelect,
		weekDaySelect,
		startDate,
		setStartDate,
		originalStartDate,
		setOriginalStartDate,
	} = useContext(AutoLoadCriteriaContext);

	useEffect(() => {
		if (location.state?.updateAutoload) {

			setUpdateAutoload(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// user shouldn't be allowed to view this route since EMV Autoload is disabled
	useEffect(() => {
		if (!emvCanAutoload) {
			setRedirect(cardOverviewRedirect);
		}
	}, [ emvCanAutoload, cardOverviewRedirect ]);

	const updateFrequency = ({
		recurrence,
		frequency,
		day,
		type,
		startDate,
		originalStart,
	}) => {
		setFrequency(frequency);
		setRecurrence(recurrence === BIWEEKLY ? WEEKLY : recurrence);
		setWeekDaySelect(day);
		setStartDate(startDate);
		setOriginalStartDate(originalStart);
		setAutoLoadCriteriaType(type);
	};

	const {
		fundingSources,
		initializeFundingSources,
		isMultiPayment,
		selectedFundingSources,
	} = useFundingSourcesContext();

	const autoloadPrimaryFundingSourceSetItem = find(fundingSourceSet?.fundingSources, { 'priority': 1 });
	const autoloadPrimaryFundingSource = find(fundingSources, { fundingSourceId: autoloadPrimaryFundingSourceSetItem?.fundingSourceId });
	const autoloadBackupFundingSourceSetItem = find(fundingSourceSet?.fundingSources, { 'priority': 2 });
	const autoloadBackupFundingSource = find(fundingSources, { fundingSourceId: autoloadBackupFundingSourceSetItem?.fundingSourceId });

	const isNew = selectedFundingSources.map(({ isNew }) => isNew);

	const getYupSchema = (formHelper) => (
		getAddPaymentMethodYupSchema({
			formHelper,
			isMultiPayment,
			isSelecting: selectedFundingSources.map(() => false),
			isAch: [ false ],
			requireAchEula: false,
			isNew,
			isNewConfirmed: selectedFundingSources.map(({ cvv }) => Boolean(cvv)),
			hasMobilePayment: false,
			disableCvv: false,
		}).concat(
			getValuePurchaseYupSchema({
				minAddTransitValue,
				maxAddTransitValue,
				autoLoadCriteriaType: null,
				valueIncrement,
				frequency: null,
			})))
		.concat(
			getBackupPaymentMethodValidation({ isNew }),
		);

	const getDataToValidate = (formHelper) => {

		const data = formHelper.getFormData();
		const elements = formHelper.getFormElements();

		forEach(elements, element => {
			const fieldName = element.name;

			if ([ 'reloadThresholdOther', 'storedValueOther' ].includes(fieldName)) {
				//convert decimal amount to int
				data[ fieldName ] = parseInt(element.value.replace(/[^0-9\.]+/g, "") * 100);
			}

			if (element.type === 'hidden' && fieldName === 'reloadThreshold') {
				data[ fieldName ] = parseFloat(element.value);
			}
		});

		addExpiryDateFields({
			isMultiPayment,
			data,
		});

		return data;
	};

	const {
		formRef,
		formHelper,
		validationState, setValidationState,
		submitting, setSubmitting,
	} = useFormHelper({ getYupSchema, getDataToValidate });

	const formHelperContext = {
		formRef,
		formHelper,
		validationState, setValidationState,
		submitting, setSubmitting,
	};

	const loadFundingSources = () => {
		loadFundingSourcesMutator().then(response => {
			const { fundingSources } = get(response, "data.OrderRoute.getAutoLoadEnrollPaymentmethods", {});
			initializeFundingSources({
				fundingSources,
				refetchFundingSources: async () => (await loadFundingSourcesMutator()).data.OrderRoute.getAutoLoadEnrollPaymentmethods,
			});
		}).catch(errorReport => {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Purchase Stored Value Payment Methods POST Failed`);
			formHelper.validationErrorHandler(errorReport);
		});

		return fundingSources;
	};

	const finalizeFundingSourcesFromForm = useFinalizeAndSetFundingSourcesToContext({ formHelper });

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.info, errorReport, `Pay for reload failed`);
			formHelper.validationErrorHandler(errorReport, false);
			return;
		}

		GoogleAnalytics.logEvent("User is attempting to pay for balance purchase");

		try {
			await handleEula({ apolloClient });
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Eula Submit Failed`);
			formHelper.validationErrorHandler(errorReport, false);
			return;
		}

		const wsAutoloadSubsystemValue = {
			type: 'AddSubsystemValue',
			loadValueBehavior: 'AddAutoloadValue',
			subsystemAccountId: subsystemAccountReference,
			subsystemRef: 'ABP',
			purseType: "StoredValue",
			purseRestriction: "Unrestricted",
			value: validated.storedValueOther ? validated.storedValueOther : validated[ 'storedValue[]' ][ 0 ],
		};

		const sameStartDate = updateAutoload && (new Date(startDate).toISOString() === new Date(originalStartDate).toISOString());
		const pastDate = updateAutoload && (new Date(startDate).toISOString() < new Date().toISOString());

		const wsAutoloadCriteria = autoLoadCriteriaType === AUTO_LOAD_CRITERIA_TYPES.threshold
			? {
				thresholdValue: validated.reloadThreshold,
			}
			: updateAutoload ?
				{
					timePeriodRecurrence: recurrence,
					timePeriodDay: weekDaySelect,
					timePeriodFrequency: frequency,
					...(!sameStartDate &&
						{
							startDate: pastDate
								? new Date().toISOString()
								: startDate,
						}),
				}
				: {
					recurrence,
					day: weekDaySelect,
					frequency,
				};

		const autoloadStartDate = startDate
			? new Date(startDate)
			: new Date();

		/**
		 * `startDate` only has month, day and year, no minutes nor seconds info so when this is parsed we get a day shift due to timezone diff.
		 * Lets apply a TZ adjustment
		 * @todo: we should use @js-temporal/polyfill - zonedDateTime
		 * **/

		if (startDate) {
			// set a future date with time-zone offset
			autoloadStartDate.setMinutes(autoloadStartDate.getMinutes() + autoloadStartDate.getTimezoneOffset());
		} else {
			// set today's date at 12am
			autoloadStartDate.setHours(0, 0, 0, 0);
		}

		// for prompted autoload from standart purchase product flow
		if (Boolean(targetAutoloads.length)) {
			removeTargetAutoload(head(targetAutoloads));
		}

		try {
			const selectedFundingSources = await finalizeFundingSourcesFromForm();

			if (updateAutoload) {
				const variables = {
					subscriptionId: currentSubscriptionInfo.subscriptionId,
					subscription: new WSAutoloadSubscriptionUpdate({
						loadValueBehavior: currentSubscriptionInfo.autoload.loadValueBehavior,
						loadValue: wsAutoloadSubsystemValue.value,
						criteriaType: autoLoadCriteriaType,
						...wsAutoloadCriteria,
						fundingSourceSet: new WSFundingSourceSetUpdate({
							fundingSourceSetId: currentSubscriptionInfo.fundingSourceSet.fundingSourceSetId.toString(),
							fundingSources: selectedFundingSources.map(({ fundingSource }, index) => new WSFundingSourceUpdateSet({
								fundingSourceId: fundingSource.fundingSourceId,
								priority: index + 1,
								fundingSource,
								...((index === 0) ? { maxPaymentAmount: validated.storedValueOther ? validated.storedValueOther : validated[ 'storedValue[]' ][ 0 ] } : {}),
							})),
						}),
					}),
					subsystemAccountId: subsystemAccountReference,
				};

				await graphqlErrorMiddleware(
					apolloClient.mutate({
						mutation: UPDATE_AUTOLOAD,
						variables,
						refetchQueries: [
							...ReloadSessionDataQs,
							...getTransitAccountRefetchQueries(subsystemAccountReference),
						],
					}));
			} else {

				const wsFundingSourceSetItems = selectedFundingSources.map(({ fundingSource }, index) => {
					return new WSFundingSourceSetItem({
						fundingSourceId: fundingSource.fundingSourceId,
						priority: index + 1,
						fundingSource: new WSFundingSource(fundingSource),
						...((index === 0) ? { maxPaymentAmount: validated.storedValueOther ? validated.storedValueOther : validated[ 'storedValue[]' ][ 0 ] } : {}),
					});
				});

				const variables = {
					fundingSourceSetItems: wsFundingSourceSetItems,
					autoloadSubsystemValue: new WSAutoloadSubsystemValue(wsAutoloadSubsystemValue),
					autoloadCriteria: new WSAutoloadCriteria({
						type: autoLoadCriteriaType,
						...wsAutoloadCriteria,
					}),
					// send this value so that when a scheduled reload is changed to automatic, it starts now and not in the future
					startDate: autoloadStartDate.toISOString(),
				};

				await graphqlErrorMiddleware(
					apolloClient.mutate({
						mutation: ENROLL_AUTOLOAD_PASS,
						variables,
						refetchQueries: [
							...ReloadSessionDataQs,
							...getTransitAccountRefetchQueries(subsystemAccountReference),
						],
					}));
			}
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Set Autoload Funding Sources Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmitting(false);
		}


		if (fromPurchaseFlow) {
			// prompted autoload from standart purchase product flow
			const autoLoadInfo = {
				type: WSAUTOLOAD_TYPES.addSubsystemValue,
				startDate: autoloadStartDate,
				amount: wsAutoloadSubsystemValue.value,
			};
			addAutoload(autoLoadInfo);

			if (Boolean(targetAutoloads.length)) {
				// next target autoload redirect
				const redirectUrl = getRedirectUrl(targetAutoloads, subsystemAccountReference);
				const clonedAutoloads = cloneDeep(autoloads);
				clonedAutoloads[ 'newPlacedAutoloads' ][ 'balance' ] = autoLoadInfo;
				clonedAutoloads[ 'targetAutoloads' ] = clonedAutoloads.targetAutoloads.slice(1);

				history.replace({
					pathname: redirectUrl,
					state: { from: AUTOLOAD_AFTER_PURCHASE, autoloads: clonedAutoloads },
				});

			} else {
				// end of target autoloads list, redirect and show toast success

				const clonedAutoloads = cloneDeep(autoloads);
				clonedAutoloads[ 'newPlacedAutoloads' ][ 'balance' ] = autoLoadInfo;

				history.replace({
					pathname: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference }),
					state: { from: AUTOLOAD_AFTER_PURCHASE, autoloads: clonedAutoloads },
				});

				setToast(<StandardNewProductAdded {...{ autoloads: clonedAutoloads }} />);
			}


		} else {
			// standard autoload flow
			const autoLoadInfo = {
				type: autoLoadCriteriaType,
				recurrence: recurrence ?? null,
				frequency: frequency ?? null,
				amount: wsAutoloadSubsystemValue.value,
				thresholdValue: wsAutoloadCriteria.thresholdValue ?? null,
				day: weekDaySelect ?? null,
			};

			setRedirect(cardOverviewRedirect);

			setToast(<NewAutoloadToast {...{ autoLoadInfo }} />);
		}

	};

	const handleCancel = () => {
		if (fromPurchaseFlow) {
			if (Boolean(targetAutoloads.length)) {
				removeTargetAutoload(head(targetAutoloads));
				// next target autoload redirect
				const redirectUrl = getRedirectUrl(targetAutoloads, subsystemAccountReference);
				const clonedAutoloads = cloneDeep(autoloads);
				clonedAutoloads[ 'targetAutoloads' ] = clonedAutoloads.targetAutoloads.slice(1);

				history.replace({
					pathname: redirectUrl,
					state: { from: AUTOLOAD_AFTER_PURCHASE, autoloads: clonedAutoloads },
				});

			} else {
				// end of target autoloads list, redirect and show toast success
				setRedirect(cardOverviewRedirect);

				setToast(<StandardNewProductAdded {...{ autoloads }} />);
			}

		} else {
			// standard autoload flow
			setRedirect(cardOverviewRedirect);

		}
	};

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout
				title={<CmsContentRenderer contentKey={updateAutoload ? cms.editHeader : cms.header}
					fallbackValue={updateAutoload ? 'Edit Autoload' : 'Set Up Autoload'} />}
				showTabs={false}
				showCancel
				handleCancel={handleCancel}
				showNickname
			>
				<Lifecycles didMount={() => loadFundingSources()}>
					{(!fundingSourcesCalled || fundingSourcesLoading)
						? <LoadingIcon size={SIZES.page} />
						: <FormHelperProvider {...{ formHelperContext }}>
							<form
								data-qa="reloadPassTakeoverForm"
								ref={formRef}
								onSubmit={PreventDefault(kickoffSubmit)}
							>
								<div className={style.container}>
									<div className={style.section}>
										<CmsContentRenderer
											contentKey={cms.description}
											fallbackValue="Autoload ensures that that your card always has value, by automatically reloading balance or passes when they're about to run out. You control the payment methods to use for the purchase, and can update these settings at any time. "
										/>
									</div>
									<div className={style.section}>
										<DisplayBalance />
									</div>

									<div className={style.section}>
										<CmsContentRenderer.P
											contentKey={cms.balanceAmount}
											fallbackValue="How Much Value Would You Like To Add To Your Balance?"
										/>
										<CmsContentRendered.P
											contentKey={cms.balanceLimit}
											fallbackValue="Your balance will not be allowed to exceed $500."
											variables={{ max: centsToDisplay(PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE) }}
										/>
										<ReloadBalance {...{
											optionsOnly: true,
											maxAddTransitValue,
											minAddTransitValue,
											transitValueOptions,
											formHelper,
											isRequired: true,
											multiStep: false,
											maxText: cms.amountMax,
										}} />
									</div>

									<div className={style.section}>
										<CmsContentRenderer.Span contentKey={cms.balanceWhen}
											className={style.sectionHeader}
											fallbackValue="When Should this Value be Autoloaded?" />

										<AutoloadFrequency {...{ formHelper, updateFrequency }} />
									</div>

									{/*
											// We are using a split payment form template+logic but the second payment method is optional
											// https://projects.invisionapp.com/d/main?origin=v7#/console/15935242/434524528/preview?scrollOffset=0
										*/}
									<>
										<FundingPaymentMethods
											{...{
												cmsContent,
												isSplit: isMultiPayment, // used to determine input names
												isFirst: true,
												defaultFundingSource: autoloadPrimaryFundingSource,
												overwriteLabel: <CmsContentRendered.Span
													contentKey={cms.primarySubheader}
													fallbackValue="Which Payment Method Should Be Charged?" />,
												requiredSave: true,
											}}
										/>
										<FundingPaymentMethods
											{...{
												cmsContent,
												isSplit: true, // used to determine input names
												isFirst: false,
												defaultFundingSource: autoloadBackupFundingSource,
												overwriteLabel: (
													<div>
														<CmsContentRendered.Span contentKey={cms.secondarySubheader}
															fallbackValue="Do You Want to Set a Backup Payment Method?" />
														<CmsContentRenderer.Span
															className={cx(style.normalWeight, style.italic)}
															fallbackValue="(optional)" />
														<CmsContentRendered.P contentKey={cms.secondaryDescription}
															className={style.normalWeight}
															fallbackValue="This payment method will be charged if your primary autoload payment method fails." />
													</div>
												),
												requiredSave: true,
											}}
										/>
									</>
									{formHelper.getFieldErrorJsx('')}
									<div className={style.section}>
										<SaveBtnPanel {...{ submitting }} />
									</div>
								</div>
							</form>
						</FormHelperProvider>
					}
				</Lifecycles>
			</TakeOverLayout>
		)}</CmsContentList>
	);
};

const AutoloadBalanceWrapper = ({
	valueIncrement,
	maxAddTransitValue,
	minAddTransitValue,
	transitValueOptions,
}) => {
	const location = useLocation();
	const [ fundingSourceSet, setFundingSourceSet ] = useState(null);

	const {
		setAutoLoadCriteriaType,
		setThresholdAmount,
		setReloadValue,
		setFrequency,
		setRecurrence,
		setWeekDaySelect,
		setStartDate,
		setOriginalStartDate,
	} = useContext(AutoLoadCriteriaContext);

	useEffect(() => {
		if (location.state?.updateAutoload) {
			const {
				wsAutoloadSubscriptionInfo: {
					criteria,
					autoload,
					fundingSourceSet,
					startDate,
				},
			} = location?.state ?? {};

			setFundingSourceSet(fundingSourceSet);
			setThresholdAmount(criteria?.thresholdValue);
			setAutoLoadCriteriaType(criteria?.type);
			setFrequency(criteria?.frequency);
			setRecurrence(criteria?.recurrence);
			setReloadValue(autoload?.value);
			if (criteria?.recurrence === WEEKLY) {
				const index = indexOf(SHORT_EN_DAYS, criteria?.day);
				setWeekDaySelect((index + 1));
			} else {
				setWeekDaySelect(criteria?.day);
			}
			setOriginalStartDate(new Date(startDate));
			setStartDate(new Date(startDate));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		location.state,
		setThresholdAmount,
		setAutoLoadCriteriaType,
		setFrequency,
		setReloadValue,
		setWeekDaySelect,
		setStartDate,
		setOriginalStartDate,
		setFundingSourceSet,
	]);

	return (<AutoloadBalanceContent {...{
		valueIncrement,
		maxAddTransitValue,
		minAddTransitValue,
		transitValueOptions,
		fundingSourceSet,
	}} />);

};

const AutoloadBalance = (props) => (
	<FundingSourcesProvider>
		<TransitAccountIdContext.Consumer>{(subsystemAccountReference) => (
			<PurseBalanceProvider {...{ subsystemAccountReference }}>
				<ProductCatalog {...{ subsystemAccountReference }}>{({
					valueIncrement,
					maxAddTransitValue,
					minAddTransitValue,
					transitValueOptions,
				}) => {
					return (
						<CmsContentList list={values(cms)}>{({ cmsContent }) => (
							<AutoLoadCriteriaContextProvider>
								<FundingSourcesQueryProvider reloadPaymentMethods>
									<AutoloadContextProvider>
										<AutoloadBalanceWrapper {...{
											valueIncrement,
											maxAddTransitValue,
											minAddTransitValue,
											transitValueOptions,
											...props,
										}} />
									</AutoloadContextProvider>
								</FundingSourcesQueryProvider>
							</AutoLoadCriteriaContextProvider>
						)}</CmsContentList>

					);
				}}</ProductCatalog>
			</PurseBalanceProvider>
		)}</TransitAccountIdContext.Consumer>
	</FundingSourcesProvider>
);

export default AutoloadBalance;
