import { gql } from "@apollo/client";
import StdQuery from "../StdQuery.js";
import React from "react";
import PropTypes from "prop-types";
import {
	pickBy,
	negate,
	isUndefined,
} from 'lodash';

import { NO_FILTERS } from 'components/FiltersConstants.js';
import { SIZES } from "components/icons/LoadingIcon.js";
import useStdQuery from "../hooks/useStdQuery.js";
import { RefetchIfOld } from "components/data/ServerTime.js";
import PublicAppVars from 'utils/PublicAppVars.js';


export const PAYMENT_HISTORY_TA_GET = gql`
	query paymentHistoryTA_GET (
		$startDateTime: String
		$endDateTime: String
		$paymentType: String
		$subsystemAccountReference: String
		$hideTravelReversals: Boolean
		$includeTravelHistoryForNonDeclinedPayments: Boolean
		$sortBy: String
		$offset: Int
		$limit: Int
		$specificDate: String
		$paymentHistoryRef: String
		$bankChargeStatusFilter: Int
		$orderId: String
		$paymentId: String
		$tokenIds: [ ID! ]
	){
		TransitAccountRoute {
			getPaymentHistoryTA (
				startDateTime: $startDateTime
				endDateTime: $endDateTime
				paymentType: $paymentType
				subsystemAccountReference: $subsystemAccountReference
				hideTravelReversals: $hideTravelReversals
				includeTravelHistoryForNonDeclinedPayments: $includeTravelHistoryForNonDeclinedPayments
				sortBy: $sortBy
				offset: $offset
				limit: $limit
				specificDate: $specificDate
				paymentHistoryRef: $paymentHistoryRef
				bankChargeStatusFilter: $bankChargeStatusFilter
				orderId: $orderId
				paymentId: $paymentId
				tokenIds: $tokenIds
			){
				lineItems {
					id
					...on WSOrderPaymentHistory {
						__typename
						orderId
						orderType
						orderStatus
						approvalStatus
						orderRefundStatus
						orderRefundDateTime
						orderLineItems {
							lineItemDesc
							lineItemType
							productName
							productStartDate
							itemTotalAmount
							feeAmount
							allowsDispute
							passFirstUseDateTime
						}
					}
					...on WSTravelPaymentHistory {
						__typename
						paymentId
						numberOfTrips
						totalDiscountAmount
						totalCapAmount
						isRefund
						trips {
							subsystemAccountRef
							tripId
							journeyId
							startDateTime
							endDateTime
							startDateTime
							startLocationDescription
							endLocationDescription
							travelMode
							totalFare
							tripStatus
							tripStatusDescription
							capAmount
							timeCategory
							fareAdjustmentAmount
							discountAmount
							subsystem
							tripPaymentAmount
							token {
								tokenType
								tokenId
							}
						}
					}
					type
					amount
					paymentRefNbr
					allowsDispute
					origin
					payments {
						__typename
						authRefNbr
						paymentType
						paymentDateTime
						paymentAmount
						paymentReferenceNumber
						paymentStatus
						...on WSPaymentHistoryCreditDebitInfo {
							maskedPAN
							maskedPan
							creditCardType
						}
					}
					paymentHistoryDateTime
				}
				totalCount
				nodeTime
			}
		}
	}`;

const notUndefined = negate(isUndefined);

export const getPaymentHistoryTAData = ({ TransitAccountRoute: { getPaymentHistoryTA } }) => {
	return getPaymentHistoryTA;
};

export const usePaymentHistoryTA = ({
	startDateTime,
	endDateTime,
	paymentType,
	subsystemAccountReference,
	hideTravelReversals,
	includeTravelHistoryForNonDeclinedPayments,
	sortBy,
	offset,
	limit,
	specificDate,
	paymentHistoryRef,
	bankChargeStatusFilter,
	orderId,
	tokenIds,
}) => {

	const variables = pickBy({
		startDateTime,
		endDateTime,
		paymentType,
		subsystemAccountReference,
		hideTravelReversals,
		includeTravelHistoryForNonDeclinedPayments,
		sortBy,
		offset,
		limit,
		specificDate,
		paymentHistoryRef,
		bankChargeStatusFilter,
		orderId,
		tokenIds,
	}, notUndefined);

	return useStdQuery(PAYMENT_HISTORY_TA_GET, { variables, fetchPolicy: "network-only" });
};

export const PaymentHistoryTA = ({
	children,
	offset,
	filtersApplied = NO_FILTERS,
	subsystemAccountReference = null,
	paymentHistoryRef = null,

	// Per Alessandro (6/18):
	// having hideTravelReversals=false causes issues which can be confusing to the user
	// change the flag to true. "hideTravelReversals=true"
	// Reversals are when we get the late arriving tap and we have to re-price
	// It also applies to the refund, however it won't be hidden in that case
	// https://reflexions.slack.com/archives/GA82SPCTV/p1592515217301500

	hideTravelReversals = true,
	limit = PublicAppVars.TRIPS_HISTORY_PAGE_SIZE,
}) => (
	<StdQuery query={PAYMENT_HISTORY_TA_GET}
		variables={{
			...pickBy(filtersApplied),
			subsystemAccountReference,
			paymentHistoryRef,
			offset,
			limit,
			hideTravelReversals,
		}}
		fetchPolicy={"cache-and-network"}
		spinnerSize={SIZES.component}
	>
		{(queryResult) => {

			const { data, refetch, loading } = queryResult;
			const { TransitAccountRoute: { getPaymentHistoryTA } } = data;
			return <>
				<RefetchIfOld {...{
					refetch: refetch,
					loading: loading,
					queryTime: getPaymentHistoryTA.nodeTime,
				}} />

				{children(getPaymentHistoryTA, queryResult)}
			</>;
		}}
	</StdQuery>
);

PaymentHistoryTA.propTypes = {
	children: PropTypes.func.isRequired,
	offset: PropTypes.number.isRequired,
	filtersApplied: PropTypes.object.isRequired,
	hideTravelReversals: PropTypes.bool,
};

export default PaymentHistoryTA;
