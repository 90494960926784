import React, { useState } from 'react';
import { values } from 'lodash';
import { gql, useMutation } from "@apollo/client";
import cx from 'classnames';

import { useModalContext } from 'context/ModalProvider.js';
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer, { getContentOrFallback } from "components/data/CmsContentRenderer.js";
import { Alternates, AcceptedManagedCustomers } from "components/data/alternates/Alternates.query.js";
import { LINK_STATUSES } from "server/api-types/WSAlternateContactInfo.js";
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

import Panel from 'components/Panel.js';
import AddAcctManager from 'components/modals/alternates/AddAccountManager.js';
import RemoveAlternate from 'components/modals/RemoveAlternate.js';
import Button, {
	buttonTypeStyleDanger,
	buttonTypeStylePlain,
} from 'components/Button.js';
import RevokeAlternateInvite from "components/modals/alternates/RevokeAlternateInvite.js";
import ManageMenu from '../ManageMenu.js';

import * as dropdown from 'components/Dropdown.module.css';
import * as typography from "styles/typography.module.css";
import * as panelStyles from 'components/Panel.module.css';
import * as buttonStyles from 'components/Button.module.css';
import CmsContent from 'components/data/CmsContent.js';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import AddedAccountManagerSuccess from "components/toasts/alternates/AddedAccountManagerSuccess.js";
import LoadingIcon from "components/icons/LoadingIcon.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import Toast, { TYPE_ERROR } from "components/Toast.js";

const cms = {
	subheader: "miscText.managed-accounts-subheader",
	description: "miscText.managed-accounts-description",
	altsSubheader: "miscText.alternates-subheader",
	altsDescription: "miscText.alternates-description",
	altsLearnMoreCta: "miscText.alternates-learnmore-cta",
	altsLearnMoreUrl: "miscText.alternates-learnmore-url",
	manageAria: "miscText.alternates-manage-aria",
	add: "miscText.alternates-add",
	addAria: "miscText.alternates-add-aria",
	hasBeenInvited: "miscText.alternates-pending-invited",
	resendInvite: "miscText.alternates-pending-resend",
	remove: "miscText.alternates-remove",
};

/**
 * Data-QA Tags:
 * PanelManagedAcctsTitle, PanelManagedAcctsAction
 * PanelAcctManagersTitle, PanelAcctManagersAction
 */

const RESEND_ALTERNATE_INVITE = gql`
	mutation resendAlternateInvite(
		$linkId: ID!
	) {
		CustomerMutationRoute {
			alternates {
				resendAlternateLink(
					linkId: $linkId
				)
			}
		}
	}
`;

const PendingInvite = ({ wsAlternateContactInfo }) => {
	const [ resendInviteMutation, { loading: resendLoading } ] = useMutation(RESEND_ALTERNATE_INVITE);
	const { setToast, removeToast } = useGlobalToastsContext();
	const { setModal } = useModalContext();

	if (resendLoading) {
		return <LoadingIcon />;
	}

	const kickoffResendInvite = async () => {
		try {
			await graphqlErrorMiddleware(resendInviteMutation({ variables: { linkId: wsAlternateContactInfo.linkId } }));
		} catch (error) {
			setToast(
				<Toast type={TYPE_ERROR}
					title={error.display.topLevelMessage}
					onClosed={removeToast}
				/>,
			);

			return;
		}

		setToast(<AddedAccountManagerSuccess email={wsAlternateContactInfo.email} resend={true} />);
	};

	const alternateOptions = [
		{
			label: <CmsContentRenderedInline
				contentKey={cms.resendInvite}
				fallbackValue="Resend Invite"
			/>,
			action: () => kickoffResendInvite(wsAlternateContactInfo),
		},
		{
			label: <CmsContentRenderedInline
				contentKey={cms.remove}
				fallbackValue="Remove"
			/>,
			action: () => setModal(<RevokeAlternateInvite {...{ wsAlternateContactInfo }}
				isAlternate={false} />),
			type: 'danger',
		},
	];

	return <>
		<div className={cx(panelStyles.rowLabel, panelStyles.truncate)}>
			<div className={panelStyles.rowLabelHeader}>
				{wsAlternateContactInfo.email}
			</div>
			<CmsContentRenderer.Div contentKey={cms.hasBeenInvited} />
		</div>

		<CmsContent contentKey={cms.manageAria}
			variables={{
				name: `${wsAlternateContactInfo.email}`,
			}}
		>
			{(content) =>
				<ManageMenu
					id={`alternate${wsAlternateContactInfo.alternateContactId}ManageMenu`}
					additionalClasses={dropdown.rowMenu}
					options={alternateOptions}
					ariaLabel={getContentOrFallback(content, `Manage alternate, ${wsAlternateContactInfo.email}`)}
				/>
			}
		</CmsContent>
	</>;
};

const ManageAccounts = () => {
	const { setModal } = useModalContext();

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Panel>
				<div className={panelStyles.section}>
					<CmsContentRenderer.H2 contentKey={cms.subheader}
						fallbackValue="Accounts I can manage"
						className={cx(typography.h7, panelStyles.heading)}
						data-qa="PanelManageAcctsTitle"
					/>
					<AcceptedManagedCustomers>
						{({ wsManagedCustomerInfos }) => {
							if (!wsManagedCustomerInfos.length) {
								return (
									<CmsContentRenderer.P
										className={panelStyles.text}
										contentKey={cms.description}
										fallbackValue="Other registered users can invite you to manage their accounts. This could be someone from your household like a child or spouse, or someone you provide care for."
									/>
								);
							}

							return wsManagedCustomerInfos.map(wsManagedCustomerInfo => (
								<div className={panelStyles.row}
									key={wsManagedCustomerInfo.id}
								>
									<span className={cx(panelStyles.rowLabel, panelStyles.truncate)}>
										<span className={panelStyles.rowLabelHeader}>
											{wsManagedCustomerInfo.firstName} {wsManagedCustomerInfo.lastName}
										</span>
									</span>

									<Button typeStyle={buttonTypeStyleDanger}
										additionalClassNames={cx(buttonStyles.isSmall, panelStyles.rowAction)}
										onClick={() => setModal(<RemoveAlternate
											wsAlternateOrManagedCustomerInfo={wsManagedCustomerInfo}
											isAlternate={true} />)}
									>
										<CmsContentRenderer.Span
											contentKey={cms.remove}
											fallbackValue="Remove"
										/>
									</Button>
								</div>
							));
						}}
					</AcceptedManagedCustomers>
				</div>

				<Alternates>
					{({ wsAlternateContactInfos, remainingLinkedAlternateContacts }) => (
						<div className={panelStyles.section}>
							<div className={panelStyles.header}>
								<CmsContentRenderer.H2 contentKey={cms.altsSubheader}
									fallbackValue="People Who Manage My Account"
									className={cx(typography.h7, panelStyles.heading)}
									data-qa="PanelAcctManagersTitle"
								/>

								<Button
									typeStyle={buttonTypeStylePlain}
									additionalClassNames={cx(buttonStyles.link, buttonStyles.linkBlue)}
									onClick={() => setModal(
										<AddAcctManager
											limitReached={!(remainingLinkedAlternateContacts)}
										/>,
									)}
									data-qa="AddAccountManager"
									aria-label={cmsContent[ cms.addAria ]}
								>
									<CmsContentRenderer.Span contentKey={cms.add} fallbackValue="Add" />
								</Button>
							</div>

							{!wsAlternateContactInfos.length
								? <CmsContentRenderer.P
									className={panelStyles.text}
									contentKey={cms.altsDescription}
									fallbackValue="You can invite other registered users to manage your account. This is useful for parents, or those caring for others with special needs."
								/>
								: wsAlternateContactInfos.map((wsAlternateContactInfo) => (
									<div
										key={wsAlternateContactInfo.id}
										className={panelStyles.row}
									>
										{wsAlternateContactInfo.linkStatus === LINK_STATUSES.accepted
											? (<>
												<span className={cx(panelStyles.rowLabel, panelStyles.truncate)}
													title={`${wsAlternateContactInfo.firstName} ${wsAlternateContactInfo.lastName}`}
												>
													<span className={panelStyles.rowLabelHeader}>
														{wsAlternateContactInfo.firstName} {wsAlternateContactInfo.lastName}
													</span>
												</span>

												<Button
													typeStyle={buttonTypeStyleDanger}
													additionalClassNames={cx(buttonStyles.isSmall, panelStyles.rowAction)}
													onClick={() => setModal(<RemoveAlternate
														wsAlternateOrManagedCustomerInfo={wsAlternateContactInfo}
														isAlternate={false} />)}
												>
													<CmsContentRenderer.Span
														contentKey={cms.remove}
														fallbackValue={'Remove'} />
												</Button>

											</>)
											: <PendingInvite wsAlternateContactInfo={wsAlternateContactInfo} />
										}
									</div>
								))
							}

							<div className={panelStyles.action}>
								<Button
									external
									to={cmsContent[ cms.altsLearnMoreUrl ] || '#'}
									typeStyle={buttonTypeStylePlain}
								>
									<CmsContentRenderer.Span
										className={panelStyles.textLink}
										contentKey={cms.altsLearnMoreCta}
										fallbackValue="Learn more about managing accounts"
									/>
								</Button>
							</div>
						</div>
					)}
				</Alternates>
			</Panel>
		)}</CmsContentList>
	);
};


export default ManageAccounts;
