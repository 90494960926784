import { HardcodedCardTypes } from "../components/Icon";
import { TRAVEL_TOKEN_TYPES } from "../server/api-types/WSSubsystemAccountTravelTokenDisplayFactory";

export const CARD_STATUS_PENDING = 'Pending';
export const CARD_STATUS_ACTIVE = 'Active';
export const CARD_STATUS_SUSPENDED = 'Suspended';

/**
 * Return time-sensitive string based on card status
 *
 * @param {Object} card - card to check
 */
export const getProductText = (card) => {
	if (card.status === CARD_STATUS_PENDING) {
		return 'Starts on ';
	} else if (card.status === CARD_STATUS_ACTIVE) {
		return 'Valid thru ';
	} else if (card.status === CARD_STATUS_SUSPENDED) {
		return 'Valid thru ';
	}
};

export const getCardType = ({
	tokenInfo,
	isMonochrome = false,
	mediaType = null,
}) => {
	if (mediaType === TRAVEL_TOKEN_TYPES.openTransitTemporary) {
		return TRAVEL_TOKEN_TYPES.openTransitTemporary;
	}

	if (tokenInfo?.tokenType === TRAVEL_TOKEN_TYPES.bankcard) {
		return isMonochrome ? `${tokenInfo.creditCardType}Inactive` : tokenInfo.creditCardType;
	} else {
		return isMonochrome ? HardcodedCardTypes.CharlieInactive : HardcodedCardTypes.CharlieCard;
	}
};
