import { gql } from '@apollo/client/core';

export const ORDER_REPLACE_MUTATION = gql`
	mutation OrderReplace (
		$replacementType: String!
		$issueMediaLineItems: [ InputWSIssueMediaLineItem! ]
		$replaceMediaLineItems: InputWSReplaceMediaLineItem
		$itemsSubtotalAmount: Int!
		$orderTotalAmount: Int!
		$reasonCode: String!

		# what we're paying with
		$payments: [ InputWSPaymentFactory! ]
		$shippingName: InputWSName
		$shippingAddress: InputWSAddressExt
	) {
		OrderRoute {
			id
			postOrderReplace (
				replacementType: $replacementType
				issueMediaLineItems: $issueMediaLineItems
				replaceMediaLineItems: $replaceMediaLineItems
				itemsSubtotalAmount: $itemsSubtotalAmount
				orderTotalAmount: $orderTotalAmount
				reasonCode: $reasonCode

				payments: $payments
				shippingName: $shippingName
				shippingAddress : $shippingAddress
			) {
				orderId
				responseCode
			}
		}
	}`;