// extracted by mini-css-extract-plugin
export var accountBenefits = "components-__Banner-module___accountBenefits";
export var accountBenefitsBtn = "components-__Banner-module___accountBenefitsBtn";
export var accountBenefitsBtnContainer = "components-__Banner-module___accountBenefitsBtnContainer";
export var accountBenefitsBtnInnerContainer = "components-__Banner-module___accountBenefitsBtnInnerContainer";
export var accountBenefitsContent = "components-__Banner-module___accountBenefitsContent";
export var accountBenefitsCta = "components-__Banner-module___accountBenefitsCta";
export var accountBenefitsImage = "components-__Banner-module___accountBenefitsImage";
export var accountBenefitsList = "components-__Banner-module___accountBenefitsList";
export var accountBenefitsListItem = "components-__Banner-module___accountBenefitsListItem";
export var accountBenefitsMedia = "components-__Banner-module___accountBenefitsMedia";
export var accountBenefitsText = "components-__Banner-module___accountBenefitsText";
export var accountBenefitsTitle = "components-__Banner-module___accountBenefitsTitle";
export var action = "components-__Banner-module___action";
export var actionButtonContainer = "components-__Banner-module___actionButtonContainer";
export var actions = "components-__Banner-module___actions";
export var button = "components-__Banner-module___button";
export var close = "components-__Banner-module___close";
export var closeButtonText = "components-__Banner-module___closeButtonText";
export var closeSvg = "components-__Banner-module___closeSvg";
export var contents = "components-__Banner-module___contents";
export var dissmissContainer = "components-__Banner-module___dissmissContainer";
export var error = "components-__Banner-module___error";
export var fullWidth = "components-__Banner-module___fullWidth";
export var informational = "components-__Banner-module___informational";
export var leftSide = "components-__Banner-module___leftSide";
export var rightSide = "components-__Banner-module___rightSide";
export var text = "components-__Banner-module___text";
export var title = "components-__Banner-module___title";
export var warning = "components-__Banner-module___warning";
export var warningIconWrapper = "components-__Banner-module___warningIconWrapper";
export var wrapper = "components-__Banner-module___wrapper";