import React from 'react';
import { Redirect } from 'react-router-dom';
import {
	parse as qs_parse,
	stringify,
} from "qs";
import {
	map,
	values,
	startCase,
} from 'lodash';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Modal from 'components/Modal.js';
import StandardMutation from 'components/data/StandardMutation.js';
import EulaData from 'components/data/EulaData.js';
import { LOGGED_IN_QUERY, PendingEulaIds } from 'components/data/session-user/LoggingIn.js';
import { ACCEPT_EULA } from './EulaById.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import { useModalContext } from "context/ModalProvider.js";
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import PreventDefault from 'utils/PreventDefault.js';
import CmsContentList from 'components/data/CmsContentList.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { usePostSigninGoToPath } from "components/data/session-user/PostSigninGoToPath.js";

import * as style from "components/account/panels/TermsAndConditions.module.css";

export const cms = {
	header: 'miscText.signin-terms-header',
	description: 'miscText.signin-terms-description',
	submit: 'miscText.signin-terms-submit',
};

const EulasPending = () => {
	const { setModal } = useModalContext();
	const postSigninGoToPath = usePostSigninGoToPath();

	const postSigninGoToUrl = new URL(postSigninGoToPath, PublicAppVars.PUBLIC_URL);

	const acceptEulasOnClose = async (mutation, eulaList) => {
		if (eulaList.length) {
			await Promise.all(map(eulaList, async eula => (
				await graphqlErrorMiddleware(
					mutation({
						variables: {
							eulaId: eula,
						},
					}),
				)
			)));
		}

		setModal(null);
	};

	return (
		<PendingEulaIds>{eulaIds => (
			eulaIds.length
				? <StandardMutation
					mutation={ACCEPT_EULA}
					refetchQueries={[ { query: LOGGED_IN_QUERY } ]}
					awaitRefetchQueries={true}
					errorChildren={null}
					renderErrorChildren={null}
				>{(acceptEulaMutation) => (
						<CmsContentList list={values(cms)}>{({ cmsContent }) => {
							return (
								<Modal
									title={<CmsContentRenderer.Span contentKey={cms.header}
										fallbackValue="Updated Terms and Conditions" />}
									showCloseBtn={false}
								>
									<CmsContentRenderer.P
										contentKey={cms.description}
										fallbackValue={`Please note that some of our terms have been updated since you last logged in. You can find the latest terms & conditions in your account settings at any time.`}
									/>
									{map(eulaIds, (eula) =>
										<EulaData eulaId={eula} key={eula} returnDocument={true}>{({
											info: {
												id,
												name,
											},
											document: {
												document,
											},
										}) => (
											<div key={id} className={style.acceptedTCWrapper}>
												<div className={style.tcName}>
													<Button
														typeStyle={buttonTypeStylePlain}
														target="_blank"
														external
														to={document}
													>
														{startCase(name)}
													</Button>
												</div>
											</div>
										)}</EulaData>
									)}
									<Button
										onClick={PreventDefault(async () => await acceptEulasOnClose(acceptEulaMutation, eulaIds))}
										text={cmsContent[ cms.submit ] || "Accept"}
									/>
								</Modal>
							);
						}}</CmsContentList>
					)}</StandardMutation>
				: <Redirect push to={{
					pathname: postSigninGoToUrl.pathname,
					search: stringify({
						eulaAccepted: true,
						...qs_parse(
							// here we append postSigninGoToUrl query strings if any
							postSigninGoToUrl.search,
							{ ignoreQueryPrefix: true },
						),
					}, { addQueryPrefix: true, skipNulls: true }),
				}} />
		)}</PendingEulaIds>
	);
};

export default EulasPending;
