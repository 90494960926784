import { gql } from "@apollo/client";
import StdQuery from 'components/data/StdQuery.js';
import React from "react";
import PropTypes from "prop-types";
import { RefetchIfOld, useRefetchIfOld } from 'components/data/ServerTime.js';

export const GET_ACCEPTED_EULAS = gql`
	query AcceptedEulas (
		$eulaType: String
		$currentOnly: Boolean
	){
		eulas {
			acceptedEulas(
				eulaType: $eulaType
				currentOnly: $currentOnly
			) { 
				acceptedEulasList {
					id
					eulaId
					eulaType
					userTypes
					userId
					subuserId1
					acceptDateTime
				}
				nodeTime
			}
		}
	}
`;

const AcceptedEulas = ({
	children,
	eulaType,
	currentOnly = false,
	loading,
}) => (
	<StdQuery
		query={GET_ACCEPTED_EULAS}
		variables={{
			eulaType,
			currentOnly,
		}}
		loading={loading}
	>
		{(response) => {
			const { data, refetch, loading } = response;

			return (<>
				<RefetchIfOld 
					{...{
						refetch,
						loading,
						queryTime: data?.eulas?.acceptedEulas?.nodeTime,
					} }
				/>
				
				{children(data.eulas.acceptedEulas.acceptedEulasList, response)}
			</>);

		}}
	</StdQuery>
);

AcceptedEulas.propTypes = {
	children: PropTypes.func.isRequired,
	eulaType: PropTypes.string,
	currentOnly: PropTypes.bool,
};

export default AcceptedEulas;
