// extracted by mini-css-extract-plugin
export var alignLeft = "components-home-__Features-module___alignLeft";
export var alignRight = "components-home-__Features-module___alignRight";
export var alignSwitch = "components-home-__Features-module___alignSwitch";
export var blue = "components-home-__Features-module___blue";
export var btn = "components-home-__Features-module___btn";
export var container = "components-home-__Features-module___container";
export var content = "components-home-__Features-module___content";
export var cta = "components-home-__Features-module___cta";
export var highlight = "components-home-__Features-module___highlight";
export var lightGray = "components-home-__Features-module___lightGray";
export var media = "components-home-__Features-module___media";
export var media_image = "components-home-__Features-module___media_image";
export var right = "components-home-__Features-module___right";
export var text = "components-home-__Features-module___text";
export var title = "components-home-__Features-module___title";
export var wrapper = "components-home-__Features-module___wrapper";