import React, { useEffect, useState, useMemo } from 'react';
import cx from "classnames";
import { values, map, find } from "lodash";
import CmsContentList from "components/data/CmsContentList";

import TakeOverLayout from 'layouts/TakeOverLayout.js';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import { WireFormHelper } from "utils/FormHelper.js";
import SelectPaymentMethodForm from 'components/account/card/add-passes/SelectPaymentMethodForm.js';
import { getCartTotal, useCartContext } from 'context/CartProvider.js';
import { Redirect } from "react-router-dom";

import * as tabs from 'styles/Tabs.module.css';

import PreventDefault from 'utils/PreventDefault.js';
import routeKeys from 'CustomerRouteKeys.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { getPathByRoute } from 'App.js';
import {
	getYupSchema as getCreditCardFormYupSchema,
	removeAddressFormInputPrefix,
} from 'pages/account/purchase/AddPaymentMethod.js';
import FundingSourcesProvider, { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import {
	NEW_ADDRESS_ID,
	AddressSelectorContext,
	useAddressSelectorState,
} from 'components/account-settings/address-selector/AddressSelector.js';
import Addresses from 'components/data/session-user/Addresses.query.js';
import SelectAddressForm, { AddressType } from 'components/account-settings/SelectAddressForm.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Toast from 'components/Toast';
import CmsContentRendered from 'components/data/CmsContentRendered.js';
import { CartStatic } from 'components/payments/Cart.js';
import { Lifecycles } from 'libreact/lib/Lifecycles/index.js';
import LoadingIcon from 'components/icons/LoadingIcon.js';
import TransitAccount, { RIDER_CLASSES } from 'components/data/transit-account/TransitAccount.js';
import { getMasterTokenInfo } from 'components/manage-cards/TokenHelpers.js';
import { isUnpaidFee } from '../purchase/StandardPurchaseFlow.js';

import * as btnStyles from "components/Button.module.css";
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import useStdQuery from 'components/data/hooks/useStdQuery.js';
import { GET_FULLNAME } from 'components/data/session-user/SessionUser.js';

import { getAddressYupSchema } from 'components/forms/address/Address.validations.js';
import { getPrimaryPaymentMethodSchema } from 'pages/account/purchase/PurchaseProductPayment.js';
import { levels, noticeError } from 'utils/Logger.js';
import WSName from 'server/api-types/WSName.js';
import WSAddressExt from 'server/api-types/WSAddressExt.js';
import WSIssueMediaLineItem from 'server/api-types/WSIssueMediaLineItem.js';
import WSIssueSubsystemMedia from 'server/api-types/WSIssueSubsystemMedia.js';
import { SUBSYSTEM_ID } from 'utils/Constants.js';
import { OPEN_TRANSIT_REGULAR_CARD } from 'server/api-types/WSSubsystemAccountToken.js';
import { useApolloClient } from '@apollo/client';
import {
	ReplacementPaymentMethodsProvider,
	useReplacementPaymentMethodsContext,
} from 'components/data/order/replace/Paymentmethods.query.js';
import WSAddress from 'server/api-types/WSAddress.js';
import FormHelperProvider from 'utils/form-helper/FormHelperProvider.js';
import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';
import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { ORDER_REPLACE_MUTATION } from 'components/data/order/replace/OrderReplace.mutation.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { SAVE_ADDRESS_SHOWN } from "components/payments/AddressForm.js";
import { postNewAddress } from "components/account/address/PostNewAddress.js";
import { getPayments } from "utils/payment/getPayments.js";
import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";


const cms = {
	header: 'miscText.card-upgrade-header',
	shippingOnly: 'miscText.card-upgrade-shipping-only',
	shippingBilling: 'miscText.card-upgrade-shipping-billing',
	paymentSelectSubheader: 'miscText.general-payment-select-subheader',
	shippingSelectSubheader: 'miscText.card-upgrade-shipping-select-subheader',
	submitBtn: 'miscText.card-upgrade-submit',
	enablementFeeInfo: 'miscText.card-upgrade-cart-enablement-fee',
	enablementFeeInfoBtn: 'miscText.card-upgrade-cart-enablement-fee-cta',
	enablementFeeInfoUrl: 'miscText.card-upgrade-cart-enablement-fee-url',
	confirmation: 'miscText.card-upgrade-confirmation',
};

// ReplacementTypes supported by 2-2-754. order/replace POST API
const upgradeTransitAccountMedia = 'UpgradeTransitAccountMedia';
const replaceTransitAccountMedia = 'ReplaceTransitAccountMedia';



const PaymentSelector = ({
	amount,
	formHelper,
}) => {
	const { getReplacementPaymentMethods, loading: replacementPaymentsLoading } = useReplacementPaymentMethodsContext();

	if (replacementPaymentsLoading) {
		return <LoadingIcon />;
	}

	return (
		<Lifecycles didMount={() => getReplacementPaymentMethods({ amount })}>
			<CmsContentRendered.H3
				className={tabs.title}
				contentKey={cms.paymentSelectSubheader}
				fallbackValue="Select a payment method"
			/>
			<WireFormHelper {...{ formHelper }}>
				<SelectPaymentMethodForm {...{
					provideNickName: false,
					formHelper,
					isSplit: false,
				}} />
			</WireFormHelper>
		</Lifecycles>
	);
};

const UpgradeCardPage = ({ transitAccountQ }) => {
	const [ redirect, setRedirect ] = useState(null);

	const { setToast, removeToast } = useGlobalToastsContext();
	const { data: nameData } = useStdQuery(GET_FULLNAME);
	const { cart, addUnpaidFee, setNegativeBalance } = useCartContext();
	const addressSelectorState = useAddressSelectorState({ addressType: AddressType.Shipping });
	const apolloClient = useApolloClient();

	const { subsystemAccountReference, tokens } = transitAccountQ;
	const successCloseRoute = getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference });

	const { mediaOptions, loading: loadingStandardCharlieCard } = useGetSubsystemProductCatalog({ riderClassId: RIDER_CLASSES.fullFare });
	const standardCharlieCard = find(mediaOptions, wsMediaProduct => wsMediaProduct.mediaType === OPEN_TRANSIT_REGULAR_CARD);

	const {
		selectedFundingSources,
		loadProductLineItemFunds,
		isMultiPayment,
	} = useFundingSourcesContext();

	const isNewCard = selectedFundingSources[ 0 ]?.isNew;

	const isSplit = false;
	const isFirst = true;
	const mobilePayment = false;

	// Find and set enablement fee
	const { hasNegativeBalance, purseTotal } = usePurseBalanceContext();
	const tempCharlieCard = getMasterTokenInfo(tokens);

	const { status, feeDueAmount } = tempCharlieCard?.tokenEnablementFee ?? {};
	const hasUnpaidFee = isUnpaidFee(status);

	// User Should not be here if we can not Enable upgrade is disabled.
	useEffect(() => {
		if (!PublicAppVars.ENABLE_UPGRADE_CARD) {
			setRedirect(<Redirect to={{ pathname: getPathByRoute(routeKeys.AccountCardOverview, { subsystemAccountReference }) }} />);
		}
	}, [ subsystemAccountReference ]);

	useEffect(() => {
		if (hasUnpaidFee && !cart?.unpaidFee) {
			addUnpaidFee(feeDueAmount);
		}
	}, [ hasUnpaidFee, addUnpaidFee, feeDueAmount, cart ]);

	useEffect(() => {
		if (hasNegativeBalance && !cart?.negativeBalance) {
			setNegativeBalance(purseTotal);
		}
	}, [ hasNegativeBalance, setNegativeBalance, purseTotal, cart ]);

	const total = getCartTotal(cart) ?? 0;

	const formHelperContext = useFormHelper({
		getYupSchema: (formHelper) => {

			// If fee is paid, we just need a shipping address. Keeping the hasUnpaidFee check here for posterity.
			if (!hasUnpaidFee) {
				return getAddressYupSchema(addressSelectorState.addressType);
			}
			// if fee is unpaid, we payment methods and shipping address
			return getCreditCardFormYupSchema({
				formHelper,
				isMultiPayment,
				isSelecting: [ false ],
				isAch: false,
				requireAchEula: false,
				isNew: [ isNewCard ],
				total: total,
				hasMobilePayment: Boolean(mobilePayment),
				disableCvv: total === 0,
			})
				.concat(getPrimaryPaymentMethodSchema())
				.concat(getAddressYupSchema(addressSelectorState.addressType));
		},
		getDataToValidate: (formHelper) => {
			const data = formHelper.getFormData();
			if (data.expirationDate) {
				data.expirationMonth = parseInt(data.expirationDate.slice(0, 2), 10);
				data.expirationYear = parseInt(data.expirationDate.slice(-2), 10);
			}
			return data;
		},
	});

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = formHelperContext;

	const finalizeAndSetFundingSourcesToContext = useFinalizeAndSetFundingSourcesToContext({ formHelper });

	// Form Functions
	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {
			const validated = await formHelper.startValidation(true);

			const {
				firstName,
				lastName,
			} = nameData?.session.customer.contact.name;

			// if the temp card fee is NOT paid, it becomes the new card's fee and is paid now
			// if the temp card fee is PAID, the new card's fee is 0
			const owedEnablementFee = hasUnpaidFee ? total : 0;

			let payments = null;

			if (hasUnpaidFee) {
				const selectedFundingSources = finalizeAndSetFundingSourcesToContext();

				payments = getPayments({
					cart,
					selectedFundingSources,
					loadProductLineItemFunds,
				});
			}

			const shippingAddress = removeAddressFormInputPrefix(validated, AddressType.Shipping);

			const wsAddressExt = shippingAddress.saveAddress
				? await postNewAddress({
					wsAddress: new WSAddress(shippingAddress),
					apolloClient,
				})
				: new WSAddressExt({
					...shippingAddress,
					...(
						addressSelectorState.selectedAddressId === NEW_ADDRESS_ID
							? {}
							: { ...shippingAddress }
					),
				});

			const variables = {
				replacementType: upgradeTransitAccountMedia,
				issueMediaLineItems: [
					new WSIssueMediaLineItem({
						issueMedia: new WSIssueSubsystemMedia({
							type: 'SubsystemMedia',
							subsystem: SUBSYSTEM_ID,
							subsystemAccountReference,
							mediaType: standardCharlieCard.mediaType,
							enablementFeeAmount: owedEnablementFee,
							itemTotalAmount: owedEnablementFee,
							subsystemTokenType: standardCharlieCard.subsystemTokenType,
						}),
					}).toResolver(),
				],
				payments: map(payments, wsPayment => wsPayment.toInputWSPaymentFactory()),
				orderTotalAmount: owedEnablementFee,
				itemsSubtotalAmount: owedEnablementFee,
				shippingName: new WSName({
					firstName,
					lastName,
				}).toResolver(),
				// Per Jon W: We are correct to use WSAddressExt and yes WSAddressExt.addressId is a required field per API spec
				// but the purchase flow allows for an empty value. jira is ccsa-3849
				// https://reflexions.slack.com/archives/CCF68M49M/p1643996850325519?thread_ts=1643232391.221400&cid=CCF68M49M
				shippingAddress: wsAddressExt,
				shoppingCartId: cart.shoppingCartId,
				reasonCode: '733', // Default to 733 until we provide the user with a reason selection. As per Slack https://reflexions.slack.com/archives/GA82SPCTV/p1621869607099800?thread_ts=1621866231.096500&cid=GA82SPCTV
			};

			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: ORDER_REPLACE_MUTATION,
					variables,
				}),
			);
		} catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Order Replacement Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		setToast(<Toast
			type="success"
			title={<CmsContentRendered.Span
				contentKey={cms.confirmation}
				fallbackValue="Your Charlie Card has been upgraded, and your new plastic card will be mailed to the shipping address you provided. Your current card will be usable until the new card is activated. Once activated, the new card will replace the current one."
			/>}
			onClosed={removeToast}
		/>);

		setRedirect(
			<Redirect push to={{
				pathname: successCloseRoute,
			}} />);
	};

	if (loadingStandardCharlieCard) {
		return <LoadingIcon />;
	}

	if (redirect) {
		return redirect;
	}

	return (<CmsContentList list={values(cms)}>{() =>
		<FormHelperProvider {...{ formHelperContext }}>
			<form
				method="post"
				ref={formRef}
				onSubmit={PreventDefault(kickoffSubmit)}
			>
				<TakeOverLayout
					title={<CmsContentRenderer.Span
						contentKey={cms.header}
						fallbackValue="Upgrade to Standard Fare Card"
					/>}
					showCancel
					cancelLink={successCloseRoute}
				>
					<div className={tabs.mainContent}>
						<div className={cx(tabs.main)}>
							<CmsContentRenderer.H2
								contentKey={hasUnpaidFee ? cms.shippingBilling : cms.shippingOnly}
							/>
							<div className={tabs.creditCardFormContainer}>
								{formHelper.getFieldErrorJsx('')}
								{hasUnpaidFee // only show payment methods if there is an enablement fee
									? <PaymentSelector
										{...{
											amount: total,
											formHelper,
										}}
									/>
									: null
								}
								<div>
									<CmsContentRenderer.H3
										className={tabs.title}
										contentKey={cms.shippingSelectSubheader}
										fallbackValue="Where should your upgraded Charlie Card be shipped?"
									/>
									{addressSelectorState ?
										<AddressSelectorContext.Provider value={addressSelectorState}>
											<Addresses>{(wsAddressExts) =>
												<SelectAddressForm
													{...{
														formHelper,
														wsAddressExts,
														isSplit,
														isFirst,
														saveAddressCheckboxVisibility: SAVE_ADDRESS_SHOWN,
													}}
												/>
											}</Addresses>
										</AddressSelectorContext.Provider>
										: null
									}
								</div>
							</div>
							<Button
								isPrimary
								type="submit"
								submitting={submitting}>
								<CmsContentRenderer.Span contentKey={cms.submitBtn} fallbackValue="Confirm your order" />
							</Button>
						</div>
						{hasUnpaidFee
							? <div className={tabs.sidebar}>
								<CmsContentRenderer.Div
									contentKey={cms.enablementFeeInfo}
									fallbackValue="You will need to pay a replacement fee to add this new card to your account. You will also need to pay for shipping."
								/>
								<Button
									overrideClass={btnStyles.learnMorePolicy}
									typeStyle={buttonTypeStylePlain}
									to={cms.enablementFeeInfoUrl}
									external={true}
								>
									<CmsContentRenderer.Span
										contentKey={cms.enablementFeeInfoBtn}
										fallbackValue="Learn more about the policy."
									/>
								</Button>
							</div>
							: null
						}
					</div>
				</TakeOverLayout>
				{hasUnpaidFee
					? <CartStatic {...{ formHelper, submitting, disableNext: true }} />
					: null
				}
			</form>
		</FormHelperProvider>
	}</CmsContentList>);
};

const UpgradeCard = () => {
	const subsystemAccountReference = useTransitAccountIdContext();
	return (
		<FundingSourcesProvider>
			<TransitAccount subsystemAccountReference={subsystemAccountReference} subscriptions={false}>{({ transitAccountQ }) => (
				<ReplacementPaymentMethodsProvider>
					<PurseBalanceProvider subsystemAccountReference={subsystemAccountReference}>
						<UpgradeCardPage {...{ transitAccountQ }} />
					</PurseBalanceProvider>
				</ReplacementPaymentMethodsProvider>
			)}</TransitAccount>
		</FundingSourcesProvider>
	);
};

export default UpgradeCard;
