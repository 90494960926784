const loginStages = {
	// user hasn't logged in (maybe because they're locked out or haven't confirmed their account)
	anonymous: 0,

	// user is logged in but hasn't created an account, which happens when you check trip history
	unRegisteredLoggedIn: 1,

	// user needs to accept updated terms & conditions. (they're required to accept during registration but t&c can change)
	// needs to be accepted before adding cards during registration
	needsEula: 2,

	// user has registered and is allowed to manage cards. They have accepted the eulas.
	// this is for permission to add/modify cards only. Any redirects will log you out.
	// That way if you e.g. click sign-in, you'll be able to try.
	registrationAddCards: 3,

	// user is logged in and has normal access
	loggedIn: 4,
};
export default loginStages;
