import PublicAppVars from "utils/PublicAppVars.js";
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';

import { CARD_TYPE_CONTACTLESS } from 'utils/Constants';
import { useTransitAccount } from "./TransitAccount.js";
import { isNegativeBalance } from "../../../utils/transit-account-utils/StatusUtils.js";

// Check if the token is EMV and if reload is enabled
export const useEmvCanReload = ({ subsystemAccountReference }) => {
	const { data, loading } = useTransitAccount({ subsystemAccountReference });

	if (!data || loading) {
		return false;
	}

	if (!PublicAppVars.ENABLE_EMV_RELOAD) {
		const primaryToken = findPrimaryToken(data.transitAccountQ.tokens);

		return primaryToken?.subsystemTokenType !== CARD_TYPE_CONTACTLESS;
	}

	return true;
};

// Check if the token is EMV and if autoload is enabled
export const useEmvCanAutoload = ({ subsystemAccountReference, queryOptions = {} }) => {
	const { data, loading } = useTransitAccount({
		subsystemAccountReference,
		...queryOptions,
	});

	if (!data || loading) {
		return false;
	}

	if (subsystemAccountReference && !PublicAppVars.ENABLE_EMV_AUTOLOAD) {
		const primaryToken = findPrimaryToken(data.transitAccountQ.tokens);

		return primaryToken?.subsystemTokenType !== CARD_TYPE_CONTACTLESS;
	}

	return true;
};

// Checks if the card is EMV & returns a boolean
export const useIsEmvCard = ({ subsystemAccountReference }) => {
	const { data, loading } = useTransitAccount({
		subsystemAccountReference,
		skip: !subsystemAccountReference,
	});

	if (!data || loading) {
		return false;
	}

	const primaryToken = findPrimaryToken(data.transitAccountQ?.tokens);
	return primaryToken?.subsystemTokenType === CARD_TYPE_CONTACTLESS;
};

// Check if the token is EMV, reload is enabled, & there is a balance to resolve
// If the EMV card is negative, the user should be able to pay off the balance.
export const useCanResolveBalance = ({ subsystemAccountReference }) => {
	const { data, loading } = useTransitAccount({ subsystemAccountReference });
	const emvCanReload = useEmvCanReload({ subsystemAccountReference });

	if (!data || loading) {
		return false;
	}

	const primaryToken = findPrimaryToken(data.transitAccountQ?.tokens);
	const isEmv = primaryToken?.subsystemTokenType === CARD_TYPE_CONTACTLESS;

	// Card is EMV
	if (isEmv) {
		const purseTotal = (data.transitAccountQ?.purses?.reduce((acc, purse) => acc + purse.balance, 0)) ?? 0;

		// Token is Emv & there is a balance that needs to be resolved.
		if (purseTotal < 0) {
			return true;
		}
		// Default state fall back to the emv logic
		return emvCanReload;
	} else {
		return true;
	}
};

export const useCanViewBalance = ({ subsystemAccountReference }) => {
	const { data, loading } = useTransitAccount({ subsystemAccountReference });
	const emvCanReload = useEmvCanReload({ subsystemAccountReference });

	if (!data || loading) {
		return false;
	}

	const primaryToken = findPrimaryToken(data.transitAccountQ?.tokens);
	const isEmv = primaryToken?.subsystemTokenType === CARD_TYPE_CONTACTLESS;

	// Card is EMV
	if (isEmv) {
		const purseTotal = (data.transitAccountQ?.purses?.reduce((acc, purse) => acc + purse.balance, 0)) ?? 0;

		// As long as the purse balance is not 0 we want to show it.
		if (purseTotal !== 0) {
			return true;
		}
		// Default state fall back to the emv logic
		return emvCanReload;
	} else {
		return true;
	}
};
