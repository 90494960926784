// Table 2.7.16 WSReplacedMedia Field Descriptions
export default class WSReplacedMedia {
	constructor({
		type,
		subsystem,
		subsystemAccountReference = null,
		travelTokenId = null,
		travelTokenNickname = null,
		travelTokenEnablementFeeAmount = null,
		mediaType,
	}) {
		// string(30)
		// (Required) Identifies the type of new media. Currently supported types are:
		// • TransitAccountMedia
		this.type = type;

		// string(20)
		// (Required) Unique identifier of the subsystem currently with the replacement media.
		this.subsystem = subsystem;

		// string(20)
		// (Optional) Unique identifier for the customer’s account in the subsystem currently with the replacement media.
		this.subsystemAccountReference = subsystemAccountReference;
		// string(20)
		// (Required) The media travel token id to be used as the replacement.
		this.travelTokenId = travelTokenId;
		// string(40)
		// (Optional) Nick name of the travel token.
		this.travelTokenNickname = travelTokenNickname;
		// int
		// (Optional) The media enablement fee amount paid for the replacement travel token. This is used to calculate potential refund.
		this.travelTokenEnablementFeeAmount = travelTokenEnablementFeeAmount;
		// string(50)
		// (Required) the type of media to issue. See 2.6.9 Media Types for allowed values.
		this.mediaType = mediaType;
	}

	static fromBackoffice(data) {
		const result = new WSReplacedMedia(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

export const WSReplacedMediaFragment = `
		type: String!
		subsystem: String!
		subsystemAccountReference: String
		travelTokenId: String
		travelTokenNickname: String
		travelTokenEnablementFeeAmount: String
		mediaType: String!
`;

export const InputWSReplacedMediaType = [ `
	input InputWSReplacedMedia {
		${WSReplacedMediaFragment}
	}
` ];

export const WSReplacedMediaType = [ `
	type WSReplacedMedia {
		${WSReplacedMediaFragment}
	}
` ];
