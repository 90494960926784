import {
	clone,
	merge,
} from "lodash";
import WSPaymentHistoryInfo, { WSPaymentHistoryInfoFragment } from "./WSPaymentHistoryInfo.js";

// 2.7.26.2.WSPaymentHistoryAccountValueInfo
// This object extends WSPaymentHistorytInfo when paymentType is CreditCard or CreditCardNotPresent or
// DebitCard.
export default class WSPaymentHistoryAccountValueInfo extends WSPaymentHistoryInfo {
	constructor({
		// this field is required, but missing sometimes.
		// https://reflexions.atlassian.net/browse/BO-70
		// We'll fallback to SubsystemAccount for now
		accountType = 'SubsystemAccount',

		//super props
		authRefNbr,
		paymentType,
		paymentDateTime,
		paymentAmount,
		paymentReferenceNumber,
		paymentStatus,
	}) {

		super({
			authRefNbr,
			paymentType,
			paymentDateTime,
			paymentAmount,
			paymentReferenceNumber,
			paymentStatus,
		});

		// string(30)
		// (Required) Type of the account:
		// • OneAccount
		// • SubsystemAccount
		this.accountType = accountType;
	}

	static fromBackoffice(data) {
		const result = new WSPaymentHistoryAccountValueInfo(data);

		return merge(
			result,
			super.fromBackoffice(data)
		);
	}

	static fromSerialized(data) {
		const result = new WSPaymentHistoryAccountValueInfo(data);

		return merge(
			result,
			super.fromSerialized(data)
		);
	}

	toBackoffice() {
		const result = clone(this);

		return merge(
			result,
			super.toBackoffice()
		);
	}

	toResolver() {
		const forGraphql = clone(this);

		return merge(
			forGraphql,
			super.toResolver()
		);
	}
}

export const WSPaymentHistoryAccountValueInfoType = [ `
	type WSPaymentHistoryAccountValueInfo implements WSPaymentHistoryInfoFactory {
		${WSPaymentHistoryInfoFragment}
		accountType: String!
	}`,
];
