// 2.7.19 WSEncryptedToken
// WSEncryptedToken represents encrypted payment information for payments using virtual wallets like
// Apple Pay/Android Pay and credit or debit cards that are not registered customer funding sources (onetime
// use). It is intended to replace the use of WSEncryptedTokenPayment by including
// WSEncryptedToken fields on on existing payment types that need to support encrypted payment
// information.

export default class WSEncryptedToken {
	constructor({
		encryptedToken,
		tokenType,
		keyName = null,
	}) {

		// string(6000)
		// (Required) The encrypted card details. Base64 encoded digital
		// wallet payload. Content of the payload is different for each Wallet
		// type
		//  Apple Pay encryptedToken is base64 encoded token from
		// PKPayment object which include JSON fields for data,
		// header, signature and version. Apple Link for details
		// Ex iOS code
		// // ios code
		// PKPayment *payment;
		// PKPaymentToken *token = payment.token;
		// NSData *paymentData = token.paymentData;
		// // Payment Token to send
		// NSString *tokenB64 = [paymentData
		// base64EncodedStringWithOptions:0];
		//  Android Pay encryptedToken is base64 encoded token
		// returned from FullWallet which includes JSON fields for
		// encryptedMessage, ephemeralPublicKey,tag. Google Link
		// for details
		// //Android code
		// Base64.encode(
		// mFullWallet
		// .getPaymentMethodToken()
		// .getToken().getBytes(),
		// Base64.NO_WRAP).toString();
		this.encryptedToken = encryptedToken;


		// string(20)
		// (Required) The token type. This is used to determine how the
		// encryptedToken should be interpreted. Valid values are:
		//  ApplePay
		//  AndroidPay
		//  DirectPay
		//  Cubic
		//  MasterPass
		this.tokenType = tokenType;

		// string(20)
		// (Conditionally-Required) The name of the key used to encrypt the
		// token. Valid values are project specific.
		// e.g. “MobileKeyNameV1”
		// For MasterPass tokenType value of keyName = “MasterPass”
		// For Jaguar orders, this field is not required.
		this.keyName = keyName;
	}

	static fromBackoffice(data) {
		const result = new WSEncryptedToken(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}

	toGraphqlInput() {
		return this;
	}

	toInputWSPaymentFactory() {
		return {
			wsEncryptedTokenPayment: this.toGraphqlInput(),
		};
	}
}

export const WSEncryptedTokenFragment = `
	encryptedToken: String!
	tokenType: String!
	keyName: String
`;

export const InputWSEncryptedTokenType = [ `
	input InputWSEncryptedToken {
		${WSEncryptedTokenFragment}
	}
` ];

export const WSEncryptedTokenType = [ `
	type WSEncryptedToken {
		${WSEncryptedTokenFragment}
	}
` ];

export const InputWSEncryptedTokenPaymentType = [
	`
		input InputWSEncryptedTokenPayment {
			${WSEncryptedTokenFragment}
		}
	`,
];
