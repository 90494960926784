import React, {
	useEffect,
	useRef,
	useState,
} from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import { get, values } from "lodash";

import { getPathByRoute } from "App.js";
import routeKeys from "CustomerRouteKeys.js";
import CredentialsForbiddenRedirect from "components/data/session-user/CredentialsForbiddenRedirect.js";
import CmsContentList from "components/data/CmsContentList.js";
import PreventDefault from "utils/PreventDefault.js";
import { useSessionCheckContext } from "components/data/session-user/SessionCheck.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import ClientAjax from "utils/ClientAjax.js";
import { log, levels } from "utils/Logger.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import { WireFormHelper } from "utils/FormHelper.js";
import TakeOverLayout from "layouts/TakeOverLayout.js";
import Button from "components/Button.js";
import Input from "components/forms/Input.js";
import Password from "components/forms/Password.js";
import useRecaptcha, { recaptchaYupValidations } from 'components/Recaptcha.js';
import { getMiscTextErrorKey } from "utils/GetErrorKey.js";
import useFormHelper from "utils/form-helper/useFormHelper";
import useStdQuery from "components/data/hooks/useStdQuery";
import { GET_SECURITY_QUESTIONS_BY_EMAIL } from "components/data/customer/SecurityQuestions";
import ErrorReport from "utils/error-handling/ErrorReport";
import { securityQuestionAnswerValidations, wsSecurityQuestionBuilder } from "components/modals/EditSecurityQuestion";

import * as tabsStyle from "styles/Tabs.module.css";
import * as loginStyle from "./Login.module.css";
import * as formsStyle from 'components/forms/Forms.module.css';



const cms = {
	header: "miscText.forgot-password-header",
	subheader: "miscText.forgot-password-email-subheader",
	emailLabel: 'miscText["forgot-password-email-label.label"]',
	emailFormSubmit: "miscText.forgot-password-email-submit",
	secQuestionsDescription: "miscText.forgot-password-questions-subheader",
	secQuestionsFormSubmit: "miscText.forgot-password-questions-submit",
};

export const validateForgotPasswordEmail = () => ({
	email: yup_string()
		.required(getMiscTextErrorKey('forgot-password-email-error-email-required'))
		.email(getMiscTextErrorKey('forgot-password-email-error-invalid')),
});

const getYupSchema = (formHelper) => {

	const validations = {
		...validateForgotPasswordEmail(),
		...recaptchaYupValidations,
	};

	const formFieldNames = formHelper.getAllFormFieldNames();

	if (formFieldNames.includes('securityQuestionOne')) {
		validations.securityQuestionOne = yup_string();
		validations.securityQuestionAnswerOne = securityQuestionAnswerValidations();
	}

	if (formFieldNames.includes('securityQuestionTwo')) {
		validations.securityQuestionTwo = yup_string();
		validations.securityQuestionAnswerTwo = securityQuestionAnswerValidations();
	}

	return yup_object().shape(validations);
};

export const redirectAccountLocked = (setRedirect, validatedEmail) => {
	setRedirect(
		<Redirect push to={{
			pathname: getPathByRoute(routeKeys.UnlockAccount),
			state: { email: validatedEmail },
		}} />
	);
};

const ForgotPassword = () => {

	const [ redirect, setRedirect ] = useState(false);
	const { formHelper, formRef, submitting, setSubmitting } = useFormHelper({ getYupSchema });
	const [ validatedEmail, setValidatedEmail ] = useState(null);

	const location = useLocation();

	const { syntheticTimerEvent } = useSessionCheckContext();

	const validateFormData = async () => {
		try {
			return await formHelper.startValidation(true);
		}
		catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			return false;
		}
	};

	const redirectResetPassword = () => {
		setRedirect(<Redirect to={{
			pathname: getPathByRoute(routeKeys.ResetPassword),
			state: { email: validatedEmail },
		}} />);
	};

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const submitRequestPasswordToken = async ({
		securityQuestionAnswers,
		recaptchaValue,
	}) => {
		const formHasSecurityQuestions = securityQuestionAnswers.length > 0;

		try {
			const postBody = {
				username: validatedEmail,
				...(formHasSecurityQuestions && { securityQuestionAnswers }),
				recaptchaValue,
			};

			// 2.9.31 customer/password/forgot POST
			await restClientMiddleware(ClientAjax.post("/ajax/request-password-token", postBody));

			redirectResetPassword();
		}
		catch (errorReport) {
			
			resetRecaptcha();

			log(
				null,
				levels.verbose,
				{
					message: `ForgotPassword server error`,
					errorReport,
				},
			);

			if (formHasSecurityQuestions) {
				if (errorReport?.display?.topLevelMessage.includes('locked')) {
					redirectAccountLocked(setRedirect, validatedEmail);
					return;
				}
				// This error has to be doctored until case #5 in JIRA https://reflexions.atlassian.net/browse/MBTA-1215
				// is resolved on Cubic's end.
				if (errorReport.TopLevelKey === getMiscTextErrorKey('errors.general.misc')) {
					errorReport = ErrorReport.newFromObj({
						TopLevelKey: getMiscTextErrorKey('forgot-password-questions-error'),
					});
				}

				formHelper.validationErrorHandler(errorReport);
			} else {
				// if there aren't security questions involved, forward to token entry form even if email is not found in system
				redirectResetPassword();
			}
		}
		finally {
			await syntheticTimerEvent();
		}
	};

	const kickoffSubmit = async () => {
		formHelper.clearAllErrors();
		setSubmitting(true);

		await checkRecaptcha();

		const validated = await validateFormData();
		if (!validated) {
			setSubmitting(false);
			resetRecaptcha();
			return;
		}

		const securityQuestionAnswers = [
			...(wsSecurityQuestionBuilder(validated.securityQuestionOne, validated.securityQuestionAnswerOne)),
			...(wsSecurityQuestionBuilder(validated.securityQuestionTwo, validated.securityQuestionAnswerTwo)),
		];

		const formHasSecurityQuestions = securityQuestionAnswers.length > 0;

		// set validated email to state to pass down to `SecurityQuestionInputs`
		// if validatedEmail was already set, SecurityQuestionInputs didn't find any Qs and is auto-submitting.
		if (!formHasSecurityQuestions && validatedEmail !== validated.email) {
			resetRecaptcha();
			setValidatedEmail(validated.email);
		} else {
			await submitRequestPasswordToken({
				securityQuestionAnswers,
				recaptchaValue: validated.recaptchaValue,
			});
		}

		setSubmitting(false);
	};

	const onEmailChange = () => {
		formHelper.clearAllErrors();
		if (validatedEmail) {
			setValidatedEmail(null);
		}
	};

	const prefill = get(location.state, "prefill", {});
	const { prefillEmail } = prefill;

	if (redirect) {
		return redirect;
	}


	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout
				title={<CmsContentRenderer contentKey={cms.header} fallbackValue="Forgotten Password" />}
				cancelLink={getPathByRoute(routeKeys.SignIn)}
				showCancel
				data-qa="forgotPswdTakeover"
			>
				<div className={loginStyle.forgotForm} data-qa="ForgotPswdInputContainer">
					<WireFormHelper {...{ formHelper }}>
						<CredentialsForbiddenRedirect />
						<form
							method="post"
							data-qa="forgotPswdForm"
							ref={formRef}
							onSubmit={PreventDefault(kickoffSubmit)}
						>
							<div className={loginStyle.registerContent}>
								<CmsContentRenderer.H2
									className={tabsStyle.title}
									data-qa="forgotPswdHeader"
									contentKey={cms.subheader}
									fallbackValue="Enter the email address associated with your Charlie account"
								/>
								<Input
									label={<CmsContentRenderer.Span
										contentKey={cms.emailLabel}
										fallbackValue="Enter your email"
									/>}
									type="text"
									name="email"
									error={formHelper.getFieldError('email')}
									onChange={onEmailChange}
									data-qa="forgotPswdEmailInput"
									defaultValue={prefillEmail}
								/>
								<div className={loginStyle.formActions}>
									<Recaptcha />
									<Button
										isPrimary
										submitting={submitting}
										data-qa="submitForgotPswdBtn"
										overrideClass={loginStyle.btn}
									>
										<CmsContentRenderer.Span
											contentKey={cms.emailFormSubmit}
											fallbackValue="Next"
										/>
									</Button>
								</div>
							</div>
							{validatedEmail && <SecurityQuestionInputs
								{...{
									validatedEmail,
									formHelper,
									submitting,
									submitForm: kickoffSubmit,
								}}
							/>}
						</form>
						{formHelper.getFieldErrorJsx('')}
					</WireFormHelper>
				</div>
			</TakeOverLayout>
		)}</CmsContentList>
	);
};

const SecurityQuestionInputs = ({
	validatedEmail,
	formHelper,
	submitting,
	submitForm,
}) => {
	const errorSubmitted = useRef(false);
	const { error, data } = useStdQuery(GET_SECURITY_QUESTIONS_BY_EMAIL, {
		variables: { email: validatedEmail },
	});

	useEffect(() => {
		// An error could mean either that the email is valid but does not have any security questions,
		// or that the email is just not found in the system.
		// In either case, continue with the request forgot password token request without including security questions.
		if (error && !errorSubmitted.current) {
			errorSubmitted.current = true;
			submitForm();
		}
	}, [ error, submitForm ]);

	if (!data) {
		return null;
	}

	const securityQuestionOne = data.securityQuestionsByEmail?.[ 0 ] ?? null;
	const securityQuestionTwo = data.securityQuestionsByEmail?.[ 1 ] ?? null;

	const onAnswerChange = () => formHelper.clearAllErrors();

	return <>
		<hr className={formsStyle.hr} />
		<WireFormHelper {...{ formHelper }}>
			<div className={loginStyle.registerContent}>
				<CmsContentRenderer.H2
					className={tabsStyle.title}
					data-qa="securityQuestionsHeader"
					contentKey={cms.secQuestionsDescription}
					fallbackValue="Answer all security questions below to confirm your account, and we'll send a password reset link to your email."
				/>
				{securityQuestionOne && <>
					<input type="hidden" name="securityQuestionOne" value={securityQuestionOne} />
					<Password
						label={securityQuestionOne}
						name="securityQuestionAnswerOne"
						error={formHelper.getFieldError('securityQuestionAnswerOne')}
						data-qa="ForgotPswdSecurityQuestionAnswerOneInput"
						onChange={onAnswerChange}
					/>
				</>}
				{securityQuestionTwo && <>
					<input type="hidden" name="securityQuestionTwo" value={securityQuestionTwo} />
					<Password
						label={securityQuestionTwo}
						name="securityQuestionAnswerTwo"
						error={formHelper.getFieldError('securityQuestionAnswerTwo')}
						data-qa="ForgotPswdSecurityQuestionAnswerTwoInput"
						onChange={onAnswerChange}
					/>
				</>}
				<div className={loginStyle.formActions}>
					<Button
						isPrimary
						submitting={submitting}
						data-qa="submitSecurityQuestionsBtn"
						overrideClass={loginStyle.btn}
					>
						<CmsContentRenderer.Span
							contentKey={cms.secQuestionsFormSubmit}
							fallbackValue="Submit"
						/>
					</Button>
				</div>
			</div>
		</WireFormHelper>
	</>;
};

export default ForgotPassword;
