import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as group from './Group.module.css';
import ManageMenu from "components/account/ManageMenu.js";

class Group extends Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		leaveGroup: PropTypes.func,
	};


	action1() {
		alert('Action 1 clicked');
	}

	render() {
		const { item, ...rest } = this.props;

		const itemOptions = [
			{
				label: "Action 1",
				action: this.action1,
				type: 'normal',
			},
			{
				label: 'Danger Action',
				action: this.props.leaveGroup,
				type: 'danger',
			},
		];

		return (
			<div className={group.card}>
				<div className={group.header}>
					<h4 className={group.name}>{item.name}</h4>
				</div>

				<div className={group.info}>
					<p className={group.infoItem}>
						<span className={group.label}>Joined</span>
						<span className={group.value}>{item.joined}</span>
					</p>
					<p className={group.infoItem}>
						<span className={group.label}>Expires</span>
						<span className={group.value}>{item.expires}</span>
					</p>

					<div className={group.manage}>
						<ManageMenu label="Manage"
							options={itemOptions}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Group;
