import React from 'react';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { gql } from "@apollo/client";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";
import RenderAfterAWhile from "utils/RenderAfterAwhile.js";
import { WSCustomerContactFragment } from "server/api-types/WSCustomerContact.js";

export const GET_ALTERNATE_ACCOUNT_FROM_SESSION = gql`
	query getAlternateAccountFromSession {
		session {
			customerId
			alternateSession {
				customer {
					contact {
						name {
							firstName
							middleInitial
							lastName
						}
					}
				}
			}
			id
		}
	}
`;

const SessionAlternate = ({ children, loadingIconSize = SIZES.component }) => {
	const { data, loading } = useStdQuery(GET_ALTERNATE_ACCOUNT_FROM_SESSION);
	if (loading) {
		return <RenderAfterAWhile timeout={300}><LoadingIcon size={SIZES.component} /></RenderAfterAWhile>;
	}

	return children({ alternateSession: data.session?.alternateSession });
};

export const useSessionAlternateName = () => {
	const { data } = useStdQuery(GET_ALTERNATE_ACCOUNT_FROM_SESSION);

	return data?.session.alternateSession?.customer.contact.name;
};

export const IsAlternate = ({ children }) => {
	return <SessionAlternate>{
		({ alternateSession }) => {
			if (!alternateSession) {
				return null;
			}

			return children;
		}
	}</SessionAlternate>;
};

export const IsNotAlternate = ({ children }) => {
	return <SessionAlternate>{
		({ alternateSession }) => {
			if (alternateSession) {
				return null;
			}

			return children;
		}
	}</SessionAlternate>;
};

export default SessionAlternate;
