import React, { useState } from "react";
import {
	levels,
	log,
} from "../utils/Logger.js";

export const ReplacementContext = React.createContext(() => log(null, levels.error, "StepSet used before it was ready"));

export const ReplacementContextProvider = ({
	children,
}) => {
	const [ replacement, setReplacement ] = useState(0);

	return (
		<ReplacementContext.Provider value={[ replacement, setReplacement ]}>
			{children}
		</ReplacementContext.Provider>
	);
};

export default ReplacementContext;
