import React, { useState } from "react";
import { values } from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Container from 'components/Container.js';
import Collapsible from "components/Collapsible.js";
import { CharlieCard, GenericCard, GuestCard } from "components/icons/CardBrands.js";
import { Chevron } from "components/icons/UI.js";
import CardForm, {
	CharlieCardFormType,
	EmvCardFormType,
} from "components/guest/CardForm.js";
import RegisterForCharlieAccount from "components/banners/RegisterForCharlieAccount.js";

import * as collapsible from 'components/Collapsible.module.css';
import * as style from "./ViewTransactionHistory.module.css";
import { getHomeBreadcrumbs } from "pages/Home";
import {  useBreadcrumbCallback } from "context/BreadcrumbProvider";
import { BreadcrumbItem } from "components/breadcrumb/Breadcrumb";
import routeKeys from "CustomerRouteKeys";
import AFC1Banner from "components/banners/AFC1Card";
import ContactCustomerService from "../../components/banners/ContactCustomerService.js";



const cms = {
	header: 'miscText.guest-login-header',
	desc: 'miscHtml.guest-login-description',
	formHeader: 'miscText.guest-login-subheader',

	toggleCharle: 'miscText.guest-login-charlie',
	toggleContactless: 'miscText.guest-login-contactless',

	next: 'miscText.guest-login-contactless-submit',
	generalNext: 'miscText.general-nav-next',
};

export const getViewTransactionHistoryBreadcrumbs = () => [
	...getHomeBreadcrumbs(),
	<BreadcrumbItem
		key={cms.header}
		cmsKey={cms.header}
		routeKey={routeKeys.ViewTransactionHistory}
		fallbackValue="Manage Unregistered Card"
	/>,
];

const ViewTransactionHistory = () => {
	useBreadcrumbCallback(getViewTransactionHistoryBreadcrumbs);

	const [ isAFC1Card, setIsAFC1Card ] = useState();

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
			{isAFC1Card && <AFC1Banner />}
			<Container>
				<div className={style.contentContainer}>
					<section className={style.col} aria-label={cmsContent[ cms.header ]}>
						<div className={style.mainContent}>
							<CmsContentRenderer.H1 contentKey={cms.header}
								className={style.title}
								fallbackValue="View Unregistered Charlie Card"
							/>

							<CmsContentRenderer.P
								rawHtml
								contentKey={cms.desc}
								className={style.text}
								fallbackValue="<p>If you're not registered for an account, you can still manage your card by entering your card details. <br /> <b>Do you travel with a numberless credit or debit card?</b><br /> We’re rolling out the new Charlie system in phases. During this time, numberless cards are not yet supported.</p>"
							/>
						</div>
					</section>

					<section className={style.col} aria-label={cmsContent[ cms.formHeader ]}>
						<CmsContentRenderer.H2 contentKey={cms.formHeader}
							className={style.subTitleViewHistory}
							fallbackValue="What do you do want to check today?"
						/>

						<div className={collapsible.guestContainer}>
							<Collapsible
								additionalClasses={collapsible.guest}
								itemKey={CharlieCard}
								toggleClosedState={<span className={collapsible.guestToggle}>
									<GuestCard type={CharlieCard} altText={CharlieCard} />
									<CmsContentRenderer.Span
										contentKey={cms.toggleCharle}
										fallbackValue={"A Charlie Card (coming soon)"}
										className={collapsible.guestToggleText}
									/>
									<Chevron overrideClass={collapsible.guestToggleChevron} />
								</span>}
								toggleOpenState={<span className={collapsible.guestToggle}>
									<GuestCard type={CharlieCard} altText={CharlieCard} />
									<CmsContentRenderer.Span contentKey={cms.toggleCharle}
										className={collapsible.guestToggleText}
									/>
									<Chevron overrideClass={collapsible.guestToggleChevron} />
								</span>}
							>
								<CardForm
									cardType={CharlieCardFormType}
									isAFC1Card
									setIsAFC1Card={setIsAFC1Card}
								/>
							</Collapsible>
						</div>

						<div className={collapsible.guestContainer}>
							<Collapsible
								additionalClasses={collapsible.guest}
								initialOpenState={true}
								itemKey={GenericCard}
								toggleClosedState={<span className={collapsible.guestToggle}>
									{<GuestCard type={GenericCard} altText={GenericCard} />}
									<CmsContentRenderer.Span contentKey={cms.toggleContactless}
										className={collapsible.guestToggleText}
										fallbackValue=""
									/>
									<Chevron overrideClass={collapsible.guestToggleChevron} />
								</span>}
								toggleOpenState={<span className={collapsible.guestToggle}>
									{<GuestCard type={GenericCard} altText={GenericCard} />}
									<CmsContentRenderer.Span contentKey={cms.toggleContactless}
										className={collapsible.guestToggleText}
										fallbackValue=""
									/>
									<Chevron overrideClass={collapsible.guestToggleChevron} />
								</span>}
							>
								<CardForm cardType={EmvCardFormType} />
							</Collapsible>
						</div>
					</section>
				</div>
			</Container>

			<RegisterForCharlieAccount />
			<ContactCustomerService />
		</>)}</CmsContentList>
	);
};

export default ViewTransactionHistory;
