// extracted by mini-css-extract-plugin
export var cardCvv = "pages-account-purchase-__PurchaseProductPayment-module___cardCvv";
export var cardSelect = "pages-account-purchase-__PurchaseProductPayment-module___cardSelect";
export var container = "pages-account-purchase-__PurchaseProductPayment-module___container";
export var enablementFee = "pages-account-purchase-__PurchaseProductPayment-module___enablementFee";
export var enablementFeeContainer = "pages-account-purchase-__PurchaseProductPayment-module___enablementFeeContainer";
export var feeWrapper = "pages-account-purchase-__PurchaseProductPayment-module___feeWrapper";
export var paymentSelection = "pages-account-purchase-__PurchaseProductPayment-module___paymentSelection";
export var paymentWFeeWrapper = "pages-account-purchase-__PurchaseProductPayment-module___paymentWFeeWrapper";
export var secondaryButton = "pages-account-purchase-__PurchaseProductPayment-module___secondaryButton";
export var takeoverLayout = "pages-account-purchase-__PurchaseProductPayment-module___takeoverLayout";