import { useApolloClient } from "@apollo/client";
import { getPathByRoute } from "App.js";
import routeKeys from "CustomerRouteKeys.js";
import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import {
	levels,
	log,
	noticeError,
} from "utils/Logger.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import LogoutRequest from "./LogoutRequestContext.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import ClientAjax from "utils/ClientAjax.js";
import { timeoutPromise } from 'utils/TimeoutPromise.js';
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';

// This Logout component is the one you'll want to reference if you're providing e.g. a logout link
// LogoutRequest is only the actual ajax call and doesn't handle clearing the Apollo cache.

export const LogoutContext = React.createContext(() => {
	log(null, levels.error, "LogoutContext used before it was ready");
});

/**
 * Clear the browser cache
 *
 * Note: this is done in a separate request that is _not_ awaited because of this bug in chrome:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=762417
 */
const clearBrowserCache = () => {
	restClientMiddleware(ClientAjax.post('/ajax/clear-cache'))
		.then(() => log(null, levels.info, "Browser cache cleared"))
		.catch((error) => noticeError(null, levels.error, error, `Error clearing browser cache`));
};

const Logout = ({ children }) => {
	const logoutAjax = useContext(LogoutRequest);
	const client = useApolloClient();
	const { refetch: refetchLoginStage } = useLoginStage();
	const history = useHistory();

	const logoutAndClearCache = async ({ redirectTo } = {}) => {
		// logout is a standard event
		// https://developers.google.com/analytics/devguides/collection/gtagjs/events#default_google_analytics_events
		GoogleAnalytics.logEvent('logout');

		await logoutAjax();
		// We're now logged out on the server-side.

		// clearing the browser cache stalls the browser for a bit. Delay that to give the browser
		// a chance to rerender once logout is complete
		setTimeout(clearBrowserCache, 1000);

		// Clear the Apollo cache.
		// resetStore will rerun all queries. clearStore just clears cache for new queries
		// but doesn't rerender. resetStore will often error if there were requests in flight
		try {
			// Fetch the session first, since we are logged out, it will return an empty session
			// graphql will now know we are logged out and not rerun queries that require customerId
			// We do this to avoid `miscText.error-general-auth-required` errors on `client.resetStore()`
			// https://reflexions.slack.com/archives/CAT36MFLZ/p1658164775605739?thread_ts=1658164648.995599&cid=CAT36MFLZ
			await refetchLoginStage();

			// timeout with delay 0 waits for next tick
			// we should wait a tick after refreshing LOGGED_IN_QUERY to give react a chance to rerender
			// that will unmount any components that were expecting us to be logged in
			await timeoutPromise(0);

			// now that we're rendering only logged out components, we can refresh their data
			await client.resetStore();

			log(null, levels.info, "Apollo store cleared");
		} catch (error) {
			noticeError(null, levels.info, error, "Error clearing Apollo store");
		}
		// Per Greg G: Always redirect the user the login screen, since the log in
		// screen shows the "logged out" banner
		// https://reflexions.slack.com/archives/CDTQPPXJ8/p1651612285639579
		finally {
			// don't want account urls added to postSigninGoToPath on logout
			// (url might be specific to a user and would error if a different user logged in)
			// there's no security benefit to this though because the history is still available with back button
			history.push(getPathByRoute(redirectTo ?? routeKeys.SignIn));
		}
	};
	return (
		<LogoutContext.Provider value={logoutAndClearCache}>
			{children}
		</LogoutContext.Provider>
	);
};

export const useLogoutContext = () => useContext(LogoutContext);

export default Logout;
