// extracted by mini-css-extract-plugin
export var buttonDescription = "pages-account-__AddContactlessEMVCard-module___buttonDescription";
export var contactlessCardIcon = "pages-account-__AddContactlessEMVCard-module___contactlessCardIcon";
export var contactlessIcon = "pages-account-__AddContactlessEMVCard-module___contactlessIcon";
export var descriptionContainer = "pages-account-__AddContactlessEMVCard-module___descriptionContainer";
export var descriptionHeaderContainer = "pages-account-__AddContactlessEMVCard-module___descriptionHeaderContainer";
export var expirationDate = "pages-account-__AddContactlessEMVCard-module___expirationDate";
export var hidePaymentOptsOnDesktop = "pages-account-__AddContactlessEMVCard-module___hidePaymentOptsOnDesktop";
export var hidePaymentOptsOnMobile = "pages-account-__AddContactlessEMVCard-module___hidePaymentOptsOnMobile";
export var iconContainer = "pages-account-__AddContactlessEMVCard-module___iconContainer";
export var infoDescription = "pages-account-__AddContactlessEMVCard-module___infoDescription";
export var infoHeader = "pages-account-__AddContactlessEMVCard-module___infoHeader";
export var inputContainer = "pages-account-__AddContactlessEMVCard-module___inputContainer";
export var mainContainer = "pages-account-__AddContactlessEMVCard-module___mainContainer";
export var tabMainContainer = "pages-account-__AddContactlessEMVCard-module___tabMainContainer";
export var tabSideBorder = "pages-account-__AddContactlessEMVCard-module___tabSideBorder";