import React, { useEffect, useState } from 'react';
import TakeOverLayout from '../../layouts/TakeOverLayout.js';
import PurchaseCharlieCardStoreForm from './PurchaseCharlieCardStoreForm.js';
import PurchaseCharlieCardSelectProducts from './PurchaseCharlieCardSelectProducts.js';
import PurchaseCharlieCardCcForm from './PurchaseCharlieCardCcForm.js';

import { StepContextProvider, useStepContext } from 'context/StepContext.js';
import { PurchaseCompleteContent } from 'pages/account/purchase/PurchaseProductComplete.js';
import PurchaseProductConfirm from 'pages/account/purchase/PurchaseProductConfirm.js';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from 'components/data/session-user/LoginStages.js';
import { Redirect } from 'react-router-dom';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import PublicAppVars from 'utils/PublicAppVars.js';
import FundingSourcesProvider from "context/FundingSourcesContext.js";
import ComingSoon from 'components/banners/ComingSoon.js';

const cms = {
	cancelPurchase: "miscText.purchase-cancel",
	header: "miscText.purchase-card-header",
	purchaseStepOneHeader: "miscText.purchase-card-1-subheader",
	purchaseStepTwoHeader: "miscText.purchase-card-2-subheader",
	purchaseStepThreeHeader: "miscText.purchase-card-3-subheader",
	purchaseStepFourHeader: "miscText.purchase-card-4-subheader",
	purchaseStepFiveHeader: "miscText.purchase-card-5-subheader",
};

const PURCHASE_CHARLIE_CARD_SELECT_QUANTITY = 0;
export const PURCHASE_CHARLIE_CARD_SELECT_PRODUCTS = 1;
export const PURCHASE_CHARLIE_CARD_PAYMENT = 2;
const PURCHASE_CHARLIE_CARD_CONFIRMATION = 3;
const PURCHASE_CHARLIE_CARD_COMPLETE = 4;

export const PURCHASE_CHARLIE_CARD_STEPS = [
	{
		step: PURCHASE_CHARLIE_CARD_SELECT_QUANTITY,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepOneHeader}
			fallbackValue="Step 1: Select quantity"
		/>,
	},
	{
		step: PURCHASE_CHARLIE_CARD_SELECT_PRODUCTS,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepTwoHeader}
			fallbackValue="Step 2: Select products"
		/>,
	},
	{
		step: PURCHASE_CHARLIE_CARD_PAYMENT,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepThreeHeader}
			fallbackValue="Step 3: Payment and shipping details"
		/>,
	},
	{
		step: PURCHASE_CHARLIE_CARD_CONFIRMATION,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepFourHeader}
			fallbackValue="Step 4: Confirmation"
		/>,
	},
	{
		step: PURCHASE_CHARLIE_CARD_COMPLETE,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepFiveHeader}
			fallbackValue="Step 5: Purchase Complete"
		/>,
	},
];

export const PurchaseCharlieCardTakeoverLayout = ({ children, step }) => (
	<>
		{PublicAppVars.COMING_SOON_PURCHASE_CHARLIE_CARD && <ComingSoon/> }
		<TakeOverLayout
			showCancel
			showSteps
			title={<CmsContentRendered
				contentKey={cms.header}
				fallbackValue="Purchase a New Charlie Card"
			/>}
			steps={PURCHASE_CHARLIE_CARD_STEPS}
			currentStep={step}
		>
			{children}
		</TakeOverLayout>
	</>
);

/**
 * Return the current step of the flow
 */
const CurrentStep = () => {
	const [ redirect, setRedirect ] = useState(null);
	const { loginStage } = useLoginStage();
	const { step, setStep } = useStepContext();

	const redirectUrl = (loginStage === loginStages.loggedIn)
		? getPathByRoute(routeKeys.AccountCardSelection)
		: getPathByRoute(routeKeys.GuestCardOverview);

	if (redirect) {
		return redirect;
	}

	switch (step) {
		case PURCHASE_CHARLIE_CARD_SELECT_QUANTITY:
			return <PurchaseCharlieCardStoreForm />;
		case PURCHASE_CHARLIE_CARD_SELECT_PRODUCTS:
			return <PurchaseCharlieCardSelectProducts
				title={<CmsContentRenderedInline
					contentKey={cms.header}
					fallbackValue="Purchase a New Charlie Card"
				/>}
				showNickname={false}
			/>;
		case PURCHASE_CHARLIE_CARD_PAYMENT:
			return <PurchaseCharlieCardCcForm />;
		case PURCHASE_CHARLIE_CARD_CONFIRMATION:

			const backToProductStep = () => setStep(PURCHASE_CHARLIE_CARD_SELECT_PRODUCTS);
			const backToPaymentStep = () => setStep(PURCHASE_CHARLIE_CARD_PAYMENT);

			return <PurchaseCharlieCardTakeoverLayout step={step}>
				<PurchaseProductConfirm
					cancelUrl={redirectUrl}
					handleBackToProductStep={backToProductStep}
					handleBackToPaymentStep={backToPaymentStep}
					showTakeOverLayout={false}
				/>
			</PurchaseCharlieCardTakeoverLayout>;
		case PURCHASE_CHARLIE_CARD_COMPLETE:

			const handleCompleteRedirect = () => {
				setRedirect(<Redirect push to={{ pathname: redirectUrl }} />);
			};

			return <PurchaseCharlieCardTakeoverLayout step={step}>
				<PurchaseCompleteContent handleRedirect={handleCompleteRedirect} />
			</PurchaseCharlieCardTakeoverLayout>;
	}
};

const PurchaseCharlieCard = () => {
	return (
		<FundingSourcesProvider>
			<StepContextProvider min={PURCHASE_CHARLIE_CARD_SELECT_QUANTITY} max={PURCHASE_CHARLIE_CARD_COMPLETE}>
				<CurrentStep />
			</StepContextProvider>
		</FundingSourcesProvider>
	);
};

export default PurchaseCharlieCard;
