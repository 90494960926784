export const COLLECTIONS = {
	login: 'Account Login',
	tripHistory: 'View Travel and Service History',
	registration: 'Create Registered Account',
	purchaseStoredValue: 'Purchase Stored Value',
	purchaseProducts: 'Purchase Transit Products',
	balanceAndProduct: 'View Balance and Product Information',
	automaticBalanceReload: 'Automatic balance reload',
	AutomaticPassRenewal: 'Automatic pass renewal',
	contactCustomerService: 'Contact Customer Service',
	alternates: 'Authorize and Manage Alternates',
	updateProfile: 'Update Account Profile',
	managePaymentInformation: 'Manage Payment Information',
	transferProducts: 'Transfer Stored Value or unused Pass from one (1) Account to another',
	securityProfile: 'Manage Security Profile',
	purchaseCharlieCard: 'Purchase Charlie Card',
	correctionLineItems: 'Correction of Missing Taps',
	fareChargeDispute: 'Fare Charge Dispute',
	orderDispute: 'Order Dispute',
	blockOrReplaceCard: 'Replace a Lost Charlie Card',
	delinkCard: 'Disassociate Credential',
	unregistered: 'Unregistered Account Access',
	manageMultipleMedia: 'Manage Multiple Media',
	viewTravelAndServiceHistory: "View Travel and Service History",
};

export const BO_ERRORS = {
	general: {
		required: 'errors.general.value.required',
		notFound: 'errors.general.record.not.found',
		tooSmall: 'errors.general.value.toosmall',
		tooLong: 'errors.general.value.toolong',
		invalidEmail: 'errors.general.email.invalid.format',
		invalidPhone: 'errors.general.invalid.phone.number',
		numbersOnly: 'errors.general.numerics.only',
		invalid: 'errors.general.value.invalid',
	},
	password: {
		containsChars: 'errors.password.must.contain.atleast.chars',
		containsMixedCase: 'errors.password.must.contain.mixed.case.chars',
		containsDigits: 'errors.password.must.contain.atleast.digits',
		containsSpecial: 'errors.password.must.contain.atleast.specials.chars',
	},
};

export const getErrorKey = (collection, boField, error) => {
	return `errors.${collection}["${boField}"]["${error}"].message`;
};

// Better to use the above error key to get it from the collection, but this is here if the collection is
// missing anything
export const getMiscTextErrorKey = (error) => {
	return `miscText["${error}"]`;
};
