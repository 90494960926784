import React from 'react';
import { gql } from "@apollo/client";
import { get, isEmpty } from 'lodash';
import PropTypes from "prop-types";

import StdQuery from "./StdQuery.js";
import { LanguageGet, useLanguage } from "./Language.js";
import { SIZES } from 'components/icons/LoadingIcon.js';
import useStdQuery from './hooks/useStdQuery.js';
import { normalizeRequest } from "components/data/CmsContentListContext.js";


const GET_TRANSLATION = gql`
	query getTranslation (
		$request: ContentRequest!
		$language: String!
	){
		translation(
			request: $request
			language: $language
		) {
			id
			key
			content
		}
	}
`;

export const useCmsContent = (request, fallback) => {
	const language = useLanguage();

	const { data } = useStdQuery(GET_TRANSLATION, {
		variables: {
			request: normalizeRequest({
				item: request,
				hasTemplates: false,
			}),
			language,
		},
	});

	return get(data, 'translation.content', fallback);
};

const CmsContent = ({
	contentKey,
	variables,
	children,
}) => {
	const request = {
		key: contentKey,
		isTemplate: !isEmpty(variables),
		variables,
	};

	return (
		<LanguageGet.Consumer>{language => (
			<StdQuery query={GET_TRANSLATION} variables={{ request, language }}
				spinnerSize={SIZES.button}>
				{({ data }) => {
					const translatedContent = data?.translation;
					return children(translatedContent);
				}}
			</StdQuery>
		)}</LanguageGet.Consumer>
	);
};

CmsContent.propTypes = {
	contentKey: PropTypes.string.isRequired,
};

export default CmsContent;
