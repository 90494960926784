import React, {
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import cx from 'classnames';

import Control from './Control.js';
import { getCardIcon } from 'components/Icon.js';
import * as forms from './Forms.module.css';

export const inputClass = (error) => {
	return error
		? forms.error
		: forms.input;
};

const CreditCard = ({
	label = '',
	hideLabel = false,
	labelFirst = true,
	error = '',
	placeholder = '',
	overrideClass = '',
	currentCard = null,
	name,
	type = 'text',
	defaultValue = null,
	options,
}) => {
	const inputRef = useRef();

	return (
		<Control
			target={name}
			overrideClass={overrideClass}
			{...{
				label,
				labelFirst,
				error,
				hideLabel,
			}}
		>
			<div className={forms.ccInput}>
				<Cleave ref={inputRef}
					id={name}
					name={name}
					className={inputClass(error)}
					value={defaultValue}
					{...{
						type,
						options,
						placeholder,
					}} />
				<div className={cx(forms.ccIcon, forms.ccSvg)}>
					{getCardIcon(currentCard)}
				</div>
			</div>
		</Control>
	);
};

CreditCard.propTypes = {
	name: PropTypes.string.isRequired,
	initialvalue: PropTypes.string,
	label: PropTypes.node,
	hideLabel: PropTypes.bool,
	labelFirst: PropTypes.bool,
	error: PropTypes.string,
	placeholder: PropTypes.string,
	overrideClass: PropTypes.string,
	currentCard: PropTypes.string,
};

export default CreditCard;
