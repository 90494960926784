import React from 'react';
import cx from "classnames";
import Button, { Primary, Secondary } from 'components/Button.js';
import { useStepContext } from 'context/StepContext.js';
import {
	PURCHASE_CHARLIE_CARD_PAYMENT,
	PURCHASE_CHARLIE_CARD_SELECT_PRODUCTS,
	PurchaseCharlieCardTakeoverLayout,
} from 'pages/account/PurchaseCharlieCard.js';
import CmsContentListContext from "components/data/CmsContentListContext.js";
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import {
	find,
	values,
} from 'lodash';
import Input from 'components/forms/Input.js';
import PreventDefault from 'utils/PreventDefault.js';
import { WireFormHelper } from 'utils/FormHelper.js';
import {
	object as yup_object,
	number as yup_number,
} from "yup";
import { useCartContext } from 'context/CartProvider.js';
import { OPEN_TRANSIT_REGULAR_CARD } from 'server/api-types/WSSubsystemAccountToken.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey, getMiscTextErrorKey } from "utils/GetErrorKey.js";
import { GET_PAYMENT_METHODS } from 'components/data/order/sale/Paymentmethods.query.js';
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';
import { SUBSYSTEM_ID } from 'utils/Constants.js';
import WSIssueMediaLineItem from 'server/api-types/WSIssueMediaLineItem.js';
import WSIssueSubsystemMedia from 'server/api-types/WSIssueSubsystemMedia.js';
import WSMediaProduct from 'server/api-types/WSMediaProduct.js';
import { RIDER_CLASSES } from 'components/data/transit-account/TransitAccount.js';
import { EnablementFee } from 'pages/account/PurchaseCharlieCardCcForm.js';
import PublicAppVars from "utils/PublicAppVars.js";
import { useCustomerTravelCards } from 'components/data/session-user/useCustomerTravelCards.js';
import { useTranslations } from "components/data/CmsContentList.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";

import * as style from 'pages/account/PurchaseCharlieCardStoreForm.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as tabs from 'styles/Tabs.module.css';
import LoadingIcon from "components/icons/LoadingIcon.js";

const cms = {
	subHeader: "miscText.purchase-card-1-count",
	subHeaderDetails: "miscHtml.purchase-card-1-count-details",
	addpasses: "miscText.purchase-card-1-addpasses",
	skipBtn: "miscText.purchase-card-1-addpasses-skip",
	continueBtn: "miscText.purchase-card-1-addpasses-continue",
	retailCta: "miscHtml.purchase-card-1-retail-cta",
	reducedFare: "miscHtml.purchase-card-1-reducedfare",
	enablementFeeDescription: "miscHtml.purchase-cart-enablementfee-description",
	maxCardLimitError: "miscText.purchase-card-1-count-error",
};

export const getYupSchema = (travelCardCount, cmsContent) => {
	return yup_object().shape({
		quantity: yup_number()
			.min(1, getMiscTextErrorKey('errors.general.value.unexpected_productQuantity'))
			.max((PublicAppVars.MAX_TRAVEL_CARD_LIMIT - travelCardCount), cmsContent[ cms.maxCardLimitError ])
			.typeError(getErrorKey(COLLECTIONS.registration, 'contact.pin', BO_ERRORS.general.numbersOnly))
			.required('miscText["errors.general.field_required"]'),

	});
};

export const fetchPaymentMethodsForMediaProduct = async (wsMediaProduct, getPaymentMethodsMutation) => {
	const paymentMethodsRes = await getPaymentMethodsMutation({
		variables: {
			issueMediaLineItems: [
				new WSIssueMediaLineItem({
					issueMedia: new WSIssueSubsystemMedia({
						type: "TransitAccountMedia",
						subsystem: SUBSYSTEM_ID,
						itemTotalAmount: wsMediaProduct.price + wsMediaProduct.enablementFeeAmount,
						...wsMediaProduct,
					}).toResolver(),
				}),
			],
		},
	});

	return paymentMethodsRes.data.OrderRoute.getPaymentmethods;
};

const PurchaseCharlieCardStoreForm = () => {
	const { step } = useStepContext();
	const { mediaOptions, loading: prodCatLoading } = useGetSubsystemProductCatalog({ riderClassId: RIDER_CLASSES.fullFare });

	const CharlieCard = prodCatLoading ? {} : find(mediaOptions, wsMediaProduct => wsMediaProduct?.mediaType === OPEN_TRANSIT_REGULAR_CARD);

	const { data: customerTravelCardsData } = useCustomerTravelCards();
	const wsSubsystemAccountInfos = customerTravelCardsData?.subsystemAccountInfoQ ?? [];

	const cmsContent = useTranslations({
		list: values(cms),
		sharedVariables: {
			num: wsSubsystemAccountInfos.length,
			remaining: (PublicAppVars.MAX_TRAVEL_CARD_LIMIT - wsSubsystemAccountInfos.length),
			max: PublicAppVars.MAX_TRAVEL_CARD_LIMIT,
		},
	});

	const {
		addProduct,
		saveShoppingCartId,
	} = useCartContext();
	const { initializeFundingSources } = useFundingSourcesContext();

	const [ getPaymentMethodsMutation ] = useStandardMutation(GET_PAYMENT_METHODS);
	const { setStep } = useStepContext();

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema: () => getYupSchema(wsSubsystemAccountInfos.length, cmsContent) });

	const validateAndAddMediaToCart = async () => {
		const validated = await formHelper.startValidation(true);

		addProduct(
			new WSMediaProduct(CharlieCard).toResolver(),
			validated.quantity,
		);

		return validated;
	};

	const kickoffSubmit = async (nextStep) => {
		setSubmitting(true);

		try {
			await validateAndAddMediaToCart();

			if (nextStep === PURCHASE_CHARLIE_CARD_PAYMENT) {
				const {
					shoppingCartId,
					loadProductLineItemFunds,
					fundingSources,
					restrictFunding,
				} = await fetchPaymentMethodsForMediaProduct(CharlieCard, getPaymentMethodsMutation);

				saveShoppingCartId(shoppingCartId);
				initializeFundingSources({
					loadProductLineItemFunds,
					fundingSources, // might be empty
					restrictFunding,
				});
			}
		} catch (errorReport) {
			//validation failed
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmitting(false);
		}

		setStep(nextStep);
	};

	return (
		<CmsContentListContext.Provider value={cmsContent}>
			<WireFormHelper {...{ formHelper }}>
				<PurchaseCharlieCardTakeoverLayout step={step}>
					<form
						ref={formRef}
						onSubmit={PreventDefault(() => kickoffSubmit(PURCHASE_CHARLIE_CARD_PAYMENT))}
					>
						<div className={tabs.mainContent} data-qa="NewCharlieCardStoreFormContainer">
							<div className={tabs.main}>
								<CmsContentRenderer.H2
									className={tabs.title}
									data-qa="NewCharlieCardStoreFormTitle"
									contentKey={cms.subHeader}
									fallbackValue="How Many Cards Would You Like To Purchase?"
								/>
								<CmsContentRendered
									rawHtml
									parseComponents={false}
									contentKey={cms.subHeaderDetails}
									variables={{ max: PublicAppVars.MAX_TRAVEL_CARD_LIMIT }}
									fallbackValue={`<p>New cards will be automatically registered to your account, with a limit of {{max}} cards per account.</p>`}
								/>

								<Input type="number"
									name="quantity"
									overrideClass={cx(forms.textAreaContainer, style.amountInputOverride)}
									error={formHelper.getFieldError("quantity")}
									defaultValue="0"
									hideErrorText
								/>
								{/* The `quantity` input has a small static width that causes the error text to wrap
								multiple times. Lets show the error in hidden input */}
								<Input
									type="hidden"
									hideLabel
									error={formHelper.getFieldError('quantity')}
								/>

								<CmsContentRenderer.H2
									className={tabs.title}
									data-qa="NewCharlieCardStoreFormTitle"
									contentKey={cms.addpasses}
									fallbackValue="Do You Want To Add Passes Or Balance To These Cards?"
								/>

								<div className={cx(tabs.actions, style.buttonsOverride)}>
									{submitting
										? <LoadingIcon />
										: <>
											<Button
												text={cmsContent[ cms.skipBtn ] || "Next Step"}
												typeStyle={Secondary}
												data-qa="NewCharlieCardStoreBtn"
												overrideClass={tabs.actionBtn}
												disabled={submitting}
											/>
											<Button
												type="button"
												text={cmsContent[ cms.continueBtn ] || "Add Products"}
												typeStyle={Primary}
												data-qa="NewCharlieCardStoreBtn"
												overrideClass={tabs.actionBtn}
												onClick={PreventDefault(() => kickoffSubmit(PURCHASE_CHARLIE_CARD_SELECT_PRODUCTS))}
												submitting={submitting}
											/>
										</>
									}
								</div>

								<CmsContentRenderer.Div
									rawHtml
									parseComponents={false}
									contentKey={cms.retailCta}
									fallbackValue="<p>Want to purchase with cash? <a href=\'/deep-link/retail\'>Find sales locations</a>.</p>"
								/>
								<CmsContentRenderer.Div
									rawHtml
									parseComponents={false}
									contentKey={cms.reducedFare}
									fallbackValue="You may be able to get a free Charlie Card if you're eligible for one of our reduced fare programs. <a href='{{content('dashboard-addcard-reducedfare-url')}}'>Learn more</a>."
								/>
							</div>

							<EnablementFee />
						</div>
					</form>
				</PurchaseCharlieCardTakeoverLayout>
			</WireFormHelper>
		</CmsContentListContext.Provider>
	);
};

export default PurchaseCharlieCardStoreForm;
