import React from 'react';
import { values } from 'lodash';


import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import Toast from "components/Toast.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";

const cms = {
	confirmation: 'miscText.managed-accounts-remove-confirmation',
};

const ResignedAlternateSuccess = ({ name }) => {
	const { removeToast } = useGlobalToastsContext();

	return (
		<CmsContentList list={values(cms)} sharedVariables={{ name }}>{() => (
			<Toast
				onClosed={removeToast}
				type="success"
				title={<CmsContentRenderer.Span
					contentKey={cms.confirmation}
					fallbackValue={`You have stopped managing ${name}.`}
				/>}
			/>
		)}</CmsContentList>
	);
};

export default ResignedAlternateSuccess;
