import React, { useContext } from 'react';
import cx from 'classnames';
import {
	forEach,
	map,
} from 'lodash';

import {
	LanguageGet,
	LANGUAGES,
	LanguageSet,
} from "components/data/Language.js";
import * as forms from '../../components/forms/Forms.module.css';

import {
	Globe,
	SmallChevron,
} from 'components/Icon.js';

import * as dropdown from './CharlieDropdown.module.css';
import * as style from './LanguageSelector.module.css';
import { useModalContext } from "context/ModalProvider.js";
import LanguageConfirmation from "components/modals/LanguageConfirmation.js";
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline';
import Dropdown, { DropdownItem } from "components/Dropdown.js";

const langToOption = (langObj, index) => ({
	key: index,
	value: index,
	label: langObj.native,
	mobile: langObj.mobile,
	abbr: langObj.abbr,
});

export const getOptions = () => {
	const languages = [];

	forEach(LANGUAGES, (language, index) => {
		languages.push(langToOption(language, index));
	});

	return languages;
};

const options = getOptions();


const cms = {
	topMenuLangue: 'miscText.topmenu-language-aria',
};
const LanguageSelector = () => {
	const currentLanguage = useContext(LanguageGet);
	const setLanguage = useContext(LanguageSet);
	const { setModal } = useModalContext();

	const onConfirmLanguageSelection = (newLanguage, langKey) => {
		setLanguage(newLanguage, langKey);
		setModal(null);
	};

	return <Dropdown
		id={'languageSelector'}
		label={<>
			<Globe overrideClass={cx(dropdown.toggleIcon, dropdown.iconGlobe)} aria-hidden={true} />
			<CmsContentRenderedInline
				className={cx(forms.srOnly)}
				contentKey={cms.topMenuLangue}
				fallbackValue="Language selection menu"
			/>
			<span aria-hidden={true} className={dropdown.toggleText}>{LANGUAGES[ currentLanguage ].abbr}</span>
			<SmallChevron overrideClass={cx(dropdown.toggleArrow)} aria-hidden={true} />
		</>}
		overrideClass={cx(dropdown.container, style.container)}
		overrideButtonClass={(isOpen) => cx(dropdown.toggle, isOpen ? dropdown.isOpen : '')}
		overrideOptionsClass={cx(dropdown.options, style.options, dropdown.i18n)}
		placement={'bottom-end'}
	>
		{map(options, (language) =>
			<DropdownItem
				key={language.key}
				itemKey={language.key}
				onClick={() => setModal(
					<LanguageConfirmation
						currentlang={currentLanguage}
						selectedlang={language.value}
						onCancelLanguageSelection={() => setModal(null)}
						onConfirmLanguageSelection={() => onConfirmLanguageSelection(language.value, language.key)}
					/>,
				)}
				overrideClass={cx(
					dropdown.option,
					style.option,
				)}
			>
				{language.label}
			</DropdownItem>,
		)}
	</Dropdown>;
};

export default LanguageSelector;
