import React, {
	Component,
	Fragment,
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Modal from '../Modal.js';
import Alert from '../Alert.js';
import Button, {
	buttonTypeStyleDanger,
	Secondary,
} from '../Button.js';

import * as modal from '../Modal.module.css';

class LeaveGroup extends Component {
	static propTypes = {
		group: PropTypes.object,
		modalActive: PropTypes.bool,
		onModalClose: PropTypes.func.isRequired,
	};

	static defaultProps = {
		modalActive: false,
		group: {
			name: "Test Group",
		},
	};

	constructor(props) {
		super(props);

		this.state = {
			modalActive: false,
			isSuccessful: false,
		};


		this.setSuccess = this.setSuccess.bind(this);
		this.closeSuccess = this.closeSuccess.bind(this);
		this.setError = this.setError.bind(this);
		this.closeError = this.closeError.bind(this);
	};

	setSuccess() {
		this.setState({ isSuccessful: true });
		this.props.onModalClose();
	}


	closeSuccess() {
		this.setState({ isSuccessful: false });
	}


	setError() {
		this.setState({ isError: true });
		this.props.onModalClose();
	}


	closeError() {
		this.setState({ isError: false });
	}


	render() {
		const {
			modalActive,
			onModalClose,
			...rest
		} = this.props;


		return (
			<Fragment>
				<Modal title={"Request to Leave Group «" + this.props.group.name + "»"}
					   modalActive={modalActive}
					   onModalClose={onModalClose}
					   overrideClass={modal.isSmall}
				>
					<p className={modal.text}
					   data-qa="LeaveGroupModalText"
					>
						You will lose all product benefits from the «{this.props.group.name}». Are you sure you want to
						leave the group?
					</p>

					<div className={cx(modal.actions, modal.split)}>
						<Button
							text="Cancel"
							type={Secondary}
							onClick={this.setError}
							overrideClass={cx(modal.btn, modal.leftButton)}
							data-qa="LeaveGroupModalBtn"
						/>

						<Button
							text="Yes, leave group"
							type={buttonTypeStyleDanger}
							onClick={this.setSuccess}
							overrideClass={cx(modal.btn, modal.rightButton)}
							data-qa="LeaveGroupModalBtn"
						/>
					</div>
				</Modal>

				<Alert type="success"
					   title="You have joined a group"
					   text="You have successfully joined the group."
					   modalActive={this.state.isSuccessful}
					   onModalClose={this.closeSuccess}
				/>
			</Fragment>
		);
	}
}

export default LeaveGroup;
