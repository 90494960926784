import { clone } from "lodash";

// 2.7.72.3 WSMediaProduct
export default class WSMediaProduct {
	constructor({
		mediaSKU,
		mediaType,
		subsystemTokenType = null,
		inventoryPartNumber = null,
		name,
		nameShort = null,
		nameLong = null,
		price,
		depositAmount = null,
		replacementFeeAmount = null,
		enablementFeeAmount = null,
		displayGroup = null,
		displayOrder = null,
		display = null,
	}) {

		/**
		 * @type string(40)
		 * (Required) Media SKU.
		 */
		this.mediaSKU = mediaSKU;

		/**
		 * @type string(40)
         * (Required) The type of media to issue. See section Error: Reference source not foundError: Reference source not foundError: Reference source not found for possible values.
		 */
		this.mediaType = mediaType;

		/**
		 * @type string(40)
		 * string(40)
		 * (Optional) Subsystem specific token type as defined by the subsystem that manages the travel token.
	     * For example, for subsystem ABP, valid values are:  Smartcard
         *  Bankcard
         *  OpenTransitRegular.
		 */
		this.subsystemTokenType = subsystemTokenType;

		/**
		* @type string(100)
		* (Optional) Media Product Inventory Part Number
		*/
		this.inventoryPartNumber = inventoryPartNumber;

		/**
		 * @type string(250)
         * (Required) The name of the media in English. This name is provided for information only and should not be used for display purposes.
		 */
		this.name = name;

		/**
		 * @type string(250)
         * (Optional) The localized short name for the media to be used on display devices with restricted output capabilities such as a point-of-sale device.
         * May be blank.
		 */
		this.nameShort = nameShort;

		/**
		* @type string(250)
		* (Optional) The localized long name for the media to be used on display devices with space to accommodate long names.
		* May be blank.
		*/
		this.nameLong = nameLong;

		/**
		 * @type int
         * (Required) Media unit price in cents.
		 * */
		this.price = price;

		/**
		 * @type int
         * (Optional) Media deposit amount in cents.
		 */
		this.depositAmount = depositAmount;

		/**
		 * @type int
         * (Optional) Media replacement fee amount in cents.
		 * */
		this.replacementFeeAmount = replacementFeeAmount;

		/**
		 * @type int
		 * (Optional) Media enablement fee amount in cents.
		 */
		this.enablementFeeAmount = enablementFeeAmount;

		/**
		 * @type int
         * (Optional) Indicates the group number for grouping the products for display.
		 *
		 * Deprecated: Use display field instead.
		 */
		this.displayGroup = displayGroup;

		/**
		* @type int
        * (Optional) Order to display the product within its group or within the list of products if a displayGroup is not provided.
		 *
		 * Deprecated: Use display field instead.
		*/
		this.displayOrder = displayOrder;

		/**
		 * List<WSProductDisplay>
		 * (Optional) Provides information on how products
		 * should be grouped and ordered for display. Multiple
		 * entries are used when a product should be
		 * displayed in more than one group.
		 * @type {null}
		 */
		this.display = display;

	}

	static fromBackoffice(data) {
		const result = new WSMediaProduct(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = forGraphql.mediaSKU;

		return forGraphql;
	}
}

export const WSMediaProductType = [ `
		type WSMediaProduct {
			id: ID!
			mediaSKU: String!
			mediaType: String!
			subsystemTokenType: String
			inventoryPartNumber: String
			name: String!
			nameShort: String
			nameLong: String
			price: Int!
			depositAmount: Int
			replacementFeeAmount: Int
			enablementFeeAmount: Int
			displayGroup: Int
			displayOrder: Int
			display: [ WSProductDisplay! ]
		}
	`,
];
