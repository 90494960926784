import React, {
	useRef,
	useState,
} from 'react';
import cx from 'classnames';
import {
	Redirect,
} from "react-router-dom";
import {
	object as yup_object,
	string as yup_string,
} from "yup";

import { Lifecycles } from "libreact/lib/Lifecycles";

import { addYupMethod } from "utils/YupValidators.js";
import FormHelper from "utils/FormHelper.js";
import {
	USER_DEBT_QUERY,
	USER_PAYMENT_METHODS_QUERY,
} from "components/data/session-user/SessionUser.js";
import Toast from "../../components/Toast";
import { LINK_TRAVEL_TOKEN } from "graphql-queries/TravelTokens.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import PreventDefault from "utils/PreventDefault.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import CmsContentRenderer, { getContentOrFallback } from "components/data/CmsContentRenderer.js";
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";

import TakeOverLayout from "layouts/TakeOverLayout.js";
import Button, { Primary } from "components/Button.js";
import MaskedInput from "components/forms/MaskedInput.js";
import Input from "components/forms/Input.js";

import * as tabs from "styles/Tabs.module.css";
import * as styles from "./AddContactlessEMVCard.module.css";
import * as forms from '../../components/forms/Forms.module.css';
import * as cardStyles from './LinkCharlieCard.module.css';

import { useGlobalToastsContext } from 'context/ToastProvider';
import { ContactlessCardIcon } from 'components/icons/CardBrands';
import { PaymentOptionImages } from 'components/account/TabAddPaymentCard';
import { DesktopContactlessIcon } from 'components/Icon';
import useCardLast4Digits from 'components/data/hooks/cardLast4Digits';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { sliceMaskedPAN } from 'utils/FormatHelpers.js';
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import useCreditCardType from 'components/data/hooks/useCreditCardType.js';
import { useCmsContent } from "components/data/CmsContent.js";
import { useCmsContentList } from "components/data/CmsContentList.js";
import CmsContentListContext from "components/data/CmsContentListContext.js";
import { ComponentLoading } from 'components/icons/LoadingIcon.js';
import { nickNameMaxLengthCMSKey } from "components/modals/EditCardNickname.js";
import PublicAppVars from 'utils/PublicAppVars';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics';


addYupMethod("creditCard");


const cms = {
	header: 'miscText.addcard-emv-header',
	subHeader: 'miscText.addcard-emv-subheader',
	cardNumberLabel: 'miscText["addcard-emv-cardnumber.label"]',
	cardExpirationDate: 'miscText["addcard-emv-expiry.label"]',
	cardExpirationPlaceHolder: 'miscText["addcard-emv-expiry.placeholder"]',
	cardNickNameLabel: 'miscText["addcard-emv-nickname.label"]',
	cardNickDescriptionLabel: 'miscText["addcard-emv-nickname.description"]',
	cardNickDescriptionPlaceHolder: 'miscText["addcard-emv-nickname.placeholder"]',
	infoWhatIsSubHeader: 'miscText["addcard-emv-info-whatis-subheader"]',
	infoWhatIsDescription: 'miscText["addcard-emv-info-whatis-description"]',
	infoIsMineSubHeader: 'miscText["addcard-emv-info-ismine-subheader"]',
	infoIsMineDescription: 'miscText["addcard-emv-info-ismine-description"]',
	switchSideBar: 'miscHtml.addcard-emv-switch',
	submitLabel: 'miscText.addcard-emv-submit',
	confirmation: 'miscText.addcard-wallet-confirmation',
};

const AddContactlessEMVCard = ({ }) => {
	const { cardLast4Digits, onBlurCardNumber } = useCardLast4Digits();
	const { creditCardType, onBlurCreditCardType } = useCreditCardType();

	const cmsContentList = useCmsContentList({ list: Object.values(cms) });

	// minimize input flickering when creditCardType updates and the content is refetched
	const cmsContent = useCmsContent(
		// request
		{
			key: cms.cardNickDescriptionPlaceHolder,
			isTemplate: true,
			variables: { cardtype: creditCardType },
		},
		// fallbackValue
		creditCardType
	);

	const [ linkTravelCardMutation ] = useStandardMutation(LINK_TRAVEL_TOKEN, {
		refetchQueries: [
			{ query: USER_DEBT_QUERY },
			{ query: USER_PAYMENT_METHODS_QUERY },
		],
	});
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});

	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const getYupSchema = () => {
		const validations = {
			cardNumber: yup_string()
				.required("miscHtml.general-payment-cc-error-cardnum")
				.trim()
				.creditCard("miscHtml.general-payment-cc-error-cardnum"),
			expirationDate: yup_string()
				.matches(/[0-9]{2}\/[0-9]{2}/, "miscHtml.general-payment-cc-error-expiry")
				.required("miscHtml.general-payment-cc-error-expiry"),
			cardNickname: yup_string()
				.max(PublicAppVars.CARD_NICKNAME_MAXLENGTH, nickNameMaxLengthCMSKey),
		};

		return yup_object().shape(validations);
	};

	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, getYupSchema);


	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true, null);
		}
		catch (errorReport) {
			//validation failed
			formHelper.validationErrorHandler(errorReport);
			return;
		}
		finally {
			setSubmitting(false);
		}

		GoogleAnalytics.logEvent("User is attempting to link an EMV bankcard");

		try {
			await graphqlErrorMiddleware(linkTravelCardMutation({
				variables: {
					travelToken: {
						// The api docs for /canbelinked POST and WSEncryptedToken don't match the
						// expected data but we call 2.11.2.subsystem/<subsystem-id>/traveltoken/canbelinked POST
						// to add a payment method which expects a WSEncryptedToken
						// that includes a cardNumber, cardholderName, expirationMonth, and expirationYear.
						// We have a placeholder value for cardholderName since the endpoint errors without a
						// `cardholderName` parameter. CardholderName does not need to match the card information.

						nameOnCard: 'Sample Card Holder Name',
						cardExpiryMMYY: validated.expirationDate.replace('/', ''),
						cardNickname: validated.cardNickname,
						cardNumber: validated.cardNumber,

						tokenType: TRAVEL_TOKEN_TYPES.bankcard,
					},
				},
			}));

			// if no cardNickname was set, we display credit card type + last 4 digits
			const nickname = validated.cardNickname
				? validated.cardNickname
				: (creditCardType + " " + sliceMaskedPAN(validated.cardNumber));

			setToast(
				<Toast
					type="success"
					title={<CmsContentRendered
						cmsContent={cmsContentList.cmsContent}
						contentKey={cms.confirmation}
						fallbackValue="EMV has been added to your account."
						variables={{ nickname }}
					/>}
					onClosed={removeToast}
				/>
			);
		}
		catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Add Card Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}
		finally {
			setSubmitting(false);
		}

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardSelection),
			}} />
		);
	};

	const onDidMount = () => {
		formHelper.wireInputs();
	};

	const handleExpDateChange = (event) => {
		const element = event.target;

		if (formHelper.getFieldError(element.name)) {
			formHelper.clearFieldErrors(element.name);
		}

		return;
	};

	if (redirect) {
		return redirect;
	};

	if (cmsContentList.cmsContentLoading) {
		return <ComponentLoading />;
	}

	return (
		<CmsContentListContext.Provider value={cmsContentList.cmsContent}>
			<TakeOverLayout
				title={<CmsContentRenderer.Span
					contentKey={cms.header}
					fallbackValue="Link a Contactless Credit Card to Your Account"
				/>}
				showCancel
				data-qa="EMVHeader"
			>
				<div className={tabs.mainContent} data-qa="EMVInputContainer">
					<div className={cx(tabs.main, styles.tabMainContainer)}>
						<div className={styles.mainContainer}>
							<div className={styles.inputContainer}>
								<CmsContentRenderer.H2
									contentKey={cms.subHeader}
									className={tabs.title}
									data-qa="EMVheader"
									fallbackValue={"Enter the details of your card"}
								/>
								<PaymentOptionImages
									additionalClassNames={styles.hidePaymentOptsOnDesktop}
								/>

								<Lifecycles didMount={onDidMount}>
									<form
										method="post"
										data-qa="addPaymentMethodForm"
										ref={formRef}
										onSubmit={PreventDefault(kickoffSubmit)}
									>
										{formHelper.getFieldErrorJsx('')}
										<div className={tabs.ccForm}>
											<Input
												type="text"
												inputMode="numeric"
												pattern="[0-9]{13,19}"
												name="cardNumber"
												label={
													<CmsContentRenderer.Span
														contentKey={cms.cardNumberLabel}
														fallbackValue="Card Number"
													/>}
												data-qa="cardNumber-input"
												error={formHelper.getFieldError("cardNumber")}
												onBlur={() => {
													onBlurCardNumber(formHelper);
													onBlurCreditCardType(formHelper);
												}}
											/>

											<MaskedInput
												type="text"
												label={
													<CmsContentRenderer.Span
														contentKey={cms.cardExpirationDate}
														fallbackValue="Card Expiration Date"

													/>}
												name="expirationDate"
												placeholder="MM / YY"
												onChange={handleExpDateChange}
												overrideClass={styles.expirationDate}
												options={{
													date: true,
													datePattern: [ "m", "y" ],
												}}
												data-qa="EMVCardExpireDate"
											/>
											{formHelper.getFieldErrorJsx('expirationDate')}

											<Input
												name="cardNickname"
												label={
													<>
														<CmsContentRenderer.Div
															contentKey={cms.cardNickNameLabel}
															fallbackValue="Card Nickname"

														/>
														<CmsContentRenderer.Div
															className={forms.ccText}
															contentKey={cms.cardNickDescriptionLabel}
															fallbackValue="Enter a nickname to help you distinguish between cards. The last 4 digits of your card will always display."

														/>
													</>
												}
												labelClass={cardStyles.labelOverride}
												placeholder={getContentOrFallback({ content: cmsContent }, creditCardType)}
												isOptional={true}
												data-qa="card-nick-name-input"
												error={formHelper.getFieldError('cardNickname')}
												maxLength={PublicAppVars.CARD_NICKNAME_MAXLENGTH}
												suffix={`...${cardLast4Digits}`}
												autocomplete="off"
											/>
										</div>

										<div className={tabs.actions}>
											<Button
												// text="Link Contactless EMV Card"
												type={Primary}
												data-qa="LinkEMVCardBtn"
												{...{ submitting }}
											>
												<CmsContentRenderer.Span
													contentKey={cms.submitLabel}
													fallbackValue="Link contactless credit card"

												/>
											</Button>
										</div>

									</form>
								</Lifecycles>

							</div>
							<div className={styles.descriptionContainer}>
								<PaymentOptionImages additionalClassNames={styles.hidePaymentOptsOnMobile} />

								<div className={styles.descriptionHeaderContainer}>
									<CmsContentRenderer.H2
										contentKey={cms.infoWhatIsSubHeader}
										data-qa="EMVWhatIsheader"
										fallbackValue={"What is this?"}
										className={styles.infoHeader}
									/>
								</div>

								<CmsContentRenderer.Span
									contentKey={cms.infoWhatIsDescription}
									className={styles.infoDescription}
									data-qa="EMVWhatIsInfo"
									fallbackValue={"Contactless cards use EMV chip technology with NFC (near-field communication) for proximity payments. They let you “tap-and-go” to make a payment. You can use this form to add a physical card, or a card from your mobile wallet."}
								/>

								<CmsContentRenderer.H2
									contentKey={cms.infoIsMineSubHeader}
									className={styles.infoHeader}
									data-qa="EMVMineheader"
									fallbackValue={"Is my card contactless?"}
								/>

								<div className={styles.buttonDescription}>
									<CmsContentRenderer.Span
										contentKey={cms.infoIsMineDescription}
										className={styles.infoDescription}
										data-qa="EMVMineInfo"
										fallbackValue={"Look for the wave symbol to determine if you have a contactless credit card."}
									/>
									<div className={styles.iconContainer}>
										<ContactlessCardIcon className={styles.contactlessIcon} />
										<DesktopContactlessIcon className={styles.contactlessCardIcon} />
									</div>
								</div>

							</div>
						</div>
					</div>
					<div className={cx(tabs.sidebar, styles.tabSideBorder)} data-qa="EMVSideNavContainer">
						<CmsContentRenderer
							contentKey={cms.switchSideBar}
							fallbackValue={'<p>Do you travel with an Apple card?</p><p><a href="/direct-link/link-wallet?option=2">Go to Apple Card form</a></p>'}
							rawHtml={true}
							data-qa="EMVBillingAddressHeader"
						/>
					</div>
				</div>
			</TakeOverLayout>
		</CmsContentListContext.Provider>
	);
};

AddContactlessEMVCard.propTypes = {};


export default AddContactlessEMVCard;
