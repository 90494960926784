import React from 'react';
import cx from "classnames";
import { values } from "lodash";

import Panel from 'components/Panel.js';
import SsoToggles from '../SsoToggles.js';
import CmsContentList from "components/data/CmsContentList.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";

import * as panel from 'components/Panel.module.css';
import * as typography from "styles/typography.module.css";


/**
 * Data-QA Tags:
 * PanelSignInTitle, PanelSignInLabel
 */

const cms = {
	header: "miscText.security-login-subheader",
	text: "miscText.security-login-description",
};

const SignInSettings = () => (
	<Panel>
		<CmsContentList list={values(cms)}>{() => <>
			<CmsContentRenderer.H2
				className={cx(typography.h8, panel.heading)}
				contentKey={cms.header}
				data-qa="PanelSignInTitle"
				fallbackValue="Sign In Settings"
			/>
			<CmsContentRenderer.P
				className={panel.label}
				data-qa="PanelSignInLabel"
				contentKey={cms.text}
				fallbackValue="Currently your are signing in to your AFC 2.0 account with email and password. You can also connect to your social accounts for social sign in:"
			/>
			<div className={panel.section}>
				<div className={panel.row}>
					<SsoToggles />
				</div>
			</div>
		</>}</CmsContentList>
	</Panel>
);

export default SignInSettings;
