import { gql, useApolloClient } from '@apollo/client';
import React, {
	useState,
	useRef,
} from 'react';
import {
	Redirect,
	useLocation,
} from 'react-router-dom';
import {
	map,
	capitalize,
} from 'lodash';
import {
	object as yup_object,
	string as yup_string,
} from 'yup';
import { RadioGroup } from '@headlessui/react';
import cx from 'classnames';

import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import routeKeys from 'CustomerRouteKeys.js';
import { getPathByRoute } from 'App.js';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import FormHelper from 'utils/FormHelper.js';
import PreventDefault from 'utils/PreventDefault.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import { addYupMethod } from 'utils/YupValidators.js';

import {
	Station,
} from 'utils/TripHistory.js';
import { ALT_DATE_FORMAT } from 'utils/Constants.js';
import { BO_ERRORS, COLLECTIONS, getErrorKey } from 'utils/GetErrorKey.js';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { GET_RIDES_HISTORY } from 'components/data/transit-account/RidesHistory.query.js';
import { getTransitAccountRefetchQueries } from 'components/data/transit-account/TransitAccount.js';
import { getSessionTransitAccountRefetchQueries } from "components/data/transit-account/SessionTransitAccount.js";
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import PublicAppVars from 'utils/PublicAppVars.js';

import TakeOverLayout from 'layouts/TakeOverLayout.js';
import {
	Email,
	FullName,
	Phone,
} from 'components/data/session-user/SessionUser.js';
import {
	IfLoggedIn,
	IfNotLoggedIn,
	useLoginStage,
} from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";
import ServerDate from 'components/ServerDate.js';
import { TotalFareCell, TransitProductCell } from 'components/account/data-tables/TripHistoryTable.js';
import { getTransitModeIcon } from 'components/icons/TransitModes.js';
import Toast from "components/Toast.js";
import Button from 'components/Button.js';
import Panel from 'components/Panel.js';
import Input from 'components/forms/Input.js';

import { useReasonCodes, REASON_CODES } from 'components/data/customer-service/ReasonCodes.query.js';

import * as style from 'pages/DisputeRideDialog.module.css';
import * as takeOverStyle from 'layouts/TakeOverLayout.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as tables from 'components/DataTable.module.css';
import Textarea from "../components/forms/Textarea.js";

const DISPUTE_RIDE = gql`
	mutation DisputeRideM(
		$subsystemAccountReference: String!
		$reasonCode: String!
		$tripId: String!
		$notes: String
	) {
		disputeRide(
			tripId: $tripId
			reasonCode: $reasonCode
			subsystemAccountReference: $subsystemAccountReference
			notes: $notes
		)
	}
`;

// TODO update cms keys
const cms = {
	header: 'miscText.trip-dispute-header',
	description: 'miscHtml.trip-dispute-description',
	headingAccount: 'miscText.trip-dispute-account-subheader',

	// Todo: add missing cms key
	email: 'miscText.',
	headingRide: 'miscText.trip-dispute-ride-subheader',
	selectReason: 'miscText.trip-dispute-reason-subheader',
	submitButton: 'miscText.trip-dispute-submit',
	legal: 'miscText.trip-dispute-disclaimer',

	successTitle: 'miscText.trip-dispute-confirmation',
	otherReason: 'miscText["trip-dispute-reason-other-details.label"]',
};

const getYupSchema = ({ notesRequired }) => {
	addYupMethod("validEmail");

	return yup_object().shape({
		reasonCode: yup_string()
			.required('miscText["errors.general.blankfield"]')
			.trim(),
		email: yup_string(),
		notes: notesRequired ? yup_string()
			.required('miscText["errors.general.blankfield"]')
			.max(PublicAppVars.DISPUTE_RIDE_MAX_CHAR_LENGTH, getErrorKey(COLLECTIONS.contactCustomerService, 'feedbackMessage', BO_ERRORS.general.tooLong))
			.trim()
			: null,
	}).transform(function (current, original) {
		// email validation only for unlogged and unregistered user to send email info to BO
		this.fields.email = original?.email?.length === undefined
			? yup_string()
			: yup_string()
				.email(getErrorKey(COLLECTIONS.fareChargeDispute, 'unregisteredEmail', BO_ERRORS.general.invalidEmail))
				// TODO clarify max value when hook up
				.max(50, getErrorKey(COLLECTIONS.fareChargeDispute, 'unregisteredEmail', BO_ERRORS.general.tooLong))
				.validEmail('miscText["errors.general.email.cannot.start.with.special.characters"]')
				.required('miscText["errors.general.blankfield"]');
		return current;
	});
};


const DisputeRideDialog = () => {

	const { reasonCodeTypes } = useReasonCodes({ typeId: REASON_CODES.TripVoid });
	const { loginStage } = useLoginStage();
	const location = useLocation();
	const { wsTripHistory, filtersApplied } = location.state ?? {};

	const {
		startDateTime,
		travelMode,
		travelModeDescription,
		startLocationDescription,
		totalFare,
		rideId,
	} = wsTripHistory ?? {};

	const subsystemAccountReference = useTransitAccountIdContext();

	const [ reasonCode, setReasonCode ] = useState('');
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);

	const unRegisteredLoggedIn = (loginStage === loginStages.unRegisteredLoggedIn);
	const apolloClient = useApolloClient();
	const formRef = useRef(null);

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, () => getYupSchema({ notesRequired: reasonCode.notesMandatoryFlag }));

	const onSuccess = (cmsContent) => setToast(
		<Toast
			type="success"
			title={<CmsContentRenderer.Span
				cmsContent={cmsContent}
				contentKey={cms.successTitle}
				fallbackValue={'Your refund request has been submitted. We will review your request and respond within 5 business days.'}
			/>}
			onClosed={removeToast}
		/>,
	);

	const kickoffSubmit = async (cmsContent) => {
		setSubmitting(true);


		let validated;
		try {
			validated = await formHelper.startValidation(true);
			formHelper.clearAllErrors();
		}
		catch (errorReport) {
			setSubmitting(false);
			// yup validation failed, but those errors are already in state
			noticeError(null, levels.verbose, errorReport, `Dispute Ride Form validation: ${errorReport.message}`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		const variables = {
			tripId: validated.tripId,
			reasonCode: validated.reasonCode,
			subsystemAccountReference,
			notes: validated.notes,
		};

		try {
			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: DISPUTE_RIDE,
					variables,
					refetchQueries: [
						{
							query: GET_RIDES_HISTORY,
							variables: {
								subsystemAccountRef: subsystemAccountReference,
								filtersApplied,
							},
						},
						...getTransitAccountRefetchQueries(subsystemAccountReference),
						...getSessionTransitAccountRefetchQueries({ subsystemAccountReference }),
					],
				}));
		}
		catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			setSubmitting(false);
			noticeError(null, levels.info, errorReport, `Dispute Ride Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("User is submitting the Dispute Ride Form");

		setSubmitting(false);

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(
					unRegisteredLoggedIn
						? routeKeys.GuestCardOverview
						: routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference }
				),
				state: { success: true },
			}} />
		);

		onSuccess(cmsContent);

	};

	if (redirect) {
		return redirect;
	}

	const reasonCodes = [
		...(reasonCodeTypes?.[ 0 ]?.reasonCodes ?? []),
	];

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout title={cmsContent[ cms.header ] || "Refund Request"}
				showCancel
			>
				<div className={takeOverStyle.contactContainer}>
					<CmsContentRenderer.Div
						rawHtml
						contentKey={cms.description}
						className={style.description}
						fallbackValue="You may dispute this trip if you believe you have been charged in error."
					/>

					<form
						method="post"
						id="DisputeFareForm"
						data-qa="disputeFareForm"
						onSubmit={PreventDefault(() => kickoffSubmit(cmsContent))}
						ref={formRef}
					>
						<section>
							<CmsContentRenderer.H2
								className={takeOverStyle.sectionHeading}
								contentKey={cms.headingAccount}
								fallbackValue="Dispute charge for:"
							/>

							<IfLoggedIn>
								<Panel>
									<div className={takeOverStyle.contactDetailsWrapper}>
										<div>
											<div className={takeOverStyle.contactValue}><FullName /></div>
											<div className={takeOverStyle.contactValue}><Email /></div>
											<div className={takeOverStyle.contactValue}><Phone /></div>
										</div>
									</div>
								</Panel>
							</IfLoggedIn>

							<IfNotLoggedIn>
								<Input
									type="email"
									name="email"
									label={cmsContent[ cms.email ] || "Your email address"}
								/>
								{formHelper.getFieldErrorJsx('email')}
							</IfNotLoggedIn>
						</section>

						<section>
							<CmsContentRenderer.H2
								className={takeOverStyle.sectionHeading}
								contentKey={cms.headingRide}
								fallbackValue="Regarding this trip:"
							/>
							<Panel>
								<div className={cx(takeOverStyle.contactDetailsWrapper, takeOverStyle.rideInfo)}>
									<div className={tables.date}>
										{startDateTime
											? <ServerDate
												dateISO={startDateTime}
												options={{ day: "numeric", month: "short" }}
												altFormat={ALT_DATE_FORMAT}
											/>
											: <div className={tables.autofilled}>&mdash;</div>
										}
									</div>

									<div className={tables.time}>
										{startDateTime
											? <ServerDate
												dateISO={startDateTime}
												options={{ hour: 'numeric', minute: 'numeric' }}
											/>
											: <div className={tables.autofilled}>&mdash;</div>
										}
									</div>

									<div className={tables.serviceLocation}>
										{getTransitModeIcon(capitalize(travelMode), { overrideClass: tables.transportIcon })}
										<Station {...{ station: startLocationDescription, mode: travelModeDescription }} />
									</div>
									<TransitProductCell {...{ wsTripHistory }} />
									<TotalFareCell {...{ value: totalFare, row: wsTripHistory }} />
								</div>
							</Panel>
						</section>

						<section className={takeOverStyle.contactSection}>
							<RadioGroup value={reasonCode}
								onChange={setReasonCode}
								className={forms.radioGroup}
							>
								<RadioGroup.Label>
									<CmsContentRenderer.H2
										className={takeOverStyle.sectionHeading}
										contentKey={cms.selectReason}
										fallbackValue="Select reason for the dispute:"
									/>
								</RadioGroup.Label>

								<div className={forms.radioGroupOptions}>
									{map(reasonCodes, (reasonCode) => (
										<RadioGroup.Option
											key={reasonCode.reasonCodeId}
											value={reasonCode}
											className={cx(forms.radioGroupOption)}
										>
											{({ checked }) => (
												<span className={cx(forms.radioLabel, checked && forms.radioChecked)}>
													{reasonCode.description}
												</span>
											)}
										</RadioGroup.Option>
									))}
								</div>
							</RadioGroup>

							{reasonCode?.notesMandatoryFlag &&
								<Textarea
									maxLength={PublicAppVars.CONTACT_US_MAX_CHAR_LENGTH}
									name='notes'
									label={<CmsContentRenderer contentKey={cms.otherReason} fallbackValue='Please explain why you are requesting a refund' />}
									error={formHelper.getFieldError('notes')}
								/>
							}

							<Input
								key={reasonCode?.reasonCodeId}
								type="hidden"
								name='reasonCode'
								value={reasonCode?.reasonCodeId}
								controlled={true}
								hideLabel={true}
							/>
						</section>
						{formHelper.getFieldErrorJsx('reasonCode')}

						<input
							type="hidden"
							name="tripId"
							value={rideId}
						/>

						<div className={forms.actions}>
							<Button
								isPrimary
								additionalClassNames={forms.action}
								submitting={submitting}
							>
								<CmsContentRenderer.Span
									contentKey={cms.submitButton}
									fallbackValue="Submit"
								/>
							</Button>
							{formHelper.getFieldErrorJsx('')}
							<CmsContentRenderer.P
								className={takeOverStyle.legal}
								contentKey={cms.legal}
								fallbackValue="Responses may take up to 5 business days"
							/>
						</div>
					</form>
				</div>
			</TakeOverLayout>
		)}</CmsContentList>
	);
};

DisputeRideDialog.propTypes = {};

export default DisputeRideDialog;
