import { clone } from "lodash";
import WSSubsystemAccountFeeDue from './WSSubsystemAccountFeeDue.js';

// 2.7.29 WSFeeDueLineItem

export default class WSFeeDueLineItem {
	constructor({
		feeDue,
	}) {
		// WSFeeDue
		// (Required) The fee due to be paid.
		this.feeDue = feeDue;
	}

	static fromBackoffice(data) {
	
		const result = new WSFeeDueLineItem(data);

		result.feeDue = new WSSubsystemAccountFeeDue(data.feeDue);
		return result;
	}

	toBackoffice() {
		const result = clone(this);

		result.feeDue = result.feeDue.toBackoffice();
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.feeDue = forGraphql.feeDue.toResolver();
		return forGraphql;
	}
}

export const WSFeeDueLineItemFragment = `
	feeDue: WSSubsystemAccountFeeDue
`;

export const InputWSFeeDueLineItemType = [ `
	input InputWSFeeDueLineItem {
		feeDue: InputWSSubsystemAccountFeeDue!
	}
` ];

export const WSFeeDueLineItemType = [ `
	type WSFeeDueLineItem {
		${WSFeeDueLineItemFragment}
	}
` ];
