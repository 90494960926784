import { gql } from "@apollo/client";
import React from 'react';
import { values } from 'lodash';

import { useGlobalToastsContext } from 'context/ToastProvider.js';
import CmsContentRenderer from "../data/CmsContentRenderer.js";
import CmsContentList from "../data/CmsContentList.js";

import Modal from '../Modal.js';
import Toast from "../Toast.js";
import EditAddressForm from "./EditAddressForm.js";


export const MANAGE_ADDRESS = gql`
	mutation manageAddressM(
		$addressId: ID
		$address: InputWSAddress!
		$primaryBilling: Boolean,
		$primaryShipping: Boolean,
		$remove: Boolean
	) {
		manageAddress(
			address: $address
			addressId: $addressId
			primaryBilling: $primaryBilling,
			primaryShipping: $primaryShipping,
			remove: $remove
			) {
				contact {
					address {
						id
						address1
						address2
						address3
						city
						state
						postalCode
						country
					}
					id
				}
			}
	}`;

export const UPDATE_PAYMENT_ADDRESS = gql`
	mutation updatePaymentAddressM(
		$addressId: ID!
		$address: InputWSAddress
		$fundingSourcesUpdated: [ ID ]
		$contactsUpdated: [ ID ]
		$updateDependencies: Boolean
		$primaryBilling: Boolean
		$primaryShipping: Boolean
		$unverified: Boolean
	) {
		updatePaymentAddress(
			address: $address
			addressId: $addressId
			contactsUpdated: $contactsUpdated
			fundingSourcesUpdated: $fundingSourcesUpdated
			updateDependencies: $updateDependencies
            primaryBilling: $primaryBilling
            primaryShipping: $primaryShipping
            unverified: $unverified
		) {
			addressId
			fundingSourcesUpdated {
				key
				value
			}
		}
	}
`;

const cms = {
	editHeader: "miscText.personal-addresses-edit-header",
	addHeader: "miscText.personal-addresses-add-header",
	addConfirmation: "miscText.account-address-confirmation-add",
	editConfirmation: "miscText.account-address-confirmation-edit",
	confirmBilling: "miscText.account-address-confirmation-billing",
	confirmShipping: "miscText.account-address-confirmation-shipping",
};

export const DEFAULT_ADDRESS_TYPES = {
	SHIPPING: 1,
	BILLING: 2,
};

const EditAddress = ({
	onModalClose = () => { },
	address = null,
	manageAddressMutation,
}) => {
	const { setToast, removeToast } = useGlobalToastsContext();

	const sessAddress = address ? address : {};
	const isEditExisting = Boolean(sessAddress.addressId);

	const onSuccess = (cmsContent) => setToast(
		<Toast
			type="success"
			title={
				<CmsContentRenderer
					cmsContent={cmsContent}
					contentKey={isEditExisting ? cms.editConfirmation : cms.addConfirmation}
					fallbackValue={isEditExisting
						? "This address has been updated."
						: "This address has been added to your personal information."
					}
				/>
			}
			onClosed={removeToast}
		/>
	);

	const onSetDefaultSuccess = (cmsContent) => (defaultAddressType) => {
		if (isEditExisting) {
			setToast(
				<Toast
					type="success"
					title={
						<CmsContentRenderer
							cmsContent={cmsContent}
							contentKey={defaultAddressType === DEFAULT_ADDRESS_TYPES.BILLING
								? cms.confirmBilling
								: cms.confirmShipping
							}
							fallbackValue={defaultAddressType === DEFAULT_ADDRESS_TYPES.BILLING
								? "This address has been saved as your default billing address."
								: "This address has been saved as your default shipping address."
							}
						/>
					}
					onClosed={removeToast}
				/>
			);
		}
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal title={
				<CmsContentRenderer
					contentKey={isEditExisting ? cms.editHeader : cms.addHeader}
					fallbackValue={isEditExisting ? "Edit Saved Address" : "Add an Address"}
				/>
			}>
				<EditAddressForm
					manageAddressMutation={manageAddressMutation}
					prefillAddress={sessAddress}
					onModalClose={onModalClose}
					onSuccess={() => onSuccess(cmsContent)}
					onSetDefaultSuccess={onSetDefaultSuccess(cmsContent)}
				/>
			</Modal>
		)}</CmsContentList>
	);
};

export default EditAddress;
