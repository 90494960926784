import WSCountryConfig, { WSCountryConfigType } from "./WSCountryConfig.js";
import WSKeyValue from "./WSKeyValue.js";
import {
	clone,
	map,
} from "lodash";

export default class WSCountryInfo {
	constructor({
		country,
		description,
		dialingCode,
		states,
		config,
	}) {
		// string(6)
		// (Required) The ISO 3166-1 alpha-2 country code.
		this.country = country;

		// String(30)
		// (Required) The short description of the country.
		this.description = description;

		// String(10)
		// (Required)  The phone dialing code of the country.
		this.dialingCode = dialingCode;

		// List<WSKeyValue>
		// (Optional)  A list of states for the country.
		this.states = states;

		// WSCountryConfig
		// (Required)  The country specific configuration for address format.
		this.config = config;
	}

	static fromBackoffice(data) {
		const result = new WSCountryInfo(data);
		result.config = WSCountryConfig.fromBackoffice(data.config);
		result.states = data.states ? map(data.states, state => WSKeyValue.fromBackoffice(state)) : null;
		return result;
	}

	static fromSerialized(data) {
		const result = new WSCountryInfo(data);
		result.config = WSCountryConfig.fromSerialized(data.config);
		result.states = data.states ? map(data.states, state => WSKeyValue.fromSerialized(state)) : null;
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = this.country;
		forGraphql.config = forGraphql.config.toResolver();
		forGraphql.states = forGraphql.states ? map(forGraphql.states, state => state.toResolver()) : null;
		return forGraphql;
	}
}

export const WSCountryInfoType = [
	// the id is the country code, which is also in the 2-character country field
	// description is the country name
	`
		type WSCountryInfo {
			id: ID!
			country: String!
			description: String!
			dialingCode: String!
			states: [ WSKeyValue ]
			config: WSCountryConfig!
		}
	`,
	...WSCountryConfigType,
];
