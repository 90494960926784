import React,{ useState, useRef } from "react";
import PropTypes from "prop-types";
import cx from 'classnames';

import Control from './Control.js';
import { Eye, EyeHide } from '../Icon.js';
import * as inputStyles from './Input.module.css';
import Input from "./Input.js";

import * as formStyles from './Forms.module.css';
import * as style from './Password.module.css';
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { getMiscTextErrorKey } from "utils/GetErrorKey.js";
import FormHelper from "utils/FormHelper.js";

const ToggleEyeIcon = ({ togglePassword, showPassword }) => {
	return (
		<button
			type="button"
			className={style.stateToggle}
			onClick={togglePassword}
		>
			{showPassword
				? <EyeHide />
				: <Eye />
			}
			<CmsContentRendered.Span
				className={formStyles.srOnly}
				contentKey={getMiscTextErrorKey(showPassword ? "register-email-password-toggle-hide" : 'register-email-password-toggle-show')}
				fallbackValue="Show password"
			/>
		</button>
	);
};


const Password = ({
	name = '',
	arialabel = '',
	password = '',
	initialvalue = '',
	label = '',
	hideLabel = false,
	labelFirst = true,
	error = '',
	errorDangerous = false,
	overrideClass = '',
	onChange = () => { },
	containerOverrideClass,
	...rest
}) => {
	const ref = useRef();
	const [ showPassword, setShowPassword ] = useState(false);

	const isInvalid = Boolean(error);

	const ariaInvalid = isInvalid
		? { "aria-invalid": isInvalid }
		: null;

	const ariaErrorMessage = isInvalid
		? { "aria-errormessage": FormHelper.getStateErrorField(name) }
		: null;

	const classNames = cx((error ? inputStyles.error : inputStyles.input), style.password);

	return (
		<Control
			hideLabel={hideLabel}
			label={label}
			target={name}
			overrideClass={overrideClass}
			error={error}
		>
			<div className={cx(style.passwordContainer, containerOverrideClass)}>
				<Input
					type={showPassword ? "text" : "password"}
					className={classNames}
					id={name}
					name={name}
					overrideAutoCompleteStyles
					ref={ref}
					defaultValue={initialvalue}
					onChange={onChange}
					aria-label={arialabel}
					aria-invalid={ariaInvalid}
					aria-errormessage={ariaErrorMessage}
					{...rest}
				/>
				<ToggleEyeIcon
					togglePassword={() => setShowPassword(!showPassword)}
					showPassword={showPassword}
				/>
			</div>
		</Control>
	);
};

Password.propTypes = {
	password: PropTypes.string,
	name: PropTypes.string.isRequired,
	initialvalue: PropTypes.string,
	label: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx as long as !hideLabel
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.node),
	]),
	arialabel: PropTypes.string,
	hideLabel: PropTypes.bool,
	labelFirst: PropTypes.bool,
	error: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.bool,
	]),
	errorDangerous: PropTypes.bool,
	overrideClass: PropTypes.string,
	onChange: PropTypes.func,
};

export default Password;
