import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
	get,
	find,
	values,
} from 'lodash';

import { getPathByRoute } from 'App.js';
import CmsContentList, { useCmsContentList } from 'components/data/CmsContentList.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { findPrimaryToken, isGhostCard } from 'components/manage-cards/TokenHelpers.js';
import { isActiveOrNegative } from "utils/transit-account-utils/StatusUtils.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { useCmsContent } from "components/data/CmsContent.js";
import TapsAlertsProvider, { TapsAlertsContext } from "context/TapsAlertsContext.js";
import PurseBalanceProvider, { PurseBalanceContext } from 'context/PurseBalanceContext.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { useTransitAccount } from "components/data/transit-account/TransitAccount.js";

import Container from 'components/Container.js';
import SidebarNavigation from 'layouts/components/SidebarNavigation.js';
import SidebarCardSelector from 'layouts/components/SidebarCardSelector.js';
import { SmallChevron } from 'components/Icon.js';
import Button, {
	Primary,
	Secondary,
} from 'components/Button.js';
import ContactCustomerService from 'components/banners/ContactCustomerService.js';
import SuspendedForNegativeBalance from 'components/banners/SuspendedForNegativeBalance.js';
import MissedTap from 'components/banners/MissedTap.js';
import Dropdown, { DropdownItem } from 'components/Dropdown.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';

import * as dropdownStyles from 'components/Dropdown.module.css';
import * as admin from './Admin.module.css';
import { getCreditCardType } from 'components/card-icons/util';
import { MaskedValues } from 'components/card-icons/GenericIconAndMaskedValue.js';
import { useEmvCanReload } from 'components/data/transit-account/EMV.helpers.js';
import TokenNameAndPan from 'components/account/card/TokenNameAndPan';
import { getCharlieCardBreadcrumbs } from 'pages/account/CardSelection';
import Untranslatable from 'components/Untranslatable';
import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb';
import { useCustomerTravelCards } from 'components/data/session-user/useCustomerTravelCards.js';
import { ComponentLoading } from 'components/icons/LoadingIcon.js';
import { ReMapOpenTransitName } from 'components/account/card/activate-card/ActivateCard.js';
import { charlieCardCmskeys } from "../components/account/card/CharlieCardCmskeys.js";

const cms = {
	sidebarCardOverview: 'miscText.cardmenu-card-overview',
	sidebarCardSettings: 'miscText.cardmenu-cardsettings',
	sidebarTripHistory: 'miscText.cardmenu-ride-history',
	sidebarPurchaseHistory: 'miscText.cardmenu-purchase-history',
	sidebarConnectedServices: 'miscText.cardmenu-connected-services',
	sidebarPurchase: 'miscText.cardmenu-purchase',

	contactFooter: 'miscText.general-contactFooter-text',
	contactFooterButton: 'miscText.general-contactFooter-button',

	checkAnotherCard: 'miscText.guest-sidebar-check-another',
	loadPassesOrBalance: 'miscText.cardmenu-purchase',

	ghostCardLabel: "miscText.empty-account-nickname",

	...charlieCardCmskeys,
};

export const SelectedCardName = ({ routeKey }) => {
	const transit_account_id = useTransitAccountIdContext();
	const {
		loading: transitAccountLoading,
		data: transitAccountQdata,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id });

	const cmsContent = useCmsContent(
		// request
		{
			key: cms.ghostCardLabel,
			isTemplate: false,
		},
		// fallbackValue
		`History`,
	);

	if (transitAccountLoading) {
		return <ComponentLoading />;
	}

	const primaryToken = findPrimaryToken(transitAccountQdata.transitAccountQ.tokens);
	const primaryTokenInfo = primaryToken?.tokenInfo;

	return (
		<BreadcrumbItem
			routeKey={routeKey}
			variables={{ transit_account_id }}
		>
			<Untranslatable elementType="span">
				{primaryTokenInfo
					? <TokenNameAndPan tokenInfo={primaryTokenInfo} mediaType={primaryToken.mediaType} />
					: <CmsContentRenderer
						elementType="span"
						content={cmsContent}
						contentKey={cms.ghostCardLabel}
					/>}
			</Untranslatable>
		</BreadcrumbItem>
	);
};

export const getCardLayoutBreadcrumbs = () => [
	...getCharlieCardBreadcrumbs(),
	<SelectedCardName
		key={"selectedCardName"}
		routeKey={routeKeys.AccountCardOverview}
	/>,
];

const CardLayout = ({
	showCallToAction = true,
	showSideNav = true,
	showRightSideNav = false,
	title = "",
	showMissedTaps = false,
	children,
}) => {

	const transit_account_id = useTransitAccountIdContext();

	const {
		data: transitAccountQdata,
		loading: transitAccountQDataLoading,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id });

	const {
		data: travelCardData,
		loading: travelCardDataLoading,
	} = useCustomerTravelCards();

	const cmsContentList = useCmsContentList({
		list: values(cms),
	});

	const transitAccountQ = transitAccountQdata?.transitAccountQ;

	const emvCanReload = useEmvCanReload({ subsystemAccountReference: transit_account_id });

	const canReload = useMemo(() => {
		if (!transitAccountQ) {
			return false;
		}

		return emvCanReload && isActiveOrNegative(transitAccountQ);

	}, [ transitAccountQ, emvCanReload ]);


	const links = [
		{
			key: 'overviewpage',
			label: <CmsContentRenderedInline contentKey={cms.sidebarCardOverview} fallbackValue='Card Overview' />,
			to: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id }),
		},
		{
			key: 'settings',
			label: <CmsContentRenderedInline contentKey={cms.sidebarCardSettings} fallbackValue='Card Settings' />,
			to: getPathByRoute(routeKeys.AccountCardSettings, { transit_account_id }),
		},
		{
			key: 'triphistory',
			label: <CmsContentRenderedInline contentKey={cms.sidebarTripHistory} fallbackValue='Trip History' />,
			to: getPathByRoute(routeKeys.AccountCardRideHistory, { transit_account_id }),
		},
		{
			key: 'purchasehistory',
			label: <CmsContentRenderedInline contentKey={cms.sidebarPurchaseHistory} fallbackValue='Purchase History' />,
			to: getPathByRoute(routeKeys.AccountCardPurchaseHistory, { transit_account_id }),
		},
	];

	if (PublicAppVars.ENABLE_CONNECTED_SERVICES) {
		links.push({
			key: 'connectedservices',
			label: <CmsContentRenderedInline contentKey={cms.sidebarConnectedServices} fallbackValue='Connected Services' />,
			to: getPathByRoute(routeKeys.AccountCardServices, { transit_account_id }),
		});
	}

	const subsystemAccountInfoQ = get(travelCardData, 'subsystemAccountInfoQ', []);

	const cards = subsystemAccountInfoQ.map(({ subsystemAccountReference, subsystemAccountDetailedInfo }) => {

		const { tokens } = subsystemAccountDetailedInfo;

		// replaced cards dont have tokens
		// https://reflexions.atlassian.net/browse/MBTA-2758
		if (isGhostCard(subsystemAccountDetailedInfo)) {
			noticeError(null, levels.error, `TA: ${subsystemAccountReference} is missing tokens`);
			return;
		};

		const primaryToken = findPrimaryToken(tokens);
		const primaryTokenInfo = primaryToken.tokenInfo;

		const nickname = ReMapOpenTransitName({
			tokenType: primaryToken.mediaType,
			cmsContent: cmsContentList.cmsContent,
			tokenNickname: primaryTokenInfo.tokenNickname,
		});

		return {
			label: <MaskedValues {...{
				showName: true,
				nickname,
				maskedPan: primaryTokenInfo.maskedPan,
				creditCardType: getCreditCardType(primaryTokenInfo),
				serialNumber: primaryTokenInfo.serialNumber,
				tokenType: primaryTokenInfo.tokenType,
			}} />,
			action: getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference }),
			subsystemAccountReference,
			type: primaryTokenInfo.tokenType,
		};
	}).filter(x => x);

	const selectedCard = find(cards, option => option.subsystemAccountReference === transit_account_id) ?? null;

	if (travelCardDataLoading || transitAccountQDataLoading) {
		return <ComponentLoading />;
	}

	return (
		<>
			<PurseBalanceProvider subsystemAccountReference={transit_account_id}>
				<PurseBalanceContext.Consumer>{({ hasNegativeBalance }) => (
					hasNegativeBalance
						? <SuspendedForNegativeBalance subsystemAccountReference={transit_account_id} />
						: null
				)}</PurseBalanceContext.Consumer>
			</PurseBalanceProvider>

			{showMissedTaps &&
				<TapsAlertsProvider>
					<TapsAlertsContext.Consumer>{({ tapsAlerts }) => (
						tapsAlerts?.length
							? <MissedTap />
							: null
					)}</TapsAlertsContext.Consumer>
				</TapsAlertsProvider>
			}

			<Container overrideClass={admin.container}>
				{showSideNav ?
					<div className={admin.sidebar}>
						{selectedCard
							? <SidebarCardSelector {...{ cards, selectedCard }} />
							: null}
						<CmsContentList list={values(cms)}>{() =>
							<>
								<SidebarNavigation {...{ links, additionalClasses: admin.cardSideNav }} />
								{canReload ?
									<Button
										additionalClassNames={admin.sidebarBtn}
										typeStyle={Primary}
										to={{
											pathname: getPathByRoute(routeKeys.AccountPurchaseProduct, { transit_account_id }),
											state: { purchase: true },
										}}
									>
										<CmsContentRenderer.Span contentKey={cms.sidebarPurchase} fallbackValue="Load Passes or Balance" />
									</Button>
									: null
								}
							</>
						}</CmsContentList>
					</div>
					: null
				}

				<section className={admin.main}>
					<div className={cx(admin.header, admin.cardLayout)}>
						<h1 className={cx(admin.pageTitle, admin.desktopOnly)}
							data-qa="AdminPageTitle"
						>
							<span className={admin.pageTitleText}>{title}</span>
						</h1>

						{selectedCard
							? <div className={cx(admin.mobilePageMenu, admin.mobileCardInfo)}>{selectedCard.label}</div>
							: null}
						<Dropdown
							id={'cardLinksDropdown'}
							additionalClasses={cx(admin.mobilePageMenu, dropdownStyles.pageMenu)}
							ariaLabel={title}
							label={<>
								<span className={admin.pageTitleText}>{title}</span>
								<SmallChevron overrideClass={admin.pageTitleIcon} aria-hidden={true} />
							</>}
						>
							{links.map(({ key, to, label }) => <DropdownItem itemKey={key} key={key} to={to}>{label}</DropdownItem>)}
						</Dropdown>
					</div>

					{children}
				</section>

				{showRightSideNav && canReload
					? (
						<div className={admin.rightNav}>
							<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
								<Button
									text={cmsContent[ cms.checkAnotherCard ] || "Check Another Card"}
									type={Secondary}
									link={getPathByRoute(routeKeys.ViewTransactionHistory)}
								/>
								<Button
									text={cmsContent[ cms.loadPassesOrBalance ] || "Load Passes or Balance"}
									type={Primary}
								/>
							</>)}</CmsContentList>
						</div>
					)
					: null}
			</Container>

			{showCallToAction
				? <ContactCustomerService {...{ subsystemAccountReference: transit_account_id }} />
				: null}
		</>
	);
};

CardLayout.propTypes = {
	showCallToAction: PropTypes.bool,
	showSideNav: PropTypes.bool,
	showRightSideNav: PropTypes.bool,
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
	]),
	showMissedTaps: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default CardLayout;
