import React, { useCallback, useEffect, useState } from 'react';
import { useTimeoutFn } from "react-use";

export const useTookAWhile = (
	timeout,
) => {
	const [ waiting, setWaiting ] = useState(false);
	const [ tookAWhile, setTookAWhile ] = useState(false);

	const [ , cancel, reset ] = useTimeoutFn(() => {
		if (!waiting) {
			return;
		}
		setTookAWhile(true);
		setWaiting(false);
	}, timeout);

	const startWaiting = useCallback(() => {
		reset();
		setTookAWhile(false);
		setWaiting(true);
	}, [ reset ]);

	const stopWaiting = useCallback(() => {
		cancel();
		setTookAWhile(false);
		setWaiting(false);
	}, [ cancel ]);

	return {
		tookAWhile,
		startWaiting,
		stopWaiting,
	};
};

const RenderAfterAWhile = ({
	children,
	timeout = 1000,
}) => {
	const { tookAWhile, startWaiting } = useTookAWhile(timeout);

	useEffect(() => {
		startWaiting();
	}, [ startWaiting ]);

	return tookAWhile
		? children
		: null;
};

export default RenderAfterAWhile;
