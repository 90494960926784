import {
	get,
	clone,
	isEmpty,
} from "lodash";
import WSLastUsageDetails, { WSLastUsageDetailsType } from "./WSLastUsageDetails.js";
import WSSubsystemAccountTravelTokenDisplayFactory, { WSSubsystemAccountTravelTokenDisplayFactoryTypes } from "./WSSubsystemAccountTravelTokenDisplayFactory.js";
import {
	closed,
	expired,
	suspended,
} from "utils/Constants.js";

export const OPEN_TRANSIT_BANK_CARD = 'OpenTransitBankcard';
export const OPEN_TRANSIT_REGULAR_CARD = 'OpenTransitRegular';
export const OPEN_TRANSIT_VIRTUAL_CARD = 'OpenTransitVirtual';
export const OPEN_TRANSIT_TEMPORARY_CARD = 'OpenTransitTemporary';

export const getFrontendStatus = (tokenInfo, status) => {
	const now = new Date();

	const expirDate = tokenInfo.cardExpiryDate
		? new Date(tokenInfo.cardExpiryDate)
		: new Date(
			tokenInfo.expirationYear,
			tokenInfo.expirationMonth,
		);

	// Per Alessandro: (4/9) If the token frontendStatus is 'suspended' and the token is expired
	// render "Expired". Note "expired" is a FE mapped value not an actual token BO status.
	// https://reflexions.slack.com/archives/CEEH1V14K/p1586466283018200?thread_ts=1586454617.013300&cid=CEEH1V14K

	const frontendStatus = status === closed && now.getTime() >= expirDate.getTime()
		? expired
		: status;

	return frontendStatus;
};

export default class WSSubsystemAccountToken {
	constructor({
		subsystemTokenType,
		subsystemTokenTypeDescription,
		tokenInfo,
		status,
		statusDescription,
		statusChangeDateTime,
		isArchived,
		tokenId,
		mediaType,
		assignedPassCount = null,

		// not documented
		tokenActivatedDateTime = null,
		canBeUnblockedByCSR,
		canBeUnblockedBySelfService,
		tokenLastUsageDetails,
	}) {
		this.subsystemTokenType = subsystemTokenType;
		this.subsystemTokenTypeDescription = subsystemTokenTypeDescription;

		this.tokenInfo = tokenInfo;

		this.status = status;
		this.statusDescription = statusDescription;
		this.statusChangeDateTime = statusChangeDateTime;
		this.isArchived = isArchived;
		this.tokenId = tokenId;
		this.mediaType = mediaType;
		this.assignedPassCount = assignedPassCount;

		// tokenActivatedDateTime:
		// dateTime
		// (Optional) the time when this token was activated.
		// Empty or null indicates token isn’t activated.
		this.tokenActivatedDateTime = tokenActivatedDateTime;

		// missing from api doc:
		this.canBeUnblockedByCSR = canBeUnblockedByCSR;
		this.canBeUnblockedBySelfService = canBeUnblockedBySelfService;
		this.tokenLastUsageDetails = tokenLastUsageDetails;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemAccountToken(data);
		result.tokenInfo = WSSubsystemAccountTravelTokenDisplayFactory.fromBackoffice(data.tokenInfo);

		// they send tokenLastUsageDetails = {}, but WSLastUsageDetails has required transactionDateTime
		result.tokenLastUsageDetails = isEmpty(data.tokenLastUsageDetails)
			? null
			: WSLastUsageDetails.fromBackoffice(data.tokenLastUsageDetails);
		return result;
	}

	static fromSerialized(data) {
		// loading from the backoffice and from the session are the same
		const result = WSSubsystemAccountToken.fromBackoffice(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = this.tokenId;
		forGraphql.tokenInfo = this.tokenInfo
			? this.tokenInfo.toResolver()
			: null;
		forGraphql.tokenLastUsageDetails = this.tokenLastUsageDetails
			? this.tokenLastUsageDetails.toResolver()
			: null;
		forGraphql.frontendStatus = getFrontendStatus(this.tokenInfo, this.status);

		return forGraphql;
	}
}

export const WSSubsystemAccountTokenType = [
	...WSLastUsageDetailsType,
	...WSSubsystemAccountTravelTokenDisplayFactoryTypes,
	// api docs say tokenLastUsageDetails is required, but they send {} when token hasn't been used
	`
		type WSSubsystemAccountToken {
			id: ID!
			subsystemTokenType: String
			subsystemTokenTypeDescription: String!
			tokenInfo: WSSubsystemAccountTravelTokenDisplayFactory!
			status: String!
			statusDescription: String!
			statusChangeDateTime: String!
			isArchived: Boolean!
			tokenId: String!
			mediaType: String!
			assignedPassCount: Int

			# not documented
			tokenActivatedDateTime: GraphQLDateTime
			canBeUnblockedByCSR: String!
			canBeUnblockedBySelfService: String!
			tokenLastUsageDetails: WSLastUsageDetails

			# generated by us
			frontendStatus: String!
		}
	`,
];
