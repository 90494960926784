import React, {
	useState,
	useContext,
} from "react";

import {
	levels,
	log,
} from "utils/Logger.js";
import { useBOLocations } from "components/data/transit-account/Location.query";
import useStdQuery from "components/data/hooks/useStdQuery";
import LoadingIcon from 'components/icons/LoadingIcon.js';
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";

export const SelectedLocationContext = React.createContext(() => log(null, levels.error, "SelectedLocationContext was used before it was ready"));

export const SelectedLocationContextProvider = ({
	children,
	variables,
}) => {
	const {
		subsystemAccountReference: providedSubsystemAccountReference,
		operator,
		route,
		zone,
	} = variables;
	const [ location, setLocation ] = useState(null);

	const transitAccountId = useTransitAccountIdContext();
	const subsystemAccountReference = providedSubsystemAccountReference || transitAccountId;

	const { data, loading: locationsLoading } = useBOLocations({
		subsystemAccountReference,
		operator,
		route,
		zone,
	});

	const locations = locationsLoading
		? []
		: data;

	const applyLocation = (location) => {
		setLocation(location);
	};

	const contextValue = {
		location,
		applyLocation,
		locations,
		locationsLoading,
	};

	return (
		<SelectedLocationContext.Provider value={contextValue}>
			{children}
		</SelectedLocationContext.Provider>
	);
};

export const useSelectedLocationContext = () => useContext(SelectedLocationContext);

export default SelectedLocationContext;
