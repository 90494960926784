import { useFundingSourcesContext } from "context/FundingSourcesContext.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { getCartTotal, useCartContext } from "context/CartProvider.js";
import { useApolloClient } from "@apollo/client";
import { getPaymentInputName } from "components/account/GetPaymentInputName.js";
import { postNewAddress } from "components/account/address/PostNewAddress.js";
import SavePaymentMethod from "pages/account/purchase/submitted-payment-method/SavePaymentMethod.js";
import WSAddress from "server/api-types/WSAddress.js";
import { sliceMaskedPAN, titleCase } from "utils/FormatHelpers.js";
import WSFundingSource from "server/api-types/WSFundingSource.js";
import CreditCardClear from "server/api-types/CreditCardClear.js";
import Payment from "payment";
import { paymentFieldsFromForm } from "pages/account/purchase/AddPaymentMethod.js";
import { useAutoloadContext } from "context/AutoloadContext.js";
import { isEmpty } from "lodash";

const getOneTimeFundingSource = async ({
	allFormData,
	apolloClient,
	isMultiPayment,
	isFirst,
	updateSelectedFundingSource,
}) => {
	const getInputName = (fieldName) => getPaymentInputName({ base: fieldName, isSplit: isMultiPayment, isFirst });

	// make consistent fields names which will be used in selectFundingSource function
	const {
		cardNumber,
		nameOnCard,
		expirationDate,
		cardCvv,
		...paymentFields
	} = paymentFieldsFromForm(allFormData, getInputName);

	const billingAddress = new WSAddress(paymentFields);

	const creditCard = new CreditCardClear({
		cardNumber,
		nameOnCard,
		cardExpiryMMYY: expirationDate.replace('/', ''),
		creditCardType: titleCase(Payment.fns.cardType(cardNumber)),
		maskedPan: sliceMaskedPAN(cardNumber),
	});

	const wsFundingSource = new WSFundingSource({
		creditCard,
		billingAddress,
	});

	const saveAddressInputName = getPaymentInputName({ base: 'saveAddress', isSplit: isMultiPayment, isFirst });
	const {
		[ saveAddressInputName ]: saveAddress,
	} = allFormData;

	if ([ 'on', 'true', true ].includes(saveAddress)) {
		const { addressId } = await postNewAddress({ wsAddress: billingAddress, apolloClient });
		wsFundingSource.billingAddressId = addressId;
	}

	const selectedFundingSource = {
		fundingSource: wsFundingSource,
		cvv: cardCvv,
	};

	updateSelectedFundingSource({
		splitPaymentIndex: isFirst ? 0 : 1,
		...selectedFundingSource,
	});

	return selectedFundingSource;
};

const getFundingSource = async ({
	formHelper,
	apolloClient,
	cart,
	subsystemAccountReference,
	updateSelectedFundingSource,
	selectedFundingSource,
	initializeFundingSources,
	isMultiPayment,
	isFirst,
	refetchFundingSources,
}) => {
	const savePaymentName = getPaymentInputName({ base: 'savePayment', isSplit: isMultiPayment, isFirst });
	const savePayment = formHelper.getFieldValue(savePaymentName);
	const isSaving = [ 'on', 'true', true ].includes(savePayment);

	const allFormData = await formHelper.startValidation(true);

	if (selectedFundingSource.isNew) {
		if (!isSaving) {
			return await getOneTimeFundingSource({
				allFormData,
				apolloClient,
				isMultiPayment,
				isFirst,
				updateSelectedFundingSource,
			});
		}

		return await SavePaymentMethod({
			allFormData,
			isSplit: isMultiPayment,
			isFirst,
			apolloClient,
			cart,
			subsystemAccountReference,
			updateSelectedFundingSource,
			initializeFundingSources,
			altGetPaymentMethods: refetchFundingSources,
		});
	}

	return selectedFundingSource;
};

export const useFinalizeAndSetFundingSourcesToContext = ({
	formHelper,
}) => {
	const {
		selectedFundingSources,
		updateSelectedFundingSource,
		initializeFundingSources,
		isMultiPayment,
		refetchFundingSources,
	} = useFundingSourcesContext();

	const subsystemAccountReference = useTransitAccountIdContext();

	const {
		cart,
	} = useCartContext();

	const autoloadContext = useAutoloadContext();

	const apolloClient = useApolloClient();
	const totalFundingSources = isMultiPayment ? 2 : 1;

	return async () => {
		const newSelectedFundingSources = [];

		if (getCartTotal(cart) === 0 && isEmpty(autoloadContext)) {
			return newSelectedFundingSources;
		}

		for (let splitPaymentIndex = 0; splitPaymentIndex < totalFundingSources; splitPaymentIndex++) {
			const isFirst = splitPaymentIndex === 0;

			const selectedFundingSource = selectedFundingSources[ splitPaymentIndex ];

			newSelectedFundingSources.push(
				await getFundingSource({
					formHelper,
					apolloClient,
					cart,
					subsystemAccountReference,
					selectedFundingSource,
					updateSelectedFundingSource,
					initializeFundingSources,
					isMultiPayment,
					isFirst,
					refetchFundingSources,
				}),
			);
		}

		return newSelectedFundingSources;
	};
};
