import { head } from "lodash";
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";

const findCardType = ( transitAccountQ ) => {
	const firstToken = head(transitAccountQ.tokens) || {};
	const tokenType = firstToken.tokenInfo?.tokenType;
	return tokenType;
};

export const isCharlieCard = (transitAccountQ) => findCardType(transitAccountQ) === TRAVEL_TOKEN_TYPES.smartcard;

export default findCardType;