import React, {
	useEffect,
	useState,
} from 'react';
import cx from "classnames";
import {
	levels,
	noticeError,
} from '../utils/Logger.js';
import * as style from "./LocalDate.module.css";
import PropTypes from "prop-types";
import {
	DEFAULT_DATE_LOCALE,
} from "./data/Language.js";
import parseISO from 'date-fns/parseISO';
import PublicAppVars from "../utils/PublicAppVars.js";

export const dateFormatter = (locale, options) => new Intl.DateTimeFormat(locale, { timeZone: PublicAppVars.TZ, ...options });

export const maybePolyfillTimezoneDateFormatter = async (locale, options) => {
	try {
		return dateFormatter(locale, options);
	}
	catch (error) {
		if (error?.message?.match(/for 'timeZone' is outside of valid range/)) {
			// ie11 hits this. Polyfill tz db on demand
			// https://stackoverflow.com/a/54365285/329062
			await import('date-time-format-timezone');
			return dateFormatter(locale, options);
		}
		else {
			noticeError(null, levels.error, error, "Intl.DateTimeFormat error");
			return null;
		}
	}
};

/**
 * Load the TZ db without increasing our bundle size for browsers that have it built-in
 * @returns {Promise<void>}
 */
export const polyfillTimezoneDb = async () => {
	await maybePolyfillTimezoneDateFormatter('en', {
		// ie11 only supports 'UTC'. Any other TZ, like NY, will trigger the polyfill
		timeZone: 'America/New_York',
	});
};

export const getDatePartsSync = ({
	locale,
	options,
	dateISO,
}) => {
	try {
		const formatter = dateFormatter(locale, options);
		const date = parseISO(dateISO);
		return formatter.formatToParts(date);
	}
	catch (error) {
		noticeError(null, levels.error, error, `getDatePartsSync error for '${dateISO}'`);
		return null;
	}
};

export const getDateStringSync = ({
	locale,
	altFormat,
	options,
	dateISO,
}) => {
	try {
		const formatter = dateFormatter(locale, options);
		const date = parseISO(dateISO);
		const internationalizedDateStr = formatter.format(date);
		return altFormat ? altFormat(internationalizedDateStr) : internationalizedDateStr;
	}
	catch (error) {
		noticeError(null, levels.error, error, `getDateStringSync error for '${dateISO}'`);
		return null;
	}
};

export const getDateStringAsync = async ({
	locale,
	altFormat,
	options,
	dateISO,
}) => {
	try {
		const formatter = await maybePolyfillTimezoneDateFormatter(locale, options);
		const date = parseISO(dateISO);
		const internationalizedDateStr = formatter.format(date);
		return altFormat ? altFormat(internationalizedDateStr) : internationalizedDateStr;
	}
	catch (error) {
		noticeError(null, levels.error, error, `getDateStringAsync error for '${dateISO}'`);
		return null;
	}
};

/**
 * Renders an iso date in the provided TZ
 * @param classNames
 * @param altFormat
 * @param options
 * @param dateISO
 * @param locale
 * @returns {*}
 * @constructor
 */
const ServerDate = ({
	classNames = null,
	altFormat = null,
	options,
	dateISO,
	locale = DEFAULT_DATE_LOCALE,
}) => {
	// don't have to polyfill on the server, so we can generate the date synchronously and have it in the initial html
	const defaultDate = typeof window === 'undefined'
		? getDateStringSync({
			locale,
			altFormat,
			options,
			dateISO,
		})
		: '';

	const [ dateString, setDateString ] = useState(defaultDate);

	useEffect(
		() => {
			getDateStringAsync({
				locale,
				altFormat,
				options,
				dateISO,
			})
				.then((dateString) => {
					setDateString(dateString);
				})
				.catch((error) => noticeError(null, levels.error, error, `Unable to format date for '${dateISO}'`));
		},
		[
			altFormat,
			options,
			dateISO,
			locale,
		],
	);

	try {
		return (
			<span
				className={cx(
					classNames,
					'iso-date',
					style.locale,
				)}
				data-dateiso={dateISO}
			>
				{dateString}
			</span>
		);
	}
	catch (error) {
		noticeError(null, levels.error, error);
		return (<span />);
	}
};
ServerDate.propTypes = {
	classNames: PropTypes.string,
	dateISO: PropTypes.string.isRequired,
	options: PropTypes.object.isRequired,
	altFormat: PropTypes.func,
	locale: PropTypes.string,
};
export default ServerDate;
