import { gql } from "@apollo/client";
import { get } from "lodash";
import {
	levels,
	noticeError,
} from "utils/Logger.js";

export const REQUEST_REFUND_METHODS = gql`
	mutation RefundMethods (
		$saleOrderId: Int!
	) {
		OrderRoute {
			id
			postRefundmethods (
				saleOrderId: $saleOrderId
			) {
				saleOrderId
				saleOrderRefundMethods {
					id
					refundPaymentType
					type
					refundAmount
					priority

					...on WSOriginalPaymentRefundMethod {
						authRefNbr
						payment {
							authRefNbr
							paymentType
							paymentSubtype
							paymentDateTime
							paymentAmount
							paymentReferenceNumber
							paymentAllocatedAmount
							paymentStatus
							refundableAmount
						}
					}

					...on WSSubsystemAccountValueRefundMethod {
						subsystem
						subsystemAccountReference
						purseRestriction
						purseType
					}
				}
			}
		}
	}`;
	
	
export const getRefundMethods = (response) => {
	return get(response, 'data.OrderRoute.postRefundmethods', []);
};