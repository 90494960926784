import React from 'react';
import { capitalize, values } from "lodash";
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
	levels,
	noticeError,
} from "utils/Logger.js";
import Input from '../forms/Input.js';
import Button from '../Button.js';
import StandardMutation from '../data/StandardMutation.js';
import Modal from '../Modal.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import { WireFormHelper } from 'utils/FormHelper';
import CmsContentList from '../data/CmsContentList.js';
import PreventDefault from 'utils/PreventDefault';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics';
import { BO_ERRORS, COLLECTIONS, getErrorKey, getMiscTextErrorKey } from 'utils/GetErrorKey.js';
import useFormHelper from 'utils/form-helper/useFormHelper';
import { GET_CUSTOMER_SECURITY_QUESTIONS, UPDATE_SECURITY_QUESTIONS } from 'components/data/customer/SecurityQuestions';
import Select from 'components/forms/inputs/Select';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware';
import WSSecurityQA from 'server/api-types/WSSecurityQA';
import { useGlobalToastsContext } from 'context/ToastProvider.js';

import * as modalStyles from '../Modal.module.css';
import * as panelStyle from '../Panel.module.css';
import * as securityInformationStyles from '../account/panels/SecurityInformation.module.css';
import Toast from 'components/Toast.js';
import PublicAppVars from 'utils/PublicAppVars.js';


const cms = {
	securityQsEditSubheader: 'miscText.security-questions-edit-subheader',
	securityQsSetupSubheader: 'miscText.security-questions-setup-subheader',
	securityQsDescription: 'miscText.security-questions-description',
	securityQsSelectPlaceholder: 'miscText.security-questions-select-placeholder',
	questionOneLabel: 'miscText["security-questions-q1.label"]',
	questionTwoLabel: 'miscText["security-questions-q2.label"]',
	answerLabel: 'miscText["security-questions-answer.label"]',
	answerHelpText: 'miscText.security-questions-error-length',
	modalSave: 'miscText.general-modal-save',
	modalCancel: 'miscText.general-modal-cancel',
	toastConfirmEditSuccess: 'miscText.security-questions-edit-confirmation',
	toastConfirmSetupSuccess: 'miscText.security-questions-setup-confirmation',
};

export const SECURITY_QUESTIONS_FORM_MODE = {
	setup: 0,
	edit: 1,
};

export const wsSecurityQuestionBuilder = (securityQuestion, securityAnswer) => {
	if (!securityQuestion || !securityAnswer) {
		return [];
	}
	return [ new WSSecurityQA({ securityQuestion, securityAnswer }) ];
};

export const securityQuestionAnswerValidations = () => {
	return yup_string()
		.required(getMiscTextErrorKey(BO_ERRORS.general.required))
		.trim()
		.min(PublicAppVars.MIN_SECURITY_QUESTION_ANSWER_LENGTH,
			() => <CmsContentRenderedInline
				contentKey={cms.answerHelpText}
				fallbackValue={`Answer must be at least ${PublicAppVars.MIN_SECURITY_QUESTION_ANSWER_LENGTH} characters in length`}
				variables={{ min: PublicAppVars.MIN_SECURITY_QUESTION_ANSWER_LENGTH }}
			/>)
		.max(PublicAppVars.MAX_SECURITY_QUESTION_ANSWER_LENGTH, getErrorKey(
			COLLECTIONS.login,
			// from 2.9.11 customer/<customer-id>/contact/<contact-id> PATCH
			'contact.securityQAs[n].securityAnswer',
			'errors.general.value.toolong',
		));
};

const getYupSchema = (formHelper) => {
	const validations = {};

	const formFieldNames = formHelper.getAllFormFieldNames();

	if (formFieldNames.includes('questionOne')) {
		validations.questionOne = yup_string()
			.required(getMiscTextErrorKey(`${BO_ERRORS.general.required}_securityQuestion`));
		validations.answerOne = securityQuestionAnswerValidations();
	}

	if (formFieldNames.includes('questionTwo')) {
		validations.questionTwo = yup_string()
			.required(getMiscTextErrorKey(`${BO_ERRORS.general.required}_securityQuestion`));
		validations.answerTwo = securityQuestionAnswerValidations();
	}

	return yup_object().shape(validations);
};

const EditSecurityQuestion = ({ customerSecurityQuestions, availableSecurityQuestions, onModalClose, formMode }) => {
	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({ getYupSchema });

	const { setToast, removeToast } = useGlobalToastsContext();

	const kickoffSubmit = async (callback) => {
		formHelper.clearAllErrors();
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(null, levels.verbose, errorReport, `Edit security questions form validation`);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent("User is attempting to edit their security questions");

		const securityQuestions = [
			...(wsSecurityQuestionBuilder(validated.questionOne, validated.answerOne)),
			...(wsSecurityQuestionBuilder(validated.questionTwo, validated.answerTwo)),
		];

		try {
			await graphqlErrorMiddleware(callback({ variables: { securityQuestions } }));
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		setToast(<Toast
			type="success"
			title={<CmsContentRendered.Span
				contentKey={cms[ formMode === SECURITY_QUESTIONS_FORM_MODE.edit ? 'toastConfirmEditSuccess' : 'toastConfirmSetupSuccess' ]}
				fallbackValue={formMode === SECURITY_QUESTIONS_FORM_MODE.edit ? 'Your security questions have been successfully updated.' : 'Your security questions have been successfully set up.'}
			/>}
			onClosed={removeToast}
		/>);

		onModalClose();
	};

	const { securityQuestion: questionOne, securityAnswer: answerOne } = customerSecurityQuestions?.[ 0 ] ?? {};
	const { securityQuestion: questionTwo, securityAnswer: answerTwo } = customerSecurityQuestions?.[ 1 ] ?? {};

	const titleAttrByMode = {
		[ SECURITY_QUESTIONS_FORM_MODE.edit ]: {
			key: cms.securityQsEditSubheader,
			fallbackValue: 'Edit Security Question',
			"data-qa": 'EditSecurityQuestionModal',
		},
		[ SECURITY_QUESTIONS_FORM_MODE.setup ]: {
			key: cms.securityQsSetupSubheader,
			fallbackValue: 'Setup Security Question',
			"data-qa": 'SetupSecurityQuestionModal',
		},
	};

	const securityQuestionOptions = availableSecurityQuestions.map(({ name }) => ({ value: name, label: name }));

	return (
		<CmsContentList list={values(cms)}>{() => (
			<Modal
				title={<CmsContentRenderer.Span
					contentKey={titleAttrByMode[ formMode ].key}
					fallbackValue={titleAttrByMode[ formMode ].fallbackValue}
				/>}
				data-qa={titleAttrByMode[ formMode ][ 'data-qa' ]}
				onModalClose={onModalClose}
			>
				<StandardMutation
					mutation={UPDATE_SECURITY_QUESTIONS}
					refetchQueries={[ { query: GET_CUSTOMER_SECURITY_QUESTIONS } ]}
					showLoadingState={false}
					errorChildren={null}
				>{updateSecurityQuestions => (
						<form
							data-qa="UpdateSecurityQuestionModalForm"
							ref={formRef}
							onSubmit={PreventDefault(() => kickoffSubmit(updateSecurityQuestions))}
						>
							<CmsContentRenderer.P
								className={panelStyle.label}
								data-qa="PanelSecQsDescLabel"
								contentKey={cms.securityQsDescription}
								fallbackValue="If you need to update your password or unlock your account, you'll need to answer these questions first. Be sure to set responses that you'll remember."
							/>
							<WireFormHelper {...{ formHelper }}>
								{formHelper.getFieldErrorJsx('')}
								<SecurityQuestionAnswerInputs
									{...{ securityQuestionOptions, formHelper }}
									question={questionOne}
									questionFieldName="questionOne"
									questionLabel={<CmsContentRenderer.Span
										contentKey={cms.questionOneLabel}
										fallbackValue="Question 1"
									/>}
									answer={answerOne}
									answerFieldName="answerOne"
								/>
								<SecurityQuestionAnswerInputs
									{...{ securityQuestionOptions, formHelper }}
									question={questionTwo}
									questionFieldName="questionTwo"
									questionLabel={<CmsContentRenderer.Span
										contentKey={cms.questionTwoLabel}
										fallbackValue="Question 2"
									/>}
									answer={answerTwo}
									answerFieldName="answerTwo"
								/>
								<div className={cx(modalStyles.actions, securityInformationStyles.modalActionRow)}>
									<Button
										type="button"
										onClick={onModalClose}
										overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
										data-qa="UpdateSecurityQuestionModalCancelButton"
									>
										<CmsContentRenderer.Span
											contentKey={cms.modalCancel}
											fallbackValue="Cancel"
										/>
									</Button>
									<Button
										type="submit"
										overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
										data-qa="UpdateSecurityQuestionModalSubmitBtn"
										submitting={submitting}
									>
										<CmsContentRenderer.Span
											contentKey={cms.modalSave}
											fallbackValue="Save"
										/>
									</Button>
								</div>
							</WireFormHelper>
						</form>
					)}</StandardMutation>
			</Modal>
		)}</CmsContentList>
	);
};

const SecurityQuestionAnswerInputs = ({
	question,
	questionFieldName,
	questionLabel,
	answer,
	answerFieldName,
	securityQuestionOptions,
	formHelper,
}) => (
	<>
		<Select
			defaultValue={question}
			name={questionFieldName}
			label={questionLabel}
			options={securityQuestionOptions}
			error={formHelper.getFieldError(questionFieldName)}
		/>
		<Input
			overrideClass={securityInformationStyles.answerTopMargin}
			defaultValue={answer ?? ""}
			name={answerFieldName}
			type="text"
			autoComplete="off"
			label={<span className={securityInformationStyles.answerLabel}>
				<CmsContentRenderer.Span
					contentKey={cms.answerLabel}
					fallbackValue="Your Answer"
				/>
				<CmsContentRendered.Span
					contentKey={cms.answerHelpText}
					variables={{ min: PublicAppVars.MIN_SECURITY_QUESTION_ANSWER_LENGTH }}
					fallbackValue={`Answer must be at least ${PublicAppVars.MIN_SECURITY_QUESTION_ANSWER_LENGTH} characters in length`}
				/>
			</span>}
			data-qa={`UpdateSecurityQuestionModal${capitalize(answerFieldName)}`}
			error={formHelper.getFieldError(answerFieldName)}
		/>
	</>
);

EditSecurityQuestion.propTypes = {
	onModalClose: PropTypes.func.isRequired,
	availableSecurityQuestions: PropTypes.array.isRequired,
	customerSecurityQuestions: PropTypes.array.isRequired,
};

export default EditSecurityQuestion;
