import React from 'react';
import TakeOverLayout from 'layouts/TakeOverLayout.js';

import TabAddPaymentCard from 'components/account/TabAddPaymentCard.js';
import TabAddPaymentBank from 'components/account/TabAddPaymentBank.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { values } from 'lodash';

const cms = {
	header: "miscText.payments-header",
	ccTabLabel: "miscText.payments-add-cc-tab",
	achTabLabel: "miscText.payments-add-ach-tab",
};

const tabContent = [
	{
		label: <CmsContentRenderer.Span contentKey={cms.ccTabLabel} fallbackValue={'Credit / Debit Card'} />,
		content: <TabAddPaymentCard />,
	},
	/**
	 * Deferred til Pilot 2
	 * {
		label: <CmsContentRenderer.Span contentKey={cms.achTabLabel} fallbackValue={'Bank Account (ACH)'} />,
		content: <TabAddPaymentBank />,
	},*/
];

const AddPaymentMethod = () => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout
				title={cmsContent[ cms.header ] || "Add New Payment Method"}
				showCancel
				showTabs
				tabs={tabContent}
			/>
		)}</CmsContentList>
	);
};

export default AddPaymentMethod;
