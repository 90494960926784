import React from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal.js";
import LocationCard from "components/map/LocationCard.js";
import * as style from "pages/RetailLocations.module.css";


const MobileRetailLocationCard = ({
	wsPointOfSaleLocation,
	modalActive = true,
	onModalClose,
}) => {
	return (
		<Modal
			title={wsPointOfSaleLocation.name}
			{...{ modalActive, onModalClose }}
			overrideOverlayClass={style.modalViewOverride}
		>
			<LocationCard {...{ wsPointOfSaleLocation }}
				overrideClass={style.noBg}
			/>
		</Modal>
	);
};

MobileRetailLocationCard.propTypes = {
	wsPointOfSaleLocation: PropTypes.object.isRequired,
	modalActive: PropTypes.bool,
	onModalClose: PropTypes.func.isRequired,
};


export default MobileRetailLocationCard;
