import React, { useRef } from "react";
import PropTypes from "prop-types";
import Cleave from 'cleave.js/react';

import Control from './Control.js';
import { inputClass } from './CreditCard.js';

const MaskedInput = ({
	labelFirst = true,
	label = '',
	id,
	name,
	hideLabel = false,
	overrideClass = '',
	type = 'text',
	placeholder = '',
	options = {},
	errorDangerous = false,
	error = '',
	initialvalue = '',
	mask = [],
	tabIndex = 0,
	...rest
}) => {
	const inputRef = useRef();

	return (
		<Control
			labelFirst={labelFirst}
			label={label}
			target={id || name}
			overrideClass={overrideClass}
			hideLabel={hideLabel}
			error={error}
		>
			<Cleave type={type}
				className={inputClass(error)}
				id={id || name}
				name={name}
				ref={inputRef}
				placeholder={placeholder}
				options={options}
				value={initialvalue}
				{...rest}
			/>
		</Control>
	);
};

MaskedInput.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string.isRequired,
	initialvalue: PropTypes.string,
	label: PropTypes.node,
	hideLabel: PropTypes.bool,
	labelFirst: PropTypes.bool,
	mask: PropTypes.array,
	error: PropTypes.oneOfType([
		PropTypes.string,
		// can be jsx
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.node),
	]),
	errorDangerous: PropTypes.bool,
	tabIndex: PropTypes.number,
	placeholder: PropTypes.string,
	overrideClass: PropTypes.string,
	options: PropTypes.object,
};

export default MaskedInput;
