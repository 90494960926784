// extracted by mini-css-extract-plugin
export var btnWrapper = "pages-auth-__VerificationCodePanel-module___btnWrapper";
export var container = "pages-auth-__VerificationCodePanel-module___container";
export var form = "pages-auth-__VerificationCodePanel-module___form";
export var fullWidth = "pages-auth-__VerificationCodePanel-module___fullWidth";
export var inputWrapper = "pages-auth-__VerificationCodePanel-module___inputWrapper";
export var passwordContainer = "pages-auth-__VerificationCodePanel-module___passwordContainer";
export var passwordValidation = "pages-auth-__VerificationCodePanel-module___passwordValidation";
export var passwordWrapper = "pages-auth-__VerificationCodePanel-module___passwordWrapper";
export var pwInput = "pages-auth-__VerificationCodePanel-module___pwInput";
export var pwInputContainer = "pages-auth-__VerificationCodePanel-module___pwInputContainer";
export var resendCodeText = "pages-auth-__VerificationCodePanel-module___resendCodeText";
export var submitBtn = "pages-auth-__VerificationCodePanel-module___submitBtn";
export var title = "pages-auth-__VerificationCodePanel-module___title";
export var twoFactorContentWrapper = "pages-auth-__VerificationCodePanel-module___twoFactorContentWrapper";
export var twoFactorSidebar = "pages-auth-__VerificationCodePanel-module___twoFactorSidebar";
export var twoFactorTooltip = "pages-auth-__VerificationCodePanel-module___twoFactorTooltip";