import { clone } from "lodash";

// 2.7.70 WSTravelTokenDisplay
// Abstract class to reference a specific travel token object as a user-friendly displayable
// search result. Each specific token defines the fields to uniquely identify the specific travel token.
export default class WSTravelTokenDisplay {
	constructor({
		tokenType,
		tokenNickname = null,
		tokenId,
	}) {
		// string(20)
		// (Required) Specific identifier of the travel token
		// type. See section 2.6.18 Travel Token Types for
		// allowed values.
		this.tokenType = tokenType;

		// string(40)
		// (Optional)  Nick name of the travel token.
		this.tokenNickname = tokenNickname ? tokenNickname : null;

		// string(20)
		// (Required) Unique token identifier which uniquely
		// identifies a token.
		this.tokenId = tokenId;
	}

	static fromBackoffice(data) {
		const result = new WSTravelTokenDisplay(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSTravelTokenDisplayFragment = `
	tokenType: String!
	tokenNickname: String
	tokenId: ID!
`;

export const WSTravelTokenDisplayType = [ `
	type WSTravelTokenDisplay implements WSTravelTokenDisplayFactory {
		${WSTravelTokenDisplayFragment}
	}
` ];
