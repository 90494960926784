import React from "react";
import cx from "classnames";
import { values } from "lodash";

import { StoredValue } from "components/Icon.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import CmsContentList from "components/data/CmsContentList.js";


import * as tables from "components/DataTable.module.css";

const cms = {

};

const StoredCreditChip = ({
	overrideClass = null,
	contentKey = null,
}) => {
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
			<StoredValue
				aria-hidden="true"
				data-qa="stored-value-icon"
				className={tables.splitPayIcon}
			/>
			{/*
			uncomment if it needs for Boston
			<CmsContentRenderer.P
				className={cx(tables.maskedPan, overrideClass)}
				contentKey={contentKey ? contentKey : cms.storedValue}
				fallbackValue="Credit"
			/>
		*/}
		</>)}</CmsContentList>
	);
};
export default StoredCreditChip;
