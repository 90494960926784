import React from "react";
import PropTypes from "prop-types";

export default class Untranslatable extends React.PureComponent {
	static propTypes = {
		// Works best as a block-level setting on a div. Trying to set individual words in a sentence as
		// untranslatable will confuse the translator when sentence order changes as part of translation
		elementType: PropTypes.elementType,
	};

	static defaultProps = {
		elementType: "div",
	};

	render() {
		const {
			elementType: ElementType,
			children,
		} = this.props;

		// https://stackoverflow.com/questions/9628507/how-can-i-tell-google-translate-to-not-translate-a-section-of-a-website
		// notranslate is for google. translate="no" is the standard
		return <ElementType className="notranslate" translate="no">{children}</ElementType>;
	}
}
