import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as dropdown from './Dropdown.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MenuProvider, Popper, useMenu, useMenuItem } from "@mui/base";
import HidePypestreamChatbot from "components/pypestream-chatbot/HidePypestreamChatbot.js";

export const TYPE_DANGER = 'danger';

export const OPTION_EDIT = 'edit';
export const OPTION_REMOVE = 'remove';

const DropdownContext = createContext(null);

export const DropdownItem = ({ itemKey, to, overrideClass, additionalClasses, onClick: propsOnClick, children }) => {
	const ref = useRef();
	const { close, id } = useContext(DropdownContext);
	const { getRootProps, disabled, focusVisible, highlighted } = useMenuItem({ ref, id: `${id}-${itemKey}` });

	const history = useHistory();

	const onClick = (event) => {
		event.preventDefault();

		if (propsOnClick) {
			propsOnClick();
		}

		if (to) {
			history.push({
				pathname: to,
			});
		}

		close();
	};

	const onMouseOver = ({ target }) => {
		target.focus();
	};

	return <li
		className={cx(overrideClass || dropdown.option, additionalClasses, disabled ? dropdown.isDisabled : '')}
		onMouseOver={onMouseOver}
		{...getRootProps({ onClick: onClick ?? (() => {}) })}
	>
		{children}
	</li>;
};

DropdownItem.propTypes = {
	itemKey: PropTypes.string.isRequired,
};

export const DropdownSection = ({
	sectionKey,
	children,
	label,
	overrideClass,
	additionalClasses = '',
	separatorOverrideClass,
	separatorAdditionalClasses = '',
	headingOverrideClass,
	headingAdditionalClasses = '',
	isFirst = false,
}) => {
	const { id } = useContext(DropdownContext);

	const labelId = `${id}${sectionKey}Label`;

	return <>
		{!isFirst &&
			<li className={cx(separatorOverrideClass || dropdown.separator, separatorAdditionalClasses)} />
		}
		<li>
			{label && <span className={cx(headingOverrideClass || dropdown.heading, headingAdditionalClasses)} id={labelId}>{label}</span>}
			<ul className={cx(overrideClass || dropdown.section, additionalClasses)} aria-labelledby={labelId}>{children}</ul>
		</li>
	</>;
};

const DropdownOptions = ({ id, children, overrideOptionsClass, additionalOptionsClasses }) => {
	const { isOpen, setIsOpen } = useContext(DropdownContext);
	const { listboxRef } = useRef();

	const { contextValue, getListboxProps } = useMenu({
		listboxRef,
		onOpenChange: setIsOpen,
		open: isOpen,
	});

	return (
		<ul
			id={id}
			className={cx(overrideOptionsClass || dropdown.options, additionalOptionsClasses)}
			{...getListboxProps()}
		>
			<MenuProvider value={contextValue}>{children}</MenuProvider>
		</ul>
	);
};

const Dropdown = ({
	id,
	label,
	ariaLabel,
	overrideClass,
	additionalClasses = '',
	overrideButtonClass: overrideButtonClassProp,
	additionalButtonClasses = '',
	overrideOptionsClass,
	additionalOptionsClasses = '',
	children,
	placement = 'bottom-start',
}) => {
	const buttonElement = useRef(null);
	const [ isOpen, setIsOpen ] = useState(false);
	const preventReopen = useRef(false);

	const onClick = (event) => {
		if (preventReopen.current) {
			event.preventDefault();
			preventReopen.current = false;
			return;
		}

		setIsOpen((open) => !open);
	};

	const onKeyDown = (event) => {
		if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
			event.preventDefault();
			setIsOpen(true);
		}
	};

	const close = () => {
		setIsOpen(false);
		buttonElement.current?.focus();
	};

	const onMouseDown = () => {
		if (isOpen) {
			preventReopen.current = true;
		}
	};

	const buttonId = `${id}Button`;
	const dropdownId = `${id}Menu`;

	const overrideButtonClass = typeof overrideButtonClassProp === 'function'
		? overrideButtonClassProp(isOpen)
		: overrideButtonClassProp;

	return (
		<DropdownContext.Provider value={{ close, isOpen, setIsOpen, id }}>

			{isOpen ? <HidePypestreamChatbot /> : null}

			<div
				className={cx(overrideClass || dropdown.container, additionalClasses)}
			>
				<button
					id={buttonId}
					ref={buttonElement}
					className={cx(overrideButtonClass || dropdown.toggle,
						isOpen && dropdown.isOpen,
						additionalButtonClasses,
					)}
					type="button"
					onClick={onClick}
					onKeyDown={onKeyDown}
					onMouseDown={onMouseDown}
					aria-label={ariaLabel}
					aria-haspopup={"menu"}
					aria-controls={dropdownId}
					aria-expanded={isOpen}
				>
					{label}
				</button>
				<Popper open={isOpen} anchorEl={buttonElement.current} disablePortal={true} placement={placement}
					className={dropdown.popper}>
					<DropdownOptions
						id={dropdownId}
						{...{
							overrideOptionsClass,
							additionalOptionsClasses,
						}}
					>
						{children}
					</DropdownOptions>
				</Popper>
			</div>

		</DropdownContext.Provider>
	);
};

Dropdown.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
	]).isRequired,

	overrideClass: PropTypes.string,
	placement: PropTypes.string,
};

export default Dropdown;
