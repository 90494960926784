import { clone } from "lodash";
import WSIssueSubsystemMedia from './WSIssueSubsystemMedia.js';

// 1.1.61 WSIssueMediaLineItem

export default class WSIssueMediaLineItem {
	constructor({
		issueMedia,
	}) {
		// WSIssueMedia
		// (Required) The media to be issued.
		this.issueMedia = issueMedia;
	}

	static fromBackoffice(data) {
		//Todo: lets use a factory to map WSIssueMedia extensions
		const result = new WSIssueMediaLineItem(data);

		result.issueMedia = new WSIssueSubsystemMedia(data.issueMedia);
		return result;
	}

	toBackoffice() {
		const result = clone(this);

		result.issueMedia = result.issueMedia.toBackoffice();
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.issueMedia = forGraphql.issueMedia.toResolver();
		return forGraphql;
	}
}

export const WSIssueMediaLineItemFragment = `
	issueMedia: WSIssueSubsystemMedia
`;

export const InputWSIssueMediaLineItemType = [ `
	input InputWSIssueMediaLineItem {
		issueMedia: InputWSIssueSubsystemMedia!
	}
` ];

export const WSIssueMediaLineItemType = [ `
	type WSIssueMediaLineItem {
		${WSIssueMediaLineItemFragment}
	}
` ];
