import { clone } from "lodash";
import {
	ccMonthYearBackoffice,
	mmYyMonth,
	mmYyYear,
} from "utils/FormatHelpers.js";
import WSSubsystemAccountTravelTokenDisplay, { WSSubsystemAccountTravelTokenDisplayFragment } from "./WSSubsystemAccountTravelTokenDisplay.js";

// 2.7.107.4 WSSubsystemAccountVirtualCardTravelTokenDisplay
// WSSubsystemAccountVirtualTravelTokenDisplay is a subclass of WSSubsystemAccountTravelTokenDisplay. 
// This object represents displayable fields for virtual travel tokens. Expected to set the tokenType as “VirtualCard”.
export default class WSSubsystemAccountVirtualCardTravelTokenDisplay extends WSSubsystemAccountTravelTokenDisplay {
	constructor({
		maskedPan,
		cardExpiry,
		
		expirationMonth,
		expirationYear,

		cardParkedFlag = null,
		vCardReference,
		vCardReferenceIdentifier,

		// super props
		tokenType,
		tokenNickname,
		tokenEnablementFee,
		mobileWalletType,
		mobileWalletDeviceType,
		masterTokenPartial = null,
	}) {

		super({
			tokenType,
			tokenNickname,
			tokenEnablementFee,
			mobileWalletType,
			mobileWalletDeviceType,
			masterTokenPartial,
		});

		// string(20)
		// (Required) The masked PAN.
		this.maskedPan = maskedPan;

		// string(4)
		// (Required) The card expiry date in MMYY format. For MM, 01 = January.
		this.cardExpiry = cardExpiry;

		// Backoffice returns a single value for expiration date: cardExpiry
		// break the exp date into 2 fields (expirationMonth, expirationYear) and replace cardExpiry
		if (cardExpiry) {
			this.expirationMonth = mmYyMonth(cardExpiry);
			this.expirationYear = mmYyYear(cardExpiry);
		}
		else {
			this.expirationMonth = expirationMonth;
			this.expirationYear = expirationYear;
		}

		// boolean
		// (Optional) Indicates if the virtual card is currently parked.
		this.cardParkedFlag = cardParkedFlag;

		// string(40)
		// (Required) Unique identifier of the virtual card in the Virtual Token Manager. This value is used for park/unpark actions from the Traveler App.
		this.vCardReference = vCardReference;

		// string(40)
		// (Required) Unique identifier of the virtual card in the mobile wallet. This value is used by the Traveler App to verify if the virtual cards is in the wallet.
		this.vCardReferenceIdentifier = vCardReferenceIdentifier;

	}

	static fromBackoffice(data) {
		const result = new WSSubsystemAccountVirtualCardTravelTokenDisplay(data);
		return result;
	}

	toBackoffice() {
		return {
			...super.toBackoffice(),
			maskedPan: this.maskedPan,
			cardParkedFlag: this.cardParkedFlag,
			vCardReference: this.vCardReference,
			vCardReferenceIdentifier: this.vCardReferenceIdentifier,
			cardExpiry: ccMonthYearBackoffice(this.expirationMonth, this.expirationYear),
		};
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSSubsystemAccountVirtualCardTravelTokenDisplayType = [ `
	type WSSubsystemAccountVirtualCardTravelTokenDisplay implements WSSubsystemAccountTravelTokenDisplayFactory {
		${WSSubsystemAccountTravelTokenDisplayFragment}
		maskedPan: String!
		cardExpiry: String!
		cardParkedFlag: Boolean
		vCardReference: String! 
		vCardReferenceIdentifier: String!

		# generated by us
		expirationMonth: Int
		expirationYear: Int
	}`,
];
