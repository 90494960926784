import { gql } from "@apollo/client";

export const RENAME_CARD = gql`
	mutation RenameCardM (
		$tokenId: String!
		$cardNickname: String!
		$subsystemAccountReference: String!
	) {
		renameCard (
			tokenId: $tokenId
			name: $cardNickname
			subsystemAccountReference: $subsystemAccountReference
		)
	}
`;
