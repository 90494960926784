import { useApolloClient, gql } from '@apollo/client';
import React, {
	useEffect,
	useState,
	useContext,
	useMemo,
} from 'react';
import {
	Redirect,
} from 'react-router-dom';

import {
	map,
	find,
	head,
	values,
	forEach,
} from 'lodash';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import { WireFormHelper } from 'utils/FormHelper.js';
import Cart from 'components/payments/Cart.js';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import ReloadBalance from 'components/account/reload-balance/ReloadBalance.js';
import { getCartBalance, getAmountDue, useCartContext, getCartTotal } from 'context/CartProvider.js';
import TakeOverLayout from 'layouts/TakeOverLayout.js';
import SelectPaymentMethodForm from 'components/account/card/add-passes/SelectPaymentMethodForm.js';

import PreventDefault from 'utils/PreventDefault.js';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import Input from 'components/forms/Input.js';
import Button, { Secondary } from 'components/Button.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import {
	getProductList,
} from 'pages/account/PurchasePass.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import NewProductAdded from 'components/toasts/NewProductAdded.js';
import FundingSourcesProvider, { useFundingSourcesContext } from 'context/FundingSourcesContext.js';

import CmsContentList from 'components/data/CmsContentList';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import ReloadSessionDataQs, { reloadProducts } from 'components/data/session-user/refetch-queries/ReloadSessionData.js';
import WSSubsystemPurse from "server/api-types/WSSubsystemPurse.js";
import { PAYMENT_TYPES } from "server/api-types/WSPayment.js";
import { UNRESTRICTED, CARD_TYPE_CONTACTLESS } from 'utils/Constants.js';
import {
	getTransitAccountRefetchQueries,
} from "components/data/transit-account/TransitAccount.js";
import { useCanResolveBalance } from "components/data/transit-account/EMV.helpers.js";

import { getYupSchema as getAddPaymentMethodYupSchema, addExpiryDateFields } from 'pages/account/purchase/AddPaymentMethod.js';
import {
	incrementError,
	getYupSchema as getValuePurchaseYupSchema,
} from 'components/account/purchases/ValuePurchaseTypeSelection.js';
import CmsContentListContext from 'components/data/CmsContentListContext.js';
import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { RELOAD_BALANCE_FORM_ID } from 'components/account/reload-balance/consts.js';
import TransitAccountIdContext, { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import FundingSourcesQueryProvider, {
	useFundingSourcesQueryContext,
} from 'components/account/reload-balance/FundingSourcesQueryProvider.js';
import LoadingIcon from 'components/icons/LoadingIcon.js';
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from "components/data/session-user/LoginStages.js";
import ProductCatalog from 'components/data/transit-account/ProductCatalog.query';
import { EnablementFee } from 'pages/account/PurchaseCharlieCardCcForm.js';
import { isUnpaidFee } from 'pages/account/purchase/StandardPurchaseFlow.js';
import useFormHelper from "utils/form-helper/useFormHelper";
import { findPrimaryToken } from "components/manage-cards/TokenHelpers.js";
import {
	getPrimaryBillingAddressSchema,
	getPrimaryPaymentMethodSchema,
} from 'pages/account/purchase/PurchaseProductPayment.js';

import * as styles from './ReloadBalanceTakeover.module.css';
import * as typography from 'styles/typography.module.css';

import PublicAppVars from 'utils/PublicAppVars';
import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";
import { getPayments } from "utils/payment/getPayments.js";

const cms = {
	pageTitle: 'miscText.purchase-header-reload-balance',
	panelHeader: 'miscText.purchase-reload-selection',
	panelSubHeader: 'miscText.purchase-card-3-payment-subheader',
	promoLabel: 'miscText["purchase-cart-promo-label.label"]',
	applyLabel: 'miscText.purchase-cart-promo-submit',
	submitButtonLabel: 'miscText.purchase-cart-submit',
	incrementError,
};

export const PURCHASE_STORED_VALUE = gql`
	mutation PurchaseSVForDebtSalesOrderM (
		$amountDue: Int!

		# what we're buying
		$storedValues: [ InputWSLoadSubsystemAccountValue! ]
		$feeDueLineItems: [ InputWSFeeDueLineItem! ]

		$eulaId: String

		# what we're paying with
		$payments: [ InputWSPaymentFactory! ]!
	) {
		OrderRoute {
			id
			postOrderSaleResolveDebt (
				amountDue: $amountDue
				storedValues: $storedValues
				feeDueLineItems: $feeDueLineItems
				eulaId: $eulaId
				payments: $payments
			) {
				orderId
			}
		}
	}`;

export const ReloadBalanceBody = ({
	formHelper,
	primaryBillingAddressId = null,
	transitValueOptions,
}) => {
	const { getFundingSources, loading: fundingSourcesLoading } = useFundingSourcesQueryContext();
	const { cart } = useCartContext();

	// When the page loads, we should see the accounts payment methods.
	// When the page loads we dont yet have a selected balance amount
	// So we should select the first amount on the transitValueOptionsarray.
	// https://reflexions.atlassian.net/browse/MBTA-1567

	const cartBalance = getCartBalance(cart);
	const amount = cartBalance
		? cartBalance
		: head(transitValueOptions)?.amount;

	return (
		<Lifecycles didMount={() => getFundingSources({ amount, formHelper })}>
			<div className={styles.container}>
				<div className={styles.section}>
					<CmsContentRenderer.P
						className={typography.h7}
						contentKey={cms.panelHeader}
						fallbackValue="You Have Selected"
					/>
					<ReloadBalance {...{
						multiStep: false,
						formHelper,
						isRequired: true,
					}} />
				</div>
				<div className={styles.section}>
					<CmsContentRenderer.H2
						className={typography.h8}
						contentKey={cms.panelSubHeader}
						fallbackValue="Enter Payment Details"
					/>
					{fundingSourcesLoading
						? <LoadingIcon />
						: <SelectPaymentMethodForm {...{
							isFirst: true,
							formHelper,
							primaryBillingAddressId,
						}} />}
				</div>
			</div>
		</Lifecycles>
	);
};

const ReloadBalanceTakeoverContent = ({
	valueIncrement,
	maxAddTransitValue: maxAddTransitValueProp,
	minAddTransitValue,
	transitValueOptions,
}) => {
	const apolloClient = useApolloClient();
	const { setToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);
	const { loginStage } = useLoginStage();
	const cmsContent = useContext(CmsContentListContext);

	const subsystemAccountReference = useTransitAccountIdContext();
	const { purseTotal, hasNegativeBalance, tokens } = usePurseBalanceContext();

	const { loading } = useFundingSourcesQueryContext();

	const [ maxAddTransitValue, setMaxAddTransitValue ] = useState(maxAddTransitValueProp ?? PublicAppVars.TRANSIT_ACCOUNT_MAX_BALANCE);

	// If the card is EMV & there is a balance it must be paid off and nothing else can happen in the flow.
	const canResolveBalance = useCanResolveBalance({ subsystemAccountReference });

	const {
		cart,
		setNegativeBalance,
		addUnpaidFee,
	} = useCartContext();

	const cartBalance = getCartBalance(cart);

	const primaryToken = useMemo(() => findPrimaryToken(tokens), [ tokens ]);
	const isEmvCard = primaryToken?.subsystemTokenType === CARD_TYPE_CONTACTLESS;

	const { status, feeDueAmount } = primaryToken?.tokenInfo?.tokenEnablementFee ?? {};
	const hasUnpaidFee = isUnpaidFee(status);

	const unRegisteredLoggedIn = loginStage === loginStages.unRegisteredLoggedIn;

	// When an account has a negative balance should we reflect the negative balance in the initial cart total
	useEffect(() => {
		if (hasNegativeBalance && !cart.negativeBalance) {
			setNegativeBalance(purseTotal);
		}
	}, [ hasNegativeBalance, cart.negativeBalance, purseTotal, setNegativeBalance, cart ]);

	useEffect(() => {
		if (hasUnpaidFee && !cart.unpaidFee) {
			addUnpaidFee(feeDueAmount);
		}
	}, [ hasUnpaidFee, cart.unpaidFee, feeDueAmount, addUnpaidFee ]);

	// Determine if the maxTransitValue should be changed if EMV_Reload is disabled and the card is EMV.
	useEffect(() => {
		if (canResolveBalance && isEmvCard && !PublicAppVars.ENABLE_EMV_RELOAD) {
			const purseNegativeBalance = Math.abs(purseTotal);
			setMaxAddTransitValue(purseNegativeBalance);
		}
	}, [ canResolveBalance, purseTotal, isEmvCard ]);

	const {
		selectedFundingSources,
		loadProductLineItemFunds,
		isMultiPayment,
		fundingSources,
	} = useFundingSourcesContext();

	const total = getCartTotal(cart);

	const getYupSchema = (formHelper) =>
		getAddPaymentMethodYupSchema({
			formHelper,
			isMultiPayment,
			isSelecting: [ false ],
			isAch: [ false ],
			requireAchEula: false,
			isNew: [ selectedFundingSources[ 0 ]?.isNew ],
			isNewConfirmed: [ false ],
			hasMobilePayment: false,
			disableCvv: total === 0,
			total,
		})
			.concat(getPrimaryPaymentMethodSchema())
			.concat(getPrimaryBillingAddressSchema({}))
			.concat(
				getValuePurchaseYupSchema({
					minAddTransitValue,
					maxAddTransitValue,
					autoLoadCriteriaType: null,
					valueIncrement,
					frequency: null,

					// We are in the balance-only purchase flow
					// the cart will always be cleared before validation
					cartBalance,
					purseTotal,
				}));

	const getDataToValidate = (formHelper) => {
		const data = formHelper.getFormData();
		const elements = formHelper.getFormElements();
		forEach(elements, element => {
			const fieldName = element.name;
			if (element.name === 'storedValueOther') {
				//convert decimal amount to int
				data[ fieldName ] = parseInt(element.value.replace(/[^0-9\.]+/g, "") * 100);
			}
		});

		addExpiryDateFields({
			isMultiPayment,
			data,
		});

		return data;
	};

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = useFormHelper({ getYupSchema, getDataToValidate });

	const finalizeFundingSourcesFromForm = useFinalizeAndSetFundingSourcesToContext({ formHelper });

	const kickoffSubmit = async () => {
		const amountDue = getAmountDue(cart);
		setSubmitting(true);

		try {
			const selectedFundingSources = await finalizeFundingSourcesFromForm();

			const payments = getPayments({
				cart,
				selectedFundingSources,
				loadProductLineItemFunds,
			});

			const storedValuePurse = new WSSubsystemPurse({
				purseType: PAYMENT_TYPES.storedValue,
				purseTypeDescription: "Stored Value",
				purseRestriction: UNRESTRICTED,
				purseRestrictionDescription: UNRESTRICTED,
			});

			const {
				storedValues,
				feeDueLineItems,
			} = getProductList(cart, subsystemAccountReference, storedValuePurse, primaryToken?.tokenId);

			const variables = {
				amountDue,

				// Todo: storedValues and products are subclasses of WSLoadProduct, we should use an input factory
				// SV is purchased in the amout of the debt owed and thats the debt resolution
				storedValues,
				payments: map(payments, wsPayment => wsPayment.toInputWSPaymentFactory()),
			};

			if (Boolean(cart.unpaidFee)) {
				variables.feeDueLineItems = feeDueLineItems;
			}

			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: PURCHASE_STORED_VALUE,
					variables,
					refetchQueries: [
						...getTransitAccountRefetchQueries(subsystemAccountReference),
						...ReloadSessionDataQs,
						reloadProducts(subsystemAccountReference),
					],
				}),
			);

		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Purchase Stored Value Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		setSubmitting(false);

		setToast(<NewProductAdded {...{ cart }} />);
		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(
					unRegisteredLoggedIn
						? routeKeys.GuestCardOverview
						: routeKeys.AccountCardOverview, { transit_account_id: subsystemAccountReference },
				),
			}} />,
		);
	};

	if (redirect) {
		return redirect;
	}

	const primaryFunding = find(fundingSources, ({ setAsPrimary }) => setAsPrimary);

	return (
		<WireFormHelper {...{ formHelper }}>
			<form
				id={RELOAD_BALANCE_FORM_ID}
				method="post"
				data-qa="reloadBalanceTakeoverForm"
				ref={formRef}
				onSubmit={PreventDefault(kickoffSubmit)}
			>
				<TakeOverLayout
					title={<CmsContentRenderer.Span contentKey={cms.pageTitle} fallbackValue="Reload Balance" />}
					showTabs={false}
					showCancel
					showNickname
				>
					<div className={styles.reloadBalanceWrapper}>
						<ReloadBalanceBody {...{
							formHelper,
							primaryBillingAddressId: primaryFunding?.billingAddressId,
							transitValueOptions,
						}} />
						<div className={styles.sideBar}>
							{hasUnpaidFee && <EnablementFee overrideClass={styles.enablementFee} />}
							<Input label={cmsContent[ cms.promoLabel ] ?? 'Promo Code'} />
							<Button typeStyle={Secondary} type={"button"}>
								<CmsContentRenderer.Span contentKey={cms.applyLabel} fallbackValue="Apply" />
							</Button>
						</div>
					</div>
				</TakeOverLayout>
				<Cart
					disableDelete={true}
					redirectPath={null}
					disableNext={loading}
					nextText={cmsContent[ cms.submitButtonLabel ] ?? 'Complete Payment'}
					{...{ submitting, formHelper }}
				/>
			</form>
		</WireFormHelper>
	);
};

const ReloadBalanceTakeover = (props) => (
	<FundingSourcesProvider>
		<TransitAccountIdContext.Consumer>{(subsystemAccountReference) =>
			<PurseBalanceProvider {...{ subsystemAccountReference }}>
				<ProductCatalog {...{ subsystemAccountReference }}>{({
					valueIncrement,
					maxAddTransitValue,
					minAddTransitValue,
					transitValueOptions,
					products,
				}) => (
					<CmsContentList list={values(cms)}>{() =>
						<FundingSourcesQueryProvider>
							<ReloadBalanceTakeoverContent {...{
								valueIncrement,
								maxAddTransitValue,
								minAddTransitValue,
								transitValueOptions,
								products,
								...props,
							}} />
						</FundingSourcesQueryProvider>
					}</CmsContentList>
				)}</ProductCatalog>
			</PurseBalanceProvider>
		}</TransitAccountIdContext.Consumer>
	</FundingSourcesProvider>
);
export default ReloadBalanceTakeover;
