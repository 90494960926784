import React from "react";
import {
	findIndex,
	forEach,
	get,
	includes,
	isEmpty,
} from "lodash";
import { yupErrorMiddleware } from "./error-handling/yupMiddleware.js";
import * as errorMsgStyle from "../styles/errormsg.module.css";
import CmsContentRendered from "../components/data/CmsContentRendered.js";
import {
	levels,
	log,
} from "./Logger.js";
import { Lifecycles } from 'libreact/lib/Lifecycles';

// form.elements that don't have an addEventListener method:
const collectionTypeStrings = [
	// FF and Safari return NodeList
	'[object NodeList]',
	// ie11 returns HtmlCollection
	'[object HtmlCollection]',
	// ie11 returns HTMLCollection
	'[object HTMLCollection]',
	// IE11 doesn't know RadioNodeList, so we can't use instanceof
	// We also can't check element instanceof NodeList because although that'd return true in
	// Chrome, an instance of RadioNodeList is not instanceof NodeList in ie11
	'[object RadioNodeList]',
];

export const WireFormHelper = ({ children, formHelper }) => <Lifecycles
	didMount={formHelper.wireInputs}>{children}</Lifecycles>;

export default class FormHelper {
	constructor(...args) {
		const useHooks = args[ 1 ] === undefined;
		const component = useHooks ? null : args[ 0 ];

		const options = useHooks
			? args[ 0 ]
			: {
				component,
				formRef: args[ 1 ],
				componentSetState: component.setState.bind(component),
			};
		this.options = options;

		options.useHooks = useHooks;

		// if there's a component, import its methods to options unless they're already set
		if (!useHooks) {
			// make sure the state is an object
			if (!options.component.state) {
				throw new Error("State must be an object");
			}

			if (options.getDataToValidate === undefined && typeof component.getDataToValidate === 'function') {
				options.getDataToValidate = component.getDataToValidate.bind(component);
			}

			if (options.getYupSchema === undefined && typeof component.getYupSchema === 'function') {
				options.getYupSchema = component.getYupSchema.bind(component);
			}

			if (options.preChangeValidation === undefined && typeof component.preChangeValidation === 'function') {
				options.preChangeValidation = component.preChangeValidation.bind(component);
			}

			if (options.postChangeValidation === undefined && typeof component.postChangeValidation === 'function') {
				options.postChangeValidation = component.postChangeValidation.bind(component);
			}

			if (!options.getYupSchema) {
				throw new Error("getYupSchema required");
			}
		}

		if (!options.formRef) {
			// note that we're not checking formRef.current here, as the form may not have rendered yet
			throw new Error("formRef required");
		}

		this.getAllFormFieldNames = this.getAllFormFieldNames.bind(this);
		this.wireInputs = this.wireInputs.bind(this);
		this.wireElement = this.wireElement.bind(this);
		this.handleInputOnChange = this.handleInputOnChange.bind(this);
	}

	static errorJsx(message, id = null) {
		return message
			? (
				<div
					data-qa="ValidationErrorMsg"
					className={errorMsgStyle.errorMsg}
					role="alert"
					{...(id ? { id } : {})}
				>
					{message}
				</div>
			)
			: null;
	}

	static getStateErrorField(fieldName) {
		return fieldName
			? `${fieldName}_error`
			: 'validationError';
	}

	/**
	 * @deprecated
	 * @param contentKey
	 * @param displayMessage
	 * @returns {JSX.Element}
	 */
	static cmsKeyToJsx(contentKey, displayMessage) {
		const msgIsHtml = contentKey && Boolean(contentKey.match(/^miscHtml/));
		const renderedMessage = msgIsHtml
			? <div dangerouslySetInnerHTML={{ __html: displayMessage }} />
			: displayMessage;

		return displayMessage
			? <div data-content-key={contentKey}>{renderedMessage}</div>
			: (<CmsContentRendered contentKey={contentKey} rawHtml={msgIsHtml} />);
	}

	/**
	 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements
	 * Note that this returns elements that we don't validate, e.g. <fieldset>. See getAllFormFieldNames for the filter.
	 * @returns HTMLFormControlsCollection
	 */
	getFormElements() {
		return get(this.options.formRef.current, 'elements', []);
	}

	getAllFormFieldNames() {
		const elements = this.getFormElements();

		const foundNames = [];

		for (let i = 0; i < elements.length; i++) {
			const el = elements[ i ];
			const name = el.name;
			const tagName = el.tagName.toLowerCase();

			if (!name) {
				// we only care about named input elements (that would submit a value)
				continue;
			}

			// The full list of possibilities is here:
			// https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/elements#Value
			if (findIndex([ 'button', 'input', 'select', 'textarea' ], tagType => tagType === tagName) === -1) {
				continue;
			}

			foundNames.push(name);
		}
		return foundNames;
	}

	getEl(fieldName) {
		const elements = this.getFormElements();
		return elements[ fieldName ];
	}

	getState(key) {
		return (this.options.useHooks
			? this.options.validationState
			: this.options.component.state
		)[ key ];
	}

	mergeState(newState) {
		if (this.options.useHooks) {
			const final = {
				...this.options.validationState,
				...newState,
			};
			this.options.setValidationState(final);
		} else {
			this.options.componentSetState(newState);
		}
	}

	setFieldError(fieldName, error) {
		const stateField = FormHelper.getStateErrorField(fieldName);

		this.mergeState({
			[ stateField ]: error,
		});
	}

	getFieldError(fieldName) {
		const stateField = FormHelper.getStateErrorField(fieldName);
		return this.getState(stateField);
	}

	getFieldErrorJsx(fieldName) {
		const fieldState = this.getFieldError(fieldName);

		return FormHelper.errorJsx(fieldState);
	}

	onHookedRender(state, stateSetter, getYupSchema, getDataToValidate, preChangeValidation, postChangeValidation) {
		this.options.validationState = state;
		this.options.setValidationState = stateSetter;
		this.options.getYupSchema = getYupSchema;
		this.options.getDataToValidate = getDataToValidate;
		this.options.preChangeValidation = preChangeValidation;
		this.options.postChangeValidation = postChangeValidation;
	}

	setGetYupSchema(getYupSchema) {
		this.options.getYupSchema = getYupSchema;
	}

	wireInputs() {
		const elements = this.getFormElements();
		const inputNames = this.getAllFormFieldNames();

		forEach(inputNames, name => {
			// Note: don't have to worry about duplicate registration
			// https://www.w3.org/TR/2000/REC-DOM-Level-2-Events-20001113/events.html#Events-Registration-interfaces
			this.wireElement(elements[ name ]);
		});
	}

	wireElement(element) {
		if (includes(collectionTypeStrings, element.toString())) {
			// we have to wire the elements in a collection individually
			forEach(element, this.wireElement);
			return;
		}

		if (!element.addEventListener) {
			log(null, levels.error, { message: "unable to wireElement", element });
			return;
		}

		// we listen onChange (basically onBlur) instead of onInput to reduce flicker as the user types
		element.addEventListener(
			'change',
			this.handleInputOnChange,
			false,
		);
	}

	async handleInputOnChange(event) {

		const skipValidation = this.preChangeValidation(event);

		if (skipValidation) {
			return;
		}

		try {
			await this.startValidation(false);
		}
		catch (errorReport) {
			this.validationFieldErrorHandler(event.target)(errorReport);
			this.postChangeValidation(errorReport);
			return;
		}
		this.clearFieldElErrors(event.target);

		this.postChangeValidation(null);
	}

	getFormData() {
		// Note: checkboxes that are unchecked will not show up as fields in the formData
		// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#attr-checked
		const formData = new FormData(this.options.formRef.current);

		const dataObj = {};
		for (var pair of formData.entries()) {
			const [ key, value ] = pair;
			if (key.substring(key.length - 2) === '[]') {
				const arr = dataObj[ key ] || [];
				arr.push(value);
				dataObj[ key ] = arr;
			} else {
				dataObj[ key ] = value;
			}
		}
		return dataObj;
	}

	preChangeValidation(event) {
		if (typeof this.options.preChangeValidation === 'function') {
			return this.options.preChangeValidation(event);
		}
		return false;
	}

	postChangeValidation(errorReport) {
		if (typeof this.options.postChangeValidation === 'function') {
			return this.options.postChangeValidation(errorReport);
		}
		return;
	}

	getDataToValidate() {
		if (typeof this.options.getDataToValidate === 'function') {
			return this.options.getDataToValidate();
		}
		return this.getFormData();
	}

	getFieldValue(fieldName) {
		const formData = new FormData(this.options.formRef.current);
		return formData.getAll(fieldName).at(-1);
	}

	getElementValue(element) {
		const formData = new FormData(this.options.formRef.current);
		return formData.get(element.name);
	}

	/**
	 * Runs full validation and sets any error messages in state.
	 * The returned promise returns the validated data or throws
	 */
	doValidation() {
		return new Promise((resolve, reject) => {
			this.startValidation(true)
				.then(validatedData => resolve(validatedData))
				.catch(error => {
					this.validationErrorHandler(error);
					throw error;
				});
		});
	}

	/** Sets up validation, but doesn't update state for errors */
	async startValidation(fullSubmit) {
		const data = this.getDataToValidate();
		const yupSchema = this.options.getYupSchema(fullSubmit);

		const remainingSchemas = Array.isArray(yupSchema)
			? yupSchema
			: [ yupSchema ];

		// an array means that there are multiple schemas to run sequentially
		// useful for registration where we do regular client-side checks then call the prevalidate api only if
		// there are no other errors
		// yup doesn't have native support for this yet, so we implement it ourselves
		// https://github.com/jquense/yup/pull/257

		let result;
		while (remainingSchemas.length) {
			const currentSchema = remainingSchemas.pop();

			// we'll throw out result unless it's the last one
			// any errors are thrown in an ErrorReport
			result = await yupErrorMiddleware(
				currentSchema
					.validate(data, {
						abortEarly: false,
					}),
			);
		}

		return result;
	}

	hasErrors() {
		return !isEmpty(this.getErrors());
	}

	getErrors() {
		return this.getAllFormFieldNames()
			.concat('') // look at the top-level error message too
			.reduce((accumulator, fieldName) => {
				const fieldError = this.getFieldError(fieldName);
				if (fieldError) {
					accumulator[ fieldName ] = fieldError;
				}
				return accumulator;
			}, {});
	}

	clearAllErrors() {
		const newState = {
			validationError: '',
		};
		this.getAllFormFieldNames()
			.map(name => {
				newState[ FormHelper.getStateErrorField(name) ] = '';
			});
		this.mergeState(newState);
	}

	fieldClearsTopLevelMessage(element) {
		return element.dataset.clearsToplevelErrorMessage !== "false";
	}


	clearFieldErrors(field) {
		this.setFieldError(field, '');
	}

	clearFieldElErrors(element) {
		if (this.fieldClearsTopLevelMessage(element)) {
			// clear the overall error state as well as the field error
			this.setFieldError('', '');
		}

		this.clearFieldErrors(element.name);
	}

	/** converts ErrorReport to state obj */
	errorReportToStateObj(report) {
		const newState = {
			validationError: report.TopLevelKey
				? FormHelper.cmsKeyToJsx(report.TopLevelKey, report.display.topLevelMessage)
				: '',
		};
		forEach(report.FieldErrors, (errorCmsKey, fieldName) => {
			if (typeof errorCmsKey === 'string') {
				newState[ FormHelper.getStateErrorField(fieldName) ] = FormHelper.cmsKeyToJsx(
					errorCmsKey,
					get(report, [ 'display', 'fieldErrors', fieldName ]),
				);
			} else {
				const messages = [];
				let index;
				for (index = 0; index < errorCmsKey.length; index++) {
					messages.push(
						<div key={index}>
							{FormHelper.cmsKeyToJsx(
								errorCmsKey[ index ],
								get(report, [ 'display', 'fieldErrors', fieldName, index ]),
							)}
						</div>,
					);
				}
				newState[ FormHelper.getStateErrorField(fieldName) ] = messages.length
					? messages
					: '';
			}
		});
		return newState;
	}

	/** updates component state with errors from ErrorReport. Used for full submits. */
	validationErrorHandler(error, focusErrorField = true) {
		this.clearAllErrors();

		if (focusErrorField && !isEmpty(error.FieldErrors)) {
			const firstFieldError = this.getEl(Object.keys(error.FieldErrors)[ 0 ]);
			// check if we are dealing with radio or checkbox list which return `RadioNodeList`
			if (includes(collectionTypeStrings, firstFieldError.toString())) {
				// we have to wire the elements in a collection individually
				firstFieldError[ 0 ]?.focus();
			} else {
				firstFieldError?.focus();
			}
		}

		const newState = this.errorReportToStateObj(error);
		this.mergeState(newState);
	}

	/**
	 * handles <input onChange> errors, and updates the error state for that one field
	 * returns a function so that it can be used as
	 * .catch(validationFieldErrorHandler(field))
	 * instead of
	 * .catch(errorReport => validationFieldErrorHandler(field)(errorReport))
	 */
	validationFieldErrorHandler(element) {
		return (report) => {
			// similar to validationErrorHandler but only updates state for one field
			const stateErrorField = FormHelper.getStateErrorField(element.name);
			const fullReportState = this.errorReportToStateObj(report);
			const newState = {
				[ stateErrorField ]: fullReportState[ stateErrorField ],
			};
			if (this.fieldClearsTopLevelMessage(element)) {
				newState[ FormHelper.getStateErrorField('') ] = '';
			}
			this.mergeState(newState);
		};
	}
}
