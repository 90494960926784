import React, {
	useRef,
	useState,
} from 'react';
import {
	map,
	head,
	values,
} from 'lodash';

import cx from "classnames";
import { useParams } from 'react-router-dom';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import PurchaseTable from 'components/account/PurchaseTable.js';
import PurchaseBillingPanel from 'components/account/PurchaseBillingPanel.js';
import PurchaseShippingPanel from 'components/account/PurchaseShippingPanel.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';

import { useCartContext, getAmountDue, getCartTotal } from 'context/CartProvider.js';

import FormHelper from 'utils/FormHelper.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import PreventDefault from 'utils/PreventDefault.js';
import { SESSION_TRANSIT_ACCOUNT_GET } from 'components/data/transit-account/SessionTransitAccount.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import {
	getProductList,
	FULL_PURCHASE_MUTATION,
} from 'pages/account/PurchasePass.js';
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import CartLeavePagePrompt from 'pages/account/purchase/CartLeavePagePrompt.js';
import { useStepContext } from 'context/StepContext.js';
import CmsContentList from 'components/data/CmsContentList.js';
import WSName from 'server/api-types/WSName.js';
import WSAddressExt from 'server/api-types/WSAddressExt.js';
import { getTransitAccountRefetchQueries } from 'components/data/transit-account/TransitAccount.js';
import { GET_PRODUCT_CATALOG } from 'components/data/transit-account/ProductCatalog.query.js';
import { STANDARD_PURCHASE_FLOW_STEPS } from 'pages/account/purchase/StandardPurchaseFlow.js';
import { cms as purchaseCmsKeys } from 'pages/account/purchase/product-catalog-takeover/constants.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { TakeOverLayoutWrapper } from 'layouts/TakeOverLayout.js';

import * as style from './PurchaseProductConfirm.module.css';
import * as typography from 'styles/typography.module.css';
import { getPayments } from "utils/payment/getPayments.js";

const cms = {
	subHeader: 'miscText.purchase-cart-subheader',
	cancel: 'miscText.general-modal-cancel',
	stepThreeDescription: 'miscText.purchase-step3-description',
	backToCatalog: 'miscText.purchase-step3-back-catalogue',
	backToPayment: 'miscText.purchase-step3-back-payment',
	backToShipping: 'miscText.purchase-card-4-back-shipping',
	completePurchase: 'miscText.purchase-step3-back-submit',
	billingInformation: "miscText.purchase-card-4-billing-subheader",
	shippingInformation: "miscText.purchase-card-4-shipping-subheader",
};

export const PurchaseConfirmContent = ({
	submitting,
	formHelper,
	cancelUrl,
	handleBackToProductStep,
	handleBackToPaymentStep,
}) => {
	const { cart } = useCartContext();

	const hasBillingInfo = getCartTotal(cart) > 0;
	const hasShippingAddress = cart?.shippingDetails?.shippingAddress?.address1;

	return (
		<CmsContentList list={values(cms)}>{() => (
			<div className={style.review}>
				<div className={style.reviewHeader}>
					<CmsContentRenderer.H2
						className={typography.h7}
						contentKey={cms.stepThreeDescription}
						fallbackValue="To complete your order, please confirm that the information below is correct."
					/>
				</div>
				<div>
					<div className={style.reviewSubheader}>
						<CmsContentRenderer.H3
							className={typography.h8}
							contentKey={cms.subHeader}
							fallbackValue="Cart"
						/>
						<Button
							type="button"
							typeStyle={buttonTypeStylePlain}
							onClick={PreventDefault(handleBackToProductStep)}
						>
							<CmsContentRenderer.Span
								className={style.link}
								contentKey={cms.backToCatalog}
								fallbackValue="Back to product selection"
							/>
						</Button>
					</div>
					<PurchaseTable />
				</div>

				<div>
					<div className={style.reviewSubheader}>
						{hasBillingInfo ?
							<CmsContentRenderer.H3
								className={typography.h8}
								contentKey={cms.billingInformation}
								fallbackValue="Billing Information"
							/>
							: <div></div>
						}
						<Button
							type="button"
							typeStyle={buttonTypeStylePlain}
							onClick={PreventDefault(handleBackToPaymentStep)}
						>
							<CmsContentRenderer.Span
								className={style.link}
								contentKey={cms.backToPayment}
								fallbackValue="Back to payment"
							/>
						</Button>
					</div>
					{hasBillingInfo
						? <PurchaseBillingPanel />
						: null
					}
				</div>

				{hasShippingAddress ?
					<div>
						<div className={cx(style.reviewSubheader, style.shippingContainerMargin)}>
							<CmsContentRenderer.H3
								className={typography.h8}
								contentKey={cms.shippingInformation}
								fallbackValue="Shipping Information"
							/>
							<Button
								type="button"
								typeStyle={buttonTypeStylePlain}
								onClick={PreventDefault(handleBackToPaymentStep)}
							>
								<CmsContentRenderer.Span
									className={style.link}
									contentKey={cms.backToShipping}
									fallbackValue="Back to shipping"
								/>
							</Button>
						</div>
						<PurchaseShippingPanel />
					</div>
					: null
				}
				<div className={style.reviewButtonContainer}>
					<div className={style.reviewButtons}>
						<Button
							type="button"
							additionalClassNames={style.button}
							text={<CmsContentRenderer.Span contentKey={cms.cancel} fallbackValue="Cancel" />}
							to={cancelUrl}
							isPrimary={false}
						/>
						<Button
							additionalClassNames={style.button}
							text={<CmsContentRenderer.Span contentKey={cms.completePurchase}
								fallbackValue="Complete Purchase" />}
							isPrimary={true}
							{...{ submitting }}
						/>
					</div>
					{formHelper.getFieldErrorJsx('')}
				</div>
			</div>
		)}</CmsContentList>
	);
};

const PurchaseProductConfirm = ({
	cancelUrl,
	handleBackToProductStep,
	handleBackToPaymentStep,
	showTakeOverLayout = true,
}) => {
	const { step, nextStep } = useStepContext();
	const [ purchasePassMutator ] = useStandardMutation(FULL_PURCHASE_MUTATION);

	const { purses, tokens } = usePurseBalanceContext();
	const tokenId = head(tokens)?.tokenId;
	const wSSubsystemPurse = head(purses);
	const {
		transit_account_id: subsystemAccountReference,
	} = useParams();

	const { cart, setPurchaseConfirmationInfo } = useCartContext();
	const amountDue = getAmountDue(cart);

	const [ submitting, setSubmitting ] = useState(false);

	const formRef = useRef(null);
	const [ validationState, setValidationState ] = useState({});

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;

	const {
		loadProductLineItemFunds,
		selectedFundingSources,
		setFinalWsPayments,
	} = useFundingSourcesContext();

	formHelper.onHookedRender(
		validationState,
		setValidationState,
	);

	const kickoffSubmit = async () => {
		setSubmitting(true);

		GoogleAnalytics.logEvent("User is attempting to pay for pass purchase");

		const payments = getPayments({
			cart,
			selectedFundingSources,
			loadProductLineItemFunds,
		});

		setFinalWsPayments(payments);

		const {
			issueMediaLineItems,
			storedValues,
			products,
			feeDueLineItems,
		} = getProductList(cart, subsystemAccountReference, wSSubsystemPurse, tokenId);

		const variables = {
			amountDue,
			shoppingCartId: cart.shoppingCartId,
			// travelTokenId is where the pass will be assigned to
			// travelTokenId, // When calling autoload/enroll API we shouldn't be passing travelTokenId. Per Alessandro https://reflexions.slack.com/archives/GA82SPCTV/p1625592071134000?thread_ts=1625249929.112200&cid=GA82SPCTV

			// Todo: storedValues and products are subclasses of WSLoadProduct, we should use an input factory
			storedValues,
			products,
			issueMediaLineItems,

			payments: map(payments, wsPayment => wsPayment.toInputWSPaymentFactory()),
		};

		if (Boolean(cart.unpaidFee)) {
			variables.feeDueLineItems = feeDueLineItems;
		}

		const { shippingDetails } = cart;

		if (shippingDetails.shippingName) {
			variables.shippingName = new WSName(shippingDetails.shippingName).toResolver();
		}

		if (shippingDetails.shippingAddress) {
			variables.shippingAddress = new WSAddressExt(shippingDetails.shippingAddress);
		}

		try {

			const { data: { OrderRoute } } = await graphqlErrorMiddleware(purchasePassMutator({
				mutation: FULL_PURCHASE_MUTATION,
				refetchQueries: [
					{ query: SESSION_TRANSIT_ACCOUNT_GET, variables: { wsSubsystemAccountInfo_Id: null } },
					// We dont have a subsystemAccountReference if we are purchasing a charlie card
					...(subsystemAccountReference
						? [
							...getTransitAccountRefetchQueries(subsystemAccountReference),
							{
								query: GET_PRODUCT_CATALOG,
								variables: { subsystemAccountReference, loadType: null },
							},
						]
						: []
					),
				],
				awaitRefetchQueries: true,
				variables,
			}));


			setPurchaseConfirmationInfo(OrderRoute.postOrderSale);

		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Purchase Pass Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmitting(false);
		}

		nextStep();
	};

	return (
		<Lifecycles didMount={() => formHelper.wireInputs()}>
			<CartLeavePagePrompt />
			<form
				method="post"
				ref={formRef}
				onSubmit={PreventDefault(kickoffSubmit)}
			>
				<TakeOverLayoutWrapper
					{...{ showTakeOverLayout }}
					title={<CmsContentRenderedInline
						contentKey={purchaseCmsKeys.purchasNewProduct}
						fallbackValue="Purchase a New Product"
					/>}
					showCancel
					showNickname
					steps={STANDARD_PURCHASE_FLOW_STEPS}
					currentStep={step}
				>
					<PurchaseConfirmContent {...{
						submitting,
						formHelper,
						cancelUrl,
						handleBackToProductStep,
						handleBackToPaymentStep,
					}} />
				</TakeOverLayoutWrapper>
			</form>
		</Lifecycles>
	);
};

export default PurchaseProductConfirm;
