import { gql } from "@apollo/client";
import React from 'react';
import { SIZES } from "components/icons/LoadingIcon.js";
import { LanguageGet } from "./Language.js";
import StdQuery from "./StdQuery.js";

const GET_EULA = gql`
	query getEula (
		$id: Int!
		$locale: String!
		$active: Boolean
		$returnDocument: Boolean!
	) {
		eulas {
			eula(
				id: $id
				locale: $locale
				active: $active
			) {
				info {
					id
					feature
					name
					noticeDate
				}
				document {
					document @include(if: $returnDocument)
				}
			}
		}
	}
`;

const EulaData = ({
	eulaId,
	returnDocument,
	children,
}) => (
	<LanguageGet.Consumer>{language => (
		<StdQuery
			query={GET_EULA}
			variables={{
				id: eulaId,
				locale: language,
				active: true,
				returnDocument,
			}}
			spinnerSize={SIZES.page}
		>
			{({ data }) => {
				return children(data.eulas.eula);
			}}
		</StdQuery>
	)}</LanguageGet.Consumer>
);

export default EulaData;
