import { gql } from "@apollo/client";

export const TOKEN_ACTION = gql`
	mutation tokenActionM(
		$tokenId: String!
		$tokenAction: String!
		$subsystemAccountReference: String!
		$frontendStatus: String
		$reasonCode: String!
	) {
		TransitAccountMutationRoute {
			tokenAction(
				tokenId: $tokenId
				tokenAction: $tokenAction
				subsystemAccountReference: $subsystemAccountReference
				frontendStatus: $frontendStatus
				reasonCode: $reasonCode
			)
		}
	}
`;