import PropTypes from "prop-types";
import {
	stringify,
} from 'qs';
import React from "react";
import {
	Redirect,
	useLocation,
} from 'react-router-dom';

import routeKeys from 'CustomerRouteKeys.js';
import groupAdminRouteKeys from "GroupAdminRouteKeys.js";
import { LoginStageData } from "./LoggingIn.js";
import PublicAppVars from "utils/PublicAppVars.js";
import {
	levels,
	log,
} from "utils/Logger.js";

import NoOffsiteUrl from "utils/NoOffsiteUrl.js";
import { getPathByRoute } from "App.js";
import loginStages from "components/data/session-user/LoginStages.js";
import { usePostSigninGoToPath } from "components/data/session-user/PostSigninGoToPath.js";


// User must have completed at least this level of registration or they get redirected
const getMinLevelRedirect = ({ minLevel, returnAfterSignIn, pathname, loginStage, data }) => {
	if (loginStage >= minLevel) {
		// nothing to do here
		return null;
	}

	log(null, levels.info, `Redirecting because loginStage ${loginStage} < ${minLevel}`);

	const postSigninGoToPath = returnAfterSignIn
		? pathname
		: getPathByRoute(PublicAppVars.isB2BApi
			? groupAdminRouteKeys.Dashboard
			: routeKeys.AccountCardSelection);

	const postSigninSearch = {
		search: stringify({
			postSigninGoToPath,
		}),
	};

	if (minLevel === loginStages.unRegisteredLoggedIn) {
		return (
			<Redirect push
				to={{
					pathname: getPathByRoute(PublicAppVars.isB2BApi
						? groupAdminRouteKeys.Home
						: routeKeys.ViewTransactionHistory),
				}}
			/>
		);
	}

	// the mapping key is the level they currently are, which we know is too low
	// send them to the page that will let them advance to the next login stage
	const mapping = {
		[ loginStages.anonymous ]: (
			<Redirect push
				to={{
					pathname: getPathByRoute(PublicAppVars.isB2BApi
						? groupAdminRouteKeys.SignIn:routeKeys.SignIn),
					...postSigninSearch,
				}}
			/>
		),
		[ loginStages.needsEula ]: (
			<Redirect push
				to={{
					pathname: getPathByRoute(PublicAppVars.isB2BApi ? groupAdminRouteKeys.EulasPending: routeKeys.EulasPending),
					...postSigninSearch,
				}}
			/>
		),
	};

	const result = mapping[ loginStage ];

	if (!result) {
		throw new Error(`Unexpected loginStage ${loginStage}`);
	}
	return result;
};

// User must have completed at most this level of registration or they get redirected
// E.g. you can't go to the sign-in page when you're logged in
const getMaxLevelRedirect = ({ maxLevel, postSigninGoToPath, loginStage, data }) => {
	if (loginStage <= maxLevel) {
		// nothing to do here
		return null;
	}

	const nextPath = NoOffsiteUrl(postSigninGoToPath ?? getPathByRoute(PublicAppVars.isB2BApi ? groupAdminRouteKeys.Dashboard : routeKeys.AccountCardSelection));

	if (loginStage === loginStages.needsEula) {
		// User tried to leave the accept eula flow. Send them back.
		// They can use the decline button there or the sign out menu to get out of the flow.
		log(null, levels.info, `Logout because loginStage ${loginStage} > ${maxLevel}`);
		return (
			<Redirect push
				to={{
					pathname: getPathByRoute(PublicAppVars.isB2BApi ? groupAdminRouteKeys.EulasPending :routeKeys.EulasPending),
					search: stringify({
						postSigninGoToPath: nextPath,
					}),
				}}
			/>
		);
	}

	log(null, levels.info, `Redirecting because loginStage ${loginStage} > ${maxLevel}`);

	return (
		<Redirect push
			to={nextPath}
		/>
	);
};

const LoginStageRedirect = ({
	minLevel = loginStages.anonymous,
	maxLevel = loginStages.loggedIn,
	returnAfterSignIn = true,
	children = null,
}) => {
	const { pathname } = useLocation();
	const postSigninGoToPath = usePostSigninGoToPath();

	return (
		<LoginStageData>{(loginStage, { data }) => (
			// if it's undefined (e.g. during apollo cache reset, render children until it fills in
			(loginStage === undefined && children)
			|| getMinLevelRedirect({ minLevel, returnAfterSignIn, pathname, loginStage, data })
			|| getMaxLevelRedirect({ maxLevel, postSigninGoToPath, loginStage, data })
			|| children
		)}</LoginStageData>
	);
};

LoginStageRedirect.propTypes = {
	minLevel: PropTypes.number,
	maxLevel: PropTypes.number,
	returnAfterSignIn: PropTypes.bool,
	maxInsteadUrl: PropTypes.string,
	children: PropTypes.element,
};

export default LoginStageRedirect;
