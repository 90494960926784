import { gql, useApolloClient } from "@apollo/client";
import React, {
	useRef,
	useState,
} from "react";
import {
	Redirect,
} from "react-router-dom";
import {
	values,
	map,
	isEmpty,
} from "lodash";
import useFormHelper from "utils/form-helper/useFormHelper.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import {
	object as yup_object,
	boolean as yup_boolean,
} from "yup";
import { Lifecycles } from "libreact/lib/Lifecycles";

import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import FormHelper from "utils/FormHelper.js";
import PreventDefault from "utils/PreventDefault.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";

import TakeOverLayout from "../../layouts/TakeOverLayout.js";
import Button, { Secondary, buttonTypeStylePlain } from "components/Button.js";
import Toast from "components/Toast.js";
import { findPrimaryToken, getTokenNicknameAndPan , isGhostCard } from "components/manage-cards/TokenHelpers.js";
import RadioInput from "components/forms/inputs/RadioInput.js";
import CmsContentList, { useCmsContentList } from 'components/data/CmsContentList.js';
import { getSessionTransitAccountRefetchQueries } from "components/data/transit-account/SessionTransitAccount.js";
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";

import { useStepContext } from "context/StepContext.js";
import { useTransferContext } from "context/TransferContext.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import { USER_TRAVEL_CARDS_QUERY } from 'components/data/session-user/SessionUser.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { TransferDetails, TransferItemsList } from "./card/TransferProductsConfirmation.js";
import { DELINK_FLOW_STEPS } from "./DeLinkCardFlow.js";
import { ComponentLoading } from 'components/icons/LoadingIcon.js';
import CmsContentListContext from 'components/data/CmsContentListContext.js';
import CmsContentRenderer, { getContentOrFallback } from 'components/data/CmsContentRenderer.js';

import * as style from "./LinkCharlieCard.module.css";
import * as tabs from '../../styles/Tabs.module.css';
import * as panel from "components/Panel.module.css";
import * as buttonStyle from 'components/Button.module.css';
import * as controlStyle from "components/forms/Control.module.css";
import * as takeoverStyles from 'layouts/TakeOverLayout.module.css';


const DELETE_TRANSIT_ACCOUNT = gql`
	mutation deleteTransitAccountM(
		$subsystemAccountReference: String!
		$transferData: InputOrderTransfer
	) {
		deleteTransitAccount (
			subsystemAccountReference: $subsystemAccountReference
			transferData: $transferData
		)
	}
`;

const cms = {
	// Page CMS
	header: "miscText.remove-transit-header",
	historySubheader: "miscText.remove-transit-history-subheader",
	historyDescription: "miscText.remove-transit-history-description",
	historyDelete: "miscText.remove-transit-history-delete",
	historyKeep: "miscText.remove-transit-history-keep",
	confirmSubheader: "miscText.remove-transit-confirm-subheader",
	confirmDescription: "miscText.remove-transit-confirm-description",
	confirmCancel: "miscText.remove-transit-confirm-cancel",
	confirmSubmit: "miscText.remove-transit-confirm-submit",
	back: 'miscText.transfer-confirm-back',

	// Toast CMS
	confirmationToastDelink: "miscText.remove-transit-confirmation-removed", // "<delinkedCardNickname> has been removed from your account"
	confirmationToastTransfer: "miscText.transfer-confirmation", // "The following has been transferred to {{nickname}}, and is available to use now:"

	ghostCardLabel: "miscText.empty-account-nickname",
};

const DeLinkCardToast = ({
	delinkedCardNickname,
	targetNickname,
	balance,
	passDescriptions,
	isTransfer = false,
}) => {
	const { removeToast } = useGlobalToastsContext();

	return <Toast
		type="success"
		onClosed={removeToast}
		title={
			<div>
				<CmsContentRenderedInline contentKey={cms.confirmationToastDelink} variables={{ nickname: delinkedCardNickname }} />
				{isTransfer && (
					<>
						<CmsContentRenderedInline contentKey={cms.confirmationToastTransfer} variables={{ nickname: targetNickname }} />
						<TransferItemsList {...{ balance, passDescriptions }} />
					</>
				)}
			</div>}
	/>;
};

const SubmitButton = ({
	submitting,
	handleCancel,
}) => {
	return (
		<div className={buttonStyle.wrapper}>
			<Button
				type={Secondary}
				onClick={PreventDefault(() => handleCancel())}
				additionalClassNames={buttonStyle.flexOne}
			>
				<CmsContentRenderer.Span contentKey={cms.confirmCancel} fallbackValue="No, do not remove" />
			</Button>
			<Button
				{...{ submitting }}
				additionalClassNames={buttonStyle.flexOne}
			>
				<CmsContentRenderer.Span contentKey={cms.confirmSubmit}
					fallbackValue="Yes, remove the card from my account" />
			</Button>
		</div>
	);
};

// To be used in Phase 2
const RemoveCardHistory = ({ formHelper }) => {
	// to be used in validation object of parent
	const validations = {
		deleteCardHistory: yup_boolean().required('miscText["errors.general.field_required"]'),
	};

	const [ deleteCardHistory, setDeleteCardHistory ] = useState(false);

	return (
		<CmsContentList list={values(cms)}>{() => (
			<>
				<CmsContentRenderer.H2
					className={tabs.title}
					contentKey={cms.historySubheader}
					fallbackValue="Do you want to delete the history associated with this card?"
					data-qa="delink-card-header"
				/>
				<CmsContentRenderer.P
					contentKey={cms.historyDescription}
					fallbackValue="If you don't delete the history, it will remain with the unregistered card."
					data-qa="link-card-sub-header"
				/>

				<div
					className={style.keepCardHistory}
					role="group"
				>
					<RadioInput
						name="deleteCardHistory"
						label={<CmsContentRenderer.Span
							className={controlStyle.label}
							contentKey={cms.historyKeep}
							fallbackValue="Keep Card History"
						/>}
						key="deleteCardHistory-false"
						id="deleteCardHistory-false"
						checked={!deleteCardHistory}
						value={false}
						onClick={() => setDeleteCardHistory(false)}
						controlled
					/>
					<RadioInput
						name="deleteCardHistory"
						label={<CmsContentRenderer.Span
							className={controlStyle.label}
							contentKey={cms.historyDelete}
							fallbackValue="Delete Card History"
						/>}
						key="deleteCardHistory-true"
						id="deleteCardHistory-true"
						checked={deleteCardHistory}
						value={true}
						onClick={() => setDeleteCardHistory(true)}
						controlled
					/>
				</div>
			</>
		)}</CmsContentList>
	);
};

const DeLinkCard = ({
	transitAccountQ,
	cancelUrl,
	hideTransfer = false,
}) => {

	// Data from Contexts
	const { step, prevStep } = useStepContext();
	const subsystemAccountReference = useTransitAccountIdContext();
	const { cart, validationState, setValidationState } = useTransferContext();

	// Navigational Helpers
	const { setToast } = useGlobalToastsContext();
	const [ redirect, setRedirect ] = useState(null);

	// FORM States
	const apolloClient = useApolloClient();
	const [ submitting, setSubmitting ] = useState(false);

	// FORM Set up
	const getYupSchema = () => {
		return yup_object().shape({});
	};

	const {
		formRef,
		formHelper,
	} = useFormHelper({ getYupSchema });

	formHelper.onHookedRender(validationState, setValidationState, getYupSchema);

	const cmsContentList = useCmsContentList({
		list: values(cms),
	});

	// replaced cards dont have tokens
	// https://reflexions.atlassian.net/browse/MBTA-2758
	const ghostCard = isGhostCard(transitAccountQ);

	// Parsings and Assignments
	const primaryToken = ghostCard
		? null
		: findPrimaryToken(transitAccountQ?.tokens);

	const delinkedCardNickname = ghostCard
		? getContentOrFallback({ content: cmsContentList.cmsContent[ cms.ghostCardLabel ] }, 'History')
		: getTokenNicknameAndPan(primaryToken); // card that's being delinked and items transfered away from. Transfer flow refers to this as "sourceWhatever"

	const passDescriptions = map(cart.transferPasses, wsSubsystemAccountPass => wsSubsystemAccountPass.passDescription);
	const targetNickname = cart.targetCard?.nickname;
	const balance = cart.transferValue; // Int
	const targetSubsystemAccountReference = cart.targetCard?.subsystemAccountReference;
	const sourceSubsystemAccountReference = subsystemAccountReference;

	// Build Transfer Object for submit. Also used to render transfer summary.
	const transferData = targetSubsystemAccountReference
		? {
			transferValueOption: cart.transferValueOption,
			transferValue: parseInt(cart.transferValue, 10),
			passSerialNumbers: map(cart.transferPasses, ({ passSerialNbr }) => passSerialNbr),
			sourceSubsystemAccountReference,
			targetSubsystemAccountReference,
		}
		: null;

	// Form Submit Method
	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {

			// Build full submit object
			const variables = {
				subsystemAccountReference,
				transferData,
			};

			await graphqlErrorMiddleware(
				apolloClient.mutate({
					mutation: DELETE_TRANSIT_ACCOUNT,
					variables,
					refetchQueries: [
						{ query: USER_TRAVEL_CARDS_QUERY },
						...getSessionTransitAccountRefetchQueries({ sourceSubsystemAccountReference }),
						...getTransitAccountRefetchQueries(sourceSubsystemAccountReference),
						// if there is a transfer, refresh cache for the TA recieving the transfer
						...(transferData ? getTransitAccountRefetchQueries(transferData.targetSubsystemAccountReference) : []),
					],
				}));

		} catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Delink transit account failed`);
			formHelper.validationErrorHandler(errorReport);

			// send field level errors back to step 1
			if (!isEmpty(errorReport.FieldErrors)) {
				prevStep();
			}

			return;
		} finally {
			setSubmitting(false);
		}

		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardSelection),
			}} />,
		);

		setToast(
			<DeLinkCardToast {...{
				delinkedCardNickname,
				targetNickname,
				balance,
				passDescriptions,
				isTransfer: Boolean(targetSubsystemAccountReference),
			}} />
		);
	};

	const handleCancel = () => {
		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardSelection),
			}} />,
		);

		return;
	};

	if (redirect) {
		return redirect;
	}

	if (cmsContentList.cmsContentLoading) {
		return <ComponentLoading />;
	}

	return (
		<CmsContentListContext.Provider value={cmsContentList.cmsContent}>
			<Lifecycles didMount={formHelper.wireIputs} >
				<form
					method="post"
					data-qa='delinekForm'
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					<TakeOverLayout title={<CmsContentRenderer contentKey={cms.header} fallbackValue="Remove Charlie Card" />}
						showCancel
						cancelLink={cancelUrl}
						showNickname={!ghostCard}
						steps={hideTransfer ? [] : DELINK_FLOW_STEPS}
						currentStep={step}
					>
						<div className={tabs.mainContent} data-qa="LinkCardContent">
							<div className={tabs.main}>

								{hideTransfer
									? null
									: <div className={takeoverStyles.backButtonWrapper}>
										<Button
											typeStyle={buttonTypeStylePlain}
											onClick={PreventDefault(prevStep)}
										>
											<CmsContentRenderer.Span contentKey={cms.back} fallbackValue="Back to Select Items for Transfer" />
										</Button>
									</div>}
								{/*
									<RemoveCardHistory formHelper={formHelper}/>
								*/}

								{/* If the user is transfering, show the transfer details */}
								{transferData
									? <TransferDetails {...{ sourceCardNickname: delinkedCardNickname, targetNickname, balance, passDescriptions }} />
									: null}

								<br />
								<div
									className={panel.card}
								>
									<div>
										<CmsContentRenderedInline
											className={tabs.title}
											contentKey={cms.confirmSubheader}
											variables={{ nickname: delinkedCardNickname }}
											fallbackValue={`Are you sure you want to remove ${delinkedCardNickname}?`}
										/>
										<CmsContentRenderer.P
											contentKey={cms.confirmDescription}
											fallbackValue="This card will still function, but will no longer be accessible from this account. You can add this card back to this or another account in the future."
										/>

										{formHelper.getFieldErrorJsx('')}
										<SubmitButton {...{ submitting, handleCancel }} />
									</div>
								</div>
							</div>
						</div>
					</TakeOverLayout>
				</form>
			</Lifecycles>
		</CmsContentListContext.Provider>
	);
};

export default DeLinkCard;
