import { WSTravelTokenEnablementFeeInfoType } from "./WSTravelTokenEnablementFeeInfo.js";
import { DateTimeType } from "./DateTime.js";

/**
 * WSSubsystemAccountTravelTokenDisplay
 * Abstract class to reference a specific travel token object as a user-friendly displayable search result when viewing
 * subsystem accounts. Each specific token defines the fields to uniquely identify the specific travel token.
 * We have to figure out what to instantiate on our own.
 **/

export default class WSSubsystemAccountTravelTokenDisplay {
	constructor({
		tokenType,
		tokenNickname = null,
		tokenEnablementFee = null,

		mobileWalletType = null,
		masterTokenPartial = null,
		mobileWalletDeviceType = null,
	}) {

		// string(20)
		// (Required)  Specific identifier of the travel token type. See section Error! Reference source not found. for allowed values.
		this.tokenType = tokenType;

		// string(40)
		// (Optional)  Nick name of the travel token.
		this.tokenNickname = tokenNickname ? tokenNickname : null;

		// WSTravelTokenEnablementFeeInfo
		// (Optional)  Information about travel token enablement.
		this.tokenEnablementFee = tokenEnablementFee;


		// string(40)
		// (Optional) If the travel token is stored in a mobile wallet, this field indicates the mobile wallet type. .Examples of valid values are:
		// ApplePay
		// GooglePay
		// SamsungPay
		// AndroidPay
		// Unknown
		this.mobileWalletType = mobileWalletType;

		// This field is applicable to Bankcard and Virtual Card travel tokens and the accuracy of these values are dependent on the ability of the credit/debit card gateway and Virtual Card TSM to report the exact wallet type.
		// The absence of this field indicates that this is a physical travel token.
		// Value “Unknown” indicates the travel token is not physical but the wallet type is not known.

		// string(20)
		// (Optional) The token partial for the master token (physical card or virtual card last 4 FPAN).
		// This field is used to group the travel tokens that have the same master.

		// sometimes masterTokenPartial is ''
		this.masterTokenPartial = masterTokenPartial ? masterTokenPartial : null;

		// string(40)
		// (Optional) If the travel token is stored in a mobile wallet, the mobile device type.
		// Phone
		// Watch
		// Unknown

		// This field is applicable to Bankcard and Virtual Card travel tokens and the accuracy of these values are dependent on the ability of the Virtual Card TSM and the Bankcard payment gateway to report the exact wallet device type.
		// The absence of this field indicates that this is a physical travel token.
		// Value “Unknown” indicates the travel token is not physical but the wallet device type is not known.
		this.mobileWalletDeviceType = mobileWalletDeviceType;
	}

	get data() {
		return this;
	}

	static fromBackoffice(data) {
		throw new Error("this is an abstract class");
	}

	set(field, value) {
		this[ field ] = value;
		return this;
	}

	toBackoffice() {
		return this;
	}
}

export const WSSubsystemAccountTravelTokenDisplayFragment = `
	id: ID!
	tokenType: String!
	tokenNickname: String
	tokenEnablementFee: WSTravelTokenEnablementFeeInfo
	mobileWalletType: String
	masterTokenPartial: String
	mobileWalletDeviceType: String
`;

export const WSSubsystemAccountTravelTokenDisplayType = [
	DateTimeType,
	...WSTravelTokenEnablementFeeInfoType, `
	type WSSubsystemAccountTravelTokenDisplay implements WSSubsystemAccountTravelTokenDisplayFactory {
		${WSSubsystemAccountTravelTokenDisplayFragment}
	}
` ];
