import { clone } from "lodash";
import WSAvsData, { WSAvsDataType } from "./WSAvsData.js";

/* This is a custom subclass of WSCreditCard, used to transit clear credit card data before being encrypted */

export default class CreditCardClear {
	constructor ({
		cardNumber,
		maskedPan,
		nameOnCard,
		cardExpiryMMYY,
		creditCardType,
	}) {
		this.cardNumber = cardNumber;
		this.maskedPan = maskedPan;
		this.nameOnCard = nameOnCard;
		this.cardExpiryMMYY = cardExpiryMMYY;
		this.creditCardType = creditCardType;
	}
}
