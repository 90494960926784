import { clone } from "lodash";
import WSSubsystemAccountTravelTokenDisplay, { WSSubsystemAccountTravelTokenDisplayFragment } from "./WSSubsystemAccountTravelTokenDisplay.js";

/**
 * WSSubsystemAccountSmartcardTravelTokenDisplay is a subclass of
 * WSSubsystemAccountTravelTokenDisplay. This object represents displayable fields for smartcard travel
 * tokens. Expected to set the tokenType as “Smartcard”.
 */

export default class WSSubsystemAccountSmartcardTravelTokenDisplay extends WSSubsystemAccountTravelTokenDisplay {
	constructor(obj = {}) {
		super(obj);

		// serialNumber
		// string(20)
		//(Required) The complete serial number of the card.
		this.serialNumber = obj.serialNumber;

		// cardExpiryDate
		// date
		// (Required) The card expiry
		this.cardExpiryDate = obj.cardExpiryDate;
	}

	get data() {
		return this;
	}

	static fromBackoffice(data) {
		const result = new WSSubsystemAccountSmartcardTravelTokenDisplay(data);
		return result;
	};

	toBackoffice() {
		return {
			...super.toBackoffice(),
			serialNumber: this.serialNumber,
			cardExpiryDate: this.cardExpiryDate,
		};
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSSubsystemAccountSmartcardTravelTokenDisplayType = [ `
	type WSSubsystemAccountSmartcardTravelTokenDisplay implements WSSubsystemAccountTravelTokenDisplayFactory {
		${WSSubsystemAccountTravelTokenDisplayFragment}
		serialNumber: String!
		cardExpiryDate: GraphQLDateTime!
	}`,
];
