import React from 'react';
import PropTypes from "prop-types";
import PublicAppVars from "utils/PublicAppVars.js";
import { deserializeGraphqlRequestError } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

export const Error = ({ error }) => {

	if (!error || !PublicAppVars.ERRORS_TO_SCREEN) {
		return null;
	}

	const graphqlError = deserializeGraphqlRequestError(error);

	let contentKey;

	if (graphqlError?.TopLevelKey) {
		contentKey = graphqlError.TopLevelKey;
	} else if (graphqlError?.FieldErrors && Object.keys(graphqlError?.FieldErrors).length > 0) {
		contentKey = graphqlError.FieldErrors[ Object.keys(graphqlError.FieldErrors)[ 0 ] ];
	}

	return <>
        Error:&nbsp;
		{ contentKey 
			? <CmsContentRenderedInline
				elementType={"span"}
				fallbackValue={"Error..."}
				contentKey={contentKey}
			/>	
			: error.message
		}
	</>;
};

Error.propTypes = {
	error: PropTypes.object,
};

export default Error;
