// extracted by mini-css-extract-plugin
export var amount = "components-account-data-tables-__PurchaseHistoryMobileRow-module___amount";
export var chevron = "components-account-data-tables-__PurchaseHistoryMobileRow-module___chevron";
export var container = "components-account-data-tables-__PurchaseHistoryMobileRow-module___container";
export var detailView = "components-account-data-tables-__PurchaseHistoryMobileRow-module___detailView";
export var fadedText = "components-account-data-tables-__PurchaseHistoryMobileRow-module___fadedText";
export var isDeclined = "components-account-data-tables-__PurchaseHistoryMobileRow-module___isDeclined";
export var location = "components-account-data-tables-__PurchaseHistoryMobileRow-module___location";
export var payment = "components-account-data-tables-__PurchaseHistoryMobileRow-module___payment";
export var productName = "components-account-data-tables-__PurchaseHistoryMobileRow-module___productName";
export var purchaseDate = "components-account-data-tables-__PurchaseHistoryMobileRow-module___purchaseDate";
export var purchaseStat = "components-account-data-tables-__PurchaseHistoryMobileRow-module___purchaseStat";
export var purchaseType = "components-account-data-tables-__PurchaseHistoryMobileRow-module___purchaseType";
export var purchaseValue = "components-account-data-tables-__PurchaseHistoryMobileRow-module___purchaseValue";