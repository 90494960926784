import React, { useContext } from "react";
import {
	levels,
	log,
} from "utils/Logger.js";
import { WireFormHelper } from 'utils/FormHelper.js';

export const FormHelperContext = React.createContext(() => log(null, levels.error, "FormHelperSet used before it was ready"));
export const useFormHelperContext = () => useContext(FormHelperContext);

export const FormHelperProvider = ({
	formHelperContext,
	children,
}) => {
	return (
		<FormHelperContext.Provider {...{ value: formHelperContext }}>
			<WireFormHelper formHelper={formHelperContext.formHelper}>
				{children}
			</WireFormHelper>
		</FormHelperContext.Provider>
	);
};

export default FormHelperProvider;