import React from 'react';
import { map } from "lodash";
import PropTypes from "prop-types";

export const normalizedRequestPropType = PropTypes.shape({
	isTemplate: PropTypes.bool,
	key: PropTypes.string.isRequired,
	variables: PropTypes.object,
});

export const looseRequestPropType = PropTypes.oneOfType([
	// can pass the key
	PropTypes.string,

	// or a full ContentRequest
	normalizedRequestPropType,
]);

export const normalizeRequest = ({ item, hasTemplates }) => (
	(typeof item === "string")
		? {
			key: item,
			isTemplate: hasTemplates,
		}
		: item
);

export const normalizeRequests = ({ list, hasTemplates }) => map(list, item => normalizeRequest({
	item,
	hasTemplates,
}));

const CmsContentListContext = React.createContext({});
export default CmsContentListContext;
