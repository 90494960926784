import PublicAppVars from "utils/PublicAppVars.js";

const groupAdminRouteKeys = {
	Home: 'Home',

	// Eulas
	EulaById: 'EulaById',
	EulasPending: 'EulasPending',
	TermsOfService: 'TermsOfService',

	// Auth
	SignIn: 'SignIn',

	// Registration
	Register: 'Register',
	RegisterProgramDetails: 'RegisterProgramDetails',
	RegisterSubmitApplication: 'RegisterSubmitApplication',
	RegisterVerify: 'RegisterVerify',

	TwoFactorAuth: 'TwoFactorAuth',
	TwoFactorAuthVerify: 'TwoFactorAuthVerify',

	ForgotPassword: 'ForgotPassword',
	ResetPassword: 'ResetPassword',
	UnlockAccount: 'UnlockAccount',
	UnlockAccountTokenVerify:'UnlockAccountTokenVerify',


	// Account
	Dashboard: "Dashboard",

	ProgramOverview: 'ProgramOverview',
	ProgramMembers: 'Members',
	PurchaseHistory: 'PurchaseHistory',
	Reports: 'Reports',
	ActivityLog: 'ActivityLog',
	PaymentMethods: 'PaymentMethods',
	ProgramDetails: 'ProgramDetails',

	AddNewPaymentMethod: "AddNewPaymentMethod",

	// Testing Routes. Leave the NODE_ENV check in so that webpack define plugin can hardcode it
	// that way it doesn't end up in client.js
	...((process.env.NODE_ENV !== "production" && PublicAppVars.TESTING_ROUTES)
		? { ApproveUser: "/reflexions-test/approve" }
		: {}
	),
};

export default groupAdminRouteKeys;
