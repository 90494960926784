import React from 'react';
import PropTypes from "prop-types";
import CmsContentRendered from "components/data/CmsContentRendered.js";

const CmsContentRenderedInline = (props) => (
	<CmsContentRendered
		elementType='span'
		{...props}
	/>
);

CmsContentRenderedInline.propTypes = {
	contentKey: PropTypes.string.isRequired,
	elementType: PropTypes.node,
	rawHtml: PropTypes.bool,
};

export default CmsContentRenderedInline;
