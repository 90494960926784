import { gql } from "@apollo/client";
import PropTypes from "prop-types";
import LoadingIcon, { SIZES } from 'components/icons/LoadingIcon.js';
import React, {
	useEffect,
	useState,
} from 'react';
import {
	log,
	levels,
	noticeError,
} from "utils/Logger.js";
import {
	getTransitAccountRefetchQueries,
} from "components/data/transit-account/TransitAccount.js";
import {
	get,
} from "lodash";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { WSAUTOLOAD_TYPES } from "server/api-types/WSAutoloadFactory.js";
import BillingAddress from "components/data/transit-account/GetBillingAddress.js";

export const UPDATE_AUTOLOAD = gql`
	mutation UpdateSubscription (
		$subscriptionId: String!
		$subsystemAccountId: String!
		$subscription: InputWSAutoloadSubscriptionUpdate!
	) {
		OrderRoute {
			id
			UpdateSubscription(
				subscriptionId: $subscriptionId
				subsystemAccountId: $subsystemAccountId
				subscription: $subscription
			) {
				subscriptionId
			}
		}
	}
`;

export const GET_AUTOLOAD_PAYMENT_METHODS = gql`
	mutation AutoLoadPaymentMethods (
		$autoloadSubsystemProduct: InputWSAutoloadSubsystemProduct
		$autoloadSubsystemValue: InputWSAutoloadSubsystemValue
	) {
		OrderRoute {
			getAutoLoadEnrollPaymentmethods (
				autoloadSubsystemProduct: $autoloadSubsystemProduct
				autoloadSubsystemValue: $autoloadSubsystemValue
			) {
				fundingSources {
					id
					fundingSourceId
					billingAddressId
					setAsPrimary
					status
					statusDescription
					creditCard {
						pgCardId
						maskedPan
						cardExpiryMMYY
						nameOnCard
						creditCardType
					}

					...on WSFundingSourceInfo {
						id
						fundingSourceId
						billingAddressId
						setAsPrimary
						status
						statusDescription
						customerId
					}
				}
			}
		}
	}`;

export const getAutoloadPaymentMethods = async ({
	wsAutoload,
	subsystemAccountReference,
	formHelper,
	mutator,
	triggerRefetch = true,
}) => {

	let response;
	try {

		const variables = new Object;

		switch (wsAutoload.type) {
			case WSAUTOLOAD_TYPES.addSubsystemValue:
				variables.autoloadSubsystemValue = wsAutoload;
				break;
			case WSAUTOLOAD_TYPES.purchaseSubsystemProduct:
				variables.autoloadSubsystemProduct = wsAutoload;
				break;
			default:
				log(null, levels.error, `Unexpected wsAutoload type ${wsAutoload.type}`);
		}

		response = await mutator({
			mutation: GET_AUTOLOAD_PAYMENT_METHODS,
			variables,
			...(triggerRefetch && {
				refetchQueries: [
					...getTransitAccountRefetchQueries(subsystemAccountReference),
				],
			}),
		});
	} catch (errorReport) {
		// we're not redirecting anywhere. Prepare the form for the next submit.
		noticeError(null, levels.info, errorReport, `Auto-load Pass Submit Failed`);
		formHelper.validationErrorHandler(errorReport);
		return;
	}
	const {
		fundingSources,
	} = get(response, "data.OrderRoute.getAutoLoadEnrollPaymentmethods", []);

	return {
		fundingSources,
	};
};


export const AutoloadPaymentMethods = ({
	subsystemAccountReference,
	wsAutoloadSubsystemValue = null,
	wsAutoloadSubsystemProduct = null,
	formHelper,
	spinnerSize = SIZES.component,
	children,
}) => {
	const [ loadingFundingSources, setLoadingFundingSources ] = useState(true);
	const [ fundingSources, setFundingSources ] = useState(null);
	const [ mutator, { loading } ] = useStandardMutation(GET_AUTOLOAD_PAYMENT_METHODS);

	useEffect(
		() => {
			// If Travel Card is not saved as payment method (fundingSourceId not returned for Travel Card)
			const fetchPaymentMethods = async () => {
				getAutoloadPaymentMethods({
					wsAutoload: wsAutoloadSubsystemProduct ?? wsAutoloadSubsystemValue,
					subsystemAccountReference,
					formHelper,
					mutator,
					triggerRefetch: false,
				})
					.then(({ fundingSources }) => {
						setFundingSources(fundingSources);
					})
					.catch((error) => noticeError(null, levels.error, error, "Get AutoloadPayment Methods Failed"));
				setLoadingFundingSources(false);
			};

			fetchPaymentMethods();
		},
		[
			wsAutoloadSubsystemProduct,
			wsAutoloadSubsystemValue,
			subsystemAccountReference,
			formHelper,
			mutator,
		],
	);

	if (loadingFundingSources) {
		return <LoadingIcon size={spinnerSize} waitAWhile />;
	}


	return Boolean(fundingSources?.length)
		? (
			<BillingAddress {...{
				billingAddressId: fundingSources[ 0 ].billingAddressId,
				formHelper,
			}}>{({ billingAddress }) => {
					return children({ fundingSources, billingAddress });
				}}
			</BillingAddress>
		)
		: children({ fundingSources });
};


AutoloadPaymentMethods.propTypes = {
	token: PropTypes.object,
	children: PropTypes.func.isRequired,
};

export default AutoloadPaymentMethods;
