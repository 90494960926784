import {
	clone,
	merge,
} from "lodash";
import WSPaymentHistoryInfo, { WSPaymentHistoryInfoFragment } from "./WSPaymentHistoryInfo.js";


// 2.7.26.1.WSPaymentHistoryCreditDebitInfo
// This object extends WSPaymentHistorytInfo when paymentType is CreditCard or CreditCardNotPresent or
// DebitCard.
export default class WSPaymentHistoryDirectDebitInfo extends WSPaymentHistoryInfo {
	constructor({
		institutionName,
		maskedAccountNumber,

		//super props
		authRefNbr,
		paymentType,
		paymentDateTime,
		paymentAmount,
		paymentReferenceNumber,
		paymentStatus,
	}) {

		super({
			authRefNbr,
			paymentType,
			paymentDateTime,
			paymentAmount,
			paymentReferenceNumber,
			paymentStatus,
		});

		// string(100)
		// (Required)  The name of the institution for this ACH Payment.
		this.institutionName = institutionName;

		// string(20)
		// (Required) The masked account number.
		this.maskedAccountNumber = maskedAccountNumber;
	}

	static fromBackoffice(data) {
		const result = new WSPaymentHistoryDirectDebitInfo(data);

		return merge(
			result,
			super.fromBackoffice(data)
		);
	}

	static fromSerialized(data) {
		const result = new WSPaymentHistoryDirectDebitInfo(data);

		return merge(
			result,
			super.fromSerialized(data)
		);
	}

	toBackoffice() {
		const result = clone(this);

		return merge(
			result,
			super.toBackoffice()
		);
	}


	toResolver() {
		const forGraphql = clone(this);

		return merge(
			forGraphql,
			super.toResolver()
		);
	}
}

export const WSPaymentHistoryDirectDebitInfoType = [ `
	type WSPaymentHistoryDirectDebitInfo implements WSPaymentHistoryInfoFactory {
		${WSPaymentHistoryInfoFragment}
		institutionName: String!
		maskedAccountNumber: String!
	}`,
];
