import React, { useState, useRef } from 'react';
import {
	Redirect,
	useLocation,
} from "react-router-dom";
import { parse } from 'qs';
import cx from 'classnames';
import {
	get,
	values,
} from 'lodash';
import {
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
} from "yup";
import PropTypes from "prop-types";
import { Lifecycles } from 'libreact/lib/Lifecycles';
import { useApolloClient } from '@apollo/client';

import TakeOverLayout from "layouts/TakeOverLayout.js";
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import Input from 'components/forms/Input.js';
import { getPathByRoute } from "App.js";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import ClientAjax from "utils/ClientAjax.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import FormHelper from "utils/FormHelper.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import PasswordValidation from 'components/auth/PasswordValidation.js';
import CmsContentList from 'components/data/CmsContentList.js';
import PreventDefault from 'utils/PreventDefault.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import routeKeys from 'CustomerRouteKeys.js';
import { LockoutResendButton } from './LockoutResendForm.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Toast from 'components/Toast.js';
import StandardMutation from 'components/data/StandardMutation.js';
import { POST_TWO_FACTOR_DELIVERY_OPTIONS, VERIFY_TWO_FACTOR_CODE } from 'components/data/TwoFactor.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import { useSessionCheckContext } from "components/data/session-user/SessionCheck.js";
import useStandardMutation from 'components/data/hooks/useStandardMutation.js';
import { TooltipCode } from 'components/guest/CardForm.js';
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";
import PublicAppVars from "utils/PublicAppVars.js";
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

import * as loginStyles from "./Login.module.css";
import * as verificationCodePanelStyles from "./VerificationCodePanel.module.css";
import * as viewTransactionHistoryStyles from 'pages/guest/ViewTransactionHistory.module.css';
import groupAdminRouteKeys from 'GroupAdminRouteKeys.js';



export const MODES = {
	RegisterVerify: "RegisterVerify",
	PasswordReset: "PasswordReset",
	UnlockAccountTokenVerify: "UnlockAccountTokenVerify",
	TwoFactorAuthVerify: "TwoFactorAuthVerify",
};

const cms = {

	// Unlock account
	unlockHeader: "miscText.unlock-account-verify-header",
	unlockSubheader: "miscText.unlock-account-verify-subheader",
	unlockSignIn: "miscText.unlock-account-verify-signin-cta",
	unlockResend: "miscText.unlock-account-verify-resend-cta",
	unlockAccounInputLabel: 'miscText.["unlock-account-verify-manual.label"]',

	// Verify email (register flow)
	registerVerifySubheader: "miscText.register-verify-manual-subheader", // TODO: add a subheader element to this page to render this content?
	registerVerifyEmailLabel: "miscText.register-verify-manual-email.label",
	registerVerifyInputLabel: "miscText.register-verify-manual-verification.label",
	registerVerifySubmit: "miscText.register-verify-manual-submit",

	// 2FA
	twoFactorHeader: 'miscText.signin-2factor-header',
	twoFactorDescription: 'miscText.signin-2factor-description',
	twoFactorInputLabel: 'miscText.["signin-2factor-verification-code.label"]',
	twoFactorTooltip: 'miscText.["signin-2factor-verification-code.tooltip"]',
	twoFactorResendText: 'miscText.signin-2factor-resend-text',
	twoFactorResendCta: 'miscText.signin-2factor-resend-cta',
	twoFactorCtaConfirmation: 'miscText.signin-2factor-resend-cta-confirmation',
	twoFactorSubmit: 'miscText.signin-2factor-submit',

	// Reset PW
	passwordResetInputLabel: "TODO:",
};

const getYupSchema = (fullSubmit, queryParams, askForUsername, askForPassword) => {
	const collectedReqs = recaptchaYupValidations;

	if (askForPassword) {
		// PCI-DSS 8.2.3 requires a minimum of 7 characters
		collectedReqs.password = yup_string()
			.min(8, "Minimum of 8 characters.")
			.required("Password is required.");
		collectedReqs.confirm = yup_string().oneOf(
			[ yup_ref("password") ],
			"Passwords don't match.",
		);
	}

	if (!queryParams.username && askForUsername) {
		collectedReqs.email = yup_string()
			.email("miscText.register-error-email")
			.required("Email is required.");
	}

	if (!queryParams.token) {
		collectedReqs.token = yup_string()
			.min(4, "Minimum of 4 characters.")
			.required("Token is required.");
	}

	const passwordReqsSchema = yup_object().shape({
		passwordCapitalLetter:
			yup_string()
				.matches(/[A-Z]/, 'miscText.register-email-password-req-upper'),
		passwordLowerCase:
			yup_string()
				.matches(/[a-z]/, 'miscText.register-email-password-req-lower'),
		passwordOneNumber:
			yup_string()
				.matches(/\d/, 'miscText.register-email-password-req-num'),
		passwordSpecialChar:
			yup_string()
				.matches(/[!@#$%^&]+/, 'miscText.register-email-password-req-special'),
	});

	if (fullSubmit) {
		return [
			passwordReqsSchema,
			yup_object().shape(collectedReqs),
		];
	}

	return yup_object().shape(collectedReqs);
};


const usernameIsEncrypted = (queryParams) => {
	// if query params not consumed, and username is set, assume it's encrypted
	return Boolean(queryParams.username);
};

/**
 * A hook to return the username from query param or from router state.
 *
 * @typedef {Object} Result
 * @property {string} username - username in queryParams OR username from router state OR null
 * @property {boolean} encrypted - returns true if username is encrypted (comes from queryParams)
 *
 *
 * @returns {Result}
 */
export const useUsername = () => {
	const location = useLocation();
	const queryParams = parse(location.search, { ignoreQueryPrefix: true });
	const { username = null } = queryParams;

	const stateUsername = location.state?.email;
	const isUsernameEncrypted = Boolean(username);
	return {
		username: username ?? stateUsername,
		encrypted: isUsernameEncrypted,
	};
};

export const useToken = () => {
	const location = useLocation();
	const queryParams = parse(location.search, { ignoreQueryPrefix: true });
	const { token = null } = queryParams;
	return token;
};

const verificationCodeLabel = (mode) => {
	const mapping = {
		[ MODES.RegisterVerify ]: { key: cms.registerVerifyInputLabel, fallbackValue: 'Verification code' },
		[ MODES.PasswordReset ]: {
			key: cms.passwordResetInputLabel,
			fallbackValue: 'Please enter the verification code from your email to reset your password and sign in:',
		},
		[ MODES.UnlockAccountTokenVerify ]: { key: cms.unlockAccounInputLabel, fallbackValue: 'Recovery Code' },
		[ MODES.TwoFactorAuthVerify ]: { key: cms.twoFactorInputLabel, fallbackValue: 'Verification Code' },
	};
	return mapping[ mode ];
};

const EmailInput = ({ initialEmail, formHelper, label }) => {
	return initialEmail
		? (
			<Input
				name="email"
				type="hidden"
				value={initialEmail}
				controlled={true}
			/>
		)
		: (
			<Input
				name="email"
				type="email"
				label={label || "Email"}
				error={formHelper.getFieldError('email')}
				data-qa="EmailInput"
				required={true}
			/>
		);
};

const TokenInput = ({ initialToken, formHelper, mode, cmsContent }) => {
	const cmsDataForInputLabel = verificationCodeLabel(mode);
	return initialToken
		? (
			<Input
				name="token"
				type="hidden"
				value={initialToken}
				controlled={true}
			/>
		)
		: mode === MODES.TwoFactorAuthVerify
		 	? (
				<div className={cx(verificationCodePanelStyles.fullWidth, viewTransactionHistoryStyles.passwordWrapper)}>
					<Input
						name="token"
						type="text"
						overrideClass={viewTransactionHistoryStyles.input}
						label={cmsContent[ cmsDataForInputLabel.key ] || cmsDataForInputLabel.fallbackValue}
						data-qa="tokenInput"
						error={formHelper.getFieldError('token')}
						required={true}
					/>
					 <TooltipCode {...{
						cmsContent,
						tooltipId: 'twoFactorAuthVerifyTooltip',
						labelCmsKey: cmsDataForInputLabel.key,
						labelPanelCmsKey: cms.twoFactorTooltip,
						labelFallbackValue: cmsDataForInputLabel.fallbackValue,
						labelPanelFallbackValue: `
								You should receive a unique code via the method you selected. If you do not receive this code,
								please confirm the selected method is accurate and try again.
							`,
					}} />
				</div>
			)
			: (
				<Input
					name="token"
					type="text"
					label={<>
						<CmsContentRenderer.Span contentKey={cmsDataForInputLabel.key} fallbackValue={cmsDataForInputLabel.fallbackValue} />
					</>}
					data-qa="tokenInput"
					error={formHelper.getFieldError('token')}
					required={true}
				/>
			);
};

const NewPassword = ({ formHelper, password, setPassword }) => {
	return (
		<div>
			<div className={loginStyles.row}>
				<CmsContentRenderer.H2
					data-qa="panelHeaderTitle"
					fallbackValue="Create a new Password:"
				/>
			</div>

			<div className={loginStyles.row}>
				<div>
					<div>
						<Input
							name="password"
							type="password"
							label="Password*"
							autoComplete="new-password"
							data-qa="newPassword"
							onChange={event => setPassword(event.target.value)}
							error={formHelper.getFieldError('password')}
							required={true}
						/>
					</div>
					<div>
						<Input
							name="confirm"
							type="password"
							label="Reenter Password*"
							autoComplete="new-password"
							data-qa="confirmPassword"
							error={formHelper.getFieldError('confirm')}
							required={true}
							aria-labelledby="passwordRequirements"
						/>
					</div>
				</div>
				<PasswordValidation password={password} />
			</div>
		</div>
	);
};

const VerificationCodePanel = ({ mode }) => {
	const isB2B = PublicAppVars.isB2BApi;

	const is2faMode = mode === MODES.TwoFactorAuthVerify;
	const isUnlockMode = mode === MODES.UnlockAccountTokenVerify;
	const isNewPasswordMode = mode === MODES.PasswordReset;

	const autoSubmitFormWithQueryParams = !isNewPasswordMode;
	const askForUsername = !is2faMode;
	const askForPassword = isNewPasswordMode;
	const showResend2faCodeButton = is2faMode;
	const showResendLockoutCodeButton = isUnlockMode;
	const showCancelButton = !is2faMode;
	const showReturnToLoginButton = isUnlockMode;

	const [ password, setPassword ] = useState("");
	const [ consumedQueryParams, setConsumedQueryParams ] = useState(false);
	const [ redirect, setRedirect ] = useState(null);
	const [ submitting, setSubmitting ] = useState(false);

	const { setToast, removeToast } = useGlobalToastsContext();
	const { syntheticTimerEvent } = useSessionCheckContext();
	const location = useLocation();
	const apolloClient = useApolloClient();

	const [ deliveryOptionsMutation ] = useStandardMutation(POST_TWO_FACTOR_DELIVERY_OPTIONS);

	const getQueryParams = () => {
		return consumedQueryParams
			? {}
			: parse(location.search, { ignoreQueryPrefix: true });
	};

	const stateUsername = location.state?.email;
	const wsAuthCodeDeliveryOption = location.state?.wsAuthCodeDeliveryOption;
	const isUsernameEncrypted = !Boolean(stateUsername);

	const queryParams = {
		username: stateUsername,
		...getQueryParams(),
	};

	const formRef = useRef(null);
	const [ validationState, setValidationState ] = useState({});
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(
		validationState,
		setValidationState,
		(fullSubmit) => getYupSchema(
			fullSubmit,
			queryParams,
			askForUsername,
			askForPassword,
		),
	);

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const submitRegisterVerify = async (validated) => {
		let response;
		try {
			response = await restClientMiddleware(ClientAjax.post("/ajax/verify-registration-token", validated));
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			setConsumedQueryParams(true);
			return;
		} finally {
			await syntheticTimerEvent();
		}

		if (get(response, 'data.success', false)) {
			// The account is now verified, but we don't have credentials to log them in.
			// Send them to the login screen and show a toast notification that their account has been verified.
			setRedirect(<Redirect push to={{
				pathname: getPathByRoute(isB2B ? groupAdminRouteKeys.SignIn : routeKeys.SignIn),
				state: { showAccountVerifiedNotification: true },
			}} />);
		} else if (get(response, 'data.activationEmailSuccess', false)) {
			alert('The link in the email you clicked is no longer valid. A new activation email is on its way. Please try again from the new email message.');
		}
	};

	const submitPasswordReset = async (validated) => {
		try {
			await restClientMiddleware(ClientAjax.post("/ajax/verify-password-token", validated));
		} catch (errorReport) {
			resetRecaptcha();
			// we're not redirecting anywhere. Prepare the form for the next submit.
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			setConsumedQueryParams(true);
			return;
		} finally {
			await syntheticTimerEvent();
		}

		const prefill = queryParams.username
			// the username they give us is encrypted, so we can't prefill
			? {}
			// we can prefill if use had to enter it
			: {
				autoSubmit: true,
				prefillEmail: validated.email,
				prefillPassword: validated.password,
			};

		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(isB2B ? groupAdminRouteKeys.SignIn : routeKeys.SignIn),
			state: {
				prefill,
			},
		}} />);
	};

	const submitUnlockAccountReset = async (validated) => {
		try {
			await restClientMiddleware(ClientAjax.post("/ajax/verify-unlock-token", validated));
		} catch (errorReport) {
			resetRecaptcha();
			// we're not redirecting anywhere. Prepare the form for the next submit.
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			setConsumedQueryParams(true);
			return;
		} finally {
			await syntheticTimerEvent();
		}

		const prefill = queryParams.username
			// the username they give us is encrypted, so we can't prefill
			? {}
			// we can prefill if use had to enter it
			: {
				autoSubmit: false,
				prefillEmail: validated.email,
			};

		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(isB2B ? groupAdminRouteKeys.SignIn : routeKeys.SignIn),
			state: {
				prefill,
			},
		}} />);
	};

	const submitTwoFactorAuthVerify = (verifyTwoFactorCodeMutation) => async (validated) => {
		try {
			const variables = {
				verificationToken: validated.token,
			};
			await graphqlErrorMiddleware(
				verifyTwoFactorCodeMutation({
					variables,
				}),
			);
		} catch (errorReport) {
			resetRecaptcha();
			// Account lockout
			// Too many failed attempts
			if (errorReport?.display?.topLevelMessage.includes('locked')) {
				setRedirect(<Redirect push to={{
					pathname: getPathByRoute(isB2B ? groupAdminRouteKeys.UnlockAccount : routeKeys.UnlockAccount),
				}} />);
				return;
			}

			// we're not redirecting anywhere. Prepare the form for the next submit.
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			setConsumedQueryParams(true);
			await syntheticTimerEvent();
			return;
		}

		// We're now logged in. Clear the Apollo cache so that we can pull in info for the new session.
		await apolloClient.resetStore().catch(error => { });

		setSubmitting(false);
		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(routeKeys.AccountCardSelection),
		}} />);
	};

	const kickoffSubmit = async (verifyTwoFactorCodeMutation) => {
		await checkRecaptcha();

		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(
				null,
				levels.verbose,
				errorReport,
				`VerificationCodePanel form validation`,
			);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent(`VerificationCodePanel submitting ${mode}`);

		// per conversation with Alessandro, we should send encrypted: true if username came from query param
		// https://reflexions.slack.com/archives/DBP03PA74/p1545244521011300
		if (validated.email && usernameIsEncrypted(queryParams)) {
			validated.encrypted = true;
		}

		const mapping = {
			[ MODES.PasswordReset ]: submitPasswordReset,
			[ MODES.RegisterVerify ]: submitRegisterVerify,
			[ MODES.UnlockAccountTokenVerify ]: submitUnlockAccountReset,
			[ MODES.TwoFactorAuthVerify ]: submitTwoFactorAuthVerify(verifyTwoFactorCodeMutation),
		};

		await mapping[ mode ](validated);
	};

	const onFormMount = () => {
		// only when askForNewPassword do we actually need input from the user if we got data in the query params
		if (autoSubmitFormWithQueryParams && queryParams.username && queryParams.token) {
			kickoffSubmit();
		}
	};

	const resendTwoFactorToken = async () => {
		try {

			const variables = { deliveryOption: wsAuthCodeDeliveryOption };
			await graphqlErrorMiddleware(
				deliveryOptionsMutation({
					variables,
				}),
			);

			setToast(<Toast
				type="success"
				title={<CmsContentRenderedInline
					contentKey={cms.twoFactorCtaConfirmation}
					fallbackValue="Unique verification code resent."
				/>}
				onClosed={removeToast}
			/>);
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
		}
	};

	const {
		token: initialToken,
		// username is encrypted, so if provided, we shouldn't show it
		username: initialEmail,
	} = queryParams;

	if (redirect) {
		// this is a <Redirect>
		return redirect;
	}

	const modeContentMap = {
		[ MODES.TwoFactorAuthVerify ]: {
			title: <CmsContentRenderer contentKey={cms.twoFactorHeader} />,
			description: <CmsContentRenderer.P contentKey={cms.twoFactorDescription} />,
			submit: <CmsContentRenderer contentKey={cms.twoFactorSubmit} fallbackValue="Submit" />,
		},
		[ MODES.UnlockAccountTokenVerify ]: {
			title: <CmsContentRenderer contentKey={cms.unlockHeader} />,
			description: <CmsContentRenderer.P contentKey={cms.unlockSubheader} />,
			submit: <CmsContentRenderer contentKey={cms.registerVerifySubmit} fallbackValue="Submit" />,
		},
	};

	const defaultContent = {
		title: !(initialEmail || initialToken) ? 'Verification Code' : null,
		description: null,
		submit: <CmsContentRenderer contentKey={cms.registerVerifySubmit} fallbackValue="Submit" />,
	};

	const modeConditionalContent = modeContentMap[ mode ] ?? defaultContent;

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Lifecycles didMount={onFormMount}>
				<TakeOverLayout
					title={modeConditionalContent.title}
					cancelLink={getPathByRoute(isB2B ? groupAdminRouteKeys.SignIn : routeKeys.SignIn)}
					showCancel={showCancelButton}
					data-qa="VerificationCodeTakeover"
				>
					<div className={cx(loginStyles.container, verificationCodePanelStyles.twoFactorContentWrapper)}>
						<StandardMutation mutation={VERIFY_TWO_FACTOR_CODE} showLoadingState={false}
							errorChildren={null}>{(verifyTwoFactorCodeMutation) => (
								<form
									className={loginStyles.registerForm}
									data-qa="verificationCodeForm"
									ref={formRef}
									onSubmit={PreventDefault(() => kickoffSubmit(verifyTwoFactorCodeMutation))}
								>
									{modeConditionalContent.description}

									{askForUsername ? <EmailInput {...{
										initialEmail,
										formHelper,
										label: cmsContent[ cms.registerVerifyEmailLabel ],
									}} /> : null}
									<TokenInput {...{ initialToken, formHelper, mode, cmsContent }} />
									{askForPassword ? <NewPassword {...{ formHelper, password, setPassword }} /> : null}
									{showResendLockoutCodeButton ? <LockoutResendButton {...{
										isUsernameEncrypted,
										parentFormHelper: formHelper,
										initialEmail,
									}} /> : null}

									<Recaptcha />

									{formHelper.getFieldErrorJsx('')}
									<div className={loginStyles.formActions}>
										{showReturnToLoginButton
											? (<Button
												to={getPathByRoute(isB2B ? groupAdminRouteKeys.SignIn : routeKeys.SignIn)}
												additionalClassNames={loginStyles.secondaryBtn}
												isPrimary={false}>
												<CmsContentRenderer.Span contentKey={cms.unlockSignIn}
													fallbackValue="Return to Log In" />
											</Button>)
											: null
										}
										<Button
											isPrimary={true}
											data-qa="SubmitVerifyTokenBtn"
											submitting={submitting}
										>
											{modeConditionalContent.submit}
										</Button>
									</div>
								</form>
							)}</StandardMutation>

						{showResend2faCodeButton && <div
							className={verificationCodePanelStyles.twoFactorSidebar}
							data-qa="TwoFactorSideNavContainer"
						>
							<CmsContentRenderer.P
								className={verificationCodePanelStyles.resendCodeText}
								contentKey={cms.twoFactorResendText}
								fallbackValue="Haven't received a message?"
								data-qa="SideNaveTextTwoFactor"
							/>
							<Button
								onClick={resendTwoFactorToken}
								type="button"
								typeStyle={buttonTypeStylePlain}
								data-qa="ResendCodeLinkTwoFactor"
							>
								<CmsContentRenderer.Span
									contentKey={cms.twoFactorResendCta}
									fallbackValue="Resend verification code"
								/>
							</Button>
						</div>}
					</div>
				</TakeOverLayout>
			</Lifecycles>
		)}</CmsContentList>
	);
};

VerificationCodePanel.propTypes = {
	mode: PropTypes.string.isRequired,
};

export default VerificationCodePanel;
