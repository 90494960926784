import React, {
	useState,
	useContext,
	useEffect,
} from 'react';
import {
	levels,
	log,
} from "utils/Logger.js";
import {
	concat,
	filter, isEqual,
} from 'lodash';
import PublicAppVars from "utils/PublicAppVars.js";
import { useLocation } from "react-router-dom";

export const TransferContext = React.createContext([]);
export const useTransferContext = () => useContext(TransferContext);

export const getTransferValue = (cart) => cart.transferValue ?? 0;
export const getTransferPasses = (cart) => cart.transferPasses ?? null;


const initialTransferCart = {
	transferValueOption: false,
	transferValue: '', // must be empty string to handle pre-populated field
	transferPasses: [], // [ WSSubsystemAccountPass ]
	targetCard: null, // WSSubsystemAccountInfo
	targetCustomerId: null,
};

export const checkoutFlowPathRegex = new RegExp("^/account/cards/\\d+/transfer-products.*$");
export const delinkFlowPathRegex = new RegExp("^/account/\\d+/delink-card");

const TransferProvider = ({
	children,
}) => {
	const location = useLocation();

	const [ validationState, setValidationState ] = useState({});
	const [ cart, setCart ] = useState(initialTransferCart);

	// clear the cart if the user leaves the checkout flow
	useEffect(
		() => {
			if (!location.pathname.match(checkoutFlowPathRegex) && !location.pathname.match(delinkFlowPathRegex)  && !isEqual(cart, initialTransferCart)) {
				// left checkout flow. Clear cart
				console.info("Left checkout flow. Clearing cart.");
				setCart(initialTransferCart);
			}
		},
		[
			location.pathname,
			cart,
		],
	);

	/**
	 * Add a pass to the cart based on wsSubsystemAccountPass
	 *
	 * @param {wsSubsystemAccountPass} pass
	 */
	const addPass = (pass) => {
		const filtered = filter(cart.transferPasses, wsSubsystemAccountPass => wsSubsystemAccountPass !== pass);

		setCart(prevCart => ({ ...prevCart, transferPasses: concat(filtered, pass) }));
	};

	/**
	 * Remove a pass from the cart based on wsSubsystemAccountPass
	 *
	 * @param {wsSubsystemAccountPass} pass
	 */
	const removePass = (pass) => {

		setCart(prevCart => ({
			...prevCart,
			transferPasses: filter(cart.transferPasses, wsSubsystemAccountPass => wsSubsystemAccountPass !== pass),
		}));
	};

	/**
	 * Update the value with a new amount, can overwrite the previous balance
	 *
	 * @param {integer} amount
	 * @param {boolean} overwrite
	 */
	const addValue = (amount, overwrite = true) => {

		if (typeof amount !== 'number') {
			log(null, levels.error, { message: "Amount was not a number", amount });
		}

		setCart(prevCart => ({
			...prevCart,
			transferValue: overwrite === true
				? amount
				: (prevCart.transferValue + amount),
		}));
	};

	/**
	 * Set the targetCard in the cart
	 *
	 * @param {string} subsystemAccountReference
	 * @param {string} nickname
	 * @param {number} targetCustomerId
	 */
	const setTargetCard = (subsystemAccountReference, nickname, targetCustomerId = null) => {

		setCart(prevCart => ({
			...prevCart,
			targetCard: {
				subsystemAccountReference,
				nickname,
			},
			targetCustomerId,
		}));
	};

	/**
	 * Set boolean value for transferValueOption
	 *
	 */
	const setTransferValueBool= (bool) => {
		setCart(prevCart => ({ ...prevCart, transferValueOption: bool }));
	};


	const resetTransfer = () => {
		setCart(initialTransferCart);
	};

	const contextValue = {
		cart,

		setTargetCard,

		setTransferValueBool,
		addValue,

		addPass,
		removePass,

		validationState,
		setValidationState,

		resetTransfer,
	};

	if (PublicAppVars.LOG_FUNDING_SOURCES_CONTEXT_CHANGES) {
		console.log(contextValue);
	}

	return (
		<TransferContext.Provider
			value={contextValue}
		>
			{children}
		</TransferContext.Provider>
	);
};

export default TransferProvider;
