import { clone } from "lodash";
import { castAmountDueToString } from "../../utils/FormatHelpers.js";

// 1.1.61 WSLoadProduct
// The WSLoadProduct is the base class for value and pass loads.

export default class WSLoadProduct {
	constructor({
		productCost,
		productLineItemType,
	}) {
		// int
		// (Required) Price of the product. This number is the number of
		// the base unit of currency, such as cents.
		// For “value” products, the value to be loaded onto the farecard
		// or into the account.
		// For “pass” products, the cost of the pass.
		this.productCost = productCost;

		// string(20)
		// (Required) Determines the type of load product line item.
		// Valid values:
		//  LoadOneAccountValue
		//  LoadSubsystemAccountValue
		//  LoadSubsystemProduct
		// (More will be added as we support other types of loads)
		this.productLineItemType = productLineItemType;
	}

	static fromBackoffice(data) {
		const result = new WSLoadProduct(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = forGraphql.productCost + forGraphql.productLineItemType;

		return forGraphql;
	}
}

export const WSLoadProductFragment = `
	productCost: Int!
	productLineItemType: String!
`;


export const WSLoadProductType = [ `
	type WSLoadProduct implements WSLoadProductFactory {
		id: ID!
		${WSLoadProductFragment}
	}
` ];
