// 2.6.18 Phone Types
const PhoneTypes = {
	Home: 'H',
	Fax: 'F',
	Mobile: 'M',
	Work: 'W',
	DayTime: 'D',
	Evening: 'E',
	Pager: 'P',
	Other: 'O',
};

export default PhoneTypes;
