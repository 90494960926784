export default class WSName {
	constructor({
		firstName,
		lastName,
		middleInitial,
		nameSuffix = null,
		title = null,
	}) {
		this.firstName = firstName;
		this.lastName = lastName;
		this.middleInitial = middleInitial;
		this.nameSuffix = nameSuffix;
		this.title = title;
	}

	toBackoffice() {
		return {
			firstName: this.firstName,
			lastName: this.lastName,
			middleInitial: this.middleInitial,
			nameSuffix: this.nameSuffix,
			title: this.title,
		};
	}

	static fromBackoffice(data) {
		const result = new WSName(data);
		return result;
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSName(data);
		return result;
	}

	toResolver() {
		return this;
	}

	set(field, value) {
		this[ field ] = value;
		return this;
	}
}

export const WSNameType = [ `
	type WSName {
		firstName: String
		lastName: String
		middleInitial: String
		nameSuffix: String
		title: String
	}
` ];

export const InputWSNameType = [ `
	input InputWSName {
		firstName: String
		lastName: String
		middleInitial: String
		nameSuffix: String
		title: String
	}
` ];
