import React, { useState } from 'react';
import {
	groupBy,
	sortBy,
	filter,
	map,
	find,
	get,
} from 'lodash';
import { Redirect } from 'react-router-dom';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { useTransitAccount } from "components/data/transit-account/TransitAccount.js";
import { Error as ComponentError } from 'components/data/Error.js';

import { getGroupedModes } from 'components/icons/TransitModes.js';
import { ComponentLoading } from 'components/icons/LoadingIcon.js';
import { WSAUTOLOAD_TYPES } from 'server/api-types/WSAutoloadFactory.js';
import { useModalContext } from "context/ModalProvider.js";

import ProductItem from 'components/account/ProductItem.js';
import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';

import * as plist from './ProductList.module.css';
import { useTransitAccountProductCatalog } from "components/data/transit-account/ProductCatalog.query.js";


const ProductList = () => {
	const [ redirect, setRedirect ] = useState(null);
	const transit_account_id = useTransitAccountIdContext();

	const {
		loading: transitAccountLoading,
		data: transitAccountQdata,
		error: transitAccountError,
	} = useTransitAccount({ subsystemAccountReference: transit_account_id, subscriptions: true });

	const {
		products: subsystemProducts,
		operators,
		loading: productCatalogLoading,
		error: productCatalogError,
	} = useGetSubsystemProductCatalog({ riderClassId: get(transitAccountQdata?.transitAccountQ, 'riderClass') });

	const {
		products: transitAccountProducts,
		loading: transitAccountCatalogLoading,
		errors: transitAccountCatalogError,
	} = useTransitAccountProductCatalog({ subsystemAccountReference: transit_account_id });

	const { setModal } = useModalContext();

	const onModalClose = () => {
		setModal(null);
	};

	if (transitAccountError || productCatalogError || transitAccountCatalogError) {
		return <ComponentError error={transitAccountError} />;
	}

	if (transitAccountLoading || productCatalogLoading || transitAccountCatalogLoading) {
		return <ComponentLoading />;
	}

	const handleEditAutoload = ({
		wsAutoloadSubscriptionInfo,
	}) => {
		setRedirect(<Redirect push to={{
			pathname: getPathByRoute(routeKeys.AutoloadPass, {
				transit_account_id,
				product_sku: wsAutoloadSubscriptionInfo.autoload.productSKU,
			}),
			state: { wsAutoloadSubscriptionInfo, updateAutoload: true },
		}} />);
	};

	const subscriptions = get(transitAccountQdata?.transitAccountQ, 'subscriptions');
	const autoLoadPasses = filter(subscriptions ?? [], wsAutoloadSummary => wsAutoloadSummary.autoload.type === WSAUTOLOAD_TYPES.purchaseSubsystemProduct);

	const groupedPasses = groupBy(transitAccountQdata?.transitAccountQ.passes, 'passSKU');

	if (redirect) {
		return redirect;
	}

	return (
		<div className={plist.container}>
			{map(groupedPasses, (wsSubsystemAccountPassGroup, passSku) => {
				const wsAccountProduct = find(subsystemProducts, { productSKU: passSku });
				const isInSubsystemCatalog = Boolean(wsAccountProduct);
				const isInTransitAccountCatalog = Boolean(find(transitAccountProducts, { productSku: passSku }));
				const groupedModes = (wsAccountProduct?.permittedUsers && wsAccountProduct.permittedUsers?.length)
					? getGroupedModes(wsAccountProduct, operators)
					: [];

				const wsAutoloadSummary = find(
					autoLoadPasses ?? [],
					wsAutoloadSummary => wsAutoloadSummary?.autoload?.productSKU === passSku
				);

				const sortedWsSubsystemAccountPassGroup = sortBy(
					wsSubsystemAccountPassGroup,
					[
						'startDateTime',
						'endDateTime',
						'createdDateTime',
					]
				);

				return (
					<div className={plist.product} key={`${passSku}`}>
						<ProductItem {...{
							groupedModes,
							wsSubsystemAccountPassGroup: sortedWsSubsystemAccountPassGroup,
							wsAutoloadSummary,
							onModalClose,
							setModal,
							handleEditAutoload,
							isInSubsystemCatalog,
							isInTransitAccountCatalog,
						}} />
					</div>
				);
			})}
		</div>
	);
};

export default ProductList;
