import React, { useEffect, useState } from 'react';
import cx from "classnames";
import {
	map,
	values,
} from "lodash";
import {
	parse as qs_parse,
	stringify as qs_stringify,
} from 'qs';
import { useHistory } from 'react-router-dom';

import { SocialMediaIcon } from 'components/Icon.js';
import Toggle from 'components/forms/Toggle.js';
import StandardMutation from "components/data/StandardMutation.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import { redirectToExpress } from "App.js";
import SocialResetPassword from "pages/auth/SocialResetPassword.js";
import { useModalContext } from 'context/ModalProvider.js';
import CmsContentList from "components/data/CmsContentList.js";
import { object as yup_object } from "yup";
import PublicAppVars from "utils/PublicAppVars.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import Toast from "components/Toast.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import FormHelper from "utils/FormHelper.js";
import PreventDefault from "utils/PreventDefault.js";
import { ComponentLoading } from "components/icons/LoadingIcon.js";
import ConfirmDelinkModal from "components/modals/ConfirmDelink.js";
import {
	SOCIAL_SIGNIN_OPTIONS,
	UPDATE_SOCIAL_SIGNIN_OPTIONS,
	useSocialSigninOptions,
} from "components/data/SocialSignIn.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import {
	SSO_ACTION_LINK,
	SSO_PROVIDERS,
} from "utils/constants/Sso.js";

import * as panel from 'components/Panel.module.css';



export const titleCaseStr = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

const cms = {
	confirmDelinkToast: "miscText.security-login-disable-confirmation",
	successLinkToast: "miscText.security-login-confirmation-social",
	successDelinkToast: "miscText.security-login-confirmation-email",
	uncheckedText: 'miscText.general-toggle-off',
	checkedText: 'miscText.general-toggle-on',
};

const getYupSchema = () => {
	return yup_object().shape({});
};

const useSocialCallbackQueryParams = () => {
	const history = useHistory();
	const { location: { search } } = history;
	const queryParams = qs_parse(search, { ignoreQueryPrefix: true });
	const [ consumedQueryParams, setConsumedQueryParams ] = useState(queryParams);

	// Example:
	// https://fe.develop.bostonrcubic.com/account/settings/security?ssoLinkSuccess=true&ssoLinkErrorMessage=&socialMediaType=GOOGLE
	// https://fe.develop.bostonrcubic.com/account/settings/security?ssoLinkSuccess=false&ssoLinkErrorMessage=There%20was%20a%20really%20bad%20error%20here!&socialMediaType=GOOGLE

	useEffect(() => {
		if (queryParams?.ssoLinkSuccess) {

			// remove relevant query params from our url now that we've consumed them
			const {
				socialMediaType,
				ssoLinkErrorMessage,
				ssoLinkSuccess,
				...strippedQueryParams
			} = queryParams;

			history.replace({
				...history.location,
				search: qs_stringify(strippedQueryParams),
			});
		}
	}, [ queryParams, history ]);

	return {
		consumedQueryParams,
		setConsumedQueryParams,
	};
};

const LinkSuccessToast = ({ socialMediaType, onClosed }) => (
	<CmsContentList list={[ cms.successLinkToast ]} sharedVariables={{ social: titleCaseStr(socialMediaType) }}>{() => (
		<Toast
			type="success"
			onClosed={onClosed}
			title={<CmsContentRenderer
				contentKey={cms.successLinkToast}
				fallbackValue={`You have successfully enabled ${titleCaseStr(socialMediaType)} login for this account.`}
			/>}
		/>
	)}</CmsContentList>
);

const SsoToggles = () => {
	const { setModal } = useModalContext();
	const { setToast, removeToast } = useGlobalToastsContext();

	const {
		formRef,
		formHelper,
		setValidationState,
	} = useFormHelper({ getYupSchema });

	const {
		loading: signInOptionsLoading,
		socialSigninOptions,
		isSystemGenPassword,
	} = useSocialSigninOptions();

	const [ toggleEnabledState, setToggleEnabledState ] = useState({});

	useEffect(() => {
		if (!signInOptionsLoading) {
			setToggleEnabledState(socialSigninOptions.reduce((res, { name }) => ({
				[ name ]: true,
				...res,
			}), {}));
		}
	}, [ socialSigninOptions, signInOptionsLoading ]);

	const {
		consumedQueryParams: {
			ssoLinkSuccess,
			socialMediaType,
			ssoLinkErrorMessage,
		},
		setConsumedQueryParams,
	} = useSocialCallbackQueryParams();

	useEffect(() => {
		// Happy path - display the link success toast notification
		if (ssoLinkSuccess === 'true') {
			setToast(<LinkSuccessToast {...{ socialMediaType, onClosed: removeToast }} />);
		}
		// There was an error - display it next to the SSO toggles
		else if (ssoLinkErrorMessage) {
			setValidationState({
				[ FormHelper.getStateErrorField('') ]: ssoLinkErrorMessage,
			});
		}
		// We're all done with the query params now.
		setConsumedQueryParams({});
	}, [
		setToast,
		ssoLinkSuccess,
		socialMediaType,
		ssoLinkErrorMessage,
		setValidationState,
		setConsumedQueryParams,
		removeToast,
	]);

	const submitDelink = async (delinkCallback, linkingKey, newPassword = null) => {
		await graphqlErrorMiddleware(delinkCallback({
			variables: {
				linkingKey,
				newPassword,
			},
		}));
	};

	const submitLink = (linkingKey) => {
		const onSuccessRedirect = '/account/settings/security';
		const url = '/login/social/redirect-to-provider?'
			+ qs_stringify({
				linkingKey,
				onSuccessRedirect,
				action: SSO_ACTION_LINK,
			});
		redirectToExpress(url);
	};

	const onToggleSSO = ({
		event,
		delinkCallback,
		linkingKey,
		formHelper,
	}) => {
		// set the value of checkbox input to state
		const {
			name: socialMediaType,
			checked: enabled,
		} = event.target;

		// Link
		if (enabled) {
			submitLink(linkingKey);
		}
		// Delink
		else {
			// If the password is system generated open the reset password modal.
			// The modal will take care of delinking as well
			//
			// Note: force setting password should only occur if ALL SSO toggles have been disabled at this point
			if (socialSigninOptions.length === 1 && isSystemGenPassword) {
				setModal(
					<SocialResetPassword
						socialMediaType={socialMediaType}
						onSubmitDelink={async (newPassword) => {
							// When the user does not have a manually set password
							// the delinking is triggered in <SocialResetPassword/>
							// after having reset a password.
							// Delink mutation Callback function lives on this component
							// Send onSubmitDelink() as a prop to <SocialResetPassword/>
							// and have it trigger delinking after password is reset.

							// Let error bubble up to be handled in <SocialResetPassword/>'s own try-catch.
							await submitDelink(delinkCallback, linkingKey, newPassword);

							setModal(null);

							setToggleEnabledState(prevState => ({
								...prevState,
								[ linkingKey ]: false,
							}));

							setToast(<Toast
								type="success"
								onClosed={removeToast}
								title={<CmsContentRenderer
									contentKey={cms.successDelinkToast}
									fallbackValue="You have successfully disabled Social Media login for this account, and will now login using your email and password."
								/>}
							/>);
						}}
					/>,
				);
			} else {
				setModal(<ConfirmDelinkModal
					socialMediaType={socialMediaType}
					onModalClose={() => setModal(null)}
					onSubmitDelink={async () => {
						try {
							await submitDelink(delinkCallback, linkingKey);
							setToggleEnabledState(prevState => ({
								...prevState,
								[ linkingKey ]: false,
							}));
						}
						catch (errorReport) {
							if (errorReport.TopLevelKey) {
								noticeError(null, levels.error, errorReport, `Delink SocialAccount failed`);
								formHelper.validationErrorHandler(errorReport);
							}
						}
						finally {
							setModal(null);
						}
					}}
				/>);
			}
		}
	};

	const topLevelError = formHelper.getFieldErrorJsx('');
	const styleMap = {};

	if (PublicAppVars.GOOGLE_OAUTH_ENABLED) {
		styleMap[ SSO_PROVIDERS.GOOGLE.key ]  = panel.googleIcon;
	}

	if (PublicAppVars.FACEBOOK_OAUTH_ENABLED) {
		styleMap[ SSO_PROVIDERS.FACEBOOK.key ] = panel.facebookIcon;
	}

	if (PublicAppVars.APPLE_OAUTH_ENABLED) {
		styleMap[ SSO_PROVIDERS.APPLE.key ] = panel.appleIcon;
	}

	return (
		<CmsContentList list={values(cms)}>{() => (
			<form
				method="post"
				data-qa="SocialMediaLinkForm"
				ref={formRef}
				className={panel.block}
			>
				{topLevelError && <>{topLevelError}<br /></>}

				{signInOptionsLoading ? <ComponentLoading /> : map(SSO_PROVIDERS, (ssoProvider) => (
					<div className={panel.row} key={ssoProvider.key}>
						<div className={panel.socialBtn}>
							<SocialMediaIcon
								socialMediaType={ssoProvider.key}
								childprops={{ overrideClass: cx(panel.socialBtnIcon, styleMap[ ssoProvider.key ]) }}
							/>
							<p className={panel.socialBtn}>{ssoProvider.display}</p>
						</div>
						<StandardMutation
							refetchQueries={[ { query: SOCIAL_SIGNIN_OPTIONS } ]}
							mutation={UPDATE_SOCIAL_SIGNIN_OPTIONS}
							errorChildren={null}
							showLoadingState={false}
						>
							{(delinkCallback) => {
								// look for e.g. FACEBOOK or FACEBOOK_AT (the latter is used by the mobile app)
								// when delinking, we have to send the same value that we get here
								const foundKey = socialSigninOptions.find(element => ssoProvider.allKeys.includes(element.name));
								// if we didn't get anything back, use our key
								const computedKey = foundKey ? foundKey.name : ssoProvider.key;

								return (
									<span className={panel.section}>
										<Toggle
											onChange={PreventDefault((event) => onToggleSSO({
												event,
												delinkCallback,
												linkingKey: computedKey,
												formHelper,
											}))}
											defaultChecked={Boolean(foundKey)}
											name={ssoProvider.key}
											error={formHelper.getFieldError(ssoProvider.key)}
											checked={toggleEnabledState[ ssoProvider.key ] ?? false}
										/>
									</span>
								);
							}}
						</StandardMutation>
					</div>
				))}
			</form>
		)}</CmsContentList>
	);
};

SsoToggles.propTypes = {};

export default SsoToggles;
