import { gql } from "@apollo/client";
import React, {
	useState,
} from 'react';
import {
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
} from 'yup';
import cx from 'classnames';
import {
	cloneDeep,
	get,
	isEqual,
	values,
} from 'lodash';

import { addYupMethod } from 'utils/YupValidators.js';
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import StdQuery from 'components/data/StdQuery.js';
import StandardMutation from 'components/data/StandardMutation.js';
import {
	GET_EMAIL,
	GET_FULLNAME,
	GET_PHONE,
} from 'components/data/session-user/SessionUser.js';
import { levels, noticeError } from 'utils/Logger.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import PreventDefault from "utils/PreventDefault.js";
import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import CmsContentList from 'components/data/CmsContentList';
import { useGlobalToastsContext } from "context/ToastProvider";
import useFormHelper from "utils/form-helper/useFormHelper";
import WSPhone, { WSPhoneOps } from "server/api-types/WSPhone";
import ErrorReport from "utils/error-handling/ErrorReport";

import Button, { Secondary } from 'components/Button.js';
import Input from 'components/forms/Input.js';
import Modal from 'components/Modal.js';
import Select from 'components/forms/inputs/Select.js';
import Phone from 'components/forms/Phone.js';
import Toast from "components/Toast";
import { BO_ERRORS, COLLECTIONS, getErrorKey } from "utils/GetErrorKey.js";
import { WireFormHelper } from "utils/FormHelper.js";

import 'react-phone-number-input/style.css';
import * as modalStyles from 'components/Modal.module.css';
import * as formStyles from 'components/forms/Forms.module.css';
import * as personalInfoStyles from 'pages/auth/PersonalInformation.module.css';
import * as inputStyles from "components/forms/Input.module.css";

import {
	GET_NOTIFICATION_PREFERENCES,
} from "components/data/notification-preferences/NotificationPreferences.query.js";



const cms = {
	header: "miscText.personal-info-edit-header",
	firstNameLabel: 'miscText["personal-info-edit-first.label"]',
	midNameLabel: 'miscText["personal-info-edit-mid.label"]',
	lastNameLabel: 'miscText["personal-info-edit-last.label"]',
	phoneOneLabel: 'miscText["personal-info-edit-phone1.label"]',
	phoneOneTypeLabel: 'miscText["personal-info-edit-phone1type.label"]',
	phoneTwoLabel: 'miscText["personal-info-edit-phone2.label"]',
	phoneTwoTypeLabel: 'miscText["personal-info-edit-phone2type.label"]',
	phoneThreeLabel: 'miscText["personal-info-edit-phone3.label"]',
	phoneThreeTypeLabel: 'miscText["personal-info-edit-phone3type.label"]',
	phoneNumberAriaLabel: 'miscText["register-info-phonetype.number"]',
	phoneCountryAriaLabel: 'miscText["register-info-phonetype.country"]',
	infoSmsNotifications: "miscText.personal-info-edit-phone-smsnotifications",
	submit: "miscText.personal-info-edit-submit",
	cancel: "miscText.personal-info-edit-cancel",
	successConfirmation: "miscText.personal-info-edit-confirmation",
	generalOptional: "miscText.general-optional-field",

	homePhone: "miscText.register-info-phonetype-home",
	officePhone: "miscText.register-info-phonetype-office",
	mobilePhone: "miscText.register-info-phonetype-mobile",
};

addYupMethod('validPhoneNumber');

let additionalValidations = {};

const PERSONAL_INFORMATION_QUERY = gql`
	query EditInformationQuery {
		session {
			customer {
				contact {
					name {
						firstName
						lastName
						middleInitial
					}
					email
					phone {
						number
						country
						type
					}
					id
				}
			}
		}
	}
`;

const UPDATE_PROFILE = gql`
	mutation updateProfile(
		$firstName: String!
		$lastName: String!
		$middleInitial: String
		$phones: [ InputWSPhone ]
	) {
		updateProfile(
			firstName: $firstName
			lastName: $lastName
			middleInitial: $middleInitial
			phones: $phones
		) {
			contact {
				name {
					firstName
					lastName
					middleInitial
				}
				email
				phone {
					number
					country
					type
				}
			}
		}
	}
`;

const getYupSchema = () => {
	const validations = {
		firstName: yup_string()
			.required("Please enter your first name.")
			.trim(),
		middleInitial: yup_string()
			.max(1, getErrorKey(COLLECTIONS.registration, 'contact.name.middleInitial', BO_ERRORS.general.tooLong))
			.matches(/^[A-Za-z]*$/, "Middle initial must contain only letters") // @todo: add translations
			.nullable(),
		lastName: yup_string()
			.required("Please enter your last name.")
			.trim(),
		phone0Number: yup_string()
			.required("Please enter a phone number")
			.validPhoneNumber(
				yup_ref("phone0Country"),
				"Please enter a valid phone number.",
			)
			.trim(),
		phone0Type: yup_string()
			.required("Please select a phone type")
			.trim(),
		...additionalValidations,
	};

	return yup_object().shape(validations);
};

/*
* Validation rules will vary for the phone number inputs depending on if the field is empty.
* This logic will dynamically add additional validation rules `onChange` of the input.
*/
const handleAdditionalPhoneValidations = ({ originalNumber, key, newNumber, smsNotification }) => {

	if (!key.includes('Number')) {
		return;
	}

	const isValueEmpty = !newNumber.trim();
	const re = new RegExp("\\" + smsNotification);

	const smsPhoneRequiredRule = yup_string().matches(
		re,
		'miscHtml.personal-info-edit-error-notifications',
	);
	const validPhoneRequiredRule = yup_string()
		.validPhoneNumber(
			yup_ref(key.replace('Number', 'Country')),
			"Please enter a valid phone number.",
		)
		.trim();
	const typeRequiredRule = yup_string()
		.required("Please select a phone type")
		.trim();

	// a phone type is always needed
	additionalValidations[ key.replace('Number', 'Type') ] = typeRequiredRule;

	// phones that are not being deleted must always be a valid phone number
	additionalValidations[ key ] = !isValueEmpty ? validPhoneRequiredRule : null;

	// cannot delete the SMS notification phone number
	if (originalNumber === smsNotification && isValueEmpty) {
		additionalValidations[ key ] = smsPhoneRequiredRule;
	}
};

const EditPersonalInformation = ({
	onModalClose = () => { },
	smsNotification = null,
}) => {
	const [ submitting, setSubmitting ] = useState(false);

	const { setToast, removeToast } = useGlobalToastsContext();
	const { formHelper, formRef } = useFormHelper({ getYupSchema });

	const updatePersonalInfoSuccess = (cmsContent) => {
		setToast(<Toast
			type="success"
			title={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cms.successConfirmation}
				fallbackValue="Your information has been updated."
			/>}
			onClosed={removeToast}
		/>);
	};

	const generatePhoneOperationsForRequest = (prevValues, currentValues) => {
		const phoneOperations = [];
		for (const key of [ 'phone0', 'phone1', 'phone2' ]) {
			const prevPhone = new WSPhone({
				number: prevValues[ `${key}Number` ],
				type: prevValues[ `${key}Type` ],
				country: prevValues[ `${key}Country` ],
			});
			const currentPhone = new WSPhone({
				number: currentValues[ `${key}Number` ],
				type: currentValues[ `${key}Type` ],
				country: currentValues[ `${key}Country` ],
			});
			if (JSON.stringify(prevPhone) !== JSON.stringify(currentPhone)) {
				if (!currentPhone.number) {
					prevPhone.op = WSPhoneOps.Remove;
					phoneOperations.push(prevPhone);
				} else if (!prevPhone.number && !prevPhone.type) {
					currentPhone.op = WSPhoneOps.Add;
					phoneOperations.push(currentPhone);
				} else {
					currentPhone.op = WSPhoneOps.Replace;
					currentPhone.oldPhone = prevPhone.number;
					currentPhone.oldType = prevPhone.type;
					phoneOperations.push(currentPhone);
				}
			}
		}
		return phoneOperations;
	};

	const haveInputsUpdated = (sessData) => {
		const currentValues = formHelper.getDataToValidate();
		const prevValues = sessData;
		return !isEqual(prevValues, currentValues);
	};

	const arePhoneNumbersUnique = (formData) => {
		const map = {};
		for (const key of [ 'phone0', 'phone1', 'phone2' ]) {
			const id = `${formData[ `${key}Number` ]}_${formData[ `${key}Type` ]}`;

			if (!(id in map) || isEqual(id.trim(), '_')) {
				map[ id ] = null;
			} else {
				return false;
			}
		}
		return true;
	};

	const kickoffSubmit = async (callback, sessData, cmsContent) => {
		setSubmitting(true);
		formHelper.clearAllErrors();

		let validated;
		try {
			validated = await formHelper.startValidation(true);
			formHelper.clearAllErrors();
		} catch (errorReport) {
			// when yup validation fails, errors are stored in the state
			setSubmitting(false);
			additionalValidations = {};
			noticeError(
				null,
				levels.verbose,
				errorReport,
				'Update Personal Info Form Validation',
			);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		additionalValidations = {};

		// at least one phone number is required
		if (!validated.phone0Number && !validated.phone1Number && !validated.phone2Number) {
			formHelper.validationErrorHandler(ErrorReport.newFromObj({
				TopLevelKey: 'miscHtml.personal-info-edit-error-nophone',
			}));
			setSubmitting(false);
			return;
		}


		// all phone numbers should be unique
		if (!arePhoneNumbersUnique(validated)) {
			formHelper.validationErrorHandler(ErrorReport.newFromObj({
				TopLevelKey: 'miscHtml.personal-info-edit-error-duplicate-phone',
			}));
			setSubmitting(false);
			return;
		}

		const phoneOperations = generatePhoneOperationsForRequest(sessData, validated);

		if (!haveInputsUpdated(sessData)) {
			updatePersonalInfoSuccess(cmsContent);
			onModalClose();
			return;
		}

		GoogleAnalytics.logEvent(
			'User is attempting to update account information',
		);

		try {
			await graphqlErrorMiddleware(
				callback({
					variables: {
						...validated,
						...(phoneOperations.length > 0 && { phones: phoneOperations }),
					},
				}),
			);
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		updatePersonalInfoSuccess(cmsContent);
		onModalClose();
	};

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Modal
				title={<CmsContentRenderer
					contentKey={cms.header}
					fallbackValue="Edit Personal Information"
				/>}
				overrideClass={personalInfoStyles.modalOverride}
			>
				<StdQuery query={PERSONAL_INFORMATION_QUERY}>
					{({ data }) => {
						const sessCustomer = data.session.customer;
						const clonedSessContact = cloneDeep(sessCustomer?.contact);
						const sessData = {
							firstName: get(clonedSessContact, 'name.firstName'),
							middleInitial: get(clonedSessContact, 'name.middleInitial'),
							lastName: get(clonedSessContact, 'name.lastName'),

							phone0Number: get(clonedSessContact, 'phone.0.number', ''),
							phone0Country: get(clonedSessContact, 'phone.0.country', 'US'),
							phone0Type: get(clonedSessContact, 'phone.0.type', ''),

							phone1Number: get(clonedSessContact, 'phone.1.number', ''),
							phone1Country: get(clonedSessContact, 'phone.1.country', 'US'),
							phone1Type: get(clonedSessContact, 'phone.1.type', ''),

							phone2Number: get(clonedSessContact, 'phone.2.number', ''),
							phone2Country: get(clonedSessContact, 'phone.2.country', 'US'),
							phone2Type: get(clonedSessContact, 'phone.2.type', ''),
						};

						return <StandardMutation
							mutation={UPDATE_PROFILE}
							refetchQueries={[
								{
									query: GET_NOTIFICATION_PREFERENCES,
								},
								{ query: GET_FULLNAME },
								{ query: GET_EMAIL },
								{ query: GET_PHONE },
								{ query: PERSONAL_INFORMATION_QUERY },
							]}
							errorChildren={null}
						>
							{(updateProfile) =>
								<WireFormHelper formHelper={formHelper}>
									<form
										data-qa="EditPersonalForm"
										ref={formRef}
										onSubmit={PreventDefault(() => kickoffSubmit(updateProfile, sessData, cmsContent))}
									>
										<div className={modalStyles.row}>
											<Input
												type="text"
												label={<CmsContentRenderer.Span contentKey={cms.firstNameLabel}
													fallbackValue="First Name" />}
												name="firstName"
												error={formHelper.getFieldError('firstName')}
												placeholder="First Name"
												defaultValue={sessData.firstName}
												data-qa="EditInfoModalFirstNameInput"
												overrideClass={personalInfoStyles.firstInput}
												labelClass={formStyles.label}
												required={true}
											/>
											<Input
												type="text"
												label={<CmsContentRenderer.Span contentKey={cms.midNameLabel}
													fallbackValue="Middle Initial" />}
												name="middleInitial"
												error={formHelper.getFieldError('middleInitial')}
												placeholder={cmsContent[ cms.midNameLabel ]}
												data-qa="EditInfoModalMiddleInitInput"
												defaultValue={sessData.middleInitial}
												overrideClass={personalInfoStyles.secondInput}
												labelClass={formStyles.label}
												required={false}
												maxLength={1}
											/>
										</div>
										<div>
											<Input
												type="text"
												name="lastName"
												label={<CmsContentRenderer.Span contentKey={cms.lastNameLabel}
													fallbackValue="Last Name" />}
												error={formHelper.getFieldError('lastName')}
												placeholder="Last Name"
												data-qa="EditInfoModalLastNameInput"
												defaultValue={sessData.lastName}
												className={cx(personalInfoStyles.phoneTypeLabel, personalInfoStyles.noMargin)}
												required={true}
											/>
										</div>
										<div>
											<PhoneInputs
												sessData={sessData}
												formHelper={formHelper}
												smsNotification={smsNotification}
												handleAdditionalPhoneValidations={handleAdditionalPhoneValidations}
												cmsContent={cmsContent}
											/>
											{formHelper.getFieldErrorJsx('')}
											<div className={cx(modalStyles.actions, personalInfoStyles.buttons)}>
												<Button
													text={<CmsContentRenderer.Span
														contentKey={cms.cancel}
														fallbackValue="Cancel"
													/>}
													type={Secondary}
													aria-label="Cancel button"
													data-qa="EditPersonalInfoCancelButton"
													overrideClass={
														cx(modalStyles.btn, personalInfoStyles.cancelButton, modalStyles.leftButton)
													}
													submitting={submitting}
													onClick={PreventDefault(() => {
														formHelper.clearAllErrors();
														onModalClose();
													})}
												/>
												<Button
													text={<CmsContentRenderer.Span
														contentKey={cms.submit}
														fallbackValue="Save Changes"
													/>}
													overrideClass={
														cx(modalStyles.btn, personalInfoStyles.saveButton, modalStyles.leftButton)
													}
													data-qa="EditPersonalInfoSaveBtn"
													aria-label="Save Changes button"
													submitting={submitting}
												/>
											</div>
										</div>
									</form>
								</WireFormHelper>
							}
						</StandardMutation>;
					}}
				</StdQuery>
			</Modal>
		)}</CmsContentList>
	);
};

const PhoneInputs = ({
	sessData,
	formHelper,
	smsNotification,
	handleAdditionalPhoneValidations,
	cmsContent,
}) => {
	const phoneInputMap = [
		{ dataKey: "phone0", contentKey: 'phoneOne', isRequired: true },
		{ dataKey: "phone1", contentKey: 'phoneTwo', isRequired: false },
		{ dataKey: "phone2", contentKey: 'phoneThree', isRequired: false },
	];
	return phoneInputMap.map(({ dataKey, contentKey, isRequired }, i) => {
		const originalNumber = sessData[ `${dataKey}Number` ];
		const showSmsLabel = originalNumber === smsNotification;

		return (
			<div key={dataKey} className={cx(formStyles.phoneNumberTypeContainer, modalStyles.row)}>
				<div className={cx(personalInfoStyles.phone, personalInfoStyles.firstInput)}>
					<Phone
						id={dataKey}
						name={`${dataKey}Number`}
						countryName={`${dataKey}Country`}
						legend={
							<PhoneNumberLabel
								numberOrType={"number"}
								showSmsLabel={showSmsLabel}
							>
								<div className={showSmsLabel ? null : personalInfoStyles.phoneLabelBottomMargin }>
									<CmsContentRenderer.Span
										className={personalInfoStyles.phoneLabelMargin}
										contentKey={cms[ `${contentKey}Label` ]}
										fallbackValue={`Phone ${i + 1}`}
									/>
									{!isRequired ?
										<span className={personalInfoStyles.optionalLabel}>
											{"("}
											<CmsContentRenderer.Span
												className={personalInfoStyles.phoneLabelMargin}
												contentKey={cms.generalOptional}
												fallbackValue="(Optional)" />
											{")"}
										</span>
										: ""
									}
								</div>
							</PhoneNumberLabel>
						}
						legendProps={{
							className: inputStyles.label,
						}}
						formHelper={formHelper}
						defaultCountry={sessData[ `${dataKey}Country` ]}
						defaultNumber={sessData[ `${dataKey}Number` ]}
						onNumberChanged={(newNumber) => handleAdditionalPhoneValidations({
							key: `${dataKey}Number`,
							originalNumber,
							newNumber,
							smsNotification,
						})}
						limitMaxLength
						phoneNumberAriaLabel={findContentOrFallback(cmsContent, cms.phoneNumberAriaLabel, 'phone number')}
						phoneCountryAriaLabel={findContentOrFallback(cmsContent, cms.phoneCountryAriaLabel, 'phone number country')}
						data-qa={`PhoneInput${i}`}
						className={formStyles.phoneInput}
						displayInitialValueAsLocalNumber={false}
					/>
				</div>
				<div className={personalInfoStyles.phoneTypeContainer}>
					<Select
						name={`${dataKey}Type`}
						defaultValue={sessData[ `${dataKey}Type` ]}
						label={<PhoneNumberLabel
							htmlFor={`${dataKey}Type-selector`}
							labelKey={cms[ `${contentKey}TypeLabel` ]}
							showSmsLabel={showSmsLabel}
							labelFallback={`Phone ${i + 1} Type`}
							numberOrType={'type'}
						/>}
						options={[
							{ value: "M", label: cmsContent[ cms.mobilePhone ] },
							{ value: "H", label: cmsContent[ cms.homePhone ] },
							{ value: "W", label: cmsContent[ cms.officePhone ] },
						]}
						data-qa={!isRequired ? `OptionalPhoneTypeDropDown${i}` : "PhoneTypeDropDown"}
						searchable={false}
						error={formHelper.getFieldError(`${dataKey}Type`)}
						overrideClass={personalInfoStyles.phoneTypeInput}
						reactSelectClassName={personalInfoStyles.selectField}
					/>
				</div>
			</div>
		);
	});
};

const PhoneNumberLabel = ({
	showSmsLabel,
	labelKey,
	labelFallback,
	numberOrType,
	children,
}) => (
	<>
		<div className={numberOrType === "number"
			? personalInfoStyles.phoneNumberLabelContainer
			: personalInfoStyles.phoneTypeLabelContainer}
		>
			{children ? children :
				<CmsContentRenderer.Span
					contentKey={labelKey}
					fallbackValue={labelFallback}
					className={numberOrType === "number" ? personalInfoStyles.phoneSmsLabel : personalInfoStyles.phoneTypeLabel}
				/>
			}

		</div>
		{children && showSmsLabel ?
			<CmsContentRenderer.Div
				className={personalInfoStyles.phoneSmsLabel}
				contentKey={cms.infoSmsNotifications}
				fallbackValue="This number will recieve SMS notifications"
			/>
			: null
		}
	</>
);

export default EditPersonalInformation;
