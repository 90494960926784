export default class WSIssueMediaLoadProduct {
	constructor({
		loadType,
		itemPrice,
		itemPriceOriginalAmount,
		itemPriceDiscountAmount,
	}) {

		/**
		 * @type string(20)
		 * (Required) Determines the type of load product line item. Valid values:
		 *  LoadTransitAccountValue – Deprecated, use LoadSubsystemAccountValue
		 *  LoadTransitAccountPass – Deprecated, use LoadSubsystemProduct
		 *  LoadSubsystemAccountValue
		 *  LoadSubsystemProduct
		 */
		this.loadType = loadType;

		/**
		 * @type int
		 * (Required) Price of the product in cents.
		 */
		this.itemPrice = itemPrice;

		/**
		 * @type int
		 * (Optional) Price of the product in cents before applying any discounts.
		 */
		this.itemPriceOriginalAmount = itemPriceOriginalAmount;

		/**
		 * @type int
		 * (Optional) Discount amount on the product in cents.
		 */
		this.itemPriceDiscountAmount = itemPriceDiscountAmount;
	}

	static fromBackoffice(data) {
		const result = new WSIssueMediaLoadProduct(data);
		return result;
	}

	static fromSerialized(data) {
		const result = new WSIssueMediaLoadProduct(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

export const WSIssueMediaLoadProductFragment = `
		loadType: String!
		itemPrice: Int!
		itemPriceOriginalAmount: Int
		itemPriceDiscountAmount: Int
`;

export const InputWSIssueMediaLoadProductType = [ `
	input InputWSIssueMediaLoadProduct {
		${WSIssueMediaLoadProductFragment}
	}
` ];

export const WSIssueMediaLoadProductType = [ `
	type WSIssueMediaLoadProduct {
		${WSIssueMediaLoadProductFragment}
	}
` ];
