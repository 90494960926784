// it gets set once per request
import { v4 as uuidv4 } from "uuid";

const req_field = 'txn_uuid';

export const set_txn_uuid = (req, new_nonce) => {
	req[ req_field ] = new_nonce;
};

export const get_txn_uuid = (req) => req[ req_field ];

export const new_txn_uuid = uuidv4;
