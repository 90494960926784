import { gql } from '@apollo/client';
import React from 'react';
import StdQuery from 'components/data/StdQuery.js';

export const GET_POINT_OF_SALE_LOCATIONS = gql`
	query GetPointOfSaleLocationsQ (
		$deviceId: String
		$longitude: String
		$latitude: String
		$address: String
		$zipcode: String
		$radius: Int
		$units: String
		$isCashPaymentNetwork: Boolean
	) {
		DeviceRoute {
			id
			getPointOfSaleLocations (
				deviceId: $deviceId
				longitude: $longitude
				latitude: $latitude
				address: $address
				zipcode: $zipcode
				radius: $radius
				units: $units
				isCashPaymentNetwork: $isCashPaymentNetwork
			) {
				pointOfSaleLocations {
					id
					distanceFromCoordinates
					unitsOfMeasure
					name
					deviceType
					address {
						address1
						city
						country
						postalCode
						state
					}
					operationSchedule {
						startTime
						endTime
						dayOfOperation
					}
					longitude
					latitude
					isADAAccessible
					isOpenAllHours
					sellPass
					sellMedia
				}
			}
		}
	}`;

const PointOfSaleLocations = ({ children, ...rest }) => (
	<StdQuery
		query={GET_POINT_OF_SALE_LOCATIONS}
		loadingSpinner={false}
		{...rest}
	>
		{(apolloResponse) => {
			const { pointOfSaleLocations } = apolloResponse.data?.DeviceRoute.getPointOfSaleLocations ?? {};
			return children(pointOfSaleLocations, apolloResponse);
		}}
	</StdQuery>
);
export default PointOfSaleLocations;
