// Table 2.7.16 WSReplacementMedia Field Descriptions
export default class WSReplacementMedia {
	constructor({
		type,
		subsystem,
		subsystemAccountReference = null,
		travelTokenId = null,
		travelTokenNickname = null,
		travelTokenEnablementFeeAmount = null,
		mediaType,
		replacementFeeAmount = null,
	}) {
		// string(30)
		// (Required) Identifies the type of new media. Currently supported types are:
		// • TransitAccountMedia
		this.type = type;

		// string(20)
		// (Required) Unique identifier of the subsystem currently with the replacement media.
		this.subsystem = subsystem;

		// string(20)
		// (Optional) Unique identifier for the customer’s account in the subsystem currently with the replacement media.
		this.subsystemAccountReference = subsystemAccountReference;
		// string(20)
		// (Required) The media travel token id to be used as the replacement.
		this.travelTokenId = travelTokenId;
		// string(40)
		// (Optional) Nick name of the travel token.
		this.travelTokenNickname = travelTokenNickname;
		// int
		// (Optional) The media enablement fee amount paid for the replacement travel token. This is used to calculate potential refund.
		this.travelTokenEnablementFeeAmount = travelTokenEnablementFeeAmount;
		// string(50)
		// (Required) the type of media to issue. See 2.6.9 Media Types for allowed values.
		this.mediaType = mediaType;

		// int
		// (Optional) The replacement fee amount to be collected for using this media as a replacement.
		this.replacementFeeAmount = replacementFeeAmount;
	}

	static fromBackoffice(data) {
		const result = new WSReplacementMedia(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	toResolver() {
		return this;
	}
}

export const WSReplacementMediaFragment = `
		type: String!
		subsystem: String!
		subsystemAccountReference: String
		travelTokenId: String
		travelTokenNickname: String
		travelTokenEnablementFeeAmount: String
		mediaType: String!
		replacementFeeAmount: Int
`;

export const InputWSReplacementMediaType = [ `
	input InputWSReplacementMedia {
		${WSReplacementMediaFragment}
	}
` ];


export const WSReplacementMediaType = [ `
	type WSReplacementMedia {
		${WSReplacementMediaFragment}
	}
` ];
