import React from 'react';
import {
	values,
} from 'lodash';

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';

import PreventDefault from 'utils/PreventDefault.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';

import Button, { buttonTypeStylePlain } from 'components/Button.js';

import * as forms from 'components/forms/Forms.module.css';

const cms = {
	chooseSaved: 'miscText.purchase-payment-choose-saved',
	chooseNew: 'miscText.purchase-payment-choose-new',
};

export const getCardNickNameOrType = (nickname, creditCardType) => nickname ? nickname : creditCardType;

const TogglePaymentMethod = ({
	formHelper,
	splitPaymentIndex = 0,
}) => {
	const {
		updateSelectedFundingSource,
		selectedFundingSources,
	} = useFundingSourcesContext();
	const selectedFundingSource = selectedFundingSources[ splitPaymentIndex ];

	return <CmsContentList list={values(cms)}>{() =>
		<div className={forms.section}>
			<Button
				type="button"
				typeStyle={buttonTypeStylePlain}
				onClick={PreventDefault(() => {
					// if there were errors with the new payment, we can ignore them if the new payment isn't selected
					// (also applies to saved payments with errors)
					formHelper.clearAllErrors();
					updateSelectedFundingSource({
						splitPaymentIndex,
						isNew: !selectedFundingSource?.isNew,
					});
				})} >
				{selectedFundingSource?.isNew
					? <CmsContentRenderer.Span
						fallbackValue='Use Saved Payment Method'
						contentKey={cms.chooseSaved}
					/>
					: <CmsContentRenderer.Span
						contentKey={cms.chooseNew}
						fallbackValue='Use a New Payment Method'
					/>}
			</Button>
		</div>
	}</CmsContentList>;
};

export default TogglePaymentMethod;
