import React, { useState, useContext } from "react";
import {
	levels,
	log,
} from 'utils/Logger.js';

export const OptionsSelectedContext = React.createContext(() => log(null, levels.error, "OptionsSelectedSet used before it was ready"));
export const useOptionsSelectedContext = () => useContext(OptionsSelectedContext);

export const OptionsSelectedProvider = ({
	min,
	max,
	children,
}) => {

	const [ actionType, setActionType ] = useState();
	const [ reasonType, setReasonType ] = useState();
	const [ resolutionType, setResolutionType ] = useState();

	const value = {
		actionType, setActionType,
		reasonType, setReasonType,
		resolutionType, setResolutionType,
	};

	return (
		<OptionsSelectedContext.Provider {...{ value }}>
			{children}
		</OptionsSelectedContext.Provider>
	);
};

export default OptionsSelectedProvider;
