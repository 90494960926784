import React from 'react';

import Language from 'components/data/Language.js';
import Logout from 'components/auth/Logout.js';
import CartProvider from 'context/CartProvider.js';
import ToastProvider from 'context/ToastProvider.js';
import BreadcrumbProvider from 'context/BreadcrumbProvider';
import ModalProvider from 'context/ModalProvider.js';
import publicAppVars from "utils/PublicAppVars.js";
import { TransitAccountIdContextProvider } from 'context/TransitAccountIdContext.js';
import { ProgramIdContextProvider } from 'context/group-admin/ProgramIdContext.js';

/**
 * Wraps the App in various Context providers.
 *
 * Keep Modal context last so that the modal rendered by ModalProvider has access to all the other contexts
 */
const AppDataProvider = ({ children }) => {
	const providers = [
		Logout,
		ToastProvider,
		Language,
		CartProvider,
		ModalProvider,
	];

	return providers.reduceRight((children, Provider) => <Provider>{children}</Provider>, children);
};

export const PostRouteDataProvider = ({ children }) => {
	const providers = [
		...(publicAppVars.isB2B
			? [ ProgramIdContextProvider ]
			: [ TransitAccountIdContextProvider ]
		),
		BreadcrumbProvider,
	];

	return providers.reduceRight((children, Provider) => <Provider>{children}</Provider>, children);
};

export default AppDataProvider;
