/**
 * This Card selector dropdown appears above the navigation in the CardLayout left sidebar
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { filter, map } from 'lodash';

import { Chevron } from 'components/icons/UI.js';
import { DropdownCard } from 'components/icons/CardBrands.js';

import * as dropdown from 'components/Dropdown.module.css';
import * as admin from 'layouts/Admin.module.css';
import Dropdown, { DropdownItem } from "components/Dropdown.js";

const SidebarCardSelector = ({
	cards,
	selectedCard,
}) =>
	<Dropdown
		id='sidebarCardSelector'
		additionalClasses={cx(admin.cardSelector)}
		additionalOptionsClasses={cx(admin.cardSelectorDropdown)}
		additionalButtonClasses={cx(admin.cardSelectorToggle)}
		label={
			<>
				{selectedCard.label}
				<Chevron overrideClass={dropdown.toggleIcon} aria-hidden={true} />
			</>
		}
	>
		{cards.map((card) => (
			<DropdownItem to={card.action} key={`link-${card.subsystemAccountReference}`} itemKey={`link-${card.subsystemAccountReference}`}>
				{card.label}

				<i className={dropdown.cardIcon}>
					<DropdownCard type={card.type} />
				</i>
			</DropdownItem>
		))}
	</Dropdown>;

SidebarCardSelector.propTypes = {
	cards: PropTypes.array.isRequired,

	selectedCard: PropTypes.object.isRequired,
};

export default SidebarCardSelector;
