import { ApolloProvider } from "@apollo/client";
//
// --- Polyfills ---
//

// TODO: use react-app-polyfill?

// @babel/plugin-transform-runtime handles most corejs polyfills, but we still have to add some manually for whatever reason
// (it seems to compile web twice, once with targets: {})
// @babel/plugin-transform-runtime is only polyfilling babeled code. These core-js polyfills are required by some of
// the node_modules that aren't being babeled by babel-some-node-modules.js, which otherwise error in ie11.
// promise, set, map are required by react https://reactjs.org/docs/javascript-environment-requirements.html
// and https://github.com/jquense/yup
// react-dom also requires object/assign
// NOTE: instead of adding polyfills here, first try adding the affected module to babel-some-node-modules.js
// and let babel do it automatically
import 'core-js/features/array/find';
import 'core-js/features/array/from';
import 'core-js/features/array/map';
import 'core-js/features/object/assign';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/array/find-index';

import 'core-js/features/number/is-nan';
// raf for react https://reactjs.org/docs/javascript-environment-requirements.html
import 'raf/polyfill';
// for URLSearchParams
import 'url-search-params-polyfill';

// for <input type="date"> in safari
import 'date-input-polyfill';

// unfetch provides fetch support for ie11 (required by apollo)
// without the /index.js it includes arrow functions which break ie11
import 'unfetch/polyfill/index.js';

// FormData.get is used by FormHelper, but not supported by IE11
// https://developer.mozilla.org/en-US/docs/Web/API/FormData/get
import 'formdata-polyfill';
//
// --- End Polyfills ---
//

import React from 'react';
// We can't use BrowserRouter because we need access to history.listen
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactDOM, { hydrate } from 'react-dom';
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from 'react-helmet-async';
import { sendPageviewEvent } from "./utils/analytics/AnalyticsEvents.js";
import { create_client_apollo_client } from './utils/ApolloClient.js';
import PublicAppVars from './utils/PublicAppVars.js';
import {
	levels,
	log,
	noticeError,
} from "utils/Logger.js";
import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import Cookies from 'universal-cookie';
import emotionCache from "client/EmotionCache.js"; // must be before anything that pulls in react-select, like App.js

import App from './App.js';
import ClientAjax from "utils/ClientAjax.js";
import LogoutRequestContext from './components/auth/LogoutRequestContext.js';
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import CspNonceContext from "components/CspNonceContext.js";
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';

if (process.env.NODE_ENV !== 'production' && process.env.WHY_DID_YOU_RENDER === '1') {
	const { whyDidYouRender } = require('@welldone-software/why-did-you-render');
	whyDidYouRender(React, {
		trackAllPureComponents: true,
	});
}

if (process.env.NODE_ENV === 'development' && PublicAppVars.REACT_AXE) {
	const axe = require('@axe-core/react');
	axe(React, ReactDOM, 1000);
}

// waiting on https://github.com/winstonjs/winston/issues/287 before we can do this:
// const winstonInstance = winston.createLogger({
// 	// https://github.com/winstonjs/winston#creating-your-own-logger
// 	level: levels.silly,
// 	exitOnError: false,
// });
// globalThis.winstonInstance = winstonInstance;

// for future consumption by shared libraries
globalThis.reflexLogger = {
	levels,
	log,
	noticeError,
};
globalThis.PublicAppVars = PublicAppVars;

const cookies = new Cookies();
const apollo_client = create_client_apollo_client(() => {
	return cookies.get('XSRF-TOKEN');
});
const history = createBrowserHistory();

// Not called on initial pageload. It's only if a <Link> is clicked
const new_page_loaded = (location, action) => {
	sendPageviewEvent(location.pathname);
};

history.listen((location, action) => {
	// Handle frontend route changes.

	/* TODO: how can we tell NR which <Route> matched? We only know the full url here
	global.newrelic.setPageViewName(transactionName);
	global.newrelic.addCustomAttributes({
		// the variable parts of the path that matched
		params: match.params,
	}); */

	log(null, levels.info, { message: `Client-side navigate to ${location?.pathname}`, location, action });

	// we want to scroll to the top when the url changes, but not on back button
	// don't wait for middleware, because sometimes e.g. one unimportant api request might be slow, the user
	// starts reading the page, then it scrolls to the top when that unimportant request finishes/times out
	if (action !== 'POP') {
		window.scrollTo(0, 0);
	}

	new_page_loaded(location, action);
});

const helmetContext = {};

const clientLogoutAjax = async () => {
	try {
		await restClientMiddleware(ClientAjax.post('/ajax/logout'));
	} catch (error) {
		noticeError(null, levels.error, error, `Client sign out error`);
		window.location.reload();
	}
};

hydrate(
	<EmotionCacheProvider value={emotionCache}>
		<HelmetProvider context={helmetContext}>
			<ApolloProvider client={apollo_client}>
				<CookiesProvider>
					<CspNonceContext.Provider value={window.__CSP_NONCE}>
						<LogoutRequestContext.Provider value={clientLogoutAjax}>
							<Router
								forceRefresh={PublicAppVars.FORCE_SERVER_RENDER}
								history={history}
							>
								<App />
							</Router>
						</LogoutRequestContext.Provider>
					</CspNonceContext.Provider>
				</CookiesProvider>
			</ApolloProvider>
		</HelmetProvider>
	</EmotionCacheProvider>,
	document.getElementById('root'),
);

// Track the pageview after the app has loaded
// Custom dimensions may have been set by GoogleAnalytics.setGA, but they're not actually sent until a pageview or event fires
if (!PublicAppVars.GOOGLE_ANALYTICS_IMMEDIATE_PAGEVIEW) {
	GoogleAnalytics.sendPageview();
}

// don't need module.hot.accept when using react-refresh-webpack-plugin
if (!process.env.shouldUseReactRefresh) {
	if (module.hot) {
		module.hot.accept();
	}
}
