import React from 'react';
import {
	min,
	max,
	forEach,
} from 'lodash';
import {
	centsToDisplay,
} from 'utils/FormatHelpers.js';
import {
	Weekly,
	Monthly,
} from 'components/account/purchases/PurchaseValue.js';
import { AUTO_LOAD_CRITERIA_TYPES } from 'server/api-types/WSAutoloadCriteria.js';
import {
	date as yup_date,
	number as yup_number,
	object as yup_object,
	string as yup_string,
	array as yup_array,
	lazy as yup_lazy,
} from 'yup';

import PublicAppVars from 'utils/PublicAppVars.js';
import { COLLECTIONS, getErrorKey } from 'utils/GetErrorKey.js';
import { balanceSelectionValidation } from 'components/account/reload-balance/helpers.js';
import FormHelper from 'utils/FormHelper.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { emptyToNull } from 'components/modals/EditPaymentMethod.js';

export const maxBalanceError = 'miscText.purchase-balance-error-max';
export const rangeError = 'miscText.purchase-balance-error-amount';
export const incrementError = 'miscText.purchase-balance-error-increment';
export const incrementErrorGeneric = getErrorKey(COLLECTIONS.AutomaticPassRenewal, 'errors.general.value.unexpected_autoload.value', 'errors.general.value.unexpected');

const cms = {
	rangeError,
	incrementError,
};

export const getIncrementTest = (valueIncrement, message = null) => ({
	name: 'valueIncrement',
	test: (val) => (Math.round(val) % valueIncrement === 0),
	message: message ?? `Amount must be a multiple of ${centsToDisplay(valueIncrement)}`,
});

export const getStoredValueOtherValidations = ({
	purseTotal,
	cartBalance,
	valueIncrement,
	minAddTransitValue,
	maxAddTransitValue,
}) => ({
	storedValueOther: yup_lazy(value => {
		if (Boolean(value)) {
			return balanceSelectionValidation({ minValue: minAddTransitValue, maxValue: maxAddTransitValue, cartBalance, purseTotal })
				.test(getIncrementTest(valueIncrement, () => <CmsContentRenderedInline
					contentKey={cms.incrementError}
					fallbackValue={`Amount must be a multiple of ${centsToDisplay(valueIncrement)}`}
					variables={{ valueIncrement: centsToDisplay(valueIncrement) }}
				/>))
				// Check if the current selected amount were added to the cart,
				// does that ammount bring the cart past the purchase limit
				.test({
					name: 'max add transit value',
					test: () => cartBalance <= maxAddTransitValue,
					message: () => <CmsContentRenderedInline
						contentKey={'miscText.purchase-balance-error-cartmax'}
						variables={{ max: centsToDisplay(maxAddTransitValue) }}
						fallbackValue={`The specified amount would bring the cart over the max balance of ${centsToDisplay(maxAddTransitValue)}`}
					/>,
				});
		}
		return yup_number();
	}),
});

export const getYupSchema = ({
	minAddTransitValue,
	maxAddTransitValue,
	autoLoadCriteriaType,
	valueIncrement,
	frequency,
	cartBalance = 0,
	purseTotal = 0,
}) => {
	const validations = {
		[ 'storedValue[]' ]: yup_array()
			.when('storedValueOther', {
				is: (storedValueOther) =>
					// There's a negative balance but not stored value.
					// We should let the user pay the balance without storedValue validation
					purseTotal < 0 && storedValueOther === 0
						?  true
						: Boolean(storedValueOther)
				,
				then: (schema) => schema.nullable(true).transform(emptyToNull),
				otherwise: (schema) => schema
					.of(yup_number())
					.min(1, () => <CmsContentRenderedInline
						contentKey={'miscText.purchase-balance-error-blank'}
						fallbackValue="You must specify an amount to add"
					/>)
					// Check if the current selected amount were added to the cart,
					// does that amount bring the cart past the purchase limit
					.test({
						name: 'max add transit value',
						test: () =>  cartBalance <= maxAddTransitValue,
						message: () => <CmsContentRenderedInline
							contentKey={'miscText.purchase-balance-error-cartmax'}
							variables={{ max: centsToDisplay(maxAddTransitValue) }}
							fallbackValue={`The specified amount would bring the cart over the max balance of ${centsToDisplay(maxAddTransitValue)}`}
						/>,
					})
					.required(() => <CmsContentRenderedInline
						contentKey={'miscText.purchase-balance-error-blank'}
						fallbackValue="You must specify an amount to add"
					/>),
			}),
		...getStoredValueOtherValidations({
			purseTotal,
			cartBalance,
			valueIncrement,
			minAddTransitValue,
			maxAddTransitValue,
		}),
	};

	if (autoLoadCriteriaType === AUTO_LOAD_CRITERIA_TYPES.threshold) {
		const thresholdValues = PublicAppVars.AUTOMATIC_RELOAD_BALANCE_THRESHOLD;

		const thresholdValidations = () => {
			return yup_number()
				.min(min(thresholdValues), "miscText.account-purchases-select-add-value-min-threshold-transit-value")
				.max(max(thresholdValues), "miscText.account-purchases-select-add-value-max-threshold-transit-value");
		};

		validations.reloadThreshold = yup_number().when('reloadThresholdOther', {
			is: (reloadThresholdOther) => Boolean(reloadThresholdOther),
			then: yup_number(),
			otherwise: thresholdValidations().required(),
		});

		validations.reloadThresholdOther = yup_lazy(value => {
			if (Boolean(value)) {
				return thresholdValidations()
					.required();
			}
			return yup_number();
		});
	};

	if (autoLoadCriteriaType === AUTO_LOAD_CRITERIA_TYPES.recurring) {
		if (frequency === Monthly) {
			validations.startMonthSelect = yup_string()
				.required("miscText.account-purchases-select-add-value-select-start-day");
			validations.monthDaySelect = yup_string()
				.required("miscText.account-purchases-select-add-value-select-start-day-of-month");
		};
		// configure date prior current
		const date = new Date();
		date.setDate(date.getDate() - 1);

		if (frequency === Weekly) {
			validations.weekDaySelect = yup_string()
				.required("miscText.account-purchases-select-add-value-select-start-week-day");
			validations.startDate = yup_date()
				.required("miscText.account-purchases-select-add-value-select-start-date")
				.min(date, cms.specificDatePast);
		};
	};

	return yup_object().shape(validations);
};

export const reMapErrors = ({
	errorReport,
	formHelper,
	valueIncrement,
	maxAddTransitValue,
	minAddTransitValue,
	balance,
	purseTotal,
}) => {
	forEach(errorReport.FieldErrors, (value, key) => {
		if (value[ 0 ] === cms.incrementError) {
			formHelper.mergeState({
				[ FormHelper.getStateErrorField(key) ]: FormHelper.errorJsx(
					<CmsContentRenderedInline
						contentKey={cms.incrementError}
						fallbackValue={`Amount must be a multiple of ${centsToDisplay(valueIncrement)}`}
						variables={{ valueIncrement: centsToDisplay(valueIncrement) }}
					/>
				),
			});
		}
		if (value[ 0 ] === cms.rangeError || ((balance + purseTotal) < maxAddTransitValue && value[ 0 ] !== cms.incrementError)) {
			formHelper.mergeState({
				[ FormHelper.getStateErrorField(key) ]: FormHelper.errorJsx(
					<CmsContentRenderedInline
						contentKey={cms.rangeError}
						fallbackValue={`Amount must be between ${centsToDisplay(minAddTransitValue)} and ${centsToDisplay(maxAddTransitValue)}`}
						variables={{ min: centsToDisplay(minAddTransitValue), max: centsToDisplay(maxAddTransitValue) }}
					/>
				),
			});
		}
		return;
	});
};
