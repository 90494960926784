import React, { useState } from 'react';
import Button, { buttonTypeStylePlain } from "components/Button.js";
import * as settings from "pages/account/card/Settings.module.css";
import { TOKEN } from "utils/Constants.js";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";
import Toast from "components/Toast.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { TOKEN_ACTION } from "components/data/transit-account/subsystem/travel-token/TokenAction.mutation.js";
import { getTransitAccountRefetchQueries } from "components/data/transit-account/TransitAccount.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";

export const useTerminateTokenMutation = ({ queryOptions = {} }) => {
	return useStandardMutation(TOKEN_ACTION, { ...queryOptions });
};


const TerminateToken = ({
	wsTransitAccountToken,
	setError = () => { },
}) => {
	const subsystemAccountReference = useTransitAccountIdContext();
	const { setToast, removeToast } = useGlobalToastsContext();
	const [ submitting, setSubmitting ] = useState(false);

	const [ tokenActionMutation ] = useTerminateTokenMutation({
		queryOptions: {
			refetchQueries: getTransitAccountRefetchQueries(subsystemAccountReference),
			awaitRefetchQueries: true,
		},
	});

	const tokenType = wsTransitAccountToken.tokenInfo.tokenType;

	const terminateToken = async () => {
		setSubmitting(true);

		const variables = {
			tokenId: wsTransitAccountToken.tokenId,
			tokenAction: TOKEN.ACTION.terminate,
			subsystemAccountReference,
			reasonCode: wsTransitAccountToken.statusReasonCode,
		};

		try {
			await graphqlErrorMiddleware(tokenActionMutation({ variables }));
		} catch (errorReport) {
			setError(errorReport.display.topLevelMessage);
		} finally {
			setSubmitting(false);
		}

		setToast(
			<Toast
				type="success"
				title={
					<CmsContentRenderedInline
						contentKey="miscText.card-settings-terminate-confirmation"
						fallbackValue={`${tokenType} as been permanently terminated.`}
						variables={{ cardtype: tokenType }}
					/>}
				onClosed={removeToast}
			/>
		);
	};

	return <div>
		<Button
			typeStyle={buttonTypeStylePlain}
			additionalClassNames={settings.cardAction}
			submitting={submitting}
			onClick={terminateToken}
		>
			<CmsContentRenderedInline contentKey="miscText.card-settings-terminate-cta" fallback="Terminate card" />
		</Button>
	</div>;
};

export default TerminateToken;
