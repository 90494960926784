import React from "react";

import LoginStageRedirect from "./LoginStageRedirect.js";
import loginStages from "components/data/session-user/LoginStages.js";

const CredentialsForbiddenRedirect = () => (
	<LoginStageRedirect
		returnAfterSignIn={false}
		maxLevel={loginStages.unRegisteredLoggedIn}
	/>
);
export default CredentialsForbiddenRedirect;
