import { gql } from "@apollo/client";
import React, {
	useContext,
} from 'react';
import {
	log,
	levels,
	noticeError,
} from "utils/Logger.js";
import useStandardMutation from "components/data/hooks/useStandardMutation.js";
import { useFundingSourcesContext } from "context/FundingSourcesContext.js";
import { useCartContext } from 'context/CartProvider.js';
import WSIssueMediaLineItem from 'server/api-types/WSIssueMediaLineItem.js';
import WSIssueSubsystemMedia from 'server/api-types/WSIssueSubsystemMedia.js';
import { SUBSYSTEM_ID } from "utils/Constants.js";
import { OPEN_TRANSIT_REGULAR_CARD } from "server/api-types/WSSubsystemAccountToken.js";

export const GET_REPLACE_PAYMENT_METHODS = gql`
	mutation Paymentmethods (
		$issueMediaLineItems: [ InputWSIssueMediaLineItem! ]
	) {
		OrderRoute {
			id
			getReplacePaymentmethods(
				issueMediaLineItems: $issueMediaLineItems
			) {
				shoppingCartId
				restrictFunding
				fundingSources {
					id
					nickname
					fundingSourceId
					billingAddressId
					setAsPrimary
					status
					statusDescription
					creditCard {
						pgCardId
						maskedPan
						cardExpiryMMYY
						nameOnCard
						creditCardType
					}
				}
			}
		}
	}`;

export const ReplacementPaymentMethodsContext = React.createContext([ {}, () => log(null, levels.error, "ReplacementPaymentMethodsContext used before it was ready") ]);
export const useReplacementPaymentMethodsContext = () => useContext(ReplacementPaymentMethodsContext);

export const ReplacementPaymentMethodsProvider = ({
	subsystemAccountReference,
	children,
}) => {
	const [ mutator, { loading } ] = useStandardMutation(GET_REPLACE_PAYMENT_METHODS);
	const { initializeFundingSources } = useFundingSourcesContext();
	const { saveShoppingCartId } = useCartContext();

	const getReplacementPaymentMethods = ({ amount }) => {
		const variables = {
			issueMediaLineItems: [
				new WSIssueMediaLineItem({
					issueMedia: new WSIssueSubsystemMedia({
						type: 'SubsystemMedia',
						subsystem: SUBSYSTEM_ID,
						subsystemAccountReference,
						mediaType: OPEN_TRANSIT_REGULAR_CARD,
						subsystemTokenType: 'OpenTransitRegular',
						itemTotalAmount: amount,
					}),
				}).toResolver(),
			],
		};

		return mutator({
			variables,
		}).then(response => {
			const {
				shoppingCartId,
				loadProductLineItemFunds,
				fundingSources,
				restrictFunding,
			} = response.data.OrderRoute.getReplacePaymentmethods;

			saveShoppingCartId(shoppingCartId);

			initializeFundingSources({
				loadProductLineItemFunds,
				fundingSources,
				restrictFunding,
				refetchFundingSources: async () => (await mutator({ variables })).data.OrderRoute.getReplacePaymentmethods,
			});
		}).catch(errorReport => {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Purchase Stored Value Payment Methods POST Failed`);
			// formHelper.validationErrorHandler(errorReport);
		});
	};

	const contextValue = {
		getReplacementPaymentMethods,
		loading,
	};

	return (
		<ReplacementPaymentMethodsContext.Provider value={contextValue}>
			{children}
		</ReplacementPaymentMethodsContext.Provider>
	);
};
