import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import {
	Tabs as ReactTabs,
	Tab,
	TabList,
	TabPanel,
} from 'react-tabs';
import cx from 'classnames';

import * as style from './Tabs.module.css';

export const tabsPrimary = style.primary;
export const tabsSecondary = style.secondary;



const Tabs = ({
	tabs,
	defaultTab = 0,
	tabStyle = tabsPrimary,
	additionalClasses,
}) => {
	return (<>
		<ReactTabs
			selectedTabClassName={style.isSelected}
			defaultIndex={defaultTab}
			className={cx(style.container, tabStyle, additionalClasses)}
		>
			<div className={style.tabHeader}>
				<TabList
					className={style.tabList}
				>
					{map(tabs, ({ label, key }) => {
						return <Tab key={'Tab-' + key}
							className={style.tabItem}
						>
							{label}
						</Tab>;
					})}
				</TabList>
			</div>

			<div className={style.tabPanels}>
				{map(tabs, ({ key , content }) => {
					return <TabPanel key={'TabPanel-' + key}
						className={style.tabPanel}
					>
						{content}
					</TabPanel>;
				})}
			</div>
		</ReactTabs>
	</>);
};

Tabs.propTypes = {};

export default Tabs;
