import React, { useState, useEffect } from 'react';
import {
	values,
} from 'lodash';
import {
	useLocation,
	Redirect,
	useParams,
} from "react-router-dom";
import CmsContentList from 'components/data/CmsContentList.js';
import LeavePagePrompt from 'pages/account/purchase/LeavePagePrompt.js';
import { checkoutFlowPathRegex } from 'context/CartProvider.js';
import { getPathByRoute } from 'App.js';
import routeKeys from "CustomerRouteKeys.js";
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';

const cms = {
	promptText: "miscText.purchase-leave-warning",
};

export const checkoutAutoloadBalanceFlowPathRegex = new RegExp("^/account/cards/\\d+/autoload-balance");
export const checkoutAutoloadPassFlowPathRegex = new RegExp("^/account/cards/\\d+/autoload-pass/.*$");

export const useCatalogPageRedirect = () => {
	const location = useLocation();
	const [ redirect, setRedirect ] = useState(null);
	const {
		transit_account_id,
	} = useParams();
	const {
		fundingSourcesInitialized,
	} = useFundingSourcesContext();

	useEffect(() => {
		// Theres no paymentsData and we are not already on the product catalog page so lets redirect
		// to the product catalog page so we can start over again
		if (!fundingSourcesInitialized && !location.pathname.includes("/purchase-product")) {
			setRedirect(
				<Redirect push to={{
					pathname: getPathByRoute(routeKeys.AccountPurchaseProduct, { ...{ transit_account_id } }),
					state: { purchase: true },
				}} />,
			);
		}
	}, [
		fundingSourcesInitialized,
		location.pathname,
		transit_account_id,
	]);

	if (redirect) {
		return redirect;
	}
};

const CartLeavePagePrompt = () => {
	const redirect = useCatalogPageRedirect();

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<LeavePagePrompt
				message={(location, action) => {
					if (location.pathname.match(checkoutAutoloadBalanceFlowPathRegex) || location.pathname.match(checkoutAutoloadPassFlowPathRegex)) {
						// don't prompt when after purchase flow redirects to autoload flows
						return true;
					}
					if (!location.pathname.match(checkoutFlowPathRegex)) {
						return (cmsContent[ cms.promptText ] || "WARNING: You have unsaved changes. Press Cancel to return to your cart, or OK to lose these changes.");
					}

					// don't prompt
					return true;
				}}
			/>
		)}</CmsContentList>
	);
};

export default CartLeavePagePrompt;
