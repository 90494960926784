import { gql } from "@apollo/client";
import { useMemo } from "react";
import useStdQuery from "components/data/hooks/useStdQuery.js";

export const GET_PYPESTREAM_CONFIG = gql`
	query GetPypestreamConfig {
		PypestreamChatbotRoute {
			id
			PypestreamConfig {
				id
				config
				enabled
			}
		}
	}
`;

export const usePypestreamConfig = () =>  {

	const queryResult = useStdQuery(GET_PYPESTREAM_CONFIG);
	const result = queryResult.data?.PypestreamChatbotRoute.PypestreamConfig;
	const resultConfig = result?.config;

	// use memo so that if we're defaulting to {} we return the same instance each time
	const config = useMemo(() => resultConfig ?? {}, [ resultConfig ]);

	return {
		queryResult,
		config,
		enabled: result?.enabled,
	};
};
