import React from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { GET_ALTERNATE_ACCOUNT_FROM_SESSION } from "components/data/alternates/SessionAlternate.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";

import Container from '../components/Container.js';
import ContactCustomerService from "components/banners/ContactCustomerService.js";
import SidebarNavigation from "./components/SidebarNavigation.js";
import Dropdown, { DropdownItem } from 'components/Dropdown.js';
import { SmallChevron } from '../components/Icon.js';

import * as dropdownStyles from 'components/Dropdown.module.css';
import * as admin from "./Admin.module.css";


const cms = {
	personalinformation: "miscText.personal-header",
	security: "miscText.security-header",
	notifications: "miscText.notifications-header",
	paymentmethods: "miscText.payments-header",
};

const AccountLayout = ({
	children,
	title,
	additionalClasses,
}) => {
	const { data, loading: alternateLoading } = useStdQuery(GET_ALTERNATE_ACCOUNT_FROM_SESSION);

	const nonAlternateLinks = [
		{
			key: 'security',
			label: <CmsContentRenderedInline contentKey={cms.security} fallbackValue="Security & Log In" />,
			to: getPathByRoute(routeKeys.AccountSecurity),
		},
		{
			key: 'notifications',
			label: <CmsContentRenderedInline contentKey={cms.notifications} fallbackValue="Notifications" />,
			to: getPathByRoute(routeKeys.AccountNotifications),
		},
	];

	const links = [
		{
			key: 'personalinformation',
			label: <CmsContentRenderedInline contentKey={cms.personalinformation}
				fallbackValue="Personal Information" />,
			to: getPathByRoute(routeKeys.AccountPersonalInformation),
		},
		...(!alternateLoading && !data.session.alternateSession ? nonAlternateLinks : []),
		{
			key: 'paymentmethods',
			label: <CmsContentRenderedInline contentKey={cms.paymentmethods} fallbackValue="Payment Methods" />,
			to: getPathByRoute(routeKeys.AccountPaymentMethods),
		},
	];

	return (
		<div className={admin.account}>
			<Dropdown
				id={'accountLinksDropdown'}
				additionalClasses={cx(dropdownStyles.pageMenu, admin.mobilePageMenu)}
				ariaLabel={title}
				label={<>
					<span className={admin.pageTitleText}>{title}</span>
					<SmallChevron overrideClass={admin.pageTitleIcon} aria-hidden={true} />
				</>}
			>
				{links.map(({ key, to, label }) => <DropdownItem itemKey={key} key={key} to={to}>{label}</DropdownItem>)}
			</Dropdown>
			<Container overrideClass={admin.container}>
				<div className={admin.header}>
					<h1 className={cx(admin.pageTitle, admin.desktopOnly)}
						data-qa="AdminPageTitle"
					>
						<span className={admin.pageTitleText}>{title}</span>
					</h1>
				</div>

				<div className={admin.sidebar}>
					<SidebarNavigation {...{ links }} />
				</div>

				<section className={cx(admin.main, additionalClasses)}>
					{children}
				</section>
			</Container>

			<ContactCustomerService />
		</div>
	);
};

AccountLayout.propTypes = {
	title: PropTypes.string,

	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),

	/** Custom classes to override styling */
	additionalClasses: PropTypes.string,
};

export default AccountLayout;
