import React, {
	useContext,
	useState,
	useEffect,
	useMemo,
} from "react";
import {
	levels,
	log,
} from "../utils/Logger.js";
import {
	slice,
} from 'lodash';

import PublicAppVars from "utils/PublicAppVars.js";
import { useRideHistory } from 'components/data/transit-account/RidesHistory.query.js';
import { NO_FILTERS } from "components/FiltersConstants.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import {
	getMissedTapsTrips,
	getAutoFilledTapsTrips,
	getDefaultFilterDates,
} from 'utils/TripHistory.js';
import { Trip } from 'server/api-types/WSRideHistoryFactory.js';


const defaultMethod = () => log(null, levels.error, "TapsAlertsContext was used before it was ready");

export const TapsAlertsContext = React.createContext({
	isLoading: true,
	alerts: [],
	addAlerts: defaultMethod,
	removeAlert: defaultMethod,
});

export const useTapsAlertsContext = () => useContext(TapsAlertsContext);

const TapsAlertsProvider = ({
	children,
}) => {
	const [ tapsAlerts, setAlerts ] = useState([]);
	const subsystemAccountRef = useTransitAccountIdContext();

	const defaultFilterDates = getDefaultFilterDates({ isAlerts: true });

	const filtersApplied = {
		...NO_FILTERS,
		...defaultFilterDates,
	};

	const { lineItems, loading } = useRideHistory({
		isMissedTaps: true,
		filtersApplied,
		subsystemAccountRef,
		type: Trip,
		// we need to get enough rides history
		limit: PublicAppVars.TRIPS_HISTORY_PAGE_SIZE * 3,
	});

	// // runs when we get new alert data
	useEffect(() => {
		if (!loading && lineItems?.length > 0) {

			const autoFilledTapsTrips = getAutoFilledTapsTrips({ lineItems }) ?? [];
			const missedTapsTrips = getMissedTapsTrips({ lineItems }) ?? [];
			const missedTaps = [ ...autoFilledTapsTrips, ...missedTapsTrips ];

			setAlerts(missedTaps);
		}
	}, [
		loading,
		lineItems,
		setAlerts,
	]);

	const addAlert = (alert) => {
		setAlerts(tapsAlerts.concat(alert));
	};

	const removeAlert = (event) => {
		event && event.preventDefault();
		setAlerts(slice(tapsAlerts, 1));
	};

	const contextValue = {
		tapsAlerts,
		addAlert,
		removeAlert,
	};

	return (
		<TapsAlertsContext.Provider value={contextValue}>
			{children}
		</TapsAlertsContext.Provider>
	);
};

export default TapsAlertsProvider;
