import React from 'react';
import { values } from "lodash";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import Toast from "../Toast";
import CmsContentList from 'components/data/CmsContentList';
import { useGlobalToastsContext } from 'context/ToastProvider.js';

const cms = {
	conactsUsSubmitted: 'miscText.customer-service-confirmation',
};

const ContactUsFormSubmitted = ({}) => {
	const { removeToast } = useGlobalToastsContext();
	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Toast
				type="success"
				title={<>
					<CmsContentRenderedInline contentKey={cms.conactsUsSubmitted} fallbackValue='Your submission has been received. Please allow up to 5 business days for a response.' />
				</>}
				onClosed={removeToast}
			/>
		)}
		</CmsContentList>
	);
};

export default ContactUsFormSubmitted;
