import React, { useState, useEffect, useMemo } from 'react';
import { values } from 'lodash';

import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList.js';
import { useEmvCanReload } from "components/data/transit-account/EMV.helpers.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import {
	useTransitAccountIdContext,
} from "context/TransitAccountIdContext.js";
import { useLoginStage } from 'components/data/session-user/LoggingIn.js';
import loginStages from 'components/data/session-user/LoginStages.js';
import { StepContextProvider, useStepContext } from 'context/StepContext.js';
import { Redirect } from 'react-router-dom';
import { useModalContext } from 'context/ModalProvider.js';
import StandardNewProductAdded from 'components/toasts/StandardNewProductAdded.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';

import PurchaseProductCatalog from 'pages/account/purchase/PurchaseProductCatalog.js';
import PurchaseProductPayment from 'pages/account/purchase/PurchaseProductPayment.js';
import PurchaseProductConfirm from 'pages/account/purchase/PurchaseProductConfirm.js';
import PurchaseProductComplete from 'pages/account/purchase/PurchaseProductComplete.js';


import PurseBalanceProvider, { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import { getMasterTokenInfo } from 'components/manage-cards/TokenHelpers.js';
import { TOKEN_ENABLEMENT_FEE_STATUS } from 'server/api-types/WSTravelTokenEnablementFeeInfo.js';
import { useCartContext } from 'context/CartProvider.js';
import FundingSourcesProvider from "context/FundingSourcesContext.js";

const cms = {
	emptySelectionError: "miscText.purchase-cart-error-empty",
	purchaseStepOneHeader: "miscText.purchase-step1-subheader",
	purchaseStepTwoHeader: "miscText.purchase-step2-subheader",
	purchaseStepThreeHeader: "miscText.purchase-step3-subheader",
	purchaseStepFourHeader: "miscText.purchase-step4-subheader",
};

export const isUnpaidFee = (status) => status === TOKEN_ENABLEMENT_FEE_STATUS.UNPAID ?? false;

const STANDARD_PURCHASE_FLOW_SELECT_PRODUCTS = 0;
const STANDARD_PURCHASE_FLOW_PAYMENT = 1;
const STANDARD_PURCHASE_FLOW_CONFIRMATION = 2;
const STANDARD_PURCHASE_FLOW_COMPLETE = 3;

export const STANDARD_PURCHASE_FLOW_STEPS = [
	{
		step: STANDARD_PURCHASE_FLOW_SELECT_PRODUCTS,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepOneHeader}
			fallbackValue="Step 1: Select products"
		/>,
	},
	{
		step: STANDARD_PURCHASE_FLOW_PAYMENT,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepTwoHeader}
			fallbackValue="Step 2: Payment method"
		/>,
	},
	{
		step: STANDARD_PURCHASE_FLOW_CONFIRMATION,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepThreeHeader}
			fallbackValue="Step 3: Review and Submit"
		/>,
	},
	{
		step: STANDARD_PURCHASE_FLOW_COMPLETE,
		label: <CmsContentRenderedInline
			contentKey={cms.purchaseStepFourHeader}
			fallbackValue="Step 4: Purchase Complete"
		/>,
	},
];

/**
 * Return the current step of the flow
 */
const CurrentStep = () => {
	const [ redirect, setRedirect ] = useState(null);
	const transit_account_id = useTransitAccountIdContext();
	const { loginStage } = useLoginStage();
	const { step, setStep } = useStepContext();
	const { setModal } = useModalContext();
	const { setToast } = useGlobalToastsContext();

	const redirectUrl = useMemo(() => loginStage === loginStages.loggedIn
		? getPathByRoute(routeKeys.AccountCardOverview, { transit_account_id })
		: getPathByRoute(routeKeys.GuestCardOverview)
	, [ loginStage, transit_account_id ]);

	const emvCanReload = useEmvCanReload({ subsystemAccountReference: transit_account_id });

	// EMV card is restrictd and user should not be able to access this page.
	// Return them to the redirectURL
	useEffect(() =>
		emvCanReload ? null : setRedirect(<Redirect push to={{
			pathname: redirectUrl,
		}} />)
	, [ emvCanReload, redirectUrl ]);

	const onModalClose = () => setModal(null);
	const setToastSuccess = ({ autoloads }) => setToast(<StandardNewProductAdded {...{ autoloads }} />);

	const { tokens } = usePurseBalanceContext();
	const { cart, addUnpaidFee } = useCartContext();

	const masterTokenInfo = getMasterTokenInfo(tokens);
	const { status, feeDueAmount } = masterTokenInfo?.tokenEnablementFee ?? {};
	const hasUnpaidFee = isUnpaidFee(status);

	useEffect(() => {
		if (hasUnpaidFee && !cart.unpaidFee) {
			addUnpaidFee(feeDueAmount);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (redirect) {
		return redirect;
	};

	switch (step) {
		case STANDARD_PURCHASE_FLOW_SELECT_PRODUCTS:
			return <PurchaseProductCatalog cancelUrl={redirectUrl} />;
		case STANDARD_PURCHASE_FLOW_PAYMENT:
			return <PurchaseProductPayment cancelUrl={redirectUrl} />;
		case STANDARD_PURCHASE_FLOW_CONFIRMATION:

			const backToProductStep = () => setStep(STANDARD_PURCHASE_FLOW_SELECT_PRODUCTS);
			const backToPaymentStep = () => setStep(STANDARD_PURCHASE_FLOW_PAYMENT);

			return <PurchaseProductConfirm
				cancelUrl={redirectUrl}
				handleBackToProductStep={backToProductStep}
				handleBackToPaymentStep={backToPaymentStep}
			/>;
		case STANDARD_PURCHASE_FLOW_COMPLETE:
			const handleCompleteRedirect = () => {
				setRedirect(<Redirect push to={{ pathname: redirectUrl }} />);
			};

			return <PurchaseProductComplete {...{
				onModalClose,
				setToastSuccess,
				handleRedirect: handleCompleteRedirect,
			}} />;
	}
	;
};

const StandardPurchaseFlow = () => {
	const subsystemAccountReference = useTransitAccountIdContext();

	return (
		<FundingSourcesProvider>
			<CmsContentList list={values(cms)}>{() => (
				<StepContextProvider min={STANDARD_PURCHASE_FLOW_SELECT_PRODUCTS} max={STANDARD_PURCHASE_FLOW_COMPLETE}>
					<PurseBalanceProvider {...{ subsystemAccountReference }}>
						<CurrentStep />
					</PurseBalanceProvider>
				</StepContextProvider>
			)}</CmsContentList>
		</FundingSourcesProvider>
	);
};

export default StandardPurchaseFlow;
