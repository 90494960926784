import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import PublicAppVars from "../utils/PublicAppVars.js";
import InvisibleRecaptcha from "react-google-invisible-recaptcha";
import FormHelper from "../utils/FormHelper.js";
import { RECAPTCHA_ENABLED_QUERY } from "./data/RecaptchaEnabled.js";
import { string as yup_string } from "yup";
import { LANGUAGES, useLanguage } from "components/data/Language.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import CmsContentRendered from "components/data/CmsContentRendered.js";
import RenderAfterAWhile from "utils/RenderAfterAwhile.js";

const BOTTOM_LEFT = "bottomleft";
const BOTTOM_RIGHT = "bottomright";

export const recaptchaYupValidations = {
	recaptchaValue: yup_string().required('miscText.general-error-recaptcha-failed'),
};

const RecaptchaComponent = forwardRef(({
	setLoading,
	executePromiseRef,
	formHelper,
}, recaptchaRef) => {
	const langKey = useLanguage();
	const locale = LANGUAGES[ langKey ].recaptchaLocale;
	const sitekey = PublicAppVars.RECAPTCHA_SITEKEY;

	const [ value, setValue ] = useState('');

	// mild hack here to get the InvisibleRecaptcha component to remount and reload the recaptcha script on lang change
	// @todo open a PR to have react-google-invisible-recaptcha do this for us when the locale changes
	const [ remountTriggerCount, setRemountTriggerCount ] = useState(1);
	const [ oldLang, setOldLang ] = useState(langKey);
	useEffect(() => {
		// Force recaptcha to refetch the recaptcha script with the updated language.
		if (!window.grecaptcha || oldLang === langKey) {
			return;
		}

		window.grecaptcha = null;
		document.querySelector('#recaptcha').remove();
		setRemountTriggerCount((oldCount) => oldCount + 1);
	}, [ langKey, oldLang ]);

	useEffect(() => {
		if (!value) {
			return;
		}

		executePromiseRef.current?.resolve();
		executePromiseRef.current = null;

		if (formHelper.getFieldError('recaptchaIncomplete')) {
			formHelper.clearFieldErrors('recaptchaIncomplete');
		}
	}, [ executePromiseRef, formHelper, value ]);

	return <>
		<InvisibleRecaptcha
			key={remountTriggerCount}
			onLoaded={() => {
				setLoading(false);
				setOldLang(langKey);
			}}
			onResolved={(response) => {
				setValue(response);
			}}
			onExpired={() => {
				executePromiseRef.current.reject({ error: 'Captcha expired' });
				setValue('');
			}}
			onError={() => {
				executePromiseRef.current.reject({ error: 'Captcha errored' });
				setValue('');
			}}
			ref={recaptchaRef}
			{...{ sitekey, locale }}
			badge={PublicAppVars.PYPESTREAM_CHATBOT_APP_ID ? BOTTOM_LEFT : BOTTOM_RIGHT}
		/>

		{FormHelper.errorJsx(formHelper.getFieldError('recaptchaValue'))}
		{formHelper.getFieldError('recaptchaIncomplete') &&
			<RenderAfterAWhile timeout={1300}>
				{FormHelper.errorJsx(formHelper.getFieldError('recaptchaIncomplete'))}
			</RenderAfterAWhile>
		}
		<input
			type="hidden"
			name="recaptchaValue"
			{...{ value }}
		/>
	</>;
});
RecaptchaComponent.displayName = 'RecaptchaComponent';

const useRecaptcha = ({ formHelper }) => {
	const { data: enabledData, loading: enabledLoading } = useStdQuery(RECAPTCHA_ENABLED_QUERY);
	const [ loading, setLoading ] = useState(true);

	const recaptchaRef = useRef(null);
	const executePromiseRef = useRef(null);
	const checkRecaptcha = useCallback(() => (new Promise((resolve, reject) => {
		if (executePromiseRef.current) {
			executePromiseRef.current.reject({ reset: false, clear: false });
		}

		executePromiseRef.current = { resolve, reject };

		formHelper.setFieldError(
			'recaptchaIncomplete',
			<CmsContentRendered.Span contentKey={'miscText.general-error-recaptcha-incomplete'}
				fallbackValue={'ReCAPTCHA not completed'} />,
		);

		recaptchaRef.current.execute();
	})).catch(({ reset = true, error = 'Captcha promise was rejected.', clear = true } = {}) => {
		if (reset) {
			recaptchaRef.current.reset();
		}

		if (clear) {
			executePromiseRef.current = null;
		}
		throw new Error(error);
	}), [ formHelper ]);

	const resetRecaptcha = useCallback(() => {
		if (formHelper.getFieldError('recaptchaIncomplete')) {
			formHelper.clearFieldErrors('recaptchaIncomplete');
		}

		executePromiseRef.current?.reject({ reset: false, error: 'Recaptcha reset' });

		recaptchaRef.current?.reset();
	}, [ formHelper ]);

	const Recaptcha = useCallback(() => <RecaptchaComponent
		ref={recaptchaRef}
		{...{
			setLoading,
			executePromiseRef,
			formHelper,
		}}
	/>, [ formHelper ]);

	useEffect(() => {
		if (enabledData && !enabledData.recaptchaEnabled) {
			executePromiseRef.current?.reject();
		}
	}, [ enabledData, enabledData?.recaptchaEnabled ]);

	if (enabledLoading) {
		return {
			Recaptcha: () => <></>,
			loading,
			checkRecaptcha: async () => {},
			resetRecaptcha: () => {},
		};
	}

	if (!enabledData.recaptchaEnabled) {
		return {
			Recaptcha: () => <input
				type="hidden"
				name="recaptchaValue"
				value="ignored"
			/>,
			loading: false,
			checkRecaptcha: async () => {},
			resetRecaptcha: () => {},
		};
	}

	return {
		Recaptcha,
		loading,
		checkRecaptcha,
		resetRecaptcha,
	};
};

export default useRecaptcha;
