import React, {
	useState,
	useRef,
} from 'react';
import { filter, includes, map, values } from "lodash";
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
	boolean as yup_boolean,
	object as yup_object,
} from "yup";
import {
	removeAddressFormInputPrefix,
} from 'pages/account/purchase/AddPaymentMethod.js';

import GoogleAnalytics from 'utils/analytics/GoogleAnalytics.js';
import Button, {
	Primary,
	Secondary,
} from '../Button.js';
import { getAddressYupSchema } from 'components/forms/address/Address.validations.js';
import FormHelper from 'utils/FormHelper.js';
import { graphqlErrorMiddleware } from 'utils/error-handling/graphql/GraphqlClientMiddleware.js';
import {
	levels,
	noticeError,
} from 'utils/Logger.js';
import AddressForm, { SAVE_ADDRESS_HIDDEN } from "../payments/AddressForm.js";
import PreventDefault from 'utils/PreventDefault.js';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import CmsContentRenderer from '../data/CmsContentRenderer.js';
import { DEFAULT_ADDRESS_TYPES } from './EditAddress.js';
import CmsContentList from 'components/data/CmsContentList.js';

import * as modalStyles from 'components/Modal.module.css';
import * as styles from './EditAddress.module.css';
import { FUNDING_SOURCES_GET_QUERY } from 'graphql-queries/FundingSources.js';
import { getCardIcon } from 'components/Icon.js';
import useStdQuery from 'components/data/hooks/useStdQuery.js';
import WSAddress from 'server/api-types/WSAddress.js';
import strToBoolean from 'utils/StrToBoolean.mjs';
import { AddressType } from "components/account-settings/SelectAddressForm.js";

const cms = {
	submit: "miscText.personal-info-edit-submit",
	cancel: "miscText.personal-info-edit-cancel",
	addressEditPaymentText: "miscText.personal-addresses-edit-payments",
};


const getYupSchema = (formHelper) => {
	const validations = {
		defaultBillingAddress: yup_boolean(),
		defaultShippingAddress: yup_boolean(),
	};

	return yup_object().shape(validations).concat(getAddressYupSchema(AddressType.PersonalInfo));
};

const haveAddressInputsChanged = (formData, prefillAddress) => (
	formData.address1 !== prefillAddress.address1 ||
	formData.address2 !== (prefillAddress.address2 ?? '') ||
	(formData.address3 ?? '') !== (prefillAddress.address3 ?? '') ||
	formData.city !== prefillAddress.city ||
	formData.country !== prefillAddress.country ||
	formData.postalCode !== prefillAddress.postalCode ||
	formData.state !== prefillAddress.state
);

const hasDefaultBillingChanged = (formData, prefillAddress) => (
	formData.primaryBilling !== prefillAddress.primaryBilling
);

const hasDefaultShippingChanged = (formData, prefillAddress) => (
	formData.primaryShipping !== prefillAddress.primaryShipping
);


const AssociatedPaymentMethods = ({ prefillAddress }) => {
	const response = useStdQuery(FUNDING_SOURCES_GET_QUERY);

	const {
		fundingSources,
	} = response?.data?.session ?? {};

	const associatedPaymentMethods = filter(
		fundingSources,
		((paymentMethod) => paymentMethod.billingAddressId === prefillAddress.addressId)
	) ?? [];

	if (!associatedPaymentMethods.length) {
		return null;
	}

	return (
		<CmsContentList list={values(cms)}>
			{() => (
				<div className={styles.cardInfoContainer}>
					<CmsContentRenderer.P
						contentKey={cms.addressEditPaymentText}
						fallbackValue="This is the billing address for the following payment methods, and any changes to this address will impact those payment methods:"
					/>
					{map(associatedPaymentMethods, (paymentMethod, index) => {
						const { creditCard, nickname } = paymentMethod;

						const creditCardBrand = includes([ 'AmEx', 'AMEX' ], creditCard?.creditCardType)
							? 'American Express'
							: creditCard?.creditCardType;

						const name = nickname ? nickname : creditCardBrand;

						return (
							<div key={`${index}${creditCard?.creditCardType}`} className={styles.cardDisplayInfo}>
								{getCardIcon(creditCard?.creditCardType, { overrideClass: styles.cardIcon })}
								<p className={styles.cardName}>{`${name}...${creditCard?.maskedPan}`}</p>
							</div>
						);
					})}
				</div>
			)}
		</CmsContentList>);
};


const EditAddressForm = ({
	manageAddressMutation,
	prefillAddress,
	onModalClose,
	onSuccess,
	onSetDefaultSuccess,
}) => {
	const [ submitting, setSubmitting ] = useState(false);
	const [ validationState, setValidationState ] = useState({});
	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, () => getYupSchema(formHelper), () => {
		const formData = formHelper.getFormData();
		return {
			...formData,
			primaryBilling: formData.primaryBilling === 'true',
			primaryShipping: formData.primaryShipping === 'true',
		};
	});

	const kickoffSubmit = async () => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true, null);
		} catch (errorReport) {
			setSubmitting(false);
			noticeError(
				null,
				levels.verbose,
				errorReport,
				`Address Form Validation!`,
			);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		GoogleAnalytics.logEvent(
			"User is attempting to update address information",
		);

		const address = removeAddressFormInputPrefix({
			...validated,
			addressId: prefillAddress.addressId,
		}, AddressType.PersonalInfo);

		try {
			await graphqlErrorMiddleware(manageAddressMutation({
				variables: {
					address: new WSAddress(address),
					addressId: prefillAddress.addressId || null,
					primaryBilling: strToBoolean(address.primaryBilling),
					primaryShipping: strToBoolean(address.primaryShipping),
				},
			}));
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		const formData = formHelper.getDataToValidate();
		const addressInputsHaveChanged = haveAddressInputsChanged(address, prefillAddress);
		const billingHasChanged = hasDefaultBillingChanged(formData, prefillAddress);
		const shippingHasChanged = hasDefaultShippingChanged(formData, prefillAddress);

		onModalClose();

		if (prefillAddress.addressId && !addressInputsHaveChanged && billingHasChanged) {
			onSetDefaultSuccess(DEFAULT_ADDRESS_TYPES.BILLING);
		} else if (prefillAddress.addressId && !addressInputsHaveChanged && shippingHasChanged) {
			onSetDefaultSuccess(DEFAULT_ADDRESS_TYPES.SHIPPING);
		} else {
			onSuccess();
		}
	};

	return (
		<CmsContentList list={values(cms)}>{() => (
			<Lifecycles didMount={formHelper.wireInputs}>
				<form
					data-qa="EditAddressForm"
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					{prefillAddress.addressId ? <AssociatedPaymentMethods prefillAddress={prefillAddress} /> : null}
					<AddressForm
						formInputNamePrefix={AddressType.PersonalInfo}
						prefillAddress={prefillAddress}
						formHelper={formHelper}
						saveAddressCheckboxVisibility={SAVE_ADDRESS_HIDDEN}
					/>
					<div className={modalStyles.actionButtons}>
						<Button
							typeStyle={Secondary}
							additionalClassNames={cx(modalStyles.btn, modalStyles.leftButton)}
							onClick={PreventDefault(onModalClose)}
						>
							<CmsContentRenderer.Span contentKey={cms.cancel} fallbackValue="Cancel" />
						</Button>
						<Button
							typeStyle={Primary}
							additionalClassNames={cx(modalStyles.btn, modalStyles.rightButton)}
							submitting={submitting}
						>
							<CmsContentRenderer.Span contentKey={cms.submit} fallbackValue="Save Changes" />
						</Button>
					</div>
				</form>
			</Lifecycles>
		)}</CmsContentList>
	);
};

EditAddressForm.propTypes = {
	manageAddressMutation: PropTypes.func.isRequired,
	prefillAddress: PropTypes.object.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onModalClose: PropTypes.func.isRequired,
};

export default EditAddressForm;
