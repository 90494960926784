import { clone } from "lodash";
import { levels, log } from "utils/Logger.js";

export const AUTH_FACTOR_ONE = 'SINGLE';
export const AUTH_FACTOR_TWO = 'TWO';

export default class WSAccessibilityPreference {
	constructor({
		lang = 'en',
		langName = 'English - USA',
		volAdjust = false,
		sessionExtend = false,
		fontMagnify = false,
		textAudio = false,
		authFactor = AUTH_FACTOR_ONE,
		...rest
	}) {
		// String(10)
		// (Optional) Customer’s preferred language.
		this.lang = lang;

		// String(20)
		// (Conditionally-Required) The name of the customer’s preferred
		// language. Required when lang is available.
		this.langName = langName;

		// boolean
		// (Optional) Audio volume can be changed or enabled for the
		// customer based on client (device) capability.
		this.volAdjust = volAdjust;

		// boolean
		// (Optional) Customer’s use session time on the client can be
		// extended.
		this.sessionExtend = sessionExtend;

		// boolean
		// (Optional) Customer’s screen text font size can be magnified
		// based on the client (device) capability.
		this.fontMagnify = fontMagnify;

		// boolean
		// (Optional) Customer’s screen text can be used for audio
		// translation based on the client (device) capability.
		this.textAudio = textAudio;

		// String(10)
		// (Optional) Customer’s authentication preference as single
		// factor or two factor. Valid values are:
		// - SINGLE
		// - TWO
		this.authFactor = authFactor;

		if (rest.length) {
			log(null, levels.info, { message: "Unexpected WSAccessibilityPreference fields found", data: arguments[ 0 ] });
		}
	}

	static fromSerialized(session) {
		const result = new WSAccessibilityPreference(session.customer.contact.accessibilityPreferences || {});
		return result;
	}

	static fromBackoffice(data) {
		const result = new WSAccessibilityPreference(data);

		if (result.lang === 'en-US') {
			// BO might be defaulting to 'en-US', but we're supposed to use 'en'
			// https://reflexions.slack.com/archives/GA82SPCTV/p1570218727191000
			result.lang = 'en';
		}
		else if (result.lang === 'es-ES') {
			// same logic as en-US
			result.lang = 'es';
		}

		return result;
	}

	toBackoffice() {
		const result = clone(this);

		// opposite logic from `this.fromBackOffice`
		if (result.lang === 'en') {
			result.lang = 'en-US';
		}
		else if (result.lang === 'es') {
			result.lang = 'es-ES';
		}

		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		return forGraphql;
	}
}

export const WSAccessibilityPreferenceType = [ `
	type WSAccessibilityPreference {
		lang: String
		langName: String
		volAdjust: Boolean
		sessionExtend: Boolean
		fontMagnify: Boolean
		textAudio: Boolean
		authFactor: String
	}
` ];
