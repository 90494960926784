import React, {
	useEffect,
	useState,
	useCallback,
} from 'react';

import PropTypes from 'prop-types';
import leafletAsset from 'leaflet-asset.json';
import { Helmet } from "react-helmet-async";
import * as style from './MbtaMap.module.css';
import { useCspNonceContext } from "../CspNonceContext.js";
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import PublicAppVars from "utils/PublicAppVars.js";
import { map } from "lodash";
import { useModalContext } from "context/ModalProvider.js";
import MobileRetailLocationCard from 'components/modals/MobileRetailLocationCard.js';

import pinSvg from 'components/icons/ui/pin.svg';
import inactivePin from 'components/icons/ui/InactivePin.svg';
import cx from 'classnames';
import {
	isTablet,
} from "utils/Breakpoints.js";

const {
	"leaflet.css": { integrity: cssIntegrity, path: cssPath },
	"leaflet.js": { integrity: jsIntegrity, path: jsPath },
} = leafletAsset;

/**
 * Leaflet has its own required CSS & JS outside of our build. Dockerfile calculates the integrity (i.e. hash) of it.
 * This component makes sure that they get loaded by the browser by putting them in the html head.
 * The js must be loaded before the react-leaflet library can be loaded.
 * @returns {JSX.Element}
 * @constructor
 */
const LeafletAssets = () => {
	const cspNonce = useCspNonceContext();
	return (
		<Helmet>
			<link rel="stylesheet" integrity={cssIntegrity} href={cssPath} />
			<script src={jsPath} defer async integrity={jsIntegrity} nonce={cspNonce} />
		</Helmet>
	);
};

const MarkerChip = ({
	setMapCenterView,
	markerComponent: MarkerComponent,
	selectedLocation, setSelectedLocation,
	wsPointOfSaleLocation,
}) => {
	const [ leaflet, setLeaflet ] = useState(null);
	const { setModal } = useModalContext();

	useEffect(() => {
		// leaflet errors if imported server-side (with "ReferenceError: window is not defined")
		// useEffect is guaranteed to not run on the server-side, so we're safe here
		import('leaflet')
			.then(leaflet => setLeaflet(leaflet))
			.catch(error => noticeError(null, levels.error, error, "leaflet failed to load"));
	}, []);

	if (!leaflet) {
		return <LeafletAssets />;
	}

	const { icon } = leaflet;

	const isSelected = selectedLocation?.id === wsPointOfSaleLocation.id;

	// https://leafletjs.com/reference.html#icon
	const customPinIcon = icon({
		iconUrl: isSelected ? pinSvg : inactivePin,
		className: cx('leaflet-div-icon', style.pinIconOverride),
	});

	return (
		<MarkerComponent
			icon={customPinIcon}
			position={[ wsPointOfSaleLocation.latitude, wsPointOfSaleLocation.longitude ]}
			// https://react-leaflet.js.org/docs/api-components/#evented-behavior
			eventHandlers={{
				click: () => {
					if (!isTablet()) {
						setModal(<MobileRetailLocationCard {...{ wsPointOfSaleLocation }} />);
					}

					// Selected location in the list should scroll into view
					// do this on mobile too in case they resize the screen to tablet size
					document.getElementById('wsPointOfSaleLocation-card-' + wsPointOfSaleLocation.id)
						.scrollIntoView(
							{
								behavior: "smooth",
								block: "nearest",
								inline: "nearest",
							}
						);

					setMapCenterView(wsPointOfSaleLocation);
					setSelectedLocation(wsPointOfSaleLocation);
				},
			}}
		/>
	);
};

const ResetAddressCenter = ({
	useMap,
	setMapSearch,
	addressCenter, setAddressCenter,
	redoSearchOnMove,
	setSelectedLocation,
}) => {
	const leafletMap = useMap();

	const handleMove = useCallback(() => {
		const position = leafletMap.getCenter();
		const lat = Number(position.lat.toFixed(4));
		const lng = Number(position.lng.toFixed(4));

		const {
			lat: currLat,
			lng: currLng,
		} = addressCenter;

		const threshold = PublicAppVars.MBTA_MAP_RECENTER_ON_CHANGE_THRESHOLD;

		// https://reflexions.atlassian.net/browse/MBTA-2767
		// we dont want the map to update at every tick of movement so we can control
		// the movement reload by the threshold
		if (redoSearchOnMove && lat && lng && Math.abs(currLat - lat) > threshold && Math.abs(currLng - lng) > threshold) {
			setAddressCenter({ lat, lng });
			setSelectedLocation(null);
			setMapSearch(true);
		}
	}, [
		leafletMap,
		addressCenter, setAddressCenter,
		setMapSearch,
		redoSearchOnMove,
		setSelectedLocation,
	]);

	useEffect(() => {
		leafletMap.on('move', handleMove);
		return () => {
			leafletMap.off('move', handleMove);
		};
	}, [ leafletMap, handleMove ]);

	return null;
};

const MbtaMap = ({
	mapRef,
	setMapCenterView,
	selectedLocation,
	setSelectedLocation,
	wsPointOfSaleLocations,
	addressCenter, setAddressCenter,
	setMapSearch,
	redoSearchOnMove,
}) => {
	const [ reactLeaflet, setReactLeaflet ] = useState(null);

	useEffect(() =>
		// react-leaflet errors if imported server-side (with "ReferenceError: window is not defined")
		// useEffect is guaranteed to not run on the server-side, so we're safe here
		import('react-leaflet')
			.then(leaflet => setReactLeaflet(leaflet))
			.catch(error => noticeError(null, levels.error, error, "react-leaflet failed to load"))
	, []);


	if (!reactLeaflet) {
		return <LeafletAssets />;
	}

	const {
		MapContainer,
		Marker,
		TileLayer,
		useMap,
	} = reactLeaflet;

	const center = selectedLocation
		? { lat: selectedLocation.latitude, lng: selectedLocation.longitude }
		: addressCenter;

	return <>
		<LeafletAssets />
		<MapContainer
			center={center}
			zoom={15}
			whenCreated={(map) => { mapRef.current = map; }}
			scrollWheelZoom={false}
			className={style.mapContainer}
		>
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url={PublicAppVars.LEAFLET_TILE_URL}
			/>
			{map(wsPointOfSaleLocations, (wsPointOfSaleLocation) =>
				<MarkerChip
					key={wsPointOfSaleLocation.id}
					markerComponent={Marker}
					{...{
						setMapCenterView,
						wsPointOfSaleLocation,
						selectedLocation, setSelectedLocation,
					}}
				/>
			)}
			<ResetAddressCenter {...{
				useMap,
				setMapSearch,
				setMapCenterView,
				addressCenter, setAddressCenter,
				redoSearchOnMove,
				setSelectedLocation,
			}} />
		</MapContainer>
	</>;
};

MbtaMap.propTypes = {
	hasAutoCompleteResult: PropTypes.bool,
};

export default MbtaMap;
