// extracted by mini-css-extract-plugin
export var bold = "components-account-__PurchaseTable-module___bold";
export var boldMobile = "components-account-__PurchaseTable-module___boldMobile";
export var hideDesktop = "components-account-__PurchaseTable-module___hideDesktop";
export var hideMobile = "components-account-__PurchaseTable-module___hideMobile";
export var table = "components-account-__PurchaseTable-module___table";
export var tableColumnOne = "components-account-__PurchaseTable-module___tableColumnOne";
export var tableColumnThree = "components-account-__PurchaseTable-module___tableColumnThree";
export var tableColumnTwo = "components-account-__PurchaseTable-module___tableColumnTwo";
export var tableHeader = "components-account-__PurchaseTable-module___tableHeader";
export var tableLineItem = "components-account-__PurchaseTable-module___tableLineItem";
export var tableSummary = "components-account-__PurchaseTable-module___tableSummary";