import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { map, values } from 'lodash';

import Countries from 'components/data/Countries.js';
import States from 'components/data/States.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { COUNTRY_USA } from 'pages/auth/PersonalInformation.js';
import Input from 'components/forms/Input.js';
import Select from 'components/forms/inputs/Select.js';
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';
import { BoolCheckbox } from '../forms/Checkbox.js';
import { IfLoggedIn } from "components/data/session-user/LoggingIn.js";
import { SmallCheck } from 'components/Icon.js';

import * as formsStyle from 'components/forms/Forms.module.css';
import * as checkboxStyle from 'components/forms/Checkbox.module.css';
import * as editAddressStyle from "components/modals/EditAddress.module.css";
import FormHelper from "utils/FormHelper.js";

const cms = {
	saveAddress: 'miscText["general-address.save"]',
	setDefaultBilling: 'miscText["general-address.set-billing"]',
	setDefaultShipping: 'miscText["general-address.set-shipping"]',
	saveAddressRequired: 'miscText["general-address.save-required"]',
	defaultShippingLabel: 'miscText["general-address.default-shipping"]',
	defaultBillingLabel: 'miscText["general-address.default-billing"]',
	defaultAddressHelpText: 'miscHtml["general-address.default-info"]',
	zipCode: 'miscText["general-address-us-zip.label"]',
	postalCode: 'miscText["general-address-int-postal.label"]',
	city: 'miscText["general-address-us-city.label"]',
	state: 'miscText["general-address-us-state.label"]',
	country: 'miscText["general-address-country.label"]',
	internationalLineOne: 'miscText["general-address-int-line1.label"]',
	internationalLineTwo: 'miscText["general-address-int-line2.label"]',
	internationalLineThree: 'miscText["general-address-int-line3.label"]',
	usLineOne: 'miscText["general-address-us-line1.label"]',
	usLineTwo: 'miscText["general-address-us-line2.label"]',
};

export const SAVE_ADDRESS_REQUIRED = 'required';
export const SAVE_ADDRESS_SHOWN = 'show';
export const SAVE_ADDRESS_HIDDEN = 'hidden';

const AddressForm = ({
	formHelper: parentFormHelper,
	prefillAddress = {},
	formInputNamePrefix = '',
	overrideClass = null,
	isSplit = false,
	isFirst = false,
	saveAddressCheckboxVisibility = SAVE_ADDRESS_SHOWN,
	hideTopLevelError = false,
}) => {
	const [ country, setCountry ] = useState(prefillAddress.country ?? COUNTRY_USA);
	const [ , setState ] = useState(prefillAddress.state ?? null);
	const [ primaryBilling, setPrimaryBilling ] = useState(prefillAddress.primaryBilling);
	const [ primaryShipping, setPrimaryShipping ] = useState(prefillAddress.primaryShipping);
	const isUSA = useMemo(() => country === COUNTRY_USA, [ country ]);

	const getFieldName = (fieldNameBase) => (formInputNamePrefix + getPaymentInputName({
		base: fieldNameBase,
		isFirst,
		isSplit,
	}));

	const onCountryChange = (option) => {
		// country affects other fields, so we need to put its value into state
		setCountry(option.value);
		setState(null);
		parentFormHelper.clearAllErrors();
	};

	const onStateChange = (option) => {
		// don't know how to get the select to work without saving this in this.state
		setState(option.value);
		// clear "state" field error
		if (parentFormHelper.getFieldError(getFieldName('state'))) {
			parentFormHelper.mergeState({ [ FormHelper.getStateErrorField(getFieldName('state')) ]: '' });
		}
	};

	return (
		<Countries>{({ countries, countriesObj }) =>
			<CmsContentList list={values(cms)}>{() => <>
				<div className={cx(formsStyle.rowStacked, editAddressStyle.editAddressStackedFormRow)}>
					<div className={formsStyle.countryDropDown}>
						{/* List of options needed, set default val for select example: select https://codesandbox.io/s/32pwzwxom */}
						<Select
							name={getFieldName("country")}
							label={<CmsContentRenderer.Span contentKey={cms.country} fallbackValue="Country" />}
							labelId="address-form-country-label"
							value={{
								value: country,
								label: countriesObj[ country ].description,
							}}
							onChange={onCountryChange}
							defaultValue={prefillAddress.country || country}
							options={map(countries, country => ({
								value: country.country,
								label: country.description,
							}))}
							searchable={false}
							error={parentFormHelper.getFieldError(getFieldName("country"))}
							reactSelectClassName={formsStyle.leftSelect}
						/>

					</div>
					<Input
						type="text"
						name={getFieldName("address1")}
						defaultValue={prefillAddress.address1}
						label={isUSA
							? <CmsContentRenderer.Span contentKey={cms.usLineOne} fallbackValue="Address Line 1" />
							: <CmsContentRenderer.Span contentKey={cms.internationalLineOne} fallbackValue="Address Line 1" />}
						overrideClass={formsStyle.inlineInput}
						data-qa="AddressFormAddress1"
						error={parentFormHelper.getFieldError(getFieldName("address1"))}
					/>
					<Input
						type="text"
						label={isUSA
							? <CmsContentRenderer.Span contentKey={cms.usLineTwo} fallbackValue="Address Line 2" />
							: <CmsContentRenderer.Span contentKey={cms.internationalLineTwo} fallbackValue="Address Line 2" />}
						name={getFieldName("address2")}
						defaultValue={prefillAddress.address2}
						overrideClass={formsStyle.inlineInput}
						data-qa="AddressFormAddress2"
						error={parentFormHelper.getFieldError(getFieldName("address2"))}
					/>
					{!isUSA
					// Street Address 3 is for international only
					// https://reflexions.slack.com/archives/CDTQPPXJ8/p1642109215054600
						? (
							<Input
								type="text"
								label={<CmsContentRenderer.Span contentKey={cms.internationalLineThree} fallbackValue="Address Line 3" />}
								name={getFieldName('address3')}
								overrideClass={formsStyle.inlineInput}
								data-qa="AddressFormAddress3"
								error={parentFormHelper.getFieldError(getFieldName("address3"))}
							/>
						)
						: null}
				</div>
				<div
					className={cx(
						formsStyle.row,
						overrideClass,
						editAddressStyle.editAddressStackedFormRow,
					)}
				>
					<Input
						type="text"
						name={getFieldName("city")}
						label={<CmsContentRenderer.Span contentKey={cms.city} fallbackValue="City" />}
						defaultValue={prefillAddress.city}
						overrideClass={cx(formsStyle.inputCity, formsStyle.leftInput)}
						data-qa="AddressFormCity"
						error={parentFormHelper.getFieldError(getFieldName("city"))}
					/>
					<States country={country}>{({ states, statesObj }) => {
						return (states.length ?
							<Select
								name={getFieldName("state")}
								label={<CmsContentRenderer.Span contentKey={cms.state} fallbackValue="State" />}
								labelId="address-form-state-label"
								defaultValue={prefillAddress.state}
								options={map(states, state => ({
									value: state.key,
									label: state.key,
								}))}
								overrideClass={editAddressStyle.statesSelectInput}
								searchable={false}
								onChange={onStateChange}
								error={parentFormHelper.getFieldError(getFieldName("state"))}
								reactSelectClassName={formsStyle.rightSelect}
							/> : null);
					}}</States>
				</div>
				<div
					className={cx(
						formsStyle.row,
						formsStyle.stateZipCountryContainer,
						overrideClass,
						editAddressStyle.editAddressStackedFormRow,
					)}
				>
					<div>
						<Input
							name={getFieldName("postalCode")}
							label={isUSA
								? <CmsContentRenderer.Span contentKey={cms.zipCode} fallbackValue="ZIP Code" />
								: <CmsContentRenderer.Span contentKey={cms.postalCode} fallbackValue="Postal Code" />
							}
							defaultValue={prefillAddress.postalCode}
							overrideClass={cx(formsStyle.inlineInput, formsStyle.leftInput)}
							data-qa="AddressFormPostalCode"
							error={parentFormHelper.getFieldError(getFieldName("postalCode"))}
						/>
					</div>
				</div>
				<IfLoggedIn>
					<div className={editAddressStyle.checkboxWrapper}>
						{saveAddressCheckboxVisibility === SAVE_ADDRESS_SHOWN
								&& <BoolCheckbox
									additionalClasses={editAddressStyle.defaultAddressCheckbox}
									label={<CmsContentRenderer.Span
										className={cx(checkboxStyle.labelText)}
										contentKey={cms.saveAddress}
										fallbackValue="Save this address to my account"
									/>}
									data-qa="SaveAddressCheckbox"
									name={getFieldName("saveAddress")}
									error={parentFormHelper.getFieldError(getFieldName('saveAddress'))}
								/>
						}
						{saveAddressCheckboxVisibility === SAVE_ADDRESS_REQUIRED
								&& <CmsContentRenderer.Span
									contentKey={cms.saveAddressRequired}
									fallbackValue="Address must be saved to your account when associated with a saved payment method."
								/>
						}
						{prefillAddress.primaryBilling
							? <div className={editAddressStyle.defaultAddressDisplayText}>
								<SmallCheck />
								<CmsContentRenderer.Div
									contentKey={cms.defaultBillingLabel}
									fallbackValue="This is the default billing address"
								/>
								<HiddenCheckedInput name={getFieldName("primaryBilling")} />
							</div>
							: <BoolCheckbox
								checked={primaryBilling}
								additionalClasses={editAddressStyle.defaultAddressCheckbox}
								onChange={() => setPrimaryBilling(prevState => !prevState)}
								label={<CmsContentRenderer.Span
									className={checkboxStyle.labelText}
									contentKey={cms.setDefaultBilling}
									fallbackValue="Set this as my default billing address"
								/>}
								data-qa="SetDefaultBillingAddress"
								name={getFieldName("primaryBilling")}
							/>}

						{prefillAddress.primaryShipping
							? <div className={editAddressStyle.defaultAddressDisplayText}>
								<SmallCheck />
								<CmsContentRenderer.Div
									contentKey={cms.defaultShippingLabel}
									fallbackValue="This is the default shipping address"
								/>
								<HiddenCheckedInput name={getFieldName("primaryShipping")} />
							</div>
							: <BoolCheckbox
								checked={primaryShipping}
								additionalClasses={editAddressStyle.defaultAddressCheckbox}
								onChange={() => setPrimaryShipping(prevState => !prevState)}
								label={<CmsContentRenderer.Span
									className={checkboxStyle.labelText}
									contentKey={cms.setDefaultShipping}
									fallbackValue="Set this as my default shipping address"
								/>}
								data-qa="SetDefaultShippingAddress"
								name={getFieldName("primaryShipping")}
							/>}
						{(prefillAddress.primaryBilling || prefillAddress.primaryShipping) &&
								<CmsContentRenderer.Div
									className={editAddressStyle.defaultAddressHelpText}
									contentKey={cms.defaultAddressHelpText}
									fallbackValue={`
									A default billing and shipping address are required.
									To change the default, add or edit another address and set it default.
								`}
								/>
						}
					</div>
					<div className={editAddressStyle.errorMessageWrapper}>
						{hideTopLevelError
							? null
							: parentFormHelper.getFieldErrorJsx('')}
					</div>
				</IfLoggedIn>
			</>}</CmsContentList>
		}</Countries>
	);
};

const HiddenCheckedInput = ({ name }) => (
	<input
		className={editAddressStyle.hiddenInput}
		type="checkbox"
		name={name}
		value={true}
		checked
	/>
);

AddressForm.propTypes = {
	formHelper: PropTypes.instanceOf(FormHelper).isRequired,
	overrideClass: PropTypes.string,
	prefillAddress: PropTypes.object,
	formInputNamePrefix: PropTypes.string,
	saveAddressCheckboxVisibility: PropTypes.oneOf([ SAVE_ADDRESS_HIDDEN, SAVE_ADDRESS_SHOWN, SAVE_ADDRESS_REQUIRED ]),
};

export default AddressForm;
