import { gql } from "@apollo/client";
import React from 'react';
import {
	isEmpty,
	map,
	values,
} from 'lodash';
import PropTypes from "prop-types";
import StdQuery from "./StdQuery.js";
import { useLanguage } from "components/data/Language.js";
import CmsContentListContext, {
	looseRequestPropType,
	normalizeRequests,
} from "./CmsContentListContext.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import PublicAppVars from "utils/PublicAppVars.js";
import { SIZES } from "components/icons/LoadingIcon.js";

const getTranslations = gql`
	query getTranslations (
		$requests: [ ContentRequest! ]!
		$language: String!
		$sharedVariables: JSONObject
		$logMissingContent: Boolean
	){
		translationByList(
			requests: $requests
			language: $language
			sharedVariables: $sharedVariables
			logMissingContent: $logMissingContent
		) {
			id
			key
			content
			customId
		}
	}
`;

export const translationByListToObj = (list, translationByList) => translationByList.reduce((accumulator, value, index) => {
	// note that the requested key might not match the id of the item returned if the translation uses variables
	// we want to key this on the requested key
	// for this to work, the resolver has to return an array of the same length and order as the request
	accumulator[ list[ index ] ] = value.content;
	return accumulator;
}, {});

export const cmsListToMock = (cms, mockData) => (
	{
		request: {
			query: getTranslations,
			variables: {
				requests: values(cms).map(key => { key; }),
				language: PublicAppVars.LANGUAGE,
			},
		},
		result: {
			data: {
				translationByList: mockData.map((mockDatum, index) => ({
					id: values(cms)[ index ],
					content: mockDatum,
				})),
			},
		},
	}
);

/**
 * Returns the full unmodified getTranslations query response
 * @param {Array<String|Object>} list
 * @param sharedVariables
 * @param languageOverride
 */
export const useTranslationByListResult = ({
	/**
	 * A list of CMS keys or ContentRequest objects to query
	 * @type {Array<String|Object>}
	 */
	list,

	/**
	 * @type {Object}
	 */
	sharedVariables,

	/**
	 * Used to query CMS data translations in a language that has not yet been set in the <Language /> context.
	 * For example: 'en', 'es', 'pt', etc.
	 * @type {String|undefined}
	 */
	languageOverride,
}) => {
	const language = useLanguage();
	const requests = normalizeRequests({ list, hasTemplates: !isEmpty(sharedVariables) });

	return useStdQuery(getTranslations, {
		variables: {
			requests,
			language: languageOverride ?? language,
			sharedVariables,
		},
		skip: isEmpty(requests),
	});
};


/**
 * Returns an object with the translations AND the full graphql response
 *
 * @param list
 * @param sharedVariables
 * @param languageOverride
 * @returns object
 */
export const useCmsContentList = ({
	/**
	 * A list of CMS keys or ContentRequest objects to query
	 * @type {Array<String|Object>}
	 */
	list,

	/**
	 * @type {Object}
	 */
	sharedVariables,

	/**
	 * Used to query CMS data translations in a language that has not yet been set in the <Language /> context.
	 * For example: 'en', 'es', 'pt', etc.
	 * @type {String|undefined}
	 */
	languageOverride,
}) => {
	const result = useTranslationByListResult({
		list,
		sharedVariables,
		languageOverride,
	});

	return {
		...result,
		cmsContent: translationByListToObj(list, result.data?.translationByList ?? []),
	};
};

/**
 * Returns an object with only the translations
 * Does not include the error or loading states -- returns an {} in those cases
 */
export const useTranslations = ({
	/**
	 * A list of CMS keys or ContentRequest objects to query
	 * @type {Array<String|Object>}
	 */
	list,

	/**
	 * @type {Object}
	 */
	sharedVariables,

	/**
	 * Used to query CMS data translations in a language that has not yet been set in the <Language /> context.
	 * For example: 'en', 'es', 'pt', etc.
	 * @type {String|undefined}
	 */
	languageOverride,
}) => {
	const { cmsContent } = useCmsContentList({ list, sharedVariables, languageOverride });

	return cmsContent;
};

/**
 * Returns translations AND the full graphql response
 * Puts the translations into context
 * Renders a spinner while loading and the StdQuery error message if the request fails
 *
 * @param children
 * @param list
 * @param spinnerSize
 * @param logMissingContent
 * @param sharedVariables
 * @param languageOverride
 * @returns {JSX.Element}
 * @constructor
 */
const CmsContentList = ({
	children,
	list,
	spinnerSize = SIZES.button,
	logMissingContent = true,
	sharedVariables,
	languageOverride,
}) => {
	const language = useLanguage();

	const requests = normalizeRequests({ list, hasTemplates: Boolean(sharedVariables) });

	return (
		<StdQuery
			query={getTranslations}
			variables={{
				requests,
				language: languageOverride || language,
				logMissingContent,
				sharedVariables,
			}}
			{...{ spinnerSize }}
		>
			{(result) => {
				const cmsContent = translationByListToObj(list, result.data.translationByList);
				const context = {
					...result,
					cmsContent,
				};
				return <CmsContentListContext.Provider value={cmsContent}>
					{children(context)}
				</CmsContentListContext.Provider>;
			}}
		</StdQuery>
	);
};

CmsContentList.propTypes = {
	list: PropTypes.arrayOf(looseRequestPropType).isRequired,
	languageOverride: PropTypes.string,
	logMissingContent: PropTypes.bool,
	sharedVariables: PropTypes.object,
	spinnerSize: PropTypes.oneOf(values(SIZES)),
};

export default CmsContentList;
