import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as icon from 'components/icons/Icon.module.css';
import { wrapper } from 'components/Icon';



const DropdownCharlie =  (props) => wrapper( 
	<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
		<g fill="none" fillRule="evenodd" stroke="currentColor" transform="translate(1 1)">
			<path strokeWidth="1.5" d="M11.6203392,0.545454545 L11.6203392,4.56262391 C11.6203392,5.16511636 11.1086647,5.653533 10.4774821,5.653533 L0,5.653533 L0,9.375799 L12.4721211,9.375799 C13.1033038,9.375799 13.6149783,9.86421564 13.6149783,10.4667081 L13.6149783,14.0764403" />
			<line x1="5.143" x2="5.143" y1="1.091" y2="14.182" strokeLinecap="square" strokeWidth="1.5" />
			<rect width="24" height="14.182" y=".545" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" rx="2.023" />
		</g>
	</svg>,
	props
);


DropdownCharlie.propTypes = { };

export default DropdownCharlie;
