import { padStart } from 'lodash';
import { levels, log } from './Logger.js';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Payment from 'payment';

export const twoDigitMonth = (monthStr) => {
	return padStart(monthStr, 2, '0');
};

export const monthIntToTwoChars = (monthInt) => {
	return twoDigitMonth(monthInt.toString());
};

export const shortYear = (yearInt) => {
	const str = yearInt.toString();
	if (str.length !== 4) {
		log(null, levels.warn, "Expected 4 digit year");
	}
	return str.slice(-2);
};

export const mmYyMonth = mmyy => {
	return parseInt(mmyy.slice(0, 2), 10);
};

export const mmYyYear = mmyy => {
	return parseInt('20' + mmyy.slice(2, 4), 10);
};

export const ccMonthYearDisplay = (month, year) => {
	return monthIntToTwoChars(month) + "/" + shortYear(year);
};

export const ccMonthYearBackoffice = (month, year) => {
	return monthIntToTwoChars(month) + shortYear(year);
};

// formats e.g. 123.456 to 123.46
export const floatToPriceDisplay = (amt) => {
	return Number(amt).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const centsToUnitlessDisplay = (amt) => {
	// toLocaleString inserts thousands separator
	return (amt / 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const centsToUnitlessNumber = (amt) => {
	// no thousands separator
	return (amt / 100).toFixed(2);
};

// format (int) 123456 into (string) "$1,234.56"
// and -123456 to "-$1,234.56"
export const centsToDisplay = (amt) => {
	return (amt / 100).toLocaleString('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		style: 'currency',
		currency: 'USD',
	});
};

// format (int) 12345 into (string) "$1,234.56"
// and -123456 to ($1,234.56)
export const centsToParens = (amt) => {
	if (amt < 0) {
		return "($" + centsToUnitlessDisplay(-1 * amt) + ")";
	}
	return "$" + centsToUnitlessDisplay(amt);
};

// format (int) 12345 into (string) "$1234.56"
// and -123456 to -$1234.56
export const centsToCsv = (amt) => {
	if (amt < 0) {
		return "-$" + centsToUnitlessNumber(-1 * amt);
	}
	return "$" + centsToUnitlessNumber(amt);
};

export const centsToScreenReader = (amt) => {
	return "$" + centsToUnitlessDisplay(amt);
};

// takes a full credit number and returns the last four digits
export const sliceMaskedPAN = (cardNumber) => {
	return cardNumber.slice(cardNumber.length - 4, cardNumber.length);
};

const parsePhoneNumber = (number, defaultCountry) => {
	return parsePhoneNumberFromString(number, { defaultCountry });
};

export const localPhoneNumber = (number, defaultCountry = 'US') => {
	try {
		const phoneNumber = parsePhoneNumber(number, defaultCountry);
		return phoneNumber.nationalNumber;
	}
	catch (error) {
		// Not a phone number, non-existent country, etc.
		return '';
	}
};

/**
 * Parses a phone number string into a 2-character ISO 3166-1 alpha-2 code
 * Note: the e.g. +1 is a country *calling* code. We're returning the country code, e.g. "US"
 * @param number
 * @param defaultCountry
 * @returns {string|CountryCode}
 */
export const phoneNumberToCountryCode = (number, defaultCountry = 'US') => {
	try {
		const phoneNumber = parsePhoneNumber(number, defaultCountry);
		return phoneNumber.country;
	}
	catch (error) {
		// Not a phone number, non-existent country, etc.
		return '';
	}
};

export const ucwords = (str) => {
	return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
		function ($1) {
			return $1.toUpperCase();
		});
};

/* monthIndex uses js numbering where Jan = 0 */
export const localizeMonth = (language, monthIndex) => {
	const date = new Date(2020, monthIndex);
	return new Intl.DateTimeFormat(language, { month: 'short' }).format(date);
};

/* monthIndex uses js numbering where Jan = 0 */
export const localizeWeekDay = (language, weekDayIndex, options = { weekday: 'long' }) => {
	const date = new Date(Date.UTC(0, 0, weekDayIndex, 0, 0, 0));
	return new Intl.DateTimeFormat(language, options).format(date);
};

// Return date string in YYYY-DD-MM format in the date object's timezone
export const ISODatePart = (date) => {
	// convert to the same named date in UTC before using toISOString, which returns a UTC date string
	return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
		.toISOString()
		.split("T")[ 0 ];
};

export const castAmountDueToString = (amountDue) => {
	return amountDue.toString();
};

/*
 * Transform monthIndex for display with leading 0
 * monthIndex uses js numbering where Jan = 0
 */
export const appendMonthNumber = (monthIndex) => {
	const monthNum = monthIndex + 1;
	return monthNum < 10
		? "0" + monthNum
		: monthNum;
};

export const titleCase = (str) => {
	return str.toLowerCase().replace(/(?:^|\s|\/|-)\w/g, (char) => {
		return char.toUpperCase();
	});
};

// "Inner Ferry" to "InnerFerry"
export const removeSpaces = (str) => {
	const titled = titleCase(str);
	return titled.replace(/\s/g, '');
};

export const abbreviatedMonthAndYear = (language, expirationMonth, expirationYear) => {
	return `${localizeMonth(language, expirationMonth - 1)}. ${expirationYear}`;
};

export const dateTimeToAbbreviatedMonthDayYear = (language, dateString) => {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = localizeMonth(language, date.getMonth());
	const year = date.getFullYear();

	return `${month}. ${day}, ${year}`;
};

export const dateTimeToAbbreviatedMonthDay = (language, dateString) => {
	const date = new Date(dateString);
	const day = date.getDate();
	const month = localizeMonth(language, date.getMonth());

	return `${month}. ${day}`;
};

export const makeOrdinal = (number) => {

	const asString = number.toString();
	const onesPlace = asString.split('').pop();
	const tensPlace = asString.length > 1 ? asString.split('').slice(-2)[ 0 ] : null;

	switch (onesPlace) {
		case '1':
			return tensPlace === '1' ? `${number}th` : `${number}st`;
		case '2':
			return tensPlace === '1' ? `${number}th` : `${number}nd`;
		case '3':
			return tensPlace === '1' ? `${number}th` : `${number}rd`;
		default:
			return `${number}th`;
	};
};

export const getCreditCardType = (cardNumber) => {
	// Determine credit card type from credit card number;
	const creditCardType = titleCase(
		Payment.fns.cardType(cardNumber)
	);
	return creditCardType;
};

export const creditCardIsValid = (cardNumber) => {
	// Determine if credit card number is valid;
	return Payment.fns.validateCardNumber(cardNumber);
};
export const getFormattedNameSuffix = ({ alternateName, customerName }) => {
	const alternateShortName = alternateName ? `${alternateName.firstName} ${alternateName.lastName.slice(0, 1)}.` : null;
	const customerShortName = customerName ? `${customerName.firstName} ${customerName.lastName.slice(0, 1)}.` : null;

	if (!alternateShortName || !customerShortName) {
		return {
			alternateNameFormatted: alternateShortName,
			customerNameFormatted: customerShortName,
		};
	}

	const shortNamesAreSame = alternateShortName === customerShortName;

	return {
		alternateNameFormatted: shortNamesAreSame
			? `${alternateName.firstName} ${alternateName.lastName}`
			: alternateShortName,
		customerNameFormatted: shortNamesAreSame
			? `${customerName.firstName} ${customerName.lastName}`
			: customerShortName,
	};
};
