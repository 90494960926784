import React, {
	useState,
	useCallback,
	useEffect,
} from 'react';
import {
	object as yup_object,
	string as yup_string,
} from "yup";
import { Redirect, Link } from 'react-router-dom';
import { values } from 'lodash';
import { stringify } from 'qs';
import cx from 'classnames';

import Container from "components/Container.js";
import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import PasswordValidation from 'components/auth/PasswordValidation.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import restClientMiddleware from 'utils/error-handling/RestClientMiddleware.js';
import { passwordVerification } from 'components/auth/LoginCredentials.js';
import ClientAjax from 'utils/ClientAjax.js';
import Input from 'components/forms/Input.js';
import PreventDefault from 'utils/PreventDefault.js';
import { WireFormHelper } from "utils/FormHelper";

import CmsContentList from 'components/data/CmsContentList.js';
import { useToken, useUsername } from './VerificationCodePanel.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import Toast from 'components/Toast.js';
import { useSessionCheckContext } from 'components/data/session-user/SessionCheck.js';
import useRecaptcha, { recaptchaYupValidations } from 'components/Recaptcha.js';
import { usePostSigninGoToPath } from "components/data/session-user/PostSigninGoToPath.js";

import * as accountCreatedStyles from './AccountCreated.module.css';
import * as loginStyles from "./Login.module.css";
import * as resetPasswordStyles from "pages/auth/ResetPassword.module.css";
import Password from 'components/forms/Password.js';

const cms = {
	header: "miscText.register-verify-csa-header",
	subheader: "miscHtml.register-verify-csa-subheader",
	benefitsCta: "miscText.register-benefits-cta",
	emailInputLabel: 'miscText["register-email-email.label"]',

	resendCta: "miscText.register-verify-resend-cta",
	signinCta: "miscText.register-verify-signin-cta",
	verifyEmailLabel: 'miscText["register-verify-manual-email.label"]',
	verifyTokenLabel: 'miscText["register-verify-manual-verification.label"]',
	submit: "miscText.register-verify-submit",
	confirmResendEmail: 'miscText.general-email-validation-resend-email-confirmation',
	registerSuccessHeader: 'miscText.register-success-header',
	registerSuccessDescription: 'miscText.register-success-description',
	passwordInputLabel: 'miscText["register-email-password.label"]',
};

const getYupSchema = (encrypted, resendEmail = false) => {
	const validations = {};

	if (encrypted) {
		validations.email = yup_string()
			.required('miscText["errors.general.blankfield"]')
			.trim();
	} else {
		validations.email = yup_string()
			.email("miscText.register-error-email")
			.required('miscText["errors.general.blankfield"]')
			.trim();
	}

	// Bail out of validation early and only confirm the email
	if (resendEmail) {
		return yup_object().shape(validations);
	}

	const passwordVerificationRules = passwordVerification({
		passwordField: 'password',
	});

	if (!resendEmail) {
		validations.token = yup_string()
			.min(4, 'miscText["errors.security.invalid_verificationToken"]')
			.required('miscText["errors.general.blankfield"]')
			.trim();
	}

	return yup_object().shape({
		...validations,
		...recaptchaYupValidations,
		password: passwordVerificationRules.password,
	});
};

const AccountCreatedToast = ({ removeToast }) =>
	<CmsContentList list={values(cms)}>{({ cmsContent }) => (
		<Toast
			type="success"
			title={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cms.registerSuccessHeader}
				fallbackValue="Success!"
			/>}
			text={<CmsContentRenderer
				cmsContent={cmsContent}
				contentKey={cms.registerSuccessDescription}
				fallbackValue="Your email has been confirmed."
			/>}
			onClosed={removeToast}
		/>
	)}</CmsContentList>;

const ResendActivationSuccessToast = ({ removeToast }) =>
	<CmsContentList list={values(cms)}>{() => (
		<Toast
			type="success"
			title={<CmsContentRenderer.Span contentKey={cms.confirmResendEmail} fallbackValue={"Email sent"} />}
			onClosed={removeToast}
		/>
	)}</CmsContentList>;

const ActivateAccountCsr = () => {
	const postSigninGoToPath = usePostSigninGoToPath();
	const [ redirect, setRedirect ] = useState(null);
	const { setToast,removeToast } = useGlobalToastsContext();

	// Username & Token from query
	const token = useToken();
	const { username, encrypted } = useUsername();

	// Local state
	const [ emailEncrypted, setEmailEncrypted ] = useState(encrypted ?? false); // true if the email came from the query params
	const [ email, setEmail ] = useState(username || '');
	const [ accessCode, setAccessCode ] = useState(token || '');
	const [ password, setPassword ] = useState('');

	// Make sure there is no race condtion and email is always populated if possible
	useEffect(() => {
		if (email === "" && emailEncrypted) {
			setEmail(username);
		}
	},[ username,emailEncrypted ,email ]);

	// Form Set up
	const [ submittingResend, setSubmittingResend ] = useState(false);
	const formHelperContext = useFormHelper({ getYupSchema: () => getYupSchema(encrypted, submittingResend) });
	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = formHelperContext;

	const { syntheticTimerEvent } = useSessionCheckContext();
	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	// Resend the verification email because the token has expired.
	const resendEmail = async () => {

		// Require plain text email to send the activation link
		if (emailEncrypted) {
			setEmail('');
			setEmailEncrypted(false);
		}

		setSubmittingResend(true);

		formHelper.clearAllErrors();
		await checkRecaptcha();

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmittingResend(false);
			return;
		}

		try {
			await restClientMiddleware(ClientAjax.post("/ajax/resend-activation-link", validated)); // requires plain text email
		} catch (errorReport) {
			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			return;
		} finally {
			setSubmittingResend(false);
			syntheticTimerEvent();
		}

		setToast(<ResendActivationSuccessToast removeToast={removeToast}/>);
	};

	const submitRegisterToken = useCallback(async () => {
		formHelper.clearAllErrors();
		await checkRecaptcha();

		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {

			resetRecaptcha();
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		let response;
		try {
			response = await restClientMiddleware(ClientAjax.post("/ajax/verify-registration-token",
				{
					...validated,
					encrypted: emailEncrypted,
				},
			));
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			resetRecaptcha();

			// if the email came from the query params,
			// clear out the email fields so the user must type in email
			if (emailEncrypted) {
				setEmail("");
				setEmailEncrypted(false);
			}

			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);

			return;
		} finally {
			syntheticTimerEvent();
		}

		if (response.data.success) {
			// The account is now verified, but we don't have credentials to log them in.
			// Send them to the login screen and show a toast notification that their account has been verified.
			setToast(<AccountCreatedToast removeToast={removeToast}/>);

			setRedirect(<Redirect push to={{
				pathname: getPathByRoute(routeKeys.SignIn),
				search: stringify({	postSigninGoToPath }),
			}} />);

		} else if (!response.data.activationEmailSuccess) {
			alert('The link in the email you clicked is no longer valid. A new activation email is on its way. Please try again from the new email message.');
		}
	}, [
		emailEncrypted,
		setEmail,
		setEmailEncrypted,
		checkRecaptcha,
		formHelper,
		postSigninGoToPath,
		resetRecaptcha,
		setSubmitting,
		setToast,
		syntheticTimerEvent,
		removeToast,
	]);

	if (redirect) {
		return redirect;
	}

	return (
		<Container overrideClass={loginStyles.resetPassword}
			data-qa="AccountCreatedContainer"
		>
			<CmsContentList list={values(cms)}>{() => (<>
				<section className={loginStyles.headerContainer}
					data-qa="RegisterHeaderContainer"
				>
					<div className={loginStyles.titleContainer}>
						<div className={loginStyles.header}>
							<CmsContentRenderer.H1
								className={loginStyles.title}
								data-qa="RegisterHeaderTitle"
								contentKey={cms.header}
								fallbackValue='Complete your registration'
							/>
						</div>
					</div>
					<div className={loginStyles.secondaryContent}>
						<div className={loginStyles.text}>
							<Link to='/'
								className={loginStyles.textLink}
								data-qa="LinkToSeeBenefits"
							>
								<CmsContentRenderer.Span
									className={cx(loginStyles.textLinkText, loginStyles.viewText)}
									contentKey={cms.benefitsCta}
									fallbackValue='See the benefits of registering'
								/>
							</Link>
						</div>
					</div>
				</section>

				<CmsContentRenderer.P
					data-qa="RegisterWithEmailSubHeader"
					contentKey={cms.subheader}
					fallbackValue={"<p>You will login using your email and password. Enter the validation code from your email, and create a password below to activate your account.</p>"}
				/>

				<div className={loginStyles.acctCreateTokenWrapper}>
					<WireFormHelper {...{ formHelper }}>
						<form
							method="post"
							ref={formRef}
							onSubmit={PreventDefault(submitRegisterToken)}
						>
							{emailEncrypted
								? <input
									name="email"
									type="hidden"
									value={email}
								/>
								: <Input
									label={<CmsContentRenderer.Span
										contentKey={cms.emailInputLabel}
										fallbackValue="Email" />
									}
									onChange={event => setEmail(event.target.value)}
									defaultValue={email}
									value={email}
									controlled={true}
									error={formHelper.getFieldError('email')}
									name="email"
								/>
							}

							<Input
								label={
									<CmsContentRenderer.Span contentKey={cms.verifyTokenLabel}
										fallbackValue="Verification Code" />}
								onChange={event => setAccessCode(event.target.value)}
								data-qa="tokenInput"
								name="token"
								type="text"
								controlled={true}
								value={accessCode}
								error={formHelper.getFieldError('token')}
							/>

							<Password
								label={<CmsContentRenderer.Span
									contentKey={cms.passwordInputLabel}
									fallbackValue={"Create Password"}
								/>}
								name="password"
								error={formHelper.getFieldError('password')}
								data-qa="passwordInputField"
								autoComplete="new-password"
								onChange={event => setPassword(event.target.value)}
							/>

							<PasswordValidation
								password={password}
								errors={Boolean(formHelper.getFieldError('password'))}
								overrideClass={resetPasswordStyles.passwordValidationBox}
							/>

							{formHelper.getFieldErrorJsx('')}
							<Recaptcha />
							<Button
								isPrimary={true}
								additionalClassNames={accountCreatedStyles.submitButton}
								submitting={submitting}
								data-qa="SubmitRegisterToken"
							>
								<CmsContentRenderer.Span contentKey={cms.submit} fallbackValue="Submit" />
							</Button>

						</form>
					</WireFormHelper>
				</div>

				<Button
					typeStyle={buttonTypeStylePlain}
					data-qa="ResendEmailButton"
					additionalClassNames={cx(loginStyles.textLink, accountCreatedStyles.resendEmailButton)}
					onClick={PreventDefault(resendEmail)}
					isPrimary={false}
					submitting={submittingResend}
				>
					<CmsContentRenderer.Span
						contentKey={cms.resendCta}
						fallbackValue="Resend email"
					/>
				</Button>

				<Button
					typeStyle={buttonTypeStylePlain}
					additionalClassNames={cx(loginStyles.textLink, accountCreatedStyles.resendEmailButton)}
					to={getPathByRoute(routeKeys.SignIn)}
					isPrimary={false}
				>
					<CmsContentRenderer.Span
						contentKey={cms.signinCta}
						fallbackValue="Return to login"
					/>
				</Button>
			</>)}</CmsContentList>
		</Container>
	);
};

export default ActivateAccountCsr;
