/**
 * This Banner appears at the bottom of the Login / Register pages,
 * as well as the bottom of the Card and Account layouts. It has a
 * similar layout to other Banners but with its own unique style,
 * which is why its stored with the
 */
import React from 'react';
import { values } from 'lodash';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Button, { Secondary } from 'components/Button.js';
import { UNREGISTERED } from 'context/TransitAccountIdContext.js';

import * as style from './ContactCustomerService.module.css';

const cms = {
	text: 'miscText.general-contactFooter-text',
	btn: 'miscText.general-contactFooter-button',
};

const ContactCustomerService = ({
	subsystemAccountReference,
	isUnregistered = false,
}) => {

	return (
		<section
			className={style.container}
			data-qa="RegisterFooterContainer"
		>
			<CmsContentList list={values(cms)}>{({ cmsContent }) => (<>
				<div className={style.titleContainer}>
					<div className={style.footer}>
						<CmsContentRenderer.H2
							className={style.title}
							contentKey={cms.text}
							data-qa="RegisterHeaderTitle"
							fallbackValue='Need help with your Charlie Account?'
						/>
					</div>
				</div>

				<div className={style.secondaryFooterContent}>
					<Button
						to={(subsystemAccountReference || isUnregistered)
							? getPathByRoute(routeKeys.ContactUsTransitAccount, { transit_account_id: isUnregistered ? UNREGISTERED : subsystemAccountReference })
							: getPathByRoute(routeKeys.ContactUs)}
						typeStyle={Secondary}
						additionalClassNames={style.btn}
						data-qa="ContactBtn"
						aria-label={cmsContent[ cms.contactAriaLabel ] || "Contact Customer Service"}
					>
						<CmsContentRenderer.Span
							contentKey={cms.btn}
							fallbackValue='Contact Customer Service'
						/>
					</Button>
				</div>
			</>)}</CmsContentList>
		</section>
	);
};

ContactCustomerService.propTypes = {};

export default ContactCustomerService;
