import { clone } from "lodash";

export default class WSAddressExt {
	constructor ({
		address1 = null,
		address2 = null,
		address3 = null,
		city = null,
		state = null,
		country,
		postalCode,
		addressId,
		attentionTo = null,
		primaryBilling = null,
		primaryShipping = null,
		unverified = null,
	}) {
		// (Required) First line of the address.
		// string(64)
		this.address1 = address1;

		// (Conditionally-Required) Second line of the address. Address2 will be required based on country.
		// string(64)
		this.address2 = address2;

		// (Optional) Third line of the address.
		// string(64)
		this.address3 = address3;

		// (Conditionally-Required) The city, town, district or something
		// equivalent, where the address is located within for some specific
		// countries. For all other countries it is a fourth address line. If this field
		// holds city, it is required. If it holds fourth address line, it is optional.
		// string(64)
		this.city = city;

		// (Conditionally-Required) The state, province, county or something
		// equivalent, where the address is located within for some specific
		// countries. For all other countries it is a fifth address line.
		// Standard state codes for some specific countries. For USA, this would
		// be the standard 2-letter state codes. If this field holds state, it is
		// required. If it holds fifth address line, it is optional
		// string(64)
		this.state = state;

		// (Required) Standard ISO 2-letter country code. e.g. US, AU, GB, etc.
		// (ISO 3166-1 alpha-2 standard)
		// For UK member regions/countries/nations it is a 6-letter country code
		// e.g. GB-ENG, GB-SCT, GB-NOR, GB-WLS, GB-CHA (ISO 3166-2
		// standard)
		// string(6)
		this.country = country;

		// (Conditionally-Required) Valid values are country specific. For
		// example, for U.S. addresses, the postal code must be exactly 5 digits.
		// Postal code is required based on the country as all countries don’t
		// have postal code.
		// string(20)
		this.postalCode = postalCode;

		// ===============
		// above are the WSAddress fields. Below are the Ext fields
		// ===============

		// string(40)
		// (Required) Unique reference of the address.
		this.addressId = addressId;

		// string(40)
		// (Optional) It is generally used to put attention to some person on an
		// address.
		this.attentionTo = attentionTo;

		/**
		 * @type {Boolean}
		 * (Optional) Indicates whether this is the primary billing address.
		 */
		this.primaryBilling = primaryBilling;

		/**
		 * @type {Boolean}
		 * (Optional) Indicates whether this is the primary shipping address.
		 */
		this.primaryShipping = primaryShipping;

		/**
		 * @type {Boolean}
		 * (Optional) Indicates whether this address has not
		 * been verified by an address verification service.
		 */
		this.unverified = unverified;
	}

	set(field, value) {
		this[ field ] = value;
		return this;
	}

	static fromBackoffice(data) {
		const result = new WSAddressExt(data);
		return result;
	}

	toBackoffice() {
		return this;
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		const result = new WSAddressExt(data);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = this.addressId;
		return forGraphql;
	}
}

const WSAddressExtFragment = `
	address1: String
	address2: String
	address3: String
	city: String
	state: String
	country: String!
	postalCode: String
	attentionTo: String
	primaryBilling: Boolean
	primaryShipping: Boolean
	unverified: Boolean
`;

// address1 no longer required as of R11
export const WSAddressExtType = [ `
	type WSAddressExt {
		id: ID!
		addressId: String!
		${WSAddressExtFragment}
	}
` ];

// Note: the only place we use this as an input type is on media orders and in that scenario addressId is _not_ required.
export const InputWSAddressExtType = [ `
	input InputWSAddressExt {
		addressId: String
		${WSAddressExtFragment}
	}
` ];
