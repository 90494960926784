import { clone } from "lodash";

// 2.7.6.WSSubsystemFunds
// The WSSubsystemFunds is the class with information of subsystem account funds that can be used as
// payment methods for orders and order line items.

export const FUND_PAYMENT_TYPES = {
	storedValue: "StoredValue",
	benefitValue: "BenefitValue",
};

export default class WSSubsystemFunds {
	constructor({
		subsystem,
		subsystemAccountReference,
		purseType,
		purseTypeDescription,
		purseRestriction,
		purseRestrictionDescription,
		nickname = null,
		balance,
		purseAllocPrecedence,
		suggestedAllocAmount,
		paymentType,
	}) {

		// string(20)
		// (Required) Unique identifier for the subsystem
		// where the travel token is registered.
		this.subsystem = subsystem;

		// string(20)
		// (Required) Unique identifier for the customer’s
		// account in the subsystem.
		this.subsystemAccountReference = subsystemAccountReference;

		// string(40)
		// (Required) Type of purse it is.
		this.purseType = purseType;

		// string(40)
		// (Required) Description of the purse type.
		this.purseTypeDescription = purseTypeDescription;

		// string(40)
		// (Required) Type of the purse restriction.
		this.purseRestriction = purseRestriction;

		// string(40)
		// (Required) Description of the purse restriction.
		this.purseRestrictionDescription = purseRestrictionDescription;

		// string(30)
		// (Optional) The nickname for this purse.
		this.nickname = nickname;

		// int
		// (Required) The total available balance of the purse
		// in cents.
		this.balance = balance;

		// int
		// (Required) Order of precedence to allocate funds
		// from this purse as configured in the subsystem.
		this.purseAllocPrecedence = purseAllocPrecedence;

		// int
		// (Required) The suggested payment allocation
		// amount from this purse in cents.
		this.suggestedAllocAmount = suggestedAllocAmount;

		// string(20)
		// (Required) Indicates the type of payment to be
		// passed in the order if this is selected as a
		// payment method. The valid values are:
		// • StoredValue
		// • BenefitValue
		this.paymentType = paymentType;

	}

	static fromBackoffice(data) {
		const result = new WSSubsystemFunds(data);
		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);
		forGraphql.id = forGraphql.balance + forGraphql.purseAllocPrecedence + forGraphql.paymentType;

		return forGraphql;
	}
}

export const WSSubsystemFundsFragment = `
	subsystem: String!
	subsystemAccountReference: String!
	purseType: String!
	purseTypeDescription: String!
	purseRestriction: String!
	purseRestrictionDescription: String!
	nickname: String
	balance: Int!
	purseAllocPrecedence: Int!
	suggestedAllocAmount: Int!
	paymentType: String!
`;

export const WSSubsystemFundsType = [ `
	type WSSubsystemFunds {
		id: ID!
		${WSSubsystemFundsFragment}
	}
` ];
