import WSAutoloadSubsystemValue from "./WSAutoloadSubsystemValue.js";
import WSAutoloadSubsystemProduct from "./WSAutoloadSubsystemProduct.js";
import WSAutoload, { WSAutoloadFragment } from "./WSAutoload.js";
import { levels, log } from "../../utils/Logger.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

// 2.7.6 WSAutoload
// The Autoload object defines what should be load. This object is abstract. See the concrete
// AutoloadOneAccountValue, AutoloadSubsystemValue and AutoloadSubsystemProduct for details.

export const WSAUTOLOAD_TYPES = {
	addOneAccountValue: 'AddOneAccountValue',
	addSubsystemValue: 'AddSubsystemValue',
	purchaseSubsystemProduct: 'PurchaseSubsystemProduct',
};

export const typeToClass = ({ type }) => {
	switch (type) {
		case WSAUTOLOAD_TYPES.purchaseSubsystemProduct:
			return "WSAutoloadSubsystemProduct";
		case WSAUTOLOAD_TYPES.addSubsystemValue:
			return "WSAutoloadSubsystemValue";
		default:
			log(null, levels.error, `typeToClass: unknown WSAutoload type ${type}`);
			return "WSAutoload";
	}
};

export default class WSAutoloadFactory {
	static buildClass(data) {
		switch (data.type) {
			case WSAUTOLOAD_TYPES.addSubsystemValue:
				return new WSAutoloadSubsystemValue(data);
			case WSAUTOLOAD_TYPES.purchaseSubsystemProduct:
				return new WSAutoloadSubsystemProduct(data);
			default:
				log(null, levels.error, `unknown WSAutoload type ${data.type}`);
				return new WSAutoload(data);
		}
	}

	static fromBackoffice(data) {
		switch (data.type) {
			case WSAUTOLOAD_TYPES.addSubsystemValue:
				return WSAutoloadSubsystemValue.fromBackoffice(data);
			case WSAUTOLOAD_TYPES.purchaseSubsystemProduct:
				return WSAutoloadSubsystemProduct.fromBackoffice(data);
			default:
				log(null, levels.error, `unknown WSAutoload type ${data.type}`);
				return WSAutoload.fromBackoffice(data);
		}
	}

	static fromSerialized(data) {
		if (!data) {
			return null;
		}

		switch (data.type) {
			case WSAUTOLOAD_TYPES.addSubsystemValue:
				return WSAutoloadSubsystemValue.fromSerialized(data);
			case WSAUTOLOAD_TYPES.purchaseSubsystemProduct:
				return WSAutoloadSubsystemProduct.fromSerialized(data);
			default:
				log(null, levels.error, `unknown WSAutoload type ${data.type}`);
				return WSAutoload.fromSerialized(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSAutoloadFactoryInterface = [ `
	interface WSAutoloadFactory {
		${WSAutoloadFragment}
	}`,
];

export const WSAutoloadFactoryType = [
	...WSAutoloadFactoryInterface,
];

export const WSAutoloadFactoryResolvers = {
	WSAutoloadFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType(obj) {
			return typeToClass(obj);
		},
	},
};
