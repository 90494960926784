import { gql } from "@apollo/client";
import React from 'react';
import PropTypes from "prop-types";
import StdQuery from "../StdQuery.js";
import {
	cloneDeep,
	findIndex,
	head,
	isNull,
	map,
	orderBy,
	reverse,
	sortBy,
	remove,
	concat,
} from "lodash";
import { SIZES } from 'components/icons/LoadingIcon.js';
import WSSubsystemAccountInfo from "server/api-types/WSSubsystemAccountInfo.js";
import { RefetchIfOld } from "components/data/ServerTime.js";


export const tokenFragment = `
	id
	tokenActivatedDateTime
	tokenId
	status
	statusDescription
	isArchived
	frontendStatus
	subsystemTokenType
	mediaType
	tokenLastUsageDetails {
		transactionDateTime
		location
	}
	tokenInfo {
		mobileWalletType
		mobileWalletDeviceType
		masterTokenPartial
		tokenNickname
		... on WSSubsystemAccountSmartcardTravelTokenDisplay {
			serialNumber
			cardExpiryDate
		}

		... on WSSubsystemAccountVirtualCardTravelTokenDisplay {
			maskedPan
			expirationMonth
			expirationYear
		}

		... on WSSubsystemAccountBankcardTravelTokenDisplay {
			maskedPan
			expirationMonth
			expirationYear
			creditCardType
		}

		tokenEnablementFee {
			status
			statusReasonCode
			statusLastUpdate
			feeDueAmount
		}
		tokenType
	}`;

export const passFragment = `
	id
	passSKU
	createdDateTime
	travelTokenId
	passDescription
	expirationDateTime
	startDateTime
	endDateTime
	passSerialNbr
	supportsAutoload
	fareProductTypeId
	autoloadEnabled
	supportsTransfer
	firstUseDateTime
	passUseCount
	payments {
		paymentType
	}
`;

export const purseFragment = `
	balance
	purseRestriction
	purseRestrictionDescription
	purseType
	purseTypeDescription
	supportsTransfer
`;

// Fetch TA from Session, ulimately from customer/<customer-id> GET
export const SESSION_TRANSIT_ACCOUNT_GET = gql`query
	SessionTransitAccountGet ($wsSubsystemAccountInfo_Id: ID) {
		subsystemAccountInfoQ (wsSubsystemAccountInfo_Id: $wsSubsystemAccountInfo_Id) {
			id
			nodeTime

			nickname
			subsystemAccountReference
			subsystemAccountDetailedInfo {
				id
				accountCreatedDateTime

				accountStatus
				accountStatusDescription
				accountStatusChangeDateTime

				tokens(archived: false) {
					${tokenFragment}
				}
				debtCollectionInfo {
					action
					amountDue
					bankcardCharged {
						maskedPan
						expirationMonth
						expirationYear
						creditCardType
					}
					attemptInSeconds
					remainingAttemptsForDay
				}

				purses {
					${purseFragment}
				}

				passes {
					${passFragment}
				}
			}

			# WSProgramMembership
			programMemberships {
				id
				programId
				programName
			}
		}
	}
`;

export const sortByStatusAndLastUsage = (subsystemAccountInfoQ) => {
	// Per Alessandro: 4/13
	// https://reflexions.slack.com/archives/GA82SPCTV/p1586799826106700
	// We order transit accounts by status:
	// - Suspended (If debt need to be collected)
	// - Active
	// - Closed
	// - Terminated
	// And of multiple transit accounts have the same status.
	// Lets say we have 3 active transit accounts. We order those by “tokenLastUsageDetails.transactionDateTime”.

	// Tokens should be ordered by the same criteria
	// First order by token status, then by tokenLastUsageDetails.transactionDateTime and then

	// Have orderOfStatus in the reverse order since we use reverse(sortBy())
	const orderOfStatus = [ "Terminated", "Closed", "Active", "Suspended" ];

	// Sort tokens by token status and tokenLastUsageDetails.transactionDateTime
	const sortTokensByStatusAndLastUsage = (tokens) => orderBy(
		tokens,
		[
			// Put tokens that havent been activated last
			// this is null on new cards while tokenActivatedDateTime is still set
			({ tokenLastUsageDetails }) => isNull(tokenLastUsageDetails),

			// Sort tokens by token status.
			// Since the tokens were already ordered by tokenLastUsageDetails.transactionDateTime
			// that order should carry over and be aggregated to ordering by status.
			({ status }) => findIndex(orderOfStatus, ((s) => s === status)),

			// Sort tokens by tokenLastUsageDetails.transactionDateTime.
			({ tokenLastUsageDetails }) => tokenLastUsageDetails?.transactionDateTime,
		],
		[
			'asc',
			'asc',
			'asc',
		]
	);

	// Sort + order tokens in each individual Transit account.
	const TAsWithTokensByStatusAndLastUsage = map(
		subsystemAccountInfoQ,
		(subsystemAccountInfo) => {

			// Clone the original TA object, it cannot be mutated
			// and we'll need to sort and replace tokens.
			const subsystemAccountInfoClone = cloneDeep(subsystemAccountInfo);

			const { subsystemAccountDetailedInfo: { tokens } } = subsystemAccountInfoClone;

			// Replace tokens with the now sorted/ordered tokens.
			subsystemAccountInfoClone.subsystemAccountDetailedInfo.tokens =
				sortTokensByStatusAndLastUsage(tokens);

			return subsystemAccountInfoClone;
		});

	// Sort TAs by TA status and the TA's first token's tokenLastUsageDetails.transactionDateTime
	const sortedTAsByStatusAndLastUsage = reverse(
		sortBy(TAsWithTokensByStatusAndLastUsage, [
			({ subsystemAccountDetailedInfo: { accountStatus } }) =>
				findIndex(orderOfStatus, (status) => status === accountStatus),

			// Sort the grouped status TAs by tokenLastUsageDetails.transactionDateTime:
			// Since the tokens themselves were already ordered by tokenLastUsageDetails.transactionDateTime
			// that order should be carried over and here help us order the TA by the token's
			// tokenLastUsageDetails.transactionDateTime.
			({ subsystemAccountDetailedInfo: { tokens } }) =>
				head(tokens)?.tokenLastUsageDetails?.transactionDateTime,
		])
	);

	const removedNoTransactionDateTime = remove(sortedTAsByStatusAndLastUsage, ({ subsystemAccountDetailedInfo: { tokens } }) =>
		!head(tokens)?.tokenLastUsageDetails?.transactionDateTime);

	return concat(sortedTAsByStatusAndLastUsage, removedNoTransactionDateTime);
};

export const getSessionTransitAccountRefetchQueries = ({
	subSystem = 'ABP',
	subsystemAccountReference = null,
}) => {
	const wsSubsystemAccountInfo_Id = subsystemAccountReference
		? subSystem + subsystemAccountReference
		: null;
	return [
		{ query: SESSION_TRANSIT_ACCOUNT_GET, variables: { wsSubsystemAccountInfo_Id } },
		wsSubsystemAccountInfo_Id !== null && { query: SESSION_TRANSIT_ACCOUNT_GET, variables: { wsSubsystemAccountInfo_Id: null } },
	].filter(x => x);
};

const SessionTransitAccount = ({
	children,
	subSystem = 'ABP',
	subsystemAccountReference = null,
	spinnerSize = SIZES.button,
}) => {
	const wsSubsystemAccountInfo_Id = subsystemAccountReference
		? subSystem + subsystemAccountReference
		: null;

	return (
		<StdQuery
			query={SESSION_TRANSIT_ACCOUNT_GET}
			variables={{ wsSubsystemAccountInfo_Id }}
			{...{ spinnerSize }}
		>
			{({ data, refetch, loading }) => {
				const transitAccounts = map(data.subsystemAccountInfoQ, transitAccout => WSSubsystemAccountInfo.fromSerialized(transitAccout));
				return <>
					{transitAccounts[ 0 ]
						? <RefetchIfOld {...{
							refetch,
							loading,
							queryTime: transitAccounts[ 0 ].nodeTime,
						}} />
						: null
					}

					{children(sortByStatusAndLastUsage(transitAccounts), data)}
				</>;
			}}
		</StdQuery>
	);
};

SessionTransitAccount.propTypes = {
	subSystem: PropTypes.string,
	subsystemAccountReference: PropTypes.string,
};

export default SessionTransitAccount;
