import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useModalContext } from 'context/ModalProvider';

import Panel from 'components/Panel.js';
import Group from 'components/account/Group.js';
import Tooltip from 'components/Tooltip.js';
import JoinGroup from 'components/modals/JoinGroup.js';
import LeaveGroup from 'components/modals/LeaveGroup.js';

import * as panel from 'components/Panel.module.css';

import { sampleGroups } from 'unit-test-sample-data/Match.js';


/**
 * Data-QA Tags:
 * PanelGroupsTitle, PanelGroupsAction, PanelGroupsGroups
 */

const MyGroups = ({
	groups = sampleGroups,
}) => {
	const { setModal } = useModalContext();

	return (
		<Panel>
			<h3 className={cx(panel.heading, panel.groupIcon)}
				data-qa="PanelGroupsTitle"
			>
				My Groups
				<span>
					<Tooltip text="MIRA! helpful info"/>
				</span>

				<button
					className={panel.headingLink}
					onClick={() => setModal(<JoinGroup />)}
					data-qa="PanelGroupsAction"
				>
					Join a group
				</button>
			</h3>

			{sampleGroups.length
				? (
					<div>
						{groups.map(item => (
							<Group
								key={item.id}
								item={item}
								leaveGroup={() => setModal(<LeaveGroup />)}
							/>
						))}
					</div>
				) : (
					<div data-qa="PanelGroupsGroups">
						<div className={panel.card} style={{ marginBottom: '24px' }}>
							<p className={panel.cardText}>You do not belong to any groups</p>
						</div>
					</div>
				)
			}
		</Panel>
	);
};

MyGroups.propTypes = {
	groups: PropTypes.array,
};


export default MyGroups;
