import {
	useCallback,
	useState,
} from 'react';
import { useInterval } from 'react-use';

export const useRestartableInterval = (callback, delay) => {
	// by giving a different delay to useInterval, we can reset its interval
	const [ delayOffset, setDelayOffset ] = useState(0);

	useInterval(callback, delay === null ? null : delay + delayOffset);

	const restartInterval = useCallback(() => {
		setDelayOffset(offset => offset ? 0 : 1);
	}, []);

	return { restartInterval };
};
