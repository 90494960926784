import React from "react";
import {
	values,
	map,
} from 'lodash';
import cx from 'classnames';
import { RadioGroup } from '@headlessui/react';


import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { CARD_ACTIONS, CARD_REASONS } from 'pages/account/card/replacement/constants.js';

import { useFormHelperContext } from 'utils/form-helper/FormHelperProvider.js';
import { useOptionsSelectedContext } from 'pages/account/card/replacement/OptionsSelectedProvider.js';
import Input from 'components/forms/Input.js';


import * as style from 'pages/account/card/BlockOrReplaceFlow.module.css';
import * as forms from 'components/forms/Forms.module.css';

export const ACTION_TYPE_PARAM = "actionType";
export const REASON_TYPE_PARAM = "reasonType";
export const RESOLUTION_TYPE_PARAM = "resolutionType";

const cms = {
	freezeSubHeader: 'miscText.card-freeze-reason-subheader',
	replaceSubHeader: 'miscText.card-freeze-replace-reason-subheader',

	lost: 'miscText.card-freeze-reason-lost',
	lostDetails: 'miscHtml.card-freeze-reason-lost-details',

	stolen: 'miscText.card-freeze-reason-stolen',
	stolenDetails: 'miscHtml.card-freeze-reason-stolen-details',

	expired: 'miscText.card-freeze-reason-expired',

	broken: 'miscText.card-freeze-reason-defective',
	brokenDetails: 'miscHtml.card-freeze-reason-defective-details',
};

// reason codes from https://www.dropbox.com/s/qsiqgyzrbyxrrab/AM-019%20Replace%20a%20Lost%20Charlie%20Card%20%281%29.docx?dl=0
export const BLOCKED_REASON_MAPPING = {
	[ CARD_REASONS.lost ]: {
		action: cms.lost,
		actionFallback: 'My card was lost',
		actionDetails: cms.lostDetails,
		actionDetailsFallback: '<p>(The card will be deactivated to prevent fraudulent use)</p>',
		reasonCode: "752",
	},
	[ CARD_REASONS.stolen ]: {
		action: cms.stolen,
		actionFallback: 'My card was stolen',
		actionDetails: cms.stolenDetails,
		actionDetailsFallback: '<p>(The card will be deactivated to prevent fraudulent use)</p>',
		reasonCode: "733",
	},
};


export const replaceReasonMapping = {
	[ CARD_REASONS.expired ]: {
		action: cms.expired,
		actionFallback: 'My card is expired',
		actionDetailsFallback: '',
		reasonCode: "-3992",
	},
	[ CARD_REASONS.broken ]: {
		action: cms.broken,
		actionFallback: 'It doesn’t work anymore',
		actionDetails: cms.brokenDetails,
		actionDetailsFallback: '<p>Did you receive a defective card? Contact customer service to see if you can replace it for free.</p>',
		reasonCode: "734",
	},
};

const ReasonsOptions = () => {
	const { formHelper } = useFormHelperContext();
	const {
		actionType,
		reasonType, setReasonType,
	} = useOptionsSelectedContext();

	return (
		<CmsContentList list={values(cms)}>{() => (
			<section className={style.actionSelectionSection}>
				<RadioGroup value={reasonType}
					onChange={value => setReasonType(value)}
					className={forms.radioGroup}
				>
					<RadioGroup.Label>
						<CmsContentRenderer.P
							className={style.sectionHeader}
							contentKey={actionType === CARD_ACTIONS.replaceCard
								? cms.replaceSubHeader
								: cms.freezeSubHeader}
							fallbackValue={actionType === CARD_ACTIONS.replaceCard
								? "Why do you need to replace this Charlie Card?"
								: "Why do you need to freeze this Charlie Card?"}
						/>
					</RadioGroup.Label>

					<div className={forms.radioGroupOptions}>
						{map({
							...BLOCKED_REASON_MAPPING,
							...(
								actionType === CARD_ACTIONS.replaceCard
									? replaceReasonMapping
									: {}
							),
						}, (value, key) => {
							return (
								<RadioGroup.Option
									key={key}
									value={value}
									checked={reasonType === key}
									className={cx(forms.radioGroupOption)}
								>
									{({ checked, active }) => (<>
										<CmsContentRenderer.P
											className={cx(forms.radioLabel, checked && forms.radioChecked)}
											contentKey={value.action}
											fallbackValue={value.actionFallback}
										/>

										{value.actionDetails ?
											<CmsContentRenderer.Div
												className={style.actionDetails}
												contentKey={value.actionDetails}
												fallbackValue={value.actionDetailsFallback}
											/>
											: null
										}
									</>)}
								</RadioGroup.Option>
							);
						})}
					</div>
				</RadioGroup>
				<Input
					key={reasonType}
					type="hidden"
					name='reasonType'
					value={reasonType}
					controlled={true}
					hideLabel={true}
					error={formHelper.getFieldError('reasonType')}
				/>
				<Input
					key={'cancel-subscription'}
					type="hidden"
					name="cancel-subscription"
					value={null}
					controlled={true}
					error={formHelper.getFieldError('cancel-subscription')}
				/>
			</section>
		)}</CmsContentList>
	);
};

export default ReasonsOptions;
