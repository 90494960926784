import React from 'react';
import PropTypes from "prop-types";
import { values } from "lodash";
import cx from "classnames";
import CmsContentList from 'components/data/CmsContentList.js';
import { A11y } from '../Icon.js';
import { directionsUrl } from "components/map/DirectionsUrl.js";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";

import * as style from "../../pages/RetailLocations.module.css";
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';

const cms = {
	getDirections: 'miscText.location-results-directions-cta',
	weekday: "miscText.general-days-weekday",
	weekend: "miscText.general-days-weekend",

	kioskRefundable: "miscText.location-results-services-ff-fvm",
	kioskEbt: "miscText.location-results-services-lf-fvm",
	charlieStore: "miscText.location-results-services-apos",
	retailStore: "miscText.location-results-services-app",

	Mo: "miscText.general-days-mon",
	Tu: "miscText.general-days-tue",
	We: "miscText.general-days-wed",
	Th: "miscText.general-days-thu",
	Fr: "miscText.general-days-fri",

	Sa: "miscText.general-days-sat",
	Su: "miscText.general-days-sun",
	Ho: "miscText.general-days-sun",
};

const Weekday = {
	Mo: "Mo",
	Tu: "Tu",
	We: "We",
	Th: "Th",
	Fr: "Fr",
};

const Weekend = {
	Sa: "Sa",
	Su: "Su",
	Ho: "Ho",
};

// BO sends us times with preceeding 0's. Remove the preceeding 0 if possible
const cleanTimeString = (time) => time.charAt(0) === '0' ? time.substring(1) : time;

const getWeekSchedule = (operationSchedule) => {
	const weekdayDays = [];
	const weekend = [];

	if (!operationSchedule || operationSchedule.length === 0) {
		return { weekdayDays, weekend };
	}

	// Get weekdays
	operationSchedule.forEach(day => {
		if (values(Weekday).includes(day.dayOfOperation)) {
			weekdayDays.push({
				...day,
				startTime: cleanTimeString(day.startTime),
				endTime: cleanTimeString(day.endTime),
			});
		}
	});

	// Get weekend
	operationSchedule.forEach(day => {
		if (values(Weekend).includes(day.dayOfOperation)) {
			weekend.push({
				...day,
				startTime: cleanTimeString(day.startTime),
				endTime: cleanTimeString(day.endTime),
			});
		}
	});

	return { weekdayDays, weekend };
};

const Day = ({ cmsContent, day }) => {
	const cmsDay = cms[ day.dayOfOperation ];
	const cmsContentDay = cmsContent[ cmsDay ];

	return (
		<div>
			<span className={style.resultText}>{cmsContentDay}</span>
			<span className={style.retailsHours}>{day.startTime} - {day.endTime}</span>
		</div>
	);
};



const getDeviceTypeCmsContent = deviceType => {
	switch (deviceType.toLowerCase()) {
		case "erm":
			return { deviceKey :cms.kioskEbt, deviceFallback:"Kiosk (EBT accepted)" };
		case "pos":
			return { deviceKey :cms.charlieStore, deviceFallback:"Charlie Store (Refundable transit value cashout available, Mobile fare card activation, EBT accepted)" };
		case "fvm":
			return { deviceKey :cms.kioskRefundable, deviceFallback:"Kiosk (Refundable transit value cashout available, EBT accepted)" };
		case "rtl":
		case "mtl":
			return { deviceKey :cms.retailStore, deviceFallback:"Retail Store (Mobile fare card activation)" };
	}
};

const LocationCard = ({
	onClick,
	overrideClass,
	wsPointOfSaleLocation,
	selectedLocation,
}) => {
	const isSelected = selectedLocation?.id === wsPointOfSaleLocation.id;
	const { weekdayDays, weekend } = getWeekSchedule(wsPointOfSaleLocation?.operationSchedule);

	const weekdayTimesAreAllSame = weekend?.length > 1
		? weekdayDays.every(day => (weekdayDays[ 0 ].startTime === day.startTime && weekdayDays[ 0 ].endTime === day.endTime))
		: false;

	const weekendTimesAreAllSame = weekend?.length > 1
		? (weekend[ 0 ].startTime === weekend[ 1 ].startTime && weekend[ 0 ].endTime === weekend[ 1 ].endTime)
		: false;

	const { deviceKey, deviceFallback } = getDeviceTypeCmsContent(wsPointOfSaleLocation.deviceType);

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) =>
			<div
				id={'wsPointOfSaleLocation-card-' + wsPointOfSaleLocation.id}
				className={cx(style.resultBox, overrideClass, isSelected && style.isSelected)}
				onClick={onClick}
			>
				<div className={style.titleContainer}>
					<h4 className={style.stationTitle}>
						{wsPointOfSaleLocation.name}
					</h4>
					<div className={style.distance}>
						{Math.round(wsPointOfSaleLocation.distanceFromCoordinates, 2)} mi
					</div>
				</div>

				<div className={cx(style.sectionText, style.resultAddress)}>
					{wsPointOfSaleLocation.address.address1},
					<br />
					{wsPointOfSaleLocation.address.postalCode}
				</div>
				<div className={style.resultText}>
					<CmsContentRenderedInline
						contentKey={deviceKey}
						fallbackValue={deviceFallback}
					/>
				</div>

				{weekdayTimesAreAllSame ? (
					<div>
						<CmsContentRenderer.Span
							contentKey={cms.weekday}
							fallbackValue="Weekday"
							className={style.resultText}
						/>
						<span className={style.retailsHours}>{weekdayDays[ 0 ].startTime} - {weekdayDays[ 0 ].endTime}</span>
					</div>
				) : weekdayDays?.map((day, index) => <Day key={`${day.dayOfOperation}-${index}`} {...{ day, cmsContent }} />)}

				{weekendTimesAreAllSame ? (
					<div>
						<CmsContentRenderer.Span
							contentKey={cms.weekend}
							fallbackValue="Weekend"
							className={style.resultText}
						/>
						<span className={style.retailsHours}>{weekend[ 0 ].startTime} - {weekend[ 0 ].endTime}</span>
					</div>
				) : weekend?.map((day, index) => <Day key={`${day.dayOfOperation}-${index}`} {...{ day, cmsContent }} />)}

				<div className={style.getDirections}>
					<div className={style.accessMedia}>
						<A11y overrideClass={style.accessMediaIcon} aria-hidden={true} />
					</div>
					<a
						href={directionsUrl(wsPointOfSaleLocation)}
						target="_blank"
						rel="noreferrer"
					>
						<CmsContentRenderer.Span
							contentKey={cms.getDirections}
							fallbackValue="Get Directions"
							className={cx(
								style.resultText,
								style.colorText,
							)}
						/>
					</a>
				</div>
			</div>
		}</CmsContentList>
	);
};

LocationCard.propTypes = {
	onClick: PropTypes.func,
	wsPointOfSaleLocation: PropTypes.object,
	overrideClass: PropTypes.string,
};

export default LocationCard;
