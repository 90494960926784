// extracted by mini-css-extract-plugin
export var actionBtn = "components-home-__Gallery-module___actionBtn";
export var actions = "components-home-__Gallery-module___actions";
export var col = "components-home-__Gallery-module___col";
export var container = "components-home-__Gallery-module___container";
export var header = "components-home-__Gallery-module___header";
export var launchButton = "components-home-__Gallery-module___launchButton";
export var link = "components-home-__Gallery-module___link";
export var linkImage = "components-home-__Gallery-module___linkImage";
export var linkMedia = "components-home-__Gallery-module___linkMedia";
export var linkText = "components-home-__Gallery-module___linkText";
export var media = "components-home-__Gallery-module___media";
export var text = "components-home-__Gallery-module___text";
export var title = "components-home-__Gallery-module___title";