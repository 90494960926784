import {
	levels,
	noticeError,
} from "utils/Logger.js";
import { addNewPaymentMethod, paymentFieldsFromForm } from "pages/account/purchase/AddPaymentMethod.js";
import { getPaymentInputName } from 'components/account/GetPaymentInputName.js';

import {
	find, findIndex,
} from "lodash";
import { getPaymentMethods } from "pages/account/PurchasePass.js";
import WSAddress from "server/api-types/WSAddress.js";
import WSFundingSourceInfo from "server/api-types/WSFundingSourceInfo.js";
import WSCreditCardReference from "server/api-types/WSCreditCardReference.js";

const findFundingSource = async ({ fundingSourceId, fundingSources, getPaymentMethods }) => {
	const fundingSource = find(
		fundingSources ?? [],
		fundingSource => fundingSource.fundingSourceId === fundingSourceId,
	);

	// This really _shouldn't_ happen. But the replacement funding source api don't return the damn fundingSourceIds for some reason
	// So we have to do this horrible workaround. Cubic said they won't fix.
	if (!fundingSource && getPaymentMethods) {
		const { fundingSources: genericFundingSourceLookupResult } = await getPaymentMethods();

		const { creditCard } = genericFundingSourceLookupResult.find(fundingSource => fundingSource.fundingSourceId === fundingSourceId);

		return fundingSources.find(fundingSource => fundingSource.creditCard.pgCardId === creditCard.pgCardId);
	}

	return fundingSource;
};

// puts errors into formhelper and returns {} on failure
const SavePaymentMethod = async ({
	allFormData,
	isSplit,
	isFirst,
	apolloClient,
	cart,
	subsystemAccountReference,
	updateSelectedFundingSource,
	initializeFundingSources,
	altGetPaymentMethods = null,
}) => {
	const getInputName = (fieldName) => getPaymentInputName({ base: fieldName, isSplit, isFirst });

	const paymentFields = paymentFieldsFromForm(allFormData, getInputName);
	const billingAddressId = paymentFields.addressId ?? null;
	const billingAddress = paymentFields.addressId
		? null
		: new WSAddress(paymentFields);

	const fundingSourceId = await addNewPaymentMethod({
		apolloClient,
		preValidatedData: paymentFields,
		billingAddressId,
		billingAddress,
	});

	const { fundingSources, loadProductLineItemFunds, restrictFunding } = altGetPaymentMethods
		? await altGetPaymentMethods()
		: await getPaymentMethods({
			cart,
			apolloClient,
			subsystemAccountReference,
		});

	initializeFundingSources({
		fundingSources,
		loadProductLineItemFunds,
		restrictFunding,
		refetchFundingSources: altGetPaymentMethods,
	});

	const fundingSource = await findFundingSource({
		fundingSources,
		fundingSourceId,
		// we might need the generic lookup if we used an alternate one
		getPaymentMethods: altGetPaymentMethods
			? () => getPaymentMethods({
				cart,
				apolloClient,
				subsystemAccountReference,
			})
			: null,
	});

	const selectedFundingSource = {
		fundingSource: new WSFundingSourceInfo({
			...fundingSource,
			creditCard: new WSCreditCardReference(fundingSource.creditCard),
		}),
		cvv: paymentFields.cardCvv,
		isNew: false,
	};

	updateSelectedFundingSource({
		splitPaymentIndex: isFirst ? 0 : 1,
		...selectedFundingSource,
	});

	return selectedFundingSource;
};

export default SavePaymentMethod;
