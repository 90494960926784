import WSPaymentHistoryInfo, { WSPaymentHistoryInfoFragment } from "./WSPaymentHistoryInfo.js";
import WSPaymentHistoryCreditDebitInfo from "./WSPaymentHistoryCreditDebitInfo.js";
import WSPaymentHistoryDirectDebitInfo from "./WSPaymentHistoryDirectDebitInfo.js";
import WSPaymentHistoryAccountValueInfo, { WSPaymentHistoryAccountValueInfoType } from "./WSPaymentHistoryAccountValueInfo.js";
import {
	levels,
	log,
} from "utils/Logger.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

const noPayment = 'NoPayment';
const cash = 'Cash';
const check = 'Check';
const storedValue = "StoredValue";
const debitCard = "DebitCard";
const benefitValue = "BenefitValue";
const directDebit = "DirectDebit"; // ACH
const creditCard = "CreditCard";
export const creditCardNotPresent = "CreditCardNotPresent";
const ppb = "PPB"; // Prepaid Benefits (B2B)
const extCollection = "ExtCollection";
const thirdParty = "ThirdParty";

export const BANKCARD_TYPES = [
	debitCard,
	creditCard,
	creditCardNotPresent,
];

// 2.6.14.Payment Type
export const PAYMENT_TYPES = {
	noPayment,
	cash,
	check,
	storedValue,
	debitCard,
	benefitValue,
	directDebit,
	creditCard,
	creditCardNotPresent,
	ppb,
	extCollection,
	thirdParty,
};

export const typeToClass = (paymentType) => {
	switch (paymentType) {
		case PAYMENT_TYPES.storedValue:
			return "WSPaymentHistoryAccountValueInfo";
		case PAYMENT_TYPES.debitCard:
		case PAYMENT_TYPES.creditCard:
		case PAYMENT_TYPES.creditCardNotPresent:
			return "WSPaymentHistoryCreditDebitInfo";
		case PAYMENT_TYPES.directDebit:
			return "WSPaymentHistoryDirectDebitInfo";
		// TODO: add WSPaymentHistoryCashInfo, WSPaymentHistoryB2BInfo classes and use them
		case PAYMENT_TYPES.noPayment:
		case PAYMENT_TYPES.thirdParty:
			// asked which type to use:
			// https://reflexions.slack.com/archives/CA82SPCTV/p1629840881164400
			log(null, levels.info, `typeToClass: saw WSPaymentHistoryInfo paymentType ${paymentType}`);
			return "WSPaymentHistoryInfo";
		default:
			// we're hitting this on type=ThirdParty and type=NoPayment
			log(null, levels.error, `typeToClass: unknown  WSPaymentHistoryInfo paymentType ${paymentType}`);
			return "WSPaymentHistoryInfo";
	}
};

// WSPaymentHistoryCreditDebitInfo extends WSPaymentHistorytInfo when paymentType is
// CreditCard or CreditCardNotPresent or DebitCard.
export default class WSPaymentHistoryInfoFactory {
	static fromBackoffice(data) {
		switch (data.paymentType) {
			case PAYMENT_TYPES.storedValue:
				return WSPaymentHistoryAccountValueInfo.fromBackoffice(data);
			case PAYMENT_TYPES.debitCard:
			case PAYMENT_TYPES.creditCard:
			case PAYMENT_TYPES.creditCardNotPresent:
				return WSPaymentHistoryCreditDebitInfo.fromBackoffice(data);
			case PAYMENT_TYPES.directDebit:
				return WSPaymentHistoryDirectDebitInfo.fromBackoffice(data);
			case PAYMENT_TYPES.noPayment:
			case PAYMENT_TYPES.thirdParty:
				// asked which type to use:
				// https://reflexions.slack.com/archives/CA82SPCTV/p1629840881164400
				log(null, levels.info, `fromBackoffice saw WSPaymentHistoryInfo paymentType ${data.paymentType}`);
				return WSPaymentHistoryInfo.fromBackoffice(data);
			default:
				log(null, levels.error, `unknown  WSPaymentHistoryInfo paymentType ${data.paymentType}`);
				return WSPaymentHistoryInfo.fromBackoffice(data);
		}
	}

	static fromSerialized(data) {
		switch (data.paymentType) {
			case PAYMENT_TYPES.storedValue:
				return WSPaymentHistoryAccountValueInfo.fromSerialized(data);
			case PAYMENT_TYPES.debitCard:
			case PAYMENT_TYPES.creditCard:
			case PAYMENT_TYPES.creditCardNotPresent:
				return WSPaymentHistoryCreditDebitInfo.fromSerialized(data);
			case PAYMENT_TYPES.directDebit:
				return WSPaymentHistoryDirectDebitInfo.fromSerialized(data);
			case PAYMENT_TYPES.noPayment:
			case PAYMENT_TYPES.thirdParty:
				// asked which type to use:
				// https://reflexions.slack.com/archives/CA82SPCTV/p1629840881164400
				log(null, levels.info, `fromSerialized saw WSPaymentHistoryInfo paymentType ${data.paymentType}`);
				return WSPaymentHistoryInfo.fromSerialized(data);
			default:
				log(null, levels.error, `unknown  WSPaymentHistoryInfo paymentType ${data.paymentType}`);
				return WSPaymentHistoryInfo.fromSerialized(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSPaymentHistoryInfoFactoryInterface = [ `
	interface WSPaymentHistoryInfoFactory {
		${WSPaymentHistoryInfoFragment}
	}`,
];

export const WSPaymentHistoryInfoFactoryTypes = [
	...WSPaymentHistoryAccountValueInfoType,
	...WSPaymentHistoryInfoFactoryInterface,
];

export const WSPaymentHistoryInfoFactoryResolvers = {
	WSPaymentHistoryInfoFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inpired by https://stackoverflow.com/a/59520666

		__resolveType({ paymentType }) {
			return typeToClass(paymentType);
		},
	},
};
