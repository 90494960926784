import cx from 'classnames';
import React, { useState } from 'react';
import { values, find } from 'lodash';
import {
	object as yup_object,
	string as yup_string,
} from "yup";

import PreventDefault from 'utils/PreventDefault.js';
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware.js";

import { WireFormHelper } from 'utils/FormHelper.js';

import { noticeError, levels } from 'utils/Logger';
import WSReplacedMedia from 'server/api-types/WSReplacedMedia';
import WSReplacementMedia from 'server/api-types/WSReplacementMedia';
import WSReplaceMediaLineItem from 'server/api-types/WSReplaceMediaLineItem';
import { Redirect } from 'react-router-dom';
import routeKeys from 'CustomerRouteKeys';
import {
	getTransitAccountRefetchQueries,
} from 'components/data/transit-account/TransitAccount';
import ReloadSessionDataQs from 'components/data/session-user/refetch-queries/ReloadSessionData.js';
import { getPathByRoute } from 'App.js';
import { ORDER_REPLACE_MUTATION } from 'components/data/order/replace/OrderReplace.mutation.js';
import { useCustomerTravelCards } from 'components/data/session-user/useCustomerTravelCards.js';
import Select from 'components/forms/inputs/Select.js';
import { DropdownLabel } from "components/FundingPaymentMethods.js";
import { findPrimaryToken, getMasterTokenInfo } from "components/manage-cards/TokenHelpers";
import { getCardNickNameOrType } from 'components/account/card/add-passes/TogglePaymentMethod.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import { USER_TRAVEL_CARDS_QUERY } from 'components/data/session-user/SessionUser.js';
import { useCmsContentList } from "components/data/CmsContentList.js";
import CmsContentListContext from "components/data/CmsContentListContext.js";
import { useStepContext } from 'context/StepContext.js';
import { useMutation } from "@apollo/client";
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import useFormHelper from "utils/form-helper/useFormHelper";
import {
	BLOCK_OR_REPLACE_CARD,
} from 'pages/account/card/BlockOrReplaceFlow.js';
import LoadingIcon from 'components/icons/LoadingIcon.js';
import { useCmsContent } from "components/data/CmsContent.js";
import {
	CharlieCard,
} from 'components/icons/CardBrands.js';
import { TRAVEL_TOKEN_TYPES } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import Toast from 'components/Toast.js';
import { getLast4Digits } from 'components/card-icons/util.js';
import PublicAppVars from 'utils/PublicAppVars';
import { useTerminateTokenMutation } from "components/account/card/token-actions/TerminateToken.js";
import { TOKEN } from "utils/Constants.js";
import {
	BLOCKED_REASON_MAPPING,
} from 'pages/account/card/replacement/ReasonOptions.js';
import { CARD_REASONS } from 'pages/account/card/replacement/constants.js';

import * as tabs from 'styles/Tabs.module.css';
import * as modalStyles from "components/Modal.module.css";

const cms = {
	subHeader: "miscText.card-replace-existing-subheader",
	selectorLabel: "miscText.card-replace-existing-select",
	emptyInputError: "miscText.card-replace-existing-error-missing",
	replacementConfirm: "miscText.card-replace-existing-description-selected",
	replacementEmpty: "miscText.card-replace-existing-description-empty",
	noValidReplacentOptions: "miscText.card-replace-existing-no-valid-options",
	return: "miscText.card-replace-existing-back",
	submit: "miscText.card-replace-existing-submit",
	confirmation: "miscText.card-replace-existing-confirmation",
};

const getTokenNickName = (token) => token?.tokenInfo
	? token?.tokenInfo.tokenNickname
		? (token?.tokenInfo.tokenNickname + " " + getLast4Digits(token?.tokenInfo))
		: getLast4Digits(token?.tokenInfo)
	: "";

const ReplacementSuccess = ({
	replacedToken,
	replacementToken,
}) => {
	const { removeToast } = useGlobalToastsContext();

	const replacedNickname = getTokenNickName(replacedToken);
	const replacementNickname = getTokenNickName(replacementToken);

	// minimize flickering when variables update and the content is refetched
	const cmsContent = useCmsContent(
		// request
		{
			key: cms.confirmation,
			isTemplate: true,
			variables: {
				replacement: replacementNickname,
				thiscard: replacedNickname,
			},
		},
		// fallbackValue
		`You've successfully replaced ${replacedNickname} with ${replacementNickname}. The history for ${replacedNickname} is available on your account from the card selection screen`,
	);

	const title = <CmsContentRenderer.Span
		content={cmsContent}
		contentKey={cms.confirmation}
	/>;

	return <Toast type="success" {...{ title }} onClosed={removeToast} />;
};

const ReplacementSelect = () => {
	const inputName = "select-replacement-dropdown";
	const transit_account_id = useTransitAccountIdContext();

	const [ orderReplaceMutation ] = useMutation(ORDER_REPLACE_MUTATION);
	const [ tokenActionMutation ] = useTerminateTokenMutation({
		queryOptions: {
			refetchQueries: [
				{ query: USER_TRAVEL_CARDS_QUERY },
				...getTransitAccountRefetchQueries(transit_account_id),
			],
			awaitRefetchQueries: true,
		},
	});

	const [ replacement, setReplacement ] = useState(null);
	const [ redirect, setRedirect ] = useState(null);
	const { setStep } = useStepContext();
	const { setToast } = useGlobalToastsContext();

	const {
		data: customerTravelCardsData,
		loading: customerTravelCardsLoading,
	} = useCustomerTravelCards();

	const wsSubsystemAccountInfos = customerTravelCardsData?.subsystemAccountInfoQ ?? [];

	const replacedTransitAccount = find(wsSubsystemAccountInfos, wsSubsystemAccountInfo => {
		return wsSubsystemAccountInfo.subsystemAccountReference === transit_account_id;
	});

	const replacedToken = replacedTransitAccount
		? findPrimaryToken(replacedTransitAccount.subsystemAccountDetailedInfo.tokens)
		: {};

	const replacementTransitAccount = replacement
		? find(wsSubsystemAccountInfos, wsSubsystemAccountInfo => {
			return wsSubsystemAccountInfo.subsystemAccountReference === replacement;
		})
		: {};

	const replacementToken = replacement
		? findPrimaryToken(replacementTransitAccount.subsystemAccountDetailedInfo.tokens)
		: {};

	const cmsContentList = useCmsContentList({
		list: values(cms),
	});

	// minimize flickering when variables update and the content is refetched
	const cmsContent = useCmsContent(
		// request
		{
			key: cms.replacementConfirm,
			isTemplate: true,
			variables: {
				replacement: getTokenNickName(replacementToken),
				thiscard: getTokenNickName(replacedToken),
			},
		},
		// fallbackValue
		`${getTokenNickName(replacedToken)}, along with its balance and passes, will be moved from its existing location to ${getTokenNickName(replacementToken)}. The usage history for ${getTokenNickName(replacedToken)} will still be available from the card selection screen screen.`
	);

	const replacementOptions = wsSubsystemAccountInfos.map(wsSubsystemAccountInfo => {
		const tokenInfo = getMasterTokenInfo(wsSubsystemAccountInfo.subsystemAccountDetailedInfo.tokens);
		const {
			tokenType,
			serialNumber,
			tokenNickname,
		} = tokenInfo ?? {};

		// we can only replace charlie cards
		// https://reflexions.atlassian.net/browse/MBTA-890

		// dont show card being replaced as replacement option
		if (tokenType !== TRAVEL_TOKEN_TYPES.smartcard || wsSubsystemAccountInfo.subsystemAccountReference === transit_account_id) {
			return null;
		}

		const nameOnCard = getCardNickNameOrType(tokenNickname, CharlieCard);

		return {
			label: <DropdownLabel {...{ creditCardType: CharlieCard, nameOnCard, maskedPan: serialNumber.slice(-4) }} />,
			value: wsSubsystemAccountInfo.subsystemAccountReference,
		};
	}).filter(x => x);

	const {
		formHelper,
		formRef,
		submitting,
		setSubmitting,
	} = useFormHelper({
		getYupSchema: () =>
			yup_object().shape({
				[ inputName ]: yup_string()
					.required(
						() => <CmsContentRenderer.Span
							cmsContent={cmsContentList.cmsContent}
							contentKey={cms.emptyInputError}
							fallbackValue="Please select a replacement card"
						/>)
					.trim(),
			}),
	});

	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {
			await formHelper.startValidation(true);
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `validation failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		const replacedMedia = new WSReplacedMedia({
			type: 'TransitAccountMedia',
			subsystem: 'ABP',
			subsystemAccountReference: transit_account_id,
			travelTokenId: replacedToken.tokenId,
			travelTokenNickname: replacedToken.tokenInfo.tokenNickname,
			travelTokenEnablementFeeAmount: '0',
			mediaType: replacedToken.mediaType,
		}).toBackoffice();

		const replacementMedia = new WSReplacementMedia({
			type: 'TransitAccountMedia',
			subsystem: 'ABP',
			subsystemAccountReference: replacementTransitAccount.subsystemAccountReference,
			travelTokenId: replacementToken.tokenId,
			travelTokenNickname: replacementToken.tokenInfo.tokenNickname,
			travelTokenEnablementFeeAmount: '0',
			mediaType: replacementToken.mediaType,
			replacementFeeAmount: 0,
		}).toBackoffice();

		const variables = {
			replacementType: 'ReplaceTransitAccountMedia',
			replaceMediaLineItems: new WSReplaceMediaLineItem({
				replacedMedia,
				replacementMedia,
				itemTotalAmount: 0,
			}).toBackoffice(),
			itemsSubtotalAmount: 0,
			orderTotalAmount: 0,
			reasonCode: '733', // Default to 733 until we provide the user with a reason selection. As per Slack https://reflexions.slack.com/archives/GA82SPCTV/p1621869607099800?thread_ts=1621866231.096500&cid=GA82SPCTV
		};

		try {
			await graphqlErrorMiddleware(
				orderReplaceMutation({
					variables,
					refetchQueries: [
						...ReloadSessionDataQs,
						...getTransitAccountRefetchQueries(transit_account_id),
					],
				}));
		} catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Order Replacement Submit Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);

			return;
		}

		// https://reflexions.atlassian.net/browse/MBTA-2816?search_id=e5e41772-3cf0-4d3d-907a-b5357cc98bdf
		// When replacing a card, manually terminate old card via the front-end
		if (PublicAppVars.TERMINATE_TOKEN_AFTER_REPLACEMENT) {
			const variables = {
				tokenId: replacedToken.tokenId,
				tokenAction: TOKEN.ACTION.terminate,
				subsystemAccountReference: transit_account_id,
				reasonCode: BLOCKED_REASON_MAPPING[ CARD_REASONS.lost ].reasonCode,
			};

			try {
				await graphqlErrorMiddleware(tokenActionMutation({ variables }));
			} catch (errorReport) {
				noticeError(null, levels.info, errorReport, `Order Replacement Terminate Token Failed`);
				formHelper.validationErrorHandler(errorReport);
			} finally {
				setSubmitting(false);
			}
		}

		setSubmitting(false);

		setToast(<ReplacementSuccess {...{ replacedToken, replacementToken }} />);
		setRedirect(
			<Redirect push to={{
				pathname: getPathByRoute(routeKeys.AccountCardSelection),
			}} />,
		);
	};

	if (redirect) {
		return redirect;
	}

	if (customerTravelCardsLoading) {
		return <LoadingIcon />;
	}

	return (
		<CmsContentListContext.Provider value={cmsContentList.cmsContent}>
			<WireFormHelper {...{ formHelper }}>
				<form
					method="post"
					ref={formRef}
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					<>
						<div className={tabs.main}>
							<div className={tabs.creditCardFormContainer}>

								<CmsContentRenderer.H2
									className={tabs.title}
									contentKey={cms.subHeader}
									fallbackValue={"Select Card & Confirm"}
								/>

								{replacementOptions.length
									? <Select
										name={inputName}
										label={
											<CmsContentRenderer.Span contentKey={cms.selectorLabel} fallbackValue="Select a card already on your account" />}
										options={replacementOptions}
										isSearchable={false}
										onChange={({ value: subsystemAccountReference }) => {
											setReplacement(subsystemAccountReference);
											formHelper.clearFieldErrors(inputName);
										}}
										error={formHelper.getFieldError(inputName)}
									/>
									: <CmsContentRenderer.P
										contentKey={cms.noValidReplacentOptions}
										fallbackValue={"Sorry, there are no valid cards for replacement on your account"}
									/>}

								{replacement
									? <CmsContentRenderer.P
										content={cmsContent}
										contentKey={cms.replacementConfirm}
										fallbackValue={`${getTokenNickName(replacedToken)}, along with its balance and passes, will be moved from its existing location to ${getTokenNickName(replacementToken)}. The usage history for ${getTokenNickName(replacedToken)} will still be available from the card selection screen screen.`}
									/>
									: <CmsContentRenderer.P
										contentKey={cms.replacementEmpty}
										fallbackValue={"The selected replacement card, along with its balance and passes, will be moved from its existing location to this card. The usage history for the replacement card will still be available from the card selection screen."}
									/>}

								{formHelper.getFieldErrorJsx('')}
								<div className={modalStyles.actionButtons}>
									<Button
										type="button"
										to={null}
										typeStyle={buttonTypeStylePlain}
										onClick={PreventDefault(() => setStep(BLOCK_OR_REPLACE_CARD))}
										overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
									>
										<CmsContentRenderer.Span contentKey={cms.return}
											fallbackValue="Go Back"
										/>
									</Button>
									<Button
										isPrimary
										type="submit"
										disabled={submitting}
										overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
									>
										<CmsContentRenderer.Span contentKey={cms.submit}
											fallbackValue="Confirm"
										/>
									</Button>
								</div>
							</div>
						</div>
					</>
				</form>
			</WireFormHelper>
		</CmsContentListContext.Provider>
	);
};

export default ReplacementSelect;
