import React, {
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	levels,
	log,
} from "../utils/Logger.js";

export const BreadcrumbContext = React.createContext(() => log(null, levels.error, "BreadcrumbContext used before it was ready"));
export const useGlobalBreadcrumbContext = () => useContext(BreadcrumbContext);

export const useBreadcrumbCallback = (breadcrumbsCallback) => {
	const { setBreadcrumbs } = useGlobalBreadcrumbContext();

	const breadcrumbs = useMemo(breadcrumbsCallback, [ breadcrumbsCallback ]);

	useEffect(() => {
		setBreadcrumbs(breadcrumbs);
	}, [ breadcrumbs, setBreadcrumbs ]);

};

const BreadcrumbProvider = ({ children }) => {
	const [ breadcrumbs, setBreadcrumbs ] = useState([]);

	return (
		<BreadcrumbContext.Provider value={{ breadcrumbs, setBreadcrumbs  }}>
			{children}
		</BreadcrumbContext.Provider>
	);
};
export default BreadcrumbProvider;
