import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import cx from 'classnames';
import { values } from "lodash";
import {
	object as yup_object,
	string as yup_string,
} from "yup";

import Container from "../../components/Container.js";
import Input from "../../components/forms/Input.js";
import Toast from "../../components/Toast";
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import Button, { buttonTypeStylePlain } from "../../components/Button.js";
import { getPathByRoute } from "App.js";
import CmsContentList from "../../components/data/CmsContentList.js";
import CmsContentRenderer from "../../components/data/CmsContentRenderer.js";
import restClientMiddleware from "utils/error-handling/RestClientMiddleware.js";
import FormHelper, { WireFormHelper } from "utils/FormHelper.js";

import { useToken, useUsername } from './VerificationCodePanel.js';
import PreventDefault from "utils/PreventDefault.js";
import ClientAjax from "utils/ClientAjax.js";
import { log, levels } from "utils/Logger.js";
import routeKeys from 'CustomerRouteKeys.js';
import { validateForgotPasswordEmail , redirectAccountLocked } from './ForgotPassword.js';
import { passwordVerification } from 'components/auth/LoginCredentials.js';
import PasswordValidation from 'components/auth/PasswordValidation.js';
import Password from 'components/forms/Password.js';
import { COLLECTIONS, BO_ERRORS, getErrorKey } from "utils/GetErrorKey.js";
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";
import { useSessionCheckContext } from "../../components/data/session-user/SessionCheck.js";

import * as loginStyle from "./Login.module.css";
import * as resetPasswordStyle from "./ResetPassword.module.css";



const cms = {
	verifyHeader: 'miscText.forgot-password-verify-header',
	verifySubheader: 'miscText.forgot-password-verify-subheader',
	tokenLabel: 'miscText["forgot-password-verify-token.label"]',
	emailLabel: 'miscText["forgot-password-verify-email.label"]',
	newPasswordHeader: 'miscText.forgot-password-reset-header',
	newPasswordSubheader: 'miscText.forgot-password-reset-subheader',
	submit: 'miscText.forgot-password-reset-submit',
	resendCTA: 'miscText.forgot-password-verify-resend-cta',
	signinCTA: 'miscText.forgot-password-verify-signin-cta',
	confirmResendEmail: 'miscText.general-email-validation-resend-email-confirmation',
	passwordInputLabel: 'miscText.forgot-password-reset-newpass',
};

const ResetPassword = () => {
	const history = useHistory();
	const location = history?.location;
	const formRef = useRef(null);
	const { syntheticTimerEvent } = useSessionCheckContext();

	const { setToast, removeToast } = useGlobalToastsContext();
	const [ validationState, setValidationState ] = useState({});
	const [ submitting, setSubmitting ] = useState(false);

	const [ redirect, setRedirect ] = useState(false);

	// Username & Token from query
	const token = useToken();
	const { username, encrypted } = useUsername();

	// Local state
	const [ email, setEmail ] = useState(null);

	useEffect(() => {
		if (email === null || email === undefined) {
			setEmail(location?.state?.email
				? location?.state?.email
				: encrypted
					? ''
					: username
			);
		}
	},[ username, encrypted, location, email ]);

	const [ accessCode, setAccessCode ] = useState(token || '');
	const [ password, setPassword ] = useState('');

	const getYupSchema = () => {
		const validations = {
			...validateForgotPasswordEmail(),
			token: yup_string()
				.required(getErrorKey(COLLECTIONS.login, 'verificationToken', BO_ERRORS.general.required))
				.trim(),
			...passwordVerification(),
			...recaptchaYupValidations,
		};
		return yup_object().shape(validations);
	};

	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));

	const formHelper = formHelperRef.current;
	formHelper.onHookedRender(validationState, setValidationState, getYupSchema, () => {
		const formData = formHelper.getFormData();
		if (!('email' in formData)) {
			formData.email = location?.state?.email ?? '';
		}
		return formData;
	});

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const kickoffSubmitResend = async (cmsContent) => {
		await checkRecaptcha();

		setSubmitting(true);
		let username;
		let recaptchaValue;
		try {
			const formData = formHelper.options.getDataToValidate();
			username = await getYupSchema().validateAt('email', formData);
			recaptchaValue = await getYupSchema().validateAt('recaptchaValue', formData);
		} catch (_errorReport) {
			setSubmitting(false);
			alert('Please enter a valid email address.');
			return;
		}

		try {
			await restClientMiddleware(ClientAjax.post("/ajax/request-password-token", { username, recaptchaValue }));
			setToast(<Toast
				type="success"
				title={<CmsContentRenderer
					cmsContent={cmsContent}
					contentKey={cms.confirmResendEmail}
					fallbackValue="Validation email has been resent."
				/>}
				onClosed={removeToast}
			/>);
		} catch (errorReport) {
			resetRecaptcha();

			log(
				null,
				levels.verbose,
				{
					message: `RetrieveAccount server error`,
					errorReport,
				},
			);
		} finally {
			setSubmitting(false);
			syntheticTimerEvent();
		}
	};

	const kickoffSubmitNewPassword = async () => {
		await checkRecaptcha();

		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		} catch (errorReport) {
			setSubmitting(false);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		try {
			const payload = {
				...validated,
				email,
				token: accessCode,
				confirm: validated.password,
			};
			await restClientMiddleware(ClientAjax.post("/ajax/verify-password-token", payload));

			// Redirect to login
			history.push({
				pathname: getPathByRoute(routeKeys.SignIn),
				state: { showNewPasswordNotification: true },
			});
		} catch (errorReport) {
			resetRecaptcha();

			log(
				null,
				levels.verbose,
				{
					message: `ResetPassword server error`,
					errorReport,
				},
			);

			if (errorReport?.display?.topLevelMessage.includes('locked')) {
				redirectAccountLocked(setRedirect, email);
				return;
			}

			formHelper.validationErrorHandler(errorReport);
		} finally {
			setSubmitting(false);
			syntheticTimerEvent();
		}
	};

	if (redirect) {
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<Container
				overrideClass={loginStyle.resetPassword}
				data-qa="ResetPswdMsgContainer"
			>
				<WireFormHelper formHelper={formHelper}>
					<form
						data-qa="ResetPswdManualTokenForm"
						ref={formRef}
						onSubmit={PreventDefault(kickoffSubmitNewPassword)}
					>
						<CmsContentRenderer.H2
							contentKey={cms.verifyHeader}
							className={resetPasswordStyle.header}
							data-qa="ResetPasswordHeaderOne"
							fallbackValue="Enter validation code below"
						/>
						<CmsContentRenderer.P
							contentKey={cms.verifySubheader}
							className={resetPasswordStyle.subText}
							data-qa="ResetPasswordSubTextOne"
							fallbackValue="A validation code was sent to the email you provided. Click the link in that email or manually enter the validation code below, then enter your new password."
						/>

						<Recaptcha />

						<div className={loginStyle.forgotForm}>
							{formHelper.getFieldErrorJsx('')}
							<Input
								value={email}
								defaultValue={email}
								label={<CmsContentRenderer.Span
									fallbackValue="Email"
									contentKey={cms.emailLabel}
								/>}
								type="text"
								name="email"
								autoComplete="email"
								error={formHelper.getFieldError('email')}
								data-qa="ResetPasswordEmailInput"
								onChange={event => setEmail(event.target.value)}
							/>
							<Input
								label={<CmsContentRenderer.Span
									contentKey={cms.tokenLabel}
									fallbackValue="Access Code"
								/>}
								name="token"
								value={accessCode}
								defaultValue={accessCode}
								onChange={event => setAccessCode(event.target.value)}
								autoComplete="one-time-code"
								type="text"
								error={formHelper.getFieldError('token')}
								data-qa="ResetPasswordTokenInput"
							/>
						</div>
						<CmsContentRenderer.H2
							contentKey={cms.newPasswordHeader}
							className={cx(resetPasswordStyle.header, resetPasswordStyle.resetPasswordHeader)}
							data-qa="ResetPasswordHeaderTwo"
							fallbackValue="Reset Your Password"
						/>
						<CmsContentRenderer.P
							contentKey={cms.newPasswordSubheader}
							className={resetPasswordStyle.subText}
							data-qa="ResetPasswordSubTextTwo"
							fallbackValue="To access your Charlie account, please reset your password below."
						/>
						<div className={loginStyle.forgotForm}>
							<Password
								label={<CmsContentRenderer.Span
									contentKey={cms.passwordInputLabel}
									fallbackValue="Create a New Password"
								/>}
								name="password"
								autoComplete="new-password"
								data-qa="ResetPasswordInputPassword"
								onChange={event => setPassword(event.target.value)}
								error={formHelper.getFieldError('password')}
								aria-labelledby="passwordRequirements"
							/>
							<PasswordValidation
								password={password}
								overrideClass={resetPasswordStyle.passwordValidationBox} />
							<Button
								isPrimary
								data-qa="ResetPasswordTokenButton"
								overrideClass={loginStyle.btn}
								disabled={submitting}
							>
								<CmsContentRenderer.Span
									contentKey={cms.submit}
									fallbackValue="Update password"
								/>
							</Button>
						</div>
						<div>
							<Button
								typeStyle={buttonTypeStylePlain}
								className={resetPasswordStyle.resendBtn}
								data-qa="ResendEmailButton"
								onClick={PreventDefault(() => kickoffSubmitResend(cmsContent))}
								disabled={submitting}
							>
								<CmsContentRenderer.Span
									contentKey={cms.resendCTA}
									fallbackValue="Resend email"
								/>
							</Button>
						</div>
						<div className={resetPasswordStyle.returnLink}>
							<Link to={getPathByRoute(routeKeys.SignIn)}>
								<CmsContentRenderer.Span
									contentKey={cms.signinCTA}
									fallbackValue="Return to Log in"
								/>
							</Link>
						</div>
					</form>
				</WireFormHelper>
			</Container>
		)}</CmsContentList>
	);
};

export default ResetPassword;
