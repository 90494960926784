import React from 'react';
import cx from 'classnames';
import { values } from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer';

import Container from 'components/Container.js';
import * as style from './Download.module.css';

import phoneapp from 'components/home/assets/phone-app.png';
import appstore from 'components/home/assets/appstore.png';
import googlebadge from 'components/home/assets/googlebadge.png';

const cms = {
	appTitle: 'miscText.landing-downloadapp-title',
	appText: 'miscText.landing-downloadapp-text',
};


const Download = () => {
	return (
		<CmsContentList list={values(cms)}>{() =>
			<section className={cx(style.infoBlock, style.infoBlockApp)}>
				<Container overrideClass={style.infoBlockContainer}>
					<div className={style.infoBlockMedia}>
						<img src={phoneapp} className={style.infoBlockImage} />
					</div>

					<div className={style.infoBlockContent}>
						<CmsContentRenderer.H2
							contentKey={cms.appTitle}
							className={style.infoBlockTitle}
							fallbackValue="Download the Charlie App"
						/>

						<CmsContentRenderer.P
							contentKey={cms.appText}
							className={style.infoBlockText}
							fallbackValue="Some information about how to download the new Charlie apps will go here."
						/>

						<div className={style.infoBlockActions}>
							<a href="#" className={style.infoBlockAppLink}>
								<img src={appstore}
									className={style.infoBlockAppLinkImage}
									alt="Download on the App Store"
								/>
							</a>

							<a href="#" className={style.infoBlockAppLink}>
								<img src={googlebadge}
									className={style.infoBlockAppLinkImage}
									alt="Get it on Google Play"
								/>
							</a>
						</div>
					</div>
				</Container>
			</section>
		}</CmsContentList>
	);
};

export default Download;
