import React from "react";
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import TransitAccountIdContext from 'context/TransitAccountIdContext.js';
import { StepContextProvider, useStepContext } from 'context/StepContext.js';

import TakeOverLayout from 'layouts/TakeOverLayout.js';
import BlockOrReplaceSelect from 'pages/account/card/BlockOrReplaceSelect.js';
import OptionsSelectedProvider from 'pages/account/card/replacement/OptionsSelectedProvider.js';
import PurchaseReplacementCharlieCardCcForm from 'pages/account/PurchaseReplacementCharlieCardCcForm.js';
import { ReplacementContextProvider } from 'context/ReplacementContext.js';
import CmsContentRendered from "components/data/CmsContentRendered";
import FundingSourcesProvider from "context/FundingSourcesContext.js";
import ReplacementSelect from "components/suspend-replace/ReplacementSelect.js";

const cms = {
	header: 'miscText.card-freeze-header',
};

export const BLOCK_OR_REPLACE_CARD = 0;
export const PURCHASE_REPLACEMENT = 1;
export const TRANSFER_REPLACEMENT = 2;

export const BYPASS_TO_REPLACEMENT = 3;

const CurrentStep = () => {
	const { step } = useStepContext();
	switch (step) {
		case BLOCK_OR_REPLACE_CARD:
			return <BlockOrReplaceSelect />;
		case PURCHASE_REPLACEMENT:
			return <PurchaseReplacementCharlieCardCcForm />;
		case TRANSFER_REPLACEMENT:
			return <ReplacementSelect />;
	}
};


const BlockOrReplaceFlow = () => (
	<FundingSourcesProvider>
		<ReplacementContextProvider>
			<TransitAccountIdContext.Consumer>{(transit_account_id) => (
				<OptionsSelectedProvider>
					<StepContextProvider min={BLOCK_OR_REPLACE_CARD} max={TRANSFER_REPLACEMENT}>
						<TakeOverLayout
							title={<CmsContentRendered contentKey={cms.header} fallbackValue="Block or Replace Card" />}
							showTabs={false}
							showCancel
							cancelLink={getPathByRoute(routeKeys.AccountCardSettings, { transit_account_id })}
							showNickname
						>
							<CurrentStep />
						</TakeOverLayout>
					</StepContextProvider>
				</OptionsSelectedProvider>
			)}</TransitAccountIdContext.Consumer>
		</ReplacementContextProvider>
	</FundingSourcesProvider>
);

export default BlockOrReplaceFlow;
