import React, { useState, useContext } from "react";
import {
	levels,
	log,
} from 'utils/Logger.js';

export const StepContext = React.createContext(() => log(null, levels.error, "StepSet used before it was ready"));
export const useStepContext = () => useContext(StepContext);

export const STEP_OFFSET_PARAM = "step-offset";

export const StepContextProvider = ({
	min,
	max,
	children,
}) => {
	const [ step, setStep ] = useState(0);

	const nextStep = (event) => {
		event?.preventDefault();

		if (step >= min && step < max) {
			//move on to next step
			setStep(step + 1);
		}
		return;
	};

	const prevStep = (event) => {
		event?.preventDefault();

		if (step > min) {
			setStep(step - 1);
		}
		return;
	};

	return (
		<StepContext.Provider value={{ step, setStep, nextStep, prevStep }}>
			{children}
		</StepContext.Provider>
	);
};

export default StepContext;
