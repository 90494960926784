import React, { useEffect, useState } from "react";
import cx from 'classnames';
import { head, includes, isEmpty, map, values } from 'lodash';
import { object as yup_object, string as yup_string } from "yup";

import TakeOverLayout from "../../layouts/TakeOverLayout";
import CmsContentList from "components/data/CmsContentList";
import PreventDefault from "utils/PreventDefault";
import CmsContentRenderer from "components/data/CmsContentRenderer";
import Tooltip from "components/Tooltip";
import RadioInput from "components/forms/inputs/RadioInput";
import Button from "components/Button";
import { Redirect } from "react-router-dom";
import WSAuthCodeDeliveryOption, { TWO_FACTOR_DELIVERY_OPTIONS } from "server/api-types/WSAuthCodeDeliveryOption";
import routeKeys  from "CustomerRouteKeys.js";
import { getPathByRoute } from "App";
import useFormHelper from "utils/form-helper/useFormHelper";
import { GET_TWO_FACTOR_DELIVERY_OPTIONS, POST_TWO_FACTOR_DELIVERY_OPTIONS } from "components/data/TwoFactor";
import StandardMutation from "components/data/StandardMutation";
import { graphqlErrorMiddleware } from "utils/error-handling/graphql/GraphqlClientMiddleware";
import { WireFormHelper } from "utils/FormHelper";

import * as loginStyles from "./Login.module.css";
import * as twoFactorAuthStyles from "./TwoFactorAuth.module.css";
import * as controlStyle from "components/forms/Control.module.css";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import { useGlobalToastsContext } from "context/ToastProvider.js";
import Toast, { TYPE_ERROR } from "components/Toast.js";


const cms = {
	header: 'miscText.signin-2factor-header',
	description: 'miscText.signin-2factor-method-description',
	tooltip: 'miscHtml.signin-2factor-method-tooltip',
	label: 'miscText.signin-2factor-method-label',
	sms: 'miscText.signin-2factor-method-sms',
	email: 'miscText.signin-2factor-method-email',
	next: 'miscText.signin-2factor-method-next',
	method2faError: 'miscText.signin-2factor-method-error-select',
};

const getYupSchema = () => yup_object().shape({
	deliveryOption: yup_string().oneOf(values(TWO_FACTOR_DELIVERY_OPTIONS)).required(),
});

const TwoFactorAuth = () => {
	const {
		data: twoFactorDeliveryData = {},
		loading: twoFactorDeliveryLoading,
		parsedError: twoFactorDeliveryError,
	} = useStdQuery(GET_TWO_FACTOR_DELIVERY_OPTIONS);

	const {
		twoFactorDeliveryOptions: wsAuthCodeDeliveryOptions = [],
	} = twoFactorDeliveryData;

	const {
		setToast,
		removeToast,
	} = useGlobalToastsContext();

	const deliveryChannels = map(wsAuthCodeDeliveryOptions, 'channel');

	const [ deliveryOption, setDeliveryOption ] = useState();
	const [ redirect, setRedirect ] = useState(null);

	const {
		formRef,
		formHelper,
		submitting, setSubmitting,
	} = useFormHelper({ getYupSchema });

	const kickoffSubmit = async (wsAuthCodeDeliveryOptions, postTwoFactorDeliveryOptions) => {
		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation();
			formHelper.clearAllErrors();
		} catch (errorReport) {
			setSubmitting(false);
			formHelper.validationErrorHandler(errorReport);
			return;
		}

		try {
			const authCodeDeliveryOption = wsAuthCodeDeliveryOptions.find(opt => opt.channel === validated.deliveryOption);
			const wsAuthCodeDeliveryOption = new WSAuthCodeDeliveryOption(authCodeDeliveryOption);
			const variables = {
				deliveryOption: wsAuthCodeDeliveryOption,
			};

			await graphqlErrorMiddleware(
				postTwoFactorDeliveryOptions({
					variables,
				}),
			);

			// Happy path - redirect to TwoFactorVerify
			setSubmitting(false);
			setRedirect(<Redirect push to={{
				pathname: getPathByRoute(routeKeys.TwoFactorAuthVerify),
				state: { wsAuthCodeDeliveryOption },
			}} />);
		} catch (errorReport) {
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
		}
	};

	useEffect(() => {
		if (!deliveryOption && !isEmpty(wsAuthCodeDeliveryOptions)) {
			setDeliveryOption(head(wsAuthCodeDeliveryOptions).channel);
		}
	}, [ deliveryOption, wsAuthCodeDeliveryOptions, setDeliveryOption ]);

	useEffect(() => {
		if (twoFactorDeliveryError) {
			setRedirect(<Redirect push to={{ pathname: getPathByRoute(routeKeys.SignIn) }} />);
			setToast(<Toast type={TYPE_ERROR} onClosed={removeToast} title={twoFactorDeliveryError.display.topLevelMessage} />);
			return;
		}

		if (!twoFactorDeliveryLoading && isEmpty(wsAuthCodeDeliveryOptions)) {
			setRedirect(<Redirect push to={{ pathname: getPathByRoute(routeKeys.Home) }} />);
		}
	}, [ setToast, removeToast, twoFactorDeliveryError, twoFactorDeliveryLoading, wsAuthCodeDeliveryOptions ]);

	if (redirect) {
		// this is a <Redirect>
		return redirect;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<TakeOverLayout
				title={<CmsContentRenderer contentKey={cms.header} fallbackValue="Two-Factor Authentication" />}
				hideCancel
				data-qa="twoFactorAuthTakeover"
			>
				<div className={loginStyles.container}>
					<StandardMutation mutation={POST_TWO_FACTOR_DELIVERY_OPTIONS}
						showLoadingState={false}>{(postTwoFactorDeliveryOptions) => (
							<WireFormHelper {...{ formHelper }}>
								<form
									className={loginStyles.registerForm}
									data-qa="verificationCodeForm"
									ref={formRef}
									onSubmit={PreventDefault(() => kickoffSubmit(wsAuthCodeDeliveryOptions, postTwoFactorDeliveryOptions))}
								>
									<div>
										<CmsContentRenderer.Span
											id="delivery-options-2fa-description"
											contentKey={cms.description}
											fallbackValue="To continue to your account, you will need to enter a verification code. Choose how you want to receive it."
										/>
										<Tooltip
											tooltipId={'verificationCodeFormToolTip'}
											overrideClass={twoFactorAuthStyles.twoFactorIcon}
											ariaLabelPanel={cmsContent[ cms.tooltip ] || "Two-factor authentication adds an extra layer of security during account login, sending a unique code via email or text message to ensure you're the account owner"}
										>
											<CmsContentRenderer.Div
												className={twoFactorAuthStyles.twoFactorTooltip}
												contentKey={cms.tooltip}
												fallbackValue="<p><strong>Two-factor authentication</strong> adds an extra layer of security during account login, sending a unique code via email or text message to ensure you're the account owner.</p>"
												rawHtml
											/>
										</Tooltip>
									</div>
									<div
										className={twoFactorAuthStyles.methodRadioContainer}
										role="group"
										aria-describedby="delivery-options-2fa-description"
									>
										{includes(deliveryChannels, TWO_FACTOR_DELIVERY_OPTIONS.sms) &&
										<RadioInput
											additionalClasses={cx(twoFactorAuthStyles.radioInput, {
												[ twoFactorAuthStyles.selected ]: deliveryOption === TWO_FACTOR_DELIVERY_OPTIONS.sms,
											})}
											name="deliveryOption"
											label={<span
												className={cx(controlStyle.label, twoFactorAuthStyles.radioLabel)}>
												<CmsContentRenderer.Span
													contentKey={cms.sms}
													fallbackValue="By SMS/text"
												/>
												<MaskedDeliveryOption
													{...{ wsAuthCodeDeliveryOptions }}
													channel={TWO_FACTOR_DELIVERY_OPTIONS.sms}
												/>
											</span>}
											checked={deliveryOption === TWO_FACTOR_DELIVERY_OPTIONS.sms}
											value={TWO_FACTOR_DELIVERY_OPTIONS.sms}
											onClick={() => setDeliveryOption(TWO_FACTOR_DELIVERY_OPTIONS.sms)}
											error={formHelper.getFieldError('deliveryOption')}
											controlled
										/>
										}
										{includes(deliveryChannels, TWO_FACTOR_DELIVERY_OPTIONS.email) &&
										<RadioInput
											additionalClasses={cx(twoFactorAuthStyles.radioInput, {
												[ twoFactorAuthStyles.selected ]: deliveryOption === TWO_FACTOR_DELIVERY_OPTIONS.email,
											})}
											name="deliveryOption"
											label={<span
												className={cx(controlStyle.label, twoFactorAuthStyles.radioLabel)}>
												<CmsContentRenderer.Span
													contentKey={cms.email}
													fallbackValue="By email"
												/>
												<MaskedDeliveryOption
													{...{ wsAuthCodeDeliveryOptions }}
													channel={TWO_FACTOR_DELIVERY_OPTIONS.email}
												/>
											</span>}
											checked={deliveryOption === TWO_FACTOR_DELIVERY_OPTIONS.email}
											value={TWO_FACTOR_DELIVERY_OPTIONS.email}
											onClick={() => setDeliveryOption(TWO_FACTOR_DELIVERY_OPTIONS.email)}
											error={formHelper.getFieldError('deliveryOption')}
											controlled
										/>
										}
									</div>
									{formHelper.getFieldErrorJsx('')}
									<div className={loginStyles.formActions}>
										<Button
											isPrimary={true}
											data-qa="SubmitTwoFactorDeliveryMethodBtn"
											submitting={submitting}
										>
											<CmsContentRenderer contentKey={cms.next} fallbackValue="Next" />
										</Button>
									</div>
								</form>
							</WireFormHelper>
						)}</StandardMutation>
				</div>
			</TakeOverLayout>
		)}</CmsContentList>
	);
};

const MaskedDeliveryOption = ({ wsAuthCodeDeliveryOptions, channel }) => {
	const deliveryOption = wsAuthCodeDeliveryOptions.find(wsAuthCodeDeliveryOption => {
		return wsAuthCodeDeliveryOption.channel === channel;
	});
	return <span>{deliveryOption?.maskedDestination ?? null}</span>;
};

export default TwoFactorAuth;
