import { useApolloClient } from "@apollo/client";
import React, {
	useRef,
	useEffect,
	useState,
} from 'react';
import {
	head,
	values,
} from 'lodash';

import CmsContentList from 'components/data/CmsContentList.js';

import FormHelper from 'utils/FormHelper.js';
import { Lifecycles } from 'libreact/lib/Lifecycles';
import {
	getCartBalance,
	useCartContext,
} from 'context/CartProvider.js';
import PreventDefault from 'utils/PreventDefault.js';

import Cart from "components/payments/Cart.js";
import { getPaymentMethods } from 'pages/account/PurchasePass.js';
import { usePurseBalanceContext } from 'context/PurseBalanceContext.js';
import {
	levels,
	noticeError,
} from "utils/Logger.js";
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import { incrementError } from 'components/account/purchases/ValuePurchaseTypeSelection.js';
import { PURCHASE_FLOW_STEP_1_FORM_ID } from 'components/account/reload-balance/consts.js';
import { useStepContext } from 'context/StepContext.js';
import { RIDER_CLASSES } from 'components/data/transit-account/TransitAccount.js';
import { useGetSubsystemProductCatalog } from 'components/data/subsystem/ProductCatalog.query.js';
import { PURCHASE_CHARLIE_CARD_STEPS } from 'pages/account/PurchaseCharlieCard.js';
import { getPurchaseCatalogTabs } from 'pages/account/purchase/product-catalog-takeover/ProductTabs.js';

import ProductTakeOverLayout from 'pages/account/purchase/product-catalog-takeover/ProductTakeOverLayout.js';

import { cms as purchaseCmsKeys } from 'pages/account/purchase/product-catalog-takeover/constants.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import ProductCatalogContext from "components/data/transit-account/ProductCatalogContext.js";

const cms = {
	emptySelectionError: "miscText.purchase-cart-error-empty",
	incrementError,
};

const mapWsAccountProductToWsTransitAccountProduct = ({
	fareProductTypeId,
	fareProductFamilyId,
	name,
	nameShort,
	nameLong,
	productSKU,
	...rest
}) => ({
	__typename: 'WSTransitAccountProduct',
	productTypeId: fareProductTypeId,
	productFamilyId: fareProductFamilyId,
	productName: name,
	productShortName: nameShort,
	productLongName: nameLong,
	productSku: productSKU,
	...rest,
});

export const mapWsProductCatalogToTransitAccountCatalog = ({
	valueIncrement,
	minAddValue,
	maxAddValue,
	valueOptions,
	products,
	...rest
}) => ({
	valueIncrement,
	minAddTransitValue: minAddValue,
	maxAddTransitValue: maxAddValue,
	transitValueOptions: valueOptions,
	products: products.map(mapWsAccountProductToWsTransitAccountProduct),
	...rest,
});

const PurchaseCharlieCardSelectProducts = ({
	cancelUrl,
	title: titleProp,
	showNickname = true,
	showTakeOverLayout = true,
}) => {
	const { step, nextStep } = useStepContext();
	const {
		cart,
		setNegativeBalance,
		saveShoppingCartId,
	} = useCartContext();

	const { initializeFundingSources } = useFundingSourcesContext();
	const [ submitting, setSubmitting ] = useState(false);
	const apolloClient = useApolloClient();
	const { purses, purseTotal } = usePurseBalanceContext();
	const wSSubsystemPurse = head(purses);
	const [ validationState, setValidationState ] = useState({});

	const wsProductCatalog = useGetSubsystemProductCatalog({ riderClassId: RIDER_CLASSES.fullFare });

	const formRef = useRef(null);
	const formHelperRef = useRef(new FormHelper({
		formRef,
	}));
	const formHelper = formHelperRef.current;

	formHelper.onHookedRender(
		validationState,
		setValidationState,
	);

	const productCatalog = mapWsProductCatalogToTransitAccountCatalog(wsProductCatalog);

	const purchaseProductCatalogTabs = getPurchaseCatalogTabs({ formHelper, setValidationState, productCatalog });

	const kickoffSubmit = async () => {
		setSubmitting(true);

		if (cart.products.length === 0 && (getCartBalance(cart) - cart.negativeBalance - cart.unpaidFee) === 0) {
			setValidationState({
				validationError: FormHelper.cmsKeyToJsx(cms.emptySelectionError),
			});
			setSubmitting(false);
			return;
		}

		let paymentMethodsRes;
		try {
			paymentMethodsRes = await getPaymentMethods({
				cart,
				apolloClient,
				subsystemAccountReference: null,
				wSSubsystemPurse,
			});
		} catch (errorReport) {
			// we're not redirecting anywhere. Prepare the form for the next submit.
			noticeError(null, levels.info, errorReport, `Get Payment Methods Failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		const {
			shoppingCartId,
			loadProductLineItemFunds,
			fundingSources,
			restrictFunding,
		} = paymentMethodsRes;

		saveShoppingCartId(shoppingCartId);
		initializeFundingSources({
			loadProductLineItemFunds,
			fundingSources, // might be empty
			restrictFunding,
		});

		setSubmitting(false);
		nextStep();
	};

	const didMount = () => {
		formHelper.wireInputs();
	};

	// When an account has a negative balance should we reflect the negative balance in the initial cart total
	useEffect(() => {
		if (!cart.negativeBalance && purseTotal < 0) {
			setNegativeBalance(purseTotal);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => {
			return (
				<Lifecycles didMount={didMount}>
					<form
						id={PURCHASE_FLOW_STEP_1_FORM_ID}
						data-qa={PURCHASE_FLOW_STEP_1_FORM_ID}
						ref={formRef}
						onSubmit={PreventDefault(kickoffSubmit)}
					>
						<ProductCatalogContext.Provider value={productCatalog}>
							<ProductTakeOverLayout
								showCancel
								showTabs
								title={titleProp ?? <CmsContentRenderedInline
									contentKey={purchaseCmsKeys.purchasNewProduct}
									fallbackValue="Purchase a New Product"
								/>}
								steps={PURCHASE_CHARLIE_CARD_STEPS}
								currentStep={step}
								tabs={purchaseProductCatalogTabs}
								cancelLink={cancelUrl}
								{...{ showNickname, showTakeOverLayout }}
							/>
						</ProductCatalogContext.Provider>
						<Cart formHelper={formHelper} {...{ submitting }} />
					</form>
				</Lifecycles>
			);
		}}</CmsContentList>
	);
};

export default PurchaseCharlieCardSelectProducts;
