// extracted by mini-css-extract-plugin
export var actionButtons = "pages-account-card-__Settings-module___actionButtons";
export var cardAction = "pages-account-card-__Settings-module___cardAction";
export var cardActions = "pages-account-card-__Settings-module___cardActions";
export var cardDetails = "pages-account-card-__Settings-module___cardDetails";
export var cardDetailsContainer = "pages-account-card-__Settings-module___cardDetailsContainer";
export var cardImage = "pages-account-card-__Settings-module___cardImage";
export var cardInfo = "pages-account-card-__Settings-module___cardInfo";
export var cardInfoLabel = "pages-account-card-__Settings-module___cardInfoLabel";
export var cardInfoValue = "pages-account-card-__Settings-module___cardInfoValue";
export var cardList = "pages-account-card-__Settings-module___cardList";
export var cardPanels = "pages-account-card-__Settings-module___cardPanels";
export var container = "pages-account-card-__Settings-module___container";
export var dangerUnlink = "pages-account-card-__Settings-module___dangerUnlink";
export var isActive = "pages-account-card-__Settings-module___isActive";
export var mainSettingsPanel = "pages-account-card-__Settings-module___mainSettingsPanel";
export var pimaryColorNoBorder = "pages-account-card-__Settings-module___pimaryColorNoBorder";
export var removeBtn = "pages-account-card-__Settings-module___removeBtn";
export var removeCard = "pages-account-card-__Settings-module___removeCard";
export var removeCardBtn = "pages-account-card-__Settings-module___removeCardBtn";
export var settingContainer = "pages-account-card-__Settings-module___settingContainer";
export var settingsTitle = "pages-account-card-__Settings-module___settingsTitle";
export var subContainer = "pages-account-card-__Settings-module___subContainer";
export var switchBtn = "pages-account-card-__Settings-module___switchBtn";
export var virtualCard = "pages-account-card-__Settings-module___virtualCard";
export var virtualCardTitle = "pages-account-card-__Settings-module___virtualCardTitle";
export var virtualContainer = "pages-account-card-__Settings-module___virtualContainer";