import React from 'react';
import TokenNameAndPan from 'components/account/card/TokenNameAndPan.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import TransitAccount from 'components/data/transit-account/TransitAccount.js';
import { SIZES } from 'components/icons/LoadingIcon.js';
import { findPrimaryToken } from 'components/manage-cards/TokenHelpers.js';
import { CardImage } from 'components/icons/CardBrands.js';
import { getCreditCardType } from 'components/card-icons/util.js';

import * as layout from 'layouts/TakeOverLayout.module.css';

/**
 * Get the ccard type icon and CardIconAndNickname / Last 4 for the currently
 * selected card
 */
const CardIconAndNickname = ({
	showCardIcon = true,
	showName = true,
}) => {
	const transit_account_id = useTransitAccountIdContext();

	return (
		<TransitAccount
			{...{
				subsystemAccountReference: transit_account_id,
				subscriptions: false,
			}}
			spinnerSize={SIZES.cmsContent}
		// eslint-disable-next-line complexity
		>{({ transitAccountQ }) => {

				const primaryToken = findPrimaryToken(transitAccountQ.tokens);
				const primaryTokenInfo = primaryToken.tokenInfo;

				if (!primaryTokenInfo) {
					throw new Error("Missing primaryTokenInfo");
				}

				return (
					<div className={layout.cardDetails}>
						{showCardIcon &&
							<div className={layout.cardMedia}>
								<CardImage
									creditCardType={getCreditCardType(primaryTokenInfo)}
									mobileWalletType={primaryTokenInfo.mobileWalletType}
									status={primaryToken.frontEndStatus}
								/>
							</div>
						}
						<div className={layout.cardName}>
							<TokenNameAndPan tokenInfo={primaryTokenInfo} mediaType={primaryToken.mediaType} showName={showName} elementType='span' />
						</div>
					</div>
				);

			}}</TransitAccount>
	);
};

export default CardIconAndNickname;
