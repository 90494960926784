/**
 * This Banner appears on Login page if the user is idle for too long, or if they logout intentionally
 */
import React from 'react';
import { values } from 'lodash';
import { useCookies } from 'react-cookie';

import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRendered from "components/data/CmsContentRendered.js";

import Banner, { TYPE_WARNING } from 'components/Banner.js';

import { USER_LOGGED_OUT_BANNER } from 'layouts/components/AccountSelector.js';
import { SESSION_EXPIRED_BANNER } from 'components/data/session-user/SessionCheck.js';

const cookieOptions = { path: '/' };

const cms = {
	title: 'miscText["timeout-banner.title"]',
	clickedLogoutTitle: 'miscText["logout-banner.title"]',
};

const LoggedOutBanner = () => {
	const [ cookies, ,removeCookie ] = useCookies([ USER_LOGGED_OUT_BANNER, SESSION_EXPIRED_BANNER ]);

	const isUserLoggedOut = cookies[ USER_LOGGED_OUT_BANNER ];
	const isSessionTimedOut = cookies[ SESSION_EXPIRED_BANNER ];

	const onBannerClose = () => {
		[
			USER_LOGGED_OUT_BANNER,
			SESSION_EXPIRED_BANNER,
		].forEach(cookieName => removeCookie(cookieName, cookieOptions));
	};

	if (!isUserLoggedOut && !isSessionTimedOut) {
		return "";
	}

	return (
		<CmsContentList list={values(cms)}>{() => (
			<Banner
				{...{ onBannerClose }}
				type={TYPE_WARNING}
				title={<CmsContentRendered.Span
					contentKey={isUserLoggedOut ? cms.clickedLogoutTitle : cms.title}
					fallbackValue={`Logout Successful`}
				/>}
			/>
		)}</CmsContentList>
	);
};

export default LoggedOutBanner;
