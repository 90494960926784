import React, { useMemo } from 'react';
import cx from 'classnames';
import {
	values,
	map,
	join,
	includes, head,
} from 'lodash';
import {
	compareDesc,
	addDays,
} from 'date-fns';
import { stringify } from "qs";

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { useEmvCanReload } from "components/data/transit-account/EMV.helpers.js";
import CmsContentRendered from 'components/data/CmsContentRendered.js';
import CmsContentList from '../data/CmsContentList.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import { useTransitAccountIdContext } from 'context/TransitAccountIdContext.js';
import { IfLoggedIn } from 'components/data/session-user/LoggingIn.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { useLanguage } from "components/data/Language.js";
import { dateTimeToAbbreviatedMonthDayYear, dateTimeToAbbreviatedMonthDay } from "utils/FormatHelpers.js";
import SubscriptionInfo from 'components/data/transit-account/autoload/SubscriptionInfo.query.js';

import { getTransitModeIcon } from 'components/icons/TransitModes.js';
import Tooltip from 'components/Tooltip.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import { AutoloadSuspendedStatuses } from 'server/api-types/WSAutoloadSummary.js';
import ManageMenu from 'components/account/ManageMenu.js';
import { ActiveAutoload, SuspendedAutoload, getAutoloadOptions } from 'components/account/CardSummary.js';
import { LONG_TERM_PASS_DAYS_MIN } from 'utils/Constants.js';



import * as plist from './ProductList.module.css';
import * as typography from "styles/typography.module.css";
import * as cardSumStyle from 'components/account/CardSummary.module.css';
import * as manageMenuStyle from 'components/account/ManageMenu.module.css';
import * as tooltipStyle from 'components/Tooltip.module.css';




const cms = {
	passCurrent: 'miscText.cardinfo-pass-current',
	passNext: 'miscText.cardinfo-pass-next',

	startsOn: 'miscText.cardinfo-pass-starts',
	startsOnNext: 'miscText.cardinfo-pass-firsttap-afterdate',
	endsOn: 'miscText.cardinfo-pass-ends',
	firstTap: "miscText.cardinfo-pass-firsttap",
	firstTapNext: 'miscText.cardinfo-pass-firsttap-aftercurrent',

	reloadPass: 'miscText.cardinfo-reload-pass',
	setupAutoload: 'miscText.autoload-setup-cta',
	autoloadTooltip: 'miscText.autoload-tooltip',

	autoloadGroup: 'miscText.autoload-group',

	autoloadWith: 'miscText.autoload-with',
	autoLoadSetDate: 'miscText.autoload-setdate',
	autoloadRecurring: 'miscText.autoload-recurring',

	autoloadSuspended: 'miscText.autoload-suspended',
	suspendedTooltipBalance: 'miscText.autoload-suspended-tooltip-balance',

	autoloadPassUnavailable: 'miscText.autoload-unavailable',
	unavailablePassTooltip: 'miscText.autoload-unavailable-tooltip-na',

	editAutoload: 'miscText.autoload-manage-edit',
	pauseAutoload: 'miscText.autoload-manage-pause',
	cancelAutoload: 'miscText.autoload-manage-cancel',
};

{/*
	references, design and integration guide with passes logic gathered in the ticket
	https://reflexions.atlassian.net/browse/MBTA-1582
	TODO implement trip-based passes when BO will start support
*/
}
export const getPassActiveStatus = (wsSubsystemAccountPass) => {
	const {
		// startDateTime
		// datetime
		// (Optional) The date and time that starts the validity
		// period of the pass.
		startDateTime,

		// endDateTime
		// datetime
		// (Optional) The date and time that ends the validity
		// period of the pass.
		endDateTime,
	} = wsSubsystemAccountPass;

	return (startDateTime && endDateTime);
};

export const passWillExpire = (wsSubsystemAccountPass) => {
	const {
		endDateTime,
	} = wsSubsystemAccountPass;

	const expires = endDateTime ? new Date(endDateTime) : null;

	if (!expires) {
		return false;
	}

	// PASS_PRODUCT_EXPIRES_WARNING: Value in seconds, defaults to 3 days;
	// getTime() returns the number of milliseconds since January 1, 1970.
	// so multiply PASS_PRODUCT_EXPIRES_WARNING by 1000 to get milliseconds
	const timeToWarn = new Date(expires.getTime() - (PublicAppVars.PASS_PRODUCT_EXPIRES_WARNING * 1000));

	return (new Date() > timeToWarn);
};

export const passHasStarted = (wsSubsystemAccountPass) => {
	const now = new Date();
	const {
		startDateTime,
	} = wsSubsystemAccountPass;

	// if startDate is BEFORE now = true
	// if startDate is AFTER now = false
	return startDateTime
		? compareDesc(new Date(startDateTime), now) > 0
		: false;
};


export const passHasEnded = (wsSubsystemAccountPass) => {
	const now = new Date();
	const {
		endDateTime,
	} = wsSubsystemAccountPass;

	// if endDateTime is BEFORE now = true
	// if endDateTime is AFTER now = false
	return endDateTime
		? compareDesc(new Date(endDateTime), now) > 0
		: false;
};

const findCurrentPassIndex = (passes) => {
	return passes.findIndex(pass => passHasStarted(pass) && !passHasEnded(pass));
};

export const PassTimeStamp = ({ wsSubsystemAccountPass }) => {
	const language = useLanguage();

	if (!wsSubsystemAccountPass) {
		return null;
	}
	// inactive rolling pass
	if (!getPassActiveStatus(wsSubsystemAccountPass)) {
		return (
			<>
				<div className={plist.cardStatLabel}>
					<CmsContentRenderedInline contentKey={cms.firstTap} fallbackValue="Valid from first tap" />
				</div>
			</>
		);
	}
	// inactive fixed date pass, not sure if we can observe this status ?
	// see slack as of 07/07/2022 https://reflexions.slack.com/archives/CCF68M49M/p1657224604428339
	if (wsSubsystemAccountPass.startDateTime && !passHasStarted(wsSubsystemAccountPass)) {
		return (
			<div className={plist.cardStatLabel}>
				<CmsContentRenderedInline
					contentKey={cms.startsOn}
					fallbackValue={`Valid starting on ${dateTimeToAbbreviatedMonthDay(language, wsSubsystemAccountPass.startDateTime)}`}
					variables={{ date: dateTimeToAbbreviatedMonthDay(language, wsSubsystemAccountPass.startDateTime) }}
				/>
			</div>
		);
	}
	// active rolling or fixed date pass
	if (wsSubsystemAccountPass.endDateTime && !passHasEnded(wsSubsystemAccountPass)) {
		const endDateTime = new Date(wsSubsystemAccountPass.endDateTime);
		const currentDateTime = new Date();
		const isLongTermPass = Math.abs((currentDateTime - endDateTime) / (1000 * 60 * 60 * 24)) > LONG_TERM_PASS_DAYS_MIN;

		const endDate = isLongTermPass
			? dateTimeToAbbreviatedMonthDayYear(language, wsSubsystemAccountPass.endDateTime)
			: dateTimeToAbbreviatedMonthDay(language, wsSubsystemAccountPass.endDateTime);

		return (
			<div className={plist.cardStatLabel}>
				<CmsContentRenderedInline
					contentKey={cms.endsOn}
					fallbackValue={`Valid until ${endDate}`}
					variables={{ date: endDate }}
				/>
			</div>
		);
	}
};

const UnavailableAutoload = ({ cmsContent, passId }) => {
	return (
		<>
			<div className={cardSumStyle.autoLoadText}>
				<CmsContentRenderer.Span
					contentKey={cms.autoloadPassUnavailable}
					fallbackValue="Autoload unavailable"
				/>
			</div>
			<Tooltip
				tooltipId={`${passId}_tooltip`}
				overrideClass={cardSumStyle.tooltipIcon}
				ariaLabel={cmsContent[ cms.autoloadPassUnavailable ] || 'Autoload unavailable'}
				ariaLabelPanel={cmsContent[ cms.unavailablePassTooltip ] || 'Autoload is unavailable for this pass type.'}
			>
				<CmsContentRenderer.Span
					className={tooltipStyle.text}
					contentKey={cms.unavailablePassTooltip}
					fallbackValue="Autoload is unavailable for this pass type."
				/>
			</Tooltip>
		</>
	);
};

export const PassGroupContent = ({ passGroup }) => {

	const currentPassIndex = findCurrentPassIndex(passGroup);
	const currentPass = currentPassIndex > -1 ? passGroup[ currentPassIndex ] : passGroup[ 0 ];

	return (
		<>
			{currentPassIndex > -1 &&
				(<div className={plist.cardStatSection}>
					<div className={plist.cardStatValue}>
						<CmsContentRenderer.Span contentKey={cms.passCurrent} fallbackValue="Current Pass" />
					</div>
				</div>)}
			<div className={plist.cardStatSection}>
				<PassTimeStamp wsSubsystemAccountPass={currentPass} />
			</div>
		</>
	);
};

// Next pass refers to multiples of the same pass. Where the next pass in the group becomes active
// after the previous one expired.
// https://reflexions.slack.com/archives/CDTQPPXJ8/p1644246028140219
export const NextPass = ({
	passGroup,
}) => {
	const language = useLanguage();
	// find active pass index
	const currentPassIndex = findCurrentPassIndex(passGroup);
	// if active pass not found use second pass in passGroup list to display Next pass
	const nextPass = currentPassIndex > -1 ? passGroup[ currentPassIndex + 1 ] : passGroup[ 1 ];
	const currentPass = currentPassIndex > -1 ? passGroup[ currentPassIndex ] : passGroup[ 0 ];

	if (!nextPass) {
		return null;
	}

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<div className={cx(plist.nextContainer, plist.cardStat)}>
				<div className={plist.cardStatSection}>
					<div className={plist.cardStatValue}>
						<CmsContentRenderer.Span contentKey={cms.passNext} fallbackValue="Next Pass " />
					</div>

					<div className={plist.cardStatSection}>
						{nextPass.startDateTime
							? <CmsContentRenderer.Div
								className={plist.cardStatLabel}
								contentKey={cms.startsOn}
								fallbackValue={`Valid starting on ${dateTimeToAbbreviatedMonthDay(language, nextPass.startDateTime)}`}
								variables={{ date: dateTimeToAbbreviatedMonthDay(language, nextPass.startDateTime) }}
							/>
							: currentPass.endDateTime
								? <CmsContentRenderer.Div
									className={plist.cardStatLabel}
									contentKey={cms.startsOnNext}
									fallbackValue={`Valid starting on first tap after ${dateTimeToAbbreviatedMonthDay(language, addDays(new Date(currentPass.endDateTime), 1))}`}
									variables={{ date: dateTimeToAbbreviatedMonthDay(language, addDays(new Date(currentPass.endDateTime), 1)) }}
								/>
								: <CmsContentRenderer.Div
									className={plist.cardStatLabel}
									contentKey={cms.firstTapNext}
									fallbackValue="Valid starting on first tap after current pass expires"
								/>}
					</div>
				</div>
			</div>
		)}</CmsContentList>
	);
};

const ProductItem = ({
	groupedModes,
	wsSubsystemAccountPassGroup,
	wsAutoloadSummary,
	onModalClose,
	setModal,
	handleEditAutoload,
	isInSubsystemCatalog,
	isInTransitAccountCatalog,
}) => {
	const transit_account_id = useTransitAccountIdContext();
	const emvCanReload = useEmvCanReload({ subsystemAccountReference: transit_account_id });
	const canReloadPass = isInTransitAccountCatalog && emvCanReload;
	const wsSubsystemAccountPass = head(wsSubsystemAccountPassGroup);
	const canAutoload = isInSubsystemCatalog && wsSubsystemAccountPass.supportsAutoload && emvCanReload;
	const isSinglePass = wsSubsystemAccountPassGroup.length === 1;

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<>
				<div className={plist.productMain}>
					<div className={plist.productContentContainer}>
						<div className={plist.productHeader}>
							<div className={plist.headerContainer}>
								<h2 className={cx(typography.h7, plist.productTitle, plist.headerText)}>
									{wsSubsystemAccountPass.passDescription}
									{!isSinglePass ? (" x" + (wsSubsystemAccountPassGroup.length)) : ""}
								</h2>
							</div>

							<div className={cx(plist.cardStatValue, plist.withModes)}>
								{map(groupedModes, (mode, key) => (
									<a key={key}
										data-qa={key}
										title={join(mode.map(wsSubsystemOperator => wsSubsystemOperator?.operatorName), ', ')}
										className={plist.mode}
									>
										{getTransitModeIcon(key)}
									</a>
								))}
							</div>
						</div>

						<div className={plist.productInfo}>
							<div className={plist.cardStat}>
								{isSinglePass
									? <PassTimeStamp wsSubsystemAccountPass={wsSubsystemAccountPass} />
									: <PassGroupContent passGroup={wsSubsystemAccountPassGroup} />}
							</div>
						</div>
					</div>

					{(canReloadPass) ?
						<div className={plist.productButtonContainer}>
							<Button
								to={{
									pathname: getPathByRoute(routeKeys.AccountCardReloadPass, {
										transit_account_id,
										product_id: wsSubsystemAccountPass.passSKU,
									}),
									state: { purchase: true },
								}}
								additionalClassNames={plist.reloadButton}
								isPrimary={true}
							>
								<CmsContentRenderer.Span contentKey={cms.reloadPass} fallbackValue="Reload pass" />
							</Button>
						</div>
						: null
					}
				</div>

				{!isSinglePass
					? <NextPass passGroup={wsSubsystemAccountPassGroup} />
					: null}

				<IfLoggedIn>
					<div>
						{Boolean(wsAutoloadSummary)
							? <SubscriptionInfo {...{
								subsystemAccountReference: transit_account_id,
								subscriptionId: wsAutoloadSummary.subscriptionId,
							}}>{(wsAutoloadSubscriptionInfo) => {

									const autoLoadStatus = wsAutoloadSummary.status;
									const autoLoadSuspended = includes(AutoloadSuspendedStatuses, autoLoadStatus);
									const expirationDateTime = wsSubsystemAccountPass.expirationDateTime;

									return (
										<div className={plist.autoloadWrapper}>
											<div className={cardSumStyle.autoLoad}>
												{/* TODO consider paused autoload */}

												{/* TODO currently display both autoload stasuses "FulfillmentPending" and "Active" similarly;
															consider Pending autoload separately from Active status after BO will fix fulfilment issue
															see slack thread https://reflexions.slack.com/archives/CCF68M49M/p1650034472308169
														*/}
												{autoLoadSuspended
													? <SuspendedAutoload {...{
														cmsContent,
														autoloadId: wsAutoloadSubscriptionInfo.subscriptionId,
													}} />
													: <ActiveAutoload {...{
														autoloadInfo: wsAutoloadSubscriptionInfo,
														autoloadId: wsAutoloadSubscriptionInfo.subscriptionId,
													}} />
												}
											</div>

											<div className={cardSumStyle.manageDropdown}>
												<ManageMenu
													id={`subscription${wsAutoloadSubscriptionInfo.subscriptionId}ManageMenu`}
													ariaLabel={cmsContent[ cms.setupAutoload ] || 'Manage this autoload method'}
													options={getAutoloadOptions({
														subsystemAccountReference: transit_account_id,
														wsAutoloadSubscriptionInfo,
														subscriptionId: wsAutoloadSubscriptionInfo.subscriptionId,
														productName: wsAutoloadSubscriptionInfo.autoload.productName,
														expirationDateTime,
														onModalClose,
														isInSubsystemCatalog,
														setModal,
														handleEditAutoload,
													})}
													additionalClasses={manageMenuStyle.cardSummary}
												/>
											</div>
										</div>
									);
								}}</SubscriptionInfo>
							: canAutoload ?
								<div className={cx(plist.nextContainer, plist.cardStat)}>
									<div className={plist.cardStatSectionAutoload}>
										<Button
											to={{
												pathname: getPathByRoute(routeKeys.AutoloadPass, {
													transit_account_id,
													product_sku: wsSubsystemAccountPass.passSKU,
												}),
												search: stringify({ validityEndDtm: wsSubsystemAccountPass.endDateTime }),
											}}
											typeStyle={buttonTypeStylePlain}
										>
											<CmsContentRenderer.Span
												contentKey={cms.setupAutoload}
												fallbackValue="Setup Autoload"
											/>
										</Button>
										<Tooltip
											tooltipId={`${wsSubsystemAccountPass.passSKU}_autoloadPassTooltip`}
											overrideClass={cardSumStyle.autoLoadTooltip}
											ariaLabel={cmsContent[ cms.setupAutoload ] || 'Set up autoload'}
											ariaLabelPanel={cmsContent[ cms.autoloadTooltip ] || 'Specify a payment method to schedule your balance or passes to automatically reload when your balance is low.'}
										>
											<CmsContentRenderer.Span
												contentKey={cms.autoloadTooltip}
												fallbackValue="Specify a payment method to schedule your balance or passes to automatically reload when your balance is low."
											/>
										</Tooltip>
									</div>
								</div>
								:
								<div className={cx(plist.nextContainer, plist.cardStat)}>
									<UnavailableAutoload {...{ cmsContent, passId: wsSubsystemAccountPass.passSKU }} />
								</div>
						}
					</div>
				</IfLoggedIn>

				<IfLoggedIn>
					{wsSubsystemAccountPass.group
						? (
							<div className={plist.group}>
								<CmsContentRendered.P
									className={plist.groupLabel}
									contentKey={cms.autoloadGroup}
									variables={{ group: wsSubsystemAccountPass.group }}
									fallbackValue={`Provided by ${wsSubsystemAccountPass.group}`}
								/>
							</div>
						)
						: null}
				</IfLoggedIn>
			</>
		)}</CmsContentList>
	);
};

export default ProductItem;
