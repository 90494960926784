// extracted by mini-css-extract-plugin
export var base = "components-__Button-module___base";
export var btn = "components-__Button-module___btn";
export var clear = "components-__Button-module___clear";
export var flexOne = "components-__Button-module___flexOne";
export var hidden = "components-__Button-module___hidden";
export var isAlert = "components-__Button-module___isAlert";
export var isCharliePrimary = "components-__Button-module___isCharliePrimary";
export var isCharlieSecondary = "components-__Button-module___isCharlieSecondary";
export var isDisabled = "components-__Button-module___isDisabled";
export var isGhost = "components-__Button-module___isGhost";
export var isGhostAlt = "components-__Button-module___isGhostAlt";
export var isPrimary = "components-__Button-module___isPrimary";
export var isSecondary = "components-__Button-module___isSecondary";
export var isSmall = "components-__Button-module___isSmall";
export var learnMorePolicy = "components-__Button-module___learnMorePolicy";
export var link = "components-__Button-module___link";
export var linkBlue = "components-__Button-module___linkBlue";
export var spinner = "components-__Button-module___spinner";
export var text = "components-__Button-module___text";
export var themeLight = "components-__Button-module___themeLight";
export var typeStyleDanger = "components-__Button-module___typeStyleDanger";
export var typeStylePlain = "components-__Button-module___typeStylePlain";
export var typeStyleSecondary = "components-__Button-module___typeStyleSecondary";
export var wrapper = "components-__Button-module___wrapper";