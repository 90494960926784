import cx from "classnames";
import React from 'react';
import {
	values,
} from 'lodash';

import { levels, noticeError } from 'utils/Logger.js';

import { useStepContext } from 'context/StepContext.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { useFundingSourcesContext } from 'context/FundingSourcesContext.js';
import { WireFormHelper } from 'utils/FormHelper.js';
import PreventDefault from 'utils/PreventDefault.js';
import Cart from 'components/payments/Cart.js';

import {
	NEW_ADDRESS_ID,
	AddressSelectorContext,
	useAddressSelectorState,
} from "components/account-settings/address-selector/AddressSelector.js";
import SelectAddressForm, { AddressType } from 'components/account-settings/SelectAddressForm.js';
import CmsContentList from 'components/data/CmsContentList.js';
import SelectPaymentMethodForm from 'components/account/card/add-passes/SelectPaymentMethodForm.js';
import Addresses from 'components/data/session-user/Addresses.query.js';

import {
	removeAddressFormInputPrefix,
	getYupSchema as getCreditCardFormYupSchema,
} from 'pages/account/purchase/AddPaymentMethod.js';
import { getAddressYupSchema } from 'components/forms/address/Address.validations.js';
import { getPrimaryPaymentMethodSchema } from 'pages/account/purchase/PurchaseProductPayment.js';
import WSName from 'server/api-types/WSName.js';
import WSAddressExt from 'server/api-types/WSAddressExt.js';
import { useCartContext } from 'context/CartProvider.js';
import { GET_FULLNAME } from "components/data/session-user/SessionUser.js";
import useStdQuery from "components/data/hooks/useStdQuery.js";
import FormHelperProvider from 'utils/form-helper/FormHelperProvider.js';
import useFormHelper from 'utils/form-helper/useFormHelper.js';
import {
	PurchaseCharlieCardTakeoverLayout,
} from 'pages/account/PurchaseCharlieCard.js';

import * as tabs from 'styles/Tabs.module.css';
import * as forms from 'components/forms/Forms.module.css';
import * as purchaseCharlie from 'pages/account/PurchaseCharlieCardCcForm.module.css';
import { postNewAddress } from "components/account/address/PostNewAddress.js";
import { useApolloClient } from "@apollo/client";
import WSAddress from "server/api-types/WSAddress.js";
import { SAVE_ADDRESS_SHOWN } from "components/payments/AddressForm.js";
import { useFinalizeAndSetFundingSourcesToContext } from "pages/account/purchase/hooks/FinalizeFundingSourcesFromForm.js";

/**
 * Data-QA Tags:
 * CharlieCardCreditCardForm, CharlieCardHeader, CharlieCardCcFormAddressHeader, CharlieCardCreditCardFormSaveCheckbox,
 * CharlieCardCreditCardFormPurchaseBtn, LinkToAddCard, LinkToEMVCardd
 */

const cms = {
	purchaseStepOneHeader: "miscText.purchase-card-1-subheader",
	subHeader: "miscText.purchase-card-3-payment-subheader",
	subHeaderDetails: "miscText.purchase-card-3-shipping-subheader",
	enablementFeeDescription: "miscHtml.purchase-cart-enablementfee-description",
	shippingSubHeader: "miscText.general-shipping-address-subheader",
};

export const EnablementFee = ({ overrideClass = null }) => {
	return (
		<CmsContentList list={values(cms)}>{() => (
			<div className={overrideClass ? overrideClass : tabs.sidebar}>
				<CmsContentRenderer.Div
					rawHtml
					parseComponents={false}
					contentKey={cms.enablementFeeDescription}
					fallbackValue="<p>A one-time $3 card fee will be added to your purchase. This fee provides one more trip if you run out of value.</p><p><a href='https://www.mbta.com/charlie/enablement-fee' target='_blank'>Learn more about this policy</a>"
				/>
			</div>
		)}</CmsContentList >
	);
};

const PurchaseCharlieCardCcForm = () => {
	const { data: nameData } = useStdQuery(GET_FULLNAME);

	const { setShippingDetails } = useCartContext();
	const { step, nextStep } = useStepContext();

	const addressSelectorState = useAddressSelectorState({ addressType: AddressType.Shipping });

	const apolloClient = useApolloClient();

	const {
		selectedFundingSources,
		isMultiPayment,
	} = useFundingSourcesContext();

	const getYupSchema = (formHelper) => getCreditCardFormYupSchema({
		formHelper,
		isMultiPayment,
		isSelecting: [ false ],
		isAch: false,
		requireAchEula: false,
		isNew: [
			selectedFundingSources[ 0 ].isNew,
		],
		hasMobilePayment: false,
		disableCvv: false,
	})
		.concat(getPrimaryPaymentMethodSchema())
		.concat(getAddressYupSchema(addressSelectorState.addressType));

	const formHelperContext = useFormHelper({ getYupSchema });

	const {
		formRef,
		formHelper,
		submitting,
		setSubmitting,
	} = formHelperContext;

	const finalizeAndSetFundingSourcesToContext = useFinalizeAndSetFundingSourcesToContext({
		formHelper,
	});

	const kickoffSubmit = async () => {
		setSubmitting(true);

		try {
			const validated = await formHelper.startValidation(true);
			await finalizeAndSetFundingSourcesToContext();

			const {
				firstName,
				lastName,
			} = nameData?.session.customer.contact.name;

			const shippingAddress = removeAddressFormInputPrefix(
				validated,
				AddressType.Shipping
			);

			const wsAddressExt = shippingAddress.saveAddress
				? await postNewAddress({
					wsAddress: new WSAddress(shippingAddress),
					apolloClient,
				})
				: new WSAddressExt({
					...shippingAddress,
					...(
						addressSelectorState.selectedAddressId === NEW_ADDRESS_ID
							? {}
							: { ...shippingAddress }
					),
				});

			setShippingDetails({
				shippingName: new WSName({
					firstName,
					lastName,
				}),
				// Per Jon W: We are correct to use WSAddressExt and yes WSAddressExt.addressId is a required field per API spec
				// but the purchase flow allows for an empty value. jira is ccsa-3849
				// https://reflexions.slack.com/archives/CCF68M49M/p1643996850325519?thread_ts=1643232391.221400&cid=CCF68M49M
				shippingAddress: wsAddressExt,
			});

			nextStep();
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `Purchase charlie card validation failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
		}
	};

	const isSplit = false;
	const isFirst = true;

	return (
		<CmsContentList list={values(cms)}>{() => (
			<FormHelperProvider {...{ formHelperContext }}>
				<form
					ref={formRef}
					method="post"
					onSubmit={PreventDefault(kickoffSubmit)}
				>
					<PurchaseCharlieCardTakeoverLayout step={step}>
						<div className={cx(tabs.mainContent, purchaseCharlie.container)} data-qa="CharlieCardCreditCardForm">
							<div className={tabs.main}>
								<CmsContentRenderer.H2
									className={tabs.title}
									data-qa="CharlieCardHeader"
									contentKey={cms.subHeader}
									fallbackValue="1. Enter Payment Details"
								/>
								<div className={tabs.creditCardFormContainer}>
									<WireFormHelper {...{ formHelper }}>
										<SelectPaymentMethodForm
											{...{
												provideNickName: true,
												formHelper,
												isFirst: true,
											}}
										/>
									</WireFormHelper>
									<CmsContentRenderer.H3
										className={tabs.title}
										data-qa="CharlieCardCcFormAddressHeader"
										contentKey={cms.subHeaderDetails}
										fallbackValue="2. Where should your new Charlie Card be shipped?"
									/>

									<CmsContentRenderer.P
										contentKey={cms.shippingSubHeader}
										className={forms.label}
										fallbackValue='Enter shipping address'
									/>
									{addressSelectorState
										? <AddressSelectorContext.Provider value={addressSelectorState}>
											<Addresses includeAlternateAddresses={true}>{(wsAddressExts, addressQueryData) =>
												<SelectAddressForm
													{...{
														formHelper,
														wsAddressExts,
														alternateAddressIds: addressQueryData?.session.alternateSession?.customer.addresses.map(
															(alternateAddress) => alternateAddress.addressId
														),
														isSplit,
														isFirst,
														saveAddressCheckboxVisibility: SAVE_ADDRESS_SHOWN,
													}}
												/>
											}</Addresses>
										</AddressSelectorContext.Provider>
										: null}
								</div>
							</div>
							<EnablementFee />
						</div>
					</PurchaseCharlieCardTakeoverLayout>
					<Cart {...{ formHelper, submitting }} />
				</form>
			</FormHelperProvider>
		)}</CmsContentList>
	);
};

export default PurchaseCharlieCardCcForm;
