import WSBankcardTravelTokenDisplay from "./WSBankcardTravelTokenDisplay.js";
import WSTravelTokenDisplay, { WSTravelTokenDisplayFragment } from "./WSTravelTokenDisplay.js";

/**
 * This is an abstract class. We have to figure out what to instantiate on our own
 */

// 1.9.1.32 WSBankcardTravelTokenDisplay
// WSBankcardTravelTokenDisplay is a subclass of WSTravelTokenDisplay. This object represents displayable
// fields for bankcard travel tokens. Expected to set the tokenType as “Bankcard”.

const Bankcard = "Bankcard";

export default class WSTravelTokenDisplayFactory {
	static fromBackoffice(data) {
		switch (data.tokenType) {
			case Bankcard:
				return WSBankcardTravelTokenDisplay.fromBackoffice(data);
			default:
				return WSTravelTokenDisplay.fromBackoffice(data);
		}
	}
}

// GraphQLInterface
// When a field can return one of a heterogeneous set of types,
// a Interface type is used to describe what types are possible,
// what fields are in common across all types,
// https://graphql.org/graphql-js/type/#graphqlinterfacetype

const WSTravelTokenDisplayFactoryInterface = [ `
	interface WSTravelTokenDisplayFactory {
		${WSTravelTokenDisplayFragment}
	}`,
];

export const WSTravelTokenDisplayFactoryTypes = [
	...WSTravelTokenDisplayFactoryInterface,
];

export const WSTravelTokenDisplayFactoryResolvers = {
	WSTravelTokenDisplayFactory: {

		// The resolveType function returns a name <string> of
		// the interface abstract type should resolve to
		// Inspired by https://stackoverflow.com/a/59520666

		__resolveType({ tokenType }) {
			switch (tokenType) {
				case Bankcard:
					return "WSBankcardTravelTokenDisplay";
				default:
					return "WSTravelTokenDisplay";
			}
		},
	},
};
