import React from 'react';
import {
	get,
	map,
	values,
} from "lodash";

import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import { useCartContext } from 'context/CartProvider.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { useGlobalToastsContext } from 'context/ToastProvider.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderedInline from 'components/data/CmsContentRenderedInline.js';
import Toast from 'components/Toast.js';

const cms = {
	title: 'miscText.purchase-confirmation-summary',
	balanceConfirmation: 'miscText.purchase-balance-confirmation',
	passConfirmation: 'miscText.purchase-pass-confirmation',
};

// pass cart as prop since after we leave the purchase flow the cart gets cleared from context
const NewProductAdded = ({ cart }) => {
	const { removeToast } = useGlobalToastsContext();

	const productList = get(cart, 'products', []);
	const balance = get(cart, 'balance', 0);

	return (
		<CmsContentList list={values(cms)}>{() =>
			<Toast
				onClosed={removeToast}
				type="success"
				title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="The following has been added to your Charlie Card:" />}
				text={<div>
					<ul>
						{Boolean(balance) && (
							<li>
								<CmsContentRenderedInline
									elementType="span"
									contentKey={cms.balanceConfirmation}
									fallbackValue={`You have successfully added ${centsToDisplay(balance)} to your balance.`}
									variables={{ amount: centsToDisplay(balance) }}
								/>
							</li>)
						}
						{map(productList, ({ productLongName }) => (
							<li>
								<CmsContentRenderedInline
									elementType="span"
									contentKey={cms.passConfirmation}
									fallbackValue={`You have successfully added ${productLongName} to your account.`}
									variables={{ pass: productLongName }}
								/>
							</li>
						))}
					</ul>
				</div>}
			/>
		}</CmsContentList>
	);
};

export default NewProductAdded;
