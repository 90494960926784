import {
	clone,
	map,
} from "lodash";
import { WSBankcardTravelTokenDisplayType } from "./WSBankcardTravelTokenDisplay.js";
import WSTravelTokenDisplayFactory, { WSTravelTokenDisplayFactoryTypes } from "./WSTravelTokenDisplayFactory.js";
import WSKeyValue, { WSKeyValueType } from "server/api-types/WSKeyValue.js";

// Table ‎2672. WSRideHistory Field Descriptions
// This object represents the transit account ride history from a subsystem

export const RESTRICTED_TRAVEL_CORRECTION_ACTION_TRIPVOID = 'TripVoid';

export default class WSRideHistory {
	constructor({
		subsystem,
		subsystemAccountReference,
		subsystemAccountNickname,
		token,
		rideId,
		type,
		travelMode,
		travelModeDescription,
		startDateTime,
		endDateTime,
		startLocationCode,
		startLocationDescription,
		endLocationCode,
		endLocationDescription,
		totalFare,
		unpaidFare,
		fareDescription,
		riderClass,
		riderClassDescription,
		timeCategory,
		timeCategoryDescription,
		concession,
		concessionDescription,
		isUncollectible,
		restrictedTravelCorrectionActions = null,
	}) {

		// string(20)
		// (Required) Unique identifier of the subsystem
		// where travel occured.
		this.subsystem = subsystem;

		// string(20)
		// (Required) Unique identifier for the account in the
		// subsystem on which travel occured.
		this.subsystemAccountReference = subsystemAccountReference;

		// string(20)
		// (Required) Nickname of the subsystem account as
		// in One Account.
		this.subsystemAccountNickname = subsystemAccountNickname;

		// string(20)
		// (Required) Unique id for the ride history. This field equates to tripId if it’s a trip and tapId if
		// it's an inspection or rejected taps.
		this.rideId = rideId;

		// string(40)
		// (Required) The type to identify ride history type. Valid values are
		// Trip
		// InspectionTap
		// RejectedTap
		this.type = type;

		// string(20)
		// (Required) The unique reference to the mode of travel.
		// Per Alessandro, `TravelMode` is a required field but can be missing in production.
		// https://reflexions.slack.com/archives/CEEH1V14K/p1571338206156300?thread_ts=1571335089.123200&cid=CEEH1V14K
		this.travelMode = travelMode ?? null;

		// string(100)
		// (Required) Description of travel mode.
		this.travelModeDescription = travelModeDescription ? travelModeDescription : null;

		// WSTravelTokenDisplay
		// (Required) Token used for the travel.

		// temporary fix while BO returns token = null
		// when Inspection and Rejected trips
		// this.token = token;
		this.token = token ?? {};

		// dateTime
		// (Conditionally-Required) The date and time when the travel started. Either a startDateTime or endDateTime is always returned.
		// For rideType adjustment
		this.startDateTime = startDateTime;

		// dateTime
		// (Conditionally-Required) The date when the travel ended. Either a startDateTime or endDateTime is always returned.
		this.endDateTime = endDateTime ? endDateTime : null;

		// string(10) PII Level 1
		// (Conditionally-Required) The travel end location code. Either a startLocationCode or endLocationCode is always returned.
		this.startLocationCode = startLocationCode ? startLocationCode : null;

		//  string(100) PII Level 1
		// (Conditionally-Required) The travel starting location. Either a startLocation or endLocation is always returned.
		this.startLocationDescription = startLocationDescription ? startLocationDescription: null;

		// string(10) PII Level 1
		// (Conditionally-Required) The travel end location code. Either a startLocationCode or endLocationCode is always returned.
		this.endLocationCode = endLocationCode ? endLocationCode : null;

		// string(100) PII Level 1
		// (Conditionally-Required) The travel ending location. Either a startLocation or endLocation is always returned.
		this.endLocationDescription = endLocationDescription ? endLocationDescription : null;

		// int
		// (Required) The total amount of the fare charged to the customer in cents. If a pass is used for the
		// trip, this column will be blank.
		this.totalFare = totalFare;

		// int
		// (Required) Amount of the fare that has not yet been paid for in cents.
		this.unpaidFare = unpaidFare;

		// string(200)
		// (Optional) Description of the fare. For rideType adjustment this is the description of adjustment.
		this.fareDescription = fareDescription ? fareDescription : null;

		// int
		// (Required) The unique identifier of the rider class.
		this.riderClass = riderClass;

		// string(40)
		// (Required) Description of rider class.
		this.riderClassDescription = riderClassDescription;

		// string(20)
		// (Optional) The unique identifier of the time category. Allowed values are:
		// Peak
		// Off-Peak
		this.timeCategory = timeCategory;

		// string(30)
		// (Optional) Description of rider class.
		this.timeCategoryDescription = timeCategoryDescription;

		// string (20)
		// (Optional) The unique identifier of the concession.
		this.concession = concession;

		// string (30)
		// (Optional) Description of concession.
		this.concessionDescription = concessionDescription;

		// boolean
		// (Required) Flag to indicate if the travel transaction is currently uncollectible.
		this.isUncollectible = isUncollectible;

		/**
		 * @type {WSKeyValue[]}
		 * List<WSKeyValue>
		 * (Optional) List of actions that should be restricted for
		 * this trip due to a previous travel correction. For
		 * example: key 11, value TripVoid, key 12, value
		 * TapVoid. Only returned if
		 * returnRestrictedTravelCorrectionActions is true.
		 */
		this.restrictedTravelCorrectionActions = restrictedTravelCorrectionActions;

	}

	static fromBackoffice(data) {
		const result = new WSRideHistory(data);

		// This isn't necessarily a WSBankcardTravelTokenDisplay. Use a factory instead.
		result.token = WSTravelTokenDisplayFactory.fromBackoffice(result.token);
		result.restrictedTravelCorrectionActions = data.restrictedTravelCorrectionActions
			? map(data.restrictedTravelCorrectionActions, action => WSKeyValue.fromBackoffice(action))
			: null;

		return result;
	}

	toBackoffice() {
		const result = clone(this);
		return result;
	}

	toResolver() {
		const forGraphql = clone(this);

		forGraphql.id = forGraphql.rideId;
		forGraphql.token = forGraphql.token.toResolver();

		return forGraphql;
	}
}

export const WSRideHistoryFragment = `
	id: ID!
	subsystem: String!
	subsystemAccountReference: String!
	subsystemAccountNickname: String!
	token: WSTravelTokenDisplayFactory!
	rideId: String!
	type: String!
	travelMode: String
	travelModeDescription: String
	startDateTime: GraphQLDateTime
	endDateTime: GraphQLDateTime
	startLocationCode: String
	startLocationDescription: String
	endLocationCode: String
	endLocationDescription: String
	totalFare: Int!
	unpaidFare: Int!
	fareDescription: String
	riderClass: Int!
	riderClassDescription: String!
	timeCategory: String
	timeCategoryDescription: String
	concession: String
	concessionDescription: String
	isUncollectible: Boolean!
	restrictedTravelCorrectionActions: [ WSKeyValue! ]
`;

export const WSRideHistoryType = [
	...WSBankcardTravelTokenDisplayType,
	...WSTravelTokenDisplayFactoryTypes,
	...WSKeyValueType, `
	type WSRideHistory implements WSRideHistoryFactory {
		${WSRideHistoryFragment}
	}
` ];
