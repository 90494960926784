import React from 'react';
import {
	head,
	values,
} from 'lodash';

import CmsContentList from 'components/data/CmsContentList';
import StandardMutation from 'components/data/StandardMutation.js';
import AccountLayout from 'layouts/AccountLayout.js';
import NotificationContactsPanel from 'components/account/panels/NotificationContacts.js';
import NotificationPreferencesPanel from 'components/account/panels/NotificationPreferences.js';
import { findContentOrFallback } from 'components/data/CmsContentRenderer.js';

import {
	GET_NOTIFICATION_PREFERENCES, NotificationPreferences,
	UPDATE_NOTIFICATION_PREFERENCES,
} from "components/data/notification-preferences/NotificationPreferences.query.js";

import * as notificationContactsStyle from 'components/account/panels/NotificationContacts.module.css';
import { getCharlieCardBreadcrumbs } from '../CardSelection';
import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb';
import routeKeys from 'CustomerRouteKeys';
import { useBreadcrumbCallback } from 'context/BreadcrumbProvider';
import PendingChangesPrompt from 'components/PendingChangesPrompt';

const cms = {
	header: "miscText.notifications-header",
};
export const getNotificationBreadcrumbs = () =>  [
	...getCharlieCardBreadcrumbs(),
	<BreadcrumbItem
		key={cms.header}
		cmsKey={cms.header}
		fallbackValue={'Security & Login'}
		routeKey={routeKeys.AccountPersonalInformation}
	>
	</BreadcrumbItem>,

];

const Notifications = () => {
	useBreadcrumbCallback(getNotificationBreadcrumbs);

	return (
		<CmsContentList list={values(cms)}>{({ cmsContent }) => (
			<NotificationPreferences primaryFirst={true}>
				{({ notificationPreferences }) =>
					<StandardMutation
						mutation={UPDATE_NOTIFICATION_PREFERENCES}
						refetchQueries={[ {
							query: GET_NOTIFICATION_PREFERENCES,
						} ]}
						showLoadingState={false}
						errorChildren={null}
						awaitRefetchQueries={true}
					>
						{(updateNotifications, loading) =>
							<AccountLayout
								title={findContentOrFallback(cmsContent, cms.header, "Notifications")}
								additionalClasses={notificationContactsStyle.mainContainer}
							>
								<PendingChangesPrompt submitting={loading.loading} />
								<NotificationContactsPanel
									selectedSms={head(notificationPreferences).smsPhone}
									updateNotifications={updateNotifications}
								/>

								{notificationPreferences.map((notificationPreferences, index) =>
									<NotificationPreferencesPanel
										key={index}
										isPrimary={index === 0}
										{...{
											notificationPreferences,
											updateNotifications,
										}}
									/>,
								)}
							</AccountLayout>
						}
					</StandardMutation>
				}
			</NotificationPreferences>
		)}</CmsContentList>
	);
};
export default Notifications;
