import { gql } from "@apollo/client";

export const GET_SECURITY_QUESTIONS_BY_EMAIL = gql`
	query securityQuestionsByEmail (
		$email: String!
	) {
		securityQuestionsByEmail(
			email: $email
		)
	}
`;

export const UPDATE_SECURITY_QUESTIONS = gql`
	mutation updateSecurityQuestions (
		$securityQuestions: [ InputWSSecurityQA! ]
	) {
		updateSecurityQuestions(
			securityQuestions: $securityQuestions
		) {
			id
		}
	}
`;

export const GET_AVAILABLE_SECURITY_QUESTIONS = gql`
    query securityQuestionsQuery {
        securityQuestionsQ {
            name
            value
        }
    }
`;

export const GET_CUSTOMER_SECURITY_QUESTIONS = gql`
	query getCustomerSecurityQuestions {
		session {
			id
			customer {
				id
				contact {
					id
                    securityQAs {
                        securityQuestion
						securityAnswer
                    }
				}
			}
		}
	}
`;